import React from 'react'
import {Row} from "react-bootstrap";
import 'components/Vote/vote.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingPlaceholderBlock from "../common/LoadingPlaceholderBlock";

const MessageLoading = () => {
    return (
        <Row style={{position: 'relative'}}>
            <LoadingPlaceholderBlock height={'150px'} borderRadius={'3px'}/>
            <div style={{position: 'absolute', background: 'transparent', top: 0, left: 0, width: '100%', height: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <CircularProgress style={{color: '#008751'}}/>
                </div>
            </div>
        </Row>
    )
}

export { MessageLoading }