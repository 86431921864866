import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {payoutsActions, pricingActions, scheduleActions} from "_actions";

export default function useGetOrganizationSchedules(organizationId) {
    const dispatch = useDispatch();

    useEffect(() => {
        if(!_.isEmpty(organizationId)) {
            const params = { groupByHandle: true };
            dispatch(scheduleActions.getAll(organizationId, params));
            dispatch(payoutsActions.getAll());
            dispatch(pricingActions.getAll());
        }
    }, [organizationId]);

    const schedulesObj = useSelector((state) => state.schedules);
    const payoutObj = useSelector((state) => state.payout);
    const pricingObj = useSelector((state) => state.pricing);
    const schedules = schedulesObj.data;
    const loadingSchedules = schedulesObj.loading;
    const payoutRates = payoutObj.all_payouts;
    const loadingPayoutRates = payoutObj.loading;
    const pricingRates = pricingObj.all_pricing;
    const loadingPricingRates = pricingObj.loading;

    return {
        schedules,
        loadingSchedules,
        payoutRates,
        loadingPayoutRates,
        pricingRates,
        loadingPricingRates
    };
}