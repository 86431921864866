import React, {useRef, useState} from "react";
import {Box} from "@mui/system";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import {getObjectProperty} from "_helpers";
import {componentControl} from "../../utilities/Component.utils";

export default function MessageSubject({subjectParentId, subject, handleSubjectChange, editedSubjectObj, isEnabled}) {
    const [value, setValue] = useState(subject);

    const useFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
        return [htmlElRef, setFocus];
    }

    const [inputRef, setInputFocus] = useFocus();
    const handleChange = (event) => {
        const inputValue = _.get(event, 'target.value');
        setValue(inputValue);
        handleSubjectChange(subjectParentId, inputValue);
    }

    const getSubjectValue = () => {
        const editedSubject = _.get(getObjectProperty(editedSubjectObj, subjectParentId), 'subject');
        return editedSubject || value;
    }

    return(
        <Box>
            <Box sx={{...styles.subjectContainer, ...componentControl(isEnabled)}}>
                <Box sx={styles.subjectLabel}>Subject:</Box>
                <input
                    type="text"
                    autoComplete="off"
                    style={styles.input}
                    value={getSubjectValue()}
                    onChange={handleChange}
                    ref={inputRef}
                />
                <Box sx={styles.buttonContainer}>
                    <IconButton size={'small'} sx={styles.iconButton} onClick={setInputFocus}>
                        <EditIcon sx={styles.editIcon}/>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    subjectContainer: {
        display: 'flex',
        py: '0px',
        mt: '-1px',
        mb: '10px',
    },
    subjectLabel: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        color: '#2f3e3f',
        mr: '5px'
    },
    subjectText: {
        fontFamily: 'LarsseitRegular',
        fontSize: '15px',
        color: '#425152',
        border: '1px solid',
    },
    textField: {
        height: '27px',
        [`& input`]: {
            fontSize: '14px',
            fontFamily: 'LarsseitMedium',
            p: '0',
            m: '0'
        },
        p: '0',
        [`& fieldset`]: {
            border: '1px solid #0C2728',
            borderRadius: '4px',
            height: '27px',
            p: '0',
        },
    },
    input: {
        width: 'fit-content',
        height: '22px',
        backgroundColor: '#F3F5F6',
        border: '1px solid transparent',
        borderRadius: '3px',
        padding: '0 5px',
        outlineColor: '#368C86',
    },
    buttonContainer: {
        ml: '7px',
        mt: '-2px'
    },
    iconButton: {
        width: '24px',
        height: '24px'
    },
    editIcon: {
        color:'#07857C',
        fontSize: '18px',
        textAlign: 'right',
    }
}