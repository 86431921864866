import React from 'react';
import { messageActions } from '_actions';
import { connect } from 'react-redux';
import {VoteLoading, VoteMessage, VotePanel} from 'components/Vote';
import 'components/Vote/vote.css'
import {getCurrentAgentName, getNextMessage, isObjectEmpty} from '_helpers';

let progressBarStyle = {
    progressBarBackground: {
        background: '#fff',
        border: '1px solid #dee2e0',
        width: '100%',
        height: '27px',
        borderRadius: '4px'
    },

    progressBarForeground: {
        background: '#53c581',
        height: '25px',
        borderBottomLeftRadius: '3px',
        borderTopLeftRadius: '3px'
    }
};

class ReviewPage extends React.Component {

    /**
     * Retrieve the current user and set in state.
     * @type {{currentUser : object}}
     */
    state = {
        currentUser: JSON.parse(localStorage.getItem('user')),
        currentResponseOption: 0,
        selectedTag: '',
        loadingProps: false,
    };

    componentDidMount() {
        /**
         * Retrieve the organization that a user will be voting for.
         * @type {object}
         */
        getNextMessage(this.props.dispatch, true);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({loadingProps: false})
    }

    /**
     * When the vote form is submitted, this method transforms
     * the data into the format that is expected by the API.
     * @param votes
     * @returns {{votes: Array}}
     */
    transformData = (votes) => {
        let results = [];
        results.push(votes);

        const isLastResponseVote = this.state.currentResponseOption === this.props.nextMessage.message.response_options.length - 1;

        return {
            votes: results,
            isLastResponseVote
        }
    };

    /**
     * A call back function that is passed as a props
     * to the VoteForm and handles submission of a vote
     * @param payload
     */
    handleVoteSubmit = (payload) => {

        this.props.dispatch(messageActions.review(this.transformData(payload)));

        this.setState((prevState) => {
            return {
                currentResponseOption: prevState.currentResponseOption + 1,
                selectedTag: ''
            }
        });

        if (this.state.currentResponseOption === this.props.nextMessage.message.response_options.length - 1) {
            this.setState({ currentResponseOption: 0, loadingProps: true });
        }

    };

    voteTagSelectHandler = (event, value) => {
        if (event.target.id !== 'submitButton') {
            this.setState({
                selectedTag: value
            })
        }
    };

    escalateMessage = (event, messageId) => {
        event.preventDefault();

        const agentId = this.state.currentUser.id;
        const escalationParams = {
            user: agentId,
            actionType: 'ESCALATE',
            message: messageId,
            isReview: true
        };
        this.props.dispatch(messageActions.escalate(escalationParams))
    };

    skipMessage = (event, messageId) => {
        event.preventDefault();

        const agentId = this.state.currentUser.id;
        const skipMessageParams = {
            user: agentId,
            actionType: 'NO_RESPONSE_NEEDED',
            message: messageId,
            isReview: true
        };
        this.props.dispatch(messageActions.skipMessage(skipMessageParams))
    };

    calculateVoteProgress = (responseIndex, totalResponse) => ( ( responseIndex + 1 ) / totalResponse ) * 100;

    render() {

        const { nextMessage, reduxStateLoading } = this.props;
        if ( isObjectEmpty(nextMessage) ) {
            return (
              <VoteLoading/>
            )
        }

        const { message } = nextMessage;
        const { response_options, dataSourceHandle } = message;
        let { progressBarBackground, progressBarForeground } = progressBarStyle;

        if ( reduxStateLoading || this.state.loadingProps ){
            return(
              <VoteLoading/>
            )
        }

        if ( !(message && response_options) ){
            return(
              <VoteLoading/>
            )
        }

        const currentResponseOption = response_options[ this.state.currentResponseOption ];

        return (
            <div className="content-wrapper top-rel-80 wrapper-body">
                <div className="vote-container">
                    <div className="vote-left-panel">
                        <VoteMessage message={message} />
                        <div className="msg-action-button-container" style={{ marginTop: '50px' }}>
                            <div style={{ marginBottom: '20px' }}>
                                <button onClick={(e) => this.skipMessage(e, message.id)}
                                        className="btn btn-block btn-default font-15 color-green"
                                        style={{
                                            width: '100%',
                                            height: '47px'
                                        }}>
                                    No response needed
                                </button>
                            </div>
                            <div style={{}}>
                                <button onClick={(e) => this.escalateMessage(e, message.id)}
                                        className="btn btn-block btn-default font-15 color-green"
                                        style={{
                                            width: '100%',
                                            height: '47px'
                                        }}>
                                    Escalate to manager
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="vote-right-panel">
                        <div className="progress-bar-container">
                            <div style={progressBarBackground}>
                                <div style={{
                                    ...progressBarForeground,
                                    width: this.calculateVoteProgress(this.state.currentResponseOption, response_options.length) + '%'
                                }} />
                                <span className="progressBarText font-14 color-grey-mid">
                                    Reviewing {this.state.currentResponseOption + 1} of {response_options.length} responses
                                </span>
                            </div>
                        </div>

                        <VotePanel
                            messageHandleType={dataSourceHandle.type}
                            agentName={getCurrentAgentName()}
                            messageAuthor={message.author}
                            selectedTag={this.state.selectedTag}
                            responseOption={currentResponseOption}
                            onVoteTagSelect={this.voteTagSelectHandler}
                            onSubmit={this.handleVoteSubmit} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { messages, vote } = state;
    return {
        nextMessage: messages.nextMessage,
        reviewOrganization: vote.reviewOrganization,
        reduxStateLoading: messages.loadingNextMessage
    };
}

const connectedReviewPage = connect(mapStateToProps)(ReviewPage);

export { connectedReviewPage as ReviewPage };
