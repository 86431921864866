import {Box, FormControl, TextField} from "@mui/material";
import React, { useState } from "react";
import {useDispatch} from "react-redux";
import BasicButton from "../../../../common/BasicButton";
import useGorgiasMsgDataSourceUpdateInfo from "../../hooks/useGorgiasMsgDataSourceUpdateInfo";
import {accessibilityMode} from "../organizationSettingsConstants";
import {gorgiasMsgDataSourceActions, organizationSettingsActions} from "_actions";
import commonStyles from "../commonStyles";
import InformationTooltip from "../InformationTooltip";

export default function GorgiasSection({handle, mode, label}) {
    const dispatch = useDispatch();
    const {lastUpdatedHandleId, error} = useGorgiasMsgDataSourceUpdateInfo();
    const [customFieldsJson, setCustomFieldsJson] = useState(null);
    const [customFieldsJsonTouched, setCustomFieldsJsonTouched] = useState(false);

    const updateGorgiasSettings = () => {
        dispatch(gorgiasMsgDataSourceActions.update(handle.msgDataSourceId, {customFieldsJson}, handle.id)).then(() => {
            setCustomFieldsJsonTouched(false);
            dispatch(organizationSettingsActions.refreshDataSourceHandleInfo(handle.organizationId));
        });
    };

    const onCustomFieldsJsonUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomFieldsJson(event.target.value);
        setCustomFieldsJsonTouched(true);
    };

    const getCustomFieldsJsonErrorMessage = () => {
        if (lastUpdatedHandleId !== handle.id || customFieldsJsonTouched) {
            return null;
        } else if (error?.code === 'INVALID_CUSTOM_FIELDS_JSON') {
            return 'The provided JSON is invalid.';
        }
        return null;
    };

    const isEditMode = () => {
        return mode === accessibilityMode.EDIT;
    };

    const customFieldsJsonErrorMessage = getCustomFieldsJsonErrorMessage();

     return (
         <Box>
             <Box component={'h4'} sx={{...commonStyles.labelBold, display: 'flex', alignItems: 'center', gap: '5px'}}>
                 {label.GORGIAS}
                 <InformationTooltip label={("These settings are channel-wide,so other data sources in the channel will be updated too.")} />
             </Box>
             <Box>
                <Box component={'h4'} sx={{...commonStyles.labelMedium, display: 'flex', alignItems: 'center', gap: '5px'}}>
                    {label.CUSTOM_FIELDS_ON_CLOSE}
                    <InformationTooltip label={("Custom fields required to close ticket (e.g - 'Contact Reason', 'Retailer'). Check database to find custom fields and set here.")} />
                </Box>
                <FormControl fullWidth>
                    <TextField
                          multiline
                          error={customFieldsJsonErrorMessage !== null}
                          helperText={customFieldsJsonErrorMessage}
                          minRows={4}
                          value={customFieldsJson !== null ? customFieldsJson : handle.gorgias.customFieldsJson}
                          onChange={isEditMode() && onCustomFieldsJsonUpdate}
                          disabled={!isEditMode()}
                          inputProps={{style: styles.jsonInput}} />
                </FormControl>
                {
                    isEditMode() && (
                        <FormControl sx={styles.saveButton}>
                            <BasicButton
                                label={'Save Gorgias settings'}
                                action={isEditMode() && updateGorgiasSettings} />
                        </FormControl>
                    )
                }
             </Box>
         </Box>
     );
}

const styles = {
    jsonInput: {
        fontFamily: 'Monospace'
    },
    saveButton: {
        mt: 2
    }
};
