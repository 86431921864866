import React, {Component, Fragment} from 'react';
import {portalActions} from "_actions";
import _ from 'lodash';
import connect from "react-redux/es/connect/connect";
import {isObjectEmpty} from "_helpers";


/**
 * @author Chris Okebata
 */

class VotePortal extends Component {

  componentDidMount() {
    const {message, dispatch} = this.props;
    dispatch(portalActions.getPortalForMessage(_.get(message, 'id')));
  }

  renderPortals = (portal) => {

    const {id, displayLabel, integratedPortal: {endpoint}, loginUrl, loginUsername, loginPassword} = portal;
    const params = { loginUrl, loginUsername, loginPassword };

    return(
      <span key={id} style={{margin: '0 10px 10px 0', display: 'inline-block'}}>
          <button
            className="font-14 color-green btn btn-block tms-no-underline btn-default"
            type="button"
            style={{padding: '3px 10px', outline: 'none', borderRadius: 3}}
            onClick={()=>{this.props.dispatch(portalActions.openPortal(endpoint, params))}}
          >
            {displayLabel}
          </button>
        </span>
    )
  };

  render() {

    const { allPortals } = this.props;

    if (isObjectEmpty(allPortals)) {
      return <Fragment/>
    }

    if (_.get(allPortals, 'portal').length === 0) {
      return <Fragment/>
    }

    return(
      <Fragment>
        <div className="font-14 color-grey-mid" style={{borderBottom: '1px solid #CED2D0', marginTop: 50, paddingBottom: 5}}>
          Portals
        </div>
        <div style={{marginTop: 10}}>
          {
            allPortals.portal.map((item) => this.renderPortals(item))
          }
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { portals: { allPortalData } } = state;
  return {
    allPortals: allPortalData,
  };
}

const connectedVotePortal = connect(mapStateToProps)(VotePortal);
export { connectedVotePortal as VotePortal };