import { history } from '_helpers';
import { helpScoutOauthService} from "../_services";
import {alertMessages, oauthConstants} from "../_constants";
import { SnackBar } from "../components/common";

function initiateOauth(callbackUrl) {
    function request(){
        return { type: oauthConstants.HELPSCOUT_OAUTH_URL_REQUEST };
    }

    function success(authUrl){
        return { type: oauthConstants.HELPSCOUT_OAUTH_URL_SUCCESS, payload:authUrl };
    }

    function failure(error){
        return { type: oauthConstants.HELPSCOUT_OAUTH_URL_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        helpScoutOauthService.initiateOauth(callbackUrl)
            .then(
                authUrl => dispatch(success(authUrl)),
                (error) => {
                    dispatch(failure());
                },
            );
    };
}

function genAccessToken(params) {
    let callbackUrl = localStorage.getItem('redirectUrl');
    callbackUrl = JSON.parse(callbackUrl).pathname;

    function request() {
        return { type: oauthConstants.HELPSCOUT_ACCESSTOKEN_REQUEST };
    }

    function success(res) {
        return { type: oauthConstants.HELPSCOUT_ACCESSTOKEN_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: oauthConstants.HELPSCOUT_ACCESSTOKEN_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        helpScoutOauthService.oauthAccessToken(params)
            .then(
                (res) => {
                    if (res) {
                        history.push(callbackUrl);
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.HELPSCOUT_OAUTH_SUCCESS);
                    }
                },
                (error) => {
                    history.push(callbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.HELPSCOUT_OAUTH_FAILURE);
                },
            );
    };
}

export const helpScoutOauthActions = {
    initiateOauth,
    genAccessToken,
};