import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { messageActions } from '_actions';
import {ConversationList} from 'components/ConversationThread/ConversationList';

class SearchMessageDetails extends Component {

    componentDidMount() {
        const { dispatch, messageId} = this.props;
        const params = { addedDetails: true }
        dispatch(messageActions.getDetailsFromTrends(messageId, params));
    }

    render() {
        const { message, conversationThread, loading,} = this.props;
        return (
            <ConversationList
                mainMessage={message}
                messages={conversationThread}
                loading={loading}
                platform='trends'
            />

        );
    }
}

function mapStateToProps(state) {
    const { messages } = state;
    return {
        conversationThread: messages.message && messages.message.conversation ? messages.message.conversation : [],
        loading: messages.loading,
        message: messages.message,
    };
}

export default connect(mapStateToProps)(SearchMessageDetails);

SearchMessageDetails.propTypes = {
    dispatch: PropTypes.func,
    message: PropTypes.object,
    conversationThread: PropTypes.array,
    loadingModal: PropTypes.bool,
};
