import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {SegmentBuilder} from "../objects/SegmentBuilder";
import SegmentActions from "../../../../common/SegmentActions";

export default function () {
    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingCampaign;
    const campaign = proactive.campaign;
    const segments = _.get(campaign, 'segments') || [];
    let rows = [];

    if(!loading && segments.length > 0) {
        segments.map( segment => {
            const id = _.get(segment, 'id');
            const campaignId = _.get(segment, 'campaignId');
            const isActive = _.get(segment, 'isActive');
            const actions = <SegmentActions segmentId={id} campaignId={campaignId} status={isActive}/>
            const builder = new SegmentBuilder();
            builder.setId(id || '-');
            builder.setType(_.get(segment, 'type') || '-');
            builder.setKeyword(_.get(segment, 'keyword') || '-');
            builder.setCampaignId(campaignId || '-');
            builder.setIsActive(_.get(segment, 'isActive') || '-');
            builder.setActions(actions);
            rows.push(builder.build());
        });
    }

    const columns = [
        {
            id: 'keyword',
            label: 'Segment',
            width: 100,
        },
        {
            id: 'actions',
            label: 'Status',
            width: 50,
            minWidth: 50,
            align: 'right',
        },
    ];
    return {rows, columns};
}