import React, { useEffect } from 'react';
import moment from 'moment'
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import { formStyles } from "./FormStyles"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { DateRange } from 'components/common/formFields/DateRange';
import { connect } from 'react-redux';
import {styles} from "../MetricsDetailsStyles";
import "./metrics-daterange-picker.css";


let DateRangeForm = ({
                         handleSubmit, formValues,// from redux
                         startDate: givenStartDate,
                         endDate: givenEndDate,
                         setDates,
                     }) => {
    // This effect replaces our typical onSubmit, because it needs to be reactive without submit button.
    useEffect(() => {
        if (!formValues || !formValues.dateRange)
            return

        // If the date range has not been changed, skip.
        const sameStartDate = moment(formValues.dateRange.startDate).isSame(moment(givenStartDate), 'day');
        const sameEndDate = moment(formValues.dateRange.endDate).isSame(moment(givenEndDate), 'day');
        if (sameStartDate && sameEndDate)
            return

        // e.dateRange.startDate
        const earliestDate = moment([2019, 11]); // December 1, 2019
        let startDate = formValues.dateRange ? formValues.dateRange.startDate : givenStartDate;
        let endDate = formValues.dateRange ? formValues.dateRange.endDate : givenEndDate;

        // We don't allow dates earlier than `earliestDate`
        if (moment(startDate).diff(earliestDate, 'minutes') < 0) {
            startDate = earliestDate.format('YYYY-MM-DD HH:mm:ss');
            endDate = moment(earliestDate).add(1, 'M').endOf('month');

            // If `endDate` is greater than today, set to today
            if (moment().diff(endDate, 'minutes') < 0) {
                endDate = moment().add(1, 'd');
            }

            endDate = endDate.format('YYYY-MM-DD HH:mm:ss');
        } else {
            startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
            endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
        }

        setDates({ startDate, endDate });
    }, [formValues]);

    return (
        <form onSubmit={handleSubmit(() => {})}>
            <Stack direction={'row'} alignItems={'center'}>
                <Box sx={formStyles.input}>
                    <Field
                        submittedStartDate={givenStartDate}
                        submittedEndDate={givenEndDate}
                        name="dateRange"
                        type="text"
                        component={DateRange}
                    />
                    <ArrowDropDownIcon sx={styles.arrowDownIcon} />
                </Box>
            </Stack>
        </form>
    );
};


function validate(values) {
    const errors = {};

    if (!values.dateRange) {
        errors.error = 'No valid input';
    }

    return errors;
}

DateRangeForm = connect((state) => ({
    formValues: getFormValues('FilterForm')(state),
}))(DateRangeForm);

const dateRangeForm = reduxForm({
    form: 'FilterForm',
    validate,
    touchOnBlur: false,
})(DateRangeForm);

DateRangeForm.propTypes = {
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    setStartDate: PropTypes.string,
    setEndDate: PropTypes.string,
    onSubmit: PropTypes.func,
};

export default dateRangeForm;
