import {useEffect, useState} from "react";
import {tiktokOauthActions} from "_actions";
import {CLIENT_URL} from "config";
import {useDispatch, useSelector} from "react-redux";
import {utilityService} from "_services";
import setRedirectUrl from "../../Utilities/setRedirectUrl";

export default function useTikTokSetup({
	callbackPath,
}){
  const dispatch = useDispatch();
  const tiktok = useSelector((state) => state.tiktokOauth);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const performAction = () => {
    setShouldRedirect(true)
    const callbackUrl = `${CLIENT_URL}${callbackPath}`;
    setRedirectUrl(callbackUrl)
    dispatch(tiktokOauthActions.initiateOauth(callbackUrl));
  };

  useEffect(() => {
    if (!tiktok.loading && tiktok.authUrl.url && shouldRedirect){
      setShouldRedirect(false)
      utilityService.redirectToAuthUrl(tiktok.authUrl.url)
    }
  });

  return { initiateTikTokSetup: performAction };
}