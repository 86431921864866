export const chatdeskUpdatesConstants = {
   FETCH_ALL_REQUEST: 'FETCH_ALL_UPDATES_REQUEST',
   FETCH_ALL_SUCCESS: 'FETCH_ALL_UPDATES_SUCCESS',
   FETCH_ALL_FAILURE: 'FETCH_ALL_UPDATES_FAILURE',

   SAVE_ALL_REQUEST: 'SAVE_ALL_UPDATES_REQUEST',
   SAVE_ALL_SUCCESS: 'SAVE_UPDATES_UPDATES_SUCCESS',
   SAVE_ALL_FAILURE: 'SAVE_UPDATES_UPDATES_FAILURE',

   EDIT_REQUEST: 'EDIT_UPDATES_REQUEST',
   EDIT_SUCCESS: 'EDIT_UPDATES_SUCCESS',
   EDIT_FAILURE: 'EDIT_UPDATES_FAILURE',
};