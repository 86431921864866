import React, {Fragment} from 'react';

/**
 * @author Chris Okebata
 */

const viewConversationButton = (props) => {

  const {showConversation, buttonText} = props;
  return(
    <Fragment>
      {
        <button
          className="font-14 color-green tms-no-underline btn-link"
          style={{marginTop: '-1px'}}
          onClick={() => showConversation()}
        >
          { buttonText }
        </button>
      }
    </Fragment>
  )
};

export {viewConversationButton as ViewConversationButton}