import {Box} from "@mui/material";
import {QuillEditor} from "../../../../common";
import styles from "../Utilities/templateStyles";

export default function TemplateTextEditor({handleChange, index}){
    return(
        <Box sx={{margin: '15px 0 10px 0'}}>
            <Box sx={styles.inputDesc}>Template</Box>
            <Box className="proactive-template-text-editor">
                <QuillEditor
                    handleTextChange={(e) => handleChange(`template_${index}`, e)}
                    isEmail={true}
                    isImageEmbedSupported
                />
            </Box>
        </Box>
    )
}