import React from 'react';
import {isObjectEmpty, objectToQueryString, queryStringToObject, collectHandles} from "_helpers";
import {Grid, Row, Table} from 'react-bootstrap';
import {organizationActions, msgSourceActions, dataSourceHandleActions} from "_actions";
import {FilterForm} from "components/DataSource";
import connect from "react-redux/es/connect/connect";
import {Loading} from "components/common";
import DatasourceStatusContextMenu from "components/common/NavContextMenu/DatasourceStatusContextMenu";
import {Link} from "react-router-dom";
import {SwitchToggleButton} from "../common";
import {publicAndFeaturedVisibility} from "../../_constants";

class DataSourceHandles extends React.Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search)
        let {organizationId} = filterParams;
        organizationId = organizationId ? organizationId.split(",") : [];
        filterParams.organizationId = organizationId;

        this.state = {
            filterParams: {
                ...filterParams
            }
        };

    }

    getDetails = (filterParams) => {
        const queryString = objectToQueryString(filterParams);

        this.props.history.push({
            pathname: '/dataSourceHandles',
            search: `?${queryString}`
        });
    };

    handleDataSourceFormSubmit = (event) => {
        event.preventDefault();
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, offset: 0
                }
            }
        });
        const {filterParams} = this.state;
        this.getDetails(filterParams)
    };

    handleChange = () => {
        this.setState((prevState) => {
            return {
                filterParams: {...prevState.filterParams}
            }
        })
    };

    componentDidMount() {
        let {filterParams} = this.state;
        this.props.dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}));
        this.props.dispatch(msgSourceActions.getAll(filterParams));
    }

    handleOrganizationFilterChange = (organization) => {
        const {key} = this.state;
        this.populateParams(key, organization);
    };

    handleStatusChange = (data) => {
        const handleId = data.id;
        delete data.id;
        this.props.dispatch(dataSourceHandleActions.update(data, handleId));
    };

    getMsgSourcesHandles() {
        try {
            const {msgSources} = this.props;
            let msgDataSources = msgSources && msgSources.map(msgSource => msgSource.msgDataSources)
            if (!Array.isArray(msgDataSources) || msgDataSources.length === 0) {
                throw new Error()
            }
            if (msgDataSources && msgDataSources.length)
                msgDataSources = msgDataSources.flat()
            const handles = collectHandles(msgDataSources)
            return handles
        } catch (_) {
            // Something is wrong with the data, the array is not an array.
            return []
        }
    }

    getOrganizations = (organizations, returnValue) => {
        const companies = [];
        organizations.forEach((organization) => {
            if (organization && organization.id) {
                if (returnValue === 'ids') {
                    companies.push(organization.id);
                } else {
                    const organizationData = {
                        value: organization.id,
                        name: organization.name,
                    };
                    companies.push(organizationData);
                }
            }
        });
        return companies;
    };

    populateParams(key, organizationId, offset) {
        this.setState(prevState => ({
            key,
            filterParams: {
                ...prevState.filterParams,
                organizationId,
                offset
            },
        }));
        const {filterParams} = this.state;
        const params = {
            ...filterParams,
            organizationId,
            offset
        };
        return params
    }

    renderRowValue = (dataSourceHandle, handleStatusChange) => {
        const sourceId = dataSourceHandle.id;
        const dispatchStatus = dataSourceHandle.dispatchStatus === "LIVE" ? "On" : dataSourceHandle.dispatchStatus;
        let autoAction = "Off";
        if (dataSourceHandle.isAutoActionActive) {
            autoAction = "On"
        }
        return (
            <tr key={sourceId}>
                <td>
                    {dataSourceHandle.name}
                </td>
                <td>
                    Status -
                    <span className="text-transform-upper-first" style={{'paddingLeft': '4px'}}>
                        {dispatchStatus}
                    </span>
                </td>
                <td style={{'minWidth': '120px'}}>
                    <Link to={`/autoAction/${sourceId}`} className="color-grey-dark">
                        AutoAction
                    </Link>
                    <span className="text-transform-upper-first" style={{'paddingLeft': '4px'}}>
                        - {autoAction}
                    </span>
                </td>
                <td>
                    <DatasourceStatusContextMenu onClick={handleStatusChange} handle={dataSourceHandle}/>
                </td>
                <td>
                    <SwitchToggleButton
                        onChange={() => { this.toggleSupervisedChatEnabled(dataSourceHandle) }}
                        defaultChecked={ dataSourceHandle.isSupervisedChatEnabled }
                        label={"Sup.Chat"} />
                    <SwitchToggleButton
                        onChange={() => { this.toggleSupervisedChatAgentEditsEnabled(dataSourceHandle) }}
                        defaultChecked={ dataSourceHandle.isSupervisedChatAgentEditsEnabled }
                        label={"Agent Edits"} />
                </td>
            </tr>
        )
    };

    toggleSupervisedChatEnabled = (dataSourceHandle) => {
        const payload = {id: dataSourceHandle.id, isSupervisedChatEnabled: !dataSourceHandle.isSupervisedChatEnabled };
        dataSourceHandle.isSupervisedChatEnabled = payload.isSupervisedChatEnabled;
        this.props.dispatch(dataSourceHandleActions.update(payload));
    };

    toggleSupervisedChatAgentEditsEnabled = (dataSourceHandle) => {
        const payload = {id: dataSourceHandle.id, isSupervisedChatAgentEditsEnabled: !dataSourceHandle.isSupervisedChatAgentEditsEnabled };
        dataSourceHandle.isSupervisedChatAgentEditsEnabled = payload.isSupervisedChatAgentEditsEnabled;
        this.props.dispatch(dataSourceHandleActions.update(payload));
    };

    render() {
        const msgSourcesHandles = this.getMsgSourcesHandles();

        if (this.props.loading) {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '100px 0'}}>
                    <Loading/>
                </div>
            )
        }

        if (isObjectEmpty(msgSourcesHandles)) {
            return (
                <div>
                    Nothing found for these filters.
                </div>
            )
        }


        const {
            filterParams: {
                organization, organizationId
            }
        } = this.state;


        let companies, companiesIds;
        const {organizations} = this.props;
        let finalOrgList = organizations.organizations ? organizations.organizations : organizations;

        if (finalOrgList) {
            companies = this.getOrganizations(finalOrgList);
            companiesIds = this.getOrganizations(finalOrgList, 'ids');
        }

        return (
            <Grid style={{position: "relative", top: "100px", zIndex: "100"}}>
                <Row>

                    <FilterForm
                        onFormSubmit={this.handleDataSourceFormSubmit}
                        organization={organization}
                        company={organizationId}
                        companiesIds={companiesIds}
                        companies={companies}
                        organizations={finalOrgList}
                        handleOrgFilterChange={this.handleOrganizationFilterChange}
                        handleChange={this.handleChange}
                        {...this.state.filterParams}
                    />
                </Row>
                <br/>
                <br/>
                <Grid xs={12} sm={12} md={10} className="shadow-border">
                    <Row>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th colSpan="3">Message Source Handles</th>
                                <th>Status</th>
                                <th>Supervised Chat</th>
                            </tr>
                            </thead>
                            <tbody>
                                {msgSourcesHandles.map(dataSourceHandle => this.renderRowValue(dataSourceHandle, this.handleStatusChange))}
                            </tbody>
                        </Table>
                    </Row>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const {organizations: {organizations}, msgSources: {msgSources}, loading} = state;

    return {
        organizations: organizations,
        loading: loading,
        msgSources: msgSources,
    };
}

const dataSourceHandles = connect(mapStateToProps)(DataSourceHandles);
export {dataSourceHandles as DataSourceHandles}
