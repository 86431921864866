import { authorizationConstants } from "_constants";

/**
 * @author Chris Okebata
 */

const INITIAL_STATE = {
  user: {},
  signupLoading: false,
  error: '',
};

export function authorizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authorizationConstants.COMPANY_SIGNUP_REQUEST:
      return {
        ...state,
        signupLoading: true
      };
    case authorizationConstants.COMPANY_SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        signupLoading: false
      };
    case authorizationConstants.COMPANY_SIGNUP_FAILURE:
      return {
        ...state,
        error: action.payload,
        signupLoading: false
      };
    default:
      return state
  }
}
