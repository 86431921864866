import ConnectChannelButton from "../Utilities/ConnectChannelButton";
import React, {Fragment, useEffect} from "react";
import twitterLogo from "components/images/logos/logo_twitter.svg";
import {twitterOauthActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_URL} from "config";
import {utilityService} from "_services";


export default function TwitterSetup(){
  const dispatch = useDispatch();
  const twitter = useSelector((state) => state.twitterOauth);

  const performAction = () => {
    const callbackUrl = `${CLIENT_URL}/signup/add-channel`;
    dispatch(twitterOauthActions.getTwitterOauthUrl(callbackUrl))
  };

  //submit the verifier and dispatch an action to generate the access token
  const submitVerifier = (data) => {
    dispatch(twitterOauthActions.getAccessToken(data));
  };

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.user);
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const oauthVerifier = values.get('oauth_verifier');
    const data = {verifier: oauthVerifier, username: currentUser.username, userId: currentUser.id};

    if(oauthVerifier && currentUser) {
      submitVerifier(data);
    }
  }, []);

  useEffect(() => {
    if(!twitter.loading && twitter.data.authUrl){
      utilityService.redirectToAuthUrl(twitter.data.authUrl)
    }
  });

  return(
    <Fragment>
      <ConnectChannelButton
        label={'Connect with Twitter'}
        channelIcon={twitterLogo}
        useCircleAvatar={true}
        action={performAction}/>
    </Fragment>
  )
}