import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {utilityService} from "_services";
import {tiktokShopActions} from "_actions";
import {CLIENT_URL} from "../../../../../../../config";
import setRedirectUrl from "../../Utilities/setRedirectUrl";
import _ from "lodash";

export default function useTikTokShopSetup({callbackPath}) {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.tiktokShop.authLoading);
    const auth = useSelector((state) => state.tiktokShop.auth);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const authUrl = _.get(auth, 'url');

    const performAction = () => {
        setShouldRedirect(true)
        const callbackUrl = `${CLIENT_URL}${callbackPath}`;
        setRedirectUrl(callbackUrl)
        dispatch(tiktokShopActions.initiateAuth(callbackUrl));
    };

    useEffect(() => {
        if (!loading && !_.isEmpty(authUrl) && shouldRedirect){
            setShouldRedirect(false)
            utilityService.redirectToAuthUrl(authUrl)
        }
    });

    return { initiateTikTokSetup: performAction };
}