import {Grid} from "@mui/material";
import WhatToExpectIndicator from "./WhatToExpectIndicator";
import personIcon from "components/images/icons/person.svg";
import messageIcon from "components/images/icons/message.svg";
import checkMarkIcon from "components/images/icons/check_mark.svg";
import React,{Fragment} from "react";
import {dashedLines} from "./DashedLines"

export default function WhatToExpectSteps() {
  const {HorizontalDashedLine, VerticalDashedLine} = dashedLines;

  return(
    <Fragment>
      <HorizontalDashedLine/>
      <VerticalDashedLine/>

      <Grid item xs={12} sm={12} md={4} lg={4}
            backgroundColor={styles.bgColor}
            sx={styles.container}>
        <WhatToExpectIndicator label={'Complete onboarding call'} icon={personIcon}/>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}
            px={{xs: '0', sm: '0', md: '20px', lg: '20px'}}
            mt={{xs: '50px', sm: '50px', md: '0', lg: '0'}}
            backgroundColor={styles.bgColor}
            sx={styles.container}>
        <WhatToExpectIndicator label={'Customize your Response Guide'} icon={messageIcon}/>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}
            mt={{xs: '50px', sm: '50px', md: '0', lg: '0'}}
            backgroundColor={styles.bgColor}
            sx={styles.container}>
        <WhatToExpectIndicator label={'Start your free trial'} icon={checkMarkIcon}/>
      </Grid>
    </Fragment>
  )
}

const styles = {
  container: {display: 'flex', flexDirection: 'column', width: '230px', alignItems: 'center', zIndex: '10'},
  bgColor: {xs: '#ffffff', sm: '#ffffff', md: 'transparent', lg: 'transparent'}
};