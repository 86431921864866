import {Fragment} from "react";
import TagContainer from "./TagContainer";
import BasicModal from "../../../../../../common/BasicModal";

export default function TagModal({title, open, handleClose, handleSelectedTags, selectedTags}) {

    return(
        <Fragment>
            <BasicModal
                title={title}
                open={open}
                style={{width: '500px'}}
                handleClose={handleClose}>

                <TagContainer
                    handleClose={handleClose}
                    handleSelectedTags={handleSelectedTags}
                    selectedTags={selectedTags}
                />
            </BasicModal>
        </Fragment>
    )
}