import { IconButton, Menu, TableCell, TableRow, Typography } from "@material-ui/core";
import * as moment from 'moment-timezone';
import {
    timeDifferenceInSeconds,
    getCurrentUser,
    secondsToMinutesHour,
} from "_helpers";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MessageLockIcon from '@mui/icons-material/Lock';
import TimerIcon from '@mui/icons-material/Schedule';
import React, { Component, Fragment } from "react";
import { MessageActionsV2 } from './MessageActionsV2';
import _ from "lodash";
import { LOCK_DURATION_IN_SECS } from "_constants";
import { HtmlTooltip } from 'components/common';
import { MessageStatusV2 } from "components/Message-V2";
import Checkbox from '@material-ui/core/Checkbox';
import { showGreenTimer, showRedTimer } from "_helpers";
import processPlaceholder from "../v2/common/Message/Utilities/MessageUtils";

const ITEM_HEIGHT = 48;
const INCOMING = 'incoming';
const OUTGOING = 'outgoing';

class MessagesRowV2 extends Component {

    state = {
        anchorElement: null,
        setCheckbox: false,
    };

    /**
     * Identifies the clicked item and opens the popup menu for the three dots
     * @param event
     */
    handleClick = event => {
        this.setState({anchorElement: event.currentTarget});
    };


    /**
     * Responsible for closing the popup menu
     */
    handleClose = () => {
        this.setState({anchorElement: null});
    };

    render() {

        const {
            message, deleteMessageFromSource, escalateMessage, getCheckboxMessages,
            skipMessage, updateMessageFromSource, platform, classes,closeModalHandler,
            showModalHandle, undoMessage, selectedMessages, selectItem, showSelectAll, blacklist,
            likeMessageFromSource, resendForResponseGenList, sendMessageForResponseGen, actionMessage

        } = this.props;

        const { anchorElement, setCheckbox } = this.state;
        const open = Boolean(anchorElement);
        let _selectedMessages = selectedMessages || [];

        /**
         * Shorten string and add ellipses if the length of text exceeds 60 characters
         * actioned in this context is for an INCOMING message (when messages are escalated or marked as no response needed)
         */
        const messageActioned = message.actioned ? message.actioned : (message.userMessageActions && message.userMessageActions.length > 0);
        let text = processPlaceholder(message.text);
        const zone_name =  moment.tz.guess();
        const timezone = moment.tz(zone_name).zoneAbbr();
        const messageId = String(_.get(message, 'id'));
        const direction = _.get(message, 'direction');
        const hasEmployeeSeen = _.get(message, 'hasEmployeeSeen');
        const needsAdminAction = _.get(message, 'needsAdminAction');
        const dateCreated = _.get(message, 'dateCreated');
        const createdDate = _.get(message, 'dateCreatedFromSource');
        const lockedAtTime = _.get(message, 'lockedAtTime');
        const isAutoEscalated = _.get(message, 'isAutoEscalated');
        const handleType = _.get(message, 'handleType');
        let isChecked = _selectedMessages.includes(messageId);
        let showCheckbox = direction && direction.toLowerCase() === INCOMING && !isAutoEscalated && (!hasEmployeeSeen || needsAdminAction) && !messageActioned;
        let lockedMsgTimeDiff = timeDifferenceInSeconds(moment(lockedAtTime).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
        let newMsgTimeDiff = timeDifferenceInSeconds(moment(dateCreated).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
        let timeInMinsHour = secondsToMinutesHour(newMsgTimeDiff);
        const shouldDisplayTimer = direction && direction.toLowerCase() === INCOMING && !isAutoEscalated && !hasEmployeeSeen && !messageActioned && dateCreated;
        const user = getCurrentUser();
        const lockedByCurrentUser = _.get(message, 'lockedByUser') === user.id;
        let durationIcon;

        if(showCheckbox && !setCheckbox){
            this.setState({setCheckbox: showCheckbox});
            showSelectAll(showCheckbox);
            getCheckboxMessages(messageId);
        }

        if(lockedAtTime && lockedMsgTimeDiff < LOCK_DURATION_IN_SECS && !lockedByCurrentUser){
            durationIcon = <MessageLockIcon className="color-grey-mid" fontSize={'small'} style={{marginTop: '6px'}}/>
        }else if(shouldDisplayTimer && showGreenTimer(handleType, newMsgTimeDiff)){
            durationIcon = <HtmlTooltip
                title={
                    <div style={{padding: '2px'}}>
                        <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}> {timeInMinsHour} </Typography>
                    </div>
                }
                placement={'bottom'}
                style={{opacity: 1}}
                enterDelay={100}>
                <TimerIcon className="color-green" fontSize={'small'} style={{marginTop: '6px'}}/>
            </HtmlTooltip>
        }else if(shouldDisplayTimer && showRedTimer(handleType, newMsgTimeDiff)){
            durationIcon = <HtmlTooltip
                title={
                    <div style={{padding: '2px'}}>
                        <Typography style={{width: '100%', fontSize: '15px', color: '#d1504d'}}> {timeInMinsHour} </Typography>
                    </div>
                }
                placement={'bottom'}
                style={{opacity: 1}}
                enterDelay={100}>
                <TimerIcon className="color-danger" fontSize={'small'} style={{marginTop: '6px'}}/>
            </HtmlTooltip>
        }

        return (
            <TableRow key={messageId} className={classes.tableRow} id="v3TableRow">
                <Fragment>
                    <TableCell className={classes.tableCell}>
                        <a href={`messages/${messageId}?platform=${platform}`} className="tms-no-underline">
                            <Typography className={classes.typography14}>
                                <span className="font-14"> {message.organizationName}</span>
                            </Typography>
                        </a>
                    </TableCell>
                </Fragment>
                <Fragment>
                    <TableCell className={classes.tableCell}>
                        <a href={`messages/${messageId}?platform=${platform}`} className="tms-no-underline">
                            <MessageStatusV2
                                message={message}
                                classes={classes}
                                platform={platform}
                            />
                        </a>
                    </TableCell>
                </Fragment>
                <Fragment>
                    <TableCell className={classes.tableCell}>
                        <a href={`messages/${messageId}?platform=${platform}`} className="tms-no-underline">
                            <Typography className={classes.typography14}>
                                <span className="uppercase">{direction}</span>
                            </Typography>
                        </a>
                    </TableCell>
                </Fragment>

                <HtmlTooltip
                    title={
                        <div style={{minWidth: '250px', maxWidth: '450px', padding: '10px'}}>
                            <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                                {text}
                            </Typography>
                        </div>
                    }
                    placement={'bottom'}
                    style={{opacity: 1}}
                    enterDelay={700}
                >
                    <TableCell className={classes.tableCell}>
                        <a href={`messages/${messageId}?platform=${platform}`} className="btn-link tms-no-underline">
                            <Typography className={classes.typography14}>
                                <span className="font-14 truncate-string">{text}</span>
                            </Typography>
                            {(direction && direction.toLowerCase() === OUTGOING && message.isDispatchedSuccessfully) &&
                            <i className="paid-message-icon" title="Paid Message">Paid</i>
                            }
                        </a>
                    </TableCell>
                </HtmlTooltip>

                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography14}>
                        {moment(dateCreated).format('MMM DD hh:mm A z')}{timezone}
                    </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography14}>
                        {moment(createdDate).format('MMM DD hh:mm A z')}{timezone}
                    </Typography>
                </TableCell>


                <TableCell className={classes.tableCell}>
                    {durationIcon}
                </TableCell>

                <TableCell className={classes.tableCell}>
                    {showCheckbox &&
                    <Checkbox
                        checked={isChecked}
                        onChange={selectItem}
                        value={messageId}
                        style={
                            _selectedMessages.includes(messageId) ?
                                {color: '#008751', padding: "7px", fontSize: 2} :
                                {color: '#c8c8c8', padding: "7px", fontSize: 2}
                        }
                    />
                    }
                </TableCell>
                <TableCell className={classes.tableCell}>

                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        style={{padding: "7px", fontSize: 2}}
                    >
                        <MoreVertIcon size="small"/>
                    </IconButton>

                    <Menu
                        id="long-menu"
                        anchorEl={anchorElement}
                        open={open}
                        onClose={this.handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                minWidth: 150,
                                border: "solid 1px #dee2e0",
                                boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)"
                            },
                        }}
                    >

                        <MessageActionsV2
                            message={message}
                            mainMessage={message}
                            updateMessageFromSource={updateMessageFromSource}
                            skipMessage={skipMessage}
                            classes={classes}
                            blacklist={blacklist}
                            platform={platform}
                            handleClose={this.handleClose}
                            isMessageActioned={messageActioned}
                            escalateMessage={escalateMessage}
                            deleteMessageFromSource={deleteMessageFromSource}
                            closeModalHandler={closeModalHandler}
                            showModalHandle={showModalHandle}
                            showEditModalButton={true}
                            showUndoButton={true}
                            undoMessage={undoMessage}
                            resendForResponseGenList={resendForResponseGenList}
                            sendMessageForResponseGen={sendMessageForResponseGen}
                            likeMessageFromSource={likeMessageFromSource}
                            actionMessage={actionMessage}
                        />

                    </Menu>
                </TableCell>

            </TableRow>
        );
    };
}

export { MessagesRowV2 }
