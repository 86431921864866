

export const websocketConstants = {
  GET_TRENDS_MESSAGE_REQUEST: 'GET_TRENDS_MESSAGE_REQUEST',
  GET_TRENDS_MESSAGE_SUCCESS: 'GET_TRENDS_MESSAGE_SUCCESS',
  GET_TRENDS_MESSAGE_FAILURE: 'GET_TRENDS_MESSAGE_FAILURE',

  GET_VOTE_MESSAGE_REQUEST: 'GET_VOTE_MESSAGE_REQUEST',
  GET_VOTE_MESSAGE_SUCCESS: 'GET_VOTE_MESSAGE_SUCCESS',
  GET_VOTE_MESSAGE_FAILURE: 'GET_VOTE_MESSAGE_FAILURE',

  GET_MESSAGE_REQUEST: 'GET_MESSAGE_REQUEST',
  GET_MESSAGE_SUCCESS: 'GET_MESSAGE_SUCCESS',
  GET_MESSAGE_FAILURE: 'GET_MESSAGE_FAILURE',

  CLEAR_TRENDS_MESSAGE: 'CLEAR_TRENDS_MESSAGE',
  CLEAR_VOTE_MESSAGE: 'CLEAR_VOTE_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',

  ADD_TEAMS_MESSAGE_SUCCESS: 'ADD_TEAMS_MESSAGE_SUCCESS',
  REMOVE_TEAMS_MESSAGES_SUCCESS: 'REMOVE_TEAMS_MESSAGES_SUCCESS',
};
