import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { NotificationsInbox } from 'components/Inbox';


/**
 * @author Chris Okebata
 */


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
});

const CenteredGrid = (props) => {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item md={8} sm={12} xs={12}>
                    <NotificationsInbox data={props}/>
                </Grid>
            </Grid>
        </div>
    );
};

CenteredGrid.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CenteredGrid);