import React, { useEffect, useState, createElement } from 'react';
import { Popover as MUIPopover } from '@mui/material';
import importedStyles from './styles';

/**
 * @param {object} params
 * @param {id} params.id - unique id of the popover, for lists add the key into it (e.g. `my-cool-id-${key}`)
 * @param {React.ReactNode | string} params.ButtonComponent - component to be used as the trigger button
 * @param {React.ReactNode} params.ButtonChildren - children content of the `props.ButtonComponent`
 * @param {React.ReactNode} params.children - children content of the popover
 */
export default function Popover({
	id,
	ButtonComponent,
	ButtonChildren,
	ButtonProps,
	children,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
    if (!id)
      console.error('Popover component: `id` prop is required');
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const componentId = open ? id : undefined;

  return (
    <div>
			{createElement(
				ButtonComponent, 
				{ 
					...ButtonProps,
					'aria-describedby': componentId,
					onClick: handleClick,
				},
				ButtonChildren
			)}

      <MUIPopover
        id={componentId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				elevation={2}
				PaperProps={{
					sx: styles.root,
				}}
      >
        {children}
      </MUIPopover>
    </div>
  );
}

const styles = {
	root: {
		...importedStyles.root,
		borderRadius: '2px',
		background: '#fff',
		border: '1px solid #D7DBDC',
		overflow: 'visible',
		p: 1.5,
		ml: 4,

		':after': {
			content: '""',
			pointerEvents: 'none',
			position: 'absolute',
			height: '12px',
			width: '12px',
			transform: 'rotate(45deg)',
			top: '-6px',
			right: '9px',
			display: 'block',
			background: '#fff',
			border: '1px solid #D7DBDC',
			borderBottomColor: 'transparent',
			borderRightColor: 'transparent',
		}
	}
}