import pjson from './../package.json';
import configJson from "./auth0_config.json";

let backendHost;
let trendsBackendHost;
let hostname = window && window.location && window.location.hostname;
let websocketServer = 'https://websocket.chatdesk.com';
let PROD_CLIENT_URL = 'teams.chatdesk.com';
let STAGING_CLIENT_URL = 'teamsstaging.chatdesk.com';
let DEV_CLIENT_URL = 'teamsdev.chatdesk.com';
let TEST_CLIENT_URL = 'http://localhost:3000';

if (hostname === PROD_CLIENT_URL) {
    backendHost = 'https://teamsapi.chatdesk.com';
    trendsBackendHost = 'https://trends.chatdesk.com';
} else if (hostname === DEV_CLIENT_URL) {
    backendHost = 'https://teamsdevapi.chatdesk.com';
    trendsBackendHost = 'https://awstrendsdev.chatdesk.com';
    websocketServer = 'https://websocketdev.chatdesk.com';
} else if (hostname === STAGING_CLIENT_URL) {
    backendHost = 'https://teamsstagingapi.chatdesk.com';
    trendsBackendHost = 'https://trendsstaging.chatdesk.com';
    websocketServer = 'https://websocketstaging.chatdesk.com';
} else {
    hostname = TEST_CLIENT_URL;
    backendHost = 'http://localhost:8080';
    trendsBackendHost = 'http://localhost:8090';
    websocketServer = 'http://localhost:8081';
}
if (!hostname.includes("http")) {
    hostname = "https://" + hostname;
}

export const CLIENT_URL = hostname;
export const SERVER_URL = backendHost;
export const TRENDS_SERVER_URL = trendsBackendHost;
export const WEBSOCKET_SERVER_URL = websocketServer;

// export const SERVER_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : 'http://localhost:8080';
export const CLIENT_VERSION = pjson.version;
export const REACT_VERSION = pjson.dependencies.react;

export function getAuth0Config() {
    if(hostname.includes(PROD_CLIENT_URL)){
        return{
            domain: configJson.prod.domain,
            clientId: process.env.REACT_APP_AUTH0_TEAMS_PROD_CLIENT_ID,
            audience: configJson.prod.audience,
            scope: configJson.prod.scope,
        }
    }else if(hostname.includes(STAGING_CLIENT_URL) || hostname.includes(DEV_CLIENT_URL)){
        return{
            domain: configJson.dev.domain,
            clientId: process.env.REACT_APP_AUTH0_TEAMS_DEV_CLIENT_ID,
            audience: configJson.dev.audience,
            scope: configJson.dev.scope,
        }
    }else {
        return {
            domain: configJson.test.domain,
            clientId: process.env.REACT_APP_AUTH0_TEAMS_TEST_CLIENT_ID,
            audience: configJson.test.audience,
            scope: configJson.test.scope,
        };
    }
}

export function getFirebaseConfig() {
    if(hostname.includes(PROD_CLIENT_URL)) {
        return {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_PROD,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
            appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD
        }
    } else {
        return {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        }
    }
}
