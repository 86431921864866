import React, {Fragment} from "react";
import ChannelCard from "../../ChannelCard";
import logoDixa from "../../../../../../images/logos/logo_dixa.png";
import useDixaSetup from "./useDixaSetup";
import DixaSetupModal from "./DixaSetupModal";

export default function DixaEntry() {
    const { initiateDixaSetup, dixaSetupOpen, handleDixaSetupOpen, handleDixaSetupClose } = useDixaSetup();

    return (
        <Fragment>
            <ChannelCard
                icon={logoDixa}
                label={'Dixa'}
                description={'Unify your communication channels; email, chat, and social media channels into Teams to centralize customer interactions'}
                onClick={initiateDixaSetup}
            />

            <DixaSetupModal
                open={dixaSetupOpen}
                handleOpen={handleDixaSetupOpen}
                handleClose={handleDixaSetupClose}
            />
        </Fragment>
    )
}