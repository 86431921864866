export class ConversationBuilder {
    constructor() {
        this.conversation = {};
    }

    setId(id){
        this.conversation.id = id;
        return this;
    }

    setMessageId(messageId){
        this.conversation.messageId = messageId;
        return this;
    }

    setAuthorName(authorName){
        this.conversation.authorName = authorName;
        return this;
    }

    setAuthorEmail(authorEmail){
        this.conversation.authorEmail = authorEmail;
        return this;
    }

    setSubject(subject){
        this.conversation.subject = subject;
        return this;
    }

    setRecipientEmail(recipientEmail){
        this.conversation.recipientEmail = recipientEmail;
        return this;
    }

    setText(text){
        this.conversation.text = text;
        return this;
    }

    setCreatedDate(createdDate){
        this.conversation.createdDate = createdDate;
        return this;
    }

    setDirection(direction){
        this.conversation.direction = direction;
        return this;
    }

    setAttachments(attachments){
        this.conversation.attachments = attachments;
        return this;
    }

    setIsTrendsSource(isTrendsSource){
        this.conversation.isTrendsSource = isTrendsSource;
        return this;
    }

    setCurrentPage(currentPage){
        this.conversation.currentPage = currentPage;
        return this;
    }

    setAssignee(assignee){
        this.conversation.assignee = assignee;
        return this;
    }

    setOther(other){
        this.conversation.other = other;
        return this;
    }

    build(){
        return this.conversation;
    }
}

