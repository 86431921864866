import {Fragment} from "react";
import BasicModal from "../../../../../../common/BasicModal";
import PerfectResponseModalContainer from "./PerfectResponseModalContainer";

export default function PerfectResponseModal({title, open, handleClose, handleSubmit, handleCloseTicket}) {

    const handleSubmitResponse = () => {
        handleSubmit();
        handleClose();
    }

    const Content = () => {
        return (
            <PerfectResponseModalContainer
                handleClose={handleClose}
                handleSubmit={handleSubmitResponse}
                handleCloseTicket={handleCloseTicket}
            />
        )
    }

    return(
        <Fragment>
            <BasicModal
                title={title}
                open={open}
                style={{width: '500px'}}
                handleClose={handleClose}>

                <Content />
            </BasicModal>
        </Fragment>
    )
}