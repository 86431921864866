import {statsConstants} from "../_constants";

const INITIAL_STATE = {
    similarityScoreDetails: {},
    loading: false,
    error: ''
};

export function similarityScoreReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case statsConstants.GET_SIMILARITY_STATS_REQUEST:
            return { ...state, loading: true, error: ''};
        case statsConstants.GET_SIMILARITY_STATS_SUCCESS:
            return { ...state, similarityScoreDetails: action.payload, loading: false};
        case statsConstants.GET_SIMILARITY_STATS_FAILURE:
            return { ...state, error: action.error, loading: false};

        default:
            return state;
    }
}
