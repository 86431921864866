import React, {Fragment} from "react";
import BasicSelect from "./BasicSelect";
import useGetOrganizations from "../views/Proactive/Utilities/hooks/useGetOrganizations";

export default function OrganizationSelect({ name, label, placeholder, defaultValue, handleChange, input, meta }) {
    const { organizations } = useGetOrganizations();

    return(
        <Fragment>
            <BasicSelect
                name={name}
                label={label}
                placeholder={placeholder}
                options={organizations}
                onChange={handleChange}
                input={input}
                defaultValue={defaultValue}
                meta={meta} />
        </Fragment>
    )
}