import {organizationSettingsConstants} from '_constants';

const INITIAL_STATE = {
    handleInfo: {},
    loadingHandleInfo: false,
    handleInfoError: false,

    autoActionsInfo: {},
    loadingAutoActionsInfo: false,
    handleAutoActionsInfoError: false,

    organizationInfo: {},
    loadingOrganizationInfo: false,
    organizationInfoError: false,

    organizationCSMs: [],
    loadingOrganizationCSMs: false,
    organizationCSMsError: false,

    removalProcesses: {},
    loadingRemovalProcesses: false,
    removalProcessesError: false,

    ticketBudgetUpdate: {},
    updatingBilling: '',
    loadingTicketBudgetUpdate: false,
    ticketBudgetUpdateError: '',

    organizationAttributeUpdate: {},
    loadingOrganizationAttributeUpdate: false,
    organizationAttributeUpdateError: '',
    payloadRef: {}
};

export function organizationSettingsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case organizationSettingsConstants.GET_ORGANIZATION_INFO_REQUEST:
            return {...state, loadingOrganizationInfo: true, organizationInfoError: ''};
        case organizationSettingsConstants.GET_ORGANIZATION_INFO_SUCCESS:
            return {...state, organizationInfo: action.payload, loadingOrganizationInfo: false};
        case organizationSettingsConstants.GET_ORGANIZATION_INFO_FAILURE:
            return {...state, organizationInfoError: action.error, loadingOrganizationInfo: false};

        case organizationSettingsConstants.UPDATE_TICKET_BUDGET_REQUEST:
            return {...state, loadingTicketBudgetUpdate: true, ticketBudgetUpdateError: '', updatingBilling: action.payload};
        case organizationSettingsConstants.UPDATE_TICKET_BUDGET_SUCCESS:
            return {...state, ticketBudgetUpdate: action.payload, loadingTicketBudgetUpdate: false};
        case organizationSettingsConstants.UPDATE_TICKET_BUDGET_FAILURE:
            return {...state, ticketBudgetUpdateError: action.error, loadingTicketBudgetUpdate: false};

        case organizationSettingsConstants.UPDATE_ORGANIZATION_ATTRIBUTE_REQUEST:
            return {...state, loadingOrganizationAttributeUpdate: true, organizationAttributeUpdateError: '', payloadRef: action.payload};
        case organizationSettingsConstants.UPDATE_ORGANIZATION_ATTRIBUTE_SUCCESS:
            return {...state, organizationAttributeUpdate: action.payload, loadingOrganizationAttributeUpdate: false};
        case organizationSettingsConstants.UPDATE_ORGANIZATION_ATTRIBUTE_FAILURE:
            return {...state, organizationAttributeUpdateError: action.error, loadingOrganizationAttributeUpdate: false};

        case organizationSettingsConstants.GET_ORGANIZATION_CSM_LIST_REQUEST:
            return {...state, loadingOrganizationCSMs: true, organizationCSMsError: ''};
        case organizationSettingsConstants.GET_ORGANIZATION_CSM_LIST_SUCCESS:
            return {...state, organizationCSMs: action.payload, loadingOrganizationCSMs: false};
        case organizationSettingsConstants.GET_ORGANIZATION_CSM_LIST_FAILURE:
            return {...state, organizationCSMsError: action.error, loadingOrganizationCSMs: false};

        case organizationSettingsConstants.GET_DATA_SOURCE_HANDLE_INFO_FETCH_REQUEST:
            return {...state, loadingHandleInfo: true, handleInfoError: ''};
        case organizationSettingsConstants.GET_DATA_SOURCE_HANDLE_INFO_REFRESH_REQUEST:
            return {...state, loadingHandleInfo: false, handleInfoError: ''};
        case organizationSettingsConstants.GET_DATA_SOURCE_HANDLE_INFO_SUCCESS:
            return {...state, handleInfo: action.payload, loadingHandleInfo: false};
        case organizationSettingsConstants.GET_DATA_SOURCE_HANDLE_INFO_FAILURE:
            return {...state, handleInfoError: action.error, loadingHandleInfo: false};

        case organizationSettingsConstants.GET_AUTO_ACTIONS_INFO_FETCH_REQUEST:
            return {...state, loadingAutoActionsInfo: true, handleAutoActionsInfoError: ''};
        case organizationSettingsConstants.GET_AUTO_ACTIONS_INFO_REFRESH_REQUEST:
            return {...state, loadingAutoActionsInfo: false, handleAutoActionsInfoError: ''};
        case organizationSettingsConstants.GET_AUTO_ACTIONS_INFO_SUCCESS:
            return {...state, autoActionsInfo: action.payload, loadingAutoActionsInfo: false};
        case organizationSettingsConstants.GET_AUTO_ACTIONS_INFO_FAILURE:
            return {...state, handleAutoActionsInfoError: action.error, loadingAutoActionsInfo: false};

        case organizationSettingsConstants.GET_ORGANIZATION_REMOVAL_PROCESSES_REQUEST:
            return {...state, loadingRemovalProcesses: true, removalProcessesError: ''};
        case organizationSettingsConstants.GET_ORGANIZATION_REMOVAL_PROCESSES_SUCCESS:
            return {...state, removalProcesses: action.payload, loadingRemovalProcesses: false};
        case organizationSettingsConstants.GET_ORGANIZATION_REMOVAL_PROCESSES_FAILURE:
            return {...state, removalProcessesError: action.error, loadingRemovalProcesses: false};

        default:
            return state
    }
}
