/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 03/10/2018 : 12:57 PM
 */

export const employmentConstants = {

    GET_ALL_REQUEST: 'EMPLOYMENTS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'EMPLOYMENTS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'EMPLOYMENTS_GET_ALL_FAILURE',

    GET_USER_EMPLOYMENTS_REQUEST: 'GET_USER_EMPLOYMENTS_REQUEST',
    GET_USER_EMPLOYMENTS_SUCCESS: 'GET_USER_EMPLOYMENTS_SUCCESS',
    GET_USER_EMPLOYMENTS_FAILURE: 'GET_USER_EMPLOYMENTS_FAILURE',

    GET_ORG_EMPLOYMENTS_REQUEST: 'GET_ORG_EMPLOYMENTS_REQUEST',
    GET_ORG_EMPLOYMENTS_SUCCESS: 'GET_ORG_EMPLOYMENTS_SUCCESS',
    GET_ORG_EMPLOYMENTS_FAILURE: 'GET_ORG_EMPLOYMENTS_FAILURE',

    GET_ORG_SCORE_REQUEST: 'GET_ORG_SCORE_REQUEST',
    GET_ORG_SCORE_SUCCESS: 'GET_ORG_SCORE_SUCCESS',
    GET_ORG_SCORE_FAILURE: 'GET_ORG_SCORE_FAILURE',

    GET_ORG_WITH_MESSAGE_REQUEST: 'GET_ORG_WITH_MESSAGE_REQUEST',
    GET_ORG_WITH_MESSAGE_SUCCESS: 'GET_ORG_WITH_MESSAGE_SUCCESS',
    GET_ORG_WITH_MESSAGE_FAILURE: 'GET_ORG_WITH_MESSAGE_FAILURE',

    DELETE_REQUEST: 'EMPLOYMENTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'EMPLOYMENTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'EMPLOYMENTS_DELETE_FAILURE',

    GET_DETAILS_REQUEST: 'GET_EMPLOYMENT_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_EMPLOYMENT_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_EMPLOYMENT_DETAILS_FAILURE',

    SAVE_EMPLOYMENT_REQUEST: 'EMPLOYMENTS_SAVE_REQUEST',
    SAVE_EMPLOYMENT_SUCCESS: 'EMPLOYMENTS_SAVE_SUCCESS',
    SAVE_EMPLOYMENT_FAILURE: 'EMPLOYMENTS_SAVE_FAILURE',

    GET_ORG_TEAM_LEAD_REQUEST: 'GET_ORG_TEAM_LEAD_REQUEST',
    GET_ORG_TEAM_LEAD_SUCCESS: 'GET_ORG_TEAM_LEAD_SUCCESS',
    GET_ORG_TEAM_LEAD_FAILURE: 'GET_ORG_TEAM_LEAD_FAILURE',

    GET_ORG_CSM_REQUEST: 'GET_ORG_CSM_REQUEST',
    GET_ORG_CSM_SUCCESS: 'GET_ORG_CSM_SUCCESS',
    GET_ORG_CSM_FAILURE: 'GET_ORG_CSM_FAILURE',

    REMOVE_EMPLOYMENT_REQUEST: 'EMPLOYMENT_REMOVE_REQUEST',
    REMOVE_EMPLOYMENT_SUCCESS: 'EMPLOYMENT_REMOVE_SUCCESS',
    REMOVE_EMPLOYMENT_FAILURE: 'EMPLOYMENT_REMOVE_FAILURE',

    GET_EMPLOYMENT_METRICS_REQUEST: "GET_EMPLOYMENT_METRICS_REQUEST",
    GET_EMPLOYMENT_METRICS_SUCCESS: "GET_EMPLOYMENT_METRICS_SUCCESS",
    GET_EMPLOYMENT_METRICS_FAILURE: "GET_EMPLOYMENT_METRICS_FAILURE",

    GET_CONVERSATION_STATS_REQUEST: 'GET_CONVERSATION_STATS_REQUEST',
    GET_CONVERSATION_STATS_SUCCESS: 'GET_CONVERSATION_STATS_SUCCESS',
    GET_CONVERSATION_STATS_FAILURE: 'GET_CONVERSATION_STATS_FAILURE',
};