import { voteConstants } from '_constants';


const INITIAL_STATE = {
    init: true,
    loading: false,
};

export function voteReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case voteConstants.SUBMIT_VOTE_REQUEST:
            return {...state, loading: true, init: true};
        case voteConstants.SUBMIT_VOTE_SUCCESS:
            return {...state, action, loading: false, init: false};
        case voteConstants.SUBMIT_VOTE_FAILURE:
            return {...state, error: action, loading: false, init: false};
        default:
            return state
    }
}