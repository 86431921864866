import {alertMessages, oauthConstants} from "_constants";
import {history} from '_helpers';
import {instagramOauthService} from "_services";
import {SnackBar} from "../components/common";
import MetaSetupModal from "../components/common/Onboarding/Modal/MetaSetupModal";

export const instagramOauthActions = {
    getPages,
    submitSelectedPage,
    saveSelectedPage
};

function getPages() {

    return dispatch => {

        dispatch(request());

        //this responds with the user's facebook pages
        instagramOauthService.getPages()
            .then(
                pages => dispatch(success(pages)),
                error => {
                    dispatch(failure(error))
                }
            )
    };

    function request() {
        return {type: oauthConstants.INSTAGRAM_PAGES_REQUEST}
    }

    function success(pages) {
        console.info('pages', pages)

        return {type: oauthConstants.INSTAGRAM_PAGES_SUCCESS, payload: pages}
    }

    function failure(error) {
        return {type: oauthConstants.INSTAGRAM_PAGES_FAILURE, payload: error}
    }
}


function submitSelectedPage(pages) {

    return dispatch => {
        dispatch(request());

        let callbackUrl = localStorage.getItem('redirectUrl');
        callbackUrl = JSON.parse(callbackUrl).pathname;
        instagramOauthService.submitSelectedPage(pages)

            .then(
                page => {
                    if (page) {
                        history.push(callbackUrl);
                        dispatch(success(page));
                        SnackBar.showMessage(alertMessages.INSTAGRAM_OAUTH_SUCCESS);
                    }
                },
                error => {
                    history.push(callbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.INSTAGRAM_OAUTH_FAILURE);
                }
            );
    };

    function request() {
        return {type: oauthConstants.INSTAGRAM_PAGE_SUBMISSION_REQUEST}
    }

    function success(page) {
        return {type: oauthConstants.INSTAGRAM_PAGE_SUBMISSION_SUCCESS, payload: page}
    }

    function failure(error) {
        return {type: oauthConstants.INSTAGRAM_PAGE_SUBMISSION_FAILURE, error: error}
    }
}

function saveSelectedPage(pages) {
    return dispatch => {
        dispatch(request());
        instagramOauthService.submitSelectedPage(pages)
            .then(
                page => {
                    if (page) {
                        MetaSetupModal.close();
                        history.push('/signup/schedule');
                        dispatch(success(page));
                        SnackBar.showMessage(alertMessages.INSTAGRAM_OAUTH_SUCCESS);
                    }
                },
                error => {
                    MetaSetupModal.close();
                    history.push('/signup/add-channel');
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.INSTAGRAM_OAUTH_FAILURE);
                }
            );
    };
    function request() {
        return {type: oauthConstants.INSTAGRAM_PAGE_SUBMISSION_REQUEST}
    }
    function success(page) {
        return {type: oauthConstants.INSTAGRAM_PAGE_SUBMISSION_SUCCESS, payload: page}
    }
    function failure(error) {
        return {type: oauthConstants.INSTAGRAM_PAGE_SUBMISSION_FAILURE, error: error}
    }
}
