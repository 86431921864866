import React, {Fragment, useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {getCurrentUser} from "_helpers";
import {msgSourceActions, kustomerAuthActions} from "_actions";
import {Box} from "@mui/material";
import {Input, InputSubdomain} from "../../Utilities/Input";
import basicModalStyles from "../../Utilities/basicModalStyles";
import SetupModalBottomBar from "../../Utilities/SetupModalBottomBar";

function KustomerSetupModalForm({description, buttonLabel, extraInfoLabel, openInNewTab, handleSubmit}){
  const dispatch = useDispatch();
  const kustomer = useSelector(state => state.kustomerOauth);

  const [disabled, setDisabled] = React.useState(true);
  const loading = React.useMemo(() => kustomer.loading && !disabled, [kustomer]);

	const placeholders = {
		subDomain: 'yoursubdomain',
		apiKey: 'API Key',
	}

	useEffect(() => {
		dispatch(msgSourceActions.getMsgSourceLink('kustomer'))
  }, []);

  const performAction = (value) => {
    setDisabled(isEmpty(value));
  };

  const onSubmit = (props) => {
		const currentUser = getCurrentUser();
		const params = {
			apiKey: props.apiKey,
			subDomain: props.subDomain,
			organizationId: currentUser._embedded.organization.trendsId
		};
		dispatch(kustomerAuthActions.verifyKey(params))
  };

  return(
    <Fragment>
      <Box sx={basicModalStyles.container}>
        <Box sx={basicModalStyles.description}>
          {description}
        </Box>

        <form name="kustomerForm" onSubmit={ handleSubmit(onSubmit) }>
					<Field
            name={'subDomain'}
            component={InputSubdomain}
            endAdornmentDomainValue={'.kustomerapp.com'}
            label={'Kustomer Subdomain'}
            placeholder={placeholders.subDomain}
            performAction={performAction}
          />

					<Field
            name={'apiKey'}
            component={Input}
            placeholder={placeholders.apiKey}
          />

          <SetupModalBottomBar
            extraInfoLabel={extraInfoLabel}
            openInNewTab={openInNewTab}
            buttonLabel={buttonLabel}
            disabled={loading || disabled}
            loading={loading}
          />
        </form>
      </Box>
    </Fragment>
  )
}

const formConfig = {
  form: 'kustomerForm',
};

export default reduxForm(formConfig)(KustomerSetupModalForm);