import { SERVER_URL } from 'config';
import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';


function initiateOauth(callbackUrl) {
    return fetch(`${SERVER_URL}/api/tiktok/oauthUrl?callbackUrl=${callbackUrl}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function oauthAccessToken(params) {
    return fetch(`${SERVER_URL}/api/tiktok/oauthToken?${objectToQueryString(params)}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function updateSearchKeyword(params){
    return fetch(`${SERVER_URL}/api/tiktok/updateSearchKeyword?${objectToQueryString(params)}`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(params)
        })
        .then(handleHttpResponse);
}


export const tiktokOauthService = {
    initiateOauth,
    oauthAccessToken,
    updateSearchKeyword
}
