import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

/**
 * @param label: {string}
 * @param options: array of objects
 * @param handleSelection
 * @returns {JSX.Element}
 * @constructor
 */
export default function BasicMenu({ label, options, handleSelection }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const performAction = (param) => {
        handleSelection(param);
        setAnchorEl(null);
    };

    const renderMenuItem = (item) => {
        return (
            <MenuItem sx={styles.menuItem} onClick={() => performAction(item)}>
                {item.name}
            </MenuItem>
        )
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={styles.label}
            >
                {label}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={performAction}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{style: styles.menu}}
            >
                { options ? options.map(renderMenuItem) : <MenuItem/> }
            </Menu>
        </div>
    );
}

const styles = {
    label: {
        border: '1.5px solid #07857C',
        borderRadius: '5px',
        p: '2px 7px',
        minWidth: '0',
        mt: '-4px',
        fontFamily: 'LarsseitMedium',
        fontSize: '16px',
        lineHeight: 1.2,
        letterSpacing: '0.01em',
        color: '#07857C',
        cursor: 'pointer'
    },
    menu: {
        border: "solid 1px #dee2e0",
        boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)",
        padding: '0px',
    },
    menuItem: {
        paddingTop: '5px',
        paddingBottom: '5px',
        outline: 'none'
    }
}
