
export const scheduleConstants = {
    GET_ALL_REQUEST: 'SCHEDULES_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'SCHEDULES_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'SCHEDULES_GET_ALL_FAILURE',
    
    GET_SCHEDULE_REQUEST: 'SCHEDULES_GET_SCHEDULE_REQUEST',
    GET_SCHEDULE_SUCCESS: 'SCHEDULES_GET_SCHEDULE_SUCCESS',
    GET_SCHEDULE_FAILURE: 'SCHEDULES_GET_SCHEDULE_FAILURE',

    GET_SCHEDULED_ORG_REQUEST: 'GET_SCHEDULED_ORG_REQUEST',
    GET_SCHEDULED_ORG_SUCCESS: 'GET_SCHEDULED_ORG_SUCCESS',
    GET_SCHEDULED_ORG_FAILURE: 'GET_SCHEDULED_ORG_FAILURE',

    SUBSCRIBE_REQUEST: 'SUBSCRIBE_TO_SCHEDULE_REQUEST',
    SUBSCRIBE_SUCCESS: 'SUBSCRIBE_TO_SCHEDULE_SUCCESS',
    SUBSCRIBE_FAILURE: 'SUBSCRIBE_TO_SCHEDULE_FAILURE',

    DELETE_REQUEST: 'DELETE_SCHEDULE_REQUEST',
    DELETE_SUCCESS: 'SCHEDULE_DELETE_SUCCESS',
    DELETE_FAILURE: 'SCHEDULE_DELETE_FAILURE',

    UPDATE_REQUEST: 'UPDATE_SCHEDULE_REQUEST',
    UPDATE_SUCCESS: 'SCHEDULE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SCHEDULE_UPDATE_FAILURE',

    ORGS_SCHEDULE_REQUEST: 'ORGS_SCHEDULE_REQUEST',
    ORGS_SCHEDULE_SUCCESS: 'ORGS_SCHEDULE_SUCCESS',
    ORGS_SCHEDULE_FAILURE: 'ORGS_SCHEDULE_FAILURE',

    BULK_PAYOUT_PRICING_REQUEST: 'BULK_PAYOUT_PRICING_REQUEST',
    BULK_PAYOUT_PRICING_SUCCESS: 'BULK_PAYOUT_PRICING_SUCCESS',
    BULK_PAYOUT_PRICING_FAILURE: 'BULK_PAYOUT_PRICING_FAILURE',

};