import React, {Component} from 'react';
import ConnectChannelErrorModal from "components/v2/views/Onboarding/ConnectChannel/Channels/Utilities/ConnectChannelErrorModal";

let _this;

export default class ChannelErrorModal extends Component{
  constructor(props){
    super(props);

    this.state = { open: false, message: '' };
    _this = this;
  }

  static open(message = ''){
    _this.setState({ open: true, message: message }) //this is invoked from outside the component
  }
  static close(){
    _this.setState({ open: false }); //this is invoked from outside the component
  }
  handleClose = () => {
    _this.setState({ open: false }); //this is invoked within the component
  };

  render() {
    return(
      <ConnectChannelErrorModal
        open={this.state.open}
        handleClose={this.handleClose}
        message={this.state.message} />
    )
  }
}