import {useState} from "react";
import {Box} from "@mui/system";
import SettingsSearch from "./SettingsSearch";
import SettingsSearchResultProvider from "./SettingsSearchResultProvider";
import usePopupSetup from "../../../../hooks/usePopupSetup";
import SettingsSearchResultContent from "./SettingsSearchResultContent";
import companySettingsLabels from "../companySettingsLabels";

export default function SettingsSearchEntry({setLocation}) {
    const {handleOpenPopup, handleClosePopup, openPopup} = usePopupSetup();
    const [searchResult, setSearchResult] = useState([]);
    const sections = companySettingsLabels().sections;

    const handleOpen = () => {
        handleOpenPopup();
    }

    const handleClose = () => {
        handleClosePopup();
    }

    const handleSearchResult = (result) => {
        setSearchResult(result);
    }

    return(
        <>
            <Box sx={{mb: '7px'}}>
                <SettingsSearch
                    handleOpen={handleOpen}
                    options={sections}
                    handleSearchResult={handleSearchResult}
                />
            </Box>
            <SettingsSearchResultProvider
                open={openPopup}
                handleClose={handleClose}>
                <SettingsSearchResultContent
                    searchResult={searchResult}
                    setLocation={setLocation}
                    handleClose={handleClose}
                />
            </SettingsSearchResultProvider>
        </>
    )
}