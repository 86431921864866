import React from 'react';
import chatdeskLogo from 'components/images/chatdesk-logo-dark.svg'


/**
 * @author Chris Okebata
 */

const PasswordError = () => {

    return(
        <div className="main-content-wrapper">
            <div className="logo-pos-login">
                <img src={chatdeskLogo} alt="Chatdesk logo"/>
            </div>

            <div className="error-message">
                <h1 className="h1-error">Error</h1>
                <p className="p-error">Your request for reset password was invalid.</p>
            </div>

            <div className="error-message-button">
                <a href="/">
                    <button className="btn btn-success" style={{padding:"7px 33px"}} id="back-to-login">
                        <span>Back</span>
                    </button>
                </a>
            </div>
        </div>
    )
};

export { PasswordError }