import _ from "lodash";
import {sortArrayOfDays} from "_helpers";
import {ScheduleBuilder} from "../objects/ScheduleBuilder";
import ScheduleActionsContainer from "../Utilities/ExpertManagement/ScheduleActionsContainer";
import {accessibilityMode} from "../Utilities/organizationSettingsConstants";
import {SchedulesColumnBuilder} from "../objects/SchedulesColumnBuilder";

export default function useSchedulesTableData(organizationId, schedules, payoutRates, pricingRates, mode) {
    const rows = [];
    const columns = [];
    const isEdit = mode === accessibilityMode.EDIT;

    function parseDays(dayList) {
        if(_.isEmpty(dayList)) return '-';
        const days = dayList.map((day) => { return day.name });
        const sortedDays = sortArrayOfDays(days);
        const shortenedDay = sortedDays.map(sortedDay => sortedDay.slice(0,3));
        return shortenedDay.join(', ');
    }

    function parsePayment(payment) {
        if(_.isEmpty(payment)) return '-';
        const amount = payment.amount;
        const currencyCode = payment.currencyCode;
        const label = payment.label;
        return `${label} - ${currencyCode} ${amount}`;
    }

    if(!_.isEmpty(schedules) && schedules.length > 0) {
        schedules.map( schedule => {
            const builder = new ScheduleBuilder();
            builder.setId(schedule.id);
            builder.setName(schedule.name);
            builder.setDays(parseDays(schedule.days));
            builder.setStartTime(schedule.startTime);
            builder.setEndTime(schedule.endTime);
            builder.setHandleId(_.get(schedule, 'handle.id'));
            builder.setHandleName(_.get(schedule, 'handle.name'));
            builder.setOrganizationId(_.get(schedule, 'organization.id'));
            builder.setOrganizationName(_.get(schedule, 'organization.name'));
            builder.setPayout(parsePayment(schedule.payoutRate) || '-');
            builder.setPricing(parsePayment(schedule.pricingRate) || '-');
            builder.setActions(<ScheduleActionsContainer scheduleId={schedule.id} organizationId={organizationId}/>);
            rows.push(builder.build());
        });
    }

    const nameBuilder = new SchedulesColumnBuilder();
    nameBuilder.setId('name');
    nameBuilder.setLabel('Schedule');
    nameBuilder.setWidth(150);
    columns.push(nameBuilder.build());

    const daysBuilder = new SchedulesColumnBuilder();
    daysBuilder.setId('days');
    daysBuilder.setLabel('Days');
    daysBuilder.setWidth(250);
    columns.push(daysBuilder.build());

    const startTimeBuilder = new SchedulesColumnBuilder();
    startTimeBuilder.setId('startTime');
    startTimeBuilder.setLabel('Start');
    startTimeBuilder.setWidth(70);
    columns.push(startTimeBuilder.build());

    const endTimeBuilder = new SchedulesColumnBuilder();
    endTimeBuilder.setId('endTime');
    endTimeBuilder.setLabel('End');
    endTimeBuilder.setWidth(70);
    columns.push(endTimeBuilder.build());

    const payoutBuilder = new SchedulesColumnBuilder();
    payoutBuilder.setId('payout');
    payoutBuilder.setLabel('Payout');
    payoutBuilder.setWidth(150);
    columns.push(payoutBuilder.build());

    const pricingBuilder = new SchedulesColumnBuilder();
    pricingBuilder.setId('pricing');
    pricingBuilder.setLabel('Pricing');
    pricingBuilder.setWidth(150);
    if(!isEdit) pricingBuilder.setAlignment('right');
    columns.push(pricingBuilder.build());

    if(isEdit) {
        const actionsBuilder = new SchedulesColumnBuilder();
        actionsBuilder.setId('actions');
        actionsBuilder.setLabel('Actions');
        actionsBuilder.setWidth(120);
        columns.push(actionsBuilder.build());
    }

    return {rows, columns};
}