import { SERVER_URL } from 'config';
import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';


function initiateOauth(callbackUrl, isTest) {
    return fetch(`${SERVER_URL}/api/salesforce/oauthUrl?callbackUrl=${callbackUrl}&isTest=${isTest}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function oauthAccessToken(params, callbackUrl, isTest) {
    return fetch(`${SERVER_URL}/api/salesforce/oauthToken?${objectToQueryString(params)}&callbackUrl=${callbackUrl}&isTest=${isTest}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

export const salesforceOauthService = {
    initiateOauth,
    oauthAccessToken,
}