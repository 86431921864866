import React, {Fragment} from 'react';
import {Col, Grid, PanelGroup, Row,} from 'react-bootstrap';
import { Grid as MaterialGrid } from '@mui/material';
import {
    dispatcherActions,
    messageActions,
    responseOptionActions,
    screenshotActions,
    utilityActions,
    websocketActions
} from '_actions';
import {connect} from 'react-redux';
import {HtmlTooltip, Loading, QuillEditor} from 'components/common';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom'
import {
    addObjectFromArray,
    getCurrentUser,
    isObjectEmpty,
    queryStringToObject, removeObjectFromArray,
    secondsToMinutesHour, showGreenTimer, showRedTimer,
    sortResponseOptions,
    stripNbspAndNewLineFromHtml,
    timeDifferenceInSeconds,
    websocket, canEmbedImage, setObjectProperty, getObjectProperty, cleanResponseText
} from '_helpers';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import htmlToText from "html-to-text";
import {notificationInboxStyle} from 'components/Inbox/StylesNotificationInbox'
import {RenderMessageV2, ShowResponseOptionV2, ToggleMenuV2} from 'components/Message-V2';
import {
    handleType,
    dispatcherConstants,
    LOCK_DURATION_IN_SECS,
    LOCK_EMAIL_DURATION_IN_SECS,
    navigationType,
    requestOrigin,
} from '_constants';
import "./messageV2.css";
import moment from "moment";
import _ from "lodash";
import randomstring from "randomstring";
import MessageLockIcon from '@material-ui/icons/Lock';
import TimerIcon from '@material-ui/icons/Schedule';
import ConversationThread from "../ConversationThread/ConversationThread";
import {ScreenshotUtils} from "../Screenshots/utils";
import JiraBugModal from 'components/Message/JiraBugModal';
import {PrivateReplyModal} from "../Message/PrivateReplyModal";
import MessageHasReplyOrIsDeleted from "../Message/MessageHasReplyOrIsDeleted";
import {canShowAttachment} from "_helpers";
import {Box} from "@mui/system";
import MessageSubject from "../v2/common/Message/MessageSubject";
import {CrossDispatchWarningModal} from "../v2/views/Messages/CrossDispatchWarningModal";

const TRENDS = 'trends';
const TEAMS = 'teams-only';
const INCOMING = 'incoming';
const OUTGOING = 'outgoing';
const GORGIAS_SOURCE_NAME = 'gorgias';
const REAMAZE_SOURCE_NAME = 'reamaze';
const TIKTOK_SOURCE_NAME = 'tiktok';
const TIKTOK_ADS_SOURCE_NAME = 'tiktokads';
const TWITTER_SOURCE_NAME = 'twitter';
const FACEBOOK_SOURCE_NAME = 'facebook';
const INSTAGRAM_SOURCE_NAME = 'instagram';
const navigation = {
    type: navigationType.AGENT_SELECTION,
};

const btnStyle = {
    cursor: 'pointer',
    padding: '0',
    outline: 'none',
    margin: '0 13px'
}

let editedSubjectObj = {};
class MessageDetailsV2 extends React.Component {
    constructor(props) {
        super(props);
        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);
        let {platform} = filterParams;
        this.editorRef = React.createRef();

        this.state = {
            replyText: '',
            attachments: [],
            platform,
            time: Date.now(),
            websocketMessage: '',
            screenshotModal: {
                show: false,
                type: ""
            },
            privateReplyModal: {
                show: false
            },
            showModal: false,
            isMessageLocked: false,
            jiraModal: false,
            crossDispatchWarningModal: false,
            crossDispatchWarningDismissed: false
        };
    }

    componentDidMount() {
        this.loadMessages();
        this.interval = setInterval(() => this.setState({time: Date.now()}), 1000);
        websocket.connect();
    }

    loadMessages() {
        let {platform} = this.state;
        const {match: {params: {id}}, dispatch} = this.props;

        dispatch(messageActions.clearUpdatedPracticePriority())
        if (platform === TRENDS) {
            const params = {addedDetails: true};
            dispatch(messageActions.getDetailsFromTrends(id, params, requestOrigin.MESSAGE_DETAILS, true));
            dispatch(responseOptionActions.getAll({message: id, platform: TRENDS}));
        }
        if (platform === TEAMS) {
            dispatch(messageActions.getMessage(id, true, true));
            dispatch(responseOptionActions.getAll({message: id, platform: TEAMS}));
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        websocket.disconnect();
    }

    handleTextFieldUpdate = (text) => {
        this.lockMessage();
        this.setState({replyText: stripNbspAndNewLineFromHtml(text)});
    };

    handleSelectFile = (data) => {
        const result = addObjectFromArray(this.state.attachments, data);
        this.setState({ attachments: result });
    };

    handleDeselectAttachment = (attachmentId) => {
        const result = removeObjectFromArray(this.state.attachments, attachmentId, 'id');
        this.setState({ attachments: result });
    };

    clearAttachment = () => {
        this.setState({ attachments: [] });
    };

    lockMessage = () => {
        let {platform, isMessageLocked} = this.state;

        if (platform === TEAMS) {
            const {message, dispatch} = this.props;
            const {dataSourceHandle: {type}} = message;
            const {id: userId} = getCurrentUser();

            if ([handleType.email, handleType.proactive_email, handleType.chat].includes(type)) {
                let timeDiff = timeDifferenceInSeconds(moment(_.get(message, 'lockedAtTime')).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
                let lockTime = [handleType.email, handleType.proactive_email].includes(type) ? LOCK_EMAIL_DURATION_IN_SECS : LOCK_DURATION_IN_SECS;

                if (!isMessageLocked && (!message.isLocked || (message.isLocked && timeDiff > lockTime))) {
                    dispatch(messageActions.lockMessage(message.id, userId));
                    this.setState({isMessageLocked: true});
                }
            }
        }
    };

    showScreenshotModal = (e, type) => {
        this.setState({screenshotModal: {show: true, type: type}});
        this.setState({showModal: true})
    };

    togglePrivateReplyModal = (e) => {
        e.preventDefault()
        this.setState({privateReplyModal: {show: !this.state.privateReplyModal.show}})
        this.setState({showModal: true})
    };

    closeModalHandler = () => {
        this.setState({showModal: false});
    };

    showModalHandle = (event) => {
        event.preventDefault();
        this.setState({showModal: true})
    };

    getSubject = (messageId, subject) => {
        return {subject, editedSubject: _.get(getObjectProperty(editedSubjectObj, messageId), 'subject')};
    }

    handleSubmit = (message, response) => {
        const openedMessageId = this.props.match.params.id;
        const subjectObj = this.getSubject(_.get(message, 'id'), _.get(message, 'subject'));
        const replyPlainText = response ? htmlToText.fromString(response, {wordwrap: false}) : '';
        response = cleanResponseText(response, message.sourceName);
        const { id } = getCurrentUser();
        const { attachments } = this.state;
        const payload = {
                openedMessageId: openedMessageId,
                isDispatched: false,
                reply: response,
                replyPlainText,
                userId: id,
                source: message.sourceName,
                isV2Enabled: message.isV2Enabled,
                attachments: attachments,
                subject: _.get(subjectObj, 'subject'),
                editedSubject: _.get(subjectObj, 'editedSubject'),
                draftId: message.draft?.id,
                crossDispatchWarningDismissed: this.state.crossDispatchWarningDismissed
            };

        this.props
            .dispatch(dispatcherActions.dispatchMessage(message.id, payload))
            .then((result) => {
                if (result.success) {
                    this.handleTextFieldUpdate('');
                    this.editorRef.current.clearEditor();
                    this.clearAttachment();
                    this.clearCrossDispatchWarningDismissed();
                } else if (!result.success && result?.response?.errorCode === dispatcherConstants.POSSIBLE_CROSS_DISPATCH_ERROR_CODE) {
                    this.showCrossDispatchWarningModal();
                }
            }).catch(error => console.error('An error occurred when dispatching message: ' + error));
    };

    deleteMessageFromSource = (messageId, payload) => {
        this.props.dispatch(dispatcherActions.deleteMessageFromSource(messageId, payload))
    };

    /**
     * Like facebook message from source
     * @param messageId
     * @param sourceName The source from which the message is to be liked (eg Facebook)
     */
    likeMessageFromSource = (messageId, sourceName) => {
        this.props.dispatch(dispatcherActions.likeMessageFromSource(messageId, sourceName))
    }

    updateMessageFromSource = (messageId, params) => {
        this.props.dispatch(dispatcherActions.updateMessageFromSource(messageId, params))
    };

    blacklist = (messageId, sourceName) => {
        this.props.dispatch(dispatcherActions.blacklist(messageId, sourceName))
    };

    submitPositiveFeedback = (data, currPath) => {
        this.props.dispatch(screenshotActions.addScreenshot(data, currPath))
    }

    skipMessage = (messageId) => {
        const {currentUser: {user: {id}}} = this.props;
        const skipMessageParams = {
            userMessageAction: {
                user: id,
                actionType: 'NO_RESPONSE_NEEDED',
                message: messageId,
            },
            navigation,
            isAdmin: true,
            platform: this.state.platform
        };
        this.props.dispatch(messageActions.skipMessage(skipMessageParams))
    };

    escalateMessage = (messageId) => {

        const {currentUser: {user: {id}}} = this.props;
        const escalationParams = {
            userMessageAction: {
                user: id,
                actionType: 'ESCALATE',
                message: messageId,
            },
            navigation,
            isAdmin: true,
            platform: this.state.platform
        };
        this.props.dispatch(messageActions.escalate(escalationParams))
    };

    changeMessagePracticePriority = (messageId, isReviewed) => {
        this.props.dispatch(messageActions.changeMessagePracticePriority(messageId, isReviewed))
    };

    sendForResponseGen = (messageId) => {
        const {dispatch} = this.props
        let {platform} = this.state;
        dispatch(messageActions.sendForResponseGeneration({messageId, platform}))
    };

    displayJiraModal = (e) => {
        e.preventDefault();
        this.setState({jiraModal: true})
    };

    hideJiraModal = () => {
        this.setState({jiraModal: false})
    };

    dataForScreenshot = (agent, type, messageUrl, description, messageId) => {
        const agentId = agent && agent.id ? agent.id : null
        const reporterId = getCurrentUser().id;
        const rootUrl = utilityActions.getRootUrl();
        const data = new FormData(this.form);

        if (agentId) data.append("agentId", agentId);
        if (reporterId) data.append('reporterId', reporterId);

        data.append('messageUrl', messageUrl);
        data.append('description', description);
        data.append('clientRootUrl', rootUrl);
        data.append('typeName', type);
        data.append('messageId', messageId);

        return data
    };

    getRandomPositiveDescription = () => {
        const descriptionArray = [
            "Great work on this message!",
            "Well done on this message, keep it up!",
            "You did a good job on this message",
            "I really like how you handled this message",
            "Nice work on this message!",
            "Nice job on this message",
            "Good work on this message!",
            "Keep up the good work!",
            "Very nicely done!"
        ]

        return _.sample(descriptionArray)
    }

    undoMessage = (messageId, params) => {
        this.props.dispatch(messageActions.undoMessage(messageId, params))
    }

    tickMessageAsActioned = (messageId) => {
        messageId = [messageId]
        const platform = this.state.platform
        this.props.dispatch(messageActions.tickMessageAsActioned(messageId, platform))
    }

    handleSubjectChange = (messageId, subject) => {
        setObjectProperty(editedSubjectObj, messageId, { subject: subject });
    }

    setWebSocketMessage = (websocketMsg) => {
        const {message} = this.props;
        if (websocket.isSameMessageThread(message, websocketMsg)) {
            this.setState({websocketMessage: websocketMsg});
        }
    }

    showCrossDispatchWarningModal = () => {
        this.setState({crossDispatchWarningModal: true});
    }

    closeCrossDispatchWarningModal = () => {
        this.setState({crossDispatchWarningModal: false, crossDispatchWarningDismissed: true});
    }

    clearCrossDispatchWarningDismissed = () => {
        this.setState({crossDispatchWarningDismissed: false});
    }

    onPrivateReplyDispatchFailure = (response) => {
        if (response?.errorCode === dispatcherConstants.POSSIBLE_CROSS_DISPATCH_ERROR_CODE) {
            this.showCrossDispatchWarningModal();
        }
    }

    render() {
        const {allMessages, dispatcherRequest, currentUser} = this.props;
        const openedMessageId = this.props.match.params.id;
        const {updatedIsReviewed} = this.props
        const {resendForResponseGenList} = allMessages;
        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);
        let {platform} = filterParams;

        // eslint-disable-next-line camelcase
        const {message, response_options, classes, conversationThread, websocketTrendsMessage, websocketTeamsMessages, dispatch} = this.props;
        const handleName = _.get(message, 'dataSourceHandle');

        if (Object.keys(message).length === 0) {
            return <Loading/>;
        }

        // eslint-disable-next-line no-underscore-dangle
        const organization = _.get(message, '_embedded.organization');
        const replies = _.get(message, '_embedded.replies');
        const {isReply} = message;
        const direction = _.get(message, 'direction');
        const messageActioned = message.actioned ? message.actioned : (message.userMessageActions && message.userMessageActions.length > 0);
        const hasEmployeeSeen = _.get(message, 'hasEmployeeSeen');
        const dateCreated = _.get(message, 'dateCreated');
        const lockedAtTime = _.get(message, 'lockedAtTime');
        const isAutoEscalated = _.get(message, 'isAutoEscalated');
        const msgSource = _.get(message, 'sourceName') || _.get(message, 'messageSource.name');
        const sortedResponseOptions = sortResponseOptions(response_options);
        const _handleType = _.get(message, 'dataSourceHandle.type');
        let finalMessage = (direction && direction.toLowerCase() === INCOMING) ? message : isReply;
        let isEmail = [handleType.email, handleType.proactive_email].includes(_.get(message, 'dataSourceHandle.type'));
        let isAttachmentSupported = canShowAttachment(_handleType, msgSource);
        let isImageEmbedSupported = canEmbedImage(_handleType, msgSource);
        let isCharDisplay = false;
        let charLength;

        if (platform === TRENDS && ((direction && direction.toLowerCase() === OUTGOING) || !direction)) {
            finalMessage = message.parentMessage || message;
        }

        let conversationArr = (platform === TRENDS ? _.get(message, 'conversation') : conversationThread) || [];
        let displayInApp = _.get(message, 'dataSourceHandle.displayInApp');

        if (platform === TRENDS && isEmail &&
            !isObjectEmpty(websocketTrendsMessage) &&
            websocketTrendsMessage?.message?.platform === TRENDS) {
            let websocketMsg = {...websocketTrendsMessage.message, isNew: true};
            const externalUniqueIds = _.map(conversationArr, (msg) => msg.externalUniqueId) || [];

            if(!externalUniqueIds.includes(websocketMsg.externalUniqueId)) {
                conversationArr.push(websocketMsg);
                this.setWebSocketMessage(websocketMsg);
            }
            dispatch(websocketActions.clearTrendsMessage());
        }

        if (platform === TEAMS && websocketTeamsMessages?.messages?.length > 0) {
            const conversationMsgIds = _.map(conversationArr, (msg) => msg.id);
            const conversationExternalUniqueIds = _.map(conversationArr, (msg) => msg.externalUniqueId);
            let lastWebSocketMessage = null;

            _.forEach(websocketTeamsMessages.messages, (websocketMsg) => {
                if (!conversationMsgIds.includes(websocketMsg.id)
                        && !conversationMsgIds.includes(websocketMsg.messageId)
                        && !conversationMsgIds.includes(websocketMsg.messageTrendsId)
                        && !conversationExternalUniqueIds.includes(websocketMsg.externalUniqueId)) {
                    conversationArr.push(websocketMsg);
                    conversationMsgIds.push(websocketMsg.id);
                    if (websocketMsg?.direction?.toUpperCase() === 'INCOMING') {
                        lastWebSocketMessage = websocketMsg;
                    }
                }
            });
            if (lastWebSocketMessage) {
                this.setWebSocketMessage(lastWebSocketMessage);
            }
            dispatch(websocketActions.removeTeamsMessages(websocketTeamsMessages.messages));
        }

        //This condition is added here instead of included above because the webSocket is only implemented for email messages currently
        if ((message.sourceName.toLowerCase() === GORGIAS_SOURCE_NAME && message.dataSourceHandle.type === handleType.chat) || (message.sourceName.toLowerCase() === REAMAZE_SOURCE_NAME)) {
            isEmail = true
        }

        if ([TIKTOK_ADS_SOURCE_NAME, TIKTOK_SOURCE_NAME].includes(message.sourceName.toLowerCase())) {
            isCharDisplay = true
            charLength = 150
        }

        if (message.sourceName.toLowerCase() === TWITTER_SOURCE_NAME) {
            isCharDisplay = true
            charLength = 280
        }

        if (message.sourceName.toLowerCase() === INSTAGRAM_SOURCE_NAME) {
            isCharDisplay = true
            charLength = 1000
        }
        const isBlacklistAllowed = message.sourceName.toLowerCase() === FACEBOOK_SOURCE_NAME || message.sourceName.toLowerCase() === INSTAGRAM_SOURCE_NAME

        const finalOrganization = (direction && direction.toLowerCase() === INCOMING) || platform === TRENDS ? organization : isReply ? isReply.organization : {};
        const isMessageActioned = message.actioned ? message.actioned : (message._embedded.userMessageActions && message._embedded.userMessageActions.length > 0);
        let lockedMsgTimeDiff = timeDifferenceInSeconds(moment(lockedAtTime).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
        let newMsgTimeDiff = timeDifferenceInSeconds(moment(dateCreated).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
        let timeInMinsHour = secondsToMinutesHour(newMsgTimeDiff);
        const user = getCurrentUser();
        const lockedByCurrentUser = _.get(message, 'lockedByUser') === _.get(user, 'id');
        let durationIcon;
        const shouldDisplayTimer = direction && direction.toLowerCase() === INCOMING && !isAutoEscalated && !hasEmployeeSeen && !messageActioned && dateCreated;

        if (lockedAtTime && lockedMsgTimeDiff < LOCK_DURATION_IN_SECS && !lockedByCurrentUser) {
            durationIcon =
                <MessageLockIcon className="color-grey-mid" fontSize={'small'} style={{margin: '0 15px 2px 15px'}}/>
        } else if (shouldDisplayTimer && showGreenTimer(_handleType, newMsgTimeDiff)) {
            durationIcon = <HtmlTooltip
                title={
                    <div style={{padding: '2px'}}>
                        <Typography
                            style={{width: '100%', fontSize: '15px', color: '#2C302E'}}> {timeInMinsHour} </Typography>
                    </div>
                }
                placement={'bottom'}
                style={{opacity: 1}}
                enterDelay={100}>
                <TimerIcon className="color-green" fontSize={'small'} style={{margin: '0 0 20px 0'}}/>
            </HtmlTooltip>
        }else if(shouldDisplayTimer && showRedTimer(_handleType, newMsgTimeDiff)){
            durationIcon = <HtmlTooltip
              title={
                  <div style={{padding: '2px'}}>
                      <Typography style={{width: '100%', fontSize: '15px', color: '#d1504d'}}> {timeInMinsHour} </Typography>
                  </div>
              }
              placement={'bottom'}
              style={{opacity: 1}}
              enterDelay={100}>
                <TimerIcon className="color-danger" fontSize={'small'} style={{marginTop: '6px'}}/>
            </HtmlTooltip>
        }

        let {screenshotModal: {show, type}, jiraModal} = this.state;
        let mistakeModal;
        let {agent} = message;
        const messageUrl = window.location.href.trim();
        const currentPath = utilityActions.getCurrentPath();
        const messageId = message.id;
        let positiveScreenshotData = {};


        const currPath = currentPath ? currentPath : "/screenshots";
        let description = this.getRandomPositiveDescription()
        const data = this.dataForScreenshot(agent, "POSITIVE", messageUrl, description, messageId)
        positiveScreenshotData = {
            data: data,
            currPath: currPath,
            dispatchFunction: this.submitPositiveFeedback
        }
        if (show) {
            mistakeModal = ScreenshotUtils.buildModal(
                this.closeModalHandler,
                this.state.showModal,
                messageId,
                currentPath,
                type,
                agent,
                messageUrl
            )
        }

        const showPrivateReplyModal = this.state.privateReplyModal.show
        let privateReplyModal
        const subject = _.get(message, 'subject');
        const hasSubject = !_.isEmpty(subject);

        if (showPrivateReplyModal) {
            privateReplyModal = <PrivateReplyModal
                platform={platform}
                messageObject={message}
                externalCloseHandler={this.togglePrivateReplyModal}
                openedMessageId={openedMessageId}
                crossDispatchWarningDismissed={this.state.crossDispatchWarningDismissed}
                onDispatchSuccess={this.clearCrossDispatchWarningDismissed}
                onDispatchFailure={this.onPrivateReplyDispatchFailure}
            />
        }

        return (
            <Grid className="content-pane-90 message-details-content-pane">
                <Row>
                    <Col xs={12} md={12}>
                        <div className="row" style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p className="col-xs-11 font-15 message-source color-grey-dark ">
                                <span className="font-15-bold">{finalOrganization.name}</span>
                                <span
                                    className="margin-left-5 message-channel"> - {finalMessage && finalMessage.channel ? finalMessage.channel : message.channel ? message.channel : null}</span>
                                {durationIcon}
                            </p>

                            <div style={{display: 'flex'}}>
                                <div className="conversation-thread"
                                     style={{width: '200px', display: 'flex', alignItems: 'center', height: '21px'}}>
                                    <ConversationThread
                                        message={(platform === TRENDS && message.direction && message.direction.toLowerCase() === OUTGOING) ? message : (finalMessage || message)}
                                        isTrendsMessage={platform === "trends"}
                                        msgSource={msgSource}
                                        organization={finalOrganization}
                                    />
                                    {message.direction.toLowerCase() === 'incoming' &&
                                    msgSource && ['facebook', 'instagram'].includes(msgSource.toLowerCase()) &&
                                    message.dataSourceHandle.type !== handleType.chat &&
                                    <MessageHasReplyOrIsDeleted
                                        className="font-15 color-green tms-no-underline transparent-button"
                                        message={message}
                                        style={btnStyle}
                                        actionMessage={this.actionMessage}
                                    />
                                    }
                                </div>
                                <div
                                    className={"action-buttons " + ((message.direction.toLowerCase() === 'incoming' && !isMessageActioned) ||
                                    (message.direction.toLowerCase() === 'incoming' && !isMessageActioned && !message.isHidden) ? "action-buttons" : 'action-buttons-closed')}>
                                    {message.direction.toLowerCase() === 'incoming' && !isMessageActioned &&
                                    <Fragment>
                                        <button
                                            className="font-15 color-green tms-no-underline transparent-button"
                                            style={btnStyle}
                                            onClick={() => this.escalateMessage(messageId)}>
                                            Escalate
                                        </button>

                                        <button
                                            className="font-15 color-green tms-no-underline transparent-button"
                                            style={btnStyle}
                                            onClick={() => this.skipMessage(messageId)}>
                                            No Response Needed
                                        </button>
                                    </Fragment>
                                    }
                                    {console.log(`msgSource is ${msgSource}; to lower ${msgSource.toLowerCase()}, msgSource included ${['facebook', 'instagram'].includes(msgSource.toLowerCase())}, handleType ${message.dataSourceHandle.type}, chatHandeType ${handleType.chat}, comparison: ${message.dataSourceHandle.type !== handleType.chat}`)}
                                    {msgSource && ['facebook', 'instagram'].includes(msgSource.toLowerCase()) && message.dataSourceHandle.type !== handleType.chat &&
                                        <MessageHasReplyOrIsDeleted
                                            message={message}
                                            style={btnStyle}
                                            actionMessage={this.actionMessage}
                                        />
                                    }

                                    {message.direction.toLowerCase() === 'incoming' && !isMessageActioned && !message.isHidden &&
                                    <button
                                        className="font-15 color-green tms-no-underline transparent-button"
                                        style={btnStyle}
                                        onClick={
                                            () => this.updateMessageFromSource(message.id, {
                                                updateFields: {
                                                    is_hidden: (_.has(message, 'hiddenFromSource') ? !message.hiddenFromSource : true)
                                                },
                                                platform,
                                                isV2Enabled: message.isV2Enabled,
                                                source: message.sourceName
                                            })}>
                                        {message.hiddenFromSource ? 'UNHIDE' : 'HIDE'}
                                    </button>
                                    }
                                </div>
                                <div className="col-xs-1 links-menu" style={{width: '75px', marginTop: '3px'}}>
                                    {message &&
                                    <ToggleMenuV2
                                        message={(platform === TRENDS && message.direction && message.direction.toLowerCase() === OUTGOING) ? message : (finalMessage || message)}
                                        mainMessage={message}
                                        updateMessageFromSource={this.updateMessageFromSource}
                                        isBlacklistAllowed={isBlacklistAllowed}
                                        blacklist={this.blacklist}
                                        skipMessage={this.skipMessage}
                                        classes={classes}
                                        platform={platform}
                                        escalateMessage={this.escalateMessage}
                                        isMessageActioned={isMessageActioned}
                                        deleteMessageFromSource={this.deleteMessageFromSource}
                                        lockMessage={this.lockMessage}
                                        showScreenshotModal={this.showScreenshotModal}
                                        showPrivateReplyModal={this.togglePrivateReplyModal}
                                        closeModalHandler={this.closeModalHandler}
                                        showModalHandle={this.showModalHandle}
                                        likeMessageFromSource={this.likeMessageFromSource}
                                        resendForResponseGenList={resendForResponseGenList}
                                        sendMessageForResponseGen={this.sendForResponseGen}
                                        positiveFeedbackData={positiveScreenshotData}
                                        showJiraSubmitBug={true}
                                        onShowJiraModal={this.displayJiraModal}
                                        undoMessage={this.undoMessage}
                                        actionMessage={this.tickMessageAsActioned}
                                        isChangePracticePriorityAllowed={platform === TEAMS && message.direction && message.direction.toLowerCase() === INCOMING}
                                        changePracticePriority={this.changeMessagePracticePriority}
                                        updatedIsReviewed={updatedIsReviewed}
                                    />
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr/>

                {displayInApp && (!isObjectEmpty(conversationArr) ?
                        <Row>
                            <Col xs={12} md={12}>
                                {
                                    conversationArr.map(conversation => <RenderMessageV2 message={conversation}
                                                                                         organization={finalOrganization}
                                                                                         isTrends={platform === TRENDS}/>)
                                }
                            </Col>
                        </Row> :
                        <Fragment>
                            <Row>
                                <Col xs={12} md={12}>{
                                    finalMessage &&
                                    <RenderMessageV2 message={finalMessage}
                                                     organization={finalOrganization}
                                                     isTrends={platform === TRENDS}/>
                                }
                                </Col>
                            </Row>

                            {message.direction && message.direction.toLowerCase() === OUTGOING &&
                            <RenderMessageV2 message={message}
                                             organization={finalOrganization}
                                             isTrends={platform === TRENDS}/>
                            }

                            {replies &&
                            <ul className="reply-container">
                                {replies.map((reply, index) => <RenderMessageV2 message={reply}
                                                                                key={index}
                                                                                organization={finalOrganization}
                                                                                isTrends={platform === TRENDS}/>)}
                            </ul>
                            }
                        </Fragment>
                )}

                {!displayInApp &&
                <Fragment>
                    <Row>
                        <Col xs={12} md={12}>{
                            finalMessage &&
                            <RenderMessageV2 message={finalMessage}
                                             organization={finalOrganization}
                                             isTrends={platform === TRENDS}/>
                        }
                        </Col>
                    </Row>

                    {message.direction && message.direction.toLowerCase() === OUTGOING &&
                    <RenderMessageV2 message={message}
                                     organization={finalOrganization}
                                     isTrends={platform === TRENDS}/>
                    }

                    {replies &&
                    <ul className="reply-container">
                        {replies.map(reply => <RenderMessageV2 message={reply}
                                                               organization={finalOrganization}
                                                               isTrends={platform === TRENDS}/>)}
                    </ul>
                    }
                </Fragment>
                }

                <Row style={{margin: "10px 0 0"}}>
                    <Box>
                        { hasSubject &&
                            <Box sx={{borderTop: '1px solid #dee2e0', p: '10px 0 0px 0'}}>
                                <MessageSubject
                                    subjectParentId={messageId}
                                    subject={subject}
                                    handleSubjectChange={this.handleSubjectChange}
                                    editedSubjectObj={editedSubjectObj}
                                    isEnabled={true}
                                />
                            </Box>
                        }
                        <QuillEditor
                            defaultText={message.draft ? message.draft.text : ''}
                            handleTextChange={this.handleTextFieldUpdate}
                            isEmail={isEmail}
                            length={charLength}
                            isCharDisplay={isCharDisplay}
                            ref={this.editorRef}
                            selectedAttachments={this.state.attachments}
                            handleSelectFile={this.handleSelectFile}
                            handleDeselectAttachment={this.handleDeselectAttachment}
                            isAttachmentSupported={isAttachmentSupported}
                            isImageEmbedSupported={isImageEmbedSupported}
                        />
                    </Box>

                    <MaterialGrid container style={{padding:"15px 0"}}>
                        <MaterialGrid item md={2} sm={2} xs={12}>
                            <button id="handle-submit-button" onClick={() => {
                                this.handleSubmit(this.state.websocketMessage || message, this.state.replyText)
                            }}
                                    type="submit" className="btn btn-success btn-block font-15"
                                    style={{padding: "7px", marginLeft: 'auto'}}
                                    disabled={dispatcherRequest}
                            >
                                {dispatcherRequest ? 'Sending ...' : 'Send'}
                            </button>
                        </MaterialGrid>
                    </MaterialGrid>
                </Row>

                {sortedResponseOptions && sortedResponseOptions.length !== 0 &&
                <div>
                    <h4 className="font-16-bold margin-top-30">Response Options</h4>
                    <Row>
                        <Col md={12} sm={12}>
                            <PanelGroup accordion id="response-options" className="message-response-options">
                                {/* eslint-disable-next-line camelcase */}
                                {sortedResponseOptions.map(ShowResponseOptionV2)}
                            </PanelGroup>
                        </Col>
                    </Row>
                </div>
                }
                <JiraBugModal
                    style={{zIndex: 1000}}
                    show={jiraModal}
                    onHide={this.hideJiraModal}
                    organization={organization}
                    currentUser={currentUser}
                />
                <CrossDispatchWarningModal
                    open={this.state.crossDispatchWarningModal}
                    handleClose={this.closeCrossDispatchWarningModal}
                />
                {mistakeModal}
                {privateReplyModal}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const {
        messages: {message, allMessages, updatedIsReviewed}, responseOptions: { responseOptions }, auth, conversation, websocket,
        dispatcher: {request}
    } = state;
    return {
        message: message,
        response_options: responseOptions,
        currentUser: auth,
        conversationThread: conversation.conversation,
        loadingModal: conversation.loading,
        websocketTrendsMessage: websocket.trendsMessage,
        websocketTeamsMessages: websocket.teamsMessages,
        allMessages: allMessages,
        dispatcherRequest: request,
        updatedIsReviewed: updatedIsReviewed
    };
}

const connectedMessageDetailsV2 = withRouter(withStyles(notificationInboxStyle)(connect(mapStateToProps)(MessageDetailsV2)));
export {connectedMessageDetailsV2 as MessageDetailsV2};

MessageDetailsV2.propTypes = {
    dispatch: PropTypes.func,
    message: PropTypes.object,
    match: PropTypes.object,
    params: PropTypes.object,
    response_options: PropTypes.array,
};
