import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { getLogoUrl } from '_helpers'
import { userActions } from '_actions';
/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/01/2019 : 4:09 PM
 */

const imgStyle = {
    maxWidth: '100%',
    width: '100%',
    height: 'auto',
    maxHeight: '100%'
};

const imgContainerStyle  = {
    minWidth: '100%',
    height: '150px',
    border: 'solid 1px lightGrey',
    borderRadius: '2%'
}

class profilePicture extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            picture: '',
            fileInput: null,
            selectImageText: 'Select',
        }
    }

    handleChange = (data) => {
        this.setState({
            picture: data,
            selectImageText: 'Update'
        })
    }

    addDefaultSrc = (ev) => {
        ev.target.src = this.props.defaultPhoto;
    };

    setFormInputValue = (data) => {
        this.setState({
            fileInput: data
        })
    }

    uploadPhoto = () => {
        const formData = new FormData()
        formData.append('photo', this.state.fileInput)
        this.props.dispatch(userActions.updateUserPhoto(this.props.userId, this.props.type, formData));
        this.setState({ picture: '', fileInput: null })
    }

    render () {
        const { imgAlt, picture, defaultPhoto } = this.props
        let profilePhoto = defaultPhoto
        if (this.state.picture) {
            profilePhoto = this.state.picture
        } else {
            if (picture) {
                profilePhoto = getLogoUrl(picture);
            }
        }
        return (
            <div>
                <Row>
                    <Col md={12} style={imgContainerStyle}>
                        <img src={profilePhoto} onError={this.addDefaultSrc}
                            alt={imgAlt} style={imgStyle}/>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default  profilePicture
