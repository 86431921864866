export const subscriptionsConstants = {
    GET_ALL_SUBSCRIPTIONS_REQUEST: 'GET_ALL_SUBSCRIPTIONS_REQUEST',
    GET_ALL_SUBSCRIPTIONS_SUCCESS: 'GET_ALL_SUBSCRIPTIONS_SUCCESS',
    GET_ALL_SUBSCRIPTIONS_FAILURE: 'GET_ALL_SUBSCRIPTIONS_FAILURE',

    GET_SUBSCRIPTION_REQUEST: 'GET_SUBSCRIPTION_REQUEST',
    GET_SUBSCRIPTION_SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
    GET_SUBSCRIPTION_FAILURE: 'GET_SUBSCRIPTION_FAILURE',

    CREATE_SUBSCRIPTION_REQUEST: 'CREATE_SUBSCRIPTION_REQUEST',
    CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
    CREATE_SUBSCRIPTION_FAILURE: 'CREATE_SUBSCRIPTION_FAILURE',

    UPDATE_SUBSCRIPTION_REQUEST: 'UPDATE_SUBSCRIPTION_REQUEST',
    UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_FAILURE: 'UPDATE_SUBSCRIPTION_FAILURE',

    SUBSCRIPTION_HISTORY_REQUEST: 'SUBSCRIPTION_HISTORY_REQUEST',
    SUBSCRIPTION_HISTORY_SUCCESS: 'SUBSCRIPTION_HISTORY_SUCCESS',
    SUBSCRIPTION_HISTORY_FAILURE: 'SUBSCRIPTION_HISTORY_FAILURE',

    ADD_ORG_SUBSCRIPTION_REQUEST: 'ADD_ORG_SUBSCRIPTION_REQUEST',
    ADD_ORG_SUBSCRIPTION_SUCCESS: 'ADD_ORG_SUBSCRIPTION_SUCCESS',
    ADD_ORG_SUBSCRIPTION_FAILURE: 'ADD_ORG_SUBSCRIPTION_FAILURE',

};
