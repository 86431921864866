import React, {Component, Fragment} from 'react';
import moment from 'moment/moment';
import {
  stripNumbersFromUsername,
  parseEscapedText,
  testForEmail,
} from '_helpers';
import _ from "lodash";
import copyIcon from "../images/outline-file_copy-24px.svg";
import {copyTicketLinkToClipboard, copyTextToClipboard} from '_actions/utility.action';
import {Loading} from "./Loading";


/**
 * @author Chris Okebata
 */

class FollowupConversation extends Component {

  /**
   * This renders the conversation gotten from trends
   * @param message
   * @param index
   * @returns {*}
   */
  renderConversation = (message, index) => {
    const text = parseEscapedText(message.text);
    return (
      <div key={message.id} style={{display: 'flex', padding: '20px', borderBottom: '1px solid #ededed', clear: 'both'}}>
        <div>
          <div style={{paddingBottom: '10px'}}>
            <span id="authorName" style={message.author ? {fontWeight: "bold", marginRight: '8px'} : {fontWeight: "bold", marginRight: '0'}}>
              {testForEmail(message.author) ? 'User' : stripNumbersFromUsername(message.author)}
            </span>
            {message.author && index === 0 && <img src={copyIcon} onClick={() => copyTextToClipboard('authorName')} style={{width: '18px', marginRight: '15px', cursor:'pointer'}} alt="Copy Customer Name"/> }

            <span>
              {moment(message.createdDate).format('MMM D HH:mm a')}
            </span>
          </div>
          <div className="no-break-out">
            {text}
          </div>
        </div>
      </div>
    );
  };


  render(){

    let fullMessageLink, author;
    let { loading, messages } = this.props;
    const messagesCount = _.get(messages, 'length');

    if (messagesCount > 0) {
      fullMessageLink =  messages[messagesCount - 1].fullMessageLink;
      author = messages[0].author;
    }

    if (loading) {
      return <Loading height={150}/>
    }

    return (
      <div>
        { messagesCount === 0 && (
          <h4 className="text-center">
            There are no messages in this conversation
          </h4>
        )
        }
        { messagesCount > 0 && <div className="conversation-list-container">
          <div className="title conversation-list-title" style={{padding: `2px 20px 0`, borderBottom: '1px solid #ededed'}}>
            <span className="tms-ellipsis" style={{width: '275px'}} title={author}> Conversation with {author}</span>
            {fullMessageLink && (
              <div>
                <span>
                  <a id="fullMessageLink"
                     className="font-14 color-green tms-no-underline"
                     href={fullMessageLink}
                     target="_blank"
                     rel="noreferrer noopener nofollow"
                     style={{ fontWeight: 'bold'}}
                  >
                      View on {' '} {messages[messagesCount - 1].messageSource.name}
                  </a>
                  <img src={copyIcon}
                       onClick={() => copyTicketLinkToClipboard('fullMessageLink')}
                       style={{width: '18px',marginLeft: '7px',cursor:'pointer'}}
                       alt="Copy Ticket Link"
                  />
                </span>
              </div>
            )}
          </div>

          <div>
            <Fragment>
              {
                messages && messages.constructor === Array && messages.map((message, index) =>
                  this.renderConversation(message, index))
              }
            </Fragment>
          </div>
          <div style={{textAlign: 'center', padding: '20px 0'}}>End of conversation</div>
        </div>
        }
      </div>
    );
  }
}

export { FollowupConversation };
