export default {
	root: {
		fontFamily: 'LarsseitRegular',
		letterSpacing: '-0.02em',
		color: '#0C2728',
	},
	headingLarge: {
		m: 0,
		fontFamily: 'LarsseitMedium',
		fontSize: 24,
		lineHeight: 1.2,
		letterSpacing: '-0.02em',
		color: '#0C2728'
	},
	headingSmall: {
		m: 0,
		mb: { xs: 2, md: 3 },
		fontSize: { xs: '16px', md: '18px' },
		fontFamily: 'LarsseitMedium',
	},
	definitionList: {
		m: 0,
	},
	definitionTitle: {
		fontSize: '16px',
		fontFamily: 'LarsseitMedium',
		fontWeight: 500,
		':not(:first-of-type)': {
			mt: 2,
		}
	},
	definitionText: {
		fontSize: '16px',
		fontFamily: 'LarsseitRegular',
	},
	textLink: {
		color: '#368C86',
		fontFamily: 'LarsseitMedium',
		':hover': {
			color: '#368C86',
			backgroundColor: 'unset'
		},
	}
}