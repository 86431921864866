import {Fragment} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import {supportedSourcesForCloseTicket} from "_constants";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "../../../../../common/ActionButton";
import TextButton from "../../../../../common/TextButton";
import PerfectResponseAction from "./PerfectResponseAction";
import CloseTicketCheckbox from "../../../../../common/CloseTicketCheckbox";
import TagEntry from "./Tag/TagEntry";
import {isTaggingSupported} from "./Tag/TagUtils";
import useViewActiveTags from "./Tag/hooks/useViewActiveTags";

export default function ResponseActions({
    responseId,
    loadingHtmlBody,
    handleOpenEditor,
    isScrollComplete,
    handleCloseEditor,
    isEditingText,
    msgDataSource,
    canBeHidden,
    response,
    openEditor,
    handleSubmitResponse,
    handleSubmitAndHide,
    submitVoteLoading,
    isEditSelected,
    isLiveAgent,
    handleSubmitPerfectResponse,
    sourceName,
    handleCloseTicket,
    handleTagSelection,
    selectedTags
}) {

    const disableButton = !isScrollComplete || submitVoteLoading;
    sourceName = _.toString(sourceName).toLowerCase();
    const isCloseTicketSupported = supportedSourcesForCloseTicket.includes(sourceName);
    const { activeTags, loading } = useViewActiveTags();
    const shouldAddTags = !loading && isTaggingSupported(sourceName) && activeTags.length > 0;

    return(
        <Fragment>
            {
                !openEditor ?
                    <Box sx={style.defaultActionButtonContainer}>
                        <Box sx={style.editButtonContainer}>
                            <ActionButton
                                label={(loadingHtmlBody && isEditSelected) ? <CircularProgress size={15} sx={{ color: '#144042'}}/> : 'Edit'}
                                action={() => handleOpenEditor(responseId, isEditingText, msgDataSource, response)}
                                buttonBordered
                                useBorderColor={'#144042'}
                                disabled={disableButton || loadingHtmlBody}
                                withIcon={false}
                                sx={style.responseButton}
                            />
                        </Box>
                        <PerfectResponseAction
                            submitVoteLoading={submitVoteLoading}
                            handleSubmitPerfectResponse={handleSubmitPerfectResponse}
                            disableButton={disableButton}
                            loadingHtmlBody={loadingHtmlBody}
                            handleCloseTicket={handleCloseTicket}
                        />
                    </Box> :
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: '2px'}}>
                        <Box sx={{width: '84px'}}>
                            <ActionButton
                                label={'Cancel'}
                                action={() => handleCloseEditor()}
                                buttonBordered
                                useBorderColor={'#07857C'}
                                disabled={disableButton}
                                withIcon={false}
                                sx={style.responseButton}
                            />
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center',}}>
                            {
                                isLiveAgent && canBeHidden &&
                                <Box sx={style.submitAndHideButton}>
                                    <TextButton
                                        label={'Submit and hide'}
                                        action={handleSubmitAndHide}
                                        disabled={disableButton}
                                        sx={{height: '22px'}}
                                        rootStyle={{height: '22px', color: '#144042'}}
                                    />
                                </Box>
                            }
                            <Box sx={style.actionsContainer}>
                                <Box sx={{pt: '1px', mx: '15px'}}>
                                    {
                                        isCloseTicketSupported &&
                                        <CloseTicketCheckbox handleCloseTicket={handleCloseTicket} />
                                    }
                                </Box>
                                {
                                    shouldAddTags &&
                                    <Box sx={{mx: '15px'}}>
                                        <TagEntry handleTagSelection={handleTagSelection} selectedTags={selectedTags} />
                                    </Box>
                                }
                                <ActionButton
                                    label={submitVoteLoading ? <CircularProgress size={15} sx={{ color: '#FFFFFF'}}/> : 'Submit response'}
                                    action={handleSubmitResponse}
                                    disabled={disableButton}
                                    withIcon={false}
                                    sx={style.submitResponseButton}
                                />
                            </Box>
                        </Box>
                    </Box>
            }
        </Fragment>
    )
}

const style = {
    responseButton: {
        height: '32px'
    },
    submitResponseButton: {
        width: '175px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitAndHideButton: {
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px',
    },
    defaultActionButtonContainer: {
        width: '84px',
        display: 'flex'
    },
    editButtonContainer: {
        marginRight: '15px'
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}