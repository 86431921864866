import React from 'react';
import {useSelector} from "react-redux";
import _ from "lodash";
import {CampaignBuilder} from "../objects/CampaignBuilder";
import {
    ALL_CAMPAIGNS,
    buildMetricsDetailsUrl,
    COMPANY_CAMPAIGN,
    extractKeywordsFromSegments,
    formatProactiveMetric,
    formatToDate
} from "../CampaignUtils";
import CampaignActions from "../../../ProactiveMetricsDetail/Utilities/CampaignActions";

export default function (params) {
    const proactive = useSelector(state => state.proactive);
    const loadingOrganizationCampaigns = proactive.loadingOrganizationCampaigns;
    const campaignsMetrics = proactive.campaignsMetrics;
    const campaignsObj = proactive.organizationCampaigns;
    const campaignList = _.get(campaignsObj, 'items') || [];
    const totalCount = _.get(campaignsObj, 'totalCount') || 0;
    const rows = [];
    const campaignMetricsList = _.get(campaignsMetrics, 'items') || [];

    if(!loadingOrganizationCampaigns && campaignList.length > 0) {
        campaignList.map( campaign => {
            const builder = new CampaignBuilder();
            builder.setId(_.get(campaign, 'id') || '-');
            builder.setName(_.get(campaign, 'name') || '-');
            builder.setChannel(_.get(campaign, 'channel') || '-');
            builder.setTooltipTitle(extractKeywordsFromSegments(_.get(campaign, 'segments')) || '-');
            builder.setSegments(extractKeywordsFromSegments(_.get(campaign, 'segments')) || '-');
            builder.setTickets('-');
            builder.setEngagement('-');
            builder.setFollow('-');
            builder.setConversion('-');
            builder.setLikesCount('-');
            builder.setRevenue('-');
            builder.setCalculatedAt('-');
            if (campaignMetricsList.length > 0) {
                const campaignObj = _.find(campaignMetricsList, {'campaignId': _.get(campaign, 'id')});
                if (!_.isEmpty(campaignObj)) {
                    const totalEngagement = campaignObj.followsCount + campaignObj.repliedMessagesCount + campaignObj.likesCount

                    builder.setTickets(_.get(campaignObj, 'messageCount') || '-');
                    builder.setEngagement(_.get(campaignObj, 'engagementRatio') || '-');
                    builder.setFollow(_.get(campaignObj, 'followRatio') || '-');
                    builder.setConversion(_.get(campaignObj, 'conversionRatio') || '-');
                    builder.setLikesCount(_.get(campaignObj, 'likesCount') || '-');
                    builder.setRevenue(_.get(campaignObj, 'revenue') || '-');
                    builder.setCalculatedAt(formatToDate(_.get(campaignObj, 'calculatedAt')) || '-');
                    builder.setConversionCountAfterFollowUp(_.get(campaignObj, 'conversionCountAfterFollowUp') || '-');
                    builder.setRevenueAfterFollowUp(_.get(campaignObj, 'revenueAfterFollowUp') || '-');
                    builder.setTotalReplies(_.get(campaignObj, 'repliedMessagesCount') || '-');
                    builder.setTotalFollows(_.get(campaignObj, 'followsCount') || '-');
                    builder.setTotalEngagement(totalEngagement || '-');
                }
            }
            builder.setParam(buildMetricsDetailsUrl(campaign, COMPANY_CAMPAIGN, params));
            builder.setActions(<CampaignActions campaign={builder.campaign} />);
            builder.setParam(buildMetricsDetailsUrl(campaign, ALL_CAMPAIGNS, params));
            rows.push(builder.campaign);
        });
    }

    const columns = [
        {
            id: 'name',
            label: 'Campaign name',
            width: 170,
            clickable: true,
            isSortable: true,
            withTooltip: true
        },
        {
            id: 'channel',
            label: 'Channel',
            width: 100,
            isSortable: true
        },
        {
            id: 'segments',
            label: 'Segments',
            width: 100,
            isSortable: true,
            withTooltip: true
        },
        {
            id: 'tickets',
            label: 'Conversations',
            width: 50,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
            isSortable: true
        },
        {
            id: 'conversion',
            label: 'Conversion rate',
            width: 50,
            align: 'right',
            format: formatProactiveMetric,
            isSortable: true
        },

        {
            id: 'revenue',
            label: 'Revenue',
            width: 50,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
            isSortable: true
        },
        {
            id: 'conversionCountAfterFollowUp',
            label: 'Converted after follow-up',
            width: 50,
            align: 'right',
            isSortable: true
        },
        {
            id: 'revenueAfterFollowUp',
            label: 'Revenue after follow-up',
            width: 50,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
            isSortable: true
        },
        {
            id: 'totalEngagement',
            label: 'Total engagement',
            width: 50,
            align: 'right',
            isSortable: true
        },
        {
            id: 'likesCount',
            label: 'Likes',
            width: 50,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
            isSortable: true
        },
        {
            id: 'totalReplies',
            label: 'Replies',
            width: 50,
            align: 'right',
            isSortable: true
        },
        {
            id: 'totalFollows',
            label: 'Follows',
            width: 50,
            align: 'right',
            isSortable: true
        },
        {
            id: 'engagement',
            label: 'Engagement %',
            width: 50,
            align: 'right',
            format: formatProactiveMetric,
            isSortable: true
        },
        {
            id: 'follow',
            label: 'Follows %',
            width: 50,
            align: 'right',
            format: formatProactiveMetric,
            isSortable: true
        },
        {
            id: 'calculatedAt',
            label: 'Last refresh',
            width: 200,
            align: 'right',
            isSortable: true
        },
        {
            id: 'actions',
            label: 'Actions',
            width: 140,
            align: 'right',
        },
    ];

    return { rows, columns, totalCount, loading: loadingOrganizationCampaigns };
}