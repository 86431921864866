import React from "react";
import {Box} from "@mui/material";
import { Field, reduxForm } from "redux-form";
import { DateRange } from 'components/common/formFields/DateRange'
import {DropDownSelect, MultiSelectContextMenu} from "components/common";
import BasicButton from "../../../common/BasicButton";

function FilterForm
({
     handleSubmit,
     submitting,
     startDate,
     messagesAction,
     channelsAction,
     endDate,
     handleChange,
     channelsFilter,
     channelsIds,
     messagesFilter,
     handleChannelsFilterChange,
     onSubmit
 }){

    const messagesInput = {onChange: handleChange, value: messagesAction};
    const channelsInput = {onChange: handleChange, value: channelsAction};
    const channelOptions = channelsFilter && channelsFilter.length > 0 ? channelsFilter.map(channelFilter => {
        return {name: channelFilter.name, value: channelFilter.value}
    }) : []

    const dropdownStyles = {
        boxShadow: "none",
        color: "#5a5f5e",
    };

    return(
        <form name="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box sx={{marginBottom: "10px"}}>
                <Box sx={styles.inputDesc}>Date</Box>
                <label className="calendar-today tms-inbox-calendar company-inbox-calender" style={{display: "block"}}>
                    <Field
                        submittedStartDate={startDate}
                        submittedEndDate={endDate}
                        name="dateRange"
                        type="text"
                        values={startDate}
                        component={DateRange}
                    />
                </label>
            </Box>
            <Box sx={{marginBottom: "10px"}}>
                <Box sx={styles.inputDesc}>Messages</Box>
                <label className="inbox-select-field" style={{display: "block"}}>
                    <Field
                        name="userActions"
                        fieldName="userActions"
                        type="text"
                        input={messagesInput}
                        style={dropdownStyles}
                        options={messagesFilter}
                        value={messagesAction}
                        component={DropDownSelect}
                    />
                </label>
            </Box>
            <Box>
                <Box sx={styles.inputDesc}>Channels</Box>
                <div className="inbox-context-menu inbox-channel-field" style={{display: "block"}}>
                    <Field
                        name="channels"
                        fieldName="channels"
                        type="text"
                        displayName={'Channels'}
                        input={channelsInput}
                        options={channelOptions}
                        checkedOptions={channelsAction}
                        ids={channelsIds}
                        handleChange={handleChannelsFilterChange}
                        component={MultiSelectContextMenu}
                    />
                </div>
            </Box>
            <Box>
                <BasicButton
                    label="Apply"
                    style={styles.applyBtnStyles}
                    type="submit"
                    disabled={submitting}
                />
            </Box>
        </form>
    )
}

const styles = {
    applyBtnStyles: {
        border: '1px solid #368C86',
        backgroundColor: '#07857C',
        width: '100%',
        color: '#FFFFFF',
        letterSpacing: '-0.5px',
        fontFamily: 'LarsseitMedium',
        borderRadius: '5px',
        paddingTop: '10px'
    },
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '5px'
    }
}


const formConfig = {
    form: 'FilterForm',
}

FilterForm = reduxForm(formConfig)(FilterForm)
export default FilterForm