import {SERVER_URL} from "config";
import axios from "axios";
import {authHeader, fileUploadHeader, objectToQueryString} from "_helpers";

function getCampaign(id) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/campaigns/${id}`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function getCampaignsMetrics(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/organizations/${params.organizationId}/campaigns/metrics`;
    return axios.get(endpoint, {
        params: params,
        headers: authHeader()
    });
}

function getCompanyCampaigns(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/organizations/${params.organizationId}/campaigns`;
    return axios.get(endpoint, {
        params: params,
        headers: authHeader()
    });
}

function getAllCampaigns(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/campaigns`;
    return axios.get(endpoint, {
        params: params,
        headers: authHeader()
    });
}

function getCompanyTemplates(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/organizations/${params.organizationId}/templates`;
    return axios.get(endpoint, {
        params: params,
        headers: authHeader()
    });
}

function getAllTemplates() {
    const endpoint = `${SERVER_URL}/api/v1/proactive/templates`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function getMsgDataSources(params) {
    const {channel, organizationId} = params
    const endpoint = `${SERVER_URL}/api/v1/proactive/organizations/${organizationId}/msg-data-sources`;
    return axios.get(endpoint, {
        params: { channel },
        headers: authHeader()
    });
}

function getOrgMsgDataSourcesBySourceName(params) {
    const {msgSourceName, organizationId} = params
    const endpoint = `${SERVER_URL}/api/organizations/${organizationId}/msgDataSources`;
    return axios.get(endpoint, {
        params: { msgSourceName },
        headers: authHeader()
    });
}

function getCustomerIoSegments(params) {
    const endpoint = `${SERVER_URL}/api/customerio/${params.msgDataSourceId}/segments`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function createCampaign(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/campaigns`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

function updateCampaign(params) {
    const {id} = params;
    const endpoint = `${SERVER_URL}/api/v1/proactive/campaigns/${id}`;
    return axios.patch(endpoint, params,{
        headers: authHeader()
    })
}

function deleteCampaignAttribute(params) {
    const {attributeId, campaignId} = params;
    const endpoint = `${SERVER_URL}/api/v1/proactive/campaigns/${campaignId}/attributes/${attributeId}`;
    return axios.delete(endpoint, {
        headers: authHeader()
    })
}

function getChannels() {
    const endpoint = `${SERVER_URL}/api/v1/proactive/channels`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function generateReplies(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/replies`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

function createTemplate(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/templates`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

function getTemplate(id) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/templates/${id}`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function updateTemplate(params) {
    const {id} = params;
    const endpoint = `${SERVER_URL}/api/v1/proactive/templates/${id}`;
    return axios.patch(endpoint, params,{
        headers: authHeader()
    })
}

function updateResponse(id, params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/suggested-responses/${id}`;
    return axios.patch(endpoint, params,{
        headers: authHeader()
    })
}

function updateCampaignSegmentStatus(payload) {
    const campaignId = payload.campaignId;
    const segmentId = payload.segmentId;
    const endpoint = `${SERVER_URL}/api/v1/proactive/campaigns/${campaignId}/segments/${segmentId}/status`;
    return axios.put(endpoint, payload,{
        headers: authHeader()
    })
}

function getMediaTypes(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/types`;
    return axios.get(endpoint, {
        params: params,
        headers: authHeader()
    });
}

function getProactiveMessagesList(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/organizations/${params.organizationId}/messages`;
    return axios.get(endpoint, {
        params: params,
        headers: authHeader()
    })
}

function getProactiveMsgConversation(id) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/messages/${id}/conversation`;
    return axios.get(endpoint,{
        headers: authHeader()
    })
}

function exportProactiveMessages(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/organizations/${params.organizationId}/messages/export/csv?${objectToQueryString(params)}`;
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(endpoint, requestOptions).then(response => {
        response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'ProactiveMetricsDetail Stats.csv';
            anchor.click();
        });
    });
}

function uploadCustomerDataFile(formData) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/customer-data-files`;
    return axios.post(endpoint, formData,{
        headers: fileUploadHeader(),
    })
}

function getSegmentTypes(params) {
    const endpoint = `${SERVER_URL}/api/v1/proactive/segments/types?${objectToQueryString(params)}`;
    return axios.get(endpoint,{
        headers: authHeader(),
    })
}

export const proactiveService = {
    getCampaign,
    getCampaignsMetrics,
    getAllCampaigns,
    getCompanyCampaigns,
    getCompanyTemplates,
    getAllTemplates,
    createCampaign,
    updateCampaign,
    getMsgDataSources,
    getOrgMsgDataSourcesBySourceName,
    getChannels,
    generateReplies,
    createTemplate,
    getTemplate,
    updateTemplate,
    updateResponse,
    getMediaTypes,
    getProactiveMessagesList,
    getProactiveMsgConversation,
    exportProactiveMessages,
    getCustomerIoSegments,
    deleteCampaignAttribute,
    updateCampaignSegmentStatus,
    uploadCustomerDataFile,
    getSegmentTypes
}