export default {
    container: {
        borderLeft: {
            xs: '1px solid transparent',
            sm: '1px solid transparent',
            md: '1px solid #CED4D4',
            lg: '1px solid #CED4D4',
        },
        height: '100%',
        p: {
            xs: '15px 20px 15px 20px',
            sm: '15px 20px 15px 20px',
            md: '20px 20px 20px 15px',
            lg: '20px 20px 20px 15px',
        },
    },
    headingText: {
        fontFamily: 'LarsseitBold',
        fontSize: '14px',
        lineHeight: 1.2,
        letterSpacing: '0.04em',
        color: '#0C2728',
        mb: '5px'
    },
    balanceText: {
        fontFamily: 'LarsseitRegular',
        fontSize: '24px',
        lineHeight: 1.2,
        letterSpacing: '0.04em',
        color: '#0C2728',
    },
    headingTextPlaceholder: {
        width: {
            xs: '135px',
            sm: '135px',
            md: '100%',
            lg: '100%',
        }
    }
}