import React, {useEffect, useRef, useState} from 'react';
import {styles} from './Utilities/ProactiveMetricsDetailStyles';
import {Box} from "@mui/material";
import {queryStringToObject, objectToQueryString} from "_helpers";
import {CampaignMetricsDetailComponent} from "./ProactiveMetricsDetailComponent";
import {proactiveActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import moment from "moment";
import useLocationQueryParams from "../../hooks/useLocationQueryParams";

const defaultFormat = 'YYYY-MM-DD HH:mm:ss'
const startDateDefault = moment().subtract(2, 'years');
const endDateDefault = moment().add(1, 'd').format(defaultFormat);

export const ProactiveMetricsDetailContainer = ({ location, history }) => {
    const search = location.search.substring(1);
    const filterParams = queryStringToObject(search);
    const { setParams } = useLocationQueryParams();
    let { campaignIds: campaignId, pageFilter, organizationId } = filterParams;
    let initialStartDate = filterParams.startDate || startDateDefault;
    let initialEndDate = filterParams.endDate || endDateDefault;
    let offset = parseInt(filterParams.offset, 10) || 0;
    let currentPage = parseInt(filterParams.currentPage, 10) || 0;

    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const [isCampaignNameDropdownOpen, setIsCampaignNameDropdownOpen] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState('all_messages');
    const [pageParams, setPageParams] = useState({offset, currentPage});
    const [dateRange, setDateRange] = useState({startDate: initialStartDate, endDate: initialEndDate});
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [liked, setLiked] = useState(Boolean(filterParams.liked));
    const [replied, setReplied] = useState(Boolean(filterParams.replied));
    const [noEngagement, setNoEngagement] = useState(Boolean(filterParams.noEngagement));
    const [followed, setFollowed] = useState(Boolean(filterParams.followed));
    const [converted, setConverted] = useState(Boolean(filterParams.converted));
    const [followUpSent, setFollowUpSent] = useState(Boolean(filterParams.followUpSent));
    const [convertedAfterFollowUpSent, setConvertedAfterFollowUpSent] = useState(Boolean(filterParams.convertedAfterFollowUpSent));
    const filterRef = useRef(null);
    const filterPageRef = useRef(null);
    const maxItemsPerPage = 10;

    const dispatch = useDispatch()
    const { startDate, endDate } = dateRange;
    const proactive = useSelector(state => state.proactive);
    const campaignsObj = proactive.organizationCampaigns;
    const campaignList = _.get(campaignsObj, 'items') || [];
    const extractedCampaignList = campaignList.map(({ name, id }) => ({ name, id }));

    let payload = {
        organizationId: organizationId,
        campaignIds: campaignId,
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
        replied: replied,
        liked: liked,
        noEngagement: noEngagement,
        currentPage: pageParams.currentPage,
        offset: pageParams.offset,
        limit: maxItemsPerPage,
        followed: followed,
        converted: converted,
        followUpSent: followUpSent,
        convertedAfterFollowUpSent: convertedAfterFollowUpSent,
        pageFilter
    }

    let formattedPayload = (payload) => {
    //Only send values for 1. Replied - ?replied=true 2.Liked - ?liked=true 3.No Engagement - ?replied=false&liked=false
        const basePayload = {
            organizationId: payload.organizationId,
            campaignIds: payload.campaignIds,
            startDate: payload.startDate,
            endDate: payload.endDate,
            currentPage: payload.currentPage,
            offset: payload.offset,
            limit: payload.limit,
            pageFilter: payload.pageFilter
        };

        if (payload.liked) {
            return { ...basePayload, liked: payload.liked };
        } else if (payload.replied) {
            return { ...basePayload, replied: payload.replied };
        } else if (payload.noEngagement) {
            return { ...basePayload, noEngagement: payload.noEngagement };
        } else if (payload.followed) {
            return {...basePayload, followed: payload.followed };
        } else if (payload.converted) {
            return {...basePayload, converted: payload.converted };
        } else if (payload.followUpSent) {
            return {...basePayload, followUpSent: payload.followUpSent };
        } else if (payload.convertedAfterFollowUpSent) {
            return {...basePayload, convertedAfterFollowUpSent: payload.convertedAfterFollowUpSent };
        }
        return basePayload;
    }
    const filteredPayload = formattedPayload(payload)

    const handleFiltersSubmit = () => {
        setParams(filteredPayload)
        dispatch(proactiveActions.getProactiveMessagesList(filteredPayload))
        setIsFilterDropdownOpen(false)
        dispatch(proactiveActions.clearConversation())
    }

    const handleFilterReset = () => {
        setSelectedCriteria("all_messages")
    }

    const onSelectChangeHandler = (currentSelectedOption) => {
        const initialPayload = { ...filteredPayload, campaignIds: currentSelectedOption.id, pageFilter:pageFilter, offset: 0, currentPage: 0 };
        const queryString = objectToQueryString(initialPayload);

        history.push({
            pathname: location.pathname,
            search: `?${queryString}`
        });
        dispatch(proactiveActions.clearConversation())
    };

    const closeOpenFilterDropdown = (e)=>{
        if(filterRef.current && isFilterDropdownOpen && !filterRef.current.contains(e.target)){
            setIsFilterDropdownOpen(false)
        }
        if(filterPageRef.current && isCampaignNameDropdownOpen && !filterPageRef.current.contains(e.target)){
            setIsCampaignNameDropdownOpen(false)
        }
    }
    document.addEventListener('mousedown',closeOpenFilterDropdown)

    const handleExport = () => {
        dispatch(proactiveActions.exportProactiveMessages(filteredPayload))
    }

    useEffect(() => {
        if (!isFirstLoad) {
            const queryString = objectToQueryString(filteredPayload);
            history.push({
                pathname: location.pathname,
                search: `?${queryString}`
            });
            return
        }

        if (campaignList.length === 0) {
            dispatch(proactiveActions.getCompanyCampaigns({
                organizationId,
                offset,
                limit: 1000
            }));
        }
        setIsFirstLoad(false);
    }, [startDate, endDate, pageParams])

    useEffect(() => {
        handleFiltersSubmit()
    }, []);

    return (
        <Box sx={styles.root}>
            <Box sx={styles.container}>
                <CampaignMetricsDetailComponent
                    pageFilter={pageFilter}
                    isFilterDropdownOpen={isFilterDropdownOpen}
                    setIsFilterDropdownOpen={setIsFilterDropdownOpen}
                    isCampaignNameDropdownOpen={isCampaignNameDropdownOpen}
                    setIsCampaignNameDropdownOpen={setIsCampaignNameDropdownOpen}
                    selectedCriteria={selectedCriteria}
                    setSelectedCriteria={setSelectedCriteria}
                    filterRef={filterRef}
                    filterPageRef={filterPageRef}
                    handleExport={handleExport}
                    pageParams={pageParams}
                    setPageParams={setPageParams}
                    selectedDropdownOption={extractedCampaignList.find(campaign => campaign.id === campaignId)}
                    onSelectChangeHandler={onSelectChangeHandler}
                    onFilterFormSubmit={handleFiltersSubmit}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    handleFilterReset={handleFilterReset}
                    extractedCampaignList={extractedCampaignList}
                    setLiked={setLiked}
                    setReplied={setReplied}
                    setNoEngagement={setNoEngagement}
                    setFollowed={setFollowed}
                    setConverted={setConverted}
                    setFollowUpSent={setFollowUpSent}
                    setConvertedAfterFollowUpSent={setConvertedAfterFollowUpSent}
                />
            </Box>
        </Box>
    )
}