import React from 'react'
import {ControlLabel, FormGroup} from "react-bootstrap";
import FormControl from "react-bootstrap/es/FormControl";

const InputGroup = (props) => {
    const {placeHolder, label, style, input, addonText, hidden, addonClassName, ...rest} = props;

    return (
        <FormGroup controlId="formControlsSelect" hidden={hidden}>
            <ControlLabel>{label}</ControlLabel>
            <div className="input-group">
                <FormControl
                    {...rest}
                    {...input}
                    placeholder={placeHolder}
                    onChange={input.onChange}
                    label='User Name'
                    style={style}
                    name={input.name}
                />
                <span className={addonClassName ? `input-group-addon ${addonClassName}` : "input-group-addon"}
                      id="basic-addon2">{addonText}</span>
            </div>
        </FormGroup>
    )
}


export {InputGroup}
