import React, {Fragment} from "react";
import ShopifySetupModalForm from "../../../../Onboarding/ConnectChannel/Channels/Shopify/ShopifySetupModalForm";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";

export default function ShopifySetupModal({
	open,
	handleClose,
	handleOpen
}){
  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Shopify'}>

        <ShopifySetupModalForm
          description={'Please copy and paste your Shopify subdomain here:'}
          placeholder={'yoursubdomain'}
          id={'yoursubdomain'}
          channelDomain={'.myshopify.com'}
          buttonLabel={'Submit'}
          openInNewTab={true}
          handleClose={handleClose}
          callbackPath={'/shopify/accounts'}
        />
      </BasicModal>
    </Fragment>
  )
}