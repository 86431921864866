import React, {useEffect} from "react";
import {zendeskOauthActions} from "_actions";
import {useDispatch} from "react-redux";

export default function useZendeskSetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const performAction = () => {
    handleOpen();
  };

  useEffect(() => {
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const zendeskAuthCode = values.get('code');
    const partner = values.get('partner');
    const channel = values.get('channel');

    if(!partner && zendeskAuthCode && !channel) {
      const params = {code: zendeskAuthCode};
      submitToken(params);
    }
  }, []);

  const submitToken = (params) => {
    dispatch(zendeskOauthActions.generateAccessToken(params, '/company/channels', '/company/connectchannels'));
  };

	return {
		initiateZendeskSetup: performAction,
		zendeskSetupOpen: open, 
		handleZendeskSetupOpen: handleOpen,
		handleZendeskSetupClose: handleClose
	};
}