import {Fragment} from "react";
import DeleteScheduleModalContainer from "./DeleteScheduleModalContainer";
import BasicModal from "../../../../common/BasicModal";

export default function DeleteScheduleModal({title, open, handleClose, handleDelete}) {

    const handleDeleteSchedule = () => {
        handleDelete();
        handleClose();
    }

    const Content = () => {
        return (
            <DeleteScheduleModalContainer
                handleClose={handleClose}
                handleDelete={handleDeleteSchedule}
            />
        )
    }

    return(
        <Fragment>
            <BasicModal
                title={title}
                open={open}
                style={{width: '500px'}}
                handleClose={handleClose}>

                <Content />
            </BasicModal>
        </Fragment>
    )
}