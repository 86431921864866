import BasicTable from "../../../common/BasicTable";
import useGetPayouts from "../Utilities/hooks/useGetPayouts";
import _ from "lodash";
import {Box} from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

export default function PayoutList(){
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const agentId = _.get(currentUser, 'id');

    const { rows, columns, totalCount, loading } = useGetPayouts(agentId);

    return(
        <>
            { loading ? (
                <Box sx={{height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress size={30} sx={{ color: '#07857C'}}/>
                </Box>
            ) : (
                <>
                    { totalCount > 0 &&
                        <BasicTable
                            rows={rows}
                            columns={columns}
                            totalCount={totalCount}
                        />
                    }
                </>
            )}
        </>
    )
}
