import React, {Fragment} from "react";
import logoFreshdesk from 'components/images/logos/logo_freshdesk.svg'
import ChannelCard from '../../ChannelCard'
import useFreshdeskSetup from "./useFreshdeskSetup";
import FreshdeskSetupModal from "./FreshdeskSetupModal";
import DataSourceWebhookModal from "../../Utilities/DataSourceWebhookModal";
import { useSelector } from "react-redux";

export default function FreshdeskEntry() {
	const freshdesk = useSelector((state) => state.freshdeskAuth)
	const { initiateFreshdeskSetup, freshdeskSetupOpen, handleFreshdeskSetupOpen, handleFreshdeskSetupClose } = useFreshdeskSetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoFreshdesk}
				label={'Freshdesk'}
				description={'Clear your email ticket backlog'}
				onClick={initiateFreshdeskSetup}
			/>

			<FreshdeskSetupModal
				open={freshdeskSetupOpen}
				handleOpen={handleFreshdeskSetupOpen}
				handleClose={handleFreshdeskSetupClose}
			/>

			<DataSourceWebhookModal
				showWebhookUrl={freshdesk?.showWebhookUrl}
				webhookUrl={freshdesk?.data?.webhookUrl}
				platformUrl={freshdesk?.data?.platformUrl}
				dataSourceName={'Freshdesk'}
			/>
		</Fragment>
	)
}