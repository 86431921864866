import React from 'react';

const SummaryItem = ({ label, value }) => (label === "Percentage of tickets solved by Chatdesk" ?
    <div>
        <h2 className="font-40">{value}</h2>
        <p>{label}</p>
    </div> :
    <div>
        <h5 style={{ color: 'rgb(180, 180, 190)', height: "40px" }}>{label}</h5>
        <h2 className="font-25" style={{ marginTop: '0px' }}>{value}</h2>
    </div>
);

export default SummaryItem;
