import ConnectChannelButton from "../Utilities/ConnectChannelButton";
import React, {Fragment, useEffect} from "react";
import zendeskLogo from "components/images/logos/logo_zendesk.svg";
import BasicModal from "../../../Utilities/BasicModal";
import ZendeskSetupModalForm from "./ZendeskSetupModalForm";
import {zendeskOauthActions} from "_actions";
import {useDispatch} from "react-redux";


export default function ZendeskSetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const performAction = () => {
    handleOpen();
  };

  useEffect(() => {
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const zendeskAuthCode = values.get('code');

    if(zendeskAuthCode) {
      const params = {code: zendeskAuthCode};
      submitToken(params);
    }
  }, []);

  const submitToken = (params) => {
    dispatch(zendeskOauthActions.generateAccessToken(params));
  };

  return(
    <Fragment>
      <ConnectChannelButton
        label={'Connect with Zendesk'}
        channelIcon={zendeskLogo}
        action={performAction}/>

      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Zendesk'}>

        <ZendeskSetupModalForm
          description={'Please copy and paste your Zendesk subdomain here:'}
          placeholder={'yoursubdomain'}
          channelDomain={'.zendesk.com'}
          extraInfoLabel={'View setup instructions'}
          buttonLabel={'Submit'}
          openInNewTab={true}
          handleClose={handleClose} />

      </BasicModal>
    </Fragment>
  )
}