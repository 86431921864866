import React, {Fragment} from "react";
import {Box} from "@mui/system";
import {handleAttachment} from "_helpers";
import {messageActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import {SnackBar} from "../../../../common";
import styles from "../Conversation/conversationStyles"
import {getAttachment} from "../Utilities/MessageUtils";

/**
 * @param attachments {array}
 * @param message {object}
 * @returns {JSX.Element}
 * @constructor
 */
export default function AttachmentContainer({ attachments, message }) {
    const dispatch = useDispatch();
    const downloadingAttachment = useSelector(state => state.messages.downloadingAttachment);
    const {id, sourceName} = message
    const params = { messageId: id, msgSource: sourceName };

    const downloadAttachment = (messageId, attachmentURL, fileName) => {
        dispatch(messageActions.downloadAttachment(messageId, attachmentURL, fileName));
        SnackBar.showMessage('Downloading attachment');
    };

    const renderAttachment = (attachment, index) => {
        console.log(attachment);

        attachment  = getAttachment(attachment)

        return(
            <Fragment>
                {
                    attachment &&
                    <Box key={index} component={'li'} sx={styles.attachment}>
                        {handleAttachment(attachment, params, downloadAttachment, downloadingAttachment)}
                    </Box>
                }
            </Fragment>
        )
    };

    return(
        <Box component={'ul'} sx={styles.attachmentContainer}>
            {
                attachments && attachments.map((attachment, index) => {
                    return renderAttachment(attachment, index);
                })
            }
        </Box>
    )
}