import React from 'react'
import {ScreenshotModal} from "./ScreenshotModal";
import {screenshotConstants} from "_constants";

const headerText = (type) =>  {
    return type === screenshotConstants.SCREENSHOT_TYPE_MISTAKE ? "Constructive Feedback" : "Positive Feedback"
}

const buildModal =
    (
        onHide,
        show,
        messageId,
        currentPath,
        screenshotType,
        agentInfo,
        messageUrl
    ) => {

    return (
        <ScreenshotModal
            onHide={onHide}
            show={show}
            headerText={headerText(screenshotType)}
            messageId={messageId}
            currentPath={currentPath}
            agentInfo={agentInfo}
            screenshotType={screenshotType}
            messageUrl={messageUrl}
        />
    )
}

export const ScreenshotUtils = {
    buildModal
}