import {Box} from "@mui/material";
import styles from "../Utilities/campaignStyles";
import UpdateCampaignForm from "./UpdateCampaignForm";

export default function AdminEditCampaign() {
    return (
        <>
            <Box sx={styles.root}>
                <Box sx={styles.container}>
                    <Box sx={styles.containerBody}>
                        <Box sx={styles.header}>
                            Edit campaign
                        </Box>
                        <UpdateCampaignForm />
                    </Box>
                </Box>
            </Box>
        </>
    )
}