
export const dixaConstants = {
    VERIFY_API_TOKEN_REQUEST: 'VERIFY_API_TOKEN_REQUEST',
    VERIFY_API_TOKEN_SUCCESS: 'VERIFY_API_TOKEN_SUCCESS',
    VERIFY_API_TOKEN_FAILURE: 'VERIFY_API_TOKEN_FAILURE',
    CLEAR_VERIFY_API_TOKEN: 'CLEAR_VERIFY_API_TOKEN',

    CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
    CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
    CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',

    SETUP_INSTRUCTIONS_LINK: 'https://help.chatdesk.com/en/articles/9675869-dixa-integration-setup-guide-for-teams'
}