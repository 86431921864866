import {Fragment} from "react";
import BasicSelect from "./BasicSelect";

export default function SafeSearchSelect({name, label, placeholder, handleFormSelectChange, defaultValue, queryParam, input, meta}) {
    //@TODO: Create an endpoint to fetch these options
    const options = [
        {id: 'moderate', name: 'Moderate'},
        {id: 'strict', name: 'Strict'},
    ];

    return(
        <Fragment>
            <BasicSelect
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={defaultValue}
                options={options}
                handleFormSelectChange={handleFormSelectChange}
                input={input}
                meta={meta} />
        </Fragment>
    )
}