import * as React from 'react';
import {Box} from "@mui/system";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';

export default function TemplateActions({id}) {

    return (
        <Box sx={{display: 'flex'}}>
            <Box sx={{}}>
                <IconButton component={'a'} sx={styles.link} size={'small'} href={`/company/edit-template/${id}`}>
                    <EditIcon sx={{color:'#07857C', fontSize: '20px'}}/>
                </IconButton>
            </Box>
            {/*<Box sx={{marginLeft: '20px'}}>*/}
            {/*    <IconButton size={'small'} onClick={()=>{}}>*/}
            {/*        <FileCopyIcon sx={{color:'#07857C', fontSize: '20px'}}/>*/}
            {/*    </IconButton>*/}
            {/*</Box>*/}
        </Box>
    )
}

const styles = {
    link: {
        ':focus': {
            outline: 'unset',
        }
    }
}