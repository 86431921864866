import React, { Component } from 'react';
import {
    Col, Grid, Image, Row,
} from 'react-bootstrap';
import { messageActions } from '_actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import businessLogo from 'components/images/business.svg'
import { generatePhotoLink, getCurrentUser } from '_helpers';
import BillingDetails from 'components/CompanyBilling/BillingDetails'
import Usage from 'components/CompanyBilling/Usage'
import { queryStringToObject } from "_helpers";

const allTabStyle = {textAlign: 'center', marginRight: '15px', padding: '10px 15px', cursor: 'pointer'}
const pageStyle = {borderBottom: '2px solid green'}

class CompanyBilling extends Component {
    constructor(props) {
        super(props)
        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);

        this.state = {
            ...{page: 'billing'},
            ...filterParams
        }
    }

    componentDidMount() {
        const { organization_id } = getCurrentUser()
        const { dispatch } = this.props
        dispatch(messageActions.getBilling({
            organization: organization_id,
        }));
    }

    switchTab = (tab) => {
        this.setState({
            page: tab
        })
    }

    render() {
        const { _embedded: { organization }} = getCurrentUser()
        const { page } = this.state
        const { organizationBilling, loading } = this.props

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <Grid style={{backgroundColor: '#fff', padding: '0 70px 70px'}}>
                    <Row>
                        <Col xs={12} md={4}>
                            <Image responsive
                                   src={organization.logo ? generatePhotoLink(organization.logo) : businessLogo}
                                   rounded style={{width: '120px', height: 'auto', margin: '0 auto', display: 'block'}}/>
                        </Col>
                        <Col>
                            <span style={{fontSize: '20px'}}>{organization.name}</span>
                        </Col>
                    </Row>
                    <Row style={{borderBottom: '1px solid #ededed'}}>
                        <Col xs={6} md={2} lg={1} onClick={() => this.switchTab('billing')}
                             style={page === 'billing' ? {...allTabStyle, ...pageStyle} : allTabStyle}>Billing</Col>
                        <Col xs={6} md={2} lg={1} onClick={() => this.switchTab('usage')}
                             style={page === 'usage' ? {...allTabStyle, ...pageStyle} : allTabStyle}>Usage</Col>
                    </Row>
                    {page === 'billing' &&
                        <BillingDetails billing={organizationBilling} loading={loading} />
                    }
                    {page === 'usage' &&
                        <Usage total_amount={organizationBilling.balance} {...this.props} />
                    }
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { messages: { organizationBilling, loading }, auth } = state;
    return {
        organizationBilling, loading,
        currentUser: auth,
    };
}

const connectedCompanyBilling = withRouter(connect(mapStateToProps)(CompanyBilling));
export default connectedCompanyBilling;

CompanyBilling.propTypes = {
    dispatch: PropTypes.func,
    message: PropTypes.object,
    match: PropTypes.object,
    params: PropTypes.object,
    response_options: PropTypes.array,
};
