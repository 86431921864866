import React from 'react';


const styles = {
  visibilityButton: {
    lineHeight: '20px',
    border: '1px solid #c9c9c9',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 25px',
    borderRadius: '3px',
    margin: '10px 0',
    cursor: 'pointer',
  },
  visibilityButtonSelected: {
    lineHeight: '20px',
    border: '1px solid #008751',
    background: '#008751',
    color: '#ffffff',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 25px',
    borderRadius: '3px',
    margin: '10px 0',
    cursor: 'pointer',
  },
  radio: {
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    border: '1px solid #8b8b8b'
  },
  radioSelected: {
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    border: '2px solid #ffffff',
  },
};

const VisibilityButton = (props) => {
  const {
    visibilityButton, visibilityButtonSelected, radio, radioSelected,
  } = styles;
  const {
    data, selected, setVisibility, text,
  } = props;

  return (
    <div
      style={selected === data ? visibilityButtonSelected : visibilityButton}
         onClick={() => setVisibility(data)}>
      <div style={{fontSize: '14px'}}>
        {text}
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span style={selected === data ? radioSelected : radio}/>
      </div>
    </div>
  );
};

export {VisibilityButton}