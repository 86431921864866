import React from "react";
import {Box, Stack} from "@mui/material";
import {styles} from "../../MetricsDetail/MetricsDetailsStyles";
import {PageTitle} from "../../MetricsDetail/PageTitle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DateRangeForm from "../../MetricsDetail/Forms/DateRangeForm";
import TuneIcon from "@mui/icons-material/Tune";
import {MetricsDetailFilter} from "../../MetricsDetail/MetricsDetailFilter";
import {Button} from "react-bootstrap";
import {pageFilterToDropdownMapping} from "../../MetricsDetail/MetricsDetailUtils";
import {CampaignNamesDropdown} from "../CampaignNamesDropdown";

export const HeaderFormFields = ({
                                     isFilterDropdownOpen,
                                     setIsFilterDropdownOpen,
                                     isCampaignNameDropdownOpen,
                                     setIsCampaignNameDropdownOpen,
                                     selectedCriteria,
                                     setSelectedCriteria,
                                     filterRef,
                                     filterPageRef,
                                     handleExport,
                                     pageFilter,
                                     selectedDropdownOption,
                                     onSelectChangeHandler,
                                     onFilterFormSubmit,
                                     handleFilterReset,
                                     dateRange,
                                     setDateRange,
                                     extractedCampaignList,
                                     setLiked,
                                     setReplied,
                                     setNoEngagement,
                                     setFollowed,
                                     setConverted,
                                     setFollowUpSent,
                                     setConvertedAfterFollowUpSent
                                 }) => {
    const filterDropdownComponent = pageFilterToDropdownMapping({
        selectedCriteria,
        setSelectedCriteria,
        setLiked,
        setReplied,
        setNoEngagement,
        setFollowed,
        setConverted,
        setFollowUpSent,
        setConvertedAfterFollowUpSent
    })[pageFilter];

    return (
        <Stack direction={{xs: 'column', md: 'row'}} sx={styles.hero} spacing={1}>
            <PageTitle pageFilter={pageFilter} />

            <Stack
                direction={{xs: 'column', md: 'row'}}
                sx={{width: '70%', justifyContent: 'flex-end'}}
            >
                <Box style={{ position: 'relative' }} ref={filterPageRef}>
                    <button
                        className="pageSelectionFilter"
                        type="button"
                        style={{ overflow: 'hidden', textWrap: 'wrap'}}
                        onClick={() => setIsCampaignNameDropdownOpen(!isCampaignNameDropdownOpen)}>
                        {selectedDropdownOption?.name}
                        <ArrowDropDownIcon sx={styles.arrowDownIcon}/>
                    </button>
                    {isCampaignNameDropdownOpen &&
                        <CampaignNamesDropdown
                            selectedDropdownOption={selectedDropdownOption}
                            onSelectChangeHandler={onSelectChangeHandler}
                            dropdownOptions={extractedCampaignList}
                        />
                    }
                </Box>

                <Box sx={{width: {xs: '100%', sm: 'auto'}, marginRight: '12px'}}>
                    <DateRangeForm
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        setDates={setDateRange}
                    />
                </Box>
                <div ref={filterRef} className="position-relative">
                    <button
                        className="metricsDetailsFilter"
                        type="button"
                        onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}>
                        <TuneIcon sx={styles.tuneIcon}
                        />
                        Filters
                        <ArrowDropDownIcon sx={styles.arrowDownIcon}/>
                    </button>
                    {isFilterDropdownOpen && <MetricsDetailFilter
                        filterDropDown={filterDropdownComponent}
                        onFilterFormSubmit={onFilterFormSubmit}
                        handleFilterReset={handleFilterReset}
                    />}
                </div>
                <Box>
                    <Button
                        onClick={handleExport}
                        className="btn btn-success"
                        type="submit"
                        style={{
                            width: '120px',
                            height: '40px',
                            background: '#144042',
                            borderRadius: '60px',
                            fontSize: '14px'
                        }}>
                        Export
                    </Button>
                </Box>
            </Stack>
        </Stack>
    )
}