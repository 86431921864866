import React, {useMemo, Fragment} from 'react';
import { useDispatch } from 'react-redux';
import {isEmpty} from "lodash";
import { Field, reduxForm } from 'redux-form';
import { Box } from '@mui/material';
import { tiktokOauthActions } from '_actions';
import TextButton from 'components/v2/views/Channels/ListChannels/TextButton';
import Input from 'components/v2/views/Channels/ConnectChannels/Utilities/Input';
import SetupModalBottomBar from 'components/v2/views/Channels/ConnectChannels/Utilities/SetupModalBottomBar';

// From Onboarding
import BasicModal from 'components/v2/views/Onboarding/Utilities/BasicModal';
import BasicSelect from 'components/v2/views/Onboarding/Utilities/BasicSelect';

function TikTokAddKeywordModal({
  handleSubmit, // from redux-form
  msgDataSources
}) {
  const dispatch = useDispatch()
	const [open, setOpen] = React.useState(false)

  const msgDataSourcesOptions = useMemo(() => {
    return msgDataSources.map(msgDataSource => ({
      id: msgDataSource.id,
      name: msgDataSource.name,
    }))
  }, [msgDataSources])

  // Input state (not controlled)
  const [_selectValue, setSelectValue] = React.useState('')
  const [_inputValue, setInputValue] = React.useState('')
  const disabled = useMemo(() => {
    return isEmpty(_inputValue) || isEmpty(_selectValue);
  }, [_inputValue, _selectValue])

  const onSubmit = (props) => {
    const params = { id: props.msgDataSourceId, searchKeyword: props.keyword }
    dispatch(tiktokOauthActions.updateSearchKeyword(params))
    setOpen(false)
  }

  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        handleOpen={() => handleOpen(true)}
        title={'Add TikTok Search Keywords'}>

        <Box sx={{ marginTop: '10px' }}>
          <form name={'tiktokAddKeywordForm'} onSubmit={handleSubmit(onSubmit)}>
            <Field
              component={BasicSelect}
              name={'msgDataSourceId'}
              id='tiktokMsgDataSourceDropdown'
              options={msgDataSourcesOptions}
              label='Channel'
              onChange={setSelectValue}
              meta={{}}
            />

            <Field
              component={Input}
              name={'keyword'}
              id='tiktokKeywordField'
              placeholder='Keyword'
              performAction={setInputValue}
            />
        
            <SetupModalBottomBar
              openInNewTab
              disabled={disabled}
              buttonLabel={'Save'}
            />
          </form>
        </Box>
      </BasicModal>

			<TextButton
				onClick={() => setOpen(true)}
				label={'Add Search Keywords'}
			/>
    </Fragment>
  )
}

const formConfig = {
  form: 'tiktokAddKeywordModalForm',
};

export default reduxForm(formConfig)(TikTokAddKeywordModal);