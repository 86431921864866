import React from 'react';
import { styles } from "./styles";
import { Image } from "react-bootstrap";

/**
 * @author: Chris Okebata
 */

export const RowCheckbox = (props) => {

    const { pageList, imageContainer, image, labelsContainer, checkboxHolder} = styles;
    const { input, pageId, pageType, label, type, value, disabled, disabledText, disabledStyle } = props;


    return (
        <li style={{...pageList, ...disabledStyle}}>
            <div style={{display:"flex"}}>
                <div style={imageContainer}>
                    <Image responsive src={"http://graph.facebook.com/" + pageId + "/picture?type=square"} style={image}/>
                </div>
                <div style={labelsContainer}>
                    <div>
                        <span className="font-14-heavy color-black-fade-mid" style={{lineHeight:"22px"}}>
                            {label} {disabledText}
                        </span>
                    </div>
                    <div>
                        <span className="font-14 color-grey-mid"  style={{lineHeight:"23px"}}>
                            {pageType}
                        </span>
                    </div>
                </div>
            </div>

            <div style={checkboxHolder}>
                <label className="checkbox-container">
                    <input
                        {...input}
                        type={type}
                        onChange={input.onChange}
                        name={input.name}
                        id={input.name}
                        disabled={disabled}
                        value={value}
                    />
                    <span className="checkbox-checkmark" />
                </label>
            </div>
        </li>
    );
};
