import { useState, useMemo } from 'react'
import { Box, Grid, Divider, useTheme, useMediaQuery } from '@mui/material';
import { Chart } from 'react-google-charts';
import useLocationQueryParams from 'components/v2/hooks/useLocationQueryParams';
import importedStyles from '../Utilities/styles'
import DashboardCard from '../Utilities/DashboardCard';
import DashboardTable, { DashboardTableRow, DashboardTableCell } from '../Utilities/DashboardTable';
import ListMenu from '../Utilities/ListMenu';
import {capitalize, toLowerCase} from '_helpers';
import useChannelsMetrics from "../Utilities/hooks/useChannelsMetrics";
import { getSubChannels } from "../Utilities/helpers/metrics.helper";

export default function TicketVolumeSection({ startDate, endDate }) {
	const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const { _originalData, loading } = useChannelsMetrics(startDate, endDate);
	const { params, setParams } = useLocationQueryParams()

	// Filter params
	const [ticketVolumeChannel, _setTicketVolumeChannel] = useState(() => {
		return (params && typeof params.ticketVolumeChannel === 'string' ? params.ticketVolumeChannel : null)
	})
	const setTicketVolumeChannel = (newVal) => {
		newVal = newVal && newVal.length ? newVal.toLowerCase() : null
		setParams({ ticketVolumeChannel: newVal })
		_setTicketVolumeChannel(newVal)
	}

	const hasData = useMemo(()=>{
		return (!loading && _originalData.length > 0);
	}, [_originalData])
	
	// Data
	const data = useMemo(() => {
		if (!_originalData) return null
		const filteredData = _originalData.filter(row => {
			if (!ticketVolumeChannel || !ticketVolumeChannel.length)
				return true
			return (row.channel && row.channel.length ? row.channel.toLowerCase() : null) === ticketVolumeChannel
		})
		return filteredData.length ? getSubChannels(filteredData) : _originalData
	}, [_originalData, ticketVolumeChannel])

	const dataVolumeByUsSum = useMemo(() => {
		if (!data) return 0
		return data.reduce((acc, row) => {
			return acc + (row.volumeByUs ? row.volumeByUs : 0)
		}, 0)
	}, [data])

	const dataVolumeAllSum = useMemo(() => {
		if (!data) return 0
		return data.reduce((acc, row) => {
			return acc + (row.volumeAll ? row.volumeAll : 0)
		}, 0)
	}, [data])

	const dataForChart = useMemo(() => {
		if (!data || !data.length) return [['', ''], [' ', 0]];
		return [
			['', 'Ticket Volume'],
			...data.map(row => [row.label, row.volumeByUs]),
		]
	}, [data])

	const filterMenuOptions = useMemo(() => {
		if (!_originalData) return []
		return [
			{
				label: 'All channels',
				value: null,
			},
			..._originalData.map(row => {
				return {
					label: row.label,
					value: row.channel,
				}
			}),
		]
	}, [_originalData])

	const ticketVolumeChannelName = useMemo(() => {
		let channel = (typeof ticketVolumeChannel === 'string' 
			? capitalize(ticketVolumeChannel)
			: 'All channels')
		return channel
	}, [ticketVolumeChannel])

	const chartOptions = {
		width: '100%',
		height: 300,
		colors: [ '#DBD8FF' ],
		fontSize: 13,
		fontName: 'LarsseitRegular',
		bars: 'vertical',
		hAxis: { format: 'decimal' },
		legend: { position: 'none' }
	};

	return (
		<DashboardCard
			isLoading={loading}
			headerTitle='Ticket metrics'
			headerChildren={hasData && (
				<ListMenu
					label={'Channel'}
					id={'ticket-volume-channel-filter'}
					options={filterMenuOptions}
					selectedValue={ticketVolumeChannel}
					onChange={setTicketVolumeChannel}
				/>
			)}>

			<Divider orientation={'vertical'} flexItem sx={styles.sectionDivider} />

			{
				!loading &&
				<Grid container columns={2} spacing={8}>
					{/* Chart – desktop only */}
					{ !isMobile ? (
						<Grid item sm={2} md={1}>
							<Box sx={styles.section}>
								<Box component={'h2'} sx={importedStyles.headingSmall}>
									{ticketVolumeChannelName}
								</Box>

								<Chart
									chartType='Bar'
									data={dataForChart}
									options={chartOptions}
								/>
							</Box>
						</Grid>
					) : null }

					{/* Table */}
					<Grid item sm={2} md={1} sx={styles.gridItemForTable}>
						<Box sx={styles.section}>
							{ !isMobile ? (
								<Box component={'h2'} sx={importedStyles.headingSmall}>
									Ticket volume details for {toLowerCase(ticketVolumeChannelName)}
								</Box>
							) : null }

							<DashboardTable
								label={`Ticket volume details for ${toLowerCase(ticketVolumeChannelName)}`}
								// @TODO: Enable section when feature is ready
								// headLabels={['Source', 'Chatdesk', 'Channel total']}
								headLabels={['Source', 'Chatdesk']}
								sx={{ maxWidth: 360, }}>

								{data && data.length ? data.map(entry => (
									<DashboardTableRow key={entry.channel}>
										<DashboardTableCell>
											{entry.label}
										</DashboardTableCell>
										<DashboardTableCell align='right'>
											{entry.volumeByUs}
										</DashboardTableCell>
										{/*@TODO: Enable section when feature is ready*/}
										{/*<DashboardTableCell align='right'>*/}
										{/*	{entry.volumeAll}*/}
										{/*</DashboardTableCell>*/}
									</DashboardTableRow>
								)) : null}

								<DashboardTableRow >
									<DashboardTableCell sx={{ backgroundColor: '#F8F9F9' }}>
										All channel volume
									</DashboardTableCell>
									<DashboardTableCell sx={{ backgroundColor: '#F8F9F9' }} align='right'>
										{dataVolumeByUsSum}
									</DashboardTableCell>
									{/*@TODO: Enable section when feature is ready*/}
									{/*<DashboardTableCell sx={{ backgroundColor: '#F8F9F9' }} align='right'>*/}
									{/*	{dataVolumeAllSum}*/}
									{/*</DashboardTableCell>*/}
								</DashboardTableRow>
							</DashboardTable>
						</Box>
					</Grid>
				</Grid>
			}
		</DashboardCard>
	)
}

const styles = {
	gridItemForTable: {
		width: '100%',
	},
	section: {
		py: { xs: 2, md: 3 },
	},
	sectionDivider: {
		display: { xs: 'none', md: 'block' },
		position: 'absolute',
		height: '100%',
		left: '50%',
	}
}