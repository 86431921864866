import {SERVER_URL} from 'config';
import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';

function verifyApiKey(params) {
    return fetch(`${SERVER_URL}/api/freshdesk/apiKey?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function getAllFreshdeskDataSource() {
    return fetch(`${SERVER_URL}/api/freshdesk/allDataSources`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

export const freshdeskAuthService = {
    verifyApiKey,
    getAllFreshdeskDataSource
};