import useGetInternalNoteAssignee from "../../../../Utilities/hooks/useGetInternalNoteAssignees";
import BasicFilterSelect from "../../../../../../common/BasicFilterSelect";

export default function InternalNoteFilterSelect({message, onChange}) {
    const {internalNoteAssignees} = useGetInternalNoteAssignee(message);

    return(
        <BasicFilterSelect
            placeholder={'Select assignee (optional)'}
            options={internalNoteAssignees}
            onChange={onChange}
        />
    )
}