import {stripeOauthService} from '_services';
import {oauthConstants, alertMessages} from '_constants';
import {SnackBar} from "../components/common";

function initiateOauth() {
    function request() {
        return {type: oauthConstants.STRIPE_OAUTH_URL_REQUEST};
    }

    function success(oauthUrl) {
        return {type: oauthConstants.STRIPE_OAUTH_URL_SUCCESS, payload: oauthUrl};
    }

    function failure(error) {
        return {type: oauthConstants.STRIPE_OAUTH_URL_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        stripeOauthService.getOauthUrl()
            .then(
                oauthUrl => dispatch(success(oauthUrl)),
                (error) => {
                    dispatch(failure());
                },
            );
    };
}

function processOauthCode(params) {
    function request() {
        return {type: oauthConstants.STRIPE_OAUTH_CODE_REQUEST};
    }

    function success(res) {
        return {type: oauthConstants.STRIPE_OAUTH_CODE_SUCCESS, payload: res};
    }

    function failure(error) {
        return {type: oauthConstants.STRIPE_OAUTH_CODE_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        stripeOauthService.processOauthCode(params)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.STRIPE_OAUTH_SUCCESS);
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.STRIPE_OAUTH_FAILURE);
                },
            );
    };
}

function stripeAccountStatus(params){
    function request() {
        return {type: oauthConstants.STRIPE_ACCOUNT_STATUS_REQUEST};
    }

    function success(res) {
        return {type: oauthConstants.STRIPE_ACCOUNT_STATUS_SUCCESS, payload: res};
    }

    function failure(error) {
        return {type: oauthConstants.STRIPE_ACCOUNT_STATUS_FAILURE, error};
    }

    return (dispatch) => {
        dispatch(request());

        stripeOauthService.stripeAccountStatus(params)
            .then(
                response => dispatch(success(response)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };
}

function stripeOnboardingLink(){
    function request() {
        return {type: oauthConstants.STRIPE_ONBOARDING_LINK_REQUEST};
    }

    function success(res) {
        return {type: oauthConstants.STRIPE_ONBOARDING_LINK_SUCCESS, payload: res};
    }

    function failure(error) {
        return {type: oauthConstants.STRIPE_ONBOARDING_LINK_FAILURE, error};
    }

    return (dispatch) => {
        dispatch(request());

        stripeOauthService.stripeOnboardingLink()
            .then(
                response => {
                    dispatch(success(response))
                    window.open(response.url, '_blank', 'noopener,noreferrer');

                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };
}

function stripeTokenExchange(authCode){
    function request() {
        return {type: oauthConstants.STRIPE_TOKEN_EXCHANGE_REQUEST};
    }

    function success(res) {
        return {type: oauthConstants.STRIPE_TOKEN_EXCHANGE_SUCCESS, payload: res};
    }

    function failure(error) {
        return {type: oauthConstants.STRIPE_TOKEN_EXCHANGE_FAILURE, error};
    }

    return (dispatch) => {
        dispatch(request());

        stripeOauthService.stripeTokenExchange(authCode)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.STRIPE_TOKEN_EXCHANGE_SUCCESS);
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.STRIPE_TOKEN_EXCHANGE_FAILURE);
                },
            );
    };

}

export const stripeOauthActions = {
    initiateOauth,
    processOauthCode,
    stripeAccountStatus,
    stripeOnboardingLink,
    stripeTokenExchange
};