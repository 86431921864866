import React from 'react'
import GenericContextMenu from 'components/common/NavContextMenu/GenericContextMenu'
import settingsIcon from 'components/images/baseline-settings-20px.svg';
import {dataSourceHandleConstants} from "_constants";

/**
 * @author Chris Okebata
 */

let position = {position:"absolute", top:"-62px", right:"30px"};
const ShowHandleState = ({onClick, status, handle}) => {

    const payload = {dispatchStatus: status.value, id: handle.id};
    return (
        <li onClick={() => onClick(payload)}>
            {status.displayName}
        </li>
    )
};

const datasourceStatusContextMenu = (props) => {

    const { onClick, handle} = props;
    return (
        <GenericContextMenu trianglePosition="right" contextPosition={position}>
            <div key="trigger" className="tms-cursor-pointer">
                <img src={settingsIcon} alt={''}/>
            </div>

            <ul key="body">
                {dataSourceHandleConstants.HANDLE_TOGGLE_STATES.map(handleStatus => {
                    return (
                        <ShowHandleState
                            onClick={onClick}
                            status={handleStatus}
                            handle={handle}
                            key={handleStatus.id}
                        />
                    )
                })}
            </ul>
        </GenericContextMenu>
    );
};

export default datasourceStatusContextMenu