import { Fragment } from 'react'
import { Box } from '@mui/material';
import BasicButton from "components/v2/common/BasicButton";
import BasicModal from "components/v2/views/Onboarding/Utilities/BasicModal";
import basicModalStyles from "components/v2/views/Channels/ConnectChannels/Utilities/basicModalStyles";
import { buttonContained } from "components/v2/views/Onboarding/Utilities/ButtonContained";

export function CrossDispatchWarningModal({handleClose, open}) {
    const {SignupButton} = buttonContained;

	return (
		<Fragment>
			<BasicModal
				open={open}
				handleClose={handleClose}
				title={'Possible Cross-Dispatch Detected'}>

				<Box sx={basicModalStyles.description}>
					<p>Your message may be intended for another conversation.</p>
					<p>Please <strong>review</strong> the message.</p>
				</Box>

				<Box sx={basicModalStyles.buttonContainer}>
					<Box />
					<Box sx={basicModalStyles.buttonRight}>
						<SignupButton label={'OK'} action={handleClose} />
					</Box>
				</Box>
			</BasicModal>
    </Fragment>
	)
}
