import React, {Component, Fragment} from 'react';
import {OrganizationScore, SnackBar} from "components/common/index";
import connect from "react-redux/es/connect/connect";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {filterObjectValue, getCapitalizedWords, getCurrentUser, internalAccounts, isObjectEmpty} from "_helpers";
import _ from "lodash";


/**
 * @author: Chris Okebata
 */

class AgentOrganization extends Component {

  statusTag = (isScheduled, messageCount) => {
    const showMessageCount = _.isNumber(messageCount) && messageCount > 0;

    return(
      <Fragment>
        {isScheduled ? (showMessageCount && this.statusLabel(messageCount)) : this.statusLabel('Out of schedule')}
      </Fragment>
    )
  };

  statusLabel = (label) => {
    return(
      <div style={{display: 'flex', alignItems: 'center',}}>
        <div className={_.isNumber(label) ? `background-persian-red color-white`:`background-grey-mid color-white`}
             style={{fontSize: '14px', borderRadius: '25px'}}>
          <div style={{margin: '2px 8px 2px 8px'}}>
            {label}
          </div>
        </div>
      </div>
    )
  };

  renderRow = (employment) => {
    const { handleSubmit, loadingScheduledOrgs, scheduledOrganizations, isHired } = this.props;
    const { id, organization } = employment;
    const messageCount = _.get(employment, 'messageCount');
    const organizationId = _.get(organization, 'id');
    const organizationName = _.get(organization, 'name');
    const userId = getCurrentUser().id;
    let showStatusTag = isHired && !internalAccounts.includes(userId);
    let scheduledObj, isScheduled;
    scheduledObj = filterObjectValue(scheduledOrganizations || [], 'id', organizationId);
    isScheduled = (!loadingScheduledOrgs && !isObjectEmpty(scheduledObj));
    const showScheduleInfo = isHired && !isScheduled;
    
    return (
      <Fragment key={id}>
        <button onClick={() => (showScheduleInfo) ?
          SnackBar.showMessage(`${getCapitalizedWords(organizationName || 'Company') } is not scheduled`) :
          handleSubmit(organizationId, scheduledObj)}
                className="font-16-bold tms-container-shadow tms-agent-employment-button">
          <div className={`tms-ellipsis color-grey-dark`} style={{marginRight: '30px'}}>
            { getCapitalizedWords(organizationName || '') }
          </div>
          <div className="tms-ellipsis" style={{display: 'flex', flexDirection: 'row'}}>
            { showStatusTag && this.statusTag(isScheduled, messageCount)}
            <div style={{width: '55px', display: 'flex', alignItems: 'center', margin: '0 2px 0 15px'}}>
              <OrganizationScore organizationId={organizationId}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <ArrowForwardIosIcon className="color-green" fontSize={'small'}/>
            </div>
          </div>
        </button>
      </Fragment>
    )
  };

  render() {
    const { employmentDesc, employmentList } = this.props;

    return(
      <Fragment>
        <div style={{margin: '12px 0', width: '100%'}}>
          <div className="font-17 color-grey-dark" style={{marginBottom: '10px'}}>
            {employmentDesc}
          </div>
          {employmentList.map(this.renderRow)}
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {schedules: {scheduledOrganizations, loadingScheduledOrgs}} = state;

  return {
    loadingScheduledOrgs,
    scheduledOrganizations: scheduledOrganizations.scheduledOrganizations,
  };
}

const connectedAgentOrganization = connect(mapStateToProps)(AgentOrganization);
export {connectedAgentOrganization as AgentOrganization}