import {authHeader, contentTypeHeader, handleHttpResponse, objectToQueryString, fileUploadHeader} from '_helpers';
import {SERVER_URL} from 'config';
import axios from "axios";

function getAll(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/users?${objectToQueryString(params)}`, requestOptions).then(handleHttpResponse);
}

function getUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/users/${id}`, requestOptions).then(handleHttpResponse);
}

function getExpertRecentActivity (orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch (`${SERVER_URL}/api/users/experts?${objectToQueryString(orgId)}`, requestOptions).then(handleHttpResponse);

}

function getAdminPerformance (data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch (`${SERVER_URL}/api/users/adminPerformance?${objectToQueryString(data)}`, requestOptions).then(handleHttpResponse);

}

function getActiveExperts (data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch (`${SERVER_URL}/api/users/experts/active`, requestOptions).then(handleHttpResponse);

}

function getInActiveExperts (data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch (`${SERVER_URL}/api/users/experts/inActive`, requestOptions).then(handleHttpResponse);
}

function getPracticingExperts (orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch (`${SERVER_URL}/api/users/experts/practicing?${objectToQueryString(orgId)}`, requestOptions).then(handleHttpResponse);

}

function getQualityScores (data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch (`${SERVER_URL}/api/users/experts/scores?${objectToQueryString(data)}`, requestOptions).then(handleHttpResponse);
}

function getQualityReviews (data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch (`${SERVER_URL}/api/users/experts/reviews?${objectToQueryString(data)}`, requestOptions).then(handleHttpResponse);
}

function getCurrentUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/users/${id}`, requestOptions).then(handleGetUserResponse);
}

function updateUser(user) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch(`${SERVER_URL}/api/users/${user.id}`, requestOptions).then(handleHttpResponse);
}

function adminUpdateUserPassword(userId, requestBody) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(requestBody)
    };

    return fetch(`${SERVER_URL}/api/users/${userId}/password`, requestOptions).then(handleHttpResponse);
}

function createUser(user) {
    const userWithFrontURL = {...user, frontEndBaseURL: window.location.origin.toString()}
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(userWithFrontURL)
    };

    // this invokes the save method in the user controller
    return fetch(`${SERVER_URL}/api/users`, requestOptions).then(handleHttpResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch('/users/' + id, requestOptions).then(handleHttpResponse);
}

function getAgentMessages(agentId, params) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/users/${agentId}/messages?${objectToQueryString(params)}`, requestOptions).then(handleHttpResponse);
}

function getAgentMessageHistory(agentId, params) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/users/${agentId}/messageHistory?${objectToQueryString(params)}`, requestOptions).then(handleHttpResponse);
}

function searchUser(param) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    const url = `${SERVER_URL}/api/users/search?username=${param.username}`
    return fetch(`${url}`, requestOptions).then(handleHttpResponse);
}




function updatePassword(userId, params) {

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(`${SERVER_URL}/api/users/update-password`, requestOptions).then(handleHttpResponse);
}

function updateUserPhoto(id, uploadType, formData) {
    const requestOptions = {
        method: 'POST',
        headers: fileUploadHeader(),
        body: formData
    };
    return fetch(`${SERVER_URL}/api/${uploadType}/${id}/upload`, requestOptions).then(handleHttpResponse)
}

/**
 * Updating te user/organization details in localstorage just in case user previously didn't have a profilePicture/logo
 * @param String uploadType
 * @param Object user
 * @returns {*}
 */
function updateCurrentStoredUser(uploadType, user) {
    if (user) {
        const userInMemory = JSON.parse(localStorage.user);
        let updatedUser = {};
        if (uploadType === 'users') {
            updatedUser = {
                ...userInMemory, profilePicture: user.profilePicture
            }
        } else {
            updatedUser = {
                ...userInMemory, _embedded: {
                    ...userInMemory._embedded, organization: {
                        ...userInMemory._embedded.organization, logo: user.logo
                    }
                }
            }
        }
        // store user details in local storage to keep user data
        localStorage.setItem('user', JSON.stringify(updatedUser));
    }
}

function deleteUserPhoto(userId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${SERVER_URL}/api/users/${userId}/delete-photo`, requestOptions).then(handleHttpResponse)
}

function confirmUserAccount(queryString) {
    const requestOptions = {
        method: 'GET',
        headers: contentTypeHeader(),
    };

    return fetch(`${SERVER_URL}/api/users/confirm_user${queryString}`, requestOptions).then(handleHttpResponse)
}

/**
 *
 * @param response
 * @returns {*}
 */
function handleGetUserResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json().then((user) => {
        if (user) {
            const userInMemory = JSON.parse(localStorage.user);
            const updatedUser = {...userInMemory, ...user};

            // store user details in local storage to keep user data
            localStorage.setItem('user', JSON.stringify(updatedUser));
        }

        return user;
    });
}

const getChatdeskUser = () => {
    const endpoint = `${SERVER_URL}/api/users/chatdesk`;

    return axios.get(endpoint, {
        headers: authHeader()
    });
};

const markInboxMsgAsDone = (messageId, userId) => {
    const endpoint = `${SERVER_URL}/api/userMessageActions/markAsDone`;

    return axios.post(endpoint, { messageId, userId },{
        headers: authHeader()
    });
};

function getMessagesForAgent(agentId, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/users/${agentId}/agentMessages?${objectToQueryString(params)}`, requestOptions).then(handleHttpResponse);
}

function getLessActiveAgents(params){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${SERVER_URL}/api/users/getLessActiveAgents?${objectToQueryString(params)}`, requestOptions).then(handleHttpResponse)
}

export const userService = {
    adminUpdateUserPassword,
    getAll,
    getUser,
    getCurrentUser,
    updatePassword,
    updateUser,
    createUser,
    getAgentMessages,
    getAgentMessageHistory,
    delete: _delete,
    updateUserPhoto,
    updateCurrentStoredUser,
    deleteUserPhoto,
    confirmUserAccount,
    getExpertRecentActivity,
    getPracticingExperts,
    getInActiveExperts,
    getActiveExperts,
    searchUser,
    getChatdeskUser,
    getMessagesForAgent,
    getAdminPerformance,
    markInboxMsgAsDone,
    getLessActiveAgents,
    getQualityScores,
    getQualityReviews
};


