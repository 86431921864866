import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {proactiveActions} from "_actions";
import useLocationQueryParams from "../../../hooks/useLocationQueryParams";
import BasicTable from "../../../common/BasicTable";
import useGetCompanyTemplates from "./hooks/useGetCompanyTemplates";
import {Box} from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "../Utilities/templateStyles";

export default function TemplatesTable() {
    const { params, setParams } = useLocationQueryParams();
    const offsetBase = 10;
    const [page, setPage] = useState(() => {
        const offset = Number(params.offset);
        return offset ? (offset / offsetBase) : 0;
    });

    const dispatch = useDispatch();
    const { organization_id = '' } = JSON.parse(localStorage.user);
    const payload = {
        organizationId: organization_id,
        offset: page * offsetBase
    }
    const { rows, columns, totalCount, loading } = useGetCompanyTemplates(payload);

    const handlePageChange = (event, nextPage) => {
        setPage(nextPage);
        const queryParams = {...payload, offset: nextPage * offsetBase}
        setParams({...params, ...queryParams});
        dispatch(proactiveActions.getCompanyTemplates(queryParams));
    };

    if(loading && _.isEmpty(rows)) {
        return(
            <Box sx={styles.emptyContainer}>
                <CircularProgress size={25} sx={{ color: '#07857C'}}/>
            </Box>
        )
    }

    return(
        <BasicTable
            rows={rows}
            columns={columns}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
            page={page}
            isPaginationDisabled={loading}
        />
    )
}