import {Box} from "@mui/system";
import {Radio, FormControlLabel} from "@mui/material";

export default function RadioButtonLabel({name, checked, value, label, onChange, disabled}) {
    return(
        <Box>
            <FormControlLabel
                name={name}
                checked={checked}
                value={value}
                sx={style.formControlLabel}
                control={<Radio/>}
                label={label}
                disabled={disabled}
                onChange={onChange}
            />
        </Box>
    )
}

const style = {
    formControlLabel: {
        ml: '-8px',
        '& .MuiSvgIcon-root': {
            fontSize: '22px',
            color: '#144042',
        },
        '& .MuiButtonBase-root': {
            padding: '5px'
        },
        '& .MuiFormControlLabel-label': {
            fontFamily: 'LarsseitRegular',
            fontSize: '16px',
            color: '#0C2728',
            letterSpacing: '-0.01em',
            // whiteSpace: 'nowrap',
        },
        "& .MuiTouchRipple-child": {
            backgroundColor: '#144042',
        },
    },
}