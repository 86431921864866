import React from 'react';
import MessageUnavailableModal from "./MessageUnavailableModal";
import { useSelector } from "react-redux";
import _ from "lodash";
import {messageEventType} from "_constants";
import {getCurrentUser, refreshPage} from "_helpers";

export default function WebsocketMessage({ message }) {
  const websocket = useSelector((state) => state.websocket);
  let isCollidedMessage = false;
  let isMessageWorkedOn = false;
  let isAgentLocked = false;
  let isSameMessage = false;
  let isAutoActioned = false;
  const websocketData = _.get(websocket, 'voteMessage');
  const eventType = _.get(websocketData, 'message.messageEventType');
  const lockedBy = _.get(websocketData, 'message.lockedBy');
  const { id } = getCurrentUser();
  const isSameUser = id === lockedBy;

  if(!_.isEmpty(websocketData)){
    isSameMessage = _.get(websocketData, 'message.id') === _.get(message, 'id');
    isCollidedMessage = isSameMessage && !isSameUser;
    isMessageWorkedOn = isCollidedMessage && eventType === messageEventType.MESSAGE_WORKED_ON;
    isAgentLocked = isCollidedMessage && eventType === messageEventType.AGENT_LOCK;
    isAutoActioned = isSameMessage && eventType === messageEventType.ACTIONED
  }

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return false;
    if (reason === "escapeKeyDown") return false;
    refreshPage();
  };

  return(
    <MessageUnavailableModal
      open={isMessageWorkedOn || isAgentLocked || isAutoActioned}
      handleClose={handleClose}
      message={"This message is no longer available to work on"} />
  )
}