export const voteConstants = {
    SUBMIT_VOTE_REQUEST: 'SUBMIT_VOTE_REQUEST',
    SUBMIT_VOTE_SUCCESS: 'SUBMIT_VOTE_SUCCESS',
    SUBMIT_VOTE_FAILURE: 'SUBMIT_VOTE_FAILURE',

    GET_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

    SUBMIT_AND_HIDE_REQUEST: 'SUBMIT_AND_HIDE_REQUEST',
    SUBMIT_AND_HIDE_SUCCESS: 'SUBMIT_AND_HIDE_SUCCESS',
    SUBMIT_AND_HIDE_FAILURE: 'SUBMIT_AND_HIDE_FAILURE',
};

export const votingOptions = {
    APPROVE: 'APPROVE',
    SEND_FOR_REVIEW: 'SEND_FOR_REVIEW',
    REJECT: 'REJECT'
};

export const navigationType = {
    PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
    AGENT_SELECTION: 'AGENT_SELECTION',
};

export const clientType = {
    WEB_APP: 'WEB_APP',
};

export const responseType = {
    editedText: 'EDITED_TEXT',
    editedHtml: 'EDITED_HTML',
    singleApprove: 'SINGLE_APPROVE',
    multiApprove: 'MULTI_APPROVE',
};

export const handleType = {
    chat: 'CHAT',
    email: 'EMAIL',
    social_feed: 'SOCIAL_FEED',
    social_feed_with_links_and_handle_mentions: 'SOCIAL_FEED_WITH_LINKS_AND_HANDLE_MENTIONS',
    social_feed_with_handle_mentions_but_no_links: 'SOCIAL_FEED_WITH_HANDLE_MENTIONS_BUT_NO_LINKS',
    social_feed_with_handle_mentions_but_no_username: 'SOCIAL_FEED_WITH_HANDLE_MENTIONS_BUT_NO_USERNAME',
    yotpo_reviews: 'YOTPO_REVIEWS',
    stamped: 'STAMPED',
    proactive_email: 'PROACTIVE_EMAIL',
};

export const responseStatus = {
    JUST_ADDED: 'Just added',
    EDITED: 'Edited',
};

export const messageEventType = {
    AGENT_LOCK: 'AGENT_LOCK',
    MESSAGE_WORKED_ON: 'MESSAGE_WORKED_ON',
    ACTIONED: 'ACTIONED'
};