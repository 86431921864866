import {Box} from "@mui/material";
import styles from "../Utilities/campaignStyles";
import CreateCampaignForm from "./CreateCampaignForm";
import React from "react";

export default function AdminCreateCampaign() {
    return (
        <>
            <Box sx={styles.root}>
                <Box sx={styles.container}>
                    <Box sx={styles.containerBody}>
                        <Box sx={styles.header}>
                            Create new campaign
                        </Box>
                        <CreateCampaignForm />
                    </Box>
                </Box>
            </Box>
        </>
    )
}