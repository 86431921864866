import React, {useState} from "react";
import {Box, Stack} from "@mui/material";
import importedStyles from "../../Proactive/Utilities/styles"
import useLocationQueryParams from "../../../hooks/useLocationQueryParams";
import {useDispatch} from "react-redux";
import {companyResourcesActions} from "_actions";
import AnchorButton from "../../../common/AnchorButton";
import useGetAllResources from "../Utilities/hooks/useGetAllResources";
import ResourcesTable from "../Utilities/ResourcesTables"

export default function ResourcesList() {
    const dispatch = useDispatch();
    const offsetBase = 10;
    const { params, setParams } = useLocationQueryParams();
    const [page, setPage] = useState(() => {
        const offset = Number(params.offset);
        return offset ? (offset / offsetBase) : 0;
    });

    const payload = {
        offset: page * offsetBase
    }

    const { rows, columns, totalCount, loading } = useGetAllResources(payload);

    const handlePageChange = (event, nextPage) => {
        setPage(nextPage);
        const queryParam = {...params, offset: nextPage * offsetBase}
        setParams({...params, ...queryParam});
        dispatch(companyResourcesActions.getAll(queryParam));
    };

    return(
        <>
            <Box sx={styles.root}>
                <Box sx={styles.container}>
                    <Stack direction={{ xs: 'column', md: 'row' }} sx={styles.hero} spacing={2}>
                        <Box component={'h1'} sx={importedStyles.headingLarge}>
                            Company Resources
                        </Box>
                        <Box sx={{ width: { xs: '100%', sm: 'auto' }, height: '40px' }}>
                            <AnchorButton label={'Create resource'} link={'/company-resources/create'}/>
                        </Box>
                    </Stack>

                    <Box>
                        <ResourcesTable
                            rows={rows}
                            columns={columns}
                            totalCount={totalCount}
                            page={page}
                            loading={loading}
                            handlePageChange={handlePageChange}
                            clickable={false}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const styles = {
    root: {
        ...importedStyles.root,
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },

    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
    },

    hero: {
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        mb: { xs: '24px', sm: '40px', },
    },
}