import React from "react";
import {autoActionActions} from "../../_actions";
import {connect} from 'react-redux'
import {HtmlTooltip, Loading} from "../common";
import {Link} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {isObjectEmpty} from "../../_helpers";
import {Typography} from "@material-ui/core";

class AutoAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoAction: {
                dataSourceHandleId: '',
                isActive: true,
                isSkipEnabled: false,
                isAutoReplyEnabled: false,
                isFirstResponseEnabled: false,
                topics: [],
                autoReplyText: [],
                firstResponseThreshold: 20,
                firstResponseFooter: ''
            },
            submitting: false,
            loading: true,
        };
    }

    componentDidMount() {
        const {match: {params: {dataSourceHandleId}}, dispatch} = this.props
        this.setState(prevState => ({
            autoAction: {
                ...prevState.autoAction,
                dataSourceHandleId: dataSourceHandleId,
            },
        }))
        dispatch(autoActionActions.getAutoAction(dataSourceHandleId))
    }

    componentDidUpdate() {
        const {loadingCreateAutoAction, autoAction, loadingAutoAction} = this.props
        const {submitting, loading} = this.state
        if (loadingCreateAutoAction !== submitting) {
            this.setState(state => ({
                ...state,
                submitting: loadingCreateAutoAction,
            }))
        }
        if (loadingAutoAction !== loading) {
            this.setState(state => ({
                ...state,
                autoAction: {
                    dataSourceHandleId: autoAction.dataSourceHandle.id,
                    isActive: autoAction.isActive,
                    isSkipEnabled: autoAction.isSkipEnabled || false,
                    isAutoReplyEnabled: autoAction.isAutoReplyEnabled || false,
                    topics: autoAction.topics || [],
                    autoReplyText: autoAction.autoReplyText || [],
                    isFirstResponseEnabled: autoAction.isFirstResponseEnabled || false,
                    firstResponseThreshold: autoAction.firstResponseThreshold,
                    firstResponseFooter: autoAction.firstResponseFooter || ''
                },
                loading: loadingAutoAction,
            }))
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const {autoAction} = this.state
        const {dispatch} = this.props
        dispatch(autoActionActions.createOrUpdateAutoAction(autoAction))
        this.setState({submitting: true})
    }

    handleIsActiveChange = (event) => {
        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                isActive: !this.state.autoAction.isActive
            }
        }))
    };

    handleIsSkipEnabledChange = (event) => {
        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                isSkipEnabled: !this.state.autoAction.isSkipEnabled
            }
        }))
    };

    handleIsAutoReplyEnabledChange = (event) => {
        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                isAutoReplyEnabled: !this.state.autoAction.isAutoReplyEnabled
            }
        }))
    };

    handleIsFirstResponseEnabledChange = (event) => {
        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                isFirstResponseEnabled: !this.state.autoAction.isFirstResponseEnabled
            }
        }))
    };

    handleTopicsChange = (event, index) => {
        const {topics} = this.state.autoAction;
        topics[index] = event.target.value;

        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                topics: topics
            }
        }))
    }

    handleFirstResponseThresholdChange = (event) => {
        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                firstResponseThreshold: event.target.value
            }
        }))
    }

    handleFirstResponseFooterChange = (event) => {
        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                firstResponseFooter: event.target.value
            }
        }))
    }

    addTopic = () => {
        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                topics: [...state.autoAction.topics, '']
            }
        }))
    }

    handleAutoReplyTextChange = (event, index) => {
        const {autoReplyText} = this.state.autoAction;
        autoReplyText[index] = event.target.value;

        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                autoReplyText: autoReplyText
            }
        }))
    }

    addAutoReplyText = () => {
        this.setState(state => ({
            autoAction: {
                ...state.autoAction,
                autoReplyText: [...state.autoAction.autoReplyText, '']
            }
        }))
    }

    render() {
        if (this.props.loadingAutoAction) {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '100px 0'}}>
                    <Loading/>
                </div>
            )
        }

        if (isObjectEmpty(this.props.dataSourceHandle)) {
            return (
                <div>
                    No data source handle
                </div>
            )
        }

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <div style={{width: '50%', background: '#fff', padding: '20px 40px'}}>
                    <Link to={`/dataSourceHandles`}
                          className="btn btn-sm"
                          style={{border: '1px solid #ededed', fontSize: '17px'}}>
                        <i className="fa fa-arrow-left color-green"/>
                    </Link>
                    <h4 className="font-16-bold color-grey-dark" style={{marginBottom: '15px'}}>
                        AutoAction for DataSourceHandle: {this.props.dataSourceHandle.name}
                    </h4>
                    <Form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div>
                                <div className="form-group">
                                    <label
                                        className="context-menu-label padding-bottom-10 align-center font-14 color-grey-dark">
                                        <input type="checkbox" name="isActive"
                                               checked={this.state.autoAction.isActive}
                                               onChange={this.handleIsActiveChange}
                                               className="tms-checkbox tms-child-checkbox"/>
                                        <span className="tms-check-mark" style={{top: "-1px", left: "-5px"}}/>
                                        Is active
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label
                                        className="context-menu-label padding-bottom-10 align-center font-14 color-grey-dark">
                                        <input type="checkbox" name="isSkipEnabled"
                                               checked={this.state.autoAction.isSkipEnabled}
                                               onChange={this.handleIsSkipEnabledChange}
                                               className="tms-checkbox tms-child-checkbox"/>
                                        <span className="tms-check-mark" style={{top: "-1px", left: "-5px"}}/>
                                        Is skip enabled
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label
                                        className="context-menu-label padding-bottom-10 align-center font-14 color-grey-dark">
                                        <input type="checkbox" name="isAutoReplyEnabled"
                                               checked={this.state.autoAction.isAutoReplyEnabled}
                                               onChange={this.handleIsAutoReplyEnabledChange}
                                               className="tms-checkbox tms-child-checkbox"/>
                                        <span className="tms-check-mark" style={{top: "-1px", left: "-5px"}}/>
                                        Is auto-reply enabled
                                    </label>
                                </div>
                                {(this.state.autoAction.isAutoReplyEnabled || this.state.autoAction.isSkipEnabled) ?
                                    <div>
                                        <div className="form-group" style={{paddingLeft: "10px", paddingRight: "10px"}}>
                                            {
                                                this.state.autoAction.topics.map((topic, index) =>
                                                    <div key={index} style={{marginBottom: "15px"}}>
                                                        <label htmlFor="topics" className="font-14 color-grey-dark">Topic
                                                            #{index + 1}</label>
                                                        <input type="text" className="form-control" id="topics"
                                                               name="topics"
                                                               value={topic} onChange={(event) => {
                                                            this.handleTopicsChange(event, index);
                                                        }}/>
                                                    </div>
                                                )
                                            }
                                            <Button className="btn btn-info btn-block font-15" type="button"
                                                    onClick={this.addTopic} style={{width: "100px"}}>
                                                Add topic
                                            </Button>
                                        </div>
                                        {this.state.autoAction.isAutoReplyEnabled ?
                                            <div className="form-group"
                                                 style={{paddingLeft: "10px", paddingRight: "10px"}}>
                                                {
                                                    this.state.autoAction.autoReplyText.map((autoReply, index) =>
                                                        <div key={index} style={{marginBottom: "15px"}}>
                                                            <label htmlFor="autoReplyText"
                                                                   className="font-14 color-grey-dark">Auto-reply
                                                                text #{index + 1}</label>
                                                            <input type="text" className="form-control"
                                                                   id="autoReplyText"
                                                                   name="autoReplyText"
                                                                   value={autoReply} onChange={(event) => {
                                                                this.handleAutoReplyTextChange(event, index);
                                                            }}/>
                                                        </div>
                                                    )
                                                }
                                                <Button className="btn btn-info btn-block font-15" type="button"
                                                        onClick={this.addAutoReplyText} style={{width: "150px"}}>
                                                    Add auto-reply text
                                                </Button>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    : null}
                                <div className="form-group">
                                    <label
                                        className="context-menu-label padding-bottom-10 align-center font-14 color-grey-dark">
                                        <input type="checkbox" name="isFirstResponseEnabled"
                                               checked={this.state.autoAction.isFirstResponseEnabled}
                                               onChange={this.handleIsFirstResponseEnabledChange}
                                               className="tms-checkbox tms-child-checkbox"/>
                                        <span className="tms-check-mark" style={{top: "-1px", left: "-5px"}}/>
                                        Enable Automatic First Responses when Queue is Busy
                                    </label>
                                </div>
                                {this.state.autoAction.isFirstResponseEnabled ?
                                    <div>
                                        <div style={{marginBottom: "15px"}}>
                                            <label htmlFor="threshold-first-response"
                                                   className="font-14 color-grey-dark">Minimum
                                                Queue Volume (Messages) to trigger auto-responder</label>
                                            <input type="text" className="form-control" id="threshold-first-response"
                                                   name="threshold-first-response"
                                                   value={this.state.autoAction.firstResponseThreshold}
                                                   onChange={(event) => {
                                                       this.handleFirstResponseThresholdChange(event);
                                                   }}/>
                                        </div>
                                        <div style={{marginBottom: "15px"}}>
                                            <HtmlTooltip
                                                title={
                                                    <div
                                                        style={{minWidth: '250px', maxWidth: '700px', padding: '10px'}}>
                                                        <Typography
                                                            style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                                                            {'Use <p></p> for new line.'}
                                                        </Typography>
                                                    </div>
                                                }
                                                placement={'right'}
                                                style={{opacity: 1}}
                                                enterDelay={0}
                                            >
                                                <label htmlFor="first-response-footer"
                                                       className="font-14 color-grey-dark">First response footer <i className="fa fa-info-circle"/></label>
                                            </HtmlTooltip>
                                            <input type="text" className="form-control" id="first-response-footer"
                                                   name="first-response-footer"
                                                   value={this.state.autoAction.firstResponseFooter}
                                                   onChange={(event) => {
                                                       this.handleFirstResponseFooterChange(event);
                                                   }}/>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                            <div style={{marginTop: "15px", paddingLeft: "10px", paddingRight: "10px"}}>
                                <Button className="btn btn-success btn-block font-15" type="submit"
                                        disabled={this.state.submitting}>
                                    {this.props.isNew ? "Create " : "Update "} AutoAction
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    const {
        autoAction: {autoAction, loadingAutoAction, loadingCreateAutoAction}
    } = state
    return {
        autoAction: autoAction,
        dataSourceHandle: autoAction.dataSourceHandle,
        isNew: !autoAction.id,
        loadingAutoAction: loadingAutoAction,
        loadingCreateAutoAction: loadingCreateAutoAction
    }
}

const autoAction = connect(mapStateToProps)(AutoAction);
export {autoAction as AutoAction}