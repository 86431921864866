import React, {Fragment} from "react"
import {Box} from "@mui/material";
import ShowMessage from "./ShowMessage";
import TikTokShopContent from "../../../common/Message/TikTokShop/TikTokShopContent";
import _ from "lodash";

export default function Conversation({conversation}){
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const { _embedded: {organization} } = currentUser;
    const conversationList = _.get(conversation, 'conversation') || [];
    const sourceName = _.get(conversation, 'conversation.sourceName');

    return(
        <Box>
            {
                conversationList &&
                conversationList.map((reply, it) => {
                    const textContent = <TikTokShopContent text={reply.text} sourceName={sourceName}/>;
                        return(
                            <Fragment key={it}>
                                <ShowMessage
                                    message={reply}
                                    author={reply.author}
                                    text={textContent}
                                    dateCreated={reply.dateCreated}
                                    organization={organization}
                                />
                            </Fragment>
                        )
                    })

            }
        </Box>
    )
}