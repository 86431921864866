import {msgDataSourceConstants} from '_constants';

const INITIAL_STATE = {
    msgDataSources: [],
    msgDataSource: {},
    msgDataSourcesForMsgSource: [],
    loading: false,
    error: '',
    msgDataSourceOrgList: [],
    gorgiasDataSourceId: '',
    gorgiasPlatformViews: {},
    gorgiasViewTickets: {},
    savedMacros: [],
    macrosList: [],
    currentPage: 0,
    nextPage: 1,
    macroLoading: false,
    initialLoad: false,
};

export function msgDataSourceReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case msgDataSourceConstants.GET_ALL_REQUEST:
            return {...state, loading: true};
        case msgDataSourceConstants.GET_ALL_SUCCESS:
            return {...state, msgDataSources: action.payload, loading: false};
        case msgDataSourceConstants.GET_ALL_FAILURE:
            return {...state, error: action.error, loading: false};


        case msgDataSourceConstants.GET_DETAILS_REQUEST:
            return {...state, loading: true};
        case msgDataSourceConstants.GET_DETAILS_SUCCESS:
            return {...state, msgDataSource: action.payload, loading: false};
        case msgDataSourceConstants.GET_DETAILS_FAILURE:
            return {...state, error: action.error, loading: false};

        case msgDataSourceConstants.GET_MSG_DATA_SOURCES_FOR_MSG_SOURCE_SUCCESS:
            return {...state, msgDataSourcesForMsgSource: action.payload, loading: false};
        case msgDataSourceConstants.ORGANIZATIONS_FOR_MSGSOURCE_SUCCESS:
            return {...state, msgDataSourceOrgList: action.payload.organizationForMsgSource, loading: false};
        case msgDataSourceConstants.ORGANIZATIONS_FOR_MSGSOURCE_FAILURE:
            return {...state, error: action.error, loading: false};
        case msgDataSourceConstants.ORGANIZATION_VIEWS_REQUEST:
            return {...state, loading: true, gorgiasDataSourceId: ''};
        case msgDataSourceConstants.ORGANIZATION_VIEWS_SUCCESS:
            return {...state,
                gorgiasPlatformViews: {
                    ...state.gorgiasPlatformViews,
                    [action.payload.gorgiasDataSourceId]: action.payload.views
                },
                gorgiasDataSourceId: action.payload.gorgiasDataSourceId,
                loading: false};
        case msgDataSourceConstants.ORGANIZATION_VIEWS_FAILURE:
            return {...state, error: action.error, loading: false};
        case msgDataSourceConstants.VIEW_TICKETS_REQUEST:
            return {...state,
                gorgiasViewTickets: {
                    ...state.gorgiasViewTickets,
                    [action.payload.viewId]: {
                        loading: true
                    }
                },
                loading: false};
        case msgDataSourceConstants.VIEW_TICKETS_SUCCESS:
            return {...state,
                gorgiasViewTickets: {
                    ...state.gorgiasViewTickets,
                    [action.payload.viewId]: {
                        ...action.payload,
                        loading: false
                    }
                },
                loading: false};
        case msgDataSourceConstants.VIEW_TICKETS_FAILURE:
            return {...state, error: action.error,
                gorgiasViewTickets: {
                    ...state.gorgiasViewTickets,
                    [action.payload.viewId]: {
                        loading: false
                    }
                },
                loading: false};
        case msgDataSourceConstants.PLATFORM_MACROS_LIST_REQUEST:
            return {
                ...state,
                macroLoading: true,
                initialLoad: true
            };
        case msgDataSourceConstants.PLATFORM_SAVE_MACROS_REQUEST:
        case msgDataSourceConstants.PLATFORM_REMOVE_MACROS_REQUEST:
        case msgDataSourceConstants.PLATFORM_UPDATE_MACROS_REQUEST:
            return {
                ...state,
                macroLoading: true,
            };
        case msgDataSourceConstants.PLATFORM_MACROS_LIST_SUCCESS:
            return {
                ...state,
                msgDataSource: action.payload.msgDataSource,
                savedMacros: action.payload.macros,
                macrosList: [], // reset Macros List for any previous msgDataSources
                macroLoading: false,
                initialLoad: true,
            };
        case msgDataSourceConstants.PLATFORM_MACROS_SELECTION_REQUEST:
            return {
                ...state,
                currentPage: 1, // Setting to one as all pages are retrieved at once
                macroLoading: true,
            };
        case msgDataSourceConstants.PLATFORM_MACROS_SELECTION_SUCCESS:
            return {
                ...state,
                macrosList: action.payload.macros,
                nextPage: 1, // Setting to 1 as all page are retrieved at once
                macroLoading: false,
            };
        case msgDataSourceConstants.PLATFORM_SAVE_MACROS_SUCCESS:
            return {
                ...state,
                savedMacros: action.payload.macros,
                macroLoading: false,
            };
        case msgDataSourceConstants.PLATFORM_REMOVE_MACROS_SUCCESS:
            return {
                ...state,
                savedMacros: state.savedMacros.filter(item => item.id !== action.payload),
                macroLoading: false
            };
        case msgDataSourceConstants.PLATFORM_UPDATE_MACROS_SUCCESS:
            let savedMacrosCopy = [...state.savedMacros]
            let updatedIndex = savedMacrosCopy.findIndex(item => item.id === action.payload.id)
            savedMacrosCopy.splice(updatedIndex, 1, action.payload)
            return {
                ...state,
                savedMacros: savedMacrosCopy,
                macroLoading: false
            };
        case msgDataSourceConstants.PLATFORM_CLEAR_MACROS_SUCCESS:
            return {
                ...state,
                macrosList: []
            };
        case msgDataSourceConstants.PLATFORM_MACROS_LIST_FAILURE:
            return {
                ...state,
                error: action.error,
                macroLoading: false,
                initialLoad: true,
            };
        case msgDataSourceConstants.PLATFORM_SAVE_MACROS_FAILURE:
        case msgDataSourceConstants.PLATFORM_MACROS_SELECTION_FAILURE:
        case msgDataSourceConstants.PLATFORM_REMOVE_MACROS_FAILURE:
        case msgDataSourceConstants.PLATFORM_UPDATE_MACROS_FAILURE:
            return {
                ...state,
                error: action.error,
                macroLoading: false,
            };
        default:
            return state
    }
}
