import {oauthConstants, alertMessages} from "_constants";
import {history} from "_helpers/index";
import {twitterOauthService} from "_services";
import {SnackBar} from "../components/common";

export const twitterOauthActions = {
    getOauthUrl,
    generateAccessToken,
    getTwitterOauthUrl,
    getAccessToken
};

function getOauthUrl(sourceName, callbackUrl) {
    return dispatch => {
        dispatch(request);

        twitterOauthService.getOauthUrl(sourceName, callbackUrl)
            .then(
                authUrl => {
                    dispatch(success(authUrl));
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TWITTER_OAUTH_FAILURE);
                }
            );
    };

    function request() {
        return {type: oauthConstants.TWITTER_OAUTH_URL_REQUEST}
    }

    function success(authUrl) {
        return {type: oauthConstants.TWITTER_OAUTH_URL_SUCCESS, payload: authUrl}
    }

    function failure(error) {
        return {type: oauthConstants.TWITTER_OAUTH_URL_FAILURE, payload: error}
    }
}

function getTwitterOauthUrl(callbackUrl) {
    return dispatch => {
        dispatch(request);
        twitterOauthService.getOauthUrl(callbackUrl)
            .then(
                authUrl => {
                    dispatch(success(authUrl));
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TWITTER_OAUTH_FAILURE);
                }
            );
    };
    function request() {
        return {type: oauthConstants.TWITTER_OAUTH_URL_REQUEST}
    }
    function success(authUrl) {
        return {type: oauthConstants.TWITTER_OAUTH_URL_SUCCESS, payload: authUrl}
    }
    function failure(error) {
        return {type: oauthConstants.TWITTER_OAUTH_URL_FAILURE, payload: error}
    }
}

function generateAccessToken(data) {

    let callbackUrl = localStorage.getItem('redirectUrl');
    callbackUrl = JSON.parse(callbackUrl).pathname;
    return dispatch => {
        dispatch(request());
        twitterOauthService.generateOauthToken(data)
            .then(
                response => {
                    if (response) {
                        history.push(callbackUrl);
                        dispatch(success(response));
                        SnackBar.showMessage(alertMessages.TWITTER_OAUTH_SUCCESS);
                    }
                },
                error => {
                    history.push(callbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TWITTER_OAUTH_FAILURE);

                },
            );

        function success(response) {
            return {type: oauthConstants.TWITTER_ACCESS_TOKEN_SUCCESS, payload: response}
        }

        function failure(error) {
            return {type: oauthConstants.TWITTER_ACCESS_TOKEN_FAILURE, error: error}
        }

        function request() {
            return {type: oauthConstants.TWITTER_ACCESS_TOKEN_REQUEST}
        }
    }
}

function getAccessToken(data, callbackUrl = '/signup/schedule', onErrorCallbackUrl = '/signup/add-channel') {
    return dispatch => {
        dispatch(request());
        twitterOauthService.generateOauthToken(data)
            .then(
                response => {
                    if (response) {
                        history.push(callbackUrl);
                        dispatch(success(response));
                        SnackBar.showMessage(alertMessages.TWITTER_OAUTH_SUCCESS);
                    }
                },
                error => {
                    history.push(onErrorCallbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TWITTER_OAUTH_FAILURE);

                },
            );
        function success(response) {
            return {type: oauthConstants.TWITTER_ACCESS_TOKEN_SUCCESS, payload: response}
        }
        function failure(error) {
            return {type: oauthConstants.TWITTER_ACCESS_TOKEN_FAILURE, error: error}
        }
        function request() {
            return {type: oauthConstants.TWITTER_ACCESS_TOKEN_REQUEST}
        }
    }
}