import {Box, Grid} from "@mui/material";
import commonStyles from "../commonStyles";

export default function MessageResponseSummary({autoAction}) {
    return (
        <Grid container columns={2}>
            <Grid item xs={4} md={1} sx={styles.nameContainer}>
                <Box component={'h4'}>{autoAction.dataSourceHandle.name}</Box>
            </Grid>
        </Grid>
    );
}

const styles = {
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    statusLabel: {
        ...commonStyles.labelMedium,
        padding: 1,
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: 4
    }
};