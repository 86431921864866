import React from 'react';
import { render } from 'react-dom';
import { store, history } from './_helpers';
import App from './components/App/App';
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";
import { getAuth0Config } from "./config"
import 'components/css/common.css';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const auth0Config = getAuth0Config();

const providerConfig = {
  domain: auth0Config.domain,
  clientId: auth0Config.clientId,
  audience: auth0Config.audience,
  scope: auth0Config.scope,
  redirectUri: `${window.location.origin}/authenticate`,
  onRedirectCallback,
};

render(
  <Provider store={store}>
    <Auth0Provider {...providerConfig}>
      <App/>
    </Auth0Provider>
  </Provider>,
  document.getElementById('root')
);
