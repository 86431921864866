import React, {Component} from 'react'
import {reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {alertActions, faceOauthActions} from "_actions";
import {Loading} from "components/common";
import {styles} from "components/OauthComponents";
import {PagesForm} from "./Pages";
import {alertMessages} from "_constants";

class FacebookPages extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(pages) {
        /**
         * data comes in the format {page_pageId:true,page_pageId:false}
         */
        const pageSelected = Object.keys(pages).some(key => {
            return pages[key]
        });
        if (pageSelected) {
            this.props.dispatch(faceOauthActions.submitSelectedPages(pages))
        } else {
            this.props.dispatch(alertActions.error(alertMessages.PAGES_LENGTH_ERROR))
        }
    }

    componentDidMount() {
        this.props.dispatch(faceOauthActions.getPages())
    }

    render() {

        const {handleSubmit, pages, loading} = this.props;
        const {buttonHolder} = styles;

        if (loading) {
            return <Loading/>
        }

        if (pages && pages.length === 0) {
            return (
                <div className="main-content-wrapper">
                    <div className="content-pane-60 margin-0-auto">
                        <div className="padding-20-0">
                            <div>
                                It looks like your account does not have administrator access for any
                                [Facebook / Instagram] pages.
                                Please login with another account
                            </div>
                            <a href="/company/connectchannels">
                                <button className="btn btn-success btn-small">
                                    Login with another account
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <PagesForm
                    pages={pages}
                    handleSubmit={handleSubmit(this.onSubmit)}
                    buttonHolder={buttonHolder}
                    pageTitle="Choose Facebook Pages"
                    withoutAccessTitle="Facebook Pages that cannot be added (Requires Role of Moderator or Higher)"
                    pageType="Facebook Page"
                />
            );
        }
    }
}

function mapStateToProps(state) {

    return {
        pages: state.facebookOauth.data,
        loading: state.facebookOauth.loading
    }
}

const formConfig = {
    form: 'facebookPagesForm'
};

FacebookPages = connect(
    mapStateToProps,
    null
)(FacebookPages);

const connectedFacebookPages = reduxForm(formConfig)(FacebookPages);

export {connectedFacebookPages as FacebookPages}