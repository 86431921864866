export class ImpactMetricsBuilder {
  constructor() {
    this.metrics = {};
  }

  setEstimatedTimeSavedPerCustomerInMin(estimatedTimeSavedPerCustomerInMin){
    this.metrics.estimatedTimeSavedPerCustomerInMin = estimatedTimeSavedPerCustomerInMin;
    return this;
  }

  setTicketsSolvedAmount(ticketsSolvedAmount){
    this.metrics.ticketsSolvedAmount = ticketsSolvedAmount;
    return this;
  }

  setTicketsSolvedMonthToDateAmount(ticketsSolvedMonthToDateAmount){
    this.metrics.ticketsSolvedMonthToDateAmount = ticketsSolvedMonthToDateAmount;
    return this;
  }

  setTicketsSolvedAllTime(ticketsSolvedAllTime){
    this.metrics.ticketsSolvedAllTime = ticketsSolvedAllTime;
    return this;
  }

  setTicketsHiddenAmount(ticketsHiddenAmount){
    this.metrics.ticketsHiddenAmount = ticketsHiddenAmount;
    return this;
  }

  build(){
    return this.metrics;
  }
}
