import React, {Component, Fragment} from 'react';
import {handleTypeNames} from "_constants";
import {Loading} from "../../common";
import {
  changeImgTagToLink,
  parseEscapedText,
  stripNumbersFromUsername,
  testForEmail,
  handleRawHtml,
  getCapitalizedWords, handleAttachment, stripStyleFromHtml,
} from "_helpers";
import _ from "lodash";
import moment from "moment";
import copyIcon from "../../images/outline-file_copy-24px.svg";
import {copyTextToClipboard, copyTicketLinkToClipboard, messageActions} from "_actions";
import {connect} from "react-redux";


/**
 * @author Chris Okebata
 */

class OriginalConversationContent extends Component{

  /**
   * Makes a request to the server to download the attachment
   * @param messageId
   * @param attachmentURL
   * @param fileName
   */
  downloadAttachment = (messageId, attachmentURL, fileName) => {
    const { dispatch } = this.props;
    dispatch(messageActions.downloadAttachment(messageId, attachmentURL, fileName));
  };

  /**
   * This renders any attachment found within the message
   * @param attachment
   * @param index
   * @param params
   * @returns {JSX.Element}
   */
  renderAttachments = (attachment, index, params) => {
    const { downloadingAttachment } = this.props;
    return(
      <div key={index} style={{margin: '4px 0'}}>
        {handleAttachment(attachment, params, this.downloadAttachment, downloadingAttachment)}
      </div>
    )
  };

  /**
   * This renders the full conversation gotten from the message source
   * @param emailConversation
   * @param index
   * @param params
   * @returns {false|JSX.Element}
   */
  renderFullEmailConversation = (emailConversation, index, params) => {
    const { id, htmlBody, authorName, createdDate, attachments, authorEmail, recipientEmail, currentPage, assignee, other} = emailConversation;
    const text = changeImgTagToLink(parseEscapedText(stripStyleFromHtml(htmlBody)));
    return (
      <div key={id} style={{display: 'flex', padding: '20px', borderBottom: '1px solid #ededed', clear: 'both'}}>
        <div>
          <div style={{paddingBottom: '10px'}}>
            {authorEmail && <span style={{marginRight: '20px'}}>
              From:
            </span>}
            <span id="author-name" style={{fontWeight: "bold"}}>
              {testForEmail(authorName) ? 'User' : stripNumbersFromUsername(authorName)}
            </span>
            {authorName && index === 0 && <img src={copyIcon} onClick={()=> copyTextToClipboard('author-name')} style={{width: '18px', margin: '0 7px', cursor:'pointer'}} alt="Copy Ticket Link"/> }
            {authorEmail &&
            <span style={{marginRight: '15px'}}>
              {` <${authorEmail}>`}
            </span>}
            <span>
              {moment(createdDate).format('MMM D HH:mm a')}
            </span>
            { currentPage &&
            <div style={{paddingTop: '5px', width: '450px'}} className="tms-ellipsis">
              <a href={currentPage} target = "_blank" rel="noopener noreferrer">
                {currentPage}
              </a>
            </div>
            }
          </div>
          {recipientEmail &&
          <div style={{paddingBottom: '10px'}}>
            <span style={{ marginRight: '35px'}}>To:</span>
            <span>{`<${recipientEmail}>`}</span>
          </div>}
            {assignee && assignee.name && assignee.email &&
            <div style={{paddingBottom: '10px'}}>
              <span style={{ marginRight: '35px'}}>Assigned To:</span>
              <span>{`<${assignee.name}>`} ({`<${assignee.email}>`})</span>
            </div>}

          <div className="no-break-out" dangerouslySetInnerHTML={handleRawHtml(text)} />
          {
            other &&
              <table style={{display: 'flex'}}>
                <tbody>
                {Object.entries(other).map(([key, value]) => {
                  return (
                      <tr key={key}>
                        <td style={{minWidth: '110px', padding: '10px 10px 0 0', wordBreak: 'break-all'}}>{key}:</td>
                        <td style={{wordBreak: 'break-all'}} dangerouslySetInnerHTML={handleRawHtml(value)}/>
                      </tr>
                  )
                })}
                </tbody>
              </table>
          }

          {
            ( attachments && attachments.length > 0 ) &&
            <div style={{paddingTop: '15px'}}>
              <div style={{display: 'flex'}}>
                <div style={{fontWeight: "bold"}}>Attachments</div>
                { this.props.downloadingAttachment && <div style={{margin: '2px 0 0 8px'}}> <Loading height={15} size={15} /> </div> }
              </div>
              { attachments.map((attachment, index) => this.renderAttachments(attachment, index, params)) }
            </div>
          }
        </div>
      </div>
    );
  };


  render() {

    const { message, emailConversationData, loadingEmailConversation } = this.props;
    let conversation, fullMessageLink, author, emailSubject, handleType, sourceName;
    const { emailConversation } = emailConversationData || [];

    handleType = _.get(message, 'dataSourceHandle.type');
    fullMessageLink = _.get(message, 'link');
    sourceName = _.get(message, 'sourceName');
    emailSubject = _.get(( _.get(Object.entries(emailConversation || {}), '0.1')), 'emailSubject') || '';
    author = _.get(( _.get(Object.entries(emailConversation || {}), '0.1')), 'authorName') || '';
    const params = {
      messageId: message.id,
      msgSource: sourceName,
    }

    if (loadingEmailConversation) {
      return <Loading height={150}/>
    }

    conversation = <Fragment>
      { emailConversation && emailConversation.map((message, index) => this.renderFullEmailConversation(message, index, params)) }
    </Fragment>;

    return(
      <Fragment>
        <div>

          {_.get(emailConversation, 'length') === 0 ?
            <div className="conversation-list-container font-15" style={{border: 'unset'}}>No messages found</div>
            :
            <div className="conversation-list-container">
              <div className="title conversation-list-title"
                   style={{padding: `2px 20px 0`, borderBottom: '1px solid #ededed'}}>
                <span> Conversation with {author}</span>
                {fullMessageLink && (
                  <div>
                  <span>
                    <a
                       id="sourceNameLink"
                       className="font-14 color-green tms-no-underline"
                       href={fullMessageLink}
                       target="_blank"
                       rel="noreferrer noopener nofollow"
                       style={{fontWeight: 'bold'}}
                    >
                        {`View in ${getCapitalizedWords(sourceName) || 'source'}`}
                    </a>
                    <img src={copyIcon} onClick={() => copyTicketLinkToClipboard('sourceNameLink')} style={{width: '18px',marginLeft: '7px',cursor:'pointer'}} alt="Copy Ticket Link"/>
                </span>
                  </div>
                )}
              </div>

              {emailSubject && [handleTypeNames.EMAIL, handleTypeNames.PROACTIVE_EMAIL].includes(handleType) &&
                <div style={{padding: '10px 20px', borderBottom: '1px solid #ededed'}}>
                  <span style={{marginRight: '8px'}}>Subject:</span>
                  <span style={{fontWeight: "bold"}}>{emailSubject}</span>
                </div>
              }

              <div> {conversation} </div>
              <div style={{textAlign: 'center', padding: '20px 0'}}>End of conversation</div>
            </div>
          }
        </div>
      </Fragment>
    )
  }

}
function mapStateToProps(state) {
  const {messages : { downloadingAttachment } } = state;
  return { downloadingAttachment };
}
const connectedOriginalConversationContent = connect(mapStateToProps)(OriginalConversationContent);
export { connectedOriginalConversationContent as OriginalConversationContent };