import React, {Fragment} from "react";
import {Box, useTheme,Button} from "@mui/material";
import ActionButton from "../../common/ActionButton";
import {useSelector} from "react-redux";
import {
    filterObjectValue,
    getCapitalizedWords,
    getCurrentUser,
    getLogoUrl,
    internalAccounts,
    isObjectEmpty
} from "_helpers";
import _ from "lodash";
import {Loading, SnackBar} from "../../../common";
import logoPlaceholder from "components/images/icon_business.png"
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Team({ employmentList, handleSubmit }){
    const loading = useSelector(state => state.employment.loading);
    const EmploymentRow = ({ employment, handleSubmit }) => {
        const {organization} = employment
        const theme = useTheme();
        const userId = getCurrentUser().id;
        const isMobile = useMediaQuery(theme.breakpoints.down('md'));

        const organizationId = _.get(organization, 'id');
        const organizationName = _.get(organization, 'name');
        const organizationLogo = _.get(organization, 'logo');
        const organizationOverview = _.get(organization, 'overview');
        const messageCount = _.get(employment, 'messageCount');
        const excludedAccount = internalAccounts.includes(userId)
        const scheduledOrganizations = useSelector(state => state.schedules.scheduledOrganizations.scheduledOrganizations)
        const loadingScheduledOrgs = useSelector(state => state.schedules.loadingScheduledOrgs)
        const isHired = true

        let scheduledObj, isScheduled;
        scheduledObj = filterObjectValue(scheduledOrganizations || [], 'id', organizationId);
        isScheduled = (!loadingScheduledOrgs && !isObjectEmpty(scheduledObj));
        const showScheduleInfo = isHired && !isScheduled;

        function answerMessages(){
            (showScheduleInfo) ?
                SnackBar.showMessage(`${getCapitalizedWords(organizationName || 'Company') } is not scheduled`) :
                handleSubmit(organizationId, scheduledObj)
        }

        return(
            <Box sx={{borderTop: '1px solid #DBDFDF', display: 'flex', height: isMobile ? '100px' : '115px', alignItems: 'stretch'}}>
                <Box sx={{borderRight: '1px solid #DBDFDF', minWidth: "60px", width: isMobile ? "60px" : "90px", display: excludedAccount ? "none" : "flex", justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{border: '1px solid #0C2728', borderRadius: '50%', width: isMobile ? '40px': '50px', height: isMobile ? '40px': '50px', color: '#0C2728', fontWeight: 'medium', fontSize: 18, display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                        {messageCount || 0}
                    </Box>
                </Box>

                <Box sx={{flex: "1 1 0px", display: 'flex', alignItems: 'center', gap: isMobile ? 1 : 2, paddingRight:  isMobile ? 1 : 2}}>
                    <Box sx={{...styles.imgContainer, width: isMobile ? "70px" : "180px", paddingLeft: isMobile ? 1 : 2}}>
                        <img
                            src={organizationLogo ? getLogoUrl(organizationLogo) : logoPlaceholder}
                            alt={organizationName}
                            style={{...styles.imgStyle, maxWidth: isMobile ? "70px" : "180px"}}
                        />
                    </Box>
                    <Box sx={{flex: "1 1 0px"}}>
                        <Box style={{fontSize: "14px", color: '#0C2728', fontFamily: 'larsseitBold', letterSpacing: "0.7px",textTransform: "uppercase"}} className="exp-dashboard-truncate-1-line">
                            {organizationName}
                        </Box>
                        <Box style={{fontWeight: 'normal', fontSize: "16px", color: '#0C2728',fontFamily: 'larsseitRegular'}} className="exp-dashboard-truncate-2-lines">
                            {organizationOverview}
                        </Box>
                    </Box>
                    <Box>
                        <ActionButton
                            label={'Answer messages'}
                            action={answerMessages}
                            sx={{display: isMobile ? "none" : "block"}}
                        />
                        <Button sx={{display: isMobile ? "block" : "none", padding: 0, minWidth: "0px", height: "20px"}} onClick={answerMessages}>
                            <ArrowForwardIosIcon style={{color: "#368C86"}} fontSize={'small'}/>
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    return(
        <Box sx={{backgroundColor: '#FFFFFF', mb: 3, border: '1px solid #CED4D4', borderTop: 'none', borderRadius: '0px 0px 5px 5px'}}>
            { loading && <Loading height={200} /> }
            { !loading && employmentList.length > 0 &&
                <>
                    { employmentList.map((employment) => {
                        return(
                            <Fragment key={employment.id}>
                                <EmploymentRow employment={employment} handleSubmit={handleSubmit} />
                            </Fragment>
                        )
                    })}
                </>
            }
        </Box>
    )
}

const styles = {
    imgContainer: {
        minWidth: '40px',
        width: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imgStyle: {
        minWidth: '40px',
        maxHeight: '40px',
        borderRadius: '5px',
    }
}