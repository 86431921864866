import React, {Fragment} from "react";
import BasicMultiSelect from "../../../common/BasicMultiSelect";

export default function ChannelsMultiSelect({ name, label, placeholder, options, handleFormSelectChange, input, meta }) {

    return(
        <Fragment>
            <BasicMultiSelect
                name={name}
                label={label}
                placeholder={placeholder}
                options={options}
                handleFormSelectChange={handleFormSelectChange}
                input={input}
                meta={meta}
            />
        </Fragment>
    )
}