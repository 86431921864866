import React from 'react'
import connect from "react-redux/es/connect/connect";
import { OrganizationSubscribeFilterForm, SubscribeDetails } from "components/Payments";
import { subscriptionsActions, organizationActions } from "_actions";
import { objectToQueryString, queryStringToObject } from "_helpers";
import {publicAndFeaturedVisibility} from "../../_constants";

class OrganizationSubscribe extends React.Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);

        this.state = {
            filterParams: {
                organization: '',
                max: 10,
                offset: 0,
                ...filterParams
            },
            subscription: '',
            showModal: false
        };
    }

    /**
     * Handles the updating state on params change and refresh page data
     * @param event
     */
    handleUpdateForm = (event) => {
        const { target : { name, value }} = event;
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams,
                    organization: name === 'organization' ?
                        value : prevState.filterParams.organization
                },
                subscription: name === 'subscription' ?
                    value : prevState.subscription
            }
        }, function() {
            if (name === 'organization') {
                const { filterParams } = this.state
                this.getSubscriptions(filterParams)
            }
        });
    }

    /**
     * Handles the change of url to retrieve schedules based on query params for filtering
     * @param filterParams The parameters for filtering the schedules to be retrieved
     */
    getSubscriptions = (filterParams) => {
        const queryString = objectToQueryString(filterParams);
        const { history } = this.props;
        history.push({
            pathname: '/organizations/subscriptions',
            search: `?${queryString}`
        });
    }

    handleSubscribe = (e) => {
        e.preventDefault();
        const { subscription, filterParams: { organization }} = this.state;
        const { dispatch } = this.props;
        dispatch(subscriptionsActions.addOrgSubscription(organization, subscription))
    };

    handlePageSelect = (page) => {
        this.setState(prevState => ({
            filterParams: {
                ...prevState.filterParams,
                offset: page.selected * prevState.filterParams.max
            }
        }), function() {
            const { filterParams } = this.state;
            this.getSubscriptions(filterParams)
        })
    }

    componentDidMount() {
        let { dispatch } = this.props
        dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}));
        dispatch(subscriptionsActions.getAllSubscriptions({max: 1000}))
        const { filterParams: { organization, max, offset }} = this.state
        if (organization) {
            dispatch(subscriptionsActions.subscriptionHistory(organization, { max, offset }))
        }
    }

    render() {
        const { filterParams: { organization, max, offset }, subscription, showModal } = this.state;
        const { subscriptions, organizations, loading } = this.props;
        const input = {onChange: this.handleUpdateForm};

        return (
            <div className="container-pane" style={{display:"", padding:"0 38px"}}>
                <div className="side-bar-pane shadow-border" >
                    <OrganizationSubscribeFilterForm
                        organization={organization}
                        organizations={organizations}
                        input={input}
                    />
                </div>

                <div className="container-right">
                    <div className="shadow-border margin-bottom-50" style={{marginLeft: "38px", padding: "20px 33px 33px 33px"}}>
                        <div>
                            {! organization &&
                                <span>Select an organization</span>
                            }
                            <SubscribeDetails
                                openModal={() => this.setState({showModal: true})}
                                closeModal={() => this.setState({ showModal: false})}
                                showModal={showModal}
                                onChange={this.handleUpdateForm}
                                handleSubmit={this.handleSubscribe}
                                pageSelected={this.handlePageSelect}
                                organization={organization}
                                suscriptions={subscriptions}
                                suscription={subscription}
                                loading={loading}
                                offset={offset}
                                max={max}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { organizations: { loading, organizations },
        subscription: { all_subscriptions }} = state;

    return {
        organizations: organizations.organizations,
        loading,
        subscriptions: all_subscriptions
    };
}

const organizationSubscribePage = connect(mapStateToProps)(OrganizationSubscribe);
export { organizationSubscribePage as OrganizationSubscribe };
