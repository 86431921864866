import _ from "lodash";

/**
 * @param shopSelected {object}
 * @param organizationId {string}
 * @returns {object|null}
 */
export function buildSaveShopPayload(shopSelected, organizationId) {
    if(_.isEmpty(shopSelected) || !organizationId) {
        return null;
    }

    return {
        shopId: _.get(shopSelected, 'id'),
        shopName: _.get(shopSelected, 'name'),
        shopCipher: _.get(shopSelected, 'cipher'),
        shopCode: _.get(shopSelected, 'code'),
        sellerType: _.get(shopSelected, 'sellerType'),
        organizationId: organizationId,
    }
}