import React, {Fragment} from "react";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {history} from "_helpers";


export default function Auth0LogoutButton (props){
  const logoutUser = () => {
    history.push('/logout');
  };

  let label = props.label || "Logout";
  let view = props.isLink ?
    <Link className="color-grey-dark" to='/logout' onClick={logoutUser}>{label}</Link> :
    <Button bsStyle="success" onClick={logoutUser} style={{width: "100%"}}>{label}</Button>;

  return (
    <Fragment>
      {view}
    </Fragment>
  );
};