import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";

export default function useGetAutoActionsInfo(organizationId) {
    const dispatch = useDispatch();

    useEffect(() => {
        if(!_.isEmpty(organizationId)) {
            dispatch(organizationSettingsActions.fetchAutoActionsInfo(organizationId));
        }
    }, [organizationId]);

    const organizationSettings = useSelector(state => state.organizationSettings);
    const loading = organizationSettings.loadingAutoActionsInfo;
    const autoActionsInfo = organizationSettings.autoActionsInfo;

    return {autoActionsInfo, loading};
}
