import {useState} from "react";
import {Box} from "@mui/system";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import BasicTextField from "../../../../common/BasicTextField";
import {search} from "./settingsSearchUtils";

export default function SettingsSearch({handleOpen, options=[], handleSearchResult}) {
    const ENTER_KEY_CODE = 13;
    const [searchField, setSearchField] = useState({});

    const handleSearch = (event) => {
        handleOpen();
        searchSettings(event);
    }

    const searchSettings = (event) => {
        if(_.isEmpty(searchField)) return;
        performSearch(event);
    }

    const handleSearchChangeField = (event) => {
        performSearch(event);
    }

    const performSearch = (event) => {
        let value = _.get(event, 'target.value') || '';
        const result = search(value.trim(), options);
        setSearchField(value);
        handleSearchResult(result);
    }

    const handleSearchOnKeyDown = (event) => {
        if(event.keyCode === ENTER_KEY_CODE) handleSearch(event);
    }

    const onFocus = () => {
        handleOpen();
    }

    return(
        <>
            <Box sx={styles.searchContainer}>
                <BasicTextField
                    name={'searchSettings'}
                    startIcon={<SearchIcon sx={styles.icon} />}
                    placeholder={'Search'}
                    handleChange={handleSearchChangeField}
                    onKeyDown={handleSearchOnKeyDown}
                    onFocus={onFocus}
                    sx={styles.textField}
                />
            </Box>
        </>
    )
}

const styles = {
    searchContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
    },
    textField: {
        height: '42px',
        [`& fieldset`]: {
            borderRadius: '8px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#687979',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },
    icon: {
        color: '#495D5E'
    },
};