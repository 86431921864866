import {dispatcherConstants} from "_constants";

const INITIAL_STATE = {
    dispatchedMessage: {},
    htmlBody: {},
    loading: false,
    request: false,
    error: '',
};
export const dispatcherReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case dispatcherConstants.DISPATCH_REQUEST:
            return {...state, loading: false, request: true};
        case dispatcherConstants.DISPATCH_SUCESS:
            return {...state, dispatchedMessage: action.payload, loading: false, request: false};
        case dispatcherConstants.DISPATCH_FAILURE:
            return {...state, error: action.error, loading: false, request: false};

        case dispatcherConstants.DISPATCHER_BLACKLIST_REQUEST:
            return {...state, loading: false, request: true};
        case dispatcherConstants.DISPATCHER_BLACKLIST_SUCCESS:
            return {...state, dispatchedMessage: action.payload, loading: false, request: false};
        case dispatcherConstants.DISPATCHER_BLACKLIST_FAILURE:
            return {...state, error: action.error, loading: false, request: false};

        case dispatcherConstants.DISPATCHER_DELETE_REQUEST:
            return {...state, loading: true};
        case dispatcherConstants.DISPATCHER_DELETE_SUCCESS:
            return {...state, dispatchedMessage: action.payload, loading: false};
        case dispatcherConstants.DISPATCHER_DELETE_FAILURE:
            return {...state, error: action.error, loading: false};

        case dispatcherConstants.DISPATCHER_LIKE_MESSAGE_REQUEST:
            return {...state, loading: true};
        case dispatcherConstants.DISPATCHER_LIKE_MESSAGE_SUCCESS:
            return {...state, dispatchedMessage: action.payload, loading: false};
        case dispatcherConstants.DISPATCHER_LIKE_MESSAGE_FAILURE:
            return {...state, error: action.error, loading: false};

        case dispatcherConstants.GET_HTML_BODY_REQUEST:
            return {...state, loading: true};
        case dispatcherConstants.GET_HTML_BODY_SUCCESS:
            return {...state, htmlBody: action.payload, loading: false};
        case dispatcherConstants.GET_HTML_BODY_FAILURE:
            return {...state, error: action.error, loading: false};

        case dispatcherConstants.CLEAR_HTML_BODY:
            return {...state, htmlBody: {}, loading: false};
        default:
            return state
    }
};