import React, {Component} from 'react'
import connect from "react-redux/es/connect/connect";
import Grid from '@material-ui/core/Grid';
import {LocationOn} from '@material-ui/icons';
import {userActions, stripeOauthActions, systemSettingsActions} from "_actions";
import {Loading} from "components/common";
import {formModes} from "_constants";
import userAvatar160 from "components/images/user-160.svg";
import {Image} from "react-bootstrap";
import ImageEditor from './ImageEditor'
import {EditUserProfile} from './EditUserProfile'
import {styles} from './StylesEditProfile'
import {getLogoUrl} from "_helpers";


/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/01/2019 : 2:26 PM
 *
 * @author Chris Okebata
 */


class UserProfilePage extends Component {
    constructor(props) {
        super(props);

        this.openPictureUpload = this.openPictureUpload.bind(this);
        this.closePictureUpload = this.closePictureUpload.bind(this);

        this.state = {
            editingUser: false,
            showPictureUpload: false,
            showProfileEdit: false
        };
    }

    componentDidMount() {
        const {id} = this.props.match.params;
        this.props.dispatch(userActions.getUser(id));
        this.props.dispatch(systemSettingsActions.showSystemSettings());
    }

    saveClickHandler = (params) => {
        this.props.dispatch(userActions.updateUser(params));

        this.setState({editingUser: false})
    };

    openPictureUpload = (event) => {
        event.preventDefault();
        this.setState({
            showPictureUpload: true
        });
    };

    closePictureUpload = () => {
        this.setState({showPictureUpload: false});
    };

    openEditProfile = (event) => {
        event.preventDefault();

        this.setState({
            showProfileEdit: true
        });
    };

    closeEditProfile = () => {
        this.setState({showProfileEdit: false});
    };

    deletePhoto = () => {
        this.props.dispatch(userActions.deleteUserPhoto(this.props.userId))
    };

    oauthRedirect(authUrl) {
        window.open(authUrl);
    }

    initiateOauth = (event) => {
        event.preventDefault();
        this.props.dispatch(stripeOauthActions.initiateOauth())
    };

    render() {

        const {loading, user, stripeOauth, systemSettings} = this.props;

        if (stripeOauth.data.oauthUrl) {
            this.oauthRedirect(stripeOauth.data.oauthUrl);
        }
        const {nameHolder, emailHolder, locationIcon, stateHolder, editButtonHolder, addStripeButtonHolder} = styles;

        let profilePicture = userAvatar160;

        if (user && user.profilePicture) {
            profilePicture = getLogoUrl(user.profilePicture);
        }

        if (loading || !user) {
            return <Loading/>
        }

        let userContactDetails = {
            phone: {
                desc: "Phone",
                value: user.phoneNumber,
            },
            address: {
                desc: "Address",
                value: user.address,
            },
            country: {
                desc: "Country",
                value: user.country,
            },
            timezone: {
                desc: "Timezone",
                value: user.timezone,
            },
        };

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <ImageEditor
                    headerText="Upload picture"
                    buttonText="Save"
                    onHide={this.closePictureUpload}
                    show={this.state.showPictureUpload}
                    dispatch={this.props.dispatch}
                    userId={user.id}
                    type="users"
                    useAspectRation
                />

                <EditUserProfile
                    bodyText=""
                    headerText="Edit Profile"
                    buttonText="Save"
                    onHide={this.closeEditProfile}
                    show={this.state.showProfileEdit}
                    dispatch={this.props.dispatch}
                    type="users"
                    onSave={this.saveClickHandler}
                    user={user}
                    mode={formModes.EDIT}
                    editedBy='SELF'
                />

                <Grid container justify="center">
                    <Grid container item xs={12} sm={12} md={10} className="shadow-border">
                        <Grid container item xs={12} md={7}>
                            <Grid item style={{padding: "50px"}}>
                                <div className="profile-picture-container" onClick={(e) => this.openPictureUpload(e)}>
                                    <Image responsive
                                           src={profilePicture}
                                           circle
                                    />
                                </div>
                                <button onClick={this.deletePhoto}>x</button>
                            </Grid>
                            <Grid item className="personal-info-wrapper">
                                <Grid item className="personal-info-container">
                                    <div style={nameHolder} className="font-22-bold color-grey-dark tms-ellipsis">
                                        {user.firstName + " " + user.lastName}
                                    </div>
                                    <div style={emailHolder} className="font-15 color-green tms-ellipsis">
                                        {user.username}
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div>
                                            <LocationOn className="color-grey-mid" style={locationIcon}/>
                                        </div>
                                        <div className="font-15 color-grey-mid tms-ellipsis" style={stateHolder}>
                                            {user.city + ", " + user.state}
                                        </div>
                                    </div>
                                    <div style={editButtonHolder}>
                                        <button onClick={(e) => this.openEditProfile(e)}
                                                className="btn btn-default font-14 color-grey-dark">Edit profile
                                        </button>
                                    </div>
                                </Grid>
                                {systemSettings.isStripeEnabled &&
                                <div style={addStripeButtonHolder}>
                                    <button onClick={(e) => this.initiateOauth(e)}
                                            className="btn btn-primary font-14 color-grey-dark">Connect Stripe account
                                    </button>
                                </div>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5} style={{padding: "50px 0"}}>
                            <div className="contact-info-container">
                                <div className="border-bottom-grey font-14">
                                    <div className="contact-text-bottom">
                                        Contact
                                    </div>
                                </div>
                                <table style={{marginTop: "10px"}}>
                                    <tbody>
                                    {
                                        Object.keys(userContactDetails).map((key, it) => {
                                            return (
                                                <tr key={it}>
                                                    <td>
                                                        <div style={{padding: "5px 0"}}
                                                             className="font-14">{userContactDetails[key].desc}:
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{padding: "5px 0 5px 30px"}}
                                                             className="font-14 color-green">{userContactDetails[key].value}</div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    const {users, stripeOauth, systemSettings: {systemSettings}} = state;
    const {user, loading} = users;

    return {
        user,
        loading,
        stripeOauth,
        systemSettings
    }
};


const connectedUserProfilePage = connect(mapStateToProps)(UserProfilePage);
export {connectedUserProfilePage as UserProfilePage};
