
export const proactiveConstants = {
    GET_CAMPAIGN_REQUEST: 'GET_CAMPAIGN_REQUEST',
    GET_CAMPAIGN_SUCCESS: 'GET_CAMPAIGN_SUCCESS',
    GET_CAMPAIGN_FAILURE: 'GET_CAMPAIGN_FAILURE',

    GET_COMPANY_CAMPAIGNS_REQUEST: 'GET_COMPANY_CAMPAIGNS_REQUEST',
    GET_COMPANY_CAMPAIGNS_SUCCESS: 'GET_COMPANY_CAMPAIGNS_SUCCESS',
    GET_COMPANY_CAMPAIGNS_FAILURE: 'GET_COMPANY_CAMPAIGNS_FAILURE',

    GET_ALL_CAMPAIGNS_REQUEST: 'GET_ALL_CAMPAIGNS_REQUEST',
    GET_ALL_CAMPAIGNS_SUCCESS: 'GET_ALL_CAMPAIGNS_SUCCESS',
    GET_ALL_CAMPAIGNS_FAILURE: 'GET_ALL_CAMPAIGNS_FAILURE',

    GET_CAMPAIGNS_METRICS_REQUEST: 'GET_CAMPAIGNS_METRICS_REQUEST',
    GET_CAMPAIGNS_METRICS_SUCCESS: 'GET_CAMPAIGNS_METRICS_SUCCESS',
    GET_CAMPAIGNS_METRICS_FAILURE: 'GET_CAMPAIGNS_METRICS_FAILURE',

    GET_COMPANY_TEMPLATES_REQUEST: 'GET_COMPANY_TEMPLATES_REQUEST',
    GET_COMPANY_TEMPLATES_SUCCESS: 'GET_COMPANY_TEMPLATES_SUCCESS',
    GET_COMPANY_TEMPLATES_FAILURE: 'GET_COMPANY_TEMPLATES_FAILURE',

    GET_ALL_TEMPLATES_REQUEST: 'GET_ALL_TEMPLATES_REQUEST',
    GET_ALL_TEMPLATES_SUCCESS: 'GET_ALL_TEMPLATES_SUCCESS',
    GET_ALL_TEMPLATES_FAILURE: 'GET_ALL_TEMPLATES_FAILURE',

    CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
    CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
    CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',

    UPDATE_CAMPAIGN_REQUEST: 'UPDATE_CAMPAIGN_REQUEST',
    UPDATE_CAMPAIGN_SUCCESS: 'UPDATE_CAMPAIGN_SUCCESS',
    UPDATE_CAMPAIGN_FAILURE: 'UPDATE_CAMPAIGN_FAILURE',

    DELETE_CAMPAIGN_ATTRIBUTE_REQUEST: 'DELETE_CAMPAIGN_ATTRIBUTE_REQUEST',
    DELETE_CAMPAIGN_ATTRIBUTE_SUCCESS: 'DELETE_CAMPAIGN_ATTRIBUTE_SUCCESS',
    DELETE_CAMPAIGN_ATTRIBUTE_FAILURE: 'DELETE_CAMPAIGN_ATTRIBUTE_FAILURE',

    GET_MSG_DATA_SOURCES_REQUEST: 'GET_MSG_DATA_SOURCES_REQUEST',
    GET_MSG_DATA_SOURCES_SUCCESS: 'GET_MSG_DATA_SOURCES_SUCCESS',
    GET_MSG_DATA_SOURCES_FAILURE: 'GET_MSG_DATA_SOURCES_FAILURE',

    GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_REQUEST: 'GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_REQUEST',
    GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_SUCCESS: 'GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_SUCCESS',
    GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_FAILURE: 'GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_FAILURE',

    GET_CUSTOMER_IO_SEGMENT_REQUEST: 'GET_CUSTOMER_IO_SEGMENT_REQUEST',
    GET_CUSTOMER_IO_SEGMENT_SUCCESS: 'GET_CUSTOMER_IO_SEGMENT_SUCCESS',
    GET_CUSTOMER_IO_SEGMENT_FAILURE: 'GET_CUSTOMER_IO_SEGMENT_FAILURE',

    GET_CHANNELS_REQUEST: 'GET_CHANNELS_REQUEST',
    GET_CHANNELS_SUCCESS: 'GET_CHANNELS_SUCCESS',
    GET_CHANNELS_FAILURE: 'GET_CHANNELS_FAILURE',

    GENERATE_REPLIES_REQUEST: 'GENERATE_REPLIES_REQUEST',
    GENERATE_REPLIES_SUCCESS: 'GENERATE_REPLIES_SUCCESS',
    GENERATE_REPLIES_FAILURE: 'GENERATE_REPLIES_FAILURE',

    CREATE_TEMPLATE_REQUEST: 'CREATE_TEMPLATE_REQUEST',
    CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
    CREATE_TEMPLATE_FAILURE: 'CREATE_TEMPLATE_FAILURE',

    GET_TEMPLATE_REQUEST: 'GET_TEMPLATE_REQUEST',
    GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
    GET_TEMPLATE_FAILURE: 'GET_TEMPLATE_FAILURE',

    UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
    UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
    UPDATE_TEMPLATE_FAILURE: 'UPDATE_TEMPLATE_FAILURE',

    UPDATE_RESPONSE_REQUEST: 'UPDATE_RESPONSE_REQUEST',
    UPDATE_RESPONSE_SUCCESS: 'UPDATE_RESPONSE_SUCCESS',
    UPDATE_RESPONSE_FAILURE: 'UPDATE_RESPONSE_FAILURE',

    GET_MEDIA_TYPES_REQUEST: 'GET_MEDIA_TYPES_REQUEST',
    GET_MEDIA_TYPES_SUCCESS: 'GET_MEDIA_TYPES_SUCCESS',
    GET_MEDIA_TYPES_FAILURE: 'GET_MEDIA_TYPES_FAILURE',

    UPDATE_SEGMENT_STATUS_REQUEST: 'UPDATE_SEGMENT_STATUS_REQUEST',
    UPDATE_SEGMENT_STATUS_SUCCESS: 'UPDATE_SEGMENT_STATUS_SUCCESS',
    UPDATE_SEGMENT_STATUS_FAILURE: 'UPDATE_SEGMENT_STATUS_FAILURE',

    GET_PROACTIVE_MESSAGES_LIST_REQUEST: 'GET_PROACTIVE_MESSAGES_LIST_REQUEST',
    GET_PROACTIVE_MESSAGES_LIST_SUCCESS: 'GET_PROACTIVE_MESSAGES_LIST_SUCCESS',
    GET_PROACTIVE_MESSAGES_LIST_FAILURE: 'GET_PROACTIVE_MESSAGES_LIST_FAILURE',

    GET_PROACTIVE_MSG_CONVERSATION_REQUEST: 'GET_PROACTIVE_MSG_CONVERSATION_REQUEST',
    GET_PROACTIVE_MSG_CONVERSATION_SUCCESS: 'GET_PROACTIVE_MSG_CONVERSATION_SUCCESS',
    GET_PROACTIVE_MSG_CONVERSATION_FAILURE: 'GET_PROACTIVE_MSG_CONVERSATION_FAILURE',

    EXPORT_PROACTIVE_MESSAGES_REQUEST: 'EXPORT_PROACTIVE_MESSAGES_REQUEST',
    EXPORT_PROACTIVE_MESSAGES_SUCCESS: 'EXPORT_PROACTIVE_MESSAGES_SUCCESS',
    EXPORT_PROACTIVE_MESSAGES_FAILURE: 'EXPORT_PROACTIVE_MESSAGES_FAILURE',

    UPLOAD_CUSTOMER_DATA_FILE_REQUEST: 'UPLOAD_CUSTOMER_DATA_FILE_REQUEST',
    UPLOAD_CUSTOMER_DATA_FILE_SUCCESS: 'UPLOAD_CUSTOMER_DATA_FILE_SUCCESS',
    UPLOAD_CUSTOMER_DATA_FILE_FAILURE: 'UPLOAD_CUSTOMER_DATA_FILE_FAILURE',

    GET_SEGMENT_TYPES_REQUEST: 'GET_SEGMENT_TYPES_REQUEST',
    GET_SEGMENT_TYPES_SUCCESS: 'GET_SEGMENT_TYPES_SUCCESS',
    GET_SEGMENT_TYPES_FAILURE: 'GET_SEGMENT_TYPES_FAILURE',

    CLEAR_CONVERSATION: 'CLEAR_CONVERSATION',
}

export const segmentTypes = {
    keyword: 'KEYWORD',
    customerIo: 'CUSTOMER_IO_SEGMENT',
    query: 'QUERY',
    customerDataFile: 'CUSTOMER_DATA_FILE'
}

export const excludedSegmentTypes = [
    segmentTypes.customerDataFile
]

export const channelTypes = {
    tikTok: 'TIKTOK',
    kustomerEmail: 'KUSTOMER_EMAIL',
    shopify: 'SHOPIFY',
    youtube: 'YOUTUBE',
};

export const disabledGenerateResponsesChannels = ['SHOPIFY', 'KUSTOMER_EMAIL']
export const proactiveEmailChannels = ['SHOPIFY', 'KUSTOMER_EMAIL']

export const suggestedResponseTypes = {
    userInput: 'USER_INPUT',
    generated: '"GENERATED"'
};

export const supportedChannelsForAttributes = [
    'SHOPIFY',
    'KUSTOMER_EMAIL'
]

export const supportedChannelsForKeywordSegment = [
    'TIKTOK',
]

export const supportedChannelsForQuerySegment = [
    'SHOPIFY',
]

export const supportedChannelsForCustomerIoSegment = [
    'KUSTOMER_EMAIL',
]

export const supportedChannelsForDateFilter = [
    'TIKTOK'
]

export const supportedChannelsForSegmentUpdate = [
    'TIKTOK'
]

export const supportedChannelsForFollowup = [
    'SHOPIFY', 'KUSTOMER_EMAIL'
]

export const supportedChannelsForTextSegment = supportedChannelsForQuerySegment + supportedChannelsForKeywordSegment

export const attributes = {
    CREATED_AT_SOURCE_AFTER_DATE_ATTRIBUTE: "CreatedAtSourceAfterDate",
    YOUTUBE_PUBLISHED_AFTER: 'publishedAfter',
    YOUTUBE_RELEVANCE_LANGUAGE: 'relevanceLanguage',
    YOUTUBE_SAFE_SEARCH: 'safeSearch',
    YOUTUBE_REGION_CODE: 'regionCode',
}