import {BasicPopover} from "../../../common/BasicPopover";
import EditButton from "./EditButton";
import {Box} from "@mui/material";
import BasicButton from "../../../common/BasicButton";
import React, {useRef, useState, Fragment} from "react";
import {userActions} from "../../../../../_actions";
import EditUserProfile from "./EditUserProfile";
import {formModes} from "../../../../../_constants";
import ImageEditor from "./ImageEditor";
import {useDispatch} from "react-redux";

export default function UserDropdownOptions({user}){
    const [openEditProfile, setOpenEditProfile] = useState(false);
    const [openImageEditor, setOpenImageEditor] = useState(false);
    const dispatch = useDispatch()
    const ref = useRef();

    const toggleEditProfile = () => setOpenEditProfile(!openEditProfile)
    const toggleEditImage = () => setOpenImageEditor(!openImageEditor)

    const handleSave = (params) => {
        dispatch(userActions.updateUser(params))
        toggleEditProfile()
    }

    return(
        <Fragment>
            <BasicPopover
                ref={ref}
                id={'edit-popup'}
                ButtonComponent={EditButton}>
                <Box sx={styles.popupContainer}>
                    <BasicButton
                        label={"Edit profile"}
                        style={styles.editButton}
                        action={toggleEditProfile}
                    />
                    <BasicButton
                        label={"Edit profile picture"}
                        style={styles.editButton}
                        action={toggleEditImage}
                    />
                </Box>
            </BasicPopover>


            <EditUserProfile
                openEditProfile={openEditProfile}
                handleCloseEditProfile={toggleEditProfile}
                user={user}
                mode={formModes.EDIT}
                editedBy="SELF"
                type="users"
                handleSave={handleSave}
            />
            <ImageEditor
                openImageEditor={openImageEditor}
                handleCloseEditImage={toggleEditImage}
                userId={user.id}
                type="users"
                useAspectRation
            />
        </Fragment>

    )
}

const styles = {
    popupContainer: {
        width: "200px",
        padding: "4px 0px"
    },
    editButton: {
        padding: "9px 15px",
        background: "transparent",
        borderRadius: "0px",
        justifyContent: "flex-start",
        color: '#368C86',
        "&:hover": {
            backgroundColor: "#E6E9E9",
            color: '#368C86',
        },
    }
}