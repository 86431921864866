import {SERVER_URL} from 'config';
import {authHeader, handleHttpResponse} from '_helpers';

function saveAccount(params) {
    return fetch(`${SERVER_URL}/api/customerio/accounts`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(params)
        })
        .then(handleHttpResponse);
}

export const customerIoAuthService = {
    saveAccount
};