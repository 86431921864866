import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {youtubeActions} from "_actions";
import {utilityService} from "_services";
import _ from "lodash";

export default function useYoutubeSetup() {
    const dispatch = useDispatch();
    const youtubeOAuth = useSelector((state) => state.youtube.oAuth);
    const loading = useSelector((state) => state.youtube.oAuthLoading);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const oAuthUrl = _.get(youtubeOAuth, 'url');

    const performAction = () => {
        setShouldRedirect(true)
        dispatch(youtubeActions.initiateOauth());
    };

    useEffect(() => {
        if (!loading && !_.isEmpty(oAuthUrl) && shouldRedirect){
            setShouldRedirect(false)
            utilityService.redirectToAuthUrl(oAuthUrl)
        }
    });

    return { initiateYouTubeSetup: performAction };
}