import React, {Component} from 'react'
import {reduxForm} from 'redux-form'
import {NextButton} from 'components/common'
import {connect} from 'react-redux'
import {msgSourceConstants} from "_constants";
import {twitterOauthActions, msgDataSourceActions} from '_actions'
import {facebookOauthService, utilityService} from "_services"
import 'react-widgets/dist/css/react-widgets.css'
import {Loading} from "components/common";
import {CopyrightFooter} from "components/common";
import {appCredentiasActions, zendeskOauthActions} from "_actions";
import gorgiasLogo from 'components/images/icon_gorgias.png'
import freshdeskLogo from 'components/images/icon_freshdesk.svg'
import kustomerLogo from 'components/images/kustomer.png'
import zendeskChatLogo from 'components/images/zendesk-chat.png'
import reamazeLogo from 'components/images/icon_reamaze.png'
import gladlyLogo from 'components/images/icon_gladly.png'
import shopifyLogo from 'components/images/icon_shopify.png'
import {history} from "_helpers";
import { AddInboxModal } from "components/AccountSettings";
import {GOOGLE_ANALYTICS_TRACKING_ID} from "_constants";
import ReactGA from "react-ga";

class AddChannel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sourceName: '',
        };
    }

    handleClick(source) {
        const callbackUrl = JSON.stringify(window.location);
        localStorage.setItem('redirectUrl', callbackUrl);
        utilityService.addChannel(this.props, source.name);
        this.setState({
            sourceName: source.name,
        })
    }

    initiateGorgiasAuth = (event) => {
        event.preventDefault();
        history.push('/gorgias/connect')
    };

    initiateFreshdeskAuth = (event) => {
        event.preventDefault();
        history.push('/freshdesk/connect')
    };

    initiateKustomerAuth =  (event) => {
        event.preventDefault();
        history.push('/kustomer/connect')
    };

    initiateZendeskChatAuth = (event) => {
        event.preventDefault();
        const callbackUrl = JSON.stringify(window.location);
        localStorage.setItem('redirectUrl', callbackUrl);
        history.push('/zendesk-chat/connect')
    }

    initiateReamazeAuth = (event) => {
        event.preventDefault();
        history.push('/reamaze/connect')
    }


    initiateGladlyAuth = (event) => {
        event.preventDefault();
        history.push('/gladly/connect')
    }

    initiateShopifyAuth = (event) => {
        event.preventDefault();
        history.push('/shopify/connect')
    }

    componentDidMount() {
        ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
        ReactGA.pageview(window.location.pathname);

        const currentUser = JSON.parse(localStorage.user);
        const orgId = currentUser._embedded.organization.id;
        const params = {organizationId: orgId};
        this.props.dispatch(msgDataSourceActions.getAll(params));
        this.props.dispatch(appCredentiasActions.getFacebookappCredentials());
        /**
         * upon login, this information is available in the redux state
         * only make a call when this information has been lost from
         * the state...maybe when the page is refreshed
         */
        let url = new URL(document.location);
        let values = new URLSearchParams(url.search);
        const oauthVerifier = values.get('oauth_verifier');
        const zendeskAuthCode = values.get('code');
        const data = {verifier: oauthVerifier, username: currentUser.username, userId: currentUser.id};

        if (oauthVerifier && currentUser) {
            this.submitVerifier(data);
        }

        if (zendeskAuthCode){
            const params = {code: zendeskAuthCode};
            const zendeskType = localStorage.getItem('zendeskType');
            if (zendeskType) {
                localStorage.removeItem('zendeskType');
                params.type = zendeskType
            }
            this.props.dispatch(zendeskOauthActions.genAccessToken(params))
        }
    }

    componentDidUpdate() {
        const facebookCreds = this.props.credentials.data;
        if (facebookCreds) {
            const {appId} = facebookCreds;
            facebookOauthService.loadFacebookApi(appId);
        }

        if (this.state.sourceName === this.props.twitter.data.messageSourceName) {
            utilityService.redirectToAuthUrl(this.props.twitter.data.authUrl)
        }

        if (this.state.sourceName === "frontapp" && !this.props.frontapp.loading) {
            utilityService.redirectToAuthUrl(this.props.frontapp.authUrl.url)
        }
        if (this.state.sourceName === "helpscout" && !this.props.helpscout.loading){
            utilityService.redirectToAuthUrl(this.props.helpscout.authUrl.url)
        }
        if (this.state.sourceName === "tiktok" && !this.props.tiktok.loading){
            utilityService.redirectToAuthUrl(this.props.tiktok.authUrl.url)
        }
        if (this.state.sourceName === "shopify" && !this.props.shopify.loading){
            utilityService.redirectToAuthUrl(this.props.shopify.authUrl.url)
        }
    }

    //submit the verifier and dispatch an action to generate the access token
    submitVerifier(data) {
        this.props.dispatch(twitterOauthActions.generateAccessToken(data));
    }

    renderMsgSource(msgSource) {


        return (
            <div
                className="card-container tms-cursor-pointer"
                onClick={() => this.handleClick(msgSource)} key={msgSource.id}>
                <div className="icon-container">
                    <img src={msgSource.image} alt={msgSource.name}/>
                </div>
                <div className="card-label color-grey-dark capitalize">
                    {msgSource.name}
                </div>
            </div>
        )
    }

    showNextButton() {

        /**
         * only show next button when oauth flow is
         * completed for current msgSource
         */
        return (
            <div>
                <NextButton to="/company/team"/>
            </div>
        )
    }

    render() {
        /**
         * msgDataSources represent a list of sources
         * added by the current user's organization
         * We know if a particular msgSource has been added
         * by a particular organization if we find that it is
         * contained in the list of msgDataSources for that organization
         * this info is pushed into the state during login so there is
         * no need to dispatch the action in this component
         */
        const {msgDataSources} = this.props;
        const msgSources = msgSourceConstants.MSG_SOURCES;
        const currentSource = this.state.sourceName;
        let currentlyAddedChannel = null;

        if (currentSource === 'twitter') {
            currentlyAddedChannel = this.props.twitter
        }
        if (currentSource === 'facebook') {
            currentlyAddedChannel = this.props.facebook
        }
        if (currentSource === 'instagram') {
            currentlyAddedChannel = this.props.instagram
        }

        /**
         * keep showing spinner until oauth flow for
         * current channel is complete (passes or fails)
         */

        if (currentlyAddedChannel && currentlyAddedChannel.loading) {
            return <Loading/>
        }

        return (
            <div className="company-admin main-content-wrapper">
                <div className="add-channel-container">
                    <p className="font-22-heavy color-grey-dark">
                        Connect your channels
                    </p>
                    <p className="font-15-bold color-grey-dark">
                        To begin using Chatdesk Teams, just connect your channels.
                    </p>

                    <div className="margin-top-50">
                        <div style={{maxWidth: "575px", textAlign: "left", margin: "0 auto"}}>
                            {msgSources.map((source) => {
                                return this.renderMsgSource(source)
                            })}
                            <div className="card-container tms-cursor-pointer"
                                 onClick={(e) => this.initiateGorgiasAuth(e)}>
                                <div className="icon-container-gorgias">
                                    <img src={gorgiasLogo} alt="Gorgias Logo"/>
                                </div>
                                <div className="card-label color-grey-dark capitalize">
                                    Gorgias
                                </div>

                            </div>
                            <div className="card-container tms-cursor-pointer"
                                 onClick={(e) => this.initiateKustomerAuth(e)}>
                                <div className="icon-container">
                                    <img src={kustomerLogo} alt="Kustomer Logo"/>
                                </div>
                                <div className="card-label color-grey-dark capitalize">
                                    Kustomer
                                </div>
                            </div>
                            <div className="card-container tms-cursor-pointer"
                                 onClick={(e) => this.initiateFreshdeskAuth(e)}>
                                <div className="icon-container">
                                    <img src={freshdeskLogo} alt="Freshdesk Logo"/>
                                </div>
                                <div className="card-label color-grey-dark capitalize">
                                    Freshdesk
                                </div>
                            </div>
                            <div className="card-container tms-cursor-pointer"
                                 onClick={(e) => this.initiateZendeskChatAuth(e)}>
                                <div className="icon-container">
                                    <img src={zendeskChatLogo} alt="Zendesk Chat Logo"/>
                                </div>
                                <div className="card-label color-grey-dark capitalize">
                                    Zendesk Chat
                                </div>
                            </div>
                            <div className="card-container tms-cursor-pointer"
                                 onClick={(e) => this.initiateReamazeAuth(e)}>
                                <div className="icon-container">
                                    <img className="icon-reamaze" src={reamazeLogo} alt="Reamaze Logo"/>
                                </div>
                                <div className="card-label color-grey-dark capitalize">
                                    Reamaze
                                </div>
                            </div>
                            <div className="card-container tms-cursor-pointer"
                                 onClick={(e) => this.initiateGladlyAuth(e)}>
                                <div className="icon-container">
                                    <img className="icon-gladly" src={gladlyLogo} alt="Gladly Logo"/>
                                </div>
                                <div className="card-label color-grey-dark capitalize">
                                    Gladly
                                </div>
                            </div>
                            <div className="card-container tms-cursor-pointer"
                                 onClick={(e) => this.initiateShopifyAuth(e)}>
                                <div className="icon-container">
                                    <img  src={shopifyLogo} alt="Shopify Logo"/>
                                </div>
                                <div className="card-label color-grey-dark capitalize">
                                    Shopify
                                </div>
                            </div>
                        </div>

                        {msgDataSources && msgDataSources.length > 0 ? this.showNextButton() : null}
                    </div>
                </div>
                <AddInboxModal />
                <CopyrightFooter/>
            </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        msgDataSources: state.msgDataSources.msgDataSources,
        twitter: state.twitterOauth,
        facebook: state.facebookOauth,
        instagram: state.instagramOauth,
        frontapp: state.frontAppOauth,
        helpscout: state.helpScoutOauth,
        tiktok: state.tiktokOauth,
        credentials: state.appcredentials,
    }
}

const formConfig = {
    form: 'msgSourceForm',
};

AddChannel = connect(
    mapStateToProps,
    null
)(AddChannel);

const connectedChannelSetting = reduxForm(formConfig)(AddChannel);

export {connectedChannelSetting as AddChannelSetting};
