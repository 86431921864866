import React, {Component} from "react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';


/**
 * this select field allows users to search and select multiple dropdown checkboxes
 */
class ChannelsDropDownSelect extends Component {

    constructor(props) {
        super(props);
        this.state={
            selectedValues: '',
            checkedPreviousValues: false
        }
    }

    componentDidUpdate = () => {
        const {options, checkedChannels} = this.props;
        if (!this.state.checkedPreviousValues){
            const prevChecked = this.previouslyCheckedChannels(options, checkedChannels)
            this.setState({
                selectedValues: prevChecked,
                checkedPreviousValues: true
            })
        }
    };

    handleChange = value => {
        this.setState({selectedValues: value, previousValues: value});
        this.updateParent(value)
    };

    previouslyCheckedChannels = (options, prevCheckedOptions) => {
        prevCheckedOptions = prevCheckedOptions.map(id => {return Number(id)});
        const result = [];
        options.forEach(currOption => {
            if (prevCheckedOptions.includes(currOption.value) > 0) {
                result.push(currOption)
            }
        });
        return result
    };


    updateParent(channels) {
        const currentIdsList = channels && channels.length > 0 ?
            channels.map(channel => {
                return channel.value
            }) : [];
        const {updateHandles, handleStatus, handleChange} = this.props;
        if (handleStatus) {
            updateHandles(currentIdsList, handleStatus);
        }
        if (currentIdsList.length > 0) {
            /**
             * handles channel filter change
             * keeps track of selected channels to maintain persistence
             * between page refreshes
             * i.e. maintain the previously selected channels in the dropdown select after
             * the page refreshes
             */
            handleChange(currentIdsList)
        }
    }

    render = () => {

        const {options} = this.props;
        return (
            <ReactMultiSelectCheckboxes
                options={options}
                value={this.state.selectedValues}
                onChange={this.handleChange}
                width="100%"
            />
        )
    }
}

export default ChannelsDropDownSelect