
/**
 * @author Chris Okebata
 */

const styles = {
    root: {
        flexGrow: 1,
        width:"auto",
        margin:"0"
    },
    gridCard:{
        width:"auto",
        padding:"40px"
    },
    gridHeader:{
        width:"auto",
        marginBottom:"5px"
    }
};


export { styles }