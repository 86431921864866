import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import SearchInput from "../Search/SearchInput";
import {adCommentsActions} from "../../_actions/adComments.actions";

class ListInstagramDataSources extends Component {

    constructor(props){
        super(props);
        this.state = {
            searchString: "",
            actualInput: ""
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAllDataSources({dataSource: 'instagram'}));
    }

    onSearch = (event) => {
        this.setState({
            searchString: this.state.actualInput
        });
    };

    onAdCommentsRefresh = (postData) => {
        const { dispatch } = this.props;
        dispatch(adCommentsActions.activatePoll({datasourceId: postData.datasourceId}));
    };

    updateStateSearchString = (event) => {
        this.setState({
            actualInput: event.target.value
        });
    }

    render() {
        const { instagramList, loading } = this.props;
        if (loading) {
            return <Loading/>

        }

        const filteredData = instagramList.filter((item) => {
            if (this.state.searchString === '') {
                return item;
            } else {
                return item.name.toLowerCase().includes(this.state.searchString.toLowerCase())
            }
        })

        return (
            <div>
                <Row>
                    <div className="search">
                        <SearchInput
                            searchAdminMessages={this.onSearch}
                            loading={this.props.loading}
                            searchString={this.state.actualInput}
                            onFormFieldChange={this.updateStateSearchString}
                        />
                    </div>
                </Row>
                <Row>
                    <Col xs={10} md={10}>
                        <table className='table trds-table'>
                            <thead className="font-18-bold">
                            <tr>
                                <th className="col-md-1">S/N</th>
                                <th>Name</th>
                                <th>Organization</th>
                                <th className="col-md-4">Refresh Ad Comments</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredData.map((dataSource, index) => (
                                <tr key={index}>
                                    <td className="vertical-align-middle">{index + 1}</td>
                                    <td className="vertical-align-middle">{dataSource.name}</td>
                                    <td className="vertical-align-middle">{dataSource.orgName}</td>
                                    <td>
                                        <Button className="margin-top-5" onClick={() => this.onAdCommentsRefresh({datasourceId: dataSource.trendsId})}>
                                            Refresh
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        );

    }
}

function mapStateToProps(state) {
    const { instagramOauth: { list, loading } } = state;
    return {
        instagramList: list, loading
    };
}

const connectedListInstagramDataSources = connect(mapStateToProps)(ListInstagramDataSources);
export { connectedListInstagramDataSources as ListInstagramDataSources };
