import {SERVER_URL} from 'config';
import {authHeader, getJSON, handleHttpResponse, history} from '_helpers/index';
import {oauthConstants} from "_constants";
import ChannelErrorModal from "../../components/common/Onboarding/Modal/ChannelErrorModal";
import MetaSetupModal from "../../components/common/Onboarding/Modal/MetaSetupModal";

export const facebookOauthService = {
    loadFacebookApi,
    fbLogin,
    submitSelectedPages,
    getPages,
    facebookLogin
};


export function loadFacebookApi(appId) {

    window.fbAsyncInit = function () {
        window.FB.init({
            appId: appId,
            cookie: true,
            status: true,
            xfbml: true,
            version: "v19.0"
        });
        window.FB.AppEvents.logPageView();
        window.FB.Canvas.setSize({width: 640, height: 480});
    };
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));
}

export function fbLogin() {

    const permissions = oauthConstants.TEAMS_FACEBOOK_APP_PERMISSIONS;
    /*
    do this check to avoid errors in the component when
    fb sdk has not loaded
    network issues may cause this particular problem
     */
    if (window.FB) {
        window.FB.login((response) => {
            if (response.status === "connected") {
                const authResponse = response.authResponse;
                verifyPermissions(authResponse);
            } else {
                alert("Something went wrong as we couldn't authenticate your login credentials");
            }
        }, {scope: permissions});
    }
}

export function facebookLogin() {
    const permissions = oauthConstants.TEAMS_FACEBOOK_APP_PERMISSIONS;

    /*
    do this check to avoid errors in the component when
    fb sdk has not loaded
    network issues may cause this particular problem
     */
    if (window.FB) {
        window.FB.login((response) => {
            if (response.status === "connected") {
                const authResponse = response.authResponse;
                validatePermissions(authResponse);
            } else {
              ChannelErrorModal.open('We are unable to authenticate your login credentials');
            }
        }, {scope: permissions});
    }
}

function verifyPermissions(authResponse) {
    getPermissions(authResponse).then(response => {
        /**
         * for the list of requested permission, check that all these permissions
         * have been granted for the current request
         * if not notify the user and end oauthFlow
         *
         * every is an ES6 array method that returns false if a single instance
         * violates a given check rule
         */
        const allpermissionsGranted = response.data.every((data) => {
            return data.status === "granted"
        });

        if (!allpermissionsGranted) {
            alert("All requested permissions are required for best results. Click ok to try again.");
            const callbackUrl = localStorage.getItem('redirectUrl');
            history.push(callbackUrl.pathname);
            return
        }

        const accessToken = authResponse.accessToken;
        const currentUser = JSON.parse(localStorage.user);
        const data = {
            accessToken: accessToken,
            fbUserId: authResponse.userID,
            userId: currentUser.id
        };
        return submitAccessToken(data)
    }).catch(err => {
        console.log(err)
    })
}

function validatePermissions(authResponse) {
    getPermissions(authResponse).then(response => {
        /**
         * for the list of requested permission, check that all these permissions
         * have been granted for the current request
         * if not notify the user and end oauthFlow
         *
         * every is an ES6 array method that returns false if a single instance
         * violates a given check rule
         */
        const allpermissionsGranted = response.data.every((data) => {
            return data.status === "granted"
        });

        if (!allpermissionsGranted) {
            ChannelErrorModal.open('All requested permissions are required');
            return
        }

        const accessToken = authResponse.accessToken;
        const currentUser = JSON.parse(localStorage.user);
        const data = {
            accessToken: accessToken,
            fbUserId: authResponse.userID,
            userId: currentUser.id
        };
        return saveAccessToken(data)
    }).catch(err => {
        console.log(err)
    })
}

const getPermissions = (payload) => {
    const permissionsUrl = `https://graph.facebook.com/v19.0/${payload.userID}/permissions`
    const authHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${payload.accessToken}`,
    }

    const options = {
        method: 'GET',
        headers: authHeader
    }

    return getJSON(permissionsUrl, options)
}

function submitAccessToken(data) {

    return fetch(`${SERVER_URL}/api/facebook/genAccessToken`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse)
        .then(
            response => {
                if (response) {
                    history.push("facebook/pages");
                }
            }
        )
}

function saveAccessToken(data) {
    return fetch(`${SERVER_URL}/api/facebook/genAccessToken`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse)
        .then(
            response => {
                if(response) MetaSetupModal.open(oauthConstants.FACEBOOK_CHANNEL);
            }
        )
}

function getPages() {

    return fetch(`${SERVER_URL}/api/facebook/pages`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse)

}

function submitSelectedPages(page) {

    return fetch(`${SERVER_URL}/api/facebook/submitpage`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(page)
        })
        .then(handleHttpResponse)
}
