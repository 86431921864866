import React from 'react';
import { Box, Stack } from '@mui/material';
import importedStyles from './Utilities/styles'
import useLocationQueryDateRange from 'components/v2/hooks/useLocationQueryDateRange';
import DateRangeForm from './DateRangeForm'

// V1 Components, replace these maybe with V2 Components later
import { CopyrightFooter } from "components/common";

// Content Sections
import PerformanceSection from './Sections/PerformanceSection';
import TicketVolumeSection from './Sections/TicketVolumeSection';
import ExpertInfoSection from './Sections/ExpertInfoSection';
import ImpactSection from './Sections/ImpactSection';

const MetricsDashboard = () => {
	const { startDate, endDate, setDates } = useLocationQueryDateRange()

	return (
		<>
			<Box sx={styles.root}>
				<Box sx={styles.container}>
					<Stack direction={{ xs: 'column', md: 'row' }} sx={styles.hero} spacing={2}>
						<Box component={'h1'} sx={importedStyles.headingLarge}>
							Teams Metrics
						</Box>
						<Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
							<DateRangeForm startDate={startDate} endDate={endDate} setDates={setDates} />
						</Box>
					</Stack>

					<Stack direction={'column'} spacing={4}>
						<PerformanceSection startDate={startDate} endDate={endDate} />
						<TicketVolumeSection startDate={startDate} endDate={endDate} />
						<ExpertInfoSection startDate={startDate} endDate={endDate} />
						<ImpactSection startDate={startDate} endDate={endDate} />
					</Stack>
				</Box>
			</Box>

			<CopyrightFooter />
		</>
	)
}

export default MetricsDashboard

const styles = {
	root: { 
		// Generic stylings
		...importedStyles.root,

		// Page related
		backgroundColor: '#F8F9F9',
		pt: 16,
		pb: 10,
		minHeight: '100vh',
	},

  container: { 
		px: { xs: 2, sm: 6 },
		mx: 'auto',
		maxWidth: '1440px',
		position: 'relative',
	},

	hero: {
		justifyContent: 'space-between',
		alignItems: { xs: 'flex-start', md: 'center' },
		mb: { xs: '24px', sm: '40px', },
	},
}