import {useEffect, useMemo, useRef} from 'react';
import {organizationActions, proactiveActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {buildGetOrgMsgDataSourcesBySourceNamePayload} from "../CampaignUtils";
import {MsgDataSourceBuilder} from "../objects/MsgDataSourceBuilder";
import {supportedChannelsForCustomerIoSegment} from "_constants";

export default function (params) {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            getMsgDataSources(params);
            return () => effectRan.current = true;
        }
    }, [params]);

    const getMsgDataSources = (params) => {
        if(_.has(params, 'organizationId')) {
            if(params.channel && supportedChannelsForCustomerIoSegment.includes(params.channel)) {
                const payload = buildGetOrgMsgDataSourcesBySourceNamePayload(params.organizationId)
                dispatch(proactiveActions.getOrgMsgDataSourcesBySourceName(payload));
            }
        }
        if(_.has(params, 'channel') && supportedChannelsForCustomerIoSegment.includes(params.channel)) {
            if(params.organizationId) {
                const payload = buildGetOrgMsgDataSourcesBySourceNamePayload(params.organizationId)
                dispatch(proactiveActions.getOrgMsgDataSourcesBySourceName(payload));
            }
        }
    }

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingMsgDataSourcesBySourceName;
    const msgDataSourceList = proactive.msgDataSourcesBySourceName || [];

    const msgDataSources = useMemo(() => {
        const msgDataSourceArr = [];
        if(!loading && msgDataSourceList.length > 0) {
            msgDataSourceList.map( msgDataSource => {
                const builder = new MsgDataSourceBuilder();
                builder.setId(_.get(msgDataSource, 'id') || '-');
                builder.setName(_.get(msgDataSource, 'name') || '-');
                builder.setMsgSourceName(_.get(msgDataSource, 'msgSourceName') || '-');

                msgDataSourceArr.push(builder.build());
            });
        }
        return msgDataSourceArr
    }, [ msgDataSourceList ]) || [];

    return {msgDataSources};
}