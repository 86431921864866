import React from 'react';
import Link from "@mui/material/Link";


export default function BasicLink({label, link, openInNewTab}) {

  return(
    <Link sx={{
      width: '100%',
      fontSize: '16px',
      padding: '0',
      color: '#368C86',
      fontFamily: 'LarsseitMedium',
      textTransform: 'none',
      ':hover': {
        color: '#285a55',
        backgroundColor: 'unset'
      }
    }} href={link} target={openInNewTab ? '_blank' : ''} rel="noopener" underline="none">
      {label}
    </Link>
  )
}