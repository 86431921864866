    import { msgSourceConstants } from '_constants/';

const INITIAL_STATE = {
    msgSources: [],
    msgSourcesList: { msgSource: [] },
    msgSourceLink: {msgSourceLink: ''},
    loading: false,
    error: '',
};

export function msgSourceReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case msgSourceConstants.GET_ALL_REQUEST:
            return { ...state, loading: true, error: '' };
        case msgSourceConstants.GET_ALL_SUCCESS:
            return { ...state, msgSources: action.payload, loading: false };
        case msgSourceConstants.GET_ALL_FAILURE:
            return { ...state, error: action.error, loading: false };

        case msgSourceConstants.GET_ALL_MSG_SOURCES_REQUEST:
            return { ...state, loading: true, error: '' };
        case msgSourceConstants.GET_ALL_MSG_SOURCES_SUCCESS:
            return { ...state, msgSourcesList: action.payload, loading: false };
        case msgSourceConstants.GET_ALL_MSG_SOURCES_FAILURE:
            return { ...state, error: action.error, loading: false };

        case msgSourceConstants.GET_A_MSG_SOURCE_REQUEST:
            return { ...state, error: '' };
        case msgSourceConstants.GET_A_MSG_SOURCE_SUCCESS:
            return { ...state, msgSourceLink: action.payload };
        case msgSourceConstants.GET_A_MSG_SOURCE_FAILURE:
            return { ...state, error: action.error };

        default:
            return state
    }
}
