import _ from "lodash";
import {getVotingOrganization} from "_helpers";
import {ResourcesSeparatorBuilder} from "./objects/ResourcesSeparatorBuilder";

export function buildGetAllResourcesByOrganization() {
    const organization = getVotingOrganization();
    const orgId = _.get(organization, 'id');
    return {
        isActive: true,
        organizationId: orgId,
    }
}

/**
 * @param resources {array}
 * @returns {*}
 */
export function separateResources(resources = []) {
    const builder = new ResourcesSeparatorBuilder();
    if(resources.length < 3) {
        builder.setVisibleList(resources);
        builder.setHiddenList([]);
        return builder.build();
    }

    builder.setVisibleList(resources.slice(0, 2));
    builder.setHiddenList(resources.slice(2));
    return builder.build();
}