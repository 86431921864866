import React, {Component, Fragment} from 'react'
import { GenericModal} from "components/common";
import {connect} from 'react-redux'
import {history} from '_helpers';

const addModalStyles = {
    buttonContainer : {
        marginTop: '15px'
    },
    label: {
        paddingLeft: 10
    },
    centeredStyle: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
};

class WebHookModal extends  Component {

    closeWebhookModal = () => {
        history.push('/company/channels')
    };
    copyToClipBoard = () => {
        let copyText = document.getElementById("input");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    };

    render() {
        const { showWebhookUrl, webhookUrl } = this.props;
        const modalTitle = 'Copy Webhook URL and paste in Kustomer';
        return(
            <GenericModal
                headerText = {modalTitle}
                hideFooter
                show = {showWebhookUrl}
                onHide = {this.closeWebhookModal}
                size = "lg"
            >
                {showWebhookUrl &&
                    <Fragment>
                        <div className="col-md-10">
                            <textarea className="form-control" rows={2} col={10} id="input" defaultValue={webhookUrl}></textarea>
                        </div>
                        <div className="col-md-2">
                        <button className='btn btn-success btn-sm font-16 copy-text' onClick = {this.copyToClipBoard}>
                            <i className="fa fa-copy"></i>
                        </button>
                        </div>
                    </Fragment>
                }
                <div className="row col-12" style={addModalStyles.buttonContainer}>
                    {showWebhookUrl &&
                    <button className='btn btn-success font-16 msg-source-submit-button' style={addModalStyles.centeredStyle}
                            onClick={this.closeWebhookModal}>Close</button>
                    }
                </div>
            </GenericModal>
        )
    }
}

function mapStateToProps(state) {
    const { kustomerOauth : { webhookUrl, showWebhookUrl }} = state;
    return {
        showWebhookUrl, webhookUrl
    }
}

const connectedAddWebhook = connect(mapStateToProps)(WebHookModal);
export { connectedAddWebhook as WebHookModal }