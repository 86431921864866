import React, { Component } from 'react'
import {connect} from "react-redux";
import {organizationActions, messageActions} from "_actions";
import {SnackBar} from "../common";
import {publicAndFeaturedVisibility} from "../../_constants";

class DeleteUserMessages extends Component {

    state = {
        organization: "",
        field: "",
        values: ""
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if(!this.state.field) {
            SnackBar.showMessage("Please select a field");
            return
        }

        if(!this.state.values) {
            SnackBar.showMessage("Please enter a value");
            return
        }

        this.props.dispatch(messageActions.deleteUserMessages(this.state))


    };

    componentDidMount() {
        this.props.dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}));
    }

    render() {
        const { organizations } = this.props;

        return (
            <div>
                <form>
                    <select name="organization" onChange={this.handleChange} value={this.state.organization}>
                        <option disabled value=""> -- select an organization -- </option>
                        {organizations && organizations.map(org =>
                            <option key={org.id} value={org.id}>{org.name}</option>
                        )}
                    </select>

                    <select required={true} name="field" onChange={this.handleChange} value={this.state.field} >
                        <option disabled value=""> -- select a field -- </option>
                        <option>id</option>
                        <option>author</option>
                        <option>sender</option>
                        <option>recipient_id</option>
                    </select>

                    <div>
                        <p>Enter the emails/ids separated by commas</p>
                        <textarea name="values" value={this.state.values} onChange={this.handleChange} style={{height: "100px", width: "30%"}}>
                        </textarea>
                    </div>

                    <button onClick={this.handleSubmit}>Delete messages</button>

                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        organizations: state.organizations.organizations.organizations
    }
}

const connectedDeleteUserMessages = connect(mapStateToProps, null)(DeleteUserMessages);

export {connectedDeleteUserMessages as DeleteUserMessages}
