const baseStyle = {
    width: "83%",
    height: "18px",
    margin: "0 auto",
    textAlign: "right",
    padding: "0 15px",
};

const regFormContainer = {
    inputField: {
        margin: "0px auto",
        height: "66px"
    },
    checkbox: {
        height: "58px"
    },
    button: {
        width: "315px",
        margin: "5px auto"
    },
    registerLink: {
        marginTop: "10px"
    },
    error: {
       ...baseStyle
    },
    normalMessage: {
        ...baseStyle
    },
    loginLinkContainer: {
        height: "10px",
        float: "right",
        position: "relative",
        right: "20px"
    },
    buttonContainer: {
        position: "absolute",
        top: "440px",
        width: "100%"
    },
    checkboxAccept: {
        width: "20px",
        height: "20px",
        paddingRight: "30px"
    },
    acceptText: {
        lineHeight: "18px"
    },
    checkboxSubscribe: {
        width: "20px",
        height: "20px",
        paddingRight: "30px"
    },
    subscribeText: {
        lineHeight: "18px"
    },
    width50FloatRight: {
        width: "50%",
        float: "right"
    },
    width50FloatLeft: {
        width: "50%",
        float: "left"
    }
    ,
    width83: {
        width: "83%",
        margin: "0 auto"
    },
    formHeadLabel: {
        position: "absolute",
        width: "100%",
        height: "auto",
        lineHeight: "40px",
        margin: "30px auto",
        padding: "0 33px",
        textAlign: "left"
    }
};

export { regFormContainer }