import {companyResourcesConstants, alertMessages, escalationConstants, adminSearchConstants,} from '_constants';
import {companyResourcesService, } from '_services';
import {SnackBar} from "../components/common";
import {alertActions} from "./alert.actions";
import {Auth} from "../components/Authorization";
import _ from "lodash";

function getActionData(actionType, payload = {}) {
    return {type: actionType, payload};
}

function getAll(params) {
    function request() {
        return { type: companyResourcesConstants.GET_ALL_COMPANY_RESOURCES_REQUEST };
    }

    function success(resources) {
        return { type: companyResourcesConstants.GET_ALL_COMPANY_RESOURCES_SUCCESS, payload: resources };
    }

    function failure(error) {
        return { type: companyResourcesConstants.GET_ALL_COMPANY_RESOURCES_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        companyResourcesService.getAll(params)
            .then(
                resources => {
                    dispatch(success(resources.data));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function createResource(params) {
    const request = () => ({
        type: companyResourcesConstants.CREATE_RESOURCE_REQUEST
    });
    const success = (response) => ({
        type: companyResourcesConstants.CREATE_RESOURCE_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: companyResourcesConstants.CREATE_RESOURCE_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        companyResourcesService.createResource(params)
            .then(response => {
                dispatch(success(response.data));
                dispatch(alertActions.clear());
                SnackBar.showMessage(alertMessages.CREATE_RESOURCE_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(error.response.data.message)
        });
    }
}

function getResource(id) {
    const request = () => ({
        type: companyResourcesConstants.GET_RESOURCE_REQUEST
    });
    const success = (response) => ({
        type: companyResourcesConstants.GET_RESOURCE_SUCCESS, payload: { ...response }
    });
    const failure = (error) => ({
        type: companyResourcesConstants.GET_RESOURCE_FAILURE, error, payload: { error }
    });

    return dispatch => {
        dispatch(request());
        companyResourcesService.getResource(id)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function updateResource(params) {
    const request = () => ({
        type: companyResourcesConstants.UPDATE_RESOURCE_REQUEST
    });
    const success = (response) => ({
        type: companyResourcesConstants.UPDATE_RESOURCE_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: companyResourcesConstants.UPDATE_RESOURCE_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        companyResourcesService.updateResource(params)
            .then(response => {
                dispatch(success(response.data));
                SnackBar.showMessage(alertMessages.UPDATE_RESOURCE_SUCCESS);
                window.location.replace('/company-resources');
            }).catch((error) => {
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.UPDATE_RESOURCE_ERROR);
        });
    }
}

function getAllResourcesByOrganization(params) {
    return (dispatch) => {
        dispatch(getActionData(companyResourcesConstants.GET_ALL_BY_ORG_RESOURCE_REQUEST));
        companyResourcesService.getAllResourcesByOrganization(params)
            .then((resources) => {
                    dispatch(getActionData(companyResourcesConstants.GET_ALL_BY_ORG_RESOURCE_SUCCESS, resources.data));
                },
            ).catch((error) => {
            dispatch(getActionData(companyResourcesConstants.GET_ALL_BY_ORG_RESOURCE_FAILURE), error);
            SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        });
    };
}

export const companyResourcesActions = {
    getAll,
    createResource,
    getResource,
    updateResource,
    getAllResourcesByOrganization
};