import React from 'react';

import { votingOptions } from '_constants';
import 'components/css/vote.css'
import { VoteButton } from 'components/Vote';
import { formattedResponseText } from '_helpers';


const votePanel = (props) => {

    const { onVoteTagSelect, selectedTag, messageAuthor, responseOption, agentName, messageHandleType } = props;
    const responseOptionText = formattedResponseText(responseOption && responseOption.text, messageAuthor, agentName, messageHandleType);

    return (

        <div style={{ display: 'flex' }}>
            <div style={{
                width: 'calc(100% - 350px)',
                height: '300px'
            }}>
                <div className="font-14 color-grey-mid margin-bottom-10">
                    Response
                </div>
                <div style={{
                    marginRight: '35px',
                    maxHeight: '450px',
                    overflowY: 'auto'
                }}
                     className="font-16 color-black-fade">
                    {responseOptionText}
                </div>
            </div>
            <div style={{
                width: '400px',
                height: '350px'
            }}>
                <div className="font-14 color-grey-mid margin-bottom-10">
                    Choose the best option
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <VoteButton
                        responseOption={responseOption}
                        value={votingOptions.APPROVE}
                        buttonName={'Perfect response'}
                        onSubmit={props.onSubmit}
                        onSelect={onVoteTagSelect}
                        selected={selectedTag === votingOptions.APPROVE}
                    />
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <VoteButton
                        responseOption={responseOption}
                        buttonName={'Edit response'}
                        onSubmit={props.onSubmit}
                        value={votingOptions.SEND_FOR_REVIEW}
                        onSelect={onVoteTagSelect}
                        selected={selectedTag === votingOptions.SEND_FOR_REVIEW}
                    />
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <VoteButton
                        responseOption={responseOption}
                        value={votingOptions.REJECT}
                        onSubmit={props.onSubmit}
                        buttonName={'Bad response'}
                        onSelect={onVoteTagSelect}
                        selected={selectedTag === votingOptions.REJECT}
                    />
                </div>
            </div>
        </div>
    );
};

export { votePanel as VotePanel }

