import React from "react";
import {Field, reduxForm} from 'redux-form'
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {QuillEditor, TextEditor} from "components/common";
import {isObjectEmpty} from "_helpers";
import {SearchableSelect} from "components/common/formFields/SearchableSelect";

const required = (value) => isObjectEmpty(value) ? "This field is required": undefined

const CompanyUpdatesAddForm = ({ handleSubmit, organizations, createUpdate}) => {

    return (
        <div className='container-pane container-pane-expert-page' style={{border: ' 1px solid red !important'}}>
            <div >
                <Form onSubmit={handleSubmit(createUpdate)} horizontal className="margin-top-50">
                    <FormGroup className="form-row">
                        <Col className="inbox-context-menu" style={{display: "block", width: '23%'}}>
                            <Field
                               validate={required}
                               options={organizations}
                               component={SearchableSelect}
                               name="organization"
                               submitValue={true}
                               placeholder="Select Organization"
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="form-row">
                        <Col className="inbox-context-menu" style={{display: "block", width: '21%'}}>
                            <Field
                                className="with-border margin-bottom-15 margin-top-15 padding-top-20 padding-bottom-20 form-control"
                                required={true}
                                component="input"
                                type="text"
                                name="summary"
                                placeholder="Summary"
                            />
                        </Col>
                    </FormGroup>


                    <FormGroup className="form-row">
                        <Col style={{width: '70%'}}>
                            <Field
                                name="description"
                                type="textarea"
                                defaultText=""
                                component={QuillEditor}
                                className="form-control"
                                rows={10}
                                useWithRedux={true}
                                required={true}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup style={{width: '72%'}} className="-align-left text-right margin-top-15">
                        <Button type="submit" className="btn btn-success font-15"
                                style={{padding: '30px 5'}}>Create Notification</Button>
                    </FormGroup>
                </Form>
            </div>
        </div>
    )
}


const connectedComponent =  reduxForm({
    form: 'chatdeskUpdatesForm',
})(CompanyUpdatesAddForm);

export {connectedComponent as CompanyUpdatesAddForm}