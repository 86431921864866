import React from "react";
import moment from 'moment/moment';
import { personalizeResponse, stripNumbersFromUsername  } from '_helpers';
import htmlParser from "he";
import _ from "lodash";

const MessageRow = (message, agent, agentNickname) => {
    const agentName = agentNickname || _.get(agent, 'firstName');
    const messageAuthor = message.author;

    const authorName = (message.direction === 'OUTGOING' && message.author === _.get(agent, 'email'))
        ? agentName : message.author;

    const dateCreated =  message.dateCreatedFromSource || message.dateCreated;
    const text = htmlParser.decode(
        personalizeResponse(message.text, messageAuthor, agentName, message.handleType),
    );

    return (
        <div className="author-conversation" key={message.id ? message.id : Math.random()}>
            <div className="conversation-header">
                <div className="author-name-conversation font-13-bold color-grey-mid">
                    {stripNumbersFromUsername(authorName)}
                </div>
                <div className="date-conversation font-13 color-grey-mid">
                    {moment.utc(dateCreated).format('MMM D')}
                    {' '}
                    {moment.utc(dateCreated).format('h:mm a')}
                </div>
            </div>
            <span className="color-black-fade font-15">
                <span>{text}</span>
            </span>
        </div>
    );
};

export default MessageRow;
