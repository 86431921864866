import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { collectHandles, sourceDeleted } from '_helpers';
import ChannelListItem from 'components/v2/views/Channels/Utilities/ChannelListItem';
import getChannelImgUrl from 'components/v2/views/Channels/Utilities/getChannelImgUrl';
import TikTokAddKeywordModal from './TikTokAddKeywordModal';
import ChannelAccordionItemAccountCard from './ChannelAccordionItemAccountCard';

export default function ChannelAccordionItem({
	msgSource,
	keyNumber,
}) {
	const handles = collectHandles(msgSource.msgDataSources);
	const sourceImg = getChannelImgUrl(msgSource.name);
	const deleted = sourceDeleted(msgSource.msgDataSources);
	const isTikTok = msgSource.name === 'TikTok';

	return !deleted ? (
		<Accordion disableGutters elevation={0} square sx={{
			'&:before': { display: 'none' },
			'&:not(:first-of-type)': {
				borderTop: '1px solid #b6bebf'
			},
		}}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ fontSize: '28px' }} />}
				aria-controls={`panel${keyNumber + 1}a-content`}
				id={`panel${keyNumber + 1}a-header`}
				sx={{
					px: '16px',
					'.MuiAccordionSummary-content': {
						my: '16px',
					}
				}}
			>
				<ChannelListItem 
					label={msgSource.name}
					description={(handles && handles.length) ? `${handles.length} channel${handles.length > 1 ? 's' : ''} connected` : '—'}
					iconImageSrc={sourceImg}
					iconSize='small'
				/>
			</AccordionSummary>
			<AccordionDetails sx={{ px: '16px', }}>
				<Box sx={{ pl: '76px' }}>
					{msgSource.msgDataSources.map((msgDataSource) => (
						<ChannelAccordionItemAccountCard
							msgDataSource={msgDataSource}
							key={msgDataSource.id}
						/>
					))}

					{(isTikTok) && (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, rowGap: 2, justifyContent: 'flex-end', alignItems: 'center', my: 2, }}>
							{isTikTok && <TikTokAddKeywordModal msgDataSources={msgSource.msgDataSources} />}
						</Box>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	) : <></>
}