import {Box} from "@mui/system";
import React from "react";
import StepIndicator from "./StepIndicator";


export default function SignupSteps({ isGetStartedActive, isConnectChannelActive, isScheduleCallActive }) {
  return(
    <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block'}} sx={{marginLeft: 'auto'}}>
      <Box style={styles.parentContainer}>
        <Box style={{...styles.container, paddingRight: '20px'}}>
          <StepIndicator
            number={1}
            label={'Get started'}
            isActive={isGetStartedActive}/>
        </Box>

        <Box style={{...styles.container, paddingRight: '20px'}}>
          <StepIndicator
            number={2}
            label={'Connect channel'}
            isActive={isConnectChannelActive}/>
        </Box>

        <Box style={styles.container}>
          <StepIndicator
            number={3}
            label={'Schedule call'}
            isActive={isScheduleCallActive}/>
        </Box>
      </Box>
      <Box style={styles.horizontalDivider}/>
    </Box>
  )
}

const styles = {
  parentContainer: {
    display: 'flex',
    width: 'fit-content',
    position: 'relative',
    zIndex: '1'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    alignItems: 'center',
    justifyContent:'center'
  },
  horizontalDivider: {
    borderTop: '1px solid #000000',
    width: '200px',
    position: 'relative',
    top: '-31px',
    left: '30px'
  }
};