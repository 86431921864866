import {useEffect, useMemo} from 'react';
import {proactiveActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {MediaTypeBuilder} from "../objects/MediaTypeBuilder";

export default function (params) {
    const dispatch = useDispatch();

    useEffect(() => {
        getMediaTypes(params);
    }, [params]);

    const getMediaTypes = (params) => {
        if(_.has(params, 'channel')) {
            dispatch(proactiveActions.getMediaTypes(params));
        }
    }

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingMediaTypes;
    const mediaTypesObj = proactive.mediaTypes;
    const mediaTypeList = _.get(mediaTypesObj, 'items') || [];

    const mediaTypes = useMemo(() => {
        const mediaTypeArr = [];
        if(!loading && mediaTypeList.length > 0) {
            mediaTypeList.map( mediaType => {
                const builder = new MediaTypeBuilder();
                builder.setId(_.get(mediaType, 'id') || '-');
                builder.setName(_.get(mediaType, 'name') || '-');
                builder.setChannel(_.get(mediaType, 'channel') || '-');

                mediaTypeArr.push(builder.build());
            });
        }
        return mediaTypeArr
    }, [ mediaTypeList ]) || [];

    return {mediaTypes, loading};
}