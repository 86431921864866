import React, {useEffect} from "react";
import {Box, Stack} from "@mui/material";
import currentUserHeroImage from '../../Employment/Utilities/default_header_bg.jpg'
import currentUserHeroImage2x from '../../Employment/Utilities/default_header_bg@2x.jpg'
import PageContainer from 'components/v2/common/PageContainer';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Loading} from "../../../../common";
import {organizationActions, systemSettingsActions} from "_actions";
import Avatar from "../../../common/Avatar";
import { getLogoUrl } from "_helpers";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import IconButton from "@mui/material/IconButton";
import CompanyProfileDetails from "../Utilities/CompanyProfileDetails"

export default function CompanyProfile(){
    const organization = useSelector((state) => state.organizations.organization)
    const loading = useSelector((state) => state.organizations.loading)
    const dispatch = useDispatch()
    const params = useParams();
    const {id} = params;

    const deletePhoto = () => {
        dispatch(organizationActions.deleteOrganizationLogo(id))
    };

    useEffect(() => {
        dispatch(organizationActions.getOrganization(id));
        dispatch(organizationActions.getOrgSlackStatus(id));
        dispatch(systemSettingsActions.showSystemSettings())
    },[])

    if (loading) {
        return <Loading />;
    }

    return(
        <PageContainer backgroundColor='#EFEAE7' size='large'>
            <Box sx={styles.root} component={'header'}>
                <Box sx={styles.heroImageContainer}>
                    <Box sx={styles.heroImage} component={'img'}
                         src={currentUserHeroImage}
                         srcSet={`${currentUserHeroImage} 1x, ${currentUserHeroImage2x} 2x`}
                    />
                </Box>

                <Box sx={styles.content}>
                    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={{ xs: 2, lg: 3 }} sx={{ position: 'relative' }}>
                        <Box sx={{position: "relative", width: "fit-content"}}>
                            <Avatar src={organization.logo ? getLogoUrl(organization.logo) : ""} userName={organization.name} background="dark" variant={'rounded'} size={'xlarge'} />

                            <IconButton component={'button'} size={'small'} sx={styles.deleteButton} onClick={deletePhoto}>
                                <ClearOutlinedIcon sx={{fontSize: '20px'}} />
                            </IconButton>
                        </Box>

                        <Stack direction={{ xs: 'column', lg: 'row' }} sx={{ flex: 1}} spacing={4}>
                            <CompanyProfileDetails organization={organization} />
                        </Stack>
                    </Stack>
                </Box>
            </Box>

            <Box sx={styles.overviewContainer}>
                <Stack spacing={{ xs: 2, md: 3 }}>
                    <Box>
                        <Box sx={styles.smallHeadline}>Overview</Box>
                        <Box sx={styles.bioDescription}>{organization.overview ? organization.overview : "No overview"}</Box>
                    </Box>
                </Stack>
            </Box>
        </PageContainer>
    )
}

const styles = {
    root: {
        borderRadius: '10px',
        overflow: 'hidden',
    },
    heroImageContainer: {
        width: '1342px',
        position: 'relative',
        height: '220px',
        background: '#DCD8FF',
    },
    heroImage: {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        border: 'none',
        '&:not([src])': {
            visibility: 'hidden',
        },
    },
    content: {
        p: { xs: 2, lg: 4, },
        backgroundColor: '#fff',
    },
    overviewContainer: {
        backgroundColor: '#fff',
        border: '1px solid #D0D9D9',
        borderRadius: '10px',
        overflow: 'hidden',
        flex: 1,
        px: { xs: 2, md: 3 },
        py: { xs: 2, md: 4 },
        mt: "48px"
    },
    bioDescription: {
        fontSize: { xs: '16px', md: '20px' },
        mt: 1.5,
    },
    smallHeadline: {
        fontFamily: 'larsseitBold',
        fontSize: { xs: '14px', md: '16px' },
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
        mb: 1,
    },
    deleteButton: {
        position: "absolute",
        bottom: "-3px",
        right: "-5px",
        zIndex: 999,
        width: "30px",
        height: "30px",
        border: "1.5px solid #144042",
        color: "#368C86",
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        },
    },
}
