import { Fragment } from 'react'
import { Box } from '@mui/material'
import TextButton from './TextButton';
import ChannelAccordionItemHandle from './ChannelAccordionItemHandle';
import { useRemoveAccountModal, RemoveAccountModal } from './RemoveAccountModal';
import {ApplicationDetailsModal, useApplicationDetailsModal} from "./ApplicationDetailsModal";

const CardEmptyState = () => (
	<Box sx={styles.CardEmptyState}>0 channels connected</Box>
)

const hasApplications = (msgDataSource) => {
	return msgDataSource && msgDataSource.applications && msgDataSource.applications.length > 0
}

export default function ChannelAccordionItemAccountCard({
	msgDataSource
}) {
	const { open, removeMsgDataSourceId, removeMsgDataSourceName, handleCancel, handleClickRemoveAccount } = useRemoveAccountModal()
	const { appDetailsOpen, applicationId, handleClickApplicationDetails, handleCloseAppDetails } = useApplicationDetailsModal()

	return (
		<Fragment>
			<Box component={'article'} sx={styles.root}>
				<Box sx={styles.cardHeader}>
					{msgDataSource.name}
					<Box>
						<Box>
							<TextButton label='Remove' onClick={() => handleClickRemoveAccount({
								msgDataSourceId: msgDataSource.id,
								msgDataSourceName: msgDataSource.name
							})}/>
						</Box>
						{
							hasApplications(msgDataSource) &&
							<Box>
								<TextButton label='View Application' onClick={() => handleClickApplicationDetails({
									applicationId: msgDataSource.applications[0].id
								})}/>
							</Box>
						}
					</Box>
				</Box>

				<Box component={'ul'} sx={{ m: 0, p: 0 }}>
					{(msgDataSource.dataSourceHandles && msgDataSource.dataSourceHandles.length)
						? msgDataSource.dataSourceHandles.map((handle, key) => (
							<ChannelAccordionItemHandle
								key={key}
								handle={handle}
							/>
						)) : (
							<CardEmptyState />
						)}
				</Box>
			</Box>

			<RemoveAccountModal {...{ open, removeMsgDataSourceId, removeMsgDataSourceName, handleCancel }} />
			{hasApplications(msgDataSource) && <ApplicationDetailsModal {...{ appDetailsOpen, applicationId, handleCloseAppDetails }} />}
		</Fragment>
	)
}

const styles = {
	root: {
		border: '1px solid #b6bebf',
		backgroundColor: '#F8F9F9',
		borderRadius: '10px',
		overflow: 'hidden',
		py: 0.5,
		mb: '16px',
	},

	cardHeader: {
		fontFamily: 'LarsseitMedium', fontSize: 18, lineHeight: 1.2, letterSpacing: '-0.02em',
		p: 2,
		py: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	CardEmptyState: {
		opacity: .66,
		fontFamily: 'LarsseitRegular', fontSize: 18, lineHeight: 1.2, letterSpacing: '-0.02em',
		px: 2,
		py: 1.5,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	}
}