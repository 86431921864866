import axios from "axios";
import {authHeader} from "_helpers";
import {SERVER_URL} from "../../config";

function initiateOAuth() {
    const endpoint = `${SERVER_URL}/api/v1/youtube/oauth`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function createAccount(payload) {
    const endpoint = `${SERVER_URL}/api/v1/youtube/accounts`;
    return axios.post(endpoint, payload,{
        headers: authHeader()
    });
}

function saveChannel(payload) {
    const endpoint = `${SERVER_URL}/api/v1/youtube/channels`;
    return axios.post(endpoint, payload,{
        headers: authHeader()
    });
}

export const youtubeOAuthService = {
    initiateOAuth,
    createAccount,
    saveChannel
};