import React, { Fragment } from 'react'
import { AgentPayoutModal, AgentPayoutHistoryList } from 'components/Payments'

const AgentPayoutHistory = (props) => {
    const { agent, ...rest } = props
    return (
        <Fragment>
            <AgentPayoutHistoryList
                {...rest}
                agent={agent}
            />
            <AgentPayoutModal
                {...rest}
                agent={agent}
            />
        </Fragment>
    )
};

export { AgentPayoutHistory }
