import React from 'react'

const UsageDetails = (props) => {
    const { total_amount } = props

    return (
        <div style={{paddingBottom: '30px'}}>
            <h3 style={{fontWeight: 'bold', marginBottom: '15px'}}>
                Total: $ {total_amount}
            </h3>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>{''}</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Business Hours</td>
                        <td>10</td>
                        <td>$ 15</td>
                    </tr>
                    <tr>
                        <td>After Hours</td>
                        <td>20</td>
                        <td>$ 38</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default UsageDetails