import React, {Fragment} from "react";
import {Box, useTheme} from "@mui/material";
import ActionButton from "../../common/ActionButton";
import _ from "lodash";
import {useSelector} from "react-redux";
import {filterObjectValue, getLogoUrl} from "_helpers";
import logoPlaceholder from "components/images/icon_business.png";
import {Loading} from "../../../common";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

export default function ContinuePracticing({ employmentList, handleSubmit }){
    const loading = useSelector(state => state.employment.loading);

    const EmploymentRow = ({ employment, handleSubmit }) => {
        const {organization} = employment
        const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('md'));

        const organizationId = _.get(organization, 'id');
        const organizationName = _.get(organization, 'name');
        const organizationLogo = _.get(organization, 'logo');
        const organizationOverview = _.get(organization, 'overview');

        const scheduledOrganizations = useSelector(state => state.schedules.scheduledOrganizations.scheduledOrganizations)
        const scheduledObj = filterObjectValue(scheduledOrganizations || [], 'id', organizationId);

        function answerMessages(){
            handleSubmit(organizationId, scheduledObj)
        }

        return(
            <Box sx={{borderTop: '1px solid #DBDFDF', height: isMobile ? '100px' : '115px'}}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: isMobile ? 1 : 2, paddingRight: isMobile ? 1 : 2, height: '100%'}}>
                    <Box sx={{...styles.imgContainer, width: isMobile ? "70px" : "180px", paddingLeft: isMobile ? 1 : 2}}>
                        <img
                            src={organizationLogo ? getLogoUrl(organizationLogo) : logoPlaceholder}
                            alt={organizationName}
                            style={{...styles.imgStyle, maxWidth: isMobile ? "70px" : "180px"}}
                        />
                    </Box>
                    <Box sx={{flex: '1 1 0px'}}>
                        <Box style={{fontSize: "14px", color: '#0C2728', fontFamily: 'larsseitBold', letterSpacing: "0.7px", textTransform: "uppercase"}} className="exp-dashboard-truncate-1-line">
                            {organizationName}
                        </Box>
                        <Box style={{fontSize: '16px', color: '#0C2728',fontFamily: 'larsseitRegular', maxWidth: '600px'}} className="exp-dashboard-truncate-2-lines">
                            {organizationOverview}
                        </Box>
                    </Box>
                    <Box >
                        <ActionButton
                            label={'Practice'}
                            action={answerMessages}
                            sx={{fontSize: "16px"}}
                            buttonBordered={true}
                            useBorderColor={'#368C86'}
                        />
                    </Box>
                </Box>
            </Box>
        )
    }

    return(
        <Box sx={{backgroundColor: '#FFFFFF', mb: 3, border: '1px solid #CED4D4', borderTop: 'none', borderRadius: '0px 0px 5px 5px'}}>
            { loading && <Loading height={200} /> }
            { !loading && employmentList.length > 0 &&
                <>
                    { employmentList.map((employment) => {
                        return(
                            <Fragment key={employment.id}>
                                <EmploymentRow employment={employment} handleSubmit={handleSubmit} />
                            </Fragment>
                        )
                    })}
                </>
            }
        </Box>
    )
}

const styles = {
    imgContainer: {
        minWidth: '40px',
        width: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imgStyle: {
        minWidth: '40px',
        maxHeight: '40px',
        borderRadius: '5px',
    }
}