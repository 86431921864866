import { Box, Stack, Rating } from '@mui/material'

/**
 * @param {object} params
 * @param {number | undefined} params.score
 */
export default function PersonRating({ score }) {
	return score ? (
		<Stack direction={'row'} alignItems={'center'} spacing={0.5}>
			<Rating sx={styles.rating} value={score} precision={0.5} readOnly />
			<Box sx={{ fontFamily: 'larsseitMedium' }}>{score.toFixed(1)}</Box>
		</Stack>
	) : null
}

const styles = {
	rating: {
		'& .MuiRating-icon': {
			color: '#1C1B1F',
		},
	},
}