import React, { Component } from 'react'
import connect from "react-redux/es/connect/connect";
import { Link } from 'react-router-dom';
import lockIcon from 'components/images/icon_lock.svg'
import userIcon from 'components/images/icon_user.svg'
import calendarIcon from 'components/images/icon-date_range.svg'
import companyIcon from 'components/images/icon_company.svg'
import { userRoles } from "_constants";
import Grid from '@material-ui/core/Grid';
import {styles} from "components/Profile/styles";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/01/2019 : 12:21 PM
 * @author Chris Okebata
 */


const allRoles = [
    userRoles.COMPANY_ADMIN_ROLE,
    userRoles.CHATDESK_ADMIN_ROLE,
    userRoles.CHATDESK_AGENT_ROLE,
    userRoles.COMPANY_USER_ROLE,
    userRoles.CHATDESK_REVIEWER_ROLE
];

const profileContents = (organizationId, userId) => ([
    {
        name: "Change Password",
        icon: `${lockIcon}`,
        link: `/update-password`,
        permission: allRoles
    },

    {
        name: "My Profile",
        icon: `${userIcon}`,
        link: `/users/${userId}/profile`,
        permission: allRoles
    },

    {
        name: "Company Profile",
        icon: `${companyIcon}`,
        link: `/company/${organizationId}/profile`,
        permission: [userRoles.COMPANY_ADMIN_ROLE]
    },

    {
        name: "Team Schedule",
        icon: `${calendarIcon}`,
        link: `/company/${organizationId}/schedules`,
        permission: [userRoles.COMPANY_ADMIN_ROLE]
    }

]);


const RenderProfileButtons = ({organizationId, userId, role}) => {

    return profileContents(organizationId, userId).map((profile, it) => {
            if(profile.permission.includes(role)){
                return(
                    <Link to={profile.link} key={it}>
                        <div className=" margin-top-20">
                            <div className="card-container tms-cursor-pointer">
                                <div className="icon-container">
                                    <img src={profile.icon} alt={profile.name}/>
                                </div>
                                <div className="card-label color-grey-dark capitalize font-14">
                                    {profile.name}
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            } else {
                return null
            }
        }
    )
};


class ProfilePage extends Component {

    currentUser = JSON.parse(localStorage.getItem('user'));

    render() {

        const { organization_id, id, roles } = this.currentUser;
        const currentUserRole = roles[0];
        const spacing = 16;

        const { root, gridCard, gridHeader } = styles;

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <Grid container style={root} spacing={spacing} justify="flex-start">
                    <Grid item style={gridCard} className="shadow-border">
                        <Grid style={gridHeader} className="font-16-bold color-grey-dark">
                            Profile
                        </Grid>
                        <Grid container justify="flex-start" spacing={spacing}>
                            <RenderProfileButtons
                                organizationId={organization_id}
                                userId={id}
                                role={currentUserRole}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}


const connectedProfilePage = connect(null)(ProfilePage);
export { connectedProfilePage as ProfilePage };

