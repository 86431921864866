import React from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Twemoji } from "react-emoji-render";
import Typography from '@material-ui/core/Typography';
import {stylesTable} from 'components/Inbox/StylesTable'
import { formatDateTime, getTimeInEST, history } from "_helpers";

/**
 * @author Chris Okebata
 */


const handleNavigation = (msgId) => {
    history.push(`/notifications/${msgId}?type=review`)
};

const ReviewsTable = (props) => {
    const { classes, tableBody } = props;

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableBody>
                    {tableBody.map(message => (
                        <TableRow key={message.id} className={classes.tableRow} onClick={()=> handleNavigation(message.id)}>
                            <TableCell component="th" scope="row" className={classes.tableCell}>
                                <Typography className={classes.typography15}><Twemoji text={message.text}/></Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography className={classes.typography14}>
                                    {formatDateTime(getTimeInEST(message.dateCreated))}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

ReviewsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(stylesTable)(ReviewsTable);
