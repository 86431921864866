import {tiktokShopConstants} from "_constants";
import _ from "lodash";
import {getArrayFromObjectArray} from "_helpers";

const INITIAL_STATE = {
    auth: {},
    authLoading: false,
    authError: '',

    shopDetails: {},
    loadingShops: false,
    shopsError: '',

    products: [],
    loadingProduct: false,
    productError: '',

    orderDetailsList: [],
    loadingOrderDetails: false,
    orderDetailsError: '',

    orders: [],
    loadingOrders: false,
    ordersError: ''
};

export function tiktokShopReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case tiktokShopConstants.INITIATE_AUTH_REQUEST:
            return {...state, authLoading: true};
        case tiktokShopConstants.INITIATE_AUTH_SUCCESS:
            return {...state, auth: action.payload, authLoading: false};
        case tiktokShopConstants.INITIATE_AUTH_FAILURE:
            return {...state, authError: action.error, authLoading: false};

        case tiktokShopConstants.CREATE_ACCOUNT_REQUEST:
            return {...state, loadingShops: true};
        case tiktokShopConstants.CREATE_ACCOUNT_SUCCESS:
        case tiktokShopConstants.NO_ACCOUNT_FOUND:
        case tiktokShopConstants.SELECT_SHOP_RESPONSE:
            return {...state, shopDetails: action.payload, loadingShops: false, authError: ''};
        case tiktokShopConstants.CREATE_ACCOUNT_FAILURE:
            return {...state, shopDetails: {}, authError: action.error, loadingShops: false};

        case tiktokShopConstants.SAVE_SHOP_REQUEST:
            return {...state, loadingShops: true};
        case tiktokShopConstants.SAVE_SHOP_SUCCESS:
            return {...state, shopDetails: action.payload, loadingShops: false};
        case tiktokShopConstants.SAVE_SHOP_FAILURE:
            return {...state, authError: action.error, loadingShops: false};

        case tiktokShopConstants.GET_PRODUCT_REQUEST:
            return {...state, loadingProduct: true};
        case tiktokShopConstants.GET_PRODUCT_SUCCESS:
            if(!_.isEmpty(state.products)) {
                const productIds = getArrayFromObjectArray(state.products, 'id') || [];
                if(productIds.includes(_.get(action, 'payload.id'))) {
                    return state;
                }
            }
            return {...state, products: [...state.products, action.payload], loadingProduct: false};
        case tiktokShopConstants.GET_PRODUCT_FAILURE:
            return {...state, productError: action.error, loadingProduct: false};

        case tiktokShopConstants.GET_ORDER_DETAILS_REQUEST:
            return {...state, loadingOrderDetails: true};
        case tiktokShopConstants.GET_ORDER_DETAILS_SUCCESS:
            if(!_.isEmpty(state.orderDetailsList)) {
                const orderIds = getArrayFromObjectArray(state.orderDetailsList, 'id') || [];
                const fetchedOrderIds = getArrayFromObjectArray(_.get(action, 'payload.orders'), 'id') || [];
                if(orderIds.every(i => fetchedOrderIds.includes(i))) {
                    return state;
                }
            }
            const orderArr = _.get(action, 'payload.orders') || [];
            const orderDetailsArr = orderArr.map(order => { return [...state.orderDetailsList, order]})[0];
            return {...state, orderDetailsList: orderDetailsArr, loadingOrderDetails: false};
        case tiktokShopConstants.GET_ORDER_DETAILS_FAILURE:
            return {...state, orderDetailsError: action.error, loadingOrderDetails: false};

        case tiktokShopConstants.GET_ORDERS_REQUEST:
            return {...state, loadingOrders: true};
        case tiktokShopConstants.GET_ORDERS_SUCCESS:
            return {...state, orders: action.payload, loadingOrders: false};
        case tiktokShopConstants.GET_ORDERS_FAILURE:
            return {...state, ordersError: action.error, loadingOrders: false};

        default:
            return state
    }
}