import * as React from 'react';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

export default function ScheduleActions({id, orgId, deleteSchedule}) {

    return (
        <Tooltip
            title={'Delete schedule'}
            componentsProps={{tooltip: { sx: styles.tooltip }}}>
            <IconButton
                component={'button'}
                size={'small'}
                onClick={() => {
                    const props = {id, orgId};
                    deleteSchedule(props)
                }}
            >
                <DeleteIcon sx={styles.deleteIcon}/>
            </IconButton>
        </Tooltip>
    )
}


const styles = {
    deleteIcon: {
        color:'#AC2925',
        fontSize: '22px',
        textAlign: 'right'
    },
    tooltip: {
        backgroundColor: '#DBD8FF',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        fontSize: '13px',
    },
}