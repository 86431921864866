import React, {useEffect, useRef, useState} from "react";
import {Box, Grid, IconButton, Switch} from "@mui/material";
import BasicFormTextField from "../../../common/BasicFormTextField";
import StatusSelect from "../../../common/StatusSelect";
import {Field, reduxForm, change} from 'redux-form'
import {isValidDate, parseDates, required} from "_helpers";
import {connect, useDispatch, useSelector} from "react-redux";
import BasicMultilineTextField from "../../../common/BasicMultilineTextField";
import OrganizationSelect from "../../../common/OrganizationSelect";
import ChannelSelect from "../../../common/ChannelSelect";
import ProactiveCommentSelectionModeSelect from "../Utilities/ProactiveCommentSelectionModeSelect";
import TemplateSelect from "../../../common/TemplateSelect";
import AnchorButton from "../../../common/AnchorButton";
import ActionButton from "../../../common/ActionButton";
import SingleDatePicker from "../../../common/SingleDatePicker";
import moment from "moment";
import {SnackBar} from "../../../../common";
import {proactiveActions} from "_actions";
import _ from "lodash";
import ProactiveMsgDataSourceMultiSelect from "../../../common/ProactiveMsgDataSourceMultiSelect";
import {buildCreateCampaignPayload, buildCSVFormData, verifyCsvFileSize} from "../Utilities/CampaignUtils";
import MediaTypeMultiSelect from "../../../common/MediaTypeMultiSelect";
import CampaignAttribute from "../Utilities/CampaignAttribute/CampaignAttribute";
import {
    segmentTypes,
    supportedChannelsForAttributes,
    supportedChannelsForCustomerIoSegment,
    supportedChannelsForDateFilter
} from "_constants";
import CampaignSegment from "../Utilities/CampaignSegment";
import CampaignDateFilter from "../Utilities/CampaignDateFilter";
import YoutubeCampaignParams from "../Utilities/YoutubeCampaignParams";
import useGetCampaign from "../Utilities/hooks/useGetCampaign";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import useGetSegmentTypes from "../Utilities/hooks/useGetSegmentTypes";

function CreateCampaignForm({ handleSubmit }) {
    const dispatch = useDispatch();
    const { campaignObj, loading } = useGetCampaign();
    const params = useParams();
    const {id} = params;
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [queryParam, setQueryParam] = useState({});
    const [channel, setChannel] = useState(null);
    const [mediaTypes, setMediaTypes] = useState(null);
    const [csvFile, setCsvFile] = useState("");
    const [csvFileName, setCsvFileName] = useState("");
    const [isFollowUpEnabled, setIsFollowUpEnabled] = useState(false);
    const [isPresaleEnabled, setIsPresaleEnabled] = useState(false);
    const csvFileRef = useRef(null)
    const selectPlaceholder = 'Choose';
    const { segmentTypes: channelSegments } = useGetSegmentTypes(channel);

    const handleSelectChange = (event) => {
        if (event.target.name === 'organizationId') {
            setQueryParam({...queryParam, organizationId: event.target.value });
        } else if (event.target.name === 'channel') {
            setQueryParam({...queryParam, channel: event.target.value });
            setChannel(event.target.value);
            dispatch(change('CreateCampaignForm','segments', undefined));
            dispatch(change('CreateCampaignForm', 'tiktokDateFilter', undefined));
            dispatch(change('CreateCampaignForm', 'publishedAfter', undefined));
            dispatch(change('CreateCampaignForm', 'regionCode', undefined));
            dispatch(change('CreateCampaignForm', 'relevanceLanguage', undefined));
            dispatch(change('CreateCampaignForm', 'safeSearch', undefined));
        } else if (event.target.name === 'mediaTypes') {
            setMediaTypes(event.target.value);
        }
    }

    const handleMsgDataSourceSelectChange = (value) => {
        dispatch(change('CreateCampaignForm','msgDataSourceIds', value));
    }

    const handleMediaTypeSelectChange = (value) => {
        dispatch(change('CreateCampaignForm','mediaTypes', value));
    }

    const handleTemplateSelectChange = (value) => {
        dispatch(change('CreateCampaignForm','templateId', value));
    }

    const handleSegmentSelectChange = (value) => {
        dispatch(change('CreateCampaignForm','segments', value));
    }

    const handleTikTokDateFilterChange = (value) => {
        dispatch(change('CreateCampaignForm', 'tiktokDateFilter', value))
    }

    const handleYoutubeDateFilterChange = (value) => {
        dispatch(change('CreateCampaignForm', 'publishedAfter', value))
    }

    const handleRegionSelectChange = (value) => {
        dispatch(change('CreateCampaignForm', 'regionCode', value))
    }

    const handleRelevanceLanguageSelectChange = (value) => {
        dispatch(change('CreateCampaignForm', 'relevanceLanguage', value))
    }

    const handleSafeSearchSelectChange = (value) => {
        dispatch(change('CreateCampaignForm', 'safeSearch', value))
    }

    const handleIsFollowUpEnabled = (event) => {
        const value = event.target.checked;
        setIsFollowUpEnabled(value);
        dispatch(change('CreateCampaignForm', 'isFollowUpEnabled', value));
    }

    const handleIsPresaleEnabled = (event) => {
        const value = event.target.checked;
        setIsPresaleEnabled(value);
        dispatch(change('CreateCampaignForm', 'isPresaleEnabled', value));
    }

    const handleFollowUpTemplate = (value) => {
        dispatch(change('CreateCampaignForm', 'followUpTemplate', value));
    }

    const proactive = useSelector(state => state.proactive);
    const loadingCreateCampaign = proactive.loadingCreateCampaign;
    const uploadingCustomerData = proactive.uploadingCustomerData;

    const isSelectionModeSupported = () => {
        return channel === 'TIKTOK' && mediaTypes && mediaTypes.includes('TIKTOK_POST_COMMENT');
    };

    const isAttributeSupported = supportedChannelsForAttributes.includes(channel);
    const isDateFilterSupported = supportedChannelsForDateFilter.includes(channel);
    const isCsvUploadSupported = channelSegments && channelSegments?.find((item) => item.id === segmentTypes.customerDataFile)
    const isYoutubeChannel = channel === 'YOUTUBE';
    const isEmailChannel = channel === 'SHOPIFY' || channel === 'KUSTOMER_EMAIL';
    const isFollowUpSupported = isEmailChannel;
    const isPresaleSupported = channel === 'SHOPIFY';

    const handleCreateCampaign = (values) => {
        const parsedDate = parseDates(startDate, endDate);
        if (!isValidDate(parsedDate.startDate, parsedDate.endDate)) {
            SnackBar.showMessage("End date cannot be earlier than start date");
            return;
        }
        if (_.isEmpty(values)) {
            return;
        }
        if (!isSelectionModeSupported()) {
            delete values.selectionMode;
        }
        if(csvFile){
            const formData = buildCSVFormData(csvFile)
            dispatch(proactiveActions.uploadCustomerDataFile(formData, values, parsedDate))
        } else {
            const payload = buildCreateCampaignPayload(values, parsedDate);
            dispatch(proactiveActions.createCampaign(payload));
        }
    };

    const handleStartDate = (date) => {
        setStartDate(date);
    }
    const handleEndDate = (date) => {
        setEndDate(date)
    }

    const handleBrowseClick = () => {
        csvFileRef?.current?.click();
    }

    const handleCsvUpload = (e) => {
        const value = e.target.files[0]
        if(verifyCsvFileSize(value)){
            setCsvFileName(value.name)
            setCsvFile(value)
        }
    }

    const handleRemoveCsv = () => {
        setCsvFile("")
        setCsvFileName("");
    }

    const renderSwitch = ({ input }) => {
        return (<Switch
            checked={input.value ? true : false}
            onChange={input.onChange}
            sx={styles.switch} />);
    };

    useEffect(() => {
        if (id && !loading) {
            dispatch(change('CreateCampaignForm', 'name', campaignObj.name));
            dispatch(change('CreateCampaignForm', 'description', campaignObj.description));
            dispatch(change('CreateCampaignForm', 'channel', campaignObj.channel));
            setChannel(campaignObj.channel)
            setMediaTypes(campaignObj.mediaTypes)
            dispatch(change('CreateCampaignForm', 'organizationId', campaignObj.organizationId));
            dispatch(change('CreateCampaignForm', 'msgDataSourceIds', campaignObj.msgDataSourceIds));
            dispatch(change('CreateCampaignForm', 'templateId', campaignObj.templateId));
            dispatch(change('CreateCampaignForm', 'mediaTypes', campaignObj.mediaTypes));
            if (campaignObj.textSegment) {
                dispatch(change('CreateCampaignForm', 'segments', campaignObj.textSegment));
            }
            dispatch(change('CreateCampaignForm', 'isFollowUpEnabled', campaignObj.isFollowUpEnabled));
            dispatch(change('CreateCampaignForm', 'isPresaleEnabled', campaignObj.isPresaleEnabled));
            setIsFollowUpEnabled(campaignObj.isFollowUpEnabled);
            setIsPresaleEnabled(campaignObj.isPresaleEnabled);
            dispatch(change('CreateCampaignForm', 'followUpTemplate', campaignObj.followUpTemplate));
            setQueryParam({...queryParam, organizationId: campaignObj.organizationId, channel: campaignObj.channel});
        }
    }, [loading]);

    if (id && loading && _.isEmpty(campaignObj)) {
        return (
            <Box m={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}>
                <CircularProgress size={25} sx={{color: '#07857C', marginLeft: '10px'}}/>
            </Box>
        )
    }
    if (id && !loading && _.isEmpty(campaignObj)) {
        return (
            <Box m={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}>
                <Box sx={styles.desc}>No data found</Box>
            </Box>
        )
    }

    return(
        <>
            <form name="form" onSubmit={handleSubmit(handleCreateCampaign)} autoComplete="off">
                <Box sx={{padding: '25px 20px 60px 20px'}}>
                    <Grid container item mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Grid item xs={12} md={7} sx={{}}>
                            <Box mr={{md: '20px', lg: '20px'}}>
                                <Box sx={styles.inputDesc}>Campaign name</Box>
                                <Field
                                    name="name"
                                    component={BasicFormTextField}
                                    placeholder={'Enter name'}
                                    validate={required}
                                />
                            </Box>
                        </Grid>
                        <Grid container item xs={12} md={5}>
                            <Grid item xs={12} md={6}>
                                <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>Start</Box>
                                    <SingleDatePicker
                                        handleChange={handleStartDate}
                                        withIcon
                                        enabled={true}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box ml={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>End</Box>
                                    <SingleDatePicker
                                        handleChange={handleEndDate}
                                        withIcon
                                        enabled={true}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Grid item xs={12} md={7}>
                            <Box mr={{md: '20px', lg: '20px'}}>
                                <Box sx={styles.inputDesc}>Description/topic</Box>
                                <Field
                                    name="description"
                                    component={BasicMultilineTextField}
                                    placeholder={'Enter topic'}
                                    validate={required}
                                />
                            </Box>
                        </Grid>
                        <Grid container item xs={12} md={5} sx={{}}>
                            <Grid item xs={12} md={6}>
                                <Box mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}
                                     mr={{xs: '0', sm: '0', md: '10px', lg: '10px'}}>
                                    <Box sx={styles.inputDesc}>Status</Box>
                                    <Field
                                        name="isActive"
                                        component={StatusSelect}
                                        placeholder={selectPlaceholder}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}} >
                        <Grid container item xs={12} md={7}>
                            <Grid item xs={12} md={7}>
                                <Box mr={{md: '10px', lg: '10px'}} mt={{md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>Organization</Box>
                                    <Field
                                        name="organizationId"
                                        component={OrganizationSelect}
                                        handleChange={handleSelectChange}
                                        placeholder={selectPlaceholder}
                                        defaultValue={campaignObj.organizationId}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box ml={{md: '10px', lg: '10px'}} mr={{md: '20px', lg: '20px'}}
                                     mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>Channel</Box>
                                    <Field
                                        name="channel"
                                        component={ChannelSelect}
                                        handleChange={handleSelectChange}
                                        placeholder={selectPlaceholder}
                                        defaultValue={campaignObj.channel}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={5}>
                            <Grid item xs={12} sx={{}}>
                                <Box mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>Message data source</Box>
                                    <Field
                                        name="msgDataSourceIds"
                                        component={ProactiveMsgDataSourceMultiSelect}
                                        placeholder={selectPlaceholder}
                                        queryParam={queryParam}
                                        handleFormSelectChange={handleMsgDataSourceSelectChange}
                                        defaultValues={campaignObj.msgDataSourceIds}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid container item xs={12} md={7}>
                            <Grid item xs={12} md={7}>
                                <Box mr={{md: '10px', lg: '10px'}} mt={{md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>Message template</Box>
                                    <Field
                                        name="templateId"
                                        component={TemplateSelect}
                                        placeholder={selectPlaceholder}
                                        queryParam={queryParam}
                                        handleFormSelectChange={handleTemplateSelectChange}
                                        defaultValue={campaignObj.templateId}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box ml={{md: '10px', lg: '10px'}} mr={{md: '20px', lg: '20px'}}
                                     mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>Media type</Box>
                                    <Field
                                        name="mediaTypes"
                                        component={MediaTypeMultiSelect}
                                        placeholder={selectPlaceholder}
                                        queryParam={queryParam}
                                        handleFormSelectChange={handleMediaTypeSelectChange}
                                        handleChange={handleSelectChange}
                                        defaultValues={campaignObj.mediaTypes}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={5} display={isSelectionModeSupported() ? '' : 'none'}>
                            <Grid item xs={12} sx={{}}>
                                <Box mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>Comment selection mode</Box>
                                    <Field
                                        name="selectionMode"
                                        component={ProactiveCommentSelectionModeSelect}
                                        placeholder={selectPlaceholder}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    { isAttributeSupported && <CampaignAttribute required={required} isCreateWizard={true} /> }

                    { isDateFilterSupported && <CampaignDateFilter handleDateFilterChange={handleTikTokDateFilterChange} />}

                    {
                        isYoutubeChannel &&
                        <YoutubeCampaignParams
                            queryParam={queryParam}
                            handleDateFilterChange={handleYoutubeDateFilterChange}
                            handleRegionSelectChange={handleRegionSelectChange}
                            handleRelevanceLanguageSelectChange={handleRelevanceLanguageSelectChange}
                            handleSafeSearchSelectChange={handleSafeSearchSelectChange}
                        />
                    }

                    <Grid container item xs={12} md={7}>
                        <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                            <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                <Box sx={styles.inputDesc}>Days between same user replies</Box>
                                <Field
                                    name="minDaysBetweenSameUserReplies"
                                    component={BasicFormTextField}
                                    placeholder={'Enter the number of days'}
                                    validate={required}
                                    props={{ type: 'number', min: 0 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <CampaignSegment
                        channel={channel}
                        queryParam={queryParam}
                        handleSegmentSelectChange={handleSegmentSelectChange}
                        required={required}
                    />

                    <Grid container item xs={12} md={7}>
                        <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                            <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                <Box sx={styles.inputDesc}>Blocked accounts &nbsp;<em>(optional)</em></Box>
                                <Field
                                    name="blockedAccounts"
                                    component={BasicFormTextField}
                                    placeholder={'Enter comma-separated accounts'}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={7}>
                        <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                            <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                <Box sx={styles.inputDesc}>Blocked keywords &nbsp;<em>(optional)</em></Box>
                                <Field
                                    name="blockedKeywords"
                                    component={BasicFormTextField}
                                    placeholder={'Enter comma-separated keywords'}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    { isCsvUploadSupported &&
                        <Grid container item mt={{xs: '15px', sm: '15px', md: '22px', lg: '22px'}}>
                            <Grid item xs={12} md={7}>
                                <Box sx={styles.uploadContainer}>
                                    <input type="file" ref={csvFileRef} onChange={handleCsvUpload} id="csvUpload" accept=".csv" style={{display: "none"}}/>
                                    <Box sx={{minWidth: 'fit-content'}}>
                                        <ActionButton
                                            label={'Upload CSV'}
                                            type={'button'}
                                            action={handleBrowseClick}
                                        />
                                    </Box>
                                    {csvFileName &&
                                        <>
                                            <Box sx={{...styles.inputDesc, mb: 0}} className="exp-dashboard-truncate-1-line">
                                                {csvFileName}
                                            </Box>
                                            <IconButton sx={{m: '5px'}} onClick={handleRemoveCsv}>
                                                <ClearIcon sx={styles.clearIcon} />
                                            </IconButton>
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    { isFollowUpSupported && (<Grid container item xs={12} md={7}>
                            <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                                <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                    <Box sx={styles.inputDesc}>
                                        Automatic Follow Up&nbsp;
                                        <Field
                                            name="isFollowUpEnabled"
                                            component={renderSwitch}
                                            defaultValue={campaignObj.isFollowUpEnabled}
                                            onChange={handleIsFollowUpEnabled} />
                                    </Box>
                                    {isFollowUpEnabled && (
                                        <Field
                                            name="followUpTemplate"
                                            component={BasicMultilineTextField}
                                            placeholder={'Enter the follow up template'}
                                            defaultValue={campaignObj.followUpTemplate}
                                            onChange={handleFollowUpTemplate} />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>)
                    }
                    { isPresaleSupported && (<Grid container item xs={12} md={7}>
                        <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                            <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                <Box sx={styles.inputDesc}>
                                    Presale&nbsp;
                                    <Field
                                        name="isPresaleEnabled"
                                        component={renderSwitch}
                                        defaultValue={campaignObj.isPresaleEnabled}
                                        onChange={handleIsPresaleEnabled} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>)
                    }
                </Box>
                <Box sx={styles.footer}>
                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Save'}
                            disabled={loadingCreateCampaign || uploadingCustomerData}
                        />
                    </Box>
                    <Box sx={{width: '100px', ml: '18px', height: '20px'}}>
                        <AnchorButton
                            label={'Cancel'}
                            link={'/admin/campaign'}
                            buttonBordered={true}
                        />
                    </Box>
                </Box>
            </form>
        </>
    )
}

const styles = {
    hero: {
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        mb: { xs: '24px', sm: '40px', },
    },
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    footer: {
        borderTop: '1px solid #CED4D4',
        padding: '12px 20px',
        display: 'flex'
    },
    uploadContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    },
    clearIcon: {
        fontSize: '16px!important',
        flexShrink: 0,
        color: '#144042',
        pointerEvents: 'none',
    },
    switch: {
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: '#368c86'
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: '#368C86'
        },
        "&:hover .MuiSwitch-switchBase": {
            color: '#368c86'
        },
    }
}

function validate(values) {
    const errors = {};
    const {isActive, templateId, channel, organizationId, msgDataSourceIds, mediaTypes, selectionMode, ticketLimit,
        isTicketLimitRecurring, attribute_type_0, publishedAfter, regionCode, relevanceLanguage, safeSearch} = values;
    const isSelectionModeSupported = channel === 'TIKTOK' && mediaTypes && mediaTypes.includes('TIKTOK_POST_COMMENT');
    const isAttributeSupported = supportedChannelsForAttributes.includes(channel);

    if(_.isEmpty(_.toString(isActive)) || isActive === 'none') errors.isActive = 'invalid status';
    if(_.isEmpty(_.toString(templateId)) || templateId === 'none') errors.templateId = 'invalid template';
    if(_.isEmpty(_.toString(channel)) || channel === 'none') errors.channel = 'invalid channel';
    if(_.isEmpty(_.toString(organizationId)) || organizationId === 'none') errors.organizationId = 'invalid organization';
    if(_.isEmpty(_.toString(msgDataSourceIds)) || msgDataSourceIds.includes('none')) errors.msgDataSourceIds = 'invalid msg data sources';
    if(isSelectionModeSupported && (_.isEmpty(_.toString(selectionMode)) || selectionMode === 'none')) errors.selectionMode = 'missing selection mode';
    if(!_.isEmpty(_.toString(ticketLimit)) && _.isEmpty(_.toString(isTicketLimitRecurring))) errors.isTicketLimitRecurring = 'missing limit type';
    if(isAttributeSupported && _.isEmpty(_.toString(attribute_type_0)) || attribute_type_0 === 'none') errors.attribute_type_0 = 'invalid attribute type';
    if(_.isEmpty(_.toString(publishedAfter))) errors.publishedAfter = 'missing publishedAfter field';
    if(_.isEmpty(_.toString(regionCode)) || regionCode === 'none') errors.regionCode = 'missing regionCode field';
    if(_.isEmpty(_.toString(relevanceLanguage)) || relevanceLanguage === 'none') errors.relevanceLanguage = 'missing relevanceLanguage field';
    if(_.isEmpty(_.toString(safeSearch)) || safeSearch === 'none') errors.safeSearch = 'missing safeSearch field';
    return errors;
}

const formConfig = {
    form: 'CreateCampaignForm',
    validate
};

CreateCampaignForm = reduxForm(formConfig)(CreateCampaignForm)
CreateCampaignForm = connect(state => (
    {
        initialValues: {
            minDaysBetweenSameUserReplies: '30'
        }
    }),
)(CreateCampaignForm);
export default CreateCampaignForm;