import {Fragment} from "react";
import _ from "lodash";
import BasicMenu from "../../../../../common/BasicMenu";

export default function CompanyResourcesMenu({resources}) {
    if(_.isEmpty(resources)) return <Fragment />

    const handleSelection = (param) => {
        if(param.hasOwnProperty('link')) {
            window.open(param.link, '_blank');
        }
    }

    return(
        <Fragment>
            <BasicMenu
                label={`+${resources.length}`}
                options={resources}
                handleSelection={handleSelection}
            />
        </Fragment>
    )
}