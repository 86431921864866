import React, { Component, Fragment } from "react";
import { GenericContextMenu } from "components/common";

/**
 * @author Sarah Chima
 */

class ChannelsContextMenu extends Component {

    state = {
        checkedChannelsNames: [],
        channels: [],
        isAllChannelsChecked: false
    };

    componentDidMount() {
        const { checkedChannels, ids } = this.props;

        if (checkedChannels) {
            const channelNames = this.getChannelsNames(checkedChannels);
            this.setState({
                checkedChannelsNames: channelNames
            });

            if (checkedChannels.length === ids.length) {
                this.setState({
                    isAllChannelsChecked: true
                })
            }
        } else {
            this.setState({
                isAllChannelsChecked: true
            })
        }
    }

    /**Computes the channel names to be displayed in the context menu parent box
    **params channelsIds array
    **/
    getChannelsNames = (channelIds) => {
        const { options } = this.props;
        let channelNames = [];
        channelIds.join("").split(',');

        options.forEach(option => {
            if (channelIds.indexOf(String(option.value)) !== -1) {
                channelNames.push(option.name)
            }
        });

        return channelNames
    };

    /**Convert Channels To Strings **/
    convertArrayItemsToString = (array) => {
        if(!array)return [];

        return array.map(channel => {
            return String(channel)
        });
    };

    /** Handles the checkbox event
     **/
    handleCheckBoxChange = (event) => {
        const { ids, checkedChannels } = this.props;
        let { channels, isAllChannelsChecked } = this.state;

        const target = event.target;

        if (checkedChannels.length === 0 && isAllChannelsChecked) {
            channels = [...ids];
        } else {
            channels = checkedChannels;
        }

        /** Values of channels are stored as strings in the HTML body
         so there is a need to convert all array items to string to properly use
         the splice method.
         **/

        channels = this.convertArrayItemsToString(channels);

        if (target.checked) {
            channels.push(target.value);

        } else {
            channels.splice(channels.indexOf(target.value), 1);
            this.setState({
                isAllChannelsChecked:  false
            })
        }

        const channelsNames = this.getChannelsNames(channels);
        this.setState({
            checkedChannelsNames: channelsNames,
            channels
        });

        if (channels.length === ids.length) {
            this.setState({
                isAllChannelsChecked: true
            })
        }

        this.props.handleChange(channels)
    };

    handleAllChannelsChange = (event) => {
        const { ids } = this.props;
        const { isAllChannelsChecked, isChannelsUpdated } = this.state;

        let channels;
        if (!isChannelsUpdated) {
            this.setState( {
                isChannelsUpdated: true
            })
        }

        if (!isAllChannelsChecked) {
            this.setState({
                isAllChannelsChecked: true
            });
            channels = [...ids];
        } else {
            this.setState({
                isAllChannelsChecked: false
            });
            channels = []
        }

        this.props.handleChange(channels)
    };

    render() {

        let { options, checkedChannels, ids } = this.props;
        const { checkedChannelsNames} = this.state;

        const selectedChannelNames = checkedChannelsNames.length !== 0 ? checkedChannelsNames.join(", ") : "Select Channel";

        checkedChannels = this.convertArrayItemsToString(checkedChannels);

        let isAllChannelsChecked = this.state.isAllChannelsChecked || checkedChannels.length === ids.length;

        return (
            <Fragment>
                <GenericContextMenu trianglePosition="left">
                    <div className="select-field tms-ellipsis color-grey-dark" key="trigger">{ selectedChannelNames} </div>
                    <ul key="body">
                        <label className="context-menu-label font-15 padding-bottom-10 align-center">
                            <input type="checkbox" name="allChannels"  checked={isAllChannelsChecked} onChange={this.handleAllChannelsChange}  className="tms-checkbox tms-child-checkbox"/>
                            <span className="tms-check-mark"></span>
                            All Channels
                        </label>

                    {options && options.map((option) => {
                        let isChecked;
                        if ( checkedChannels.length !== 0) {
                            isChecked = checkedChannels.includes(String(option.value));
                        } else if (checkedChannels.length === 0 && !isAllChannelsChecked ) {
                            isChecked = false
                        } else {
                            isChecked = true
                        }

                        return (
                            <label key={option.value} className="context-menu-label font-15 padding-bottom-10 align-center">
                                <input type="checkbox" name={option.name} checked={isChecked} value={option.value} onChange={this.handleCheckBoxChange}  className="tms-checkbox tms-child-checkbox"/>
                                <span className="tms-check-mark"></span>
                                {option.name}
                            </label>
                        )
                    })}
                    </ul>
                </GenericContextMenu>
            </Fragment>
        )
    }
}

export default ChannelsContextMenu;