import {Box} from "@mui/system";
import IncomingArrow from '@mui/icons-material/EastOutlined';
import OutgoingArrow from '@mui/icons-material/WestOutlined';
import {messageDirection} from "_constants";
import {selectItem} from "../FilterUtililties/FilterUtils";
import FilterCheckbox from "../FilterUtililties/FilterCheckbox";

export default function DirectionFilterItem({options, checkedSecondaryOptions, setCheckedSecondaryOptions}) {
    const handleSelect = (clickedIndex, event) => {
        const values = selectItem(event, checkedSecondaryOptions);
        setCheckedSecondaryOptions(values);
    }

    const RenderValues = ({options}) => {
        return options.map((item, index) => {
            return (
                <Box key={index} sx={{}}>
                    <Box sx={{display: 'flex', }}>
                        <Box sx={{width: '90px'}}>
                            <FilterCheckbox
                                label={item.name}
                                value={item.value}
                                index={index}
                                checkedItemsValues={checkedSecondaryOptions}
                                onChange={handleSelect}
                            />
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {
                                item.value === messageDirection.INCOMING ?
                                    <IncomingArrow sx={style.incomingDirectionIcon}/> :
                                    <OutgoingArrow sx={style.outgoingDirectionIcon}/>
                            }
                        </Box>
                    </Box>
                </Box>
            )
        })
    }

    return(
        <Box sx={{width: '110px'}}>
            <Box sx={style.description}>Direction</Box>
            <Box>
                <RenderValues options={options} />
            </Box>
        </Box>
    )
}

const style = {
    description: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        color: '#495D5E',
        letterSpacing: '-0.02em',
    },
    incomingDirectionIcon: {
        color: '#0C2728',
        fontSize: '20px',
    },
    outgoingDirectionIcon: {
        color: '#9EA9A9',
        fontSize: '20px',
    }
}