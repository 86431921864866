import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {frontAppOauthActions} from "_actions";
import {Loading, SnackBar} from 'components/common';
import {history} from "_helpers";
import { alertMessages } from "_constants";

export default function FrontAppUrlRedirect() {
  const dispatch = useDispatch();
  const url = new URL(document.location);
  const values = new URLSearchParams(url.search);
	const frontApp = useSelector((state) => state.frontAppOauth);
  const frontAppAuthCode = values.get('code');
	const frontAppRequestState = values.get('state');

  useEffect(() => {
    if (frontAppAuthCode && frontAppRequestState) {
      const params = { code: frontAppAuthCode, state: frontAppRequestState };
      dispatch(frontAppOauthActions.genAccessToken(params));
    } else {
			SnackBar.showMessage(alertMessages.FRONT_APP_CHAT_FAILURE)
			history.push('/company/connectchannels');
		}
  }, []);

  if (frontApp.loading) {
    return <Loading />;
  }

	// If everything is successful, redirect to the connectchannels page. On there, a modal will open
	// To setup the inboxes. This modal checks the global state of `frontAppOAuth.showInboxes` is true and then opens.
	history.push('/company/connectchannels');

	return(
		<Fragment />
	)
}