import {connect, useDispatch, useSelector} from "react-redux";
import {Fragment, useState} from "react";
import {Box} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import {proactiveActions} from "_actions";
import DateRangeFilter from "../../../common/DateRangeFilter";
import {reduxForm} from "redux-form";
import useLocationQueryDateRange from "../../../hooks/useLocationQueryDateRange";
import ActionButton from "../../../common/ActionButton";
import {buildGetCompanyCampaigns} from "./CampaignUtils";
import useLocationQueryParams from "../../../hooks/useLocationQueryParams";
import {getYearRange, queryStringToObject} from "_helpers";
import {requestOrigin} from "_constants";
import BasicFilterSelect from "../../../common/BasicFilterSelect";
import useGetActiveOrganizations from "./hooks/useGetActiveOrganizations";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";

function GetCampaignForm({ handleSubmit, urlParams }) {
    const dispatch = useDispatch();
    const { params, setParams } = useLocationQueryParams();
    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingOrganizationCampaigns;
    const { organizations, loading: organizationLoading } = useGetActiveOrganizations();

    const yearCount = 2;
    const { startDate, endDate } = useLocationQueryDateRange({
        initialStartDate: moment().subtract(yearCount, 'years')
    });
    const [dateRange, setDateRange] = useState({
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
    });
    const [organizationId, setOrganizationId] = useState("");
    const organizationName = organizations?.find((item) => item.id === _.get(urlParams, 'organizationId'))

    const handleGetCampaigns = (values) => {
        const payload = buildGetCompanyCampaigns(values, dateRange);
        dispatch(proactiveActions.getCompanyCampaigns({...payload, organizationId}, requestOrigin.ADMIN_CAMPAIGNS_VIEW));
    }
    const handleDateRangeChange = (startDate, endDate) => {
        const dateParam = {
            ...dateRange,
            startDate: moment(startDate).startOf('day').toISOString(),
            endDate: moment(endDate).endOf('day').toISOString()
        }
        setDateRange(dateParam);
        setParams(dateParam);
    }

    const handleOrganizationSelectChange = (event) => {
        setOrganizationId(event?.id)
        const queryParams = {...params, organizationId: event?.id}
        setParams(queryParams);
    }

    return(
        <Fragment>
            <form name="form" onSubmit={handleSubmit(handleGetCampaigns)} autoComplete="off">
                <Box sx={styles.paramSelectionContainer}>
                    <DateRangeFilter
                        name="dateRange"
                        startDate={startDate}
                        endDate={endDate}
                        handleChange={handleDateRangeChange}
                        additionalRanges={[
                            getYearRange(yearCount)
                        ]}
                    />
                    <Box sx={styles.organizationSelectContainer}>
                        {organizationLoading ?
                            <LoadingPlaceholderBlock
                                width={'100%'}
                                height={'45px'}
                                borderRadius={'8px'}
                                backgroundColor={'#E3E2E1'}
                            /> :
                            <BasicFilterSelect
                                placeholder={'Select organization'}
                                options={organizations}
                                value={organizationName}
                                onChange={handleOrganizationSelectChange}
                                textFieldStyle={styles.organizationSelectInput}
                                listBoxHeight={380}
                            />
                        }
                    </Box>
                    <Box sx={styles.applyButtonContainer}>
                        <ActionButton
                            label={'Apply'}
                            disabled={loading}
                            sx={{width: '110px'}}
                        />
                    </Box>
                </Box>
            </form>
        </Fragment>
    )
}

function validate(values) {
    const errors = {};
    const {organizationId} = values;
    if(_.isEmpty(_.toString(organizationId)) || organizationId === 'none') errors.organizationId = 'invalid organization';
    return errors;
}

const formConfig = {
    form: 'GetCampaignForm',
    validate
};

GetCampaignForm = reduxForm(formConfig)(GetCampaignForm)
GetCampaignForm = connect( function (state) {
    const search = window.location.search.substring(1);
    const queryObj =  queryStringToObject(search);

    return {
        initialValues: {
            organizationId: _.get(queryObj, 'organizationId'),
        }
    }
})(GetCampaignForm);
export default GetCampaignForm;

const styles = {
    paramSelectionContainer: {
        width: { xs: '100%', sm: 'auto' },
        display: 'flex',
    },
    organizationSelectContainer: {
        width: '250px',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        ml: '10px'
    },
    organizationSelectInput : {
        [`& fieldset`]: {
            borderRadius: '8px',
        },
        '& .MuiInputBase-input': {
            height: '12px',
        },
    },
    applyButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        pt: '2px',
        ml: '10px'
    }
}