import React, { Component } from 'react';

class FrontAppInboxes extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="main-content-wrapper">
                <div className="content-pane-60 margin-0-auto">
                    <div className="padding-20-0">
                        <p>Select Inboxes</p>
                    </div>
                </div>
            </div>
        );
    }
}


export default FrontAppInboxes;
