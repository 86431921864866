import {authHeader, handleHttpResponseAndReturnError, objectToQueryString} from "../_helpers";
import {SERVER_URL} from "config";

function getAll(params){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/updates?${objectToQueryString(params)}`, requestOptions)
        .then(handleHttpResponseAndReturnError);
}

function save(payload){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };


    return fetch(`${SERVER_URL}/api/updates`, requestOptions)
        .then(handleHttpResponseAndReturnError);
}

function edit(id, payload){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };


    return fetch(`${SERVER_URL}/api/updates/${id}`, requestOptions)
        .then(handleHttpResponseAndReturnError);
}


function setDisplayOptions(){
    const pagesToDhowOn = ["messageIndex", "voteIndex"]
    for (const currentPage of pagesToDhowOn){
        const showOption = {showCount: 1}
        localStorage.setItem(currentPage, JSON.stringify(showOption))
    }
}

function reduceDisplayCount(key){
    if (localStorage.hasOwnProperty(key)){
        const option = JSON.parse(localStorage.getItem(key))
        const reducedCount = parseInt(option.showCount) - 1
        if (reducedCount <= 0) localStorage.removeItem(key)
        else localStorage.setItem(key, `${reducedCount}`)
    }
}

function getShowCount(key){
    if (localStorage.hasOwnProperty(key)){
        const option = JSON.parse(localStorage.getItem(key))
        return parseInt(option.showCount)
    }
    return 0
}


export const chatdeskUpdatesService = {
    getAll,
    save,
    edit,
    setDisplayOptions,
    getShowCount,
    reduceDisplayCount
}