import React, { Component } from "react";
import {Button, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {dataSourceHandleActions, msgDataSourceActions} from "_actions";
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";
import {Field, reduxForm} from "redux-form";
import { DropDownSelect } from "../common";
import { DateRange } from 'components/common/formFields/DateRange'
import {MultiSelectContextMenu} from "../common";

const INCOMING = 'incoming';
const dropdownStyles = {
    borderColor: "#c4c9c7",
    boxShadow: "none",
    color: "#5a5f5e",
    width: "100%"
};

const platformConstants = {
    trends: 'trends',
    teams: 'teams-only'
};

class FilterFormV2 extends Component {

    state = {
        dateRanges: {
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Month to Date': [moment().startOf('month'), moment()],
            'Year to date': [moment().startOf('year'), moment()],
            'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        }
    };
    handleOrgChange = (event) => {
        const params = {organization: event.target.value};
        this.props.dispatch(msgDataSourceActions.getAll(params));
    };

    updateHandles = (organizations, handleStatus) => {
        this.props.dispatch(dataSourceHandleActions.getAll({
            organizations: organizations,
            max: 1000,
            handleStatus: this.props.platform === platformConstants.teams ? handleStatus : null
        }));
    };

    render() {
        let {
            submitting,
            onFormUpdate,
            onFormSubmit,
            messageStatus,
            messageStatusOptions,
            onDatePickerUpdate,
            startDate,
            handleStatus,
            author,
            direction,
            endDate,
            platform,
            handleChange,
            messageDuration,
            channelsAction,
            channelsIds,
            organizations,
            companiesIds,
            channelsFilter,
            companies,
            handleChannelsFilterChange,
            handleOrgFilterChange,
            dataSourceLoading,
        } = this.props;

        const channelsInput = {onChange: handleChange, value: channelsAction};

        const handleStatusOptions = [
            {name: 'Live', id: 'LIVE'},
            {name: 'Practice', id: 'PRACTICE'},
        ];

        const messageDurationOptions = [
            {name: 'Within two months', id: 'two_months'},
            {name: 'All Period', id: 'all_period'},
        ];

        const messageDirectionOptions = [
            {name: 'All', id: 'ALL'},
            {name: 'Incoming', id: 'INCOMING'},
            {name: 'Outgoing', id: 'OUTGOING'},
        ];

        const authors = [
            {name: 'All Authors', id: "all_authors"}
        ];

        const itemsConstant = {
            OUTGOING: 'OUTGOING',
            LIVE: 'LIVE'
        };

        const platformFilter = [{id: 'trends', name: 'trends'}, {id: 'teams-only', name: 'teams-only'}];
        const platformInput = {onChange: onFormUpdate, value: platform};
        const handleStatusInput = {onChange: onFormUpdate, value: handleStatus};
        const messageDirectionInput = {onChange: onFormUpdate, value: direction};
        const messageStatusInput = {onChange: onFormUpdate, value: messageStatus};
        const authorInput = {onChange: onFormUpdate, value: author};
        const organizationOptions = companies && companies.length > 0 ? companies.map(company => {
            return {name: company.name, value: company.value}
        }) : [];
        const messageDurationInput = {onChange: onFormUpdate, value: messageDuration};

        return (
            <Form inline onSubmit={onFormSubmit} className="messages-page-filter-form">
                <div>
                    <span className="font-14 color-grey-dark">Date</span>
                    <label className="calendar-today tms-inbox-calendar" style={{display: "block"}}>
                        <Field
                            submittedStartDate={startDate}
                            submittedEndDate={endDate}
                            id="datePicker"
                            name="datePicker"
                            defaultDateRange="today"
                            onEvent={onDatePickerUpdate}
                            values={startDate}
                            // placeholder={`${startDate} - ${endDate}`}
                            component={DateRange}
                        />
                    </label>
                </div>

            <div style={{marginTop: "10px"}}>
                <span className="font-14 color-grey-dark">Database</span>
                <label className="" style={{display: "block"}}>
                    <Field
                        name="platform"
                        fieldName="platform"
                        input={platformInput}
                        style={dropdownStyles}
                        options={platformFilter}
                        value={platform}
                        component={DropDownSelect}/>
                </label>
            </div>

            <div style={{marginTop: "10px"}}>
                <span className="font-14 color-grey-dark">Organizations</span>
                <div className="inbox-context-menu" style={{display: "block"}}>
                    <Field
                        name="organizations"
                        fieldName="organizations"
                        type="text"
                        displayName={'Organizations'}
                        input={channelsInput}
                        options={organizationOptions}
                        checkedOptions={organizations}
                        ids={companiesIds}
                        handleChange={handleOrgFilterChange}
                        component={MultiSelectContextMenu}/>

                </div>
            </div>
            <div style={{marginTop: "10px"}}>
                <span className="font-14 color-grey-dark">Data Source Handles</span>
                <div className="inbox-context-menu" style={{display: "block"}}>
                    <Field
                        name="channels"
                        fieldName="channels"
                        type="text"
                        displayName={'Channels'}
                        input={channelsInput}
                        options={channelsFilter}
                        checkedOptions={channelsAction}
                        ids={channelsIds}
                        handleChange={handleChannelsFilterChange}
                        component={MultiSelectContextMenu}
                        loading={dataSourceLoading}/>
                </div>
            </div>

            {platform !== platformConstants.trends &&
            <div style={{marginTop: "10px"}}>
                <span className="font-14 color-grey-dark">Handle Status</span>
                <label className="" style={{display: "block"}}>
                    <Field
                        name="handleStatus"
                        fieldName="handleStatus"
                        input={handleStatusInput}
                        style={dropdownStyles}
                        options={handleStatusOptions}
                        value={handleStatus}
                        component={DropDownSelect}/>
                </label>
            </div>
            }

            <div style={{marginTop: "10px"}}>
                <span className="font-14 color-grey-dark">Message Direction</span>
                <label className="" style={{display: "block"}}>
                    <Field
                        name="direction"
                        fieldName="direction"
                        input={messageDirectionInput}
                        style={dropdownStyles}
                        options={messageDirectionOptions}
                        value={direction}
                        component={DropDownSelect}/>
                </label>
            </div>

            {direction && direction.toLowerCase() === INCOMING &&
            <div style={{marginTop: "10px"}}>
                <span className="font-14 color-grey-dark">Message Status</span>
                <label className="" style={{display: "block"}}>
                    <Field
                        name="messageStatus"
                        fieldName="messageStatus"
                        input={messageStatusInput}
                        style={dropdownStyles}
                        options={messageStatusOptions}
                        value={messageStatus}
                        component={DropDownSelect}/>
                </label>
            </div>}

            {direction === itemsConstant.OUTGOING &&
            <div style={{marginTop: "10px"}}>
                <span className="font-14 color-grey-dark">Author</span>
                <label className="" style={{display: "block"}}>
                    <Field
                        name="author"
                        fieldName="author"
                        input={authorInput}
                        style={dropdownStyles}
                        options={authors}
                        value={author}
                        component={DropDownSelect}/>
                </label>
            </div>
            }

            <div style={{marginTop: "10px"}}>
                <span className="font-14 color-grey-dark">Duration</span>
                <label className="" style={{display: "block"}}>
                    <Field
                        name="messageDuration"
                        fieldName="messageDuration"
                        input={messageDurationInput}
                        style={dropdownStyles}
                        options={messageDurationOptions}
                        value={messageDuration}
                        component={DropDownSelect}/>
                </label>
            </div>


            <div style={{marginTop: "15px"}}>
                <Button bsStyle="success" type="submit" disabled={submitting} style={{width: "100%"}}>
                    <i className='fa fa-filter'/> Filter
                </Button>
            </div>
        </Form>
        );
    }
}

const mapStateToProps = (state) => {
    const {auth, messages, msgDataSources, dataSourceHandles} = state;
    return {
        currentUser: auth,
        authors: messages.authors,
        msgDataSources: msgDataSources.msgDataSources,
        dataSourceLoading: dataSourceHandles.loading,
    };
};

FilterFormV2 = connect(mapStateToProps)(FilterFormV2);
const filterFormV2 = reduxForm({
    form: 'FilterFormV2'
})(FilterFormV2);

export { filterFormV2 as FilterFormV2 }
