import Box from "@mui/material/Box";
import {TextField, useTheme} from "@mui/material";
import React from "react";
import {makeStyles} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";


export default function SubdomainInput({channelDomain, input, performAction, placeholder}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = React.useState({ input: ''});

  const handleChange = (event) => {
    const value = event.target.value;
    setValue(value);
    performAction(value);
  };

  return(
    <Box className={isMobile ? classes.formSectionMobile : classes.formSection} {...input}>
      <Box className={isMobile ? classes.descriptionMobile : classes.description}>
        https://
      </Box>
      <Box sx={{width: '100%', margin: '0 8px'}}>
        <TextField className={classes.textField}
                   InputProps={{ classes: { root: classes.textField } }}
                   label={placeholder}
                   onChange={handleChange}
                   variant="outlined" />
      </Box>
      <Box className={isMobile ? classes.descriptionMobile : classes.description}>
        {channelDomain}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  textField: {
    width: '100%',
    fontSize: '16px !important',
    color: '#0C2728',
    [`& fieldset`]: {
      border: '1px solid #0C2728', borderRadius: '10px',
    },
    [`& label`]: {
      fontSize: '16px',
    },
  },
  formSection:{
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
  },
  formSectionMobile:{
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
  },
  description: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '18px',
    lineHeight: '120%'
  },
  descriptionMobile: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '16px',
    lineHeight: '120%',
  }
});