import React, {useEffect} from "react";
import {Box, Stack} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import currentUserHeroImage from '../../Employment/Utilities/default_header_bg.jpg'
import currentUserHeroImage2x from '../../Employment/Utilities/default_header_bg@2x.jpg'
import PageContainer from 'components/v2/common/PageContainer';
import Avatar from "../../../common/Avatar";
import {useSelector, useDispatch} from "react-redux";
import {userActions} from "_actions";
import {useParams} from "react-router-dom";
import {Loading} from "components/common";
import {getLogoUrl} from "_helpers";
import IconButton from "@mui/material/IconButton";
import UserProfileDetails from "../Utilities/UserProfileDetails";

export default function UserProfile(){
    const loading = useSelector((state) => state.users.loading)
    const user = useSelector((state) => state.users.user)
    const dispatch = useDispatch()
    const params = useParams();
    const {id} = params;

    const deletePhoto = () => {
        dispatch(userActions.deleteUserPhoto(user.id))
    };

    const currentUserPhoto = loading ? " " : getLogoUrl(user?.profilePicture)
    const currentUserName = loading ? " " : `${user?.firstName} ${user?.lastName}`;

    useEffect(() => {
        dispatch(userActions.getUser(id));
    },[])

    if (loading || !user) {
        return <Loading/>
    }

    return(
        <PageContainer backgroundColor='#EFEAE7' size='large'>
            <Box sx={styles.root} component={'header'}>
                <Box sx={styles.heroImageContainer}>
                    <Box sx={styles.heroImage} component={'img'}
                         src={currentUserHeroImage}
                         srcSet={`${currentUserHeroImage} 1x, ${currentUserHeroImage2x} 2x`}
                    />
                </Box>

                <Box sx={styles.content}>
                    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={{ xs: 2, lg: 3 }} sx={{ position: 'relative' }}>
                        <Box sx={{position: "relative", width: "fit-content"}}>
                            <Avatar src={currentUserPhoto} userName={currentUserName} alt={currentUserName} background="dark" variant={'rounded'} size={'xlarge'} />

                            <IconButton component={'button'} size={'small'} sx={styles.deleteButton} onClick={deletePhoto}>
                                <ClearOutlinedIcon sx={{fontSize: '20px'}} />
                            </IconButton>
                        </Box>

                        <Stack direction={{ xs: 'column', lg: 'row' }} sx={{ flex: 1}} spacing={4}>
                            <UserProfileDetails user={user}/>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </PageContainer>
    )
}

const styles = {
    root: {
        borderRadius: '10px',
        overflow: 'hidden',
    },
    heroImageContainer: {
        width: '1342px',
        position: 'relative',
        height: '220px',
        background: '#DCD8FF',
    },
    heroImage: {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        border: 'none',
        '&:not([src])': {
            visibility: 'hidden',
        },
    },
    content: {
        p: { xs: 2, lg: 4, },
        backgroundColor: '#fff',
    },
    deleteButton: {
        position: "absolute",
        bottom: "-3px",
        right: "-5px",
        zIndex: 999,
        width: "30px",
        height: "30px",
        border: "1.5px solid #144042",
        color: "#368C86",
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        },
    },
}