import { payoutsConstants, alertMessages } from '_constants';
import { payoutsService } from '_services';
import {SnackBar} from "../components/common";

function getAll(params) {
    function request() {
        return { type: payoutsConstants.GET_ALL_PAYOUTS_REQUEST };
    }

    function success(payouts) {
        return { type: payoutsConstants.GET_ALL_PAYOUTS_SUCCESS, payload: payouts };
    }

    function failure(error) {
        return { type: payoutsConstants.GET_ALL_PAYOUTS_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        payoutsService.getAll(params)
            .then(
                payouts => dispatch(success(payouts)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };
}

function getPayout(id) {
    return (dispatch) => {
        dispatch(request());

        payoutsService.getPayout(id)
            .then(
                payouts => dispatch(success(payouts)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request() {
        return { type: payoutsConstants.GET_PAYOUT_REQUEST};
    }

    function success(payouts) {
        return { type: payoutsConstants.GET_PAYOUT_SUCCESS, payload: payouts };
    }

    function failure(error) {
        return {type: payoutsConstants.GET_PAYOUT_FAILURE, payload: error};
    }
}

function createPayout(data) {
    return (dispatch) => {
        dispatch(request());

        payoutsService.createPayout(data)
            .then(
                payouts => {
                    dispatch(success(payouts));
                    SnackBar.showMessage(alertMessages.CREATE_PAYOUT_SUCCESS);
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request(id) {
        return {type: payoutsConstants.CREATE_PAYOUT_REQUEST, payload: id};
    }

    function success(payouts) {
        return {type: payoutsConstants.CREATE_PAYOUT_SUCCESS, payload: payouts};
    }

    function failure(error) {
        return {type: payoutsConstants.CREATE_PAYOUT_FAILURE, payload: error};
    }
}

function updatePayout(data, id) {
    return (dispatch) => {
        dispatch(request());

        payoutsService.updatePayout(data, id)
            .then(
                payouts => {
                    dispatch(success(payouts));
                    SnackBar.showMessage(alertMessages.UPDATE_PAYOUT_SUCCESS);
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request(id) {
        return {type: payoutsConstants.UPDATE_PAYOUT_REQUEST, payload: id};
    }

    function success(payouts) {
        return {type: payoutsConstants.UPDATE_PAYOUT_SUCCESS, payload: payouts};
    }

    function failure(error) {
        return {type: payoutsConstants.UPDATE_PAYOUT_FAILURE, payload: error};
    }
}

export const payoutsActions = {
    getAll,
    getPayout,
    createPayout,
    updatePayout
};