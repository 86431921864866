import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";

export default function useGetOrganizationInfo(organizationId) {
    const dispatch = useDispatch();

    useEffect(() => {
        if(!_.isEmpty(organizationId)) {
            dispatch(organizationSettingsActions.getOrganizationInfo(organizationId));
        }
    }, [organizationId]);

    const organizationSettings = useSelector(state => state.organizationSettings);
    const loading = organizationSettings.loadingOrganizationInfo;
    const organizationInfo = organizationSettings.organizationInfo;

    return {organizationInfo, loading};
}