import React from 'react';

import { Col, Grid, Panel } from 'react-bootstrap';
import { roleActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import moment from "moment/moment";
import { Link } from "react-router-dom";


class RoleDetails extends React.Component {

    componentDidMount() {
        const {roleId} = this.props.match.params;
        this.props.dispatch(roleActions.getRole(roleId));
    }

    render() {

        if (!this.props.role || !this.props.role.id) {
            return <Loading/>
        }

        const {role} = this.props;

        return (
            <Grid>

                <Col xs={8} xsOffset={2}>

                    <Panel>
                        <Panel.Heading>Role Details</Panel.Heading>
                        <Panel.Body>
                            <strong>Role Authority:</strong> {role.authority}
                            <hr/>
                            <strong>Date Created: </strong>{moment(role.dateCreated).format('MMMM D, YYYY')}
                            <hr/>
                            <strong>Last Updated: </strong>{moment(role.lastUpdated).format('MMMM D, YYYY')}
                            <hr/>
                        </Panel.Body>
                        <Panel.Footer>
                            <Link className='btn btn-success' to='/roles'>Back</Link>
                            <Link className='btn btn-success pull-right' to={`/roles/${role.id}/edit`}>Edit Role</Link>
                        </Panel.Footer>
                    </Panel>

                </Col>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const {roles: {role}} = state;
    return {
        role
    };
}

const connectedRoleDetails = connect(mapStateToProps)(RoleDetails);
export {connectedRoleDetails as RoleDetails};
