import {ChannelMetricsBuilder} from "../objects/ChannelMetricsBuilder";
import {capitalize} from "_helpers";
import _ from "lodash";


/**
 * Get sub-channels from channels object
 * @param channelArr
 * @returns {*[]|*}
 */
export function getSubChannels(channelArr) {
  if(_.isEmpty(channelArr)) return [];
  if(channelArr.length !== 1) return channelArr;

  const channelsData = [];
  const subChannelArr = _.first(channelArr).subChannels

  subChannelArr.map( channel => {
      const builder = new ChannelMetricsBuilder();
      const msgSource = _.get(channel, 'channel') || '';
      builder.setChannel(msgSource.toLowerCase());
      builder.setLabel(capitalize(msgSource));
      builder.setVolumeByUs(_.get(channel, 'count') || 0);

      channelsData.push(builder.metrics);
    })
  return channelsData;
}

/**
 * Convert a zero number to -
 * @param number
 * @returns {string|*}
 */
export function formatNumber(number) {
  if(!number) return '-'
  return number
}