import React from "react";

export default function useKustomerSetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const performAction = () => {
    handleOpen();
  };

	return {
		initiateKustomerSetup: performAction,
		kustomerSetupOpen: open, 
		handleKustomerSetupOpen: handleOpen,
		handleKustomerSetupClose: handleClose
	};
}