import {SERVER_URL} from "config";
import {authHeader} from '_helpers';
import axios from "axios";
function getEarningsHistory(params, agentId) {
    const endpoint = `${SERVER_URL}/api/earnings/history/${agentId}`;
    return axios.get(endpoint,{
        params: params,
        headers: authHeader()
    })
}

function getLeadAgents(agentId){
    const endpoint = `${SERVER_URL}/api/users/leadAgents/${agentId}`;
    return axios.get(endpoint, {
        headers: authHeader()
    })
}

function getAgentPayouts(agentId){
    const endpoint = `${SERVER_URL}/api/users/${agentId}/payouts`;
    return axios.get(endpoint, {
        headers: authHeader()
    })
}


export const earningsService = {
    getEarningsHistory,
    getLeadAgents,
    getAgentPayouts
};