import { dataSourceHandleConstants } from '_constants';
import { dataSourceHandleService } from '_services';
import {SnackBar} from "../components/common";
import {alertMessages} from "../_constants";


export const dataSourceHandleActions = {
    getAll,
    deactivateAll,
    getDataSourceHandle,
    update
};

function getAll(params) {
    function request() {
        return { type: dataSourceHandleConstants.GET_ALL_REQUEST };
    }

    function success(dataSourceHandles) {
        return { type: dataSourceHandleConstants.GET_ALL_SUCCESS, payload: dataSourceHandles };
    }

    function failure(error) {
        return { type: dataSourceHandleConstants.GET_ALL_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        dataSourceHandleService.getAll(params)
            .then(
                dataSourceHandles => dispatch(success(dataSourceHandles)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function deactivateAll() {
    function request() {
        return { type: dataSourceHandleConstants.DEACTIVATE_ALL_REQUEST };
    }

    function success(dataSourceHandle) {
        return { type: dataSourceHandleConstants.DEACTIVATE_ALL_SUCCESS, payload: dataSourceHandle };
    }

    function failure(error) {
        return { type: dataSourceHandleConstants.DEACTIVATE_ALL_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        dataSourceHandleService.deactivateAll()
            .then(
                (dataSourceHandle) => {dispatch(success(dataSourceHandle));
                    SnackBar.showMessage(alertMessages.DEACTIVATE_ALL_SUCCESS);
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}


function getDataSourceHandle(id) {
    return (dispatch) => {
        dispatch(request());

        dataSourceHandleService.getDataSourceHandle(id)
            .then(
                dataSourceHandle => dispatch(success(dataSourceHandle)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };

    function request() {
        return { type: dataSourceHandleConstants.GET_DETAILS_REQUEST};
    }

    function success(dataSourceHandle) {
        return { type: dataSourceHandleConstants.GET_DETAILS_SUCCESS, payload: dataSourceHandle };
    }

    function failure(error) {
        return {type: dataSourceHandleConstants.GET_DETAILS_FAILURE, payload: error};
    }
}

function update(data, handleId) {
    return (dispatch) => {
        dispatch(request());

        return dataSourceHandleService.update(data, handleId)
            .then(
                dataSourceHandle => {
                    dispatch(success(dataSourceHandle));
                    SnackBar.showMessage(alertMessages.DATA_SOURCE_HANDLE_UPDATE_SUCCESS);
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };

    function request(id) {
        return {type: dataSourceHandleConstants.UPDATE_HANDLE_REQUEST, payload: id};
    }

    function success(dataSourceHandle) {
        return {type: dataSourceHandleConstants.UPDATE_HANDLE_SUCCESS, payload: dataSourceHandle};
    }

    function failure(error) {
        return {type: dataSourceHandleConstants.UPDATE_HANDLE_FAILURE, payload: error};
    }
}
