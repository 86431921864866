import {cannotResolveOptionType, workMode, handleType, supportedSourcesForInternalNote} from "_constants";

export default [
    {
        label: `No response needed because this is spam or "thanks"`,
        value: cannotResolveOptionType.NO_RESPONSE_NEEDED,
        supportedWorkMode: [workMode.ACTIVE, workMode.PRACTICE],
    },
    {
        label: `I don't know how to answer this, but another Chatdesk Expert might`,
        value: cannotResolveOptionType.REROUTE,
        supportedWorkMode: [workMode.ACTIVE],
    },
    {
        label: `I was told to escalate this type of message e.g. very angry customer, health concerns, etc`,
        value: cannotResolveOptionType.ESCALATE,
        supportedWorkMode: [workMode.ACTIVE, workMode.PRACTICE],
    },
    {
        label: `I will leave an internal note for the company`,
        value: cannotResolveOptionType.INTERNAL_NOTE,
        supportedWorkMode: [workMode.ACTIVE],
        supportedHandleTypes: [handleType.email],
        supportedSources: supportedSourcesForInternalNote
    },
    {
        label: `I was told to skip this type of message`,
        value: cannotResolveOptionType.SKIPPED,
        supportedWorkMode: [workMode.ACTIVE, workMode.PRACTICE],
    },
]