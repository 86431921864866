import {metricsConstants, proactiveConstants} from '_constants';

const INITIAL_STATE = {
    campaign: {},
    loadingCampaign: true,
    campaignError: '',

    organizationCampaigns: {},
    loadingOrganizationCampaigns: false,
    organizationCampaignsError: '',

    allCampaigns: {},
    loadingAllCampaigns: true,
    allCampaignsError: '',

    createCampaign: {},
    loadingCreateCampaign: false,
    createCampaignError: '',

    updateCampaign: {},
    loadingUpdateCampaign: false,
    updateCampaignError: '',

    deletedCampaignAttribute: {},
    deletingCampaignAttribute: false,
    deletingCampaignAttributeError: '',
    requestAttributeId: '',

    organizationTemplates: {},
    loadingOrganizationTemplates: true,
    organizationTemplatesError: '',

    allTemplates: {},
    loadingAllTemplates: true,
    allTemplatesError: '',

    msgDataSources: {},
    loadingMsgDataSources: false,
    msgDataSourcesError: '',

    msgDataSourcesBySourceName: [],
    loadingMsgDataSourcesBySourceName: false,
    msgDataSourcesBySourceNameError: '',

    customerIoSegments: [],
    loadingCustomerIoSegments: false,
    customerIoSegmentsError: '',

    channels: {},
    loadingChannels: false,
    channelsError: '',

    replies: {},
    loadingGenerateReplies: false,
    generateRepliesError: '',

    createTemplate: {},
    loadingCreateTemplate: false,
    createTemplateError: '',

    updateTemplate: {},
    loadingUpdateTemplate: false,
    updateTemplateError: '',

    template: {},
    loadingTemplate: false,
    templateError: '',

    updateSuggestedResponse: {},
    loadingUpdateSuggestedResponse: false,
    updateSuggestedResponseError: '',
    updatingResponseId: undefined,

    mediaTypes: {},
    loadingMediaTypes: false,
    mediaTypesError: '',

    proactiveMessagesList: {},
    loadingProactiveMessagesList: false,
    proactiveMessagesListError: '',

    proactiveMsgConversation: {},
    loadingProactiveMsgConversation: false,
    proactiveMsgConversationError: '',

    campaignsMetrics: {},
    loadingCampaignsMetrics: false,
    campaignsMetricsError: '',

    exporting: false,

    segmentStatus: '',
    loadingSegmentStatus: false,
    segmentStatusError: '',

    customerDataFile: {},
    uploadingCustomerData: false,
    customerDataFileError: '',

    segmentTypes: {},
    loadingSegmentTypes: false,
    segmentTypesError: ''
};

export function proactiveReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case proactiveConstants.GET_CAMPAIGN_REQUEST:
            return {...state, loadingCampaign: true, campaignError: ''};
        case proactiveConstants.GET_CAMPAIGN_SUCCESS:
            return {...state, campaign: action.payload, loadingCampaign: false};
        case proactiveConstants.GET_CAMPAIGN_FAILURE:
            return {...state, campaignError: action.error, loadingCampaign: false};

        case proactiveConstants.GET_COMPANY_CAMPAIGNS_REQUEST:
            return {...state, loadingOrganizationCampaigns: true, organizationCampaignsError: ''};
        case proactiveConstants.GET_COMPANY_CAMPAIGNS_SUCCESS:
            return {...state, organizationCampaigns: action.payload, loadingOrganizationCampaigns: false};
        case proactiveConstants.GET_COMPANY_CAMPAIGNS_FAILURE:
            return {...state, organizationCampaignsError: action.error, loadingOrganizationCampaigns: false};

        case proactiveConstants.GET_CAMPAIGNS_METRICS_REQUEST:
            return {...state, campaignsMetrics: {}, loadingCampaignsMetrics: true, campaignsMetricsError: ''};
        case proactiveConstants.GET_CAMPAIGNS_METRICS_SUCCESS:
            return {...state, campaignsMetrics: action.payload, loadingCampaignsMetrics: false};
        case proactiveConstants.GET_CAMPAIGNS_METRICS_FAILURE:
            return {...state, campaignsMetricsError: action.error, loadingCampaignsMetrics: false};

        case proactiveConstants.GET_ALL_CAMPAIGNS_REQUEST:
            return {...state, loadingAllCampaigns: true, allCampaignsError: ''};
        case proactiveConstants.GET_ALL_CAMPAIGNS_SUCCESS:
            return {...state, allCampaigns: action.payload, loadingAllCampaigns: false};
        case proactiveConstants.GET_ALL_CAMPAIGNS_FAILURE:
            return {...state, allCampaignsError: action.error, loadingAllCampaigns: false};

        case proactiveConstants.CREATE_CAMPAIGN_REQUEST:
            return {...state, loadingCreateCampaign: true, createCampaignError: ''};
        case proactiveConstants.CREATE_CAMPAIGN_SUCCESS:
            return {...state, createCampaign: action.payload, loadingCreateCampaign: false};
        case proactiveConstants.CREATE_CAMPAIGN_FAILURE:
            return {...state, createCampaignError: action.error, loadingCreateCampaign: false};

        case proactiveConstants.UPDATE_CAMPAIGN_REQUEST:
            return {...state, loadingUpdateCampaign: true, updateCampaignError: ''};
        case proactiveConstants.UPDATE_CAMPAIGN_SUCCESS:
            return {...state, updateCampaign: action.payload, loadingUpdateCampaign: false};
        case proactiveConstants.UPDATE_CAMPAIGN_FAILURE:
            return {...state, updateCampaignError: action.error, loadingUpdateCampaign: false};

        case proactiveConstants.DELETE_CAMPAIGN_ATTRIBUTE_REQUEST:
            return {...state, deletedCampaignAttribute: {}, requestAttributeId: action.requestAttributeId, deletingCampaignAttribute: true, deletingCampaignAttributeError: ''};
        case proactiveConstants.DELETE_CAMPAIGN_ATTRIBUTE_SUCCESS:
            return {...state, deletedCampaignAttribute: action.payload, requestAttributeId: '', deletingCampaignAttribute: false};
        case proactiveConstants.DELETE_CAMPAIGN_ATTRIBUTE_FAILURE:
            return {...state, deletingCampaignAttributeError: action.error, deletingCampaignAttribute: false};

        case proactiveConstants.GET_COMPANY_TEMPLATES_REQUEST:
            return {...state, loadingOrganizationTemplates: true, organizationTemplatesError: ''};
        case proactiveConstants.GET_COMPANY_TEMPLATES_SUCCESS:
            return {...state, organizationTemplates: action.payload, loadingOrganizationTemplates: false};
        case proactiveConstants.GET_COMPANY_TEMPLATES_FAILURE:
            return {...state, organizationTemplatesError: action.error, loadingOrganizationTemplates: false};

        case proactiveConstants.GET_ALL_TEMPLATES_REQUEST:
            return {...state, loadingAllTemplates: true, allTemplatesError: ''};
        case proactiveConstants.GET_ALL_TEMPLATES_SUCCESS:
            return {...state, allTemplates: action.payload, loadingAllTemplates: false};
        case proactiveConstants.GET_ALL_TEMPLATES_FAILURE:
            return {...state, allTemplatesError: action.error, loadingAllTemplates: false};

        case proactiveConstants.GET_MSG_DATA_SOURCES_REQUEST:
            return {...state, loadingMsgDataSources: true, msgDataSourcesError: ''};
        case proactiveConstants.GET_MSG_DATA_SOURCES_SUCCESS:
            return {...state, msgDataSources: action.payload, loadingMsgDataSources: false};
        case proactiveConstants.GET_MSG_DATA_SOURCES_FAILURE:
            return {...state, msgDataSourcesError: action.error, loadingMsgDataSources: false};

        case proactiveConstants.GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_REQUEST:
            return {...state, loadingMsgDataSourcesBySourceName: true, msgDataSourcesBySourceNameError: ''};
        case proactiveConstants.GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_SUCCESS:
            return {...state, msgDataSourcesBySourceName: action.payload, loadingMsgDataSourcesBySourceName: false};
        case proactiveConstants.GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_FAILURE:
            return {...state, msgDataSourcesBySourceNameError: action.error, loadingMsgDataSourcesBySourceName: false};

        case proactiveConstants.GET_CUSTOMER_IO_SEGMENT_REQUEST:
            return {...state, loadingCustomerIoSegments: true, customerIoSegmentsError: ''};
        case proactiveConstants.GET_CUSTOMER_IO_SEGMENT_SUCCESS:
            return {...state, customerIoSegments: action.payload, loadingCustomerIoSegments: false};
        case proactiveConstants.GET_CUSTOMER_IO_SEGMENT_FAILURE:
            return {...state, customerIoSegmentsError: action.error, loadingCustomerIoSegments: false};

        case proactiveConstants.GET_CHANNELS_REQUEST:
            return {...state, loadingChannels: true, channelsError: ''};
        case proactiveConstants.GET_CHANNELS_SUCCESS:
            return {...state, channels: action.payload, loadingChannels: false};
        case proactiveConstants.GET_CHANNELS_FAILURE:
            return {...state, channelsError: action.error, loadingChannels: false};

        case proactiveConstants.GENERATE_REPLIES_REQUEST:
            return {...state, loadingGenerateReplies: true, generateRepliesError: ''};
        case proactiveConstants.GENERATE_REPLIES_SUCCESS:
            return {...state, replies: action.payload, loadingGenerateReplies: false};
        case proactiveConstants.GENERATE_REPLIES_FAILURE:
            return {...state, generateRepliesError: action.error, loadingGenerateReplies: false};

        case proactiveConstants.CREATE_TEMPLATE_REQUEST:
            return {...state, loadingCreateTemplate: true, createTemplateError: ''};
        case proactiveConstants.CREATE_TEMPLATE_SUCCESS:
            return {...state, createTemplate: action.payload, loadingCreateTemplate: false};
        case proactiveConstants.CREATE_TEMPLATE_FAILURE:
            return {...state, createTemplateError: action.error, loadingCreateTemplate: false};

        case proactiveConstants.GET_TEMPLATE_REQUEST:
            return {...state, loadingTemplate: true, templateError: ''};
        case proactiveConstants.GET_TEMPLATE_SUCCESS:
            return {...state, template: action.payload, loadingTemplate: false};
        case proactiveConstants.GET_TEMPLATE_FAILURE:
            return {...state, templateError: action.error, loadingTemplate: false};

        case proactiveConstants.UPDATE_TEMPLATE_REQUEST:
            return {...state, loadingUpdateTemplate: true, updateTemplateError: ''};
        case proactiveConstants.UPDATE_TEMPLATE_SUCCESS:
            return {...state, updateTemplate: action.payload, loadingUpdateTemplate: false};
        case proactiveConstants.UPDATE_TEMPLATE_FAILURE:
            return {...state, updateTemplateError: action.error, loadingUpdateTemplate: false};

        case proactiveConstants.UPDATE_RESPONSE_REQUEST:
            return {...state, loadingUpdateSuggestedResponse: true, updateSuggestedResponseError: '', updatingResponseId: action.responseId};
        case proactiveConstants.UPDATE_RESPONSE_SUCCESS:
            return {...state, updateSuggestedResponse: action.payload, loadingUpdateSuggestedResponse: false};
        case proactiveConstants.UPDATE_RESPONSE_FAILURE:
            return {...state, updateSuggestedResponseError: action.error, loadingUpdateSuggestedResponse: false};

        case proactiveConstants.GET_MEDIA_TYPES_REQUEST:
            return {...state, loadingMediaTypes: true, mediaTypesError: ''};
        case proactiveConstants.GET_MEDIA_TYPES_SUCCESS:
            return {...state, mediaTypes: action.payload, loadingMediaTypes: false};
        case proactiveConstants.GET_MEDIA_TYPES_FAILURE:
            return {...state, mediaTypesError: action.error, loadingMediaTypes: false};

        case proactiveConstants.UPDATE_SEGMENT_STATUS_REQUEST:
            return {...state, loadingSegmentStatus: true, segmentStatusError: ''};
        case proactiveConstants.UPDATE_SEGMENT_STATUS_SUCCESS:
            return {...state, segmentStatus: action.payload, loadingSegmentStatus: false};
        case proactiveConstants.UPDATE_SEGMENT_STATUS_FAILURE:
            return {...state, segmentStatusError: action.error, loadingSegmentStatus: false};

        case proactiveConstants.GET_PROACTIVE_MESSAGES_LIST_REQUEST:
            return {...state, loadingProactiveMessagesList: true, proactiveMessagesListError: ''};
        case proactiveConstants.GET_PROACTIVE_MESSAGES_LIST_SUCCESS:
            return {...state, proactiveMessagesList: action.payload, loadingProactiveMessagesList: false};
        case proactiveConstants.GET_PROACTIVE_MESSAGES_LIST_FAILURE:
            return {...state, proactiveMessagesListError: action.error, loadingProactiveMessagesList: false};

        case proactiveConstants.GET_PROACTIVE_MSG_CONVERSATION_REQUEST:
            return {...state, loadingProactiveMsgConversation: true, proactiveMsgConversationError: ''};
        case proactiveConstants.GET_PROACTIVE_MSG_CONVERSATION_SUCCESS:
            return {...state, proactiveMsgConversation: action.payload.items, loadingProactiveMsgConversation: false};
        case proactiveConstants.GET_PROACTIVE_MSG_CONVERSATION_FAILURE:
            return {...state, proactiveMsgConversationError: action.error, loadingProactiveMsgConversation: false};

        case proactiveConstants.EXPORT_PROACTIVE_MESSAGES_REQUEST:
            return {...state, exporting: true, error: ''};
        case proactiveConstants.EXPORT_PROACTIVE_MESSAGES_SUCCESS:
            return { ...state, exporting: false };
        case proactiveConstants.EXPORT_PROACTIVE_MESSAGES_FAILURE:
            return {...state, error: action.error, exporting: false};

        case proactiveConstants.UPLOAD_CUSTOMER_DATA_FILE_REQUEST:
            return {...state, uploadingCustomerData: true, error: ''};
        case proactiveConstants.UPLOAD_CUSTOMER_DATA_FILE_SUCCESS:
            return { ...state, uploadingCustomerData: false, customerDataFile: action.payload};
        case proactiveConstants.UPLOAD_CUSTOMER_DATA_FILE_FAILURE:
            return {...state, customerDataFileError: action.error, uploadingCustomerData: false};

        case proactiveConstants.GET_SEGMENT_TYPES_REQUEST:
            return {...state, loadingSegmentTypes: true, error: ''};
        case proactiveConstants.GET_SEGMENT_TYPES_SUCCESS:
            return { ...state, loadingSegmentTypes: false, segmentTypes: action.payload};
        case proactiveConstants.GET_SEGMENT_TYPES_FAILURE:
            return {...state, segmentTypesError: action.error, loadingSegmentTypes: false};

        case proactiveConstants.CLEAR_CONVERSATION:
            return {...state, proactiveMsgConversation: []};

        default:
            return state
    }
}
