import {alertMessages} from "_constants";
import {SnackBar} from "components/common";
import {adCommentsConstants} from "../_constants/adComments.constants";
import {adCommentsService} from "../_services/adComments.service";

export const adCommentsActions = {
    activatePoll
};

function activatePoll(params) {
    return (dispatch) => {
        dispatch(request());
        adCommentsService.activatePoll(params)
            .then(
                response => {
                    dispatch(success(response))
                    SnackBar.showMessage(response?.message ?? "Request placed successfully")
                },
                error => {
                    Promise.resolve(error).then(function(value) {
                        SnackBar.showMessage(value ?? alertMessages.GENERIC_ERROR)
                    })
                    dispatch(failure(error));
                }
            );
    }

    function request(){
        return {type: adCommentsConstants.ACTIVATE_POLL_REQUEST}
    }

    function success(response) {
        return {type: adCommentsConstants.ACTIVATE_POLL_SUCCESS, payload: response}
    }

    function failure(error){
        return {type: adCommentsConstants.ACTIVATE_POLL_FAILURE, error: error}
    }
}


