import {Fragment} from "react";
import {Box} from "@mui/system";
import ActionButton from "../../../../common/ActionButton";

export default function DeleteScheduleModalContainer({handleClose, handleDelete}) {

    return(
        <Fragment>
            <Box sx={{marginTop: '-8px'}}>
                <Box sx={styles.heading}>
                    The selected schedule will be deleted
                </Box>

                <Box sx={styles.actionsContainer}>
                    <Box sx={styles.buttonContainer}>
                        <ActionButton
                            label={'Cancel'}
                            action={handleClose}
                            buttonBordered
                            useBorderColor={'#07857C'}
                            withIcon={false}
                            sx={styles.cancel}
                        />
                        <ActionButton
                            label={'Delete'}
                            action={handleDelete}
                            withIcon={false}
                            sx={styles.delete}
                        />
                    </Box>
                </Box>
            </Box>
        </Fragment>
    )
}

const styles = {
    heading: {
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#0C2728',
        letterSpacing: '-0.01em',
        marginBottom: '12px'
    },
    buttonContainer: {
        display: 'flex',
    },
    cancel: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '8px'
    },
    delete: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    actionsContainer: {
        display: 'flex',
        marginTop: '27px',
        justifyContent: 'space-between'
    }
}