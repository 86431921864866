import React from 'react';
import { Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();

let sentryDsn;
let hostname = window && window.location && window.location.hostname;
let PROD_CLIENT_URL = 'teams.chatdesk.com';

if (hostname === PROD_CLIENT_URL) {
    sentryDsn =  process.env.REACT_APP_TEAMS_PROD_SENTRY_DSN;
} else {
    sentryDsn = process.env.REACT_APP_TEAMS_DEV_STAGING_SENTRY_DSN;
}

Sentry.init({
    dsn: sentryDsn,
    integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ],
    tracesSampleRate: 1.0,
});

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return <SentryRoute {...rest} component={Component}/>
};