import {Box} from "@mui/system";
import LoadingPlaceholderBlock from "../../../../../common/LoadingPlaceholderBlock";

export default function ConversationInfoEmptyState() {
    return(
        <Box sx={style.root}>
            <Box sx={style.placeholderContainer}>
                <LoadingPlaceholderBlock
                    height={'18px'}
                    borderRadius={'3px'}
                    backgroundColor={'#EFEAE5'}
                />
            </Box>
        </Box>
    )
}

const style = {
    root: {
        height: '24px',
        display: 'flex',
        alignItems: 'center',
    },
    placeholderContainer: {
        width: '250px',
    }
}