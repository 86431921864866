import {Box} from "@mui/system";
import React, {Fragment} from "react";


export default function StepIndicator({number, label, isActive}) {
  return(
    <Fragment>
      <Box style={isActive ? styles.bgTeal : styles.bgSubmarine}>
        <Box style={isActive ? styles.numberWhite : styles.numberSubmarine}>
          {number}
        </Box>
      </Box>
      <Box style={isActive ? styles.labelTeal : styles.labelSubmarine}>
        {label}
      </Box>
    </Fragment>
  )
}

const styles = {
  numberWhite:{
    fontSize: '11px',
    color: '#fff',
    fontFamily: 'LarsseitMedium'
  },
  numberSubmarine:{
    fontSize: '11px',
    color: '#859393',
    fontFamily: 'LarsseitMedium'
  },
  labelTeal: {
    lineHeight: '120%',
    marginTop: '8px',
    fontSize: '12px',
    color: '#0C2728',
    fontFamily: 'LarsseitRegular'
  },
  labelSubmarine: {
    lineHeight: '120%',
    marginTop: '8px',
    fontSize: '12px',
    color: '#859393',
    fontFamily: 'LarsseitRegular'
  },
  bgTeal: {
    width: '16px',
    height: '16px',
    border: '1px solid #0C2728',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    backgroundColor: '#0C2728',
    paddingTop: '0.7px'
  },
  bgSubmarine:{
    width: '16px',
    height: '16px',
    border: '1px solid #859393',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    backgroundColor: '#fff',
    paddingTop: '0.7px'
  }
};