import React from 'react'
import { connect } from "react-redux";
import { payoutsActions, currencyActions, alertActions } from "_actions";
import PaymentForm from 'components/Payments/PaymentForm'

class CreatePayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payoutsData: {
                currency: '',
                amount: '',
                label: '',
            },
            submitting: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(currencyActions.getAll())
    }

    handleChange = (event) => {
        const { target: {name, value}} = event
        this.setState(prevState => ({
            payoutsData: {
                ...prevState.payoutsData,
                [name]: value
            }
        }))
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { payoutsData } = this.state
        const { dispatch } = this.props
        if (! payoutsData.currency || ! payoutsData.amount || !payoutsData.label) {
            dispatch(alertActions.error('All Form Fields are required'))
            return
        }
        this.setState({ submitting: true })
        dispatch(payoutsActions.createPayout(payoutsData))
    }

    render() {
        const { currencies } = this.props
        const { payoutsData, submitting } = this.state
        
        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <PaymentForm
                    title="Create Payout Rate"
                    formData={payoutsData}
                    currencies={currencies}
                    submitting={submitting}
                    url="/payouts-rates"
                    actionType="Create"
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { currency: { all_currency } } = state;

    return {
        currencies: all_currency
    };
}

const createPayoutPage = connect(mapStateToProps)(CreatePayout);
export { createPayoutPage as CreatePayout };