const notificationInboxStyle = theme => ({
    root: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding:"18px 38px",
        background:"#fff",
        borderRadius:"3px",
        border: "solid 1px #dee2e0",
        boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)"
    },
    paper: {
        padding:"10px 0",
        fontSize:"16px",
        boxShadow: "0 0px 1px 0 rgba(196, 201, 199, 0.0)"
    },

    typography: {
        fontSize:"15px",
    },

    messageContent: {
        borderTop:"1px solid #dee2e0",
        padding:"15px 0"
    },

    colorGreen: {
        color: "#008751"
    },

    buttonContainer: {
        padding:"15px 0"
    },

    navLink: {
        cursor:"pointer",
    },

    menuItem:{
        fontSize:"14px",
        margin:"0 20px",
        padding:"7px 2px",
        background:"transparent !important",
        borderBottom:"1px solid #dee2e0",
        '&:last-child':{
            borderBottom:"1px solid transparent"
        },
        '&:hover':{
            color: " #01577c"
        }
    }
});

export { notificationInboxStyle }