import React, {Component} from "react";
import { stylesTable} from "components/Inbox/StylesTable";
import { Paper, Table, TableBody, TableCell, TableRow, Typography, withStyles} from '@material-ui/core';
import PropTypes from "prop-types";
import moment from "moment";
import htmlParser from "he";
import {stripTrailingQuestionMarkFromHtmlEntity} from "_helpers";
import IconHead from "../images/person_pin-24px.svg";

/**
 * @author Ogunyale Richard Kehinde <kehinde@chatdesk.com>
 */

class SearchResultTable extends Component {
    render() {
        const { messages, classes, resultCount, showConversations} = this.props;
        const zone_name = moment.tz.guess();
        const timezone = moment.tz(zone_name).zoneAbbr();
        return (
            <div className="search-messages-table">
                <div style={{marginBottom: '30px', marginTop: '10px' }}>{resultCount ? resultCount :0} results</div>
                <Paper className={classes.root}>
                    {messages.length ===0 &&
                    <div style={{textAlign: 'center', fontsize: '20px', padding: '30px'}}>
                        There are no message that matches your search
                    </div>
                    }
                    {messages.length > 0 &&
                    <Table className={classes.table}>
                        <thead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography style={{width: "850px"}} className={classes.typography15}>Message</Typography>
                                </TableCell>
                                <TableCell style={{width: "80px"}} className={classes.tableRow}>
                                    <Typography className={classes.typography15}>Direction</Typography>
                                </TableCell>
                                <TableCell style={{width: '200px'}} className={classes.tableCell}>
                                    <Typography className={classes.typography15}>Date</Typography>
                                </TableCell>
                            </TableRow>
                        </thead>
                        <TableBody>
                            {messages.map((message, index) => {
                                let text =htmlParser.decode(stripTrailingQuestionMarkFromHtmlEntity(message.text));
                                return (
                                    <TableRow key={index} className={classes.tableRow}>
                                        <TableCell className={classes.tableCell}>
                                            <div onClick={() => showConversations(message.messageId)} className="btn-link tms-no-underline">
                                                <Typography className={classes.typography14}>
                                                    <span className="font-14 truncate-string">{text}</span>
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {message.direction === "Incoming" &&
                                            <span>
                                                <img src={IconHead} title="incoming message" alt="incoming message" />
                                            </span>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography className={classes.typography14}>
                                                {moment(message.messageDate).format('MMM DD YYYY hh:mm A z')}{timezone}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>}
                </Paper>
            </div>
        )
    }
}

SearchResultTable.propTypes = {
    classes: PropTypes.object.isRequired,
}

const StyledMessagesTable = withStyles(stylesTable)(SearchResultTable);

export { StyledMessagesTable as SearchResultTable }