import {Box, Stack} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, {Fragment} from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeSharpIcon from "@mui/icons-material/HomeSharp";
import PublicIcon from "@mui/icons-material/Public";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UserDropdownOptions from "./UserDropdownOptions";

export default function UserProfileDetails({user}) {
    const userContactDetails = [
        {
            desc: 'Phone',
            value: user?.phoneNumber,
            icon: <PhoneIcon />
        },
        {
            desc: 'Address',
            value: user?.address,
            icon: <HomeSharpIcon />
        },
        {
            desc: 'Country',
            value: user?.country,
            icon: <PublicIcon />
        },
        {
            desc: 'Timezone',
            value: user?.timezone,
            icon: <AccessTimeIcon />
        }
    ];

    return(
        <Fragment>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Box>
                    <Box sx={{ fontSize: '24px', fontFamily: 'larsseitMedium', whiteSpace: 'pre', mb: '10px', lineHeight: '19.2px' }}>
                        {user?.firstName} {user?.lastName}
                    </Box>
                    <Box sx={{ fontSize: '18px', fontFamily: 'larsseitMedium', whiteSpace: 'pre', mb: '10px' }}>
                        {user.username}
                    </Box>
                    <Stack direction={'row'} alignItems={"center"} spacing={1}>
                        <Box sx={styles.iconContainer}>
                            <LocationOnIcon size="small" />
                        </Box>
                        <Box sx={styles.contactText} >
                            {user.city + ", " + user.state}
                        </Box>
                    </Stack>
                </Box>
            </Box>

            <Box sx={{ flex: 1 }}>
                <Box sx={styles.contactHeading}>CONTACT</Box>
                <Stack spacing={1}>
                    {user && userContactDetails?.map(({desc, value, icon}) => (
                        <Stack direction={'row'} alignItems={"flex-end"} spacing={1} key={desc}>
                            <Box sx={styles.iconContainer}>{icon}</Box>
                            <Box sx={styles.contactText}>{value}</Box>
                        </Stack>
                    ))}
                </Stack>
            </Box>

            <Box>
                <UserDropdownOptions user={user} />
            </Box>
        </Fragment>
    )
}
const styles = {
    contactHeading: {
        fontSize: '16px',
        fontFamily: 'larsseitMedium',
        lineHeight: '19.2px',
        fontWeight: '700',
        mb: '10px'
    },
    iconContainer: {
        color: '#07857C',
        fontSize: '20px',
        width: '25px',
        height: '22px',
    },
    contactText: {
        fontSize: "14px",
        fontFamily: "LarsseitMedium",
        color: "#495D5E",
    },
}