import React, {useEffect} from "react";
import {Box} from "@mui/material";
import AddScheduleForm from "./AddScheduleForm";
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router-dom";
import {dataSourceHandleActions, scheduleActions} from "_actions";
import {Loading} from "../../../../common";
import moment from "moment";

function AddSchedule(){
    const dispatch = useDispatch()
    const params = useParams();
    const {id} = params;

    const dataSourceHandles = useSelector((state) => state.dataSourceHandles.dataSourceHandles)
    const loading = useSelector((state) => state.dataSourceHandles.loading)

    const options = dataSourceHandles.length > 0 ? dataSourceHandles.map(handle => {
        return {name: handle.name, id: handle.id}
    }) : []

    const onSubmit = (payload) => {
        const format = "MM/DD/YYYY HH:mm:ss";
        let {startTime, endTime} = payload;
        startTime = moment(startTime).format(format);
        endTime = moment(endTime).format(format);

        const data = {
            ...payload,
            startTime: startTime,
            endTime: endTime,
            organization: id
        };

        dispatch(scheduleActions.addSchedule(data))
    }

    useEffect(() => {
        dispatch(dataSourceHandleActions.getAll({organization: id}))
    },[])

    if (loading) return <Loading/>

    return(
        <Box sx={styles.rootWrapper}>
            <Box sx={styles.container}>
                <Box component={"h1"} sx={styles.headingText}>Add Schedule</Box>

                <AddScheduleForm
                    options={options}
                    onSubmit={onSubmit}
                />
            </Box>
        </Box>
    )
}

const styles = {
    rootWrapper: {
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
    },
    headingText: {
        m: 0,
        fontFamily: 'LarsseitMedium',
        fontSize: 24,
        lineHeight: 1.2,
        letterSpacing: '-0.02em',
        color: '#0C2728'
    },
}

export default AddSchedule