import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {metricsActions} from "_actions";
import _ from "lodash";

export default function (startDate, endDate) {
  const dispatch = useDispatch();

  useEffect(() => {
    const { organization_id = '' } = JSON.parse(localStorage.user);
    dispatch(metricsActions.getQualityScoreMetrics(organization_id, startDate, endDate));
  }, [startDate, endDate]);

  const metrics = useSelector(state => state.metrics);
  const qualityScoreMetrics = metrics.qualityScoreMetrics;
  const loading = metrics.loadingQualityScoreMetrics;

  const data = {};

  if (!loading) {
    // Divide by 100 to calculate percentage in decimal.
    data.csat = _.get(qualityScoreMetrics, 'csat') / 100 || 0;
  }

  return { data, loading };
}
