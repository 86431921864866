import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {messageActions} from "_actions";
import {buildNextMessagePayload} from "_helpers";

export default function () {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            showNextVoteMessage();
            return () => effectRan.current = true;
        }
    }, []);

    const showNextVoteMessage = () => {
        const payload = buildNextMessagePayload();
        dispatch(messageActions.showNextVoteMessage(payload));
    }

    const nextMessage = useSelector(state => state.messages.nextMessage);
    const loadingNextMessage = useSelector(state => state.messages.loadingNextMessage);
    return { nextMessage, loadingNextMessage };
}