import { useMemo } from 'react';
import { queryStringToObject } from '_helpers';

/**
 * This hook can be used when working with query params.
 * You can receive the currently set url query params and by using the returned `setParams` method,
 * the browser will change the query params you set, while keeping all others, and refreshing the `params` state.
 */
const useLocationQueryParams = () => {
	const params = useMemo(() => {
		const search = window.location.search.substring(1);
		return queryStringToObject(search);
	}, [window.location]);

	/**
	 * @param {object} newParams 
	 */
	const setParams = (newParams) => {
		const url = new URL(window.location);
		
		for (const [key, value] of Object.entries({ ...Object.fromEntries(url.searchParams), ...newParams })) {
			if (!(key in newParams))
				continue;
			else {
				if (newParams[key] === undefined || newParams[key] === null) 
					url.searchParams.delete(key);
				else
					url.searchParams.set(key, value);
			}
		}

		window.history.pushState(null, '', url.toString());
	}

	return {
		params,
		setParams,
	}
}

export default useLocationQueryParams