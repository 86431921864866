import {shopifyOauthService, utilityService} from '_services';
import {alertMessages, oauthConstants} from '_constants';
import {SnackBar} from "../components/common";
import {history} from "../_helpers";


function initiateOauth(params) {
    function request() {
        return {type: oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_REQUEST};
    }

    function success(authUrl) {
        return {type: oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_SUCCESS, payload: authUrl};
    }

    function failure(error) {
        return {type: oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        shopifyOauthService.initiateOauth(params)
            .then(
                (authUrl) => {
                    if (authUrl) {
                        dispatch(success(authUrl));
                    }
                },
                (error) => {


                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SHOPIFY_OAUTH_ERROR);

                },
            );
    };
}

function initOauth(params) {
    function request() {
        return {type: oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_REQUEST};
    }
    function success(authUrl) {
        return {type: oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_SUCCESS, payload: authUrl};
    }
    function failure(error) {
        return {type: oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());
        shopifyOauthService.initiateOauth(params)
          .then((res) => {
                utilityService.redirectToAuthUrl(res.redirectUrl);
                dispatch(success(res));
            },
            (error) => {
                dispatch(failure(error));
                SnackBar.showMessage(alertMessages.SHOPIFY_OAUTH_ERROR);
            },
          );
    };
}

function generateAccessToken(params, successRedirectUrl = undefined, errorRedirectUrl = undefined) {

    return (dispatch) => {
        dispatch(request());

        shopifyOauthService.generateAccessToken(params)
            .then(
                (res) => {
                    if (res) {
                        if (successRedirectUrl) {
                            history.push(successRedirectUrl);
                        }
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.SHOPIFY_OAUTH_SUCCESS);
                    }
                },
                (error) => {
                    if (errorRedirectUrl) {
                        history.push(errorRedirectUrl);
                    }
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SHOPIFY_OAUTH_ERROR);
                },
            );
    };

    function request() {
        return { type: oauthConstants.SHOPIFY_GENTOKEN_REQUEST };
    }

    function success(res) {
        return { type: oauthConstants.SHOPIFY_GENTOKEN_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: oauthConstants.SHOPIFY_GENTOKEN_FAILURE, payload: error };
    }
}

export const shopifyOauthActions = {
    initiateOauth,
    generateAccessToken,
    initOauth
};