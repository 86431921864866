import { oauthConstants } from '_constants';

const INITIAL_STATE = {
    data: [],
    error: '',
    loading: true,
    status: '',
    channel: 'shopify',
};

export function shopifyOauthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_REQUEST:
            return {...state, loading: true};
        case oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_SUCCESS:
            return {...state, data: action.payload, loading: false};
        case oauthConstants.SHOPIFY_SUBMIT_SUBDOMAIN_FAILURE:
            return {...state, error: action.error, loading: false};

        case oauthConstants.SHOPIFY_GENTOKEN_REQUEST:
            return { ...state, loading: true };
        case oauthConstants.SHOPIFY_GENTOKEN_SUCCESS:
            return { ...state, authUrl: action.payload, loading: false };
        case oauthConstants.SHOPIFY_GENTOKEN_FAILURE:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}