import {companyRepliedConstants, userConstants} from '_constants';


const INITIAL_STATE = {
    users: [],
    visitedPages: {},
    experts: {},
    activeExperts:[],
    inActiveExperts: [],
    practicingExperts: {},
    qualityScores: {},
    qualityReviews: {},
    lessActiveAgents: {},
    activeExpertsLoading: false,
    inActiveExpertsLoading: false,
    practicingExpertsLoading: false,
    qualityScoresLoading: false,
    qualityReviewsLoading: false,
    user: {},
    currentUser: {},
    confirmUser: false,
    loading: false,
    adminPerformance: {},
    error: '',
    chatdeskUser: [],
    loadingMarkAsDone: false,
    markAsDone: {},
    loadingCompanyReplied: false,
    companyReplied: {},
};

export function usersReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case userConstants.GET_ALL_REQUEST:
            return { ...state, loading: true };
        case userConstants.GET_ALL_SUCCESS:
            return { ...state, users: action.users, loading: false };
        case userConstants.GET_ALL_FAILURE:
            return { ...state, error: action.error, loading: false };

        case userConstants.SEARCH_USER_REQUEST:
            return {...state, loading: true};

        case userConstants.SEARCH_USER_SUCCESS:
            return {...state, users: action.users, loading: false};

        case userConstants.SEARCH_USER_FAILURE:
            return {...state, error: action.error, loading: false};


        case userConstants.GET_DETAILS_REQUEST:
            return { ...state, loading: true };
        case userConstants.GET_DETAILS_SUCCESS:
            return { ...state, user: action.payload, loading: false };
        case userConstants.GET_DETAILS_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case userConstants.GET_EXPERT_RECENT_ACTIVITY_DATA_REQUEST:
            return { ...state, loading: true };
        case userConstants.GET_EXPERT_RECENT_ACTIVITY_DATA_SUCCESS:
            return { ...state, experts: action.users, loading: false };
        case userConstants.GET_EXPERT_RECENT_ACTIVITY_DATA_FAILURE:
            return { ...state, error: action.error, loading: false };

        case userConstants.GET_ADMIN_PERFORMANCE_REQUEST:
            return { ...state, loading: true };
        case userConstants.GET_ADMIN_PERFORMANCE_SUCCESS:
            return { ...state, adminPerformance: action.admins, loading: false };
        case userConstants.GET_ADMIN_PERFORMANCE_FAILURE:
            return { ...state, error: action.error, loading: false };

        case userConstants.GET_ACTIVE_EXPERT_REQUEST:
            return { ...state, activeExpertsLoading: true };
        case userConstants.GET_ACTIVE_EXPERT_SUCCESS:
            return { ...state, activeExperts: action.experts, activeExpertsLoading: false };
        case userConstants.GET_ACTIVE_EXPERT_FAILURE:
            return { ...state, error: action.error, activeExpertsLoading: false };

        case userConstants.GET_INACTIVE_EXPERT_REQUEST:
            return { ...state, inActiveExpertsLoading: true };
        case userConstants.GET_INACTIVE_EXPERT_SUCCESS:
            return { ...state, inActiveExperts: action.experts, inActiveExpertsLoading: false };
        case userConstants.GET_INACTIVE_EXPERT_FAILURE:
            return { ...state, error: action.error, inActiveExpertsLoading: false };

        case userConstants.GET_PRACTICING_EXPERT_REQUEST:
            return { ...state, practicingExpertsLoading: true };
        case userConstants.GET_PRACTICING_EXPERT_SUCCESS:
            return { ...state, practicingExperts: action.experts, practicingExpertsLoading: false };
        case userConstants.GET_PRACTICING_EXPERT_FAILURE:
            return { ...state, error: action.error, practicingExpertsLoading: false };

        case userConstants.QUALITY_SCORES_REQUEST:
            return { ...state, qualityScoresLoading: true };
        case userConstants.QUALITY_SCORES_SUCCESS:
            return { ...state, qualityScores: action.qualityScores, qualityScoresLoading: false };
        case userConstants.QUALITY_SCORES_FAILURE:
            return { ...state, error: action.error, qualityScoresLoading: false };

        case userConstants.QUALITY_REVIEWS_REQUEST:
            return { ...state, qualityReviewsLoading: true };
        case userConstants.QUALITY_REVIEWS_SUCCESS:
            return { ...state, qualityReviews: action.qualityReviews, qualityReviewsLoading: false };
        case userConstants.QUALITY_REVIEWS_FAILURE:
            return { ...state, error: action.error, qualityReviewsLoading: false };

        case userConstants.GET_AGENT_MESSAGES_REQUEST:
            return { ...state, loading: true };

        case userConstants.DELETE_VISITED_PAGES:
            return { ...state, visitedPages: {} };

        case userConstants.GET_AGENT_MESSAGES_SUCCESS:
            return { ...state,
                    user: { messages: action.payload.messages },
                    visitedPages: {...state.visitedPages, [`page${action.payload.page}`]:action.payload.messages},
                    loading: false };

        case userConstants.GET_AGENT_MESSAGES_FAILURE:
            return { ...state, error: action.payload, loading: false };


        case userConstants.GET_CURRENT_USER_REQUEST:
            return { ...state, currentUser: { ...action, loading: true } };
        case userConstants.GET_CURRENT_USER_SUCCESS:
            return { ...state, currentUser: action.payload, loading: false };
        case userConstants.GET_CURRENT_USER_FAILURE:
            return { ...state, error: action.payload, loading: false };


        case userConstants.SAVE_USER_REQUEST:
            return { ...state, loading: true };
        case userConstants.SAVE_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false };
        case userConstants.SAVE_USER_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case userConstants.SAVE_USER_BY_ADMIN_REQUEST:
            return { ...state, loading: true };
        case userConstants.SAVE_USER_BY_ADMIN_SUCCESS:
            return { ...state, user: action.payload, loading: false };
        case userConstants.SAVE_USER_BY_ADMIN_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map(user =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                )
            };
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                items: state.items.filter(user => user.id !== action.id)
            };
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        case userConstants.UPDATE_USER_PHOTO_REQUEST:
            return { ...state, loading: true }
        case userConstants.UPDATE_USER_PHOTO_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user, profilePicture: `${action.user.profilePicture}#${new Date().getTime()}`
                },
                currentUser: {
                    ...state.currentUser, profilePicture: `${action.user.profilePicture}#${new Date().getTime()}`
                },
                loading: false
            }
        case userConstants.UPDATE_USER_PHOTO_FAILURE:
            return { ...state, loading: false }
        case userConstants.USER_CONFIRM_ACCOUNT_SUCCESS:
            return { ...state, confirmUser: action.response, loading: false }
        case userConstants.USER_CONFIRM_ACCOUNT_FAILURE:
            return { ...state, loading: false }

        case userConstants.GET_CHATDESK_USER_REQUEST:
            return { ...state, loading: true };
        case userConstants.GET_CHATDESK_USER_SUCCESS:
            return { ...state, chatdeskUser: action.payload, loading: false };
        case userConstants.GET_CHATDESK_USER_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case userConstants.GET_MESSAGES_FOR_AGENT_REQUEST:
            return { ...state, loading: true };

        case userConstants.GET_MESSAGES_FOR_AGENT_SUCCESS:
            return { ...state,
                user: { messages: action.payload.messages },
                visitedPages: {...state.visitedPages, [`page${action.payload.page}`]:action.payload.messages},
                loading: false };

        case userConstants.GET_MESSAGES_FOR_AGENT_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case userConstants.MARK_COMPANY_INBOX_MSG_AS_DONE_REQUEST:
            return { ...state, markAsDone: {}, loadingMarkAsDone: true };
        case userConstants.MARK_COMPANY_INBOX_MSG_AS_DONE_SUCCESS:
            return { ...state, markAsDone: action.payload, loadingMarkAsDone: false };
        case userConstants.MARK_COMPANY_INBOX_MSG_AS_DONE_FAILURE:
            return { ...state, loadingMarkAsDone: false };

        case companyRepliedConstants.COMPANY_REPLIED_REQUEST:
            return { ...state, markAsDone: {}, companyReplied: {}, loadingCompanyReplied: true };
        case companyRepliedConstants.COMPANY_REPLIED_SUCCESS:
            return { ...state, companyReplied: action.payload, loadingCompanyReplied: false };
        case companyRepliedConstants.COMPANY_REPLIED_FAILURE:
            return { ...state, loadingCompanyReplied: false };

        case userConstants.GET_LESS_ACTIVE_AGENT_REQUEST:
            return { ...state, loading: true };
        case userConstants.GET_LESS_ACTIVE_AGENT_SUCCESS:
            return { ...state, lessActiveAgents: action.experts, loading: false };
        case userConstants.GET_LESS_ACTIVE_AGENT_FAILURE:
            return { ...state, error: action.error, loading: false };

        default:
            return state
    }
}
