import {SERVER_URL} from "../config";
import axios from "axios";
import {authHeader} from "_helpers";

/**
 * @author: Chris Okebata
 */


const getAll = () => {
  const endpoint = `${SERVER_URL}/api/portals`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
};

const getPortalForMessage = (messageId) => {
  const endpoint = `${SERVER_URL}/api/portals/message/${messageId}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
};

const getOrganizationPortal = (organizationId) => {
  const endpoint = `${SERVER_URL}/api/portals/organization/${organizationId}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
};

const getAllIntegratedPortals = () => {
  const endpoint = `${SERVER_URL}/api/portals/integrated`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
};

const savePortal = (params) => {
  const endpoint = `${SERVER_URL}/api/portals`;

  return axios.post(endpoint, params, {
    headers: authHeader()
  });
};

const editPortal = (id, params) => {
  const endpoint = `${SERVER_URL}/api/portals/${id}`;

  return axios.put(endpoint, params, {
    headers: authHeader()
  });
};

const openPortal = (endpoint, payload) => {
  const url = `http://127.0.0.1:3000/${endpoint}`;

  return axios.post(url, payload,{
    headers: authHeader()
  });
};

const deletePortal = (id) => {
  const endpoint = `${SERVER_URL}/api/portals/${id}`;

  return axios.delete(endpoint, {
    headers: authHeader()
  });
};


export const portalService = {
  getAll,
  getPortalForMessage,
  getOrganizationPortal,
  getAllIntegratedPortals,
  savePortal,
  editPortal,
  deletePortal,
  openPortal,
};