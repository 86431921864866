import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';
import { SERVER_URL } from 'config';
import axios from "axios";


function getAll(params) {
    const id = params.message;
    return fetch(`${SERVER_URL}/api/responses/message/${id}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

/**
 * Retrieves the responses the given expert voted/acted on for the given messages.
 * @param messageId: String
 * @param expertId
 * @example '50m3r4nd0m-5tr1n9-451D0f'
 * @param params: Filter parameters e.g {voteTag: 'APPROVE', actionType: 'escalation'}
 * @returns {Promise<Response>}
 */
function getExpertMessageResponses(messageId, expertId, params) {
  return fetch(`${SERVER_URL}/api/users/${expertId}/messages/${messageId}/responses?${objectToQueryString(params)}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

const addResponseOption = (messageId, responseMessageId, text, userId) => {
  const endpoint = `${SERVER_URL}/api/responses/add`;

  return axios.post(endpoint, { messageId, responseMessageId, text, userId },{
    headers: authHeader()
  });
};

function getUserAddedResponses(messageId, userId) {
    const endpoint = `${SERVER_URL}/api/responses/message/${messageId}/user/${userId}`;

    return axios.get(endpoint, {
        headers: authHeader()
    });
}

export const responseOptionService = {
  getAll,
  getExpertMessageResponses,
  addResponseOption,
  getUserAddedResponses
};
