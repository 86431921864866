export class ResourcesBuilder {
    constructor() {
        this.resource = {};
    }

    setId(id){
        this.resource.id = id;
        return this;
    }

    setName(name){
        this.resource.name = name;
        return this;
    }

    setOrganizationName(organizationName){
        this.resource.organizationName = organizationName;
        return this;
    }

    setLink(link){
        this.resource.link = link;
        return this;
    }

    setStatus(isActive){
        this.resource.isActive = isActive ? "Enabled" : "Disabled";
        return this;
    }

    setActions(actions){
        this.resource.actions = actions;
        return this;
    }
    build(){
        return this.resource;
    }
}
