import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

export const billingService = {
    getBillingSettingsLink
};

function getBillingSettingsLink() {
    return fetch(`${SERVER_URL}/api/billing/settings`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}