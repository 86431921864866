import {dataSourceHandleConstants} from '_constants';

const INITIAL_STATE = {
    dataSourceHandles: [],
    dataSourceHandle: {},
    loading: true,
    error: '',
};

export function dataSourceHandleReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case dataSourceHandleConstants.GET_ALL_REQUEST:
            return {...state, loading: true, error: ''};
        case dataSourceHandleConstants.GET_ALL_SUCCESS:
            return {...state, dataSourceHandles: action.payload, loading: false};
        case dataSourceHandleConstants.GET_ALL_FAILURE:
            return {...state, error: action.error, loading: false};


        case dataSourceHandleConstants.GET_DETAILS_REQUEST:
            return {...state, loading: true, error: ''};
        case dataSourceHandleConstants.GET_DETAILS_SUCCESS:
            return {...state, dataSourceHandle: action.payload, loading: false};
        case dataSourceHandleConstants.GET_DETAILS_FAILURE:
            return {...state, error: action.error, loading: false};

        case dataSourceHandleConstants.UPDATE_HANDLE_REQUEST:
            return {...state, loading: true, error: ''};
        case dataSourceHandleConstants.UPDATE_HANDLE_SUCCESS:
            return {...state, dataSourceHandle: action.payload, loading: false};
        case dataSourceHandleConstants.UPDATE_HANDLE_FAILURE:
            return {...state, error: action.error, loading: false};


        default:
            return state
    }
}
