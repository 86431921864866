import {Fragment} from "react";
import {Box} from "@mui/system";
import BasicAccordion from "../../BasicAccordion";
import {parseHtml} from "_helpers";

export default function MoreInfo({other}) {
    const InfoItem = ({objectKey, objectValue}) => {
        return(
            <Box sx={styles.accordionDetail}>
                <Box sx={styles.infoContentKey}>
                    {`${objectKey}:`}
                </Box>
                <Box sx={styles.infoContentValue}>
                    {parseHtml(objectValue)}
                </Box>
            </Box>
        )
    }

    return(
        <Fragment>
            <Box sx={{}}>
                <BasicAccordion key={1} keyNumber={1} summaryComponent={<Box>More information</Box>} sx={styles.accordionRoot}>
                    {
                        Object.entries(other || {}).map(([key, value], index) => {
                            return <InfoItem key={index} objectKey={key} objectValue={value} />
                        })
                    }
                </BasicAccordion>
            </Box>

        </Fragment>
    )
}

const styles = {
    accordionRoot: {
        border: '1px solid #DCE2E3',
        borderRadius: '4px',
        lineHeight: '140%',
        fontFamily: 'LarsseitMedium',
        fontSize: '15px',
        color: '#07857C',
    },
    accordionDetail: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: '#DCE2E3',
        padding: '8px 10px',
        lineHeight: '140%',
        fontSize: '14px',
        color: '#0C2728',
        fontFamily: 'LarsseitRegular',
        display: 'flex',
        '&:first-of-type': {borderTopLeftRadius: '3px', borderTopRightRadius: '3px', borderTopWidth: '1px'},
        '&:last-of-type': {borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px'},
    },
    infoContentKey: {
        fontFamily: 'LarsseitMedium',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        width: '50%',
    },
    infoContentValue: {
        marginLeft: '10px',
        overflowWrap: 'break-word',
        width: '50%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    }
}