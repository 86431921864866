import {Box} from "@mui/system";
import styles from "../voteMainStyles";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";

export default function ResponseContainerEmptyState() {
    return(
        <Box sx={styles.responsePanelRoot}>
            <Box sx={styles.heading}>
                <Box sx={style.responseHeading}>
                    <LoadingPlaceholderBlock
                        width={'85px'}
                        height={'14px'}
                        borderRadius={'3px'}
                        backgroundColor={'#EFEAE5'}
                    />
                </Box>
            </Box>
            <Box sx={styles.panelContent}>
                <Box sx={styles.responseScrollArea}>
                    <Box sx={{marginBottom: '10px'}}>
                        <LoadingPlaceholderBlock
                            height={'200px'}
                            borderRadius={'6px'}
                            backgroundColor={'#EFEAE5'}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const style = {
    responseHeading: {
        height: '25px',
        display: 'flex',
        alignItems: 'center'
    },
}