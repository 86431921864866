import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {companyResourcesActions} from "_actions";
import {buildGetAllResourcesByOrganization} from "../CompanyResourceUtils";
import {ResourcesBuilder} from "../objects/ResourcesBuilder";
import {prependHttp} from "_helpers";

export default function () {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            getAllByOrganization();
            return () => effectRan.current = true;
        }
    }, []);

    const getAllByOrganization = () => {
        const params = buildGetAllResourcesByOrganization();
        dispatch(companyResourcesActions.getAllResourcesByOrganization(params));
    }

    const resourcesObj = useSelector(state => state.companyResources.organizationResources);
    const loadingResources = useSelector(state => state.companyResources.loadingAllOrganizationResources);
    const resources = [];

    if(!loadingResources && !_.isEmpty(resourcesObj)) {
        const resourceList = _.get(resourcesObj, 'companyResources') || [];
        if(resourceList.length > 0) {
            resourceList.map( resource => {
                const builder = new ResourcesBuilder();
                builder.setName(_.get(resource, 'name') || '-');
                builder.setOrganizationName(_.get(resource, 'organization.name') || '-');
                builder.setLink(prependHttp(_.get(resource, 'link')) || '#');
                builder.setStatus(_.get(resource, 'isActive'));
                resources.push(builder.resource);
            });
        }
    }

    return { resources, loadingResources };
}