import {Fragment} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import useViewMetaEmbed from "./hooks/useViewMetaEmbed";

export default function MetaEmbed({message}) {
    const {metaOEmbed, loadingMetaOEmbed} = useViewMetaEmbed();
    const html = _.get(metaOEmbed, 'html');
    const withDm = _.get(message, 'canReplyPrivately');

    if(loadingMetaOEmbed) return <Fragment/>;
    if(!loadingMetaOEmbed && _.isEmpty(html)) return <Fragment/>;

    return <Box component={'iframe'} sx={withDm ? style.iframeWithDm : style.iframe} srcDoc={html}/>
}

const style = {
    iframe: {
        width: '100%',
        height: '100%',
        border: 0,
    },
    iframeWithDm: {
        width: '100%',
        height: 'calc(100vh - 278px)',
        border: 0,
    }
}