import { SERVER_URL } from 'config';
import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';


function initiateOauth(params) {
  return fetch(`${SERVER_URL}/api/zendesk/initiateOauth?${objectToQueryString(params)}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

function genAccessToken(params) {
  return fetch(`${SERVER_URL}/api/zendesk/genAccessToken?${objectToQueryString(params)}`,
    {
      method: 'POST',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

export const zendeskOauthService = {
  initiateOauth,
  genAccessToken,
};
