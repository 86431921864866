import React from "react";
import {Box} from "@mui/material";
import importedStyles from "../../Proactive/Utilities/campaignStyles";
import CreateResourceForm from "./CreateResourceForm";

export default function CreateResource() {
    return (
        <>
            <Box sx={importedStyles.root}>
                <Box sx={styles.container}>
                    <Box sx={importedStyles.containerBody}>
                        <Box sx={importedStyles.header}>
                            Create new resource
                        </Box>
                        <CreateResourceForm />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const styles = {
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '700px',
        position: 'relative',
    },
}