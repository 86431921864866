import React, {Fragment} from "react";
import PropTypes from "prop-types";


/**
 * @author Chris Okebata
 */

const styles = {
  info: {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'},
};

const InputTextField = (props) => {

  const {input, label, placeholder, type, required, meta: { touched, error, warning }} = props;

  return(
    <Fragment>
      <div style={styles.info}>
        <label htmlFor="">{label}</label>
        <span className="font-12 color-danger" style={{marginTop: 3}}>
          {touched &&
          ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </span>
      </div>
      <div>
        <input {...input} className="text-field" type={type} placeholder={placeholder} required={required}/>
      </div>
    </Fragment>
  );
};

export {InputTextField}

InputTextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};