import React, {Component} from "react";
import {GenericModal} from "components/common";
import {ScreenshotsAdd} from "components/Screenshots/index";


export class ScreenshotModal extends Component {

    render() {
        const {show, onHide, headerText, agentInfo, messageUrl, currentPath, messageId, screenshotType} = this.props;
        return (
            <GenericModal
                show={show}
                onHide={onHide}
                headerText={headerText}
                buttonText="Constructive Feedback"
                className="modal-edit-message"
                hideFooter={true}
            >
                <ScreenshotsAdd
                    agentInfo={agentInfo}
                    screenshotType={screenshotType}
                    displayInModal={true}
                    messageUrl={messageUrl}
                    currentPath={currentPath}
                    messageId={messageId}
                    submitButtonText={headerText}
                />
            </GenericModal>
        )
    }
}