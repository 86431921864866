import React, {useState} from "react";
import {Box} from "@mui/system";
import styles from "../../organizationMainStyles";
import {Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";
import {accessibilityMode} from "../organizationSettingsConstants";
import {buildUpdateEscalationViaEmailPayload} from "../organizationSettingsUtils";
import {SnackBar} from "../../../../../common";
import InformationTooltip from "../InformationTooltip";

export default function EscalationEmailToggleContainer({mode, organization, label}) {
    const disable = mode === accessibilityMode.READ_ONLY;
    const isEscalationsViaEmailEnabled = _.get(organization, 'escalationsViaEmail');
    const [enableEscalationEmail, setEnableEscalationEmail] = useState(isEscalationsViaEmailEnabled);
    const organizationId = _.get(organization, 'id');
    const dispatch = useDispatch();

    const handleToggle = (event) => {
        const value = _.get(event, 'target.checked');
        setEnableEscalationEmail(value);
        handleUpdate(value);
    }

    const handleUpdate = (value) => {
        const payload = buildUpdateEscalationViaEmailPayload(value);
        if(!organizationId || !payload) return SnackBar.showMessage('Invalid parameters');
        dispatch(organizationSettingsActions.updateOrganizationAttribute(organizationId, payload));
    }

    return(
        <Box sx={styles.notificationToggleContainer}>
            <Box component={'h2'} sx={styles.notificationToggleLabel}>
                {label.EMAIL_ESCALATION}
                <InformationTooltip label={"If enabled, will send a weekly email with the list of messages that experts escalated to the CSM assigned to this account, as well as anyone listed as an ADMIN for the company, which is configurable in the client's teams login."}/>
            </Box>
            <Box>
                <Switch disabled={disable} checked={enableEscalationEmail} onChange={handleToggle} sx={styles.switch} />
            </Box>
        </Box>
    )
}