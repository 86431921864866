import React, {useState} from "react";
import {Box, Grid} from "@mui/material";
import {Field, reduxForm} from "redux-form";
import BasicFormTextField from "../../common/BasicFormTextField";
import {required} from "../../../../_helpers";
import ActionButton from "../../common/ActionButton";
import AnchorButton from "../../common/AnchorButton";
import {useDispatch} from "react-redux";
import {userActions} from "_actions";
import _ from "lodash";
import {useHistory} from "react-router-dom";

function UpdatePassword({ handleSubmit }){
    const [isSubmitting, setIsSubmitting] = useState(false)

    const dispatch = useDispatch();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const history = useHistory()

    const handleUpdatePassword = (values) => {
        if(_.isEmpty(values)) return;
        setIsSubmitting(true)
        dispatch(userActions.updatePassword(currentUser.id, values, history))
    };

    return(
        <Box sx={styles.rootWrapper}>
            <Box sx={styles.container}>
                <Box component={"h1"} sx={styles.headingText}>Change Password</Box>

                <form name="form" onSubmit={handleSubmit(handleUpdatePassword)} autoComplete="off">
                    <Grid container sx={{marginTop: "30px"}}>
                    <Grid item sx={styles.formContainer} xs={12} md={6} lg={4}>
                        <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                            <Box sx={styles.inputDesc}>Current Password</Box>
                            <Field
                                type="password"
                                name="currentPassword"
                                component={BasicFormTextField}
                                validate={required}
                            />
                        </Box>
                        <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                            <Box sx={styles.inputDesc}>New Password</Box>
                            <Field
                                type="password"
                                name="newPassword"
                                component={BasicFormTextField}
                                validate={required}
                            />
                        </Box>
                        <Box mb={{xs: "15px", sm: "15px", md: "20px", lg: "20px"}}>
                            <Box sx={styles.inputDesc}>Confirm Password</Box>
                            <Field
                                type="password"
                                name="confirmPassword"
                                component={BasicFormTextField}
                                validate={required}
                            />
                        </Box>

                        <Box sx={styles.footer}>
                            <Box sx={{width: '100px'}}>
                                <ActionButton
                                    label={'Save'}
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Box sx={{width: '100px'}}>
                                <AnchorButton
                                    label={'Cancel'}
                                    link={'/profile'}
                                    buttonBordered={true}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                </form>

            </Box>
        </Box>
    )
}

const styles = {
    rootWrapper: {
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
    },
    headingText: {
        m: 0,
        fontFamily: 'LarsseitMedium',
        fontSize: 24,
        lineHeight: 1.2,
        letterSpacing: '-0.02em',
        color: '#0C2728'
    },
    formContainer: {
        backgroundColor: '#FFF',
        border: '1px solid #CED4D4',
        borderRadius: '5px',
        padding: '20px'
    },
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    footer: {
        display: 'flex',
        gap: '18px'
    },
}

function validate(values) {
    const errors = {}
    const {currentPassword, newPassword, confirmPassword} = values

    if(_.isEmpty(_.toString(currentPassword)) || currentPassword === 'none') errors.currentPassword = "invalid password";
    if(_.isEmpty(_.toString(newPassword)) || newPassword === 'none') errors.newPassword = "invalid password";
    if(_.isEmpty(_.toString(confirmPassword)) || confirmPassword === 'none') errors.confirmPassword = "invalid password";
    return errors;
}

const formConfig = {
    form: 'UpdatePasswordForm',
    validate
};

UpdatePassword = reduxForm(formConfig)(UpdatePassword)
export default UpdatePassword