import {SERVER_URL} from "../config";
import axios from "axios";
import {authHeader} from "_helpers";

/**
 * @author: Chris Okebata
 */


const authenticateUser = (payload) => {
  const endpoint = `${SERVER_URL}/api/auth/login`;

  return axios.post(endpoint, payload,{
    headers: authHeader()
  })
};

const logoutUser = () => {
  const endpoint = `${SERVER_URL}/api/auth/logout`;

  return axios.post(endpoint, {},{
    headers: authHeader()
  })
};

const createProfile = (payload) => {
  const endpoint = `${SERVER_URL}/api/auth/profile/create`;

  return axios.post(endpoint, payload,{
    headers: authHeader()
  })
};


export const auth0Service = {
  authenticateUser,
  logoutUser,
  createProfile
};