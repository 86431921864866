import {SERVER_URL} from 'config';
import {authHeader, handleHttpResponse} from '_helpers';


function initiateOauth(redirectUrl) {
    return fetch(`${SERVER_URL}/api/trustpilot/oauthUrl?redirectUrl=${redirectUrl}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function saveClientAppData(data) {
    return fetch(`${SERVER_URL}/api/trustpilot/saveClientAppData`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

function generateAccessToken(params) {
    return fetch(`${SERVER_URL}/api/trustpilot/oauthToken`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(params)
        })
        .then(handleHttpResponse);
}

function sendToTrends(msgDataSourceId) {
    return fetch(`${SERVER_URL}/api/trustpilot/triggerTrendsCreationEvent/${msgDataSourceId}`,
        {
            method: 'POST',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function refreshToken(msgDataSourceId) {
    return fetch(`${SERVER_URL}/api/trustpilot/triggerRefreshAccessToken/${msgDataSourceId}`,
        {
            method: 'POST',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function refreshAllTokens() {
    return fetch(`${SERVER_URL}/api/trustpilot/refreshAllTokens`,
        {
            method: 'POST',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function saveCredentials(credentials) {
    return fetch(`${SERVER_URL}/api/trustpilot/saveCredentials`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(credentials)
        })
        .then(handleHttpResponse);
}

export const trustpilotOauthService = {
    initiateOauth,
    saveClientAppData,
    generateAccessToken,
    sendToTrends,
    refreshToken,
    refreshAllTokens,
    saveCredentials,
};
