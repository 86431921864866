import {Fragment, useState} from "react";
import BasicModal from "../../../../../common/BasicModal";
import CannotResolveContainer from "./CannotResolveContainer";
import AppreciationContainer from "./AppreciationContainer";

export default function CannotResolveModal({title, open, handleClose}) {
    const [optionSubmitted, setOptionSubmitted] = useState(false);
    const handleOptionSubmitted = (value) => {
        setOptionSubmitted(value);
    }

    const Content = () => {
        if(optionSubmitted) return <AppreciationContainer />;
        return <CannotResolveContainer handleClose={handleClose} handleOptionSubmitted={handleOptionSubmitted} />
    }

    return(
        <Fragment>
            <BasicModal
                title={!optionSubmitted && title}
                withoutCloseIcon={optionSubmitted}
                open={open || optionSubmitted}
                style={{width: '700px'}}
                handleClose={handleClose}>

                <Content />
            </BasicModal>
        </Fragment>
    )
}