import {Box} from "@mui/system";
import _ from "lodash";

export default function TikTokShopVideoCard({typeObj}) {
    const url = _.get(typeObj, 'url');
    const width = _.get(typeObj, 'width');
    const format = _.get(typeObj, 'format');

    return(
        <Box>
            <video style={{...styles.video, ...{width: `${width}px`, maxWidth: `400px`}}} controls>
                <source src={url} type={`video/${format}`}/>
                Your browser does not support the video tag.
            </video>
        </Box>
    )
}

const styles = {
    video: {
        borderRadius: '5px',
        border: '1px solid #C1C1C1'
    },
}