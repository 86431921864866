import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";
import BasicTextField from "../../../../common/BasicTextField";
import {buildUpdateTicketBudgetPayload} from "../organizationSettingsUtils";
import {SnackBar} from "../../../../../common";

export const TicketBudgetTextField = forwardRef(({billingPlanId, organizationId, defaultValue, selectedBillingPlan}, ref) => {
    let [ticketCount, setTicketCount] = useState(() => {
        if(!_.isEmpty(selectedBillingPlan) && billingPlanId === selectedBillingPlan.billingPlanId) {
            return selectedBillingPlan.ticketBudget;
        }
        return defaultValue
    });
    let element = useRef(defaultValue);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const val = parseInt(_.get(event, 'target.value'), 10);
        setTicketCount(val);
        element.current = val;
    }

    const handleSubmit = () => {
        const payload = buildUpdateTicketBudgetPayload(element.current, billingPlanId);
        if(!organizationId || !payload) return SnackBar.showMessage('Invalid parameters');
        dispatch(organizationSettingsActions.updateTicketBudget(organizationId, payload));
    }

    const handleCancel = () => {
        setTicketCount(defaultValue);
        element.current = defaultValue;
    }

    useImperativeHandle(ref, () => ({
        handleBudgetUpdate() {
            handleSubmit();
        },
        handleCancelUpdate() {
            handleCancel();
        }
    }));

    return(
        <BasicTextField
            type={'number'}
            sx={style.textField}
            value={_.isNaN(ticketCount) ? 0 : ticketCount}
            min={0}
            handleChange={handleChange}
        />
    )
})

const style = {
    textField: {
        width: '80px',
        height: '35px',
        p: 0,
        pl: '0px',
        [`& fieldset`]: {
            border: '1px solid #0C2728',
            borderRadius: '5px',
            p: 0,
        },
    }
}