import {Fragment} from "react";
import {Box} from "@mui/system";
import styles from "../../../../voteMainStyles";
import DirectMessageContainer from "./DirectMessageContainer";

export default function DirectMessageEntry({isActive, isScrollComplete}) {
    const Content = () => {
        if(!isActive) return <Fragment/>
        return (
            <Box sx={styles.directMessageContainer}>
                <DirectMessageContainer isScrollComplete={isScrollComplete}/>
            </Box>
        )
    }

    return <Content />;
}