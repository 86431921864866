import {reamazeAuthService} from '_services';
import {oauthConstants, alertMessages} from "_constants";
import {SnackBar} from "components/common";
import {history} from "_helpers";

function verifyKey(params){
    function request() {
        return {type: oauthConstants.REAMAZE_AUTH_REQUEST};
    }

    function success(payload) {
        return {type: oauthConstants.REAMAZE_AUTH_SUCCESS, payload};
    }

    function failure() {
        return {type: oauthConstants.REAMAZE_AUTH_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        reamazeAuthService.verifyApiKey(params)
            .then(
                (res) => {
                    dispatch(success(res));
                    SnackBar.showMessage(alertMessages.REAMAZE_AUTH_SUCCESS)
                    history.push('/company/channels')
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.REAMAZE_AUTH_FAILURE)
                },
            );
    };
}

export const reamazeAuthActions = {
    verifyKey
}
