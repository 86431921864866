import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { employmentStatus as empStatus, workMode } from '_constants';
import { ConfirmationModal, StarRating } from 'components/common';
import userAvatar160 from 'components/images/user-160.svg'
import { generatePhotoLink } from '_helpers'

/**
 * Renders details of a user on the employmenr detail page.
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 03/11/2018 : 4:06 PM
 * @author Chris Okebata
 */
class EmploymentUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            employmentId: '',
            status: '',
            mode: ''
        }
    }

    handleClose = () => {
        this.setState({ show: false });
    };

    handleShow = (event, params) => {
        event.preventDefault();
        this.setState({
            ...params,
            show: true
        });
    };

    saveEmployment = (params) => {
        if(this.props.loadingEmploymentUpdate) return;
        this.props.onRemoveFromTeam(params);
        this.handleClose();
    };

    /**
     * Returns a string representing the agents's location in the format, <city>, <state>
     *
     * @param agent
     * @returns {*}
     */
    getLocation = (agent) => {

        if (agent.city && agent.state) {
            return `${agent.city}, ${agent.state}`
        }

        if (agent.city && !agent.state) {
            return agent.city
        }

        if (agent.state && !agent.city) {
            return agent.state
        }

        return 'Not Specified';
    };

    render() {

        const { agent, status, nickname, bio, employmentId, loadingEmploymentUpdate } = this.props;
        const hired = status === empStatus.HIRED;
        const employmentStatus = status === empStatus.HIRED ? 'Hired' : 'Practicing';

        const params = hired ?
            {
                employmentId,
                status: empStatus.UNEMPLOYED,
                mode: workMode.PRACTICE
            } :
            {
                employmentId,
                status: empStatus.HIRED,
                mode: workMode.ACTIVE
            };

        const header = hired ? 'Remove from team' : 'Add to your Team';
        const buttonText = hired ? 'Remove' : 'Add to Team';
        const bodyText = hired ? 'This agent won\'t be able to respond to your messages' : 'Adding this agent to your team will enable them to respond to customers';

        return (
            <div>
                <ConfirmationModal
                    onSave={this.saveEmployment}
                    bodyText={bodyText}
                    headerText={header}
                    buttonText={buttonText}
                    payload={{
                        id: this.state.employmentId,
                        mode: this.state.mode,
                        status: this.state.status
                    }}
                    onHide={this.handleClose}
                    show={this.state.show} />

                <div id="profile-image-large-container">
                    <Image responsive
                           src={agent.photo ? generatePhotoLink(agent.photo) : userAvatar160}
                           circle />
                </div>
                <div className="employee-info-container">
                    <div className="color-grey-mid font-13">Name</div>
                    <div className="color-black-fade-mid font-14">
                        {nickname ? nickname : agent.firstName}
                    </div>
                </div>
                <div className="employee-info-container">
                    <div className="color-grey-mid font-13">Location</div>
                    <div className="color-black-fade-mid font-14">
                        {this.getLocation(agent)}
                    </div>

                    <div className="padding-top-15">
                        <div className="color-grey-mid font-13">Profile</div>
                        <div
                            className="color-black-fade-mid font-14">{bio ? bio : 'Unavailable'}</div>
                    </div>
                </div>

                <div className="employee-info-container">
                    <div className="color-grey-mid font-13">Status</div>
                    <div
                        className="color-black-fade-mid font-14 text-transform-upper-first">
                        {employmentStatus}
                    </div>
                </div>

                <div className="employee-info-container padding-top-20 border-top-grey-mid">
                    {hired ?
                        <span>
                            <span className="fa fa-check color-grey-mid margin-right-10" />
                            <div className="color-grey-mid font-13"
                                 style={{ display: 'inline-block' }}>Added to team</div>
                            <div className="color-green font-14-bold" id="remove-text"
                                 onClick={(e) => this.handleShow(e, params)}>
                                Remove
                            </div>
                        </span>
                        :
                        <button className="btn btn-block btn-success font-15" disabled={loadingEmploymentUpdate}
                                onClick={(e) => this.handleShow(e, params)}>
                            Add to team
                        </button>
                    }
                </div>
            </div>
        )
    }
}

export { EmploymentUser };

