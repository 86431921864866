import React from 'react';
import {Col, Grid, Row} from 'react-bootstrap';
import {roleActions} from "_actions";
import {connect} from "react-redux";
import {Loading} from "components/common";
import {RoleForm} from "components/Role";
import {formModes} from "_constants"


class EditRole extends React.Component {

    componentDidMount() {
        const {id} = this.props.match.params;
        this.props.dispatch(roleActions.getRole(id));
    }

    render() {

        if (!this.props.role || !this.props.role.id) {
            return <Loading/>
        }

        const {role} = this.props;

        return (
            <Grid>

                <Row>
                    <Col xs={8} xsOffset={2}>
                        <RoleForm role={role} mode={formModes.EDIT}/>
                    </Col>
                </Row>

            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const {roles: {role}} = state;

    return {
        role
    };
}

const connectedEditRole = connect(mapStateToProps)(EditRole);
export {connectedEditRole as EditRole};
