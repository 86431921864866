import React from "react";
import LoadingPlaceholderBlock from "../../../../../components/common/LoadingPlaceholderBlock";

export default function PreSaleLoader() {
    return (
        <div className="sale-options">
            <div style={{display: 'flex', marginRight: '10px'}}>
                <LoadingPlaceholderBlock width={'75px'} height={'25px'} borderRadius={'16px'} backgroundColor={'rgb(239, 234, 229)'} />
                <span style={{marginRight: '5px'}} />
                <LoadingPlaceholderBlock width={'75px'} height={'25px'} borderRadius={'16px'} backgroundColor={'rgb(239, 234, 229)'} />
            </div>
        </div>
    )
}