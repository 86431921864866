import React from 'react';
import {isObjectEmpty,queryStringToObject} from "_helpers";
import {Grid, Table, Tabs, Tab, Button} from 'react-bootstrap';
import {employmentActions, userActions} from "_actions";
import connect from "react-redux/es/connect/connect";
import moment from 'moment'
import {Link} from "react-router-dom";
import {ConfirmationModal, Loading} from "components/common";
import BackArrow from '../images/icon_back_arrow.svg';
import { employmentStatus as status, workMode as mode } from '_constants';

class ExpertPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showDeleteModal: false,
            employmentId: '',
            addToTeamPayload: null,
            removeFromTeamPayload: null,
        }
    }

    componentDidMount() {
        const orgId = this.props.match.params;
        this.props.dispatch(userActions.getExpertRecentActivity(orgId));
    }

    /**
     * Handles tabs selection. This method loads the content of each tab onSelect.
     * It dispatches the action on each tab only once
     * @param orgId
     * @returns {function(*)}
     */
    handleTabsSelect = (key) => {
        const orgId = this.props.match.params;
        const payload = {orgId: orgId.orgId};

        if (key === 2)
            this.props.dispatch(userActions.getActiveExperts(payload));
        else if (key === 3)
            this.props.dispatch(userActions.getInActiveExperts(payload));
        else if (key === 4)
            this.props.dispatch(userActions.getPracticingExperts(orgId));
        else if (key === 5)
            this.props.dispatch(userActions.getQualityScores({organizationId: orgId.orgId}));
        else if (key === 6)
            this.props.dispatch(userActions.getQualityReviews({organizationId: orgId.orgId}));
    };

    handleShowModal = (event, employmentId) => {
        event.preventDefault();

        const addToTeamPayload = {
            id: employmentId,
            mode: mode.ACTIVE,
            status: status.HIRED
        };
        this.setState({
            showModal: true,
            addToTeamPayload
        });
    };

    handleShowDeleteModal = (event, employmentId) => {
        event.preventDefault();

        const removeFromTeamPayload = {
            id:employmentId
        }
        this.setState({
            showDeleteModal: true,
            removeFromTeamPayload
        });
    }

    handleClose = () => {
        this.setState({ showModal: false });
        this.setState({ showDeleteModal: false });
    };

    saveEmployment = (params) => {
        this.props.dispatch(employmentActions.addEmployment(params));
        this.handleClose();
    };

    removeEmployment = (params) => {
        this.props.dispatch(employmentActions.removeEmployment(params))
        this.handleClose();
    }

    render() {
        const {
            experts,
            loading,
            activeExperts,
            inActiveExperts,
            practicingExperts,
            qualityScores,
            qualityReviews,
            activeExpertsLoading,
            inActiveExpertsLoading,
            practicingExpertsLoading,
            qualityScoresLoading,
            qualityReviewsLoading,
            loadingEmploymentUpdate,
            removeEmploymentLoading
        } = this.props;
        const orgName = queryStringToObject(window.location.search.substring(1)).orgName;

        let addToTeamModal;
        let removeFromTeamModal;
        if (this.state.showModal && this.state.addToTeamPayload) {
            addToTeamModal = <ConfirmationModal
                onSave={this.saveEmployment}
                bodyText="Adding this agent to the team will enable them to respond to customers"
                headerText="Add to Team"
                buttonText="Add to Team"
                payload={this.state.addToTeamPayload}
                onHide={this.handleClose}
                show={this.state.showModal} />
        }

        if (this.state.showDeleteModal && this.state.removeFromTeamPayload ) {
            removeFromTeamModal = <ConfirmationModal
                onSave={this.removeEmployment}
                bodyText="Removing this expert will prevent them from responding to customers"
                headerText="Remove from Team"
                buttonText="Remove from Team"
                payload={this.state.removeFromTeamPayload}
                onHide={this.handleClose}
                show={this.state.showDeleteModal} />
        }

        return (
            <Grid className="container-pane-expert-page expert-container">
                {addToTeamModal}
                {removeFromTeamModal}
                <div className="current-org">
                    <div className="back-arrow-button" onClick={this.props.history.goBack}>
                         <img src={BackArrow} alt="back to organizations"/>
                    </div>
                    <b>{orgName}</b>
                </div>
                <Tabs onSelect={this.handleTabsSelect} defaultActiveKey={1} className="expert-tabs margin-top-50" id="uncontrolled-tab-example">
                    <Tab eventKey={1} title="Recent Paid Votes">
                        {(loading) && <Loading height="400"/> }
                        {!loading && isObjectEmpty(experts) &&
                        <div className="with-border margin-top-50 font-20-bold tms-align-text-center padding-20-0">
                            No paid messages in the last 3 days
                        </div> }
                        {!loading && !isObjectEmpty(experts) &&
                        <Table striped bordered hover className="margin-top-50">
                            <thead>
                                <tr>
                                    <th className="tms-width-50">Expert</th>
                                    <th className="tms-width-50">Recent Paid Message Date</th>
                                    <th className="tms-width-50">Action Taken</th>
                                    <th className="tms-width-50">Amount Paid</th>
                                </tr>
                            </thead>
                            <tbody>
                            {experts.map(user => {
                                return (
                                    <tr key={user.id + user.date}>
                                        <td>
                                            <Link to={`details/${user.id}`}>{user.firstName + " " + user.lastName}</Link>
                                        </td>
                                        <td>
                                            <Link to={`/messages/${user.message_id}?platform=teams-only`}>
                                                {moment(user.date).format('MMMM Do YYYY, h:mm:ss a')}
                                            </Link>
                                        </td>
                                        <td>{user.action_taken}</td>
                                        <td>{user.amount_paid}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>}
                    </Tab>
                    <Tab eventKey={2} title="Active Experts (last 3 days)">
                        <div className="margin-top-50">
                            {activeExpertsLoading && <Loading height="400"/> }
                            {!activeExpertsLoading && isObjectEmpty(activeExperts) &&
                            <div className="with-border margin-top-50 font-20-bold tms-align-text-center padding-20-0">
                                No active experts in the last 3 days
                            </div> }
                            {!activeExpertsLoading && !isObjectEmpty(activeExperts) &&
                            <Table striped bordered hover className="margin-top-50">
                                <thead>
                                <tr>
                                    <th className="tms-width-30">Expert</th>
                                    <th className="tms-width-30">Paid Messages</th>
                                    <th className="tms-width-30">Last Seen</th>
                                    <th className="tms-width-10" />
                                </tr>
                                </thead>
                                <tbody>
                                {activeExperts.map(user => {
                                    return (
                                        <tr key={user.id}>
                                            <td>
                                                <Link to={`details/${user.id}`}>{user.firstName + " " + user.lastName}</Link>
                                            </td>
                                            <td>{user.msgCount}</td>
                                            <td>{moment(user.date).fromNow()}</td>
                                             <td valign="center">
                                                 <Button
                                                     bsStyle='success'
                                                     style={{ padding: '7px 12px' }}
                                                     disabled={removeEmploymentLoading}
                                                     onClick={(e) => this.handleShowDeleteModal(e, user.employmentId)}>
                                                     Remove From Team
                                                 </Button>
                                             </td>
                                        </tr>)
                                })}
                                </tbody>
                            </Table>}
                        </div>
                    </Tab>
                    <Tab eventKey={3} title="Inactive Experts (last 3 days)">
                        <div className="margin-top-50">
                        {inActiveExpertsLoading && <Loading height="400"/> }
                        {!inActiveExpertsLoading && isObjectEmpty(inActiveExperts) &&
                        <div className="with-border margin-top-50 font-20-bold tms-align-text-center padding-20-0">
                            No inactive experts in the last 3 days
                        </div> }
                        {!inActiveExpertsLoading && !isObjectEmpty(inActiveExperts) &&
                        <Table striped bordered hover className="margin-top-50">
                            <thead>
                                <tr>
                                <th className="tms-width-90">Expert</th>
                                <th className="tms-width-10" />
                            </tr>
                            </thead>
                            <tbody>
                            {inActiveExperts.map(user => {
                            return (
                                <tr key={user.id}>
                                    <td className='margin-bottom-15 padding-left-40'>
                                        <Link to={`details/${user.id}`}>{user.firstName + " " + user.lastName}</Link>
                                    </td>
                                    <td>
                                        <Button
                                            bsStyle='success'
                                            style={{ padding: '7px 12px' }}
                                            onClick={(e) => this.handleShowDeleteModal(e, user.employmentId)}>
                                            Remove From Team
                                        </Button>
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </Table> }
                        </div>
                    </Tab>
                    <Tab eventKey={4} title="Practicing Experts">
                        {practicingExpertsLoading && <Loading height="400"/> }
                        {!practicingExpertsLoading && isObjectEmpty(practicingExperts) &&
                        <div className="with-border margin-top-50 font-20-bold tms-align-text-center padding-20-0">
                            No practicing experts at the moment
                        </div> }
                        {!practicingExpertsLoading && !isObjectEmpty(practicingExperts) &&
                        <Table striped bordered hover className="margin-top-50">
                            <thead>
                                <tr>
                                    <th className="tms-width-150">Expert</th>
                                    <th className="tms-width-50" />
                                </tr>
                            </thead>
                            <tbody>
                            {practicingExperts.map(user => {
                                return (
                                    <tr key={user.id}>
                                        <td>
                                            <Link to={`details/${user.id}`}>{user.firstName + " " + user.lastName}</Link>
                                        </td>
                                        <td valign="center">
                                            <Button
                                                bsStyle='success'
                                                style={{ padding: '7px 12px' }}
                                                disabled={loadingEmploymentUpdate}
                                                onClick={(e) => this.handleShowModal(e, user.employmentId)}>
                                                Add to Team
                                            </Button>
                                        </td>
                                    </tr>
                                )
                                })}
                            </tbody>
                        </Table>}
                    </Tab>
                    <Tab eventKey={5} title="Quality Scores">
                        {qualityScoresLoading && <Loading height="400"/> }
                        {!qualityScoresLoading && isObjectEmpty(qualityScores) &&
                        <div className="with-border margin-top-50 font-20-bold tms-align-text-center padding-20-0">
                            No quality scores at the moment
                        </div> }
                        {!qualityScoresLoading && !isObjectEmpty(qualityScores) &&
                        <Table striped bordered hover className="margin-top-50">
                            <thead>
                                <tr>
                                    <th className="tms-width-50">Expert Name</th>
                                    <th className="tms-width-50">Message Date</th>
                                    <th className="tms-width-50">Score</th>
                                    <th className="tms-width-50">Customer Comment</th>
                                    <th className="tms-width-50">Reviewer Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {qualityScores.map(qualityScore => {
                                return (
                                    <tr key={qualityScore.id + qualityScore.date}>
                                        <td>
                                            <Link to={`details/${qualityScore.agentId}`}>{qualityScore.firstName + " " + qualityScore.lastName}</Link>
                                        </td>
                                        <td>
                                            <Link to={`/messages/${qualityScore.messageId}?platform=teams-only`}>
                                                {moment(qualityScore.date).format('MMMM Do YYYY, h:mm:ss a')}
                                            </Link>
                                        </td>
                                        <td>{qualityScore.ratingScore}</td>
                                        <td>{qualityScore.ratingComment}</td>
                                        <td></td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>}
                    </Tab>
                    <Tab eventKey={6} title="Quality Review">
                        {qualityReviewsLoading && <Loading height="400"/> }
                        {!qualityReviewsLoading && isObjectEmpty(qualityReviews) &&
                        <div className="with-border margin-top-50 font-20-bold tms-align-text-center padding-20-0">
                            No quality reviews at the moment
                        </div> }
                        {!qualityReviewsLoading && !isObjectEmpty(qualityReviews) &&
                        <Table striped bordered hover className="margin-top-50">
                            <thead>
                                <tr>
                                    <th className="tms-width-50">Message Date</th>
                                    <th className="tms-width-50">Customer Message</th>
                                </tr>
                            </thead>
                            <tbody>
                            {qualityReviews.map(qualityReview => {
                                return (
                                    <tr key={qualityReview.messageId + qualityReview.date}>
                                        <td>
                                            <Link to={`/messages/${qualityReview.messageId}?platform=teams-only`}>
                                                {moment(qualityReview.date).format('MMMM Do YYYY, h:mm:ss a')}
                                            </Link>
                                        </td>
                                        <td>{qualityReview.message}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>}
                    </Tab>
                </Tabs>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const {
        users: {
            loading,
            experts,
            activeExperts,
            inActiveExperts,
            practicingExperts,
            qualityScores,
            qualityReviews,
            activeExpertsLoading,
            inActiveExpertsLoading,
            practicingExpertsLoading,
            qualityScoresLoading,
            qualityReviewsLoading,
    }, employment: {loadingEmploymentUpdate, removeEmploymentLoading}} = state;

    return {
        loading,
        experts,
        activeExperts,
        inActiveExperts,
        practicingExperts,
        qualityScores,
        qualityReviews,
        inActiveExpertsLoading,
        activeExpertsLoading,
        practicingExpertsLoading,
        qualityScoresLoading,
        qualityReviewsLoading,
        loadingEmploymentUpdate,
        removeEmploymentLoading
    };
}

const expertPage = connect(mapStateToProps)(ExpertPage);
export {expertPage as ExpertPage};
