import {useEffect} from "react";
import {appCredentiasActions} from "_actions";
import {facebookOauthService} from "_services";
import {useDispatch, useSelector} from "react-redux";
import setRedirectUrl from "../../Utilities/setRedirectUrl";

export default function useFacebookSetup() {
	const dispatch = useDispatch();
  const credentials = useSelector((state) => state.appcredentials);

  useEffect(() => {
    dispatch(appCredentiasActions.getFacebookappCredentials());
  }, []);

  useEffect(() => {
    const facebookCreds = credentials.data;
    const {appId} = facebookCreds;
    if (appId) {
      facebookOauthService.loadFacebookApi(appId);
    }
  }, [credentials]);

  const performAction = () => {
    setRedirectUrl()
    facebookOauthService.facebookLogin();
  };

	return { initiateFacebookSetup: performAction };
}