import React, {Fragment, useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {getCurrentUser} from "_helpers";
import {msgSourceActions, gladlyAuthActions} from "_actions";
import {Box} from "@mui/material";
import {Input, InputSubdomain} from "../../Utilities/Input";
import basicModalStyles from "../../Utilities/basicModalStyles";
import SetupModalBottomBar from "../../Utilities/SetupModalBottomBar";

function GladlySetupModalForm({description, buttonLabel, extraInfoLabel, openInNewTab, handleSubmit}){
  const dispatch = useDispatch();
  const gladly = useSelector(state => state.gladlyAuth);

  const [disabled, setDisabled] = React.useState(true);
  const loading = React.useMemo(() => gladly.loading && !disabled, [gladly]);

	const placeholders = {
		loginEmail: 'Gladly Login Email Address',
		subdomain: 'yoursubdomain',
		apiKey: 'API Key',
	}

  useEffect(() => {
		dispatch(msgSourceActions.getMsgSourceLink('gladly'))
  }, []);

  const performAction = (value) => {
    setDisabled(isEmpty(value));
  };

  const onSubmit = (props) => {
		const { id } = getCurrentUser();
		const params = {
			apiKey: props.apiKey,
			subdomain: props.subdomain,
			loginEmail: props.loginEmail,
			userId: id
		};
		dispatch(gladlyAuthActions.verifyKey(params))
  };

  return(
    <Fragment>
      <Box sx={basicModalStyles.container}>
        <Box sx={basicModalStyles.description}>
          {description}
        </Box>

        <form name="gladlyForm" onSubmit={ handleSubmit(onSubmit) }>
					<Field
            name={'subdomain'}
            component={InputSubdomain}
            endAdornmentDomainValue={'.gladly.com'}
            label='Gladly Subdomain'
            placeholder={placeholders.subdomain}
            performAction={performAction}
          />

          <Field
            name={'loginEmail'}
            component={Input}
            placeholder={placeholders.loginEmail}
          />

					<Field
            name={'apiKey'}
            component={Input}
            placeholder={placeholders.apiKey}
          />

          <SetupModalBottomBar
            extraInfoLabel={extraInfoLabel}
            openInNewTab={openInNewTab}
            buttonLabel={buttonLabel}
            disabled={loading || disabled}
            loading={loading}
          />
        </form>
      </Box>
    </Fragment>
  )
}

const formConfig = {
  form: 'gladlyForm',
};

export default reduxForm(formConfig)(GladlySetupModalForm);