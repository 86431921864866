import React from "react";
import {Box} from "@mui/material";
import BasicFormTextField from "../../../common/BasicFormTextField";
import StatusSelect from "../Utilities/StatusSelect";
import {Field, reduxForm} from 'redux-form'
import {useDispatch, useSelector} from "react-redux";
import OrganizationSelect from "../../../common/OrganizationSelect";
import AnchorButton from "../../../common/AnchorButton";
import ActionButton from "../../../common/ActionButton";
import {required} from "_helpers";
import {companyResourcesActions} from "_actions";
import _ from "lodash";

function CreateResourceForm({ handleSubmit }) {
    const dispatch = useDispatch();
    const selectPlaceholder = 'Choose';

    const resource = useSelector(state => state.companyResources);
    const loadingCreateResource = resource.loadingCreateResource;

    const handleCreateCampaign = (values) => {
        if(_.isEmpty(values)) return;
        dispatch(companyResourcesActions.createResource(values));
    };

    return(
        <>
            <form name="form" onSubmit={handleSubmit(handleCreateCampaign)} autoComplete="off">
                <Box sx={{padding: '25px 20px 60px 20px'}}>
                    <Box  mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Box sx={styles.inputDesc}>Resource name</Box>
                        <Field
                            name="name"
                            component={BasicFormTextField}
                            placeholder={'Enter name'}
                            validate={required}
                        />
                    </Box>
                    <Box  mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Box sx={styles.inputDesc}>Resource link</Box>
                        <Field
                            name="link"
                            component={BasicFormTextField}
                            placeholder={'Enter link'}
                            validate={required}
                        />
                    </Box>
                    <Box  mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Box sx={styles.inputDesc}>Organization</Box>
                        <Field
                            name="organizationId"
                            component={OrganizationSelect}
                            placeholder={selectPlaceholder}
                        />
                    </Box>
                    <Box  mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Box sx={styles.inputDesc}>Status</Box>
                        <Field
                            name="isActive"
                            component={StatusSelect}
                            placeholder={selectPlaceholder}
                        />
                    </Box>
                </Box>

                <Box sx={styles.footer}>
                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Save'}
                            disabled={loadingCreateResource}
                        />
                    </Box>
                    <Box sx={{width: '100px', ml: '18px', height: '20px'}}>
                        <AnchorButton
                            label={'Cancel'}
                            link={'/company-resources'}
                            buttonBordered={true}
                        />
                    </Box>
                </Box>
            </form>
        </>
    )
}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    footer: {
        borderTop: '1px solid #CED4D4',
        padding: '12px 20px',
        display: 'flex'
    },
}

function validate(values) {
    const errors = {};
    const {isActive, organizationId} = values;

    if(_.isEmpty(_.toString(isActive)) || isActive === 'none') errors.isActive = 'invalid status';
    if(_.isEmpty(_.toString(organizationId)) || organizationId === 'none') errors.organizationId = 'invalid organization';
    return errors;
}

const formConfig = {
    form: 'CreateResourceForm',
    validate
};

CreateResourceForm = reduxForm(formConfig)(CreateResourceForm)
export default CreateResourceForm;
