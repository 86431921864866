import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Loading} from "../common";
import {auth0Actions} from "_actions";
import {useDispatch} from "react-redux";


export default function Auth0Logout (){
  const {logout} = useAuth0();
  const dispatch = useDispatch();

  logout();
  dispatch(auth0Actions.logoutUser());

  return (
    <Loading/>
  );
};

