/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 13/09/2018 : 11:05 PM
 */

import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';
import { SERVER_URL } from 'config';

function getData(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${SERVER_URL}/api/stats?${objectToQueryString(params)}`, requestOptions).then(handleHttpResponse);
}

/**
 * Get messages analytics data from trends and teams for display on analytics page
 * @param params
 * @returns {Promise<Response | never>}
 */
function getAnalyticsData(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${SERVER_URL}/api/messages/analytics?${objectToQueryString(params)}`,
    requestOptions).then(handleHttpResponse);
}

function getSimilarityScoreStats(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${SERVER_URL}/api/similarityScore?${objectToQueryString(params)}`,
      requestOptions).then(handleHttpResponse);
}

export const statsService = {
  getData,
  getAnalyticsData,
  getSimilarityScoreStats
};
