import {facebookOauthService} from '_services'
import {history} from '_helpers';
import {alertMessages, oauthConstants} from "_constants";
import {SnackBar} from "../components/common";
import MetaSetupModal from "../components/common/Onboarding/Modal/MetaSetupModal";

export const faceOauthActions = {
    getPages,
    submitSelectedPages,
    saveSelectedPages
};

function getPages() {

    return dispatch => {

        dispatch(request());

        //this responds with the user's facebook pages
        facebookOauthService.getPages()
            .then(
                pages => dispatch(success(pages)),
                error => {
                    dispatch(failure(error))
                }
            )
    };

    function request() {
        return {type: oauthConstants.FACEBOOK_PAGES_REQUEST}
    }

    function success(pages) {

        return {type: oauthConstants.FACEBOOK_PAGES_SUCCESS, payload: pages}
    }

    function failure(error) {
        return {type: oauthConstants.FACEBOOK_PAGES_FAILURE, payload: error}
    }
}


function submitSelectedPages(pages) {

    return dispatch => {
        dispatch(request());

        let callbackUrl = localStorage.getItem('redirectUrl');
        callbackUrl = JSON.parse(callbackUrl).pathname;
        facebookOauthService.submitSelectedPages(pages)
            .then(
                pages => {
                    if (pages) {
                        history.push(callbackUrl);
                        dispatch(success(pages));
                        SnackBar.showMessage(alertMessages.FACEBOOK_OAUTH_SUCCESS);
                    }
                },

                error => {
                    history.push(callbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.FACEBOOK_OAUTH_FAILURE);
                }
            );
    };

    function request() {
        return {type: oauthConstants.FACEBOOK_PAGE_SUBMISSION_REQUEST}
    }

    function success(page) {
        return {type: oauthConstants.FACEBOOK_PAGE_SUBMISSION_SUCCESS, payload: page}
    }

    function failure(error) {
        return {type: oauthConstants.FACEBOOK_PAGE_SUBMISSION_FAILURE, error: error}
    }
}


function saveSelectedPages(pages) {
    return dispatch => {
        dispatch(request());
        facebookOauthService.submitSelectedPages(pages)
            .then(
                pages => {
                    if (pages) {
                        MetaSetupModal.close();
                        history.push('/signup/schedule');
                        dispatch(success(pages));
                        SnackBar.showMessage(alertMessages.FACEBOOK_OAUTH_SUCCESS);
                    }
                },
                error => {
                    MetaSetupModal.close();
                    history.push('/signup/add-channel');
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.FACEBOOK_OAUTH_FAILURE);
                }
            );
    };
    function request() {
        return {type: oauthConstants.FACEBOOK_PAGE_SUBMISSION_REQUEST}
    }
    function success(page) {
        return {type: oauthConstants.FACEBOOK_PAGE_SUBMISSION_SUCCESS, payload: page}
    }
    function failure(error) {
        return {type: oauthConstants.FACEBOOK_PAGE_SUBMISSION_FAILURE, error: error}
    }
}
