import React, {Component, Fragment} from 'react';
import {calculateRating, filterObjectValue} from "_helpers";
import {StarRating} from "../index";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";


/**
 * @author: Chris Okebata
 */

class OrganizationScore extends Component {

  render() {

    const { organizationId, organizationScores, loadingOrgScores } = this.props;
    let score = 0;

    if(loadingOrgScores){
      return(
        <div>
          Loading...
        </div>
      )
    }

    if(organizationScores && organizationScores.length > 0){
      const organization = filterObjectValue(organizationScores || '', 'organization', organizationId);
      score = _.get(organization[0], 'score') / 5 || 0
    }

    return(
      <Fragment>
        <div className="font-15-bold color-orange" style={{paddingRight: '4px'}}>
          {calculateRating(score || 0)}
        </div>
        <div style={{marginTop: '4px'}}>
          <StarRating starDimension={"20px"} numberOfStars={1} rating={score || 0} />
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {
    employment: {
      organizationScores,
      loadingOrgScores,
    }
  } = state;

    return {
      organizationScores: organizationScores.organizationScoreList,
      loadingOrgScores
    }
}

const connectedOrganizationScore = connect(mapStateToProps)(OrganizationScore);
export {connectedOrganizationScore as OrganizationScore}