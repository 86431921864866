export default {
	headingBox: {
		display: 'flex',
		justifyContent:'space-between',
		alignItems: 'center'
	},
	h1: { mt: 0, mb: { xs: 0.5, sm: 1 }, fontFamily: 'LarsseitMedium', fontSize: 24, lineHeight: 1.2, letterSpacing: '-0.02em', color: '#0C2728'},
	card: {
		border: '1px solid #b6bebf',
		borderRadius: '10px',
		overflow: 'hidden',
		mt: '48px'
	},
	cardHeader: {
		background: '#fff',
		p: { xs: '1rem', sm: '1.5rem' },
		color: '#0C2728',
		fontFamily: 'larsseitMedium',
		fontSize: '20px',
	},
}