export const formModes = {
    EDIT: 'EDIT',
    ADD: 'ADD'
};

export const employmentStatus = {
    UNDER_REVIEW: 'UNDER_REVIEW',
    REJECTED: 'REJECTED',
    TERMINATED: 'TERMINATED',
    HIRED: 'HIRED',
    UNEMPLOYED: 'UNEMPLOYED',
    QUIT: 'QUIT'

};

export const workMode = {
    ACTIVE: 'ACTIVE',
    PRACTICE: 'PRACTICE'
};