import React, {Component} from 'react'
import Toggle from 'react-toggle'

class SwitchToggleButton extends Component {

    render() {

        // const {style, label, input, ...rest} = this.props;
        const {onChange, defaultChecked, label} = this.props;
        return (

            <label>
                <span> {label ? label : null} </span>
                <Toggle
                    defaultChecked={defaultChecked}
                    onChange={onChange}/>
            </label>
        );
    }
}

export {SwitchToggleButton}