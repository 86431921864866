import React from 'react';

const style = {
    width: "100%",
    outline: "none"
}

const TextArea = ({type, initialValue, name, onChange, label}) => {
    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <textarea
                type={type}
                name={name}
                onChange={onChange}
                className="textarea"
                defaultValue={initialValue}
                style={style}>
            </textarea>
        </div>
    )
}

export default TextArea;