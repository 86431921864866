import {utilityConstants} from "_constants";


const INITIAL_STATE = {
    data: [],
    error: '',
    loading: false,
    isVerticalScrollComplete: false
};

export function utilityReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case utilityConstants.GET_VERTICALS_SUCCESS:
            return {...state, data: action.payload, loading: false, error: ''};

        case utilityConstants.GET_VERTICALS_REQUEST:
            return {...state, loading: true, error: ''};

        case utilityConstants.GET_VERTICALS_FAILURE:
            return {...state, loading: false, error: action.payload};

        case utilityConstants.GET_LANGUAGES_SUCCESS:
            return {...state, languages: action.payload, loading: false, error: ''};

        case utilityConstants.GET_LANGUAGES_REQUEST:
            return {...state, loading: true, error: ''};

        case utilityConstants.GET_LANGUAGES_FAILURE:
            return {...state, loading: false, error: action.payload};

        case utilityConstants.SET_VERTICAL_SCROLL_COMPLETE:
            return {...state, isVerticalScrollComplete: action.status};

        default:
            return state
    }
}

