import React, { Component } from "react";
import { Table } from 'react-bootstrap';
import { shortenString } from "_helpers";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment/moment'
import { Twemoji } from 'react-emoji-render'
import { isEmpty } from 'lodash'
import { Loading } from "components/common";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 10/12/2018 : 5:36 PM
 */
class ReviewsTab extends Component {
    render() {

        const { messages, loading } = this.props;

        if (loading) {
            return <Loading/>
        }

        /**
         * Renders a component for a single row
         * of a message in the list of tables.
         * @param message
         * @returns {*}
         */
        function renderMessageRow(message) {

            /**
             * Shorten string and add ellipses if the length of text exceeds 60 characters
             */
            let text = shortenString(message.text, 40) + ( message.text.length > 60 ? '...' : '' );
            text = ReactHtmlParser(text).join(' ');

            return (
                <tr key={message.id}>
                    <td>
                        <Link to={`/notifications/${message.id}?type=review`}>
                            {text.endsWith('...') ?
                                (
                                    <div><Twemoji text={text}/> <Link to='#'>Read More</Link></div>
                                ) : (
                                    <Twemoji text={text}/>
                                )
                            }
                        </Link>
                    </td>
                    <td>{moment(message.dateCreated).format('MMMM D, YYYY')}</td>
                    <td>{moment(message.dateCreated).format('HH:mm A')}</td>
                    <td><Link to='#'>View</Link></td>
                </tr>
            );
        }

        return (
            <div style={{ marginTop: '30px' }}>
                {!isEmpty(messages) ? (
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Customer Comment</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Responses</th>
                        </tr>
                        </thead>
                        <tbody>

                        {messages.map(renderMessageRow)}

                        </tbody>
                    </Table>
                ) : (
                    <h4>No Messages Found within the specified Date Range</h4>
                )
                }
            </div>
        );
    }
}

export { ReviewsTab };
