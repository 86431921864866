import {Box, Stack} from "@mui/material";
import React from "react";
import {styles} from "./MetricsDetailsStyles";
import {pageFilterToTitleMapper} from "./MetricsDetailUtils";


export const PageTitle = ({pageFilter}) => {
    return (
        <Stack sx={{width: '30%'}}>
            <Box component={'h3'} sx={styles.heading}>
                {pageFilterToTitleMapper[pageFilter]}
            </Box>
        </Stack>
    )
}

