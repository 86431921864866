import {SERVER_URL} from 'config';
import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';


function initiateOauth() {
    return fetch(`${SERVER_URL}/api/tiktok-ads/oauthUrl`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function generateAccessToken(params) {
    return fetch(`${SERVER_URL}/api/tiktok-ads/accessToken?${objectToQueryString(params)}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

export const tiktokAdsOauthService = {
    initiateOauth,
    generateAccessToken
}
