import { messageActions } from '_actions';
import {getArrayFromObjectArray, getCurrentUser, sortResponseOptions} from '_helpers';
import {
    navigationType,
    clientType,
    handleType,
    EMAIL_DURATION_IN_SECS,
    NON_EMAIL_DURATION_IN_SECS,
    votingOptions
} from "_constants";
import _ from "lodash";
import useViewUserAddedResponseOption from "../components/v2/views/Vote/Utilities/hooks/useViewUserAddedResponseOption";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 14/03/2019 : 9:07 PM
 */

/**
 * Sets (stores) the organization that an agent
 * will be voting for in local storage.
 *
 * @param params
 */
export const setCurrentEmployment = (params) => {
    localStorage.setItem('activeEmployment', JSON.stringify(params))
};

export const setCurrentScheduledOrgs = (params) => {
    localStorage.setItem('currentScheduledOrg', JSON.stringify(params || {}))
};

/**
 * Retrieves the employment (link between agent and organization) from the local storage.
 * @returns {string}
 */
export const getCurrentEmployment = () => ( JSON.parse(localStorage.getItem('activeEmployment')) );

export const getCurrentScheduledOrgs = () => ( JSON.parse(localStorage.getItem('currentScheduledOrg')) );

/**
 * Retrieves the organization object from the employment object from local storage.
 * @returns {organization}
 */
export const getVotingOrganization = () => ( _.get(getCurrentEmployment(), 'organization') );

/**
 * Retrieves the organization object from the employment object from local storage.
 * @returns {organization}
 */
export const getWorkMode = () => ( _.get(getCurrentEmployment(), 'mode') );

/**
 * Retrieves the (appropriate) name of the agent who is currently in active session of voting.
 * This gives preference to the nickname, then the first name.
 * @return name: String
 */
export const getCurrentAgentName = () => {
    const nickname = getCurrentEmployment().nickname;
    return nickname ? nickname : getCurrentUser().firstName
};

/**
 * Dispatches an action creator to get next message for voting or review
 * @param dispatch
 * @param isForReview
 */
export const getNextMessage = (dispatch, isForReview = false) => {
    const {votingOrganization, workMode, handleIds} = nextMessageParams();

    dispatch(messageActions.getNext({
        review: isForReview,
        organization: _.get(votingOrganization, 'id'),
        mode: workMode,
        navigationType: navigationType.AGENT_SELECTION,
        clientType: clientType.WEB_APP,
        handleIds: handleIds
    }));
};

export const nextMessageParams = () => {
    const votingOrganization = getVotingOrganization();
    const workMode = getWorkMode();
    const currentScheduledOrg = getCurrentScheduledOrgs();
    const handleIds = getArrayFromObjectArray(currentScheduledOrg, 'handleId', true);

    return {
        votingOrganization: votingOrganization,
        workMode: workMode,
        handleIds: handleIds
    }
}

export const buildNextMessagePayload = () => {
    const { votingOrganization, workMode, handleIds } = nextMessageParams();
    return {
        review: false,
        organization: _.get(votingOrganization, 'id'),
        mode: workMode,
        navigationType: navigationType.AGENT_SELECTION,
        clientType: clientType.WEB_APP,
        handleIds: handleIds
    }
}

export const showGreenTimer = (type, duration) => {
    if([handleType.email, handleType.proactive_email].includes(type)){ return duration < EMAIL_DURATION_IN_SECS;}
    return duration < NON_EMAIL_DURATION_IN_SECS;
};

export const showRedTimer = (type, duration) => {
    if([handleType.email, handleType.proactive_email].includes(type)){ return duration > EMAIL_DURATION_IN_SECS;}
    return duration > NON_EMAIL_DURATION_IN_SECS;
};

export const internalAccounts = [
    'f51b19a5-bb93-4586-bd6f-c6f370104baa', //sara@gmail.com - prod account
]

export const testCompanyAccounts = [
    
]

/**
 * @param selectedResponse {string}
 * @param allResponses {array}
 */
export const processRejectedResponses = (selectedResponse, allResponses) => {
    let rejectedResponses = []
    allResponses.map((item) => {
        if(selectedResponse !== item.id) {
            rejectedResponses.push(
                {
                    response_id: item.id,
                    tag: votingOptions.REJECT,
                    text: item.text,
                    is_best_response: false,
                }
            )
        }
    });
    return rejectedResponses
}

/**
 * @param responseOptions {array}
 * @param addedResponseOption {array}
 * @param searchedMessage {object}
 */
export function checkUserAddedResponseStatus(responseOptions, addedResponseOption, searchedMessage) {
    const radix = 10;
    return (addedResponseOption.some(it => parseInt(it.responseMessageId, radix) === parseInt(searchedMessage.messageId, radix)) ||
        (responseOptions.some(it => parseInt(it.responseMessageId, radix) === parseInt(searchedMessage.messageId, radix))));
}

/**
 * @param responseOptions {array}
 * @returns {[]|*}
 */
export function processResponseOptions(responseOptions) {
    const {addedResponseOption, loadingAddResponseOption} = useViewUserAddedResponseOption();
    let responseOptionIds = getArrayFromObjectArray(responseOptions, 'id');
    let addedResponseOptionIds = getArrayFromObjectArray(addedResponseOption, 'id');

    if(!loadingAddResponseOption && !_.isEmpty(addedResponseOptionIds)) {
        addedResponseOption.filter( it => {
            if(!_.isEmpty(_.get(it, 'id')) && !responseOptionIds.includes(it.id)) {
                return responseOptions.unshift(it);
            }
            return null
        });
    }
    return sortResponseOptions(responseOptions);
}

/**
 * @param options {array}
 * @param messageHandleType {string}
 * @param sourceName {string}
 * @returns {*}
 */
export function processCannotResolveOptions(options, messageHandleType, sourceName) {
    const workMode = getWorkMode();
    return options.filter(option => {
        if(option.supportedWorkMode.includes(workMode) &&
            option.hasOwnProperty('supportedHandleTypes') &&
            option.hasOwnProperty('supportedSources') ) {
            return (option.supportedHandleTypes.includes(messageHandleType) && option.supportedSources.includes(sourceName));
        }
        return option.supportedWorkMode.includes(workMode);
    });
}