import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {history} from "_helpers";
import {Loading} from "../../../../../../common";
import {youtubeActions} from "_actions";

export default function YoutubeUrlRedirect() {
    const dispatch = useDispatch();
    const url = new URL(document.location);
    const searchParams = new URLSearchParams(url.search);
    const authCode = searchParams.get('code');

    useEffect(() => {
        if (authCode) {
            const params = {authCode};
            dispatch(youtubeActions.createAccount(params));
        }
    }, []);

    const youtube = useSelector((state) => state.youtube);
    const loadingChannels = youtube.loadingChannels;

    if (loadingChannels) {
        return <Loading />;
    }

    history.push('/company/connectchannels');
    return(
        <Fragment/>
    )
}