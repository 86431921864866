import {getCurrentUser, isvalidDateRange, required} from "_helpers";
import Grid from "@material-ui/core/Grid";
import {Field, reduxForm} from "redux-form";
import TimePicker from "components/common/formFields/TimePicker";
import {MultiSelectCheckbox} from "components/common/formFields/MultiSelectCheckbox";
import React from "react";
import {CustomField} from "./utils/CustomField";
import {ShowDay} from "./utils/ShowDay";

const DaysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const validate = (values) => {
    const errors = {};
    const {startTime, endTime} = values;
    const validDateRange = isvalidDateRange(startTime, endTime);
    if (!validDateRange){
        errors.endTime = "End time must be after Start time"
    }

    if (startTime === undefined){
        errors.startTime = "This field is required!"
    }

    if (endTime === undefined){
        errors.endTime = "This field is required!"
    }

    return errors
};

const SchedulesForm = ({handleSubmit, onSubmit, options, input}) => {
    const currentUser = getCurrentUser();
    const { timezone } = currentUser._embedded.organization;

    const customStyles = {
        option: (provided) => ({
            ...provided,
            padding: 10,
        })
    }

    const formatChannels = (channels) => {
        return channels && channels.map(channel => {
            return channel.value
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="margin-top-30 add-schedule-form">
            <Grid className="form-group row margin-bottom-30" container>
                <Grid item xs={12}  className="padding-left-40 label-container">
                    <label className="font-16">Schedule name</label>
                </Grid>

                <Grid item xs={12}>
                    <Field
                        name="name"
                        component={CustomField}
                        type="text"
                        placeholder="Schedule name"
                        className="form-control input-field"
                        validate={required}
                        forwardData={input}
                    />
                </Grid>
            </Grid>

            <Grid className="form-group row padding-left-40 margin-bottom-30 " container>
                <Grid item xs={12}  className="label-container">
                    <label className="font-16">Days</label>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className="add-schedule-checkboxes" >
                        {DaysOfWeek.map(day => {
                            return (
                                <ShowDay day={day} key={day}/>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>

            <Grid className="form-group row padding-left-40 margin-bottom-30" container>
                <Grid item xs={3} className="form-group " >
                    <Grid item xs={12}  className=" label-container">
                        <label className="font-16">Start time</label>
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="startTime"
                            component={TimePicker}
                            type="text"
                            showSecond={false}
                            applyValidation={true}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={3} className="form-group " container>
                    <Grid item xs={12} className="label-container">
                        <label className="font-16">End time</label>
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="endTime"
                            component={TimePicker}
                            type="text"
                            showSecond={false}
                            applyValidation={true}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={6} className="form-group display-block" container>
                    <label className="font-16">Timezone</label>
                    <p className="font-15 color-grey-dark margin-top-10"> {timezone ? timezone : "No timezone set"}</p>
                </Grid>
            </Grid>

            <Grid className="form-group row margin-bottom-30" container>
                <Grid item xs={12}  className="padding-left-40 label-container">
                    <label className="font-16">Select Channel</label>
                </Grid>

                <Grid item xs={12}>
                    <div style={{margin: '0px auto', padding: '0 65px 0 40px'}}>
                        <Field
                            name="channels"
                            component={MultiSelectCheckbox}
                            placeholderButtonLabel="Channels"
                            customStyles={customStyles}
                            options={options}
                            type="text"
                            className="form-control input-field"
                            width="100%"
                            normalize={(value) => (formatChannels(value))}
                        />
                    </div>
                </Grid>
            </Grid>


            <Grid className="form-group row" container>
                <Grid item xs={12} className="text-center">
                    <button className="btn btn-success font-15" type="submit">
                        Save
                    </button>
                </Grid>
            </Grid>
        </form>

    )
};

const formConfig = {
    form: 'AddScheduleForm',
    validate
};

const connectedForm = reduxForm(formConfig)(SchedulesForm)
export {connectedForm as SchedulesForm}