import React, {useState} from "react";
import {Box, Divider, IconButton} from "@mui/material";
import IconAdd from "../../../../common/IconAdd";
import TextButton from "../../../../common/TextButton";
import CampaignAttributeItem from "./CampaignAttributeItem";
import {SnackBar} from "../../../../../common";
import CampaignAttributeTable from "./CampaignAttributeTable";
import {useDispatch} from "react-redux";
import {change} from "redux-form";
import ClearIcon from "@mui/icons-material/Clear";

export default function CampaignAttribute({required, isCreateWizard = false}) {
    const maxAttribute = 5;
    const [attributeList, setAttributeList] = useState([
        <CampaignAttributeItem required={required} index={0}/>
    ]);
    const dispatch = useDispatch();

    const removeAttribute = (indexToRemove) => {
        setAttributeList(prev => {
            return prev.filter((_, index) => index !== indexToRemove);
        });
        dispatch(change('CreateCampaignForm', `attribute_name_${indexToRemove}`, ''));
        dispatch(change('CreateCampaignForm', `attribute_type_${indexToRemove}`, ''));
        dispatch(change('CreateCampaignForm', `attribute_value_${indexToRemove}`, ''));
    };

    const renderAttributes = (attribute, index) => {
        return(
            <Box key={index} sx={styles.attributesContainer}>
                {attribute}
                {attributeList?.length > 1 &&
                    <Box display='flex' alignItems='flex-end' visibility={index === 0 ? 'hidden' : 'visible'}>
                        <Box sx={styles.clearIconContainer}>
                            <IconButton sx={{m: '5px'}} onClick={() => removeAttribute(index)}>
                                <ClearIcon sx={styles.clearIcon} />
                            </IconButton>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    const addAttribute = () => {
        const attributeCount = attributeList.length;
        if(attributeCount >= maxAttribute) {
            SnackBar.showMessage("You have reached the maximum number of attributes");
            return
        }
        setAttributeList(attributeList.concat(
            <CampaignAttributeItem index={attributeCount}/>
        ));
    }

    return(
        <Box>
            <Divider orientation={'horizontal'} flexItem sx={styles.sectionDivider}/>
            <Box sx={styles.sectionDesc}>
                Attributes
            </Box>
            {attributeList.map((attribute, index) => renderAttributes(attribute, index))}
            <Box sx={{mb: '22px'}}>
                <TextButton
                    label={'Add another attribute'}
                    action={addAttribute}
                    startIcon={<IconAdd/>}
                    sx={{mt: '10px', mb: '-7px', height: '25px'}}
                />
            </Box>
            {!isCreateWizard && <CampaignAttributeTable />}
            <Divider orientation={'horizontal'} flexItem sx={styles.sectionDivider}/>
        </Box>
    )
}

const styles = {
    sectionDivider: {
        mt: '20px',
        backgroundColor: '#E7E9E9'
    },
    sectionDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '16px',
        fontWeight: 500,
        color: '#0C2728',
        mt: '10px',
    },
    attributesContainer: {
        display: 'flex',
        alignItems: 'stretch'
    },
    clearIconContainer: {
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    clearIcon: {
        fontSize: '16px!important',
        flexShrink: 0,
        color: '#144042',
        pointerEvents: 'none',
    },
}