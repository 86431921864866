import React, {Fragment} from 'react';
import {messageActions, voteActions, organizationActions, dataSourceHandleActions} from '_actions';
import { connect } from 'react-redux';
import { Row, Button} from 'react-bootstrap'
import {VoteLoading, VoteMessage, VotePanel, ApproveResponse, EditResponse, VotePortal} from 'components/Vote';
import 'components/Vote/vote.css';
import {
  getCurrentAgentName,
  getNextMessage,
  filterObjectValue,
  isObjectEmpty,
  queryStringToObject,
  getVotingOrganization
} from '_helpers';
import { navigationType, responseType, handleType} from '_constants';
import htmlToText from "html-to-text";
import _ from 'lodash';
import SearchInput from "./SearchInput";
import {SearchResultTable} from "./SearchResultTable";
import {FilterForm} from "./FilterForm";
import ReactPaginate from "react-paginate";
import {SearchLoading} from "../Search/SearchLoading";
import SearchMessageDetails  from "./SearchMessageDetails";


const progressBarStyle = {
  progressBarBackground: {
    background: '#fff',
    border: '1px solid #dee2e0',
    width: '100%',
    height: '27px',
    borderRadius: '4px',
  },

  progressBarForeground: {
    background: '#53c581',
    height: '25px',
    borderBottomLeftRadius: '3px',
    borderTopLeftRadius: '3px',
  }
};

const navigation = {
  type: navigationType.AGENT_SELECTION,
};

const APPROVE_TAG = 'APPROVE';

class VotePage extends React.Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search.substring(1);
    let filterParams = queryStringToObject(search);
    let { channels, organizations } = filterParams;
    channels =  channels ? channels.split(",").map(Number) : [];
    organizations = organizations ? organizations : [];
    filterParams.channels = channels
    filterParams.organization = organizations
    this.state = {
      currentUser: JSON.parse(localStorage.getItem('user')),
      currentResponseOption: 0,
      selectedTag: '',
      loadingProps: false,
      voteArr: [],
      displayApproveResponses: false,
      displayEditResponses: false,
      displayConversations: false,
      displaySearchResults: false,
      selectedApproveResponse: '',
      selectedEditResponse: '',
      approveVoteArr: [],
      editVoteArr: [],
      max: 10,
      messageId: '',
      channelsInitialized: false,
      filterParams: {
        direction: 'ALL',
        searchString: '',
        ...filterParams
      },
    };
  }

  /**
   * Retrieve the current user and set in state.
   * @type {{currentUser : object}}
   */
  updateStateSearchString = (e) => {
    const { name, value } = e.target;
    this.setState({
      filterParams: {
        ...this.state.filterParams,
        [name]: value
      }
    });
  };

  searchOnKeyEnter = (e) => {
    if(e.keyCode === 13){
      this.onSearch()
    }
  };

  /**
   * Search admin messages and resets page and offset to 0 on fresh search
   */
  onSearch = () => {
    this.setState({
      filterParams: {
        ...this.state.filterParams,
        page: 0,
        offset : 0
      }
    }, () => {
      this.searchAdminMessages();
      this.props.dispatch(messageActions.clearVisitedPages());
    });
  };

  /**
   * Calls the searchAdminMessages action with the current state as the payload
   */
  searchAdminMessages = () => {
    const { organization } =this.props;
    const orgId = organization.trendsId
    this.setState({
      displayConversations: false,
      displaySearchResults: true,
      filterParams: {
        ...this.state.filterParams,
        organizations: [orgId],
      }
    },() => {
      const searchParams = this.state.filterParams;
      this.props.dispatch(messageActions.searchAdminMessages(searchParams));
    });
  };

  populateParams(key, channels) {
    const mappedChannels = channels.map(Number);
    this.setState(prevState => ({
      key,
      filterParams: {
        ...prevState.filterParams,
        channels: mappedChannels
      },
    }));
    return {
      ...this.state.filterParams
    };
  };

  getChannels = (dataSourceHandles, returnValue) => {
    return dataSourceHandles.map(({ trendsId, name }) => returnValue === 'ids' ? trendsId : ({ value: trendsId, name }));
  };

  /**
   * Handles the update of a form element on the filter form.
   * @param event
   */
  handleFormUpdate = (event) => {
    const target = event.target;
    this.setState((prevState) => {
      return {
        filterParams: {
          ...prevState.filterParams,
          [target.name]: target.value}
      }
    });
  };

  handleChannelsFilterChange = (channels) => {
    const { key } = this.state;
    this.populateParams(key, channels);
  };

  handlePageSelect = (page) => {
    this.setState({
      filterParams: {
        ...this.state.filterParams,
        offset : page.selected * this.state.max,
        page: page.selected
      }
    },() => {
      this.searchAdminMessages();
    });
  };

  navigateToEdit = () =>{
    this.setState({
      displaySearchResults: false
    })
  }

  navigateToSearchResult = () =>{
    this.setState({
      displaySearchResults: true,
      displayConversations: false
    })
  }

  getDataSourceHandles = (organizations) => {
    this.props.dispatch(dataSourceHandleActions.getAll({
      organizations
    }));
  };

  showConversations =(messageId) =>{
    this.setState({
      displayConversations:true,
      displaySearchResults: true,
      messageId : messageId
    });
  }


  componentDidMount() {
    getNextMessage(this.props.dispatch, false);
    const org = getVotingOrganization();
    this.props.dispatch(organizationActions.getOrganization(_.get(org, 'id')));
    this.props.dispatch(dataSourceHandleActions.getAll({organization: _.get(org, 'id')}))
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({loadingProps: false})
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let channelIds = nextState.filterParams.channels;
    if (!nextState.channelsInitialized && !!nextProps.dataSourceHandles.length && channelIds.length === 0 ) {
      this.setState({
        channelsInitialized: true,
        filterParams: {
          ...nextState.filterParams,
          channels: this.getChannels(nextProps.dataSourceHandles, 'ids')
        }
      });
    }

    return true;
  }

  /**
   * This combines all the votes to an array
   * @param vote
   * @returns {*[]}
   */
  getAllVotes = (vote) => {
    let allVotes = this.state.voteArr.concat(vote);
    this.setState({ voteArr: allVotes });

    return allVotes;
  };

  /**
   *
   * @param votesArray
   * @param response_id
   * @param response_type
   * @param response_text
   */
  submitVoteData = (votesArray, response_id = null, response_type = null, response_text = null) => {
    const payload = this.buildVotePayload(votesArray, response_id, response_type, response_text);
    this.props.dispatch(voteActions.submitVote(payload));

    //Reset state to default in order to process the next message
    this.setState({
      loadingProps: true,
      voteArr: [],
      displayApproveResponses: false,
      selectedApproveResponse: '',
      approveVoteArr: [],
      displayEditResponses: false,
      selectedEditResponse: '',
      editVoteArr: [],
    });
  };

    /**
     * A call back function that is passed as a props
     * to the VoteForm and handles submission of a vote
     * @param payload
     */
    handleVoteSubmit = (payload) => {

      const votesArray = this.getAllVotes(payload);
      const approveVotes = filterObjectValue(votesArray, 'tag', 'APPROVE');
      const editVotes = filterObjectValue(votesArray, 'tag', 'SEND_FOR_REVIEW');

      this.setState((prevState) => {
        return {
          currentResponseOption: prevState.currentResponseOption + 1,
          selectedTag: '',
          approveVoteArr: approveVotes,
          editVoteArr: editVotes,
        };
      });

      if (this.isLastResponseOption()) {
        this.setState({ currentResponseOption: 0 });

        if(approveVotes.length > 1) {
          //Show the approve response page
          this.setState({ displayApproveResponses: true })
        }
        else if(editVotes.length > 0 && approveVotes.length === 0) {
          //Show the edit response page
          this.setState({ displayEditResponses: true })
        }
        else if(editVotes.length === 0 && approveVotes.length === 0) {
          //Submit bad responses
          this.submitVoteData(votesArray);
        }
        else{
          //Submit only one approve response
          const responseId = approveVotes[0] && (approveVotes[0].response_id);
          this.submitVoteData(votesArray, responseId, responseType.singleApprove);
        }
      }

    };

  /**
   * This builds the payload for submitting a vote
   * @param votes
   * @param response_id
   * @param response_type
   * @param response_text
   * @returns {*}
   */
    buildVotePayload = (votes, response_id = null, response_type = null, response_text = null) => {

        const { nextMessage : {message : { dataSourceHandle }} } = this.props;
        const isEmail = [handleType.email, handleType.proactive_email].includes(dataSourceHandle.type);

        let _votes = [], plainResponseText = response_text;

        if(isEmail) {
            plainResponseText = htmlToText.fromString(response_text, {wordwrap: false});
        }

        switch (response_type) {
        case responseType.singleApprove:
        case responseType.multiApprove:
          _votes = votes.map(voteObj =>
            voteObj.response_id === response_id ?
              {
                ...voteObj,
                is_best_response: true,
              } : voteObj
          );

          return {
            userVote: {
              votes: _votes,
              isLastResponseVote: true
            },
            navigation,
          };

        case responseType.editedText:
          _votes = votes.map(voteObj =>
            voteObj.response_id === response_id ?
              {
                ...voteObj,
                tag: APPROVE_TAG,
                is_best_response: true,
                edited_text: plainResponseText,
                is_text_edited: true,
              } : voteObj
          );

          return {
            userVote: {
              votes: _votes,
              isLastResponseVote: true
            },
            navigation,
          };

        case responseType.editedHtml:
          _votes = votes.map(voteObj =>
            voteObj.response_id === response_id ?
              {
                ...voteObj,
                tag: APPROVE_TAG,
                is_best_response: true,
                edited_text: plainResponseText,
                edited_html_body: response_text,
                is_text_edited: true,
              } : voteObj
          );

          return {
            userVote: {
              votes: _votes,
              isLastResponseVote: true
            },
            navigation,
          };

        default:
          return {
            userVote: {
              votes,
              isLastResponseVote: this.isLastResponseOption() //This property will be available in the payload temporarily for backward compatibility
            },
            navigation,
          };
      }
    };

    /**
     * Check if the current response option is the last
     * @returns {boolean}
     */
    isLastResponseOption = () => {
      return this.state.currentResponseOption === this.props.nextMessage.message.response_options.length - 1
    };

    handleApproveResponse = (event, responseId) => {
      if (event.target.id !== 'submitApproveButton') {
        this.setState({ selectedApproveResponse: responseId })
      }
    };

    handleEditResponse = (responseId) => {
      this.setState({ selectedEditResponse: responseId })
    };

    voteTagSelectHandler = (event, value) => {
      if (event.target.id !== 'submitButton') {
        this.setState({
          selectedTag: value,
        });
      }
    };

    escalateMessage = (event, messageId) => {
      event.preventDefault();

      const agentId = this.state.currentUser.id;
      const escalationParams = {
        userMessageAction:{
          user: agentId,
          actionType: 'ESCALATE',
          message: messageId,
        },
        navigation,
      };
      this.props.dispatch(messageActions.escalate(escalationParams))
    };

    skipMessage = (event, messageId) => {
        event.preventDefault();

        const agentId = this.state.currentUser.id;
        const skipMessageParams = {
          userMessageAction:{
            user: agentId,
            actionType: 'NO_RESPONSE_NEEDED',
            message: messageId,
          },
          navigation
        };
        this.props.dispatch(messageActions.skipMessage(skipMessageParams))
    };

    calculateVoteProgress = (responseIndex, totalResponse) => ( ( responseIndex + 1 ) / totalResponse ) * 100;

    renderApproveResponse = (vote, index, agentName, messageAuthor, messageHandleType) => {

      return(
        <ApproveResponse
          approveVote={vote}
          onApproveResponseSelect={this.handleApproveResponse}
          selectedResponse={this.state.selectedApproveResponse}
          allVotes={this.state.voteArr}
          agentName={agentName}
          messageAuthor={messageAuthor}
          messageHandleType={messageHandleType}
          submitApproveResponse={this.submitVoteData}
          index={ index + 1 }
          key={index}
        />
      )
    };

    renderEditResponse = (vote, index, agentName, messageAuthor, messageHandleType) => {

      return(
        <EditResponse
          editVote={vote}
          onEditResponseSelect={this.handleEditResponse}
          selectedResponse={this.state.selectedEditResponse}
          allVotes={this.state.voteArr}
          agentName={agentName}
          messageAuthor={messageAuthor}
          messageHandleType={messageHandleType}
          submitEditResponse={this.submitVoteData}
          index={ index + 1 }
          key={index}
        />
      )
    };

    render() {
    const { nextMessage, reduxStateLoading } = this.props;
    const { displayApproveResponses, displayEditResponses, currentUser } = this.state;
    const messages = this.props.messages ? this.props.messages : [];
    const { filterParams: { msgDataSource, direction } } = this.state;
    const { organization } =this.props;
    const orgName = organization.name


    const input = {onChange: this.handleFormUpdate}

    let channels, channelsIds;
    const { dataSourceHandles } = this.props;

    if (dataSourceHandles) {
      channels = this.getChannels(dataSourceHandles);
      channelsIds = this.getChannels(dataSourceHandles, 'ids');
    }

    //Pagination : Get the total number of pages using resultCount and the page number from react paginate
    const { max, filterParams } = this.state;
    const page = parseInt(filterParams.page, 10);
    const count = this.props.resultCount;
    const totalPages = Math.ceil(count / max);

    if ( isObjectEmpty(nextMessage) ) {
      return (
        <VoteLoading/>
      )
    }

    const { message, conversationCount } = nextMessage;
    const { response_options, dataSourceHandle } = message;
    let { progressBarBackground, progressBarForeground } = progressBarStyle;

    if ( reduxStateLoading || this.state.loadingProps ){
      return(
        <VoteLoading/>
      )
    }

    if ( !(message && response_options) ){
      return(
        <VoteLoading/>
      )
    }

    const currentResponseOption = response_options[ this.state.currentResponseOption ];
    let voteSection;

    if (this.state.displayApproveResponses) {
        voteSection = <Fragment>
          <div className="vote-right-panel">
            <div className="progress-bar-container font-17 color-grey-mid">
              Send the best response to the customer
            </div>

            <div>
                {
                  this.state.approveVoteArr.map((vote, index) =>
                  this.renderApproveResponse(vote, index, getCurrentAgentName(), message.author, dataSourceHandle.type))
                }
            </div>
          </div>
        </Fragment>;
    }
    else if (this.state.displayConversations){
      voteSection=<Fragment>
        <div className="vote-right-panel">
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={this.navigateToSearchResult}
                    className="btn btn-sm"
                    style={{border: '1px solid #ededed', fontSize: '17px'}}>
              <i className="fa fa-arrow-left color-green"/> Back to Search Result
            </Button>
          </div>
          <SearchMessageDetails
              messageId = {this.state.messageId}
          />
        </div>
      </Fragment>
    }

    else if (this.state.displaySearchResults){

      voteSection = <Fragment>
        <div className="vote-right-panel">
          <h3>
            Showing Search Results For {orgName}
          </h3>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={this.navigateToEdit}
                    className="btn btn-sm"
                    style={{border: '1px solid #ededed', fontSize: '17px'}}>
              <i className="fa fa-arrow-left color-green"/> Back to responses
            </Button>
          </div>
          <Row>
            <FilterForm
                onFormUpdate={this.handleFormUpdate}
                input={input}
                channelsFilter={channels}
                channelsAction={this.state.filterParams.channels}
                channelsIds={channelsIds}
                handleChannelsFilterChange={this.handleChannelsFilterChange}
                msgDataSource={msgDataSource}
                direction={direction}
            />
          </Row>
          {this.props.loadingSearch &&
          <SearchLoading />
          }
          {!this.props.loadingSearch &&
          <div>
            <SearchResultTable
                messages = {messages}
                resultCount={this.props.resultCount}
                showConversations={this.showConversations}
            />
          </div>
          }
          {totalPages > 1 &&
          <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={<span>...</span>}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              disableInitialCallback={true}
              pageRangeDisplayed={5}
              forcePage={page}
              onPageChange={(page) => this.handlePageSelect(page)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
          />
          }
        </div>
      </Fragment>
    }
    else if (this.state.displayEditResponses) {
        voteSection = <Fragment>
          <div className="vote-right-panel">
            <div className="progress-bar-container font-17 color-grey-mid">
              { this.state.editVoteArr.length > 1 ? `Choose a response to edit` : `Edit response`}
            </div>

            <div>
              {
                this.state.editVoteArr.map((vote, index) =>
                  this.renderEditResponse(vote, index, getCurrentAgentName(), message.author, dataSourceHandle.type))
              }
            </div>
          </div>
        </Fragment>;
    }

    else{
      voteSection = <Fragment>
        <div className="vote-right-panel">
          <div className="progress-bar-container">
            <div style={progressBarBackground}>
              <div style={{
                ...progressBarForeground,
                width: response_options.length === 0 ? 0 : this.calculateVoteProgress(this.state.currentResponseOption, response_options.length) + '%'
              }} />
              <span className="progressBarText font-14 color-grey-mid">
                Reviewing {response_options.length === 0 ? 0 : this.state.currentResponseOption + 1} of {response_options.length} responses
              </span>
            </div>
          </div>

          <VotePanel
            messageHandleType={dataSourceHandle.type}
            agentName={getCurrentAgentName()}
            messageAuthor={message.author}
            selectedTag={this.state.selectedTag}
            responseOption={currentResponseOption}
            onVoteTagSelect={this.voteTagSelectHandler}
            onSubmit={this.handleVoteSubmit} />
        </div>
      </Fragment>;
    }

    let searchPanel = <div style={{marginTop: '50px'}}>
        <SearchInput
            searchAdminMessages={this.onSearch}
            searchOnKeyEnter={this.searchOnKeyEnter}
            loading={this.props.loadingSearch}
            searchString={this.state.filterParams.searchString}
            onFormFieldChange={this.updateStateSearchString}
        />
    </div>
    return (
      <div className="content-wrapper top-rel-80 wrapper-body">
        <div className="vote-container">
          <div className="vote-left-panel">
            <VoteMessage message={message} conversationCount={conversationCount} />
            {
              (!displayEditResponses) ? '': searchPanel
            }
            {
              ( displayApproveResponses ) ? '':

              <div className="msg-action-button-container" style={{marginTop: '50px'}}>
                <div style={{marginBottom: '20px'}}>
                  <button onClick={(e) => this.skipMessage(e, message.id)}
                          className="btn btn-block btn-default font-15 color-green"
                          style={{ width: '100%',height: '47px' }}>
                    No response needed
                  </button>
                </div>
                <div>
                  <button onClick={(e) => this.escalateMessage(e, message.id)}
                          className="btn btn-block btn-default font-15 color-green"
                          style={{ width: '100%',height: '47px' }}>
                    Escalate to manager
                  </button>
                </div>
              </div>
            }

            { _.get(currentUser, 'isRemotePortalServer') && <VotePortal message={message}/> }

          </div>
          <Fragment>
            {voteSection}
          </Fragment>
        </div>
      </div>

    );
  }
}

function mapStateToProps(state) {
  const { search:{ searchData, loading }, dataSourceHandles, messages, vote, organizations } = state;
  const {organization } = organizations;
  return {
    nextMessage: messages.nextMessage,
    votingOrganization: vote.votingOrganization,
    reduxStateLoading: messages.loadingNextMessage,
    dataSourceHandles: dataSourceHandles.dataSourceHandles,
    messages: searchData.searchResult,
    resultCount: searchData.resultCount,
    organization,
    loadingSearch: loading
  };
}

const connectedVotePage = connect(mapStateToProps)(VotePage);

export { connectedVotePage as VotePage };
