import {Box} from "@mui/material";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";
import BasicFilterSelect from "../../../common/BasicFilterSelect";

export default function OrganizationFilterSelect({organizations, loading, value, handleOrganizationSelectChange}) {
    return(
        <Box sx={styles.organizationSelectContainer}>
            {loading ?
                <LoadingPlaceholderBlock
                    width={'100%'}
                    height={'42px'}
                    borderRadius={'8px'}
                    backgroundColor={'#d7d2cf'}
                /> :
                <BasicFilterSelect
                    placeholder={'Select organization'}
                    options={organizations}
                    value={value}
                    onChange={handleOrganizationSelectChange}
                    textFieldStyle={styles.organizationSelectInput}
                    listBoxHeight={380}
                />
            }
        </Box>
    )
}

const styles = {
    organizationSelectContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
    },
    organizationSelectInput : {
        height: '42px',
        [`& fieldset`]: {
            borderRadius: '8px',
        },
        '& .MuiInputBase-input': {
            height: '10px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#687979',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },
}