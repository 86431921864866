import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {subscriptionsActions} from "_actions";
import ReactPaginate from "react-paginate";
import { queryStringToObject, objectToQueryString } from "_helpers";

class Subscription extends Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);

        this.state = {
            offset: 0,
            max: 10,
            ...filterParams
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { max, offset } = this.state;
        dispatch(subscriptionsActions.getAllSubscriptions({max, offset}))
    }

    handlePageSelect = (page) => {
        this.setState(prevState => ({
            offset: page.selected * prevState.max
        }), function() {
            const { max, offset } = this.state;
            this.getSubscriptions({max, offset})
        })
    }

    /**
     * Handles the change of url to retrieve subscriptions based on query params for filtering
     * @param filterParams The parameters for filtering the messages to be retrieved
     */
    getSubscriptions = (filterParams) => {
        const { history } = this.props
        const queryString = objectToQueryString(filterParams);
        history.push({
            pathname: '/subscriptions',
            search: `?${queryString}`
        });
    };

    render() {
        const { all_subscriptions, total } = this.props;
        const { max, offset } = this.state;
        const getOffsetInt = parseInt(offset,10);
        const getMaxInt = parseInt(max, 10);
        let page = getOffsetInt > 0 ? getOffsetInt / getMaxInt : getOffsetInt;
        const totalPages = total && max ? Math.ceil(total / max) : false;
        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <div style={{width: '50%', background: '#fff', padding: '20px 40px'}}>
                    <Link to="/create-subscription" className="btn btn-success pull-right" style={{marginBottom: '15px'}}>
                        Create Subscription
                    </Link>
                    <table className="table table-responsive clear-both">
                        <thead>
                        <tr>
                            <th>Currency</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>{''}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {total > 0 && all_subscriptions.map(subscription =>
                            <tr key={subscription.id}>
                                <td style={{verticalAlign: 'middle'}}>
                                    {subscription.currency &&
                                        `${subscription.currency.label} (${subscription.currency.code})`
                                    }
                                    {! subscription.currency &&
                                        'Unavailable'
                                    }
                                </td>
                                <td style={{verticalAlign: 'middle'}}>{subscription.amount}</td>
                                <td style={{verticalAlign: 'middle'}}>{subscription.label}</td>
                                <td style={{verticalAlign: 'middle'}}>
                                    <Link to={`/edit-subscription/${subscription.id}`} className="btn btn-info btn-sm">
                                        Edit Subscription
                                    </Link>
                                </td>
                            </tr>
                        )}
                        {total === 0 &&
                            <tr>
                                <td style={{textAlign: 'center', padding: '10px 0'}} colSpan="3">
                                    No Subscriptions have been previously added
                                </td>
                            </tr>
                        }
                        </tbody>
                        {totalPages && totalPages > 1 &&
                            <ReactPaginate previousLabel={"<"}
                               nextLabel={">"}
                               breakLabel={<span>...</span>}
                               breakClassName={"break-me"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               disableInitialCallback={true}
                               pageRangeDisplayed={5}
                               forcePage={page}
                               onPageChange={(page) => this.handlePageSelect(page)}
                               containerClassName={"pagination"}
                               subContainerClassName={"pages pagination"}
                               activeClassName={"active"}
                               activePage={offset / max}
                               handleSelect={this.handleSelect}
                            />
                        }
                    </table>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { subscription: { all_subscriptions, total }} = state;
    return {
        all_subscriptions, total
    };
}

let subscriptionConnect = connect(mapStateToProps)(Subscription);

export { subscriptionConnect as Subscription }