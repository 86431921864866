/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 29/11/2018 : 8:12 PM
 */

import { conversationConstants } from '_constants';

const INITIAL_STATE = {
  conversation: [],
  loading: false,
  error: '',
};

export function conversationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case conversationConstants.GET_CONVERSATION_REQUEST:
      return { ...state, loading: true, error: '' };
    case conversationConstants.GET_CONVERSATION_SUCCESS:
      return { ...state, conversation: action.payload, loading: false };
    case conversationConstants.GET_CONVERSATION_FAILURE:
      return { ...state, error: action.error, loading: false };

    case conversationConstants.GET_CONVERSATION_THREAD_REQUEST:
      return { ...state, loading: true, error: '', conversation: [] };
    case conversationConstants.GET_CONVERSATION_THREAD_SUCCESS:
      return { ...state, conversation: action.payload, loading: false };
    case conversationConstants.GET_CONVERSATION_THREAD_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
