import { subscriptionsConstants, alertMessages } from '_constants';
import { subscriptionsService } from '_services';
import {SnackBar} from "../components/common";

function getAllSubscriptions(params) {
    function request() {
        return { type: subscriptionsConstants.GET_ALL_SUBSCRIPTIONS_REQUEST };
    }

    function success(subscriptions) {
        return { type: subscriptionsConstants.GET_ALL_SUBSCRIPTIONS_SUCCESS, payload: subscriptions };
    }

    function failure(error) {
        return { type: subscriptionsConstants.GET_ALL_SUBSCRIPTIONS_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        subscriptionsService.getAll(params)
            .then(
                subscriptions => dispatch(success(subscriptions)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };
}

function getSubscription(id) {
    return (dispatch) => {
        dispatch(request());

        subscriptionsService.getSubscription(id)
            .then(
                subscriptions => dispatch(success(subscriptions)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request() {
        return { type: subscriptionsConstants.GET_SUBSCRIPTION_REQUEST};
    }

    function success(subscriptions) {
        return { type: subscriptionsConstants.GET_SUBSCRIPTION_SUCCESS, payload: subscriptions };
    }

    function failure(error) {
        return {type: subscriptionsConstants.GET_SUBSCRIPTION_FAILURE, payload: error};
    }
}

function createSubscription(data) {
    return (dispatch) => {
        dispatch(request());

        subscriptionsService.createSubscription(data)
            .then(
                subscriptions => {
                    dispatch(success(subscriptions));
                    SnackBar.showMessage(alertMessages.CREATE_SUBSCRIPTION_SUCCESS);
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request(id) {
        return {type: subscriptionsConstants.CREATE_SUBSCRIPTION_REQUEST, payload: id};
    }

    function success(subscriptions) {
        return {type: subscriptionsConstants.CREATE_SUBSCRIPTION_SUCCESS, payload: subscriptions};
    }

    function failure(error) {
        return {type: subscriptionsConstants.CREATE_SUBSCRIPTION_FAILURE, payload: error};
    }
}

function updateSubscription(data, id) {
    return (dispatch) => {
        dispatch(request());

        subscriptionsService.updateSubscription(data, id)
            .then(
                subscriptions => {
                    dispatch(success(subscriptions));
                    SnackBar.showMessage(alertMessages.UPDATE_SUBSCRIPTION_SUCCESS);
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request(id) {
        return {type: subscriptionsConstants.UPDATE_SUBSCRIPTION_REQUEST, payload: id};
    }

    function success(subscriptions) {
        return {type: subscriptionsConstants.UPDATE_SUBSCRIPTION_SUCCESS, payload: subscriptions};
    }

    function failure(error) {
        return {type: subscriptionsConstants.UPDATE_SUBSCRIPTION_FAILURE, payload: error};
    }
}

function subscriptionHistory(orgId, params) {
    return (dispatch) => {
        dispatch(request());

        subscriptionsService.subscriptionHistory(orgId, params)
            .then(
                history => {
                    dispatch(success(history));
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request() {
        return {type: subscriptionsConstants.SUBSCRIPTION_HISTORY_REQUEST};
    }

    function success(history) {
        return {type: subscriptionsConstants.SUBSCRIPTION_HISTORY_SUCCESS, payload: history};
    }

    function failure(error) {
        return {type: subscriptionsConstants.SUBSCRIPTION_HISTORY_FAILURE, payload: error};
    }
}

function addOrgSubscription(organization, subscription) {
    return (dispatch) => {
        dispatch(request());

        subscriptionsService.addOrgSubscription(organization, subscription)
            .then(
                history => {
                    dispatch(success(history));
                    SnackBar.showMessage(alertMessages.ORG_SUBSCRIPTION_SUCCESS);
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request() {
        return {type: subscriptionsConstants.ADD_ORG_SUBSCRIPTION_REQUEST};
    }

    function success(history) {
        return {type: subscriptionsConstants.ADD_ORG_SUBSCRIPTION_SUCCESS, payload: history};
    }

    function failure(error) {
        return {type: subscriptionsConstants.ADD_ORG_SUBSCRIPTION_FAILURE, payload: error};
    }
}

export const subscriptionsActions = {
    getAllSubscriptions,
    getSubscription,
    createSubscription,
    updateSubscription,
    subscriptionHistory,
    addOrgSubscription
};