import React, {Fragment, useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {getCurrentUser} from "_helpers";
import {msgSourceActions, gorgiasAuthActions} from "_actions";
import {Box} from "@mui/material";
import Input from "../../Utilities/Input";
import basicModalStyles from "../../Utilities/basicModalStyles";
import SetupModalBottomBar from "../../Utilities/SetupModalBottomBar";
import SubdomainInput from "../../../../Onboarding/ConnectChannel/Channels/Utilities/SubdomainInput";
import {SERVER_URL} from 'config';

function GorgiasSetupModalForm({description, buttonLabel, channelDomain, extraInfoLabel, openInNewTab, handleSubmit, callbackUrl = `${CLIENT_URL}/signup/add-channel`}){
  const dispatch = useDispatch();
  const gorgias = useSelector(state => state.gorgiasAuth);

  const [disabled, setDisabled] = React.useState(true);
  const loading = React.useMemo(() => gorgias.loading && !disabled, [gorgias]);

	const placeholders = {
		subdomain: 'Subdomain'
	}

  useEffect(() => {
		dispatch(msgSourceActions.getMsgSourceLink('gorgias'))
  }, []);

  const performAction = (value) => {
    setDisabled(isEmpty(value));
  };

  const onSubmit = (props) => {
      const { id } = getCurrentUser();
      const params = { redirectUrl: callbackUrl + "?account=" + props.subdomain + "&partner=gorgias", subdomain: props.subdomain, userId: id };
      dispatch(gorgiasAuthActions.initOauth(params));
  };

  return(
    <Fragment>
      <Box sx={basicModalStyles.container}>

        <Box sx={basicModalStyles.description}>
          {description}
        </Box>

        <form name="gorgiasForm" onSubmit={ handleSubmit(onSubmit) }>
          <Field
            name={'subdomain'}
            component={SubdomainInput}
            channelDomain={channelDomain}
            placeholder={placeholders.subdomain}
            performAction={performAction}
          />

          <SetupModalBottomBar
            extraInfoLabel={extraInfoLabel}
            openInNewTab={openInNewTab}
            buttonLabel={buttonLabel}
            disabled={loading || disabled}
            loading={loading}
          />
        </form>
      </Box>
    </Fragment>
  )
}

const formConfig = {
  form: 'gorgiasForm',
};

export default reduxForm(formConfig)(GorgiasSetupModalForm);