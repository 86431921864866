import {useDispatch} from "react-redux";
import React, {Fragment, useState} from "react";
import {CircularProgress} from "@mui/material";
import _ from "lodash";
import {oEmbedActions, proactiveActions} from "_actions";
import {isTimeToday} from "_helpers";
import {isMetaSourcesForOEmbed} from "../Vote/Sections/BodySection/Conversation/OEmbed/EmbedUtils";
import {formatDateCreated} from "./ProactiveMetricsDetailUtil";

export const ProactiveMessageList = ({msgList, setMessageLink, setMessageSource, loading}) => {
    const dispatch = useDispatch();
    const [selectedMessage, setSelectedMessage ] = useState(null);

    const onMessageSelect = ({id, link, msgSource, displayInApp}) => {
        const msgSourceName = _.toString(_.get(msgSource, 'name')).toLowerCase();
        setMessageLink(link)
        setMessageSource(msgSourceName)
        setSelectedMessage(id)
        dispatch(proactiveActions.getProactiveMsgConversation(id))
        if(isMetaSourcesForOEmbed(msgSourceName, displayInApp)) {
            dispatch(oEmbedActions.getMetaEmbed(id));
        }
    };

    return msgList.map(msg => {
        return (
            <Fragment>
                {loading &&
                <div style={{ background: 'transparent', top: 0, left: 0, width: '100%', height: '100%'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                        <CircularProgress style={{color: '#143F42'}}/>
                    </div>
                </div>
                }
                {!loading &&
                <div
                    role="button"
                    key={msg.id}
                    id={msg.id}
                    style={{
                        backgroundColor: selectedMessage === msg.id ? '#F3F5F6' : 'white'
                    }}
                    onClick={() => onMessageSelect(msg)}
                >
                    <div className="message-list-container" >
                        <div className="tms-truncate-2-lines message-list-text">
                            {msg.text}
                        </div>
                        <div className="message-list-date" >
                            {isTimeToday(msg.dateCreatedFromSource) ?
                                formatDateCreated(msg.dateCreatedFromSource, 'HH:mm a') :
                                formatDateCreated(msg.dateCreatedFromSource, 'DD-MMM-YYYY')}
                        </div>
                    </div>
                </div>
                }
            </Fragment>
        )
    })
}