import {Fragment, useEffect, useState} from "react";
import {Box} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import {RadioGroup, useTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Field, reduxForm} from "redux-form";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import _ from "lodash";
import {filterObjectValue} from "_helpers";
import ChannelRow from "./ChannelRow";
import {youtubeActions} from "_actions";
import {buildSaveChannelPayload} from "./YoutubeUtils";
import ActionButton from "../../../../../common/ActionButton";

function ChannelSelectionModalContent({ handleClose, handleSubmit }) {
    const [selectedChannel, setSelectedChannel] = useState('');
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const youtubeData = useSelector((state) => state.youtube);
    const channelDetails = _.get(youtubeData, 'channelDetails.data') || {};
    const channels = _.get(channelDetails, 'channels') || {};
    const tokenObj = _.get(channelDetails, 'tokenResponse') || {};
    const organizationId = _.get(channelDetails, 'organizationId');
    const loading = _.get(youtubeData, 'loadingChannels');
    const classes = useStyles();

    useEffect(() => {

    }, []);

    const handleChange = (event) => {
        setSelectedChannel(_.get(event, 'target.value'));
    }

    const hasChannel = !_.isEmpty(channels) && channels.length > 0;

    const onSubmit = () => {
        const channelSelected = filterObjectValue(channels, 'id', selectedChannel);
        if(!_.isEmpty(channelSelected)) {
            const payload = buildSaveChannelPayload(channelSelected, organizationId, tokenObj);
            dispatch(youtubeActions.saveChannel(payload));
        }
        handleClose();
    };

    const Loading = () => {
        return(
            <Box className={classes.progress}>
                <CircularProgress sx={{color: '#143F42'}}/>
            </Box>
        )
    };

    if(loading){
        return <Loading/>
    }

    return(
        <Fragment>
            <Box sx={isMobile ? {marginTop: '10px'} : {}}>
                <form name="youtubeChannels" onSubmit={ handleSubmit(onSubmit) }>
                    <RadioGroup name="youtube-channel-group" onChange={handleChange}>
                        {hasChannel && channels.map((channel) => {
                            return (
                                <Fragment>
                                    <Field
                                        id={ channel.id }
                                        name={channel.id }
                                        channelName={ channel.name }
                                        customUrl={ channel.customUrl }
                                        thumbnailUrl={ channel.thumbnailUrl }
                                        component={ ChannelRow }
                                    />
                                </Fragment>
                            )
                        })}
                    </RadioGroup>
                    <Box className={isMobile ? classes.buttonContainerMobile : classes.buttonContainer}>
                        <Box className={isMobile ? classes.buttonMobile : classes.button}>
                            <ActionButton label={'Submit'}/>
                        </Box>
                    </Box>
                </form>

            </Box>
        </Fragment>
    )
}

const useStyles = makeStyles({
    button: {},
    buttonMobile: {
        width: '100%'
    },
    text: {
        color: '#0C2728',
        fontFamily: 'larsseitRegular',
        fontSize: '18px',
        lineHeight: '120%',
        width: 'calc(100% - 47px)',
    },
    textMobile: {
        color: '#0C2728',
        fontFamily: 'larsseitRegular',
        fontSize: '16px',
        lineHeight: '120%',
        width: 'calc(100% - 47px)',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '30px',
    },
    buttonContainerMobile: {
        display: 'flex',
        marginTop: '35px',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    progress: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const formConfig = {
    form: 'facebookPagesForm',
};

export default reduxForm(formConfig)(ChannelSelectionModalContent);