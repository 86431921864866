import {Box} from "@mui/system";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/ContentCopy';

export default function CampaignActions({ campaign }) {
    return (
        <Box sx={{display: 'flex'}}>
            <Box>
                <IconButton component={'a'} sx={styles.link} size={'small'} href={`edit-campaign/${campaign.id}`}>
                    <EditIcon sx={styles.icon}/>
                </IconButton>
            </Box>
            <Box>
                <IconButton component={'a'} sx={styles.link} size={'small'} href={`create-campaign/${campaign.id}`}>
                    <CopyIcon sx={styles.icon}/>
                </IconButton>
            </Box>
        </Box>
    )
}

const styles = {
    icon: {
        color:'#07857C',
        fontSize: '20px'
    },
    link: {
        ':focus': {
            outline: 'unset',
        }
    }
}