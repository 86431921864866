import React from "react";
import {Box, Grid} from "@mui/material";
import {change, Field, reduxForm} from "redux-form";
import BasicFormTextField from "../../../common/BasicFormTextField";
import {getCurrentUser, isvalidDateRange, required} from "_helpers";
import CheckboxLabel from "../../../common/CheckboxLabel";
import ActionButton from "../../../common/ActionButton";
import AnchorButton from "../../../common/AnchorButton";
import ChannelsMultiSelect from "../Utilities/ChannelsMultiSelect";
import {useDispatch} from "react-redux";
import BasicTimePicker from "../../../common/BasicTimePicker";
import _ from "lodash";

const DaysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function AddSchedulePageV2({ handleSubmit, options, onSubmit }){
    const dispatch = useDispatch();

    const currentUser = getCurrentUser();
    const { timezone } = currentUser._embedded.organization;

    const handleChannelsSelect = (value) => {
        dispatch(change('AddSchedulePageV2','channels', value));
    }

    const onChange = (event) => {
        const name = _.get(event, 'target.name');
        const value = _.get(event, 'target.checked');
        dispatch(change('AddSchedulePageV2',name, value))
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{marginTop: "30px"}}>
                <Grid item sx={styles.formContainer} xs={12} md={6} lg={6}>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Schedule Name</Box>
                        <Field
                            type="text"
                            name="name"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>

                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Days</Box>
                        <Grid container>
                            {DaysOfWeek.map(day => {
                                return (
                                    <Grid item xs={3} lg={3} key={day}>
                                        <CheckboxLabel
                                            name={day}
                                            value={day}
                                            label={day}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                )})}
                        </Grid>
                    </Box>

                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Box sx={styles.inputDesc}>Start Time</Box>
                                <Field
                                    name="startTime"
                                    component={BasicTimePicker}
                                    type="text"
                                    showSecond={false}
                                    applyValidation={true}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box sx={styles.inputDesc}>End Time</Box>
                                <Field
                                    name="endTime"
                                    component={BasicTimePicker}
                                    type="text"
                                    showSecond={false}
                                    applyValidation={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={styles.inputDesc}>Timezone</Box>
                                <Box sx={{color: "#0C2728", fontSize: 14, fontFamily: 'larsseitRegular'}} >{timezone ? timezone : "No timezone set"}</Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mb={{xs: "15px", sm: "15px", md: "20px", lg: "20px"}}>
                        <Box sx={styles.inputDesc}>Select Channel</Box>
                        <Field
                            name="channels"
                            component={ChannelsMultiSelect}
                            options={options}
                            handleFormSelectChange={handleChannelsSelect}
                        />
                    </Box>

                    <Box sx={styles.footer}>
                        <Box sx={{width: '100px'}}>
                            <ActionButton
                                label={'Save'}
                            />
                        </Box>
                        <Box sx={{width: '100px'}}>
                            <AnchorButton
                                label={'Cancel'}
                                link={'/profile'}
                                buttonBordered={true}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}

const styles = {
    formContainer: {
        backgroundColor: '#FFF',
        border: '1px solid #CED4D4',
        borderRadius: '5px',
        padding: '20px'
    },
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    footer: {
        display: 'flex',
        gap: '18px'
    },
}

function validate(values) {
    const errors = {};
    const {startTime, endTime} = values;
    const validDateRange = isvalidDateRange(startTime, endTime);

    if (!validDateRange){
        errors.endTime = "End time must be after Start time"
    }

    if (startTime === undefined){
        errors.startTime = "This field is required!"
    }

    if (endTime === undefined){
        errors.endTime = "This field is required!"
    }

    return errors
}

const formConfig = {
    form: 'AddSchedulePageV2',
    validate
};

AddSchedulePageV2 = reduxForm(formConfig)(AddSchedulePageV2)
export default AddSchedulePageV2