import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getVotingOrganization} from "_helpers";
import {organizationActions} from "_actions";
import _ from "lodash";

export default function () {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            getOrganization();
            return () => effectRan.current = true;
        }
    }, []);

    const getOrganization = () => {
        const organization = getVotingOrganization();
        dispatch(organizationActions.getOrganization(_.get(organization, 'id')));
    }

    const organization = useSelector(state => state.organizations.organization);
    const loadingOrganization = useSelector(state => state.organizations.loading);
    return { organization, loadingOrganization };
}