import React from "react";
import { reduxForm } from "redux-form";
import { CriteriaResponseCheckboxes } from "../Fields/CriteriaResponseCheckboxes";
import { presaleCriteriaOptions } from "../MetricsDetailUtils";
import { ChannelsCheckboxes } from "../Fields/ChannelsCheckboxes";
import Stack from "@mui/material/Stack";

const PreSaleFilterDropdownForm = ({
                                       handles,
                                       setSelectedChannels,
                                       selectedChannels,
                                       selectedCheckboxes,
                                       setSelectedCheckboxes,
                                       allChannelsSelected,
                                       setAllChannelsSelected
}) => {
    const criteriaHeading = "Criteria"

    return(
        <Stack direction={'row'} spacing={1}>
            <ChannelsCheckboxes
                handles={handles}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                allChannelsSelected={allChannelsSelected}
                setAllChannelsSelected={setAllChannelsSelected}
            />

            <div className="filter-all-criteria" style={{marginLeft: '15px'}}>
                <CriteriaResponseCheckboxes
                    criteriaOptions={presaleCriteriaOptions}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    criteriaHeading={criteriaHeading}
                />
            </div>
        </Stack>
    )
}

const preSaleFilterDropdownForm = reduxForm({
    form: 'PreSaleFilterDropdownForm'
})(PreSaleFilterDropdownForm);

export { preSaleFilterDropdownForm as PreSaleFilterDropdownForm }