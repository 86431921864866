import {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import {Box} from "@mui/system";
import _ from "lodash";
import {messageActions} from "_actions";
import ActionButton from "../../../../../common/ActionButton";
import BasicTextField from "../../../../../common/BasicTextField";
import useViewOrganization from "../../../Utilities/hooks/useViewOrganization";
import {buildSearchPayload} from "../../../Utilities/VoteUtils";
import useViewOrgDataSourceHandles from "../../../Utilities/hooks/useViewOrgDataSourceHandles";
import CircularProgress from "@mui/material/CircularProgress";
import voteViews from "../../../Utilities/voteViews";

export default function SearchContainer({handleSearchViewToggle, isScrollComplete}) {
    const dispatch = useDispatch();
    const {organization} = useViewOrganization();
    const {dataSourceHandles} = useViewOrgDataSourceHandles();
    let filterParams = {};
    const [searchField, setSearchField] = useState({});
    const [params, setParams] = useState({
        ...filterParams,
        direction: 'ALL',
        searchString: '',
        page: 0,
        offset: 0
    });
    const loadingSearch = useSelector(state => state.search.loading);
    const shouldDisable = _.isEmpty(searchField) || !isScrollComplete || loadingSearch;

    const handleSearchField = (event) => {
        const { name, value } = event.target;
        setSearchField({ [name]: value });
    }

    const handleSearchOnKeyDown = (event) => {
        if(event.keyCode === 13) handleSearch();
    }

    const handleSearch = () => {
        searchMessage();
    }

    const searchMessage = () => {
        if(_.isEmpty(searchField)) return;
        const trendsOrganizationId = _.get(organization, 'trendsId');
        const payload = buildSearchPayload(params, searchField, trendsOrganizationId, dataSourceHandles);
        setParams({...params, ...payload});
        dispatch(messageActions.searchMessages(payload));
        handleSearchViewToggle(voteViews.searchResult);
    }

    return(
        <Fragment>
            <Box sx={{display: 'flex'}}>
                <Box sx={{width: '100%'}}>
                    <BasicTextField
                        name={'searchMessage'}
                        startIcon={<SearchIcon sx={styles.icon} />}
                        placeholder={'Search previous conversations'}
                        handleChange={handleSearchField}
                        disabled={!isScrollComplete}
                        onKeyDown={handleSearchOnKeyDown}
                        sx={styles.textField}
                    />
                </Box>
                <Box sx={{marginLeft: '10px'}}>
                    <ActionButton
                        label={loadingSearch ? <CircularProgress size={15} sx={{ color: '#ffffff'}}/> : 'Search'}
                        action={handleSearch}
                        disabled={shouldDisable}
                        withIcon={false}
                        useDark
                        sx={styles.searchButton} />
                </Box>
            </Box>
        </Fragment>
    )
}

const styles = {
    textField: {
        height: '35px',
        [`& fieldset`]: {
            borderRadius: '5px',

        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#D0D9D9',
            },
            '&:hover fieldset': {
                borderColor: '#9EA9A9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },
    icon: {
        color: '#495D5E'
    },
    searchButton: {
        width: '100px',
        height: '38px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};