import {styles as panelStyles} from "../../Settings";

const styles = theme => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        top: '140px'
    },
    paper: {
        padding: theme.spacing.unit * 7,
        margin: 'auto',
        maxWidth: 1100,
        borderRadius: "3px",
        border: "solid 1px #dee2e0",
        boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)"
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    table: {
        width: '100%',
        overflowX: 'auto',
        marginTop: '20px',
        boxShadow: "none",
        minWidth: '100%',
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    timezone: {
        fontSize: "15px",
        paddingTop: "6px",
        color: "#5a5f5e"
    },
    bold: {
        fontWeight: "500"
    },
    tableCell: {
        padding: "7px 10px 7px 24px",
        color: "#5a5f5e"
    },
    tableHeadCell: {
        paddingRight: "10px",
        fontWeight: "500",
        color: "#5a5f5e"
    },
    header: {
        marginTop: "0",
        marginBottom: "20px",
        color: "#5a5f5e"

    },
});

const {
    panel,
    panelTitleContainer,
    panelTitleContent,
} = panelStyles;

export const scheduleStyles = {
    styles,
    panelStyles: {
        panel,
        panelTitleContainer,
        panelTitleContent,
    }
}