import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";

export default function useDixaSetup(){
    const [open, setOpen] = React.useState(false);
    const account = useSelector((state) => state.dixa.account);
    const loading = useSelector((state) => state.dixa.createAccountLoading);

    useEffect(() => {
        if(!loading && _.get(account, 'statusCode') === 201) {
            setOpen(false);
        }
    }, [loading]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const performAction = () => {
        handleOpen();
    };

    return {
        initiateDixaSetup: performAction,
        dixaSetupOpen: open,
        handleDixaSetupOpen: handleOpen,
        handleDixaSetupClose: handleClose
    };
}