import { currencyConstants } from '_constants';
import { currencyService } from '_services';
import {SnackBar} from "../components/common";

function getAll(params) {
    function request() {
        return { type: currencyConstants.GET_ALL_CURRENCY_REQUEST };
    }

    function success(currency) {
        return { type: currencyConstants.GET_ALL_CURRENCY_SUCCESS, payload: currency };
    }

    function failure(error) {
        return { type: currencyConstants.GET_ALL_CURRENCY_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        currencyService.getAll(params)
            .then(
                currency => dispatch(success(currency)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error);
                },
            );
    };
}

export const currencyActions = {
    getAll,
};