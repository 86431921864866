export class ExpertsTicketMetricsBuilder {
  constructor() {
    this.metrics = {};
  }

  setExpertName(expertName){
    this.metrics.expertName = expertName;
    return this;
  }

  setVolumeTicketsHandled(volumeTicketsHandled){
    this.metrics.volumeTicketsHandled = volumeTicketsHandled;
    return this;
  }

  setSentForReTraining(sentForReTraining){
    this.metrics.sentForReTraining = sentForReTraining;
    return this;
  }

  build(){
    return this.metrics;
  }
}
