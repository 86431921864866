import {SERVER_URL} from "config";
import axios from "axios";
import {authHeader, handleHttpResponseAndReturnError} from "_helpers";
import { objectToQueryString, handleHttpResponse } from "_helpers";

function getPerformanceMetrics(organizationId, startDate, endDate) {
  const endpoint = `${SERVER_URL}/api/v1/metrics/performance`;

  return axios.get(endpoint, {
    params: { organizationId, startDate, endDate },
    headers: authHeader()
  });
}

function getQualityScoreMetrics(organizationId, startDate, endDate) {
  const endpoint = `${SERVER_URL}/api/v1/metrics/quality-score`;

  return axios.get(endpoint, {
    params: { organizationId, startDate, endDate },
    headers: authHeader()
  });
}

function getChannelsMetrics(organizationId, startDate, endDate) {
  const endpoint = `${SERVER_URL}/api/v1/metrics/channels`;

  return axios.get(endpoint, {
    params: { organizationId, startDate, endDate },
    headers: authHeader()
  });
}

function getExpertsTicketMetrics(organizationId, startDate, endDate) {
  const endpoint = `${SERVER_URL}/api/v1/metrics/experts`;

  return axios.get(endpoint, {
    params: { organizationId, startDate, endDate },
    headers: authHeader()
  });
}

function getImpactMetrics(organizationId, startDate, endDate) {
  const endpoint = `${SERVER_URL}/api/v1/metrics/impact`;

  return axios.get(endpoint, {
    params: { organizationId, startDate, endDate },
    headers: authHeader()
  });
}

function getScheduleSummary(organizationId) {
  const endpoint = `${SERVER_URL}/api/v1/metrics/scheduleSummary`;

  return axios.get(endpoint, {
    params: { organizationId },
    headers: authHeader()
  });
}

function getScheduleDetails(organizationId) {
  const endpoint = `${SERVER_URL}/api/v1/metrics/scheduleDetails`;

  return axios.get(endpoint, {
    params: { organizationId },
    headers: authHeader()
  });
}

function getResponseTimeDetails(params) {
  const endpoint = `${SERVER_URL}/api/v1/metrics-details/getResponseTime?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
}

function getMetricsDetailConversation(params) {
  const endpoint = `${SERVER_URL}/api/v1/metrics-details/responses?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
}

function getQualityScoreMessages(params) {
  const endpoint = `${SERVER_URL}/api/v1/metrics-details/qualityScoreMessages?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
}

function getPreSaleMessages(params) {
  const endpoint = `${SERVER_URL}/api/v1/metrics-details/preSaleMessages?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
}

function getCustomerAnsweredMessages(params) {
  const endpoint = `${SERVER_URL}/api/v1/metrics-details/customerAnsweredMessages?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
}

function exportMetricsMessages(payload) {
  const endpoint = `${SERVER_URL}/api/v1/metrics-details/export?${objectToQueryString(payload)}`;

  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
  };
  return fetch(endpoint, requestOptions).then(response => {
    response.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'MetricsDetail Stats.csv';
      anchor.click();
    });
  });
}

function setMessagePreSale(params) {
  return fetch(`${SERVER_URL}/api/v1/metrics-details/setPreSaleValue`,
      {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
      })
      .then(handleHttpResponse);
}

export const metricsService = {
  getPerformanceMetrics,
  getQualityScoreMetrics,
  getChannelsMetrics,
  getExpertsTicketMetrics,
  getImpactMetrics,
  getScheduleSummary,
  getScheduleDetails,
  getResponseTimeDetails,
  getMetricsDetailConversation,
  getQualityScoreMessages,
  getPreSaleMessages,
  getCustomerAnsweredMessages,
  exportMetricsMessages,
  setMessagePreSale,
}