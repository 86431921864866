import videoThumbnail from "components/images/chatdesk_video_thumbnail.png";
import Button from "@mui/material/Button";
import playIcon from "components/images/icons/video_play.svg";
import {Box} from "@mui/system";
import React,{Fragment} from "react";
import Modal from '@mui/material/Modal';
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

export default function Testimonial() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const performAction = () => {
    handleOpen();
  };

  return(
    <Fragment>
      <Box mt={{xs: '20px', sm: '20px', md: '20px', lg: '20px'}}
           fontSize={{xs: '18px', sm: '18px', md: '24px', lg: '24px'}}
           sx={styles.container}>
        <img src={videoThumbnail} style={{width: '100%', borderRadius: '7px'}} alt="video thumbnail"/>

        <Button sx={styles.playButton} onClick={performAction}>
          <img src={playIcon} style={{width: '100%'}} alt="video thumbnail"/>
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.9)" } }}
      >
        <Box>
          <Box sx={styles.closeButtonContainer}>
            <Button onClick={handleClose} sx={styles.closeButton}>
              <CloseIcon sx={styles.closeIcon}/>
            </Button>
          </Box>
          <Box sx={ isMobile ? styles.videoContainerMobile : styles.videoContainer}>
            <CircularProgress sx={ isMobile ? styles.progressContainerMobile : styles.progressContainer}/>
            <iframe style={styles.iFrame}
                    src="https://www.loom.com/embed/f8a2027b74fd4fc8987156432d2f1ad2"
                    allowFullScreen>
            </iframe>
          </Box>
        </Box>

      </Modal>

    </Fragment>
  )
}

const styles = {
  playButton: {
    width: '75px',
    height: '75px',
    position: 'absolute',
    padding: '0',
    boxShadow: '0 0px 15px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: '100px',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  container: {
    fontFamily: 'larsseitRegular',
    lineHeight: '120%',
    position: 'relative',
    display: 'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  videoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '950px',
    height: '700px',
    color: '#fff'
  },
  videoContainerMobile: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '60%',
    color: '#fff'
  },
  progressContainer: {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 20px)',
    transform: 'translate(-50%, -50%)',
    width: '950px',
    height: '700px',
    color: '#fff'
  },
  progressContainerMobile: {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 20px)',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '60%',
    color: '#fff'
  },
  iFrame: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 20,
    border: 'unset'
  },
  closeButtonContainer: {
    position:'absolute',
    color: '#ffffff',
    marginTop: '20px',
    left: 'calc(100% - 50px)'
  },
  closeButton: {
    padding: '0',
    minWidth: "30px",
    minHeight: '30px'
  },
  closeIcon: {
    fontSize: '24px',
    color: '#ffffff'
  }
};