import React, {Fragment} from "react";
import BasicSelect from "./BasicSelect";

export default function StatusSelect({ id, name, label, placeholder, defaultValue, input, meta }) {
    const options = [
        { 'id': true, 'name': 'Active' },
        { 'id': false, 'name': 'Inactive' },
    ]

    return(
        <Fragment>
            <BasicSelect
                id={id}
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={defaultValue}
                options={options}
                input={input}
                meta={meta} />
        </Fragment>
    )
}