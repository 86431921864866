import React from 'react'

const style= {
    width: "100%",
    marginBottom: "15px"
}

const InputField = ({type, name, initialValue, onChange, label }) => {
    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <input type={type}
                   name={name}
                   className="input-field"
                   defaultValue={initialValue}
                   onChange={onChange}
                   style={style}
                   required/>
        </div>
    );
};

export default InputField;