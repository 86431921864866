import { oauthConstants, msgDataSourceConstants } from '_constants';

const INITIAL_STATE = {
  authUrl: '',
  error: '',
  loading: true,
  showInboxes: false,
  showWebhookUrl: false,
  platformUrl: '',
  inboxList: [],
  messageDataSourceId: '',
  status: '',
  channel: 'frontapp',
  webhookUrl: '',
  list: []
};

export function frontAppOauthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case oauthConstants.FRONTAPP_OAUTH_URL_REQUEST:
      return { ...state, loading: true };
    case oauthConstants.FRONTAPP_OAUTH_URL_SUCCESS:
      return { ...state, authUrl: action.payload, loading: false };
    case oauthConstants.FRONTAPP_OAUTH_URL_FAILURE:
      return { ...state, error: action.error, loading: false };

    case oauthConstants.FRONTAPP_ACCESSTOKEN_REQUEST:
      return { ...state, loading: true };
    case oauthConstants.FRONTAPP_ACCESSTOKEN_SUCCESS:
      return {
        ...state,
        showInboxes: true,
        inboxList: action.payload.data ? action.payload.data.inboxes : [],
        messageDataSourceId: action.payload.data ? action.payload.data.id : '',
        webhookUrl: action.payload.data ? action.payload.data.webhookUrl : '',
        platformUrl: action.payload.data ? action.payload.data.platformUrl : '',
        loading: false,
      };
    case oauthConstants.FRONTAPP_ACCESSTOKEN_FAILURE:
      return { ...state, error: action.error, loading: false };

    case oauthConstants.FRONTAPP_INBOX_REQUEST:
      return { ...state, loading: true };
    case oauthConstants.FRONTAPP_INBOX_SUCCESS:
      return {
        ...state,
        showWebhookUrl: true,
        inboxList: [],
        messageDataSourceId: '',
        loading: false,
      };
    case oauthConstants.FRONTAPP_INBOX_FAILURE:
      return { ...state, error: action.error, loading: false };
    case oauthConstants.FRONTAPP_CLOSE_MODAL:
      return { ...state, showInboxes: false, showWebhookUrl: false, loading: false };
    case msgDataSourceConstants.GET_ALL_DATA_SOURCE:
      return {...state, loading: true};
    case msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS:
      return {
        ...state,
        list: action.payload ? action.payload.filter(item => [state.channel, 'front'].includes(item.dataSourceType)) : [],
        loading: false
      };
    case msgDataSourceConstants.ALL_DATA_SOURCE_ERROR:
      return {...state, error: action.error, loading: false};
    case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES:
      return {...state, loading: true};
    case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload.msgDataSourceId) {
            item[action.payload.updateField] = action.payload.enable
          }
          return item
        }),
        loading: false
      };
    case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_ERROR:
      return {...state, error: action.error, loading: false};
    case oauthConstants.CLOSE_WEBHOOK_MODAL:
      return {...state, showWebhookUrl: false};
    default:
      return state;
  }
}
