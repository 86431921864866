import React from 'react';
import {Button, Modal} from 'react-bootstrap'

const GenericModal = (props) => {
    const {
        payload,
        onSave,
        onHide,
        show,
        headerText,
        size,
        buttonText,
        hideFooter,
        isConversationThread,
        classes,
        className,
        enforceFocus,
        disabled
    } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            size={size ? size : 'md'}
            className={className ? className : `tms-modal-dialog ${isConversationThread ? "conversation-thread-modal": ""} ${classes ? `${classes}` : ""}  ` }
            enforceFocus={enforceFocus}

        >
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="container-fluid">
                {props.children}
            </Modal.Body>
            {!hideFooter &&
            <Modal.Footer>
                <Button bsStyle="success" onClick={() => onSave(payload)} disabled={disabled}>{buttonText}</Button>
                <Button onClick={onHide} className="btn btn-default">Cancel</Button>
            </Modal.Footer>}
        </Modal>);
};

export default GenericModal;
