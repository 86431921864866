import {Fragment} from "react";
import InternalNoteContent from "./InternalNoteContent";
import useViewNextMessage from "../../../../Utilities/hooks/useViewNextMessage";

export default function InternalNoteContainer({handleClose, handleOptionSubmitted}) {
    const {message} = useViewNextMessage();

    return(
        <Fragment>
            <InternalNoteContent
                message={message}
                handleClose={handleClose}
                handleOptionSubmitted={handleOptionSubmitted}
            />
        </Fragment>
    )
}

