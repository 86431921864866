import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from "@mui/material/SnackbarContent";

let _this;

const styles = {
    colorBg: {
        backgroundColor: '#63636d',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    icon: {
        fontSize: 20,
    },

    container: {
        minWidth: '200px',
        height: '50px',
        backgroundColor: '#144042',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        px: '20px',
    },
    message: {
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#FFFFFF',
        letterSpacing: '0.02em',
    }
};

class BasicSnackBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            snackbarOpen: false,
            snackbarMessage: '',
            autoHideDuration: 3000,
            actionButton: ''
        };
        _this = this;
    }

    snackbarClose = () => {
        this.setState({snackbarOpen: false});
    };

    static snackbarClose = () => {
        _this.snackbarClose();
    };

    static showMessage(message, autoHideDuration = undefined){
        _this.setState({
            snackbarOpen: true,
            snackbarMessage: message,
            autoHideDuration,
        })
    }


    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'left',}}
                    open={this.state.snackbarOpen}
                    onClose={this.snackbarClose}
                    ContentProps={{'aria-describedby': 'message-id'}}
                    autoHideDuration={this.state.autoHideDuration}
                    style={{top: "90px"}}
                >
                    <SnackbarContent
                        message={<span id="message-id" style={styles.message}>{this.state.snackbarMessage}</span>}
                        style={styles.container}
                        action={this.props.actionButton}
                    />
                </Snackbar>
            </div>
        );
    }
}
export { BasicSnackBar };
