import _ from "lodash";
import {tiktokShopActions} from "_actions";
import {getArrayFromObjectArray, isValidJson} from "_helpers";

export const tiktokShopMessageType = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    PRODUCT_CARD: 'PRODUCT_CARD',
    BUYER_ENTER_FROM_PRODUCT: 'BUYER_ENTER_FROM_PRODUCT',
    ORDER_CARD: 'ORDER_CARD',
    BUYER_ENTER_FROM_ORDER: 'BUYER_ENTER_FROM_ORDER',
};

export function getPlaceholder(text) {
    const placeHolder = (placeholderName) => `[${placeholderName}]`

    if(isValidJson(text)) {
        let typeObj = JSON.parse(text || '{}');
        const type = _.get(typeObj, 'type');
        switch (type) {
            case tiktokShopMessageType.PRODUCT_CARD:
            case tiktokShopMessageType.BUYER_ENTER_FROM_PRODUCT:
                return placeHolder('PRODUCT CARD');

            case tiktokShopMessageType.ORDER_CARD:
            case tiktokShopMessageType.BUYER_ENTER_FROM_ORDER:
                return placeHolder('ORDER CARD');

            case tiktokShopMessageType.IMAGE:
                return placeHolder( 'IMAGE');

            case tiktokShopMessageType.VIDEO:
                return placeHolder( 'VIDEO');

            default:
                return text;
        }
    }
    return text;
}

export function getTikTokShopMessageMetadata(dispatch, typeObj, msgDataSourceId) {
    const type = _.get(typeObj, 'type');
    switch (type) {
        case tiktokShopMessageType.PRODUCT_CARD:
        case tiktokShopMessageType.BUYER_ENTER_FROM_PRODUCT:
            return getTikTokShopProduct(dispatch, typeObj, msgDataSourceId);

        case tiktokShopMessageType.ORDER_CARD:
        case tiktokShopMessageType.BUYER_ENTER_FROM_ORDER:
            return getTikTokShopOrderDetails(dispatch, typeObj, msgDataSourceId);

        default:
            return;
    }
}

export function processTiktokShopPost(dispatch, conversation, message) {
    let { msgDataSource, author } = message;
    const msgDataSourceId = _.get(msgDataSource, 'id');
    const textArr = [...new Set(getArrayFromObjectArray(conversation, 'text'))] || [];
    textArr.map( text => {
        const typeObj = getTextType(text);
        if(!_.isEmpty(typeObj)) {
            getTikTokShopMessageMetadata(dispatch, typeObj, msgDataSourceId);
        }
    });
    const buyerUserId = resolveTikTokShopBuyerId(author);
    getTikTokShopOrders(dispatch, buyerUserId, msgDataSourceId);
}

function resolveTikTokShopBuyerId(author) {
    if(!author || (author && !author.includes("Buyer"))) {
        return null;
    }
    const buyerUserId = author.split(' ')[0];
    return buyerUserId.trim();
}

function getTextType(text) {
    if(!isValidJson(text)) {
        return;
    }
    return JSON.parse(text || '{}');
}

export function getTikTokShopOrders(dispatch, buyerUserId, msgDataSourceId) {
    if(!buyerUserId || !msgDataSourceId) {
        return;
    }
    dispatch(tiktokShopActions.getTikTokShopOrders({ buyerUserId, msgDataSourceId }));
}

function getTikTokShopProduct(dispatch, typeObj, msgDataSourceId) {
    const productId = _.get(typeObj, 'product_id');
    if(!productId || !msgDataSourceId) {
        return null;
    }
    dispatch(tiktokShopActions.getTikTokShopProduct({ productId, msgDataSourceId }));
}

function getTikTokShopOrderDetails(dispatch, typeObj, msgDataSourceId) {
    const orderId = _.get(typeObj, 'order_id');
    if(!orderId || !msgDataSourceId) {
        return null;
    }
    dispatch(tiktokShopActions.getTikTokShopOrderDetails({ orderId, msgDataSourceId }));
}