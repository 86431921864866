import {alertMessages, portalConstants} from "_constants";
import {portalService} from "_services";
import {SnackBar} from "../components/common";
import {alertActions} from "./alert.actions";
import {Auth} from "../components/Authorization";
import _ from "lodash";

/**
 * @author: Chris Okebata
 */


const getAll = () => {
  const request = () => ({
    type: portalConstants.GET_ALL_PORTALS_REQUEST
  });

  const success = (response) => ({
    type: portalConstants.GET_ALL_PORTALS_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: portalConstants.GET_ALL_PORTALS_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    portalService.getAll()
      .then(response => {
        dispatch(success(response.data));

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};

const getPortalForMessage = (messageId) => {
  const request = () => ({
    type: portalConstants.GET_PORTALS_FOR_MESSAGE_REQUEST
  });

  const success = (response) => ({
    type: portalConstants.GET_PORTALS_FOR_MESSAGE_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: portalConstants.GET_PORTALS_FOR_MESSAGE_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    portalService.getPortalForMessage(messageId)
      .then(response => {
        dispatch(success(response.data));

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};

const getOrganizationPortal = (organizationId) => {
  const request = () => ({
    type: portalConstants.GET_ORG_PORTAL_REQUEST
  });

  const success = (response) => ({
    type: portalConstants.GET_ORG_PORTAL_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: portalConstants.GET_ORG_PORTAL_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    portalService.getOrganizationPortal(organizationId)
      .then(response => {
        dispatch(success(response.data));

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};

const getAllIntegratedPortals = () => {
  const request = () => ({
    type: portalConstants.GET_ALL_INTEGRATED_PORTALS_REQUEST
  });

  const success = (response) => ({
    type: portalConstants.GET_ALL_INTEGRATED_PORTALS_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: portalConstants.GET_ALL_INTEGRATED_PORTALS_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    portalService.getAllIntegratedPortals()
      .then(response => {
        dispatch(success(response.data));

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};

const savePortal = (params) => {
  const request = () => ({
    type: portalConstants.POST_PORTAL_REQUEST
  });

  const success = (response) => ({
    type: portalConstants.POST_PORTAL_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: portalConstants.POST_PORTAL_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    portalService.savePortal(params)
      .then(response => {
        dispatch(success(response.data));
        dispatch(alertActions.clear());
        SnackBar.showMessage(alertMessages.CREATE_PORTAL_SUCCESS)

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      dispatch(alertActions.clear());
      SnackBar.showMessage(alertMessages.CREATE_PORTAL_ERROR)
    });
  }
};

const editPortal = (id, params) => {
  const request = () => ({
    type: portalConstants.PUT_PORTAL_REQUEST
  });

  const success = (response) => ({
    type: portalConstants.PUT_PORTAL_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: portalConstants.PUT_PORTAL_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    portalService.editPortal(id, params)
      .then(response => {
        dispatch(success(response.data));
        dispatch(alertActions.clear());
        SnackBar.showMessage(alertMessages.EDIT_PORTAL_SUCCESS)

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      dispatch(alertActions.clear());
      SnackBar.showMessage(alertMessages.EDIT_PORTAL_ERROR)
    });
  }
};

const deletePortal = (id) => {
  const request = () => ({
    type: portalConstants.DELETE_PORTAL_REQUEST
  });

  const success = (response) => ({
    type: portalConstants.DELETE_PORTAL_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: portalConstants.DELETE_PORTAL_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    portalService.deletePortal(id)
      .then(response => {
        dispatch(success(response.data));
        dispatch(alertActions.clear());
        SnackBar.showMessage(alertMessages.DELETE_PORTAL_SUCCESS)

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.DELETE_PORTAL_ERROR)
    });
  }
};

const openPortal = (endpoint, payload) => {
  const request = () => ({
    type: portalConstants.OPEN_PORTAL_REQUEST
  });

  const success = (response) => ({
    type: portalConstants.OPEN_PORTAL_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: portalConstants.OPEN_PORTAL_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    portalService.openPortal(endpoint, payload)
      .then(response => {
        dispatch(success(response.data));

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR)
    });
  }
};


export const portalActions = {
  getAll,
  getPortalForMessage,
  getOrganizationPortal,
  getAllIntegratedPortals,
  savePortal,
  editPortal,
  deletePortal,
  openPortal,
};