
export class BillingInfoBuilder {
    constructor() {
        this.billingInfo = {};
    }

    setId(id){
        this.billingInfo.id = id;
        return this;
    }

    setProduct(product){
        this.billingInfo.product = product;
        return this;
    }

    setTicketBudget(ticketBudget){
        this.billingInfo.ticketBudget = ticketBudget;
        return this;
    }

    setUsedTickets(usedTickets){
        this.billingInfo.usedTickets = usedTickets;
        return this;
    }

    setActions(actions) {
        this.billingInfo.actions = actions;
        return this;
    }

    build(){
        return this.billingInfo;
    }
}