import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

let _this;

const styles = {
  colorBg: {
    backgroundColor: '#63636d',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  icon: {
    fontSize: 20,
  },
};

class SnackBar extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      autoHideDuration: 3000
    };

    _this = this;
  }

  snackbarClose = () => {
    this.setState({snackbarOpen: false});
  };

  static showMessage(message, autoHideDuration = 3000){
    _this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      autoHideDuration: autoHideDuration
    })
  }

  render() {

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center',}}
          open={this.state.snackbarOpen}
          onClose={this.snackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          autoHideDuration={this.state.autoHideDuration}
          style={{top: "10px", }}
        >
          <SnackbarContent
            message={<span id="message-id" style={{fontSize: "15px", color: '#ffffff'}}>{this.state.snackbarMessage}</span>}
            style={styles.colorBg}

            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={this.snackbarClose}>
                <CloseIcon style={styles.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    );
  }
}

export { SnackBar };
