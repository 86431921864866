/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 13/09/2018 : 11:11 PM
 */

import { statsConstants } from '_constants';

const INITIAL_STATE = {
  data: [],
  analytics: {},
  loading: true,
  error: '',
};

export function statsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case statsConstants.GET_DATA_REQUEST:
      return { ...state, loading: true };
    case statsConstants.GET_DATA_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case statsConstants.GET_DATA_FAILURE:
      return { ...state, error: action.error, loading: false };

    case statsConstants.GET_ANALYTICS_DATA_REQUEST:
      return { ...state, loading: true, error: '' };
    case statsConstants.GET_ANALYTICS_DATA_SUCCESS:
      return { ...state, analytics: action.payload, loading: false };
    case statsConstants.GET_ANALYTICS_DATA_FAILURE:
      return {
        ...state, messages: action.payload, error: action.error, loading: false,
      };

    default:
      return state;
  }
}
