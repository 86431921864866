import {SERVER_URL} from 'config';
import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';

function verifyApiKey(params) {
    return fetch(`${SERVER_URL}/api/gorgias/apiKey?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function gorgiasSupportEmails(data) {
    return fetch(`${SERVER_URL}/api/gorgiasDataSource/supportEmails?ids=${data}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function getAutoSenders(data) {
    return fetch(`${SERVER_URL}/api/msgDataSource/autoSenders?ids=${data}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function saveAutoSenders(data) {
    return fetch(`${SERVER_URL}/api/msgDataSource/autoSenders`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

function deleteAutoSenderData(data) {
    return fetch(`${SERVER_URL}/api/msgDataSource/autoSenders`,
        {
            method: 'DELETE',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}



function initiateOauth(params) {
  return fetch(`${SERVER_URL}/api/gorgias/initiateOauth?${objectToQueryString(params)}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

function genAccessToken(params) {
  return fetch(`${SERVER_URL}/api/gorgias/genAccessToken?${objectToQueryString(params)}`,
    {
      method: 'POST',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}


export const gorgiasAuthService = {
    verifyApiKey,
    gorgiasSupportEmails,
    getAutoSenders,
    saveAutoSenders,
    deleteAutoSenderData,
    initiateOauth,
    genAccessToken
};