import React, {useRef, useState} from "react";
import {Box, Grid, IconButton, Switch} from "@mui/material";
import BasicFormTextField from "../../../common/BasicFormTextField";
import StatusSelect from "../../../common/StatusSelect";
import {Field, reduxForm, change} from 'redux-form'
import {isValidDate, parseDates, required} from "_helpers";
import {useDispatch, useSelector} from "react-redux";
import BasicMultilineTextField from "../../../common/BasicMultilineTextField";
import TemplateSelect from "../../../common/TemplateSelect";
import AnchorButton from "../../../common/AnchorButton";
import ActionButton from "../../../common/ActionButton";
import SingleDatePicker from "../../../common/SingleDatePicker";
import moment from "moment";
import {SnackBar} from "../../../../common";
import {proactiveActions} from "_actions";
import _ from "lodash";
import {
    arrayToCommaSeparatedValues, buildCSVFormData,
    buildUpdateCampaignPayload, verifyCsvFileSize,
} from "../Utilities/CampaignUtils";
import { connect } from 'react-redux'
import useGetCampaign from "../Utilities/hooks/useGetCampaign";
import CircularProgress from "@mui/material/CircularProgress";
import CampaignAttribute from "../Utilities/CampaignAttribute/CampaignAttribute";
import {
    segmentTypes,
    supportedChannelsForAttributes,
    supportedChannelsForDateFilter,
    supportedChannelsForSegmentUpdate
} from "_constants";
import CampaignSegment from "../Utilities/CampaignSegment";
import CampaignDateFilter from "../Utilities/CampaignDateFilter";
import CampaignSegmentTable from "../Utilities/CampaignSegmentTable";
import ClearIcon from "@mui/icons-material/Clear";
import CsvUploadsTable from "../Utilities/CsvUploadsTable";
import useGetSegmentTypes from "../Utilities/hooks/useGetSegmentTypes";

function UpdateCampaignForm({ handleSubmit }) {
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const selectPlaceholder = 'Choose';
    const csvFileRef = useRef(null)

    const { campaignObj, loading } = useGetCampaign();
    const { segmentTypes: channelSegments } = useGetSegmentTypes(campaignObj.channel);
    const isAttributeSupported = supportedChannelsForAttributes.includes(campaignObj.channel);
    const isDateFilterSupported = supportedChannelsForDateFilter.includes(campaignObj.channel);
    const isSegmentUpdateSupported = supportedChannelsForSegmentUpdate.includes(campaignObj.channel);
    const isCsvUploadSupported = channelSegments && channelSegments?.find((item) => item.id === segmentTypes.customerDataFile)
    const [csvFile, setCsvFile] = useState("");
    const [csvFileName, setCsvFileName] = useState("");
    const [isFollowUpEnabled, setIsFollowUpEnabled] = useState();
    const [isPresaleEnabled, setIsPresaleEnabled] = useState();

    const effectiveIsFollowUpEnabled = isFollowUpEnabled === undefined ? campaignObj.isFollowUpEnabled : isFollowUpEnabled;
    const effectiveIsPresaleEnabled = isPresaleEnabled === undefined ? campaignObj.isPresaleEnabled : isPresaleEnabled;

    const handleTemplateSelectChange = (value) => {
        dispatch(change('UpdateCampaignForm','templateId', value));
    }

    const proactive = useSelector(state => state.proactive);
    const loadingUpdateCampaign = proactive.loadingUpdateCampaign;
    const uploadingCustomerData = proactive.uploadingCustomerData;
    const channel = _.get(proactive, 'campaign.channel');
    const organizationId = _.get(proactive, 'campaign.organizationId');

    const handleUpdateCampaign = (values) => {
        const parsedDate = parseDates(startDate, endDate);
        if (!isValidDate(parsedDate.startDate, parsedDate.endDate)) {
            SnackBar.showMessage("End date cannot be earlier than start date");
            return;
        }
        if (!_.get(campaignObj, 'isStartDateEditable')) {
            delete parsedDate['startDate'];
        }
        if (!_.get(campaignObj, 'isEndDateEditable')) {
            delete parsedDate['endDate'];
        }
        if(_.isEmpty(values)) return;
        const id = _.get(campaignObj, 'id');
        if(csvFile) {
            const formData = buildCSVFormData(csvFile)
            dispatch(proactiveActions.uploadCustomerDataFile(formData, values, parsedDate, id, channel))
        } else {
            const payload = buildUpdateCampaignPayload(id, values, parsedDate, channel);
            dispatch(proactiveActions.updateCampaign(payload));
        }
    };

    const handleSegmentSelectChange = (value) => {
        dispatch(change('UpdateCampaignForm','segments', value));
    }

    const handleStartDate = (date) => {
        setStartDate(date);
    }
    const handleEndDate = (date) => {
        setEndDate(date)
    }

    const handleDateFilterChange = (value) => {
        dispatch(change('UpdateCampaignForm', 'dateFilter', value))
    }

    const handleIsFollowUpEnabled = (event) => {
        const value = event.target.checked;
        setIsFollowUpEnabled(value);
        dispatch(change('CreateCampaignForm', 'isFollowUpEnabled', value));
    }

    const handleIsPresaleEnabled = (event) => {
        const value = event.target.checked;
        setIsPresaleEnabled(value);
        dispatch(change('CreateCampaignForm', 'isPresaleEnabled', value));
    }

    const handleFollowUpTemplate = (value) => {
        dispatch(change('CreateCampaignForm', 'followUpTemplate', value));
    }

    const handleBrowseClick = () => {
        csvFileRef?.current?.click();
    }

    const handleCsvUpload = (e) => {
        const value = e.target.files[0]
        if(verifyCsvFileSize(value)){
            setCsvFileName(value.name)
            setCsvFile(value)
        }
    }

    const handleRemoveCsv = () => {
        setCsvFile("")
        setCsvFileName("");
    }

    const isEmailChannel = channel === 'SHOPIFY' || channel === 'KUSTOMER_EMAIL';
    const isFollowUpSupported = isEmailChannel;
    const isPresaleSupported = channel === 'SHOPIFY';

    const renderSwitch = ({ input }) => {
        return (<Switch
            checked={input.value ? true : false}
            onChange={input.onChange}
            sx={styles.switch} />);
    };

    if(loading && _.isEmpty(campaignObj)){
        return (
            <Box m={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}>
                <CircularProgress size={25} sx={{ color: '#07857C', marginLeft: '10px' }}/>
            </Box>
        )
    }
    if(!loading && _.isEmpty(campaignObj)){
        return (
            <Box m={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}>
                <Box sx={styles.desc}>No data found</Box>
            </Box>
        )
    }

    return(
        <>
            <form name="form" onSubmit={handleSubmit(handleUpdateCampaign)} autoComplete="off">
                <Box sx={{padding: '25px 20px 60px 20px'}}>
                    <Grid container item mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Grid item xs={12} md={7} sx={{}}>
                            <Box mr={{md: '20px', lg: '20px'}}>
                                <Box sx={styles.inputDesc}>Campaign name</Box>
                                <Field
                                    name="name"
                                    component={BasicFormTextField}
                                    placeholder={'Enter name'}
                                    validate={required}
                                />
                            </Box>
                        </Grid>
                        <Grid container item xs={12} md={5}>
                            <Grid item xs={12} md={6}>
                                <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>Start</Box>
                                    <SingleDatePicker
                                        handleChange={handleStartDate}
                                        defaultDate={_.get(campaignObj, 'startDate')}
                                        withIcon
                                        enabled={_.get(campaignObj, 'isStartDateEditable')}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box ml={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                    <Box sx={styles.inputDesc}>End</Box>
                                    <SingleDatePicker
                                        handleChange={handleEndDate}
                                        defaultDate={_.get(campaignObj, 'endDate')}
                                        withIcon
                                        enabled={_.get(campaignObj, 'isEndDateEditable')}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Grid item xs={12} md={7}>
                            <Box mr={{md: '20px', lg: '20px'}}>
                                <Box sx={styles.inputDesc}>Description/topic</Box>
                                <Field
                                    name="description"
                                    component={BasicMultilineTextField}
                                    placeholder={'Enter topic'}
                                    validate={required}
                                />
                            </Box>
                        </Grid>
                        <Grid container item xs={12} md={5} sx={{}}>
                            <Grid item xs={12} md={6}>
                                <Box mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}
                                     mr={{xs: '0', sm: '0', md: '10px', lg: '10px'}}>
                                    <Box sx={styles.inputDesc}>Status</Box>
                                    <Field
                                        name="isActive"
                                        component={StatusSelect}
                                        placeholder={selectPlaceholder}
                                        defaultValue={_.get(campaignObj, 'isActive')}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    { isAttributeSupported && <CampaignAttribute /> }
                    { isDateFilterSupported && <CampaignDateFilter handleDateFilterChange={handleDateFilterChange} defaultDate={_.get(campaignObj, 'dateFilter')} />}

                    <Grid container item xs={12} md={12} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Grid item xs={12} md={7} pr={{md: '10px', lg: '10px'}}>
                            <Box mr={{md: '10px', lg: '10px'}} mt={{md: '0', lg: '0'}}>
                                <Box sx={styles.inputDesc}>Message template</Box>
                                <Field
                                    name="templateId"
                                    component={TemplateSelect}
                                    placeholder={selectPlaceholder}
                                    defaultValue={_.get(campaignObj, 'templateId')}
                                    queryParam={{
                                        organizationId: _.get(campaignObj, 'organizationId'),
                                        channel: _.get(campaignObj, 'channel')
                                    }}
                                    handleFormSelectChange={handleTemplateSelectChange}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={5} mr={{md: '0px', lg: '0px'}}>
                            <Box mr={{md: '0px', lg: '0px'}} mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                                <Box sx={styles.inputDesc}>Days between same user replies</Box>
                                <Field
                                    name="minDaysBetweenSameUserReplies"
                                    component={BasicFormTextField}
                                    placeholder={'Enter the number of days'}
                                    validate={required}
                                    props={{ type: 'number', min: 0 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <CampaignSegment
                        channel={channel}
                        queryParam={{channel, organizationId}}
                        handleSegmentSelectChange={handleSegmentSelectChange}
                    />

                    {
                        isSegmentUpdateSupported &&
                        <Grid container item xs={12} md={7}>
                            <Grid item xs={12} md={12}
                                  mr={{md: '20px', lg: '20px'}}
                                  mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                <CampaignSegmentTable />
                            </Grid>
                        </Grid>
                    }

                    <Grid container item xs={12} md={7}>
                        <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                            <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                <Box sx={styles.inputDesc}>Blocked accounts &nbsp;<em>(optional)</em></Box>
                                <Field
                                    name="blockedAccounts"
                                    component={BasicFormTextField}
                                    placeholder={'Enter comma-separated accounts'}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} md={7} mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                            <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                <Box sx={styles.inputDesc}>Blocked keywords &nbsp;<em>(optional)</em></Box>
                                <Field
                                    name="blockedKeywords"
                                    component={BasicFormTextField}
                                    placeholder={'Enter comma-separated keywords'}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    { isCsvUploadSupported &&
                        <Grid container item xs={12} md={7}>
                            <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                                <Box mr={{md: '10px', lg: '10px'}}>
                                    <CsvUploadsTable segments={campaignObj.segments} />
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    { isCsvUploadSupported &&
                        <Grid container item  mt={{xs: '15px', sm: '15px', md: '22px', lg: '22px'}}>
                            <Grid item xs={12} md={7}>
                                <Box sx={styles.uploadContainer}>
                                    <input type="file" ref={csvFileRef} onChange={handleCsvUpload} id="csvUpload" accept=".csv" style={{display: "none"}}/>
                                    <Box sx={{minWidth: 'fit-content'}}>
                                        <ActionButton
                                            label={'Upload CSV'}
                                            type={'button'}
                                            action={handleBrowseClick}
                                        />
                                    </Box>
                                    {csvFileName &&
                                        <>
                                            <Box sx={{...styles.inputDesc, mb: 0}} className="exp-dashboard-truncate-1-line">
                                                {csvFileName}
                                            </Box>
                                            <IconButton sx={{m: '5px'}} onClick={handleRemoveCsv}>
                                                <ClearIcon sx={styles.clearIcon} />
                                            </IconButton>
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    { isFollowUpSupported && (<Grid container item xs={12} md={7}>
                            <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                                <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                    <Box sx={styles.inputDesc}>
                                        Automatic Follow Up&nbsp;
                                        <Field
                                            name="isFollowUpEnabled"
                                            component={renderSwitch}
                                            defaultValue={campaignObj.isFollowUpEnabled}
                                            onChange={handleIsFollowUpEnabled} />
                                    </Box>
                                    { effectiveIsFollowUpEnabled && (
                                        <Field
                                            name="followUpTemplate"
                                            component={BasicMultilineTextField}
                                            placeholder={'Enter the follow up template'}
                                            defaultValue={campaignObj.followUpTemplate}
                                            onChange={handleFollowUpTemplate} />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>)
                    }
                    { isPresaleSupported && (<Grid container item xs={12} md={7}>
                        <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                            <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                                <Box sx={styles.inputDesc}>
                                    Presale&nbsp;
                                    <Field
                                        name="isPresaleEnabled"
                                        component={renderSwitch}
                                        defaultValue={campaignObj.isPresaleEnabled}
                                        onChange={handleIsPresaleEnabled} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>)
                    }
                </Box>
                <Box sx={styles.footer}>
                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Save'}
                            disabled={loadingUpdateCampaign || uploadingCustomerData}
                        />
                    </Box>
                    <Box sx={{width: '100px', ml: '18px', height: '20px'}}>
                        <AnchorButton
                            label={'Cancel'}
                            link={'/admin/campaign'}
                            buttonBordered={true}
                        />
                    </Box>
                </Box>
            </form>
        </>
    )
}

const styles = {
    hero: {
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        mb: { xs: '24px', sm: '40px', },
    },
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    desc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '16px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    footer: {
        borderTop: '1px solid #CED4D4',
        padding: '12px 20px',
        display: 'flex'
    },
    uploadContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    clearIcon: {
        fontSize: '16px!important',
        flexShrink: 0,
        color: '#144042',
        pointerEvents: 'none',
    },
    switch: {
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: '#368c86'
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: '#368C86'
        },
        "&:hover .MuiSwitch-switchBase": {
            color: '#368c86'
        },
    }
}

function validate(values) {
    const errors = {};
    const {isActive, templateId } = values;
    if(_.isEmpty(_.toString(isActive)) || isActive === 'none') errors.isActive = 'invalid status';
    if(_.isEmpty(_.toString(templateId)) || templateId === 'none') errors.templateId = 'invalid template';
    return errors;
}

const formConfig = {
    form: 'UpdateCampaignForm',
    enableReinitialize: true,
    validate
};

UpdateCampaignForm = reduxForm(formConfig)(UpdateCampaignForm)
UpdateCampaignForm = connect(state => (
    {
        initialValues: {
            name: _.get(state, 'proactive.campaign.name'),
            description: _.get(state, 'proactive.campaign.description'),
            isActive: _.get(state, 'proactive.campaign.isActive'),
            minDaysBetweenSameUserReplies: _.toString(_.get(state, 'proactive.campaign.minDaysBetweenSameUserReplies')),
            blockedAccounts: arrayToCommaSeparatedValues(_.get(state, 'proactive.campaign.blockedAccounts')),
            blockedKeywords: arrayToCommaSeparatedValues(_.get(state, 'proactive.campaign.blockedKeywords')),
            isFollowUpEnabled: _.get(state, 'proactive.campaign.isFollowUpEnabled'),
            isPresaleEnabled: _.get(state, 'proactive.campaign.isPresaleEnabled'),
            followUpTemplate: _.get(state, 'proactive.campaign.followUpTemplate'),
        }
    }),
)(UpdateCampaignForm);
export default UpdateCampaignForm;