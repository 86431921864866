import React, {Fragment, useEffect, useState} from 'react'
import {Box, InputAdornment, TextField} from '@mui/material';
import {copyValueToClipboard} from '_actions'
import BasicModal from "components/v2/views/Onboarding/Utilities/BasicModal";
import basicModalStyles from "components/v2/views/Channels/ConnectChannels/Utilities/basicModalStyles";
import {useDispatch, useSelector} from 'react-redux';
import {IconButton} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {applicationsActions} from "../../../../../_actions/applications.actions";
import {Loading} from "../../../../common";

export const useApplicationDetailsModal = () => {
    const [appDetailsOpen, setAppDetailsOpen] = useState(false);
    const [applicationId, setApplicationId] = useState(null);

    const handleClickApplicationDetails = ({applicationId}) => {
        setApplicationId(applicationId);
        setAppDetailsOpen(true);
    }

    const handleCloseAppDetails = () => {
        setApplicationId(null);
        setAppDetailsOpen(false);
    }

    return {
        appDetailsOpen, applicationId, handleClickApplicationDetails, handleCloseAppDetails
    }
}

export function ApplicationDetailsModal({
                                            appDetailsOpen, applicationId, handleCloseAppDetails
                                        }) {

    const applications = useSelector((state) => state.applications);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(applicationsActions.clear())
        if (appDetailsOpen && applicationId) {
            dispatch(applicationsActions.getApplicationDetails(applicationId))
        }
    }, [appDetailsOpen])

    return (
        <Fragment>
            <BasicModal
                open={appDetailsOpen}
                handleClose={handleCloseAppDetails}
                title={`Application Details`}>
                <Box sx={basicModalStyles.container}>
                    {applications && applications.loading && <Loading height={100}/> }
                    {applications && applications.error &&
                        <Box sx={basicModalStyles.description}>
                            Error occurred when fetching application details.
                        </Box>
                    }
                    {applications && applications.applicationDetails && (
                        <Box>
                            <Box sx={basicModalStyles.description}>
                                Use these credentials to complete installation of Chatdesk application on your instance
                            </Box>
                            <ApplicationPropertyField fieldValue={applications.applicationDetails.name} label={"Application Name"}/>
                            <ApplicationPropertyField fieldValue={applications.applicationDetails.clientId} label={"Application Client Id"}
                                                      withClipboardButton={true}/>
                            <ApplicationPropertyField fieldValue={applications.applicationDetails.clientSecret} label={"Application Client Secret"}
                                                      withClipboardButton={true}/>
                        </Box>)}
                </Box>
            </BasicModal>
        </Fragment>
    )
}

function ApplicationPropertyField({fieldValue, label, withClipboardButton = false}) {
    return (
        <Box sx={styles.formSection}>
            <Box sx={{width: '100%'}}>
                <TextField sx={styles.textField}
                           InputProps={{
                               sx: styles.textField,
                               endAdornment:
                                   (withClipboardButton && (
                                           <InputAdornment position="end">
                                               <IconButton onClick={() => copyValueToClipboard(fieldValue)} edge="end">
                                                   <FileCopyIcon/>
                                               </IconButton>
                                           </InputAdornment>)
                                   ),
                           }}
                           type='text'
                           label={label}
                           value={fieldValue}
                           variant="outlined"
                           readOnly={true}/>
            </Box>
        </Box>
    )
}

const styles = {
    textField: {
        width: '100%',
        fontSize: '16px !important',
        color: '#0C2728',
        [`& fieldset`]: {
            border: '1px solid #0C2728', borderRadius: '10px',
        },
        [`& label`]: {
            fontSize: '16px',
        },
        [`& .MuiInputAdornment-root p`]: {
            fontSize: '16px',
        },
    },
    formSection: {
        display: 'flex',
        marginTop: '20px',
        alignItems: 'center',
    },
    loadingContainer: {
        maxHeight: 100
    }
}