export class CustomerIoSegmentBuilder {
    constructor() {
        this.customerIoSegment = {};
    }

    setId(id){
        this.customerIoSegment.id = id;
        return this;
    }

    setName(name){
        this.customerIoSegment.name = name;
        return this;
    }

    build(){
        return this.customerIoSegment;
    }
}
