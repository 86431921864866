import moment from "moment";
import React from "react";

const CsatComponent = ({ unifiedScore, csatDate, mmdComment, timezone }) => {

    return (
        <div className=" conversation-rating" >
            <div className="ratings-fullName">
                {`CSAT Rating  -  ${unifiedScore}%`}
            </div>
            <div className="ratings-dateTime">
                {moment(csatDate).fromNow()}
                {` (${moment(csatDate).format('MMMM Do YYYY HH:mm')} ${timezone})`}
            </div>
            <div className="metricsDetail-rating">
                {mmdComment &&
                    <span className="ratings-text">
                        {`"${mmdComment}"`}
                    </span>
                }
            </div>
        </div>
    )
}

export default CsatComponent