export class ChannelMetricsBuilder {
  constructor() {
    this.metrics = {};
  }

  setChannel(channel){
    this.metrics.channel = channel;
    return this;
  }

  setSubChannels(subChannels){
    this.metrics.subChannels = subChannels;
    return this;
  }

  setLabel(label){
    this.metrics.label = label;
    return this;
  }

  setVolumeByUs(volumeByUs){
    this.metrics.volumeByUs = volumeByUs;
    return this;
  }

  build(){
    return this.metrics;
  }
}
