import {Fragment} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import ScheduleAccordionItem from "./ScheduleAccordionItem";

export default function SchedulesContainer({
    organizationId,
    schedules,
    loadingSchedules,
    payoutRates,
    loadingPayoutRates,
    pricingRates,
    loadingPricingRates,
    mode
}) {

    return(
        <Fragment>
            {!loadingSchedules && !_.isEmpty(schedules) &&
                <Box sx={{}}>
                    {Object.entries(schedules).map(([key, value]) => {
                        return(
                            <ScheduleAccordionItem
                                key={key}
                                handleName={key}
                                schedules={value}
                                payoutRates={payoutRates}
                                pricingRates={pricingRates}
                                organizationId={organizationId}
                                mode={mode}
                            />
                        )})}
                </Box>
            }
        </Fragment>
    )
}