import {getTimeZone} from "_helpers";
import moment from "moment";

export const proactiveCriteriaOptions = [
    {
        "name": "all_messages",
        "value": "All messages"
    },
    {
        "name": "replied",
        "value": "Replied"
    },
    {
        "name": "liked",
        "value": "Liked"
    },
    {
        "name": "noEngagementYet",
        "value": "No Engagement Yet"
    },
    {
        "name": "followed",
        "value": "Followed"
    },
    {
        "name": "converted",
        "value": "Converted"
    },
    {
        "name": "followUpSent",
        "value": "Follow Up Sent"
    },
    {
        "name": "convertedAfterFollowUpSent",
        "value": "Converted After Follow Up"
    },
]

export function formatDateCreated(date, format) {
    const timezone = getTimeZone();
    return `${moment.tz(date, timezone).format(format)}`
}
