import React from "react";
import moment from 'moment/moment';
import { personalizeResponse } from '_helpers';
import htmlParser from "he";
import _ from "lodash";


const ResponseRow = (response, agent, agentNickname) => {
    const agentName = agentNickname || _.get(agent, 'firstName');

    const { message } = response;

    const dateCreated =  response.dispatchedAt || response.dateCreated;
    const text = htmlParser.decode(personalizeResponse(response.editedText || response.text, message.author, agentName, null));

    return (
        <div className="author-conversation" key={response.id ? response.id : Math.random()}>
            <div className="conversation-header">
                <div className="author-name-conversation font-13-bold color-grey-mid">
                    {agentName}
                </div>
                <div className="date-conversation font-13 color-grey-mid">
                    {moment.utc(dateCreated).format('MMM D')}
                    {' '}
                    {moment.utc(dateCreated).format('h:mm a')}
                </div>
            </div>
            <span className="color-black-fade font-15">
                <span>{text} </span>
            </span>
        </div>
    );
};

export default ResponseRow;
