import {getTimeZone} from "_helpers";
import moment from "moment";

export function formatInitiatedDate(dateCreated) {
    const timezone = getTimeZone();
    return `${moment.tz(dateCreated, timezone).format('MMMM D, YYYY h:mm a')}`
}

export function formatArrivalDate(dateCreated) {
    const timezone = getTimeZone();
    return `${moment.tz(dateCreated, timezone).format('MMMM D, YYYY')}`;
}

export const calculateProgress = (currentPosition, totalPosition) => {
    return ((currentPosition) / totalPosition) * 100;
};