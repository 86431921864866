export class OrganizationBuilder {
    constructor() {
        this.organization = {};
    }

    setId(id){
        this.organization.id = id;
        return this;
    }

    setName(name){
        this.organization.name = name;
        return this;
    }

    build(){
        return this.organization;
    }
}
