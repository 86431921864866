import {handleHttpResponse, history} from "_helpers";
import {instagramOauthService, facebookOauthService} from "_services";
import {
    twitterOauthActions,
    frontAppOauthActions,
    helpScoutOauthActions,
    tiktokOauthActions,
    shopifyOauthActions
} from "_actions";
import {TRENDS_SERVER_URL, CLIENT_URL, SERVER_URL} from 'config';


export const utilityService = {
    getVerticals,
    addChannel,
    redirectToAuthUrl,
    getLanguages
};


function getVerticals() {

    return fetch(`${SERVER_URL}/api/verticals`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    }).then(handleHttpResponse)
}

function getLanguages() {

    return fetch(`${SERVER_URL}/api/languages`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    }).then(handleHttpResponse)
}


function addChannel(props, source) {
    switch (source) {

        case 'facebook':
            facebookOauthService.fbLogin();
            break;

        case 'instagram':
            instagramOauthService.fbLogin();
            break;

        case 'twitter':
            generateAuthUrl(props);
            break;

        case 'zendesk':
            zendeskRedirect();
            break;

        case 'frontapp':
            frontAppLogin(props);
            break;

        case 'helpscout':
            helpScoutRedirect(props);
            break;

        case 'tiktok':
            tiktokRedirect(props);
            break;
        case 'shopify':
            shopifyRedirect(props)
            break;

        default:
            break
    }

    function generateAuthUrl(props) {

        const callbackUrl = window.location;
        props.dispatch(twitterOauthActions.getOauthUrl(callbackUrl));
    }

    function zendeskRedirect() {
        history.push('/company/zendeskConnect')

    }

    function frontAppLogin(props) {
        const callbackUrl = `${CLIENT_URL}/frontapp/accounts`;
        props.dispatch(frontAppOauthActions.initiateOauth(callbackUrl));
    }

    function helpScoutRedirect(props) {
        const callbackUrl = `${CLIENT_URL}/helpscout/accounts`;
        props.dispatch(helpScoutOauthActions.initiateOauth(callbackUrl));
    }

    function tiktokRedirect(props) {
        const callbackUrl = `${CLIENT_URL}/tiktok/accounts`;
        props.dispatch(tiktokOauthActions.initiateOauth(callbackUrl));
    }

    function shopifyRedirect(props) {
        const callbackUrl = `${CLIENT_URL}/shopify/accounts`;
        props.dispatch(shopifyOauthActions.initiateOauth(callbackUrl));
    }

}

function redirectToAuthUrl(authUrl) {
    window.open(authUrl)
}
