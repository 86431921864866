import React, { Component, Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import { PlatformMacroModal } from 'components/MsgDataSources'

/**
 * @author Offiong Aniekan <ekanoffiong@gmail.com>
 * @date 13/10/2022 : 11:07 AM
 */
const statusList = [
    "Open", "Pending", "Solved",
];

class ListZendeskDataSources extends Component {

    state = {
        showStatusOnReplySelection: false,
        currentStatus: '',
        dataSourceId: null,
        showPlatformMacros: false,
        macroDataSourceId: null
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAllDataSources({dataSource: 'Zendesk'}));
    }

    toggleClosedTicketActioning = (postData) => {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.toggleClosedTicketActioning({...postData, msgDataSourceLabel: 'Zendesk'}));
    };

    toggleStatusOnReplySelection = (dataSource) => {
        this.setState({
            showStatusOnReplySelection: true,
            dataSourceId: dataSource.id,
            currentStatus: dataSource.ticketStatusOnReply || ''
        });
    };

    statusChange = (e) => {
        this.setState({
            currentStatus: e.target.value
        });
    };

    saveStatusOnReply = () => {
        const { dataSourceId, currentStatus } = this.state;
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.saveStatusOnReply({currentStatus, dataSourceId}));
        this.resetState()
    };

    resetState = () => {
        this.setState({
            showStatusOnReplySelection: false,
            currentStatus: '',
            dataSourceId: null
        })
    };

    viewPlatformMacros = (dataSourceId) => {
        this.setState({
            macroDataSourceId: dataSourceId,
            showPlatformMacros: true
        });
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getSavedPlatformMacros({ msgDataSourceId: dataSourceId }))
    };

    render() {
        const { zendeskList, loading } = this.props;
        const { showStatusOnReplySelection, dataSourceId, currentStatus, showPlatformMacros, macroDataSourceId } = this.state;
        const currentZendeskDataSource = zendeskList.find(item => item.id === macroDataSourceId);
        const platformMacroEnabled = currentZendeskDataSource && currentZendeskDataSource.isPlatformMacroEnabled
        if (loading) {
            return <Loading/>
        }
        return (
            <div>
                <Row>
                    <Col xs={10} md={10}>
                        <table className='table trds-table'>
                            <thead className="font-18-bold">
                            <tr>
                                <th className="col-md-1">S/N</th>
                                <th>Organization</th>
                                <th>Ticket Status On Reply</th>
                                <th>Zendesk Macros</th>
                                <th className="col-md-4">Close Ticket - No Response Needed</th>
                            </tr>
                            </thead>
                            <tbody>
                            {zendeskList.map((dataSource, index) => (
                                <tr key={index}>
                                    <td className="vertical-align-middle">{index + 1}</td>
                                    <td className="vertical-align-middle">{dataSource.orgName} {dataSource.type ? `(${dataSource.type})` : ''}</td>
                                    <td>
                                        <div>
                                            {dataSourceId !== dataSource.id &&
                                                <Fragment>
                                                    <b>{dataSource.ticketStatusOnReply ? dataSource.ticketStatusOnReply : 'Not Set'}</b>
                                                    <Button className="btn-info margin-top-5 btn-sm display-block" onClick={() => this.toggleStatusOnReplySelection(dataSource)}>
                                                        Update
                                                    </Button>
                                                </Fragment>
                                            }
                                        </div>
                                        {showStatusOnReplySelection && dataSourceId === dataSource.id &&
                                            <Fragment>
                                                <select className="form-control form-control-inline" value={currentStatus} onChange={this.statusChange}>
                                                    <option hidden value="">Select Status</option>
                                                    {statusList.map((item, index) =>
                                                        <Fragment>
                                                            <option value={item} key={index}>{item}</option>
                                                        </Fragment>
                                                    )}
                                                </select>
                                                <Button className="btn-info margin-top-5 btn-sm" onClick={this.saveStatusOnReply}>
                                                    Save
                                                </Button>
                                                <Button className="btn-danger margin-left-5 margin-top-5 btn-sm" onClick={this.resetState}>
                                                    Cancel
                                                </Button>
                                            </Fragment>
                                        }
                                    </td>
                                    <td>
                                        <Button className='btn-primary margin-top-5' onClick={() => this.viewPlatformMacros(dataSource.id)}>
                                            Set up Macros
                                        </Button>
                                    </td>
                                    <td>
                                        {dataSource.closeTicketOnNoResponse &&
                                        <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnNoResponse', enable: false})}>
                                            Disable Close Ticket on No Response Needed
                                        </Button>
                                        }
                                        {!dataSource.closeTicketOnNoResponse &&
                                        <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnNoResponse', enable: true})}>
                                            Enable Close Ticket on No Response Needed
                                        </Button>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <PlatformMacroModal
                    msgDataSourceId={macroDataSourceId}
                    showData={showPlatformMacros}
                    dataType="Zendesk"
                    platformMacroEnabled={platformMacroEnabled}
                    closeModal={() => this.setState({showPlatformMacros:false})}
                    platformMacroType="ZENDESK_MACRO"
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { zendeskOauth: { list, loading } } = state;
    return {
        zendeskList: list, loading
    };
}

const connectedListZendeskDataSources = connect(mapStateToProps)(ListZendeskDataSources);
export { connectedListZendeskDataSources as ListZendeskDataSources };
