import * as React from 'react';
import {Box} from "@mui/system";
import UpdateSegmentStatusAction from "./UpdateSegmentStatusAction";

export default function SegmentActions({segmentId, campaignId, status}) {
    return (
        <Box>
            <Box sx={styles.container}>
                <UpdateSegmentStatusAction segmentId={segmentId} campaignId={campaignId} status={status} />
                {/*Add more actions*/}
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}