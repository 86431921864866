import {Box, Grid} from "@mui/material";
import {Field} from "redux-form";
import {
    supportedChannelsForCustomerIoSegment,
    supportedChannelsForKeywordSegment,
    supportedChannelsForQuerySegment
} from "_constants";
import CustomerIoSegment from "./CustomerIoSegment";
import BasicFormTextField from "../../../common/BasicFormTextField";

export default function CampaignSegment({channel, queryParam, handleSegmentSelectChange, required = undefined}) {
    const isKeywordSegmentSupported = supportedChannelsForKeywordSegment.includes(channel);
    const isQuerySegmentSupported = supportedChannelsForQuerySegment.includes(channel);
    const isCustomerIoSegmentSupported = supportedChannelsForCustomerIoSegment.includes(channel);

    const getPlaceholder = () => {
        if(isKeywordSegmentSupported) return 'Enter comma-separated segments';
        else if(isQuerySegmentSupported) return 'Enter segment query';
        else if(isCustomerIoSegmentSupported) return '';
        return 'Enter segment';
    }

    if(isCustomerIoSegmentSupported){
        return (
            <CustomerIoSegment
                queryParam={queryParam}
                handleSegmentSelectChange={handleSegmentSelectChange}
            />
        )
    }

    return (
        <Grid container item xs={12} md={7}>
            <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                    <Box sx={styles.inputDesc}>Segments</Box>
                    <Field
                        name="segments"
                        component={BasicFormTextField}
                        placeholder={getPlaceholder()}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
}