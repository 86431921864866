export const userConstants = {
    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    DELETE_VISITED_PAGES: 'USERS_DELETE_VISITED_PAGES',

    SAVE_USER_REQUEST: 'USERS_SAVE_REQUEST',
    SAVE_USER_SUCCESS: 'USERS_SAVE_SUCCESS',
    SAVE_USER_FAILURE: 'USERS_SAVE_FAILURE',

    SAVE_USER_BY_ADMIN_REQUEST: 'USERS_SAVE_BY_ADMIN_REQUEST',
    SAVE_USER_BY_ADMIN_SUCCESS: 'USERS_SAVE_BY_ADMIN_SUCCESS',
    SAVE_USER_BY_ADMIN_FAILURE: 'USERS_SAVE_BY_ADMIN_FAILURE',

    GET_ALL_REQUEST: 'USERS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'USERS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'USERS_GET_ALL_FAILURE',

    GET_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

    GET_EXPERT_RECENT_ACTIVITY_DATA_REQUEST: "GET_EXPERT_RECENT_ACTIVITY_DATA_REQUEST",
    GET_EXPERT_RECENT_ACTIVITY_DATA_SUCCESS: "GET_EXPERT_RECENT_ACTIVITY_DATA_SUCCESS",
    GET_EXPERT_RECENT_ACTIVITY_DATA_FAILURE: "GET_EXPERT_RECENT_ACTIVITY_DATA_FAILURE",

    GET_ACTIVE_EXPERT_REQUEST: "GET_ACTIVE_EXPERT_REQUEST",
    GET_ACTIVE_EXPERT_SUCCESS: "GET_ACTIVE_EXPERT_SUCCESS",
    GET_ACTIVE_EXPERT_FAILURE: "GET_ACTIVE_EXPERT_FAILURE",

    GET_INACTIVE_EXPERT_REQUEST: "GET_INACTIVE_EXPERT_REQUEST",
    GET_INACTIVE_EXPERT_SUCCESS: "GET_INACTIVE_EXPERT_SUCCESS",
    GET_INACTIVE_EXPERT_FAILURE: "GET_INACTIVE_EXPERT_FAILURE",

    GET_PRACTICING_EXPERT_REQUEST: "GET_PRACTICING_EXPERT_REQUEST",
    GET_PRACTICING_EXPERT_SUCCESS: "GET_PRACTICING_EXPERT_SUCCESS",
    GET_PRACTICING_EXPERT_FAILURE: "GET_PRACTICING_EXPERT_FAILURE",

    QUALITY_SCORES_REQUEST: "QUALITY_SCORES_REQUEST",
    QUALITY_SCORES_SUCCESS: "QUALITY_SCORES_SUCCESS",
    QUALITY_SCORES_FAILURE: "QUALITY_SCORES_FAILURE",

    QUALITY_REVIEWS_REQUEST: "QUALITY_REVIEWS_REQUEST",
    QUALITY_REVIEWS_SUCCESS: "QUALITY_REVIEWS_SUCCESS",
    QUALITY_REVIEWS_FAILURE: "QUALITY_REVIEWS_FAILURE",

    GET_ADMIN_PERFORMANCE_REQUEST: "GET_ADMIN_PERFORMANCE_REQUEST",
    GET_ADMIN_PERFORMANCE_SUCCESS: "GET_ADMIN_PERFORMANCE_SUCCESS",
    GET_ADMIN_PERFORMANCE_FAILURE: "GET_ADMIN_PERFORMANCE_FAILURE",

    GET_AGENT_MESSAGES_REQUEST: 'GET_AGENT_MESSAGES_REQUEST',
    GET_AGENT_MESSAGES_SUCCESS: 'GET_AGENT_MESSAGES_SUCCESS',
    GET_AGENT_MESSAGES_FAILURE: 'GET_AGENT_MESSAGES_FAILURE',

    GET_CURRENT_USER_REQUEST: 'GET_CURRENT_USER_DETAILS_REQUEST',
    GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_DETAILS_SUCCESS',
    GET_CURRENT_USER_FAILURE: 'GET_CURRENT_USER_DETAILS_FAILURE',

    UPDATE_USER_PHOTO_REQUEST: 'UPDATE_USER_PHOTO_REQUEST',
    UPDATE_USER_PHOTO_SUCCESS: 'UPDATE_USER_PHOTO_SUCCESS',
    UPDATE_USER_PHOTO_FAILURE: 'UPDATE_USER_PHOTO_FAILURE',

    USER_CONFIRM_ACCOUNT_REQUEST: 'USER_CONFIRM_ACCOUNT_REQUEST',
    USER_CONFIRM_ACCOUNT_SUCCESS: 'USER_CONFIRM_ACCOUNT_SUCCESS',
    USER_CONFIRM_ACCOUNT_FAILURE: 'USER_CONFIRM_ACCOUNT_FAILURE',

    SEARCH_USER_REQUEST: 'SEARCH_USER_REQUEST',
    SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
    SEARCH_USER_FAILURE: 'SEARCH_USER_FAILURE',

    GET_CHATDESK_USER_REQUEST: 'GET_CHATDESK_USER_REQUEST',
    GET_CHATDESK_USER_SUCCESS: 'GET_CHATDESK_USER_SUCCESS',
    GET_CHATDESK_USER_FAILURE: 'GET_CHATDESK_USER_FAILURE',

    GET_MESSAGES_FOR_AGENT_REQUEST: 'GET_MESSAGES_FOR_AGENT_REQUEST',
    GET_MESSAGES_FOR_AGENT_SUCCESS: 'GET_MESSAGES_FOR_AGENT_SUCCESS',
    GET_MESSAGES_FOR_AGENT_FAILURE: 'GET_MESSAGES_FOR_AGENT_FAILURE',

    MARK_COMPANY_INBOX_MSG_AS_DONE_REQUEST: 'MARK_COMPANY_INBOX_MSG_AS_DONE_REQUEST',
    MARK_COMPANY_INBOX_MSG_AS_DONE_SUCCESS: 'MARK_COMPANY_INBOX_MSG_AS_DONE_SUCCESS',
    MARK_COMPANY_INBOX_MSG_AS_DONE_FAILURE: 'MARK_COMPANY_INBOX_MSG_AS_DONE_FAILURE',

    GET_LESS_ACTIVE_AGENT_REQUEST: 'GET_LESS_ACTIVE_AGENT_REQUEST',
    GET_LESS_ACTIVE_AGENT_SUCCESS: 'GET_LESS_ACTIVE_AGENT_SUCCESS',
    GET_LESS_ACTIVE_AGENT_FAILURE: 'GET_LESS_ACTIVE_AGENT_FAILURE',
};
