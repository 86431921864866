import React from 'react';
import 'components/Vote/vote.css';
import { Button } from 'react-bootstrap';

/**
 * @author Chris Okebata
 */

const styles = {

  buttonDefault: {
    border: '2px solid #dee2e0',
    background: '#fff',
    color: '#5a5f5e',
    borderRadius: '5px',
    height: '75px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  buttonActive: {
    border: '2px solid #008751',
    background: '#7fbf7a',
    color: '#fff',
    borderRadius: '5px',
    height: '75px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  checkboxDefault: {
    border: '2px solid #008751',
    width: '28px',
    height: '28px',
    borderRadius: '100%',
    margin: '0 20px',
    fontSize: '18px',
    textAlign: 'center',
  },

  checkboxActive: {
    border: '2px solid #008751',
    background: '#008751',
    width: '28px',
    height: '28px',
    borderRadius: '100%',
    margin: '0 20px',
    fontSize: '18px',
    textAlign: 'center',
  },

  buttonSubmitShow: {
    width: 'calc(100% - 230px)',
    height: '35px',
    paddingRight: '20px',
    display: 'block',
  },

  buttonSubmitHide: {
    display: 'none',
  },
};

const voteButton = (props) => {
  const {
    buttonName, onSubmit, onSelect, responseOption, selected, value,
  } = props;
  const {
    buttonDefault, buttonActive, checkboxDefault,
    checkboxActive, buttonSubmitShow, buttonSubmitHide,
  } = styles;

  const votePayload = {
    response_id: responseOption && responseOption.id,
    tag: value,
    text: responseOption && responseOption.text,
    is_best_response: false,
    edited_text: '',
    edited_html_body: '',
    is_text_edited: false,
  };

  return (
    <div
      className='no-select'
      style={selected ? buttonActive : buttonDefault}
      onClick={event => onSelect(event, value)}
    >
      <div style={{ height: '28px' }}>
        <div style={selected ? checkboxActive : checkboxDefault}>
          {selected ? <i className="fa fa-check color-white" /> : <i />}
        </div>
      </div>
      <div style={{ width: '165px' }}>
        <div className="font-17-bold">
          {buttonName}
        </div>
      </div>
      <div style={selected ? buttonSubmitShow : buttonSubmitHide}>
        <Button
          onClick={() => onSubmit(votePayload)}
          id="submitButton"
          className="btn btn-block btn-primary"
          style={{ height: '35px', lineHeight: '0px' }}
          type="submit"
        >
                    Submit
        </Button>
      </div>
    </div>
  );
};

export { voteButton as VoteButton };
