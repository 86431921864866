import {useEffect, useMemo} from 'react';
import {proactiveActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {SegmentTypeBuilder} from "../objects/SegmentTypeBuilder";

export default function (params) {
    const dispatch = useDispatch();

    useEffect(() => {
        if(params){
            const channel = params
            dispatch(proactiveActions.getSegmentTypes({channel}));
        }
    }, [params]);

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingSegmentTypes;
    const segmentTypesObj = proactive.segmentTypes;
    const segmentTypeList = _.get(segmentTypesObj, 'items') || [];

    const segmentTypes = useMemo(() => {
        const segmentTypeArr = [];
        if(!loading && segmentTypeList.length > 0) {
            segmentTypeList.map( segmentType => {
                const builder = new SegmentTypeBuilder();
                builder.setId(_.get(segmentType, 'id') || '-');
                builder.setName(_.get(segmentType, 'name') || '-');
                segmentTypeArr.push(builder.build());
            });
        }
        return segmentTypeArr
    }, [ segmentTypeList ]) || [];

    return {segmentTypes, loading};
}