/**
 * @author Teyim Pila <pila@chatdesk.com>
 */
import { alertMessages, authConstants } from '_constants';
import { authService } from '_services';
import { userActions } from '_actions';
import { history } from '_helpers';
import {SnackBar} from "../components/common";

/**
 * When company admins login, we need to know if
 * they have added any channels into the system
 */


function login(username, password) {
    function request(user) {
        return { type: authConstants.LOGIN_REQUEST, user };
    }

    function success(user) {
        return { type: authConstants.LOGIN_SUCCESS, user };
    }

    function failure(error) {
        return { type: authConstants.LOGIN_FAILURE, error };
    }

    return (dispatch) => {
        dispatch(request({ username }));

        authService.login(username, password)
            .then(
                (user) => {
                    dispatch(userActions.getCurrentUser(user.id));
                    dispatch(success(user));
                    /**
                     * if the user logging in is a company admin, then we need to:
                     * 1. redirect to add channels page if they have none
                     * 2. redirect to teams page if they have > 1 channel and > 1 team member
                     * 3. redirect to a default page of (1) and (2) above are unmet
                     * this check should be done just for company admins
                     * but the user above does not contain enough information to do certain checks
                     * This is also the main reason why we pass userId while
                     * fetching dataSources for organization instead of
                     * orgId as one may expect
                     * @TODO update this check when such data is available
                     */
                    history.push('/dashboard')
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.INVALID_USERNAME);
                },
            );
    };
}

function logout(destination) {
    authService.logout();
    history.push(destination ? destination : '/logout');
    return { type: authConstants.LOGOUT };
}

function updateAuthUserPhoto(user) {
    return { type: authConstants.UPDATE_USER_PHOTO, user }
}

function updateAuthOrgPhoto(org) {
    return { type: authConstants.UPDATE_ORG_PHOTO, org }
}

function saveUpdatedUser(user) {
    return { type: authConstants.SAVE_UPDATED_USER, user }
}

function register(params) {
    function request(user) {
        return { type: authConstants.REGISTER_REQUEST, user };
    }

    function success(user) {
        return { type: authConstants.REGISTER_SUCCESS, user };
    }

    function failure(error) {
        return { type: authConstants.REGISTER_FAILURE, error };
    }

    return (dispatch) => {
        dispatch(request(params));

        authService.register(params)
            .then(
                (user) => {
                    dispatch(success(user));
                    SnackBar.showMessage(alertMessages.REGISTRATION_SUCCESSFUL);
                    history.push('/verify-email')
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error);
                },
            );
    };
}

function registerAgent(params) {
    function request(user) {
        return { type: authConstants.REGISTER_REQUEST, user };
    }

    function success(user) {
        return { type: authConstants.REGISTER_SUCCESS, user };
    }

    function failure(error) {
        return { type: authConstants.REGISTER_FAILURE, error };
    }

    return (dispatch) => {
        dispatch(request(params));

        authService.registerAgent(params)
            .then(
                (user) => {
                    dispatch(success(user));
                    SnackBar.showMessage(alertMessages.REGISTRATION_SUCCESSFUL);
                    history.push('/login')
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SAVE_USER_FAILURE);
                },
            );
    };
}
const verifyEmailRequest = () => ({
    type: authConstants.VERIFY_EMAIL_REQUEST
});

const verifyEmailSuccess = (response) => ({
    type: authConstants.VERIFY_EMAIL_SUCCESS,
    payload: {
        ...response
    }
});

const verifyEmailFailure = (error) => ({
    type: authConstants.VERIFY_EMAIL_FAILURE, error,
    payload: {
        error
    }
});

const confirmEmailRequest = () => ({
    type: authConstants.CONFIRM_EMAIL_REQUEST
});

const confirmEmailSuccess = (response) => ({
    type: authConstants.CONFIRM_EMAIL_SUCCESS,
    payload: {
        ...response
    }
});

const confirmEmailFailure = (error) => ({
    type: authConstants.CONFIRM_EMAIL_FAILURE, error,
    payload: {
        error
    }
});

const resetPasswordRequest = () => ({
    type: authConstants.PASSWORD_RESET_REQUEST
});

const resetPasswordSuccess = (response) => ({
    type: authConstants.PASSWORD_RESET_SUCCESS,
    payload: {
        ...response
    }
});

const resetPasswordFailure = (error) => ({
    type: authConstants.PASSWORD_RESET_FAILURE, error,
    payload: {
        error
    }
});


export const authActions = {
    login,
    logout,
    register,
    registerAgent,
    updateAuthUserPhoto,
    updateAuthOrgPhoto,
    saveUpdatedUser,
    verifyEmailRequest,
    verifyEmailSuccess,
    verifyEmailFailure,
    confirmEmailRequest,
    confirmEmailSuccess,
    confirmEmailFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure
};
