import React from 'react';
import { currentDashboard } from '_helpers'

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 05/10/2018 : 2:08 PM
 */

const Dashboard = () => {

    return (
        <div>
            {currentDashboard()}
        </div>
    )
};

export { Dashboard };
