import React, { Component } from "react";
import { Col, Form, Grid, Row} from "react-bootstrap";
import { ChannelsContextMenu } from "../Inbox";
import { Field, reduxForm } from "redux-form";
import { DropDownSelect } from "components/common";


/**
 * @author Ogunyale Richard Kehinde <kehinde@chatdesk>
 */
class FilterForm extends Component {
    render() {
        let {
            onFormUpdate,
            direction,
            handleChange,
            channelsAction,
            channelsIds,
            channelsFilter,
            handleChannelsFilterChange,
        } = this.props;

        const dropdownStyles = { borderColor: "#c4c9c7", boxShadow: "none", color: "#5a5f5e", width: "100%" };
        const channelsInput = {onChange: handleChange, value: channelsFilter};
        const messageDirectionInput = {onChange: onFormUpdate, value: direction};
        const messageDirectionOptions = [
            {name: 'All', id: 'ALL'},
            {name: 'Incoming', id: 'INCOMING'},
            {name: 'Outgoing', id: 'OUTGOING'}
        ];

        return (
            <Form inline className="messages-page-filter-form">
                <Grid style={{width: '100%'}}>

                    <Row style={{display: 'flex', justifyContent: 'left'}}>
                        <Col style={{paddingTop: '8px',paddingLeft: '21px'}}>Filter By </Col>
                        <Col className="inbox-context-menu" style = {{marginBottom: "5px"}} md={3}>
                            <Field
                                name="channels"
                                fieldName="channels"
                                type="text"
                                input={channelsInput}
                                options={channelsFilter}
                                checkedChannels={channelsAction}
                                ids={channelsIds}
                                style={dropdownStyles}
                                caretPosition="top"
                                handleChange={handleChannelsFilterChange}
                                component={ChannelsContextMenu} />
                        </Col>
                        <Col className="font-14 color-grey-dark" style = {{marginBottom: "5px"}} md={3}>
                            <Field
                                name="direction"
                                fieldName="direction"
                                input={messageDirectionInput}
                                style={dropdownStyles}
                                options={messageDirectionOptions}
                                value={direction}
                                component={DropDownSelect}/>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        );
    }
}

const filterForm = reduxForm({
    form: 'FilterForm'
})(FilterForm);

export { filterForm as FilterForm }
