import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {metricsActions} from "_actions";
import {PerformanceMetricsBuilder} from "../objects/PerformanceMetricsBuilder";
import _ from "lodash";

export default function (startDate, endDate) {
  const dispatch = useDispatch();

  useEffect(() => {
    const { organization_id = '' } = JSON.parse(localStorage.user);
    dispatch(metricsActions.getPerformanceMetrics(organization_id, startDate, endDate));
  }, [startDate, endDate])

  const metrics = useSelector(state => state.metrics);
  const performanceMetrics = metrics.performanceMetrics;
  const loading = metrics.loadingPerformanceMetrics;
  const builder = new PerformanceMetricsBuilder();

  if(!loading){
    builder.setAnswerRate(_.get(performanceMetrics,'answerRate') || 0);
    builder.setResponsesLessThan90Minutes(_.get(performanceMetrics,'responseRate.responsesLessThan90Minutes') || 0);
    builder.setAnswerRateCompareToAvg(_.get(performanceMetrics,'responseRate.averageResponseTime') || 0);
    builder.setPreSaleQuestions(_.get(performanceMetrics,'preSaleQuestions') || 0);
  }

  const data = builder.metrics || {};
  return { data, loading }
}