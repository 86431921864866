import React, {Component} from "react";
import {Col, Nav, NavItem, Row, Tab} from 'react-bootstrap';
import {connect} from 'react-redux'
import {GeneralSettings} from "./index";
import {UserIndex} from "components/User";
import {ListDataSourceHandles, ListGorgiasDataSources, ListZendeskDataSources,
    ListInstagramDataSources, ListFacebookDataSources, ListFreshdeskDataSources, ListKustomerDataSources,
    ListReamazeDataSources, ListFrontDataSources, ListGladlyDataSources, ListTrustpilotDataSources } from "components/MsgDataSources";
import {ListMsgSources} from "components/MsgSources";
import {dataSourceHandleActions, systemSettingsActions, jiraActions, organizationActions} from "_actions";
import {queryStringToObject} from "_helpers";
import {Loading} from "components/common";
import { DeleteUserMessages } from "../DeleteUserMessages";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 20/12/2018 : 9:16 AM
 */
class ChatdeskAdminSettingsPage extends Component {

    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const {key} = queryStringToObject(search);

        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            key: key ? key : 'general',
            endDate: ''
        };
    }

    componentDidMount() {
        this.props.dispatch(systemSettingsActions.showSystemSettings());

    }

    updateSystemSettings = (params) => {
        this.props.dispatch(systemSettingsActions.updateSystemSettings(params))
    };

    handleChange = (event) => {
        this.setState({
            endDate: event.target.value
        })
    }

    deleteAzurePayload = () => {
        const { endDate } = this.state;
        this.props.dispatch(systemSettingsActions.deleteAzurePayload({endDate}))
    };

    /**
     * Dispatches an action creator to deactivate handles.
     */
    disableAllDataSourceHandles = () => {
        this.props.dispatch(dataSourceHandleActions.deactivateAll());
    };

    jiraOauth = () => {
        let redirectHost = window.location.origin;
        localStorage.setItem('jiraHost', redirectHost)
        this.props.dispatch(jiraActions.oauth(redirectHost));
    };

    handleSelect = (key) => {
        this.setState({key});
    };

    saveOrgVerticals = () => {
        const { dispatch } = this.props
        dispatch(organizationActions.saveOrgVerticals())
    }

    render() {

        const {systemSettings, isLoading} = this.props;
        const { endDate } = this.state;
        if (isLoading) {
            return <Loading/>
        }

        return (

            <div className="top-rel-100">
                <Tab.Container id="left-tabs-example" activeKey={this.state.key} onSelect={this.handleSelect}>
                    <Row className="clearfix">
                        <Col sm={3} style={{backgroundColor: 'white'}}>
                            <Nav bsStyle="pills" stacked>
                                <NavItem eventKey="general">General</NavItem>
                                <NavItem eventKey="handles">Message Handles</NavItem>
                                <NavItem eventKey="users">Manage Users</NavItem>
                                <NavItem eventKey="roles">Manage Roles</NavItem>
                                <NavItem eventKey="organizations">Organizations</NavItem>
                                <NavItem eventKey="msgSources">Message Sources</NavItem>
                                <NavItem eventKey="gorgiasDataSources">Gorgias DataSources</NavItem>
                                <NavItem eventKey="zendeskDataSources">Zendesk DataSources</NavItem>
                                <NavItem eventKey="gladlyDataSource">Gladly DataSources</NavItem>
                                <NavItem eventKey="frontDataSources">Front DataSources</NavItem>
                                <NavItem eventKey="kustomerDataSources">Kustomer DataSources</NavItem>
                                <NavItem eventKey="freshdeskDataSources">Freshdesk DataSources</NavItem>
                                <NavItem eventKey="reamazeDataSources">Reamaze DataSources</NavItem>
                                <NavItem eventKey="trustpilotDataSources">Trustpilot DataSources</NavItem>
                                <NavItem eventKey="instagramDataSources">Instagram DataSources</NavItem>
                                <NavItem eventKey="facebookDataSources">Facebook DataSources</NavItem>
                                <NavItem eventKey="deleteMessages">Delete User Messages</NavItem>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content animation>
                                <Tab.Pane eventKey="general">
                                    <GeneralSettings settings={systemSettings}
                                                     updateSystemSettings={this.updateSystemSettings}
                                                     deleteAzurePayload={this.deleteAzurePayload}
                                                     handleChange={this.handleChange}
                                                     endDate={endDate}
                                                     jiraOauth={this.jiraOauth}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="users">
                                    <UserIndex/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="handles">
                                    {this.state.key === 'handles' &&
                                    <ListDataSourceHandles disableMsgSources={this.disableAllDataSourceHandles}/>}
                                </Tab.Pane>
                                <Tab.Pane eventKey="msgSources">
                                    {this.state.key === 'msgSources' &&
                                    <ListMsgSources/>}
                                </Tab.Pane>
                                <Tab.Pane eventKey="gorgiasDataSources">
                                    {this.state.key === 'gorgiasDataSources' &&
                                    <ListGorgiasDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="zendeskDataSources">
                                    {this.state.key === 'zendeskDataSources' &&
                                    <ListZendeskDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="instagramDataSources">
                                    {this.state.key === 'instagramDataSources' &&
                                        <ListInstagramDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="facebookDataSources">
                                    {this.state.key === 'facebookDataSources' &&
                                        <ListFacebookDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="gladlyDataSource">
                                    {this.state.key === 'gladlyDataSource' &&
                                    <ListGladlyDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="freshdeskDataSources">
                                    {this.state.key === 'freshdeskDataSources' &&
                                    <ListFreshdeskDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="kustomerDataSources">
                                    {this.state.key === 'kustomerDataSources' &&
                                    <ListKustomerDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="frontDataSources">
                                    {this.state.key === 'frontDataSources' &&
                                    <ListFrontDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="reamazeDataSources">
                                    {this.state.key === 'reamazeDataSources' &&
                                    <ListReamazeDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="trustpilotDataSources">
                                    {this.state.key === 'trustpilotDataSources' &&
                                    <ListTrustpilotDataSources />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="deleteMessages">
                                    {this.state.key === 'deleteMessages' &&
                                    <DeleteUserMessages />}
                                </Tab.Pane>
                                <Tab.Pane eventKey="roles"><h2>Roles</h2></Tab.Pane>
                                <Tab.Pane eventKey="organizations">
                                    <h2>Organizations</h2>
                                    <button className="btn btn-info" onClick={this.saveOrgVerticals}>Save Organization Verticals</button>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const {systemSettings: {systemSettings, isLoading}} = state;

    return {
        systemSettings,
        isLoading
    };
}

const connectedChatdeskAdminSettingsPage = connect(mapStateToProps)(ChatdeskAdminSettingsPage);
export {connectedChatdeskAdminSettingsPage as ChatdeskAdminSettingsPage};
