import { useMemo } from 'react';
import { Avatar as MUIAvatar } from '@mui/material';

/**
 * 
 * @param {object} params
 * @param {string} params.userName – (required) User name, used for 'John Doe -> J' letter display when no image is present
 * @param {string | undefined} params.src - (optional) 
 * @param {string} params.alt - (required) Alt description of image
 * @param {'dark' | 'light' | 'teal' | 'grey'} params.background - (optional) Background color of avatar
 * @param {'default' | 'large' | 'xlarge'} params.size - (optional) Size of avatar
 * @param {import('@mui/material/Avatar').AvatarProps['variant']} params.variant - (optional) Variant of avatar
 * @param {boolean?} params.hasBorder - (optional) Variant of avatar
 * @param {object} params.style
 * @param {number} params.initialsCount
 */
export default function Avatar({
	userName,
	src,
	alt,
	background = 'light',
	size = 'default',
	variant = 'circle',
	hasBorder = false,
	style,
	initialsCount = 1
}) {
	const getInitials = (name) => {
		return useMemo(() => {
			if(!name) return '';
			const initials = [];
			const splitNameArr = name.split(' ');
			splitNameArr.map( item => initials.push(item.split('')[0]))
			return initials.slice(0, initialsCount).join('');
		}, [ name ] ) || '';
	}
	const initial = getInitials(userName);

	const getSizeStyle = () => {
		switch (size) {
			case 'default': return {
				width: '36px', height: '36px', fontSize: '20px',
				borderRadius: variant !== 'circle' ? '4px' : undefined,
				border: hasBorder && '1px solid #fff',
			};
			case 'large': return {
				width: '72px', height: '72px', fontSize: '24px',
				borderRadius: variant !== 'circle' ? '4px' : undefined,
				border: hasBorder && '2px solid #fff',
			};
			case 'xlarge': return {
				width: '160px', height: '160px', fontSize: '64px',
				borderRadius: variant !== 'circle' ? '10px' : undefined,
				border: hasBorder && '4px solid #fff',
			};
		}
	}

	const getBackgroundStyle = () => {
		switch (background) {
			case 'light': return {
				backgroundColor: '#EFEAE7',
				color: '#0C2728',
			};
			case 'dark': return {
				backgroundColor: '#144042',
				color: '#FFFFFF',
			};
			case 'teal': return {
				backgroundColor: '#E6F3F2',
				border: '1px solid #DCE2E3',
				color: '#0C2728',
			};
			case 'grey': return {
				backgroundColor: '#F3F5F6',
				border: '1px solid #DCE2E3',
				color: '#0C2728',
			};
		}
	}

	return (
		<MUIAvatar
			sx={{
				...styles.root,
				...getSizeStyle(),
				...getBackgroundStyle(),
				...style,
			}}
			alt={alt}
			src={src}
			variant={variant}
			>
			{initial}
		</MUIAvatar>
	)
}

const styles = {
	root: {
		fontFamily: 'LarsseitMedium',
		lineHeight: 1.2,
	},
}