import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

export const msgSourceService = {
    getAll,
    getAllMsgSources,
    saveSetupLink,
    getMsgSourceLink
};

/**
 *
 * @param params
 * @returns {Promise<Response | never>}
 */
function getAll(params) {
    return fetch(`${SERVER_URL}/api/msgSources?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}


/**
 * Makes a request to get all available message sources
 * @returns {Promise<Response>}
 */
function getAllMsgSources() {
    return fetch(`${SERVER_URL}/api/allMsgSources`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

/**
 * Makes a request to get the link for a single message source
 * @returns {Promise<Response>}
 */
function getMsgSourceLink(msgSrcName) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/msgSourceLink/${msgSrcName}`, requestOptions).then(handleHttpResponse);
}

function saveSetupLink(id, setupLink) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ setupLink })
    };

    return fetch(`${SERVER_URL}/api/setupLink/${id}`, requestOptions).then(handleHttpResponse);
}