import {Fragment} from "react";
import {Button} from "@mui/material";

export default function BasicButton({label, action, disabled, startIcon, endIcon, type, style}) {

    return(
        <Fragment>
            <Button
                sx={{...styles.root, ...style}}
                type={type}
                onClick={action}
                variant="contained"
                disableElevation
                disabled={disabled}
                startIcon={startIcon}
                endIcon={endIcon}>
                {label}
            </Button>
        </Fragment>
    )
}

const styles = {
    root: {
        width: '100%',
        fontSize: '16px',
        padding: '11px 24px',
        color: '#fff',
        fontFamily: 'LarsseitMedium',
        lineHeight: 1,
        textTransform: 'none',
        borderRadius: '50px',
        backgroundColor: '#07857C',
        ':hover': {
            backgroundColor: '#34756f',
            color: 'white',
        },
        ':disabled': {
            color: '#fff',
            backgroundColor: '#368C8680'
        },
        ':focus': {
            outline: '0'
        }
    }
}