import {alertMessages, autoActionConstants} from "_constants";
import {autoActionService} from "_services";
import {Auth} from "../components/Authorization";
import {SnackBar} from "../components/common";
import { refreshPage } from '_helpers';
import _ from "lodash";

function getAutoAction(params) {
    const request = () => ({
        type: autoActionConstants.GET_AUTO_ACTION_REQUEST
    });
    const success = (response) => ({
        type: autoActionConstants.GET_AUTO_ACTION_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: autoActionConstants.GET_AUTO_ACTION_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        autoActionService.getAutoAction(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function createOrUpdateAutoAction(params) {
    const request = () => ({
        type: autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_REQUEST
    });
    const success = (response) => ({
        type: autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        autoActionService.createOrUpdateAutoAction(params)
            .then(response => {
                dispatch(success(response.data));
                refreshPage();
                SnackBar.showMessage(alertMessages.CREATE_AUTO_ACTION_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(error.response.data.message || alertMessages.CREATE_AUTO_ACTION_ERROR);
        });
    }
}

function createOrUpdateAutoActionFromMessageResponse(params) {
    const request = () => ({
        type: autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_REQUEST
    });
    const success = (response) => ({
        type: autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_FAILURE, error,
        payload: { error }
    });

    return (dispatch) => {
        dispatch(request());
        return autoActionService.createOrUpdateAutoAction(params)
            .then(response => {
                dispatch(success(response.data));
                SnackBar.showMessage(alertMessages.CREATE_AUTO_ACTION_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(error.response.data.message || alertMessages.CREATE_AUTO_ACTION_ERROR);
        });
    }
}

export const autoActionActions = {
    getAutoAction,
    createOrUpdateAutoAction,
    createOrUpdateAutoActionFromMessageResponse,
}