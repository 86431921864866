import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {getLogoUrl} from '_helpers';
import { Box, Stack } from '@mui/material'
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import Avatar from 'components/v2/common/Avatar'
import { buttonContained } from 'components/v2/views/Onboarding/Utilities/ButtonContained';
import HeadlineArrow from '../Utilities/HeadlineArrow';
import PersonRating from '../Utilities/PersonRating'

// Local
import chatdeskAdminProfilePhoto from './Utilities/chatdesk_admin_profile.svg'

const getEmploymentName = ({ employment }) => employment.nickname || employment.firstName

const isEmploymentAdmin = ({ employment }) => {
	const employmentName = getEmploymentName({ employment })
	return (employmentName === 'Chatdesk Admin')
}

function EmploymentListItemName({ employment }) {
	const employmentName = getEmploymentName({ employment })
	return <Box sx={{ flex: 1, fontFamily: 'larsseitMedium', color: '#0C2728' }}>{employmentName}</Box>
}

function EmploymentListItemAvatar({ employment }) {
	const employmentName = getEmploymentName({ employment })
	const getEmploymentPhoto = () => {
		if (isEmploymentAdmin({ employment }))
			return chatdeskAdminProfilePhoto
		return employment.photo ? getLogoUrl(employment.photo) : null
	}

	return (
		<Avatar
			userName={employmentName}
			src={getEmploymentPhoto()}
			size={'large'}
			variant={'rounded'}
			background={'dark'}
		/>
	)
}

function EmploymentListItemLocation({ employment }) {
	const location = employment.city && employment.state ? `${employment.city}, ${employment.state}` :  (employment.city || employment.state || "N/A")
	return !isEmploymentAdmin({ employment }) ? (
		location && <Box>{location}</Box>
	) : null
}

function EmploymentListItemLearnMoreButton({ employment }) {
	const { LinkButton } = buttonContained
	return !isEmploymentAdmin({ employment }) ? (
		<LinkButton 
			buttonBordered
			to={`/v2/company/experts/${employment.id}/show`}
			label={'Learn More'}
			onClick={() => {}}
		/>
	) : null
}

function sortOrgEmpList(arr) {
	return arr && arr.reduce((others, chatdeskAdmin) => {
		if (chatdeskAdmin.nickname === 'Chatdesk Admin') {
			others.adminList.push(chatdeskAdmin);
		} else {
			others.othersList.push(chatdeskAdmin);
		}
		return others
	}, {othersList: [], adminList: []})
}

/**
 * @param {object} params
 * @param {string} params.status
 * @param {string} params.headline
 */
export default function EmploymentList({
	status,
	headline,
}) {
	const orgEmploymentsState = useSelector(state => state.employment.orgEmployments)
	const orgEmploymentList = useMemo(() => {
		if (!orgEmploymentsState || !orgEmploymentsState.organizationEemployment || !orgEmploymentsState.organizationEemployment.length) 
			return null

		const data = orgEmploymentsState.organizationEemployment
		return data.filter(employment => employment.status === status)
	}, [orgEmploymentsState, status]);

	let sortedOrgEmpList;
	if(orgEmploymentList && orgEmploymentList.length > 0) {
		const empList = orgEmploymentList ? new Object(orgEmploymentList) : "";
		const result = sortOrgEmpList(empList)
		const sortedList = result.othersList.concat(result.adminList);
		sortedOrgEmpList = [...sortedList]
	}

	return sortedOrgEmpList && sortedOrgEmpList.length > 0 ? (
		<Box sx={styles.root}>
			<HeadlineArrow headline={headline} />

			<Stack sx={styles.tableContainer}>
				<TableContainer>
					<Table size={'medium'}>
						<TableBody>
							{sortedOrgEmpList.map(employment => (
								<TableRow key={employment.id}>
									<TableCell sx={{ width: '0', verticalAlign: 'top', pr: { xs: 0, md: 1 } }}>
										<EmploymentListItemAvatar employment={employment} />
									</TableCell>
									
									<TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
										<EmploymentListItemName employment={employment} />
									</TableCell>
									<TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
										<PersonRating score={employment && employment.score} />
									</TableCell>
									<TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
										<EmploymentListItemLocation employment={employment} />
									</TableCell>
									<TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
										<EmploymentListItemLearnMoreButton employment={employment}  />
									</TableCell>
									
									<TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
										<Stack direction={'column'} spacing={1}>
											<EmploymentListItemName employment={employment} />
											<PersonRating score={employment && employment.score} />
											<EmploymentListItemLocation employment={employment} />
											<EmploymentListItemLearnMoreButton employment={employment} />
										</Stack>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</Box>
	) : null
}

const styles = {
	root: {
		flex: 1,
	},
	tableContainer: {
		backgroundColor: '#fff',
		border: '1px solid #D0D9D9',
		borderRadius: '10px',
		overflow: 'hidden',
		flex: 1,
		fontcolor: '#0C2728',
	},
	employment: {
		p: { xs: 2, lg: 3 },
		'&:not(:last-child)': {
			borderBottom: '1px solid #D0D9D9',
		}
	},
}