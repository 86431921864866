export class TemplateBuilder {
    constructor() {
        this.template = {};
    }

    setId(id){
        this.template.id = id;
        return this;
    }

    setName(name){
        this.template.name = name;
        return this;
    }

    setChannel(channel){
        this.template.channel = channel;
        return this;
    }

    setOrganizationId(organizationId){
        this.template.organizationId = organizationId;
        return this;
    }

    setDescription(description){
        this.template.description = description;
        return this;
    }

    setDateCreated(dateCreated){
        this.template.dateCreated = dateCreated;
        return this;
    }

    setSuggestedResponses(suggestedResponses){
        this.template.suggestedResponses = suggestedResponses;
        return this;
    }

    build(){
        return this.template;
    }
}
