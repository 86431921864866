export const styles = {
    contentPane: {
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    panel: {
        border: "1px solid #dee2e0",
        borderRadius: "3px",
        margin: "10px 0"
    },

    panelTitleContainer: {
        height: "80px",
        padding: "0 30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    panelTitleContent: {
        display: "flex",
        alignItems: "center"
    },

    panelBody: {
        margin: "0",
        padding: "0",
        border: "none"
    },

    panelBodyContainer: {
        margin: "0 30px",
        borderTop: "1px solid #dee2e0"
    },

    panelRowWrapper: {
        padding: "0 15px",
        borderBottom: "1px solid #dee2e0"
    },

    panelCol: {
        height: "50px",
        display: "flex",
        alignItems: "center",
        padding: "0",
        textTransform: "capitalize"
    }
};