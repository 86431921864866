import React from "react";

export default function useGladlySetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const performAction = () => {
    handleOpen();
  };

	return {
		initiateGladlySetup: performAction,
		gladlySetupOpen: open, 
		handleGladlySetupOpen: handleOpen,
		handleGladlySetupClose: handleClose
	};
}