import {Checkbox as MaterialCheckbox, FormControlLabel as MaterialFormControlLabel} from '@mui/material'

const style = {
	marginLeft: 0,
	width: '100%',

	'& .MuiSvgIcon-root': {
		fontSize: '25px', color: '#368C86'
	},

	'& .MuiFormControlLabel-label': {
		flex: 1,
		fontSize: 18,
		fontFamily: 'larsseitRegular',
	}
}

export default function Checkbox({
	// HTML props
	id, name,
	
	// Controlled props
	checked, onChange,

	// Material props
	label, disabled,
}) {
	const _onChange = (event) => {
		onChange(event.target.checked)
	}

	return (
		<MaterialFormControlLabel 
			control={<MaterialCheckbox
				{...{
					checked: checked ?? undefined,
					onChange: onChange ? _onChange : undefined
				}}
				{...{id, name}}
			/>}
			labelPlacement="start"
			sx={style}
			label={label}
			disabled={disabled}
		/>
	)
}