import { systemSettingsConstants, alertMessages } from '_constants';
import { systemSettingsService } from '_services';
import {SnackBar} from "../components/common";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 29/01/2019 : 5:40 PM
 */

export const systemSettingsActions = {
    updateSystemSettings,
    showSystemSettings,
    deleteAzurePayload
};

/**
 * Dispatches actions involves in systemSettings
 * submission, processing and potential failure.
 *
 * SystemSettings: These are configurations that guide system-wide operations.
 * E.g. toggling dispatcher on/off for the entire app.
 *
 * @param systemSettings
 * @returns {function(*)}
 */
function updateSystemSettings(systemSettings) {
    return dispatch => {
        dispatch(request(systemSettings));

        systemSettingsService.updateSystemSettings(systemSettings)
            .then(
                systemSettings => {
                    dispatch(success(systemSettings));
                    SnackBar.showMessage(alertMessages.SYSTEM_UPDATE_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(systemSettings) {
        return { type: systemSettingsConstants.UPDATE_REQUEST, systemSettings }
    }

    function success(systemSettings) {
        return { type: systemSettingsConstants.UPDATE_SUCCESS, systemSettings }
    }

    function failure(error) {
        return { type: systemSettingsConstants.UPDATE_FAILURE, error }
    }
}

function deleteAzurePayload(params) {
    return dispatch => {
        dispatch(request());

        systemSettingsService.deleteAzurePayload(params)
            .then(
                systemSettings => {
                    dispatch(success(systemSettings));
                    SnackBar.showMessage(`Deleted ${systemSettings.totalDeleted || 'No'} blobs on Azure`);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(systemSettings) {
        return { type: systemSettingsConstants.UPDATE_REQUEST, systemSettings }
    }

    function success(systemSettings) {
        return { type: systemSettingsConstants.UPDATE_SUCCESS, systemSettings }
    }

    function failure(error) {
        return { type: systemSettingsConstants.UPDATE_FAILURE, error }
    }
}


/**
 * Dispatches the 'getSystemSettings' action.
 * @returns {function(*)}
 */
function showSystemSettings() {
    return dispatch => {
        dispatch(request());

        systemSettingsService.showSystemSettings()
            .then(
                systemSettings => dispatch(success(systemSettings)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: systemSettingsConstants.SHOW_REQUEST }
    }

    function success(systemSettings) {
        return { type: systemSettingsConstants.SHOW_SUCCESS, payload: systemSettings }
    }

    function failure(error) {
        return { type: systemSettingsConstants.SHOW_FAILURE, payload: error }
    }
}

