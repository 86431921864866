import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { userActions } from '_actions';
import { getCurrentUser, isValidEmail } from '_helpers';
import BasicModal from 'components/v2/views/Onboarding/Utilities/BasicModal';
import {Input} from 'components/v2/views/Channels/ConnectChannels/Utilities/Input';
import SetupModalBottomBar from 'components/v2/views/Channels/ConnectChannels/Utilities/SetupModalBottomBar';
import generator from 'generate-password';

const validateRequired = value => value ? undefined : 'This field is required'
const validateEmail = value => value && !isValidEmail(value) ? 'Invalid email address' : undefined

export function useUsersAddModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const initiateUsersAddModal = () => {
    handleOpen()
  }

  return {
    initiateUsersAddModal,
    modalProps: {
      open,
      handleOpen,
      handleClose,
    }
  }
}

function UsersAddModal({
  handleSubmit, // from redux-form
	open, handleClose, handleOpen,
}){
  const dispatch = useDispatch()
  const currentRole = useSelector((state) => state.roles.currentRoleId)
  const loading = useSelector((state) => state.users.loading)
  const currentUser = getCurrentUser()
  const additionalFormData = {
    role: currentRole,
    organization: currentUser.organization_id || null,
    enabled: false,
    userToOrganization: true,
  }

  const onSubmit = (props) => {
    const password = generator.generate({
      length: 12,
      numbers: true,
      uppercase: true,
      symbols: true,
      lowercase: true,
      strict: true,
    })

    const formData = {
      ...props,
      ...additionalFormData,
      password,
    }

    dispatch(userActions.createUser(formData))
  }

  return (
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Add User'}>

        <form name='usersAddModalForm' onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={Input}
            name={'firstName'}
            placeholder={'First Name'}
            validate={validateRequired}
          />

          <Field
            component={Input}
            name={'lastName'}
            placeholder={'Last Name'}
            validate={validateRequired}
          />

          <Field
            component={Input}
            name={'username'}
            type={'email'}
            placeholder={'Email (Username)'}
            validate={[validateRequired, validateEmail]}
          />

          <SetupModalBottomBar
            buttonLabel={'Add User'}
            loading={loading}
          />
        </form>
      </BasicModal>
    </Fragment>
  )
}

const formConfig = {
  form: 'usersAddModalForm',
};

export default reduxForm(formConfig)(UsersAddModal);

