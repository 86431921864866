export const jiraConstants = {
    JIRA_BUG_REQUEST: 'JIRA_BUG_REQUEST',
    JIRA_BUG_SUCCESS: 'JIRA_BUG_SUCCESS',
    JIRA_BUG_FAILURE: 'JIRA_BUG_FAILURE',

    JIRA_BUG_ATTACHMENT_REQUEST: 'JIRA_BUG_ATTACHMENT_REQUEST',
    JIRA_BUG_ATTACHMENT_SUCCESS: 'JIRA_BUG_ATTACHMENT_SUCCESS',
    JIRA_BUG_ATTACHMENT_FAILURE: 'JIRA_BUG_ATTACHMENT_FAILURE',

    JIRA_CLEAR_TOGGLE: 'JIRA_CLEAR_TOGGLE'
}