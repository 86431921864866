import {Fragment} from "react";
import BasicButton from "../../BasicButton";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DropDownArrow from '@mui/icons-material/ArrowDropDownOutlined';
import { buttonClasses } from "@mui/material/Button";

/**
 * @param label {string}
 * @param onClick {function}
 * @param disabled {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
export default function FilterButton({label='Filters', onClick, disabled}) {

    return(
        <Fragment>
            <BasicButton
                style={styles.root}
                action={onClick}
                disabled={disabled}
                startIcon={<TuneOutlinedIcon/>}
                endIcon={<DropDownArrow/>}
                label={label}/>
        </Fragment>
    )
}

const styles = {
    root: {
        width: '100%',
        fontSize: '16px',
        padding: '6px 0px',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        lineHeight: 1,
        textTransform: 'none',
        border: '1.5px solid #9EA9A9',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        ':hover': {
            borderColor: '#4b5252',
            backgroundColor: '#FFFFFF',
        },
        ':disabled': {
            borderColor: '#cfd3d3',
            backgroundColor: '#FFFFFF'
        },
        ':focus': {
            borderColor: '#9EA9A9',
            outline: '0'
        },
        [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
            fontSize: "24px"
        },
        [`& .${buttonClasses.endIcon} > *:nth-of-type(1)`]: {
            fontSize: "24px"
        }
    },
}