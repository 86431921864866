import {subscriptionsConstants} from '_constants';

const INITIAL_STATE = {
    all_subscriptions: [],
    total: 0,
    subscription: {},
    history: [],
    data: {},
    loading: true,
    error: '',
};

export function subscriptionsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case subscriptionsConstants.GET_ALL_SUBSCRIPTIONS_REQUEST:
            return {...state, loading: true, error: ''};
        case subscriptionsConstants.GET_ALL_SUBSCRIPTIONS_SUCCESS:
            return {...state,
                all_subscriptions: action.payload.subscriptions,
                total: action.payload.total,
                loading: false
            };
        case subscriptionsConstants.GET_ALL_SUBSCRIPTIONS_FAILURE:
            return {...state, error: action.error, loading: false};

        case subscriptionsConstants.GET_SUBSCRIPTION_REQUEST:
            return {...state, loading: true, error: ''};
        case subscriptionsConstants.GET_SUBSCRIPTION_SUCCESS:
            return {...state, subscription: action.payload, loading: false};
        case subscriptionsConstants.GET_SUBSCRIPTION_FAILURE:
            return {...state, error: action.error, loading: false};

        case subscriptionsConstants.CREATE_SUBSCRIPTION_REQUEST:
            return {...state, loading: true, error: ''};
        case subscriptionsConstants.CREATE_SUBSCRIPTION_SUCCESS:
            return {...state, subscription: action.payload, loading: false};
        case subscriptionsConstants.CREATE_SUBSCRIPTION_FAILURE:
            return {...state, error: action.error, loading: false};

        case subscriptionsConstants.UPDATE_SUBSCRIPTION_REQUEST:
            return {...state, loading: true, error: ''};
        case subscriptionsConstants.UPDATE_SUBSCRIPTION_SUCCESS:
            return {...state, subscription: action.payload, loading: false};
        case subscriptionsConstants.UPDATE_SUBSCRIPTION_FAILURE:
            return {...state, error: action.error, loading: false};

        case subscriptionsConstants.SUBSCRIPTION_HISTORY_REQUEST:
            return {...state, loading: true, error: ''};
        case subscriptionsConstants.SUBSCRIPTION_HISTORY_SUCCESS:
            return {...state,
                history: action.payload.subscriptionHistory,
                data: {
                    count: action.payload.count,
                    currentBalance: action.payload.currentBalance,
                    lifeTimeSpending: action.payload.lifeTimeSpending,
                },
                loading: false};
        case subscriptionsConstants.SUBSCRIPTION_HISTORY_FAILURE:
            return {...state, error: action.error, loading: false};

        case subscriptionsConstants.ADD_ORG_SUBSCRIPTION_REQUEST:
            return {...state, loading: true, error: ''};
        case subscriptionsConstants.ADD_ORG_SUBSCRIPTION_SUCCESS:
            return {...state, loading: false};
        case subscriptionsConstants.ADD_ORG_SUBSCRIPTION_FAILURE:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}
