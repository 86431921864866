import React from "react";
import BasicTable from "../../../common/BasicTable";
import {Box} from "@mui/system";
import importedStyles from "./templateStyles";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";

export default function CampaignsTable({ rows, columns, totalCount, page, loading, handlePageChange, clickableRow, handleTableRowClick, handleTableCellClick }) {

    if(loading && _.isEmpty(rows)) {
        return(
            <Box sx={importedStyles.emptyContainer}>
                <CircularProgress size={25} sx={{ color: '#07857C'}}/>
            </Box>
        )
    }

    return (
        <BasicTable
            rows={rows}
            columns={columns}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
            page={page}
            isPaginationDisabled={loading}
            clickableRow={clickableRow}
            handleTableRowClick={handleTableRowClick}
            handleTableCellClick={handleTableCellClick}
            tableHeadStyle={styles.ellipsis}
        />
    );
}

const styles = {
    ellipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}