import {alertMessages, requestOrigin, responseOptionConstants, voteConstants} from '_constants';
import {dispatcherService, responseOptionService, voteService} from '_services';
import {buildNextMessagePayload, getNextMessage} from '_helpers';
import {SnackBar} from "../components/common";
import {messageActions} from "./message.actions";
import {dispatcherActions} from "./dispatcher.actions";
import {alertActions} from "./alert.actions";

function getActionData(actionType, payload = {}) {
    return {type: actionType, payload};
}

function request(vote) {
  return { type: voteConstants.SUBMIT_VOTE_REQUEST, vote };
}

function success(vote) {
  return { type: voteConstants.SUBMIT_VOTE_SUCCESS, vote };
}

function failure(error) {
  return { type: voteConstants.SUBMIT_VOTE_FAILURE, error };
}

/**
 * Dispatches actions involves in a vote
 * submission, processing and potential failure.
 *
 * @param vote
 * @returns {function(*)}
 */
function submitVote(vote) {
  return (dispatch) => {
    dispatch(request(vote));

    return voteService.submitVote(vote)
      .then(
        (response) => {
          dispatch(success(response));

          if (vote.userVote.isLastResponseVote) {
            getNextMessage(dispatch);
          }

          return { success: true, response: response };
        },
        (error) => {
          return error.then(e => {
            dispatch(failure(e));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
            return { success: false, response: e };
          });
        },
      );
  };
}

/**
 * @param vote {object}
 * @returns {(function(*): void)|*}
 */
function submitResponse(vote) {
  return (dispatch) => {
    dispatch(request(vote));

    return voteService.submitVote(vote)
        .then(response => {
            dispatch(success(response));
            dispatch(messageActions.clearConversation());
            dispatch(alertActions.clear());
            return { success: true, response: response };
        }).catch(error => {
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
            return { success: false, response: error };
        });
  };
}

/**
 * @param messageId {string}
 * @param payload {object}
 * @returns {(function(*): void)|*}
 */
function submitAndHide(messageId, payload) {
    return (dispatch) => {
        dispatch(getActionData(voteConstants.SUBMIT_AND_HIDE_REQUEST));
        dispatcherService.updateMessageFromSource(messageId, payload)
            .then(() => {
                dispatch(messageActions.tickMessageAsActioned([messageId], 'teams-only', requestOrigin.VOTE_INDEX));
            }).then(() => {
                dispatch(getActionData(voteConstants.SUBMIT_AND_HIDE_SUCCESS));
                const payload = buildNextMessagePayload();
                dispatch(messageActions.showNextVoteMessage(payload));
            }).catch(() => {
                dispatch(getActionData(voteConstants.SUBMIT_AND_HIDE_FAILURE));
            });
    };
}

export const voteActions = {
  submitVote,
  submitResponse,
  submitAndHide
};
