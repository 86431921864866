import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useRef} from "react";
import {messageActions} from "_actions";
import _ from "lodash";
import {InternalNoteAssigneeBuilder} from "../objects/InternalNoteAssigneeBuilder";

export default function (message) {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            getInternalNoteAssignees();
            return () => effectRan.current = true;
        }
    }, []);

    const getInternalNoteAssignees = () => {
        const messageId = _.get(message, 'id');
        console.log("Fetching internal note assignees...")
        dispatch(messageActions.getInternalNoteAssignees(messageId));
    }

    const internalNoteAssigneeList = useSelector(state => state.messages.internalNoteAssignees);
    const loadingInternalNoteAssignees = useSelector(state => state.messages.loadingInternalNoteAssignees);

    const internalNoteAssignees = useMemo(() => {
        const internalNoteAssigneeArr = [];
        if(!loadingInternalNoteAssignees && internalNoteAssigneeList.length > 0) {
            internalNoteAssigneeList.map( internalNoteAssignee => {
                const builder = new InternalNoteAssigneeBuilder();
                builder.setId(_.get(internalNoteAssignee, 'id') || '-');
                builder.setEmail(_.get(internalNoteAssignee, 'email') || '-');
                builder.setName(_.get(internalNoteAssignee, 'name') || '-');

                internalNoteAssigneeArr.push(builder.build());
            });
        }
        return internalNoteAssigneeArr
    }, [ internalNoteAssigneeList ]) || [];

    return {internalNoteAssignees, loadingInternalNoteAssignees };
}