import {SERVER_URL} from "config";
import {authHeader, handleHttpResponse} from '_helpers';


export const voteService = {
    submitVote
};

function submitVote(vote) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(vote)
    };

    return fetch(`${SERVER_URL}/api/votes`, requestOptions)
        .then(handleHttpResponse)
}
