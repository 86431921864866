import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgSourceActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import { Modal } from "./Modal";


/**
 * @author Okpala Oluchukwu Chioma<oluchukwuokpala1759@gmail.com>
 * @date 18/03/2020 : 05:06 PM
 */
class ListMsgSources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            setupLink: '',
            currentId: ''
        };
    }

    componentDidMount() {
        this.props.dispatch(msgSourceActions.getAllMsgSources());
    }

    onInputChange = (e) => {
        this.setState({
            setupLink: e.target.value
        });
    };

    handleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            currentId: id
        })
    };

    /**
     * Saves a setup link for a message source
     * @param Id
     * @param link
     */
    saveSetupLink = () => {
        const { currentId, setupLink } = this.state
        this.props
            .dispatch(msgSourceActions.saveSetupLink(currentId, setupLink))
            .then(() => {
                this.props.dispatch(msgSourceActions.getAllMsgSources());
                this.setState({
                    showModal: !this.state.showModal,
                    setupLink: ''
                });
            });
    };

    render() {
        const { msgSourcesList, loading }  = this.props;
        const linkStyle = {verticalAlign: 'middle', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'};

        if (loading) {
            return <Loading/>
        }

        return (
            <div>
                <Row>
                    <Col sm={12} md={12}>
                        <table className='table trds-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{width:'55%'}}>Link</th>
                                </tr>
                            </thead>
                            <tbody>
                            {msgSourcesList.map((msgSource) => {
                                return (
                                    <tr key={msgSource.id}>
                                        <td style={{verticalAlign: 'middle'}}>{msgSource.name}</td>
                                        <td style={linkStyle}>{msgSource.link}</td>
                                        <td>
                                            <Button className='btn-success' onClick={() => this.handleModal(msgSource.id)}>
                                                Configure Setup Link
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                            )}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Modal
                    value={this.state.setupLink}
                    onInputChange={(event) => this.onInputChange(event)}
                    showModal={this.state.showModal}
                    saveSetupLink={this.saveSetupLink}
                    handleModal={this.handleModal}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { msgSources: { msgSourcesList, loading }} = state;
    return {
        msgSourcesList: msgSourcesList.msgSource,
        loading
    };
}

const connectedMsgSources = connect(mapStateToProps)(ListMsgSources);
export { connectedMsgSources as ListMsgSources };
