import React from 'react';
import 'react-widgets/dist/css/react-widgets.css'
import { Loading } from 'components/common/index';
import { Redirect } from 'react-router-dom'
import { Grid, Row } from 'react-bootstrap'


const CompanyAdminDashboard = () => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
    if (userDetails && userDetails.loading) {
        return <Loading />
    }

    const { hasMsgDataSource, hasEmployment } = userDetails._embedded.organization;
    if (hasMsgDataSource) {
        if (hasEmployment) {
            return <Redirect to="/metrics" />
        } else {
            return <Redirect to="/signup/dashboard" />
        }
    }

    return (
        <Grid className="top-rel-100"
              style={{
                  backgroundColor: 'white',
                  borderRadius: '1%',
                  border: '.5px solid lightGrey'
              }}>
            <Row className="text-center">
                <Redirect to={
                  (JSON.parse(localStorage.getItem('isOnboarding')) && localStorage.getItem('currentOnboardingPage')) ||
                  "company/connectchannels" } />
            </Row>
        </Grid>
    );
};

export { CompanyAdminDashboard };
