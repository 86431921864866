import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/system";
import IconButton from "@mui/material/IconButton";
import AddResponseIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ResponseAddedIcon from '@mui/icons-material/CheckCircleOutlined';
import _ from "lodash";
import {responseOptionActions} from "_actions";
import CircularProgress from "@mui/material/CircularProgress";
import {checkUserAddedResponseStatus} from "_helpers";

export default function AddResponseAction({message, searchedMessage}) {
    const dispatch = useDispatch();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const addedResponseOption = useSelector(state => state.responseOptions.addedResponseOption) || [];
    const loading = useSelector(state => state.responseOptions.loadingAddResponseOption);
    const clickedResponseMessageId = useSelector(state => state.responseOptions.responseMessageId);
    const responseOptions = _.get(message, 'response_options') || [];
    const isAdded = checkUserAddedResponseStatus(responseOptions, addedResponseOption, searchedMessage);
    const isAdding = loading && clickedResponseMessageId === _.get(searchedMessage, 'messageId');

    const addResponseToMessage = () => {
        const messageId = _.get(message, 'id');
        const userId = _.get(currentUser, 'id');
        const text = _.get(searchedMessage, 'messageText');
        const responseMessageId = _.get(searchedMessage, 'messageId');
        dispatch(responseOptionActions.addResponseOption(messageId, responseMessageId, text, userId));
    }

    return (
        <Box>
            {
                isAdded ?
                    <IconButton sx={styles.buttonIcon} size={'small'} disabled>
                        <ResponseAddedIcon sx={styles.disabledIcon}/>
                    </IconButton>
                    :
                    <IconButton sx={styles.buttonIcon} size={'small'} onClick={addResponseToMessage} disabled={loading}>
                        {
                            isAdding ?
                                <Box sx={styles.progressIconContainer}>
                                    <CircularProgress size={20} sx={styles.icon}/>
                                </Box>:
                                <AddResponseIcon sx={isAdding ? styles.disabledIcon : styles.icon}/>
                        }
                    </IconButton>
            }
        </Box>
    )
}

const styles = {
    buttonIcon: {
        ':focus': {
            outline: 'unset',
        },
    },
    disabledIcon: {
        color:'#368C8680',
        fontSize: '25px'
    },
    icon: {
        color:'#07857C',
        fontSize: '25px'
    },
    progressIconContainer: {
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}