import { regFormContainer } from "components/RegisterPage";
import React from "react";

const InputField = ({ input, label, type, isRequired, readOnly, meta: {touched, error, warning} }) => {
    if (isRequired !== false) {
        input.required = true;
    }

    return (
        <div>
            <div style={regFormContainer.error} className="font-12 color-danger">
                {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
            <input {...input} type={type}
                   className="input-field"
                   readOnly={readOnly}
            />
            <label htmlFor={label}><span>{label}</span></label>
        </div>
    );
};

export default InputField;