export class ResourcesSeparatorBuilder {
    constructor() {
        this.resources = {};
    }

    setVisibleList(visibleList){
        this.resources.visibleList = visibleList;
        return this;
    }

    setHiddenList(hiddenList){
        this.resources.hiddenList = hiddenList;
        return this;
    }

    build(){
        return this.resources;
    }
}
