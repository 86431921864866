import {Box, Divider} from "@mui/material";
import React, {useState} from "react";
import CompanyInfoActions from "../CompanyInfo/CompanyInfoActions";
import {buildMessageValidityPayload} from "../organizationSettingsUtils";
import {SnackBar} from "../../../../../common";
import {organizationSettingsActions} from "../../../../../../_actions";
import BasicTextField from "../../../../common/BasicTextField";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {accessibilityMode} from "../organizationSettingsConstants";
import importedStyles from "../../organizationMainStyles";
import InformationTooltip from "../InformationTooltip";

export default function MessageValidity({organization, mode, label}){
    const defaultValidityValue = _.get(organization,'messageValidityDurationInDays')
    const [messageValidity, setMessageValidity] = useState(defaultValidityValue)
    const dispatch = useDispatch();
    const organizationId = _.get(organization, 'id');
    const organizationSettings = useSelector(state => state.organizationSettings);
    const loading = organizationSettings.loadingOrganizationAttributeUpdate;
    const actionType = organizationSettings.actionType;
    const ACTION_ID = 'messageValidity_action';

    const handleUpdate = () => {
        const payload = buildMessageValidityPayload(messageValidity);
        if(!organizationId || !payload) return SnackBar.showMessage('Invalid parameters');
        dispatch(organizationSettingsActions.updateOrganizationAttribute(organizationId, payload, ACTION_ID));
    }

    const handleChange = (event) => {
        const {target: {value}} = event
        setMessageValidity(value)
    }

    const handleCancel = () => {
        setMessageValidity(defaultValidityValue);
    }

    return(
        <>
            <Box component={'h2'} sx={styles.heading}>
                {label.MESSAGE_RETENTION_DURATION}
                <InformationTooltip label={"How long a message will be available to experts in teams in days. If unset, the default is 7 days."}/>
            </Box>
            {mode === accessibilityMode.EDIT ?
                <Box>
                    <Box sx={styles.inputContainer}>
                        <Box sx={{width: '100px'}}>
                            <BasicTextField
                                type={'number'}
                                value={messageValidity}
                                handleChange={handleChange}
                                min={0}
                                sx={styles.textField}
                            />
                        </Box>
                        <CompanyInfoActions
                            id={ACTION_ID}
                            selectedId={actionType}
                            performAction={handleUpdate}
                            performCancel={handleCancel}
                            loading={loading}
                        />
                    </Box>
                    <Box sx={{width: '100px'}}>
                        <Divider orientation={'horizontal'} flexItem sx={styles.sectionDivider} />
                        <Box sx={{textAlign: 'center'}}>
                            Days
                        </Box>
                    </Box>
                </Box>
                :
                 <Box sx={importedStyles.infoItem}>{defaultValidityValue && `${defaultValidityValue} days`}</Box>
            }
        </>
    )
}

const styles = {
    heading: {
        ...importedStyles.heading,
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    sectionDivider: {
        margin: '7px 0 2px 0',
        backgroundColor: '#B6BEBF'
    },
    textField: {
        height: '42px',
        px: '0px'
    },
    inputContainer: {
        display: 'flex',
        mt: '10px',
        gap: '10px',
        alignItems: 'center'
    },
}