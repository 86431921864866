import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

function getAttributes(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/users/${params.userId}/attributes`, requestOptions)
        .then(handleHttpResponse);
}

function putAttributes(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            selectedAttributes: data.selectedAttributes
        })
    };

    return fetch(`${SERVER_URL}/api/users/${data.userId}/attributes`, requestOptions)
        .then(handleHttpResponse);
}

export const attributesService = {
    getAttributes,
    putAttributes,
};

