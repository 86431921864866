import React, {Fragment} from "react";
import {Button, ControlLabel, Form, FormGroup, ListGroup, ListGroupItem} from "react-bootstrap";
import {Field, reduxForm} from 'redux-form'
import {TextEditor} from "components/common";
import {SelectField} from "../../common/formFields/SelectField";
import {required} from "_helpers";

const SecondaryCreateForm = props => {

    const {handleSubmit, agentInfo, submitButtonText, feedbackOptions} = props;
    let nickname;
    let showAgentInfo = false;
    if (agentInfo) {
        nickname = agentInfo.agentNickname ? agentInfo.agentNickname : agentInfo.name;
        showAgentInfo = true
    }

    return (
        <ListGroup>
            {!showAgentInfo ? <span className="text-danger">Message author is not an agent. No email will be sent</span> :
                <Fragment>
                    <div className="border-0" style={{paddingBottom: '15px'}}>
                        Expert - {nickname}
                    </div>
                </Fragment>
            }
            <ListGroupItem className="border-0">
                <Form onSubmit={handleSubmit} horizontal>

                    <FormGroup>
                        <ControlLabel style={{paddingBottom: '3px'}}>Options</ControlLabel>
                        <Field
                            name="type"
                            component={SelectField}
                            options={feedbackOptions}
                            className='form-control'
                            style={{padding: '20px 3px'}}
                            optionPropertyId="id"
                            optionPropertyName="name"
                            placeholder="Select Type"

                        />

                    </FormGroup>

                    <FormGroup>
                        <ControlLabel style={{paddingBottom: '3px'}}>Description</ControlLabel>
                        <Field
                            name="description"
                            component={TextEditor}
                            type="textarea"
                            placeholder="Description"
                            className='form-control'
                            style={{padding: '20px 3px'}}
                            useWithRedux={true}
                            outputHtml={true}
                            validateForm={true}
                            validate={required}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button type="submit" className="btn btn-success font-15"
                                style={{padding: '30px 5'}}>{submitButtonText ? submitButtonText : 'Constructive Feedback'}</Button>
                    </FormGroup>
                </Form>
            </ListGroupItem>
        </ListGroup>

    )

};

const connectedComponent = reduxForm({
    form: 'expertMistakeForm'
})(SecondaryCreateForm);

export {connectedComponent as SecondaryCreateForm}

