import { parsePhoneNumber, AsYouType } from 'libphonenumber-js'
import _ from "lodash";
import * as EmailValidator from 'email-validator';
import {passwordStrength} from "check-password-strength";
import {alertMessages} from "_constants";

export const required = (value) => !(value && value.trim().length) ? "This field is required" : undefined;

export const minLength = (min) => {
    return (value) => (value && value.length < min) ? "Minimum " + min + " characters required" : undefined;
};

export const validateCharLength = (value) => {
    const charMinLength = 3
    if(value && value.length < charMinLength) return `Minimum ${charMinLength} characters required`;
    else if(!(value && value.trim().length)) return `This field is required`;
    else return undefined;
};

export const isValidEmail = (email) => {
    return EmailValidator.validate(email);
};

export const isValidPassword = (password) => {
    const pwdStrength = passwordStrength(password);
    const isValidCharCount = pwdStrength.length >= 8;
    const isValidOptions = pwdStrength.contains.length >= 3;
    return isValidCharCount && isValidOptions;
};

/**
 * Checks if a text is a US based phone number
 * @param number
 * @returns {boolean}
 */
export const isPhoneNumber = (number) => {
    let validNumber = false;

    if(number){
        const US_BASED_NUMBER = new AsYouType('US');
        US_BASED_NUMBER.input(number);

        try {
            const phoneNumber = parsePhoneNumber(US_BASED_NUMBER.getNumber().number);
            validNumber = phoneNumber.isValid();
        } catch (error) {
            validNumber = false
        }
    }

    return validNumber
};

//checks if a text (phone-number) format matches the given country-code-list
export const isValidNumberForCountries = (validCountryList, number) => {
    for (let i = 0; i < validCountryList.length; i++){
        try {
            const phoneNumber = parsePhoneNumber(number || '', validCountryList[i]);
            if (phoneNumber.isValid()){
                return true
            }
        } catch(error){
            console.log(error)
        }
    }
    return false
};

export const fieldsMatch = (field, secondField) => {
    return field === secondField
};

export const isvalidDateRange = (startDate, endDate) => {
    return endDate >= startDate
};

export const isVerticalSelected = (vertical) => {
    return !_.isEmpty(vertical);
};

export const validate = (values) => {
    const errors = {};
    const {email, vertical, password } = values;

    if(_.isEmpty(_.toString(vertical))){
        errors.vertical = alertMessages.NO_VERTICAL_ERROR;
    }
    if (!isValidEmail(email)){
        errors.email = 'invalid email';
    }
    if(!isValidPassword(password)){
        errors.password = 'weak password';
    }
    return errors
};

export const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}