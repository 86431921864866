import {HtmlTooltip} from "../../../../common";
import {Typography} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";

export default function InformationTooltip({label}) {
    return(
        <HtmlTooltip
            title={
                <div style={{minWidth: '250px', maxWidth: '450px', padding: '10px'}}>
                    <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                        {label}
                    </Typography>
                </div>
            }
            placement={'bottom'}
            style={{opacity: 1}}
            enterDelay={700}
        >
            <InfoIcon sx={{fontSize: '18px'}}/>
        </HtmlTooltip>
    )
}