import {MenuItem, MenuList} from "@material-ui/core";
import React, {Component} from "react";
import {screenshotConstants} from "../../_constants";
import _ from 'lodash'

const btnStyle = {
    backgroundColor: 'transparent',
    border: '0',
    margin: '2px',
    color: '#008751',
    padding: '0'
};

class MessageActionsV2 extends Component {

    render() {
        /**
         * mainMessage is the actual message an action should be performed on, i.e. the message currently visible to the user
         * this property was added to replace 'message' because the latter was inconsistent but did fix other issues
         * 'message'is allowed here for backward compatibilty.
         */
        const {
            deleteMessageFromSource,
            escalateMessage,
            handleClose,
            showScreenshotModal,
            mainMessage,
            skipMessage,
            updateMessageFromSource,
            isBlacklistAllowed,
            blacklist,
            isMessageActioned,
            platform,
            showModalHandle,
            showEditModalButton,
            showUndoButton,
            undoMessage,
            likeMessageFromSource,
            resendForResponseGenList,
            sendMessageForResponseGen,
            positiveFeedbackData,
            showPrivateReplyModal,
            actionMessage
        } = this.props;
        const messageId = mainMessage.id;

        let dispatchFunction, data, currPath
        let isPositiveFeedback = false
        if (positiveFeedbackData && Object.entries(positiveFeedbackData).length !== 0) {
            dispatchFunction = positiveFeedbackData.dispatchFunction
            data = positiveFeedbackData.data
            currPath = positiveFeedbackData.currPath
            isPositiveFeedback = true
        }


        return (
            <span>
                {mainMessage && mainMessage.direction ?

                    <span>
                       {mainMessage && mainMessage.canBeDeletedFromSource &&
                       <MenuItem onClick={handleClose} className="delete-menu-item">
                           <button type="button" className="btn btn-danger" disabled={mainMessage.removedFromSource}
                                   onClick={() => deleteMessageFromSource(mainMessage.id, {
                                       isV2Enabled: mainMessage.isV2Enabled,
                                       source: mainMessage.sourceName
                                   })}>
                               <span className="glyphicon glyphicon-trash"/> Delete
                           </button>
                       </MenuItem>
                       }

                        {mainMessage.direction.toLowerCase() === "incoming" ?
                            <span>
                                {mainMessage && mainMessage.canReplyPrivately &&
                                <MenuItem onClick={handleClose}>
                                    <button type="button" className="btn btn-default" style={btnStyle}
                                            onClick={(e) => showPrivateReplyModal(e)}>
                                        SEND PRIVATE REPLY
                                    </button>
                                </MenuItem>
                                }

                                {!isMessageActioned ?
                                    <MenuList className="message-actions-list">
                                        <MenuItem onClick={handleClose}>
                                            <button type="button" className="btn" style={btnStyle}
                                                    onClick={() => escalateMessage(messageId)}>
                                                ESCALATE
                                            </button>
                                        </MenuItem>

                                        <MenuItem onClick={handleClose}>
                                            <button type="button" className="btn" style={btnStyle}
                                                    onClick={() => skipMessage(messageId)}>
                                                NO RESPONSE NEEDED
                                            </button>
                                        </MenuItem>

                                        <MenuItem onClick={handleClose}>
                                            <button type="button" className="btn" style={btnStyle}
                                                    onClick={() => actionMessage(messageId)}>
                                                ACTION
                                            </button>
                                        </MenuItem>
                                    </MenuList>
                                    :
                                    null
                                }
                                {showUndoButton && isMessageActioned &&
                                <MenuItem onClick={handleClose}>
                                    <button type="button" className="btn" style={btnStyle}
                                            onClick={
                                                () => undoMessage(mainMessage.id)}>
                                        UNDO
                                    </button>
                                </MenuItem>
                                }
                                {isBlacklistAllowed &&
                                <MenuItem onClick={handleClose}>
                                    <button type="button" className="btn" style={btnStyle}
                                            onClick={
                                                () => {blacklist(mainMessage.id, mainMessage.sourceName)}}>
                                        Blacklist Post
                                    </button>
                                </MenuItem>
                                }
                                {!mainMessage.isHidden &&
                                <MenuItem onClick={handleClose}>
                                    <button type="button" className="btn" style={btnStyle}
                                            onClick={
                                                () => updateMessageFromSource(mainMessage.id, {
                                                    updateFields: {
                                                        is_hidden: (_.has(mainMessage, 'hiddenFromSource') ? !mainMessage.hiddenFromSource : true)
                                                    },
                                                    platform,
                                                    isV2Enabled: mainMessage.isV2Enabled,
                                                    source: mainMessage.sourceName
                                                })}>
                                        {mainMessage.hiddenFromSource ? "UNHIDE MESSAGE" : "HIDE MESSAGE"}
                                    </button>
                                </MenuItem>
                                }
                                {resendForResponseGenList && resendForResponseGenList.includes(messageId) &&
                                <MenuItem onClick={handleClose}>
                                    <button type="button" className="btn" style={btnStyle}
                                            onClick={() => sendMessageForResponseGen(messageId)}>
                                        SEND FOR RESPONSE GENERATION
                                    </button>
                                </MenuItem>
                                }

                                {mainMessage.canBeLiked &&
                                <MenuItem onClick={handleClose}>
                                    <button type="button" className="btn btn-default btn-block"
                                            onClick={() => likeMessageFromSource(messageId, mainMessage.sourceName)}
                                    >
                                        LIKE MESSAGE
                                    </button>
                                </MenuItem>
                                }
                            </span>
                            :
                            <MenuList>
                                {showEditModalButton &&
                                <MenuItem className="update-menu-item">
                                    <button type="button" className="btn btn-default"
                                            disabled={mainMessage.sourceName === "instagram"}
                                            onClick={(e) => showModalHandle(e, mainMessage)}>
                                        <span className="glyphicon glyphicon-pencil"/> UPDATE
                                    </button>
                                </MenuItem>
                                }

                            </MenuList>
                        }

                        {isPositiveFeedback &&
                        <MenuList>
                            <MenuItem className="update-menu-item">
                                <button type="button" className="btn btn-default btn-block"
                                        onClick={
                                            (e) =>
                                                dispatchFunction(data, currPath)
                                        }>
                                    <span className="glyphicon glyphicon-camera"/> POSITIVE FEEDBACK
                                </button>
                            </MenuItem>
                        </MenuList>
                        }

                        {showScreenshotModal &&
                        <MenuList>
                            <MenuItem onClick={handleClose} className="update-menu-item">
                                <button type="button" className="btn btn-default btn-block"
                                        onClick={
                                            (e) =>
                                                showScreenshotModal(e, screenshotConstants.SCREENSHOT_TYPE_MISTAKE)
                                        }>
                                    <span className="glyphicon glyphicon-camera"/> Constructive Feedback
                                </button>
                            </MenuItem>
                        </MenuList>
                        }

                    </span>
                    :
                    <span></span>
                }
            </span>
        );
    };
}

export {MessageActionsV2}
