import React from 'react';
import {formatCustomerName, getCurrentUser} from '_helpers';
import {isEmpty} from 'lodash';
import ResponseRow from 'components/Employment/ResponseRow';
import MessageRow from 'components/Employment/MessageRow';
import {ToggleMenu} from "./ToggleMenu";
import {utilityActions} from "_actions";


/**
 * Renders a list of messages that an agent has been voting on.
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 12/11/2018 : 5:11 PM
 * @author Chris Okebata
 */
const Conversation = (props) => {
    const {
        agent,
        agentNickname,
        agentMessages,
        selectedMessageId,
        selectedFilterOption,
        approvedResponses,
        isConversationSelected,
        loading,
        positiveFeedbackData
    } = props;

    let body = (
        <p className="textEmptyState font-15 color-grey-mid">
            <span id="conversation-status">
                Select a message to view conversation
            </span>
        </p>
    );

    const dataForScreenshot = (agent, type, messageUrl, description, messageId) => {
        const agentId = agent && agent.id ? agent.id : null
        const reporterId = getCurrentUser().id;
        const rootUrl = utilityActions.getRootUrl();
        const data = new FormData();

        if (agentId) data.append("agentId", agentId);
        if (reporterId) data.append('reporterId', reporterId);

        data.append('messageUrl', messageUrl);
        data.append('description', description);
        data.append('clientRootUrl', rootUrl);
        data.append('typeName', type);
        data.append('messageId', messageId);

        return data
    };


    if (isConversationSelected && selectedMessageId && !isEmpty(agentMessages) && !loading) {
        const selectedMessage = agentMessages.agentMessage.messages.find(msge => (msge.id === selectedMessageId));
        const author = formatCustomerName(selectedMessage.author);
        const {expert, type, messageUrl, description, currPath, dispatchFunction} = positiveFeedbackData
        const data = dataForScreenshot(expert, type, messageUrl, description, selectedMessage.id)
        let positiveData = {};
        positiveData = {
            data: data,
            currPath: currPath,
            dispatchFunction: dispatchFunction
        }

        body = (
            <div>
                <div>

                    <div
                        style={{
                            padding: '7px 25px',
                            borderBottom: '1px solid #dee2e0',
                            background: '#FBFBFB',
                            position: 'sticky',
                            top: '0',
                            display: 'flex'
                        }}
                        className="font-14"
                    >
                        <div
                            style={{
                                width: '70%',
                                display: 'inline-block',
                                paddingTop: '15px',
                            }}
                            className="tms-ellipsis color-grey-dark"
                        >
                            Conversation with
                            {' '}
                            {author}
                        </div>

                        <div className="expert-view-conversation-link">
                            <ToggleMenu
                                selectedMessage={selectedMessage}
                                agent={agent}
                                nickname={agentNickname}
                                positiveFeedbackData={positiveData}
                            />
                        </div>
                    </div>

                </div>

                <div style={{
                    width: '100%',
                    height: '100vh',
                    overflowY: 'auto',
                    background: '#fff',
                }}
                >
                    {selectedMessage && MessageRow(selectedMessage, agent, agentNickname)}

                    {(selectedFilterOption === 'responses' || selectedFilterOption === 'reviewed') && approvedResponses
                    && approvedResponses[0] && ResponseRow(approvedResponses[0], agent, agentNickname)
                    }

                    <p className="textEmptyState font-13 color-grey-mid">
                        <span id="conversation-status">End of conversation</span>
                    </p>
                </div>
            </div>
        );
    }
    return (
        <div>
            {body}
        </div>
    );
};

export default Conversation;
