import {Box} from "@mui/system";
import styles from "../Utilities/templateStyles";
import UpdateTemplateForm from "./UpdateTemplateForm";

export default function CompanyEditTemplate() {

    return(
        <>
            <Box sx={styles.root}>
                <Box sx={styles.container}>
                    <Box sx={styles.containerBody}>
                        <Box sx={styles.header}>
                            Edit template
                        </Box>
                        <UpdateTemplateForm />
                    </Box>
                </Box>
            </Box>
        </>
    )
}