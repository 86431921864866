import React, {useState} from "react";
import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";
import CSMFilterSelect from "./CSMFilterSelect";
import styles from "../../organizationMainStyles";
import {accessibilityMode} from "../organizationSettingsConstants";
import CompanyInfoActions from "./CompanyInfoActions";
import {buildUpdateCSMPayload, useUserFullName} from "../organizationSettingsUtils";
import {SnackBar} from "../../../../../common";

export default function CSMContainer({organization, mode, csmList, currentCSM, loadingCSMInfo, label}) {
    const [organizationCSM, setOrganizationCSM] = useState(currentCSM);
    const organizationId = _.get(organization, 'id');
    const dispatch = useDispatch();
    const organizationSettings = useSelector(state => state.organizationSettings);
    const loading = organizationSettings.loadingOrganizationAttributeUpdate;
    const actionType = _.get(organizationSettings, 'payloadRef.actionType');
    const updatedCSM = _.get(organizationSettings, 'payloadRef.payload.customerSuccessManager');
    const ACTION_ID = 'csm_action';

    const handleSelectChange = (value) => {
        setOrganizationCSM(value);
    }

    const handleUpdate = () => {
        const payload = buildUpdateCSMPayload(organizationCSM);
        if(!organizationId || !payload) return SnackBar.showMessage('Invalid parameters');
        dispatch(organizationSettingsActions.updateOrganizationAttribute(organizationId, payload, ACTION_ID));
    }

    const handleCancel = () => {
        setOrganizationCSM(currentCSM);
    }

    const Content = () => {
        if(mode === accessibilityMode.EDIT) {
            return (
                <Box sx={{display: 'flex'}}>
                    <Grid container item xs={12} md={6} sx={styles.editableInfo}>
                        <Grid item xs={12} md={12} mr={'15px'}>
                            <CSMFilterSelect
                                csmList={csmList}
                                selectedCSM={organizationCSM}
                                updatedCSM={updatedCSM}
                                handleSelectChange={handleSelectChange}
                                loadingCSMInfo={loadingCSMInfo}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{mt: '15px'}}>
                        <CompanyInfoActions
                            id={ACTION_ID}
                            selectedId={actionType}
                            performAction={handleUpdate}
                            performCancel={handleCancel}
                            loading={loading}
                        />
                    </Box>
                </Box>
            )
        }
        return <Box sx={styles.infoItem}>{useUserFullName(currentCSM)}</Box>;
    }

    return(
        <>
            <Box component={'h2'} sx={styles.heading}>
                {label.CSM}
            </Box>
            <Content />
        </>
    )
}