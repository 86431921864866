import {Box} from "@mui/system";
import GorgiasSection from './GorgiasSection';
import SalesforceSection from "./SalesforceSection";
import SupervisedChatSection from "./SupervisedChatSection";
import KustomerSection from "./KustomerSection";

export default function DataSourceDetails({handle, mode, label}) {
    const isSalesforce = () => {
        return handle.msgSourceName?.toLowerCase()?.includes('salesforce');
    };

    return (
        <Box>
            { handle.supervisedChat.available && (<SupervisedChatSection handle={handle} mode={mode} label={label} />) }
            { handle.gorgias && (<GorgiasSection handle={handle} mode={mode} label={label} />) }
            { handle.kustomer && (<KustomerSection handle={handle} mode={mode} label={label} />) }
            { isSalesforce() && (<SalesforceSection handle={handle} mode={mode} label={label} />) }
        </Box>
    );
}
