import React, { forwardRef } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import importedStyles from './Utilities/styles'

export const UsersTableRow = forwardRef(( props, ref ) => {
	return (
		<TableRow ref={ref} {...props} sx={{ ...props.sx, '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
			{props.children}
		</TableRow>
	)
})

export const UsersTableCell = forwardRef(({ borderVertical, ...props }, ref ) => {
	return (
		<TableCell ref={ref} {...props} sx={{
			...props.sx,
			...importedStyles.root,
			...styles.tableBodyCell,
			...(borderVertical ? styles.borderVertical : {})
		}}>
			{props.children}
		</TableCell>
	)
})

/**
 * @param {object} params
 * @param {React.ReactNode} params.children
 * @param {string} params.label
 * @param {string[]} params.headLabels - (optional) array of strings for labelling the table head (e.g. ['Name', 'Ticket Volume', 'Since last week'])
 * @param {boolean} params.borderVertical - (optional, defaults to `false`) if the table should have a vertical+horizontal border.
 */
export default function UsersTable({
	children,
	label,
	headLabels,
	borderVertical = false,
}) {
	return (
		<TableContainer sx={styles.root}>
			<Table aria-label={label} size={'medium'}>
				{headLabels && headLabels.length ? (
					<TableHead>
						<TableRow>
							{headLabels.map((headLabel, key) => (
								<TableCell
									key={key}
									sx={{
										...importedStyles.root,
										...styles.tableHeadCell,
										...(key === 0 ? {} : { width: '1%', whiteSpace: 'nowrap' }),
										...(borderVertical ? styles.borderVertical : {})	}}>
									{headLabel}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
				): null}
				<TableBody>
          {children}
        </TableBody>
			</Table>
		</TableContainer>
	)
}

const styles = {
	root: {
		border: '1px solid #B6BEBF',
		borderRadius: '10px',
		background: '#fff',
		overflow: 'visible',
	},
	tableHeadCell: {
		p: { xs: '16px', md: '24px' },
		whiteSpace: 'nowrap',
		fontSize: '20px!important',
		fontFamily: 'LarsseitMedium',
	},
	tableBodyCell: {
		p: { xs: '16px', md: '24px' },
		whiteSpace: 'nowrap',
	},
	borderVertical: { ':not(:last-child)': { borderRight: '1px solid rgba(224, 224, 224, 1)' } },
}