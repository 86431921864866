import React, { Component } from 'react';
import { Loading } from '../common'
import { connect } from 'react-redux'
import { jiraActions } from '_actions';

class JiraOauthComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let url = new URL(document.location);
        let values = new URLSearchParams(url.search);
        const oauthCode = values.get('code');
        let redirectHost = localStorage.getItem('jiraHost');
        this.props.dispatch(jiraActions.completeOauthProcess(redirectHost, oauthCode));
    }

    render() {
        return <Loading />
    }
}

let jiraConnect = connect(null)(JiraOauthComponent);
export { jiraConnect as JiraOauthComponent }