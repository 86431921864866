import {authHeader, handleHttpResponse, handleHttpResponseAndReturnError, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';
import axios from "axios";
import {clientType} from "_constants";


/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 03/10/2018 : 12:59 PM
 */


function getAll(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/employments?${objectToQueryString(params)}`, requestOptions)
        .then(handleHttpResponse);
}


function getUserEmployments(userId, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/users/${userId}/employments?${objectToQueryString(params)}`, requestOptions)
        .then(handleHttpResponse);
}

function getOrgEmployments(params) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/organizations/${params.orgId}/employments?${objectToQueryString(params)}`, requestOptions)
        .then(handleHttpResponse);
}

function getEmployment(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/employments/${id}/show`, requestOptions)
        .then(handleHttpResponse);
}

function updateEmployment(employment) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(employment)
    };

    return fetch(`${SERVER_URL}/api/employments/${employment.id}/update`, requestOptions)
        .then(handleHttpResponse);
}

function removeEmployment(employment) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(employment)
    };

    return fetch(`${SERVER_URL}/api/employments/${employment.id}/remove`, requestOptions)
        .then(handleHttpResponse);
}

function createEmployment(employment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employment)
    };

    return fetch(`${SERVER_URL}/api/employments`, requestOptions)
        .then(handleHttpResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch('/employments/' + id, requestOptions)
        .then(handleHttpResponse);
}

const getOrganizationScore = (userId) => {
    const endpoint = `${SERVER_URL}/api/employments/score/user/${userId}`;

    return axios.get(endpoint, {
        headers: authHeader()
    });
};

const getOrgWithRecentMessages = (userId, handleIds) => {
    const endpoint = `${SERVER_URL}/api/employments/message/user`;

    return axios.post(endpoint, {userId, handleIds, clientType: clientType.WEB_APP},{
        headers: authHeader()
    });
};

function getEmploymentDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/employments/${id}/employmentDetails`, requestOptions)
        .then(handleHttpResponse);
}

function getOrgTeamLead(orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/employments/${orgId}/teamLead`, requestOptions)
        .then(handleHttpResponseAndReturnError);
}

function getOrgCsm(orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/employments/${orgId}/csm`, requestOptions)
        .then(handleHttpResponseAndReturnError);
}

function getEmploymentMetrics(params){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/employments/employmentMetrics?${objectToQueryString(params)}`, requestOptions)
        .then(handleHttpResponse);
}

function getConversationStats(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/employments/${id}/conversationStats`, requestOptions)
        .then(handleHttpResponse);
}

export const employmentService = {
    getUserEmployments,
    getOrgEmployments,
    getAll,
    getEmployment,
    updateEmployment,
    createEmployment,
    delete: _delete,
    removeEmployment,
    getOrganizationScore,
    getOrgWithRecentMessages,
    getEmploymentDetails,
    getOrgTeamLead,
    getOrgCsm,
    getEmploymentMetrics,
    getConversationStats
};

