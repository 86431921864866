
import {screenshotConstants} from "_constants"

const INITIAL_STATE = {
    data: [],
    current: {},
    loading: false,
    fileData: '',
    error: ''
};


export const screenshotsReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case screenshotConstants.GET_ALL_REQUEST:
            return {...state, loading: true};
        case screenshotConstants.GET_ALL_SUCCESS:
            return {...state, data: action.payload, loading: false};
        case screenshotConstants.GET_ALL_FAILURE:
            return {...state, error: action.error, loading: false};

        case screenshotConstants.GET_SCREENSHOT_REQUEST:
            return {...state, loading: true};
        case screenshotConstants.GET_SCREENSHOT_SUCCESS:
            return {...state, current: action.payload, loading: false};
        case screenshotConstants.GET_SCREENSHOT_FAILURE:
            return {...state, error: action.error, loading: false};

        case screenshotConstants.UPDATE_SCREENSHOT_REQUEST:
            return { ...state, loading: true};
        case screenshotConstants.UPDATE_SCREENSHOT_SUCCESS:
            return { ...state, current: action.payload, loading: false};
        case screenshotConstants.UPDATE_SCREENSHOT_FAILURE:
            return { ...state, error: action.error, loading: false};

        case screenshotConstants.SAVE_SCREENSHOT_REQUEST:
            return { ...state, loading: true};
        case screenshotConstants.SAVE_SCREENSHOT_SUCCESS:
            return { ...state, current: action.payload, loading: false};
        case screenshotConstants.SAVE_SCREENSHOT_FAILURE:
            return { ...state, error: action.error, loading: false};

        case screenshotConstants.SEND_EMAIL_REQUEST:
            return { ...state, loading: true};
        case screenshotConstants.SEND_EMAIL_SUCCESS:
            return { ...state, current: action.payload, loading: false};
        case screenshotConstants.SEND_EMAIL_FAILURE:
            return { ...state, error: action.error, loading: false};

        case screenshotConstants.DOWNLOAD_SCREENSHOT_REQUEST:
            return { ...state, loading: true};
        case screenshotConstants.DOWNLOAD_SCREENSHOT_SUCCESS:
            return { ...state, fileData: action.payload, loading: false};
        case screenshotConstants.DOWNLOAD_SCREENSHOT_FAILURE:
            return { ...state, error: action.error, loading: false};

        default:
            return state
    }
};
