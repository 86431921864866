import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import moment from 'moment'

/**
 * @author Pila Louis <louis@chatdesk.com>
 * @date 05/01/2021 : 11:07 AM
 */
class ListReamazeDataSources extends Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAllDataSources({dataSource: 'Reamaze'}));
    }

    toggleClosedTicketActioning = (postData) => {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.toggleClosedTicketActioning({...postData, msgDataSourceLabel: 'Reamaze'}));
    };

    render() {
        const { reamazeList, loading } = this.props;
        if (loading) {
            return <Loading/>
        }
        return (
            <div>
                <Row>
                    <Col xs={10} md={10}>
                        <table className='table trds-table'>
                            <thead className="font-18-bold">
                            <tr>
                                <th className="col-md-1">S/N</th>
                                <th>Name</th>
                                <th>Organization</th>
                                <th className="col-md-4">Close Ticket on (Reply/No Response Needed)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {reamazeList.map((dataSource, index) => (
                                <tr key={index}>
                                    <td className="vertical-align-middle">{index + 1}</td>
                                    <td className="vertical-align-middle">{dataSource.name}</td>
                                    <td className="vertical-align-middle">{dataSource.orgName}</td>
                                    <td>
                                        {dataSource.closeTicketOnReply &&
                                        <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnReply', enable: false})}>
                                            Disable Close Ticket on Reply
                                        </Button>
                                        }
                                        {!dataSource.closeTicketOnReply &&
                                        <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnReply', enable: true})}>
                                            Enable Close Ticket on Reply
                                        </Button>
                                        }
                                        {dataSource.closeTicketOnNoResponse &&
                                        <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnNoResponse', enable: false})}>
                                            Disable Close Ticket on No Response Needed
                                        </Button>
                                        }
                                        {!dataSource.closeTicketOnNoResponse &&
                                        <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnNoResponse', enable: true})}>
                                            Enable Close Ticket on No Response Needed
                                        </Button>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        );

    }
}

function mapStateToProps(state) {
    const { reamazeAuth: { list, loading } } = state;
    return {
        reamazeList: list, loading
    };
}

const connectedListReamazeDataSources = connect(mapStateToProps)(ListReamazeDataSources);
export { connectedListReamazeDataSources as ListReamazeDataSources};
