import React from 'react'
import {Link} from 'react-router-dom'

function NextButtonComponent(props) {

    const nextPage = props.to;
    return (
        <div style={{height: "35px", marginTop: "30px"}}>
            <div style={{width: "100px", height: "35px", float: "right", position: "relative", right: "47px"}}>
                <Link className="btn btn-success btn-block" to={nextPage}>Next</Link>
            </div>
        </div>
    )
}

export {NextButtonComponent as NextButton}

