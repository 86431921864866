/**
 * @author Chris Okebata
 */

import {conversationConstants} from '_constants';

const INITIAL_STATE = {
  emailConversationData: {},
  loadingEmailConversation: false,
  error: '',
};

export function fullEmailConversationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    case conversationConstants.GET_FULL_EMAIL_CONVERSATION_REQUEST:
      return { ...state, emailConversationData: [], loadingEmailConversation: true, error: '' };
    case conversationConstants.GET_FULL_EMAIL_CONVERSATION_SUCCESS:
      return { ...state, emailConversationData: action.payload, loadingEmailConversation: false };
    case conversationConstants.GET_FULL_EMAIL_CONVERSATION_FAILURE:
      return { ...state, error: action.error, loadingEmailConversation: false };

    case conversationConstants.CLEAR_CONVERSATION:
      return { ...state, emailConversationData: {} };

    default:
      return state;
  }
}
