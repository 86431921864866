import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { zendeskOauthActions, msgSourceActions } from '_actions';
import { getCurrentUser } from '_helpers';
import {SetupLink} from "components/OauthComponents";

class ZendeskContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(msgSourceActions.getMsgSourceLink('zendesk'));
  }

  onSubmit(props) {
    /**
         * make request to backend with subdomain to generate credentials
         */
    const callbackUrl = localStorage.getItem('redirectUrl');
    const url = JSON.parse(callbackUrl).href;
    const { id } = getCurrentUser();
    const params = { redirectUrl: url, subdomain: props.subdomain, userId: id };
    this.props.dispatch(zendeskOauthActions.initiateOauth(params));
  }

  oauthRedirect(authUrl) {
    window.open(authUrl);
  }

  render() {
    const { handleSubmit, data, msgSourceLink } = this.props;

    if (data.url) {
      this.oauthRedirect(data.url);
    }

    return (
        <div className="main-content-wrapper">
          <div className="content-pane-60 margin-0-auto">
            <div className="padding-20-0">
              <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <div>
                  <h1 className="msg-source-submit-header font-22-heavy color-grey-dark margin-bottom-30">Connect your Zendesk subdomain</h1>
                  <div>
                    <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">
                      <span>https://</span>
                      <Field
                          component="input"
                          type="text"
                          className="input-field font-16 msg-source-submit-input"
                          name="subdomain"
                      />
                      <span>.zendesk.com</span>
                    </label>
                  </div>
                  <button type="submit" className='btn btn-success font-16 msg-source-submit-button'>
                    Submit
                  </button>
                  <SetupLink msgSourceLink={msgSourceLink}/>
                </div>
              </form>
            </div>
          </div>
        </div>
    );
  }
}

const formConfig = {
  form: 'zendeskForm',
};

const mapStateToProps = (state) => {
  const { zendeskOauth, msgSources: { msgSourceLink } } = state;
  return {
    data: zendeskOauth.data,
    msgSourceLink: msgSourceLink.msgSourceLink
  };
};

const zendeskContainer = connect(
  mapStateToProps, null,
)(ZendeskContainer);

export default reduxForm(formConfig)(zendeskContainer);
