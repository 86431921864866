import {Panel, PanelGroup} from "react-bootstrap";
import _ from "lodash";
import React from "react";
import {ScheduleTable} from "./PanelTable";

export const SchedulePanel = (props) => {

    const extractHandleName = (handleId) => {
        if (handleId === "null") return "All Channels"
        return handleId.split('_')[1]
    }

    const {
        handleId,
        schedules,
        deleteSchedule,
        orgId,
        classes,
        panelStyles,
    } = props

    const {
        panel,
        panelTitleContainer,
        panelTitleContent
    } = panelStyles

    return (
        <PanelGroup accordion id="accordion">
            <Panel eventKey="1" style={panel}>
                <Panel.Title toggle className="panel-title">
                    <div style={panelTitleContainer}>
                        <div style={panelTitleContent}>
                            {_.capitalize(extractHandleName(handleId))}
                        </div>
                    </div>
                </Panel.Title>
                <Panel.Body collapsible style={{border: 'none'}}>
                    <div>
                        <ScheduleTable
                            schedules={schedules}
                            classes={classes}
                            deleteSchedule={deleteSchedule}
                            orgId={orgId}
                        />
                    </div>
                </Panel.Body>
            </Panel>
        </PanelGroup>
    )
}
