import  React, {Component} from 'react'
import {scheduleActions} from "_actions";
import {SchedulesList} from "./SchedulesList";
import {connect} from 'react-redux'
import {getCurrentUser} from "_helpers";

class SchedulePage extends Component {

    componentDidMount() {
        const currentUser = getCurrentUser();
        const orgId = currentUser._embedded.organization.id;
        const params = {groupByHandle: true}
        this.props.dispatch(scheduleActions.getAll(orgId, params))
    }

    deleteSchedule(data){
        this.props.dispatch(scheduleActions.deleteSchedule(data))
    }

    render() {
        const {schedules:{data, loading}} = this.props;
        return (
            <SchedulesList
                orgSchedules={Object.entries(data)}
                isLoading={loading}
                deleteSchedule={this.deleteSchedule.bind(this)}
            />
        )
    }
}


const mapStateToProps = (state) => {

    const {schedules} = state;
    return {
        schedules
    }
};

const connectedSchedulesPage = connect(mapStateToProps)(SchedulePage);
export { connectedSchedulesPage as SchedulesPage }