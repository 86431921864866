import React, { Component, Fragment } from "react";
import { GenericContextMenu } from "components/common";
import _ from "lodash";
import {filterObjectValue, isObjectEmpty, getArrayFromObjectArray} from "_helpers";

/**
 * @author Chris Okebata
 */

class MultiSelectContextMenu extends Component{

  constructor(props){
    super(props);

    this.state = {
      checkedItemsValues: [],
      checkedItemsNames: [],
      searchField: '',
      searchedOption: [],
    }
  }

  componentDidMount() {
    let { checkedOptions } = this.props;

    if(checkedOptions && checkedOptions.constructor === Array){
      checkedOptions = checkedOptions.map((index) => {
        if(!isNaN(index)) {
          return parseInt(index, 10);
        }
        return index
      });

      this.setState({ checkedItemsValues: checkedOptions});
    }
  }

  /**
   * Search items
   * @param event
   */
  search = (event) => {
    const { name, value } = event.target;
    const { options } = this.props;
    this.setState({ [name]: value });

    let result = options && options.filter(option => {
      return option.name.toLowerCase().includes(value.toLowerCase())
    });

    this.setState({searchedOption: result});
  };

  /**
   * Select all the items from the checkbox list
   * @param arg
   * @returns {Function}
   */
  selectAll = (arg) => (event) => {
    let allValues = [], allNames = [], value, name;
    let { checkedItemsValues, checkedItemsNames } = this.state;

    checkedItemsValues.length = 0;
    checkedItemsNames.length = 0;

    const target = event.target;
    if(target.checked){
      arg.map(index => {
        value = _.get(index, 'value');
        if(!isNaN(value)) {
          value = parseInt(value, 10);
        }
        name = _.get(index, 'name');
        allValues.push(value);
        allNames.push(name);
        return {allValues, allNames}
      });
    }

    this.setState({ checkedItemsValues: allValues, checkedItemsNames: allNames });
    this.props.handleChange(allValues);
  };

  /**
   * Deselect any previously selected items and select only the currently clicked items
   * @param value
   * @param name
   * @returns {Function}
   */
  selectOnly = (value, name) => (event) => {
    let { checkedItemsValues, checkedItemsNames } = this.state;
    if(!isNaN(value)) {
      value = parseInt(value, 10);
    }
    checkedItemsValues.length = 0;
    checkedItemsNames.length = 0;

    checkedItemsValues.push(value);
    checkedItemsNames.push(name);

    this.setState({ checkedItemsValues, checkedItemsNames });
    this.props.handleChange(this.state.checkedItemsValues);
  };

  /**
   * Select an item from the checkbox list
   * @param event
   */
  selectItem = (event) => {
    const target = event.target;
    let value = _.get(target, 'value');

    if(!isNaN(value)) {
      value = parseInt(value, 10);
    }
    const name = _.get(target, 'name');
    let { checkedItemsValues, checkedItemsNames } = this.state;

    if(target.checked){
      checkedItemsValues.push(value);
      checkedItemsNames.push(name);
    }
    else {
      checkedItemsValues.splice(checkedItemsValues.indexOf(value), 1);
      checkedItemsNames.splice(checkedItemsNames.indexOf(name), 1);
    }
    this.setState({ checkedItemsValues, checkedItemsNames });
    this.props.handleChange(this.state.checkedItemsValues);
  };

  /**
   * Determine if all items are checked
   * @param allItems
   * @param selectedItems
   * @returns {boolean}
   */
  isAllChecked = (allItems, selectedItems) => {
    let _isAllChecked = selectedItems.length !== 0 && selectedItems.length === allItems.length;
    let isSubset = false;

    if(_isAllChecked){
      let _allValues = getArrayFromObjectArray(allItems, 'value');
      isSubset = selectedItems.every(function(val) {
        return _allValues.indexOf(val) >= 0;
      })
    }

    return isSubset;
  };

  /**
   * Converts the name array to an alphabetically ordered string
   * @param nameArr
   * @returns {*}
   */
  processItemNames = (nameArr) => {
    if(nameArr && nameArr.length === 0) return '';
    return nameArr.filter(Boolean).sort().join(', ')
  };

  render(){

    let { displayName, options, checkedOptions, caretPosition, displayAllItemsCheckbox=true } = this.props;
    let { checkedItemsValues, checkedItemsNames, searchField, searchedOption } = this.state;
    let value, name, tempFilteredObject = [], filteredNameArr = [];
    options = isObjectEmpty(searchField) ? options : searchedOption;
    let isAllChecked = this.isAllChecked(options || {}, checkedItemsValues || []);

    if(checkedOptions && checkedOptions.constructor === Array){
      checkedOptions.map((index) => {
        if(!isObjectEmpty(index)){
          index = isNaN(index) ? index : parseInt(index, 10);
          tempFilteredObject = filterObjectValue(options || [], 'value', index);

          filteredNameArr.push(_.get(tempFilteredObject, '0.name'));
        }

        return filteredNameArr
      });
    }

    return(
      <Fragment>
        <GenericContextMenu trianglePosition={caretPosition ? caretPosition : "left"} scrollable={true} customStyle={{padding: '20px 1px', marginBottom: '20px', minWidth: '300px'}}>
          <div className="select-field tms-ellipsis color-grey-dark" key="trigger">{ (this.processItemNames(filteredNameArr) || this.processItemNames(checkedItemsNames) ) || `Select ${displayName}` }</div>

          {isObjectEmpty(searchField) && isObjectEmpty(options) ?

            <ul key="header" className="font-15" style={{padding: '0 0 20px 15px', margin: '0', borderBottom: '1px solid #E6E5E5', textAlign: 'left'}}>
              No <span style={{textTransform: 'lowercase'}}>{displayName} found</span>
            </ul> :

            <ul key="header" style={{padding: '0px 15px', margin: '0', borderBottom: '1px solid #E6E5E5'}}>
              <div className="align-center">
                <input type="text" className='search-field' name="searchField" value={searchField}
                       onChange={(event) => this.search(event)} onClick={(event) => {
                  event.stopPropagation()
                }} style={{width: '100%', height: '40px'}} placeholder={'Search'}/>
              </div>
              <div style={{display: 'flex', padding: '12px 0 0 0', margin: '0', justifyContent: "space-between"}}>
                <label className="font-15 padding-bottom-10" style={{position: 'relative', left: '-13px'}}>

                  {
                    !isObjectEmpty(searchField) && isObjectEmpty(searchedOption) ?
                      <span className="color-grey-dark" style={{padding: '0 15px'}}>No search result</span> :

                      <Fragment>
                        {displayAllItemsCheckbox && <div>
                          <input type="checkbox" name={'select_all'} value={-1} checked={isAllChecked}
                                 onChange={this.selectAll(options)} className="tms-checkbox tms-child-checkbox"/>
                          <span className="tms-check-mark"/>
                          <span className="tms-ellipsis" style={{
                            color: `${isAllChecked ? 'rgb(19, 160, 186)' : 'rgb(90, 95, 94)'}`,
                            marginLeft: '5px',
                            paddingRight: '15px',
                            maxWidth: '500px',
                            position: 'relative',
                            top: '-2px'
                          }}>
                            All {displayName}
                          </span>
                        </div>
                        }
                      </Fragment>
                  }
                </label>
              </div>

            </ul>
          }

          <ul key="body" onClick={ (event) => {event.stopPropagation()} }>

            {options && options.map((option) => {

              value = _.get(option, 'value');
              name = _.get(option, 'name');

              let isChecked = checkedItemsValues.includes(value);

              return(
                <div key={value} className="tms-multi-select">
                  <label key={ value } className="context-menu-label font-15 padding-bottom-10 align-center">

                    <input type="checkbox" name={ name } checked={isChecked} value={ value } onChange={ this.selectItem }  className="tms-checkbox tms-child-checkbox"/>
                    <span className="tms-check-mark" />
                    <span className="tms-ellipsis" style={{color: `${isChecked ? 'rgb(19, 160, 186)' : 'rgb(90, 95, 94)'}`, marginLeft: '5px', maxWidth: '400px'}} title={ name }>
                      { name }
                    </span>

                  </label>
                  <span style={{width: '50px'}} className="color-green tms-cursor-pointer tms-only-text" onClick={ this.selectOnly(value, name) }/>
                </div>
              );
            })}

          </ul>
        </GenericContextMenu>
      </Fragment>
    )
  }
}

export default MultiSelectContextMenu;