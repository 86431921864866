import {Fragment} from 'react'
import { useSelector } from 'react-redux'
import logoFrontapp from 'components/images/logos/logo_frontapp.svg'
import ChannelCard from '../../ChannelCard'
import useFrontappSetup from './useFrontappSetup'
import FrontappSetupInboxesModal from './FrontappSetupInboxesModal'
import DataSourceWebhookModal from '../../Utilities/DataSourceWebhookModal'

export default function FrontappEntry() {
  const frontApp = useSelector((state) => state.frontAppOauth)
	const { initiateFrontappSetup, frontappSetupInboxesOpen, handleFrontappSetupInboxesClose } = useFrontappSetup({ 
		callbackPath: '/frontapp/accounts'
	})
	return (
		<Fragment>
			<ChannelCard
				icon={logoFrontapp}
				label={'Frontapp'}
				description={'Clear your email ticket backlog. Select the inboxes you would your Chatdesk team to focus on'}
				onClick={initiateFrontappSetup}
			/>

			<FrontappSetupInboxesModal
				open={frontappSetupInboxesOpen && !frontApp?.showWebhookUrl}
				handleClose={handleFrontappSetupInboxesClose}
			/>

			<DataSourceWebhookModal
				showWebhookUrl={frontApp?.showWebhookUrl}
				webhookUrl={frontApp?.webhookUrl}
				platformUrl={frontApp?.platformUrl}
				dataSourceName={'Frontapp'}
			/>
		</Fragment>
	)
}