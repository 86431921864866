import useViewActiveTags from "./hooks/useViewActiveTags";
import BasicFilterMultiSelect from "../../../../../../common/BasicFilterMultiSelect";

export default function TagFilterSelect({onChange, selectedTags}) {
    const { activeTags } = useViewActiveTags();

    return(
        <BasicFilterMultiSelect
            placeholder={'Choose'}
            options={activeTags}
            defaultOptions={selectedTags}
            onChange={onChange}
            listBoxHeight={300}
            maxLimit={5}
        />
    )
}