import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, {Fragment} from "react";
import {makeStyles} from "@mui/styles";
import {selectStyles} from "./selectStyles";
import _ from "lodash";
import {isValueEmpty} from "_helpers";

export default function BasicSelect({input, label, defaultValue, placeholder, options, id, name, onChange, handleFormSelectChange = (val) => {}, meta}) {
  const classes = useStyles();
  const touched = _.get(meta, 'touched');
  const error = _.get(meta, 'error');
  const warning = _.get(meta, 'warning');
  const hasError = touched && !_.isEmpty(error);
  const placeholderValue = isValueEmpty(defaultValue) ? 'none' : defaultValue;
  const [item, setItem] = React.useState(placeholderValue);
  const [selectOptions, setSelectOptions] = React.useState(options);

  const handleChange = (event) => {
    if (typeof onChange === 'function') {
      onChange(event);
    }
    setItem(event.target.value);
  };

  const handleRenderValue = (selected) => {
      setSelectOptions(options);
      if(!(_.isEqual(_.sortBy(options || []), _.sortBy(selectOptions || [])))) {
          handleFormSelectChange(placeholderValue);
          return;
      }

    const res = options.find(item => item.id  === selected)
    return res && res.name || placeholder;
  };

  // if(input.value === "") input.value = placeholderValue;

  const renderMenuItems = (item) => {
    return <MenuItem
      key={item.id}
      input={input}
      value={item.id}
      style={{fontSize: '16px'}}>
      {item.name}
    </MenuItem>
  };

  return(
    <Fragment>
      <TextField className={ (hasError || warning) ? classes.textFieldError : classes.textField }
                 InputProps={{ classes: { root: classes.textField } }}
                 inputProps={{ classes: { icon: classes.icon } }}
                 SelectProps={{
                   value: item,
                   onChange: handleChange,
                   renderValue: handleRenderValue
                 }}
                 id={id}
                 name={name}
                 label={label}
                 variant="outlined"
                 select
                 {...input}
                 error={ hasError }>
        <MenuItem value={placeholderValue} disabled sx={{display: 'none'}}>
          {placeholder}
        </MenuItem>
        { options ? options.map(renderMenuItems) : <MenuItem/> }
      </TextField>
    </Fragment>
  )
}

const useStyles = makeStyles(selectStyles);