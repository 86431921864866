import React from "react";
import {Box} from "@mui/system";
import {buttonContained} from '../../Utilities/ButtonContained';
import TermsCheckboxLabel from "../../Utilities/TermsCheckboxLabel";
import BasicTextField from "../../Utilities/BasicTextField";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import PasswordGroup from "./PasswordGroup";
import {Field, reduxForm} from 'redux-form'
import { required, validate } from "_helpers";
import VerticalSelect from "./VerticalSelect";
import {useDispatch, useSelector} from "react-redux";
import {authorizationActions} from "_actions";


function SignupForm({ handleSubmit }) {
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(false);
  const { SignupButton } = buttonContained;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleVisibility = (value) => {
    setActive(value);
  };

  const handleSignup = (values) => {
    dispatch( authorizationActions.signup(values) );
  };

  const authorization = useSelector((state) => state.authorization);
  const { signupLoading } = authorization;

  return(
    <Box style={ isMobile ? styles.formContainerMobile :
      (active ? styles.formContainerWithPasswordChecker : styles.formContainer) }>

      <Box style={styles.requiredLabel}
           display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block'}}>
        All fields required
      </Box>

      <form name="form" onSubmit={handleSubmit(handleSignup)} autoComplete="off">
        <Box sx={{marginTop: '10px'}}>
          <Field
            name="firstName"
            label="First Name"
            component={BasicTextField}
            validate={required}
          />
        </Box>

        <Box sx={{marginTop: '15px'}}>
          <Field
            name="lastName"
            label="Last name"
            component={BasicTextField}
            validate={required}
          />
        </Box>

        <Box sx={{marginTop: '15px'}}>
          <Field
            name="company"
            label="Company name"
            component={BasicTextField}
            validate={required}
          />
        </Box>

        <Box sx={{marginTop: '15px'}}>
          <Field
            name="vertical"
            label="Industry"
            component={VerticalSelect}
          />
        </Box>

        <Box sx={{marginTop: '15px'}}>
          <Field
            name="email"
            label="Work email"
            component={BasicTextField}
            validate={required}
          />
        </Box>

        <Box sx={{marginTop: '15px'}}>
          <Field
            name="password"
            component={PasswordGroup}
            handleVisibility={ handleVisibility }
            validate={required}
          />
        </Box>

        <Box sx={{marginTop: '7px'}}>
          <TermsCheckboxLabel checked={true}/>
        </Box>

        <Box style={styles.buttonContainer}>
          <SignupButton label={'Next'}
            disabled={signupLoading}
            isLoading={signupLoading}
          />
        </Box>
      </form>
    </Box>
  )
}

const styles = {
  formContainer: {
    height: 'auto',
    marginTop: '155px',
    marginBottom: '50px',
  },
  formContainerMobile: {
    height: 'auto',
    marginTop: '30px',
    marginBottom: '30px'
  },
  formContainerWithPasswordChecker: {
    height: 'auto',
    marginTop: '30px',
    marginBottom: '50px',
  },
  requiredLabel : {
    textAlign: 'right',
    fontSize: '14px',
    lineHeight: '120%',
    color: '#0C2728',
    fontFamily: 'larsseitLight',
    fontStyle: 'italic'
  },
  buttonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: "flex-end"
  },
};

const formConfig = {
  form: 'CompanySignupForm',
  initialValues: { isCompanyAdmin: true, terms: true },
  validate,
};

export default reduxForm(formConfig)(SignupForm);