import {utilityService, gorgiasMsgDataSourceService} from '_services';
import {alertMessages, msgDataSourceConstants} from "../_constants";
import {SnackBar} from "../components/common";

function update(msgDataSourceId, data, handleId) {
    function request(handleId) {
        return {type: msgDataSourceConstants.UPDATE_GORGIAS_MSG_DATA_SOURCE_REQUEST, handleId: handleId};
    }

    function success() {
        return {type: msgDataSourceConstants.UPDATE_GORGIAS_MSG_DATA_SOURCE_SUCCESS};
    }

    function failure(error) {
        return {type: msgDataSourceConstants.UPDATE_GORGIAS_MSG_DATA_SOURCE_FAILURE, error: error};
    }

    return (dispatch) => {
        dispatch(request(handleId));
        return gorgiasMsgDataSourceService.update(msgDataSourceId, data)
            .then(
                response => {
                    dispatch(success());
                    SnackBar.showMessage(alertMessages.DATA_SOURCE_HANDLE_UPDATE_SUCCESS);
                },
                errorPromise => {
                    errorPromise.then(error => {
                        dispatch(failure(error));
                        SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                    });
                },
            );
    };
}

export const gorgiasMsgDataSourceActions = {
    update
};
