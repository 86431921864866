import { useEffect } from 'react'
import {useDispatch, useSelector} from "react-redux";
import {metricsActions} from "_actions";
import {ImpactMetricsBuilder} from "../objects/ImpactMetricsBuilder";
import _ from "lodash";

export default function (startDate, endDate) {
  const dispatch = useDispatch();

  useEffect(() => {
    const { organization_id = '' } = JSON.parse(localStorage.user);
    dispatch(metricsActions.getImpactMetrics(organization_id, startDate, endDate));
  }, [startDate, endDate])

  const metrics = useSelector(state => state.metrics);
  const impactMetrics = metrics.impactMetrics;
  const expertsTicketMetrics = metrics.expertsTicketMetrics; //Get allSolvedTicketsCount
  const loadingImpactMetrics = metrics.loadingImpactMetrics;
  const loadingExpertsTicketMetrics = metrics.loadingExpertsTicketMetrics;
  const builder = new ImpactMetricsBuilder();

  if(!loadingImpactMetrics){
    builder.setEstimatedTimeSavedPerCustomerInMin(_.get(impactMetrics, 'estimatedTimeSavedPerCustomerInMin'));
    builder.setTicketsSolvedAmount(_.get(expertsTicketMetrics, 'allSolvedTicketsCount'));
    builder.setTicketsSolvedMonthToDateAmount(_.get(impactMetrics, 'ticketsSolvedMonthToDateAmount'));
    builder.setTicketsHiddenAmount(_.get(impactMetrics, 'ticketsHiddenAmount'));
  }
  if(!loadingExpertsTicketMetrics){
    builder.setTicketsSolvedAllTime(_.get(impactMetrics, 'ticketsSolvedAllTime'));
  }

  const data = builder.metrics || {};
  return { data, loadingImpactMetrics }
}