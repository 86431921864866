import React, { Component } from 'react';
import { getCurrentUser } from '_helpers';
import { freshdeskAuthActions, msgSourceActions } from '_actions';
import connect from 'react-redux/es/connect/connect';
import { Field, reduxForm } from 'redux-form';
import { TextField, InputGroup } from 'components/common';
import { DataSourceWebhookModal } from 'components/AccountSettings';
import {SetupLink} from "components/OauthComponents";

const inputWidth = {
  width: '60%',
  minWidth: 300,
};
class FreshdeskConnect extends Component {

  componentDidMount() {
    this.props.dispatch(msgSourceActions.getMsgSourceLink('freshdesk'));
  }

  onSubmit(props) {
    const { id } = getCurrentUser();
    const { dispatch } = this.props;
    const params = { ...props, userId: id };
    dispatch(freshdeskAuthActions.verifyKey(params));
  }

  render() {
    const { handleSubmit, valid, submitting, showWebhookUrl, webhookUrl, platformUrl, msgSourceLink } = this.props;

    return (
      <div className="main-content-wrapper">
        <div className="content-pane-60 margin-0-auto">
          <div className="padding-20-0">
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <div>
                <h1 className="msg-source-submit-header margin-bottom-30">Connect your Freshdesk Account</h1>
                <div style={inputWidth}>
                  <Field
                    component={TextField}
                    label="Freshdesk Support Email"
                    placeHolder="e.g. support@companyname.com"
                    addonClassName="background-white"
                    className="font-16 width-60"
                    name="supportEmail"
                  />

                  <Field
                    component={InputGroup}
                    label="Freshdesk Subdomain"
                    placeHolder="e.g. companyname"
                    addonText=".freshdesk.com"
                    addonClassName="background-white"
                    className="font-16 width-60"
                    name="subdomain"
                  />

                  <Field
                    component={TextField}
                    label="API Key"
                    placeHolder="e.g ABCDEFGHI23456789"
                    className="font-16 width-60"
                    name="apiKey"
                  />
                </div>
                <button type="submit" className="btn btn-success font-16 msg-source-submit-button" disabled={! valid || submitting}>
                  Submit
                </button>
                <SetupLink msgSourceLink={msgSourceLink}/>
              </div>
            </form>
          </div>
        </div>
        <DataSourceWebhookModal
          dataSourceName="Freshdesk"
          showWebhookUrl={showWebhookUrl}
          webhookUrl={webhookUrl}
          platformUrl={platformUrl}
        />
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.subdomain) {
    errors.subdomain = 'Field cannot be null!!!';
  }

  if (!values.apiKey) {
    errors.apiKey = 'Field cannot be null!!!';
  }

  return errors;
}

const formConfig = {
  form: 'freshdeskForm',
  validate,
  touchOnBlur: false,
};

function mapStateToProps(state) {
  const { freshdeskAuth: { data: { webhookUrl, platformUrl }, showWebhookUrl }, msgSources: { msgSourceLink }} = state;
  return {
    showWebhookUrl,
    webhookUrl,
    platformUrl,
    msgSourceLink: msgSourceLink.msgSourceLink
  }
}

const freshdeskConnect = connect(mapStateToProps)(FreshdeskConnect);

export default reduxForm(formConfig)(freshdeskConnect);
