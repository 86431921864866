import React from "react";
import {filterOptions} from '../MetricsDetailUtils';

export const FilterOptionsDropdown = ({onSelectChangeHandler}) => {
    return (
        <div style={{
            display: 'inline-block',
            width: '237px',
            height: 'auto',
            borderRadius: '8px',
            marginRight: '12px',
            fontFamily: 'LarsseitRegular',
            fontSize: '14px',
            fontWeight: '500',
            border: '1px solid #9EA9A9',
            backgroundColor: '#FFF',
            position: 'absolute',
            left: '0',
            top: '46px',
            zIndex: '10'
        }}>
            {filterOptions.map(option =>
                <div
                    key={option.value}
                    className="filter-options-div"
                     onClick={() => onSelectChangeHandler(option)}>
                    {option.label}
                </div>
            )}

        </div>
    )
}