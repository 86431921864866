import { Box } from '@mui/material';

/**
 * Almost the same as client/src/components/v2/views/Channels/Utilities/ChannelListItem.jsx,
 * so maybe we can merge this and use a global component in the future.
 * This Component renders a channel (facebook, twitter, reamaze, etc.) item.
 * @param {Object} props
 * @param {import('react').ReactNode} props.buttonChildren (optional)
 * @param {string} props.label
 * @param {string} props.description
 * @param {'default' | 'small'} props.iconSize	(optional)
 * @param {string} props.iconImageSrc (optional)
 */
export default function ChannelListItem({
	buttonChildren = undefined,
	label,
	description,
	iconSize = 'default',
	iconImageSrc = undefined,
}) {
	return (
		<Box sx={styles.root}>
      {iconImageSrc ? (
        <Box sx={styles.logoWrapper}>
          <Box component={'img'} sx={{...styles.logo, ...(iconSize === 'small' ? styles.logoWrapperSizeSmall : styles.logoWrapperSizeDefault)}} src={iconImageSrc} alt={`${label} Logo`} />
        </Box>
      ) : null}
      <Box sx={styles.content}>
        <Box>
          <Box component={'h2'} sx={styles.contentLabel}>
            {label}
          </Box>
          <Box component={'p'} sx={styles.p}>
            {description}
          </Box>
        </Box>

				{buttonChildren && (
					<Box sx={styles.buttonContainer}>
						{buttonChildren}
					</Box>
				)}
      </Box>
    </Box>
	)
}

const styles = {
	root: {
    display: 'flex',
    alignItems: { xs: 'center', sm: 'flex-start' },
  },
	p: { m: 0, fontFamily: 'LarsseitRegular', fontSize: 18, lineHeight: 1.2, letterSpacing: '-0.02em', color: '#0C2728' },
  logoWrapper: {
    flexShrink: 0,
    alignSelf: { xs: 'flex-start', md: 'center' },
    mr: { xs: 1.5, sm: 4 },
  },
	logoWrapperSizeDefault: {
		height: { xs: '40px', sm: '60px' },
    width: { xs: '40px', sm: '60px' },
	},
	logoWrapperSizeSmall: {
		height: '43px',
    width: '43px',
	},
	logo: {
		height: '100%',
    width: '100%',
	},
  content: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { xs: 'column', sm: 'row' },
  },
  contentLabel: {
    fontFamily: 'LarsseitBold',
    fontSize: 14,
    lineHeight: 1.2,
    letterSpacing: '0.05em',
    color: '#0C2728',
    textTransform: 'uppercase',
    mt: 0,
    mb: { xs: 0, sm: 0.5 },
  },
  buttonContainer: {
    alignSelf: { xs: 'flex-start', sm: 'center' },
		width: { xs: '100%', sm: 'auto' },
    ml: { xs: 0, sm: 2 },
    mt: { xs: 1, sm: 0 },
  }
}