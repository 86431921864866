import {attributesService} from "_services";
import {alertMessages, attributesConstants} from "_constants";
import {SnackBar} from "../components/common";

function getAttributes(params) {
    return dispatch => {
        dispatch(request());

        attributesService.getAttributes(params)
            .then(
                attributes => dispatch(success(attributes)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: attributesConstants.GET_ATTRIBUTES_REQUEST }
    }

    function success(attributes) {
        return { type: attributesConstants.GET_ATTRIBUTES_SUCCESS, attributes }
    }

    function failure(error) {
        return { type: attributesConstants.GET_ATTRIBUTES_FAILURE, error }
    }
}

function putAttributes(data) {
    return dispatch => {
        dispatch(request());

        attributesService.putAttributes(data)
            .then(
                res => {
                    dispatch(success())
                    SnackBar.showMessage(alertMessages.ATTRIBUTES_UPDATED_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: attributesConstants.PUT_ATTRIBUTES_REQUEST }
    }

    function success() {
        return { type: attributesConstants.PUT_ATTRIBUTES_SUCCESS }
    }

    function failure(error) {
        return { type: attributesConstants.PUT_ATTRIBUTES_FAILURE, error }
    }
}

export const attributesActions = {
    getAttributes,
    putAttributes,
};
