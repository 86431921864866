import React, { Component } from 'react'
import { employmentActions, organizationActions, utilityActions } from '_actions';
import { Loading } from 'components/common';
import { SelectMultipleCheckbox } from 'components/common/CustomFormFields/SelectMultipleCheckbox';
import { JoinOrganizationModal } from 'components/Employment';
import { connect } from 'react-redux';
import { Button, Image, Table } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import {getCurrentUser, removeItemFromArray, ensureValidHref, getLogoUrl} from '_helpers';
import {employmentStatus as status, formModes, publicAndFeaturedVisibility, workMode as mode} from '_constants';
import { Link } from 'react-router-dom';
import logoPlaceholder from 'components/images/icon_business.png'
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 10/01/2019 : 2:51 PM
 */
let selectedVerticals = [];
class EmployersPage extends Component {

    state = {
        formMode: '',
        showModal: false,
        showEditBioModal: false,
    };

    componentDidMount() {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        this.props.dispatch(organizationActions.getAll({
            user: currentUser.id,
            visibility: publicAndFeaturedVisibility,
        }));
        this.props.dispatch(utilityActions.getVerticals());
    }

    /**
     * Handles the closing of the
     * employment confirmation modal.
     */
    closeModalHandler = () => {
        this.setState({ showModal: false });
    };

    /**
     * Updates state to render join organization or
     * edit bio modal.
     * @param event
     * @param selectedOrg
     * @param formMode
     */
    showModalHandler = (event, selectedOrg, formMode) => {
        event.preventDefault();

        let joinOrganizationPayload = {
            organization: selectedOrg.id,
            user: getCurrentUser().id,
            mode: mode.PRACTICE,
            status: status.UNEMPLOYED,
            organizationName: selectedOrg.name
        };

        if (formMode === formModes.EDIT) {
            const { employment: { id, nickname, bio } } = selectedOrg._embedded;

            joinOrganizationPayload = {
                organization: selectedOrg.id,
                employmentId: id,
                employmentNickname: nickname,
                employmentBio: bio,
                organizationName: selectedOrg.name
            }
        }

        this.setState({
            formMode,
            showModal: true,
            joinOrganizationPayload
        });
    };

    /**
     * Dispatches an event to create an employment
     * @param params
     */
    saveEmploymentHandler = (params) => {

        if (params.id) {
            this.props.dispatch(employmentActions.updateEmployment(params));
        } else {
            this.props.dispatch(employmentActions.createEmployment(params));
        }

        this.closeModalHandler()
    };

    /**
     * Add or removes checked/unchecked items to/from the selectedVerticals
     * @param name
     * @param value
     */
    handleVerticalSelect = (name, value) => {
        if(value){
            selectedVerticals.push(name);
        } else { removeItemFromArray(selectedVerticals, name); }
    };

    /**
     * Filter employers based on selected verticals
     */
    FilterEmployers = () => {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        this.props.dispatch(organizationActions.getAll({
            user: currentUser.id,
            visibility: publicAndFeaturedVisibility,
            verticals: selectedVerticals
        }));
    };
    /**
     * Render a row in the table showing organizations
     * @param organization
     * @returns {*}
     */
    renderRow = (organization) => {

        const { employment } = organization._embedded;
        let actionButton;
        if (employment) {
            actionButton = <Button
                bsStyle="default"
                className='tms-join-org-btn'
                onClick={(e) => this.showModalHandler(e, organization, formModes.EDIT)}>
                Edit Bio
            </Button>
        } else {
            actionButton = <Button
                bsStyle="success"
                className="tms-join-org-btn"
                onClick={(e) => this.showModalHandler(e, organization, formModes.ADD)}>
                Get Started
            </Button>
        }

        return (
            <tr key={organization.id} className="bg-on-hover" style={{ height: '120px'}}>
                <td className="vertical-align-middle" style={{ width: '25%', paddingLeft: '30px' }}>
                    <Image className="logo-style" responsive
                           src={organization.logo ? getLogoUrl(organization.logo) : logoPlaceholder}
                           rounded />
                </td>
                <td className="vertical-align-middle color-grey-dark" style={{ width: '55%' }}>
                    <Link to='#' style={{ color: '#828282', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                        {organization.name}
                        {organization.visibility === "FEATURED" &&
                        <span className="featured-tag">
                            <FontAwesomeIcon icon={faFlag} />
                            <span style={{paddingLeft: '10px'}}>Featured</span>
                        </span>}
                    </Link>
                    <div
                        style={{marginTop: '15px', maxWidth:'400px'}}
                        className="vertical-align-middle tms-truncate-3-lines">{organization ? organization.overview : ''}
                    </div>
                </td>
                <td align="center" className="vertical-align-middle">
                    <div className="employers-bio-area">
                        {actionButton}
                        <a  className="color-green font-14-bold"
                            href={ensureValidHref(organization.website)}
                            rel="noopener noreferrer"
                            target='_blank'>Learn More
                        </a>
                    </div>
                </td>
            </tr>
        );
    };

    render() {
        const { organizations: { organizations }, loading, verticals } = this.props;
        let joinOrganizationModal;
        if (this.state.showModal && this.state.joinOrganizationPayload) {
            joinOrganizationModal = <JoinOrganizationModal
                onSave={this.saveEmploymentHandler}
                payload={this.state.joinOrganizationPayload}
                buttonText="Join"
                onHide={this.closeModalHandler}
                show={this.state.showModal} />
        }

        return (
            <div className="container-pane">
                <div className="side-bar-pane shadow-border font-14 color-grey-dark">
                    <p style={{fontWeight: '600'}}>Filter by Industry</p>
                    {verticals && verticals.map((vertical) => {
                        return <SelectMultipleCheckbox
                            key={vertical.id}
                            checkedItem={vertical.id}
                            itemName={vertical.name}
                            checkedItemList={selectedVerticals}
                            setValues={this.handleVerticalSelect}
                         />
                    })}

                    <Button
                        bsStyle="success"
                        className="tms-employers-apply-btn"
                        onClick={this.FilterEmployers}>
                        Apply
                    </Button>
                </div>
                <div className='employers-grid-area'>
                    {loading && <Loading/>}

                    {isEmpty(organizations) &&
                    <div className="font-20-bold tms-align-text-center">
                        No companies available for this industry
                    </div>}

                    {!isEmpty(organizations) && <div
                          style={{
                              backgroundColor: 'white',
                              border: 'solid 1px lightGrey',
                              borderRadius: '4px',
                              width: '100%'
                          }}>

                        {joinOrganizationModal}

                            <Table responsive style={{marginBottom: '0'}}>
                                <tbody>
                                {organizations && organizations.sort((a, b) => (a.visibility > b.visibility) ? 1 : -1).map(this.renderRow)}
                                </tbody>
                            </Table>
                    </div>}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { organizations: { organizations, loading }, utility } = state;

    return {
        organizations,
        loading,
        verticals: utility.data.verticalsList
    };
}

const connectedEmployersPage = connect(mapStateToProps)(EmployersPage);
export { connectedEmployersPage as EmployersPage };
