import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

export default function EditButton({onClick}){
    return(
        <IconButton component={'button'} size={'small'} onClick={onClick} >
            <EditIcon sx={{color:'#07857C', fontSize: '20px'}}/>
        </IconButton>
    )
}