import React, {Fragment} from "react";
import PropTypes from "prop-types";


/**
 * @author Chris Okebata
 */

const renderOptions = (item, id, name) => {
  return <option key={item[id]} value={item[id]}>{item[name]}</option>
};

const SelectField = (props) => {

  const {input, label, placeholder, placeholderVisible, options, optionPropertyId, optionPropertyName, required, meta: { touched, error, warning }} = props;

  return(
    <Fragment>
      <div style={styles.info}>
        <label htmlFor="">{label}</label>
        <span className="font-12 color-danger" style={{marginTop: 3}}>
          {touched &&
          ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </span>
      </div>
      <div>
        <label id="drop-down-arrow-down">
          <select {...input} className="select-field" required={required}>
            <option key='' hidden={! placeholderVisible}>{placeholder}</option>
            {options ? options.map((item) => renderOptions(item, optionPropertyId, optionPropertyName)) : null}
          </select>
        </label>
      </div>
    </Fragment>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

const styles = {
  info: {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'},
};

export {SelectField}

