import {IconButton, Link, Menu, TableCell, TableRow, Typography} from "@material-ui/core";
import React, {useState} from "react";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {ChatdeskUpdatesActions} from "./Actions";
import {HtmlTooltip} from "../common";

export const ChatdeskUpdatesRow = ({update, classes}) => {
    const {
        summary,
        isActive,
        dateCreated,
        organization: {name},
        creator: {username},
        googleDocsLink
    } = update

    const statusText = isActive ? "Enabled" : "Disabled"
    const zone_name =  moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();
    const link = <Link href={googleDocsLink} target="_blank">View In Google Doc</Link>

    const [anchorElement, setAnchorElement] = useState(undefined)
    const handleClick = (event) => setAnchorElement(event.currentTarget)
    const handleClose = () => setAnchorElement(undefined)
    const open = Boolean(anchorElement);

    return (
        <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell} >
                <Typography className={classes.typography13}>
                    <span>{name}</span>
                </Typography>
            </TableCell>

            <TableCell className={classes.tableCell}>
                <Typography className={classes.typography13}>
                    <span>
                      {`${moment(dateCreated).format('MMM DD hh:mm A z ')} ${timezone}`}
                    </span>
                </Typography>
            </TableCell>

            <TableCell className={classes.tableCell}>
                <Typography className={classes.typography13}>
                    <span>{username}</span>
                </Typography>
            </TableCell>

            <HtmlTooltip
                title={
                    <div style={{width: 'fit-content', maxWidth: '700px', padding: '10px'}}>
                        <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                            {summary}
                        </Typography>
                    </div>
                }
                placement={'bottom'}
                style={{opacity: 1}}
                enterDelay={700}
            >
                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography13}>
                        <span>{summary}</span>
                    </Typography>
                </TableCell>
            </HtmlTooltip>

            <TableCell className={classes.tableCell}>
                <Typography className={classes.typography13}>
                    <span>{statusText}</span>
                </Typography>
            </TableCell>

            <TableCell className={classes.tableCell}>
                <Typography className={classes.typography13}>
                    <span>{googleDocsLink ? link : 'No Link available'}</span>
                </Typography>
            </TableCell>

            <TableCell className={classes.tableCell}>
                <IconButton
                    aria-label="More"
                    aria-owns={open ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    style={{padding: "7px", fontSize: 2}}
                >
                    <MoreVertIcon size="small"/>
                </IconButton>

                    <Menu
                        id="long-menu"
                        anchorEl={anchorElement}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                                minWidth: 150,
                                border: "solid 1px #dee2e0",
                                boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)"
                            },
                        }}
                    >
                        <ChatdeskUpdatesActions
                            update={update}
                        />

                    </Menu>
            </TableCell>

        </TableRow>
    )
}