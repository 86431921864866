import React from "react";
import {MetricsMessagesBox} from "./MetricsMessagesBox";
import {useSelector} from "react-redux";
import {
    ANSWER_RATE_PAGE_FILTER,
    CSAT_PAGE_FILTER,
    PRESALE_PAGE_FILTER,
    RESPONSE_TIME_PAGE_FILTER
} from "../Utilities/MetricsDetail.constants";

export const MetricsMessageBoxContainer = ({pageFilter, selectedChannels, setPageParams, pageParams}) => {
    const allMetricsDetailData = useSelector(state => state.metrics);

    const filterTypeToMessageDetails = {
        [RESPONSE_TIME_PAGE_FILTER]: {
            messages: allMetricsDetailData.responseTimesDetails.data,
            count: allMetricsDetailData.responseTimesDetails.count,
            loading: allMetricsDetailData.loadingResponseTimesDetails
        },
        [CSAT_PAGE_FILTER]: {
            messages: allMetricsDetailData.qualityScoreMessages.data,
            count: allMetricsDetailData.qualityScoreMessages.count,
            loading: allMetricsDetailData.loadingQualityScoreMessages
        },
        [PRESALE_PAGE_FILTER]: {
            messages: allMetricsDetailData.preSaleMessages.data,
            count: allMetricsDetailData.preSaleMessages.count,
            loading: allMetricsDetailData.loadingPreSaleMessages
        },
        [ANSWER_RATE_PAGE_FILTER]: {
            messages: allMetricsDetailData.customerAnsweredMessages.data,
            count: allMetricsDetailData.customerAnsweredMessages.count,
            loading: allMetricsDetailData.loadingCustomerAnsweredMessages
        },
    }

    return (
        <MetricsMessagesBox
            selectedChannels={selectedChannels}
            messageList={filterTypeToMessageDetails[pageFilter].messages}
            allMessagesCount={filterTypeToMessageDetails[pageFilter].count}
            eachPageMessagesCount={filterTypeToMessageDetails[pageFilter].messages?.length}
            loading={filterTypeToMessageDetails[pageFilter].loading}
            setPageParams={setPageParams}
            pageParams={pageParams}
            pageFilter={pageFilter}
        />
    )
}