import React from 'react'

let date = new Date()

class CopyrightFooter extends React.Component{
    render(){
        return(
            <div className="footer-login">
                <span className="copyright">&copy; {date.getFullYear()}</span> Chatdesk | <a href="https://chatdesk.com/privacy.html" rel="noopener">Privacy</a>
            </div>
        )
    }
}

export {CopyrightFooter}