import React from 'react';

const CriteriaResponseCheckboxes = ({
    criteriaOptions,
    setSelectedCheckboxes,
    selectedCheckboxes,
    criteriaHeading,
}) => {
    const handleCheckboxChange = (checked, value) => {
        let currentCriteria = [...selectedCheckboxes]

        if (checked) {
            currentCriteria.push(value)
            setSelectedCheckboxes(currentCriteria)
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter(a => a !== value));
        }
    };
    return (
        <div className="criteria-filter">
            <span className="criteria-heading">{criteriaHeading}</span>
            {criteriaOptions.map((option, index) =>
                <label key={index} className="label" htmlFor={option.name} style={{marginTop: '5px'}}>
                    {option.value}
                    <input
                        type="checkbox"
                        name="criteriaFilterCheckbox"
                        id={option.name}
                        checked={selectedCheckboxes.includes(option.name)}
                        onChange={(e) => handleCheckboxChange(e.target.checked, option.name)}
                    />
                    <span className="checkmark"/>
                </label>
            )}
        </div>
    )
}

export {CriteriaResponseCheckboxes}