import {Box} from "@mui/system";
import AccordionItem from 'components/v2/common/AccordionItem';
import DataSourceDetails from './DataSourceDetails';
import DataSourceSummary from './DataSourceSummary';

export default function DataSource({handle, mode, label}) {
    return (
        <AccordionItem
                key={handle.id}
                summaryComponent={<DataSourceSummary handle={handle} mode={mode} label={label} />}>
            <DataSourceDetails handle={handle} mode={mode} label={label} />
        </AccordionItem>
    );
}
