import React, {Component, Fragment} from 'react';
import LoadingPlaceholderBlock from "../../common/LoadingPlaceholderBlock";


/**
 * @author Chris Okebata
 */


const styles = {
  bgColor: '#e8e7e7',
};

class SearchConversationEmptyState extends Component{


  render() {

    const { bgColor } = styles;

    return(
      <Fragment>
        <div className="font-14" style={{width: '100%', height: '40px', display: 'flex', alignItems: 'center', padding: '0px 35px', borderBottom: '1px solid #dee2e0', backgroundColor: '#f3f3f3'}}>
          <div className="search-back-button">
            <LoadingPlaceholderBlock width={'30px'} height={'30px'} borderRadius={'100%'} backgroundColor={bgColor}/>
          </div>
          <span className="color-grey-dark" style={{display: 'flex', alignItems: 'center'}}>
            <LoadingPlaceholderBlock width={'150px'} height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
          </span>
        </div>

        <div style={{height: 'calc(100% - 110px)', backgroundColor: '#fafafa'}}>
          <div style={{height: 'calc(100%)', position: 'relative'}}>
            <div style={{padding: '20px 35px', width: '100%', height: 'calc(100%)', position: 'absolute', top: '0'}}>
              <LoadingPlaceholderBlock width={'100%'} height={'200px'} borderRadius={'4px'} backgroundColor={bgColor}/>
            </div>
          </div>
        </div>

      </Fragment>
    )
  }

}

export { SearchConversationEmptyState };