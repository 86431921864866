export const authConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    VERIFY_EMAIL_REQUEST: 'VERIFY_USERS_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_USERS_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'VERIFY_USERS_EMAIL_FAILURE',

    CONFIRM_EMAIL_REQUEST: 'CONFIRM_USERS_EMAIL_REQUEST',
    CONFIRM_EMAIL_SUCCESS: 'CONFIRM_USERS_EMAIL_SUCCESS',
    CONFIRM_EMAIL_FAILURE: 'CONFIRM_USERS_EMAIL_FAILURE',

    PASSWORD_RESET_REQUEST: 'RESET_PASSWORD_REQUEST',
    PASSWORD_RESET_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    PASSWORD_RESET_FAILURE: 'RESET_PASSWORD_FAILURE',

    UPDATE_USER_PHOTO: 'UPDATE_USER_PHOTO',
    UPDATE_ORG_PHOTO: 'UPDATE_ORG_PHOTO',
    
    SAVE_UPDATED_USER: 'SAVE_UPDATED_USER',

    LOGOUT: 'USER_LOGOUT',

};


export const userRoles = {
    CHATDESK_ADMIN_ROLE: 'ROLE_CHATDESK_ADMIN',
    CHATDESK_REVIEWER_ROLE: 'ROLE_CHATDESK_REVIEWER',
    COMPANY_ADMIN_ROLE: 'ROLE_COMPANY_ADMIN',
    COMPANY_USER_ROLE: 'ROLE_COMPANY_USER',
    CHATDESK_AGENT_ROLE: 'ROLE_CHATDESK_AGENT',
    BILLING_ADMIN_ROLE: 'ROLE_BILLING_ADMIN',
};