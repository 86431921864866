import React from 'react'
import {Panel, PanelGroup} from "react-bootstrap";
import {styles} from './styles'
import arrowDown from "components/images/baseline-keyboard_arrow_down-24px.svg";
import _ from 'lodash'
import {DataSourceHandles} from "components/Settings";
import {collectHandles, sourceDeleted, sourceAdded} from "_helpers";
import { snakeCase } from "snake-case"
import  TikTokSearchModal  from "./TikTokSearchModal"

const {
    panel,
    panelBodyContainer,
    panelTitleContainer,
    panelTitleContent,
    panelBody,
    panelCol
} = styles;

const getSourceImgUrl = (name) => {
    const sourceName = name ? snakeCase(name) : '';

    try {
        return require(`components/images/${sourceName}.png`)
    } catch (e) {
        try {
            return require(`components/images/${sourceName}.svg`)
        } catch (e) {
            return require('components/images/icon_business.png')
        }
    }
};

const showDataSources = (dataSources, dispatchMsgDataSources) => {
    dispatchMsgDataSources(dataSources)
};

const MsgSource = ({dataSources, onClick, msgSource, dispatchMsgDataSources}) => {

    const handles = collectHandles(dataSources);
    const showChildren = handles && handles.length > 0;
    const added = sourceAdded(dataSources);
    const sourceImg = getSourceImgUrl(msgSource.name);
    const deleted = sourceDeleted(dataSources);
    const isTikTok = msgSource.name === "TikTok";

    if (!deleted)
        return (
            <PanelGroup accordion id="accordion" key={msgSource.id}>
                <Panel eventKey="1" style={panel}>
                    <Panel.Title toggle className="panel-title">
                        <div style={panelTitleContainer}>
                            <div style={panelTitleContent}>
                                <div className="icon-container-small">
                                    <img src={_.get(sourceImg, 'default')} alt="Settings Icon"/>
                                </div>
                                <div className="font-16-bold color-grey-dark padding-0-15">
                                    {_.capitalize(msgSource.name)}
                                </div>
                            </div>

                            <div className="tms-cursor-pointer">
                                <img src={arrowDown} alt={''}/>
                            </div>
                        </div>
                    </Panel.Title>
                    <Panel.Body collapsible style={panelBody}>
                        <div style={panelBodyContainer}>
                            {showChildren ? <DataSourceHandles handles={handles} onClick={onClick}/> : null}
                        </div>

                        {
                            added ?
                                <div
                                    style={{
                                        ...panelCol,
                                        padding: "0 30px",
                                        justifyContent: "flex-end",
                                        color: "#0695ba"
                                    }}
                                    onClick={() => showDataSources(dataSources, dispatchMsgDataSources)}
                                >
                                    Remove from teams
                                </div>
                                :
                               null
                        }
                        {
                            isTikTok ?
                                <div
                                    style={{
                                        ...panelCol,
                                        padding: "0 30px",
                                        justifyContent: "flex-end",
                                        color: "#0695ba"
                                    }}
                                >
                                    <TikTokSearchModal
                                        msgDataSources={dataSources}
                                    />
                                </div>
                                :
                                null
                        }
                    </Panel.Body>
                </Panel>
            </PanelGroup>
        );
    else
        return null
};

export const MsgSources = ({ onClick, msgSources, dispatchMsgDataSources}) => {
    return (
        msgSources.map(msgSource => {
            return (
                <MsgSource
                    key={msgSource.id}
                    msgSource={msgSource}
                    onClick={onClick}
                    dataSources={msgSource.msgDataSources}
                    dispatchMsgDataSources={dispatchMsgDataSources}
                />
            )
        })
    )
};
