import React, {Fragment, useEffect, useState} from "react";
import {getDatabase, onValue, ref} from "firebase/database";
import _ from "lodash";
import {getCurrentUser, getWorkMode, refreshPage} from "_helpers";
import app from "../../../../../../firebase.config";
import MessageUnavailableModal from "../../../../../Vote/v2/MessageUnavailableModal";
import {workMode} from "_constants";

export default function FirebaseMessageListener({message}) {
    const userWorkMode = getWorkMode();
    if(userWorkMode === workMode.PRACTICE) {
        return <Fragment />;
    }
    const [state, setState] = useState({});
    const { id } = getCurrentUser();
    const isSameUser = id === _.get(state, 'lockedBy');
    const isSameMessage = _.get(state, 'id') === _.get(message, 'id');
    const isCollidedMessage = isSameMessage && !isSameUser;
    const openModal = (isCollidedMessage || state.actioned || state.hasEmployeeSeen || state.removedFromSource) || false;

    useEffect(() => {
        const database = getDatabase(app);
        const databaseRef = ref(database, `active_messages/${message.id}`);
        onValue(databaseRef, (snapshot) => {
            if(snapshot.exists()) {
                setState(snapshot.val())
            }
        })
        return database;
    },[]);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") return false;
        if (reason === "escapeKeyDown") return false;
        refreshPage();
    };

    return(
        <MessageUnavailableModal
            open={openModal}
            handleClose={handleClose}
            message={"This message is no longer available to work on"} />
    )
}