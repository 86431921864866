import {useRef, useEffect, useCallback} from "react";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {utilityActions} from "_actions";
import {isTypeEmail} from "_helpers";
import {hasVerticalScroll} from "../../../utilities/Component.utils";

export default function(message, conversation, loading, isEmbed) {
    const ref = useRef();
    const dispatch = useDispatch();
    const isEmail = isTypeEmail(_.get(message, 'dataSourceHandle.type'));

    const handleScroll = useCallback(() => {
        const offset = 100;
        const { scrollTop, scrollHeight, clientHeight } = _.get(ref, 'current') || {};
        const height = scrollTop + clientHeight + offset;
        console.log("height: ",height);
        console.log("scrollTop: ",scrollTop);
        console.log("clientHeight: ",clientHeight);
        console.log("scrollHeight: ",scrollHeight);
        console.log("height >= scrollHeight: ",height >= scrollHeight);
        console.log("hasVerticalScroll: ",hasVerticalScroll(ref));
        if(height >= scrollHeight) {
            console.log("Scroll complete");
            dispatch(utilityActions.setVerticalScrollComplete(true));
        }
    }, [ref]);

    useEffect(() => {
        // emails are rendered with an iframe, and the iframe height is resized after
        // the content has been loaded, so this can only work with non-emails
        if(!isEmbed || !isEmail) {
            if(loading) dispatch(utilityActions.setVerticalScrollComplete(false));
            if(!_.isEmpty(conversation) && !hasVerticalScroll(ref)) dispatch(utilityActions.setVerticalScrollComplete(true));
        }
    }, [loading]);

    useEffect(() => {
        if(ref) {
            ref.current && ref.current.addEventListener("scroll", handleScroll);
            return () => ref.current && ref.current.removeEventListener("scroll", handleScroll);
        }
    }, [ref, handleScroll]);
    return { ref };
};