import {Fragment} from "react";
import {Box} from "@mui/system";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";
import styles from "../organizationMainStyles";

export default function NotificationsEmptyState() {
    return(
        <Fragment>
            <Box sx={styles.sectionWrapper}>
                <Box sx={{width: '155px', mt: '12px'}}>
                    <LoadingPlaceholderBlock
                        height={'16px'}
                        borderRadius={'5px'}
                        backgroundColor={'#d7d2cf'}
                    />
                </Box>
                <Box sx={{width: '155px', mt: '32px'}}>
                    <LoadingPlaceholderBlock
                        height={'16px'}
                        borderRadius={'5px'}
                        backgroundColor={'#d7d2cf'}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}