import * as React from 'react';
import {Box} from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from "@mui/material/CircularProgress";

export default function CompanyInfoActions({ id, selectedId, performAction = ()=>{}, performCancel = ()=>{}, loading }) {
    const isUpdating = loading && id === selectedId;

    const handleUpdate = () => {
        performAction();
    }

    const handleCancel = () => {
        performCancel();
    }

    return (
        <Box sx={style.actionButtonsContainer}>
            <Box sx={{width: '30px'}}>
                {
                    isUpdating ?
                        <Box sx={style.iconContainer}>
                            <CircularProgress size={18} sx={style.spinnerIcon}/>
                        </Box> :
                        <IconButton size={'small'} onClick={handleUpdate} disabled={loading}>
                            <CheckIcon sx={style.actionButtonIcon}/>
                        </IconButton>
                }
            </Box>
            <Box sx={{ml: '5px'}}>
                <IconButton size={'small'} onClick={handleCancel} disabled={loading}>
                    <CloseIcon sx={style.actionButtonIcon}/>
                </IconButton>
            </Box>
        </Box>
    )
}

const style = {
    actionButtonsContainer: {
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: 'center',
    },
    actionButtonIcon: {
        color:'#07857C',
        fontSize: '20px',
        stroke: '#07857C',
        strokeWidth: 0.7
    },
    iconContainer: {
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spinnerIcon: {
        color:'#07857C',
    },
}