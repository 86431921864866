import React, {useEffect} from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { Logo } from "../../Utilities/ChatdeskLogo";
import {descriptions} from "../../Utilities/Descriptions";
import SignupSteps from "./SignupSteps";
import SignupForm from "./SignupForm";
import {history} from "_helpers";
import ReactGA from "react-ga";
import {GOOGLE_ANALYTICS_TRACKING_ID} from "_constants";


export default function CompanySignup() {
  if (JSON.parse(localStorage.getItem('user'))) history.push('/dashboard');
  const {ChatdeskSymbol, ChatdeskWordMark} = Logo;
  const {GetStartedDescription, GetStartedDescriptionMobile} = descriptions;

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, []);

  return(
      <Grid container sx={{background: "#FFFFFF", minHeight: '100vh'}}>

        {/**************** Parent Left *****************/}
        <Grid item xs={12} sm={12} md={6} lg={6}
              order={{xs: 2, sm: 2, md: 1, lg: 1}}
              backgroundColor="#EFEAE7"
              display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
              px={{md: '35px', lg: '50px'}}
        >
          <ChatdeskSymbol/>

          <Box sx={{height: 'auto', marginTop: '100px'}}>
            <GetStartedDescription/>
          </Box>
        </Grid>

        {/**************** Parent right *****************/}
        <Grid item xs={12} sm={12} md={6} lg={6}
              order={{xs: 1, sm: 1, md: 2, lg: 2}}
              px={{xs: '20px', sm: '20px', md: '35px', lg: '50px'}}
              sx={{backgroundColor: "#FFFFFF", height: "fit-content"}}
        >
          <Box mt={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}} sx={{display: 'flex'}}>
            <ChatdeskWordMark/>
            <SignupSteps
                isGetStartedActive={true}
                isConnectChannelActive={false}
                isScheduleCallActive={false}/>
          </Box>

          <GetStartedDescriptionMobile/>
          <SignupForm/>
        </Grid>
      </Grid>
  )
}