import React from "react";

export default function useCustomerIoSetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const performAction = () => {
    handleOpen();
  };

	return {
		initiateCustomerIoSetup: performAction,
		customerIoSetupOpen: open,
		handleCustomerIoSetupOpen: handleOpen,
		handleCustomerIoSetupClose: handleClose
	};
}