import {roleConstants, alertMessages} from '_constants';
import {roleService} from '_services';
import {history} from "_helpers";
import {SnackBar} from "../components/common";

export const roleActions = {
    createRole,
    updateRole,
    getAll,
    getRole,
    setCurrentRole,
    delete: _delete
};

function getAll(params) {
    return dispatch => {
        dispatch(request());

        roleService.getAll(params)
            .then(
                roles => dispatch(success(roles)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return {type: roleConstants.GET_ALL_REQUEST}
    }

    function success(roles) {
        return {type: roleConstants.GET_ALL_SUCCESS, roles}
    }

    function failure(error) {
        return {type: roleConstants.GET_ALL_FAILURE, error}
    }
}

/**
 * Dispatches actions involves in a role
 * submission, processing and potential failure.
 *
 * @param role
 * @returns {function(*)}
 */
function updateRole(role) {
    return dispatch => {
        dispatch(request(role));

        roleService.updateRole(role)
            .then(
                role => {
                    dispatch(success(role));
                    history.push(`/roles`);
                    SnackBar.showMessage(alertMessages.SAVE_ROLE_SUCCESS)
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(role) {
        return {type: roleConstants.SAVE_USER_REQUEST, role}
    }

    function success(role) {
        return {type: roleConstants.SAVE_USER_SUCCESS, role}
    }

    function failure(error) {
        return {type: roleConstants.SAVE_USER_FAILURE, error}
    }
}

/**
 * Dispatches actions involves in a role
 * submission, processing and potential failure.
 *
 * @param role
 * @returns {function(*)}
 */
function createRole(role) {
    return dispatch => {
        dispatch(request(role));

        roleService.createRole(role)
            .then(
                role => {
                    dispatch(success(role));
                    history.push(`/roles`);
                    SnackBar.showMessage(alertMessages.SAVE_ROLE_SUCCESS)
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(role) {
        return {type: roleConstants.SAVE_ROLE_REQUEST, role}
    }

    function success(role) {
        return {type: roleConstants.SAVE_ROLE_SUCCESS, role}
    }

    function failure(error) {
        return {type: roleConstants.SAVE_ROLE_FAILURE, error}
    }
}

/**
 * Dispatches the 'getRole' action.
 * @param id
 * @returns {function(*)}
 */
function getRole(id) {
    return dispatch => {
        dispatch(request(id));

        roleService.getRole(id)
            .then(
                role => dispatch(success(role)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(id) {
        return {type: roleConstants.GET_DETAILS_REQUEST, payload: id}
    }

    function success(role) {
        return {type: roleConstants.GET_DETAILS_SUCCESS, payload: role}
    }

    function failure(error) {
        return {type: roleConstants.GET_DETAILS_FAILURE, payload: error}
    }
}

/**
 * Sets the current Role of the logged in user
 * @param id
 * @returns {function(*)}
 */
function setCurrentRole(id) {
    return dispatch => {
        dispatch(success(id))
    };

    function success(roleId) {
        return {type: roleConstants.SET_CURRENT_ROLE, payload: roleId}
    }
}



// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        roleService.delete(id)
            .then(
                role => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) {
        return {type: roleConstants.DELETE_REQUEST, id}
    }

    function success(id) {
        return {type: roleConstants.DELETE_SUCCESS, id}
    }

    function failure(id, error) {
        return {type: roleConstants.DELETE_FAILURE, id, error}
    }
}

