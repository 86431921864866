/********************************************************************************
 * This file collects all the application alert messages in one place,
 * making it easy to keep track of and maintain.
 * The alert messages are group according to the different modules of the project.
 *
 * @author Teyim Pila <pila@chatdesk.com>
 **********************************************************************************/



export const alertMessages = {
    REGISTRATION_SUCCESSFUL: 'Registration was successful. Please Check your email to activate your account',
    // REGISTRATION_SUCCESSFUL: 'Registration was successful. Please log in to start using Teams',
    INVALID_USERNAME: 'Invalid username or password',
    DEACTIVATE_ALL_SUCCESS: 'Action Successful',

    SAVE_EMPLOYMENT_SUCCESS: 'Team update completed',
    CREATE_EMPLOYMENT_SUCCESS: 'Thanks! You can now start practicing on messages',

    UPDATE_ORGANIZATION_SUCCESS: 'Update complete',
    SAVE_ROLE_SUCCESS: 'Role Saved',
    SYSTEM_UPDATE_SUCCESS: 'System Settings Saved',

    VERIFY_EMAIL_SUCCESS: 'We sent you an email',
    VERIFY_EMAIL_FAILURE: 'Invalid email format',

    SAVE_USER_SUCCESS: 'User Saved',
    SAVE_USER_FAILURE: 'There was an error creating the User',

    SAVE_USER_BY_ADMIN_SUCCESS: 'User saved by Admin successfully',
    SAVE_USER_BY_ADMIN_FAILURE: 'There was an error when updating the user by Admin',
    SAVE_USER_BY_ADMIN_PASSWORD_FAILURE: 'There was an error updating the user password by Admin',

    SEARCH_USER_SUCCESS: 'User search successful',
    SEARCH_USER_FAILURE: 'There was an error searching for the User',

    UPDATE_USER_PHOTO_SUCCESS: 'Photo updated successfully',
    UPDATE_USER_PHOTO_FAILURE: 'Unable to upload Photo',

    DELETE_USER_PHOTO_SUCCESS: 'Photo deleted successfully',
    DELETE_USER_PHOTO_FAILURE: 'Unable to deleted Photo',

    UPDATE_PASSWORD_SUCCESS: 'Password updated successfully',
    UPDATE_PASSWORD_FAILURE: 'Unable to update password',

    SUBMIT_VOTE_SUCCESS: 'Thanks for your response!',
    ESCALATION_SUCCESS: 'Thanks for your escalation',
    MESSAGE_SKIP_SUCCESSFUL: 'Thanks for letting us know',
    MESSAGE_SKIP_UNSUCCESSFUL: 'There was a problem with this action',
    GET_NEXT_MESSAGE_FAILURE: 'No new messages. Please try another company',
    ADMIN_SEARCH_FAILURE: 'There was an error searching admin messages',
    EXPORT_ADMIN_SEARCH_FAILURE: 'There was an error searching admin messages',
    MESSAGE_ACTIONED_SUCCESS: "Thanks for your action!",

    TWITTER_OAUTH_FAILURE: 'Unable to connect your Twitter account',
    TWITTER_OAUTH_SUCCESS: 'Your Twitter account is now connected',

    ZENDESK_OAUTH_FAILURE: 'Unable to connect your Zendesk account',
    ZENDESK_OAUTH_SUCCESS: 'Your Zendesk account is now connected',

    GORGIAS_OAUTH_FAILURE: 'Unable to connect your Gorgias account',
    GORGIAS_OAUTH_SUCCESS: 'Your Gorgias account is now connected',

    FACEBOOK_OAUTH_FAILURE: 'Unable to connect your Facebook page',
    FACEBOOK_OAUTH_SUCCESS: 'Your Facebook page is now connected',

    PAGES_LENGTH_ERROR:'Please select at least one page to connect',

    INSTAGRAM_OAUTH_FAILURE: 'Unable to connect your Instagram page',
    INSTAGRAM_OAUTH_SUCCESS: 'Your Instagram account is now connected',

    MSG_DATA_SOURCE_DELETE_FAILURE: 'Unable to delete channel',
    MSG_DATA_SOURCE_DELETE_SUCCESS: 'Channel removed from Teams',

    DATA_SOURCE_HANDLE_UPDATE_SUCCESS: 'Channel updated completed',

    DISPATCH_REQUEST: "Sending Message",
    DISPATCH_SUCESS: 'Message sent!',
    DISPATCH_FAILURE: 'Unable to send message',

    DISPATCH_DELETE_SUCCESS: 'Message deleted successfully',
    DISPATCH_DELETE_FAILURE: 'Failed to delete message',

    LIKE_MESSAGE_SUCCESS: 'Message liked successfully',
    LIKE_MESSAGE_FAILURE: 'Failed to like message',

    DISPATCH_UPDATE_SUCCESS: 'Message updated successfully',
    DISPATCH_UPDATE_FAILURE: 'Failed to update message',

    DISPATCH_BLACKLIST_SUCCESS: 'Message blacklisted successfully',
    DISPATCH_BLACKLIST_FAILURE: 'Failed to blacklist message',

    MESSAGE_DELETED_FROM_SOURCE: 'This message has been deleted from source',

    SCHEDULE_SUCCESS: 'Schedule added successfully',
    SCHEDULE_FAILURE: 'Failed to add schedule',


    SCREENSHOT_SUCCESS: 'Screenshot added successfully',
    SCREENSHOT_FAILURE: 'Failed to add screenshot',
    DOWNLOAD_SCREENSHOT_FAILURE: "Failed to download screenshot",

    SCREENSHOT_MAIL_SUCCESS: 'Mail sent successfully',
    SCREENSHOT_MAIL_FAILURE: 'Failed to send email for screenshot',

    SCREENSHOT_UPDATE_SUCCESS: 'Screenshot updated successfully',
    SCREENSHOT_UPDATE_FAILURE: 'Failed to update screenshot',

    SCREENSHOT_DOWNLOAD_SUCCESS: 'Screenshot downloaded successfully',
    SCREENSHOT_DOWNLOAD_FAILURE: 'Failed to download screenshot',

    SCREENSHOT_FETCH_SUCCESS: 'Successfully fetched screenshots',
    SCREENSHOT_FETCH_FAILURE: 'Error fetching screenshots',

    SCHEDULE_DELETE_SUCCESS: 'Schedule deleted successfully',
    SCHEDULE_DELETE_FAILURE: 'Failed to delete schedule',

    FRONTAPP_OAUTH_FAILURE: 'Unable to connect your Frontapp account',
    FRONTAPP_OAUTH_SUCCESS: 'Your Frontapp account is now connected ... Choose Inboxes',
    FRONTAPP_INBOX_SUCCESS: 'Selected Inboxes have been saved',

    STRIPE_OAUTH_FAILURE: 'Unable to connect your Stripe account',
    STRIPE_OAUTH_SUCCESS: 'Your Stripe account is now connected',

    CREATE_PAYOUT_SUCCESS: 'New Payout Rate successfully created',
    UPDATE_PAYOUT_SUCCESS: 'Payout Rate successfully updated',

    CREATE_PRICING_SUCCESS: 'New Pricing Rate successfully created',
    UPDATE_PRICING_SUCCESS: 'Pricing Rate successfully updated',

    ORGS_SCHEDULE_SUCCESS: 'Organizations with schedules retrieved',
    ORGS_SCHEDULE_FAILURE: 'Error retrieving organizations with schedules',

    SCHEDULE_UPDATE_SUCCESS: 'Schedule updated successfully',
    SCHEDULE_UPDATE_FAILURE: 'Error updating Schedule',

    SLACK_OAUTH_FAILURE: 'Unable to connect your Slack account',
    SLACK_OAUTH_SUCCESS: 'Your Slack account is now connected',

    SLACK_OAUTH_DISCONNECT_FAILURE: 'Unable to disconnect your Slack account',
    SLACK_OAUTH_DISCONNECT_SUCCESS: 'Your Slack account is now disconnected',


    GORGIAS_AUTH_FAILURE: 'Unable to connect your Gorgias account',
    GORGIAS_AUTH_SUCCESS: 'Your Gorgias account is now connected',

    GORGIAS_EMPTY_FIELD_ERROR: 'Fields cannot be empty!',

    FRESHDESK_AUTH_FAILURE: 'Unable to connect your Freshdesk account',
    FRESHDESK_AUTH_SUCCESS: 'Your Freshdesk account is now connected',

    CREATE_SUBSCRIPTION_SUCCESS: 'New Subscription successfully created',
    UPDATE_SUBSCRIPTION_SUCCESS: 'Subscription successfully updated',

    ORG_SUBSCRIPTION_SUCCESS: 'Organization subscription has been added',

    KUSTOMER_CREATE_ACCOUNT_FAILURE: 'Unable to connect your Kustomer account',
    KUSTOMER_CREATE_ACCOUNT_SUCCESS: 'Your Kustomer account is now connected',

    KUSTOMER_VERIFY_KEY_SUCCESS:'Your API Key has been verified',
    KUSTOMER_VERIFY_KEY_FAILURE:'Unable to verify your API Key',

    AGENT_PAYOUT: 'Agent payout: ',

    CREATE_PORTAL_SUCCESS: 'Portal created',
    CREATE_PORTAL_ERROR: 'Error creating portal',

    EDIT_PORTAL_SUCCESS: 'Portal edited',
    EDIT_PORTAL_ERROR: 'Error editing portal',

    DELETE_PORTAL_SUCCESS: 'Portal deleted',
    DELETE_PORTAL_ERROR: 'Error deleting portal',

    ZENDESKCHAT_OAUTH_ERROR: 'Please add Zendesk Email account before adding Zendesk Chat',

    GENERIC_ERROR: 'Could not complete request',

    HELPSCOUT_OAUTH_FAILURE: 'Unable to connect your Help Scout account',
    HELPSCOUT_OAUTH_SUCCESS: 'Your Help Scout account is connected successfully',

    SALESFORCE_OAUTH_FAILURE: 'Unable to connect your Salesforce account',
    SALESFORCE_OAUTH_SUCCESS: 'Your Salesforce account is connected successfully via OAuth. Now you can view the created application and proceed with installation on your Salesforce instance.',

    FRONT_APP_CHAT_FAILURE: 'Failed to load Frontapp Chat Data',

    UNDO_MESSAGE_SUCCESS: 'The undo operation was successfully performed',
    UNDO_MESSAGE_FAILURE: 'The undo operation was not successful',

    APPLICATIONS_GET_DETAILS_FAILURE: 'Unable to fetch application details. Please try again later.',

    MARK_AS_DONE_SUCCESS: 'Marked message as done',

    REAMAZE_AUTH_FAILURE: 'Unable to connect your Reamaze account',
    REAMAZE_AUTH_SUCCESS: 'Your Reamaze account is now connected',

    GLADLY_AUTH_FAILURE: 'Unable to connect your Gladly account',
    GLADLY_AUTH_SUCCESS: 'Your Gladly account is now connected',

    ADD_RESPONSE_SUCCESS: 'Response has been added',
    MAX_ADD_RESPONSE_LIMIT: 'You can only add a maximum of 5 responses to a message',

    BULK_SCHEDULE_UPDATE_SUCCESS: 'Schedules have been successfully updated',
    BULK_SCHEDULE_UPDATE_FAILURE: 'There was an error updating the schedules',

    SEND_RESPONSE_GENERATION_SUCCESS: 'Message has been successfully sent for Response Generation',
    SEND_RESPONSE_GENERATION_FAILURE: 'Failed to send message for Response Generation',

    COPY_CUSTOMER_NAME_SUCCESS: 'Customer Name has been copied to the clipboard',
    COPY_CUSTOMER_NAME_FAILURE: 'Unable to copy Customer Name',

    COPY_VALUE_SUCCESS: 'Value copied to the clipboard',
    COPY_VALUE_FAILURE: 'Unable to copy value to the clipboard',

    COPY_TICKET_LINK_SUCCESS: 'Ticket Link has been copied to the clipboard',
    COPY_TICKET_LINK_FAILURE: 'Unable to copy Ticket Link',

    TIKTOK_OAUTH_FAILURE: 'Unable to connect your TikTok account',
    TIKTOK_OAUTH_SUCCESS: 'Your TikTok account is connected successfully',

    TIKTOK_UPDATE_SEARCH_SUCCESS: 'Search keyword added successfully',
    TIKTOK_UPDATE_SEARCH_FAILURE: 'Failed to Update search Keyword',

    TIKTOK_ADS_OAUTH_FAILURE: 'Unable to connect your TikTok Ads account',
    TIKTOK_ADS_OAUTH_SUCCESS: 'Your TikTok Ads account is connected successfully',

    DELETE_USER_MESSAGES_SUCCESS: 'Successfully Deleted Messages',
    DELETE_USER_MESSAGES_FAILURE: 'Failed to Delete Messages',

    JIRA_BUG_SUCCESS: 'Bug successfully submitted to Jira',
    JIRA_BUG_FAILURE: 'Error submitting Bug to Jira',

    JIRA_OAUTH_SUCCESS: 'Jira Oauth successful',
    JIRA_OAUTH_FAILURE: 'Error while completing Jira Oauth',

    NO_DATA_FOR_FILTER: 'No Data for the filter used',

    SHOPIFY_OAUTH_ERROR: 'Error while connecting your shopify account',
    SHOPIFY_OAUTH_SUCCESS: 'Your Shopify account is now connected',

    NO_VERTICAL_ERROR: 'Please select the industry of your company',

    NO_REPLY_ON_MESSAGE_ACTION: "Message can be worked on",

    UPDATES_FETCH_FAILURE: "Failed to fetch updates",

    UPDATES_SAVE_FAILURE: "Failed to create new notification",

    UPDATES_SAVE_SUCCESS: "notification created successfully",

    UPDATES_EDIT_FAILURE: "Failed to edit notification",

    UPDATES_EDIT_SUCCESS: "notification updated successfully",

    MSGDATASOURCE_ACCOUNT_UPDATED: "Account updated successfully",

    BLACKLIST_SEARCH_MESSAGE_SUCCESS: "Messages blacklisted successfully",

    SIGNUP_ERROR: "Could not complete signup",

    FIND_AND_REPLACE_TEXT_FAILURE: "Text not successfully replaced",
    FIND_AND_REPLACE_TEXT_SUCCESS: "Text has been sent for replacement",

    MSGDATASOURCE_INBOXES_RETRIEVED: "Inboxes retrieved successfully",

    GORGIAS_AUTO_SENDER_SUCCESS: "Gorgias Auto Sender saved successfully",
    GORGIAS_AUTO_SENDER_UPDATE: "Gorgias Auto Sender updated successfully",
    GORGIAS_AUTO_SENDER_DELETE: "Gorgias Auto Sender deleted successfully",

    BILLING_SETTINGS_ERROR: 'Could not open billing settings',

    SELECT_AN_ORGANIZATION: 'Please select an organization to continue',
    SELECT_VIEWS: 'Please select some views to see ticket counts',

    REMOVE_EMPLOYMENT_SUCCESS: 'Team update completed, expert removed successfully',

    ATTRIBUTES_UPDATED_SUCCESS: 'User attributes updated successfully',

    MESSAGE_PRACTICE_PRIORITY_UPDATE_SUCCESS: 'Message priority for practice updated successfully',

    CREATE_RESOURCE_SUCCESS: 'Resource created',
    CREATE_RESOURCE_ERROR: 'Error creating resource',

    UPDATE_RESOURCE_SUCCESS: 'Resource updated',
    UPDATE_RESOURCE_ERROR: 'Error updating resource',

    CREATE_CAMPAIGN_SUCCESS: 'Campaign created',
    CREATE_CAMPAIGN_ERROR: 'Error creating campaign',

    CREATE_AUTO_ACTION_SUCCESS: 'Auto action changed successfully',
    CREATE_AUTO_ACTION_ERROR: 'Error creating auto action',

    UPDATE_CAMPAIGN_SUCCESS: 'Campaign updated',
    UPDATE_CAMPAIGN_ERROR: 'Error updating campaign',

    DELETE_CAMPAIGN_ATTRIBUTE_SUCCESS: 'Attribute deleted',
    DELETE_CAMPAIGN_ATTRIBUTE_ERROR: 'Error deleting attribute',

    CREATE_TEMPLATE_SUCCESS: 'Template created',
    CREATE_TEMPLATE_ERROR: 'Error creating template',

    UPDATE_TEMPLATE_SUCCESS: 'Template updated',
    UPDATE_TEMPLATE_ERROR: 'Error updating template',

    UPDATE_RESPONSE_SUCCESS: 'Suggested response updated',
    UPDATE_RESPONSE_ERROR: 'Error updating suggested response',

    PLATFORM_MACRO_SAVED_SUCCESS: 'Platform Macro saved successfully',
    PLATFORM_MACRO_SAVED_FAILURE: 'Failed to save Platform Macro',
    PLATFORM_MACRO_GET_SUCCESS: 'Platform Macro retrieved successfully',
    PLATFORM_MACRO_GET_FAILURE: 'Failed to retrieve Platform Macros',
    PLATFORM_MACRO_REMOVED_SUCCESS: 'Platform Macro removed successfully',
    PLATFORM_MACRO_UPDATE_SUCCESS: 'Platform Macro updated successfully',

    TRUSTPILOT_OAUTH_FAILURE: 'Unable to connect your Trustpilot account',
    TRUSTPILOT_OAUTH_SUCCESS: 'Your Trustpilot account is connected successfully',
    TRUSTPILOT_SEND_TO_TRENDS_SUCCESS: 'Trustpilot sent to trends',
    TRUSTPILOT_SEND_TO_TRENDS_FAILURE: 'Failed to send to trends',

    TRUSTPILOT_REFRESH_TOKEN_SUCCESS: 'Trustpilot refresh token successful',
    TRUSTPILOT_REFRESH_TOKEN_FAILURE: 'Trustpilot refresh token failed',

    SAVE_TRUSTPILOT_CREDENTIALS_SUCCESS: 'Trustpilot credential saved successful',
    SAVE_TRUSTPILOT_CREDENTIALS_FAILURE: 'Trustpilot credential failed to save',

    EXPORT_METRICS_MESSAGES_FAILURE: 'Export failed',

    STRIPE_TOKEN_EXCHANGE_SUCCESS: 'Your Stripe account is connected successfully',
    STRIPE_TOKEN_EXCHANGE_FAILURE: 'Unable to connect your Stripe account',

    CUSTOMER_IO_AUTH_FAILURE: 'Unable to connect your customer.io account',
    CUSTOMER_IO_AUTH_SUCCESS: 'Your customer.io account is now connected',
    NO_CAMPAIGNS_FOR_ORG: 'No campaign found for the selected organization',

    YOUTUBE_CREATE_ACCOUNT_SUCCESS: 'Your YouTube channel has been connected',
    YOUTUBE_NO_CHANNEL_FOUND: 'No channel found for you youtube account',

    SEGMENT_UPDATE_SUCCESS: 'Segment has been updated',

    METRICS_DETAIL_NO_CONVERSATION_FOUND: 'No conversation found for message',

    ORGANIZATION_ATTRIBUTE_UPDATE_SUCCESS: 'Updated organization attribute',
    ORGANIZATION_ATTRIBUTE_UPDATE_FAILURE: 'Could not update organization attribute',

    TICKET_BUDGET_UPDATE_SUCCESS: 'Updated ticket budget',
    TICKET_BUDGET_UPDATE_FAILURE: 'Could not update ticket budget',

    INITIATE_ORGANIZATION_REMOVAL_PROCESS_SUCCESS: 'Successfully initiated organization removal process',
    INITIATE_ORGANIZATION_REMOVAL_PROCESS_FAILURE: 'Could not initiate organization removal process',

    TIKTOK_SHOP_CREATE_ACCOUNT_SUCCESS: 'Your TikTok Shop has been connected',
    TIKTOK_SHOP_NO_SHOP_FOUND: 'No TikTok Shop found',

    DIXA_CREATE_ACCOUNT_SUCCESS: 'Your Dixa has been connected',

    LIST_TAGS_ERROR: 'Could not fetch tags',
};


