import {Fragment} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButton from "../../../../../common/ActionButton";
import PerfectResponseModal from "./PerfectResponseModal/PerfectResponseModal";
import useModalSetup from "../../../../../hooks/useModalSetup";

export default function PerfectResponseAction({
  submitVoteLoading,
  handleSubmitPerfectResponse,
  disableButton,
  loadingHtmlBody,
  handleCloseTicket
}) {
    const {handleOpenModal, handleCloseModal, openModal} = useModalSetup();

    return(
        <Fragment>
            <ActionButton
                label={(submitVoteLoading) ? <CircularProgress size={15} sx={{ color: '#FFFFFF'}}/> : 'Perfect'}
                action={handleOpenModal}
                disabled={disableButton || loadingHtmlBody}
                withIcon={false}
                sx={style.perfectResponseButton}
            />
            <PerfectResponseModal
                title={'Submit response'}
                open={openModal}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmitPerfectResponse}
                handleCloseTicket={handleCloseTicket}
            />
        </Fragment>
    )
}

const style = {
    perfectResponseButton: {
        height: '32px',
        width: '100px',
    },
}