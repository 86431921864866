import {createElement, useState} from "react";
import _ from "lodash";
import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import Filter from "./FilterUtililties/Filter";
import TextButton from "../TextButton";
import BasicButton from "../BasicButton";

export default function FilterEntry(
    {label, primaryOptions, primaryOptionsDefaultValues, secondaryOptions, secondaryOptionsDefaultValues, rootStyle,
        withOptional= true, handleClose=()=>{}, handleApplyFilters, SecondaryFilter}) {

    const secondaryOptionsDefaultSelected = [...secondaryOptionsDefaultValues];

    const [checkedSecondaryOptions, setCheckedSecondaryOptions] = useState([...secondaryOptionsDefaultValues]);
    const [checkedPrimaryOptions, setCheckedPrimaryOptions] = useState(() => {
        const checkedItems = primaryOptionsDefaultValues.map((item) => _.toString(item));
        return checkedItems || [];
    });

    const applyFilters = () => {
        handleApplyFilters(checkedPrimaryOptions, checkedSecondaryOptions);
        handleClose();
    };

    const resetFilters = () => {
        setCheckedSecondaryOptions(secondaryOptionsDefaultSelected);
        setCheckedPrimaryOptions(_.toString(primaryOptionsDefaultValues).split(','));
    };

    return(
        <Box sx={{...styles.root, ...rootStyle}}>
            <Box sx={styles.filterSection}>
                <Box sx={styles.title}>Filter by</Box>
                <Grid container>
                    <Grid item sx={{}}
                          xs={12} sm={12} md={withOptional ? 8 : 12} lg={withOptional ? 8 : 12}
                          order={{xs: 2, sm: 2, md: 1, lg: 1}}>
                        <Box sx={styles.primaryFilterContainer}>
                            <Filter
                                label={label}
                                options={primaryOptions}
                                checkedItemsValues={checkedPrimaryOptions}
                                setCheckedItemsValues={setCheckedPrimaryOptions}
                            />
                        </Box>
                    </Grid>
                    <Grid item sx={{}} xs={12} sm={12} md={4} lg={4} order={{xs: 1, sm: 1, md: 2, lg: 2}}>
                        <Box sx={styles.secondaryFilterContainer}>
                            <Box>
                                {
                                    createElement(
                                        SecondaryFilter,
                                        {
                                            options: secondaryOptions,
                                            checkedSecondaryOptions: checkedSecondaryOptions,
                                            setCheckedSecondaryOptions: setCheckedSecondaryOptions
                                        },
                                    )
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={styles.footerSection}>
                <Box>
                    <TextButton
                        label={'Reset filters'}
                        sx={{height: '20px', marginTop: '17px'}}
                        rootStyle={{fontSize: '14px', height: '18px'}}
                        action={resetFilters}
                    />
                </Box>
                <Box>
                    <BasicButton
                        style={{fontSize: '14px'}}
                        action={applyFilters}
                        label={'Apply filters'}/>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    root: {
        minWidth: '500px',
        maxWidth: '700px',
    },
    title: {
        marginBottom: '20px',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
    },
    filterSection: {
        padding: '20px 20px 7px 20px',
        borderBottom: '1px solid #B6BEBF',
    },
    footerSection: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    primaryFilterContainer: {
        width: '100%',
    },
    secondaryFilterContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}