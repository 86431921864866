import { useState } from 'react';
import moment from 'moment';
import useLocationQueryParams from 'components/v2/hooks/useLocationQueryParams';

const defaultFormat = 'YYYY-MM-DD HH:mm:ss'
const startDateDefault = moment().startOf('month').format(defaultFormat);
const endDateDefault = moment().add(1, 'd').format(defaultFormat);

/**
 * This hooks is created for the purpose of
 * 1. Getting the currently set daterange from the current url (e.g. /my/url?startDate=YYYY-MM-DD 00:00:00&endDate=YYYY-MM-DD 00:00:00)
 * 		and setting it in a state.
 * 2. Setting a new daterange if needed and update the state.
 * @returns {{
 * 	startDate: string;
 * 	endDate: string; 
 * 	setDate: ({ startDate: string, endDate: string }) => void
 * }}
 */
const useLocationQueryDateRange = ( props = {}) => {
	const { params, setParams } = useLocationQueryParams()
	const defaultStartDate = props && props.initialStartDate ? props.initialStartDate : startDateDefault
	const [startDate, _setStartDate] = useState(() => {
		let startDate = params.startDate ? moment(params.startDate) : defaultStartDate;
    startDate = moment(startDate).format(defaultFormat);
		return startDate;
	})
	const setStartDate = (newVal) => {
		_setStartDate(newVal || defaultStartDate);
	}

	const [endDate, _setEndDate] = useState(() => {
		let endDate = params.endDate ? moment(params.endDate) : endDateDefault;
    endDate = moment(endDate).format(defaultFormat);
		return endDate;
	})
	const setEndDate = (newVal) => {
		_setEndDate(newVal || endDateDefault);
	}

	const setDates = ({ startDate, endDate }) => {
		const newStartDate = startDate || defaultStartDate;
		const newEndDate = endDate || endDateDefault;

		if (params.startDate === newStartDate && params.endDate === newEndDate) 
			return

		setStartDate(startDate);
		setEndDate(endDate);
		setParams({ startDate, endDate })
	}

	return {
		startDate,
		endDate,
		setDates,
	}
}

export default useLocationQueryDateRange