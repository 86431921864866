import {Fragment} from "react";
import {Box} from "@mui/system";
import styles from "../../../views/Vote/voteMainStyles";
import _ from 'lodash';
import ConversationInfoEmptyState from "./EmptyStates/ConversationInfoEmptyState";

export default function ConversationInfo({ message, loading }) {
    const getConversationInfo = (message) => {
        if(_.isEmpty(message)) return '';
        const handleName = _.get(message, 'dataSourceHandle.name');

        return <Fragment>
            <Box component={'span'} className={'capitalize'}>
                {handleName}
            </Box>
        </Fragment>
    }

    const info = getConversationInfo(message);

    return(
        <Box sx={styles.heading}>
            { loading ? <ConversationInfoEmptyState/> : info }
        </Box>
    )
}