import React from 'react'
import { Button, Form } from 'react-bootstrap';
import { GenericModal } from 'components/common';

const AddOrganizationSubscription = (props) => {
    const { handleSubmit, showModal, closeModal, subscriptions, subscription,
        submitting, onChange } = props

    return (
        <GenericModal
            headerText="Add Organization Suscription"
            hideFooter
            show={showModal}
            onHide={closeModal}
        >
            <Form onSubmit={handleSubmit}>
                <h4 className="text-warning text-center">This action will fund the Organization's Wallet</h4>
                <span className="font-14 color-grey-dark">Subscriptions</span>
                <div className="form-group has-success">
                    <label id="drop-down-arrow-down">
                        <select name="subscription"
                                className="form-control select-field color-grey-dark"
                                value={subscription}
                                onChange={onChange}>
                            <option value="" hidden>Select Subscription Rate</option>
                            {subscriptions && subscriptions.map(item =>
                                <option key={item.id} value={item.id}>
                                    {item.label} - {item.currency.code} {item.amount}
                                </option>
                            )}
                        </select>
                    </label>
                </div>
                <div className="col-12">
                    <Button className="btn btn-success btn-block font-15" type="submit" disabled={submitting}>
                        Add Subscription
                    </Button>
                </div>
            </Form>
        </GenericModal>
    )
};

export { AddOrganizationSubscription }
