/**
 * @author Chris Okebata
 */

export const portalConstants = {
  GET_ALL_PORTALS_REQUEST: 'GET_ALL_PORTALS_REQUEST',
  GET_ALL_PORTALS_SUCCESS: 'GET_ALL_PORTALS_SUCCESS',
  GET_ALL_PORTALS_FAILURE: 'GET_ALL_PORTALS_FAILURE',

  GET_PORTALS_FOR_MESSAGE_REQUEST: 'GET_PORTALS_FOR_MESSAGE_REQUEST',
  GET_PORTALS_FOR_MESSAGE_SUCCESS: 'GET_PORTALS_FOR_MESSAGE_SUCCESS',
  GET_PORTALS_FOR_MESSAGE_FAILURE: 'GET_PORTALS_FOR_MESSAGE_FAILURE',

  GET_ORG_PORTAL_REQUEST: 'GET_ORGANIZATION_PORTAL_REQUEST',
  GET_ORG_PORTAL_SUCCESS: 'GET_ORGANIZATION_PORTAL_SUCCESS',
  GET_ORG_PORTAL_FAILURE: 'GET_ORGANIZATION_PORTAL_FAILURE',

  GET_ALL_INTEGRATED_PORTALS_REQUEST: 'GET_ALL_INTEGRATED_PORTALS_REQUEST',
  GET_ALL_INTEGRATED_PORTALS_SUCCESS: 'GET_ALL_INTEGRATED_PORTALS_SUCCESS',
  GET_ALL_INTEGRATED_PORTALS_FAILURE: 'GET_ALL_INTEGRATED_PORTALS_FAILURE',

  POST_PORTAL_REQUEST: 'POST_PORTAL_REQUEST',
  POST_PORTAL_SUCCESS: 'POST_PORTAL_SUCCESS',
  POST_PORTAL_FAILURE: 'POST_PORTAL_FAILURE',

  PUT_PORTAL_REQUEST: 'PUT_PORTAL_REQUEST',
  PUT_PORTAL_SUCCESS: 'PUT_PORTAL_SUCCESS',
  PUT_PORTAL_FAILURE: 'PUT_PORTAL_FAILURE',

  DELETE_PORTAL_REQUEST: 'DELETE_PORTAL_REQUEST',
  DELETE_PORTAL_SUCCESS: 'DELETE_PORTAL_SUCCESS',
  DELETE_PORTAL_FAILURE: 'DELETE_PORTAL_FAILURE',

  OPEN_PORTAL_REQUEST: 'OPEN_PORTAL_REQUEST',
  OPEN_PORTAL_SUCCESS: 'OPEN_PORTAL_SUCCESS',
  OPEN_PORTAL_FAILURE: 'OPEN_PORTAL_FAILURE',
};