import React from 'react';

/**
 * @author: Okpala, Oluchukwu Chioma
 */

export const SetupLink = (props) => {
    const { msgSourceLink } = props;

    return (
        <span>
            {msgSourceLink && <a
            href={msgSourceLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{marginLeft:'20px', fontWeight: 'bold', }}>View setup instructions</a>}
        </span>
    );
};
