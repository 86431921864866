import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from 'components/common';
import {history} from "_helpers";
import {CLIENT_URL} from "config";
import {trustpilotOauthActions} from "_actions";

export default function TrustpilotUrlRedirect() {
    const dispatch = useDispatch();
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const trustpilotAuthToken = values.get('code');

    useEffect(() => {
        if (trustpilotAuthToken) {
            const callbackPath = '/trustpilot/accounts';
            const params = {code: trustpilotAuthToken, redirectUrl: `${CLIENT_URL}${callbackPath}`};
            dispatch(trustpilotOauthActions.generateAccessToken(params));
        }
    }, []);

    const trustpilotAuth = useSelector((state) => state.trustpilotAuth);

    if (trustpilotAuth.loading) {
        return <Loading />;
    }

    history.push('/company/connectchannels');

    return(
        <Fragment/>
    )
}