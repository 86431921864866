import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { messageActions } from '_actions';
import BackIcon from '@material-ui/icons/ArrowBack';
import _ from "lodash";
import {getCapitalizedWords} from "_helpers";
import {ConversationItem} from "./ConversationItem";
import {SearchConversationEmptyState} from "./SearchConversationEmptyState";
import {OriginalConversation} from "./OriginalConversation";


/**
 * @author Chris Okebata
 */

class SearchResultConversation extends Component {

  componentDidMount() {
    const { dispatch, searchedMessageId,  } = this.props;
    const params = { addedDetails: true };

    dispatch(messageActions.getDetailsFromTrends(searchedMessageId, params));
  }

  render() {
    const { loading, closeSearchConversation, messages} = this.props;
    const message = _.get(messages, 'message') || [];
    const displayInApp = _.get(messages, 'message.dataSourceHandle.displayInApp') || false;
    const conversationThread = _.get(messages, 'message.conversation') || [];
    const author = _.get(messages, 'message.conversation.0.author') || '';
    const fullMessageLink = _.get(messages, 'message.conversation.0.fullMessageLink') || '';
    const sourceName = _.get(messages, 'message.conversation.0.messageSource.name') || '';

    if(loading){
      return <SearchConversationEmptyState/>;
    }

    return (
      <Fragment>
        <div className="font-14" style={{width: '100%', height: '40px', display: 'flex', alignItems: 'center',
          padding: '0px 35px', borderBottom: '1px solid #dee2e0', backgroundColor: '#f3f3f3'}}>

          <div style={{width: '40px'}}>
            <div className="search-back-button" onClick={closeSearchConversation}>
              <BackIcon style={{fontSize: '22px', fontWeight: "bold"}} className="color-green"/>
            </div>
          </div>

          <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            { conversationThread.length > 0 && <span className=" font-14">Conversation with {author}</span> }

              <div>
                { displayInApp && <OriginalConversation message={message}/> }

                {fullMessageLink && (
                  <span style={{marginLeft: '15px'}}>
                    <a className="font-14 color-green tms-no-underline"
                       href={fullMessageLink}
                       target="_blank"
                       rel="noreferrer noopener nofollow"
                       style={{float: 'right', fontWeight: 'bold'}}
                    >
                        {`View in ${getCapitalizedWords(sourceName) || 'source'}`}
                    </a>
                  </span>
                )}
              </div>
          </div>
        </div>

        <div style={{height: 'calc(100% - 110px)', backgroundColor: '#fafafa'}}>
          <div style={{height: 'calc(100%)', position: 'relative'}}>
            <div style={{padding: '20px 35px', width: '100%', height: 'calc(100%)', position: 'absolute', top: '0'}}>
              <div style={{border: '1px solid #CED2D0', borderRadius: '4px', backgroundColor: '#ffffff', overflowY: 'auto', height: 'calc(100%)', padding: '0 20px 10px 20px'}}>

                { conversationThread.length > 0 ?
                  <Fragment>
                    {/*BEGIN LOOP HERE*/}
                    {
                      conversationThread.map((conversation)=>{
                        return(
                          <ConversationItem
                            key={_.get(conversation, 'id')}
                            message={conversation}
                          />
                        )
                      })
                    }
                    {/*END LOOP HERE*/}

                    <div className="font-14 color-grey-mid" style={{textAlign: 'center', padding: '15px 0'}}>End of conversation</div>
                  </Fragment>
                  :
                  <div className="font-14 color-grey-mid" style={{textAlign: 'center', padding: '15px 0'}}>No messages found</div>
                }

              </div>
            </div>
          </div>
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state;
  return {
    messages,
    loading: messages.loading,
  };
}

const connectedSearchResultConversation = connect(mapStateToProps)(SearchResultConversation);

export { connectedSearchResultConversation as SearchResultConversation };