import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useMemo } from 'react';

export default function ChannelAccordionItemHandle({
	handle,
}) {
	const statusLabel = useMemo(() => {
		switch (handle.dispatchStatus) {
			case 'LIVE': return 'On'
			case 'PRACTICE': return 'Practice'
			case 'OFF': return 'Off'
		}
	}, [handle.dispatchStatus])

	return (
		<Box component={'li'} sx={styles.root}>
			<span>{handle.name}</span>
			
			<Box sx={styles.status}>
				<Box component={'span'} sx={visuallyHidden}>Status:</Box>
				<Box>{statusLabel}</Box>
			</Box>
		</Box>
	)
}

const styles = {
	root: {
		fontFamily: 'LarsseitRegular', fontSize: 18, lineHeight: 1.2, letterSpacing: '-0.02em', color: '#0C2728',
		px: 2,
		py: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	status: {
		display: 'flex',
		alignItems: 'center',
	},
}