import {websocketConstants} from "_constants";


/**
 * @author Chris Okebata
 */

/**
 * Set the websocket trends message to redux state
 * @param message
 * @returns {Function}
 */
const setTrendsMessage = (message) => {

  const success = (message) => ({
    type: websocketConstants.GET_TRENDS_MESSAGE_SUCCESS,
    payload: { message }
  });


  return dispatch => {
    dispatch(success(message));
  }
};

const addTeamsMessage = (message) => {
    const success = (message) => ({
        type: websocketConstants.ADD_TEAMS_MESSAGE_SUCCESS,
        payload: { message }
    });
    return dispatch => {
        dispatch(success(message));
    }
};

const removeTeamsMessages = (messages) => {
    const success = (messages) => ({
        type: websocketConstants.REMOVE_TEAMS_MESSAGES_SUCCESS,
        payload: { messages }
    });
    return dispatch => {
        dispatch(success(messages));
    }
};

const setMessage = (message) => {
  const success = (message) => ({
    type: websocketConstants.GET_MESSAGE_SUCCESS,
    payload: { message }
  });

  return dispatch => {
    dispatch(success(message));
  }
};

/**
 * Clear the websocket trends message from the redux state
 * @returns {Function}
 */
const clearTrendsMessage = () => {

  const clear = () => ({
    type: websocketConstants.CLEAR_TRENDS_MESSAGE,
    payload: {}
  });


  return dispatch => {
    dispatch(clear());
  }
};

/**
 * Set the websocket vote message to redux state
 * @param message
 * @returns {Function}
 */
const setVoteMessage = (message) => {
  const success = (message) => ({
    type: websocketConstants.GET_VOTE_MESSAGE_SUCCESS,
    payload: { message }
  });

  return dispatch => {
    dispatch(success(message));
  }
};

/**
 * Clear the websocket vote message from the redux state
 * @returns {Function}
 */
const clearVoteMessage = () => {
  const clear = () => ({
    type: websocketConstants.CLEAR_VOTE_MESSAGE,
    payload: {}
  });

  return dispatch => {
    dispatch(clear());
  }
};

const clearMessage = () => {
  const clear = () => ({
    type: websocketConstants.CLEAR_MESSAGE,
    payload: {}
  });

  return dispatch => {
    dispatch(clear());
  }
};


export const websocketActions = {
  setTrendsMessage,
  clearTrendsMessage,
  setVoteMessage,
  clearVoteMessage,
  setMessage,
  clearMessage,
  addTeamsMessage,
  removeTeamsMessages,
};