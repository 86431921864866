
export class CSMInfoBuilder {
    constructor() {
        this.csmInfo = {}
    }

    setId(id) {
        this.csmInfo.id = id;
        return this;
    }

    setName(name) {
        this.csmInfo.name = name;
        return this;
    }

    build(){
        return this.csmInfo;
    }
}