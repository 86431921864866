import htmlParser from "he";
import {Col, Panel, Row} from "react-bootstrap";
import React from "react";
import {parseHtmlWithImage, stripTrailingQuestionMarkFromHtmlEntity} from '_helpers';

function ShowResponseOptionV2(option) {
    const text = htmlParser.decode(stripTrailingQuestionMarkFromHtmlEntity(option.text));

    return (
        <Panel eventKey={option.id} key={option.id}>
            <Panel.Heading>
                <Panel.Title>
                    <Row>
                        <Col lg={12} md={12} className="pull-left">
                            {parseHtmlWithImage(text)}
                        </Col>
                    </Row>
                </Panel.Title>
            </Panel.Heading>

        </Panel>
    );
}

export { ShowResponseOptionV2 }