import React from 'react';
import {Button} from "react-bootstrap";
import "./modalStyle.css";

/**
 * @author: Okpala, Oluchukwu Chioma <oluchukwuokpala1759@gmail.com>
 */
export const Modal = (props) => {
    const { value, onInputChange, showModal, saveSetupLink, handleModal } = props;

    return (
        <div className="popup-modal">
            {showModal &&
            <div className="popup-modalContent">
                <div className="header">
                    <label>Message Source Setup Link</label>
                    <span onClick={handleModal}>x</span>
                </div>
                <input
                    type='text'
                    autoFocus
                    value={value}
                    onChange={onInputChange}
                    required
                />
                <Button
                    disabled={!value}
                    className='btn-success'
                    onClick={saveSetupLink}>Save
                </Button>
            </div>}
        </div>
    );
};
