import React from 'react';
import Box from "@mui/material/Box";

export default function AttachmentChip({ id, fileName, fileSize, action }) {

  return(
    <Box className={'attachment'} key={id}>
      <Box className='attachment-file-name tms-ellipsis'>{ fileName }</Box>
      <Box className='attachment-file-size tms-ellipsis'>{ fileSize && `(${fileSize})` }</Box>
      <Box className={'attachment-deselect'} onClick={ () => action(id) }/>
    </Box>
  )
}