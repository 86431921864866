export default {
	container: {
		marginTop: { xs: '10px', md: '0' },
	},
  buttonContainer: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: { xs: 'column', md: 'row' },
  },
  buttonLeft: {
    color: '#368C86',
    fontFamily: 'larsseitMedium',
    fontSize: '18px',
    lineHeight: '120%',
    marginRight: { xs: 'auto', md: '0' },
    width: '50%'
  },
  buttonRight: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: { xs: '16px', md: '0' },
  },
  loadingSpinner: {
    marginRight: 24,
    height: 40,
  },
  description: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '18px',
    lineHeight: '120%'
  },
}