import React from 'react';
import { Field, reduxForm } from "redux-form";
import { Table, TableRow, TableBody, TableCell, TableHead } from '@material-ui/core';
import { organizationActions } from "_actions";
import connect from "react-redux/es/connect/connect";
import { Loading } from "components/common";
import { isEmpty } from "lodash";
import { DateRange } from 'components/common/formFields/DateRange'
import moment from "moment";

class BillingSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().add(2, 'd').format('YYYY-MM-DD HH:mm:ss')
        };
    }

    componentDidMount() {
        this.props.dispatch(organizationActions.getOrganizationsPaidTickets(this.state))
    }

    /**
     * Handles date picker update. This method update the selected dates to the state
     * and dispatches the getOrganizationsPaidTickets action for the date selected
     * @param orgId
     * @returns {function(*)}
     */
    handleDatePickerUpdate = (event, data) => {
        if(event.type === 'apply'){
            this.setState({
                startDate : data.startDate.format('YYYY-MM-DD HH:mm:ss'),
                endDate   : data.endDate.format('YYYY-MM-DD HH:mm:ss')
            }, () => {
                this.props.dispatch(organizationActions.getOrganizationsPaidTickets(this.state))
            });
        }
    };

    render() {
        const { loading, organizationsPaidTickets } = this.props;
        const  { startDate, endDate } = this.state;

        return (
            <div className='container-pane container-pane-expert-page'>
                {loading && <Loading/>}

                <label className="calendar-today tms-inbox-calendar expert-page-date-picker">
                    <Field
                        submittedStartDate={startDate}
                        submittedEndDate={endDate}
                        id="datePicker"
                        name="datePicker"
                        defaultDateRange="today"
                        onEvent={this.handleDatePickerUpdate}
                        values={startDate}
                        component={DateRange}
                    />
                </label>

                {!loading && isEmpty(organizationsPaidTickets) &&
                <div className="font-20-bold tms-align-text-center padding-20-0 expert-page-table">
                    No companies available for this industry
                </div>}

                {!loading && !isEmpty(organizationsPaidTickets) &&
                <Table className="tms-billingSummary-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Company</TableCell>
                            <TableCell>Total Paid Tickets</TableCell>
                            <TableCell>Total Amount Charged</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organizationsPaidTickets && organizationsPaidTickets.map(org => {
                            return (
                                <TableRow key={org.id}>
                                    <TableCell>{org.name}</TableCell>
                                    <TableCell>{org.total_tickets}</TableCell>
                                    <TableCell>{org.total_amount}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {organizations: { organizationsPaidTickets,loading }} = state;

    return {
        loading,
        organizationsPaidTickets
    };
}

BillingSummary = connect(mapStateToProps)(BillingSummary);
const billingSummaryPage = reduxForm({
    form: 'BillingSummary'
})(BillingSummary);

export { billingSummaryPage as BillingSummary }