import React from 'react';
import {
    chatdeskUpdatesActions,
    dataSourceHandleActions,
    dispatcherActions,
    messageActions,
    organizationActions,
} from "_actions";
import {connect} from "react-redux";
import {FilterForm, MessageLoading, MessagesTable} from "components/Message";
import moment from 'moment';
import {isObjectEmpty, objectToQueryString, processAdminMessage, queryStringToObject, stringToBoolean,} from "_helpers";
import ReactPaginate from 'react-paginate';
import {navigationType, platformConstants} from '_constants';
import "./messages.css";
import {AdminUpdatesDisplayModal} from "../ChatdeskUpdates/modals/AdminDisplayModal";
import {chatdeskUpdatesService} from "_services";


const navigation = {
    type: navigationType.AGENT_SELECTION,
};

class MessageIndex extends React.Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);
        let {channels, organizations} = filterParams;
        channels = channels ? channels.split(",") : [];
        organizations = organizations ? organizations.split(",") : [];
        /**
         * By Default, if there is no selected start and end date,
         * Set the start and end of today as the date
         * This date is converted to UTC (the timezone of the DB) before sending it to the backend
         */
        let startDate = filterParams.startDate ? moment(filterParams.startDate) : moment().startOf('day');
        let endDate = filterParams.endDate ? moment(filterParams.endDate) : moment().endOf('day');

        filterParams.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        filterParams.endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
        filterParams.channels = channels;
        filterParams.organizations = organizations;
        this.state = {
            filterParams: {
                ...{
                    organizations: [],
                    dataSourceHandle: [],
                    msgDataSource: 'all_msgDataSources',
                    direction: 'ALL',
                    handleStatus: 'LIVE',
                    messageStatus: 'all',
                    messageDuration: 'two_months',
                    author: 'all_authors',
                    sortByDateCreated: true,
                    offset: 0,
                    max: 10,
                    platform: platformConstants.TEAMS,
                    initialPageLoad: true,
                }, ...filterParams
            }
        };

        this.getDataSourceHandles = this.getDataSourceHandles.bind(this)
    }

    getAllActiveCompanyUpdates() {
        const pageName = "messageIndex"
        const showCount = chatdeskUpdatesService.getShowCount(pageName)
        if (showCount > 0) {
            const filterParams = {
                isActive: true,
                max: 5,
            }
            this.props.dispatch(chatdeskUpdatesActions.getAll(filterParams))
        }
    }

    componentDidMount() {
        const {filterParams} = this.state;
        const {
            organizations, handleStatus, direction, platform, startDate,
            endDate, offset, channels, messageStatus, initialPageLoad, messageDuration
        } = filterParams;
        const {dispatch} = this.props;
        const visibility = ['PUBLIC', 'PRIVATE', 'FEATURED'];

        if (!stringToBoolean(initialPageLoad)) {
            if (platform === platformConstants.TRENDS) {
                const params = {
                    startDate, endDate, offset, channels,
                    querySource: 'admin', organizations, direction, platform, messageStatus, messageDuration
                };

                dispatch(messageActions.getAllMessagesFromTrends(params));
            } else {
                dispatch(messageActions.getAllMessages(filterParams));
            }
        }

        dispatch(organizationActions.getAll({max: 1000, visibility}));
        this.getDataSourceHandles(organizations, platform, handleStatus);
        const authorsQueryParams = {
            organizationId: organizations,
            handleStatus: handleStatus,
            direction: direction,
            messageDuration: messageDuration
        };
        dispatch(messageActions.getAuthors(authorsQueryParams));
        this.getAllActiveCompanyUpdates()
    }

    /**
     * Handles the update of a form element on the filter form.
     * @param event
     */
    handleFormUpdate = (event) => {
        const target = event.target;
        this.setState((prevState) => {
            return {
                filterParams: {...prevState.filterParams, [target.name]: target.value}
            }
        }, function () {
            const {filterParams} = this.state;
            const {organizations, handleStatus, direction, platform} = filterParams;
            if (target.name === 'organizations') {
                this.getDataSourceHandles(organizations, platform, handleStatus);
                let authorsQueryParams = {
                    organizationId: organizations,
                    handleStatus: handleStatus,
                    direction: direction
                };
                this.props.dispatch(messageActions.getAuthors(authorsQueryParams));
            } else if (target.name === 'handleStatus') {
                this.getDataSourceHandles(organizations, platform, handleStatus)

            } else if (target.name === 'platform') {
                this.getDataSourceHandles(organizations, platform, handleStatus)

            }
        });
    };

    getDataSourceHandles(organizations, platform, handleStatus) {
        this.props.dispatch(dataSourceHandleActions.getAll({
            organizations: organizations,
            max: 1000,
            handleStatus
        }));
    }

    /**
     * Handles the change of url to retrieve messages based on query params for filtering
     * @param filterParams The parameters for filtering the messages to be retrieved
     */
    getMessages = (filterParams) => {
        const queryString = objectToQueryString(filterParams);

        this.props.history.push({
            pathname: '/messages',
            search: `?${queryString}`
        });
    };

    handleDatePickerUpdate = (event, data) => {
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, ...{
                        startDate: data.startDate.format('YYYY-MM-DD HH:mm:ss'),
                        endDate: data.endDate.format('YYYY-MM-DD HH:mm:ss'),
                        initialPageLoad: false,
                    }
                }
            }
        });
    };

    /**
     * Handles the submission of a date range from the filter form.
     * @param event
     */
    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, ...{
                        offset: 0,
                        dataSourceHandle: prevState.filterParams.organizations === 'all_organizations'
                            ? 'all_dataSourceHandles' : prevState.filterParams.dataSourceHandle,
                        initialPageLoad: false,
                    }
                }
            }
        }, function () {
            const {filterParams} = this.state;
            this.getMessages(filterParams)
        })
    };

    deleteMessageFromSource(messageId, params) {
        this.props.dispatch(dispatcherActions.deleteMessageFromSource(messageId, params))
    }

    updateMessageFromSource(messageId, params) {
        this.props.dispatch(dispatcherActions.updateMessageFromSource(messageId, params))
    }

    escalateMessage = (messageIds) => {

        const {currentUser: {user: {id}}} = this.props;
        const escalationParams = {
            userMessageAction: {
                user: id,
                actionType: 'ESCALATE',
                message: messageIds,
            },
            navigation,
            isAdmin: true,
            platform: this.state.filterParams.platform
        };
        this.props.dispatch(messageActions.escalate(escalationParams))
    };


    undoMessage = (messageId, params) => {
        this.props.dispatch(messageActions.undoMessage(messageId, params))
    }

    skipMessage = (messageIds) => {
        const {currentUser: {user: {id}}} = this.props;
        const skipMessageParams = {
            userMessageAction: {
                user: id,
                actionType: 'NO_RESPONSE_NEEDED',
                message: messageIds,
            },
            navigation,
            isAdmin: true,
            platform: this.state.filterParams.platform

        };
        this.props.dispatch(messageActions.skipMessage(skipMessageParams))
    };

    handleOrganizationsFilterChange = (organizations) => {
        const {key} = this.state;
        const {startDate, endDate} = this.state.filterParams;
        this.populateOrgParams(key, organizations, startDate, endDate);
    };

    handleChannelsFilterChange = (channels) => {
        const {key} = this.state;
        const {startDate, endDate} = this.state.filterParams;
        this.populateParams(key, channels, startDate, endDate);
    };

    getChannels = (dataSourceHandles, returnValue) => {
        const channels = [];

        dataSourceHandles.forEach((dataSourceHandle) => {
            if (returnValue === 'ids') {
                channels.push(dataSourceHandle.trendsId);
            } else {
                const dataSource = {
                    value: dataSourceHandle.trendsId,
                    name: dataSourceHandle.name,
                };
                channels.push(dataSource);
            }
        });

        return channels;
    };


    getOrganizations = (organizations, returnValue) => {
        const orgList = [];
        organizations.forEach((organization) => {
            if (organization && organization.trendsId) {
                if (returnValue === 'ids') {
                    orgList.push(organization.trendsId);
                } else {
                    const organizationData = {
                        value: organization.trendsId,
                        name: organization.name,
                    };
                    orgList.push(organizationData);
                }
            }
        });
        return orgList;
    };

    populateOrgParams(key, organizations, inputStartDate, inputEndDate) {
        const startDate = moment(inputStartDate).format('YYYY-MM-DD HH:mm:ss');
        const endDate = moment(inputEndDate).format('YYYY-MM-DD HH:mm:ss');

        this.setState(prevState => ({
            key,
            filterParams: {
                ...prevState.filterParams,
                startDate,
                endDate,
                organizations,
            },
        }), function () {
            const {filterParams} = this.state;
            const {organizations, handleStatus, platform} = filterParams;
            this.getDataSourceHandles(organizations, platform, handleStatus);
        });
        const {filterParams} = this.state;
        return {
            ...filterParams,
            startDate: moment(filterParams.startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(filterParams.endDate).format('YYYY-MM-DD HH:mm:ss'),
        };
    }

    populateParams(key, channels, inputStartDate, inputEndDate) {
        const startDate = moment(inputStartDate).format('YYYY-MM-DD HH:mm:ss');
        const endDate = moment(inputEndDate).format('YYYY-MM-DD HH:mm:ss');

        this.setState(prevState => ({
            key,
            filterParams: {
                ...prevState.filterParams,
                startDate,
                endDate,
                channels,
            },
        }));
        const {filterParams} = this.state;
        return {
            ...filterParams,
            startDate: moment(filterParams.startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(filterParams.endDate).format('YYYY-MM-DD HH:mm:ss'),
        };
    }

    getNextPage = (queryOffset, queryMax, messageCount) => {
        queryOffset = parseInt(queryOffset, 10);
        queryMax = parseInt(queryMax, 10);
        messageCount = parseInt(messageCount, 10);

        if (!messageCount) return 0;

        if ([0, 10, 20, 30, 40, 50, 60, 70, 80, 90].includes(queryOffset) && messageCount === 10) {
            return messageCount + 1;
        } else if (messageCount / queryMax === 1) {
            return (queryOffset / messageCount) + 2;
        } else if (messageCount / queryMax < 1) {
            return (queryOffset / queryMax) + 1;
        } else {
            return 100 // fallback
        }
    };

    sendForResponseGen = (messageId) => {
        const {dispatch} = this.props
        const {filterParams: {platform}} = this.state
        dispatch(messageActions.sendForResponseGeneration({messageId, platform}))
    };

    render() {
        const {allMessages, chatdeskUpdates} = this.props;
        const {messages, params, count, resendForResponseGenList} = processAdminMessage(allMessages);
        const {
            filterParams: {
                startDate, endDate, organizations, msgDataSource,
                author, messageStatus, dataSourceHandle, handleStatus,
                direction, offset, max, platform, messageDuration
            }
        } = this.state;
        const messageStatuses = platform === platformConstants.TEAMS
            ? [{id: 'all', name: 'All'}, {id: 'new', name: 'New'}, {id: 'actioned', name: 'Actioned'},
                {id: 'skipped', name: 'Skipped'}, {id: 'replied', name: 'Replied'},
                {id: 'noResponse', name: 'No Response Needed'}, {id: 'escalated', name: 'Escalated'}]
            : [{id: 'all', name: 'All'}, {id: 'new', name: 'New'}];

        const totalPages = count ? Math.ceil(count / params.max) : this.getNextPage(params.offset, params.max, messages.length);
        const setStartDate = moment(startDate).format('M/D/YYYY');
        const setEndDate = moment(endDate).format('M/D/YYYY');
        const getOffsetInt = parseInt(offset, 10);
        const getMaxInt = parseInt(max, 10);
        let page = getOffsetInt > 0 ? getOffsetInt / getMaxInt : getOffsetInt;

        const handleSelect = (page) => {
            const newEndDate = moment().isSame(endDate, 'month') && moment().isSame(endDate, 'day') ?
                moment().add(1, 'd').format('YYYY-MM-DD HH:mm:ss') : endDate;
            this.setState((prevState) => {
                return {
                    filterParams: {
                        ...prevState.filterParams, ...{
                            offset: page.selected * prevState.filterParams.max,
                            endDate: newEndDate
                        }
                    }
                }
            }, function () {
                this.getMessages(this.state.filterParams)
            })
        };

        const input = {onChange: this.handleFormUpdate};

        let channels, channelsIds, companies, companiesIds;
        const {dataSourceHandles, orgList} = this.props;
        let finalOrgList = orgList.organizations ? orgList.organizations : orgList;

        if (dataSourceHandles) {
            channels = this.getChannels(dataSourceHandles);
            channelsIds = this.getChannels(dataSourceHandles, 'ids');
        }
        if (finalOrgList) {
            companies = this.getOrganizations(finalOrgList);
            companiesIds = this.getOrganizations(finalOrgList, 'ids');
        }

        const showCount = chatdeskUpdatesService.getShowCount("messageIndex")
        const adminUpdatesModalPresent = showCount > 0 && !chatdeskUpdates.loading &&
            !isObjectEmpty(chatdeskUpdates.updatesList) &&
            chatdeskUpdates.updatesList.data.length > 0

        return (

            <div className="container-pane">
                <div className="side-bar-pane shadow-border">
                    <FilterForm
                        onFormUpdate={this.handleFormUpdate}
                        onFormSubmit={this.handleFormSubmit}
                        organizations={organizations}
                        input={input}
                        onDatePickerUpdate={this.handleDatePickerUpdate}
                        dataSourceHandle={dataSourceHandle}
                        channelsFilter={channels}
                        channelsAction={this.state.filterParams.channels}
                        channelsIds={channelsIds}
                        companiesIds={companiesIds}
                        companies={companies}
                        handleChannelsFilterChange={this.handleChannelsFilterChange}
                        handleOrgFilterChange={this.handleOrganizationsFilterChange}
                        msgDataSource={msgDataSource}
                        messageStatus={messageStatus}
                        messageStatusOptions={messageStatuses}
                        messageDuration={messageDuration}
                        author={author}
                        handleStatus={handleStatus}
                        direction={direction}
                        startDate={setStartDate}
                        endDate={setEndDate}
                        platform={platform}
                    />
                </div>

                <div className="container-right messages-container-right">

                    {this.props.loading &&
                    <MessageLoading/>
                    }
                    {(!this.props.loading) &&
                    <div>
                        <MessagesTable
                            messages={messages}
                            updateMessageFromSource={this.updateMessageFromSource.bind(this)}
                            handleStatus={handleStatus}
                            platform={platform}
                            skipMessage={this.skipMessage}
                            escalateMessage={this.escalateMessage}
                            deleteMessageFromSource={this.deleteMessageFromSource.bind(this)}
                            undoMessage={this.undoMessage.bind(this)}
                            resendForResponseGenList={resendForResponseGenList}
                            sendMessageForResponseGen={this.sendForResponseGen}
                        />
                        {totalPages > 1 &&
                        <div className="align-center">
                            <ReactPaginate previousLabel={"<"}
                                           nextLabel={">"}
                                           breakLabel={<span>...</span>}
                                           breakClassName={"break-me"}
                                           pageCount={totalPages}
                                           marginPagesDisplayed={2}
                                           disableInitialCallback={true}
                                           pageRangeDisplayed={5}
                                           forcePage={page}
                                           onPageChange={(page) => handleSelect(page)}
                                           containerClassName={"pagination"}
                                           subContainerClassName={"pages pagination"}
                                           activeClassName={"active"}
                            />
                        </div>
                        }
                    </div>
                    }
                </div>

                <div>
                    {adminUpdatesModalPresent &&
                    <AdminUpdatesDisplayModal
                        companyUpdates={chatdeskUpdates.updatesList}
                        fromPage="messageIndex"
                    />
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        messages: {loading, messages, allMessages, allTrendsMessages},
        dataSourceHandles,
        organizations,
        auth,
        chatdeskUpdates
    } = state;

    return {
        messages: messages.messages,
        allMessages: allMessages,
        allTrendsMessages: allTrendsMessages,
        count: messages.count,
        params: messages.params,
        loading,
        currentUser: auth,
        orgList: organizations.organizations,
        dataSourceHandles: dataSourceHandles.dataSourceHandles,
        chatdeskUpdates
    };
}

const connectedMessageIndex = connect(mapStateToProps)(MessageIndex);
export {connectedMessageIndex as MessageIndex};
