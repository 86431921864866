import React, {Component} from 'react'
import { reduxForm } from 'redux-form'
import {connect} from 'react-redux'
import {msgDataSourceActions, dataSourceHandleActions, msgSourceActions} from "_actions";
import {utilityService} from "_services";
import { Loading } from "components/common";
import {styles} from 'components/Settings'
import {MsgSources} from "components/Settings";

class ChannelsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sourceName: ''
        };

        this.handleClick = this.handleClick.bind(this);
        this.submitHandleStatus = this.submitHandleStatus.bind(this);
        this.dispatchMsgDataSources = this.dispatchMsgDataSources.bind(this)
    }

    componentDidMount() {
        const currentUser = JSON.parse(localStorage.user);
        const organizationId = currentUser._embedded.organization.id;
        this.props.dispatch(msgSourceActions.getAll({organizationId: organizationId}));
    }

    submitHandleStatus(data){
        const handleId = data.id;
        delete data.id;
        this.props.dispatch(dataSourceHandleActions.update(data, handleId));
    }

    dispatchMsgDataSources(dataSources){
        this.props.dispatch(msgDataSourceActions.msgDataSourcesForMsgSource(dataSources))
    }

    handleClick(source) {
        localStorage.setItem('callbackUrl', window.location);
        utilityService.addChannel(this.props, source.name);
        this.setState({
            sourceName: source.name
        })
    }

    render() {

        //we know that a msgSource has been added if it is contained
        //in the payload of msgDataSources belonging to that organization
        const { contentPane } = styles;
        const {msgSources, loading} = this.props;

        if (loading) {
            return <Loading/>
        }

        return (
            <div className="main-content-wrapper">
                <div className="content-pane-65 margin-left-150">
                    <div className="color-black-fade-mid font-16-bold" style={contentPane}>
                        <span>
                            Channels
                        </span>
                        <a href='/company/connectchannels'>
                            <button className="btn btn-success btn-small">
                                Add Channels
                            </button>
                        </a>
                    </div>

                    <div className="margin-20-0">
                        {
                            msgSources.length > 0
                                ?
                                <MsgSources
                                    onClick={this.submitHandleStatus}
                                    msgSources={msgSources}
                                    dispatchMsgDataSources={this.dispatchMsgDataSources}
                                />
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }
}


const formConfig = {
    form: 'msgSourceForm',
};


function mapStateToProps(state) {
    return {
        msgSources: state.msgSources.msgSources,
        loading: state.msgSources.loading,
    }
}

ChannelsList = connect(
    mapStateToProps,
    null
)(ChannelsList);


const connectedChannelList = reduxForm(formConfig)(ChannelsList);

export {connectedChannelList as ChannelList};
