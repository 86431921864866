import { useEffect} from "react";
import {twitterOauthActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_URL} from "config";
import {utilityService} from "_services";
import setRedirectUrl from "../../Utilities/setRedirectUrl";

export default function useTwitterSetup({
  callbackPath,
}) {
	const dispatch = useDispatch();
  const twitter = useSelector((state) => state.twitterOauth);

  //submit the verifier and dispatch an action to generate the access token
  const submitVerifier = (data) => {
    dispatch(twitterOauthActions.getAccessToken(data, '/company/channels', '/company/connectchannels'));
  };

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.user);
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const oauthVerifier = values.get('oauth_verifier');
    const data = {verifier: oauthVerifier, username: currentUser.username, userId: currentUser.id};

    if(oauthVerifier && currentUser) {
      submitVerifier(data);
    }
  }, []);

  useEffect(() => {
    if(!twitter.loading && twitter.data.authUrl){
      utilityService.redirectToAuthUrl(twitter.data.authUrl)
    }
  });

  const performAction = () => {
    const callbackUrl = `${CLIENT_URL}${callbackPath}`;
    setRedirectUrl(callbackUrl)
    dispatch(twitterOauthActions.getTwitterOauthUrl(callbackUrl))
  };

	return { initiateTwitterSetup: performAction };
}