import React, {Fragment} from "react";
import {Box} from "@mui/system";
import {Divider} from "@mui/material";
import _ from "lodash";
import styles from "../organizationMainStyles";
import useGetOrganizationInfo from "../hooks/useGetOrganizationInfo";
import BillingInfoTable from "../Utilities/CompanyInfo/BillingInfoTable";
import CSMContainer from "../Utilities/CompanyInfo/CSMContainer";
import VisibilityContainer from "../Utilities/CompanyInfo/VisibilityContainer";
import HideInMobileToggleContainer from "../Utilities/CompanyInfo/HideInMobileToggleContainer";
import CompanyInfoHeader from "../Utilities/CompanyInfo/CompanyInfoHeader";
import {getAccessibilityMode, getLabel} from "../Utilities/organizationSettingsUtils";
import useGetCSMs from "../hooks/useGetCSMs";
import {fragmentId} from "../Utilities/organizationSettingsConstants";
import CompanyInfoBodyEmptyState from "../EmptyStates/CompanyInfoBodyEmptyState";
import NoDataView from "../EmptyStates/NoDataView";

export default function CompanyInfo({organizationId}) {
    const label = getLabel(fragmentId.INFO);
    const {organizationInfo, loading} = useGetOrganizationInfo(organizationId);
    const {csmList, loadingCSMInfo} = useGetCSMs(organizationId);
    const mode = getAccessibilityMode();
    const organization = _.get(organizationInfo, 'organization');
    const currentCSM = _.get(organization, 'customerSuccessManager');

    if(_.isEmpty(organizationId) && _.isEmpty(organizationInfo)) {
        return <NoDataView />
    }

    const BodyContent = () => {
        if(loading) {
            return <CompanyInfoBodyEmptyState />
        }
        return (
            <Fragment>
                <Box sx={style.mb20}>
                    <BillingInfoTable mode={mode} label={label} />
                </Box>
                <Box sx={style.mb20}>
                    <CSMContainer
                        organization={organization}
                        mode={mode}
                        loadingCSMInfo={loadingCSMInfo}
                        csmList={csmList}
                        currentCSM={currentCSM}
                        label={label}
                    />
                </Box>
                <Box sx={style.mb20}>
                    <VisibilityContainer
                        mode={mode}
                        organization={organization}
                        label={label}
                    />
                </Box>
                <HideInMobileToggleContainer
                    mode={mode}
                    organization={organization}
                    label={label}
                />
            </Fragment>
        )
    }

    return(
        <Box sx={styles.sectionWrapper}>
            <CompanyInfoHeader organization={organization} loading={loading} label={label} />
            <Divider orientation={'horizontal'} flexItem sx={styles.sectionDivider} />
            <BodyContent />
        </Box>
    )
}

const style = {
    mb20: {mb: '22px'}
}

