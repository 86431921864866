import React from "react";
import {Box, Stack} from "@mui/material";
import importedStyles from "../Utilities/styles";
import PageContainer from "../../../common/PageContainer";
import AnchorButton from "../../../common/AnchorButton";
import TemplatesTable from "../Utilities/TemplatesTable";

export default function CompanyTemplate({}) {
    return(
        <PageContainer backgroundColor={'#EFEAE7'}>
            <Stack direction={{ xs: 'column', md: 'row' }} sx={styles.hero} spacing={2}>
                <Box component={'h1'} sx={importedStyles.headingLarge}>
                    List of templates
                </Box>
                <Box sx={{ width: { xs: '100%', sm: 'auto' ,height: '40px'} }}>
                    <AnchorButton label={'Create new template'} link={'/company/create-template'}/>
                </Box>
            </Stack>

            <Box>
                <TemplatesTable/>
            </Box>
        </PageContainer>
    )
}

const styles = {
    hero: {
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        mb: { xs: '24px', sm: '40px', },
    },
}