import ConnectChannelButton from "../Utilities/ConnectChannelButton";
import React, {Fragment, useEffect} from "react";
import tiktokLogo from "components/images/logos/logo_tiktok.svg";
import {tiktokOauthActions} from "_actions";
import {CLIENT_URL} from "config";
import {useDispatch, useSelector} from "react-redux";
import {utilityService} from "_services";


export default function TikTokSetup(){
  const dispatch = useDispatch();
  const tiktok = useSelector((state) => state.tiktokOauth);

  const performAction = () => {
    const callbackUrl = `${CLIENT_URL}/signup/tiktok-redirect`;
    dispatch(tiktokOauthActions.initiateOauth(callbackUrl));
  };

  useEffect(() => {
    if (!tiktok.loading){
      utilityService.redirectToAuthUrl(tiktok.authUrl.url)
    }
  });

  return(
    <Fragment>
      <ConnectChannelButton
        label={'Connect with TikTok'}
        channelIcon={tiktokLogo}
        useCircleAvatar={true}
        action={performAction}/>
    </Fragment>
  )
}