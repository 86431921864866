import React from 'react';
import { Chart } from 'react-google-charts';




const SummaryChart = ({ data, options }) => (
    <div className={"color-grey-dark"}>
        <Chart chartType='ColumnChart'
            loader={<div>Loading Chart...</div>}
            data={data}
            width='100%'
            height='400px'
            formatters={[
                {
                    type: 'NumberFormat',
                    column: 1,
                    options: { pattern: options.vAxis.format !== '#%' ? '#' : '#%' }
                },
                {
                    type: 'DateFormat',
                    column: 0,
                    options: { pattern: 'MMM d, y' }
                }]
            }
            options={options}
            legendToggle
        />
    </div>
);

export default SummaryChart

