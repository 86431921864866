import {uploadConstants} from "_constants";

/**
 * @author Chris Okebata
 */

const INITIAL_STATE = {
  uploadImage: {},
  loadingUploadImage: false,
  uploadImageError: '',
};

export function uploadReducer(state = INITIAL_STATE, action) {

  switch (action.type) {
    case uploadConstants.UPLOAD_IMAGE_REQUEST:
      return { ...state, loadingUploadImage: true };
    case uploadConstants.UPLOAD_IMAGE_SUCCESS:
      return { ...state, uploadImage: action.payload, loadingUploadImage: false };
    case uploadConstants.UPLOAD_IMAGE_FAILURE:
      return { ...state, uploadImageError: action.payload, loadingUploadImage: false };

    default:
      return state
  }
}