import {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "@mui/material";
import {proactiveActions} from "_actions";
import {buildUpdateSegmentStatusPayload} from "../views/Proactive/Utilities/CampaignUtils";

export default function UpdateSegmentStatusAction({segmentId, campaignId, status}) {
    const [checkedStatus, setCheckedStatus] = useState(false);
    const [clicked, setClicked] = useState(false);
    const dispatch = useDispatch();
    const proactive = useSelector(state => state.proactive);
    const loadingSegmentStatus = proactive.loadingSegmentStatus;

    const handleStatusUpdate = (event) => {
        const payload = buildUpdateSegmentStatusPayload(event.target.checked, segmentId, campaignId)
        dispatch(proactiveActions.updateCampaignSegmentStatus(payload));
        setCheckedStatus(event.target.checked);
        setClicked(true);
    }

    const getCheckedStatus = () => {
        return clicked ? checkedStatus : status;
    }

    return(
        <Fragment>
            <Checkbox
                size={'small'}
                sx={styles.checkbox}
                checked={getCheckedStatus()}
                onChange={handleStatusUpdate}
                disabled={loadingSegmentStatus}
            />
        </Fragment>
    )
}

const styles = {
    checkbox: {
        fontSize: '22px',
        textAlign: 'right',
        '& .MuiSvgIcon-root': {
            color: '#144042',
        },
    },
    tooltip: {
        backgroundColor: '#DBD8FF',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        fontSize: '13px',
    },
    progressIcon: {
        color:'#07857C',
        fontSize: '25px'
    },
    progressIconContainer: {
        width: '32px',
        height: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}