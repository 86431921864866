import React, {Fragment, useState} from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {Box} from "@mui/material";
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DatetimeRangePicker from "react-bootstrap-daterangepicker";
import _ from 'lodash';

export default function DateRangeFilter({startDate, endDate, handleChange, additionalRanges = []}) {

    const ranges = {
        'Today': [moment(), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Month to Date': [moment().startOf('month'), moment()],
        'Year to date': [moment().startOf('year'), moment()],
        'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }
    if (additionalRanges) {
        additionalRanges.forEach((range) => {
            ranges[range.label] = [range.startDate, range.endDate]
        });
    }

    const [defaultStartDate, setDefaultStartDate] = useState(!_.isEmpty(startDate) ? moment(startDate) : moment().subtract(6, 'days'));
    const [defaultEndDate, setDefaultEndDate] = useState(!_.isEmpty(endDate) ? moment(endDate) : moment().subtract());
    const placeholder = `${defaultStartDate.format('MM/DD/YYYY')} - ${defaultEndDate.format('MM/DD/YYYY')}`;


    function handleEvent(event, picker) {
        if(_.get(event, 'handleObj.type') === 'apply'){
            setDefaultStartDate(picker.startDate);
            setDefaultEndDate(picker.endDate);
            handleChange(picker.startDate, picker.endDate);
        }
    }

    return(
        <Fragment>
            <Box sx={styles.input}>
                <CalendarMonthIcon sx={styles.inputIcon} />
                <DatetimeRangePicker
                    startDate={defaultStartDate}
                    endDate={defaultEndDate}
                    ranges={ranges}
                    onEvent={handleEvent}>
                    <input className="input-popup color-grey-dark font-14 tms-ellipsis"
                           type="text"
                           placeholder={placeholder}
                           tabIndex="-1"
                    />
                </DatetimeRangePicker>
            </Box>
        </Fragment>
    );
}

const styles = {
    heading: {
        fontSize: '18px',
        mr: 2,
        display: { xs: 'none', sm: 'block' }
    },
    input: {
        height: '45px',
        borderRadius: '8px',
        overflow: 'hidden',
        width: { xs: '100%', sm: 'auto' },
        position: 'relative',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        border: '1.5px solid #9EA9A9',
        '*': {
            fontFamily: 'LarsseitMedium',
            fontSize: '16px',
            backgroundColor: 'transparent',
            border: 'none!important',
            outline: 'none!important',
            cursor: 'pointer',
            '::placeholder': {
                color: '#144042',
            }
        },
        '.react-bootstrap-daterangepicker-container': {
            width: '100%',

            'input': {
                color: '#0C2728',
                paddingLeft: '16px',
                marginRight: '-68px',
                width: '280px',
            }
        }
    },
    inputIcon: {
        fontSize: '25px!important',
        flexShrink: 0,
        color: '#144042',
        mr: '-5px',
        ml: '10px',
        pointerEvents: 'none',
    },
}