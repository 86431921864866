import {Fragment, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Box} from "@mui/material";
import _, {isEmpty} from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import basicModalStyles from "../../Utilities/basicModalStyles";
import {Input} from "../../Utilities/Input";
import SetupModalBottomBar from "../../Utilities/SetupModalBottomBar";
import {dixaActions} from "_actions";
import {dixaConstants} from "_constants";

function DixaSetupModalForm({description, buttonLabel, extraInfoLabel, openInNewTab, handleSubmit}) {
    const effectRan = useRef(false);
    const [disabled, setDisabled] = useState(true);
    const dispatch = useDispatch();
    const apiTokenStatus = useSelector((state) => state.dixa.apiTokenStatus);
    const isApiTokenValid = _.get(apiTokenStatus, 'isValid');
    const verifyApiTokenLoading = useSelector((state) => state.dixa.verifyApiTokenLoading);
    const createAccountLoading = useSelector((state) => state.dixa.createAccountLoading);
    const loading = false;

    useEffect(() => {
        if(effectRan.current === false) {
            resetApiTokenStatus();
            return () => effectRan.current = true;
        }
    }, []);

    const performAction = (value) => {
        setDisabled(isEmpty(value));
        if(isEmpty(value) || loading) {
            return;
        }
        verifyToken(value);
    };

    const resetApiTokenStatus = () => {
        dispatch(dixaActions.resetApiTokenStatus());
    }

    const verifyToken = (apiToken) => {
        dispatch(dixaActions.verifyApiToken(apiToken));
    }

    const onSubmit = (params) => {
        dispatch(dixaActions.createAccount(_.get(params, 'apiToken')));
    };

    const Status = () => {
        if(verifyApiTokenLoading) {
            return <StatusProgress />;
        } else if (!verifyApiTokenLoading && isApiTokenValid !== undefined) {
            return <StatusResult />
        } else {
            return <Fragment />
        }
    }

    const StatusProgress = () => {
        return (
            <Box sx={styles.statusContainer}>
                <Fragment>
                    <Box sx={{}}>
                        Verifying credential...
                    </Box>
                    <Box sx={styles.progressContainer}>
                        <CircularProgress size={15} sx={{ color: '#144042'}}/>
                    </Box>
                </Fragment>
            </Box>
        )
    }

    const StatusResult = () => {
        return (
            <Box sx={styles.statusContainer}>
                {isApiTokenValid ? `Valid credential` : `Invalid credential`}
            </Box>
        )
    }

    return (
        <Fragment>
            <Box sx={basicModalStyles.container}>
                <Box sx={basicModalStyles.description}>
                    {description}
                </Box>
                <form name="dixaForm" onSubmit={ handleSubmit(onSubmit) }>
                    <Box>
                        <Field
                            name={'apiToken'}
                            component={Input}
                            placeholder={'API Token'}
                            performAction={performAction}
                            disabled={verifyApiTokenLoading || createAccountLoading}
                        />
                        <Status />
                    </Box>

                    <SetupModalBottomBar
                        extraInfoLabel={extraInfoLabel}
                        extraInfoLink={dixaConstants.SETUP_INSTRUCTIONS_LINK}
                        openInNewTab={openInNewTab}
                        buttonLabel={buttonLabel}
                        disabled={createAccountLoading || disabled || !isApiTokenValid}
                        loading={createAccountLoading}
                    />
                </form>
            </Box>
        </Fragment>
    )
}

const styles = {
    statusContainer: {
        mt: '8px',
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        position: 'absolute',
        display: 'flex'
    },
    progressContainer: {
        mt: '2px',
        ml: '7px'
    }

}

const formConfig = {
    form: 'dixaForm',
};

export default reduxForm(formConfig)(DixaSetupModalForm);