import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import { DateRange } from 'components/common/formFields/DateRange'


class FilterForm extends Component {
    state = {
        dateRange: {
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Month to Date': [moment().startOf('month'), moment()],
            'Year to Date': [moment().startOf('year'), moment()],
            'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        }
    };

    render(){
        let { submitting, onDatePickerUpdate, startDate, endDate, onFormSubmit} = this.props

        return (
            <Form inline onSubmit={onFormSubmit} className="messages-page-filter-form">
                <div>
                    <span className="font-14 color-grey-dark">Date</span>
                    <label className="calendar-today tms-inbox-calendar" style={{display: "block"}}>
                        <Field
                            submittedStartDate={startDate}
                            submittedEndDate={endDate}
                            id="datePicker"
                            name="datePicker"
                            defaultDateRange="today"
                            onEvent={onDatePickerUpdate}
                            values={startDate}
                            component={DateRange}
                        />
                    </label>
                </div>

                <div style={{marginTop: "15px"}}>
                    <Button bsStyle="success" type="submit" disabled={submitting} style={{width: "100%"}}>
                        <i className='fa fa-filter'/> Filter
                    </Button>
                </div>
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        currentUser: auth
    };
};

FilterForm = connect(mapStateToProps)(FilterForm);
const filterForm = reduxForm({
    form: 'FilterForm'
})(FilterForm);

export { filterForm as FilterForm }
