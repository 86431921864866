import AccordionItem from 'components/v2/common/AccordionItem';
import styles from "../../organizationMainStyles";
import React from "react";
import MessageResponseSummary from "./MessageResponseSummary";
import {Box} from "@mui/system";
import CircularProgress from "@material-ui/core/CircularProgress";
import MessageResponseDetails from "./MessageResponseDetails";

const loadingInProgress = (
    <Box sx={styles.bodyLoading}>
        <CircularProgress sx={styles.bodyLoadingSpinner} />
    </Box>
);

export default function MessageResponse({autoAction, organizationId, mode, label}) {
    return (
        <AccordionItem
                key={autoAction.dataSourceHandle.id}
                summaryComponent={<MessageResponseSummary autoAction={autoAction} />}>
            <MessageResponseDetails key={autoAction.dataSourceHandle.id} autoAction={autoAction} organizationId={organizationId} mode={mode} label={label}/>
        </AccordionItem>
    );
}
