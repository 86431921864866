import {utilityService, zendeskOauthService} from '_services';
import { oauthConstants, alertMessages } from '_constants';
import { history } from '_helpers';
import {SnackBar} from "../components/common";

function initiateOauth(params) {
  function request() {
    return { type: oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_REQUEST };
  }

  function success(authUrl) {
    return { type: oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_SUCCESS, payload: authUrl };
  }

  function failure(error) {
    return { type: oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_FAILURE, payload: error };
  }

  return (dispatch) => {
    dispatch(request());

    zendeskOauthService.initiateOauth(params)
      .then(
        authUrl => dispatch(success(authUrl)),
        (error) => {
          if (params.type && params.type === 'CHAT') {
            SnackBar.showMessage(alertMessages.ZENDESKCHAT_OAUTH_ERROR);
          }
          dispatch(failure());
        },
      );
  };
}

function initOauth(params) {
  function request() {
    return { type: oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_REQUEST };
  }
  function success(authUrl) {
    return { type: oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_SUCCESS, payload: authUrl };
  }
  function failure(error) {
    return { type: oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_FAILURE, payload: error };
  }

  return (dispatch) => {
    dispatch(request());
    zendeskOauthService.initiateOauth(params)
      .then((res) => {
          utilityService.redirectToAuthUrl(res.url);
          dispatch(success(res))}
        ,(error) => {
          if (params.type && params.type === 'CHAT') {
            SnackBar.showMessage(alertMessages.ZENDESKCHAT_OAUTH_ERROR);
          }
          dispatch(failure());
        },
      );
  };
}

function genAccessToken(params) {
  let callbackUrl = localStorage.getItem('redirectUrl');
  callbackUrl = JSON.parse(callbackUrl).pathname;
  return (dispatch) => {
    dispatch(request());

    zendeskOauthService.genAccessToken(params)
      .then(
        (res) => {
          if (res) {
            history.push(callbackUrl);
            dispatch(success(res));
            SnackBar.showMessage(alertMessages.ZENDESK_OAUTH_SUCCESS);
          }
        },
        (error) => {
          history.push(callbackUrl);

          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.ZENDESK_OAUTH_FAILURE);
        },
      );
  };

  function request() {
    return { type: oauthConstants.ZENDESK_GENTOKEN_REQUEST };
  }

  function success(res) {
    return { type: oauthConstants.ZENDESK_GENTOKEN_SUCCESS, payload: res };
  }

  function failure(error) {
    return { type: oauthConstants.ZENDESK_GENTOKEN_FAILURE, payload: error };
  }
}

function generateAccessToken(params, callbackUrl = '/signup/schedule', onErrorCallbackUrl = '/signup/add-channel') {
  return (dispatch) => {
    dispatch(request());
    zendeskOauthService.genAccessToken(params)
      .then(
        (res) => {
          if (res) {
            history.push(callbackUrl);
            dispatch(success(res));
            SnackBar.showMessage(alertMessages.ZENDESK_OAUTH_SUCCESS);
          }
        },
        (error) => {
          history.push(onErrorCallbackUrl);
          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.ZENDESK_OAUTH_FAILURE);
        },
      );
  };

  function request() {
    return { type: oauthConstants.ZENDESK_GENTOKEN_REQUEST };
  }
  function success(res) {
    return { type: oauthConstants.ZENDESK_GENTOKEN_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: oauthConstants.ZENDESK_GENTOKEN_FAILURE, payload: error };
  }
}

export const zendeskOauthActions = {
  initiateOauth,
  genAccessToken,
  initOauth,
  generateAccessToken,
};
