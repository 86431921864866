import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import _ from "lodash";
import CompanyLogo from "../../Utilities/CompanyLogo";
import CompanyIcon from "../../Utilities/CompanyIcon";
import useGetOrganization from "../../Utilities/hooks/useGetOrganization";
import CompanyResources from "./CompanyResources/CompanyResources";
import CurrentBalance from "./CurrentBalance/CurrentBalance";

export default function VoteHeaderSection() {
    const {organization, loadingOrganization} = useGetOrganization();
    const name = _.get(organization, 'name');
    const logo = _.get(organization, 'logo');

    return (
        <Grid container sx={{borderBottom: '1px solid #CED4D4'}}>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{padding: '20px'}}>
                <Box sx={{display: 'flex'}}>
                    {/*<Box sx={{marginRight: '10px'}}>*/}
                    {/*    <CompanyIcon companyName={name}/>*/}
                    {/*</Box>*/}
                    <Box>
                        <CompanyLogo logoUrl={logo} />
                    </Box>
                </Box>
            </Grid>
            <Grid container item xs={12} sm={12} md={6} lg={6} sx={{}}>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                    <CompanyResources />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <CurrentBalance />
                </Grid>
            </Grid>
        </Grid>
    )
}