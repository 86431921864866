import React, {Component, Fragment} from 'react';
import {ShowMoreToggle} from "./ShowMoreToggle";
import moment from 'moment/moment';
import {stripNumbersFromUsername} from "_helpers";
import _ from "lodash";


/**
 * @author Chris Okebata
 */

class ConversationItem extends Component{

  render() {
    const { keyId, message } = this.props;

    if(!message) return <div/>;

    const { text, author, dateCreated, dateCreatedFromSource, createdDate } = message;
    const msgSource = _.get(message, 'messageSource.name');
    let date = (dateCreatedFromSource ? dateCreatedFromSource : dateCreated) || createdDate;
    date = moment(date).format('MMM D, YYYY h:mm a');


    return(
      <Fragment>
        <div style={{borderBottom: '1px solid #CED2D0', marginBottom: '0px', padding: '15px 0 5px 0'}}>
          <div className="font-14 color-grey-dark" style={{display: 'flex', justifyContent: 'space-between' }}>
            <div style={{width: '50%'}} className="tms-ellipsis">
              {stripNumbersFromUsername(author)}
            </div>
            <div style={{width: '50%', textAlign: 'right'}} className="tms-ellipsis">
              {date}
            </div>
          </div>

          <div style={{padding: '10px 0'}}>
            <ShowMoreToggle
              keyId={keyId}
              text={text}
              shouldParseText={true}
              msgSource={msgSource}
            />
          </div>

        </div>
      </Fragment>
    )
  }
}

export { ConversationItem };