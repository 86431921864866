import logoTikTok from 'components/images/logos/logo_tiktok.svg'
import useTikTokSetup from './useTikTokSetup'
import ChannelCard from '../../ChannelCard'

export default function TikTokEntry() {
	const { initiateTikTokSetup } = useTikTokSetup({ callbackPath: '/tiktok/accounts' })
	return (
		<ChannelCard
			icon={logoTikTok}
			label={'TikTok'}
			description={'Reply to comments and posts that mention your brand'}
			onClick={initiateTikTokSetup}
		/>
	)
}