import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

function getAll(params = {}) {

    return fetch(`${SERVER_URL}/api/payoutRates?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getPayout(id) {

    return fetch(`${SERVER_URL}/api/payoutRates/${id}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function createPayout(data) {

    return fetch(`${SERVER_URL}/api/payoutRates`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

function updatePayout(data, id) {

    return fetch(`${SERVER_URL}/api/payoutRates/${id}`,
        {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

export const payoutsService = {
    getAll,
    getPayout,
    createPayout,
    updatePayout,
};

