import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {proactiveActions} from "_actions";
import _ from "lodash";
import {CompanyTemplateBuilder} from "../objects/CompanyTemplateBuilder";
import TemplateActions from "../../../../common/TemplateActions";

export default function (params) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(proactiveActions.getCompanyTemplates(params));
    }, [params.startDate, params.endDate]);

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingOrganizationTemplates;
    const templatesObj = proactive.organizationTemplates;
    const templateList = _.get(templatesObj, 'items') || [];
    const totalCount = _.get(templatesObj, 'totalCount') || 0;
    const rows = [];

    if(!loading && templateList.length > 0) {
        templateList.map( template => {
            const builder = new CompanyTemplateBuilder();
            builder.setName(_.get(template, 'name') || '-');
            builder.setDescription(_.get(template, 'description') || '-');
            builder.setActions(<TemplateActions id={_.get(template, 'id')}/>);

            rows.push(builder.template);
        });
    }

    const columns = [
        {
            id: 'name',
            label: 'Template name',
            width: 250
        },
        {
            id: 'description',
            label: 'Description',
            width: 250,
        },
        {
            id: 'actions',
            label: 'Actions',
            width: 20,
        },
    ];

    return { rows, columns, totalCount, loading };
}