import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';
import { SERVER_URL } from 'config';

export const msgDataSourceService = {
    getAll,
    getMsgDataSource,
    update,
    deleteMsgDataSource,
    updateMsgDataSource,
    getAllDataSources,
    toggleClosedTicketActioning,
    saveSupportEmail,
    getAccountInboxes,
    saveSelectedInboxes,
    saveStatusOnReply,
    getOrganizationsForMsgSource,
    getOrganizationViews,
    getViewsTickets,
    retrievePlatformMacros,
    getSavedPlatformMacros,
    saveSelectedPlatformMacros,
    deletePlatformMacro,
    updateMacro,
};

/**
 *
 * @param params
 * @returns {Promise<Response | never>}
 */
function getAll(params) {
    return fetch(`${SERVER_URL}/api/msgDataSources?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

/**
 *
 * @param id
 * @returns {Promise<Response | never>}
 */
function getMsgDataSource(id) {
    return fetch(`${SERVER_URL}/api/msgDataSources/${id}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

/**
 *
 * @param msgDataSource
 * @returns {Promise<Response | never>}
 */
function update(msgDataSource) {

    return fetch(`${SERVER_URL}/users/${msgDataSource.id}`,
        {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(msgDataSource)
        })
        .then(handleHttpResponse);
}

function deleteMsgDataSource(id){

    return fetch(`${SERVER_URL}/api/msgDataSources/${id}/delete`,
        {
            method: 'DELETE',
            headers: authHeader(),
        })
        .then(handleHttpResponse)
}

function updateMsgDataSource(msgDataSource, params){
    let updateUrl = `${SERVER_URL}/api/msgDataSources/${msgDataSource.id}/update`

    if (params){
        updateUrl = `${updateUrl}?${objectToQueryString(params)}`
    }
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(msgDataSource)
    };

    return fetch(updateUrl, requestOptions)
        .then(handleHttpResponse);
}

function getAllDataSources(params) {
    return fetch(`${SERVER_URL}/api/msgDataSource/allDataSources?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function toggleClosedTicketActioning(data) {
    return fetch(`${SERVER_URL}/api/msgDataSource/toggleClosedTicketActioning`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

function saveSupportEmail(payload) {
    return fetch(`${SERVER_URL}/api/msgDataSource/supportEmail/save`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(payload)
        })
        .then(handleHttpResponse);
}

function saveSelectedInboxes(payload) {
    return fetch(`${SERVER_URL}/api/msgDataSource/selectedInboxes/save`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(payload)
        })
        .then(handleHttpResponse);
}

function getAccountInboxes(dataSourceId) {
    return fetch(`${SERVER_URL}/api/msgDataSource/gladly/${dataSourceId}/inboxes`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function saveStatusOnReply(payload) {
    return fetch(`${SERVER_URL}/api/msgDataSource/statusOnReply`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(payload)
        })
        .then(handleHttpResponse);
}

function getOrganizationsForMsgSource(msgSource) {
    return fetch(`${SERVER_URL}/api/msgSource/organizations?msgSource=${msgSource}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function getOrganizationViews(currentOrg, msgSource) {
    return fetch(`${SERVER_URL}/api/gorgiasPlatform/views?orgId=${currentOrg}&msgSource=${msgSource}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function getViewsTickets(viewId, currentOrg, dataSource) {
    return fetch(`${SERVER_URL}/api/gorgiasPlatform/views/${viewId}/tickets?orgId=${currentOrg}&dataSource=${dataSource}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function retrievePlatformMacros(params) {
    return fetch(`${SERVER_URL}/api/v1/msgDataSource/platformMacros?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function getSavedPlatformMacros(params) {
    return fetch(`${SERVER_URL}/api/v1/msgDataSource/platformMacros/saved?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function saveSelectedPlatformMacros(payload) {
    return fetch(`${SERVER_URL}/api/v1/msgDataSource/platformMacros`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(payload)
        })
        .then(handleHttpResponse);
}

function deletePlatformMacro(macroId) {
    return fetch(`${SERVER_URL}/api/v1/msgDataSource/platformMacros/${macroId}`,
        {
            method: 'DELETE',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function updateMacro(macroId) {
    return fetch(`${SERVER_URL}/api/v1/msgDataSource/platformMacros/${macroId}`,
        {
            method: 'PUT',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}
