import {Box} from "@mui/system";
import {Chip} from "@mui/material";

export default function EditorTagChip({tag, handleDeselect}) {

    const handleRemove = () => {
        handleDeselect(tag);
    }

    return(
        <Box sx={styles.chipContainer}>
            <Chip
                label={tag}
                onDelete={handleRemove}
            />
        </Box>
    )
}

const styles = {
    chipContainer: {p: '7px 0 0 7px'}
}