import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { GenericModal } from 'components/common';
import {connect} from "react-redux";

const payoutRateText = "Payout Rate";

const SetPayoutPricingModal = (props) => {
    const {
        handleSubmit, title, showModal, closeModal, value,
        submitting, handleChange, type, all_pricing, all_payouts
    } = props;

    let itemsList = type === payoutRateText ? all_payouts : all_pricing;
    let selectName = type === payoutRateText ? 'payoutRate' : 'pricingRate';
    return (
        <GenericModal
            headerText={title}
            hideFooter
            show={showModal}
            onHide={closeModal}
        >
            <Form onSubmit={handleSubmit}>
                <span className="font-14 color-grey-dark">Select {type}</span>
                <div className="form-group has-success">
                    <label id="drop-down-arrow-down">
                        <select name={selectName} onChange={handleChange} value={value} className="form-control select-field color-grey-dark">
                            <option key='' value="" hidden="hidden">Select {type}</option>
                            {itemsList.length > 0 && itemsList.map((item, index) => (
                                <option value={item.id} key={index}>
                                    {item.label} ({item.amount} {item.currency && item.currency.code ? item.currency.code : ''})
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className="col-12">
                    <Button
                        className="btn btn-success btn-block font-15"
                        type="submit"
                        disabled={submitting || !value}
                    >
                        Update
                    </Button>
                </div>
            </Form>
        </GenericModal>
    );
};

const mapStateToProps = (state) => {
    const { payout: { all_payouts }, pricing: { all_pricing }} = state
    return {
        all_pricing, all_payouts
    }
};

const payoutPricing = connect(mapStateToProps)(SetPayoutPricingModal);
export { payoutPricing as SetPayoutPricingModal }
