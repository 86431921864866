import React from "react";
import {reduxForm} from "redux-form";
import {CriteriaResponseCheckboxes} from "../Fields/CriteriaResponseCheckboxes";
import {answerRateCriteriaOptions} from "../MetricsDetailUtils";
import {ChannelsCheckboxes} from "../Fields/ChannelsCheckboxes";
import Stack from "@mui/material/Stack";

const AnswerRateFilterDropdownForm = ({
                                          handles,
                                          setSelectedCriteria,
                                          setSelectedChannels,
                                          selectedChannels,
                                          selectedCheckboxes,
                                          setSelectedCheckboxes,
                                          allChannelsSelected,
                                          setAllChannelsSelected,
}) => {

    const criteriaHeading = "Criteria"
    return(
        <Stack direction={'row'} spacing={1}>
            <ChannelsCheckboxes
                handles={handles}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                allChannelsSelected={allChannelsSelected}
                setAllChannelsSelected={setAllChannelsSelected}
            />

            <div className="filter-all-criteria" style={{marginLeft: '15px'}}>
                <CriteriaResponseCheckboxes
                    setSelectedCriteria={setSelectedCriteria}
                    criteriaOptions={answerRateCriteriaOptions}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    criteriaHeading={criteriaHeading}
                />
            </div>
        </Stack>
    )
}

const answerRateFilterDropdownForm = reduxForm({
    form: 'AnswerRateFilterDropdownForm'
})(AnswerRateFilterDropdownForm);

export { answerRateFilterDropdownForm as AnswerRateFilterDropdownForm }