import React, {useState} from 'react'
import {Box} from "@mui/system";
import Button from "@mui/material/Button";
import {Switch, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";

// copied from /v2/views/Onboarding/Utilities/ButtonContained
function ActionButton({ label, action, disabled, sx, shouldPassTestValue = false, isTestEnv = false }) {
  return(
    <Box sx={{...sx, ...{height: '40px'}}}>
      <Button 
        sx={{
          width: '100%',
          fontSize: '16px',
          padding: '12px 24px',
          color: '#fff',
          fontFamily: 'LarsseitMedium',
          lineHeight: 1,
          textTransform: 'none',
          borderRadius: '50px',
          backgroundColor: '#368C86',
          ':hover': {
            backgroundColor: '#34756f',
            color: 'white',
          },
          ':disabled': {
            color: '#fff',
            backgroundColor: '#368C8680'
          },
          ':focus': {
            outline: '0'
          }
        }}
        type="submit"
        onClick={() => {shouldPassTestValue ? action(isTestEnv) : action()}}
        variant="contained"
        disableElevation
        disabled={disabled}>
        {label}
      </Button>
    </Box>
  )
}

export default function ChannelCard({
	label,
	description,
	icon,
	onClick,
    allowTestInstanceConnection = false
}) {
    const [connectTestEnv, setConnectTestEnv] = useState(false)
    const toggleTestInstance = () =>{
        setConnectTestEnv(!connectTestEnv);
    }
	return (
    <Box component={'li'} sx={styles.root}>
      <Box sx={styles.logoWrapper}>
        {icon ? <Box component={'img'} sx={styles.logo} src={icon} alt={`${label} Logo`} /> : 'Logo'}
      </Box>
      <Box sx={styles.content}>
        <Box>
          <Box component={'h2'} sx={styles.contentLabel}>
            {label}
          </Box>
          <Box component={'p'} sx={styles.p}>
            {description}
          </Box>
            {allowTestInstanceConnection &&
                <Box>
                    <FormControlLabel control={<Switch onChange={toggleTestInstance} sx={styles.switch} />}
                                      label={<Typography sx={styles.instanceLabel}>Test Instance</Typography>}/>
                </Box>
            }
        </Box>
          <ActionButton
          sx={styles.button}
          label={connectTestEnv ? 'Connect Test Instance' : 'Connect'}
          action={onClick}
          shouldPassTestValue={allowTestInstanceConnection}
          isTestEnv={connectTestEnv}/>
      </Box>
    </Box>
	)
}
const styles = {
	root: {
    p: { xs: 2, sm: 3 },
    display: 'flex',
    borderRadius: { xs: '10px', sm: 0 }, 
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#B6BEBF',
    backgroundColor: '#FFF',
    borderTopWidth: { xs: 1, sm: 0 },
    mb: { xs: 3, sm: 0 },
    '&:first-of-type': { borderTopLeftRadius: { sm: 10 }, borderTopRightRadius: { sm: 10 }, borderTopWidth: 1, },
    '&:last-of-type': { mb: 0, borderBottomLeftRadius: { sm: 10 }, borderBottomRightRadius: { sm: 10 } },
  },
	p: { m: 0, fontFamily: 'LarsseitRegular', fontSize: 18, lineHeight: 1.2, letterSpacing: '-0.02em', color: '#0C2728' },
  logoWrapper: {
		height: { xs: '40px', sm: '60px' },
    width: { xs: '40px', sm: '60px' },
    flexShrink: 0,
    alignSelf: { xs: 'flex-start', md: 'center' },
    mr: { xs: 1.5, sm: 4 },
  },
	logo: {
		height: '100%',
    width: '100%',
	},
  content: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { xs: 'column', sm: 'row' },
  },
  contentLabel: {
    fontFamily: 'LarsseitBold',
    fontSize: 14,
    lineHeight: 1.2,
    letterSpacing: '0.05em',
    color: '#0C2728',
    textTransform: 'uppercase',
    mt: 0,
    mb: { xs: 0, sm: 0.5 },
  },
  button: {
    alignSelf: { xs: 'flex-start', sm: 'center' },
		width: { xs: '100%', sm: 'auto' },
    ml: { xs: 0, sm: 2 },
    mt: { xs: 1, sm: 0 },
  },
  instanceLabel: {
      m: 0,
      fontFamily: 'LarsseitRegular',
      fontSize: 18, lineHeight: 1.2,
      letterSpacing: '-0.02em',
      color: '#0C2728'
  },
  switch: {
      "& .MuiSwitch-switchBase.Mui-checked": {
          color: '#368c86'
      },
      "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: '#368C86'
      },
      "&:hover .MuiSwitch-switchBase": {
          color: '#368c86'
      },
  }
}