import {Box} from "@mui/system";
import {Fragment} from "react";
import TagFilterSelect from "./TagFilterSelect";
import ActionButton from "../../../../../../common/ActionButton";

export default function TagContent({handleClose, handleSelectedTags, selectedTags}) {
    let tags = [];

    const handleTagsSelection = (value) => {
        tags = value;
    };

    const handleSelect = () => {
        handleClose();
        handleSelectedTags(tags);
    };

    return(
        <Fragment>
            <Box sx={{mb: '20px'}}>
                <TagFilterSelect
                    onChange={handleTagsSelection}
                    selectedTags={selectedTags}
                />
            </Box>

            <Box sx={style.buttonContainer}>
                <Box sx={style.cancelButtonContainer}>
                    <ActionButton
                        label={'Cancel'}
                        action={handleClose}
                        buttonBordered
                        useBorderColor={'#07857C'}
                        withIcon={false}
                        sx={style.cancelButton}
                    />
                </Box>
                <Box>
                    <ActionButton
                        label={'Add'}
                        action={handleSelect}
                        withIcon={false}
                        sx={style.sendButton} />
                </Box>
            </Box>
        </Fragment>
    )
}

const style = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '15px'
    },
    cancelButtonContainer: {
        width: '84px',
        marginRight: '15px'
    },
    cancelButton: {
        height: '35px',
    },
    sendButton: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}