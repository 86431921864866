import React, {Fragment} from "react";
import UserDisplayInitials from "../../common/UserDisplayInitials";
import htmlParser from "he";
import {personalizeResponse} from "_helpers";
import moment from "moment";
import {metricsActions} from "_actions";
import {useDispatch} from "react-redux";
import CsatComponent from "./CsatComponent";
import {parseHtml} from "_helpers";

const MetricsConversation = ({message, pageFilter, nextMessage, csatList, msgSource}) => {
    const dispatch = useDispatch();
    const messageAuthor = message?.msgAuthor;
    const currentUser =  JSON.parse(localStorage.getItem('user'))
    const orgId = currentUser._embedded.organization.id;
    const zone_name =  moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();

    const conversationItemUserDisplayInitials = (name) => {
        return (<UserDisplayInitials
            userName={name}
            size={'default'}
            variant={'rounded'}
            background={'#E6F3F2'}
            color={'#0C2728'}
        />)
    }

    const initials = conversationItemUserDisplayInitials(messageAuthor)
    const dateCreated =  message?.dateCreatedFromSrc || message?.dateCreated;
    const nextMsgDateCreated = nextMessage?.dateCreatedFromSrc || nextMessage?.dateCreated
    const text = parseHtml(htmlParser.decode(personalizeResponse(message.text, messageAuthor, "", null)), msgSource, true, true);

    let showPresaleBtn;

    const setPreSaleValue = (messageId, orgId, preSaleValue) => {
        dispatch(metricsActions.setPreSaleValue({messageId, organization: orgId, preSaleValue}))
    };

    if (pageFilter === 'presale' && message?.presale != null)  {
        showPresaleBtn =
            <div className="sale-options">
                {message.presale &&
                     <Fragment>
                         <span className="selected-sale-option">Pre-sale</span>
                         <button className="preSaleBtn"
                                 onClick={() => setPreSaleValue(message.msgId, orgId, false)}>Other
                         </button>
                     </Fragment>
                }
                {!message.presale &&
                    <Fragment>
                        <button className="preSaleBtn"
                                onClick={() => setPreSaleValue(message.msgId, orgId, true)}>Pre-sale
                        </button>
                        <span className="selected-sale-option">Other</span>
                    </Fragment>
                }
            </div>
    }

    return(
        <Fragment>
            <div className="md-conversation-container" style={{width: '100%', color: '#0C2728'}}>
                <div className="md-msg-container">
                    <div className="row conversation-item-container">
                        <div className="col-2 metricsDetail-dp">
                            <div className="metricsDetail-avatar">{initials}</div>
                        </div>
                        <div className="col-8 conversation-item-row">
                            <div style={{display: 'flex', width: '100%', paddingBottom: '5px'}}>
                                <div style={{width: '30%', textAlign: 'left'}}
                                     className="metricsDetail-fullName">{messageAuthor}</div>
                                <div style={{width: '70%', textAlign: 'right'}} className="metricsDetail-dateTime"><span
                                    className="metrics-detail-date">
                                {moment.utc(dateCreated).fromNow()}
                                    {` (${moment(dateCreated).format('MMMM Do YYYY HH:mm')} ${timezone})`}
                                    </span></div>
                            </div>
                            <div className="metricsDetail-full-msg"
                                 style={message.direction.toLowerCase() === "outgoing" ? {
                                     background: '#F3F5F6',
                                     border: '1px solid #DCE2E3'
                                 } : {}}>{text}
                            </div>
                            {showPresaleBtn}
                        </div>
                    </div>
                </div>
                {csatList.filter(
                    item => (moment(item.csatDate).isSameOrAfter(moment(dateCreated)) && !nextMessage)
                    || (moment(item.csatDate).isSameOrAfter(moment(dateCreated)) && nextMessage &&
                        moment(item.csatDate).isBefore(moment(nextMsgDateCreated)))
                ).map(csatItem =>
                    <CsatComponent
                        key={csatItem.mmdId}
                        timezone={timezone}
                        mmdComment={csatItem.mmdComment}
                        unifiedScore={csatItem.mmdUnifiedScore}
                        csatDate={csatItem.csatDate}
                    />)
                }
            </div>
        </Fragment>
    )
}

export default MetricsConversation;

