import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {queryStringToObject} from "../../_helpers";
import {alertActions, dataSourceHandleActions, messageActions, organizationActions} from "../../_actions";
import moment from "moment";
import {Button, Col, Form, Row} from "react-bootstrap";
import DatetimeRangePicker from "react-bootstrap-daterangepicker";
import SummaryItem from "../SummaryPage/SummaryItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Field, reduxForm} from "redux-form"
import {SearchableSelect} from "../common/formFields/SearchableSelect";
import {MultiSelectContextMenu} from "../common";

class ResponseTimes extends Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search.substring(1);
    const filterParams = queryStringToObject(search);
    let { channels } = filterParams;
    channels = channels ? channels.split(",") : [];

    filterParams.startDate = filterParams.startDate ?
      moment(filterParams.startDate) :
      moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
    filterParams.endDate = filterParams.endDate ?
      moment(filterParams.endDate) :
      moment().add(1, 'd').format('YYYY-MM-DD HH:mm:ss');
    filterParams.channels = channels;

    this.state = {
      filterParams: {
        organization: filterParams.organization ? filterParams.organization : '',
        ...filterParams
      },
    };

    this.getDataSourceHandle = this.getDataSourceHandle.bind(this)
  }

  componentDidMount() {
    const {filterParams} = this.state;
    const { organization } = filterParams;
    const {dispatch} = this.props;
    const visibility = ['PUBLIC', 'PRIVATE', 'FEATURED'];
    dispatch(organizationActions.getAll({visibility}));
    this.getDataSourceHandle(organization)
  }

  handleOrganizationUpdateForm = (data) => {
    this.setState((prevState) => {
      return {
        filterParams: {
          ...prevState.filterParams,
          organization: data,
        },
      }
    }, function (){
      const {filterParams} = this.state;
      const {organization} = filterParams;
      this.getDataSourceHandle(organization);
    });
  }

  handleDatePickerUpdate = (event, data) => {
    this.setState((prevState) => {
      return {
        filterParams: {
          ...prevState.filterParams, ...{
            startDate: data.startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDate: data.endDate.format('YYYY-MM-DD HH:mm:ss'),
          }
        }
      }
    })
  }

  getDataSourceHandle(organizations){
    this.props.dispatch(dataSourceHandleActions.getAll({
      organization: organizations.id,
      max:1000
    }));
  }

  getChannels = (dataSourceHandles, returnValue) => {
    const channels = [];
    dataSourceHandles.forEach(handle => {
      if(returnValue === 'ids'){
        channels.push(handle.trendsId);
      }else{
        const dataSource = {
          value: handle.trendsId,
          name: handle.name,
        };
        channels.push(dataSource);
      }
    });

    return channels;
  }

  handleChannelsFilterChange = (channels) => {
    const { key } = this.state;
    const { startDate, endDate } = this.state.filterParams;
    this.populateParams(key, channels, startDate, endDate);
  };

  populateParams(key, channels, inputStartDate, inputEndDate) {
    const startDate = moment(inputStartDate).format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(inputEndDate).format('YYYY-MM-DD HH:mm:ss');

    this.setState(prevState => ({
      key,
      filterParams: {
        ...prevState.filterParams,
        startDate,
        endDate,
        channels,
      },
    }));
    const {filterParams} = this.state;
    return {
      ...filterParams,
      startDate: moment(filterParams.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(filterParams.endDate).format('YYYY-MM-DD HH:mm:ss'),
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {organization, startDate, endDate, channels} = this.state.filterParams;
    const {dispatch} = this.props;
    if (!organization) {
      dispatch(alertActions.error("Organization filed is required"));
      return;
    }
    dispatch(messageActions.getResponseTimes({...{organization: organization.value}, startDate, endDate, channels}));
  }

  renderResponseTimeStats = (responseTimesDetails) => {
    let {averageResponseTime, percentResponse, averageSystemDelayTime} = responseTimesDetails;
    averageResponseTime = averageResponseTime ? averageResponseTime : '-';
    percentResponse = percentResponse ? percentResponse : '-';
    averageSystemDelayTime = averageSystemDelayTime ?averageSystemDelayTime : '-';

    return (
      <div className="shadow-border margin-right-500"
           style={{marginLeft: '50px', padding: '20px 33px 33px 33px'}}>
        <Row className="text-center" style={{margin: '2%'}}>
          <Col md={4} style={{borderRight: '0.5px solid lightGrey'}}>
            <SummaryItem value={averageResponseTime} label="AverageResponse time in minutes"/>
          </Col>
          <Col md={4} style={{borderRight: '0.5px solid lightGrey'}}>
            <SummaryItem value={percentResponse} label="% of response times < 30 minutes"/>
          </Col>
          <Col md={4}>
            <SummaryItem value={averageSystemDelayTime} label="Average system delay time in minutes"/>
          </Col>
        </Row>
      </div>
    );
  }

  mapLabelAndValueToOrg = (item) => {
    item.value = item.id;
    item.label = item.name;
    return item;
  }

  render() {
    const {organization, startDate, endDate, channels} = this.state.filterParams;
    const {organizations, responseTimesDetails, loading, dataSourceHandles, dataSourceLoading} = this.props;
    const input = {
      onChange: this.handleOrganizationUpdateForm, onBlur: () => {
      }
    };
    const channelsInput = { onChange: this.handleChannelsFilterChange, value: channels}
    let handles, channelsIds;
    if (dataSourceHandles) {
      handles = this.getChannels(dataSourceHandles, 'dataSource');
      channelsIds = this.getChannels(dataSourceHandles, 'ids');
    }
    let setStartDate = moment(startDate).format('M/D/YYYY');
    const setEndDate = moment(endDate).format('M/D/YYYY');

    return (
      <Fragment>
        <div className="container-pane" style={{display: '', padding: '0 38px'}}>
          <div className="side-bar-pane shadow-border">
            <Form onSubmit={this.handleSubmit}>
              <span className="font-14 color-grey-dark">Organization</span>
              <div style={{marginTop: "3px", display: "block"}}>
                <SearchableSelect
                  name="organization"
                  type="text"
                  placeHolder="Select Organization"
                  input={{...input, value: organization}}
                  options={organizations ? organizations.map(this.mapLabelAndValueToOrg) : []}/>
              </div>
              <div>
                <span className="font-14 color-grey-dark">Date</span>
                <span className="calendar-today tms-inbox-calendar" style={{display: 'block'}}>
                  <DatetimeRangePicker
                    startDate={setStartDate}
                    endDate={setEndDate}
                    onEvent={this.handleDatePickerUpdate}>
                      <input className="input-popup color-grey-dark font-14 tms-ellipsis"
                             type="text"
                             name="date-picker"
                             placeholder={`${setStartDate} - ${setEndDate}`}
                      />
                  </DatetimeRangePicker>
                </span>
              </div>
              <div style={{marginTop: "3px"}}>
                <span className="font-14 color-grey-dark">Channels</span>
                <div className="inbox-context-menu" style={{display: "block"}}>
                  <Field
                    name="channels"
                    fieldName="channels"
                    type="text"
                    displayName={'Channels'}
                    input={channelsInput}
                    options={handles}
                    checkedOptions={this.state.filterParams.channels}
                    ids={channelsIds}
                    handleChange={this.handleChannelsFilterChange}
                    component={MultiSelectContextMenu}
                    loading={dataSourceLoading}
                  />
                </div>
              </div>
              <div style={{marginTop: '20px'}}>
                <Button className="btn btn-success btn-block font-15" type="submit" disabled={loading}>
                  Fetch Stats
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="container-right header-pane-without-border-lines">
          {loading ?
            <div className="shadow-border margin-right-500"
                 style={{
                   display: 'flex',
                   justifyContent: 'center',
                   marginLeft: '50px',
                   padding: '20px 33px 33px 33px'
                 }}>
              <CircularProgress size={40} style={{color: '#008751'}}/>
            </div>
            : this.renderResponseTimeStats(responseTimesDetails)}
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {responseTimes, dataSourceHandles, organizations: {organizations}} = state;
  return {
    organizations: organizations.organizations,
    responseTimesDetails: responseTimes.responseTimesDetails,
    loading: responseTimes.loading,
    dataSourceHandles: dataSourceHandles.dataSourceHandles,
    dataSourceLoading: dataSourceHandles.loading
  };
}
ResponseTimes = connect(mapStateToProps)(ResponseTimes)
const responseTimes = reduxForm({
  form: 'ResponseTimes'
})(ResponseTimes)
export {responseTimes as ResponseTimes};
