import { walletConstants, alertMessages } from '_constants';
import { walletService } from '_services';
import {SnackBar} from "../components/common";

function getAgentWallets(params) {

    return (dispatch) => {
        dispatch(request());

        walletService.getAgentWallets(params)
            .then(
                subscriptions => dispatch(success(subscriptions)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };

    function request() {
        return { type: walletConstants.GET_ALL_AGENT_WALLET_REQUEST };
    }

    function success(result) {
        return { type: walletConstants.GET_ALL_AGENT_WALLET_SUCCESS, payload: result };
    }

    function failure(error) {
        return { type: walletConstants.GET_ALL_AGENT_WALLET_FAILURE, payload: error };
    }
}

function agentPayoutHistory(agentId) {
    return (dispatch) => {
        dispatch(request());

        walletService.agentPayoutHistory(agentId)
            .then(
                history => {
                    dispatch(success(history));
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };

    function request() {
        return {type: walletConstants.AGENT_PAYOUT_HISTORY_REQUEST};
    }

    function success(result) {
        return {type: walletConstants.AGENT_PAYOUT_HISTORY_SUCCESS, payload: result};
    }

    function failure(error) {
        return {type: walletConstants.AGENT_PAYOUT_HISTORY_FAILURE, payload: error};
    }
}

function payoutToAgent(agentId, amount) {
    return (dispatch) => {
        dispatch(request());

        walletService.payoutToAgent(agentId, amount)
            .then(
                history => {
                    dispatch(success(history));
                    SnackBar.showMessage(alertMessages.AGENT_PAYOUT + history.payoutHistory.status);
                },
                error=> {
                    dispatch(failure(error));
                    error.then(
                        reason => {
                            SnackBar.showMessage(reason.message)
                        }
                    );
                },
            );
    };

    function request() {
        return {type: walletConstants.PAYOUT_TO_AGENT_REQUEST};
    }

    function success(result) {
        return {type: walletConstants.PAYOUT_TO_AGENT_SUCCESS, payload: result};
    }

    function failure(error) {
        return {type: walletConstants.PAYOUT_TO_AGENT_FAILURE, payload: error};
    }
}

function handleModal() {
    return (dispatch) => {
        dispatch(success());
    };

    function success() {
        return {type: walletConstants.UPDATE_HANDLE_MODAL};
    }
}

function getAgentWalletBalance(agentId) {
    return (dispatch) => {
        dispatch(request());

        walletService.getAgentWalletBalance(agentId)
            .then(
                history => {
                    dispatch(success(history));
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };

    function request() {
        return {type: walletConstants.GET_AGENT_WALLET_BALANCE_REQUEST};
    }

    function success(result) {
        return {type: walletConstants.GET_AGENT_WALLET_BALANCE_SUCCESS, payload: result};
    }

    function failure(error) {
        return {type: walletConstants.GET_AGENT_WALLET_BALANCE_FAILURE, error};
    }
}

export const walletActions = {
    getAgentWallets,
    agentPayoutHistory,
    payoutToAgent,
    handleModal,
    getAgentWalletBalance
};