import React, {Component} from 'react';
import { Button, Modal } from 'react-bootstrap'
import _ from 'lodash';


/**
 * @author Chris Okebata
 */


const PortalInfo = (props) => {
  const {info, desc} = props;
  const {detailContainer, _info, data} = styles;

  return(
    <div style={detailContainer}>
      <div style={_info}>
        {info}
      </div>
      <div style={data}>
        {desc}
      </div>
    </div>
  )
};

class ViewPortalModal extends Component {


  render () {

    const {onHide, show, headerText, buttonText, portalData} = this.props;

    return (
      <Modal show={show} onHide={onHide} className="tms-modal-dialog">
        <Modal.Header closeButton>
          <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PortalInfo
            info={'Organization'}
            desc={_.get(portalData, 'organization.name')}
          />
          <PortalInfo
            info={'Display Name'}
            desc={_.get(portalData, 'displayLabel')}
          />
          <PortalInfo
            info={'Login URL'}
            desc={_.get(portalData, 'loginUrl')}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="success" style={{ padding: '7px 20px' }} onClick={onHide}>
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const styles = {
  _info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '14px',
    paddingBottom: 2,
  },
  data: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '17px',
    fontWeight: 'bold',
  },
  detailContainer: {
    marginBottom: 8,
    paddingBottom: 7,
    borderBottom: '1px solid #e8e8e8'
  },
};

export { ViewPortalModal };
