import {buildTikTokVideoEmbedUrl, buildYoutubeVideoEmbedUrl} from "./EmbedUtils";
import {Box} from "@mui/system";


export default function YoutubeEmbed({message}) {
    const url = buildYoutubeVideoEmbedUrl(message);
    return <Box component={'iframe'} sx={style.iframe} src={url}/>
}

const style = {
    iframe: {
        width: '100%',
        height: '100%',
        border: 0,
    }
}