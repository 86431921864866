import React, {Component, Fragment} from 'react';
import LoadingPlaceholderBlock from "../../common/LoadingPlaceholderBlock";


/**
 * @author Chris Okebata
 */

const styles = {
  wrapper: {
    borderRadius: '4px',
    padding: '0 20px',
    position:'relative',
    backgroundColor: '#fff',
  },
  container: {
    borderRadius: '0px',
    padding: '0px 0 15px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: '0px',
  },
  bgColor: '#e8e7e7',
};


class ResponseEmptyState extends Component{

  constructor(props){
    super(props);

    this.state = {
      isResponseSelected: false,
      selectedResponseId: '',
    }
  }

  renderOptions = (index) => {
    const { wrapper, container, bgColor } = styles;

    return (
      <div style={wrapper} key={index}>
        <div className="font-14 color-grey-mid" style={{width: '80px', padding: '15px 0 12px 0'}}>
          <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
        </div>

        <div style={{borderBottom: '1px solid #CED2D0', padding: '0 0 12px 0'}}>
          <div style={{...container,}}>
            <div style={{width: '100%', padding: '0px 0 0 0'}}>
              <LoadingPlaceholderBlock height={'67px'} borderRadius={'3px'} backgroundColor={bgColor}/>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: '5px'}}>
              <LoadingPlaceholderBlock width={'70px'} height={'32px'} borderRadius={'3px'} backgroundColor={bgColor}/>
            </div>

            <div style={{marginLeft: '5px'}}>
              <LoadingPlaceholderBlock width={'90px'} height={'32px'} borderRadius={'3px'} backgroundColor={bgColor}/>
            </div>
          </div>
        </div>

      </div>
    )
  };

  render() {

    const { bgColor } = styles;

    return(
      <Fragment>
        <div className="font-14" style={{width: '100%', height: '40px', display: 'flex', alignItems: 'center', padding: '0px 35px', borderBottom: '1px solid #dee2e0', backgroundColor: '#f3f3f3'}}>
          <span className="color-grey-dark" style={{width: '130px'}}>
            <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
          </span>
        </div>

        <div style={{ height: 'calc(100% - 110px)', position: 'relative', backgroundColor: '#fafafa'}}>
          <div className="response-modal"/>

          <div style={{padding: '25px 35px 0 35px', width: '100%', height: 'calc(100% - 45px)', position: 'absolute', top: '0'}}>
            <div style={{border: '1px solid #CED2D0', borderRadius: '4px', backgroundColor: '#ffffff', overflowY: 'auto', height: 'calc(100%)', padding: '0 0 10px 0'}}>

              {/*BEGIN LOOP HERE*/}
                {[1,2,3].map((index) => this.renderOptions(index))}
              {/*END LOOP HERE*/}

            </div>
          </div>

          <div className="font-14 color-grey-dark" style={{width: '100%', height: '45px', position: 'absolute', bottom: '0', padding: '10px 35px', display: 'flex', alignItems: 'center'}}>
            <div style={{width: '80px', marginRight: '40px'}}>
              <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
            </div>
            <div style={{width: '210px'}}>
              <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

}

export { ResponseEmptyState };