import React, {Component} from 'react';
import { Button, Modal } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles';
import {InputTextField} from "../common/formFields/InputTextField";
import {SelectField} from "../common/formFields/SelectField";
import connect from "react-redux/es/connect/connect";
import stylesPortal from "./stylesPortal";
import {isObjectEmpty, required} from "_helpers";
import {Loading} from "../common";
import {Field, reduxForm} from "redux-form";
import {CheckboxMat} from "../common/formFields/CheckboxMat";
import {portalActions} from "_actions";
import _ from 'lodash';


/**
 * @author Chris Okebata
 */

class EditPortalModal extends Component {

  state = {
    applyToAllHandlesStatus: true,
  };

  handleApplyToAllHandlesStatus = event => {
    event.stopPropagation();
    this.setState({
      applyToAllHandlesStatus: event.target.checked
    });
  };

  buildPayload = (values) => {
    const {organization, loginPassword, loginUsername, loginUrl, integratedPortal, displayLabel, dataSourceHandles} = values;
    const {applyToAllHandlesStatus} = this.state;
    const params = {
      organization,
      loginPassword,
      loginUsername,
      loginUrl,
      integratedPortal,
      displayLabel,
      dataSourceHandles: applyToAllHandlesStatus ? [] : [
        {id: dataSourceHandles}
      ],
      isAppliedToAllHandles: applyToAllHandlesStatus
    };

    this.handleEditPortal(_.get(this.props.portalData, 'id'), params)
  };

  handleEditPortal = (id, params) => {
    this.props.dispatch(portalActions.editPortal(id, params));
  };


  render () {

    const {onHide, show, headerText, buttonText, organizationData, allIntegratedPortals, dataSourceHandles, handleSubmit, loading} = this.props;
    const { applyToAllHandlesStatus } = this.state;

    if (isObjectEmpty(organizationData) || isObjectEmpty(allIntegratedPortals) || isObjectEmpty(dataSourceHandles)) {
      return <Loading />
    }

    const {organizations} = organizationData;
    const integratedPortals = Object.values(allIntegratedPortals);

    return (
      <Modal show={show} onHide={onHide} className="tms-modal-dialog">
        <form onSubmit={handleSubmit(this.buildPayload)}>
          <Modal.Header closeButton>
            <Modal.Title>{headerText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div>
              <div style={{marginBottom: 10}}>
                <Field
                  component={SelectField}
                  validate={required}
                  label={'Organization'}
                  placeholder={'Select Organization'}
                  options={organizations}
                  optionPropertyId={'id'}
                  optionPropertyName={'name'}
                  name={'organization'}
                  required
                />
              </div>

              <div style={{marginBottom: 10}}>
                <Field
                  component={InputTextField}
                  validate={required}
                  label={'Display Name'}
                  placeholder={'Portal external name'}
                  type={'text'}
                  name={'displayLabel'}
                  required
                />
              </div>

              <div style={{marginBottom: 10}}>
                <Field
                  component={SelectField}
                  validate={required}
                  label={'Integrated Portals'}
                  placeholder={'Select Portals'}
                  options={integratedPortals}
                  optionPropertyId={'id'}
                  optionPropertyName={'label'}
                  name={'integratedPortal'}
                  required
                />
              </div>

              <div style={{marginBottom: 35}}>
                <div style={{height: '20px', marginTop: '15px', display: 'flex', alignItems: 'center',}}>
                  <CheckboxMat
                    checkedStatus={applyToAllHandlesStatus}
                    styleStatus={applyToAllHandlesStatus}
                    handleOnChange={this.handleApplyToAllHandlesStatus}
                    label={'Apply across all handles'}
                  />
                </div>

                {
                  !applyToAllHandlesStatus &&
                  <div style={{marginTop: 10}}>
                    <Field
                      component={SelectField}
                      validate={ !applyToAllHandlesStatus && required }
                      label={'Handle'}
                      placeholder={'Select Handle'}
                      options={dataSourceHandles}
                      optionPropertyId={'id'}
                      optionPropertyName={'name'}
                      name={'dataSourceHandles'}
                    />
                  </div>
                }
              </div>

              <div style={{marginBottom: 10}}>
                <Field
                  component={InputTextField}
                  validate={required}
                  label={'Login URL'}
                  placeholder={'url'}
                  type={'text'}
                  name={'loginUrl'}
                  required
                />
              </div>

              <div style={{marginBottom: 10}}>
                <Field
                  component={InputTextField}
                  validate={ required}
                  label={'Username'}
                  placeholder={'john-doe'}
                  type={'text'}
                  name={'loginUsername'}
                  required
                />
              </div>

              <div style={{marginBottom: 10}}>
                <Field
                  component={InputTextField}
                  validate={required}
                  label={'Password'}
                  placeholder={'password'}
                  type={'password'}
                  name={'loginPassword'}
                  required
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide} style={{ padding: '7px 12px' }} className="btn btn-default">Cancel</Button>
            <Button bsStyle="success" style={{ padding: '7px 12px' }} type="submit" disabled={loading}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    organizations: { loading, organizations },
    portals: { allPortalData, allIntegratedPortalData },
    dataSourceHandles: { dataSourceHandles }
  } = state;

  let handleIndex = 0;

  return {
    loading,
    organizationData : organizations,
    allPortals : allPortalData,
    allIntegratedPortals : allIntegratedPortalData,
    dataSourceHandles,
    initialValues: {
      organization: _.get(props.portalData, 'organization.id'),
      loginUsername:  _.get(props.portalData, 'loginUsername'),
      loginPassword: _.get(props.portalData, 'loginPassword'),
      loginUrl: _.get(props.portalData, 'loginUrl'),
      integratedPortal: _.get(props.portalData, 'integratedPortal.id'),
      displayLabel: _.get(props.portalData, 'displayLabel'),
      dataSourceHandles: _.get(props.portalData, `dataSourceHandle[${handleIndex}].id`),
      isAppliedToAllHandles: _.get(props.portalData, 'isAppliedToAllHandles'),
    }
  };
}

EditPortalModal = reduxForm({
  form: 'EditPortalForm',
  enableReinitialize: true,
})(EditPortalModal);
EditPortalModal = connect(mapStateToProps)(EditPortalModal);
EditPortalModal = withStyles(stylesPortal)(EditPortalModal);

export {EditPortalModal}
