import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import {VisibilityButton} from "./VisibilityButton";
import {MultiSelectContextMenu} from "../../components/common";
import {OrganizationForm} from "./OrganizationForm";
import {organizationActions} from "_actions";
import {connect} from "react-redux";


/**
 * @author Chris Okebata
 */

const styles = {
    label: {
        marginTop: '4px',
        fontSize: '14px'
    },

    bodyTextHolder: {
        display: "flex",
        alignItem: "center"
    },
};


class OrganizationModal extends Component {

    state = {
        messageNotification: false,
        qualityScore: false,
        selectedVisibility: "",
        languages: ["en"],
        shopifyRevenueTracking: false,
        gorgiasRevenueTracking: false,
        closeTicketsOnSource: false,
        hideInMobile: false,
        teamLead: [],
        csm: [],
        weeklyMetricsEmail: false,
        escalationsViaEmail: false,
        messageValidityDurationInDays: 0
    };

    componentDidMount() {
        const { organizationParam } = this.props;
        this.props.dispatch(organizationActions.getTeamLeads(organizationParam?.id));
        this.props.dispatch(organizationActions.getCSMs(organizationParam?.id));

        if(organizationParam) {
            this.setState({
                messageNotification: organizationParam.notifications,
                qualityScore: organizationParam.qualityScore,
                selectedVisibility: organizationParam.visibility,
                languages: organizationParam.languages,
                shopifyRevenueTracking: organizationParam.shopifyRevenueTracking,
                gorgiasRevenueTracking: organizationParam.gorgiasRevenueTracking,
                closeTicketsOnSource: organizationParam.closeTicketsOnSource,
                teamLead: organizationParam.teamLead,
                csm: organizationParam.csm,
                weeklyMetricsEmail: organizationParam.isWeeklyMetricsEmailEnabled,
                escalationsViaEmail: organizationParam.escalationsViaEmail,
                hideInMobile: organizationParam.hideInMobile,
                messageValidityDurationInDays: organizationParam.messageValidityDurationInDays
            });
        }

    }

    handleVisibility = (data) => {
        this.setState({
            selectedVisibility: data
        });
    };

    handleChange = event => {
        event.stopPropagation();
        this.setState({
            [event.target.value]: event.target.checked
        });
    };

    getLanguageCodes = () => {
        const { languageList } = this.props.languages;
        let languageCodes = languageList.map(language => language.value);
        return languageCodes;
    };

    handleLanguageCheckboxChange = (values) => {
        this.setState({
            languages: values
        })
    };

    handleTeamLeadChange = (values) => {
        this.setState({
            teamLead: values
        })
    };
    handleCSMChange = (values) => {
        this.setState({
            csm: values
        })
    };
    handleOrgFormChanges = (e) => {
        if(e.hasOwnProperty('selectedTL')){
            this.handleTeamLeadChange(e);
        }
        else if(e.hasOwnProperty('selectedCsm')){
            this.handleCSMChange(e);
        }
    };

    triggerEscalationsViaEmail = () => {
        const { organizationParam } = this.props;
        this.props.dispatch(organizationActions.orgEscalationsViaEmail(organizationParam.id))
    }

    handleMessageValidityDurationChange = (event) => {
        this.setState({ messageValidityDurationInDays: event.target.value });
    }

    render () {

        const {classes, onSave, onHide, show, headerText, buttonText, organizationParam} = this.props;
        const {bodyTextHolder} = styles;

        let teamLeadList = [];
        let csmList = [];
        const teamLeadOptions = this.props.teamLead && this.props.teamLead.teamLeadList
        const csmOptions = this.props.csm && this.props.csm.csmList
        if (teamLeadOptions) {
            teamLeadList = teamLeadOptions.map((teamLead) => {
                return{
                    value: teamLead.userId,
                    label: teamLead.username,
                    selectedTL: "selectedTeamLead"
                }
            })
        }
        if (csmOptions) {
            csmList = csmOptions.map((csm) => {
                return {
                    value: csm.userId,
                    label: csm.username,
                    selectedCsm: "selectedCsm"
                }
            });
        }

        const payload = {
            visibility: this.state.selectedVisibility,
            messageNotification: this.state.messageNotification,
            qualityScore: this.state.qualityScore,
            languages: this.state.languages,
            shopifyRevenueTracking: this.state.shopifyRevenueTracking,
            gorgiasRevenueTracking: this.state.gorgiasRevenueTracking,
            closeTicketsOnSource: this.state.closeTicketsOnSource,
            teamLead: this.state.teamLead,
            csm: this.state.csm,
            weeklyMetricsEmail: this.state.weeklyMetricsEmail,
            escalationsViaEmail: this.state.escalationsViaEmail,
            hideInMobile: this.state.hideInMobile,
            messageValidityDurationInDays: this.state.messageValidityDurationInDays,
        };

        const disableButton = typeof this.state.selectedVisibility === 'undefined';

        const languageList = this.props.languages && this.props.languages.languageList;
        let languageCodes = [];
        if(languageList) {
            languageCodes = this.getLanguageCodes();
        }


        return (
            <Modal show={show} onHide={onHide} className="tms-modal-dialog">
                <Modal.Header closeButton>
                    <Modal.Title>{headerText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={bodyTextHolder}>
                        <div style={{lineHeight: "20px", fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Visibility</span>
                        </div>
                    </div>

                    <div style={{margin: '15px 0 25px 0' }}>
                        <VisibilityButton
                            text={'Closed'}
                            data={'CLOSED'}
                            selected={this.state.selectedVisibility}
                            setVisibility={this.handleVisibility}
                            organizationParam={organizationParam}
                        />

                        <VisibilityButton
                            text={'Featured'}
                            data={'FEATURED'}
                            selected={this.state.selectedVisibility}
                            setVisibility={this.handleVisibility}
                            organizationParam={organizationParam}
                        />

                        <VisibilityButton
                            text={'Private'}
                            data={'PRIVATE'}
                            selected={this.state.selectedVisibility}
                            setVisibility={this.handleVisibility}
                            organizationParam={organizationParam}
                        />

                        <VisibilityButton
                            text={'Public'}
                            data={'PUBLIC'}
                            selected={this.state.selectedVisibility}
                            setVisibility={this.handleVisibility}
                            organizationParam={organizationParam}
                        />
                    </div>

                    <div style={bodyTextHolder}>
                        <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Notifications</span>
                        </div>
                    </div>
                    <div style={{height: '10px', marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    onChange={(event)=>{this.handleChange(event)}}
                                    style={ this.state.messageNotification ?
                                        {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                                        {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
                                    value = 'messageNotification'
                                    checked={this.state.messageNotification}
                                />
                            }
                            label="Messages"
                        />
                    </div>
                    <div style={{height: '10px', marginTop: '25px', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    onChange={(event)=>{this.handleChange(event)}}
                                    style={ this.state.weeklyMetricsEmail ?
                                        {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                                        {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
                                    value = 'weeklyMetricsEmail'
                                    checked={this.state.weeklyMetricsEmail}
                                />
                            }
                            label="Weekly metrics email"
                        />
                    </div>

                    <div style={{...bodyTextHolder, marginTop: "30px" }}>
                        <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Score</span>
                        </div>
                    </div>
                    <div style={{height: '10px', marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    onChange={(event)=>{this.handleChange(event)}}
                                    style={ this.state.qualityScore ?
                                        {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                                        {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
                                    value = 'qualityScore'
                                    checked={this.state.qualityScore}
                                />
                            }
                            label="Quality score"
                        />
                    </div>

                    <div style={{...bodyTextHolder, marginTop: "30px" }}>
                        <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Shopify</span>
                        </div>
                    </div>
                    <div style={{height: '10px', marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    onChange={(event)=>{this.handleChange(event)}}
                                    style={ this.state.shopifyRevenueTracking ?
                                        {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                                        {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
                                    value = 'shopifyRevenueTracking'
                                    checked={this.state.shopifyRevenueTracking}
                                />
                            }
                            label="Shopify revenue tracking"
                        />
                    </div>

                    <div style={{...bodyTextHolder, marginTop: "30px" }}>
                        <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Gorgias</span>
                        </div>
                    </div>
                    <div style={{height: '10px', marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    onChange={(event)=>{this.handleChange(event)}}
                                    style={ this.state.gorgiasRevenueTracking ?
                                        {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                                        {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
                                    value = 'gorgiasRevenueTracking'
                                    checked={this.state.gorgiasRevenueTracking}
                                />
                            }
                            label="Gorgias revenue tracking"
                        />
                    </div>

                    <div style={{...bodyTextHolder, marginTop: "30px" }}>
                        <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Tickets</span>
                        </div>
                    </div>
                    <div style={{height: '10px', marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    onChange={(event)=>{this.handleChange(event)}}
                                    style={ this.state.closeTicketsOnSource ?
                                        {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                                        {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
                                    value = 'closeTicketsOnSource'
                                    checked={this.state.closeTicketsOnSource}
                                />
                            }
                            label="Close tickets on source"
                        />
                    </div>
                    <div style={{...bodyTextHolder, marginTop: "30px" }}>
                        <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Mobile app setting</span>
                        </div>
                    </div>
                    <div style={{height: '10px', marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    onChange={(event)=>{this.handleChange(event)}}
                                    style={ this.state.hideInMobile ?
                                        {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                                        {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
                                    value = 'hideInMobile'
                                    checked={this.state.hideInMobile}
                                />
                            }
                            label="Hide in mobile app"
                        />
                    </div>

                    <div style={{...bodyTextHolder, marginTop: "30px" }}>
                        <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Escalation setting</span>
                        </div>
                    </div>
                    <div style={{height: '10px', marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    onChange={event => this.handleChange(event)}
                                    style={ this.state.escalationsViaEmail ?
                                        {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                                        {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
                                    value = 'escalationsViaEmail'
                                    checked={this.state.escalationsViaEmail}
                                />
                            }
                            label="Escalations via email"
                        />
                    </div>

                    {
                        !_.isEmpty(organizationParam) &&
                        <div>
                            <div style={{...bodyTextHolder, margin: "30px 0 5px 0" }}>
                                <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                                    <span>Message setting</span>
                                </div>
                            </div>
                            <label htmlFor={'messageValidityDuration'} style={{fontWeight: 'normal'}}>
                                Validity duration in days:
                            </label> &nbsp;
                            <input
                                id={'messageValidityDurationInDays'}
                                type={'number'}
                                min={0}
                                value={this.state.messageValidityDurationInDays}
                                onChange={(event) => this.handleMessageValidityDurationChange(event)}
                            />
                        </div>
                    }

                    <div style={{...bodyTextHolder, marginTop: "30px" }}>
                        <div style={{lineHeight: 1, fontWeight: 'bold', fontSize: '15px'}}>
                            <span>Languages</span>
                        </div>
                    </div>

                    <div className="register-page-context-menu">
                        <MultiSelectContextMenu
                            name="language"
                            fieldName="language"
                            type="text"
                            displayName={'Languages'}
                            options={languageList}
                            checkedOptions={this.state.languages}
                            ids={languageCodes}
                            displayAllItemsCheckbox={false}
                            handleChange={this.handleLanguageCheckboxChange}
                            component={MultiSelectContextMenu}
                        />
                    </div>

                   <OrganizationForm
                       teamLeadOptions={teamLeadList}
                       csmOptions={csmList}
                       handleChange={(e)=>{this.handleOrgFormChanges(e)}}
                   />

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide} style={{ padding: '7px 12px' }} className="btn btn-default">Cancel</Button>
                    <Button bsStyle="success" style={{ padding: '7px 12px' }}
                            onClick={() => onSave(payload)}
                            disabled={disableButton}>
                        {buttonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const { organizations: {organizationDetails: {teamLead, csm}} } = state;
    return {
        teamLead,
        csm
    }
}
const connectedOrganizationModal = connect(mapStateToProps)(withStyles(styles)(OrganizationModal));
export { connectedOrganizationModal as OrganizationModal };