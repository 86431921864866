import React, {Fragment, useState} from "react";
import {Box} from "@mui/system";
import {reduxForm} from 'redux-form';
import {useDispatch, useSelector} from "react-redux";
import useGetTemplate from "../Utilities/hooks/useGetTemplate";
import _ from "lodash";
import {Grid} from "@mui/material";
import styles from "../Utilities/templateStyles";
import ActionButton from "../../../common/ActionButton";
import AnchorButton from "../../../common/AnchorButton";
import {buildUpdateTemplatePayload, validateTopic} from "../Utilities/CampaignUtils";
import {proactiveActions} from "_actions";
import CircularProgress from "@mui/material/CircularProgress";
import UpdateCriteriaForm from "./UpdateCriteriaForm";
import UpdateReplies from "./UpdateReplies";
import {disabledGenerateResponsesChannels} from "_constants";

function UpdateTemplateForm({ handleSubmit }) {
    const dispatch = useDispatch();
    const { templateObj, loading } = useGetTemplate();

    const handleUpdateTemplate = () => {
        if(criteriaValues.hasOwnProperty('topic') && !validateTopic(criteriaValues)) return;
        let payload = buildUpdateTemplatePayload(templateObj.id, criteriaValues, selectedReplies);
        payload = _.omitBy(payload, _.isEmpty);
        dispatch(proactiveActions.updateTemplate(payload));
    };

    const [isEdited, setIsEdited] = useState(false);
    const [criteriaValues, setCriteriaValues] = useState({});
    const [selectedReplies, setSelectedReplies] = useState([]);
    const proactive = useSelector(state => state.proactive);
    const loadingUpdateTemplate = proactive.loadingUpdateTemplate;

    const handleCriteria = (values) => {
        setIsEdited(true);
        setCriteriaValues(values);
    };

    const handleReplies = (value) => {
        setSelectedReplies(value);
    };

    const clearSelectedReplies = () => {
        setSelectedReplies([]);
    }

    if(loading && _.isEmpty(templateObj)){
        return (
            <Box m={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}>
                <CircularProgress size={25} sx={{ color: '#07857C', marginLeft: '10px' }}/>
            </Box>
        )
    }
    if(!loading && _.isEmpty(templateObj)){
        return (
            <Box m={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}>
                <Box sx={styles.desc}>No data found</Box>
            </Box>
        )
    }

    const isGenerateRepliesDisabled = !_.isEmpty(templateObj) && disabledGenerateResponsesChannels.includes(_.get(templateObj, 'channel'))

    return(
        <>
            <Box>
                <Grid container>
                    <UpdateCriteriaForm
                        template={templateObj}
                        handleCriteria={handleCriteria}
                        clearSelectedReplies={clearSelectedReplies}
                        generateRepliesDisabled={isGenerateRepliesDisabled}
                    />
                    <UpdateReplies
                        template={templateObj}
                        handleReplies={handleReplies}
                        selectedReplies={selectedReplies}
                        setIsEdited={setIsEdited}
                        generateRepliesDisabled={isGenerateRepliesDisabled}
                    />
                </Grid>
            </Box>
            <Fragment>
                <form name="form" onSubmit={handleSubmit(handleUpdateTemplate)} autoComplete="off">
                    <Box sx={styles.footer}>
                        <Box sx={{width: '100px', marginRight: '18px'}}>
                            <ActionButton
                                label={'Save'}
                                disabled={!isEdited || loadingUpdateTemplate}
                                withIcon={loadingUpdateTemplate}
                            />
                        </Box>
                        <Box sx={{width: '100px', height: '40px'}}>
                            <AnchorButton
                                label={'Cancel'}
                                link={'/company/template'}
                                buttonBordered={true}
                            />
                        </Box>
                    </Box>
                </form>
            </Fragment>
        </>
    )
}

const formConfig = {
    form: 'UpdateTemplateForm',
};

export default reduxForm(formConfig)(UpdateTemplateForm);