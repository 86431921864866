import React from "react";
import { Box } from '@mui/material';
import facebookIcon from 'components/images/logos/logo_facebook.svg';
import freshdeskIcon from 'components/images/logos/logo_freshdesk.svg';
import frontappIcon from 'components/images/logos/logo_frontapp.svg';
import gladlyIcon from 'components/images/logos/logo_gladly.svg';
import gorgiasIcon from 'components/images/logos/logo_gorgias.svg';
import instagramIcon from 'components/images/logos/logo_instagram.svg';
import helpscoutIcon from 'components/images/logos/logo_helpscout.svg';
import kustomerIcon from 'components/images/logos/logo_kustomer.svg';
import reamazeIcon from 'components/images/logos/logo_reamaze.png';
import shopifyIcon from 'components/images/logos/logo_shopify.svg';
import tiktokIcon from 'components/images/logos/logo_tiktok.svg';
import twitterIcon from "components/images/logos/logo_twitter.svg";
import whistleIcon from 'components/images/logos/logo_whistle.svg';
import zendeskIcon from 'components/images/logos/logo_zendesk.svg';


export default function ConnectedChannels({ msgSourceName }){
    const msgSrcName = msgSourceName?.name?.toLowerCase()

    return (
        <Box>
            { msgSrcName === "facebook" && <img className="tms-channel-icons" src={facebookIcon} title="facebook" alt="facebookIcon"/> }
            { msgSrcName === "freshdesk" && <img className="tms-channel-icons" src={freshdeskIcon} title="freshdesk" alt="freshdeskIcon"/> }
            { msgSrcName === "frontapp" && <img className="tms-channel-icons" src={frontappIcon} title="frontapp" alt="frontappIcon"/> }
            { msgSrcName === "gladly" && <img className="tms-channel-icons" src={gladlyIcon} title="gladly" alt="gladlyIcon"/> }
            { msgSrcName === "gorgias" && <img className="tms-channel-icons" src={gorgiasIcon} title="gorgias" alt="gorgiasIcon"/> }
            { msgSrcName === "helpscout" && <img className="tms-channel-icons" src={helpscoutIcon} title="helpscout" alt="helpscoutIcon"/> }
            { msgSrcName === "instagram" && <img className="tms-channel-icons" src={instagramIcon} title="instagram" alt="instagramIcon"/> }
            { msgSrcName === "kustomer" && <img className="tms-channel-icons" src={kustomerIcon} title="kustomer" alt="kustomerIcon"/> }
            { msgSrcName === "reamaze" && <img className="tms-channel-icons" src={reamazeIcon} title="reamaze" alt="reamazeIcon"/> }
            { msgSrcName === "shopify" && <img className="tms-channel-icons" src={shopifyIcon} title="shopify" alt="shopifyIcon"/> }
            { msgSrcName === "tiktok" && <img className="tms-channel-icons" src={tiktokIcon} title="tiktok" alt="tiktokIcon"/> }
            { msgSrcName === "twitter" && <img className="tms-channel-icons" src={twitterIcon} title="twitter" alt="twitterIcon"/> }
            { msgSrcName === "whistle" && <img className="tms-channel-icons" src={whistleIcon} title="whistle" alt="whistleIcon"/> }
            { msgSrcName === "zendesk" && <img className="tms-channel-icons" src={zendeskIcon} title="zendesk" alt="zendeskIcon"/> }
        </Box>
    )
}