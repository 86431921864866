import React from 'react';
import 'components/Vote/vote.css';

const LoadingPlaceholderBlock = (props) => {

  const {width, height, borderRadius, backgroundColor} = props;

  return(
    <div style={{overflow: 'hidden'}} >
      <div
        style={{
          width: `${width}`,
          height: `${height}`,
          borderRadius: `${borderRadius}`,
          backgroundColor: `${ backgroundColor || '#eae9e9'}`
        }}
        className='loading'/>
    </div>
  )
};

export default LoadingPlaceholderBlock