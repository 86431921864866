import React from 'react';
import { Field, reduxForm } from "redux-form";
import { Table, TableRow, TableBody, TableCell, TableHead } from '@material-ui/core';
import {userActions, organizationActions} from "_actions";
import connect from "react-redux/es/connect/connect";
import { Loading } from "components/common";
import { isEmpty } from "lodash";
import { DateRange } from 'components/common/formFields/DateRange'
import moment from "moment";
import {OrganizationDropDownSelect} from "components/Message/OrganizationDropDownSelect";
import {Button} from "react-bootstrap";


const dropdownStyles = { borderColor: "#c4c9c7", boxShadow: "none", color: "#5a5f5e", width: "100%" };
class AdminPerformance extends React.Component {
    state = {
        startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().add(2, 'd').format('YYYY-MM-DD HH:mm:ss'),
        organizations: []
    };

    componentDidMount() {
        const visibility = ['PUBLIC', 'PRIVATE', 'FEATURED'];
        this.props.dispatch(organizationActions.getActiveOrganizations({max: 1000, visibility}));
        this.props.dispatch(userActions.getAdminPerformance(this.state))
    }

    handleDatePickerUpdate = (e, data) => {
        this.setState({
            startDate : data.startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDate   : data.endDate.format('YYYY-MM-DD HH:mm:ss')
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.dispatch(userActions.getAdminPerformance(this.state))
    };

    handleOrganizationsSelect = (organizations) => {
        this.setState( { organizations});
    };

    getOrganizations = (organizations, returnValue) => {
        const orgList = [];
        organizations.forEach((organization) => {
            if (returnValue === 'ids') {
                orgList.push(`'${organization.id}'`);
            } else {
                const organizationData = {
                    value: `'${organization.id}'`,
                    name: organization.name,
                };
                orgList.push(organizationData);
            }
        });
        return orgList;
    };

    render() {
        const { loading, adminPerformance, orgList } = this.props;
        const  { startDate, endDate, organizations } = this.state;
        let finalOrgList = orgList?.organizations
        let companies, companiesIds;
        if (finalOrgList) {
            companies = this.getOrganizations(finalOrgList);
            companiesIds = this.getOrganizations(finalOrgList, 'ids');
        }
        const organizationOptions = companies && companies.length > 0 ? companies.map(company => {
            return {label: company.name, value: company.value}
        }) : [];
        const companiesInput = {onChange: this.handleOrganizationsSelect, value: companies};

        return (
            <div className='container-pane'>
                <div inline className="side-bar-pane shadow-border messages-page-filter-form">
                    <span className="font-14 color-grey-dark">Date</span>
                    <label className="calendar-today tms-inbox-calendar">
                        <Field
                            submittedStartDate={startDate}
                            submittedEndDate={endDate}
                            id="datePicker"
                            name="datePicker"
                            defaultDateRange="today"
                            onEvent={this.handleDatePickerUpdate}
                            values={startDate}
                            component={DateRange}
                        />
                    </label>
                    <div style={{marginTop: "20px"}}>
                        <span className="font-14 color-grey-dark">Organizations</span>
                        <div className="inbox-context-menu" style={{display: "block"}}>
                            <Field
                                name="organizations"
                                fieldName="organizations"
                                type="text"
                                input={companiesInput}
                                options={organizationOptions}
                                checkedOrganizations={organizations}
                                ids={companiesIds}
                                style={dropdownStyles}
                                caretPosition="left"
                                component={OrganizationDropDownSelect}
                                handleChange={this.handleOrganizationsSelect}
                                placeHolder="Select Organization"
                            />
                        </div>
                    </div>
                    <div style={{marginTop: "30px"}}>
                        <Button
                            bsStyle="success"
                            onClick={this.handleSubmit}
                            disabled={loading}
                            style={{width: "100%"}}> {loading ? "Loading..." : "Apply"}
                        </Button>
                    </div>
                </div>
                <div className="container-right messages-container-right bg-white">
                    {loading &&
                    <div className="font-20-bold tms-align-text-center padding-20-0 expert-page-table background-white">
                        <Loading/>
                    </div>
                    }

                    {!loading && isEmpty(adminPerformance) &&
                    <div className="font-20-bold tms-align-text-center padding-20-0 expert-page-table background-white">
                        No Admin Paid Messages Found!
                    </div>}

                    {!loading && !isEmpty(adminPerformance) &&
                    <Table className="expert-page-table background-white">
                        <TableHead>
                            <TableRow>
                                <TableCell>Admin</TableCell>
                                <TableCell align="center">Total Messages</TableCell>
                                <TableCell align="center">Inbox/Vote</TableCell>
                                <TableCell align="center">Escalate</TableCell>
                                <TableCell align="center">No Response</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {adminPerformance && adminPerformance.map( admin => {
                                return (
                                    <TableRow key={admin.id}>
                                        <TableCell>{admin.firstName + " " + admin.lastName}</TableCell>
                                        <TableCell align="center">{admin.totalMessages}</TableCell>
                                        <TableCell align="center">{admin.totalByVote}</TableCell>
                                        <TableCell align="center">{admin.totalByEscalate}</TableCell>
                                        <TableCell align="center">{admin.totalByNoResponse}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { users: { loading, adminPerformance}, organizations } = state;
    return { loading, adminPerformance, orgList: organizations.activeOrganizations };
}

AdminPerformance = connect(mapStateToProps)(AdminPerformance);
const adminPerformance = reduxForm({
    form: 'AdminPerformance'
})(AdminPerformance);

export { adminPerformance as AdminPerformance }