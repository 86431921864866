import { employmentService } from "_services";
import { employmentConstants, alertMessages } from "_constants";
import {filterObjectValue, getArrayFromObjectArray, history} from "_helpers";
import {SnackBar} from "../components/common";
import _ from "lodash";
import {scheduleActions} from "./schedule.actions";
import {Auth} from "../components/Authorization";
import {alertActions} from "./alert.actions";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 03/10/2018 : 12:52 PM
 */

function getAll(params) {
    return dispatch => {
        dispatch(request());

        employmentService.getAll(params)
            .then(
                employments => dispatch(success(employments)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: employmentConstants.GET_ALL_REQUEST }
    }

    function success(employments) {
        return { type: employmentConstants.GET_ALL_SUCCESS, employments }
    }

    function failure(error) {
        return { type: employmentConstants.GET_ALL_FAILURE, error }
    }
}

function getUserEmployments(userId, params, reqOrigin = null) {
    return dispatch => {
        dispatch(request());

        employmentService.getUserEmployments(userId, params)
            .then( (employments) => {
                dispatch(success(employments));

                let _employments = _.get(employments, 'employments');
                let hiredOrgs = filterObjectValue(_employments || [], 'status', 'HIRED');
                let orgIdArr = [...new Set(getArrayFromObjectArray(hiredOrgs, 'organization.id'))];
                orgIdArr && orgIdArr.length > 0 && dispatch(scheduleActions.getScheduledOrganizations({
                    organizationIds: orgIdArr
                }, reqOrigin));
              },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: employmentConstants.GET_USER_EMPLOYMENTS_REQUEST }
    }

    function success(employments) {
        return { type: employmentConstants.GET_USER_EMPLOYMENTS_SUCCESS, employments }
    }

    function failure(error) {
        return { type: employmentConstants.GET_USER_EMPLOYMENTS_FAILURE, error }
    }
}

function getOrgEmployments(params) {
    return dispatch => {
        dispatch(request());

        employmentService.getOrgEmployments(params)
            .then(
                employments => dispatch(success(employments)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: employmentConstants.GET_ORG_EMPLOYMENTS_REQUEST }
    }

    function success(employments) {
        return { type: employmentConstants.GET_ORG_EMPLOYMENTS_SUCCESS, employments }
    }

    function failure(error) {
        return { type: employmentConstants.GET_ORG_EMPLOYMENTS_FAILURE, error }
    }
}


/**
 * Dispatches actions involves in a employment
 * submission, processing and potential failure.
 *
 * @param employment
 * @returns {function(*)}
 */
function updateEmployment(employment) {
    return dispatch => {
        dispatch(request(employment));

        employmentService.updateEmployment(employment)
            .then(
                employment => {
                    dispatch(success(employment));
                    SnackBar.showMessage(alertMessages.SAVE_EMPLOYMENT_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(employment) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_REQUEST, employment }
    }

    function success(employment) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_SUCCESS, employment }
    }

    function failure(error) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_FAILURE, error }
    }
}

/**
 * Dispatches actions involves adding an expert to a team.
 * @param employment
 * @returns {function(*)}
 */
function addEmployment(employment) {
    return dispatch => {
        dispatch(request(employment));

        employmentService.updateEmployment(employment)
            .then(
                employment => {
                    dispatch(success(employment));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.SAVE_EMPLOYMENT_SUCCESS);
                },
            ).catch(
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(employment) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_REQUEST, employment }
    }

    function success(employment) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_SUCCESS, employment }
    }

    function failure(error) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_FAILURE, error }
    }
}

/**
 * Dispatches actions involves removing an expert from a team.
 * @param employment
 * @returns {function(*)}
 */
function removeEmployment(employment) {
    return dispatch => {
        dispatch(request(employment));

        employmentService.removeEmployment(employment)
            .then(
                removeEmployment => {
                    dispatch(success(removeEmployment));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.REMOVE_EMPLOYMENT_SUCCESS);
                },
            ).catch(
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: employmentConstants.REMOVE_EMPLOYMENT_REQUEST }
    }

    function success(removeEmployment) {
        return { type: employmentConstants.REMOVE_EMPLOYMENT_SUCCESS, payload: removeEmployment }
    }

    function failure(error) {
        return { type: employmentConstants.REMOVE_EMPLOYMENT_FAILURE, payload: error }
    }
}

/**
 * Dispatches actions involves in a employment
 * submission, processing and potential failure.
 *
 * @param employment
 * @returns {function(*)}
 */
function createEmployment(employment) {
    return dispatch => {
        dispatch(request(employment));

        employmentService.createEmployment(employment)
            .then(
                employment => {
                    dispatch(success(employment));
                    history.push('/dashboard');
                    SnackBar.showMessage(alertMessages.CREATE_EMPLOYMENT_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(employment) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_REQUEST, employment }
    }

    function success(employment) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_SUCCESS, employment }
    }

    function failure(error) {
        return { type: employmentConstants.SAVE_EMPLOYMENT_FAILURE, error }
    }
}

/**
 * Dispatches the 'getEmployment' action.
 * @param id
 * @returns {function(*)}
 */
function getEmployment(id) {
    return dispatch => {
        dispatch(request(id));

        employmentService.getEmployment(id)
            .then(
                employment => dispatch(success(employment)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(id) {
        return { type: employmentConstants.GET_DETAILS_REQUEST, payload: id }
    }

    function success(employment) {
        return { type: employmentConstants.GET_DETAILS_SUCCESS, payload: employment }
    }

    function failure(error) {
        return { type: employmentConstants.GET_DETAILS_FAILURE, payload: error }
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        employmentService.delete(id)
            .then(
                employment => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) {
        return { type: employmentConstants.DELETE_REQUEST, id }
    }

    function success(id) {
        return { type: employmentConstants.DELETE_SUCCESS, id }
    }

    function failure(id, error) {
        return { type: employmentConstants.DELETE_FAILURE, id, error }
    }
}

const getOrganizationScore = (userId) => {
    const request = () => ({
        type: employmentConstants.GET_ORG_SCORE_REQUEST
    });

    const success = (response) => ({
        type: employmentConstants.GET_ORG_SCORE_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: employmentConstants.GET_ORG_SCORE_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        employmentService.getOrganizationScore(userId)
          .then(response => {
              dispatch(success(response.data));

          }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

const getOrgWithRecentMessages = (userId, handleIds) => {
    const request = () => ({
        type: employmentConstants.GET_ORG_WITH_MESSAGE_REQUEST
    });

    const success = (response) => ({
        type: employmentConstants.GET_ORG_WITH_MESSAGE_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: employmentConstants.GET_ORG_WITH_MESSAGE_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        employmentService.getOrgWithRecentMessages(userId, handleIds)
          .then(response => {
              dispatch(success(response.data));

          }).catch((error) => {
          if(_.get(error, 'response.status') === 401){
            Auth.processLogout();
            return;
          }

          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

function getEmploymentDetails(id) {
    return dispatch => {
        dispatch(request(id));

        employmentService.getEmploymentDetails(id)
            .then(
                employment => dispatch(success(employment)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(id) {
        return { type: employmentConstants.GET_DETAILS_REQUEST, payload: id }
    }

    function success(employment) {
        return { type: employmentConstants.GET_DETAILS_SUCCESS, payload: employment }
    }

    function failure(error) {
        return { type: employmentConstants.GET_DETAILS_FAILURE, payload: error }
    }
}

function getOrgTeamLead(orgId) {
    return dispatch => {
        dispatch(request(orgId));

        employmentService.getOrgTeamLead(orgId)
            .then(
                orgTeamLead => {
                    dispatch(success(orgTeamLead))
                }, error => {
                    dispatch(failure(error));
                    error = error || {}
                    SnackBar.showMessage(error.message || alertMessages.GENERIC_ERROR)
                }
            );

        function request() {
            return { type: employmentConstants.GET_ORG_TEAM_LEAD_REQUEST }
        }

        function success(orgTeamLead) {
            return { type: employmentConstants.GET_ORG_TEAM_LEAD_SUCCESS, payload:orgTeamLead }
        }

        function failure(error) {
            return { type: employmentConstants.GET_ORG_TEAM_LEAD_FAILURE, payload:error }
        }
    }
}

function getOrgCsm(orgId) {
    return dispatch => {
        dispatch(request(orgId));

        employmentService.getOrgCsm(orgId)
            .then(
                orgCsm => {
                    dispatch(success(orgCsm))
                }, error => {
                    dispatch(failure(error));
                    error = error || {}
                    SnackBar.showMessage(error.message || alertMessages.GENERIC_ERROR)
                }
            );

        function request() {
            return { type: employmentConstants.GET_ORG_CSM_REQUEST }
        }

        function success(orgCsm) {
            return { type: employmentConstants.GET_ORG_CSM_SUCCESS, payload:orgCsm }
        }

        function failure(error) {
            return { type: employmentConstants.GET_ORG_CSM_FAILURE, payload:error }
        }
    }
}

function getEmploymentMetrics(params) {
    return dispatch => {
        dispatch(request());

        employmentService.getEmploymentMetrics(params)
            .then(
                employments => dispatch(success(employments)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: employmentConstants.GET_EMPLOYMENT_METRICS_REQUEST }
    }

    function success(employmentMetrics) {
        return { type: employmentConstants.GET_EMPLOYMENT_METRICS_SUCCESS, payload: employmentMetrics }
    }

    function failure(error) {
        return { type: employmentConstants.GET_EMPLOYMENT_METRICS_FAILURE, error }
    }
}

function getConversationStats(id) {
    function request(id) {
        return { type: employmentConstants.GET_CONVERSATION_STATS_REQUEST, payload: id }
    }

    function success(employment) {
        return { type: employmentConstants.GET_CONVERSATION_STATS_SUCCESS, payload: employment }
    }

    function failure(error) {
        return { type: employmentConstants.GET_CONVERSATION_STATS_FAILURE, payload: error }
    }

    return dispatch => {
        dispatch(request(id));

        employmentService.getConversationStats(id)
            .then(
                employment => dispatch(success(employment)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };
}

export const employmentActions = {
    getUserEmployments,
    getOrgEmployments,
    createEmployment,
    updateEmployment,
    addEmployment,
    removeEmployment,
    getAll,
    getEmployment,
    delete: _delete,
    getOrganizationScore,
    getOrgWithRecentMessages,
    getEmploymentDetails,
    getOrgTeamLead,
    getOrgCsm,
    getEmploymentMetrics,
    getConversationStats
};
