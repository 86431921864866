import {Box, FormControl, Grid, TextField, Tooltip} from "@mui/material";
import Icon from '@mui/material/Icon';
import {useState} from "react";
import {useDispatch} from "react-redux";
import BasicButton from "../../../../common/BasicButton";
import {accessibilityMode} from "../organizationSettingsConstants";
import {organizationSettingsActions} from "_actions";
import commonStyles from "../commonStyles";
import useKustomerMsgDataSourceUpdateInfo from "../../hooks/useKustomerMsgDataSourceUpdateInfo";
import {kustomerMsgDataSourceActions} from "../../../../../../_actions/kustomer.msgdatasource.actions";

export default function KustomerSection({handle, mode, label}) {
    const dispatch = useDispatch();
    const {lastUpdatedHandleId, error} = useKustomerMsgDataSourceUpdateInfo();
    const [emailAppProvider, setEmailAppProvider] = useState(null);
    const [emailAppProviderTouched, setEmailAppProviderTouched] = useState(false);

    const updateKustomerSettings = () => {
        dispatch(kustomerMsgDataSourceActions.update(handle.msgDataSourceId, buildKustomerSettingsPayload(), handle.id)).then(() => {
            setEmailAppProviderTouched(false);
            dispatch(organizationSettingsActions.refreshDataSourceHandleInfo(handle.organizationId));
        });
    };

    const buildKustomerSettingsPayload = () => {
        return {
            emailAppProvider: emailAppProvider ? emailAppProvider : null
        }
    };

    const onEmailAppProviderUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAppProvider(event.target.value);
        setEmailAppProviderTouched(true);
    };

    const getEmailAppProviderErrorMessage = () => {
        if (lastUpdatedHandleId !== handle.id || emailAppProviderTouched) {
            return null;
        } else if (error?.errorList?.flatMap((error) => error.errorCodes === 'INVALID_EMAIL_APP_PROVIDER')) {
            return 'The provided email app is invalid.';
        }
        return null;
    };

    const isEditMode = () => {
        return mode === accessibilityMode.EDIT;
    };

    const emailAppProviderErrorMessage = getEmailAppProviderErrorMessage();

    return (
        <Box>
            <Box component={'h4'} sx={commonStyles.labelBold}>
                Kustomer
                {
                    isEditMode() && (
                        <Tooltip
                            title={'These settings are channel-wide, so other data sources in the channel will be updated too.'}>
                            <Icon sx={styles.infoIcon} color={'info'}>info</Icon>
                        </Tooltip>
                    )
                }
            </Box>
            <Grid container>
                <Grid item xs={4}>
                    <Box>
                        <Box component={'h4'} sx={commonStyles.labelMedium}>Email App Provider</Box>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                error={emailAppProviderErrorMessage !== null}
                                helperText={emailAppProviderErrorMessage}
                                value={emailAppProvider !== null ? emailAppProvider : handle.kustomer.emailAppProvider}
                                onChange={isEditMode() && onEmailAppProviderUpdate}
                                disabled={!isEditMode()} />
                        </FormControl>
                        {
                            isEditMode() && (
                                <FormControl sx={styles.saveButton}>
                                    <BasicButton
                                        label={'Save Kustomer settings'}
                                        action={isEditMode() && updateKustomerSettings}/>
                                </FormControl>
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

const styles = {
    infoIcon: {
        ml: 0.5,
        verticalAlign: 'top',
        fontSize: '14px'
    },
    jsonInput: {
        fontFamily: 'Monospace'
    },
    saveButton: {
        mt: 2
    }
};
