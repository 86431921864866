import {organizationConstants, alertMessages, requestOrigin, oauthConstants} from '_constants';
import {organizationService, slackOauthService} from '_services';
import {alertActions,authActions, roleActions, scheduleActions} from '_actions';
import {SnackBar} from "../components/common";
import _ from "lodash";
import {getArrayFromObjectArray} from "../_helpers";


function getAll(params) {
    function request() {
        return { type: organizationConstants.GET_ALL_REQUEST };
    }

    function success(organizations) {
        return { type: organizationConstants.GET_ALL_SUCCESS, payload: organizations };
    }

    function failure(error) {
        return { type: organizationConstants.GET_ALL_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getAll(params)
            .then(
                organizations => {
                    dispatch(success(organizations));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

/**
 * Gets all the paid messages for each organization together with
 * the number from experts for a specified daterange
 * @param Date
 * @returns {function(*)}
 */
function getOrganizationsPaidMessages(params) {
    function request() {
        return { type: organizationConstants.GET_ORG_PAID_MESSAGES_REQUEST };
    }

    function success(organizations) {
        return { type: organizationConstants.GET_ORG_PAID_MESSAGES_SUCCESS, payload: organizations };
    }

    function failure(error) {
        return { type: organizationConstants.GET_ORG_PAID_MESSAGES_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrganizationsPaidMessages(params)
            .then(
                organizations => dispatch(success(organizations)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

/**
 * Gets all the paid tickets for each organization together with
 * the number from experts for a specified daterange
 * @param Date
 * @returns {function(*)}
 */
function getOrganizationsPaidTickets(date) {
    function request() {
        return { type: organizationConstants.GET_ORG_PAID_TICKETS_REQUEST };
    }

    function success(organizations) {
        return { type: organizationConstants.GET_ORG_PAID_TICKETS_SUCCESS, payload: organizations };
    }

    function failure(error) {
        return { type: organizationConstants.GET_ORG_PAID_TICKETS_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrganizationsPaidTickets(date)
            .then(
                organizations => dispatch(success(organizations)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

/**
 * Dispatches actions involves in a organization
 * submission, processing and potential failure.
 *
 * @param organization
 * @returns {function(*)}
 */
function updateOrganization(organization) {
    function request() {
        return { type: organizationConstants.UPDATE_ORGANIZATION_REQUEST };
    }

    function success(organization) {
        return { type: organizationConstants.UPDATE_ORGANIZATION_SUCCESS, organization };
    }

    function failure(error) {
        return { type: organizationConstants.UPDATE_ORGANIZATION_FAILURE, error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.updateOrganization(organization)
            .then(
                (organization) => {
                    dispatch(success(organization));
                    SnackBar.showMessage(alertMessages.UPDATE_ORGANIZATION_SUCCESS);
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

/**
 *
 * @param organizations
 * @returns {function(*)}
 */
function updateMultipleOrganizations(organizations) {
    function request() {
        return { type: organizationConstants.UPDATE_ORGANIZATION_REQUEST };
    }

    function success(organization) {
        return { type: organizationConstants.UPDATE_ORGANIZATION_SUCCESS, organization };
    }

    function failure(error) {
        return { type: organizationConstants.UPDATE_ORGANIZATION_FAILURE, error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.updateMultipleOrganizations(organizations)
            .then(
                (response) => {
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(response.message || alertMessages.UPDATE_ORGANIZATION_SUCCESS);
                },
                (error) => {
                    dispatch(failure(error));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function getOrganization(id) {
    function request(id) {
        return { type: organizationConstants.GET_DETAILS_REQUEST, payload: id };
    }

    function success(organization) {
        return { type: organizationConstants.GET_DETAILS_SUCCESS, payload: organization };
    }

    function failure(error) {
        return { type: organizationConstants.GET_DETAILS_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrganization(id)
            .then(
                organization => dispatch(success(organization)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function getOrgUsers(orgId, params) {
    function request() {
        return { type: organizationConstants.GET_ORGANIZATION_USERS_REQUEST };
    }

    function success(orgUsers) {
        return { type: organizationConstants.GET_ORGANIZATION_USERS_SUCCESS, payload: orgUsers };
    }

    function failure(error) {
        return { type: organizationConstants.GET_ORGANIZATION_USERS_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrgUsers(orgId, params)
            .then(
                (usersList) => {
                    dispatch(success(usersList));
                    dispatch(roleActions.setCurrentRole(usersList.role));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function getAgentEmployers(agentId) {
    function request() {
        return { type: organizationConstants.GET_AGENT_EMPLOYERS_REQUEST };
    }

    function success(organizations) {
        return { type: organizationConstants.GET_AGENT_EMPLOYERS_SUCCESS, payload: organizations };
    }

    function failure(error) {
        return { type: organizationConstants.GET_AGENT_EMPLOYERS_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getAgentEmployers(agentId)
            .then(
                organizations => dispatch(success(organizations)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function getSummaryStats(organizationId, params) {
    function request(organizationId) {
        return { type: organizationConstants.GET_SUMMARY_STATS_DATA_REQUEST, payload: organizationId };
    }

    function success(data) {
        return { type: organizationConstants.GET_SUMMARY_STATS_DATA_SUCCESS, payload: data };
    }

    function failure(error) {
        return { type: organizationConstants.GET_SUMMARY_STATS_DATA_FAILURE, payload: error };
    }
    return (dispatch) => {
        dispatch(request());

        organizationService.getSummaryStats(organizationId, params)
            .then(
                data => dispatch(success(data)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function getPositiveScreenshots(organizationId, params) {
    function request(organizationId) {
        return { type: organizationConstants.GET_POSITIVE_SCREENSHOTS_REQUEST, payload: organizationId };
    }

    function success(data) {
        return { type: organizationConstants.GET_POSITIVE_SCREENSHOTS_SUCCESS, payload: data };
    }

    function failure(error) {
        return { type: organizationConstants.GET_POSITIVE_SCREENSHOTS_FAILURE, payload: error };
    }
    return (dispatch) => {
        dispatch(request());

        organizationService.getPositiveScreenshots(organizationId, params)
            .then(
                data => dispatch(success(data)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function exportStats(organizationId, params) {
    return (dispatch) => {
        dispatch({ type: organizationConstants.EXPORT_ORGANIZATION_STATS });

        organizationService.exportStats(organizationId, params)
            .then(
                () => dispatch({ type: organizationConstants.EXPORT_ORGANIZATION_STATS_SUCCESS }),
                (error) => {
                    dispatch({ type: organizationConstants.EXPORT_ORGANIZATION_STATS_FAILURE, payload: error });
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

/**
 * Gets all organizations paid messages gotten by vote and the paid messages with atleast 1 perfect response
 * @returns {function(*)}
 * @param date
 */
function getPerfectResponsesSummary(date) {
    function request() {
        return { type: organizationConstants.GET_ORG_PERFECT_RESPONSES_SUMMARY_REQUEST };
    }

    function success(orgPerfectResponses) {
        return { type: organizationConstants.GET_ORG_PERFECT_RESPONSES_SUMMARY_SUCCESS, payload: orgPerfectResponses };
    }

    function failure(error) {
        return { type: organizationConstants.GET_ORG_PERFECT_RESPONSES_SUMMARY_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getPerfectResponsesSummary(date)
            .then(
                orgPerfectResponses => dispatch(success(orgPerfectResponses)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

/**
 * Get all organizations profitability
 * @returns {function(*)}
 * @param date
 */
function getOrganizationsProfitability(date) {
    function request() {
        return { type: organizationConstants.GET_ORGANIZATION_PROFITABILITY_REQUEST}
    }
    function success(organizations) {
        return { type: organizationConstants.GET_ORGANIZATION_PROFITABILITY_SUCCESS, payload: organizations }
    }
    function failure(error) {
        return { type: organizationConstants.GET_ORGANIZATION_PROFITABILITY_FAILURE, payload: error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrganizationsProfitability(date)
            .then(
                organizations => dispatch(success(organizations)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function saveOrgVerticals() {
    function request() {
        return { type: organizationConstants.SAVE_ORG_VERTICALS_REQUEST}
    }
    function success() {
        return { type: organizationConstants.SAVE_ORG_VERTICALS_SUCCESS }
    }
    function failure(error) {
        return { type: organizationConstants.SAVE_ORG_VERTICALS_FAILURE, payload: error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.saveOrgVerticals()
            .then(
                response => {
                    dispatch(success(response))
                    SnackBar.showMessage(response ? response.message : '')
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function getTeamLeads(orgId) {
    function request() {
        return { type: organizationConstants.GET_TEAM_LEADS_REQUEST }
    }

    function success(teamLeads) {
        return { type: organizationConstants.GET_TEAM_LEADS_SUCCESS, payload: teamLeads }
    }

    function failure(error) {
        return { type: organizationConstants.GET_TEAM_LEADS_FAILURE, payload: error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getTeamLeads(orgId)
            .then(
                teamLeads => dispatch(success(teamLeads)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}
function getCSMs(orgId) {
    function request() {
        return { type: organizationConstants.GET_CSM_REQUEST }
    }

    function success(csms) {
        return { type: organizationConstants.GET_CSM_SUCCESS, payload: csms }
    }

    function failure(error) {
        return { type: organizationConstants.GET_CSM_FAILURE, payload: error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getCSMs(orgId)
            .then(
                csms => dispatch(success(csms)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            )
    }
}

function getOrgSlackStatus(id){
    function request(){
        return {type: organizationConstants.GET_SLACK_STATUS_REQUEST }
    }
    function success(response) {
        return {type: organizationConstants.GET_SLACK_STATUS_SUCCESS, payload: response };
    }

    function failure(error) {
        return {type: organizationConstants.GET_SLACK_STATUS_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrganizationsSlackStatus(id)
            .then(
                organization => dispatch(success(organization)),
                (error) => {
                    console.log("ERROR", error)
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function orgEscalationsViaEmail(orgId) {
    function request() {
        return { type: organizationConstants.TRIGGER_ESCALATIONS_VIA_EMAIL_REQUEST }
    }

    function success() {
        return { type: organizationConstants.TRIGGER_ESCALATIONS_VIA_EMAIL_SUCCESS  }
    }

    function failure(error) {
        return { type: organizationConstants.TRIGGER_ESCALATIONS_VIA_EMAIL_FAILURE, payload: error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.orgEscalationsViaEmail(orgId)
            .then(
                response => {
                    dispatch(success(response))
                    SnackBar.showMessage(response ? response.message : '')
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            )
    }
}

function getCompanyAdmins(orgId) {
    function request() {
        return { type: organizationConstants.GET_COMPANY_ADMIN_REQUEST }
    }

    function success(companyAdmins) {
        return { type: organizationConstants.GET_COMPANY_ADMIN_SUCCESS, payload: companyAdmins }
    }

    function failure(error) {
        return { type: organizationConstants.GET_COMPANY_ADMIN_FAILURE, payload: error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getCompanyAdmins(orgId)
            .then(
                companyAdmins => dispatch(success(companyAdmins)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            )
    }
}

function getOrganizationsCsat(params) {
    function request() {
        return { type: organizationConstants.GET_ORGANIZATIONS_CSAT_REQUEST }
    }

    function success(organizations) {
        return { type: organizationConstants.GET_ORGANIZATIONS_CSAT_SUCCESS, payload: organizations }
    }

    function failure(error) {
        return { type: organizationConstants.GET_ORGANIZATIONS_CSAT_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrganizationsCsat(params)
            .then(
                organizations => dispatch(success(organizations)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            )
    }
}

function getOrganizationsActiveExperts(params) {
    function request() {
        return { type: organizationConstants.GET_ORGANIZATIONS_ACTIVE_EXPERTS_REQUEST }
    }

    function success(organizations) {
        return { type: organizationConstants.GET_ORGANIZATIONS_ACTIVE_EXPERTS_SUCCESS, payload: organizations }
    }

    function failure(error) {
        return { type: organizationConstants.GET_ORGANIZATIONS_ACTIVE_EXPERTS_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrganizationsActiveExperts(params)
            .then(
                organizations => dispatch(success(organizations)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            )
    }
}

function getOrganizationsReviewsStat(params) {
    function request() {
        return { type: organizationConstants.GET_ORGANIZATIONS_REVIEWS_STATS_REQUEST }
    }

    function success(organizations) {
        return { type: organizationConstants.GET_ORGANIZATIONS_REVIEWS_STATS_SUCCESS, payload: organizations }
    }

    function failure(error) {
        return { type: organizationConstants.GET_ORGANIZATIONS_REVIEWS_STATS_FAILURE, error }
    }

    return (dispatch) => {
        dispatch(request());

        organizationService.getOrganizationsReviewsStats(params)
            .then(
                organizations => dispatch(success(organizations)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            )
    }
}

function deleteOrganizationLogo(orgId) {
    function request() {
        return { type: organizationConstants.DELETE_ORGANIZATION_LOGO_REQUEST }
    }

    function success(organizations) {
        return { type: organizationConstants.DELETE_ORGANIZATION_LOGO_SUCCESS, payload: organizations }
    }

    function failure(error) {
        return { type: organizationConstants.DELETE_ORGANIZATION_LOGO_FAILURE, error }
    }

    return dispatch => {
        dispatch(request());

        organizationService.deleteOrganizationLogo(orgId)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(authActions.updateAuthOrgPhoto(user));
                    SnackBar.showMessage(alertMessages.DELETE_USER_PHOTO_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.DELETE_USER_PHOTO_FAILURE);
                }
            );
    };
}

function getActiveOrganizations(params) {
    function request() {
        return { type: organizationConstants.GET_ACTIVE_ORGANIZATIONS_REQUEST };
    }
    function success(organizations) {
        return { type: organizationConstants.GET_ACTIVE_ORGANIZATIONS_SUCCESS, payload: organizations };
    }
    function failure(error) {
        return { type: organizationConstants.GET_ACTIVE_ORGANIZATIONS_FAILURE, payload: error };
    }
    return (dispatch) => {
        dispatch(request());
        organizationService.getActiveOrganizations(params)
            .then(
                organizations => {
                    dispatch(success(organizations));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

export const organizationActions = {
    getAll,
    getOrganizationsPaidMessages,
    getOrganizationsPaidTickets,
    updateOrganization,
    updateMultipleOrganizations,
    getOrganization,
    getPerfectResponsesSummary,
    getOrgUsers,
    getAgentEmployers,
    getSummaryStats,
    exportStats,
    getOrganizationsProfitability,
    getPositiveScreenshots,
    saveOrgVerticals,
    getTeamLeads,
    getCSMs,
    getOrgSlackStatus,
    orgEscalationsViaEmail,
    getCompanyAdmins,
    getOrganizationsCsat,
    getOrganizationsActiveExperts,
    getOrganizationsReviewsStat,
    deleteOrganizationLogo,
    getActiveOrganizations
};
