import {Fragment} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import {supportedSourcesForCloseTicket} from "_constants";
import ActionButton from "../../../../../../common/ActionButton";
import CloseTicketCheckbox from "../../../../../../common/CloseTicketCheckbox";
import useViewNextMessage from "../../../../Utilities/hooks/useViewNextMessage";

export default function PerfectResponseModalContainer({handleClose, handleSubmit, handleCloseTicket}) {
    const { message } = useViewNextMessage();
    const sourceName = _.toString(_.get(message, 'sourceName')).toLowerCase();
    const isCloseTicketSupported = supportedSourcesForCloseTicket.includes(sourceName);

    return(
        <Fragment>
            <Box sx={{marginTop: '-8px'}}>
                <Box sx={styles.heading}>
                    The selected response option will be submitted
                </Box>

                <Box sx={styles.actionsContainer}>
                    <Box sx={{pt: '1px'}}>
                        {
                            isCloseTicketSupported &&
                            <CloseTicketCheckbox handleCloseTicket={handleCloseTicket} />
                        }
                    </Box>

                    <Box sx={styles.buttonContainer}>
                        <ActionButton
                            label={'Cancel'}
                            action={handleClose}
                            buttonBordered
                            useBorderColor={'#07857C'}
                            withIcon={false}
                            sx={styles.cancel}
                        />
                        <ActionButton
                            label={'Submit'}
                            action={handleSubmit}
                            withIcon={false}
                            sx={styles.submitReason}
                        />
                    </Box>
                </Box>
            </Box>
        </Fragment>
    )
}

const styles = {
    heading: {
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#0C2728',
        letterSpacing: '-0.01em',
        marginBottom: '12px'
    },
    buttonContainer: {
        display: 'flex',
    },
    cancel: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '8px'
    },
    submitReason: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    actionsContainer: {
        display: 'flex',
        marginTop: '27px',
        justifyContent: 'space-between'
    }
}