import _ from "lodash";
import {history} from "_helpers";
import {SnackBar} from "components/common";
import {alertMessages,authorizationConstants} from "_constants";
import {authorizationService} from "_services";
import {userActions} from "_actions";


const signup = (values) => {
  const request = () => ({
    type: authorizationConstants.COMPANY_SIGNUP_REQUEST
  });

  const success = (user) => {
    return { type: authorizationConstants.COMPANY_SIGNUP_SUCCESS, user };
  };

  const failure = (error) => {
    return { type: authorizationConstants.COMPANY_SIGNUP_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    authorizationService.companySignup(values)
      .then(response => {
        const userData = _.get(response, "data");
        const { user, message, status } = userData;
        const parsedUser = JSON.parse(user || '{}');

        if(_.isEmpty(parsedUser) || status !== 201){
          SnackBar.showMessage(message);
          dispatch(failure(message));
          return;
        }
        const ADD_CHANNEL = '/signup/add-channel';
        localStorage.setItem('isOnboarding', 'true');
        localStorage.setItem('currentOnboardingPage', ADD_CHANNEL);

        localStorage.setItem('user', user);
        dispatch(success(parsedUser));
        dispatch(userActions.getCurrentUser(parsedUser.id));
        history.push(ADD_CHANNEL);

      }).catch((error) => {
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};


export const authorizationActions = {
  signup,
};