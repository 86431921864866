import React, { useState, useEffect, Fragment } from 'react';
import { List, ListItem, MenuItem, Menu } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import importedStyles from './styles';

/**
 * 
 * @param {object} params
 * @param {id} params.id - unique id of the component, for lists add the key into it (e.g. `my-cool-id-${key}`)
 * @param {{ label: string; value: any; }[]} params.options - list of options to be displayed
 * @param {any} params.selectedValue - (optional)
 * @param {function | undefined} params.onChange - (optional) callback function to be called when the value changes
 * @param {string | undefined} params.label - (optional), label, not being displayed. (only a11y).
 * @param {'outlined' | 'filled'} params.variant - (optional, default = 'outlined') the visual appearance.
 */
export default function ListMenu({
  id,
  options,
  selectedValue: _selectedValueProp,
  onChange,
  label,
  variant = 'outlined',
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isVariantFilled = variant === 'filled'

  useEffect(() => {
    if (!id)
      console.error('ListMenu component: `id` prop is required');
  }, [])

  useEffect(() => {
    if (!options || !options.length)
      return

    if (_selectedValueProp !== null || _selectedValueProp !== undefined) {
      const selectedIndex = options.findIndex(option => option.value === _selectedValueProp);
      setSelectedIndex(selectedIndex);
    }
  }, [_selectedValueProp, options]);

  const handleMenuItemClick = (event, index) => {
    if (typeof onChange === 'function')
      onChange(options[index].value);

    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return options && options.length ? (
    <Fragment>
      <List
        component="nav"
        sx={{ p: 0, width: { xs: '100%', sm: 'auto' } }}
      >
        <ListItem
          sx={{ ...styles.button, ...(isVariantFilled ? styles.buttonFilled : styles.buttonOutlined) }}
          button
          id={`${id}-button`}
          aria-haspopup="listbox"
          aria-controls={`${id}-menu`}
          aria-label={label}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          {options[selectedIndex].label}
          <KeyboardArrowDownIcon sx={styles.buttonIcon} />
        </ListItem>
      </List>

      <Menu
        elevation={2}
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: styles.menu
        }}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={styles.menuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  ) : null;
}

const styles = {
  button: {
    borderRadius: '9999px',
    border: '1.5px solid transparent',
    height: '40px',
    fontFamily: 'LarsseitMedium',
    fontSize: '16px',
    px: 2,
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonFilled: {
    backgroundColor: '#368C86',
    borderColor: '#368C86',
    color: '#fff',
    ':hover': {
      backgroundColor: '#368C86',
    }
  },
  buttonOutlined: {
    backgroundColor: 'transparent',
    borderColor: '#368C86',
    color: '#368C86',
  },

  buttonIcon: {
    fontSize: '24px',
  },

  menu: {
    border: '1px solid #D7DBDC',
    borderRadius: '2px',
    mt: 0.5,
  },
  menuItem: {
    ...importedStyles.root,
    fontSize: '14px',
    color: '#368C86',

    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#D7DBDC',
    }
  }
}