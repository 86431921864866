import * as React from 'react';
import {Box} from "@mui/system";
import EditSchedulesAction from "./EditSchedulesAction";
import DeleteScheduleAction from "./DeleteScheduleAction";

export default function ScheduleActionsContainer({ scheduleId, organizationId }) {
    return (
        <Box sx={style.actionButtonsContainer}>
            <Box>
                {/*@TODO: Implement Edit*/}
                {/*<EditSchedulesAction organizationId={organizationId} scheduleId={scheduleId}/>*/}
            </Box>
            <Box sx={{ml: '5px'}}>
                <DeleteScheduleAction organizationId={organizationId} scheduleId={scheduleId}/>
            </Box>
        </Box>
    )
}

const style = {
    actionButtonsContainer: {
        display: 'flex',
        justifyContent: "flex-end"
    }
}