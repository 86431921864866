import {earningsConstants, alertMessages} from '_constants';
import {earningsService} from '_services';
import {SnackBar} from "../components/common";

function getEarningsHistory(params, agentId) {
    function request() {
        return { type: earningsConstants.GET_EARNINGS_HISTORY_REQUEST };
    }

    function success(res) {
        return { type: earningsConstants.GET_EARNINGS_HISTORY_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: earningsConstants.GET_EARNINGS_HISTORY_FAILURE, error };
    }

    return (dispatch) => {
        dispatch(request());

        earningsService.getEarningsHistory(params, agentId)
            .then(
                resources => {
                    dispatch(success(resources.data));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function getLeadAgents(agentId) {
    function request() {
        return { type: earningsConstants.GET_LEAD_AGENTS_REQUEST };
    }

    function success(res) {
        return { type: earningsConstants.GET_LEAD_AGENTS_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: earningsConstants.GET_LEAD_AGENTS_FAILURE, error };
    }

    return (dispatch) => {
        dispatch(request());

        earningsService.getLeadAgents(agentId)
            .then(
                resources => {
                    dispatch(success(resources.data));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function getAgentPayouts(agentId) {
    function request() {
        return { type: earningsConstants.GET_AGENT_PAYOUTS_REQUEST };
    }

    function success(res) {
        return { type: earningsConstants.GET_AGENT_PAYOUTS_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: earningsConstants.GET_AGENT_PAYOUTS_FAILURE, error };
    }

    return (dispatch) => {
        dispatch(request());

        earningsService.getAgentPayouts(agentId)
            .then(
                response => {
                    dispatch(success(response.data));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

export const earningsActions = {
    getEarningsHistory,
    getLeadAgents,
    getAgentPayouts
};