import React from "react";
import {AnswerRateFilterDropdownForm} from "./Forms/AnswerRateFilterDropdownForm";
import {PreSaleFilterDropdownForm} from "./Forms/PreSaleFilterDropdownForm";
import {CsatFilterDropdownForm} from "./Forms/CsatFilterDropdownForm";
import {FilterDropdownForm as ResponseTimeFilterDropdownForm} from "./Forms/ResponseTimeFilterDropdownForm";
import {ProactiveDropdownForm} from "../ProactiveMetricsDetail/Forms/ProactiveDropdownForm";

export const pageFilterToDropdownMapping = ({
    allHandles,
    selectedCriteria,
    setSelectedCriteria,
    setSelectedChannels,
    selectedChannels,
    selectedCheckboxes,
    setSelectedCheckboxes,
    allChannelsSelected,
    setAllChannelsSelected,
    setLiked,
    setReplied,
    setNoEngagement,
    setFollowed,
    setConverted,
    setFollowUpSent,
    setConvertedAfterFollowUpSent
}) => {

    return {
        responseTime: (
            <ResponseTimeFilterDropdownForm
                handles={allHandles}
                selectedCriteria={selectedCriteria}
                setSelectedCriteria={setSelectedCriteria}
                setSelectedChannels={setSelectedChannels}
                selectedChannels={selectedChannels}
                allChannelsSelected={allChannelsSelected}
                setAllChannelsSelected={setAllChannelsSelected}
            />
        ),
        answerRate: <AnswerRateFilterDropdownForm
                        handles={allHandles}
                        selectedCriteria={selectedCriteria}
                        setSelectedCriteria={setSelectedCriteria}
                        setSelectedChannels={setSelectedChannels}
                        selectedChannels={selectedChannels}
                        selectedCheckboxes={selectedCheckboxes}
                        setSelectedCheckboxes={setSelectedCheckboxes}
                        allChannelsSelected={allChannelsSelected}
                        setAllChannelsSelected={setAllChannelsSelected}
        />,
        presale: <PreSaleFilterDropdownForm
                    handles={allHandles}
                    selectedCriteria={selectedCriteria}
                    setSelectedCriteria={setSelectedCriteria}
                    setSelectedChannels={setSelectedChannels}
                    selectedChannels={selectedChannels}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    allChannelsSelected={allChannelsSelected}
                    setAllChannelsSelected={setAllChannelsSelected}
        />,
        csat: <CsatFilterDropdownForm
                    handles={allHandles}
                    selectedCriteria={selectedCriteria}
                    setSelectedCriteria={setSelectedCriteria}
                    setSelectedChannels={setSelectedChannels}
                    selectedChannels={selectedChannels}
                    allChannelsSelected={allChannelsSelected}
                    setAllChannelsSelected={setAllChannelsSelected}
        />,
        allCampaigns: <ProactiveDropdownForm
            setLiked={setLiked}
            setReplied={setReplied}
            setNoEngagement={setNoEngagement}
            selectedCriteria={selectedCriteria}
            setSelectedCriteria={setSelectedCriteria}
            setFollowed={setFollowed}
            setConverted={setConverted}
            setFollowUpSent={setFollowUpSent}
            setConvertedAfterFollowUpSent={setConvertedAfterFollowUpSent}
        />,
        companyCampaign: <ProactiveDropdownForm
            setLiked={setLiked}
            setReplied={setReplied}
            setNoEngagement={setNoEngagement}
            selectedCriteria={selectedCriteria}
            setSelectedCriteria={setSelectedCriteria}
            setFollowed={setFollowed}
            setConverted={setConverted}
            setFollowUpSent={setFollowUpSent}
            setConvertedAfterFollowUpSent={setConvertedAfterFollowUpSent}
        />,
    }
};

export const pageFilterToTitleMapper = {
    responseTime: "Response time metrics",
    answerRate: "Customer questions solved",
    presale: "Presale questions",
    csat: "Customer satisfaction",
    allCampaigns: "Proactive Campaigns",
    companyCampaign: "Proactive Campaigns",
};

export const PRESALE = "presale";
export const RESPONSE_TIME = "responseTime";
export const CSAT = "csat";
export const ANSWER_RATE = "answerRate";

export const ALL_RESPONSES = "allResponses";

export const filterOptions = [
    {
        value: PRESALE,
        label: 'Presale questions'
    },
    {
        value: RESPONSE_TIME,
        label: 'Response time'
    },
    {
        value: ANSWER_RATE,
        label: 'Customer questions solved'
    },
    {
        value: CSAT,
        label: 'Customer satisfaction'
    }
];

export const responseTimeCriteriaOptions = [
    {"name": ALL_RESPONSES,
        "value": "All responses" },
    {"name": "LT90",
        "value": "Responses less than 90 Minutes"},
    {"name": "MT90",
        "value": "Responses more than 90 Minutes"},
    {"name": "LT4H",
        "value": "Responses less than 4 hours"},
    {"name": "MT4H",
        "value": "Responses more than 4 hours"}
]

export const csatCriteriaOptions = [
    {"name": ALL_RESPONSES,
        "value": "All responses" },
    {"name": "LT80",
        "value": "Lower than 80%" },
    {"name": "HT80",
        "value": "Higher than 80%" },
]

export const presaleCriteriaOptions = [
    {"name": PRESALE,
        "value": "Presale" },
    {"name": "other",
        "value": "Other"},
    ]

export const answerRateCriteriaOptions = [
    {"name": "solved",
        "value": "Solved" },
    {"name": "escalated",
        "value": "Escalated"},
    {"name": "skipped",
        "value": "Skipped"},
    {"name": "hidden",
        "value": "Hidden"},
    {"name": "noResponseNeeded",
        "value": "No Response Needed"}
]