import React, {useEffect, useState} from "react";
import {Box} from "@mui/system";
import {Divider, Grid} from "@mui/material";
import {connect, useDispatch, useSelector} from "react-redux";
import {Field, reduxForm} from "redux-form";
import styles from "../Utilities/templateStyles";
import BasicFormTextField from "../../../common/BasicFormTextField";
import BasicMultilineTextField from "../../../common/BasicMultilineTextField";
import TextButton from "../../../common/TextButton";
import ActionButton from "../../../common/ActionButton";
import TemplateField from "../Utilities/TemplateField";
import {required, validateCharLength} from "_helpers";
import {SnackBar} from "../../../../common";
import {buildGenerateRepliesPayload} from "../Utilities/CampaignUtils";
import {proactiveActions} from "_actions";
import _ from "lodash";
import IconAdd from "../../../common/IconAdd";
import TemplateTextEditor from "../Utilities/TemplateTextEditor"
import {disabledGenerateResponsesChannels} from "../../../../../_constants";

function UpdateCriteriaForm({ handleSubmit, handleCriteria, clearSelectedReplies, template, generateRepliesDisabled}) {
    const dispatch = useDispatch();
    let [fieldValues, setFieldValues] = useState({});

    const proactive = useSelector(state => state.proactive);
    const loadingGenerateReplies = proactive.loadingGenerateReplies;

    const handleFieldChange = (event) => {
        const fieldName = event.target.name;
        const value = event.target.value;
        if(!_.isEmpty(value)) Object.assign(fieldValues, { [fieldName] : value });
        else delete fieldValues[fieldName];
        setFieldValues(fieldValues);
        handleCriteria(fieldValues);
    }

    const handleRichTextChange = (fieldName, value) => {
        if(!_.isEmpty(value)) Object.assign(fieldValues, {[fieldName]: value})
        else delete fieldValues[fieldName];
        setFieldValues(fieldValues);
        handleCriteria(fieldValues);
    }

    const [templateList, setTemplateList] = useState([
        <TemplateField index={0} handleChange={handleFieldChange}/>
    ]);

    const handleGenerateReplies = (values) => {
        const payload = buildGenerateRepliesPayload(values)
        dispatch(proactiveActions.generateReplies(payload));
    };

    const addTemplate = () => {
        const templateCount = templateList.length;
        if(templateCount >= 3) {
            SnackBar.showMessage("You have reached the maximum number of templates");
            return
        }

        const _template = disabledGenerateResponsesChannels.includes(template?.channel) ?
            <TemplateTextEditor index={templateCount} handleChange={handleRichTextChange} /> :
            <TemplateField required={required} index={templateCount} handleChange={handleFieldChange} />;
        setTemplateList(templateList.concat(_template));
    }

    const renderTemplates = (template, index) => {
        return <Box key={index}>{template}</Box>
    }

    useEffect(() => {
        if(template?.channel){
            const _template = disabledGenerateResponsesChannels.includes(template?.channel) ?
                <TemplateTextEditor index={0} handleChange={handleRichTextChange} /> :
                <TemplateField required={required} index={0} handleChange={handleFieldChange} />;
            setTemplateList([_template])
        }
    },[template])

    return(
        <>
            <Grid item xs={12} md={6} sx={styles.criteriaContainer}>
                <form name="form" onSubmit={handleSubmit(handleGenerateReplies)} autoComplete="off">
                    <Box sx={styles.heading}>
                        Criteria
                    </Box>
                    <Box sx={styles.nameInputContainer}>
                        <Box>
                            <Box sx={styles.inputDesc}>Message template name</Box>
                            <Field
                                name="name"
                                component={BasicFormTextField}
                                placeholder={'Enter name'}
                                onChange={handleFieldChange}
                                validate={required}
                            />
                        </Box>
                        <Box sx={styles.topicInputContainer}>
                            <Box sx={styles.inputDesc}>Topic</Box>
                            <Field
                                name="topic"
                                component={BasicMultilineTextField}
                                rowsCount={4}
                                placeholder={'Enter topic'}
                                onChange={handleFieldChange}
                                validate={ validateCharLength }
                            />
                        </Box>
                        {!generateRepliesDisabled &&
                            <Box sx={styles.durationInputContainer}>
                                <Box sx={styles.inputDesc}>Days between same user replies</Box>
                                <Field
                                    name="minDaysBetweenSameReplyUsages"
                                    component={BasicFormTextField}
                                    placeholder={'Enter the number of days'}
                                    onChange={handleFieldChange}
                                    props={{ type: 'number', min: 0 }}
                                    validate={required}
                                />
                            </Box>
                        }
                        <Divider orientation={'horizontal'} flexItem sx={styles.sectionDivider} />
                        { templateList.map((template, index) => renderTemplates(template, index)) }
                        <Box>
                            <TextButton
                                label={'Add another template'}
                                action={addTemplate}
                                startIcon={<IconAdd/>}
                            />
                        </Box>
                        {!generateRepliesDisabled &&
                            <Box sx={styles.generateRepliesButtonContainer}>
                                <ActionButton
                                    label={'Generate replies'}
                                    action={clearSelectedReplies}
                                    disabled={loadingGenerateReplies}
                                    withIcon={false}
                                />
                            </Box>
                        }
                    </Box>
                </form>
            </Grid>
        </>
    )
}

const formConfig = {
    form: 'CriteriaSectionForm',
};

function getInitialValues(state) {
    const criteriaObj = {
        name: _.get(state, 'proactive.template.name') || '',
        topic: _.get(state, 'proactive.template.description') || '',
        minDaysBetweenSameReplyUsages: _.toString(_.get(state, 'proactive.template.minDaysBetweenSameReplyUsages')) || '',
    };
    return { initialValues: criteriaObj }
}

UpdateCriteriaForm = reduxForm(formConfig)(UpdateCriteriaForm)
UpdateCriteriaForm = connect(state => getInitialValues(state))(UpdateCriteriaForm);
export default UpdateCriteriaForm;