import React from 'react';
import {isObjectEmpty, objectToQueryString, queryStringToObject} from "_helpers";
import {Grid, Row, Table} from 'react-bootstrap';
import {organizationActions, msgDataSourceActions} from "_actions";
import {FilterForm} from "components/DataSource";
import {SwitchToggleButton} from "../common";
import connect from "react-redux/es/connect/connect";
import {Loading} from "components/common";
import {publicAndFeaturedVisibility} from "../../_constants";

class DataSource extends React.Component {
    constructor(props){
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams =queryStringToObject(search)
        let {organizationId} = filterParams;
        organizationId = organizationId ? organizationId.split(",") : [];
        filterParams.organizationId = organizationId;

        this.state = {
            filterParams: {
                ...filterParams
            }
        };

    }

    getDetails = (filterParams) => {
        const queryString = objectToQueryString(filterParams);

        this.props.history.push({
            pathname:'/dataSource',
            search: `?${queryString}`
        });
    };

    handleDataSourceFormSubmit = (event) => {
        event.preventDefault();
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, offset:0
                }
            }
        });
        const {filterParams} = this.state;
        this.getDetails(filterParams)
    };

    handleChange = () => {
        this.setState((prevState) =>{
            return {
                filterParams : {...prevState.filterParams}
            }
        })
    };

    toggleDispatcherV2 = (msgDataSource) => {
        const payload = {id: msgDataSource.id, isDispatcherV2Active: !msgDataSource.isDispatcherV2Active };
        msgDataSource.isDispatcherV2Active = payload.isDispatcherV2Active;
        this.props.dispatch(msgDataSourceActions.updateMsgDataSource(payload, {updateOnTrends: true}));
    };

    componentDidMount() {
        let {filterParams} = this.state;
        this.props.dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}));
        this.props.dispatch(msgDataSourceActions.getAll(filterParams));
    };

    handleOrganizationFilterChange = (organization) => {
        const {key} = this.state;
        this.populateParams(key, organization);
    };

    getOrganizations = (organizations, returnValue) => {
        const companies = [];
        organizations.forEach((organization) => {
            if (organization && organization.id) {
                if (returnValue === 'ids') {
                    companies.push(organization.id);
                } else {
                    const organizationData = {
                        value: organization.id,
                        name: organization.name,
                    };
                    companies.push(organizationData);
                }
            }
        });
        return companies;
    };

    populateParams(key, organizationId, offset) {
        this.setState(prevState => ({
            key,
            filterParams: {
                ...prevState.filterParams,
                organizationId,
                offset
            },
        }));
        const {filterParams} = this.state;
        const params = {
            ...filterParams,
            organizationId,
            offset
        };
        return params
    }

    renderRowValue = (msgDataSource) => {
        return (
            <tr key = {msgDataSource.id}>
                <td>
                    {msgDataSource.name}
                </td>
                <td>
                    <SwitchToggleButton
                        onChange={(event) => { this.toggleDispatcherV2(msgDataSource) }}
                        defaultChecked={msgDataSource.isDispatcherV2Active}
                        label={"DispatcherV2"}
                    />
                </td>
            </tr>
        )
    };

    render() {

        const {msgDataSources} = this.props;

        if (this.props.loading || isObjectEmpty(msgDataSources)) {
            return (
                <div style = {{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '100px 0'}}>
                    <Loading/>
                </div>
            )
        }


        const {
            filterParams: {
                organization,organizationId
            }
        } = this.state;


        let companies, companiesIds;
        const {organizations} =  this.props;
        let finalOrgList = organizations.organizations? organizations.organizations : organizations;

        if (finalOrgList) {
            companies = this.getOrganizations(finalOrgList);
            companiesIds = this.getOrganizations(finalOrgList, 'ids');
        }

        return (
            <Grid style ={{position: "relative", top: "100px", zIndex: "100"}}>
                <Row>
                    <FilterForm
                        onFormSubmit={this.handleDataSourceFormSubmit}
                        organization={organization}
                        company={organizationId}
                        companiesIds={companiesIds}
                        companies={companies}
                        organizations={finalOrgList}
                        handleOrgFilterChange={this.handleOrganizationFilterChange}
                        handleChange={this.handleChange}
                        {...this.state.filterParams}
                    />
                </Row>
                <br/>
                <br/>
                <Grid xs={12} sm={12} md={10} className="shadow-border">
                    <Row>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Message Data Source</th>
                            </tr>
                            </thead>
                            <tbody>
                            {msgDataSources.map(this.renderRowValue)}
                            </tbody>
                        </Table>
                    </Row>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const {organizations: {organizations}, msgDataSources:{msgDataSources}, loading} = state;

    return {
        organizations: organizations,
        loading: loading,
        msgDataSources: msgDataSources
    };
}

const dataSource = connect(mapStateToProps)(DataSource);
export {dataSource as DataSource}
