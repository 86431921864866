import React, {Fragment} from "react";
import TagModal from "./TagModal";
import useModalSetup from "../../../../../../hooks/useModalSetup";
import ActionButton from "../../../../../../common/ActionButton";

export default function TagEntry({ handleTagSelection, selectedTags }) {
    const { handleOpenModal, handleCloseModal, openModal } = useModalSetup();

    const handleSelectedTags = (tags) => {
        handleTagSelection(tags)
    }

    return(
        <Fragment>
            <ActionButton
                label={'Add tags'}
                action={handleOpenModal}
                withIcon={false}
                sx={styles.actionButton}
            />
            <TagModal
                title={'Select tags'}
                open={openModal}
                handleClose={handleCloseModal}
                handleSelectedTags={handleSelectedTags}
                selectedTags={selectedTags}
            />
        </Fragment>
    )
}

const styles = {
    actionButton: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}