export const dispatcherConstants = {
    DISPATCH_FAILURE: 'MESSAGE_DISPATCH_FAILURE',
    DISPATCH_SUCESS: 'MESSAGE_DISPATCH_SUCCESS',
    DISPATCH_REQUEST: 'MESSAGE_DISPATCH_REQUEST',

    DISPATCHER_DELETE_REQUEST: 'DISPATCHER_DELETE_REQUEST',
    DISPATCHER_DELETE_SUCCESS: 'DISPATCHER_DELETE_SUCCESS',
    DISPATCHER_DELETE_FAILURE: 'DISPATCHER_DELETE_FAILURE',

    DISPATCHER_LIKE_MESSAGE_REQUEST: 'DISPATCHER_LIKE_MESSAGE_REQUEST',
    DISPATCHER_LIKE_MESSAGE_SUCCESS: 'DISPATCHER_LIKE_MESSAGE_SUCCESS',
    DISPATCHER_LIKE_MESSAGE_FAILURE: 'DISPATCHER_LIKE_MESSAGE_FAILURE',

    DISPATCHER_UPDATE_REQUEST: 'DISPATCHER_UPDATE_REQUEST',
    DISPATCHER_UPDATE_SUCCESS: 'DISPATCHER_UPDATE_SUCCESS',
    DISPATCHER_UPDATE_FAILURE: 'DISPATCHER_UPDATE_FAILURE',
    
    DISPATCHER_BLACKLIST_REQUEST: 'DISPATCHER_BLACKLIST_REQUEST',
    DISPATCHER_BLACKLIST_SUCCESS: 'DISPATCHER_BLACKLIST_SUCCESS',
    DISPATCHER_BLACKLIST_FAILURE: 'DISPATCHER_BLACKLIST_FAILURE',

    DISPATCHER_MESSAGE_DELETE_STATUS_REQUEST: 'MESSAGE_DELETE_STATUS_REQUEST',
    DISPATCHER_MESSAGE_DELETE_STATUS_SUCCESS: 'DISPATCHER_MESSAGE_DELETE_STATUS_SUCCESS',
    DISPATCHER_MESSAGE_DELETE_STATUS_FAILURE: 'DISPATCHER_MESSAGE_DELETE_STATUS_FAILURE',

    GET_HTML_BODY_REQUEST: 'HTML_BODY_REQUEST',
    GET_HTML_BODY_SUCCESS: 'HTML_BODY_SUCCESS',
    GET_HTML_BODY_FAILURE: 'HTML_BODY_FAILURE',

    CLEAR_HTML_BODY: 'CLEAR_HTML_BODY',

    POSSIBLE_CROSS_DISPATCH_ERROR_CODE: 'POSSIBLE_CROSS_DISPATCH',
};