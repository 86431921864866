import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * @param {import('@mui/material').AccordionProps} params
 * @param {import('react').ReactNode} params.summaryComponent
 * @param {number} params.keyNumber Use the same value as the `key` value you are providing when looping.
 */
export default function AccordionItem({ summaryComponent, keyNumber, children, ...props }) {
	return (
		<Accordion disableGutters elevation={0} square sx={{
			'&:before': { display: 'none' },
			'&:not(:first-of-type)': {
				borderTop: '1px solid #b6bebf'
			},
		}} {...props}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ fontSize: '28px' }} />}
				aria-controls={`panel${keyNumber + 1}a-content`}
				id={`panel${keyNumber + 1}a-header`}
				sx={{
					px: '16px',
					'.MuiAccordionSummary-content': {
						my: '16px',
					}
				}}
			>
				{summaryComponent}
			</AccordionSummary>
			<AccordionDetails sx={{ px: '16px' }}>
				{children}
			</AccordionDetails>
		</Accordion>
	)
}