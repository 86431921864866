import {Box} from "@mui/system";
import React, {Fragment, useState} from "react";
import _ from "lodash";
import useGetCompanyUpdate from "../../../hooks/CompanyUpdate/useGetCompanyUpdate";
import WarningOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {formatDate, setAsViewed} from "../../../utilities/CompanyUpdateUtils";

export default function CompanyUpdate({handleIsEnabled}) {
    const [isOpen, setIsOpen] = useState(true);
    const { companyUpdate, loadingCompanyUpdates, shouldView } = useGetCompanyUpdate();

    if(loadingCompanyUpdates) {
        handleIsEnabled(false);
        return <Fragment />;
    }
    if(!shouldView && !loadingCompanyUpdates) {
        handleIsEnabled(true);
        return <Fragment />;
    }

    const organization = _.get(companyUpdate, 'organization.name');
    const dateCreated = _.get(companyUpdate, 'dateCreated');
    const dateInfo = formatDate(dateCreated);
    const googleDocsLink = _.get(companyUpdate, 'googleDocsLink');

    const openDocument = () => {
        window.open(googleDocsLink, '_blank');
        setIsOpen(false);
        handleIsEnabled(true);
        setAsViewed();
    }

    return(
        <Box sx={{...styles.container, ... !isOpen && {display: 'none'}}}>
            <Box sx={styles.wrapper}>
                <Box sx={styles.info}>
                    <Box sx={styles.iconContainer}>
                        <WarningOutlinedIcon sx={styles.icon}/>
                    </Box>
                    <Box sx={styles.description}>
                        Please review a new update from {organization} before answering this message
                    </Box>
                    <Box sx={styles.linkContainer}>
                        <Box sx={styles.link} onClick={openDocument}>
                            Click here for update
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.date}>
                    Posted: {dateInfo}
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        px: { xs: 2, sm: 4, md: 4, lg: 4 },
        maxWidth: '1500px',
        mx: 'auto',
        mt: '10px',
        mb: '25px',
        position: 'relative',
    },
    wrapper:{
        border: '1px solid',
        height: '70px',
        borderRadius: '5px',
        backgroundColor: '#144042',
        fontSize: '18px',
        fontFamily: 'LarsseitRegular',
        color: '#FFFFFF',
        px: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    info: {
        display: 'flex',
        alignItems: 'center'
    },
    iconContainer: {
        margin: '5px 20px 0 0'
    },
    icon: {
        color:'#E7FF56',
        fontSize: '24px'
    },
    description: {
        display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
    },
    linkContainer: {
        ml: { xs: '0', sm: '0', md: '15px', lg: '15px' },
    },
    link: {
        color: '#FFF',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    date: {
        fontSize: '16px',
        ml: '5px'
    }
}