import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { GenericModal } from 'components/common';
import { connect } from 'react-redux';
import { messageActions } from '_actions';
import ResponsePayloadData from 'components/ResponsePayload/ResponsePayloadData';

class ResponsePayload extends Component {
    state = {
      showModal: false,
    };

    hideModal = () => {
      this.setState({ showModal: false });
    }

    getResponsePayload= (message) => {
      const { dispatch } = this.props;
      dispatch(messageActions.getResponsePayload(message.id));
      this.setState({ showModal: true });
    }

    render() {
      const { message, responsePayload, loadingModal } = this.props;
      const { showModal } = this.state;
      return (
        <Fragment>
          <button
            className="font-14 color-green tms-no-underline btn-link"
            onClick={() => this.getResponsePayload(message)}
            type="button"
          >
            View Response Payload
          </button>
          <GenericModal
            headerText="Message Response Payload"
            hideFooter
            show={showModal}
            onHide={this.hideModal}
            size="lg"
          >
            <ResponsePayloadData payload={responsePayload} loading={loadingModal} />
          </GenericModal>
        </Fragment>
      );
    }
}

function mapStateToProps(state) {
  const { messages } = state;
  return {
    responsePayload: messages.responsePayload,
    loadingModal: messages.loading,
  };
}

export default connect(mapStateToProps)(ResponsePayload);

ResponsePayload.propTypes = {
  dispatch: PropTypes.func,
  message: PropTypes.object,
  responsePayload: PropTypes.string,
  loadingModal: PropTypes.bool,
};
