import React from 'react'
import {ControlLabel, FormGroup} from "react-bootstrap";
import FormControl from "react-bootstrap/es/FormControl";

const InputGroupButton = (props) => {
    const {placeHolder, name, label, style, input, btnAddonText, hidden, handleBtnSubmit, ...rest} = props;

    return (
        <FormGroup controlId="formControlsSelect" hidden={hidden}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <div className="input-group">
                <FormControl
                    className="form-control select-field color-grey-dark"
                    {...rest}
                    {...input}
                    placeholder={placeHolder}
                    onChange={input.onChange}
                    label='User Name'
                    style={style}
                    name={name}
                />
                <span className="input-group-btn">
                    <button className="btn btn-default" type="button" style={{padding: '9px 20px'}}
                        onClick={handleBtnSubmit}>{btnAddonText}</button>
                </span>
            </div>
        </FormGroup>
    )
}


export {InputGroupButton}
