import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Router, Switch } from 'react-router-dom';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from 'react-redux';
import { FacebookPages, InstagramPages, JiraOauthComponent } from 'components/OauthComponents';
import { Billing, ChannelList, ChatdeskAdminSettingsPage } from 'components/Settings';
import { history } from '_helpers';
import { alertActions } from '_actions';
import { userRoles as roles } from '_constants';
import {
  Loading, NotFoundPage, Notifier, PrivateRoute, SnackBar,
} from 'components/common';
import { ConfirmUserAccount } from 'components/LoginPage/ConfirmUserAccount';
import { Authorization } from 'components/Authorization';
import { Dashboard } from 'pages/Dashboards';
import {
  CompanyTeam, EmployersPage, EmploymentDetails, CompanyUsers,
} from 'components/Employment';
import { ReviewPage } from 'components/Review/ReviewPage';
import {
  AddUser, EditUser, UserDetails, UserIndex,
} from 'components/User';

import {
  AddRole, EditRole, RoleDetails, RoleIndex,
} from 'components/Role';
import { AllCompaniesSchedules, EditSchedule } from 'components/Profile';
import { AdminSearch } from 'components/Search/AdminSearch';
import Analytics from 'components/SummaryPage/Analytics';
import CompanyBilling from 'components/CompanyBilling/CompanyBilling';
import { BillingSummary } from 'components/CompanyBilling/BillingSummary';
import { ConfirmEmail, PasswordError } from 'components/ResetPasswordPage';
import 'components/css/custom-elements.css';
import 'components/css/fonts.css';
import 'components/css/colors.css';
import 'font-awesome/css/font-awesome.min.css';
import { Organizations } from 'pages';

import Auth0Init from 'components/LoginPage/Auth0Init';
import Auth0Logout from 'components/LoginPage/Auth0Logout';
import Auth0Callback from 'components/LoginPage/Auth0Callback';
import Auth0ProfileSetup from 'components/LoginPage/Auth0ProfileSetup';
import PromptMessage from 'components/LoginPage/PromptMessage';
import RegisterPage from 'components/RegisterPage/RegisterPage';
import AgentSignup from 'components/RegisterPage/AgentSignup';
import { RegisterSuccessPage, ConfirmRegistration } from 'components/RegisterPage';
import NavBar from 'components/common/NavBar';
import ZendeskSubmit from 'components/OauthComponents/zendesk/ZendeskContainer';
import FrontAppInboxes from 'components/OauthComponents/frontapp/FrontAppInboxes';

import { MsgDataSources } from 'components/Settings/MsgDataSources';
import { StripeConnect } from 'components/OauthComponents/stripe/StripeConnect';
import {
  CreatePayout, Payouts, CreatePricing, Pricing, EditPayout, EditPricing,
  Subscription, CreateSubscription, EditSubscription, OrganizationSubscribe,
  AgentPayouts, PayoutHistoryDetails
} from 'components/Payments';
import { SlackConnect } from 'components/OauthComponents/slack/SlackConnect';
import GorgiasConnect from 'components/OauthComponents/gorgias/GorgiasConnect';
import FreshdeskConnect from 'components/OauthComponents/freshdesk/FreshdeskConnect';
import KustomerConnect from 'components/OauthComponents/kustomer/KustomerConnect';
import ZendeskChatConnect from 'components/OauthComponents/zendesk/ZendeskChatConnect';
import ReamazeConnect from "components/OauthComponents/reamaze/ReamazeConnect";
import GladlyConnect from "components/OauthComponents/gladly/GladlyConnect";
import { ExpertPage, ExpertDetails, PaidMessages } from 'components/ExpertTracker';
import { PerfectResponses} from 'components/PerfectResponses';
import { AdminPerformance} from 'components/AdminTracker';
import { Portal } from 'components/Portal';
import { ResponseTimes } from 'components/ResponseTimes/ResponseTimes';
import { DataSource, DataSourceHandles } from 'components/DataSource';
import {ScreenshotsList, ScreenshotsAdd, ScreenshotFileView} from "components/Screenshots";
import { ShowDataSourceStatus } from 'components/DataSourceStatus'
import {VoteIndex} from "components/Vote/v2/VoteIndex";
import {Auth} from "../Authorization";
import {Profitability} from "../Profitability/Profitability";
import { SlippingReportIndex } from "../SlippingReport";
import {MessagesRequiringAction} from "../Message/MessagesRequiringAction";
import ShopifyConnect from 'components/OauthComponents/shopify/ShopifyConnect';
import {SimilarityScore} from "../SimilarityScore/SimilarityScore";
import {SummaryPage} from "../SummaryPage";
import {MessageIndexV2, MessageDetailsV2 } from "../Message-V2";

import {ChatdeskUpdatesList} from "../ChatdeskUpdates/List";
import {CreateChatdeskUpdate} from "../ChatdeskUpdates/CreateUpdate";
import CompanySignup from "components/v2/views/Onboarding/Signup/CompanySignup/CompanySignup";
import ConnectChannel from "components/v2/views/Onboarding/ConnectChannel/Channels/Utilities/ConnectChannel";
import ScheduleCall from "components/v2/views/Onboarding/ScheduleCall/ScheduleCall";
import OnboardingDashboard from "components/v2/views/Onboarding/Dashboard/Dashboard";
import ChannelErrorModal from "../common/Onboarding/Modal/ChannelErrorModal";
import PageSelectionModal from "../common/Onboarding/Modal/MetaSetupModal";
import TikTokUrlRedirect from "../v2/views/Onboarding/ConnectChannel/Channels/TikTok/TikTokUrlRedirect";

import ResourcesList from "../v2/views/Resources/Admin/ResourcesList";
import CreateResource from "../v2/views/Resources/Admin/CreateResource";
import EditResource from "../v2/views/Resources/Admin/EditResource";

// V2
import ConnectChannelsV2 from "components/v2/views/Channels/ConnectChannels/ConnectChannels";
import ConnectChannelsV2TikTokRedirect from "components/v2/views/Channels/ConnectChannels/providers/TikTok/TikTokUrlRedirect.jsx";
import ConnectChannelsV2TikTokAdsRedirect from "components/v2/views/Channels/ConnectChannels/providers/TikTokAds/TikTokAdsUrlRedirect.jsx";
import ConnectChannelsV2HelpScoutRedirect from "components/v2/views/Channels/ConnectChannels/providers/HelpScout/HelpScoutUrlRedirect.jsx";
import ConnectChannelsV2FrontappRedirect from "components/v2/views/Channels/ConnectChannels/providers/Frontapp/FrontappUrlRedirect.jsx";
import ConnectChannelsV2SalesforceRedirect from "components/v2/views/Channels/ConnectChannels/providers/Salesforce/SalesforceUrlRedirect.jsx";
import ConnectChannelsV2ShopifyRedirect from "components/v2/views/Channels/ConnectChannels/providers/Shopify/ShopifyUrlRedirect.jsx";
import ConnectChannelsV2TrustpilotRedirect from "components/v2/views/Channels/ConnectChannels/providers/Trustpilot/TrustpilotUrlRedirect.jsx";
import ListChannelsV2 from "components/v2/views/Channels/ListChannels/ListChannels";
import UsersV2 from "components/v2/views/Users/Users";
import CompanyTeamV2 from "components/v2/views/Employment/CompanyTeam/CompanyTeam";
import EmploymentDetailsV2 from "components/v2/views/Employment/EmploymentDetails/EmploymentDetails";
import MetricsDashboardV2 from "components/v2/views/MetricsDashboard/MetricsDashboard";
import MetricsScheduleDetailsV2 from "components/v2/views/MetricsDashboard/MetricsScheduleDetails";
import { GorgiasListOrganizations, GorgiasOrgViewsList, GorgiasOrgOpenTicketsList } from 'components/OpenTicketsCount'
import CompanyCampaign from "components/v2/views/Proactive/Company/CompanyCampaign";
import CompanyTemplate from "components/v2/views/Proactive/Company/CompanyTemplate";
import CompanyCreateTemplate from "components/v2/views/Proactive/Company/CompanyCreateTemplate";
import AdminCreateCampaign from "components/v2/views/Proactive/Admin/AdminCreateCampaign";
import Campaigns from "../v2/views/Proactive/Admin/Campaigns";
import AdminEditCampaign from "../v2/views/Proactive/Admin/AdminEditCampaign";
import CompanyEditTemplate from "../v2/views/Proactive/Company/CompanyEditTemplate";
import ShopifyUrlRedirect from "components/v2/views/Channels/ConnectChannels/providers/Shopify/ShopifyUrlRedirect.jsx";
import {MetricsDetailContainer} from "../v2/views/MetricsDetail/MetricsDetailContainer";
import VoteEntry from "../v2/views/Vote/VoteEntry";
import {BasicSnackBar} from "../v2/common/BasicSnackBar";
import {RefreshToast} from "../v2/common/RefreshToast";
import ExpertsPayouts from "../v2/views/ExpertEarnings/Payouts/ExpertsPayouts";
import {ProactiveMetricsDetailContainer} from "../v2/views/ProactiveMetricsDetail/ProactiveMetricsDetailContainer";
import  EarningsHistory from "../v2/views/ExpertEarnings/EarningsHistory/EarningsHistory";
import EarningsSummary from "../v2/views/ExpertEarnings/EarningsSummary/EarningsSummary";
import {AutoAction} from "../AutoAction";
import CompanyInboxV2 from "../v2/views/Inbox/Company/Inbox";
import InboxDetailsV2 from "../v2/views/Inbox/InboxDetails/InboxDetails"
import ExpertDashboard from "../v2/views/ExpertDashboard/ExpertDashboard";
import YoutubeUrlRedirect from "../v2/views/Channels/ConnectChannels/providers/YouTube/YoutubeUrlRedirect";
import ProfileV2 from "../v2/views/Profile/Profile";
import UpdatePasswordV2 from "../v2/views/Profile/UpdatePassword";
import UserProfileV2 from "../v2/views/Profile/UserProfile/UserProfile";
import CompanyProfileV2 from "../v2/views/Profile/CompanyProfile/CompanyProfile";
import SchedulePageV2 from "../v2/views/Profile/Schedule/Schedule";
import AddSchedulePageV2 from "../v2/views/Profile/Schedule/AddSchedule";
import OutOfService from "../LoginPage/OutOfService";
import YoutubeSetupModal from "../v2/views/Channels/ConnectChannels/providers/YouTube/YoutubeSetupModal";
import OrganizationSettingsEntry from "../v2/views/OrganizationSettings/OrganizationSettingsEntry";
import TikTokShopUrlRedirect from "../v2/views/Channels/ConnectChannels/providers/TikTokShop/TikTokShopUrlRedirect";
import TikTokShopSetupModal from "../v2/views/Channels/ConnectChannels/providers/TikTokShop/TikTokShopSetupModal";

const backgroundColor = {
  white: '#fff',
  whiteGrey: '#f1f1f1',
  snow: '#f9f9f9',
};

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen(() => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  render() {

    const { alert, loadingCurrentUser } = this.props;
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    const currentUserRoles = loggedInUser && loggedInUser.roles && loggedInUser.roles[0];
    const isChatdeskAgent = loggedInUser && currentUserRoles === roles.CHATDESK_AGENT_ROLE;
    const isChatdeskReviewer = loggedInUser && currentUserRoles === roles.CHATDESK_REVIEWER_ROLE;

    if (loadingCurrentUser) {
      return <Loading />;
    }

    document.body.style.setProperty('background', backgroundColor.whiteGrey);
    if (history.location.pathname === '/confirm_registration'
      || (history.location.pathname === '/dashboard' && isChatdeskAgent)
      || (history.location.pathname === '/dashboard' && isChatdeskReviewer)
      || (history.location.pathname === '/dashboard' && isChatdeskReviewer)
      || (history.location.pathname === '/v2/vote' && isChatdeskAgent)
    ) {
      document.body.style.setProperty('background', backgroundColor.white);
    } else if (
      (history.location.pathname === '/review' && isChatdeskReviewer)
      || (history.location.pathname === '/vote' && isChatdeskAgent)
      || (history.location.pathname === '/company/connectchannels')
    ) {
      document.body.style.setProperty('background', backgroundColor.snow);
    }

    // Authorizations
    const ChatdeskAdminAuth = Authorization([roles.CHATDESK_ADMIN_ROLE, roles.BILLING_ADMIN_ROLE]);
    const ChatdeskAgentAuth = Authorization([roles.CHATDESK_AGENT_ROLE]);
    const ChatdeskReviewerAuth = Authorization([roles.CHATDESK_REVIEWER_ROLE]);
    const CompanyAndChatdeskAdminAuth = Authorization([roles.COMPANY_ADMIN_ROLE, roles.CHATDESK_ADMIN_ROLE, roles.BILLING_ADMIN_ROLE]);
    const CompanyAdminAuth = Authorization([roles.COMPANY_ADMIN_ROLE]);
    const BillingAdminAuth = Authorization([roles.BILLING_ADMIN_ROLE]);
    const AllRolesAuthorized = Authorization(
      [
        roles.CHATDESK_ADMIN_ROLE,
        roles.BILLING_ADMIN_ROLE,
        roles.COMPANY_ADMIN_ROLE,
        roles.CHATDESK_AGENT_ROLE,
        roles.CHATDESK_REVIEWER_ROLE,
      ],
    );



    return (
      <div>
        {alert.message && <Notifier msg={alert.message} />}
        <SnackBar />
        <RefreshToast />
        <Auth />

        <Router history={history}>
          <div>
            <NavBar />
            <Switch>
              <Route exact path="/" component={Auth0Init} />

              {/*New signup flow*/}
              <Route exact path="/signup/create-account" component={ CompanySignup }/>
              <PrivateRoute exact path="/signup/add-channel" component={ CompanyAdminAuth(ConnectChannel) } />
              <PrivateRoute exact path="/signup/schedule" component={ CompanyAdminAuth(ScheduleCall) }/>
              <PrivateRoute exact path="/signup/dashboard" component={ CompanyAdminAuth(OnboardingDashboard) }/>
              <PrivateRoute exact path="/signup/tiktok-redirect" component={ CompanyAdminAuth(TikTokUrlRedirect) }/>
              <PrivateRoute exact path="/signup/shopify-redirect" component={ CompanyAdminAuth(ShopifyUrlRedirect) }/>

              <Route exact path="/signup" component={Auth0Init} />
              <Route exact path="/logout" component={Auth0Logout} />
              <Route exact path="/authenticate" component={Auth0Callback} />
              <Route exact path="/out-of-service" component={OutOfService} />
              <Route exact path="/profile/setup" component={Auth0ProfileSetup} />
              <Route exact path="/contact-support" component={PromptMessage} />
              <Route exact path="/verify-email" component={PromptMessage} />
              <Route exact path="/profile-success" component={PromptMessage} />
              <Route exact path="/expert-signup" component={AgentSignup} />
              <Route exact path="/register" component={RegisterPage} />
              <Route exact path="/register-success" component={RegisterSuccessPage} />
              <Route exact path="/confirmEmail" component={ConfirmEmail} />
              <Route exact path="/resetPassword" component={PasswordError} />
              <Route exact path="/users/confirm_user" component={ConfirmUserAccount} />
              <Route exact path="/stripe/connect" component={CompanyAndChatdeskAdminAuth(StripeConnect)} />
              <Route exact path="/slack/connect" component={CompanyAdminAuth(SlackConnect)} />
              <Route exact path="/gorgias/connect" component={CompanyAdminAuth(GorgiasConnect)} />
              <Route exact path="/freshdesk/connect" component={CompanyAdminAuth(FreshdeskConnect)} />
              <Route exact path="/kustomer/connect" component={CompanyAdminAuth(KustomerConnect)} />
              <Route exact path="/zendesk-chat/connect" component={CompanyAdminAuth(ZendeskChatConnect)} />
              <Route exact path="/reamaze/connect" component={CompanyAdminAuth(ReamazeConnect)} />
              <Route exact path="/gladly/connect" component={CompanyAdminAuth(GladlyConnect)} />
              <Route exact path="/jira/handleOauthRedirect" component={ChatdeskAdminAuth(JiraOauthComponent)} />
              <Route exact path="/shopify/connect" component={CompanyAdminAuth(ShopifyConnect)} />

              <Route exact path="/screenshots" component={ChatdeskAdminAuth(ScreenshotsList)}/>
              <Route exact path="/screenshots/add" component={ChatdeskAdminAuth(ScreenshotsAdd)}/>
              <Route exact path="/screenshots/:id/viewFile" component={AllRolesAuthorized(ScreenshotFileView)}/>

              { /* --- New Channels v2 routes --- */ }
              <PrivateRoute exact path="/company/connectchannels" component={CompanyAdminAuth(ConnectChannelsV2)} />
              <PrivateRoute exact path="/tiktok/accounts" component={CompanyAdminAuth(ConnectChannelsV2TikTokRedirect)} />
              <PrivateRoute exact path="/tiktok-ads/accounts" component={CompanyAdminAuth(ConnectChannelsV2TikTokAdsRedirect)} />
              <PrivateRoute exact path="/helpscout/accounts" component={CompanyAdminAuth(ConnectChannelsV2HelpScoutRedirect)} />
              <PrivateRoute exact path="/frontapp/accounts" component={CompanyAdminAuth(ConnectChannelsV2FrontappRedirect)} />
              <PrivateRoute exact path="/salesforce/accounts" component={CompanyAdminAuth(ConnectChannelsV2SalesforceRedirect)} />
              <PrivateRoute exact path="/salesforce-test/accounts" component={CompanyAdminAuth(ConnectChannelsV2SalesforceRedirect)} />
              <PrivateRoute exact path="/shopify/accounts" component={CompanyAdminAuth(ConnectChannelsV2ShopifyRedirect)} />
              <PrivateRoute exact path="/trustpilot/accounts" component={CompanyAdminAuth(ConnectChannelsV2TrustpilotRedirect)} />
              <PrivateRoute exact path="/youtube/accounts" component={CompanyAdminAuth(YoutubeUrlRedirect)} />
              <PrivateRoute exact path="/company/channels" component={CompanyAdminAuth(ListChannelsV2)} />
              <PrivateRoute exact path="/company/users" component={CompanyAdminAuth(UsersV2)} />
              <PrivateRoute exact path="/v2/company/team" component={CompanyAdminAuth(CompanyTeamV2)} />
              <PrivateRoute exact path="/v2/company/experts/:employmentId/show" component={CompanyAdminAuth(EmploymentDetailsV2)}/>
              <PrivateRoute exact path="/metrics" component={CompanyAdminAuth(MetricsDashboardV2)} />
              <PrivateRoute exact path="/metrics/schedule-details" component={CompanyAdminAuth(MetricsScheduleDetailsV2)} />
              <PrivateRoute exact path="/metrics-details" component={CompanyAdminAuth(MetricsDetailContainer)} />
              <PrivateRoute exact path="/tiktokshop/accounts" component={CompanyAdminAuth(TikTokShopUrlRedirect)} />
              { /* --- */ }

              <PrivateRoute exact path="/confirm_registration" component={ConfirmRegistration} />
              <PrivateRoute exact path="/dashboard" component={AllRolesAuthorized(Dashboard)} />

              <PrivateRoute exact path="/organizations" component={ChatdeskAdminAuth(Organizations)} />
              <PrivateRoute exact path="/messages" component={ChatdeskAdminAuth(MessageIndexV2)} />
              <PrivateRoute exact path="/messages/:id" component={ChatdeskAdminAuth(MessageDetailsV2)} />

              <PrivateRoute exact path="/users" component={BillingAdminAuth(UserIndex)} />
              <PrivateRoute exact path="/users/add" component={BillingAdminAuth(AddUser)} />
              <PrivateRoute exact path="/users/:userId/show" component={BillingAdminAuth(UserDetails)} />
              <PrivateRoute exact path="/users/:id/edit" component={BillingAdminAuth(EditUser)} />
              <PrivateRoute exact path="/update-password" component={AllRolesAuthorized(UpdatePasswordV2)} />

              <PrivateRoute exact path="/roles" component={ChatdeskAdminAuth(RoleIndex)} />
              <PrivateRoute exact path="/roles/add" component={ChatdeskAdminAuth(AddRole)} />
              <PrivateRoute exact path="/roles/:roleId/show" component={ChatdeskAdminAuth(RoleDetails)} />
              <PrivateRoute exact path="/roles/:id/edit" component={ChatdeskAdminAuth(EditRole)} />

              <PrivateRoute exact path="/profile" component={AllRolesAuthorized(ProfileV2)} />

              {/*<PrivateRoute exact path="/vote" component={ChatdeskAgentAuth(VoteIndex)} />*/}
              <PrivateRoute exact path="/review" component={ChatdeskReviewerAuth(ReviewPage)} />

              <PrivateRoute exact path="/experts" component={ChatdeskAdminAuth(PaidMessages)} />
              <PrivateRoute exact path="/experts/:orgId" component={ChatdeskAdminAuth(ExpertPage)} />
              <PrivateRoute exact path="/experts/details/:userId" component={ChatdeskAdminAuth(ExpertDetails)} />
              <PrivateRoute exact path="/perfectResponses" component={ChatdeskAdminAuth(PerfectResponses)}/>
              <PrivateRoute exact path="/adminPerformance" component={ChatdeskAdminAuth(AdminPerformance)}/>

              <PrivateRoute exact path="/company/team" component={CompanyAdminAuth(CompanyTeam)} />
              {/*<PrivateRoute exact path="/company/users" component={CompanyAdminAuth(CompanyUsers)} />*/}
              <PrivateRoute exact path="/company/experts/:employmentId/show" component={CompanyAndChatdeskAdminAuth(EmploymentDetails)}/>

              <PrivateRoute exact path="/employers" component={AllRolesAuthorized(EmployersPage)}/>


              <PrivateRoute exact path="/company/:id/profile" component={CompanyAdminAuth(CompanyProfileV2)} />
              <PrivateRoute exact path="/users/:id/profile" component={AllRolesAuthorized(UserProfileV2)} />

              <PrivateRoute exact path="/search" component={ChatdeskAdminAuth(AdminSearch)} />

              <PrivateRoute exact path="/inbox" component={CompanyAdminAuth(CompanyInboxV2)} />
              <PrivateRoute exact path="/inbox/:id" component={CompanyAdminAuth(InboxDetailsV2)} />
              <PrivateRoute exact path="/stats" component={CompanyAdminAuth(SummaryPage)} />
              <PrivateRoute exact path="/analytics" component={ChatdeskAdminAuth(Analytics)} />
              <PrivateRoute exact path="/billing-summary" component={BillingAdminAuth(BillingSummary)} />
              <PrivateRoute exact path="/slipping-report" component={BillingAdminAuth(SlippingReportIndex)} />

              <PrivateRoute exact path="/company/:id/schedules" component={CompanyAdminAuth(SchedulePageV2)} />
              <PrivateRoute exact path="/company/:id/schedules/add" component={CompanyAdminAuth(AddSchedulePageV2)} />
              <PrivateRoute exact path="/frontapp/inboxes" component={CompanyAdminAuth(FrontAppInboxes)} />
              <PrivateRoute exact path="/settings/billing" component={CompanyAdminAuth(Billing)} />
              <PrivateRoute exact path="/company/facebook/pages" component={CompanyAdminAuth(FacebookPages)} />
              <PrivateRoute exact path="/company/datasources" component={CompanyAdminAuth(MsgDataSources)} />
              <PrivateRoute exact path="/company/instagram/pages" component={CompanyAdminAuth(InstagramPages)}/>

              <PrivateRoute exact path="/admin/settings" component={ChatdeskAdminAuth(ChatdeskAdminSettingsPage)}/>
              <PrivateRoute exact path="/company/zendeskConnect" component={CompanyAdminAuth(ZendeskSubmit)} />
              <PrivateRoute exact path="/pricing-rates" component={BillingAdminAuth(Pricing)} />
              <PrivateRoute exact path="/create-pricing-rate" component={BillingAdminAuth(CreatePricing)} />
              <PrivateRoute exact path="/payouts-rates" component={BillingAdminAuth(Payouts)} />
              <PrivateRoute exact path="/create-payout-rate" component={BillingAdminAuth(CreatePayout)} />
              <PrivateRoute exact path="/edit-payout-rate/:itemId" component={BillingAdminAuth(EditPayout)} />
              <PrivateRoute exact path="/edit-pricing-rate/:itemId" component={BillingAdminAuth(EditPricing)} />
              <PrivateRoute exact path="/schedules" component={ChatdeskAdminAuth(AllCompaniesSchedules)} />
              <PrivateRoute exact path="/edit-schedule/:orgId/:scheduleId" component={ChatdeskAdminAuth(EditSchedule)} />
              <PrivateRoute exact path="/billing" component={BillingAdminAuth(CompanyBilling)} />
              <PrivateRoute exact path="/subscriptions" component={BillingAdminAuth(Subscription)} />
              <PrivateRoute exact path="/create-subscription" component={BillingAdminAuth(CreateSubscription)} />
              <PrivateRoute exact path="/edit-subscription/:itemId" component={BillingAdminAuth(EditSubscription)} />
              <PrivateRoute exact path="/organizations/subscriptions" component={BillingAdminAuth(OrganizationSubscribe)} />
              <PrivateRoute exact path="/agents/payouts" component={BillingAdminAuth(AgentPayouts)} />
              <PrivateRoute exact path="/agents/payouts/:agentId" component={BillingAdminAuth(PayoutHistoryDetails)} />
              <PrivateRoute exact path="/portal" component={BillingAdminAuth(Portal)}/>
              <PrivateRoute exact path="/dataSource" component={ChatdeskAdminAuth(DataSource)}/>
              <PrivateRoute exact path="/dataSourceHandles" component={ChatdeskAdminAuth(DataSourceHandles)}/>
              <PrivateRoute exact path="/autoaction/:dataSourceHandleId" component={ChatdeskAdminAuth(AutoAction)}/>
              <PrivateRoute exact path="/showDataSourceStatus" component={ChatdeskAdminAuth(ShowDataSourceStatus)}/>
              <PrivateRoute exact path="/profitability" component={BillingAdminAuth(Profitability)} />
              <PrivateRoute exact path="/messagesRequiringAction" component={ChatdeskAdminAuth(MessagesRequiringAction)} />
              <PrivateRoute exact path="/responseTimes" component={ChatdeskAdminAuth(ResponseTimes)}/>
              <PrivateRoute exact path="/similarityScore" component={ChatdeskAdminAuth(SimilarityScore)}/>
              <PrivateRoute exact path="/companyUpdates" component={ChatdeskAdminAuth(ChatdeskUpdatesList)}/>
              <PrivateRoute exact path="/companyUpdates/create" component={ChatdeskAdminAuth(CreateChatdeskUpdate)}/>
              <PrivateRoute exact path="/open-tickets" component={ChatdeskAdminAuth(GorgiasListOrganizations)}/>
              <PrivateRoute exact path="/open-tickets-views" component={ChatdeskAdminAuth(GorgiasOrgViewsList)}/>
              <PrivateRoute exact path="/open-tickets-list" component={ChatdeskAdminAuth(GorgiasOrgOpenTicketsList)}/>

              {/*Proactive*/}
              <PrivateRoute exact path="/company/campaign" component={CompanyAdminAuth(CompanyCampaign)}/>
              <PrivateRoute exact path="/company/template" component={CompanyAdminAuth(CompanyTemplate)}/>
              <PrivateRoute exact path="/company/create-template" component={CompanyAdminAuth(CompanyCreateTemplate)}/>
              <PrivateRoute exact path="/company/edit-template/:id" component={CompanyAdminAuth(CompanyEditTemplate)}/>
              <PrivateRoute exact path="/admin/campaign" component={ChatdeskAdminAuth(Campaigns)}/>
              <PrivateRoute exact path="/admin/create-campaign" component={ChatdeskAdminAuth(AdminCreateCampaign)}/>
              <PrivateRoute exact path="/admin/create-campaign/:id" component={ChatdeskAdminAuth(AdminCreateCampaign)}/>
              <PrivateRoute exact path="/admin/edit-campaign/:id" component={ChatdeskAdminAuth(AdminEditCampaign)}/>
              <PrivateRoute exact path="/proactive-metrics-details" component={CompanyAndChatdeskAdminAuth(ProactiveMetricsDetailContainer)} />
              { /* --- */ }

              {/*Resources*/}
              <PrivateRoute exact path="/company-resources" component={ChatdeskAdminAuth(ResourcesList)}/>
              <PrivateRoute exact path="/company-resources/create" component={ChatdeskAdminAuth(CreateResource)}/>
              <PrivateRoute exact path="/company-resources/:id" component={ChatdeskAdminAuth(EditResource)}/>

              {/*Vote*/}
              <PrivateRoute exact path="/vote" component={ChatdeskAgentAuth(VoteEntry)} />
              { /* --- */ }

              {/*Expert earnings*/}
              <PrivateRoute exact path="/expert-payouts" component={ChatdeskAgentAuth(ExpertsPayouts)} />
              <PrivateRoute exact path="/earnings-history" component={ChatdeskAgentAuth(EarningsHistory)} />
              <PrivateRoute exact path="/earnings-summary" component={ChatdeskAgentAuth(EarningsSummary)} />
              { /* --- */ }

              {/*Settings*/}
              <PrivateRoute exact path="/organizations/settings" component={ChatdeskAdminAuth(OrganizationSettingsEntry)} />
              <PrivateRoute exact path="/organizations/:id/settings" component={ChatdeskAdminAuth(OrganizationSettingsEntry)} />
              { /* --- */ }

              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </Router>

        <ChannelErrorModal />
        <PageSelectionModal />
        <YoutubeSetupModal />
        <TikTokShopSetupModal />
      </div>
    );
  }
}

App.propTypes = {
  loadingCurrentUser: PropTypes.bool,
  alert: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { alert, auth, users: { currentUser } } = state;

  return {
    alert,
    loadingCurrentUser: currentUser.loading,
    authUser: auth.user,
  };
}

export default connect(mapStateToProps)(App);
