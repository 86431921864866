import {SERVER_URL} from "config";
import axios from "axios";
import {authHeader} from "_helpers";

function getAutoAction(dataSourceHandleId) {
    const endpoint = `${SERVER_URL}/api/autoAction/${dataSourceHandleId}`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function createOrUpdateAutoAction(params) {
    const endpoint = `${SERVER_URL}/api/autoAction`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

export const autoActionService = {
    getAutoAction,
    createOrUpdateAutoAction,
}