import React, { Component, Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import moment from 'moment'

/**
 * @author Offiong Aniekan <ekanoffiong@gmail.com>
 * @date 20/12/2018 : 11:07 AM
 */
class ListFreshdeskDataSources extends Component {

    state = {
        updateDataSources: {}
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAllFreshdeskDataSource());
    }

    editEmailData = (dataSource) => {
        this.setState((prevState) => ({
            updateDataSources: {
                ...prevState.updateDataSources,
                [dataSource.id]: {
                    supportEmail: dataSource.supportEmail,
                    orgSupportEmail: dataSource.orgSupportEmail,
                }
            }
        }))
    };

    handleEmailChange = (e, dataSourceId) => {
        this.setState((prevState) => ({
            updateDataSources: {
                ...prevState.updateDataSources,
                [dataSourceId]: {
                    ...prevState.updateDataSources[dataSourceId],
                    [e.target.name]: e.target.value
                }
            }
        }));
    };

    cancelUpdate = (dataSourceId) => {
        this.setState((prevState) => {
            let { [dataSourceId]: removed, ...restDataSources } = prevState.updateDataSources;
            return {
                updateDataSources: restDataSources
            }
        })
    };

    saveSupportEmail = (dataSourceId) => {
        const { dispatch } = this.props;
        const { updateDataSources } = this.state;
        dispatch(msgDataSourceActions.saveSupportEmail({dataSourceId: dataSourceId, data: updateDataSources[dataSourceId], msgDataSource: 'freshdesk'}));
        this.cancelUpdate(dataSourceId)
    };

    render() {
        const { dataSources, loading } = this.props;
        const { updateDataSources } = this.state
        if (loading) {
            return <Loading/>
        }
        return (
            <div>
                <Row>
                    <Col xs={12} md={12}>
                        <table className='table trds-table'>
                            <thead className="font-18-bold">
                                <tr>
                                    <th className="col-md-1">S/N</th>
                                    <th className="col-md-3">Organization (subdomain)</th>
                                    <th className="col-md-4"><strong>Support Email</strong> <br/> e.g. support@company.com <br />(You can add multiple Company email like email1@company.com,email2@company.com)</th>
                                    <th className="col-md-3"><strong>Teams Reply Email</strong> <br /> e.g. company@chatdesk.com</th>
                                    <th className="col-md-1">{""}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {dataSources.map((dataSource, index) => (
                                <tr key={index}>
                                    <td className="vertical-align-middle">{index + 1}</td>
                                    <td className="vertical-align-middle">{dataSource.org.name} ({dataSource.subdomain})</td>
                                    <td>
                                        {!(dataSource.id in updateDataSources) &&
                                        <Fragment>
                                            {dataSource.orgSupportEmail &&
                                            <Fragment>
                                                        <span>
                                                            {dataSource.orgSupportEmail.split(',').join(', ')}
                                                        </span>
                                                <Button className='btn-info btn-sm margin-left-5' onClick={() => this.editEmailData(dataSource)}>
                                                    Edit
                                                </Button>
                                            </Fragment>
                                            }
                                            {!dataSource.orgSupportEmail &&
                                            <Button className='btn-info btn-sm float-right' onClick={() => this.editEmailData(dataSource)}>
                                                Add
                                            </Button>
                                            }
                                        </Fragment>
                                        }
                                        {dataSource.id in updateDataSources &&
                                        'orgSupportEmail' in updateDataSources[dataSource.id] &&
                                            <input type="text" className="form-control" name="orgSupportEmail" value={updateDataSources[dataSource.id].orgSupportEmail} onChange={(e) => this.handleEmailChange(e, dataSource.id)}/>
                                        }
                                    </td>
                                    <td>
                                        {!(dataSource.id in updateDataSources) &&
                                            <Fragment>
                                                {dataSource.supportEmail &&
                                                    <Fragment>
                                                        <span>
                                                            {dataSource.supportEmail}
                                                        </span>
                                                        <Button className='btn-info btn-sm margin-left-5' onClick={() => this.editEmailData(dataSource)}>
                                                            Edit
                                                        </Button>
                                                    </Fragment>
                                                }
                                                {!dataSource.supportEmail &&
                                                    <Button className='btn-info btn-sm float-right' onClick={() => this.editEmailData(dataSource)}>
                                                        Add
                                                    </Button>
                                                }
                                            </Fragment>
                                        }
                                        {dataSource.id in updateDataSources &&
                                            'supportEmail' in updateDataSources[dataSource.id] &&
                                            <input type="text" className="form-control" name="supportEmail" value={updateDataSources[dataSource.id].supportEmail} onChange={(e) => this.handleEmailChange(e, dataSource.id)} />
                                        }
                                    </td>
                                    <td>
                                        {dataSource.id in updateDataSources &&
                                            <Fragment>
                                                <Button className="margin-top-5 btn-sm btn-success"
                                                    onClick={() => this.saveSupportEmail(dataSource.id)}>
                                                    Save
                                                </Button>
                                                <Button className="margin-top-5 btn-danger btn-sm"
                                                    onClick={() => this.cancelUpdate(dataSource.id)}>
                                                    Cancel
                                                </Button>
                                            </Fragment>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        );

    }
}

function mapStateToProps(state) {
    const { freshdeskAuth: { dataSources, loading } } = state;
    return {
        dataSources, loading
    };
}

const connectedListFreshdeskDataSources = connect(mapStateToProps)(ListFreshdeskDataSources);
export { connectedListFreshdeskDataSources as ListFreshdeskDataSources };
