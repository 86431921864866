export class SearchMessageBuilder {
    constructor() {
        this.message = {};
    }

    setId(id){
        this.message.id = id;
        return this;
    }

    //Required for clickable table rows
    setParam(param){
        this.message.param = param;
        return this;
    }

    setDirection(direction){
        this.message.direction = direction;
        return this;
    }

    setDate(date){
        this.message.date = date;
        return this;
    }

    setText(text){
        this.message.text = text;
        return this;
    }

    setPhrase(phrase){
        this.message.phrase = phrase;
        return this;
    }

    setAction(action){
        this.message.action = action;
        return this;
    }

    build(){
        return this.message;
    }
}
