import {createElement} from "react";
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import navigationItems from "./navigationItems";
import {getUrlSlug, navigate} from "./organizationSettingsUtils";

export default function NavigationPanel({userRole, setLocation}) {
    const navItems = navigationItems(userRole);

    function getSelected(fragmentId) {
        return getUrlSlug().includes(fragmentId);
    }

    function handleNavigation(fragmentId) {
        navigate(fragmentId);
        setLocation(fragmentId);
    }

    return(
        <>
            <Box sx={style.drawerContainer}>
                <List>
                    {navItems.filter(item => item.permission).map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton
                                    sx={style.listItemButton}
                                    onClick={() => handleNavigation(item.fragmentId)}
                                    selected={getSelected(item.fragmentId)}>
                                    <ListItemIcon sx={style.listItemIcon}>
                                        {createElement(item.icon, {
                                            style: getSelected(item.fragmentId) ? style.elementIcon : {}
                                        })}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.name}
                                        primaryTypographyProps={style.listItemTypography}
                                        sx={style.listItemText}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </List>
            </Box>
        </>
    )
}

const style = {
    drawerContainer: {
        minWidth: '285px',
        px: '10px',
        backgroundColor: '#FFFFFF',
        borderRadius: '7px',
        border: '1px solid #CED4D4'
    },
    listItemButton: {
        borderRadius: '3px',
        height: '48px',
        px: '10px',
    },
    listItemIcon: {
        p: '0px',
        minWidth: '35px',
    },
    listItemText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    listItemTypography: {
        fontSize: '14.5px',
        fontFamily: 'LarsseitRegular'
    },
    elementIcon: {
        color: "#3081ED"
    }
}