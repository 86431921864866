import {utilityService, gorgiasAuthService} from '_services';

import {oauthConstants, alertMessages} from '_constants';
import {SnackBar} from "../components/common";
import { history } from '_helpers';

/**
 * This method handles verification of the user's credentials
 * @param params
 * @returns {Function}
 */
function verifyKey(params) {
    function request() {
        return {type: oauthConstants.GORGIAS_AUTH_REQUEST};
    }

    function success(payload) {
        return {type: oauthConstants.GORGIAS_AUTH_SUCCESS, payload }
    }

    function failure() {
        return {type: oauthConstants.GORGIAS_AUTH_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        gorgiasAuthService.verifyApiKey(params)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.GORGIAS_AUTH_SUCCESS);
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GORGIAS_AUTH_FAILURE);
                },
            );
    };
}

function getGorgiasSupportEmails(msgDataSourceIds) {
    function success(payload) {
        return {type: oauthConstants.GORGIAS_SUPPORT_EMAIL_SUCCESS, payload }
    }

    function failure() {
        return {type: oauthConstants.GORGIAS_SUPPORT_EMAIL_FAILURE}
    }

    return (dispatch) => {
        gorgiasAuthService.gorgiasSupportEmails(msgDataSourceIds)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

function initOauth(params) {
  function request() {
    return { type: oauthConstants.GORGIAS_SUBMIT_SUBDOMAIN_REQUEST };
  }
  function success(authUrl) {
    return { type: oauthConstants.GORGIAS_SUBMIT_SUBDOMAIN_SUCCESS, payload: authUrl };
  }
  function failure(error) {
    return { type: oauthConstants.GORGIAS_SUBMIT_SUBDOMAIN_FAILURE, payload: error };
  }

  return (dispatch) => {
    dispatch(request());
    gorgiasAuthService.initiateOauth(params)
      .then((res) => {
          utilityService.redirectToAuthUrl(res.url);
          dispatch(success(res))}
        ,(error) => {
          dispatch(failure());
        },
      );
  };
}


function generateAccessToken(params, callbackUrl = '/company/channels', onErrorCallbackUrl = '/company/connectchannels') {
  return (dispatch) => {
    dispatch(request());
    gorgiasAuthService.genAccessToken(params)
      .then(
        (res) => {
          if (res) {
            history.push(callbackUrl);
            dispatch(success(res));
            SnackBar.showMessage(alertMessages.GORGIAS_OAUTH_SUCCESS);
          }
        },
        (error) => {
          history.push(onErrorCallbackUrl);
          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.GORGIAS_OAUTH_FAILURE);
        },
      );
  };

  function request() {
    return { type: oauthConstants.GORGIAS_GENTOKEN_REQUEST };
  }
  function success(res) {
    return { type: oauthConstants.GORGIAS_GENTOKEN_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: oauthConstants.GORGIAS_GENTOKEN_FAILURE, payload: error };
  }
}


export const gorgiasAuthActions = {
    verifyKey,
    getGorgiasSupportEmails,
    initOauth,
    generateAccessToken
};