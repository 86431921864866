import React, {Component} from 'react';
import htmlParser from 'he';
import {Typography} from "@material-ui/core";
import {HtmlTooltip, MessageFollowupParser} from "components/common";


/**
 * @author Chris Okebata
 */


class ShowMoreToggle extends Component{

  constructor(props){
    super(props);

    this.state = {
      width: 0,
      height: 0,

      showMoreButtonActive: false,
      showLessButtonActive: false,
      defaultWrap: true,
      wrapText: true,
    };

    this.hasOverflowingContent = true;
    this.isShowMore = false;
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  getElementData = (element) => {
    this.hasOverflowingContent = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
    this.isShowMore = this.hasOverflowingContent;

    if(this.hasOverflowingContent){
      if(this.state.defaultWrap){
        this.setState({showMoreButtonActive:true, defaultWrap: false})
      }
    }
  };

  showMore = () => {
    this.setState({showMoreButtonActive:false, showLessButtonActive:true, wrapText: false});
    this.isShowMore = false;
  };

  showLess = () => {
    this.setState({showMoreButtonActive:true, showLessButtonActive:false, wrapText: true});
    this.isShowMore = true;
  };


  render() {

    const { text, index, usePopover, shouldParseText, msgSource } = this.props;
    const { showMoreButtonActive, showLessButtonActive, wrapText } = this.state;
    let showTextToggle;

    if((showMoreButtonActive && this.isShowMore) || (showMoreButtonActive && this.hasOverflowingContent)){
      showTextToggle = <button style={{padding: '0'}} onClick={this.showMore} className="transparent-button font-13">Show more</button>
    }else if(showLessButtonActive){
      showTextToggle = <button style={{padding: '0'}} onClick={this.showLess} className="transparent-button font-13">Show less</button>
    }
    let renderText, textContent, parsedText = htmlParser.decode(text);

    if(shouldParseText){
      textContent = <MessageFollowupParser
                      text={parsedText}
                      msgSource={msgSource}
                    />
    }else {
      textContent = <div>{parsedText}</div>
    }

    if(usePopover && (this.isShowMore)){
      renderText = <HtmlTooltip
                    title={
                      <div style={{width: 'fit-content', maxWidth: '700px', padding: '10px'}}>
                        <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                          {parsedText}
                        </Typography>
                      </div>
                    }
                    placement={'bottom'}
                    style={{opacity: 1}}
                    enterDelay={700}>
                    <div>
                      {textContent}
                    </div>
                  </HtmlTooltip>
    }else {
      renderText = <div> {textContent} </div>
    }

    return(
      <div key={index}>
        <div className={`font-15 color-black-fade ${(wrapText || this.isShowMore) && 'tms-truncate-3-lines'}`}>
          <div ref={el => {
            if (!el) return;
            this.getElementData(el);
          }}>
            {renderText}
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'flex-start',}}>
          { showTextToggle }
        </div>
      </div>
    )
  }

}

export { ShowMoreToggle };