import React from "react";
import {Box} from "@mui/system";
import Password from "../../Utilities/Password";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import { passwordStrength } from 'check-password-strength';
import _ from 'lodash';


export default function PasswordGroup({ input, handleVisibility, handleValidPassword, meta }) {
  const [value, setValue] = React.useState('');
  const [keyCount, setKeyCount] = React.useState(0);

  const performAction = (value) => {
    const isValEmpty = !_.isEmpty(value);
    setValue(value);
    if(isValEmpty) setKeyCount(keyCount + 1);
    handleVisibility(isValEmpty || keyCount > 0);
  };

  return(
    <Box input={input} >
      <Password
        input={input}
        meta={meta}
        label={"Password"}
        performAction={ performAction } />

      <Box sx={{marginTop: '15px'}}>
        <PasswordStrengthIndicator
          input={input}
          value={value}
          isVisible={ keyCount > 0 }
          passwordStrength={passwordStrength(value)}
          meta={meta} />
      </Box>
    </Box>
  )
}