import React, {Fragment, useState} from 'react';
import {Grid} from "@mui/material";
import ReactPaginate from "react-paginate";
import {useSelector, useDispatch} from "react-redux";
import {styles} from '../Utilities/ProactiveMetricsDetailStyles'
import {proactiveActions} from "_actions";
import {ProactiveMessageList} from "../ProactiveMessageList";
import ProactiveMetricsConversation from "../ProactiveMetricsConversation";
import ConversationCarousel from "../../Vote/Sections/BodySection/Conversation/ConversationCarousel";
import {
    isOEmbedSupported
} from "../../Vote/Sections/BodySection/Conversation/OEmbed/EmbedUtils";
import _ from "lodash";

export const ProactiveMessagesBox = ({
     messageList,
     allMessagesCount,
     eachPageMessagesCount,
     selectedCheckboxes,
     pageParams,
     setPageParams,
     loading
}) => {
    const proactive = useSelector((state) => state.proactive)
    const conversationThread = useSelector((state) => proactive.proactiveMsgConversation)
    const loadingConversation = proactive.loadingProactiveMsgConversation;
    const dispatch = useDispatch();
    const [messageLink, setMessageLink] = useState("");
    const [messageSource, setMessageSource] = useState("");

    const handlePageSelect = (page) => {
        if (conversationThread?.length > 0) {
            dispatch(proactiveActions.clearConversation())
        }
        setPageParams({offset: parseInt(page.selected,10) * 10, currentPage: parseInt(page.selected, 10)})
    }
    const getNextPage = () => {
        const queryOffset = parseInt(pageParams.offset, 10);
        const queryMax = parseInt('10', 10);
        eachPageMessagesCount = parseInt(eachPageMessagesCount, 10)

        if (!eachPageMessagesCount) return 0;

        if ([0, 10, 20, 30, 40, 50, 60, 70, 80, 90].includes(queryOffset) && eachPageMessagesCount === 10) {
            return eachPageMessagesCount + 1;
        } else if (eachPageMessagesCount / queryMax === 1) {
            return (queryOffset / eachPageMessagesCount) + 2;
        } else if (eachPageMessagesCount / queryMax < 1) {
            return (queryOffset / queryMax) + 1;
        } else {
            return 100 // fallback
        }
    };

    const totalPages = getNextPage();
    const messageCountStart = pageParams.offset + 1;
    const totalMsgsCount = allMessagesCount
    const messageCountEnd = pageParams.offset + eachPageMessagesCount
    const conversationItem = conversationThread?.length > 0 && conversationThread[0] || {};
    let {msgSource, displayInApp} = conversationItem;
    msgSource = _.toString(msgSource).toLowerCase();
    const showCarousel = isOEmbedSupported(msgSource, displayInApp);

    function Content() {
        return(
            conversationThread?.length > 0 && conversationThread.map((conversation, index) =>
                <ProactiveMetricsConversation
                    key={_.get(conversation, 'id')}
                    message={conversation}
                    loadingConversation={loadingConversation}
                    index={index}
                />
            )
        )
    }

    return(
        <Fragment>
            <div style={styles.rootWrapper}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={5} lg={5} style={{ width: '543px'}}>
                        <div style={{
                            height: 'calc(100vh - 60px)',
                            borderRight: '1px solid #CED2D0',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '72px',
                                display: 'flex',
                                alignItems: 'left',
                                padding: '25px 339px 28px 25px',
                                border: '0.5px solid #CED4D4',
                                backgroundColor: '#fff',
                                borderTopLeftRadius: '10px',
                                borderRight: 'none'
                            }}>
                                <span style={styles.proactiveMsgsBoxHeading2}>INCOMING MESSAGES</span>
                            </div>
                            <div style={{height: 'calc(100% - 110px)'}}>
                                <div style={{overflowY: 'auto', height: '100%'}}>
                                    { messageList &&
                                        <ProactiveMessageList
                                            msgList={messageList}
                                            selectedCheckboxes={selectedCheckboxes}
                                            setMessageLink={setMessageLink}
                                            setMessageSource={setMessageSource}
                                            loading={loading}
                                        />
                                    }
                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '0px 35px',
                                bottom: '0',
                                borderTop: '1px solid #CED2D0',
                            }}>
                                <span> {allMessagesCount ? `${messageCountStart} - ${messageCountEnd} of ${totalMsgsCount}` : ""}</span>
                                {totalPages > 1 &&
                                <div className="md-pagination numberless-pagination">
                                    <div style={{ fontSize: '14px'}}>
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageClassName="page-item-none"
                                            pageCount={totalPages}
                                            marginPagesDisplayed={0}
                                            disableInitialCallback
                                            pageRangeDisplayed={2}
                                            forcePage={pageParams.currentPage}
                                            onPageChange={page => handlePageSelect(page)}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                            breakClassName="page-item-none"
                                            previousClassName={`previous ${pageParams.currentPage === 1 ? "disabled" : ""}`}
                                            nextClassName={`next ${pageParams.currentPage === totalPages ? "disabled" : ""}`}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} lg={7} style={{width: '802px'}}>
                        <div style={{width: '100%', height: 'calc(100vh - 60px)', borderRadius: '10px'}}>
                            <div style={{
                                width: '100%',
                                height: '72px',
                                display: 'flex',
                                alignItems: 'left',
                                padding: '25px 642px 28px 25px',
                                border: '0.5px solid #CED4D4',
                                backgroundColor: '#fff',
                                borderTopRightRadius: '10px',
                                borderLeft: 'none'
                            }}>
                                <span style={styles.proactiveMsgsBoxHeading2}>CONVERSATION</span>
                            </div>
                            <div style={{height: 'calc(100% - 110px)'}}>
                                <div style={{overflowY: 'auto', height: '100%'}}>
                                    {
                                        showCarousel ?
                                            <ConversationCarousel
                                                InAppContent={Content}
                                                message={conversationThread[0]}
                                                loading={loadingConversation}
                                                isScrollComplete={true}
                                                usePadding={true}
                                            /> :
                                            <Content />
                                    }

                                </div>
                            </div>

                            <div>
                                <div style={{
                                    width: '100%',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    padding: '0px 35px 0px 20px',
                                    bottom: '0',
                                    borderTop: '1px solid #CED2D0',
                                }}>
                                    {conversationThread?.length > 0 &&
                                    <a className="viewInSourceLink" target="_blank" rel="noreferrer noopener nofollow" href={messageLink}>
                                        View in <span className="viewInSourceLink text-transform-capitalize">{messageSource ? messageSource : 'source platform'}</span>
                                    </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}
