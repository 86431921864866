import {useSelector} from "react-redux";
import _ from "lodash";
import {useMemo, useState} from "react";

export default function () {
    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingGenerateReplies;
    const [requested, setRequested] = useState(false);
    const repliesObj = proactive.replies;
    const replies = _.get(repliesObj, 'replies') || [];

    const requestSent = useMemo(() => {
        if (loading) setRequested(true);
        return requested
    }, [ loading ]) || requested;

    return { replies, loading, requested: requestSent };
}