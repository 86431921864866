import React, {Component, Fragment} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {isObjectEmpty} from "_helpers";
import CircularProgress from "@material-ui/core/CircularProgress";


/**
 * @author Chris Okebata
 */


const styles = {
  progressContainer: {
    width: '32px',
    height: '38px',
    zIndex: '10',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};


class Search extends Component{

  constructor(props){
    super(props);

    this.state = {
      searchField: '',
    };
  }

  /**
   * Search items
   * @param event
   */
  search = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  searchOnKeyEnter = (e) => {
    if(e.keyCode === 13) this.onSearch();
  };

  onSearch = () => {
    const {searchField} = this.state;
    if(!isObjectEmpty(searchField)) this.props.searchMessage(searchField);
  };

  render() {

    const { loadingSearch, loadingHtmlBody } = this.props;
    const { searchField } = this.state;
    const { progressContainer } = styles;
    const shouldDisable = loadingSearch || loadingHtmlBody;

    return(
      <Fragment>
        <div style={{width: '50%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <div style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
            <SearchIcon className={isObjectEmpty(this.state.searchField) ? 'color-grey-mid': 'color-green'}
                        fontSize={'default'} style={{marginLeft: '8px'}}/>
          </div>

          <input type="text" autoComplete="off" className='search-field font-15' name="searchField" value={searchField}
                 onChange={(event) => this.search(event)} onClick={(event) => { event.stopPropagation() }}
                 style={{width: '100%', padding: '8px 15px 8px 35px'}} placeholder={'Search'}
                 onKeyDown={this.searchOnKeyEnter}
                 disabled={shouldDisable}
          />
        </div>
        <div style={{marginLeft: '10px'}}>
          {
            loadingSearch &&
            <div style={progressContainer}>
              <CircularProgress size={20} style={{color: '#008751'}}/>
            </div>
          }

          <button className="btn btn-block btn-default font-15 color-green"
                  style={{width: '100px', padding: '8px 0', }}
                  disabled={shouldDisable}
                  onClick={this.onSearch}>
            Search
          </button>
        </div>

      </Fragment>
    )
  }
}

export { Search };