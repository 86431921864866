import { metricsConstants, alertMessages } from "_constants";
import { metricsService } from "_services";
import {Auth} from "../components/Authorization";
import {SnackBar} from "../components/common";
import _ from "lodash";

function getPerformanceMetrics (organizationId, startDate, endDate) {
  const request = () => ({
    type: metricsConstants.GET_PERFORMANCE_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_PERFORMANCE_SUCCESS,
    payload: { ...response }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_PERFORMANCE_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getPerformanceMetrics(organizationId, startDate, endDate)
      .then(response => {
        dispatch(success(response.data));
      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getQualityScoreMetrics(organizationId, startDate, endDate) {
  const request = () => ({
    type: metricsConstants.GET_QUALITY_SCORE_METRICS_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_QUALITY_SCORE_METRICS_SUCCESS,
    payload: { ...response }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_QUALITY_SCORE_METRICS_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getQualityScoreMetrics(organizationId, startDate, endDate)
      .then(response => {
        dispatch(success(response.data));
      }).catch((error) => {
      if (_.get(error, 'response.status') === 401) {
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getChannelsMetrics (organizationId, startDate, endDate) {
  const request = () => ({
    type: metricsConstants.GET_CHANNELS_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_CHANNELS_SUCCESS,
    payload: { ...response }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_CHANNELS_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getChannelsMetrics(organizationId, startDate, endDate)
      .then(response => {
        dispatch(success(response.data));
      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getExpertsTicketMetrics (organizationId, startDate, endDate) {
  const request = () => ({
    type: metricsConstants.GET_EXPERTS_TICKETS_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_EXPERTS_TICKETS_SUCCESS,
    payload: { ...response }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_EXPERTS_TICKETS_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getExpertsTicketMetrics(organizationId, startDate, endDate)
      .then(response => {
        dispatch(success(response.data));
      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getImpactMetrics (organizationId, startDate, endDate) {
  const request = () => ({
    type: metricsConstants.GET_IMPACT_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_IMPACT_SUCCESS,
    payload: { ...response }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_IMPACT_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getImpactMetrics(organizationId, startDate, endDate)
      .then(response => {
        dispatch(success(response.data));
      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getScheduleSummary (organizationId) {
  const request = () => ({
    type: metricsConstants.GET_SCHEDULE_SUMMARY_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_SCHEDULE_SUMMARY_SUCCESS,
    payload: { ...response }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_SCHEDULE_SUMMARY_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getScheduleSummary(organizationId)
      .then(response => {
        dispatch(success(response.data));
      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getScheduleDetails (organizationId) {
  const request = () => ({
    type: metricsConstants.GET_SCHEDULE_DETAILS_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_SCHEDULE_DETAILS_SUCCESS,
    payload: { ...response }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_SCHEDULE_DETAILS_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getScheduleDetails(organizationId)
      .then(response => {
        dispatch(success(response.data));
      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getResponseTimeDetails (params) {
  const request = () => ({
    type: metricsConstants.GET_RESPONSE_TIME_DETAILS_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_RESPONSE_TIME_DETAILS_SUCCESS,
    payload: { data: response[0]?.messages, count: response[0]?.count, params: response[0]?.params }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_RESPONSE_TIME_DETAILS_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getResponseTimeDetails(params)
        .then(response => {
          dispatch(success(response.data));
        }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getMetricsDetailConversation(params) {
  function request() {
    return { type: metricsConstants.GET_METRICS_DETAIL_CONVERSATION_REQUEST };
  }

  function success(responses, props) {
    return { type: metricsConstants.GET_METRICS_DETAIL_CONVERSATION_SUCCESS, payload: responses, props };
  }

  function failure(error) {
    return { type: metricsConstants.GET_METRICS_DETAIL_CONVERSATION_FAILURE, payload: error };
  }

  return (dispatch) => {
    dispatch(request());
    metricsService.getMetricsDetailConversation(params)
      .then(responses => {
        const { msgSource, handleTypeName, externalParentId } = params;
        dispatch(success(responses, { msgSource, handleTypeName, externalParentId }))
        if ( _.get(responses.data, 'messages')) {
            SnackBar.showMessage(alertMessages.METRICS_DETAIL_NO_CONVERSATION_FOUND)
        }
      }).catch((error) => {
        if(error?.responses?.status === 401){
          Auth.processLogout();
          return;
        }
        dispatch(failure(error));
        SnackBar.showMessage(alertMessages.GENERIC_ERROR)
      });
  };
}

function getQualityScoreMessages (params) {
  const request = () => ({
    type: metricsConstants.GET_QUALITY_SCORE_MESSAGES_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_QUALITY_SCORE_MESSAGES_SUCCESS,
    payload: { data: response[0]?.messages, count: response[0]?.count, params: response[0]?.params }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_QUALITY_SCORE_MESSAGES_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getQualityScoreMessages(params)
        .then(response => {
          dispatch(success(response.data));
        }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getPreSaleMessages (params) {
  const request = () => ({
    type: metricsConstants.GET_PRESALE_MESSAGES_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_PRESALE_MESSAGES_SUCCESS,
    payload: { data: response[0]?.messages, count: response[0]?.count, params: response[0]?.params }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_PRESALE_MESSAGES_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getPreSaleMessages(params)
        .then(response => {
          dispatch(success(response.data));
        }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function getCustomerAnsweredMessages (params) {
  const request = () => ({
    type: metricsConstants.GET_CUSTOMER_ANSWERED_MESSAGES_REQUEST
  });
  const success = (response) => ({
    type: metricsConstants.GET_CUSTOMER_ANSWERED_MESSAGES_SUCCESS,
    payload: { data: response[0]?.messages, count: response[0]?.count, params: response[0]?.params }
  });
  const failure = (error) => ({
    type: metricsConstants.GET_CUSTOMER_ANSWERED_MESSAGES_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    metricsService.getCustomerAnsweredMessages(params)
        .then(response => {
          dispatch(success(response.data));
        }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

function exportMetricsMessages(payload) {
  function request() {
    return { type: metricsConstants.EXPORT_METRICS_MESSAGES_REQUEST };
  }

  function success(responses) {
    return { type: metricsConstants.EXPORT_METRICS_MESSAGES_SUCCESS, payload: responses };
  }

  function failure(error) {
    return { type: metricsConstants.EXPORT_METRICS_MESSAGES_FAILURE, error: error };
  }

  return (dispatch) => {
    dispatch(request());

    metricsService.exportMetricsMessages(payload)
        .then(
            responses => dispatch(success(responses)),
            (error) => {
              dispatch(failure(error));
              SnackBar.showMessage(alertMessages.GENERIC_ERROR)
            },
        );
  };
}

function setPreSaleValue(params) {
  function request() {
    return { type: metricsConstants.SET_MESSAGE_PRESALE_VALUE_REQUEST };
  }

  function success(responses) {
    return { type: metricsConstants.SET_MESSAGE_PRESALE_VALUE_SUCCESS, payload: responses };
  }

  function failure(error) {
    return { type: metricsConstants.SET_MESSAGE_PRESALE_VALUE_FAILURE, error: error };
  }

  return (dispatch) => {
    dispatch(request());

    metricsService.setMessagePreSale(params)
        .then(
            responses => dispatch(success(params)),
            (error) => {
              dispatch(failure(error));
              SnackBar.showMessage(alertMessages.GENERIC_ERROR)
            },
        );
  };
}

function clearConversation() {
  function success() {
    return { type: metricsConstants.CLEAR_CONVERSATION };
  }

  return (dispatch) => {
    dispatch(success());
  };
}

export const metricsActions = {
  getPerformanceMetrics,
  getQualityScoreMetrics,
  getChannelsMetrics,
  getExpertsTicketMetrics,
  getImpactMetrics,
  getScheduleSummary,
  getScheduleDetails,
  getResponseTimeDetails,
  getMetricsDetailConversation,
  getQualityScoreMessages,
  getPreSaleMessages,
  getCustomerAnsweredMessages,
  exportMetricsMessages,
  setPreSaleValue,
  clearConversation
};