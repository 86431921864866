import _ from 'lodash';

export const getCurrentUser = () => {
  const user = _.get(localStorage, 'user');
  return user ? JSON.parse(user) : null;
};

export const currentUser = getCurrentUser();

/**
 * Returns request header containing content-type and bearer token.
 * @returns {object}
 */
export const authHeader = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_.get(getCurrentUser(), 'access_token') || ''}`,
    }
};

export const contentTypeHeader = () => {
    return {
        'Content-Type': 'application/json',
    }
};

export const fileUploadHeader = () => {
    return {
        'Authorization': `Bearer ${getCurrentUser().access_token}`
    }
};


