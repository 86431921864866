import {Fragment, useState} from "react";
import {Box, Grid} from "@mui/material";
import {Field} from "redux-form";
import SafeSearchSelect from "../../../common/SafeSearchSelect";
import RegionSelect from "../../../common/RegionSelect";
import RelevanceLanguageSelect from "../../../common/RelevanceLanguageSelect";
import SingleDatePicker from "../../../common/SingleDatePicker";

export default function YoutubeCampaignParams({
    defaultDate = null,
    queryParam,
    handleDateFilterChange,
    handleRelevanceLanguageSelectChange,
    handleSafeSearchSelectChange,
    handleRegionSelectChange,
}) {
    const selectPlaceholder = 'Choose';
    const [startDate, setStartDate] = useState(defaultDate);

    const handleStartDate = (date) => {
        setStartDate(date);
        handleDateFilterChange(date)
    }

    return(
        <Fragment>
            <Grid container item mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}} >
                <Grid container item xs={12} md={7}>
                    <Grid item xs={12} md={7}>
                        <Box mr={{md: '10px', lg: '10px'}} mt={{md: '0', lg: '0'}}>
                            <Box sx={styles.inputDesc}>Published after</Box>
                            <Field
                                name="publishedAfter"
                                component={SingleDatePicker}
                                handleChange={handleStartDate}
                                defaultDate={startDate}
                                withIcon
                                enabled={true}
                                isClearable={true}
                            />

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box ml={{md: '10px', lg: '10px'}} mr={{md: '20px', lg: '20px'}}
                             mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                            <Box sx={styles.inputDesc}>Safe search</Box>
                            <Field
                                name="safeSearch"
                                component={SafeSearchSelect}
                                placeholder={selectPlaceholder}
                                queryParam={queryParam}
                                handleFormSelectChange={handleSafeSearchSelectChange} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}} >
                <Grid container item xs={12} md={7}>
                    <Grid item xs={12} md={6}>
                        <Box mr={{md: '10px', lg: '10px'}} mt={{md: '0', lg: '0'}}>
                            <Box sx={styles.inputDesc}>Region</Box>
                            <Field
                                name="regionCode"
                                component={RegionSelect}
                                placeholder={selectPlaceholder}
                                queryParam={queryParam}
                                handleFormSelectChange={handleRegionSelectChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box ml={{md: '10px', lg: '10px'}} mr={{md: '20px', lg: '20px'}}
                             mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                            <Box sx={styles.inputDesc}>Relevance language</Box>
                            <Field
                                name="relevanceLanguage"
                                component={RelevanceLanguageSelect}
                                placeholder={selectPlaceholder}
                                queryParam={queryParam}
                                handleFormSelectChange={handleRelevanceLanguageSelectChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
}