import {Box} from "@mui/system";
import FilterCheckbox from "./FilterCheckbox";
import TextButton from "../../TextButton";
import {useState} from "react";

export default function FilterItem({item, index, checkedItemsValues, handleItemSelect}) {
    const [onlyButtonStyle, setOnlyButtonStyle] = useState({display: 'none'});

    const handleMouseEnter = (event) =>{
        setOnlyButtonStyle({display: 'block'});
    }

    const handleMouseLeave = (event) =>{
        setOnlyButtonStyle({display: 'none'});
    }

    return(
        <Box id={index} key={index} sx={style.itemRoot}
             // onMouseEnter={handleMouseEnter}
             // onMouseLeave={handleMouseLeave}
        >
            <Box sx={style.itemContainer}>
                <FilterCheckbox
                    label={item.name}
                    value={item.value}
                    index={index}
                    checkedItemsValues={checkedItemsValues}
                    onChange={handleItemSelect}
                />
            </Box>
            {/*<Box sx={style.onlyButtonContainer}>*/}
            {/*    <TextButton*/}
            {/*        label={'Only'}*/}
            {/*        sx={{...style.onlyButton, ...onlyButtonStyle}}*/}
            {/*        rootStyle={style.onlyButtonRoot}*/}
            {/*    />*/}
            {/*</Box>*/}
        </Box>
    )
}

const style = {
    itemRoot: {
        maxWidth: '400px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    itemContainer: {
        maxWidth: '400px', //@TODO: set to 350px when "Only" button is visible
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingLeft: '10px',
    },
    onlyButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45px'
    },
    onlyButton: {
        height: '20px',
    },
    onlyButtonRoot: {
        color: '#144042',
        fontSize: '14px',
        height: '15px',
        marginTop: '-3px',
        minWidth: '45px',
        border: '1px solid',
        py: '9px',

        ':hover': {
            color: '#3b7477',
            backgroundColor: 'unset'
        },
    }
}