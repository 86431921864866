import React, {useEffect} from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { Logo } from "../Utilities/ChatdeskLogo";
import {descriptions} from "../Utilities/Descriptions";
import SignupSteps from "../Signup/CompanySignup/SignupSteps";
import {footer} from "../Utilities/Footer";
import ScheduleCallSetup from "./ScheduleCallSetup";
import ReactGA from "react-ga";
import {GOOGLE_ANALYTICS_TRACKING_ID} from "_constants";


export default function ScheduleCall() {
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname);

    if(JSON.parse(localStorage.getItem('isOnboarding')))
    localStorage.setItem('currentOnboardingPage', '/signup/schedule');
  }, []);

  const user = JSON.parse(localStorage.getItem('user') || {});

  const {ChatdeskSymbol, ChatdeskWordMark} = Logo;
  const {ScheduleCallDescription, ScheduleCallDescriptionMobile} = descriptions;
  const {ScheduleCallFooter} = footer;

  return(
    <Grid container height={{xs: 'auto', sm: 'auto', md: '100vh', lg: '100vh'}}>

      {/**************** Parent Left *****************/}
      <Grid item xs={12} sm={12} md={6} lg={6}
            order={{xs: 2, sm: 2, md: 1, lg: 1}}
            height={{xs: 'fit-content', sm: 'fit-content', md: 'auto', lg: 'auto'}}
            sx={{backgroundColor: '#EFEAE7', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <Box item xs={12} sm={12} md={12} lg={12}
             display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
             px={{md: '35px', lg: '50px'}}
             sx={{backgroundColor: '#EFEAE7', height: '600px'}}>
          <ChatdeskSymbol/>
          <Box sx={{height: 'auto', marginTop: '70px'}}>
            <ScheduleCallDescription name={user ? user.firstName : undefined} />
          </Box>
        </Box>
        <ScheduleCallFooter/>
      </Grid>

      {/**************** Parent right *****************/}
      <Grid item xs={12} sm={12} md={6} lg={6}
            order={{xs: 1, sm: 1, md: 2, lg: 2}}
            px={{xs: '20px', sm: '20px', md: '35px', lg: '50px'}}
            height={{xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto'}}
            sx={{backgroundColor: '#ffffff'}}>

        <Box sx={{height: 'auto'}}>
          <Box mt={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}
               sx={{display: 'flex'}}>
            <ChatdeskWordMark/>
            <SignupSteps
              isGetStartedActive={true}
              isConnectChannelActive={true}
              isScheduleCallActive={true}/>
          </Box>
          <ScheduleCallDescriptionMobile name={user ? user.firstName : undefined} />
          <ScheduleCallSetup />
        </Box>
      </Grid>
    </Grid>
  )
}