import {metaOEmbedConstants} from "_constants";

const INITIAL_STATE = {
    metaOEmbed: {},
    loadingMetaOEmbed: false,
    metaOEmbedError: ''
};

export function oEmbedReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case metaOEmbedConstants.GET_META_OEMBED_REQUEST:
            return {...state, metaOEmbedError: '', metaOEmbed: {}, loadingMetaOEmbed: true};
        case metaOEmbedConstants.GET_META_OEMBED_SUCCESS:
            return {...state, metaOEmbed: action.payload, loadingMetaOEmbed: false};
        case metaOEmbedConstants.GET_META_OEMBED_FAILURE:
            return {...state, metaOEmbedError: action.payload, loadingMetaOEmbed: false};
        default:
            return state
    }
}