import React from "react";
import {Box} from "@mui/material";
import {Field, reduxForm} from "redux-form";
import BasicFormTextField from "../../../common/BasicFormTextField";
import {required} from "_helpers";
import ActionButton from "../../../common/ActionButton";
import {connect} from "react-redux";
import moment from "moment/moment";
import BasicModal from "../../Onboarding/Utilities/BasicModal";
import TimezonePickerComponent from "./TimezonePicker";

function EditUserProfile(props){
    const {openEditProfile, handleCloseEditProfile, handleSubmit, handleSave} = props

    return(
        <BasicModal
            open={openEditProfile}
            handleClose={handleCloseEditProfile}
            title={"Edit Profile"}>

            <form name="form" onSubmit={handleSubmit(handleSave)}>
                <Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>First Name</Box>
                        <Field
                            type="text"
                            name="firstName"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Last Name</Box>
                        <Field
                            type="text"
                            name="lastName"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Phone Number</Box>
                        <Field
                            type="text"
                            name="phoneNumber"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Email</Box>
                        <Field
                            type="text"
                            name="username"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Address</Box>
                        <Field
                            type="text"
                            name="address"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>City</Box>
                        <Field
                            type="text"
                            name="city"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>State</Box>
                        <Field
                            type="text"
                            name="state"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Country</Box>
                        <Field
                            type="text"
                            name="country"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Timezone</Box>
                        <Field
                            type="text"
                            name="timezone"
                            component={TimezonePickerComponent}
                            validate={required}
                        />
                    </Box>
                </Box>

                <Box sx={styles.footer}>
                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Cancel'}
                            buttonBordered={true}
                            useBorderColor="#368C86"
                            type={"button"}
                            action={handleCloseEditProfile}/>
                    </Box>
                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Save'}/>
                    </Box>
                </Box>
            </form>
        </BasicModal>
    )
}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px',
    },
    footer: {
        display: 'flex',
        gap: '18px',
        justifyContent: 'flex-end',
        paddingTop: '20px'
    },
};

const formConfig = {
    form: 'EditUserForm',
};
EditUserProfile = reduxForm(formConfig)(EditUserProfile)

EditUserProfile = connect(
    state => ({
        initialValues: {
            ...state.users.user,
            dateCreated: moment(state.users.user.dateCreated).format('MMMM D, YYYY'),
        }
    }),
)(EditUserProfile)
export default EditUserProfile
