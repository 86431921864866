import React from "react";
import {Button} from "react-bootstrap";
import Stack from "@mui/material/Stack";

const MetricsDetailFilter = ({ filterDropDown, onFilterFormSubmit, handleFilterReset }) => {

    const renderFilterButtons = () => {
        return (
            <div>
                <div className="reset-button">
                    <Button onClick={handleFilterReset} className="resetFilters">Reset filters</Button>
                </div>
                <div className="filter-submit-buttons">
                    <Button onClick={onFilterFormSubmit} type="submit" className="applyFilters">Apply filters</Button>
                </div>
            </div>
        )
    }

    return (
        <Stack spacing={2} className="response-filter-box">
            <span className="filter-by">Filter by</span>
            <div style={{ width: '100%', height: 'auto'}}>
                {filterDropDown}
            </div>
            <hr/>
           {renderFilterButtons()}
        </Stack>
    )
}

export {MetricsDetailFilter}