import {Box} from "@mui/system";
import React from "react";
import iconThumbsUp from "components/images/icons/icon_thumbs_up.png";

export default function AppreciationContainer () {

    return(
        <Box sx={styles.container}>
            <Box>
                <Box sx={styles.imgContainer}>
                    <img src={iconThumbsUp} alt="thumbs up icon" style={styles.img}/>
                </Box>
                <Box sx={styles.gotIt}>
                    Got it!
                </Box>
                <Box sx={styles.thanks}>
                    Thanks for letting us know that this message could not be resolved
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        height: '320px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imgContainer: {
        maxHeight: '85px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '25px'
    },
    img: {
        maxHeight: '85px',
        borderRadius: '5px',
    },
    gotIt: {
        fontSize: '24px',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        letterSpacing: '-0.02em',
        textAlign: 'center',
        marginBottom: '5px'
    },
    thanks: {
        maxWidth: '450px',
        fontSize: '24px',
        color: '#0C2728',
        fontFamily: 'LarsseitRegular',
        letterSpacing: '-0.02em',
        textAlign: 'center'
    }
}