import {Box, Grid} from "@mui/material";
import SingleDatePicker from "../../../common/SingleDatePicker";
import React, {useState} from "react";

export default function CampaignDateFilter({
                                               handleDateFilterChange,
                                               defaultDate = null
                                           }) {

    const [startDate, setStartDate] = useState(defaultDate);

    const handleStartDate = (date) => {
        setStartDate(date);
        handleDateFilterChange(date)
    }

    return (
        <Grid container item xs={12} md={7}>
            <Grid item xs={12} md={12} mr={{md: '10px', lg: '10px'}}>
                <Box mr={{md: '10px', lg: '10px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                    <Box sx={styles.inputDesc}>Filter posts by date</Box>
                    <SingleDatePicker
                        handleChange={handleStartDate}
                        defaultDate={startDate}
                        withIcon
                        enabled={true}
                        isClearable={true}
                    />
                </Box>
            </Grid>
        </Grid>
    )

}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
}