import React, { Component } from "react";
import { stylesTable } from 'components/Inbox/StylesTable'
import { Paper, Table, TableBody, TableCell, TableRow, Typography, withStyles } from '@material-ui/core';
import PropTypes from "prop-types";
import moment from "moment";
import htmlParser from "he";
import ArrowGreenIcon from 'components/images/arrow_green_right.svg';
import ArrowBlueIcon from 'components/images/arrow_blue_right.svg';
import {stripEmTagFromHtml, stripTrailingQuestionMarkFromHtmlEntity} from "_helpers";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import { HtmlTooltip } from 'components/common';
import Checkbox from "@material-ui/core/Checkbox";
import {userRoles as roles} from "../../_constants";


/**
 * @author Okpala Oluchukwu Chioma <oluchukwu@chatdesk>
 */
class SearchMessagesTable extends Component {
    render() {
        const { messages, classes, resultCount, exportCSV, exporting, blacklistCheckedMessages, handleMessageCheck,
          selectedMessages, handleAllMessagesCheck, isAllChecked, showSelectAllCheckbox, blacklistAllMessages } = this.props;
        const zone_name =  moment.tz.guess();
        const timezone = moment.tz(zone_name).zoneAbbr();
        const loggedInUser = JSON.parse(localStorage.getItem("user"));
        const currentUserRoles = loggedInUser && loggedInUser.roles && loggedInUser.roles[0];
        const isBillingAdmin = loggedInUser && currentUserRoles === roles.BILLING_ADMIN_ROLE;
        return (
            <div className="search-messages-table">
                <div style={{marginBottom: '30px', marginTop: '10px' }}>{resultCount ? resultCount : 0} results</div>
                <Paper className={classes.root}>
                    {messages.length === 0 &&
                    <div style={{textAlign: 'center', fontSize: '20px', padding: '30px'}}>
                        There are no messages that match your search
                    </div>
                    }
                    {messages.length > 0 &&
                    <Table className={classes.table}>
                        <thead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <Typography className={classes.typography15}>Message</Typography>
                                </TableCell>
                                <TableCell style={{width: '80px'}}>
                                    <Typography className={classes.typography15}>Direction</Typography>
                                </TableCell>
                                <TableCell style={{width: '180px'}} className={classes.tableCell}>
                                    <Typography className={classes.typography15}>Date</Typography>
                                </TableCell>
                                <TableCell style={{width: '100px'}} className={classes.tableCell}>
                                  {
                                    showSelectAllCheckbox && <div>
                                      <Checkbox
                                        onChange={handleAllMessagesCheck}
                                        checked={isAllChecked}
                                        style={
                                          isAllChecked ?
                                            {color: '#008751', padding: "7px"} :
                                            {color: '#c8c8c8', padding: "7px"}
                                        }
                                      />
                                    </div>
                                  }
                                </TableCell>
                            </TableRow>
                        </thead>
                        <TableBody>
                            {messages.map((message, index) => {
                                let highlightPhrase = stripEmTagFromHtml(message.phrase);
                                let text = htmlParser.decode(stripTrailingQuestionMarkFromHtmlEntity(message.messageText));

                                return (
                                    <TableRow key={index} className={classes.tableRow}>
                                        <HtmlTooltip
                                            title={
                                                <div style={{width: 'fit-content', maxWidth: '700px', padding: '10px'}}>
                                                    <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                                                        {text}
                                                    </Typography>
                                                </div>
                                            }
                                            placement={'bottom'}
                                            style={{opacity: 1}}
                                            enterDelay={700}
                                        >
                                            <TableCell className={classes.tableCell}>
                                                <Link to={`messages/${message.messageId}?platform=trends`} className="btn-link tms-no-underline">
                                                    <Typography className={classes.typography14}>
                                                     <span className="font-14 truncate-string">{highlightPhrase}</span>
                                                    </Typography>
                                                </Link>
                                            </TableCell>
                                        </HtmlTooltip>
                                        <TableCell className={classes.tableCell}>
                                            {message.direction === "Outgoing" &&
                                            <span>
                                                <img className="arrow-icon-outgoing" src={ArrowBlueIcon} title="outgoing message" alt="outgoing message" />
                                            </span>
                                            }
                                            {message.direction === "Incoming" &&
                                            <span>
                                                <img className="arrow-icon-incoming" src={ArrowGreenIcon} title="incoming message" alt="incoming message" />
                                            </span>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography className={classes.typography14}>
                                                {moment(message.messageDate).format('YYYY MMM DD hh:mm A z')}{timezone}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                          {message.direction === "Outgoing" && !message.hasDetractor &&
                                            <Checkbox
                                              onChange={handleMessageCheck}
                                              value={message.messageId}
                                              checked={selectedMessages.has(message.messageId)}
                                              style={ selectedMessages.has(message.messageId) ?
                                                {color: '#008751', padding: "7px"} :
                                                {color: '#c8c8c8', padding: "7px"}
                                              }
                                            />
                                          }
                                          {message.direction === "Outgoing" && message.hasDetractor &&
                                            <div style={{textAlign: 'left', display: 'flex'}}>
                                              <span className="font-13 color-grey-mid">Blacklisted</span>
                                              <span className="fa fa-check color-green margin-left-5"/>
                                            </div>
                                          }
                                        </TableCell>
                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                    }
                </Paper>
                {
                    messages.length > 0 &&
                    <Button
                        onClick={blacklistCheckedMessages}
                        className="btn btn-success font-15"
                        type="submit"
                        style={{width: '150px', padding: '15px', marginTop: '15px', float: 'right'}}
                    >
                        Blacklist Selections
                    </Button>
                }
                {
                    messages.length > 0 &&
                    <Button
                        onClick={exportCSV}
                        className="btn btn-success font-15"
                        type="submit"
                        style={{width:'100px', padding: '15px', marginTop: '15px', float: 'right', marginRight: '10px'}}>
                        {exporting ? 'Exporting...' : 'Export'}
                    </Button>
                }

                {(isBillingAdmin && messages.length > 0) &&
                <Button
                    onClick={blacklistAllMessages}
                    className="btn btn-success font-15"
                    type="submit"
                    style={{width:'170px', padding: '15px', marginTop: '15px', float: 'right', marginRight: '10px'}}>
                    Blacklist All Messages
                </Button>
                }
            </div>
        )
    }
}

SearchMessagesTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const StyledMessagesTable = withStyles(stylesTable)(SearchMessagesTable);

export { StyledMessagesTable as SearchMessagesTable }