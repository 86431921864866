import {Fragment, useState} from "react";
import {Box} from "@mui/system";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {cannotResolveOptionType} from "_constants";
import InternalNoteEditor from "./InternalNoteEditor";
import {submitReason} from "../CannotResolveUtils";
import InternalNoteFilterSelect from "./InternalNoteFilterSelect";
import ActionButton from "../../../../../../common/ActionButton";
import {buildAssigneeObject, buildCannotResolvePayload} from "../../../../Utilities/VoteUtils";

export default function InternalNoteContent({message, handleClose, handleOptionSubmitted}) {
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [assignee, setAssignee] = useState({});
    const optionType = cannotResolveOptionType.INTERNAL_NOTE;

    const handleTextChange = (editorText) => {
        setText(editorText);
    }

    const handleSubmit = () => {
        if(_.isEmpty(text)) return;
        const assigneeObj = buildAssigneeObject(assignee);
        const payload = buildCannotResolvePayload(message, optionType, {assignee: assigneeObj, text});
        submitReason(dispatch, payload, optionType);
        handleClose();
        handleOptionSubmitted(true);
    }

    const handleAssigneeSelection = (value) => {
        setAssignee(value)
    }

    return(
        <Fragment>
            <Box sx={{mb: '20px'}}>
                <InternalNoteFilterSelect
                    message={message}
                    onChange={handleAssigneeSelection}
                />
            </Box>

            <InternalNoteEditor
                isEmail={false} //this makes the editor out a plain text instead of html
                handleTextChange={handleTextChange}
            />

            <Box sx={style.buttonContainer}>
                <Box sx={style.cancelButtonContainer}>
                    <ActionButton
                        label={'Cancel'}
                        action={handleClose}
                        buttonBordered
                        useBorderColor={'#07857C'}
                        withIcon={false}
                        sx={style.cancelButton}
                    />
                </Box>
                <Box>
                    <ActionButton
                        label={'Submit'}
                        action={handleSubmit}
                        withIcon={false}
                        sx={style.sendButton} />
                </Box>
            </Box>
        </Fragment>
    )
}

const style = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '15px'
    },
    cancelButtonContainer: {
        width: '84px',
        marginRight: '15px'
    },
    cancelButton: {
        height: '35px',
    },
    sendButton: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}