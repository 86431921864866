import {SERVER_URL} from 'config';
import {authHeader, handleHttpResponse} from '_helpers';

export const applicationsService = {
    getApplicationDetails
};

function getApplicationDetails(applicationId) {
    return fetch(`${SERVER_URL}/api/applications/${applicationId}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}