import React from "react";
import {Box} from "@mui/system";
import privacyLockIcon from "components/images/icons/privacy_lock.svg";


function GetStartedDescription(){
  return(
    <Box>
      <p style={styles.descParagraphHeader}>
				Let’s get your free trial started.
			</p>
			<p style={{ ...styles.descParagraphBody, marginTop: '20px' }}>
				Fill out your name and basic company information.
			</p>
    </Box>
  )
}

function GetStartedDescriptionMobile(){
  return(
    <Box mt={{xs: '50px', sm: '50px'}}
         display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none'}}>
      <p style={styles.descParagraphHeaderMobile}>
        Let’s get your free trial started.
      </p>
    </Box>
  )
}

function ConnectChannelDescription(){
  return(
    <Box>
      <p style={styles.descParagraphHeader}>
        Connect a channel
      </p>
      <p style={{...styles.descParagraphBody, marginTop: '20px'}}>
        Connect your first channel, and Chatdesk will take care of the rest.
      </p><br/>
      <p style={styles.descParagraphBody}>
        Our algorithm will analyze your previous support messages to create an ever-evolving, on brand knowledge base.
        Tickets get cleared faster and more successfully, all with less work from your team.
      </p><br/>
      <p style={styles.descParagraphBody}>
        To see the magic happen, select your first channel.
      </p>
      <Box sx={{display: 'flex', marginTop: '60px', alignItems:'center',}}>
        <Box sx={{width: '38px', height: '38px'}}>
          <img src={privacyLockIcon} style={{maxWidth: '100%'}} alt="privacy lock icon"/>
        </Box>
        <Box sx={{marginLeft: '5px'}}>
          <Box fontSize={{xs: '18px', sm: '18px', md: '18px', lg: '18px'}}
               className="larsseit-medium"
               style={{color: '#0C2728', lineHeight: '120%'}}>
            Chatdesk privacy
          </Box>
          <Box fontSize={{xs: '18px', sm: '18px', md: '18px', lg: '18px'}}
               className="larsseit-regular"
               style={{color: '#0C2728', lineHeight: '120%'}}>
            We follow the privacy guidelines from your channel provider
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function ConnectChannelDescriptionMobile(){
  return(
    <Box mt={{xs: '50px', sm: '50px'}}
         display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none'}}>
      <p style={styles.descParagraphHeaderMobile}>
        Connect a channel
      </p>
    </Box>
  )
}

function ScheduleCallDescription({ name = undefined }){
  return(
    <Box>
      <p style={styles.descParagraphHeader}>
        Welcome to Chatdesk{name ? `, ${name}` : ''}! 
      </p>
      <p style={{...styles.descParagraphBody, marginTop: '20px'}}>
        Let’s connect. Pick a time that works for you for your onboarding session. Nothing for you to do - just sit back and relax. Our Client Success Managers will take care of everything from here.
      </p><br/>
      <p style={styles.descParagraphBody}>
        If you don’t see a time that works for you, shoot us a note at info@chatdesk.com.
      </p>
    </Box>
  )
}

function ScheduleCallDescriptionMobile({ name = undefined }){
  return(
    <Box mt={{xs: '50px', sm: '50px'}}
         display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none'}}>
      <p style={styles.descParagraphHeaderMobile}>
        Welcome to Chatdesk{name ? `, ${name}` : ''}! 
      </p>
      <p style={{ ...styles.descParagraphBodyMobile, marginTop: '12px' }}>
        Let’s connect. Pick a time that works for you for your onboarding session.
      </p>
    </Box>
  )
}

export const descriptions = {
  GetStartedDescription,
  GetStartedDescriptionMobile,
  ConnectChannelDescription,
  ConnectChannelDescriptionMobile,
  ScheduleCallDescription,
  ScheduleCallDescriptionMobile,
};

const styles = {
  descParagraphHeader: {
    color: '#0C2728',
    fontSize: '32px',
    lineHeight: '120%',
    fontFamily: 'LarsseitMedium'
  },
  descParagraphBody: {
    color: '#0C2728',
    fontSize: '24px',
    lineHeight: '120%',
    fontFamily: 'LarsseitRegular'
  },
  descParagraphHeaderMobile: {
    color: '#0C2728',
    fontSize: '28px',
    lineHeight: '100%',
    fontFamily: 'LarsseitMedium'
  },
  descParagraphBodyMobile: {
    color: '#0C2728',
    fontSize: '18px',
    lineHeight: '120%',
    fontFamily: 'LarsseitRegular'
  },
};