import {chatdeskUpdatesActions} from "_actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import React, {Component} from "react";
import ChatdeskUpdatesTable from "./Table";
import {isObjectEmpty} from "_helpers";
import {Loading} from "components/common";
import moment from "moment";

class ChatdeskUpdatesList extends Component {

    componentDidMount(){
        const fromDate = moment().subtract(7, 'd').format("YYYY-MM-DD HH:mm:ss")
        const params = { isActive: true, from: fromDate}
        this.props.getAllUpdates(params)
    }

    render(){
        const {updatesList, loading} = this.props

        if (loading || isObjectEmpty(updatesList)){
            return <Loading/>
        }

        const {data} = updatesList

        return (
            <div className="container-pane">
                <div className="container" style={{minWidth: '1200px'}}>
                    <div className="-align-left text-right" style={{marginBottom: '15px'}}>
                        <Link to='/companyUpdates/create'>
                            <button className='btn btn-primary'>Create Notification</button>
                        </Link>
                    </div>

                    <div>
                        <ChatdeskUpdatesTable
                            data={data}
                        />

                    </div>
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getAllUpdates: (props) => dispatch(chatdeskUpdatesActions.getAll(props)),
    }
}

const mapStateToProps = (state) => {
    const {chatdeskUpdates: {updatesList, loading}} = state;

    return {
        updatesList,
        loading
    }

}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ChatdeskUpdatesList);
export {connectedComponent as ChatdeskUpdatesList}