/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 15/03/2019 : 1:51 PM
 */

export default {
    rootWrapper: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        top: '120px',
        padding: '0 10%'
    },
    containerElement: {
        padding: '100px 0',
        textAlign: 'left',
        maxWidth: '1000px',
        height: 'auto',
        position: 'relative',
        margin: '0 auto'
    },
    formContainer: {
        width: '550px',
        maxWidth: '600px',
        borderRight: '1px solid #dee2e0',
        display: 'inline-block'
    },
    inputContainer: {
        padding: '18px 0 0 0',
        maxWidth: '500px',
        margin: '0',
        display: 'flex'
    },
    textContainer: {
        width: 'calc(100% - 600px)',
        float: 'right',
        display: 'inline-block',
        textAlign: 'center',
        padding: '10px'
    },
    inputElement: {
        background: '#fff',
        cursor: 'pointer'
    }
};

