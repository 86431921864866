import React, {Fragment} from "react";
import logoZendesk from 'components/images/logos/logo_zendesk.svg'
import useZendeskSetup from './useZendeskSetup'
import ChannelCard from '../../ChannelCard'
import ZendeskSetupModal from './ZendeskSetupModal'

export default function ZendeskEntry() {
	const { initiateZendeskSetup, zendeskSetupOpen, handleZendeskSetupClose, handleZendeskSetupOpen } = useZendeskSetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoZendesk}
				label={'Zendesk'}
				description={'Clear your email ticket backlog and track the CSAT of your Chatdesk team. Zendesk is one of our most popular integrations.'}
				onClick={initiateZendeskSetup}
			/>

			<ZendeskSetupModal
				open={zendeskSetupOpen}
				handleOpen={handleZendeskSetupOpen}
				handleClose={handleZendeskSetupClose}
			/>
		</Fragment>
	)
}