import React from "react";
import {Box, Grid} from "@mui/material";
import BasicFormTextField from "../../../common/BasicFormTextField";
import StatusSelect from "../Utilities/StatusSelect";
import {Field, reduxForm} from 'redux-form'
import {useDispatch, useSelector} from "react-redux";
import AnchorButton from "../../../common/AnchorButton";
import ActionButton from "../../../common/ActionButton";
import {companyResourcesActions} from "_actions";
import _ from "lodash";
import { connect } from 'react-redux';
import {required} from "_helpers";
import useGetResource from "../Utilities/hooks/useGetResource"
import CircularProgress from "@mui/material/CircularProgress";

function UpdateResourceForm({ handleSubmit }) {
    const dispatch = useDispatch();
    const selectPlaceholder = 'Choose';

    const { resourceObj, loading } = useGetResource();

    const resource = useSelector(state => state.companyResources);
    const loadingUpdateResource = resource.loadingUpdateResource;

    const handleUpdateResource = (values) => {
        if(_.isEmpty(values)) return;
        const id = _.get(resourceObj, 'id');
        const payload = {id, values}
        dispatch(companyResourcesActions.updateResource(payload));
    };

    if(loading && _.isEmpty(resourceObj)){
        return (
            <Box m={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}>
                <CircularProgress size={25} sx={{ color: '#07857C', marginLeft: '10px' }}/>
            </Box>
        )
    }
    if(!loading && _.isEmpty(resourceObj)){
        return (
            <Box m={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}>
                <Box sx={styles.desc}>No data found</Box>
            </Box>
        )
    }

    return(
        <>
            <form name="form" onSubmit={handleSubmit(handleUpdateResource)} autoComplete="off">
                <Box sx={{padding: '25px 20px 60px 20px'}}>
                    <Box mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Box sx={styles.inputDesc}>Resource name</Box>
                        <Field
                            name="name"
                            component={BasicFormTextField}
                            placeholder={'Enter name'}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Box sx={styles.inputDesc}>Resource link</Box>
                        <Field
                            name="link"
                            component={BasicFormTextField}
                            placeholder={'Enter link'}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                        <Box sx={styles.inputDesc}>Status</Box>
                        <Field
                            name="isActive"
                            component={StatusSelect}
                            placeholder={selectPlaceholder}
                            defaultValue={_.get(resourceObj, 'isActive')}
                        />
                    </Box>
                </Box>
                <Box sx={styles.footer}>
                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Save'}
                            disabled={loadingUpdateResource}
                        />
                    </Box>
                    <Box sx={{width: '100px', ml: '18px', height: '20px'}}>
                        <AnchorButton
                            label={'Cancel'}
                            link={'/company-resources'}
                            buttonBordered={true}
                        />
                    </Box>
                </Box>
            </form>
        </>
    )
}

const styles = {
    hero: {
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        mb: { xs: '24px', sm: '40px', },
    },
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    desc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '16px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    footer: {
        borderTop: '1px solid #CED4D4',
        padding: '12px 20px',
        display: 'flex'
    },
}

function validate(values) {
    const errors = {};
    const {isActive } = values;
    if(_.isEmpty(_.toString(isActive)) || isActive === 'none') errors.isActive = 'invalid status';
    return errors;
}

const formConfig = {
    form: 'UpdateResourceForm',
    enableReinitialize: true,
    validate
};

UpdateResourceForm = reduxForm(formConfig)(UpdateResourceForm)
UpdateResourceForm = connect(state => (
    {
        initialValues: {
            name: _.get(state, 'companyResources.resource.name'),
            link: _.get(state, 'companyResources.resource.link'),
            isActive: _.get(state, 'companyResources.resource.isActive'),
        }
    }),
)(UpdateResourceForm);

export default UpdateResourceForm;