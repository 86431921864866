import { Box, Button } from '@mui/material';

export default function TextButton({ label, action, disabled, startIcon, endIcon, sx, rootStyle, type }) {
    return(
        <Box sx={{height: '40px', ...sx}}>
            <Button
                sx={{...styles.root, ...rootStyle}}
                type={type}
                onClick={action}
                variant="text"
                disableElevation
                disabled={disabled}
                disableRipple={true}
                startIcon={startIcon}
                endIcon={endIcon}>
                {label}
            </Button>
        </Box>
    )
}

const styles = {
    root: {
        fontSize: '16px',
        padding: '5px 0',
        color: '#07857C',
        fontFamily: 'LarsseitMedium',
        lineHeight: 1,
        textTransform: 'none',
        borderRadius: '50px',
        letterSpacing: '-0.001em',
        ':hover': {
            color: '#34756f',
            backgroundColor: 'unset'
        },
        ':disabled': {
            color: '#368C8680',
        },
        ':active': {
            outline: '0',
            color: '#54afa9',
        },
    }
}