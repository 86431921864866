import React, {useEffect} from "react";
import {gorgiasAuthActions} from "_actions";
import {useDispatch} from "react-redux";
import {CLIENT_URL} from "config";

export default function useGorgiasSetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const performAction = () => {
    handleOpen();
  };

  useEffect(() => {
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const authCode = values.get('code');
    const partner = values.get('partner');
    const account = values.get('account');

    if(partner == 'gorgias' && authCode) {
      const params = {code: authCode, redirectUrl: `${CLIENT_URL}/company/connectchannels?account=${account}&partner=gorgias`, account: values.get('account')};
      submitToken(params);
    }
  }, []);

  const submitToken = (params) => {
    dispatch(gorgiasAuthActions.generateAccessToken(params));
  };

	return {
		initiateGorgiasSetup: performAction,
		gorgiasSetupOpen: open, 
		handleGorgiasSetupOpen: handleOpen,
		handleGorgiasSetupClose: handleClose
	};
}