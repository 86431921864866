import {metricsConstants} from "_constants";

/**
 * @author Chris Okebata
 */

const INITIAL_STATE = {
  performanceMetrics: {},
  loadingPerformanceMetrics: true,
  performanceMetricsError: '',

  channelsMetrics: {},
  loadingChannelsMetrics: true,
  channelsMetricsError: '',

  expertsTicketMetrics: {},
  loadingExpertsTicketMetrics: true,
  expertsTicketMetricsError: '',

  impactMetrics: {},
  loadingImpactMetrics: true,
  impactMetricsError: '',

  scheduleSummary: {},
  loadingScheduleSummary: true,
  scheduleSummaryError: '',

  scheduleDetails: {},
  loadingScheduleDetails: true,
  scheduleDetailsError: '',

  responseTimesDetails: [],
  loadingResponseTimesDetails: true,
  responseTimesDetailsError: '',

  metricsDetailConversation: [],
  loadingMetricsDetailConversation: true,
  metricsDetailConversationError: '',
  csatList: [],
  messageProps: {},

  qualityScoreMessages: [],
  loadingQualityScoreMessages: true,
  qualityScoreMessagesError: '',

  preSaleMessages: [],
  loadingPreSaleMessages: true,
  preSaleMessagesError: '',

  customerAnsweredMessages: [],
  loadingCustomerAnsweredMessages: true,
  customerAnsweredMessagesError: '',

  exporting: false,
};

export function metricsReducer(state = INITIAL_STATE, action) {

  switch (action.type) {
    case metricsConstants.GET_PERFORMANCE_REQUEST:
      return { ...state, loadingPerformanceMetrics: true };
    case metricsConstants.GET_PERFORMANCE_SUCCESS:
      return { ...state, performanceMetrics: action.payload, loadingPerformanceMetrics: false };
    case metricsConstants.GET_PERFORMANCE_FAILURE:
      return { ...state, performanceMetricsError: action.payload, loadingPerformanceMetrics: false };

    case metricsConstants.GET_QUALITY_SCORE_METRICS_REQUEST:
      return { ...state, loadingQualityScoreMetrics: true };
    case metricsConstants.GET_QUALITY_SCORE_METRICS_SUCCESS:
      return { ...state, qualityScoreMetrics: action.payload, loadingQualityScoreMetrics: false };
    case metricsConstants.GET_QUALITY_SCORE_METRICS_FAILURE:
      return { ...state, qualityScoreMetricsError: action.payload, loadingQualityScoreMetrics: true };

    case metricsConstants.GET_CHANNELS_REQUEST:
      return { ...state, loadingChannelsMetrics: true };
    case metricsConstants.GET_CHANNELS_SUCCESS:
      return { ...state, channelsMetrics: action.payload, loadingChannelsMetrics: false };
    case metricsConstants.GET_CHANNELS_FAILURE:
      return { ...state, channelsMetricsError: action.payload, loadingChannelsMetrics: false };

    case metricsConstants.GET_EXPERTS_TICKETS_REQUEST:
      return { ...state, loadingExpertsTicketMetrics: true };
    case metricsConstants.GET_EXPERTS_TICKETS_SUCCESS:
      return { ...state, expertsTicketMetrics: action.payload, loadingExpertsTicketMetrics: false };
    case metricsConstants.GET_EXPERTS_TICKETS_FAILURE:
      return { ...state, expertsTicketMetricsError: action.payload, loadingExpertsTicketMetrics: false };

    case metricsConstants.GET_IMPACT_REQUEST:
      return { ...state, loadingImpactMetrics: true };
    case metricsConstants.GET_IMPACT_SUCCESS:
      return { ...state, impactMetrics: action.payload, loadingImpactMetrics: false };
    case metricsConstants.GET_IMPACT_FAILURE:
      return { ...state, impactMetricsError: action.payload, loadingImpactMetrics: false };

    case metricsConstants.GET_SCHEDULE_SUMMARY_REQUEST:
      return { ...state, loadingScheduleSummary: true };
    case metricsConstants.GET_SCHEDULE_SUMMARY_SUCCESS:
      return { ...state, scheduleSummary: action.payload, loadingScheduleSummary: false };
    case metricsConstants.GET_SCHEDULE_SUMMARY_FAILURE:
      return { ...state, scheduleSummaryError: action.payload, loadingScheduleSummary: false };

    case metricsConstants.GET_SCHEDULE_DETAILS_REQUEST:
      return { ...state, loadingScheduleDetails: true };
    case metricsConstants.GET_SCHEDULE_DETAILS_SUCCESS:
      return { ...state, scheduleDetails: action.payload, loadingScheduleDetails: false };
    case metricsConstants.GET_SCHEDULE_DETAILS_FAILURE:
      return { ...state, scheduleDetailsError: action.payload, loadingScheduleDetails: false };

    case metricsConstants.GET_RESPONSE_TIME_DETAILS_REQUEST:
      return { ...state, loadingResponseTimesDetails: true };
    case metricsConstants.GET_RESPONSE_TIME_DETAILS_SUCCESS:
      return { ...state, responseTimesDetails: action.payload, loadingResponseTimesDetails: false };
    case metricsConstants.GET_RESPONSE_TIME_DETAILS_FAILURE:
      return { ...state, responseTimesDetailsError: action.payload, loadingResponseTimesDetails: false };

    case metricsConstants.GET_METRICS_DETAIL_CONVERSATION_REQUEST:
      return { ...state, loadingMetricsDetailConversation: true, messageProps: {} };
    case metricsConstants.GET_METRICS_DETAIL_CONVERSATION_SUCCESS:
      return {
        ...state,
        metricsDetailConversation: action.payload.data?.conversation?.msgThread,
        csatList: action.payload.data?.conversation?.csatList,
        loadingMetricsDetailConversation: false,
        messageProps: action.props
      };
    case metricsConstants.GET_METRICS_DETAIL_CONVERSATION_FAILURE:
      return { ...state, metricsDetailConversationError: action.payload, loadingMetricsDetailConversation: false };

    case metricsConstants.GET_QUALITY_SCORE_MESSAGES_REQUEST:
      return { ...state, loadingQualityScoreMessages: true };
    case metricsConstants.GET_QUALITY_SCORE_MESSAGES_SUCCESS:
      return { ...state, qualityScoreMessages: action.payload, loadingQualityScoreMessages: false };
    case metricsConstants.GET_QUALITY_SCORE_MESSAGES_FAILURE:
      return { ...state, qualityScoreMessagesError: action.payload, loadingQualityScoreMessages: false };

      case metricsConstants.GET_PRESALE_MESSAGES_REQUEST:
      return { ...state, loadingPreSaleMessages: true };
    case metricsConstants.GET_PRESALE_MESSAGES_SUCCESS:
      return { ...state, preSaleMessages: action.payload, loadingPreSaleMessages: false };
    case metricsConstants.GET_PRESALE_MESSAGES_FAILURE:
      return { ...state, preSaleMessagesError: action.payload, loadingPreSaleMessages: false };

      case metricsConstants.GET_CUSTOMER_ANSWERED_MESSAGES_REQUEST:
      return { ...state, loadingCustomerAnsweredMessages: true };
    case metricsConstants.GET_CUSTOMER_ANSWERED_MESSAGES_SUCCESS:
      return { ...state, customerAnsweredMessages: action.payload, loadingCustomerAnsweredMessages: false };
    case metricsConstants.GET_CUSTOMER_ANSWERED_MESSAGES_FAILURE:
      return { ...state, customerAnsweredMessagesError: action.payload, loadingCustomerAnsweredMessages: false };

    case metricsConstants.EXPORT_METRICS_MESSAGES_REQUEST:
        return {...state, exporting: true, error: ''};
    case metricsConstants.EXPORT_METRICS_MESSAGES_SUCCESS:
        return { ...state, exporting: false };
    case metricsConstants.EXPORT_METRICS_MESSAGES_FAILURE:
        return {...state, error: action.error, exporting: false};

    case metricsConstants.CLEAR_CONVERSATION:
        return {...state, metricsDetailConversation: []};

    case metricsConstants.SET_MESSAGE_PRESALE_VALUE_SUCCESS:
        return {
          ...state,
          metricsDetailConversation: state.metricsDetailConversation.map(item => {
            if (item.msgId === action.payload.messageId) {
              item.presale = action.payload.preSaleValue
            }
            return item
          })
        };

    default:
      return state
  }
}