import {alertMessages, oauthConstants, trustpilotConstants} from "../_constants";
import {trustpilotOauthService} from "../_services";
import {history} from "../_helpers";
import {SnackBar} from "../components/common";

function initiateOauth(redirectUrl) {
    function request(){
        return { type: oauthConstants.TRUSTPILOT_OAUTH_INITIATE_REQUEST };
    }

    function success(authUrl){
        return { type: oauthConstants.TRUSTPILOT_OAUTH_INITIATE_SUCCESS, payload:authUrl };
    }

    function failure(error){
        return { type: oauthConstants.TRUSTPILOT_OAUTH_INITIATE_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        trustpilotOauthService.initiateOauth(redirectUrl)
            .then(
                authUrl => dispatch(success(authUrl)),
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

function saveClientAppData(payload) {
    function request(){
        return { type: oauthConstants.TRUSTPILOT_SAVE_CLIENT_APP_DATA_REQUEST };
    }

    function success(authUrl){
        return { type: oauthConstants.TRUSTPILOT_SAVE_CLIENT_APP_DATA_SUCCESS, payload:authUrl };
    }

    function failure(error){
        return { type: oauthConstants.TRUSTPILOT_SAVE_CLIENT_APP_DATA_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        trustpilotOauthService.saveClientAppData(payload)
            .then(
                authUrl => dispatch(success(authUrl)),
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

function generateAccessToken(params) {
    let callbackUrl = localStorage.getItem('redirectUrl');
    callbackUrl = JSON.parse(callbackUrl).pathname;

    function request() {
        return { type: oauthConstants.TRUSTPILOT_ACCESSTOKEN_REQUEST };
    }

    function success(res) {
        return { type: oauthConstants.TRUSTPILOT_ACCESSTOKEN_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: oauthConstants.TRUSTPILOT_ACCESSTOKEN_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        trustpilotOauthService.generateAccessToken(params)
            .then(
                (res) => {
                    if (res) {
                        history.push(callbackUrl);
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.TRUSTPILOT_OAUTH_SUCCESS);
                    }
                },
                (error) => {
                    history.push(callbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TRUSTPILOT_OAUTH_FAILURE);
                },
            );
    };
}

function sendToTrends(msgDataSourceId) {
    function request() {
        return { type: trustpilotConstants.TRUSTPILOT_SEND_TO_TRENDS_REQUEST };
    }

    return (dispatch) => {
        dispatch(request());

        trustpilotOauthService.sendToTrends(msgDataSourceId)
            .then(
                (res) => {
                    if (res) {
                        SnackBar.showMessage(alertMessages.TRUSTPILOT_SEND_TO_TRENDS_SUCCESS);
                    }
                },
                (error) => {
                    SnackBar.showMessage(alertMessages.TRUSTPILOT_SEND_TO_TRENDS_FAILURE);
                },
            );
    };
}

function refreshToken(msgDataSourceId) {
    function request() {
        return { type: trustpilotConstants.REFRESH_TRUSTPILOT_TOKEN_REQUEST };
    }

    return (dispatch) => {
        dispatch(request());

        trustpilotOauthService.refreshToken(msgDataSourceId)
            .then(
                () => {
                    SnackBar.showMessage(alertMessages.TRUSTPILOT_REFRESH_TOKEN_SUCCESS);
                },
                () => {
                    SnackBar.showMessage(alertMessages.TRUSTPILOT_REFRESH_TOKEN_FAILURE);
                },
            );
    };
}

function refreshAllTokens() {
    function request() {
        return { type: trustpilotConstants.REFRESH_ALL_TRUSTPILOT_TOKEN_REQUEST };
    }

    return (dispatch) => {
        dispatch(request());

        trustpilotOauthService.refreshAllTokens()
            .then(
                () => {
                    SnackBar.showMessage(alertMessages.TRUSTPILOT_REFRESH_TOKEN_SUCCESS);
                },
                () => {
                    SnackBar.showMessage(alertMessages.TRUSTPILOT_REFRESH_TOKEN_FAILURE);
                },
            );
    };
}

function saveCredentials(credentials) {
    function request() {
        return { type: trustpilotConstants.SAVE_TRUSTPILOT_CREDENTIALS_REQUEST };
    }

    return (dispatch) => {
        dispatch(request());

        trustpilotOauthService.saveCredentials(credentials)
            .then(
                () => {
                    SnackBar.showMessage(alertMessages.SAVE_TRUSTPILOT_CREDENTIALS_SUCCESS);
                },
                () => {
                    SnackBar.showMessage(alertMessages.SAVE_TRUSTPILOT_CREDENTIALS_FAILURE);
                },
            );
    };
}

function clearParamsRequest() {
    function success() {
        return { type: oauthConstants.TRUSTPILOT_OAUTH_CLEAR_REQUEST_PARAMS };
    }

    return (dispatch) => {
        dispatch(success());
    };
}

export const trustpilotOauthActions = {
    initiateOauth,
    saveClientAppData,
    generateAccessToken,
    clearParamsRequest,
    sendToTrends,
    refreshToken,
    refreshAllTokens,
    saveCredentials,
};
