import {Box} from "@mui/system";
import React from "react";


function HorizontalDashedLine() {
  return(
    <Box display={{xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
         sx={{position: 'absolute', top: '40px', width: '100%', padding: '0 16%'}}>
      <Box sx={{borderTop: '2px dashed #144042', width: '100%'}} />
    </Box>
  )
}

function VerticalDashedLine() {
  return(
    <Box display={{xs: 'flex', sm: 'flex', md: 'none', lg: 'none'}}
         sx={{position: 'absolute', width: '100%', height: '100%', justifyContent:'center', }}>
      <Box sx={{borderLeft: '2px dashed #144042'}} />
    </Box>
  )
}

export const dashedLines = {
  HorizontalDashedLine,
  VerticalDashedLine
};