import {Fragment} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import useGetTikTokShopOrderDetails from "../../../hooks/Message/Conversation/useGetTikTokShopOrderDetails";

export default function TikTokShopOrderCard({typeObj}) {
    const { loading, orderDetails } = useGetTikTokShopOrderDetails(_.get(typeObj, 'order_id'));
    const id = _.get(orderDetails, 'id');
    const cancellationInitiator = _.get(orderDetails, 'cancellationInitiator');
    const cancelReason = _.get(orderDetails, 'cancelReason');
    const deliveryOptionName = _.get(orderDetails, 'deliveryOptionName');
    const deliveryType = _.get(orderDetails, 'deliveryType');
    const status = _.get(orderDetails, 'status');
    const shippingType = _.get(orderDetails, 'shippingType');

    if(loading || _.isEmpty(orderDetails) ) {
        return <Fragment/>;
    }

    const CardItem = ({label, value}) => {
        return (
            <Box sx={styles.description}>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{fontFamily: 'LarsseitMedium', mr: '5px'}}>{`${label}:`}</Box>
                    <Box>{value}</Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Box sx={styles.container}>
                <Box sx={styles.title}>
                    Order summary
                </Box>
                <CardItem label={'ID'} value={id} />
                <CardItem label={'Status'} value={status} />
                <CardItem label={'Canceled by'} value={cancellationInitiator} />
                <CardItem label={'Cancel reason'} value={cancelReason} />
                <CardItem label={'Delivery option'} value={deliveryOptionName} />
                <CardItem label={'Delivery type'} value={deliveryType} />
                <CardItem label={'Shipping type'} value={shippingType} />
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        width: '350px',
        backgroundColor: '#FFF',
        border: '1px solid #C1C1C1',
        p: '10px',
        borderRadius: '8px'
    },
    image: {
        borderRadius: '5px',
        border: '1px solid #C1C1C1'
    },
    label: {
        mb: '6px',
        fontFamily: 'LarsseitMedium',
        fontSize: '15px'
    },
    title: {
        mb: '5px',
        fontFamily: 'LarsseitMedium',
        fontSize: '16px'
    },
    description: {
        color: '#495D5E',
        mt: '5px',
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        maxWidth: '100%',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    price: {
        mt: '5px',
        fontFamily: 'LarsseitMedium',
        fontSize: '20px',
        color: '#07857C'
    }
}