import { useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {proactiveActions} from "_actions";
import _ from "lodash";
import {useParams} from "react-router-dom";
import {TemplateBuilder} from "../objects/TemplateBuilder";

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const {id} = params;

    useEffect(() => {
        dispatch(proactiveActions.getTemplate(id));
    }, [id]);

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingTemplate;
    const template = proactive.template;
    const suggestedResponse = proactive.updateSuggestedResponse;

    const templateObj = useMemo(() => {
        let _templateObj = {};
        let suggestedResponses = [];
        if(!loading && !_.isEmpty(template)) {
            const builder = new TemplateBuilder();
            builder.setId(_.get(template, 'id') || '-');
            builder.setName(_.get(template, 'name') || '-');
            builder.setChannel(_.get(template, 'channel') || '-');
            builder.setDescription(_.get(template, 'description') || '-');
            builder.setOrganizationId(_.get(template, 'organizationId') || '-');
            builder.setSuggestedResponses(_.get(template, 'suggestedResponses') || []);
            suggestedResponses = _.get(template, 'suggestedResponses') || [];
            if(!_.isEmpty(suggestedResponse)) {
                const index = _.findIndex(suggestedResponses, {id: _.get(suggestedResponse, 'id')});
                suggestedResponses.splice(index, 1, suggestedResponse);
            }
            _templateObj = builder.build();
        }
        return _templateObj
    }, [ template, suggestedResponse ]) || {};

    return { templateObj, loading };
}