import useYoutubeSetup from "./useYoutubeSetup";
import logoYoutube from "../../../../../../images/logos/logo_youtube.svg";
import ChannelCard from "../../ChannelCard";

export default function YoutubeEntry() {
    const {initiateYouTubeSetup} = useYoutubeSetup();

    return (
        <ChannelCard
            icon={logoYoutube}
            label={'YouTube'}
            description={'Connect your YouTube channel'}
            onClick={initiateYouTubeSetup}
        />
    )
}