import {roleConstants, youtubeConstants} from '_constants';


const INITIAL_STATE = {
    oAuth: {},
    oAuthLoading: false,
    oAuthError: '',
    channelDetails: {},
    loadingChannels: false,
    channelsError: ''
};

export function youtubeReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case youtubeConstants.INITIATE_OAUTH_REQUEST:
            return {...state, oAuthLoading: true};
        case youtubeConstants.INITIATE_OAUTH_SUCCESS:
            return {...state, oAuth: action.payload, oAuthLoading: false};
        case youtubeConstants.INITIATE_OAUTH_FAILURE:
            return {...state, oAuthError: action.error, oAuthLoading: false};

        case youtubeConstants.CREATE_ACCOUNT_REQUEST:
            return {...state, loadingChannels: true};
        case youtubeConstants.CREATE_ACCOUNT_SUCCESS:
        case youtubeConstants.NO_ACCOUNT_FOUND:
            return {...state, channelDetails: action.payload, loadingChannels: false};
        case youtubeConstants.CREATE_ACCOUNT_FAILURE:
            return {...state, channelsError: action.error, loadingChannels: false};

        case youtubeConstants.SAVE_CHANNEL_REQUEST:
            return {...state, loadingChannels: true};
        case youtubeConstants.SAVE_CHANNEL_SUCCESS:
            return {...state, channelDetails: action.payload, loadingChannels: false};
        case youtubeConstants.SAVE_CHANNEL_FAILURE:
            return {...state, channelsError: action.error, loadingChannels: false};
        default:
            return state
    }
}