import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, {Fragment} from "react";
import {makeStyles} from "@mui/styles";


export default function BasicTextField({input, label, options, id, name, onChange, meta: {touched, error, warning}}) {
  const classes = useStyles();
  const [item, setItem] = React.useState('');
  const hasError = touched && !_.isEmpty(error);

  const handleChange = (event) => {
    if (typeof onChange === 'function') {
      onChange(event);
    }
    setItem(event.target.value);
  };

  const renderMenuItems = (item) => {
    return <MenuItem
      key={item.id}
      input={input}
      value={item.id}
      style={{fontSize: '16px'}}>
      {item.name}
    </MenuItem>
  };

  return(
    <Fragment>
      <TextField className={ (touched && error) || (warning) ? classes.textFieldError : classes.textField }
                 InputProps={{ classes: { root: classes.textField } }}
                 inputProps={{ classes: { icon: classes.icon } }}
                 id={id}
                 name={name}
                 label={label}
                 variant="outlined"
                 value={item}
                 onChange={handleChange}
                 select
                 {...input}
                 error={ hasError }>
        { options ? options.map(renderMenuItems) : <MenuItem/> }
      </TextField>
    </Fragment>
  )
}

const useStyles = makeStyles({
  outlinedInput: {
    width: '100%',
    fontSize: '16px !important',
    [`& fieldset`]: {
      border: '1px solid #0C2728', borderRadius: '10px',
    },
  },
  icon: {
    width: '30px !important',
    height: '30px !important',
    margin: '-8px 8px 0 0'
  },

  textField: {
    width: '100%',
    fontSize: '16px !important',
    color: '#0C2728',
    [`& fieldset`]: {
      border: '1px solid #0C2728',
      borderRadius: '10px',
    },
    [`& label`]: {
      fontSize: '16px',
    },

    '& label.Mui-focused': {
      color: '#0C2728',
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0C2728',
      },
      '&:hover fieldset': {
        borderColor: '#0C2728',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#368C86',
      },
    },
  },

  textFieldError: {
    width: '100%',
    fontSize: '16px !important',
    color: '#0C2728',
    [`& fieldset`]: {
      border: '1px solid #EB5757',
      borderRadius: '10px',
    },
    [`& label`]: {
      fontSize: '16px',
    },
  },
});