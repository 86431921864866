import React, {Component} from "react";
import {ScreenshotsTable} from "./Table";
import {connect} from "react-redux";
import {organizationActions, screenshotActions} from "_actions";
import {isObjectEmpty, objectToQueryString, queryStringToObject} from "../../_helpers";
import moment from 'moment';
import {ScreenshotFilterForm} from "./Form/ScreenshotsFilteForm";
import {adjustTimeZone} from "_helpers";
import ReactPaginate from 'react-paginate';
import './screenshots.css'
import {Link} from "react-router-dom";
import {Loading} from "components/common";

class ScreenshotsList extends Component {
    constructor(props){
        super(props);
        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);
        let { organizations } = filterParams;
        organizations = organizations ? organizations.split(",") : [];

        let startDate = filterParams.startDate ? moment(filterParams.startDate) : moment().startOf('day');
        let endDate = filterParams.endDate ? moment(filterParams.endDate) : moment().add(1, 'd').format('YYYY-MM-DD HH:mm:ss');
        filterParams.endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
        filterParams.organizations = organizations;


        filterParams.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');

        this.state = {
            filterParams : {
                ...{
                    organizations: [],
                    type: 'MISTAKE',
                    offset: 0,
                    max: 10,
                }, ...filterParams
            },
            dateWasUpdated: false
        }
    }

    /**
     * to maintain persistence of selected form fields across browser refresh
     * @param event
     */
    handleFormUpdate = (event) => {
        const {target} = event;
        this.setState((prevState) => {
            return {
                filterParams:  {...prevState.filterParams, [target.name]: target.value}
            }
        })
    };


    handleFormSubmit = (event) => {
        event.preventDefault();
        const {filterParams: {endDate}, dateWasUpdated} = this.state;
        let newEndDate = moment(endDate);
        newEndDate = adjustTimeZone(newEndDate, dateWasUpdated);
        newEndDate = newEndDate.format('YYYY-MM-DD HH:mm:ss');
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, ...{
                        offset: 0, endDate: newEndDate,
                    }
                }
            }
        }, function () {
                const {filterParams} = this.state;
                this.updateSearchParams(filterParams)
            })
    };

    /**
     * @param filterParams
     */
    updateSearchParams = (filterParams) => {
        const queryString = objectToQueryString(filterParams);

        this.props.history.push({
            pathname: '/screenshots',
            search: `?${queryString}`
        });
    };

    /**
     * maintain persistence of the selected start and end date
     * between page reloads
     * Push the selected values into the state which are then used to
     * preselect appropriate components after the browser refreshes
     * @param event
     * @param data
     */
    handleDatePickerUpdate = (event, data) => {
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, ...{
                        startDate: data.startDate.format('YYYY-MM-DD HH:mm:ss'),
                        endDate: data.endDate.format('YYYY-MM-DD HH:mm:ss'),
                    }
                },
                dateWasUpdated: true
            }
        });
    };


    componentDidMount() {
        const visibility = ['PUBLIC', 'PRIVATE', 'FEATURED'];
        const {filterParams} = this.state;
        this.props.getAllScreenshots(filterParams);
        this.props.getAllOrganizations({visibility: visibility})
    }

    handleOrganizationsFilterChange = (organizations) => {
        this.setState(prevState => ({
            filterParams: {
                ...prevState.filterParams,
                organizations
            }
        }));
    };

    handlePaginationChange = (page) => {
        const {
            filterParams: {
                endDate
            }
        } = this.state;
        const {itemsPerPage} = this.props;
        const newEndDate = moment().isSame(endDate, 'month') && moment().isSame(endDate, 'day') ?
            moment().add(1, 'd').format('YYYY-MM-DD HH:mm:ss') : endDate;

        let offset = Math.ceil(page.selected * itemsPerPage);
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, ...{
                        offset:offset,
                        endDate: newEndDate
                    }
                }
            }
        }, function () {
            const {filterParams} = this.state;
            this.updateSearchParams(filterParams)}
        )};


    render() {

        const {
            filterParams: {
                startDate,
                endDate,
                type,
                organizations,
                offset
            }
        } = this.state;

        const {screenshots, organizationList} = this.props;

        const setStartDate = moment(startDate).format('M/D/YYYY');
        const setEndDate = moment(endDate).format('M/D/YYYY');
        const screenshotTypeOptions = [
            {'id': 'MISTAKE', name: 'MISTAKE'},
            {'id': 'POSITIVE', name: 'POSITIVE'}
        ];

        const organizationOptions = organizationList && organizationList.length > 0 ? organizationList.filter(org => {
            return org.trendsId
        }).map(currOrg => {
            return {label: currOrg.name, value: currOrg.trendsId}
        }) : [];



        const {totalItems, itemsPerPage, loading} = this.props;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const showPagination = totalItems && totalItems > itemsPerPage;
        const page = parseInt(offset, 10);

        if (loading && isObjectEmpty(organizationList)){
            return <Loading/>
        }

        return (
            <div className="container-pane">
                <div className="side-bar-pane shadow-border">
                   <ScreenshotFilterForm
                       onFormSubmit={this.handleFormSubmit}
                       startDate={setStartDate}
                       endDate={setEndDate}
                       onDatePickerUpdate={this.handleDatePickerUpdate}
                       handleOrgFilterChange={this.handleOrganizationsFilterChange}
                       onFormUpdate={this.handleFormUpdate}
                       typeOptions={screenshotTypeOptions}
                       screenshotType={type}
                       organizationOptions={organizationOptions}
                       checkedOrganizations={organizations}

                   />
                </div>

                <div className="container-right messages-container-right">
                    <div className="-align-left text-right" style={{marginBottom: '15px'}}>
                        <Link to='/screenshots/add'>
                            <button className='btn btn-primary'>Add screenshot</button>
                        </Link>
                    </div>
                    <div>
                        <ScreenshotsTable
                            screenshots={screenshots}
                        />
                        {showPagination ?
                        <div className="align-center">
                            <ReactPaginate
                                nextLabel={">"}
                                previousLabel={"<"}
                                breakLabel={<span>...</span>}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={2}
                                disableInitialCallback={true}
                                pageRangeDisplayed={5}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                onPageChange={(page) => this.handlePaginationChange(page)}
                                forcePage={page}
                            />
                        </div> : ''
                        }
                    </div>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const {screenshots: {data: {params, count, data}, loading}, organizations: {organizations}} = state;
    return {
        screenshots: data,
        loading,
        params,
        totalItems: count,
        itemsPerPage: params ? params.max : 10,
        organizationList: organizations.organizations
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllScreenshots: (props)  => dispatch(screenshotActions.getAll(props)),
        getAllOrganizations: (props) => dispatch(organizationActions.getAll(props))
    }
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ScreenshotsList);
export {connectedComponent as ScreenshotsList}



