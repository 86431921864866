import {Box} from "@mui/system";
import React from "react";

/**
 * @param label {string}
 * @param link {string}
 * @param buttonBordered {boolean}
 * @param sx
 * @returns {JSX.Element}
 * @constructor
 */
export default function AnchorButton({ label, link, buttonBordered , sx}) {
    return(
        <Box sx={{}}>
            <Box sx={styles}>
                <a href={link} style={buttonBordered ? styles.buttonBordered : styles.buttonColored}>
                    {label}
                </a>
            </Box>
        </Box>
    )
}

const styles = {
    buttonBordered: {
        width: '100%',
        fontSize: '16px',
        padding: '7px 25px',
        height: '40px',
        color: '#368C86',
        fontFamily: 'LarsseitMedium',
        textTransform: 'none',
        borderRadius: '50px',
        backgroundColor: '#ffffff',
        border: '1px solid #368C86',
        textDecoration: 'none',
        position: 'relative', top: '8px',
        ':hover': {
            backgroundColor: '#ffffff',
            color: '#368C86',
        },
        ':disabled': {
            color: '#368C8680',
            backgroundColor: '#f5f5f5',
            border: '1px solid #368C8680'
        },
        ':focus': {
            outline: '0'
        }
    },
    buttonColored: {
        width: '100%',
        fontSize: '16px',
        padding: '8px 25px',
        color: '#fff',
        fontFamily: 'LarsseitMedium',
        textTransform: 'none',
        borderRadius: '50px',
        backgroundColor: '#07857C',
        textDecoration: 'none',
        position: 'relative', top: '9px',
        ':hover': {
            backgroundColor: '#34756f',
            color: 'white',
        },
        ':disabled': {
            color: '#fff',
            backgroundColor: '#368C8680'
        },
        ':focus': {
            outline: '0'
        }
    },
}