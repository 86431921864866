import React, { Component, Fragment } from "react";
import {TableCell, TableRow, Typography, withStyles} from "@material-ui/core";
import moment from 'moment';
import {
  stripTrailingQuestionMarkFromHtmlEntity,
  timeDifferenceInSeconds,
  getCurrentUser,
  formatDateTime, convertUtcToTimezone,
} from "_helpers";
import MessageLockIcon from '@material-ui/icons/Lock';
import htmlParser from "he";
import _ from "lodash";
import {LOCK_DURATION_IN_SECS} from "_constants";
import { HtmlTooltip } from 'components/common';
import CheckMarkIcon from "../images/check_circle.svg";
import {stylesTable} from "./StylesTable";
import {Link} from "react-router-dom";



/**
 * @author Chris Okebata
 */


class InboxMessageRow extends Component {

  render() {

    const { message, messageType, classes } = this.props;
    let parsedMessage = htmlParser.decode(stripTrailingQuestionMarkFromHtmlEntity(message.text));
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const {_embedded: {organization: {timezone }}} = currentUser;

    let timeDiff = timeDifferenceInSeconds(moment(_.get(message, 'lockedAtTime')).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
    const user = getCurrentUser();
    const lockedByCurrentUser = _.get(message, 'lockedByUser') === user.id;

    return(
      <Fragment>
        <TableRow key={message.id} className={classes.tableRow}>
          <HtmlTooltip
            title={
              <div style={{minWidth: '250px', maxWidth: '700px', padding: '10px'}}>
                <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                  {parsedMessage}
                </Typography>
              </div>
            }
            placement={'bottom'}
            style={{opacity: 1}}
            enterDelay={700}
          >
            <TableCell style={{paddingRight: '0px', width: '70%'}} component="th" scope="row" className={classes.tableCell}>
              <Link to={`/inbox/${message.id}?type=${messageType}`} className="btn-link tms-no-underline">
              <Typography className={classes.typography15}><span>{parsedMessage}</span></Typography>
              </Link>
            </TableCell>
          </HtmlTooltip>
          <TableCell className={classes.tableCell} style={{padding: '0', width: '14%', textAlign: "center"}}>
            <Typography className={classes.typography14}>
              {formatDateTime(convertUtcToTimezone(message.dateCreated, timezone))}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} style={{padding: '0', width: '4%'}}>
            {
              _.get(message, 'lockedAtTime') && timeDiff < LOCK_DURATION_IN_SECS && !lockedByCurrentUser &&
              <MessageLockIcon className="color-grey-mid" fontSize={'small'} style={{marginTop: '6px'}}/>
            }
          </TableCell>
          <TableCell className={classes.tableCell} style={{paddingRight: '4px', paddingLeft: '4px', width: '5%'}}>
            {(message.hasCompanyReplied || message.isDispatchedSuccessfully) && <img src={CheckMarkIcon} alt="actioned"/>}
          </TableCell>
        </TableRow>
      </Fragment>
    );

  };
}

const inboxMessageRow = withStyles(stylesTable)(InboxMessageRow);
export { inboxMessageRow as InboxMessageRow }
