import React, {useEffect} from "react";
import Auth0LogoutButton from "./Auth0LogoutButton";
import chatdeskLogo from 'components/images/chatdesk-logo-dark.svg'
import {CopyrightFooter, Loading} from "../common";
import {useAuth0} from "@auth0/auth0-react";
import ReactGA from 'react-ga';
import {GOOGLE_ANALYTICS_TRACKING_ID} from "_constants";
import {authService} from "_services";


function PromptMessage () {

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, []);

  let message;
  const url = window.location.href.trim();
  const { isAuthenticated, logout } = useAuth0();

  switch (true) {
    case url.includes('contact-support'):
      if(isAuthenticated){
        authService.logoutWithRedirect('contact-support');
      }
      message = 'Contact support@chatdesk.com to complete setup';
      break;
    case url.includes('verify-email'):
      if(isAuthenticated){
        authService.logoutWithRedirect('verify-email');
      }
      message = 'We have sent a message to your email inbox. Please click the link in your email to verify your email address';
      break;
    case url.includes('profile-success'):
      if(isAuthenticated){
        authService.logoutWithRedirect('profile-success');
      }
      message = 'Your profile has been created, login to continue';
      break;
  }

  if(isAuthenticated){ // at this point, the user is still being logged out from Auth0
    return <Loading/>
  }

  return (
    <div>
      <div className="content-pane" style={{width: '550px', margin: "0 auto"}}>
        <div style={{minHeight: '200px', padding: '45px 0'}} className="font-16">
          <div style={{textAlign: 'center'}}>
            <img src={chatdeskLogo} alt="chatdesk logo" className="tms-logoImg" style={{margin: "0 auto"}}/>
          </div>
          <div style={{width: '95%', minHeight: '30px', textAlign: 'center', margin: '50px auto'}}>
            {message}
          </div>
          <div style={{minHeight: '30px'}}>
            <div style={{width: "170px", margin: "0 auto"}}>
              <Auth0LogoutButton label={'Back to login'}/>
            </div>
          </div>
        </div>
      </div>

      <div style={{position: 'relative', top: '200px'}}>
        <CopyrightFooter/>
      </div>
    </div>
  );
}

export default PromptMessage;
