import { useState, Fragment } from 'react'
import { Box } from '@mui/material';
import { msgDataSourceActions } from '_actions'
import BasicModal from "components/v2/views/Onboarding/Utilities/BasicModal";
import basicModalStyles from "components/v2/views/Channels/ConnectChannels/Utilities/basicModalStyles";
import {buttonContained} from "components/v2/views/Onboarding/Utilities/ButtonContained";
import { useDispatch } from 'react-redux';

export const useRemoveAccountModal = () => {
	const [open, setOpen] = useState(false);
	const [removeMsgDataSourceId, setRemoveMsgDataSourceId] = useState(null);
	const [removeMsgDataSourceName, setRemoveMsgDataSourceName] = useState(null);

	const handleClickRemoveAccount = ({	msgDataSourceId, msgDataSourceName }) => {
		setRemoveMsgDataSourceId(msgDataSourceId);
		setRemoveMsgDataSourceName(msgDataSourceName);
		setOpen(true);
	}

	const handleCancel = () => {
		setRemoveMsgDataSourceId(null);
		setRemoveMsgDataSourceName(null);
		setOpen(false);
	}

	return {
		open, removeMsgDataSourceId, removeMsgDataSourceName,
		handleCancel, handleClickRemoveAccount
	}
}

/**
 * This modal is being used when the user wants to remove an account from a channel.
 * (e.g. Remove account @dogs123 and all it's handles (if it has some) from channel instagram)
 **/
export function RemoveAccountModal({
	open, removeMsgDataSourceId, removeMsgDataSourceName,
	handleCancel,
}) {
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch()

	const {SignupButton} = buttonContained;

	const handleClickRemove = (e) => {
		e.preventDefault()
		setIsLoading(true)
		dispatch(msgDataSourceActions.deleteMsgDataSource(removeMsgDataSourceId))
	}

	return (
		<Fragment>
			<BasicModal
				open={open}
				handleClose={handleCancel}
				title={'Remove this Account?'}>

				<Box sx={basicModalStyles.description}>
					Do you want to remove <strong>{removeMsgDataSourceName}</strong>?
				</Box>

				<Box sx={basicModalStyles.buttonContainer}>
					<Box />
					<Box sx={basicModalStyles.buttonRight}>
						<SignupButton label={'Remove'} action={handleClickRemove} isLoading={isLoading} />
					</Box>
				</Box>
			</BasicModal>
    </Fragment>
	)
}