export class MediaTypeBuilder {
    constructor() {
        this.mediaType = {};
    }

    setId(id){
        this.mediaType.id = id;
        return this;
    }

    setName(name){
        this.mediaType.name = name;
        return this;
    }

    setChannel(channel){
        this.mediaType.channel = channel;
        return this;
    }

    build(){
        return this.mediaType;
    }
}
