import React from "react";
import {useDispatch} from "react-redux";
import {Box} from "@mui/system";
import styles from "../organizationMainStyles";
import {getAccessibilityMode, getLabel} from "../Utilities/organizationSettingsUtils";
import {fragmentId} from "../Utilities/organizationSettingsConstants";
import useGetOrganizationSchedules from "../hooks/useGetOrganizationSchedules";
import SchedulesContainer from "../Utilities/ExpertManagement/SchedulesContainer";
import _ from "lodash";
import NoDataView from "../EmptyStates/NoDataView";
import ExpertManagementEmptyState from "../EmptyStates/ExpertManagementEmptyState";

export default function ExpertManagement({organizationId}) {
    const label = getLabel(fragmentId.EXPERT);
    const mode = getAccessibilityMode();
    const {
        schedules,
        loadingSchedules,
        payoutRates,
        loadingPayoutRates,
        pricingRates,
        loadingPricingRates
    } = useGetOrganizationSchedules(organizationId);

    if(_.isEmpty(organizationId) && _.isEmpty(schedules)) {
        return <NoDataView />
    }

    if(loadingSchedules) {
        return (
            <Box sx={styles.sectionWrapper}>
                <ExpertManagementEmptyState/>
            </Box>
        )
    }

    return(
        <Box sx={styles.sectionWrapper}>
            <Box sx={style.desc}>{label.SCHEDULES}</Box>
            <SchedulesContainer
                organizationId={organizationId}
                payoutRates={payoutRates}
                pricingRates={pricingRates}
                schedules={schedules}
                loadingPayoutRates={loadingPayoutRates}
                loadingPricingRates={loadingPricingRates}
                loadingSchedules={loadingSchedules}
                mode={mode}
            />
        </Box>
    )
}

const style = {
    desc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '20px',
        pb: '15px',
        borderBottom: '1px solid #B5BEBF'
    }
}