import React, {Component, Fragment} from "react";
import {GenericModal} from "./index";
import connect from "react-redux/es/connect/connect";
import {getVotingOrganization, stripHashTag} from "_helpers";
import _ from "lodash";
import {messageActions} from "_actions";
import {FollowupConversation} from "./FollowupConversation";


/**
 * @author Chris Okebata
 */

class FollowupModal extends Component{

  constructor(props){
    super(props);

    this.state = {
      showModal: false,
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  openModal = (ticketId) => {
    const { dispatch, organization, isTrends } = this.props;
    const orgId = _.get(getVotingOrganization(), 'id') || _.get(organization, 'id');

    dispatch(messageActions.getMessagesInTicket(ticketId, orgId, isTrends));
    this.setState({ showModal: true });
  };

  render() {

    const { showModal } = this.state;
    const { target, loadingTicketThread, ticketThread } = this.props;
    const ticketId = stripHashTag(target);
    const conversation = _.get(ticketThread, 'conversation');

    return (
      <Fragment>
        <button
          className="transparent-button-default"
          onClick={() => this.openModal(ticketId)}
          type="button"
          style={{padding: 0, outline: 'none', lineHeight: '14px'}}
        >
          {target || '[button]'}
        </button>

        <GenericModal
          headerText="Conversation"
          hideFooter
          show={showModal}
          onHide={this.hideModal}
          size="lg"
          isConversationThread={true}
        >
          <FollowupConversation messages={conversation} loading={loadingTicketThread}/>
        </GenericModal>
      </Fragment>
    )
  }

}

function mapStateToProps(state) {
  const { messages: {loadingTicketThread, ticketThread} } = state;
  return {
    loadingTicketThread,
    ticketThread
  };
}

const connectedFollowupModal = connect(mapStateToProps)(FollowupModal);
export { connectedFollowupModal as FollowupModal };