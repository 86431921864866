import TimezonePicker from "react-timezone";
import React from "react";

export default function TimezonePickerComponent(props) {
    const { placeHolder, label, input, ...rest } = props;

    return(
        <TimezonePicker
            {...rest}
            {...input}
            placeholder={placeHolder}
            name={input.name}
            onChange={input.onChange}
            className="profile-timezone"
            required
        />
    )
}