import React, {Component} from 'react';
import { Button, Form, Modal } from 'react-bootstrap'
import 'react-image-crop/dist/ReactCrop.css';
import { regFormContainer } from "components/RegisterPage";
import { Field, reduxForm } from "redux-form";
import { required } from "_helpers";
import { Loading, InputField, Timezone } from "components/common";
import {formModes, publicAndFeaturedVisibility} from "_constants";
import { organizationActions, roleActions } from "_actions";
import moment from "moment/moment";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

/**
 * @author Chris Okebata
 */


class EditUserProfile extends Component{

    constructor(props) {
        super(props);
        const fetchRoles = this.props.mode === formModes.ADD || ( this.props.mode === formModes.EDIT && isEmpty(this.props.user._embedded && this.props.user._embedded.roles) );
        const fetchOrganizations = this.props.mode === formModes.ADD || ( this.props.mode === formModes.EDIT && isEmpty(this.props.user._embedded && this.props.user._embedded.organization) );

        this.state = {
            fetchRoles,
            fetchOrganizations
        };

        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {

        if (this.state.fetchRoles) {
            /**
             * Fetch roles to populate
             * the roles dropdown.
             * */
            this.props.dispatch(roleActions.getAll());
        }

        if (this.state.fetchOrganizations) {
            /**
             * Fetch roles to populate
             * the roles dropdown.
             * */
            this.props.dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}))
        }

        this.props.initialize({
            ...this.props.user,
            dateCreated: moment(this.props.user.dateCreated).format('MMMM D, YYYY'),
        });
    }


    handleCancel() {
        this.props.onHide()
    }

    render(){
        const { show, headerText, buttonText} = this.props;

        let { submitting,  handleSubmit, user, roles, mode, organizations } = this.props;

        if (( mode === formModes.EDIT && !user ) ||
            ( this.state.fetchOrganizations && !organizations ) ||
            ( this.state.fetchRoles && !roles )) {
            return <Loading/>
        }

        return (
            <Modal show={show} onHide={this.handleCancel} className="tms-edit-profile-modal">
                <Form onSubmit={handleSubmit(this.props.onSave)}>
                <Modal.Header closeButton>
                    <Modal.Title>{headerText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{zIndex: "5", padding:"20px 0 35px 0", width:"100%"}}>
                        <div className="tms-signup-form-content">
                            <div className="tms-signup-form-section-1">
                                <div style={regFormContainer.inputField}>
                                    <Field
                                        name="firstName"
                                        label="First Name"
                                        component={InputField}
                                        validate={required}
                                        type="text"
                                    />
                                </div>
                                <div style={regFormContainer.inputField}>
                                    <Field
                                        name="lastName"
                                        label="Last Name"
                                        component={InputField}
                                        validate={required}
                                        type="text"
                                    />
                                </div>
                                <div style={regFormContainer.inputField}>
                                    <Field
                                        name="phoneNumber"
                                        label="Phone Number"
                                        component={InputField}
                                        validate={required}
                                        type="text"
                                    />
                                </div>
                                <div style={regFormContainer.inputField}>
                                    <Field
                                        name="username"
                                        label="Email"
                                        component={InputField}
                                        validate={required}
                                        type="text"
                                        readOnly={true}
                                    />
                                </div>

                            </div>
                            <div className="tms-signup-form-section-2">
                                <div style={regFormContainer.inputField}>
                                    <Field
                                        name="address"
                                        label="Address Line 1"
                                        component={InputField}
                                        validate={required}
                                        type="text"
                                    />
                                </div>

                                <div style={regFormContainer.inputField}>
                                    <Field
                                        name="city"
                                        label="City"
                                        component={InputField}
                                        validate={required}
                                        type="text"
                                    />
                                </div>
                                <div style={regFormContainer.inputField}>
                                    <Field
                                        name="state"
                                        label="State"
                                        component={InputField}
                                        validate={required}
                                        type="text"
                                    />
                                </div>
                                <div style={regFormContainer.inputField} >

                                    <Field
                                        name="country"
                                        label="Country"
                                        component={InputField}
                                        validate={required}
                                        type="text"
                                    />
                                    </div>
                                <div style={{...regFormContainer.inputField}} className="edit-profile-timezone">
                                    <div style={{ width: '83%', margin:"0 auto"}} >
                                        <Field
                                            name="timezone"
                                            label="Timezone"
                                            component={Timezone}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <div style={{marginLeft:"auto"}}>
                            <Button onClick={this.handleCancel} style={{padding: '7px 12px', width:"80px"}} className="btn btn-default">Cancel</Button>
                            <Button className="btn btn-success"
                                    type="submit"
                                    style={{padding: '7px 12px', width:"80px"}}
                                    disabled={submitting}>{buttonText}</Button>
                        </div>
                    </div>
                </Modal.Footer>
                </Form>
            </Modal>
        );
    }

}

const mapStateToProps = (state) => {
    const { roles, organizations: { organizations } } = state;

    return {
        roles: roles.roles,
        organizations: organizations
    };
};

const formConfig = {
    form: 'RegistrationForm'
};
const connectedEditUserProfile = connect(mapStateToProps)(EditUserProfile);
const reduxEditUserProfile = reduxForm(formConfig)(connectedEditUserProfile);

export {reduxEditUserProfile as EditUserProfile};
