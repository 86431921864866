import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import BackIcon from '@material-ui/icons/ArrowBack';
import {SearchFilter} from "./SearchFilter";
import {Paper, Table, TableBody, TableCell, TableRow, Typography, withStyles} from "@material-ui/core";
import htmlParser from "he";
import {stripTrailingQuestionMarkFromHtmlEntity, stripEmTagFromHtml} from "_helpers";
import IconHead from "../../images/person_pin-24px.svg";
import moment from "moment";
import PropTypes from "prop-types";
import {stylesTable} from "components/Inbox/StylesTable";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import {HtmlTooltip} from "../../common";
import {SearchPanelEmptyState} from "./SearchPanelEmptyState";
import {Button} from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import {responseOptionActions} from "_actions";


/**
 * @author Chris Okebata
 */

const OUTGOING = 'outgoing';
const INCOMING = 'incoming';

class SearchResultPanel extends Component{

  addResponseToMessage(searchedMessage){
    const {dispatch, currentUser, message} = this.props;
    let messageId = _.get(message, 'id');
    let userId = _.get(currentUser, 'id');
    let text = _.get(searchedMessage, 'messageText');
    let responseMessageId = _.get(searchedMessage, 'messageId');

    dispatch(responseOptionActions.addResponseOption(messageId, responseMessageId, text, userId));
  }

  render() {

    const {
      closeSearchPanel, searchText, onFormUpdate, channelsFilter, filterParams,
      handlePageSelect, addedResponseOption, loadingAddResponseOption,
      channelsIds, handleChannelsFilterChange, direction, classes,
      searchedData, loadingSearch, max, showSearchConversation, message
    } = this.props;

    const { response_options, isProactive } = message;


    let resultObject = searchedData || {};
    let searchedMessages = _.get(resultObject, 'searchResult');
    let count = _.get(resultObject, 'resultCount');

    let { channels, page } = filterParams;

    const currentPage = parseInt(page, 10);
    const totalPages = Math.ceil(count / max);

    if(loadingSearch){
      return <SearchPanelEmptyState/> ;
    }

    return(
      <Fragment>
        <div className="font-14" style={{width: '100%', height: '40px', display: 'flex', alignItems: 'center', padding: '0px 35px', borderBottom: '1px solid #dee2e0', backgroundColor: '#f3f3f3'}}>
          <div className="search-back-button" onClick={closeSearchPanel}>
            <BackIcon style={{fontSize: '22px', fontWeight: "bold"}} className="color-green"/>
          </div>
          <span className="color-grey-dark">
            <span className=" font-14">Search result for</span>
            <span className="font-14-bold" style={{marginLeft: '3px', fontStyle: 'italic'}}>{searchText}</span>
          </span>
        </div>

        <div style={{height: 'calc(100% - 110px)', position: 'relative', backgroundColor: '#fafafa'}}>

          <div className="modal-fade" style={ loadingAddResponseOption ? {display: 'block'} : {display: 'none'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
              <CircularProgress size={40} style={{color: '#008751'}}/>
            </div>
          </div>

           <div style={{padding: '0 35px', width: '100%', height: 'calc(100% - 87px)', position: 'absolute', top: '0'}}>
              <div style={{height: '80px', padding: '0px 0px', display: 'flex', alignItems: 'center'}}>
                <SearchFilter
                  channelsFilter={channelsFilter}
                  channelsIds={channelsIds}
                  handleChannelsFilterChange={handleChannelsFilterChange}
                  channelsAction={channels}
                  direction={direction}
                  onFormUpdate={onFormUpdate}
                />
              </div>

              <div style={{border: '1px solid #CED2D0', borderRadius: '4px', backgroundColor: '#ffffff', overflowY: 'auto', height: 'calc(100% - 45px)'}}>

                <div className="search-messages-table">
                  <Paper className={classes.root} style={{overflowX: 'unset', border: 'unset'}}>
                    {_.get(searchedMessages, 'length') === 0 &&
                      <div style={{textAlign: 'center', fontsize: '20px', padding: '30px'}}>
                        No results found
                      </div>
                    }
                    {_.get(searchedMessages, 'length') > 0 &&
                      <Table className={classes.table}>
                        <thead>
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <Typography style={{width: "440px"}} className={classes.typography15}>Message</Typography>
                          </TableCell>
                          <TableCell style={{width: "80px"}} className={classes.tableRow}>
                            <Typography className={classes.typography15}>Direction</Typography>
                          </TableCell>
                          <TableCell style={{width: '100px'}} className={classes.tableCell}>
                            <Typography className={classes.typography15}>Date</Typography>
                          </TableCell>
                          <TableCell style={{width: '125px'}} className={classes.tableCell}>
                            <Typography className={classes.typography15}/>
                          </TableCell>
                        </TableRow>
                        </thead>

                        <TableBody>
                          {searchedMessages.map((searchedMessage, index) => {
                            let highlightPhrase = stripEmTagFromHtml(_.get(searchedMessage, 'phrase'));
                            let text = htmlParser.decode(stripTrailingQuestionMarkFromHtmlEntity(_.get(searchedMessage, 'messageText')));

                            return (
                          <TableRow key={index} className={classes.tableRow}>
                            <HtmlTooltip
                              title={
                                <div style={{minWidth: '250px', maxWidth: '700px', padding: '10px'}}>
                                  <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                                    {text}
                                  </Typography>
                                </div>
                              }
                              placement={'bottom'}
                              style={{opacity: 1}}
                              enterDelay={700}
                            >
                              <TableCell className={classes.tableCell}>
                                <div onClick={() => showSearchConversation(_.get(searchedMessage, 'messageId'))} className="tms-no-underline">
                                  <Typography className={classes.typography14}>
                                    <span className="font-14 truncate-string">{highlightPhrase}</span>
                                  </Typography>
                                </div>
                              </TableCell>
                            </HtmlTooltip>

                            <TableCell className={classes.tableCell}>
                              {_.get(searchedMessage, 'direction').toString().toLowerCase() === INCOMING &&
                                <span>
                                  <img src={IconHead} title="incoming message" alt="incoming message" />
                                </span>
                              }
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Typography className={classes.typography14}>
                                {moment(_.get(searchedMessage, 'messageDate')).format('MMM D, YYYY')}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {
                                  !isProactive && _.get(searchedMessage, 'direction').toString().toLowerCase() === OUTGOING &&
                                <div>
                                  { (addedResponseOption.some(it => it.responseMessageId === parseInt(searchedMessage.messageId, 10))||
                                    (response_options.some(it => it.responseMessageId === parseInt(searchedMessage.messageId, 10)))) ?
                                  <div style={{textAlign: 'left'}}>
                                    <span className="font-13 color-grey-mid">Added</span>
                                    <span className="fa fa-check color-green margin-left-5"/>
                                  </div> :
                                    <Button
                                      onClick={() => this.addResponseToMessage(searchedMessage)}
                                      id="submitEditButton"
                                      className="btn btn-block btn-default button-helper"
                                      style={{ height: '30px', lineHeight: '0px', fontSize: '13px', padding: '0'}}
                                      type="button"
                                    >
                                      Add response
                                    </Button>
                                  }
                                </div>
                              }
                            </TableCell>
                          </TableRow>)
                          })}
                        </TableBody>

                      </Table>
                    }
                  </Paper>
                </div>
              </div>
            </div>

          <div className="font-14 color-grey-dark" style={{width: '100%', height: '52px', position: 'absolute', bottom: '0px',
            padding: '0px 35px', display: 'flex', alignItems: 'center', zIndex: '0'}}>

            {totalPages > 1 &&
            <div style={{width: '', marginRight: '10px'}}>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={<span>...</span>}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                disableInitialCallback={true}
                pageRangeDisplayed={5}
                forcePage={currentPage}
                onPageChange={(currentPage) => handlePageSelect(currentPage)}
                containerClassName={"pagination pagination-adj"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            }

            <span>{`${count || 0} Results`}</span>
          </div>
        </div>

      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { responseOptions } = state;

  return {
    addedResponseOption: responseOptions.addedResponseOption,
    loadingAddResponseOption: responseOptions.loadingAddResponseOption
  };
}

SearchResultPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedSearchResultPanel = connect(mapStateToProps)(withStyles(stylesTable)(SearchResultPanel));
export { connectedSearchResultPanel as SearchResultPanel };