import React, { Component } from 'react';
import { connect } from 'react-redux';
import {employmentActions, responseOptionActions, screenshotActions, utilityActions} from '_actions';
import { EmploymentUser } from 'components/Employment';
import AgentsMessages from 'components/Employment/AgentsMessages';
import Conversation from 'components/Employment/Conversation';
import { Loading } from 'components/common';
import _, { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {objectToQueryString, queryStringToObject} from '_helpers';
import 'components/Employment/employment.css';
import { votingOptions } from "../../_constants";

const filterOptions = [
    {
        value: 'reviewed',
        label: 'Reviewed'
    },
    {
        value: 'responses',
        label: 'Responses'
    },
    {
        value: 'all_messages',
        label: 'All Messages'
    },
    {
        value: 'escalations',
        label: 'Escalations'
    },
    {
        value: 'no_response_needed',
        label: 'No Response Needed'
    }
];

/**
 * Parent component of the employment detail page.
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 03/11/2018 : 3:28 PM
 * @author Chris Okebata
 */

class EmploymentDetails extends Component {

    constructor(props) {
        super(props);

        /**
         * Get the query string from the current url.
         * @type {string}
         */
        const queryString = this.props.location.search.substring(1);
        const queryStringAsObject = queryStringToObject(queryString);

        let currentFilter = {
            value: 'reviewed',
            label: 'Reviewed'
        };

        if (!isEmpty(queryStringAsObject)) {
            currentFilter = filterOptions.filter(option => (option.value === queryStringAsObject.responseType))[0];
        }

        this.state = {
            employmentId: this.props.match.params.employmentId,
            selectedOption: currentFilter,
            selectedMessageRowId: '',
            conversationSelected: false
        };

    }

    componentDidMount() {
        const { employmentId } = this.props.match.params;
        this.props.dispatch(employmentActions.getEmploymentDetails(employmentId));
    }

    getConversationOnClick = (messageId, agentId) => {

        const {dispatch} = this.props;

        dispatch(responseOptionActions.getExpertMessageResponses(messageId, agentId, {
            max: 2,
            voteTag: votingOptions.APPROVE
        }));
        this.setState({
            selectedMessageRowId: messageId,
            conversationSelected: true
        })
    };

    /**
     * Handles changes to the message filter dropdown
     * @param selectedOption
     */
    onSelectChangeHandler = (selectedOption) => {
        this.setState({selectedOption});

        const queryString = objectToQueryString({responseType: selectedOption.value});

        /**
         * This applies the query parameters from form to browser URL
         */
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?${queryString}`
        });
    };

    removeFromTeamHandler = (params) => {
        this.props.dispatch(employmentActions.updateEmployment(params));
    };

    submitPositiveFeedback = (data, currPath) => {
        this.props.dispatch(screenshotActions.addScreenshot(data, currPath))
    }

    getRandomPositiveDescription = () => {
        const descriptionArray = [
            "Great work on this message!",
            "Well done on this message, keep it up!",
            "You did a good job on this message",
            "I really like how you handled this message",
            "Nice work on this message!",
            "Nice job on this message",
            "Good work on this message!",
            "Keep up the good work!",
            "Very nicely done!"
        ]

        return _.sample(descriptionArray)
    }

    /**
     * This method 'clears' the conversation. It is used in the agent messages to
     * hide the previous conversation when a new page is selected.
     *
     **/
    clearConversation = () => {
        this.setState({
            conversationSelected: false
        })
    };

    render() {
        const {employmentData, approvedResponses, agentMessages, loadingMessages, loadingEmploymentData, loadingEmploymentUpdate} = this.props;
        const {selectedOption, selectedMessageRowId, conversationSelected, employmentId} = this.state;

        const agentApprovedResponses = approvedResponses.response_options;

        // const {mode, bio, nickname, status, _embedded} = employmentData;
        const mode = _.get(employmentData, 'mode');
        const bio = _.get(employmentData, 'bio');
        const nickname = _.get(employmentData, 'nickname');
        const status = _.get(employmentData, 'status');
        const _embedded = _.get(employmentData, '_embedded');

        const agent = _.get(_embedded, "agent")
        const organization = _.get(_embedded, "organization")

        const messageUrl = window.location.href.trim();
        const currentPath = utilityActions.getCurrentPath();
        let positiveScreenshotData = {};

        const currPath = currentPath ? currentPath : "/screenshots";
        let description = this.getRandomPositiveDescription()
        positiveScreenshotData = {
            currPath: currPath,
            expert: agent,
            type: "POSITIVE",
            messageUrl: messageUrl,
            description: description,
            dispatchFunction: this.submitPositiveFeedback
        }

        return (
            <div className="main-content-wrapper" style={{padding: '0 38px'}}>
                <div className="header-pane">
                    <div style={{padding: '0px 40px'}}>
                        <div id="breadcrumb-container" style={{margin: '0 100px 0 0'}}>
                            <span style={{display: 'inline-block'}}>
                                <Link className="color-green font-15-bold trd-nav-hover"
                                      to="/company/team">
                                    My Team
                                </Link>
                            </span>
                            <span style={{
                                margin: '0 20px',
                                position: 'relative',
                                top: '1px',
                                display: 'inline-block',
                                fontSize: '16px'
                            }}>
                                <i className="fa fa-caret-right color-grey-dark">
                                </i>
                            </span>
                            <span style={{display: 'inline-block'}}
                                  className="color-grey-dark font-15-bold">
                                {nickname ? nickname : agent?.firstName}
                            </span>
                        </div>

                        <div style={{
                            display: 'inline-block',
                            width: '300px'
                        }}>
                            <Select
                                value={selectedOption}
                                options={filterOptions}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: 'green',
                                    },
                                })}
                                onChange={this.onSelectChangeHandler}
                            />
                        </div>
                        {/*<div id="view-next-candidate-container">*/}
                        {/*/!*@Todo will get back to this*!/*/}

                        {/*/!*<span className="ellipsis-vertical color-green font-15" id="ellipsis-view-next">*!/*/}
                        {/*/!*<i className="fa fa-ellipsis-v" style={{fontSize:"18px"}}/>*!/*/}
                        {/*/!*</span>*!/*/}
                        {/*/!*<div id="view-next-candidate">*!/*/}
                        {/*/!*<span className="color-green font-15">*!/*/}
                        {/*/!*View next*!/*/}
                        {/*/!*</span>*!/*/}
                        {/*/!*<span id="right-arrow-container">*!/*/}
                        {/*/!*<i className="fa fa-caret-right color-green">*!/*/}
                        {/*/!*</i>*!/*/}
                        {/*/!*</span>*!/*/}
                        {/*/!*</div>*!/*/}
                        {/*</div>*/}

                    </div>

                </div>
                <div className="main-content-pane margin-bottom-25">
                    <div style={{display: 'flex'}}>
                        <div className="employment-user-container">
                            {(loadingEmploymentData || isEmpty(employmentData)) && (employmentData ? employmentId !== employmentData.id : true) ? (
                                <div>
                                    <Loading />
                                </div>
                            ) : (
                                <EmploymentUser
                                    onRemoveFromTeam={this.removeFromTeamHandler}
                                    bio={bio}
                                    nickname={nickname}
                                    employmentId={employmentData.id}
                                    mode={mode}
                                    status={status}
                                    agent={agent}
                                    loadingEmploymentUpdate={loadingEmploymentUpdate}
                                />
                            )}
                        </div>

                        <div className="agent-messages-container">
                            <AgentsMessages
                                onConversationSelect={this.getConversationOnClick}
                                filterBy={selectedOption.value}
                                selectedConversation={selectedMessageRowId}
                                agent={agent}
                                organization={organization}
                                agentMessages={agentMessages}
                                clearConversation={this.clearConversation}
                            />
                        </div>

                        <div className="conversation-container">
                            <Conversation agent={agent}
                                          agentNickname={nickname}
                                          agentMessages={agentMessages}
                                          selectedMessageId={selectedMessageRowId}
                                          selectedFilterOption={selectedOption.value}
                                          isConversationSelected={conversationSelected}
                                          approvedResponses={agentApprovedResponses}
                                          loading={loadingMessages}
                                          positiveFeedbackData={positiveScreenshotData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const {employment, responseOptions: {responseOptions, loading}, users} = state;

    const {user} = users;

    return {
        employmentData: employment.employment,
        approvedResponses: responseOptions,
        agentMessages: user.messages,
        loadingMessages: users.loading,
        loadingResponses: loading,
        loadingEmploymentData: employment.loading,
        loadingEmploymentUpdate: employment.loadingEmploymentUpdate
    };
}

const connectedEmploymentDetails = connect(mapStateToProps)(EmploymentDetails);
export {connectedEmploymentDetails as EmploymentDetails};
