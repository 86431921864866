import React, { Fragment } from 'react'
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const AdminSchedulesEditForm = (props) => {
    const { handleSubmit, onChange, pricingRates, pricingRate, schedule,
        payoutRates, payoutRate, submitting, orgId } = props

    return (
        <Fragment>
            <Link to={`/schedules?organization=${orgId}`}
                className="btn btn-sm"
                style={{border: '1px solid #ededed', fontSize: '17px'}}>
                <i className="fa fa-arrow-left color-green"/>
            </Link>
            <h4 className="font-16-bold color-grey-dark" style={{marginBottom: '15px'}}>
                Update Payout & Pricing For {schedule ? schedule.name : ''} Schedule
            </h4>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <span className="font-14 color-grey-dark">Payout Rates</span>
                        <div style={{marginTop:"3px", display:"flex"}}>
                            <select name="payoutRate"
                            className="form-control"
                            value={payoutRate}
                            onChange={onChange}>
                                <option value="" hidden>Select Payout Rate</option>
                                {payoutRates && payoutRates.map(item =>
                                    <option key={item.id} value={item.id}>
                                        {item.label} - {item.currency.code} {item.amount}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 form-group">
                        <span className="font-14 color-grey-dark">Pricing Rates</span>
                        <div style={{marginTop:"3px", display:"flex"}}>
                            <select name="pricingRate"
                            className="form-control"
                            value={pricingRate}
                            onChange={onChange}>
                                <option value="" hidden>Select Pricing Rate</option>
                                {pricingRates && pricingRates.map(item =>
                                    <option key={item.id} value={item.id}>
                                        {item.label} - {item.currency.code} {item.amount}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div style={{marginTop:"15px"}}>
                        <Button className="btn btn-success btn-block font-15" type="submit" disabled={submitting}>
                            Update Schedule
                        </Button>
                    </div>
                </div>
            </Form>
        </Fragment>
    )
};

export default AdminSchedulesEditForm