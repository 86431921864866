import {SnackBar} from "../components/common";
import {alertMessages} from "_constants";
import {chatdeskUpdatesService} from "_services";
import {chatdeskUpdatesConstants} from "_constants";
import {history} from "../_helpers";


function getAll(params) {
    return dispatch => {
        dispatch(request(params));

        chatdeskUpdatesService.getAll(params)
            .then(
                message => {
                    dispatch(success(message));
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.UPDATES_FETCH_FAILURE)
                }
            );
    };

    function request() {
        return {type: chatdeskUpdatesConstants.FETCH_ALL_REQUEST}
    }

    function success(message) {
        return {type: chatdeskUpdatesConstants.FETCH_ALL_SUCCESS, payload: message}
    }

    function failure(error) {
        return {type: chatdeskUpdatesConstants.FETCH_ALL_FAILURE, payload: error}
    }
}

function save(data) {
    return dispatch => {
        dispatch(request(data));

        chatdeskUpdatesService.save(data)
            .then(
                message => {
                    dispatch(success(message));
                    SnackBar.showMessage(alertMessages.UPDATES_SAVE_SUCCESS);
                    history.push('/companyUpdates')
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.UPDATES_SAVE_FAILURE)
                }
            );
    };

    function request() {
        return {type: chatdeskUpdatesConstants.SAVE_ALL_REQUEST}
    }

    function success(message) {
        return {type: chatdeskUpdatesConstants.SAVE_ALL_SUCCESS, payload: message}
    }

    function failure(error) {
        return {type: chatdeskUpdatesConstants.SAVE_ALL_FAILURE, payload: error}
    }
}

function edit(id, data) {
    return dispatch => {
        dispatch(request(data));

        chatdeskUpdatesService.edit(id, data)
            .then(
                message => {
                    dispatch(success(message));
                    SnackBar.showMessage(alertMessages.UPDATES_EDIT_SUCCESS);
                    history.push('/companyUpdates')
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.UPDATES_EDIT_FAILURE)
                }
            );
    };

    function request() {
        return {type: chatdeskUpdatesConstants.EDIT_REQUEST}
    }

    function success(message) {
        return {type: chatdeskUpdatesConstants.EDIT_SUCCESS, payload: message}
    }

    function failure(error) {
        return {type: chatdeskUpdatesConstants.EDIT_FAILURE, payload: error}
    }
}


const displayErrorMessage = (error, fallback) => {
    let message = fallback
    if (error && (error.userMessage || error.message)){
        message = error.userMessage ? error.userMessage : error.message
    }
    SnackBar.showMessage(message)
};


export const chatdeskUpdatesActions = {
    getAll,
    save,
    edit
}