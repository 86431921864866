import {Box} from "@mui/system";
import Avatar from "../../../../common/Avatar";
import _ from "lodash";
import {getLogoUrl} from "_helpers";
import CompanyInfoHeaderEmptyState from "../../EmptyStates/CompanyInfoHeaderEmptyState";

export default function CompanyInfoHeader({organization, label, loading}) {
    const organizationName = _.get(organization, 'name');
    const organizationLogo = getLogoUrl(_.get(organization, 'logo'));
    const contactEmail = _.get(organization, 'teamLead.username') || 'N/A';

    if(loading) {
        return <CompanyInfoHeaderEmptyState />
    }

    return(
        <Box sx={{display: {xs: 'block', md: 'flex'}}}>
            <Box sx={{mr: '15px', mb: {xs: '10px', md: 0}}}>
                <Avatar
                    src={organizationLogo}
                    userName={organizationName}
                    background='dark'
                    variant={'rounded'}
                    size={'xlarge'}
                    hasBorder
                />
            </Box>
            <Box sx={{}}>
                <Box sx={style.companyName}>{organizationName}</Box>
                <Box sx={style.contactEmailDesc}>{label.CONTACT_EMAIL}</Box>
                <Box sx={style.email}>{contactEmail}</Box>
            </Box>
        </Box>
    )
}

const style = {
    companyName: {
        fontSize: '24px',
        fontFamily: 'larsseitMedium',
        whiteSpace: 'pre',
    },
    contactEmailDesc: {
        fontSize: {xs: '14px', md: '16px'},
        fontFamily: 'larsseitMedium',
        whiteSpace: 'pre',
        mt: {xs: '10px', md: '15px'}
    },
    email: {
        fontSize: '16px',
        fontFamily: 'larsseitRegular',
        whiteSpace: 'pre'
    },
}