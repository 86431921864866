import {SERVER_URL} from "../../config";
import axios from "axios";
import {authHeader} from "_helpers";

function verifyApiToken(apiToken) {
    const endpoint = `${SERVER_URL}/api/v1/dixa/accounts/verification`;
    return axios.post(endpoint, { apiToken },{
        headers: authHeader()
    });
}

function createAccount(apiToken) {
    const endpoint = `${SERVER_URL}/api/v1/dixa/accounts`;
    return axios.post(endpoint, { apiToken },{
        headers: authHeader()
    });
}

export const dixaService = {
    verifyApiToken,
    createAccount,
};