import {currencyConstants} from '_constants';

const INITIAL_STATE = {
    all_currency: [],
    currency: {},
    loading: true,
    error: '',
};

export function currencyReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case currencyConstants.GET_ALL_CURRENCY_REQUEST:
            return {...state, loading: true, error: ''};
        case currencyConstants.GET_ALL_CURRENCY_SUCCESS:
            return {...state, all_currency: action.payload, loading: false};
        case currencyConstants.GET_ALL_CURRENCY_FAILURE:
            return {...state, error: action.error, loading: false};

        case currencyConstants.GET_CURRENCY_REQUEST:
            return {...state, loading: true, error: ''};
        case currencyConstants.GET_CURRENCY_SUCCESS:
            return {...state, pricing: action.payload, loading: false};
        case currencyConstants.GET_CURRENCY_FAILURE:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}
