import React, {Component} from 'react'
import {messageActions} from "_actions";
import {connect} from 'react-redux'
import CenteredGrid from "components/Inbox/CenteredGrid"
import {Loading} from "components/common";
import _ from "lodash";
import {requestOrigin} from "_constants";


class InboxDetail extends Component {

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const notificationType = urlParams.get('type');
        const {id} = this.props.match.params;

        if (notificationType === "priority") {
            this.props.dispatch(messageActions.getConversation(id));
        }

        if (notificationType === "all_messages") {
            this.props.dispatch(messageActions.getConversationFromTrends(id, {}, null, requestOrigin.INBOX_DETAILS));
        }

    }

    render() {
        const openedMessageId = this.props.match.params.id;
        const urlParams = new URLSearchParams(window.location.search);
        const notificationType = urlParams.get('type');
        const { conversation, isDispatching, loading,
            loadingTrendsCorrespondingMessages, trendsCorrespondingMessages,
            companyReplied, loadingCompanyReplied, markAsDone, loadingMarkAsDone } = this.props;

        if (loading || isDispatching || loadingTrendsCorrespondingMessages){
            return <Loading/>
        }

        return (
            <div className="container-pane margin-bottom-50">
                {conversation && <CenteredGrid
                    openedMessageId={openedMessageId}
                    conversation={conversation}
                    notificationType={notificationType}
                    isDispatching={isDispatching}
                    loadingMessage={_.isEmpty(conversation)}
                    trendsCorrespondingMessages={trendsCorrespondingMessages}
                    companyReplied={companyReplied}
                    loadingCompanyReplied={loadingCompanyReplied}
                    markAsDone={markAsDone}
                    loadingMarkAsDone={loadingMarkAsDone}
                />}
            </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        conversation: state.conversation.conversation,
        conversationLoading: state.conversation.loading,
        isDispatching: state.dispatcher.loading,
        trendsCorrespondingMessages: state.messages.trendsCorrespondingMessages,
        loadingTrendsCorrespondingMessages: state.messages.loadingTrendsCorrespondingMessages,
        companyReplied: state.users.companyReplied,
        loadingCompanyReplied: state.users.loadingCompanyReplied,
        markAsDone: state.users.markAsDone,
        loadingMarkAsDone: state.users.loadingMarkAsDone,
    }
}

InboxDetail = connect(mapStateToProps)(InboxDetail);
export { InboxDetail }