import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {removeItemFromObjectArray} from "_helpers";
import {AttributeBuilder} from "../objects/AttributeBuilder";
import AttributeActions from "../../../../common/AttributeActions";

export default function () {
    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingCampaign;
    const deletingAttribute = proactive.deletingCampaignAttribute;
    const deletedAttribute = proactive.deletedCampaignAttribute;
    const campaign = proactive.campaign;
    const attributeList = _.get(campaign, 'attributes') || [];
    const rows = [];

    if(!deletingAttribute && !_.isEmpty(deletedAttribute)) {
        removeItemFromObjectArray(attributeList, 'id', deletedAttribute.attributeId);
    }

    if(!loading && attributeList.length > 0) {
        attributeList.map( attribute => {
            const builder = new AttributeBuilder();
            builder.setName(_.get(attribute, 'name') || '-');
            builder.setType(_.get(attribute, 'type') || '-');
            builder.setValue(_.get(attribute, 'value') || '-');
            builder.setActions(
                <AttributeActions
                    attributeId={_.get(attribute, 'id')}
                    campaignId={_.get(campaign, 'id')}
                />
            )
            rows.push(builder.build());
        });
    }

    const columns = [
        {
            id: 'name',
            label: 'Name',
            width: 50,
        },
        {
            id: 'type',
            label: 'Type',
            width: 50,
            align: 'center',
        },
        {
            id: 'value',
            label: 'Value',
            width: 100,
            minWidth: 100,
        },
        {
            id: 'actions',
            label: ' ',
            width: 30,
            minWidth: 30,
            align: 'right',
        },
    ];
    return {rows, columns};
}