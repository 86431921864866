import React, {useState} from "react"
import {Col, Modal, Row} from "react-bootstrap";
import moment from "moment";
import {chatdeskUpdatesService} from "../../../_services";

const AgentDisplayModal = ({companyUpdates, fromPage}) => {

    const [show, setShow] = useState(true)
    const handleClose = (googleDocsLink) => {
        if (fromPage) chatdeskUpdatesService.reduceDisplayCount(fromPage)
        setShow(false)
        window.open(googleDocsLink, "_blank")
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size='sm'
            className='tm-updates-modal with-border'
        >

            <Modal.Body className="container-fluid">
                <ModalContents
                    updates={companyUpdates}
                    handleClose={handleClose}
                />
            </Modal.Body>

        </Modal>
    )

}

export const ModalContents = ({updates: {data}, handleClose}) => {
    const {organization: {name}, summary, dateCreated, googleDocsLink} = data[0]
    const zone_name = moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();

    return (
        <div className="container-fluid background-white centered no-border font-17 padding-top-15 padding-bottom-20">
            <Row className="padding-bottom-20">
                <Col className='pull-left'>
                    <span>New Updates for {name}</span>
                </Col>
            </Row>

            <Row>
                <Col className='pull-left font-16-heavy'>
                    <span>{summary}</span>
                </Col>
            </Row>

            <Row className="padding-bottom-30">
                <Col className='pull-left'>
                    <div className="padding-top-15 font-italic"><em>Posted
                        on {`${moment(dateCreated).format('MMM DD hh:mm A z ')} ${timezone}`}</em></div>
                </Col>
            </Row>

            <Row>
                <Col className="col-md-offset-2">
                    <button className='btn btn-primary pull-left' onClick={() => handleClose(googleDocsLink)}
                            data-googleDocLink-attr={googleDocsLink}>
                        Review new Updates
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export {AgentDisplayModal as AgentUpdatesDisplayModal}