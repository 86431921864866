import logoFacebook from 'components/images/logos/logo_facebook.svg'
import useFacebookSetup from './useFacebookSetup'
import ChannelCard from '../../ChannelCard'

export default function FacebookEntry() {
	const { initiateFacebookSetup } = useFacebookSetup()
	return (
		<ChannelCard
			icon={logoFacebook}
			label={'Facebook'}
			description={'Respond to comments on Facebook Ads, Mentions, Posts, Reviews and DMs. Hide and moderate spam and negative comments'}
			onClick={initiateFacebookSetup}
		/>
	)
}