import React, {Fragment} from "react";
import useGetMsgDataSources from "../views/Proactive/Utilities/hooks/useGetOrgMsgDataSourcesByProactiveChannel";
import BasicMultiSelect from "./BasicMultiSelect";

export default function ProactiveMsgDataSourceMultiSelect({ name, label, placeholder, handleFormSelectChange, queryParam, input, meta, defaultValues }) {
    const { msgDataSources } = useGetMsgDataSources(queryParam);

    return(
        <Fragment>
            <BasicMultiSelect
                name={name}
                label={label}
                placeholder={placeholder}
                options={msgDataSources}
                handleFormSelectChange={handleFormSelectChange}
                input={input}
                meta={meta}
                defaultValues={defaultValues}
            />
        </Fragment>
    )
}