import React from "react";
import {Box} from "@mui/system";
import chatdeskLogo from 'components/images/logos/logo_chatdesk.svg';
import chatdeskWordMark from "components/images/logos/chatdesk_wordmark_dark_teal.svg";


function ChatdeskSymbol() {
  return (
    <Box sx={{height: 'auto', padding: '25px 0'}}>
      <Box sx={{width: '50px', height: '50px', cursor: 'pointer', zIndex: '10', position: 'relative'}}>
        <a href={'/'}><img src={chatdeskLogo} style={{height: '100%'}} alt={`Chatdesk logo`}/></a>
      </Box>
    </Box>
  )
}

function ChatdeskWordMark() {
  return(
    <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none'}}
         sx={{width: '165px', cursor: 'pointer', zIndex: '10', position: 'relative'}}>
      <a href={'/'}><img src={chatdeskWordMark} style={{width: '100%'}} alt={`Chatdesk Word mark`}/></a>

    </Box>
  )
}

export const Logo = {
  ChatdeskSymbol,
  ChatdeskWordMark
};

