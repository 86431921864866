import {Box, Grid} from "@mui/material";
import DataSourceStatusButtons from "./DataSourceStatusButtons";
import commonStyles from "../commonStyles";

export default function DataSourceSummary({handle, mode, label}) {
    return (
        <Grid container columns={2}>
            <Grid item xs={4} md={1} sx={styles.nameContainer}>
                <Box component={'h4'}>{handle.name}</Box>
            </Grid>
            <Grid item xs={4} md={1} sx={styles.statusContainer}>
                <Box sx={styles.statusLabel}>{label.VISIBILITY}</Box>
                <DataSourceStatusButtons handle={handle} mode={mode} />
            </Grid>
        </Grid>
    );
}

const styles = {
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    statusLabel: {
        ...commonStyles.labelMedium,
        padding: 1,
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: 4
    }
};