import React, {Component} from "react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

/**
 * this select field allows users to search and select multiple dropdown checkboxes
 */
class MultiSelectCheckbox extends Component {

    constructor(props) {
        super(props);
        this.state={
            selectedOptions: [],
            options: []
        }
    }


    getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All Selected`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }

    formatOptions = options => {
        let selectOptions = [{label: 'Select All'}]
        if (options.length > 0) selectOptions.push(options)
    }
    componentDidMount() {
        this.setState({options: [{label: 'Select All', value: '*'}, ...this.props.options]})
    }

    handleChange = (value, event) => {
        const {options} = this.state
        if (event.option.value === "*"){
            if (event.action === "select-option"){
                this.setState({selectedOptions: options});
            } else if (event.action === "deselect-option"){
                this.setState({selectedOptions: []});
            }
        } else {
            if (event.action === "deselect-option"){
                this.setState({selectedOptions: value.filter(op => op.value !== "*")});
            }
            else if (value.length === options.length - 1) {
                this.setState({selectedOptions: options});
            }
            else {
                this.setState({selectedOptions: value});
            }
        }
    }

    updateParent = (options, input) => {
        const data = options.filter(o => o.value !== "*")
        input.onChange(data)
    }

    render = () => {
        const {options, customStyles, placeholderButtonLabel, width, input} = this.props;
        this.updateParent(this.state.selectedOptions, input)
        return (
            <ReactMultiSelectCheckboxes
                options={[{label: 'Select All', value: '*'}, ...options]}
                onChange={this.handleChange}
                parse={this.state.selectedOptions}
                placeholderButtonLabel={placeholderButtonLabel}
                styles={customStyles}
                width={width}
                value={this.state.selectedOptions}
                getDropdownButtonLabel={this.getDropdownButtonLabel}
            />
        );
    }
}


export {MultiSelectCheckbox}
