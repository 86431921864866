import React, { Component } from "react";
import {Button} from "react-bootstrap";
import "components/Message/messages.css";


/**
 * @author Okpala Oluchukwu Chioma <oluchukwu@chatdesk>
 */
class SearchInput extends Component {

    render() {
        const { searchString, onFormFieldChange, searchAdminMessages, searchOnKeyEnter, loading } = this.props;
        return (
            <div className="search-messages-table">
                <div>
                    <input
                        type="text"
                        autoFocus
                        placeholder="Search"
                        className="search-input"
                        name="searchString"
                        value={searchString}
                        onChange={(event) => onFormFieldChange(event)}
                        onKeyDown={searchOnKeyEnter}
                        required/>
                    <Button
                        onClick={searchAdminMessages}
                        className="btn btn-success font-15"
                        type="submit"
                        style={{width:'15%', padding: '12px'}}>{loading ? 'Searching...' : 'Search'}
                    </Button>
                </div>
            </div>
        )
    }
};
export default SearchInput