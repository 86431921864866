import React, {Fragment} from "react";
import {CLIENT_URL} from "config";
import ZendeskSetupModalForm from "../../../../Onboarding/ConnectChannel/Channels/Zendesk/ZendeskSetupModalForm";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";

export default function ZendeskChatSetupModal({
	open,
	handleClose,
	handleOpen
}){
  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Zendesk Chat'}>

        <ZendeskSetupModalForm
          description={'Please copy and paste your Zendesk subdomain here:'}
          placeholder={'yoursubdomain'}
          channelDomain={'.zendesk.com'}
          extraInfoLabel={'View setup instructions'}
          buttonLabel={'Submit'}
          openInNewTab={true}
          handleClose={handleClose}
          callbackUrl={`${CLIENT_URL}/company/connectchannels?channel=ZENDESK_CHAT`}
          type={'CHAT'}
        />
      </BasicModal>
    </Fragment>
  )
}