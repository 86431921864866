/**
 * @param channelSelected {object}
 * @param organizationId {string}
 * @param tokenObj {object}
 * @returns {{}}
 */
export function buildSaveChannelPayload(channelSelected, organizationId, tokenObj) {
    const {id, customUrl} = channelSelected[0] || {};
    const {accessToken, refreshToken, issuedAt, expiresIn} = tokenObj || {};

    return {
        channelId: id,
        channelName: customUrl,
        organizationId,
        accessToken,
        refreshToken,
        issuedAt,
        expiresIn
    };
}