import {Fragment} from "react";
import BasicModal from "../../../../../../common/BasicModal";
import DirectMessageContent from "./DirectMessageContent";


export default function DirectMessageModal({title, open, handleClose, crossDispatchWarningDismissed, onDispatchSuccess, onDispatchFailure}) {

    return(
        <Fragment>
            <BasicModal
                title={title}
                withoutCloseIcon
                open={open}
                style={style.modal}
                handleClose={handleClose}>

                <DirectMessageContent
                    handleClose={handleClose}
                    crossDispatchWarningDismissed={crossDispatchWarningDismissed}
                    onDispatchSuccess={onDispatchSuccess}
                    onDispatchFailure={onDispatchFailure}
                />
            </BasicModal>
        </Fragment>
    )
}

const style = {
    modal: {
        width: '700px',
        padding: '10px 20px 15px 20px',
        title: {
            color: '#0C2728',
            fontSize: '18px',
            fontFamily: 'larsseitRegular',
            marginTop: '4px',
            marginBottom: '15px',
            paddingRight: '35px',
            lineHeight: '120%',
            letterSpacing: '-0.02em',
        },
    }
}