import React from "react";
import {Field, reduxForm} from 'redux-form'
import {Button} from "react-bootstrap";


const UserSearchForm = (props) => {
    const {handleSubmit, onFormSubmit} = props
    const style = {
        "width" :"330px",
        "height" : "40px",
        "float" : "right",
        "border": "1px solid #008751",
        "padding": "12px",
        "text-align":"right"
    }

    return (
        <form onSubmit = {handleSubmit(onFormSubmit)} >
            <Field className="searchInput"
                name = "username"
                component = "input"
                type = "text"
                placeHolder="Search User"
                style={style}
            />

            <Button className="btn btn-success pull-right" type="submit">
                Search User
            </Button>
        </form>
    )
}



const UserSearch = reduxForm({
    form: 'searchUser'
})(UserSearchForm)

export default UserSearch;