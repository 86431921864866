import * as React from 'react';
import {Box} from "@mui/system";
import DeleteAttributeAction from "./DeleteAttributeAction";

export default function AttributeActions({attributeId, campaignId}) {
    return (
        <Box>
            <Box sx={styles.container}>
                <DeleteAttributeAction attributeId={attributeId} campaignId={campaignId} />
                {/*Add more actions*/}
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}