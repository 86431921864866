import React, { Fragment } from "react";
import { ShowMessage } from "components/Inbox";
import { withRouter } from "react-router-dom";


/**
 * @author Chris Okebata
 */


const ShowReplies = ({replies, author, organization}) => {

    return replies.map((reply, it) => {
        return (
            <Fragment key={it}>
                <ShowMessage
                    message={reply}
                    author={reply.author}
                    text={reply.text}
                    dateCreated={reply.dateCreated}
                    organization={organization}
                />
            </Fragment>
        )
    })
};


class ShowConversation extends React.Component{

    render(){
        let { conversation } = this.props;
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const { _embedded: {organization} } = currentUser;

        let author,
            dateCreated,
            link,
            text,
            replies;

        if (conversation) {
            ({ author, dateCreated, link, text } = conversation);

            if (conversation._embedded ) {
                replies = conversation._embedded.replies
            } else {
                replies = conversation.reply;
            }
        }

        return (
            <Fragment>
                { conversation &&
                    <ShowMessage
                        message={conversation}
                        author={author}
                        isReply={false}
                        dateCreated={dateCreated}
                        organization={organization}
                        link={link}
                        text={text}
                    />
                }

                { replies &&
                    <ShowReplies
                        replies={replies}
                        organization={organization}
                    />

                }
            </Fragment>
        )

    }
}

export default withRouter(ShowConversation);