import {SERVER_URL} from "config";
import axios from "axios";
import {authHeader} from "_helpers";


const companySignup = (payload) => {
  const endpoint = `${SERVER_URL}/api/v2/auth/companySignup`;
  return axios.post(endpoint, payload,{
    headers: authHeader()
  })
};

const completeOnBoarding = () => {
  localStorage.removeItem('isOnboarding');
  localStorage.removeItem('currentOnboardingPage');
};

export const authorizationService = {
  companySignup,
  completeOnBoarding
};