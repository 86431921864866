import { oauthConstants } from "_constants";

const INITIAL_STATE = {
    authUrl: '',
    error: '',
    loading: true,
    channel:'tiktokads',
    messageDataSourceId: '',
};

export function tiktokAdsOauthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.TIKTOK_ADS_OAUTH_URL_REQUEST:
            return {...state, loading: true};
        case oauthConstants.TIKTOK_ADS_OAUTH_URL_SUCCESS:
            return {...state, authUrl: action.payload, loading: false};
        case oauthConstants.TIKTOK_ADS_OAUTH_URL_FAILURE:
            return {...state, error: action.error, loading: false};

        case oauthConstants.TIKTOK_ADS_ACCESSTOKEN_REQUEST:
            return {...state, loading: true};
        case oauthConstants.TIKTOK_ADS_ACCESSTOKEN_SUCCESS:
            return {
                ...state,
                messageDataSourceId: action.payload.data ? action.payload.data.id : '',
                loading: false,
            };
        case oauthConstants.TIKTOK_ADS_ACCESSTOKEN_FAILURE:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}
