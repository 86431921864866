import React from "react";
import {Box} from "@mui/system";
import htmlToText from "html-to-text";
import _ from "lodash";
import {getCurrencySymbol, parseHtml} from "_helpers";
import useGetTikTokShopProduct from "../../../hooks/Message/Conversation/useGetTikTokShopProduct";
import TikTokShopCardEmptyState from "./TikTokShopCardEmptyState";

export default function TikTokShopProductCard({typeObj}) {
    const { loading, product } = useGetTikTokShopProduct(_.get(typeObj, 'product_id'));
    const thumbUrl = _.get(product, 'mainImages.0.thumbUrls.0');
    const width = _.get(product, 'mainImages.0.width');
    const title = _.get(product, 'title');
    const description = _.get(product, 'description');
    const price = _.get(product, 'skus.0.price');
    const descriptionPlainText = parseHtml(htmlToText.fromString(description, {wordwrap: false}));

    const resolvePrice = (price) => {
        const currency = getCurrencySymbol(_.get(price, 'currency'));
        const salePrice = _.get(price, 'salePrice');
        return `${currency}${salePrice}`;
    }

    const addDefaultSrc = (ev) => {
        ev.target.src = '';
    };

    if(loading || _.isEmpty(product)) {
        return <TikTokShopCardEmptyState/>;
    }

    return (
        <Box>
            <Box sx={{...styles.container, ...{width: `${width + 21.5}px`,}}}>
                <img src={`${thumbUrl}`}
                     onError={addDefaultSrc}
                     alt={'product image'}
                     style={{...styles.image, ...{maxWidth: `${width}px`}}}
                />
                <Box sx={styles.title}>
                    { title }
                </Box>
                <Box sx={styles.description}>
                    { descriptionPlainText }
                </Box>
                <Box sx={styles.price}>
                    {resolvePrice(price)}
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        backgroundColor: '#FFF',
        border: '1px solid #C1C1C1',
        p: '10px',
        borderRadius: '8px'
    },
    image: {
        borderRadius: '5px',
        border: '1px solid #C1C1C1'
    },
    label: {
        mb: '6px',
        fontFamily: 'LarsseitMedium',
        fontSize: '15px'
    },
    title: {
        mt: '10px',
        fontFamily: 'LarsseitMedium',
        fontSize: '16px'
    },
    description: {
        color: '#495D5E',
        mt: '5px',
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        maxWidth: '100%',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    price: {
        mt: '5px',
        fontFamily: 'LarsseitMedium',
        fontSize: '20px',
        color: '#07857C'
    }
}