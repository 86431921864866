import {Box} from "@mui/system";
import React from "react";
import {textButton} from "../../../Utilities/TextButton";

export default function SkipInfo() {

  const {LinkButton} = textButton;

  return(
    <Box mb={{xs: '100px'}}>
      <Box fontSize={{xs: '16px', sm: '16px', md: '18px', lg: '18px'}}>
        <Box sx={{fontFamily: 'LarsseitMedium', lineHeight: '120%', color: '#0C2728', marginBottom: '1px'}}>
          Looking for more channels?
        </Box>
        <Box sx={{fontFamily: 'LarsseitRegular', lineHeight: '120%', color: '#0C2728'}}>
          Chatdesk supports Gorgias, Re:amaze, Freshdesk, Kustomer, Gladly, and more.
          You'll be able to connect these channels during your onboarding call with your Chatdesk Lead,
          or from your dashboard later.
        </Box>
      </Box>

      <Box mt={{xs: '25px', sm: '25px', md: '15px', lg: '15px'}}>
        <Box fontSize={{xs: '16px', sm: '16px', md: '18px', lg: '18px'}}
             sx={{fontFamily: 'LarsseitMedium', color: '#368C86', lineHeight: '120%', width: 'fit-content'}}>

          <LinkButton label={'Skip this step'} to={'/signup/schedule'}/>
        </Box>
      </Box>
    </Box>
  )
}