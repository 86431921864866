import {createElement} from "react";
import {getSection, getUrlSlug} from "../Utilities/organizationSettingsUtils";

export default function SectionEntry({organizationId}) {
    const slug = getUrlSlug();
    const Section = getSection(slug);

    return (
        <>{ createElement(Section, {organizationId}) }</>
    );
}