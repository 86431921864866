import {TextEditor} from "components/common";
import React, {useState} from "react";
import {handleType} from "_constants";
import {useDispatch} from "react-redux";
import {dispatcherActions} from "_actions";
import Modal from "react-bootstrap/lib/Modal";
import {Button} from "react-bootstrap";

export const PrivateReplyModal = ({platform, messageObject, externalCloseHandler, openedMessageId, crossDispatchWarningDismissed, onDispatchSuccess, onDispatchFailure}) => {
    const [show, setShow] = useState(true);
    const [messageReply, setText] = useState('');

    const handleClose = () => setShow(false);
    const handleInputChange = (text) => setText(text);
    const isEmail = messageObject.dataSourceHandle && [handleType.email, handleType.proactive_email].includes(messageObject.dataSourceHandle.type);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        const payload = {
            'isPrivateComment': true,
            platform,
            openedMessageId,
            isV2Enabled: messageObject.isV2Enabled,
            source: messageObject.sourceName,
            reply: messageReply,
            crossDispatchWarningDismissed: crossDispatchWarningDismissed
        }
        dispatch(dispatcherActions.dispatchMessage(messageObject.id, payload))
            .then((result) => {
                handleClose();
                externalCloseHandler(e);
                if (result.success) {
                    onDispatchSuccess(result?.response);
                } else {
                    onDispatchFailure(result?.response);
                }
            }).catch((error) => console.error('An error occurred when dispatching message: ' + error));
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            className="modal-edit-message"
            enforceFocus={false}
        >

            <Modal.Body>
                <TextEditor
                    handleTextChange={handleInputChange}
                    isEmail={isEmail}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="success" onClick={(e) => handleSubmit(e)}>SEND PRIVATE REPLY</Button>
            </Modal.Footer>
        </Modal>
    )
}
