import _ from "lodash";
import {Fragment} from "react";
import {Box} from "@mui/system";
import LikeAction from "./LikeAction";
import HideAction from "./HideAction";

export default function ReactionEntry({message}) {
    const canBeLiked = _.get(message, 'canBeLiked');
    const canBeHidden = _.get(message, 'canBeHidden');
    const showActions = canBeLiked || canBeHidden; // or with any other actions to be taken

    return(
        <Fragment>
            {
                showActions &&
                <Box sx={{marginTop: '5px', display: 'flex', }}>
                    { canBeLiked && <LikeAction message={message} /> }
                    { canBeHidden && <HideAction message={message} /> }
                    {/*Add another action here*/}
                </Box>
            }
        </Fragment>
    )
}