import { oauthConstants, msgDataSourceConstants } from '_constants';

const INITIAL_STATE = {
    data: {},
    error: '',
    loading: true,
    status: '',
    channel: 'freshdesk',
    showWebhookUrl: false,
    dataSources: []
};

export function freshdeskAuthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.FRESHDESK_AUTH_REQUEST:
            return {...state, loading: true};
        case oauthConstants.FRESHDESK_AUTH_SUCCESS:
            return {
                ...state,
                showWebhookUrl: true,
                data: action.payload,
                loading: false,
            };
        case oauthConstants.FRESHDESK_AUTH_FAILURE:
            return {...state, error: action.error, loading: false};
        case oauthConstants.FRESHDESK_CLOSE_MODAL:
            return { ...state, showWebhookUrl: false, loading: false };
        case msgDataSourceConstants.GET_FRESHDESK_DATA_SOURCE:
            return { ...state, loading: true };
        case msgDataSourceConstants.FRESHDESK_DATA_SOURCE_SUCCESS:
            return { ...state, dataSources: action.payload.dataSources, loading: false };
        case msgDataSourceConstants.FRESHDESK_DATA_SOURCE_ERROR:
            return { ...state, error: action.error, loading: false };
        case msgDataSourceConstants.SAVE_MSG_DATA_SOURCE:
            return { ...state, loading: true };
        case msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_SUCCESS:
            let findIndex = state.dataSources.findIndex(item => item.id === action.payload.msgDataSource.id);
            state.dataSources.splice(findIndex, 1, action.payload.msgDataSource);
            return { ...state, dataSources: state.dataSources, loading: false };
        case msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_ERROR:
            return { ...state, error: action.error, loading: false };
        case oauthConstants.CLOSE_WEBHOOK_MODAL:
            return {...state, showWebhookUrl: false};
        default:
            return state;
    }
}