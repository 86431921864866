import {SERVER_URL} from 'config';
import {authHeader, handleHttpResponse} from '_helpers';

export const appcredentialsService = {
    getAppCredentials,
};

function getAppCredentials() {

    return fetch(`${SERVER_URL}/api/facebook/appCredentials`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}