import {oauthConstants} from "_constants";

const INITIAL_STATE = {
    data: {},
    error: '',
    loading: true,
    status: '',
    channel: 'customerio'
};

export function customerIoAuthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.CUSTOMER_IO_SAVE_ACCOUNT_REQUEST:
            return {...state, loading: true};
        case oauthConstants.CUSTOMER_IO_SAVE_ACCOUNT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case oauthConstants.CUSTOMER_IO_SAVE_ACCOUNT_FAILURE:
            return {...state, error: action.error, loading: false};
        default:
            return state;
    }
}