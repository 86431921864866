import {useMemo} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";

export default function (orderId) {
    const tiktokShop = useSelector(state => state.tiktokShop);
    const orderDetailsList = _.get(tiktokShop, 'orderDetailsList') || [];
    const loading = _.get(tiktokShop, 'loadingOrderDetails');
    const error = _.get(tiktokShop, 'orderDetailsError');

    const orderDetails = useMemo(() => {
        if(!loading && orderDetailsList.length > 0) {
            return orderDetailsList.find( item => {
                return item.id === orderId
            });
        }
    }, [loading, orderDetailsList]) || [];

    return { loading, orderDetails, error };
}