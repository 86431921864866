import React from 'react'

const Checkbox = ({ input, meta, label, type, checked }) => {
    return (
        <div>
            <input type={type}
                   className="tms-checkbox"
                   {...input}
                   checked={checked}
                   autoComplete="off"/>
            <span className="tms-check-mark">
            </span>
        </div>
    );
};

export default Checkbox;