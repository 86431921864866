import React, {Component} from 'react';
import {getCurrentUser} from '_helpers';
import {gorgiasAuthActions, msgSourceActions} from '_actions';
import connect from 'react-redux/es/connect/connect';
import {Field, reduxForm} from "redux-form";
import { DataSourceWebhookModal } from 'components/AccountSettings';
import {SetupLink} from "components/OauthComponents";

class GorgiasConnect extends Component {

    componentDidMount() {
       this.props.dispatch(msgSourceActions.getMsgSourceLink('gorgias'));
    }

    onSubmit(props) {
        const { id } = getCurrentUser();
        const params = {apiKey: props.apiKey, userName: props.userName, baseApiUrl: props.baseApiUrl, userId: id};
        this.props.dispatch(gorgiasAuthActions.verifyKey(params))
    }

    render() {
        const { handleSubmit, pristine, submitting, showWebhookUrl, webhookUrl, platformUrl, msgSourceLink } = this.props;
        return (
            <div className="main-content-wrapper">
                <div className="content-pane-60 margin-0-auto">
                    <div className="padding-20-0">
                        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                            <div>
                                <h1 className="msg-source-submit-header margin-bottom-30">Connect your Gorgias Account</h1>
                                <div>
                                    <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">
                                        <div>Base API Url</div>
                                        <Field
                                            component="input"
                                            type="text"
                                            className="input-field font-16 msg-source-submit-input"
                                            name="baseApiUrl"
                                        />
                                    </label>

                                    <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">
                                        <div>User Name</div>
                                        <Field
                                            component="input"
                                            type="text"
                                            className="input-field font-16 msg-source-submit-input"
                                            name="userName"
                                        />
                                    </label>

                                    <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">
                                        <div>API Key</div>
                                        <Field
                                            component="input"
                                            type="text"
                                            className="input-field font-16 msg-source-submit-input"
                                            name="apiKey"
                                        />
                                    </label>
                                </div>
                                <button type="submit" className='btn btn-success font-16 msg-source-submit-button' disabled={pristine || submitting}>
                                    Submit
                                </button>
                                <SetupLink msgSourceLink={msgSourceLink}/>
                            </div>
                        </form>
                    </div>
                </div>
                <DataSourceWebhookModal
                    dataSourceName="Gorgias"
                    showWebhookUrl={showWebhookUrl}
                    webhookUrl={webhookUrl}
                    platformUrl={platformUrl}
                />
            </div>
        );
    }
}

function validate(values) {
    const errors = {};

    if (!values.baseApiUrl){
        errors.baseApiUrl = "Field cannot be null!!!";
    }

    if (!values.userName){
        errors.userName = "Field cannot be null!!!";
    }

    if (!values.apiKey){
        errors.apiKey = "Field cannot be null!!!";
    }

    return errors;
}

const formConfig = {
    form: 'gorgiasForm',
    validate,
    touchOnBlur: false
};

const mapStateToProps = (state) => {
    const { gorgiasAuth: { data: { webhookUrl, platformUrl }, showWebhookUrl }, user, msgSources: { msgSourceLink } } = state;

    return {
        showWebhookUrl,
        webhookUrl,
        platformUrl,
        user,
        msgSourceLink: msgSourceLink.msgSourceLink
    }
};

const gorgiasConnect = connect(mapStateToProps)(GorgiasConnect);

export default reduxForm(formConfig)(gorgiasConnect);