import React, {useState} from "react";
import {Box, Button, Menu, MenuItem, IconButton} from "@mui/material";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import _ from "lodash";
import useLocationQueryParams from "../../../hooks/useLocationQueryParams";
import {
    formatDateTime,
    queryStringToObject,
    removeChars,
} from "../../../../../_helpers";
import {useDispatch} from "react-redux";
import {dispatcherActions} from "../../../../../_actions";
import * as moment from 'moment-timezone';

export default function ShowMessage(props){
    const {conversation, author, organization, link, text} = props
    let { dateCreated } = props;
    const dispatch = useDispatch()
    const ITEM_HEIGHT = 48;

    const [anchorElement, setAnchorElement] = useState(null)
    const { params } = useLocationQueryParams();

    const open = Boolean(anchorElement);
    const timezone = organization.timezone;

    if (timezone) {
        dateCreated = moment.tz(dateCreated, timezone);
    }

    const handleClose = () => {
        setAnchorElement(null)
    }

    const handleClick = (event) => {
        setAnchorElement(event.currentTarget)
    }

    const updateMessageFromSource = () => {
        const sourceName = _.get(conversation, 'conversation.0.sourceName');
        const isV2Enabled = _.get(conversation, 'conversation.0.isV2Enabled');

        const filterParams = queryStringToObject(params);
        let platform = (filterParams.type === 'all_message') ? 'trends' : 'teams';

        let messageId = _.get(conversation, 'conversation.0.id');
        const updateFields = {'is_hidden': true}
        let _params = {updateFields: updateFields, 'platform': platform, isV2Enabled: isV2Enabled, source: sourceName}

        dispatch(dispatcherActions.updateMessageFromSource(messageId, _params))
    }

    return(
        <Box sx={styles.container}>
            <Box sx={styles.messageInfoContainer}>
                <Box sx={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <Box sx={styles.author}>
                        {author && removeChars(author)}
                    </Box>
                    <Box sx={styles.date}>
                        {formatDateTime(dateCreated)}
                    </Box>
                </Box>
                <Box sx={{ width: "50px"}}>
                    { link &&
                        <IconButton
                            aria-label="More"
                            aria-owns={open ? 'long-menu' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            style={{padding: "5px"}}
                        >
                            <MoreVertIcon size="small"/>
                        </IconButton>
                    }

                    <Menu
                        id="long-menu"
                        anchorEl={anchorElement}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                minWidth: 150,
                                border: "solid 1px #dee2e0",
                                boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)"
                            },
                        }}
                    >
                        <MenuItem sx={styles.menuItem} onClick={handleClose}>
                            <Button sx={styles.textStyle} onClick={updateMessageFromSource}>
                                Hide message
                            </Button>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>

            <Box sx={{margin: "3px 30px 0px 0px",}}>
                <Box sx={styles.content}>
                    {text}
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        borderTop:"1px solid #dee2e0",
        padding:"15px 0"
    },
    messageInfoContainer:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: '35px'
    },
    content: {
        fontFamily: "LarsseitRegular",
        fontSize: "16px",
        color: "#0C2728",
        letterSpacing: "-0.14px",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        lineHeight: "22px",
        // lineHeight: '140%',
    },
    author: {
        fontFamily: "LarsseitRegular",
        fontSize: "14px",
        color: "#0C2728",
    },
    date: {
        fontSize: '13px',
        color: '#495D5E',
        fontFamily: 'LarsseitRegular',
        lineHeight: 1.2,
    },
    menuItem:{
        fontSize:"14px",
        margin:"0 20px",
        padding:"7px 2px",
        background:"#FFFFFF",
        borderBottom:"1px solid transparent",
        ':hover': {
            color: "#285a55",
            backgroundColor: "transparent",
        },
    },
    textStyle: {
        fontSize: '16px',
        padding: '0px',
        color: '#07857C',
        textTransform: 'none',
        fontFamily: 'LarsseitMedium',
        letterSpacing: "-0.14px",
        ':hover': {
            color: "#285a55",
            backgroundColor: "transparent",
            textDecoration: 'none',
        },
        ':focus': {
            color: '#07857C',
            textDecoration: 'none',
        },
    },
}
