import React, {Fragment, useState} from 'react';
import {Grid} from "@mui/material";
import MessageList from "../MessageList";
import ReactPaginate from "react-paginate";
import {useSelector, useDispatch} from "react-redux";
import MetricsConversation from "../MetricsConversation";
import {metricsActions} from "_actions";
import MetricsMessageListEmptyState from "../EmptyStatesAndLoaders/MetricsMessageListEmptyState";
import {MetricsMessageListLoader} from "../EmptyStatesAndLoaders/MetricsMessageListLoader";
import {MetricsConversationLoader} from "../EmptyStatesAndLoaders/MetricsConversationLoader";
import _ from "lodash";
import {isOEmbedSupported} from "../../Vote/Sections/BodySection/Conversation/OEmbed/EmbedUtils";
import ConversationCarousel from "../../Vote/Sections/BodySection/Conversation/ConversationCarousel";
import {shouldDisplayInApp} from "_helpers";

const MetricsMessagesBox = ({messageList, allMessagesCount, eachPageMessagesCount, selectedChannels, pageParams, setPageParams, pageFilter, loading}) => {
    const state = useSelector((state) => state.metrics)
    const conversationThread = state.metricsDetailConversation
    const messageProps = state.messageProps;
    const csatList = state.csatList
    const loadingConversation = state.loadingMetricsDetailConversation
    const dispatch = useDispatch();
    const [messageLink, setMessageLink] = useState("");
    const [messageSource, setMessageSource] = useState("");
    const [messageClicked, setMessageClicked] = useState(false);

    const handlePageSelect = (page) => {
        if (conversationThread?.length > 0) {
            dispatch(metricsActions.clearConversation())
        }
        setPageParams({offset: parseInt(page.selected,10) * 10, currentPage: parseInt(page.selected, 10)})
    }
    const getNextPage = () => {
        const queryOffset = parseInt(pageParams.offset, 10);
        const queryMax = parseInt('10', 10);
        eachPageMessagesCount = parseInt(eachPageMessagesCount, 10)

        if (!eachPageMessagesCount) return 0;

        if ([0, 10, 20, 30, 40, 50, 60, 70, 80, 90].includes(queryOffset) && eachPageMessagesCount === 10) {
            return eachPageMessagesCount + 1;
        } else if (eachPageMessagesCount / queryMax === 1) {
            return (queryOffset / eachPageMessagesCount) + 2;
        } else if (eachPageMessagesCount / queryMax < 1) {
            return (queryOffset / queryMax) + 1;
        } else {
            return 100 // fallback
        }
    };

    const totalPages = getNextPage();
    const messageCountStart = pageParams.offset + 1;
    const totalMsgsCount = allMessagesCount
    const messageCountEnd = pageParams.offset + eachPageMessagesCount
    const {msgSource, handleTypeName, externalParentId} = messageProps;
    const displayInApp = shouldDisplayInApp(msgSource, handleTypeName);
    const showCarousel = isOEmbedSupported(msgSource, displayInApp);
    let firstMessage = conversationThread[0] || {};
    firstMessage = Object.assign(firstMessage, {msgSource, externalParentId});

    function Content() {
        return(
            conversationThread?.length > 0 && conversationThread.map((conversation, index) =>
                <MetricsConversation
                    key={_.get(conversation, 'id')}
                    message={conversation}
                    pageFilter={pageFilter}
                    nextMessage={index + 1 < conversationThread.length ? conversationThread[index + 1] : null}
                    csatList={csatList}
                    msgSource={messageSource}
                />
            )
        )
    }

    return(
        <Fragment>
            <div style={styles.rootWrapper}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={5} lg={5} style={{ width: '543px'}}>
                        <div style={{
                            height: 'calc(100vh - 60px)',
                            borderRight: '1px solid #CED2D0',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '72px',
                                display: 'flex',
                                alignItems: 'left',
                                padding: '25px 339px 28px 25px',
                                border: '0.5px solid #CED4D4',
                                backgroundColor: '#fff',
                                borderTopLeftRadius: '10px',
                                borderRight: 'none'
                            }}>
                                <span style={styles.boxHeading}>INCOMING MESSAGES</span>
                            </div>
                            <div style={{height: 'calc(100% - 110px)'}}>
                                { loading ? <MetricsMessageListLoader /> :
                                    <div style={{overflowY: 'auto', height: '100%'}}>
                                        {messageList && messageList.length === 0 ?
                                            <MetricsMessageListEmptyState /> :
                                            messageList &&
                                            <MessageList
                                                msgList={messageList}
                                                selectedChannels={selectedChannels}
                                                setMessageLink={setMessageLink}
                                                setMessageSource={setMessageSource}
                                                loading={loading}
                                                setMessageClicked={setMessageClicked}
                                            />
                                        }
                                    </div>
                                }
                            </div>

                            <div style={{
                                width: '100%',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '0px 35px',
                                bottom: '0',
                                borderTop: '1px solid #CED2D0',
                            }}>
                                <span> {allMessagesCount ? `${messageCountStart} - ${messageCountEnd} of ${totalMsgsCount}` : ""}</span>
                                {totalPages > 1 &&
                                    <div className="md-pagination numberless-pagination">
                                        <div style={{ fontSize: '14px'}}>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                pageClassName="page-item-none"
                                                pageCount={totalPages}
                                                marginPagesDisplayed={0}
                                                disableInitialCallback
                                                pageRangeDisplayed={2}
                                                forcePage={pageParams.currentPage}
                                                onPageChange={page => handlePageSelect(page)}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                breakClassName="page-item-none"
                                                previousClassName={`previous ${pageParams.currentPage === 1 ? "disabled" : ""}`}
                                                nextClassName={`next ${pageParams.currentPage === totalPages ? "disabled" : ""}`}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} lg={7} style={{width: '802px'}}>
                        <div style={{width: '100%', height: 'calc(100vh - 60px)', borderRadius: '10px'}}>
                            <div style={{
                                width: '100%',
                                height: '72px',
                                display: 'flex',
                                alignItems: 'left',
                                padding: '25px 642px 28px 25px',
                                border: '0.5px solid #CED4D4',
                                backgroundColor: '#fff',
                                borderTopRightRadius: '10px',
                                borderLeft: 'none'
                            }}>
                                <span style={styles.boxHeading}>CONVERSATION</span>
                            </div>
                            <div style={{height: 'calc(100% - 110px)'}}>
                                { messageClicked && loadingConversation ?
                                    <MetricsConversationLoader
                                        pageFilter={pageFilter}
                                    /> :
                                    <div style={{overflowY: 'auto', height: '100%'}}>
                                        {
                                            showCarousel ?
                                                <ConversationCarousel
                                                    InAppContent={Content}
                                                    message={firstMessage}
                                                    loading={loadingConversation}
                                                    isScrollComplete={true}
                                                    usePadding={true}
                                                /> :
                                                <Content />
                                        }
                                    </div>
                                }
                            </div>

                            <div>
                                <div style={{
                                    width: '100%',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    padding: '0px 35px 0px 20px',
                                    bottom: '0',
                                    borderTop: '1px solid #CED2D0',
                                    }}>
                                        {conversationThread?.length > 0 &&
                                            <a className="viewInSourceLink" target="_blank" rel="noreferrer noopener nofollow" href={messageLink}>
                                                View in <span className="viewInSourceLink text-transform-capitalize">{messageSource}</span>
                                            </a>
                                        }
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}

const styles = {
    rootWrapper: {
        width: '100%',
        height: '100%',
        border: '1px solid #D0D9D9',
        marginTop: '35px',
        background: '#fff',
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        borderRadius: '10px',
    },
    heading: {
        fontSize: '18px',
        mr: 2,
        display: {xs: 'none', sm: 'block'}
    },
    input: {
        borderRadius: '9999px',
        overflow: 'hidden',
        width: {xs: '100%', sm: 'auto'},
        position: 'relative',
        backgroundColor: '#368C86',
        display: 'flex',
        alignItems: 'center',

        '*': {
            fontSize: '18px',
            backgroundColor: 'transparent',
            border: 'none!important',
            outline: 'none!important',
            cursor: 'pointer',
            '::placeholder': {
                color: '#fff',
            }
        },
        '.react-bootstrap-daterangepicker-container': {
            width: '100%',

            'input': {
                color: '#fff',
                paddingLeft: '16px',
                paddingRight: '64px',
                marginRight: '-48px',
                width: '280px',
            }
        }
    },
    inputIcon: {
        fontSize: '28px!important',
        flexShrink: 0,
        color: '#fff',
        mr: 2,
        ml: 'auto',
        pointerEvents: 'none',
    },
    boxHeading: {
        fontFamily: 'LarsseitBold',
        letterSpacing: '0.05em',
    },
};

export {MetricsMessagesBox};