import axios from "axios";
import {authHeader} from "_helpers";
import {SERVER_URL} from "../../config";

function listTags(msgDataSourceId) {
    const endpoint = `${SERVER_URL}/api/v1/tags/list`;
    return axios.get(endpoint,{
        params: {msgDataSourceId},
        headers: authHeader()
    })
}

export const tagService = {
    listTags
};