import {TRENDS_SERVER_URL, SERVER_URL} from 'config';
import {authHeader, handleHttpResponse} from '_helpers';

function verifyApiKey(params) {
    return fetch(`${TRENDS_SERVER_URL}/api/v1/kustomer/verifyApiKey`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(params)
        })
        .then(handleHttpResponse);
}

function createAccount(params) {
    return fetch( `${TRENDS_SERVER_URL}/api/v1/kustomer/create`,
        {
            method:'POST',
            headers: authHeader(),
            body: JSON.stringify(params)
        })
        .then(handleHttpResponse);
}

function savePayload(payload){
    return fetch (`${SERVER_URL}/api/kustomer/createMsgDataSource`,
        {
            method:'POST',
            headers: authHeader(),
            body: JSON.stringify(payload)
        })
        .then(handleHttpResponse)
}

export const kustomerAuthService = {
    verifyApiKey,
    createAccount,
    savePayload
};