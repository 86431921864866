/**
 * @author Teyim Pila <pila@chatdesk.com>
 */
import { alertConstants } from '_constants';


function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR, message: '' };
}

export const alertActions = {
    success,
    error,
    clear,
};
