import {Box} from "@mui/system";
import {getAccessibilityMode, navigate} from "../Utilities/organizationSettingsUtils";
import styles from "../organizationMainStyles";
import RemoveCompany from "../Utilities/Offboard/RemoveCompany";
import CompanyInfo from "./CompanyInfo";
import {accessibilityMode, fragmentId} from "../Utilities/organizationSettingsConstants";

export default function Offboard({organizationId}) {
    const mode = getAccessibilityMode();
    if(mode === accessibilityMode.READ_ONLY) {
        navigate(fragmentId.INFO);
        return <CompanyInfo/>;
    }

    return(
        <Box sx={styles.sectionWrapper}>
            <RemoveCompany organizationId={organizationId} mode={mode}/>
        </Box>
    )
}