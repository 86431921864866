import React from "react";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';

function ActionButton({label, action}) {

  return(
    <Button sx={{width: '100%', fontSize: '16px', padding: '0', color: '#368C86', fontFamily: 'LarsseitMedium',
      textTransform: 'none',
      ':hover': {
        color: '#285a55',
        backgroundColor: 'unset'
      }
    }}
    onClick={action}
    disableElevation>
      {label}
    </Button>
  )
}

function LinkButton({label, to}) {

  return(
    <Button sx={{width: '100%', fontSize: '16px', padding: '0', color: '#368C86', fontFamily: 'LarsseitMedium',
      textTransform: 'none',
      ':hover': {
        color: '#285a55',
        backgroundColor: 'unset'
      },
      ':focus': {
        outline: '0'
      }
    }}
    component={Link}
    to={to}
    disableElevation>
      {label}
    </Button>
  )
}

export const textButton = {
  ActionButton,
  LinkButton
};