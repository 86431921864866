import React, { Component } from "react";
import MultiToggle from 'react-multi-toggle';
import "react-multi-toggle/style.css";


class MultiToggleButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.selectedOption
        };
    }


    render = () => {
        const { groupOptions, onToggleChange } = this.props;
        const { value } = this.state;
        const onGroupSizeSelect = value => {
            this.setState({ value: value });
            onToggleChange(value)
        };

        return (
            <span>
                < MultiToggle
                    options={groupOptions}
                    selectedOption={value}
                    onSelectOption={onGroupSizeSelect}
                />
            </span>
        );
    };
}


export { MultiToggleButton }
