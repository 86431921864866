import {responseTimeConstants} from "../_constants";

const INITIAL_STATE = {
  responseTimesDetails: {},
  loading: false,
  error: ''
};

export function responseTimesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case responseTimeConstants.GET_MESSAGES_RESPONSE_TIMES_REQUEST:
      return {...state, error: '', loading: true};
    case responseTimeConstants.GET_MESSAGES_RESPONSE_TIMES_SUCCESS:
      return {...state, responseTimesDetails: action.payload, loading: false};
    case responseTimeConstants.GET_MESSAGES_RESPONSE_TIMES_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
}