import {SERVER_URL} from "../config";
import axios from "axios";
import {authHeader} from "../_helpers";

function uploadImage(formData) {
  const endpoint = `${SERVER_URL}/api/v1/uploads/image`;

  return axios.post(endpoint, formData, {
    headers: authHeader()
  });
}

export const uploadService = {
  uploadImage
}