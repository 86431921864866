import {useEffect, useState } from 'react';
import {Box} from "@mui/system";
import {Divider} from "@mui/material";
import _ from "lodash";
import BasicTextField from "../../BasicTextField";
import CheckboxLabel from "../../CheckboxLabel";
import {isAllSelected, search, selectAll, selectItem, selectOnShiftKeyDown} from "./FilterUtils";
import styles from "./filterStyles";
import FilterItem from "./FilterItem";

export default function Filter({label='Value', options=[], checkedItemsValues, setCheckedItemsValues}) {
    const labelPlural = `${_.toString(label).toLowerCase()}s`;
    const [searchField, setSearchField] = useState('');
    const [searchedOption, setSearchedOption] = useState([]);
    const [eventKeyCode, setEventKeyCode] = useState(undefined);
    const [clickedIndexSet, setClickedIndexSet] = useState(new Set());
    const optionArr = _.isEmpty(searchField) ? options : searchedOption;

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const handleKeyDown = (event) => {
        setEventKeyCode(event.keyCode);
    }
    const handleKeyUp = () => {
        setEventKeyCode(undefined);
    }

    const handleSearch = (event) => {
        let value = _.get(event, 'target.value');
        const result = search(value, options);
        setSearchField(value);
        setSearchedOption(result);
    }

    const handleItemSelect = (clickedIndex, event) => {
        const values = selectItem(event, checkedItemsValues);
        setCheckedItemsValues(values);
        handleShiftSelect(clickedIndex);
    }

    const handleShiftSelect = (clickedIndex) => {
        const selectCount = 2;
        clickedIndexSet.add(clickedIndex);
        const arr = [...clickedIndexSet];
        if(arr.length >= selectCount) arr.shift();
        setClickedIndexSet(new Set(arr));
        if(clickedIndexSet.size === selectCount && eventKeyCode === 16) {
            let values = selectOnShiftKeyDown(clickedIndex, clickedIndexSet, checkedItemsValues, optionArr);
            setCheckedItemsValues(values);
        }
    }

    const handleSelectAll = (options) => (event) => {
        const values = selectAll(options, event);
        setCheckedItemsValues(values);
    }

    let isAllChecked = isAllSelected(optionArr || {}, checkedItemsValues || []);

    const RenderValues = ({options}) => {
        return _.isEmpty(options) ?
            <Box sx={styles.emptyResultDescription}>
                {`No ${_.toString(label).toLowerCase()} found`}
            </Box> :

            options.map((item, index) => {
                return (
                    <Box key={index}>
                        <FilterItem
                            index={index}
                            item={item}
                            checkedItemsValues={checkedItemsValues}
                            handleItemSelect={handleItemSelect} />
                    </Box>
                )
            })
    }

    return(
        <Box sx={{}}>
            <Box sx={styles.filterLabel}>{label}</Box>
            <Box sx={styles.textFieldContainer}>
                <BasicTextField sx={styles.textField} handleChange={handleSearch} placeholder={`Search ${labelPlural}`} />
            </Box>
            <Box sx={{}}>
                <CheckboxLabel
                    label={`All ${labelPlural}`}
                    checked={isAllChecked}
                    onChange={handleSelectAll(optionArr)}
                    disabled={_.isEmpty(optionArr)}
                />
            </Box>
            <Divider orientation={'horizontal'} flexItem sx={styles.sectionDivider} />
            <Box sx={styles.valuesContainer}>
                <Box sx={styles.valuesScrollArea}>
                    <RenderValues options={optionArr} />
                </Box>
            </Box>
        </Box>
    )
}