import React from 'react';
import {Grid, IconButton, Menu, MenuItem, Typography} from "@material-ui/core";
import {
    formatDateTime,
    queryStringToObject,
    removeChars,
    stripTrailingQuestionMarkFromHtmlEntity
} from "_helpers";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {withRouter} from 'react-router-dom';
import moment from 'moment/moment';
import 'moment-timezone';
import {showConversationStyle} from 'components/Inbox/StylesShowConversation'
import htmlParser from "he";
import {dispatcherActions} from "_actions";
import {connect} from "react-redux";
import _ from "lodash";

const btnStyle = {
    backgroundColor: 'transparent',
    border: '0',
    margin: '2px',
    color: '#337ab7',
    padding: '0'
};

const ITEM_HEIGHT = 48;


class ShowMessage extends React.Component {

    state = {
        anchorElement: null,
    };

    /**
     * Identifies the clicked item and opens the popup menu for the three dots
     * @param event
     */
    handleClick = event => {
        this.setState({anchorElement: event.currentTarget});
    };


    /**
     * Responsible for closing the popup menu
     */
    handleClose = () => {
        this.setState({anchorElement: null});
    };

    updateMessageFromSource = () => {
        const { conversation } = this.props;
        const sourceName = _.get(conversation, 'conversation.0.sourceName');
        const isV2Enabled = _.get(conversation, 'conversation.0.isV2Enabled');
        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);
        let platform = (filterParams.type === 'all_message') ? 'trends' : 'teams';

        let messageId = _.get(conversation, 'conversation.0.id');
        const updateFields = {'is_hidden': true}
        let params = {updateFields: updateFields, 'platform': platform, isV2Enabled: isV2Enabled, source: sourceName}

        this.props.dispatch(dispatcherActions.updateMessageFromSource(messageId, params))
    };


    render() {

        const { anchorElement } = this.state;
        const open = Boolean(anchorElement);
        const { author, organization, link, text } = this.props;
        let { dateCreated } = this.props;

        const { messageContent, menuItem, typography } = showConversationStyle;
        const timezone = organization.timezone;

        if (timezone) {
            dateCreated = moment.tz(dateCreated, timezone);
        }

        let parsedMessage;
        if (text) {
            parsedMessage = htmlParser.decode(stripTrailingQuestionMarkFromHtmlEntity(text));
        }

        return (
            <div style={messageContent} key={dateCreated}>
                <div>
                    <Typography component={'span'} style={typography}>
                        <Grid container>
                            <Grid container item md={11} sm={11} xs={11}>
                                <Grid item className="tms-ellipsis">
                                    <div className="font-13  color-grey-dark tms-ellipsis padding-right-20">
                                        {author && removeChars(author)}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className="font-13 color-grey-mid tms-ellipsis">
                                        {formatDateTime(dateCreated)}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item md={1} sm={1} xs={1}>
                                <span className="font-13 color-green" style={{float: "right", marginTop: "-6px"}}>
                                    {link && <IconButton
                                        aria-label="More"
                                        aria-owns={open ? 'long-menu' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleClick}
                                        style={{padding: "5px"}}
                                    >
                                        <MoreVertIcon size="small"/>
                                    </IconButton>}
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorElement}
                                        open={open}
                                        onClose={this.handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                minWidth: 150,
                                                border: "solid 1px #dee2e0",
                                                boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)"
                                            },
                                        }}
                                    >
                                    <MenuItem style={menuItem} onClick={this.handleClose}>
                                        <button type="button" className="btn" style={btnStyle}
                                                onClick={this.updateMessageFromSource}>
                                            Hide message
                                        </button>
                                    </MenuItem>
                                    </Menu>
                                </span>
                            </Grid>
                        </Grid>
                    </Typography>
                </div>
                <div style={{marginRight: "30px"}}>
                    <Typography style={typography}>
                        <span className="no-break-out">{parsedMessage}</span>
                    </Typography>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { conversation } = state;
    return {
        conversation,
    };
}

export default withRouter(connect(mapStateToProps)(ShowMessage));
