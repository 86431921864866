import {showDataSourceStatusConstants} from "../_constants";


const INITIAL_STATE = {
    showDataSourceStatusList: [],
    loading: true,
    error: '',
};

export function dataSourceStatusReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case showDataSourceStatusConstants.GET_ALL_REQUEST:
            return {...state, loading: true};

        case showDataSourceStatusConstants.GET_ALL_SUCCESS:
            return {...state, showDataSourceStatusList: action.payload, loading: false};

        case showDataSourceStatusConstants.GET_ALL_FAILURE:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}