import React, {Component} from 'react';
import {DropDownSelect, GenericModal} from "../common";
import {tiktokOauthActions} from "_actions";
import connect from 'react-redux/es/connect/connect';
import {Field} from "redux-form";


class TikTokSearchModal extends Component{
    state = {
        showModal: false,
        searchKeyword: '',
        msgDataSource: ''
    };

    hideModal = () => {
        this.setState({ showModal: false });
    };

    openModal = () => {
        this.setState({ showModal: true });
    }

    handleDispatch =(event)=> {
        event.preventDefault()
        const {searchKeyword, msgDataSource}= this.state
        const params = {id: msgDataSource, searchKeyword: searchKeyword}
        this.props.dispatch(tiktokOauthActions.updateSearchKeyword(params))
    }

    handleChange =(event)=>{
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        const {showModal, searchKeyword, msgDataSource} = this.state
        const {msgDataSources} = this.props
        const dropdownStyles = { borderColor: "#c4c9c7", boxShadow: "none", color: "#5a5f5e", width: "100%" };
        const mdsInput = {onChange: this.handleChange, value: msgDataSource};
        const mdsOptions = msgDataSources.map(item=>({
            id: item.trendsId,
            name: item.name
        }))

        return (
            <div>
                <button
                    className="font-14 color-green tms-no-underline btn-link"
                    onClick={() => this.openModal()}
                    type="button"
                    style={{padding: 0, outline: 'none', lineHeight: '9px', justifyContent: "flex-end"}}
                >
                    Add search keywords
                </button>
                <GenericModal
                    headerText="Add TikTok Search Keywords"
                    hideFooter
                    show={showModal}
                    onHide={this.hideModal}
                    size="lg"
                    isConversationThread={false}
                    enforceFocus={true}
                >
                    <form onSubmit={this.handleDispatch}>
                        <div>
                            <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">
                                <Field
                                    name="msgDataSource"
                                    fieldName="msgDataSource"
                                    input={mdsInput}
                                    style={dropdownStyles}
                                    options={mdsOptions}
                                    value={msgDataSource}
                                    component={DropDownSelect}/>
                            </label>
                        </div>
                        <div>
                            <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">

                                <input
                                    className="form-control"
                                    type="text"
                                    name="searchKeyword"
                                    onChange={this.handleChange}
                                    value={searchKeyword}
                                />
                            </label>
                        </div>
                        <button type="submit" className='btn btn-success font-16 msg-source-submit-button'
                                onClick={this.hideModal}>
                            Submit
                        </button>
                    </form>

                </GenericModal>
            </div>
        )
    }
}

export default connect(null)(TikTokSearchModal);
