import React, {Component, Fragment} from 'react';
import {authActions} from "_actions";
import { connect } from 'react-redux';

/**
 * @author Chris Okebata
 */

let _this;

class Auth extends Component{

  constructor(props){
    super(props);
    _this = this;
  }

  static processLogout (){
    _this.props.dispatch(authActions.logout());
  }

  render() { return <Fragment/>; }
}

const connectedAuth = connect(null)(Auth);
export { connectedAuth as Auth };