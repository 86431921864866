import React, {Fragment} from "react";
import logoGorgias from 'components/images/logos/logo_gorgias.svg'
import ChannelCard from '../../ChannelCard'
import useGorgiasSetup from "./useGorgiasSetup";
import GorgiasSetupModal from "./GorgiasSetupModal";
import DataSourceWebhookModal from "../../Utilities/DataSourceWebhookModal";
import { useSelector } from "react-redux";

export default function GorgiasEntry() {
	const gorgias = useSelector((state) => state.gorgiasAuth)
	const { initiateGorgiasSetup, gorgiasSetupOpen, handleGorgiasSetupOpen, handleGorgiasSetupClose } = useGorgiasSetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoGorgias}
				label={'Gorgias'}
				description={'Clear your email and chat ticket backlog. Gorgias is one of our most popular integrations.'}
				onClick={initiateGorgiasSetup}
			/>

			<GorgiasSetupModal
				open={gorgiasSetupOpen}
				handleOpen={handleGorgiasSetupOpen}
				handleClose={handleGorgiasSetupClose}
			/>

			<DataSourceWebhookModal
				showWebhookUrl={gorgias?.showWebhookUrl}
				webhookUrl={gorgias?.data?.webhookUrl}
				platformUrl={gorgias?.data?.platformUrl}
				dataSourceName={'Gorgias'}
			/>
		</Fragment>
	)
}