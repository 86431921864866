import React from 'react'
import { FormGroup, Radio } from "react-bootstrap";

class RadioButton extends React.Component {
    render() {

        const { placeHolder, label, style, input, ...rest } = this.props;

        return (

            <FormGroup controlId="formControlsSelect">
                <Radio
                    {...rest}
                    {...input}
                    // value={value}
                    placeholder={placeHolder}
                    onChange={input.onChange}
                    style={style}
                    name={input.name}>{label}</Radio>
            </FormGroup>

        )
    }
}


export { RadioButton }