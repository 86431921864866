import React from 'react'
import { connect } from "react-redux";
import { pricingActions, currencyActions, alertActions } from "_actions";
import PaymentForm from 'components/Payments/PaymentForm'

class EditPricing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentId: '',
            pricingData: {
                currency: '',
                amount: '',
                label: '',
            },
            submitting: false,
            updated: false
        };
    }

    componentDidMount() {
        const { dispatch, match: { params: { itemId }}} = this.props
        dispatch(pricingActions.getPricing(itemId))
        dispatch(currencyActions.getAll())
    }

    componentDidUpdate(prevProps, prevState) {
        const { pricing, match: { params: { itemId }}} = this.props
        const { updated } = this.state
        if (pricing.id === itemId && ! updated) {
            this.setState({
                currentId: pricing.id,
                pricingData: {
                    currency: pricing.currency.id,
                    amount: pricing.amount,
                    label: pricing.label,
                },
                updated: true
            })
        }
    }

    handleChange = (event) => {
        const { target: {name, value}} = event
        this.setState(prevState => ({
            pricingData: {
                ...prevState.pricingData,
                [name]: value
            }
        }))
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { pricingData, currentId } = this.state
        const { dispatch } = this.props
        if (! pricingData.currency || ! pricingData.amount || ! pricingData.label) {
            dispatch(alertActions.error('All Form Fields are required'))
            return
        }
        this.setState({ submitting: true })
        dispatch(pricingActions.updatePricing(pricingData, currentId))
    }

    render() {
        const { currencies } = this.props
        const { pricingData, submitting } = this.state

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <PaymentForm
                    title="Edit Pricing Rate"
                    formData={pricingData}
                    currencies={currencies}
                    submitting={submitting}
                    url="/pricing-rates"
                    actionType="Update"
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { currency: { all_currency }, pricing: { pricing }} = state;

    return {
        currencies: all_currency,
        pricing
    };
}

const editPricingPage = connect(mapStateToProps)(EditPricing);
export { editPricingPage as EditPricing };