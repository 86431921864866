import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

export const roleService = {
    getAll,
    getRole,
    updateRole,
    createRole,
    delete: _delete
};


function getAll(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/roles?${objectToQueryString(params)}`, requestOptions).then(handleHttpResponse);
}

function getRole(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/roles/${id}`, requestOptions).then(handleHttpResponse);
}


function updateRole(role) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(role)
    };

    return fetch(`${SERVER_URL}/api/roles/${role.id}`, requestOptions).then(handleHttpResponse);
}


function createRole(role) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(role)
    };

    return fetch(`${SERVER_URL}/api/roles`, requestOptions).then(handleHttpResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch('/roles/' + id, requestOptions).then(handleHttpResponse);
}

