import { Box, Stack } from '@mui/material';
import { getLogoUrl, getImageUrl } from '_helpers';
import Avatar from 'components/v2/common/Avatar';
import React, {useEffect, useMemo} from 'react';

// Local
/** @todo this is a mock, replace this with real user profile hero image */
import currentUserHeroImage from '../Utilities/default_header_bg.jpg'
import currentUserHeroImage2x from '../Utilities/default_header_bg@2x.jpg'
import { msgSourceActions, organizationActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import ConnectedChannels from "./ConnectedChannels";

/**
 * @param {object} props
 * @param {string} props.profilePicture url or s3 image id of profile picture
 * @param {string} props.firstName
 * @param {string} props.lastName
 * @param {string} props.dateCreated Date string of when user was created. Must be compatible with `new Date()` method.
 * @param {object} props.organization Organization data object.
 */
export default function PersonHeader({
		 profilePicture,
		 firstName,
		 lastName,
		 nickname,
	     dateCreated,
	     organization,
	     personHeaderType
    }) {
	const dispatch = useDispatch()
	const msgSourceInfo = useSelector(state => state.msgSources.msgSources)
	const orgData = useSelector(state => state.organizations.organization);
	const emp = useSelector(state => state.employment)
	const loading = emp.loading;
	const coverImage = orgData && getImageUrl(orgData.coverImage);
	const currentUserAccountCreatedDate = useMemo(() => {
		try {
			if (!dateCreated || !dateCreated.length)
				throw new Error()
			return new Date(dateCreated).toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
		} catch (e) {
			return null;
		}
	}, [dateCreated])

	const currentUserPhoto = loading ? " " : getLogoUrl(profilePicture);
	const currentUserName = loading ? " " : nickname || `${firstName} ${lastName}`;
	const organizationName = loading ? " " : organization?.name;

	useEffect(() => {
		if(organization){
			dispatch(msgSourceActions.getAll({organizationId: organization?.id}))
			dispatch(organizationActions.getOrganization(organization?.id))
		}
	}, [organization])


	return (
		<Box sx={styles.root} component={'header'}>
			<Box sx={styles.heroImageContainer}>
				{ coverImage ?
					<img style={styles.heroImage} src={coverImage} alt="coverImage"/> :
					<Box sx={styles.heroImage} component={'img'}
						 src={currentUserHeroImage}
						 srcSet={`${currentUserHeroImage} 1x, ${currentUserHeroImage2x} 2x`}/>
				}
			</Box>

			<Box sx={styles.content}>
				<Stack direction={{ xs: 'column', lg: 'row' }} spacing={{ xs: 2, lg: 3 }} sx={{ position: 'relative' }}>
					<Box sx={{ marginTop: { xs: -14, lg: -9 } }}>
						<Avatar src={currentUserPhoto} userName={currentUserName} background='dark' variant={'rounded'} size={'xlarge'} hasBorder />
					</Box>
					<Stack direction={{ xs: 'column', lg: 'row' }} sx={{ flex: 1 }} spacing={4}>
						<Box sx={{ flex: 1 }}>
							<Box sx={{ fontSize: '24px', fontFamily: 'larsseitMedium', whiteSpace: 'pre' }}>{currentUserName}</Box>
							<Box sx={{ fontSize: '18px', fontFamily: 'larsseitMedium', whiteSpace: 'pre' }}>{organizationName}</Box>
							{(currentUserAccountCreatedDate && !loading) ? (
								<Box sx={{ mt: 2 }}>Joined {currentUserAccountCreatedDate}</Box>
							) : null}
						</Box>

						{ (personHeaderType === "companyTeam") ?
							<Box sx={{ flex: 1 }}>
								<Box sx={styles.connectedChannelText}>
									<Box sx={styles.connectedChannelText }>CONNECTED CHANNELS</Box>
								</Box>
								<div className="tms-connected-channels-container">
									<div className="tms-connected-channels">
										{ msgSourceInfo && msgSourceInfo.map((msgSourceName) => {
											return <ConnectedChannels msgSourceName={msgSourceName} />
										})}
									</div>
								</div>
							</Box>
							: ""
						}
					</Stack>
				</Stack>
			</Box>
		</Box>
	)
}

const styles = {
	root: {
		borderRadius: '10px',
		border: '1px solid #D0D9D9',
		overflow: 'hidden',
	},
	heroImageContainer: {
		width: '1342px',
		position: 'relative',
		height: '220px',
		background: '#DCD8FF',
	},
	heroImage: {
		display: 'block',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'center',
		border: 'none',
		'&:not([src])': {
			visibility: 'hidden',
		},
	},
	content: {
		p: { xs: 2, lg: 4, },
		backgroundColor: '#fff',
	},
	connectedChannelText: {
		Width : '198px',
		Height : '19px',
		Top: '412px',
		Left : '858px',
		fontSize: '16px',
		fontFamily: 'larsseitMedium',
		lineHeight: '19.2px',
		fontWeight: '700',
	}
}