import { useMemo, useEffect } from 'react';
import { msgSourceActions } from '_actions'
import { useSelector, useDispatch } from 'react-redux';

export default function () {
	const dispatch = useDispatch()
	const msgSources = useSelector((state) => state.msgSources.msgSources)

	useEffect(() => {
		// Dispatch `getAll` to fetch all msgSources into state.
		const currentUser = JSON.parse(localStorage.user);
		const organizationId = currentUser._embedded.organization.id;
		dispatch(msgSourceActions.getAll({ organizationId: organizationId }))
	}, [])

	const filteredMsgSources = useMemo(() => {
		return msgSources.filter(msgSource => {
			const filtered = msgSource.msgDataSources.filter(msgDataSource => {
				return msgDataSource.isActive === true
			})
		
			if (!filtered.length)
				return false
		
			return {
				...msgSource,
				msgDataSources: filtered
			}
		})
	}, [msgSources])

	return { filteredMsgSources }
};