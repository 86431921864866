import _ from "lodash";
import ConversationCarousel from "./ConversationCarousel";
import InAppConversation from "./ConversationType/InAppConversation";
import {isOEmbedSupported} from "./OEmbed/EmbedUtils";
import useVerticalScrollCompleteListener from "../../../../../hooks/Message/Conversation/useVerticalScrollCompleteListener";

export default function Conversation({message, conversation, loading, withDm, isScrollComplete}) {
    let {sourceName, dataSourceHandle} = message;
    sourceName = _.toString(sourceName).toLowerCase();
    const displayInApp = _.get(dataSourceHandle, 'displayInApp');
    const isEmbed = isOEmbedSupported(sourceName, displayInApp);
    const {ref} = useVerticalScrollCompleteListener(message, conversation, loading, isEmbed);

    const InAppContent = <InAppConversation
                            conversation={conversation}
                            message={message}
                            loading={loading}
                            withDm={withDm}
                            refProp={ref} />;

    // if(isEmbed) {
    //     return (
    //         <ConversationCarousel
    //             InAppContent={InAppContent}
    //             message={message}
    //             loading={loading}
    //             isScrollComplete={isScrollComplete} />
    //     )
    // }
    return InAppContent;
}