import React, { Component, Fragment } from "react";
import { stylesTable } from 'components/Inbox/StylesTable'
import {
    IconButton, Menu, MenuItem, MenuList,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import PropTypes from "prop-types";
import { MessagesRowV2 } from "components/Message-V2";
import _ from "lodash";
import EditMessageModalV2 from "./EditMessageModalV2";
import Checkbox from "@material-ui/core/Checkbox";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const btnStyle = {
    backgroundColor: 'transparent',
    fontSize: '14px',
    color: '#5E6763',
};

class MessagesTableV2 extends Component {

    constructor(props){
        super(props);

        this.state = {
            handleStatus: 'LIVE',
            platform: 'teams-only',
            time: Date.now(),
            showModal: false,
            message: '',
            checkedItems: [],
            anchorElement: null,
            showSelectAllCheckbox: false,
            checkboxMessages: [],
        };

        this.ITEM_HEIGHT = 48;
    }



    componentDidMount() {
        const {handleStatus, platform } = this.props;
        this.setState ({
            handleStatus,
            platform
        });

        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    /**
     * Identifies the clicked item and opens the popup menu for the three dots
     * @param event
     */
    handleClick = event => {
        this.setState({anchorElement: event.currentTarget});
    };

    /**
     * Responsible for closing the popup menu
     */
    handleClose = () => {
        this.setState({anchorElement: null});
    };

    closeModalHandler = () => {
        this.setState({ showModal: false });
    };

    showModalHandle = (event, message) => {
        event.preventDefault();
        this.setState({showModal: true, message: message})
    };

    /**
     * Determine if all items are checked
     * @param allItems
     * @param selectedItems
     * @returns {boolean}
     */
    isAllChecked = (allItems, selectedItems) => {
        allItems = allItems || {};
        selectedItems = selectedItems || [];

        return selectedItems.length !== 0 && selectedItems.length === allItems.length;
    };

    /**
     * Select all the items from the checkbox list
     * @param arg
     * @returns {Function}
     */
    selectAll = (arg) => (event) => {
        let allValues = [];
        let { checkedItems } = this.state;

        checkedItems.length = 0;

        const target = event.target;
        if(target.checked){
            arg.map(index => {
                allValues.push(String(index));
                return {allValues}
            });
        }

        this.setState({ checkedItems: allValues });
    };

    /**
     * Select an item from the checkbox list
     * @param event
     */
    selectItem = (event) => {
        const target = event.target;
        const value = _.get(target, 'value');

        let { checkedItems } = this.state;

        if(target.checked) checkedItems.push(value);
        else checkedItems.splice(checkedItems.indexOf(value), 1);

        this.setState({ checkedItems });
    };

    showSelectAll = (value) => {
        this.setState({showSelectAllCheckbox: value});
    };

    getCheckboxMessages = (messageId) => {
        const {checkboxMessages} = this.state;
        checkboxMessages.push(messageId);
        this.setState({ checkboxMessages });
    };


    render() {

        const  { platform, handleStatus, time, checkedItems, anchorElement, showSelectAllCheckbox,checkboxMessages } = this.state;
        const open = Boolean(anchorElement);

        const {
            messages, classes, updateMessageFromSource, blacklist,
            skipMessage, escalateMessage, deleteMessageFromSource, undoMessage,
            likeMessageFromSource, resendForResponseGenList, sendMessageForResponseGen,
            organizations, actionMessage
        } = this.props;
        const isMessageActioned = messages.actioned ? messages.actioned : "";
        let editMessageModal;
        let isAllChecked = this.isAllChecked(checkboxMessages, checkedItems);

        if (this.state.showModal){
            editMessageModal = <EditMessageModalV2
                buttonText="UPDATE"
                onHide={this.closeModalHandler}
                show={this.state.showModal}
                headerText="Update Message From source"
                message={this.state.message}
                updateMessageFromSource={updateMessageFromSource}
                platform={platform}
                closeModalHandler={this.closeModalHandler}
            />
        }

        return (
            <div className="admin-messages-table">
                {editMessageModal}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <thead>
                        <TableRow id="v2TableRow" className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                                <Typography className={classes.typography15}>Organization</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography className={classes.typography15}>Status</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography className={classes.typography15}>Direction</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography className={classes.typography15}>Message</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography className={classes.typography15}>Date Imported</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography className={classes.typography15}>Date Created</Typography>
                            </TableCell>

                            <TableCell className={classes.tableCell} style={{width: "30px"}}/>
                            <TableCell className={classes.tableCell} style={{width: "30px"}}>
                                {showSelectAllCheckbox &&
                                <div>
                                    <Checkbox
                                        onChange={this.selectAll(checkboxMessages)}
                                        checked={isAllChecked}
                                        style={
                                            isAllChecked ?
                                                {color: '#008751', padding: "7px"} :
                                                {color: '#c8c8c8', padding: "7px"}
                                        }
                                    />
                                </div>
                                }
                            </TableCell>

                            <TableCell className={classes.actionTableCell} style={{width: '6%', paddingRight: '20px'}}>
                                <div style={{paddingLeft: '3px'}}>
                                    {checkedItems.length > 0 &&
                                    <Fragment>
                                        <IconButton
                                            aria-label="More"
                                            aria-owns={open ? 'long-menu' : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleClick}
                                            disableRipple={true}
                                            style={{
                                                padding: "1px 0",
                                                fontSize: 2,
                                                borderRadius: '3px',
                                                width: '32px',
                                                border: '1px solid #a8a8a8'
                                            }}
                                        >
                                            <MoreHorizIcon
                                                style={{color: '#808080'}}
                                                size="small"/>
                                        </IconButton>

                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorElement}
                                            open={open}
                                            onClose={this.handleClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: this.ITEM_HEIGHT * 4.5,
                                                    // minWidth: 150,
                                                    border: "solid 1px #dee2e0",
                                                    boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)",
                                                    marginTop: '55px',
                                                    padding: '0px',
                                                    position: 'absolute',
                                                    top: '70px'
                                                },
                                            }}
                                        >
                                            <MenuList className="message-actions-list">
                                                <MenuItem onClick={this.handleClose}
                                                          style={{height: '10px', outline: 'none', padding: '12px 0'}}>
                                                    <button type="button" className="btn" style={btnStyle}
                                                            onClick={() => escalateMessage(this.state.checkedItems)}>
                                                        Escalate
                                                    </button>
                                                </MenuItem>
                                                <MenuItem onClick={this.handleClose}
                                                          style={{height: '10px', outline: 'none', padding: '12px 0'}}>
                                                    <button type="button" className="btn" style={btnStyle}
                                                            onClick={() => skipMessage(this.state.checkedItems)}>
                                                        No response needed
                                                    </button>
                                                </MenuItem>
                                                {!isMessageActioned &&
                                                    <MenuItem onClick={this.handleClose}
                                                          style={{
                                                              height: '10px',
                                                              outline: 'none',
                                                              padding: '12px 0'
                                                          }}>
                                                        <button type="button" className="btn" style={btnStyle}
                                                                onClick={() => actionMessage(this.state.checkedItems)}>
                                                            Action
                                                        </button>
                                                    </MenuItem>
                                                }
                                            </MenuList>

                                        </Menu>
                                    </Fragment>
                                    }
                                </div>

                            </TableCell>

                        </TableRow>
                        </thead>
                        <TableBody>
                            {_.get(messages, 'length') > 0 && messages.length === 0 &&
                            <TableRow className={classes.tableRow}>
                                <TableCell colSpan={4}>There are no messages available</TableCell>
                            </TableRow>
                            }
                            {_.get(messages, 'length') > 0 && messages.map(message => {
                                return <MessagesRowV2
                                    updateMessageFromSource={updateMessageFromSource}
                                    handleStatus={handleStatus}
                                    message={message}
                                    classes={classes}
                                    platform={platform}
                                    blacklist={blacklist}
                                    skipMessage={() => skipMessage(message.id)}
                                    escalateMessage={() => escalateMessage(message.id)}
                                    likeMessageFromSource={likeMessageFromSource}
                                    undoMessage={undoMessage}
                                    deleteMessageFromSource={deleteMessageFromSource}
                                    time={time}
                                    key={message.id}
                                    closeModalHandler={this.closeModalHandler}
                                    showModalHandle={this.showModalHandle}
                                    selectItem={this.selectItem}
                                    selectedMessages={checkedItems}
                                    showSelectAll={this.showSelectAll}
                                    getCheckboxMessages={this.getCheckboxMessages}
                                    resendForResponseGenList={resendForResponseGenList}
                                    sendMessageForResponseGen={sendMessageForResponseGen}
                                    organizations={organizations}
                                    actionMessage={() => actionMessage([message.id])}
                                />
                            })}

                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
};

MessagesTableV2.propTypes = {
    classes: PropTypes.object.isRequired,
}

const StyledMessagesTableV2 = withStyles(stylesTable)(MessagesTableV2);
export { StyledMessagesTableV2 as MessagesTableV2 }