import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {ShowMoreToggle} from "./ShowMoreToggle";
import {Button} from "react-bootstrap";
import {QuillEditor} from "components/common";
import {dispatcherActions} from "_actions";
import {handleType, responseStatus, responseType, votingOptions} from "_constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import htmlParser from 'he';
import {
  canShowAttachment,
  addObjectFromArray,
  removeObjectFromArray,
  canEmbedImage,
  formattedResponseText,
  isObjectEmpty,
  formatText,
  processRejectedResponses,
} from "_helpers";


/**
 * @author Chris Okebata
 */


const styles = {
  wrapper: {
    borderRadius: '4px',
    padding: '0 20px',
    position:'relative',
    backgroundColor: '#fff',
  },
  container: {
    borderRadius: '0px',
    padding: '0px 0 5px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: '0px',
  },
  progressContainer: {
    width: '32px',
    height: '32px',
    zIndex: '10',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-2px'
  },
};

const REAMAZE_SOURCE_NAME = 'reamaze';
const GORGIAS_SOURCE_NAME = "gorgias";
const TIKTOK_SOURCE_NAME = 'tiktok';
const TWITTER_SOURCE_NAME = 'twitter';

class ResponseOption extends Component{

  constructor(props){
    super(props);
    this.editorRef = React.createRef();

    this.state = {
      editedHtmlBody: '',
      attachments: [],
    }
  }

  /**
   * Get the html body for a particular message using the response Id.
   * This triggers an event to the server to make a request to the external source of a message
   * to retrieve the html body
   * @param responseId
   */
  getHtmlBody = (responseId) => {
    this.props.dispatch(dispatcherActions.getHtmlBody(responseId));
  };

  /**
   * This open the editor
   * @param responseId
   * @param isEditingText
   * @param msgDataSource
   * @param responseOption
   */
  openEditor = (responseId, isEditingText, msgDataSource, responseOption) => {
    const {dispatch, messageHandleType, onSelectEditResponse, getObjectProperty, setObjectProperty, editedResponseObj, sourceName} = this.props;
    dispatch(dispatcherActions.clearHtmlBody());

    const isMacro = msgDataSource && msgDataSource.isPlatformMacroEnabled
    if (isMacro) {
      // for messages that have Zendesk Macro enabled, we don't want to get htmlBody, so we set text to editedResponseObj
        if (isObjectEmpty(getObjectProperty(editedResponseObj, _.get(responseOption, 'id')))) {
            setObjectProperty(editedResponseObj, _.get(responseOption, 'id'), { text: formatText(_.get(responseOption, 'htmlBody') || _.get(responseOption, 'text'), sourceName, isMacro) });
        }
    }
    else if(!isEditingText && messageHandleType === _.get(handleType, 'email')){
      this.getHtmlBody(responseId);
    }

    onSelectEditResponse(responseId);
  };

  /**
   * This handles the state change of a text in the editor
   * @param text
   */
  handleTextChange = (text) => {

    let {editedResponseObj, setObjectProperty, responseOption} = this.props;
    const responseId = _.get(responseOption, 'id');
    setObjectProperty(editedResponseObj, responseId, { text: text });

    this.setState({
      editedHtmlBody: text,
    })
  };

  processSelectedVote = (isEditSelected) => {
    let {author, agentName, responseOption, messageHandleType, htmlBodyData, submitVoteData, editedResponseObj, getObjectProperty, sourceName, msgDataSource} = this.props;
    const { attachments } = this.state;
    let _votePayload = [], votePayload;
    const { text } = responseOption;
    const isEmail = [handleType.email, handleType.proactive_email].includes(messageHandleType);
    let responseText = '';
    const {isProactive, response_options} = this.props.message;
    const isMacro = msgDataSource && msgDataSource.isPlatformMacroEnabled;

    responseOption = {
      ...responseOption, text: formattedResponseText(text, author, agentName, messageHandleType)
    };

    votePayload = {
      response_id: responseOption.id,
      tag: votingOptions.APPROVE,
      text: responseOption.text,
      attachments: attachments
    };

    _votePayload.push(votePayload);

    if(isProactive) {
      _votePayload = _votePayload.concat(processRejectedResponses(responseOption.id, response_options));
    }

    if(isEmail) {
      responseText = this.state.editedHtmlBody || _.get(getObjectProperty(editedResponseObj, responseOption.id), 'text')
          || formatText(_.get(htmlBodyData, 'html_body'), sourceName, isMacro) || responseOption.text;
    }else {
      responseText = _.get(getObjectProperty(editedResponseObj, responseOption.id), 'text') || responseOption.text;
    }


    if(isEditSelected) {
      submitVoteData(
        _votePayload,
        responseOption.id,
        isEmail ? responseType.editedHtml : responseType.editedText,
        responseText
      );
    }else {
      submitVoteData(
        _votePayload,
        responseOption.id,
        responseType.singleApprove,
        responseOption.text || _.get(getObjectProperty(editedResponseObj, responseOption.id), 'text')
      );
    }
  };

  renderResponseStatus = (status) => {
    return (
      <div className="color-white font-13" style={{backgroundColor: '#ECB101',
        padding: '1px 5px 0 5px', borderRadius: '4px', marginLeft: '10px'}}>
        {status}
      </div>
    )
  };

  handleSelectFile = (data) => {
    const result = addObjectFromArray(this.state.attachments, data);
    this.setState({ attachments: result });
  };

  handleDeselectAttachment = (attachmentId) => {
    const result = removeObjectFromArray(this.state.attachments, attachmentId, 'id');
    this.setState({ attachments: result });
  };

  render() {

    const {
      onSelectResponse, isResponseSelected, isEditing, selectedResponseId, responseOption, index,
      messageHandleType, agentName, author, htmlBodyData, loading, responseCount,
      editedResponseObj, getObjectProperty, setObjectProperty, sourceName, closeModal, msgDataSource
    } = this.props;
    const { wrapper, container, progressContainer } = styles;
    const {id, text, addedById} = responseOption;
    let isSelected = isResponseSelected && selectedResponseId === id;
    let isEditSelected = isEditing && selectedResponseId === id;
    let isEditingText = !isObjectEmpty(getObjectProperty(editedResponseObj, id));
    const responseOptionText = formattedResponseText(text, author, agentName, messageHandleType);
    let isEmail = messageHandleType === _.get(handleType, 'email');
    let openEditor = false;
    let includedResponseId = Object.keys(editedResponseObj || {}).includes(id);
    let _responseStatus;
    let isAttachmentSupported = canShowAttachment(messageHandleType, sourceName);
    let isImageEmbedSupported = canEmbedImage(messageHandleType, sourceName);
    const isMacro = msgDataSource && msgDataSource.isPlatformMacroEnabled;

    //If the selected response option has html_body data and its id has not been added to the editedResponseObj, then add it
    if(isEditSelected && htmlBodyData.hasOwnProperty('html_body') && !(editedResponseObj.hasOwnProperty(id))){
      setObjectProperty(editedResponseObj, _.get(htmlBodyData, 'response_id'), { text: formatText(_.get(htmlBodyData, 'html_body'), sourceName, isMacro) });
    }

    let _responseTitle = `Response #${index}`;
    if (isMacro) {
      _responseTitle = responseOption.title
    }

    if((isEmail && isEditingText) || !isEmail) {
      openEditor = isEditSelected;
    }
    else if(isEmail){
      openEditor = isEditSelected && !loading && !isObjectEmpty(htmlBodyData);
    }

    let responseContainer;

    if((sourceName && sourceName.toLowerCase() === REAMAZE_SOURCE_NAME) ||
        ((sourceName && sourceName.toLowerCase() === GORGIAS_SOURCE_NAME && (messageHandleType === _.get(handleType, 'chat'))))){
      isEmail=true
    }

    let isCharDisplay=false;
    let charLength;

    if(sourceName && sourceName.toLowerCase() === TIKTOK_SOURCE_NAME){
        isCharDisplay=true
        charLength = 150
    }

    if(sourceName && sourceName.toLowerCase() === TWITTER_SOURCE_NAME){
        isCharDisplay=true
        charLength = 280
    }

    if(openEditor){
      responseContainer = <QuillEditor
          defaultText={
              _.get(getObjectProperty(editedResponseObj, id), 'text') ||
              formatText(_.get(htmlBodyData, 'html_body'), sourceName, isMacro) ||
              responseOptionText
          } // the text that is currently being edited is prioritized here

          handleTextChange={this.handleTextChange}
          isEmail={isEmail}
          length={charLength}
          isCharDisplay={isCharDisplay}
          ref={this.editorRef}
          selectedAttachments={this.state.attachments}
          handleSelectFile={this.handleSelectFile}
          handleDeselectAttachment={this.handleDeselectAttachment}
          isAttachmentSupported={isAttachmentSupported}
          isImageEmbedSupported={isImageEmbedSupported}
      />
    }
    else if(isSelected && !isEditing){
      responseContainer = <div className="font-15 color-black-fade">{ htmlParser.decode(responseOptionText) }</div>
    }else {
      responseContainer = <div>
                            <ShowMoreToggle
                              key={index}
                              index={index}
                              text={responseOptionText}
                              usePopover={true}
                            />
                          </div>
    }

    if(!isObjectEmpty(addedById) && !includedResponseId){
      _responseStatus = this.renderResponseStatus(responseStatus.JUST_ADDED);
    }
    else if(includedResponseId){
      _responseStatus = this.renderResponseStatus(responseStatus.EDITED);
    }

    let editButton;

    if(isEditSelected && !loading){
      editButton = <div style={{marginRight: '5px'}}>
                    <Button
                      onClick={() => closeModal()}
                      id="submitEditButton"
                      className="btn btn-block btn-default"
                      style={{ height: '28px', lineHeight: '0px', fontSize: '14px', width: '80px', padding: '0'}}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </div>
    }
    else {
      editButton = <div style={{marginRight: '5px'}}>
                    <Button
                      onClick={() => this.openEditor(id, isEditingText, msgDataSource, responseOption)}
                      id="submitEditButton"
                      className="btn btn-block btn-default"
                      style={{ height: '28px', lineHeight: '0px', fontSize: '14px', width: '80px', padding: '0'}}
                      type="button"
                      disabled={loading ? loading : isEditSelected}
                    >
                      Edit
                    </Button>
                  </div>
    }

    return(
      <div key={index} style={ isSelected ? {...wrapper, zIndex: '1', boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.5)'}: wrapper}>
        <div className="font-14 color-grey-mid" style={{padding: '12px 0 6px 0', display: 'flex',}}>
          <span>{_responseTitle}</span>
          {_responseStatus}

        </div>

        <div style={{borderBottom: '1px solid #CED2D0', padding: '0 0 12px 0'}}>

          <div style={isSelected || responseCount === index ? container : {...container}}>
            <div style={{width: 'calc(100%)'}}>
              { responseContainer }
            </div>
          </div>

          <div style={{display: 'flex', marginTop: '5px'}}>
            <div style={{}}>
              {
                isEditSelected && loading &&
                <div style={progressContainer}>
                  <CircularProgress size={20} style={{color: '#008751'}}/>
                </div>
              }
              { editButton }
            </div>

            <div style={{marginLeft: '5px'}}>
              { isSelected ?
                <Button
                  onClick={() => this.processSelectedVote(isEditSelected)}
                  id="submitEditButton"
                  className="btn btn-block btn-default button-helper"
                  style={{ height: '28px', lineHeight: '0px', fontSize: '14px', width: '80px', padding: '0'}}
                  type="button"
                  disabled={loading}
                >
                  Submit
                </Button> :
                (
                  isEmail ? <Fragment/> :
                  <Button
                    onClick={() => onSelectResponse(id)}
                    id="submitEditButton"
                    className="btn btn-block btn-default button-helper"
                    style={{ height: '28px', lineHeight: '0px', fontSize: '14px', width: '80px', padding: '0'}}
                    type="button"
                  >
                    Perfect
                  </Button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

}

function mapStateToProps(state) {
  const { dispatcher: { htmlBody, loading } } = state;

  return {
    htmlBodyData: htmlBody,
    loading,
  };
}

const connectedResponseOption = connect(mapStateToProps)(ResponseOption);
export { connectedResponseOption as ResponseOption };
