import logoSalesforce from 'components/images/logos/logo_salesforce.svg'
import useSalesforceSetup from './useSalesforceSetup'
import ChannelCard from '../../ChannelCard'

export default function SalesforceEntry() {
    const {initiateSalesforceSetup} = useSalesforceSetup({
        callbackPath: '/salesforce/accounts',
        testCallbackPath: '/salesforce-test/accounts'
    })
    return (
        <ChannelCard
            icon={logoSalesforce}
            label={'Salesforce'}
            description={'Respond to customer messages from cases'}
            onClick={initiateSalesforceSetup}
            allowTestInstanceConnection={true}
        />
    )
}