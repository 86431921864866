import {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {proactiveActions} from "_actions";

export default function DeleteAttributeAction({attributeId, campaignId}) {
    const dispatch = useDispatch();
    const proactive = useSelector(state => state.proactive);
    const deletingAttribute = proactive.deletingCampaignAttribute;
    const requestAttributeId = proactive.requestAttributeId;
    const isDeleting = deletingAttribute && requestAttributeId === attributeId;

    const handleAttributeDelete = () => {
        dispatch(proactiveActions.deleteCampaignAttribute({attributeId, campaignId}));
    }

    return(
        <Fragment>
            {
                isDeleting ?
                    <Box sx={styles.progressIconContainer}>
                        <CircularProgress size={17} sx={styles.progressIcon}/>
                    </Box>:
                    <Tooltip
                        title={'Delete attribute'}
                        componentsProps={{tooltip: { sx: styles.tooltip }}}>
                        <IconButton size={'small'} onClick={handleAttributeDelete} disabled={deletingAttribute}>
                            <DeleteIcon sx={styles.deleteIcon}/>
                        </IconButton>
                    </Tooltip>
            }
        </Fragment>
    )
}

const styles = {
    deleteIcon: {
        color:'#07857C',
        fontSize: '22px',
        textAlign: 'right'
    },
    tooltip: {
        backgroundColor: '#DBD8FF',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        fontSize: '13px',
    },
    progressIcon: {
        color:'#07857C',
        fontSize: '25px'
    },
    progressIconContainer: {
        width: '32px',
        height: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}