import {BasicPopover} from "../../../common/BasicPopover";
import EditButton from "./EditButton";
import {Box} from "@mui/material";
import BasicButton from "../../../common/BasicButton";
import React, {Fragment, useRef, useState} from "react";
import EditCompanyProfile from "./EditCompanyProfile";
import ImageEditor from "./ImageEditor";
import {useParams} from "react-router-dom";
import {organizationActions} from "../../../../../_actions";
import {useDispatch} from "react-redux";

export default function CompanyDropdownOptions(){
    const [openEditProfile, setOpenEditProfile] = useState(false);
    const [showProfileUpload, setShowProfileUpload] = useState(false);
    const [showCoverUpload, setShowCoverUpload] = useState(false);
    const dispatch = useDispatch()
    const ref = useRef();
    const params = useParams();
    const {id} = params;
    const imageCropRatio = 151/40;

    const toggleEditProfile = () => setOpenEditProfile(!openEditProfile)
    const toggleEditImage = () => setShowProfileUpload(!showProfileUpload)
    const toggleEditCoverImage = () => setShowCoverUpload(!showCoverUpload)

    const handleSave = (params) => {
        dispatch(organizationActions.updateOrganization(params))
        toggleEditProfile()
    }

    return(
        <Fragment>
            <BasicPopover
                ref={ref}
                id={'edit-company-profile-popup'}
                ButtonComponent={EditButton}>
                <Box sx={styles.popupContainer}>
                    <BasicButton
                        label={"Edit profile"}
                        style={styles.editButton}
                        action={toggleEditProfile}
                    />
                    <BasicButton
                        label={"Edit profile picture"}
                        style={styles.editButton}
                        action={toggleEditImage}
                    />
                    <BasicButton
                        label={"Edit cover image"}
                        style={styles.editButton}
                        action={toggleEditCoverImage}
                    />
                </Box>
            </BasicPopover>

            <EditCompanyProfile
                openEditProfile={openEditProfile}
                handleCloseEditProfile={toggleEditProfile}
                handleSave={handleSave}
            />
            <ImageEditor
                openImageEditor={showProfileUpload}
                handleCloseEditImage={toggleEditImage}
                userId={id}
                type="organizations"
                cropRatio={imageCropRatio}
            />
            <ImageEditor
                openImageEditor={showCoverUpload}
                handleCloseEditImage={toggleEditCoverImage}
                userId={id}
                type="employments"
                cropRatio={imageCropRatio}
                headerText="Upload cover image"
            />
        </Fragment>
    )
}

const styles = {
    popupContainer: {
        width: "200px",
        padding: "4px 0px"
    },
    editButton: {
        padding: "9px 15px",
        background: "transparent",
        borderRadius: "0px",
        justifyContent: "flex-start",
        color: '#368C86',
        "&:hover": {
            backgroundColor: "#E6E9E9",
            color: '#368C86',
        },
    }
}