import {combineReducers} from 'redux';

import {reducer as formReducer} from 'redux-form';

import {
    alertReducer,
    authenticationReducer,
    conversationReducer,
    originalConversationReducer,
    dataSourceHandleReducer,
    employmentsReducer,
    facebookOauthReducer,
    instagramReducer,
    messageReducer,
    msgDataSourceReducer,
    msgSourceReducer,
    organizationReducer,
    responseOptionReducer,
    responseTimesReducer,
    rolesReducer,
    statsReducer,
    systemSettingsReducer,
    twitterOauthReducer,
    usersReducer,
    utilityReducer,
    voteReducer,
    dispatcherReducer,
    appcredentialsReducer,
    schedulesReducer,
    zendeskOauthReducer,
    frontAppOauthReducer,
    stripeOauthReducer,
    payoutsReducer,
    pricingReducer,
    subscriptionsReducer,
    currencyReducer,
    slackOauthReducer,
    gorgiasAuthReducer,
    gorgiasMsgDataSourceReducer,
    kustomerOauthReducer,
    kustomerMsgDataSourceReducer,
    walletReducer,
    searchReducer,
    freshdeskAuthReducer,
    fullEmailConversationReducer,
    agentScoreReducer,
    portalReducer,
    dataSourceStatusReducer,
    helpScoutOauthReducer,
    websocketReducer,
    screenshotsReducer,
    reamazeAuthReducer,
    gladlyAuthReducer,
    tiktokOauthReducer,
    jiraReducer,
    shopifyOauthReducer,
    similarityScoreReducer,
    auth0Reducer,
    chatdeskUpdatesReducer,
    authorizationReducer,
    metricsReducer,
    billingReducer,
    uploadReducer,
    tiktokAdsOauthReducer,
    attributesReducer,
    salesforceOauthReducer,
    salesforceOrgConfigReducer,
    applicationsReducer,
    proactiveReducer,
    autoActionReducer,
    trustpilotReducer,
    companyResourcesReducer,
    oEmbedReducer,
    earningsReducer,
    customerIoAuthReducer,
    youtubeReducer,
    organizationSettingsReducer,
    tiktokShopReducer,
    dixaReducer,
    tagReducer
} from "_reducers";

const rootReducer = combineReducers({
    auth: authenticationReducer,
    messages: messageReducer,
    conversation: conversationReducer,
    originalConversation: originalConversationReducer,
    organizations: organizationReducer,
    msgDataSources: msgDataSourceReducer,
    msgSources: msgSourceReducer,
    dataSourceHandles: dataSourceHandleReducer,
    responseOptions: responseOptionReducer,
    responseTimes: responseTimesReducer,
    employment: employmentsReducer,
    attributes: attributesReducer,
    users: usersReducer,
    roles: rolesReducer,
    alert: alertReducer,
    vote: voteReducer,
    form: formReducer,
    twitterOauth: twitterOauthReducer,
    facebookOauth: facebookOauthReducer,
    zendeskOauth: zendeskOauthReducer,
    frontAppOauth: frontAppOauthReducer,
    appcredentials: appcredentialsReducer,
    instagramOauth: instagramReducer,
    utility: utilityReducer,
    stats: statsReducer,
    systemSettings: systemSettingsReducer,
    dispatcher: dispatcherReducer,
    schedules: schedulesReducer,
    stripeOauth: stripeOauthReducer,
    payout: payoutsReducer,
    pricing: pricingReducer,
    subscription: subscriptionsReducer,
    currency: currencyReducer,
    slackOauth: slackOauthReducer,
    gorgiasAuth: gorgiasAuthReducer,
    gorgiasMsgDataSource: gorgiasMsgDataSourceReducer,
    kustomerOauth: kustomerOauthReducer,
    kustomerMsgDataSource: kustomerMsgDataSourceReducer,
    wallet: walletReducer,
    search: searchReducer,
    freshdeskAuth: freshdeskAuthReducer,
    fullEmailConversation: fullEmailConversationReducer,
    agentScore: agentScoreReducer,
    portals: portalReducer,
    showDataSourceStatus: dataSourceStatusReducer,
    helpScoutOauth: helpScoutOauthReducer,
    websocket: websocketReducer,
    screenshots: screenshotsReducer,
    reamazeAuth: reamazeAuthReducer,
    gladlyAuth: gladlyAuthReducer,
    tiktokOauth: tiktokOauthReducer,
    tiktokAdsOauth: tiktokAdsOauthReducer,
    jira: jiraReducer,
    shopifyOauth: shopifyOauthReducer,
    similarityScore: similarityScoreReducer,
    auth0: auth0Reducer,
    chatdeskUpdates: chatdeskUpdatesReducer,
    authorization: authorizationReducer,
    metrics: metricsReducer,
    billing: billingReducer,
    uploads: uploadReducer,
    salesforceOauth: salesforceOauthReducer,
    salesforceOrgConfig: salesforceOrgConfigReducer,
    applications: applicationsReducer,
    proactive: proactiveReducer,
    autoAction: autoActionReducer,
    companyResources: companyResourcesReducer,
    trustpilotAuth: trustpilotReducer,
    oEmbed: oEmbedReducer,
    earnings: earningsReducer,
    customerIoAuth: customerIoAuthReducer,
    youtube: youtubeReducer,
    organizationSettings: organizationSettingsReducer,
    tiktokShop: tiktokShopReducer,
    dixa: dixaReducer,
    tag: tagReducer
});

export default rootReducer;
