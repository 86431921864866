import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingPlaceholderBlock from "../common/LoadingPlaceholderBlock";


/**
 * @author Okpala Oluchukwu Chioma <oluchukwu@chatdesk>
 */

const SearchLoading = () => {

    return(
        <div style={{width: '100%', height: '300px'}}>
            <div className="margin-bottom-10">
                <LoadingPlaceholderBlock width={'100px'} height={'24px'} borderRadius={'3px'}/>
            </div>
            <div style={{marginBottom: '10px'}}>
                <LoadingPlaceholderBlock height={'50px'} borderRadius={'3px'}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh', marginBottom: '10px', borderRadius: '3px', background: '#eae9e9'}}>
                <CircularProgress style={{color: '#008751'}}/>
            </div>
            <div className="color-black-fade" style={{float: 'right', marginTop: '10px'}}>
                <LoadingPlaceholderBlock height={'50px'} width={'100px'} borderRadius={'3px'}/>
            </div>
        </div>
    );
};

export {SearchLoading}