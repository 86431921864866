import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {dataSourceHandleActions, scheduleActions} from "_actions";
import {connect} from "react-redux";
import {SchedulesForm} from "./ScheduleForm";
import moment from "moment";
import {Loading} from "components/common";

const styles = theme => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        top: '140px'
    },
    paper: {
        padding: theme.spacing.unit * 4,
        margin: 'auto',
        maxWidth: 700,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    table: {
        root: {
            width: '100%',
            overflowX: 'auto',
        },
        minWidth: 650,
    },
    formLabel: {
        padding: "30px 0"
    },
    header: {
        paddingLeft: "25px"
    }

});

class AddSchedule extends Component {

    constructor(props){
        super(props);
        this.submit = this.submit.bind(this)
    }

    submit(payload){
        const format = "MM/DD/YYYY HH:mm:ss";
        let {startTime, endTime} = payload;
        startTime = moment(startTime).format(format);
        endTime = moment(endTime).format(format);
        const { match: { params: {id}} } = this.props;
        const data = {
            ...payload,
            startTime: startTime,
            endTime: endTime,
            organization: id
        };
        this.props.addSchedule(data)
    };

    componentDidMount() {
        const { match: { params: {id}} } = this.props;
        this.props.getHandles({organization: id})
    }

    render(){
       const {classes, dataSourceHandles, loading} = this.props;
       if (loading) return <Loading/>

       const options = dataSourceHandles.length > 0 ? dataSourceHandles.map(handle => {
           return {label: handle.name, value: handle.id}
       }) : []

       return (
           <div className={classes.root}>
               <Paper className={classes.paper}>
                   <Grid item xs={12} >
                       <h3 className={classes.header}> Add Schedule</h3>
                       <SchedulesForm
                           onSubmit={this.submit}
                           options={options}
                       />
                   </Grid>
               </Paper>
           </div>
       )
   }

}

const mapStateToProps = state => {
    const { dataSourceHandles: {dataSourceHandles, loading} } = state;
    return {
        dataSourceHandles: dataSourceHandles,
        loading
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getHandles: (params) => dispatch(dataSourceHandleActions.getAll(params)),
        addSchedule: (data) => dispatch(scheduleActions.addSchedule(data))
    }
}

const styledComponent = withStyles(styles)(AddSchedule)
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(styledComponent)
export {connectedComponent as AddSchedules}