import React, {useRef} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import {QuillEditor} from "../../../../../../common";
import {
    canEmbedImage,
    canShowAttachment,
    getObjectProperty,
    setObjectProperty,
    addObjectFromArray,
    removeObjectFromArray,
    formatText,
} from "_helpers";
import useViewHtmlBody from "../../../Utilities/hooks/useViewHtmlBody";

export default function ResponseEditor({
    responseId,
    text,
    isEmail,
    charLength,
    isCharDisplay,
    editedResponseObj,
    sourceName,
    messageHandleType,
    attachments,
    setAttachments,
    handleEditedResponse,
    isMacro,
    selectedTags,
    handleRemoveTag
}) {
    const editorRef = useRef();
    const isAttachmentSupported = canShowAttachment(messageHandleType, sourceName);
    const isImageEmbedSupported = canEmbedImage(messageHandleType, sourceName);
    const {htmlBody, loadingHtmlBody} = useViewHtmlBody();

    const getEditorText = () => {
        return _.get(getObjectProperty(editedResponseObj, responseId), 'text') ||
            formatText(_.get(htmlBody, 'html_body'), sourceName, isMacro) || text;
    }

    const handleTextChange = (editorText) => {
        setObjectProperty(editedResponseObj, responseId, { text: editorText });
        handleEditedResponse(editorText);
    }

    const handleSelectFile = (data) => {
        const result = addObjectFromArray(attachments, data);
        editorRef.current.updateAttachments(result);
        setAttachments(result);
    };

    const handleDeselectAttachment = (attachmentId) => {
        const result = removeObjectFromArray(attachments, attachmentId, 'id');
        editorRef.current.updateAttachments(result);
        setAttachments(result);
    };

    return(
        <Box sx={{marginTop: '9px'}}>
            <QuillEditor
                defaultText={getEditorText()}
                handleTextChange={handleTextChange}
                isEmail={isEmail}
                length={charLength}
                isCharDisplay={isCharDisplay}
                ref={editorRef}
                selectedAttachments={attachments}
                handleSelectFile={handleSelectFile}
                handleDeselectAttachment={handleDeselectAttachment}
                isAttachmentSupported={isAttachmentSupported}
                isImageEmbedSupported={isImageEmbedSupported}
                selectedTags={selectedTags}
                handleRemoveTag={handleRemoveTag}
            />
        </Box>

    )
}