import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import businessLogo from "../../../../../../images/business.svg";
import React, {Fragment} from "react";
import {Box} from "@mui/system";
import RadioButtonLabel from "../../../../../common/RadioButtonLabel";
import {makeStyles} from "@mui/styles";

export default function ShopRow({id, shopName, input}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const addDefaultSrc = (ev) => {
        ev.target.src = businessLogo
    };

    return(
        <Box className={classes.container} key={id} {...input}>
            <Box className={classes.shopInfoContainer}>
                <Box className={classes.imageContainer}>
                    <img onError={addDefaultSrc} src={''} style={{width: '100%'}}/>
                </Box>
                <Box className={isMobile ? classes.shopNameMobile : classes.shopName}>
                    <span style={{}}>{shopName}</span>
                </Box>
            </Box>
            <Box className={classes.checkboxContainer}>
                <RadioButtonLabel value={id} />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles({
    shopName: {
        color: '#0C2728',
        fontFamily: 'larsseitRegular',
        fontSize: '18px',
        lineHeight: '120%',
        width: 'calc(100% - 47px)',
        padding: '0 15px',
    },
    shopNameMobile: {
        color: '#0C2728',
        fontFamily: 'larsseitRegular',
        fontSize: '16px',
        lineHeight: '120%',
        width: 'calc(100% - 47px)',
        padding: '0 10px'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '5px 0 20px 0'
    },
    shopInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '20px',
        width: '100%'
    },
    imageContainer: {
        width: '47px',
        height: '47px',
        maxWidth: '47px',
        maxHeight: '47px',
        border: '1px solid #fafafa'
    },
    checkboxContainer: {
        width: 'fit-content',
        height: 'fit-content'
    },
});