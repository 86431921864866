export class SchedulesColumnBuilder {
    constructor() {
        this.schedulesColumn = {};
    }

    setId(id) {
        this.schedulesColumn.id = id;
        return this;
    }

    setLabel(label) {
        this.schedulesColumn.label = label;
        return this;
    }

    setWidth(width) {
        this.schedulesColumn.width = width;
        return this;
    }

    setAlignment(alignment) {
        this.schedulesColumn.align = alignment;
        return this;
    }

    build(){
        return this.schedulesColumn;
    }
}