import { earningsConstants } from '_constants';
const INITIAL_STATE = {
    earningsHistory: {},
    loadingEarningsHistory: true,
    earningsHistoryError: '',

    leadAgents: {},
    leadAgentsLoading: false,
    leadAgentsError: '',

    agentPayouts: [],
    agentPayoutsLoading: false,
    agentPayoutError: ''
};
export function earningsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case earningsConstants.GET_EARNINGS_HISTORY_REQUEST:
            return {...state, loadingEarningsHistory: true};
        case earningsConstants.GET_EARNINGS_HISTORY_SUCCESS:
            return {...state, earningsHistory: action.payload, loadingEarningsHistory: false};
        case earningsConstants.GET_EARNINGS_HISTORY_FAILURE:
            return {...state, earningsHistoryError: action.error, loadingEarningsHistory: false};

        case earningsConstants.GET_LEAD_AGENTS_REQUEST:
            return {...state, leadAgentsLoading: true};
        case earningsConstants.GET_LEAD_AGENTS_SUCCESS:
            return {...state, leadAgents: action.payload, leadAgentsLoading: false};
        case earningsConstants.GET_LEAD_AGENTS_FAILURE:
            return {...state, leadAgentsError: action.error, leadAgentsLoading: false};

        case earningsConstants.GET_AGENT_PAYOUTS_REQUEST:
            return {...state, agentPayoutsLoading: true};
        case earningsConstants.GET_AGENT_PAYOUTS_SUCCESS:
            return {...state, agentPayouts: action.payload, agentPayoutsLoading: false};
        case earningsConstants.GET_AGENT_PAYOUTS_FAILURE:
            return {...state, agentPayoutError: action.error, agentPayoutsLoading: false};

        default:
            return state
    }
}