import React, {Component} from 'react';
import {getCurrentUser} from '_helpers';
import {userRoles} from '_constants';
import {Redirect} from 'react-router-dom';
import {stripeOauthActions, alertActions} from '_actions';
import {alertMessages} from '_constants';
import connect from "react-redux/es/connect/connect";
import {queryStringToObject} from "_helpers";

class StripeConnect extends Component {

    componentDidMount() {

        const search = this.props.location.search.substring(1);
        const values = queryStringToObject(search);

        if (values.code && values.state) {
            const params = {code: values.code, state: values.state};
            const {id, organization_id} = getCurrentUser();
            switch (getCurrentUser().roles[0]) {
                case userRoles.COMPANY_ADMIN_ROLE:
                    params.organizationId = organization_id;
                    break;
                case userRoles.CHATDESK_AGENT_ROLE:
                    params.userId = id;
                    break;
                default:
                //do nothing
            }

            this.props.dispatch(stripeOauthActions.processOauthCode(params))
        } else {
            this.props.dispatch(alertActions.error(alertMessages.STRIPE_OAUTH_FAILURE));
        }
    }


    render() {
        const {id, organization_id} = getCurrentUser();
        switch (getCurrentUser().roles[0]) {
            case userRoles.COMPANY_ADMIN_ROLE:
                return <Redirect
                    to={{
                        pathname: `/company/${organization_id}/profile`,
                        state: {from: this.props.location}
                    }}
                />;
            case userRoles.CHATDESK_AGENT_ROLE:
                return <Redirect
                    to={{
                        pathname: `/users/${id}/profile`,
                        state: {from: this.props.location}
                    }}
                />;
            default:
                return <Redirect
                    to={{
                        pathname: `/users/${id}/profile`,
                        state: {from: this.props.location}
                    }}
                />;
        }

    }
}

const mapStateToProps = (state) => {
    const {stripeOauth, user} = state;

    return {
        stripeOauth,
        user
    }
};


const stripeConnect = connect(mapStateToProps)(StripeConnect);
export {stripeConnect as StripeConnect};