import React, {Component} from "react";
import {Grid} from "react-bootstrap";
import Paper from '@material-ui/core/Paper';
import {PrimaryCreateForm, SecondaryCreateForm} from "./Form";
import {withStyles} from '@material-ui/core/styles';
import {screenshotActions} from "_actions/screenshot.actions";
import {connect} from "react-redux";
import {Loading} from "components/common";
import {getCurrentUser} from "_helpers";
import {utilityActions} from "_actions";

const styles = theme => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        top: '140px',
        display: 'flex'
    },
    paper: {
        padding: theme.spacing.unit * 4,
        margin: 'auto',
        maxWidth: 750,
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    formLabel: {
        padding: "20px 3px"
    },
    header: {
        paddingLeft: "25px",
    },
    file: {
        padding: "5px 8px",
    }
});

const screenshotTypeOptions = [
    {'id': 'MISTAKE', name: 'MISTAKE'},
    {'id': 'POSITIVE', name: 'POSITIVE'}
];

const feedbackOptions =[
    {id: "Typo in response_sent", name: "Typo in response sent"},
    {id: "Incorrect response", name: "Incorrect response"},
    {id: "Missed message", name: "Missed message"},
    {id: "Skipped when should have answered", name: "Skipped when should have answered"},
    {id: "Should have chosen no response", name: "Should have chosen no response"},
    {id: "Should have escalated", name: "Should have escalated"},
    {id: "Should have skipped", name: "Should have skipped"}
]


class ScreenshotsAdd extends Component {

    state = {
        screenshot: null,
    };

    submitForm = values => {
        const data = this.modifyFormData(values)
        const currentPath = this.props.currentPath ? this.props.currentPath : "/screenshots";
        this.props.saveScreenshot(data, currentPath)
    };

    modifyFormData(values) {
        const fileInput = this.state.screenshot;
        const data = new FormData(this.form);
        let {description, messageUrl, type} = values;
        let typeScreenshot;
        const {screenshotType, messageId, agentInfo} = this.props;
        const agentId = agentInfo && agentInfo.id ? agentInfo.id : null
        const reporterId = getCurrentUser().id;
        const rootUrl = utilityActions.getRootUrl()

        if (fileInput) data.append('screenshot', fileInput)
        if (!messageUrl) messageUrl = this.props.messageUrl;
        if (screenshotType) typeScreenshot = screenshotType
        if (agentId) data.append('agentId', agentId)
        if (reporterId) data.append('reporterId', reporterId);
        if (messageId) data.append('messageId', messageId)

        data.append('messageUrl', messageUrl);
        data.append('description', description);
        data.append('clientRootUrl', rootUrl)
        data.append('typeName', typeScreenshot);
        data.append('feedBackOptions', type);

        return data
    }

    handleFileChange = (fileData) => {
        this.setState({
            screenshot: fileData
        })
    };

    render() {
        const {classes, loading, screenshotType, agentInfo, displayInModal, currentPath, submitButtonText} = this.props;
        if (loading) {
            return <Loading/>
        }

        const formView = <FormView
            screenshotType={screenshotType}
            typeOptions={screenshotTypeOptions}
            classes={classes}
            onSubmit={this.submitForm}
            handleFileChange={this.handleFileChange}
            agentInfo={agentInfo}
            currentPath={currentPath ? currentPath : '/screenshots'}
            submitButtonText={submitButtonText}
        />

        if (displayInModal) {
            return (
                <div>
                    {formView}
                </div>
            )
        } else {
            return (
                <div className={classes.root} style={{position: "relative"}}>
                    <Paper className={classes.paper}>
                        <Grid item sm={12} style={{maxWidth: '100%'}}>
                            {formView}
                        </Grid>
                    </Paper>
                </div>
            )
        }

    }
}

/**
 * Show the secondary form when type is to be automatically populated
 * i.e. user does not have to select type as we already know if we are submitting
 * for a mistake for positive scenario
 * in this view also, we do not have to manually populate the message url in the form
 * as the message id or url is automatically captured and submitting when creating.
 * The primary view, however, was the initial view and may be deprecated soon.
 * @param screenshotType
 * @param classes
 * @param onSubmit
 * @param handleFileChange
 * @param agentInfo
 * @param messageId
 * @param submitButtonText
 * @returns {JSX.Element}
 * @constructor
 */
const FormView = ({screenshotType, classes, onSubmit, handleFileChange, agentInfo, messageId, submitButtonText}) => {
    if (screenshotType) {
        return (
            <SecondaryCreateForm
                typeOptions={screenshotTypeOptions}
                classes={classes}
                onSubmit={onSubmit}
                handleFileChange={handleFileChange}
                agentInfo={agentInfo}
                messageId={messageId}
                submitButtonText={submitButtonText}
                feedbackOptions={feedbackOptions}
            />
        )
    } else {
        return (
            <div>
                <h3 className={classes.header}> Add Screenshot</h3>
                <PrimaryCreateForm
                    typeOptions={screenshotTypeOptions}
                    classes={classes}
                    onSubmit={onSubmit}
                    handleFileChange={handleFileChange}
                />
            </div>
        )
    }
};

const connectedStyledComponent = withStyles(styles)(ScreenshotsAdd);

function mapDispatchToProps(dispatch) {
    return {
        saveScreenshot: (props, currentPath) => dispatch(screenshotActions.addScreenshot(props, currentPath))
    }
}

function mapStateToProps(state) {
    const {screenshots} = state;
    const loading = screenshots && screenshots.loading ? true : screenshots.loading;
    return {
        loading
    }
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedStyledComponent);
export {connectedComponent as ScreenshotsAdd}
