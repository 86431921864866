import styles from "./conversationStyles";
import {Box} from "@mui/system";
import _ from "lodash";
import AuthorIcon from "./AuthorIcon";
import MessageContainer from "./MessageContainer";
import MessageInfoContainer from "./MessageInfoContainer";
import {stripNumbersFromUsername} from "_helpers";
import {
    getAuthor,
    getBackgroundColor,
    getInitialsCount,
    getMessageDirection
} from "../../../utilities/ConversationUtils";

export default function ConversationItem({ refProp, conversationItem, message, index }) {
    const direction = getMessageDirection(conversationItem, message);
    const author = getAuthor(_.get(conversationItem, 'authorName'), direction);
    const initialsCount = getInitialsCount(direction);
    const background = getBackgroundColor(direction);
    const attachments = _.get(conversationItem, 'attachments');
    const other = _.get(conversationItem, 'other');

    return(
        <Box sx={styles.root}>
            <Box sx={styles.icon}>
                <AuthorIcon
                    name={stripNumbersFromUsername(author)}
                    background={background}
                    initialsCount={initialsCount}
                />
            </Box>
            <Box sx={styles.messageContainer}>
                <MessageInfoContainer conversationItem={conversationItem} message={message} />
                <MessageContainer
                    refProp={refProp}
                    text={_.get(conversationItem, 'text')}
                    message={message}
                    attachments={attachments}
                    other={other}
                    background={background}
                    index={index}
                />
            </Box>
        </Box>
    )
}