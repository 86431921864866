import {BasicSnackBar} from "./BasicSnackBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import {refreshPage} from "_helpers";
import React from "react";

let _this;

class RefreshToast extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            snackbarOpen: false,
            snackbarMessage: '',
            autoHideDuration: 3000,
            actionButton: ''
        };
        _this = this;
    }

    handlePageRefresh = () => {
        refreshPage();
    }

    static showMessage(message){
        BasicSnackBar.showMessage(message);
    }

    static close(){
        BasicSnackBar.snackbarClose();
    }

    render() {
        return(
            <BasicSnackBar
                actionButton={
                    <IconButton size={'small'} onClick={this.handlePageRefresh}>
                        <RefreshIcon sx={{color:'#FFFFFF', fontSize: '20px'}}/>
                    </IconButton>
                }
            />
        )
    }
}

export { RefreshToast };