import React from 'react'
import {Paper, Table, TableBody, TableCell, TableRow, Typography, withStyles} from '@material-ui/core';
import PropTypes from "prop-types";
import {stylesTable} from "../Inbox/StylesTable";
import {ScreenshotRow} from "./Row";

const ScreenshotsTable = ({screenshots, classes}) => {

    return (
        <div className="search-messages-table">
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <thead>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell} style={{width: "35%"}}>
                            <Typography className={classes.typography13}>Message</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{width: "11%"}}>
                            <Typography className={classes.typography13}>Name</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography className={classes.typography13}>Email</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography className={classes.typography13}>Reviewer</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{width: "14%"}}>
                            <Typography className={classes.typography13}>Date</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                    </TableRow>

                    </thead>
                    <TableBody>
                        {screenshots && screenshots.length === 0  &&
                        <TableRow className={classes.tableRow}>
                            <TableCell colSpan={4} className={classes.typography13}>There are no screenshots available</TableCell>
                        </TableRow>}

                        {screenshots && screenshots.length > 0 && screenshots.map(screenshot => {
                            return (
                                <ScreenshotRow
                                    key={screenshot.id}
                                    screenshot={screenshot}
                                    classes={classes}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
};
ScreenshotsTable.propStyles = {
    classes: PropTypes.object.isRequired,
}

const StyledScreenshotTable = withStyles(stylesTable)(ScreenshotsTable)


export {StyledScreenshotTable as ScreenshotsTable}