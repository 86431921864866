import React from "react";
import { Stack } from "@mui/material";
import { styles } from "./MetricsDetailsStyles";
import { MetricsMessageBoxContainer } from "./MessageBoxes/MetricsMessageBoxContainer";
import { MetricsDetailFooter, MetricsDetailHeader } from "./Sections";

export const MetricsDetailsComponent = ({
                                            pageFilter,
                                            allHandles,
                                            selectedDropdownOption,
                                            isFilterDropdownOpen,
                                            setIsFilterDropdownOpen,
                                            isPageSelectionDropdownOpen,
                                            setIsPageSelectionDropdownOpen,
                                            selectedChannels,
                                            selectedCriteria,
                                            setSelectedChannels,
                                            setSelectedCriteria,
                                            dateRange,
                                            setDateRange,
                                            pageParams,
                                            setPageParams,
                                            onPageFilterDropdownChange,
                                            onFilterFormSubmit,
                                            onSelectChangeHandler,
                                            handleFilterReset,
                                            handleExport,
                                            filterRef,
                                            filterPageRef,
                                            selectedCheckboxes,
                                            setSelectedCheckboxes,
                                            allChannelsSelected,
                                            setAllChannelsSelected,
                                        }) => {
    const renderMetricsMessagesBox = () => {
        return (
            <Stack direction={'row'} sx={styles.hero} spacing={1}>
                <MetricsMessageBoxContainer
                    pageFilter={pageFilter}
                    selectedChannels={selectedChannels}
                    pageParams={pageParams}
                    setPageParams={setPageParams}
                />
            </Stack>
        )
    }

    return (
       <>
           <MetricsDetailHeader
                pageFilter={pageFilter}
                allHandles={allHandles}
                isFilterDropdownOpen={isFilterDropdownOpen}
                setIsFilterDropdownOpen={setIsFilterDropdownOpen}
                isPageSelectionDropdownOpen={isPageSelectionDropdownOpen}
                setIsPageSelectionDropdownOpen={setIsPageSelectionDropdownOpen}
                selectedDropdownOption={selectedDropdownOption}
                selectedChannels={selectedChannels}
                selectedCriteria={selectedCriteria}
                setSelectedCriteria={setSelectedCriteria}
                setSelectedChannels={setSelectedChannels}
                dateRange={dateRange}
                setDateRange={setDateRange}
                onPageFilterDropdownChange={onPageFilterDropdownChange}
                onFilterFormSubmit={onFilterFormSubmit}
                onSelectChangeHandler={onSelectChangeHandler}
                handleFilterReset={handleFilterReset}
                handleExport={handleExport}
                filterRef={filterRef}
                filterPageRef={filterPageRef}
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={setSelectedCheckboxes}
                allChannelsSelected={allChannelsSelected}
                setAllChannelsSelected={setAllChannelsSelected}
            />
           {renderMetricsMessagesBox()}
           <MetricsDetailFooter />
       </>
    )

}