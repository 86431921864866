import {useState} from "react";
import {Box, Grid} from "@mui/material";
import {Field} from "redux-form";
import _ from "lodash";
import BasicFormTextField from "../../../../common/BasicFormTextField";
import AttributeNameSelect from "../../../../common/AttributeNameSelect";
import AttributeTypeSelect from "../../../../common/AttributeTypeSelect";


export default function CampaignAttributeItem({required, index}) {
    const selectPlaceholder = 'Choose';
    const [name, setName] = useState('');
    const [type, setType] = useState('');

    const handleNameSelectChange = (event) => {
        const selectedName = _.get(event, 'target.value');
        setName(selectedName);
    };

    const handleTypeSelectChange = (event) => {
        const selectedType = _.get(event, 'target.value');
        setType(selectedType);
    };

    const getPlaceholder = () => {
        if(type === 'TEXT') return 'Enter value';
        else if(type === 'LIST') return 'Enter comma-separated values';
        return 'Select attribute type to proceed';
    };

    return(
        <Grid container item sx={{}} mt={{xs: '5px', sm: '5px', md: '10px', lg: '10px'}}>
            <Grid item xs={12} md={4} sx={{}}>
                <Box mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}
                     mr={{md: '20px', lg: '20px'}}>
                    <Box sx={styles.inputDesc}>Name</Box>
                    <Field
                        name={`attribute_name_${index}`}
                        component={AttributeNameSelect}
                        placeholder={selectPlaceholder}
                        handleChange={handleNameSelectChange}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{}}>
                <Box mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}
                     mr={{md: '20px', lg: '20px'}}>
                    <Box sx={styles.inputDesc}>Type</Box>
                    <Field
                        name={`attribute_type_${index}`}
                        component={AttributeTypeSelect}
                        placeholder={selectPlaceholder}
                        handleChange={handleTypeSelectChange}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{}}>
                <Box mt={{xs: '10px', sm: '10px', md: '0', lg: '0'}}>
                    <Box sx={styles.inputDesc}>Value</Box>
                    <Field
                        name={`attribute_value_${index}`}
                        component={BasicFormTextField}
                        placeholder={getPlaceholder()}
                        validate={required}
                        disabled={_.isEmpty(type)}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
}