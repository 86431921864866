import React, {useEffect} from "react";
import {Box, Stack} from "@mui/material";
import importedStyles from "../../MetricsDashboard/Utilities/styles";
import BasicButton from "../../../common/BasicButton";
import {getCurrentUser, isObjectEmpty} from "_helpers";
import {useDispatch, useSelector} from "react-redux";
import {scheduleActions} from "_actions";
import ScheduleItem from "../Utilities/ScheduleItem"
import {Loading} from "../../../../common";
import {Link} from 'react-router-dom'

function Schedule(){
    const currentUser = getCurrentUser();
    const orgId = currentUser._embedded.organization.id;
    const params = {groupByHandle: true}
    const dispatch = useDispatch();

    const schedules = useSelector((state) => state.schedules.data)
    const loading = useSelector((state) => state.schedules.loading)

    const timezone = currentUser?._embedded?.organization?.timezone
    const id = currentUser?._embedded?.organization?.id
    const deleteSchedule = (data) => {
        dispatch(scheduleActions.deleteSchedule(data))
    }

    useEffect(() => {
        dispatch(scheduleActions.getAll(orgId, params))
    },[])

    return(
        <Box sx={styles.rootWrapper}>
            <Box sx={styles.container}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ justifyContent: 'space-between' }}>
                    <Stack direction='column' sx={styles.hero} spacing={1}>
                        <Box component={'h1'} sx={importedStyles.headingLarge}>
                            Team Schedule
                        </Box>
                        <Box sx={importedStyles.root}>
                            Timezone: {timezone ? timezone : "No timezone set"}
                        </Box>
                    </Stack>

                    <Link to={`/company/${id}/schedules/add`}>
                        <BasicButton label={'Add schedule'} />
                    </Link>
                </Stack>

                {loading && <Loading height={400}/>}

                {!loading && !isObjectEmpty(schedules) &&
                    <Box sx={styles.card}>
                        {Object.entries(schedules).map(([keyName, value]) => {
                            return(
                                <ScheduleItem
                                    key={keyName}
                                    handleId={keyName}
                                    schedules={value}
                                    orgId={id}
                                    deleteSchedule={deleteSchedule}
                                />
                            )})}
                    </Box>
                }
            </Box>
        </Box>
    )
}

const styles = {
    rootWrapper: {
        backgroundColor: '#F8F9F9',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
    },
    heading: {
        mb: '0!important',
        mr: 1,
        fontFamily: 'LarsseitRegular',
    },
    headingBox: {
        height: { xs: 'auto', md: '20px' },
        mb: { xs: 4, md: 3 },
    },
    card: {
        border: '1px solid #b6bebf',
        backgroundColor: '#fff',
        borderRadius: '10px',
        overflow: 'hidden',
        mt: 4,
    },
    contentLabel: {
        fontFamily: 'LarsseitBold',
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: '0.05em',
        color: '#0C2728',
        textTransform: 'uppercase',
        mt: 0,
        mb: { xs: 0, sm: 0.5 },
    }
}

export default Schedule