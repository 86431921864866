import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RadioGroup, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {Box} from "@mui/system";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import {Field, reduxForm} from "redux-form";
import {makeStyles} from "@mui/styles";
import _ from "lodash";
import {filterObjectValue} from "_helpers";
import {tiktokShopActions} from "_actions";
import ShopRow from "./ShopRow";
import ActionButton from "../../../../../common/ActionButton";
import {buildSaveShopPayload} from "./tiktokShopUtils";
import {SnackBar} from "../../../../../../common";

function ShopSelectionModalContent({ handleClose, handleSubmit }) {
    const [selectedShop, setSelectedShop] = useState('');
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const tiktokShopData = useSelector((state) => state.tiktokShop);
    const loading = _.get(tiktokShopData, 'loadingShops');
    const shops = _.get(tiktokShopData, 'shopDetails.data.shops') || [];
    const organizationId = _.get(tiktokShopData, 'shopDetails.data.organizationId');
    const classes = useStyles();

    useEffect(() => {

    }, []);

    const handleChange = (event) => {
        setSelectedShop(_.get(event, 'target.value'));
    }

    const hasShop = !_.isEmpty(shops) && shops.length > 0;

    const onSubmit = () => {
        const shopSelected = filterObjectValue(shops, 'id', selectedShop);
        if(_.isEmpty(shopSelected)) {
            SnackBar.showMessage("Select a shop to continue");
            return;
        }
        const payload = buildSaveShopPayload(shopSelected[0], organizationId);
        dispatch(tiktokShopActions.saveShop(payload));
        handleClose();
    };

    const Loading = () => {
        return(
            <Box className={classes.progress}>
                <CircularProgress sx={{color: '#143F42'}}/>
            </Box>
        )
    };

    if(loading){
        return <Loading/>
    }

    return(
        <Fragment>
            <Box sx={isMobile ? {marginTop: '10px'} : {}}>
                <form name="tiktokShopsForm" onSubmit={ handleSubmit(onSubmit) }>
                    <RadioGroup name="tiktok-shop-group" onChange={handleChange}>
                        {hasShop && shops.map((shop) => {
                            return (
                                <Field
                                    key={ shop.id }
                                    id={ shop.id }
                                    name={ shop.id }
                                    shopName={ shop.name }
                                    component={ ShopRow }
                                />
                            )
                        })}
                    </RadioGroup>
                    <Box className={isMobile ? classes.buttonContainerMobile : classes.buttonContainer}>
                        <Box className={isMobile ? classes.buttonMobile : classes.button}>
                            <ActionButton label={'Submit'}/>
                        </Box>
                    </Box>
                </form>

            </Box>
        </Fragment>
    )
}

const useStyles = makeStyles({
    button: {},
    buttonMobile: {
        width: '100%'
    },
    text: {
        color: '#0C2728',
        fontFamily: 'larsseitRegular',
        fontSize: '18px',
        lineHeight: '120%',
        width: 'calc(100% - 47px)',
    },
    textMobile: {
        color: '#0C2728',
        fontFamily: 'larsseitRegular',
        fontSize: '16px',
        lineHeight: '120%',
        width: 'calc(100% - 47px)',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '30px',
    },
    buttonContainerMobile: {
        display: 'flex',
        marginTop: '35px',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    progress: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const formConfig = {
    form: 'tiktokShopsForm',
};

export default reduxForm(formConfig)(ShopSelectionModalContent);