import {ConversationBuilder} from "../common/objects/ConversationBuilder";
import _ from "lodash";
import {
    handleTypeNames,
    messageDirection,
    metaMsgSources,
    supportedSourcesForOEmbed,
    supportedSourcesForViewHtmlAndPhotos
} from "_constants";
import {getVotingOrganization} from "_helpers";

/**
 * @param conversationList {array}
 */
export function buildOriginalConversation(conversationList) {
    const conversationArr = [];
    conversationList.map(conversation => {
        const builder = new ConversationBuilder();
        builder.setId(_.get(conversation, 'id'));
        builder.setAuthorEmail(_.get(conversation, 'authorPhoneNumber') || _.get(conversation, 'authorEmail'));
        builder.setAuthorName(_.get(conversation, 'authorPhoneNumber') || _.get(conversation, 'authorName') || 'User');
        builder.setSubject(_.get(conversation, 'emailSubject'));
        builder.setText(_.get(conversation, 'htmlBody') || '-');
        builder.setRecipientEmail(_.get(conversation, 'recipientPhoneNumber') || _.get(conversation, 'recipientEmail'));
        builder.setCreatedDate(_.get(conversation, 'createdDate'));
        builder.setAttachments(_.get(conversation, 'attachments'));
        builder.setIsTrendsSource(false);
        builder.setAssignee(_.get(conversation, 'assignee'));
        builder.setCurrentPage(_.get(conversation, 'currentPage'));
        builder.setOther(_.get(conversation, 'other'));
        conversationArr.push(builder.conversation);
    })
    return conversationArr;
}

/**
 * @param conversationList {array}
 */
export function buildConversation(conversationList) {
    const conversationArr = [];
    conversationList.map(conversation => {
        const builder = new ConversationBuilder();
        builder.setId(_.get(conversation, 'id'));
        builder.setAuthorEmail(_.get(conversation, 'authorPhoneNumber') || _.get(conversation, 'authorId'));
        builder.setAuthorName(_.get(conversation, 'authorPhoneNumber') || _.get(conversation, 'author') || 'User');
        builder.setText(_.get(conversation, 'text') || '-');
        builder.setDirection(_.get(conversation, 'direction'));
        builder.setCreatedDate(_.get(conversation, 'dateCreatedFromSource') || _.get(conversation, 'createdDate') || '-');
        builder.setIsTrendsSource(true);
        conversationArr.push(builder.conversation);
    })
    return conversationArr;
}

/**
 * @param conversation {object}
 * @param message {object}
 * @returns {string}
 */
export function getMessageDirection(conversation, message) {
    const authorId = _.get(message, 'authorId');
    const author = _.get(message, 'author');
    const direction = _.get(conversation, 'direction');
    const authorEmail = _.get(conversation, 'authorEmail');
    const authorName = _.get(conversation, 'authorName');
    const isTrendsSource = _.get(conversation, 'isTrendsSource');

    if(isTrendsSource) return direction;
    if((!authorEmail || !authorId) && (!author || !authorName)) return undefined;
    return ((authorEmail === authorId) || (author === authorName)) ? messageDirection.INCOMING : messageDirection.OUTGOING;
}

/**
 * @param direction {string}
 * @returns {string}
 */
export function getBackgroundColor(direction) {
    return (_.toString(direction).toUpperCase() === messageDirection.INCOMING) ? 'teal' : 'grey';
}

/**
 * @param author {string}
 * @param direction {string}
 * @returns {string}
 */
export function getAuthor(author, direction) {
    const org = getVotingOrganization();
    return (_.toString(direction).toUpperCase() === messageDirection.INCOMING) ?
        author : _.get(org, 'name');
}

/**
 * @param direction {string}
 * @returns {number}
 */
export function getInitialsCount(direction) {
    return (_.toString(direction).toUpperCase() === messageDirection.INCOMING) ? 2 : 1;
}

/**
 * @param assignee {object}
 * @returns {string}
 */
export function processAssignee(assignee) {
    const placeholder = 'None';
    if(_.isEmpty(assignee)) return placeholder;
    const name = _.get(assignee, 'name');
    const email = _.get(assignee, 'email');
    if(_.isEmpty(name) || _.isEmpty(email)) return placeholder;
    return `${name} ${email}`;
}

export function shouldShowCopyIcon(direction) {
    return (_.toString(direction).toUpperCase() === messageDirection.INCOMING)
}

/**
 * @param sourceName {string}
 * @param handleName {string}
 * @param isInApp {boolean}
 * @param isProactive {boolean}
 * @returns {boolean}
 */
export function isViewOriginalSupported(sourceName, handleName, isInApp, isProactive) {
    return isInApp && supportedSourcesForViewHtmlAndPhotos.includes(sourceName) && !isMetaMentions(handleName) && !isProactive;
}

/**
 * @param handleName {string}
 * @returns {boolean}
 */
export function isMetaMentions(handleName) {
    let metaMentionsPattern = new RegExp("(Instagram|Facebook) (Mentions).*");
    return metaMentionsPattern.test(handleName || '');
}

/**
 * @param handleName
 * @returns {boolean}
 */
export function isTiktokShopPost(handleName) {
    return handleName === handleTypeNames.TIKTOK_SHOP_POST;
}