import {Box} from "@mui/system";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {utilityActions} from "_actions";
import {hasVerticalScroll} from "../../../utilities/Component.utils";

export default function MessageIframe({text, message, refProp, index}) {
    const dispatch = useDispatch();
    const offset = 20;

    const onLoad = () => {
        return setTimeout(() => {
            const obj = document.getElementById(`${index}-messageFrame`);
            if(obj) {
                obj.style.height = obj.contentWindow.document.body.scrollHeight + offset + 'px';
            }
            if(!_.isEmpty(message) && !hasVerticalScroll(refProp)) {
                dispatch(utilityActions.setVerticalScrollComplete(true));
            }
        }, 50);
    }

    return(
        <Box component={'iframe'}
             id={`${index}-messageFrame`}
             scrolling={'no'}
             sx={styles.iframe}
             srcDoc={text}
             onLoad={onLoad}
        />
    )
}

const styles = {
    iframe: {
        width: '100%',
        border: 0,
    },
}