import React, {Component, Fragment} from 'react';
import connect from "react-redux/es/connect/connect";
import {filterObjectValue, filterObjectTwoValues, isObjectEmpty} from "_helpers";
import {Typography} from "@material-ui/core";
import {platformConstants} from "_constants";
import _ from "lodash";
import LoadingPlaceholderBlock from "../common/LoadingPlaceholderBlock";


/**
 * @author: Chris Okebata
 */

class MessageStatusV2 extends Component {

    getStatus = (message, status, scheduled) => {
        const actionTaken = _.get(message, 'actionTaken') || _.get(status[0], 'actionTaken');
        const NO_RESPONSE_NEEDED = ['No Response Needed'];
        const REPLIED = ['VOTE', 'TRENDS', 'INBOX'];
        const ESCALATE = ['Escalate'];
        const SKIPPED = ['SKIPPED'];
        const INCOMING = 'incoming';
        const OUTGOING = 'outgoing';
        const { direction, actioned, hasEmployeeSeen, isAutoEscalated, hiddenFromSource, removedFromSource } = message;
        const isNoResponseNeededAction = NO_RESPONSE_NEEDED.includes(actionTaken);
        const isRepliedAction = REPLIED.includes(actionTaken);
        const isEscalatedAction = ESCALATE.includes(actionTaken);
        const isSkippedAction = SKIPPED.includes(actionTaken);
        const isIncomingDirection = (direction && direction.toString().toLowerCase() === INCOMING);
        const isOutgoingDirection = (direction && direction.toString().toLowerCase() === OUTGOING);
        const isScheduled = !isObjectEmpty(scheduled[0]);
        const isActionTakenEmpty = _.isEmpty(actionTaken);

        const isSkipped = isIncomingDirection && isSkippedAction && hasEmployeeSeen;
        const isEscalated = isIncomingDirection && isEscalatedAction && (actioned || hasEmployeeSeen || isAutoEscalated) && (!hiddenFromSource && !removedFromSource);
        const isNoResponseNeeded = isIncomingDirection && isNoResponseNeededAction && (actioned || hasEmployeeSeen || isAutoEscalated) && (!hiddenFromSource && !removedFromSource);
        const isReplied = isIncomingDirection && isRepliedAction && (hasEmployeeSeen || actioned) && (!hiddenFromSource && !removedFromSource);
        const isActioned = isIncomingDirection && (actioned || isAutoEscalated || hasEmployeeSeen) && !isEscalatedAction && !isNoResponseNeeded && !isRepliedAction && (!hiddenFromSource && !removedFromSource);
        const isHidden = isIncomingDirection && (hiddenFromSource && removedFromSource) || (hiddenFromSource && !removedFromSource);
        const isDeleted = isIncomingDirection && removedFromSource;
        const isRecent = isIncomingDirection && (!isSkipped && !isEscalated && !isNoResponseNeeded && !isReplied && !isActioned && !isHidden && !isDeleted);
        const isOutOfSchedule = !isScheduled && isRecent;
        const isNew = isScheduled && isRecent;

        if(isSkipped) return "SKIPPED";
        if(isEscalated) return "ESCALATED";
        if(isNoResponseNeeded) return "NO RESPONSE NEEDED";
        if(isReplied) return "REPLIED";
        if(isActioned) return "ACTIONED";
        if(isHidden) return "HIDDEN";
        if(isDeleted) return "DELETED";
        if(isOutOfSchedule) return "NOT SCHEDULED";
        if(isNew) return <span className="badge btn-danger" style={{padding: '4px'}}>NEW</span>;
        if(isOutgoingDirection) return isActionTakenEmpty ? "OUTGOING" : "REPLIED"
    };

    render() {
        const {message, classes, loadingScheduledOrgs, scheduledOrganizations, loadingMessageStatus, messageStatus, platform} = this.props;
        const { id } = message;
        let status, scheduled;

        if(loadingMessageStatus || loadingScheduledOrgs){
            return(
                <LoadingPlaceholderBlock height={'10px'} borderRadius={'3px'}/>
            )
        }

        if(platform === platformConstants.TEAMS){
            const handleId = _.get(message, 'handleId') || _.get(message, 'dataSourceHandle');
            status = filterObjectValue(messageStatus || [], 'messageId', _.get(message, 'id'));
            scheduled = filterObjectTwoValues(scheduledOrganizations || [], 'handleId', handleId,
              'id', _.get(message, 'organizationId'));

        }else if(platform === platformConstants.TRENDS){
            const organizationId = _.get(message, 'organizationId') || _.get(message, 'organization');
            status = filterObjectValue(messageStatus || [], 'trendsMessageId', _.get(message, 'id'));
            scheduled = filterObjectTwoValues(scheduledOrganizations || [], 'trendsHandleId', _.get(message, 'handleId'),
              'trendsId', organizationId);
        }

        return(
            <Fragment key={id}>
                <Typography className={classes.typography14}>
                    {this.getStatus(message, status, scheduled)}
                </Typography>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {
        schedules: {scheduledOrganizations, loadingScheduledOrgs},
        messages: {loadingMessageStatus, messageStatus}
    } = state;

    return {
        loadingScheduledOrgs,
        scheduledOrganizations: scheduledOrganizations.scheduledOrganizations,
        loadingMessageStatus,
        messageStatus: messageStatus.messageStatus,
    };
}

const connectedMessageStatusV2 = connect(mapStateToProps)(MessageStatusV2);
export {connectedMessageStatusV2 as MessageStatusV2}