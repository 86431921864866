import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'components/common';

const preStyle = {
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
};

const ResponsePayloadData = (props) => {
  const { loading, payload } = props;
  return (
    <div>
      {loading && <Loading />}
      {(!loading && !payload)
            && (
            <h4 className="text-center">
                    There is no response payload data available for this message
            </h4>
            )
            }
      {(!loading && payload)
            && (
            <pre style={preStyle}>
              {JSON.stringify(JSON.parse(payload), null, 2)}
            </pre>
            )
            }
    </div>
  );
};

export default ResponsePayloadData;

ResponsePayloadData.propTypes = {
  loading: PropTypes.bool,
  payload: PropTypes.string,
};
