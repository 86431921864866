import React from "react";

import {Alert, Button, Col, Form, Grid, Panel, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Loading, TextField} from "components/common";
import {Link} from "react-router-dom";
import {roleActions} from "_actions";
import moment from "moment/moment";
import {formModes} from "_constants"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


class RoleForm extends React.Component {

    componentDidMount() {
        this.props.initialize({
            ...this.props.role,
            dateCreated: moment(this.props.role.dateCreated).format('MMMM D, YYYY'),
        });
    }

    handleSubmit = (params) => {
        if (this.props.mode === formModes.EDIT) {
            this.props.dispatch(roleActions.updateRole(params))
        } else {
            this.props.dispatch(roleActions.createRole(params))
        }
    };

    render() {

        let {submitting, error, handleSubmit, pristine, role, mode} = this.props;

        if (((mode === formModes.EDIT) && !role)) {
            return <Loading/>
        }

        return (

            <Panel>
                <Panel.Heading>{mode === formModes.EDIT ? 'Edit Role' : 'Add Role'}</Panel.Heading>
                <Panel.Body>
                    <Form onSubmit={handleSubmit(this.handleSubmit.bind(this))}>

                        <Grid>

                            <Row>
                                {error &&
                                <Col xs={12} md={12}>
                                    <Alert bsStyle="danger">
                                        <strong>Invalid Form:</strong> {error}
                                    </Alert>
                                </Col>
                                }
                            </Row>
                        </Grid>

                        <Field
                            type='text'
                            name='authority'
                            placeHolder='ROLE_COMPANY_ADMIN'
                            label='Authority'
                            disabled={false}
                            value={role.authority}
                            component={TextField}/>

                        <hr/>

                        <Row className='text-center'>
                            <Col lg={6} xs={6} md={6}>
                                <Link className="btn btn-info pull-left"
                                      to={this.props.exitTo ? this.props.exitTo : "/roles"}>
                                    Exit
                                </Link>
                            </Col>
                            <Col lg={6} xs={6} md={6}>
                                <Button bsStyle="success" type="submit" className='pull-right'
                                        disabled={pristine || submitting}>
                                    <FontAwesomeIcon icon='save'/> Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Panel.Body>
            </Panel>
        );
    }
}

const mapStateToProps = (state) => {
    const {roles} = state;

    return {
        roles: roles.roles,
    };
};

RoleForm = connect(mapStateToProps)(RoleForm);

const roleForm = reduxForm({
    form: 'RoleForm',
    touchOnBlur: false
})(RoleForm);

export {roleForm as RoleForm};