import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { userActions } from "_actions";
import { Link } from 'react-router-dom'
import chatdeskLogo from 'components/images/chatdesk-logo-dark.svg'
import { Grid, Row } from "react-bootstrap";
import { CopyrightFooter } from "components/common";

/**
 * @author Aniekan Offiong
 */

class ConfirmUserAccount extends React.Component {

  componentDidMount() {
    const { location: { search } } = this.props;
    this.props.dispatch(userActions.confirmUserAccount(search))
  }

  render() {
    const { confirmUser, loading } = this.props;
    return (
      <Fragment>
        {!loading && !confirmUser &&
        this.props.history.push('/404')
        }
        {confirmUser && !loading &&
        <Grid fluid={true} className="main-content-wrapper">
          <div className="logo-pos-login">
            <img src={chatdeskLogo} alt="Chatdesk logo" className="logo"/>
          </div>

          <Row className='container-fluid'
               style={{ maxWidth: '380px', marginLeft: 'auto', marginRight: 'auto' }}>
            <div className="error-message" style={{ margin: '80px auto' }}>
              <h3 className="h1-error">Account Activated</h3>
              <p className="p-error">Click the button below to login</p>
              <Link to="/" className="btn btn-success font-15" style={{position: 'relative', top: '45px', padding: '7px 32px'}}>
                <span>Login</span>
              </Link>
            </div>
          </Row>
          <div style={{position: 'relative', top: '150px'}}>
            <CopyrightFooter/>
          </div>

        </Grid>
        }
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { users: { confirmUser, loading } } = state;
  return { confirmUser, loading }
}

const connectedConfirmUserAccount = connect(mapStateToProps)(ConfirmUserAccount);
export { connectedConfirmUserAccount as ConfirmUserAccount };
