import React, {Fragment, useEffect} from "react";
import {Box} from "@mui/system";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {makeStyles} from "@mui/styles";
import {buttonContained} from "../../../Utilities/ButtonContained";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, faceOauthActions, instagramOauthActions} from "_actions";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import _ from "lodash";
import PageRow from "./PageRow";
import {Field, reduxForm} from 'redux-form'
import {alertMessages, oauthConstants} from "_constants";


function PageSelectionModal({ handleClose, handleSubmit, channel }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const facebookOauth = useSelector((state) => state.facebookOauth);
  const instagramOauth = useSelector((state) => state.instagramOauth);
  const classes = useStyles();
  const { ActionButton } = buttonContained;
  const isFacebookChannel = channel === oauthConstants.FACEBOOK_CHANNEL;

  useEffect(() => {
    if(isFacebookChannel){
      dispatch(faceOauthActions.getPages());
    }else {
      dispatch(instagramOauthActions.getPages())
    }
  }, []);

  let { data, loading } = isFacebookChannel ? facebookOauth : instagramOauth;
  const isPageEmpty = _.isEmpty(data) || _.get(data, 'status') === 'BAD_REQUEST';

  const onSubmit = (pages) => {
    /**
     * data comes in the format {page_pageId:true,page_pageId:false}
     */
    const pageSelected = Object.keys(pages).some(key => {
      return pages[key]
    });

    if (pageSelected) {
      isFacebookChannel ? dispatch(faceOauthActions.saveSelectedPages(pages)) :
      dispatch(instagramOauthActions.saveSelectedPage(pages));
    } else {
      dispatch(alertActions.error(alertMessages.PAGES_LENGTH_ERROR))
    }
  };

  const NotFound = () => {
    return(
      <Box className={isMobile ? classes.textMobile : classes.text}>
        It looks like your account does not have administrator access for any
        [Facebook / Instagram] pages.
        Please login with another account
      </Box>
    )
  };

  const Loading = () => {
    return(
      <Box className={classes.progress}>
        <CircularProgress sx={{color: '#143F42'}}/>
      </Box>
    )
  };

  if(loading){
    return <Loading/>
  }

  if(isPageEmpty){
    return <NotFound/>
  }

  return(
    <Fragment>
      <Box sx={isMobile ? {marginTop: '10px'} : {}}>
        <form name="facebookPages" onSubmit={ handleSubmit(onSubmit) }>

          {!isPageEmpty && data.map((page) => {
            let cannotAddPage = page.hasOwnProperty('canAddPage') && !page.canAddPage;

            return (
              <Fragment>
                <Field
                  name={ "page_" + page.id }
                  label={ page.name }
                  value={ page.id }
                  key={ page.id }
                  component={ PageRow }
                  pageId={ page.pageId ? page.pageId : page.id }
                  disabled={cannotAddPage}
                  disabledText={cannotAddPage ? " (Moderator or higher role needed)" : ""}
                />
              </Fragment>
              )
          })}

          <Box className={isMobile ? classes.buttonContainerMobile : classes.buttonContainer}>
            <Box className={isMobile ? classes.buttonMobile : classes.button}>
              <ActionButton label={'Submit'} />
            </Box>
          </Box>
        </form>
      </Box>
    </Fragment>
  )
}

const useStyles = makeStyles({
  button: {
    width: '160px',
  },
  buttonMobile: {
    width: '100%'
  },
  text: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '18px',
    lineHeight: '120%',
    width: 'calc(100% - 47px)',
  },
  textMobile: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '16px',
    lineHeight: '120%',
    width: 'calc(100% - 47px)',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '30px',
  },
  buttonContainerMobile: {
    display: 'flex',
    marginTop: '35px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const formConfig = {
  form: 'facebookPagesForm',
};

export default reduxForm(formConfig)(PageSelectionModal);
