import React, {Component} from 'react'
import {GenericModal, TextEditor, Loading} from "components/common";
import { stripNbspAndNewLineFromHtml } from '_helpers';
import { jiraActions } from '_actions';
import {connect} from "react-redux";

class JiraBugModal extends Component {
    state = {
        summary: '',
        description: '',
        messageLink: '',
        attachments: []
    };

    componentDidMount() {
        let defaultInfoMap = this.defaultInfo();
        this.setState({
            summary: defaultInfoMap.defaultSummary,
            description: defaultInfoMap.defaultDescription,
            messageLink: window.location.href
        })
    }

    closeModal = () => {
        const { closeJiraModal, onHide, dispatch } = this.props
        onHide();
        if (closeJiraModal) {
            dispatch(jiraActions.clearToggle())
        }
        // Reset Modal content
        let defaultInfoMap = this.defaultInfo();
        this.setState({
            summary: defaultInfoMap.defaultSummary,
            description: defaultInfoMap.defaultDescription,
        });
    };

    defaultInfo = () => {
        const { organization, currentUser: { user }} = this.props;
        let orgName = organization.name;
        let userName = user.firstName || user.lastName ?
            `${user.firstName || ''} ${user.lastName || ''} (${user.username})` :
            user.username;
        let defaultSummary = `[${orgName}]  `;
        let defaultDescription = `
<p></p>
<p></p>
Submitted by
${userName}

Message link
${window.location.href}  
        `;
        return {defaultDescription, defaultSummary}
    };

    createAnotherIssue = (e) => {
        const { dispatch } = this.props;
        e.preventDefault();
        let defaultInfoMap = this.defaultInfo();
        this.setState({
            summary: defaultInfoMap.defaultSummary,
            description: defaultInfoMap.defaultDescription,
        });
        dispatch(jiraActions.clearToggle())
    };

    handleJiraChange = (e) => {
        const { target: { value }} = e;
        this.setState({ summary: value })
    };

    handleTextFieldUpdate = (text) => {
        this.setState({description: stripNbspAndNewLineFromHtml(text)});
    };

    handleJiraFile = (event) => {
        this.setState({ attachments: event.target.files })
    };

    handleJiraSubmit = () => {
        const { dispatch } = this.props;
        let payload = {
            summary: this.state.summary,
            description: this.state.description,
            messageLink: this.state.messageLink,
        };
        let formData = new FormData();
        if (this.state.attachments) {
            for (let i = 0; i < this.state.attachments.length; i++) {
                formData.append("attachments." + i, this.state.attachments[i]);
            }
        }
        dispatch(jiraActions.jiraIssueCreation(payload, formData))
    };

    render() {
        const { summary, description } = this.state;
        const { show, loading, link, closeJiraModal } = this.props;
        return (
            <GenericModal
                show={show}
                onHide={this.closeModal}
                headerText="Create Bug on Jira"
                buttonText="Submit"
                onSave={this.handleJiraSubmit}
                className="modal-edit-message"
                autoFocus={false}
                enforceFocus={false}
            >
                {loading && <Loading height={70} />}
                {!loading && closeJiraModal &&
                <div className="row tms-align-text-center" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="trends-table-header-width-100 margin-bottom-10">
                        <h3>Jira Issue has been created successfully</h3>
                    </div>
                    <div className="trends-table-header-width-40 margin-bottom-15 margin-top-15 flex-container justify-content-around">
                        <div className="col-3 margin-bottom-15">
                            <a href={link} className="btn-link" target="_blank" rel="nofollow noreferrer noopener">View Issue on Jira</a>
                        </div>
                        <div className="col-3 margin-bottom-10">
                            <button className={"btn-link"} onClick={this.createAnotherIssue}>Create Another Issue</button>
                        </div>
                    </div>
                    <div className="trends-table-header-width-100 margin-bottom-10">
                        <button className={'btn btn-cancel'} onClick={this.closeModal}>Close</button>
                    </div>
                </div>
                }
                {!loading && !closeJiraModal &&
                <div className="row">
                    <div className="trends-table-header-width-100 margin-bottom-10">
                        <label htmlFor="summary">Issue Summary</label>
                        <input autoFocus={true} type="text" name="summary" id="summary" className="form-control" value={summary} onChange={this.handleJiraChange} />
                    </div>
                    <div className="trends-table-header-width-100 margin-bottom-10">
                        <label>Description</label>
                        <TextEditor
                            name="description"
                            handleTextChange={this.handleTextFieldUpdate}
                            defaultText={description}
                        />
                    </div>
                    <div className="trends-table-header-width-100 margin-bottom-10">
                        <label htmlFor="attachments">Attachments (Multiple allowed)</label>
                        <input multiple type="file" name="attachments" id="attachments" className="form-control" onChange={this.handleJiraFile} />
                        <span style={{color: '#ededed'}}>Allowed File Types: .jpeg, .jpg, .png, .svg, .out, .txt</span>
                    </div>
                </div>}
            </GenericModal>
        )
    }
};

function mapStateToProps(state) {
    const { jira: { closeJiraModal, loading, link }} = state;
    return {
        closeJiraModal, loading, link
    };
}

const connectedToggleMenu = connect(mapStateToProps)(JiraBugModal);
export default connectedToggleMenu
