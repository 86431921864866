import { oauthConstants, msgDataSourceConstants } from "_constants";
import { msgDataSourceService, gorgiasAuthService, freshdeskAuthService } from "_services";
import { gorgiasAuthActions, gladlyAuthActions } from "_actions";
import {alertMessages} from "_constants";
import {history} from "_helpers";
import {SnackBar} from "components/common";
import moment from "moment";


export const msgDataSourceActions = {
    getAll,
    getMsgDataSource,
    deleteMsgDataSource,
    msgDataSourcesForMsgSource,
    updateMsgDataSource,
    getAllDataSources,
    toggleClosedTicketActioning,
    getAllFreshdeskDataSource,
    saveSupportEmail,
    getAccountInboxes,
    saveSelectedInboxes,
    resetInboxData,
    saveStatusOnReply,
    getAutoSenders,
    saveAutoSender,
    clearUpdatedFlag,
    deleteAutoSenderData,
    getOrganizationsForMsgSource,
    getOrganizationViews,
    getViewsTickets,
    retrievePlatformMacros,
    getSavedPlatformMacros,
    saveSelectedPlatformMacros,
    deletePlatformMacro,
    updateMacro,
    clearMacrosList,
    clearWebhookModal
};


function getAll(params) {
    return dispatch => {
        dispatch(request());

        msgDataSourceService.getAll(params)
            .then(
                msgDataSources => dispatch(success(msgDataSources)),
            error => {
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        }
    );
    };

    function request() {
        return {type: msgDataSourceConstants.GET_ALL_REQUEST}
    }

    function success(msgDataSources) {
        return {type: msgDataSourceConstants.GET_ALL_SUCCESS, payload: msgDataSources}
    }

    function failure(error) {
        return {type: msgDataSourceConstants.GET_ALL_FAILURE, payload: error}
    }
}

function getMsgDataSource(id) {
    return dispatch => {
        dispatch(request());

        msgDataSourceService.getMsgDataSource(id)
            .then(
                msgDataSource => dispatch(success(msgDataSource)),
            error => {
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        }
    );
    };

    function request(id) {
        return {type: msgDataSourceConstants.GET_DETAILS_REQUEST, payload: id}
    }

    function success(msgDataSource) {
        return {type: msgDataSourceConstants.GET_DETAILS_SUCCESS, payload: msgDataSource}
    }

    function failure(error) {
        return {type: msgDataSourceConstants.GET_DETAILS_FAILURE, payload: error}
    }
}

function deleteMsgDataSource(id) {

    return dispatch => {

        dispatch(request());

        msgDataSourceService.deleteMsgDataSource(id)
            .then(
                response => {
                    dispatch(success(response));
                    history.push("/company/channels");
                    SnackBar.showMessage(alertMessages.MSG_DATA_SOURCE_DELETE_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    history.push("/company/channels");
                    SnackBar.showMessage(alertMessages.MSG_DATA_SOURCE_DELETE_FAILURE);
                }
            )
    };

    function request(){
        return {type: msgDataSourceConstants.DELETE_REQUEST}
    }

    function success(response) {
        return {type: msgDataSourceConstants.DELETE_SUCCESS, payload: response}
    }

    function failure(error){

        return {type: msgDataSourceConstants.DELETE_FAILURE, error: error}
    }
}

/**
 * this action is used for storing data from one page
 * and retrieving it on another
 * no api call is made to the backend
 * @param msgDataSources
 * @returns {Function}
 */
function msgDataSourcesForMsgSource(msgDataSources){

    return dispatch => {
        dispatch(success(msgDataSources));
        history.push('/company/datasources')
    };

    function success(msgDataSources){
        return {type: msgDataSourceConstants.GET_MSG_DATA_SOURCES_FOR_MSG_SOURCE_SUCCESS, payload: msgDataSources}
    }
}

function updateMsgDataSource(msgDataSource, params){

    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.updateMsgDataSource(msgDataSource, params)
            .then(
                msgDataSource => dispatch(success(msgDataSource)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    }
};

function getAllDataSources(params) {
    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.getAllDataSources(params)
            .then(
                msgDataSources => {
                    if (params.dataSource == 'Gorgias') {
                        let msgDataSourceIds = msgDataSources.map(item => item.trendsId);
                        dispatch(gorgiasAuthActions.getGorgiasSupportEmails(msgDataSourceIds.join()))
                        let msgDataSourceAutoSenderEnabled = msgDataSources.filter(item => item.isAutoSenderEnabled)
                        if (msgDataSourceAutoSenderEnabled.length) {
                            dispatch(msgDataSourceActions.getAutoSenders(msgDataSourceAutoSenderEnabled.map(item => item.id)))
                        }
                    }
                    dispatch(success(msgDataSources))
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    }

    function request(){
        return {type: msgDataSourceConstants.GET_ALL_DATA_SOURCE}
    }

    function success(response) {
        return {type: msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS, payload: response}
    }

    function failure(error){
        return {type: msgDataSourceConstants.ALL_DATA_SOURCE_ERROR, error: error}
    }
}

function toggleClosedTicketActioning(data) {
    return (dispatch) => {
        msgDataSourceService.toggleClosedTicketActioning(data)
            .then(
                res => {
                    dispatch(success(data));
                    SnackBar.showMessage(res.message)
                },
                error => {
                    error.then(text => {
                        dispatch(failure(text));
                        SnackBar.showMessage(typeof text == 'object' ? text.message : JSON.parse(text).message)
                    })
                }
            );
    }

    function success(response) {
        return {type: msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_SUCCESS, payload: response}
    }

    function failure(error){
        return {type: msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_ERROR, error: error}
    }
}

function getAllFreshdeskDataSource() {
    return (dispatch) => {
        dispatch(request());

        freshdeskAuthService.getAllFreshdeskDataSource()
            .then(
                msgDataSource => dispatch(success(msgDataSource)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(){
        return {type: msgDataSourceConstants.GET_FRESHDESK_DATA_SOURCE}
    }

    function success(response) {
        return {type: msgDataSourceConstants.FRESHDESK_DATA_SOURCE_SUCCESS, payload: response}
    }

    function failure(error){
        return {type: msgDataSourceConstants.FRESHDESK_DATA_SOURCE_ERROR, error: error}
    }
}

function saveSupportEmail(payload) {
    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.saveSupportEmail(payload)
            .then(
                msgDataSource => {
                    dispatch(success({msgDataSource, payload}))
                    SnackBar.showMessage(alertMessages.MSGDATASOURCE_ACCOUNT_UPDATED)
                },
                error => {
                    error.then(text => {
                        dispatch(failure(text));
                        SnackBar.showMessage(text.message)
                    })
                }
            );
    };

    function request(){
        return {type: msgDataSourceConstants.SAVE_MSG_DATA_SOURCE}
    }

    function success(response) {
        return {type: msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_SUCCESS, payload: response}
    }

    function failure(error){
        return {type: msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_ERROR, error: error}
    }
}

function getAccountInboxes(msgDataSourceId) {
    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.getAccountInboxes(msgDataSourceId)
            .then(
                inboxes => {
                    dispatch(success({msgDataSourceId, inboxes}))
                    SnackBar.showMessage(alertMessages.MSGDATASOURCE_INBOXES_RETRIEVED)
                },
                error => {
                    error.then(text => {
                        dispatch(failure(text));
                        SnackBar.showMessage(JSON.parse(text).message)
                    })
                }
            );
    };

    function request(){
        return {type: msgDataSourceConstants.MSG_DATA_SOURCE_INBOXES_REQUEST}
    }

    function success(response) {
        return {type: msgDataSourceConstants.MSG_DATA_SOURCE_INBOXES_SUCCESS, payload: response}
    }

    function failure(error){
        return {type: msgDataSourceConstants.MSG_DATA_SOURCE_INBOXES_FAILURE, error: error}
    }
}

function saveSelectedInboxes(payload) {
    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.saveSelectedInboxes(payload)
            .then(
                msgDataSource => {
                    dispatch(success(msgDataSource))
                    SnackBar.showMessage(alertMessages.MSGDATASOURCE_ACCOUNT_UPDATED)
                },
                error => {
                    error.then(text => {
                        dispatch(failure(text));
                        SnackBar.showMessage(JSON.parse(text).message)
                    })
                }
            );
    };

    function request(){
        return {type: msgDataSourceConstants.SAVE_MSG_DATA_SOURCE}
    }

    function success(response) {
        return {type: msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_SUCCESS, payload: response}
    }

    function failure(error){
        return {type: msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_ERROR, error: error}
    }
}

function resetInboxData() {

    return dispatch => {
        dispatch(success())
    };

    function success() {
        return {type: msgDataSourceConstants.RESET_INBOX_DATA}
    }
}

function request(id) {
    return {type: msgDataSourceConstants.UPDATE_DETAILS_REQUEST, payload: id}
}

function success(msgDataSource) {
    return {type: msgDataSourceConstants.UPDATE_DETAILS_SUCCESS, payload: msgDataSource}
}

function failure(error) {
    return {type: msgDataSourceConstants.UPDATE_DETAILS_FAILURE, payload: error}
}

function saveStatusOnReply(payload) {
    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.saveStatusOnReply(payload)
            .then(() => {
                dispatch(success(payload))
                SnackBar.showMessage(alertMessages.MSGDATASOURCE_ACCOUNT_UPDATED)
            },
            error => {
                error.then(text => {
                dispatch(failure(text));
                    SnackBar.showMessage(JSON.parse(text).message)
                });
            }
        );
    };

    function request(){
        return {type: msgDataSourceConstants.TICKET_STATUS_UPDATE_REQUEST}
    }

    function success(payload) {
        return {type: msgDataSourceConstants.TICKET_STATUS_UPDATE_SUCCESS, payload }
    }

    function failure(error){
        return {type: msgDataSourceConstants.TICKET_STATUS_UPDATE_FAILURE, error: error}
    }
}


function getAutoSenders(msgDataSourceIds) {
    function success(payload) {
        return {type: oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SUCCESS, payload }
    }

    function failure() {
        return {type: oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_FAILURE}
    }

    return (dispatch) => {
        gorgiasAuthService.getAutoSenders(msgDataSourceIds)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

function saveAutoSender(autoSenderData, dataSourceId, editDataId) {
    function success(payload) {
        return {type: oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SAVE_SUCCESS, payload }
    }

    function failure() {
        return {type: oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SAVE_FAILURE}
    }

    return (dispatch) => {
        gorgiasAuthService.saveAutoSenders({autoSenderData, dataSourceId, editDataId })
            .then(
                (res) => {
                    res.editedId = editDataId
                    dispatch(success(res));
                    SnackBar.showMessage(editDataId ? alertMessages.GORGIAS_AUTO_SENDER_UPDATE : alertMessages.GORGIAS_AUTO_SENDER_SUCCESS);
                },
                (error) => {
                    error.then(message => SnackBar.showMessage(message.message))
                    dispatch(failure(error));
                },
            );
    };
}

function clearUpdatedFlag() {
    function success() {
        return {type: oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SAVE_CLEAR }
    }

    return (dispatch) => {
        dispatch(success());
    };
}


function deleteAutoSenderData(dataSourceId, deleteId) {
    function success(payload) {
        return {type: oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_DELETE_SUCCESS, payload }
    }

    function failure() {
        return {type: oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_DELETE_FAILURE}
    }

    return (dispatch) => {
        gorgiasAuthService.deleteAutoSenderData({ dataSourceId, deleteId })
            .then(() => {
                    dispatch(success({dataSourceId, deleteId}));
                    SnackBar.showMessage(alertMessages.GORGIAS_AUTO_SENDER_DELETE);
                },
                (error) => {
                    error.then(message => SnackBar.showMessage(message.message))
                    dispatch(failure(error));
                },
            );
    };
}

function getOrganizationsForMsgSource(msgSource) {
    function success(payload) {
        return {type: msgDataSourceConstants.ORGANIZATIONS_FOR_MSGSOURCE_SUCCESS, payload }
    }

    function failure() {
        return {type: msgDataSourceConstants.ORGANIZATIONS_FOR_MSGSOURCE_FAILURE}
    }

    return (dispatch) => {
        msgDataSourceService.getOrganizationsForMsgSource(msgSource)
            .then((orgList) => {
                    dispatch(success(orgList));
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

function getOrganizationViews(currentOrg, msgSource) {
    function request() {
        return {type: msgDataSourceConstants.ORGANIZATION_VIEWS_REQUEST }
    }

    function success(payload) {
        return {type: msgDataSourceConstants.ORGANIZATION_VIEWS_SUCCESS, payload }
    }

    function failure() {
        return {type: msgDataSourceConstants.ORGANIZATION_VIEWS_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.getOrganizationViews(currentOrg, msgSource)
            .then((orgList) => {
                    dispatch(success(orgList));
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

function getViewsTickets(viewId, currentOrg, dataSource) {
    function success(payload) {
        return {type: msgDataSourceConstants.VIEW_TICKETS_REQUEST, payload }
    }

    function success(payload) {
        return {type: msgDataSourceConstants.VIEW_TICKETS_SUCCESS, payload }
    }

    function failure(payload) {
        return {type: msgDataSourceConstants.VIEW_TICKETS_FAILURE, payload }
    }

    return (dispatch) => {
        dispatch(request({viewId}));

        msgDataSourceService.getViewsTickets(viewId, currentOrg, dataSource)
            .then((ticketsData) => {
                    dispatch(success(ticketsData));
                },
                (error) => {
                    dispatch(failure({error, viewId}));
                },
            );
    };
}

function retrievePlatformMacros(params) {
    return (dispatch) => {
        dispatch(request());
        msgDataSourceService.retrievePlatformMacros(params)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.PLATFORM_MACRO_GET_SUCCESS);
                    }
                },
                (error) => {
                    error.then(res => {
                        dispatch(failure(res));
                        SnackBar.showMessage(res.message)
                    })
                },
            );
    };

    function request() {
        return { type: msgDataSourceConstants.PLATFORM_MACROS_SELECTION_REQUEST };
    }
    function success(res) {
        return { type: msgDataSourceConstants.PLATFORM_MACROS_SELECTION_SUCCESS, payload: res };
    }
    function failure(error) {
        return { type: msgDataSourceConstants.PLATFORM_MACROS_SELECTION_FAILURE, payload: error };
    }
}

function getSavedPlatformMacros(params) {
    return (dispatch) => {
        dispatch(request());
        msgDataSourceService.getSavedPlatformMacros(params)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.PLATFORM_MACRO_GET_SUCCESS);
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.PLATFORM_MACRO_GET_FAILURE);
                },
            );
    };

    function request() {
        return { type: msgDataSourceConstants.PLATFORM_MACROS_LIST_REQUEST };
    }
    function success(res) {
        return { type: msgDataSourceConstants.PLATFORM_MACROS_LIST_SUCCESS, payload: res };
    }
    function failure(error) {
        return { type: msgDataSourceConstants.PLATFORM_MACROS_LIST_FAILURE, payload: error };
    }
}

function saveSelectedPlatformMacros(params) {
    return (dispatch) => {
        dispatch(request());
        msgDataSourceService.saveSelectedPlatformMacros(params)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.PLATFORM_MACRO_SAVED_SUCCESS);
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.PLATFORM_MACRO_SAVED_FAILURE);
                },
            );
    };

    function request() {
        return { type: msgDataSourceConstants.PLATFORM_SAVE_MACROS_REQUEST };
    }
    function success(res) {
        return { type: msgDataSourceConstants.PLATFORM_SAVE_MACROS_SUCCESS, payload: res };
    }
    function failure(error) {
        return { type: msgDataSourceConstants.PLATFORM_SAVE_MACROS_FAILURE, payload: error };
    }
}

function deletePlatformMacro(macroId) {
    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.deletePlatformMacro(macroId)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(macroId));
                        SnackBar.showMessage(alertMessages.PLATFORM_MACRO_REMOVED_SUCCESS);
                    }
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };

    function request(res) {
        return { type: msgDataSourceConstants.PLATFORM_REMOVE_MACROS_REQUEST };
    }
    function success(res) {
        return { type: msgDataSourceConstants.PLATFORM_REMOVE_MACROS_SUCCESS, payload: res };
    }
    function failure(error) {
        return { type: msgDataSourceConstants.PLATFORM_REMOVE_MACROS_FAILURE, payload: error };
    }
}

function updateMacro(macroId) {
    return (dispatch) => {
        dispatch(request());

        msgDataSourceService.updateMacro(macroId)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.PLATFORM_MACRO_UPDATE_SUCCESS);
                    }
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };

    function request() {
        return { type: msgDataSourceConstants.PLATFORM_UPDATE_MACROS_REQUEST };
    }
    function success(res) {
        return { type: msgDataSourceConstants.PLATFORM_UPDATE_MACROS_SUCCESS, payload: res };
    }
    function failure(error) {
        return { type: msgDataSourceConstants.PLATFORM_UPDATE_MACROS_FAILURE, payload: error };
    }
}

function clearMacrosList() {
    return (dispatch) => {
        dispatch(success());
    };

    function success() {
        return { type: msgDataSourceConstants.PLATFORM_CLEAR_MACROS_SUCCESS };
    }
}

function clearWebhookModal() {
    function success() {
        return { type: oauthConstants.CLOSE_WEBHOOK_MODAL };
    }

    return (dispatch) => {
        dispatch(success());
    };
}

