import React, {useState} from "react";
import Stack from "@mui/material/Stack";

const ChannelsCheckboxes = ({
    handles,
    setSelectedChannels,
    selectedChannels,
    allChannelsSelected,
    setAllChannelsSelected
}) => {
    const [searchText, setSearchText] = useState('');
    let searchedHandles = [...handles]
    if (searchText) searchedHandles = searchedHandles.filter(handle => handle.name && handle.name.toLowerCase().includes(searchText.toLowerCase()))

    const handleSelection = (e) => {
        const currentValue = e.target.value;
        if (e.target.checked) {
            if (selectedChannels.length === handles.length - 1){
                setAllChannelsSelected(true)
            }
            setSelectedChannels([...selectedChannels, currentValue]);
        } else {
            const currentSelections = selectedChannels.filter(channelId => channelId !== currentValue)
            setSelectedChannels(currentSelections);
            setAllChannelsSelected(false)
        }
    }

    const handleCheckAllChannel = (e) => {
        const allHandles = searchedHandles.map(handle => handle.id)
        if (e.target.checked) {
            setAllChannelsSelected(true);
            setSelectedChannels(allHandles)
        } else {
            setAllChannelsSelected(false)
            setSelectedChannels([])
        }
    };

    return (
        <Stack spacing={1} className="filter-all-channels" >
            <div>
                <span style={{color: '#495D5E'}}>Channel</span>
                <input className="searchable-channel-input" placeholder="Search channels"
                   onChange={e => setSearchText(e.target.value)} value={searchText} />
            </div>
            <Stack spacing={2} className="channel-checkbox-container">
                <label className="label" style={{marginTop: '10px', marginBottom: '10px'}}>All Channels
                    <input name="allChannelsSelected" type="checkbox" checked={allChannelsSelected} onChange={handleCheckAllChannel}/>
                    <span className="checkmark"/>
                </label>

                <hr style={{margin: '0px'}}/>
                <div className="scrollable-handle-list">
                    {searchedHandles.map((handle) =>
                        <label key={handle.id} htmlFor={handle.id} className="label channel-list-label" >
                            {handle.name}
                            <input id={handle.id} type="checkbox"
                                   checked={selectedChannels.includes(handle.id)}
                                   value={handle.id}
                                   onChange={handleSelection}
                            />
                            <span className="checkmark"/>
                        </label>
                    )}
                </div>
            </Stack>
        </Stack>
    )
}

export {ChannelsCheckboxes};