import { walletConstants } from '_constants';

const INITIAL_STATE = {
    agents: {},
    payoutHistory: [],
    agentDetails: {},
    handleModal: false,

    walletBalance: {},
    walletBalanceLoading: false,
    walletBalanceError: ''
};

export function walletReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case walletConstants.GET_ALL_AGENT_WALLET_REQUEST:
            return {...state, loading: true};
        case walletConstants.GET_ALL_AGENT_WALLET_SUCCESS:
            return {
                ...state,
                agents: action.payload,
                loading: false,
            };
        case walletConstants.GET_ALL_AGENT_WALLET_FAILURE:
            return {...state, error: action, loading: false };
        case walletConstants.AGENT_PAYOUT_HISTORY_REQUEST:
            return {...state, loading: true};
        case walletConstants.AGENT_PAYOUT_HISTORY_SUCCESS:
            return {
                ...state,
                payoutHistory: action.payload,
                loading: false,
            };
        case walletConstants.AGENT_PAYOUT_HISTORY_FAILURE:
            return {...state, error: action, loading: false };
        case walletConstants.PAYOUT_TO_AGENT_REQUEST:
            return {...state, loading: true};
        case walletConstants.PAYOUT_TO_AGENT_SUCCESS:
            let findIndex = state.agents.wallets ? state.agents.wallets.findIndex(item => item.agentId === action.payload.agentId) : null
            let current_all_payout = state.payoutHistory.payoutHistory ? [...state.payoutHistory.payoutHistory] : [];
            current_all_payout.unshift(action.payload.payoutHistory);
            if (current_all_payout.length > 10) {
                current_all_payout.pop()
            }
            state.agents.wallets.splice(findIndex, 1, {
                ...state.agents.wallets[findIndex],
                balance: action.payload.currentBalance
            })
            return {
                ...state,
                payoutHistory: {
                    ...state.payoutHistory,
                    currentBalance: action.payload.currentBalance,
                    count: state.payoutHistory.count + 1,
                    payoutHistory: current_all_payout
                },
                agents: !findIndex ? state.agents : {
                    ...state.agents,
                    wallets: state.agents.wallets,
                },
                handleModal: true,
                loading: false };
        case walletConstants.PAYOUT_TO_AGENT_FAILURE:
            return {...state, error: action, loading: false };
        case walletConstants.UPDATE_HANDLE_MODAL:
            return {...state, handleModal: false}

        case walletConstants.GET_AGENT_WALLET_BALANCE_REQUEST:
            return {...state, walletBalanceLoading: true};
        case walletConstants.GET_AGENT_WALLET_BALANCE_SUCCESS:
            return {...state, walletBalance: action.payload, walletBalanceLoading: false};
        case walletConstants.GET_AGENT_WALLET_BALANCE_FAILURE:
            return {...state, walletBalanceError: action.error, walletBalanceLoading: false};
            
        default:
            return state
    }
}