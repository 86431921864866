import React, {Fragment} from "react";
import EmailInfo from "./EmailInfo";
import {processAssignee} from "../../../utilities/ConversationUtils";

export default function EmailInfoList({ conversationItem }) {
    const {recipientEmail, subject, currentPage, assignee, other} = conversationItem;
    return(
        <Fragment>
            {
                currentPage &&
                <EmailInfo label={
                    <a href={currentPage} target="_blank" rel="noopener noreferrer">
                        {currentPage}
                    </a>
                }/>
            }
            {
                recipientEmail &&
                <EmailInfo label={`To: ${recipientEmail}`}/>
            }
            {
                subject &&
                <EmailInfo label={`Subject: ${subject}`}/>
            }
            {
                assignee &&
                <EmailInfo label={`Assigned to: ${processAssignee(assignee)}`}/>
            }
        </Fragment>
    )
}