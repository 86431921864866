import {useState} from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import {SnackBar} from "../../common";

export default function BasicFilterMultiSelect({options, defaultOptions, placeholder, onChange, textFieldStyle = {}, listBoxHeight = 150, maxLimit = undefined}) {
    const [initValue, setInitValue] = useState(defaultOptions);

    const handleOnChange = (event, values) => {
        if (maxLimit && values.length > maxLimit) {
            values.pop();
            SnackBar.showMessage("You have reached the maximum number of selections");
            return;
        }
        onChange(values);
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Autocomplete
            multiple
            options={options}
            defaultValue={initValue}
            isOptionEqualToValue={(option, selectedValue) => {
                return option.name === selectedValue.name;
            }}
            filterOptions={filterOptions}
            ListboxProps={{ style:  {maxHeight: listBoxHeight, ...styles.listbox}}}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={handleOnChange}
            renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: '20px', color: '#368C86' } }}
                            checked={selected}
                        />
                        {option.name}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{...styles.textField, ...textFieldStyle}}
                    placeholder={placeholder}
                />
            )}
        />

    );
}

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name,
});

const styles = {
    textField: {
        width: '100%',
        fontSize: '16px !important',
        fontFamily: 'LarsseitRegular',
        backgroundColor: 'unset !important',
        color: '#0C2728',
        [`& fieldset`]: {
            border: '1px solid #0C2728',
            borderRadius: '5px',
        },
        [`& label`]: {
            fontSize: '16px',
        },
        '& label.Mui-focused': {
            color: '#0C2728',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#0C2728',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
        '& .MuiInputBase-input': {
            height: '8px',
        },
        'input': {
            '&::placeholder': {
                fontFamily: 'LarsseitRegular',
            }
        }
    },
    listbox: {
        fontFamily: 'LarsseitRegular',
    }
};