export class MsgDataSourceBuilder {
    constructor() {
        this.msgDataSource = {};
    }

    setId(id){
        this.msgDataSource.id = id;
        return this;
    }

    setName(name){
        this.msgDataSource.name = name;
        return this;
    }

    setAccountName(accountName){
        this.msgDataSource.accountName = accountName;
        return this;
    }

    setMsgSourceName(msgSourceName){
        this.msgDataSource.msgSourceName = msgSourceName;
        return this;
    }

    build(){
        return this.msgDataSource;
    }
}
