import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import millify from "millify";
import { Loading } from 'components/common';
import StatsFilterForm from 'components/SummaryPage/StatsFilterForm';
import { organizationActions } from '_actions';
import { queryStringToObject, objectToQueryString } from '_helpers';
import SummaryItem from './SummaryItem';
import SummaryChart from "./SummaryChart";
import { TicketsSlider }from "./TicketsSlider";


/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 10/12/2018 : 9:47 PM
 */
class SummaryPage extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const search = location.search.substring(1);
    const filterParams = queryStringToObject(search);

    /**
      * By Default, if there is no selected start and end date,
      * we set the start date to the start of the current month and the endDate today
      */
    const startDate = filterParams.startDate ? moment(filterParams.startDate) : moment().startOf('month');
    const endDate = filterParams.endDate ? moment(filterParams.endDate) : moment().add(1, 'd');

    filterParams.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
    filterParams.endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

    this.state = {
      filterParams,
      organizationId: '',
    };
  }

  /**
     * A lifecycle method that dispatches an action creator to fetch data.
     */
  componentDidMount() {
    const { organization_id = '' } = JSON.parse(localStorage.user);

    this.setState({ organizationId: organization_id }, () => {
      this.getStats();
      this.getPositiveScreenshots();
    });
  }

  /**
   * Handles the change of url to retrieve messages based on query params for filtering
   */
  getStats = () => {
    const { state: { filterParams, organizationId }, props: { dispatch } } = this;

    dispatch(organizationActions.getSummaryStats(organizationId, { ...filterParams, organizationId }));
  };

  getPositiveScreenshots = () => {
    const { state: { filterParams, organizationId }, props: { dispatch } } = this;

    dispatch(organizationActions.getPositiveScreenshots(organizationId, filterParams));
  };

  /**
   * Handles the submission of a date range from the filter form.
   * @param props
   */
  handleFormSubmit = () => {
    const {
      state: { filterParams },
      props: { history, FilterForm: { values = { dateRange: null } } },
    } = this;
    const earliestDate = moment([2019, 11]); // December 1, 2019
    let startDate = values.dateRange ? values.dateRange.startDate : filterParams.startDate;
    let endDate = values.dateRange ? values.dateRange.endDate : filterParams.endDate;

    // We don't allow dates earlier than `earliestDate`
    if (moment(startDate).diff(earliestDate, 'minutes') < 0) {
      startDate = earliestDate.format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(earliestDate).add(1, 'M').endOf('month');

      // If `endDate` is greater than today, set to today
      if (moment().diff(endDate, 'minutes') < 0) {
        endDate = moment().add(1, 'd');
      }

      endDate = endDate.format('YYYY-MM-DD HH:mm:ss');
    } else {
      startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
    }

    this.setState({ filterParams: { startDate, endDate } }, () => {
      history.push({ pathname: '/stats', search: `?${objectToQueryString({ startDate, endDate })}` });
    });
  };

  handleExportClicked = () => {
    const { state: { filterParams, organizationId }, props: { dispatch } } = this;

    dispatch(organizationActions.exportStats(organizationId, filterParams));
  };

  renderWithoutFigures = () => (
    <div>
      <Row className="text-center" style={{ margin: '2%' }}>
        <Col mdOffset={4} md={4} style={{ paddingBottom: '7%' }}>
          <SummaryItem
            value="-"
            label="Total tickets handled by your Chatdesk Team"
          />
        </Col>
      </Row>
    </div>
  );

  renderWithFigures() {
    const { props: { summaryStats: { solvedTickets, lowPriority, highPriority, qualityScore, summaryStats, revenue }, positiveScreenshots } } = this;
    const total = solvedTickets + lowPriority + highPriority;
    const solvedPercentage = total ? Math.ceil((solvedTickets + lowPriority) / total * 100) : 0;

    for (let j = 1; j < summaryStats.length; j++) {
      summaryStats[j][0] = new Date(summaryStats[j][0]);
    }

    //style for the graph
    const options = {
      hAxis: {
        title: "Selected Date range",
        titleTextStyle: { color: 'rgb(180, 180, 190)' },
        textStyle: { color: 'rgb(150, 150, 150)' },
        gridlines: { color: '#fff', count: 1 },
        format: (summaryStats[1] && summaryStats[1][0].getYear() === summaryStats[summaryStats.length - 1][0].getYear()) ? 'M/d' : 'M/d/yy'
      },
      vAxis: {
        textStyle: { color: 'rgb(150, 150, 150)' },
        format: '#'
      },
      colors: ['#118652', '#808080', '#22A0B8'],
      legend: 'none',
      chartArea: { width: '100%', left: '20' },
      backgroundColor: '#fff',
      fontSize: 13,
      fontFamily: 'Roboto',
      isStacked: true
    };

    const rowWidth = revenue > 0 ? "20%" : "25%";


    return (
      <div>
        <Row className="text-left color-grey-dark" style={{ margin: '1%', height: '30px' }}>
          <p className="font-18">Tickets handled by your Chatdesk Team</p>
        </Row>

        <Row className="text-center color-grey-dark" style={{ borderBottom: '0.5px solid lightGrey', height: '100px' }}>
          {revenue > 0 &&
          <Col md={2} style={{ borderRight: '0.5px solid lightGrey', width: rowWidth }}>
            <SummaryItem value={"$" + millify(Math.floor(revenue))} label="Estimated Revenue" />
          </Col>
          }
          {qualityScore === 0 &&
            <Col md={3} style={{ borderRight: '0.5px solid lightGrey', width: rowWidth  }}>
              <SummaryItem value={total} label="Total Tickets" />
            </Col>
          }
          {qualityScore > 0 &&
            <Col md={ 3} style={{ borderRight: '0.5px solid lightGrey', width: rowWidth }}>
              <SummaryItem value={qualityScore + "%"} label="CSAT" />
            </Col>
          }
          <Col md={3} style={{ borderRight: '0.5px solid lightGrey', width: rowWidth }}>
            <SummaryItem value={solvedTickets} label="Solved" />
          </Col>
          <Col md={3} style={{ borderRight: '0.5px solid lightGrey', width: rowWidth  }}>
            <SummaryItem value={lowPriority} label="No Response Needed" />
          </Col>
          <Col md={2} style={{ width: "20%"  }}>
            <SummaryItem value={highPriority} label="Escalated" />
          </Col>
        </Row>
        <Row className="text-center color-grey-dark" style={{ margin: '0%' }}>
          <Col md={10} style={{ paddingBottom: '5%' }}>
            <SummaryChart data={summaryStats} options={options} />
          </Col>
          <Col md={2} style={{ paddingBottom: '5%', paddingTop: '5%', paddingLeft: '2%', textAlign: 'left'}}>
            <SummaryItem
              value={`${solvedPercentage}%`}
              label="Percentage of tickets solved by Chatdesk"
            />
          </Col>
        </Row>

        {positiveScreenshots && positiveScreenshots.screenshotsMessages.length > 0 && <div>
          <TicketsSlider
              screenshotsMessages={positiveScreenshots.screenshotsMessages}
          />
        </div>
        }
      </div>
    );
  }

  render() {
    const {
      props: { loading, summaryStats: { solvedTickets, lowPriority, highPriority } },
      state: { filterParams },
    } = this;

    return loading
      ? <Loading />
      : (
        <Fragment>
          <div className="container-pane" style={{ display: '', padding: '0 38px', marginBottom: '20px' }}>
            <div className="side-bar-pane shadow-border">
              <StatsFilterForm
                setStartDate={moment(filterParams.startDate).format('MM/DD/YYYY')}
                setEndDate={moment(filterParams.endDate).format('MM/DD/YYYY')}
                onDatePickerUpdate={this.handleDatePickerUpdate}
                onSubmit={this.handleFormSubmit}
                exportClicked={this.handleExportClicked}
                {...filterParams}
              />
            </div>
            <div className="container-right">
              <div className="shadow-border margin-bottom-50" style={{ marginLeft: '38px', padding: '20px 33px 33px 33px' }}>

                {solvedTickets + lowPriority + highPriority
                  ? this.renderWithFigures()
                  : this.renderWithoutFigures()
                }

              </div>

            </div>
          </div>
        </Fragment>
      );
  }
}

function mapStateToProps(state) {
  const {
    alert,
    organizations: { loading, summaryStats, positiveScreenshots },
    form: { FilterForm },
  } = state;

  return {
    loading,
    summaryStats,
    alert,
    FilterForm,
    positiveScreenshots
  };
}

const connectedSummaryPage = connect(mapStateToProps)(SummaryPage);

export { connectedSummaryPage as SummaryPage };

SummaryPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  summaryStats: PropTypes.object,
};
