import { SERVER_URL } from 'config';
import {authActions} from "_actions";
import axios from "axios";
import {history} from "_helpers";
import { alertMessages } from '_constants';
import {SnackBar} from "../components/common";
import {useAuth0} from "@auth0/auth0-react";

/**
 *
 * @param username
 * @param password
 * @returns {Promise<Response | never>}
 */
function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username,
            password
        })
    };

    return fetch(`${SERVER_URL}/api/login`,
        requestOptions
    )
        .then(handleLoginResponse);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');

    // remove `redirectUrl`, this is set by connect channels functionality.
    localStorage.removeItem('redirectUrl');

    //remove `slackOAuthState` this is set when connecting slack account
    localStorage.removeItem('slackOAuthState');

    //remove `companyUpdate`, this is set when a company update is viewed
    localStorage.removeItem('companyUpdate');
}

function logoutWithRedirect (link) {
    const { logout } = useAuth0();
    authService.logout();
    logout({ returnTo: `${window.location.origin}/${link}` });
}

/**
 *
 * @param user
 * @returns {Promise<Response | never>}
 */
function register(user) {
    const userData = {...user, frontEndBaseURL: window.location.origin.toString()};
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    };

    return fetch(`${SERVER_URL}/api/signup`, requestOptions)
        .then(handleResponse)
}

function registerAgent(user) {
    const userData = {...user, frontEndBaseURL: window.location.origin.toString()};
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    };

    return fetch(`${SERVER_URL}/api/expert-signup`, requestOptions)
        .then(handleResponse)
}

/**
 *
 * @param response
 * @returns {*}
 */
function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    //Resolve promise to get JSON body
    return response.json()
        .then((json) => {
            return json
        });
}

/**
 *
 * @param response
 * @returns {*}
 */
function handleLoginResponse(response) {
    if (!response.ok) {
        const statusText = response.status === 401 ? 'Invalid username or password' : 'Login failed';
        return Promise.reject(statusText);
    }

    return response.json()
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user && user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}


const verifyEmail = (email) => {
    const verifyEmailEndpoint = `${SERVER_URL}/api/authentication`;

    return (dispatch) => {
        dispatch(authActions.verifyEmailRequest());

        return axios.post(verifyEmailEndpoint, {
            username: email.trim(),
            frontEndBaseURL: window.location.origin.toString()
        }).then(response => {

            if (response.data.status !== 'invalid email') {
                SnackBar.showMessage(alertMessages.VERIFY_EMAIL_SUCCESS);
                dispatch(authActions.verifyEmailSuccess(response.data));
            } else {
                SnackBar.showMessage(alertMessages.VERIFY_EMAIL_FAILURE);
                dispatch(authActions.verifyEmailSuccess(response.data));
            }

        }).catch(error => {
            dispatch(authActions.verifyEmailFailure(error.message));
        })
    }
};

const resetPassword = (newPassword, confirmPassword, encryptedUserId) => {
    const resetPasswordEndpoint = `${SERVER_URL}/api/authentication/createNewPassword`;

    return (dispatch) => {
        dispatch(authActions.resetPasswordRequest());

        return axios.post(resetPasswordEndpoint,
            {
                newPassword: newPassword.trim(),
                confirmPassword: confirmPassword.trim(),
                encryptedUserId: encryptedUserId
            }
        ).then(response => {
            dispatch(authActions.resetPasswordSuccess(response.data));
            if(response.data.status === 'success'){
                history.push('/');
            }

        }).catch(error => {
            dispatch(authActions.resetPasswordFailure(error.message));
        })
    }
};

const confirmEmail = (userId, token) => {

    const confirmEmailEndpoint = `${SERVER_URL}/api/authentication/confirmEmail`;
    return async (dispatch) => {
        dispatch(authActions.confirmEmailRequest());
        return await axios
            .get(confirmEmailEndpoint, {
                params: {
                    userId: userId,
                    token: token,
                }
            }).then(response => {
                dispatch(authActions.confirmEmailSuccess(response.data))

            }).catch(error => {
                dispatch(authActions.confirmEmailFailure(error.message));
                SnackBar.showMessage(error.message)
            })
    }
};

export const authService = {
    login,
    logout,
    register,
    registerAgent,
    verifyEmail,
    confirmEmail,
    resetPassword,
    logoutWithRedirect
};

