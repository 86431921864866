import React, { Fragment } from "react";
import logoTrustpilot from 'components/images/logos/logo_trustpilot.svg'
import useTrustpilotSetup from './useTrustpilotSetup'
import ChannelCard from '../../ChannelCard'
import TrustpilotAppModal from "./TrustpilotAppModal";
import DataSourceWebhookModal from "../../Utilities/DataSourceWebhookModal";
import {useSelector} from "react-redux";

export default function TrustpilotEntry() {
    const trustpilot = useSelector((state) => state.trustpilotAuth)
    const {initiateTrustpilotSetup,
        trustpilotSetupOpen,
        handleTrustpilotSetupClose,
        handleTrustpilotSetupOpen,
        redirectUrl
    } = useTrustpilotSetup({ callbackPath: '/trustpilot/accounts' });

    return (
        <Fragment>
            <ChannelCard
                icon={logoTrustpilot}
                label={'Trustpilot'}
                description={'Respond to customer reviews'}
                onClick={initiateTrustpilotSetup}
            />

            <TrustpilotAppModal
                open={trustpilotSetupOpen}
                handleOpen={handleTrustpilotSetupOpen}
                handleClose={handleTrustpilotSetupClose}
                redirectUrl={redirectUrl}
            />

            <DataSourceWebhookModal
                showWebhookUrl={trustpilot?.showWebhookUrl}
                webhookUrl={trustpilot?.webhookUrl}
                platformUrl={trustpilot?.platformUrl}
                dataSourceName={'Trustpilot'}
            />

        </Fragment>
    )
}