import {Box, FormControl, FormControlLabel, FormGroup, Grid, Switch, Toggle} from "@mui/material";
import {dataSourceHandleActions, organizationSettingsActions} from "_actions";
import {accessibilityMode} from "../organizationSettingsConstants";
import {useDispatch} from "react-redux";
import commonStyles from "../commonStyles";
import React from "react";
import InformationTooltip from "../InformationTooltip";

export default function SupervisedChatSection({handle, mode, label}) {
    const dispatch = useDispatch();

    const updateDataSourceHandle = (requestBody: object) => {
        dispatch(dataSourceHandleActions.update(requestBody, handle.id)).then(() => {
            dispatch(organizationSettingsActions.refreshDataSourceHandleInfo(handle.organizationId));
        });
    };

    const handleBasicChatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateDataSourceHandle({isSupervisedChatEnabled: event.target.checked});
    };

    const handleAgentEditsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateDataSourceHandle({isSupervisedChatAgentEditsEnabled: event.target.checked});
    };

    const basicChatSwitchEnabled = () => {
        return mode === accessibilityMode.EDIT;
    };

    const agentEditsSwitchEnabled = () => {
        return mode === accessibilityMode.EDIT && handle.supervisedChat.enabled;
    };

    return (
        <Box>
            <Box component={'h4'} sx={{...commonStyles.labelBold, display: 'flex', alignItems: 'center', gap: '5px'}}>
                {label.SUPERVISED_CHAT}
                <InformationTooltip label={"Allows dedicated experts to respond to tickets in the teams interface with suggested responses"} />
            </Box>
            <Grid container>
                <Grid item xs={3} sx={styles.labelContainer}>
                    <Box sx={commonStyles.labelMedium}>{label.BASIC_CHAT}</Box>
                </Grid>
                <Grid item xs={9}>
                    <Switch
                        checked={handle.supervisedChat.enabled}
                        onChange={mode == accessibilityMode.EDIT && handleBasicChatChange}
                        disabled={!basicChatSwitchEnabled()} />
                </Grid>
                <Grid item xs={3} sx={styles.labelContainer}>
                    <Box sx={commonStyles.labelMedium}>{label.AGENT_EDITS}</Box>
                </Grid>
                <Grid item xs={9}>
                    <Switch
                        checked={handle.supervisedChat.agentEdits}
                        onChange={mode == accessibilityMode.EDIT && handleAgentEditsChange}
                        disabled={!agentEditsSwitchEnabled()} />
                </Grid>
            </Grid>
        </Box>
    );
}

const styles = {
    labelContainer: {
         display: 'flex',
         alignItems: 'center'
    }
}
