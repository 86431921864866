import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import { Grid, Row } from "react-bootstrap";
import {
    organizationActions,
    slackOauthActions,
    systemSettingsActions,
} from "_actions";
import { Loading } from "components/common";
import { CompanyDetails, CompanyForm } from "components/Profile";

const style = { padding: "50px 65px", backgroundColor: "white" };

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/01/2019 : 2:26 PM
 */
class CompanyProfilePage extends Component {
    state = {
        editingOrganization: false,
    };

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.dispatch(organizationActions.getOrganization(id));
        this.props.dispatch(systemSettingsActions.showSystemSettings());
        this.props.dispatch(organizationActions.getOrgSlackStatus(id));
    }

    editClickHandler = () => {
        this.setState({ editingOrganization: true });
    };

    cancelClickHandler = () => {
        this.setState({ editingOrganization: false });
    };

    formSubmitHandler = (params) => {
        this.props.dispatch(organizationActions.updateOrganization(params));
        this.setState({ editingOrganization: false });
    };

    oauthRedirect(authUrl) {
        window.open(authUrl);
    }

    initiateOauth = (event) => {
        event.preventDefault();
        this.props.dispatch(slackOauthActions.initiateOauth());
    };

    disconnectSlack = (event,organization) => {
        event.preventDefault();
        this.props.dispatch(slackOauthActions.disconnectSlack(organization.id))
    }

    render() {
        const { loading, organization, slackOauth, organizationSlackStatus } = this.props;
        const { id } = this.props.match.params;

        if (slackOauth.data.oauthUrl) {
            const url = new URL(slackOauth.data.oauthUrl);
            const searchParams = url.searchParams;
            localStorage.setItem("slackOAuthState", searchParams.get("state"));
            this.oauthRedirect(slackOauth.data.oauthUrl);
        }

        if (loading) {
            return <Loading />;
        }

        return (
            <Fragment>
                <CompanyForm
                    style={{ border: "none" }}
                    company={organization}
                    show={this.state.editingOrganization}
                    close={this.cancelClickHandler}
                    onSave={this.formSubmitHandler}
                />

                <Grid className="top-rel-100 shadow-border">
                    <Row style={style}>
                        <CompanyDetails
                            company={organization}
                            onEditClick={this.editClickHandler}
                            dispatch={this.props.dispatch}
                            id={id}
                        />
                        <div>
                            {organizationSlackStatus?.isActive ? (
                                <button
                                    onClick={(e) => this.disconnectSlack(e, organization)}
                                    className="btn font-14 color-danger"
                                >
                                    Disconnect Slack account
                                </button>
                            ) : (
                                <button
                                    onClick={(e) => this.initiateOauth(e)}
                                    className="btn btn-primary font-14 color-grey-dark"
                                >
                                    Connect Slack account
                                </button>
                            )}
                        </div>
                    </Row>
                </Grid>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        organizations,
        slackOauth,
        systemSettings: { systemSettings },
    } = state;
    const { organization, organizationSlackStatus, loading } = organizations;

    return {
        organization,
        loading,
        slackOauth,
        systemSettings,
        organizationSlackStatus,
    };
};

const connectedCompanyProfilePage =
    connect(mapStateToProps)(CompanyProfilePage);
export { connectedCompanyProfilePage as CompanyProfilePage };
