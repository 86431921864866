import React, {Fragment, useState} from "react";
import {Box} from "@mui/system";
import styles from "../Utilities/templateStyles";
import {Grid} from "@mui/material";
import useGenerateReplies from "../Utilities/hooks/useGenerateReplies";
import CircularProgress from "@mui/material/CircularProgress";
import _ from 'lodash';
import UseReplyButton from "../Utilities/UseReplyButton";
import SuggestedResponseButton from "../Utilities/SuggestedResponseButton";

export default function UpdateReplies({ handleReplies, selectedReplies, template, setIsEdited, generateRepliesDisabled }) {
    const { replies, loading, requested } = useGenerateReplies();
    const [selectedResponses, setSelectedResponses] = useState(new Set());
    const [isEditing, setIsEditing] = useState(false);
    const [isResponseSelected, setIsResponseSelected] = useState(false);
    const [selectedResponseId, setSelectedResponseId] = useState('');
    let message = '';
    let renderEmptyState = true;
    let renderSuggestedResponses = false;
    let messageObj = {
        default: 'New replies will appear once criteria fields are filled out',
        empty: 'No replies generated',
        loading: <CircularProgress size={25} sx={{ color: '#07857C'}}/>
    };
    const suggestedResponses = _.get(template, 'suggestedResponses') || [];
    const channel = _.get(template, 'channel');

    if(!requested && _.isEmpty(replies)) {
        message = messageObj.default;
        renderEmptyState = true;
    }
    if(loading) {
        message = messageObj.loading
        renderEmptyState = true;
    }
    if (!loading && requested &&  _.isEmpty(replies)){
        message = messageObj.empty;
        renderEmptyState = true;
    }
    if (!loading && requested &&  !_.isEmpty(replies)){
        renderEmptyState = false;
    }

    if(!_.isEmpty(suggestedResponses)) {
        renderSuggestedResponses = true;
    }

    const handleOpenEditResponse = (suggestedResponseId) => {
        setIsEditing(true);
        setIsResponseSelected(true);
        setSelectedResponseId(suggestedResponseId);
    };

    const handleCloseEditResponse = () => {
        setIsEditing(false);
        setIsResponseSelected(false);
        setSelectedResponseId('');
    };

    const RepliesContent = ({ replies, hasResponses }) => {
        if(renderEmptyState) {
            return (
                <Box sx={ hasResponses ?
                    {...styles.repliesContainerEmptyState, ...{minHeight: '150px'}} : styles.repliesContainerEmptyState}>
                    { message }
                </Box>
            )
        }
        return (
            <Box sx={{marginTop: '12px'}}>
                <Box component={'ul'} sx={{listStyle: 'none', m: 0, p: 0}}>
                    { replies.map((item, index, replies) =>
                        <UseReplyButton
                            key={index}
                            item={item}
                            index={index}
                            replies={replies}
                            selectedReplies={selectedReplies}
                            handleReplies={handleReplies}
                        />)
                    }
                </Box>
            </Box>
        )
    }

    const SuggestedResponsesContent = ({ responses }) => {
        if(!renderSuggestedResponses) {
            return (
                <Box sx={styles.repliesContainerEmptyState}>
                    { message }
                </Box>
            )
        }
        return (
            <Box sx={{marginTop: '12px'}}>
                <Box component={'ul'} sx={{listStyle: 'none', m: 0, p: 0}}>
                    { responses.map((item, index, responses) =>
                        <SuggestedResponseButton
                            key={index}
                            item={item}
                            index={index}
                            responses={responses}
                            selectedResponses={selectedResponses}
                            handleSelectedResponses={setSelectedResponses}
                            setIsEdited={setIsEdited}
                            isEditing={isEditing}
                            selectedResponseId={selectedResponseId}
                            handleOpenEditResponse={handleOpenEditResponse}
                            handleCloseEditResponse={handleCloseEditResponse}
                            channel={channel}
                        />)
                    }
                </Box>
            </Box>
        )
    }

    return(
        <>
            <Grid item xs={12} md={6} sx={styles.repliesSection}>
                {
                    !generateRepliesDisabled &&
                    <Fragment>
                        <Box sx={styles.heading}> New replies </Box>
                        <RepliesContent replies={replies} hasResponses={renderSuggestedResponses} />
                    </Fragment>
                }
                {
                    renderSuggestedResponses &&
                    <Fragment>
                        <Box sx={{...styles.heading, ... !generateRepliesDisabled && {marginTop: '25px'}}}> Suggested responses </Box>
                        <SuggestedResponsesContent responses={suggestedResponses} />
                    </Fragment>
                }
            </Grid>
        </>
    )
}