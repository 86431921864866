import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { GenericModal } from 'components/common';
import { connect } from 'react-redux';
import { messageActions } from '_actions';
import {ConversationList} from 'components/ConversationThread/ConversationList';
import { canShowConversationCount } from "_helpers";
import _ from 'lodash';

class ConversationThread extends Component {
    state = {
        showModal: false,
    };

    hideModal = () => {
        this.setState({ showModal: false });
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    showConversationThread = (message) => {
        const { dispatch } = this.props;
        const isProactive = _.get(message, 'isProactive');
        const isSupervisedChat = _.get(message, 'isSupervisedChat');

        if (isProactive || isSupervisedChat) {
            dispatch(messageActions.getConversationFromTeams(message.id));
        } else {
            dispatch(messageActions.getConversationFromTrends(message.id));
        }
        this.openModal()
    };

    render() {
        const { message, conversationCount, conversationThread, loadingModal, isTrendsMessage, style, content, msgSource, organization } = this.props;
        const { link, dataSourceHandle: { displayInApp }, isProactive } = message;
        const { showModal } = this.state;

        return (
            <Fragment>
                { displayInApp && (
                    <button
                        className="font-15 color-green tms-no-underline transparent-button"
                        onClick={() => this.showConversationThread(message)}
                        type="button"
                        style={style ? style : {padding: 0, outline: 'none'}}
                    >
                        {content ? content : <span>View Conversation { canShowConversationCount(conversationCount, displayInApp) && `(${conversationCount} messages)`}</span>}
                    </button>
                )
                }
                {(link && link !== 'null' && !displayInApp)
                && (
                    <a
                        className="font-15 color-green tms-no-underline"
                        href={link}
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                    >
                        {content ? content : "View Conversation" }
                    </a>
                )
                }
                <GenericModal
                    headerText="Customer conversation thread"
                    hideFooter
                    show={showModal}
                    onHide={this.hideModal}
                    size="lg"
                    isConversationThread={true}
                    enforceFocus={false}
                >
                    <ConversationList
                      mainMessage={message}
                      messages={conversationThread}
                      loading={loadingModal}
                      isTrendsMessage={isTrendsMessage}
                      msgSource={msgSource}
                      organization={organization}
                    />
                </GenericModal>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { conversation } = state;
    return {
        conversationThread: conversation.conversation,
        loadingModal: conversation.loading,
    };
}

export default connect(mapStateToProps)(ConversationThread);

ConversationThread.propTypes = {
    dispatch: PropTypes.func,
    message: PropTypes.object,
    conversationThread: PropTypes.array,
    loadingModal: PropTypes.bool,
};
