import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { metricsActions } from "_actions";
import _ from "lodash";

export default function () {
  const dispatch = useDispatch();
  const { organization } = JSON.parse(localStorage.user);

  useEffect(() => {
    dispatch(metricsActions.getScheduleDetails(_.get(organization, 'id')));
  }, [])
  const metrics = useSelector(state => state.metrics);
  const scheduleDetails = metrics.scheduleDetails;
  const loading = metrics.loadingScheduleDetails;
  const data = _.get(scheduleDetails, 'scheduleDetailList') || [];

  return {
    data,
    loading,
    extraData: {
      timezone: _.get(organization, 'timezone')
    }
  }
}