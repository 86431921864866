import {authConstants} from '_constants';

let user = JSON.parse(localStorage.getItem('user'));

const INITIAL_STATE = user ? {loggedIn: true, user} : {verifyEmailResponse: {}, error: null};

export function authenticationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {loggingIn: true, user: action};
        case authConstants.LOGIN_SUCCESS:
            return {loggedIn: true, user: action.user};
        case authConstants.LOGIN_FAILURE:
            return {loggedIn: false, loggingIn: false, error: 'Invalid username or password', password: ''};
        case authConstants.LOGOUT:
            return {};
        case authConstants.REGISTER_REQUEST:
            return {registering: true};
        case authConstants.REGISTER_SUCCESS:
            return {email: action.user.username};
        case authConstants.REGISTER_FAILURE:
            return {};
        case authConstants.UPDATE_USER_PHOTO:
        return {
            ...state, user: {
                    ...state.user, profilePicture: `${action.user.profilePicture}#${new Date().getTime()}`
                },
            };
        case authConstants.UPDATE_ORG_PHOTO:
        return {
            ...state, user: {
                    ...state.user, _embedded: {
                        ...state.user._embedded, organization: {
                            ...state.user._embedded.organization, logo: `${action.org.logo}#${new Date().getTime()}`
                        }
                    }
                },
            };
        case authConstants.SAVE_UPDATED_USER:
        return {
            ...state, user: action.user
        };


        case authConstants.VERIFY_EMAIL_REQUEST:
            return{
                ...state, verifyingEmail: true
            };
        case authConstants.VERIFY_EMAIL_SUCCESS:
            return{
                ...state,
                verifyingEmail: false,
                verifyEmailResponse: action.payload,
            };
        case authConstants.VERIFY_EMAIL_FAILURE:
            return{
                ...state,
                verifyingEmail: false,
                verifyEmailResponse: action.payload,
            };


        case authConstants.CONFIRM_EMAIL_REQUEST:
            return{
                ...state, confirmingEmail: true
            };
        case authConstants.CONFIRM_EMAIL_SUCCESS:
            return{
                ...state,
                confirmingEmail: false,
                confirmEmailResponse: action.payload,
            };
        case authConstants.CONFIRM_EMAIL_FAILURE:
            return{
                ...state,
                confirmingEmail: false,
                confirmEmailResponse: action.payload,
            };


        case authConstants.PASSWORD_RESET_REQUEST:
            return{
                ...state, resettingPassword: true
            };
        case authConstants.PASSWORD_RESET_SUCCESS:
            return{
                ...state,
                resettingPassword: false,
                resetPasswordResponse: action.payload,
            };
        case authConstants.PASSWORD_RESET_FAILURE:
            return{
                ...state,
                resettingPassword: false,
                resetPasswordResponse: action.payload,
            };
        default:
            return state
    }
}