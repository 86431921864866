import React, {Component} from 'react';
import ClickOutHandler from 'react-onclickout'
import connect from "react-redux/es/connect/connect";
import {getComponentByKey} from '_helpers'


/**
 * @author Chris Okebata
 */


/**
 * trigger:             When clicked, this content toggles the context menu
 * body:                This is the list of actions to perform on the context menu
 * trianglePosition:    This determines the direction of the context menu and positions the triangle to either 'left, right or top'
 */
class GenericContext extends Component{

    constructor(props) {
        super(props);

        this.state = {
            showContextMenu: false
        };
    }

    /**
     * This handles the toggle of the dropdown menu
     */
    toggleContextMenuHandler = () => {
        let menuState = this.state.showContextMenu;
        this.setState({ showContextMenu: !menuState });
    };

    /**
     * This handles the closing of the dropdown menu
     */
    clickOutsideHandler = () => {
        this.setState({ showContextMenu: false })
    };

    render() {

        const { trianglePosition, contextPosition, scrollable, customStyle } = this.props;
        const { showContextMenu } = this.state;

        return(
            <div>
                <ClickOutHandler onClickOut={this.clickOutsideHandler}>
                    <div className="contextContainer no-select">
                        <span onClick={this.toggleContextMenuHandler}>
                            {getComponentByKey("trigger", this.props)}
                        </span>

                        {/*contextPosition={{position:"absolute", top:"0px", right:"0px"}} is the default style, adjust top and/or right values when needed*/}
                        <span className={`toggle-context-menu ${showContextMenu ? 'open-context-menu' : ''}`} style={contextPosition}>
                            <div className={`triangle-outer ${trianglePosition === 'left'
                                ? 'triangle-left' : trianglePosition === 'right'
                                    ? 'triangle-right' : 'triangle-top'}`}>
                                <div className="trds-triangle-inner" />
                            </div>

                            <div className={`trds-context-menu trds-context-settings ${trianglePosition}`} style={{...customStyle}}>
                                {getComponentByKey("header", this.props)}
                                <div className={`${scrollable ? 'scroll-y': ''}`}>
                                    <div className="font-14-bold color-grey-dark tms-ellipsis">
                                        <span onClick={this.toggleContextMenuHandler} className="context-menu-body-container">
                                            {getComponentByKey("body", this.props)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </ClickOutHandler>
            </div>
        )
    }
}

const GenericContextMenu = connect(null)(GenericContext);
export default GenericContextMenu;