import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { FilterForm } from 'components/SummaryPage/FilterForm';
import { statsActions, organizationActions, dataSourceHandleActions } from '_actions';
import moment from 'moment/moment';
import { queryStringToObject, objectToQueryString } from '_helpers';
import AnalyticsTable from 'components/SummaryPage/AnalyticsTable';
import {publicAndFeaturedVisibility} from "../../_constants";

class Analytics extends React.Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const search = location.search.substring(1);
    const filterParams = queryStringToObject(search);

    let { channels } = filterParams;
    channels =  channels ? channels.split(",") : [];

    /**
     * By Default, if there is no selected start and end date,
     * we set the start date to the start of 2 years previously and the endDate today
     */
    const startDate = filterParams.startDate ? moment(filterParams.startDate) : moment().startOf('year').subtract(2, 'years');
    const endDate = filterParams.endDate ? moment(filterParams.endDate) : moment().format('YYYY-MM-DD');

    filterParams.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
    filterParams.endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
    filterParams.channels = channels

    this.state = {
      filterParams: {
        ...{
          organization: 'select_organization',
          channels: [],
        },
        ...filterParams,
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const { organization: { id } } = currentUser._embedded;

    dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}));
    dispatch(dataSourceHandleActions.getAll({ organization: id, max: 1000 }));
    const { filterParams } = this.state;
    if (filterParams.organization !== 'select_organization') {
      dispatch(statsActions.getAnalyticsData(filterParams));
    }
  }

  /**
   * Update component state when filterForms data is changed
   * @param event
   */
  handleUpdateForm = (event) => {
    const { target } = event;
    this.setState(prevState => ({
      filterParams: { ...prevState.filterParams, [target.name]: target.value },
    }));
  }

  /**
   * Handles updating state when datepicker selection changes
   * @param event
   * @param data
   */
  handleDatePickerUpdate = (event, data) => {
    this.setState(prevState => ({
      filterParams: {
        ...prevState.filterParams,
        ...{
          startDate: data.startDate.format('YYYY-MM-DD HH:mm:ss'),
          endDate: data.endDate.format('YYYY-MM-DD HH:mm:ss'),
        },
      },
    }));
  }

  /**
   * Handles the change of url to retrieve messages based on query params for filtering
   * @param filterParams The parameters for filtering the messages to be retrieved
   */
  getAnalyticsData = (filterParams) => {
    const queryString = objectToQueryString(filterParams);
    const { history } = this.props
    history.push({
      pathname: '/analytics',
      search: `?${queryString}`,
    });
  }

  /**
   * Handles a change in the Channels filter.
   * params channels
   */
  handleChannelsFilterChange = (channels) => {
    const { key } = this.state;
    const { startDate, endDate } = this.state.filterParams;
    this.populateParams(key, channels, startDate, endDate);
  };

  /**
   * Extracts message channels List from dataSourceHandles
   * @param dataSourceHandles
   * @param returnValue (either ids or object)
   */
  getChannels = (dataSourceHandles, returnValue) => {
    const channels = [];

    dataSourceHandles.forEach((dataSourceHandle) => {
      if (returnValue === 'ids') {
        channels.push(dataSourceHandle.trendsId);
      } else {
        const dataSource = {
          value: dataSourceHandle.trendsId,
          name: dataSourceHandle.name,
        };
        channels.push(dataSource);
      }
    });

    return channels;
  };

  /**
   * Handles the submission of a date range from the filter form.
   * @param props
   */
  handleFormSubmit = (props) => {
    const { filterParams: { endDate } } = this.state;
    const newEndDate = moment().isSame(endDate, 'week')
      ? moment().add(1, 'd').format('YYYY-MM-DD HH:mm:ss') : endDate;
    this.setState(prevState => ({
      filterParams: {
        ...prevState.filterParams, endDate: newEndDate,
      },
    }), function () {
      const { filterParams } = this.state;
      this.getAnalyticsData(filterParams);
    });
  }

  populateParams(key, channels, inputStartDate, inputEndDate){
    const startDate = moment(inputStartDate).format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(inputEndDate).format('YYYY-MM-DD HH:mm:ss');

    this.setState(prevState => ({
      key,
      filterParams: {
        ...prevState.filterParams,
        startDate,
        endDate,
        channels,
      },
    }));

    const { filterParams } = this.state;
    return {
      ...filterParams,
      startDate: moment(filterParams.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(filterParams.endDate).format('YYYY-MM-DD HH:mm:ss'),
    };

  }

  render() {
    const { filterParams } = this.state;
    const { startDate, endDate, organization } = filterParams
    const { analytics, loading, dataSourceHandles } = this.props;
    let setStartDate = moment().startOf('year').subtract(2, 'years').format('M/D/YYYY');
    if (startDate) {
      setStartDate = moment(startDate).format('M/D/YYYY');
    }
    const setEndDate = moment(endDate).format('M/D/YYYY');
    let channels;
    let channelsIds;

    if (dataSourceHandles) {
      channels = this.getChannels(dataSourceHandles);
      channelsIds = this.getChannels(dataSourceHandles, 'ids');
    }

    return (
      <div className="container-pane" style={{ display: '', padding: '0 38px' }}>
        <div className="side-bar-pane shadow-border">
          <FilterForm
            startDate={setStartDate}
            endDate={setEndDate}
            organization={organization}
            channelsFilter={channels}
            channelsAction={filterParams.channels}
            channelsIds={channelsIds}
            onDatePickerUpdate={this.handleDatePickerUpdate}
            handleChannelsFilterChange={this.handleChannelsFilterChange}
            onFormSubmit={(event) => this.handleFormSubmit(event)}
            onFormUpdate={this.handleUpdateForm}
          />
        </div>

        <div className="container-right">
          <div className="shadow-border margin-bottom-50" style={{ marginLeft: '38px', padding: '20px 33px 33px 33px' }}>
            <div>
              <AnalyticsTable
                analytics={analytics}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, stats: { analytics, loading }, dataSourceHandles } = state;

  return {
    alert,
    analytics,
    loading,
    dataSourceHandles: dataSourceHandles.dataSourceHandles,
  };
}

export default connect(mapStateToProps)(Analytics);

Analytics.propTypes = {
  dispatch: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  analytics: PropTypes.object,
  loading: PropTypes.bool,
  dataSourceHandles: PropTypes.array,
};
