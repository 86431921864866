import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {history} from "_helpers";
import {tiktokShopActions} from "_actions";
import {Loading} from "../../../../../../common";

export default function TikTokShopUrlRedirect() {
    const dispatch = useDispatch();
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const authCode = values.get('code');
    const state = values.get('state');

    useEffect(() => {
        if (authCode && state) {
            const params = {authCode, state};
            dispatch(tiktokShopActions.createAccount(params));
        }
    }, []);

    const tiktokShop = useSelector((state) => state.tiktokShop);

    if (tiktokShop.authLoading) {
        return <Loading />;
    }

    history.push('/company/connectchannels');

    return(
        <Fragment/>
    )
}