import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {metricsActions} from "_actions";
import _ from "lodash";

export default function () {
  const dispatch = useDispatch();

  useEffect(() => {
    const { organization_id = '' } = JSON.parse(localStorage.user);
    dispatch(metricsActions.getScheduleSummary(organization_id));
  }, [])

  const metrics = useSelector(state => state.metrics);
  const scheduleSummary = metrics.scheduleSummary;
  const loadingScheduleSummary = metrics.loadingScheduleSummary;
  const scheduleSummaryList = _.get(scheduleSummary, 'scheduleSummaryList') || [];
  let scheduleSummaryObj = {}

  if(!loadingScheduleSummary && scheduleSummaryList.length > 0 ){
    scheduleSummaryObj = _(scheduleSummaryList).groupBy('dayId').value()
  }

  return { scheduleSummaryObj, loadingScheduleSummary }
}