import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

export const organizationService = {
    getAll,
    getOrganizationsPaidMessages,
    getOrganizationsPaidTickets,
    getOrganization,
    getOrgUsers,
    getAgentEmployers,
    getSummaryStats,
    exportStats,
    updateOrganization,
    getPerfectResponsesSummary,
    updateMultipleOrganizations,
    getOrganizationsProfitability,
    getPositiveScreenshots,
    saveOrgVerticals,
    getTeamLeads,
    getCSMs,
    getOrganizationsSlackStatus,
    orgEscalationsViaEmail,
    getCompanyAdmins,
    getOrganizationsCsat,
    getOrganizationsActiveExperts,
    getOrganizationsReviewsStats,
    deleteOrganizationLogo,
    getActiveOrganizations
};


function getAll(params) {

    return fetch(`${SERVER_URL}/api/organizations?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getOrganizationsPaidMessages(params) {

    return fetch(`${SERVER_URL}/api/organizations/totalPaidMessages?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getOrganizationsCsat(params){
    return fetch(`${SERVER_URL}/api/organizations/organizationsCsat?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getOrganizationsActiveExperts(params){
    return fetch(`${SERVER_URL}/api/organizations/organizationsActiveExperts?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getOrganizationsReviewsStats(params){
    return fetch(`${SERVER_URL}/api/organizations/organizationsReviewsStats?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getOrganizationsPaidTickets(date) {

    return fetch(`${SERVER_URL}/api/organizations/paidTickets?${objectToQueryString(date)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}
function getOrganization(id) {

    return fetch(`${SERVER_URL}/api/organizations/${id}/show`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function getOrgUsers(orgId, params) {

    return fetch(`${SERVER_URL}/api/organizations/${orgId}/users?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function getAgentEmployers(agentId) {

    return fetch(`${SERVER_URL}/api/users/${agentId}/employers`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

/**
 * Given an organization's ID, this method makes a GET request
 * to the server to retrieve the organization's stats
 * @param organizationId
 * @returns {Promise<Response | never>}
 */
function getSummaryStats(organizationId, params) {

    return fetch(`${SERVER_URL}/api/organizations/${organizationId}/summaryStats?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}
/**
 * Given an organization's ID, this method makes a request
 * to the server to retrieve the organization's stats as a CSV file
 * @param organizationId
 * @param params
 * @returns {Promise<Response | never>}
 */
function exportStats(organizationId, params) {
    return fetch(`${SERVER_URL}/api/organizations/${organizationId}/exportStats?${objectToQueryString(params)}`,
        { method: 'GET', headers: authHeader() })
        .then((response) => {
            response
                .blob()
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = 'Organization stats.csv';
                    anchor.click();
                });
        });
}

function updateOrganization(organization) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(organization)
    };

    return fetch(`${SERVER_URL}/api/organizations/${organization.id}/update`, requestOptions).then(handleHttpResponse);
}

function updateMultipleOrganizations(organization) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization)
    };

    return fetch(`${SERVER_URL}/api/organizations/updateMultiple`, requestOptions).then(handleHttpResponse);
}

function getPerfectResponsesSummary(date) {

    return fetch(`${SERVER_URL}/api/organizations/perfectResponseSummary?${objectToQueryString(date)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getOrganizationsProfitability(date) {
    return fetch(`${SERVER_URL}/api/organizations/profitability?${objectToQueryString(date)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getPositiveScreenshots(organizationId, params) {
    return fetch(`${SERVER_URL}/api/organizations/${organizationId}/screenshotsMessages?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function saveOrgVerticals() {
    return fetch(`${SERVER_URL}/api/organizations/verticals`,
        {
            method: 'POST',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getTeamLeads(orgId) {
    return fetch(`${SERVER_URL}/api/organizations/${orgId}/teamLeads`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getCSMs(orgId) {
    return fetch(`${SERVER_URL}/api/organizations/${orgId}/csms`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getOrganizationsSlackStatus (id){
    return fetch(`${SERVER_URL}/api/organizations/${id}/slack/status`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getCompanyAdmins(orgId) {
    return fetch(`${SERVER_URL}/api/organizations/${orgId}/companyAdmins`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function orgEscalationsViaEmail(orgId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`${SERVER_URL}/api/organizations/${orgId}/orgEscalationsViaEmail`, requestOptions).then(handleHttpResponse);
}

function deleteOrganizationLogo(orgId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${SERVER_URL}/api/organizations/${orgId}/delete-logo`, requestOptions).then(handleHttpResponse);
}

function getActiveOrganizations(params) {
    return fetch(`${SERVER_URL}/api/organizations/activeOrganizations?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}