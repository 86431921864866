import React from 'react'
import {ControlLabel, FormGroup} from "react-bootstrap";
import FormControl from "react-bootstrap/es/FormControl";

const NormalTextField = (props) => {
    const {placeHolder, type, label, style, name, id, value, onChange, hidden, autoComplete } = props;

    return (

        <FormGroup hidden={hidden}>
            <ControlLabel htmlFor={id}>{label}</ControlLabel>
            <FormControl
                name={name}
                value={value}
                id={id}
                autoComplete={autoComplete}
                type={type}
                placeholder={placeHolder}
                onChange={onChange}
                style={style} />
        </FormGroup>

    )
}


export { NormalTextField }