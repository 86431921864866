import {Box} from "@mui/material";
import _ from "lodash";
import BasicFilterSelect from "../../../../common/BasicFilterSelect";
import {visibility} from "../organizationSettingsConstants";

export default function VisibilityFilterSelect({selectedVisibility, handleSelectChange, updatedVisibility}) {
    const options = [
        { 'id': visibility.CLOSED.value, 'name': visibility.CLOSED.name },
        { 'id': visibility.FEATURED.value, 'name': visibility.FEATURED.name },
        { 'id': visibility.PRIVATE.value, 'name': visibility.PRIVATE.name },
        { 'id': visibility.PUBLIC.value, 'name': visibility.PUBLIC.name },
    ];
    const value = options?.find((item) => item.id === (updatedVisibility || _.get(selectedVisibility, 'id')));

    return(
        <Box sx={styles.visibilitySelectContainer}>
            <BasicFilterSelect
                placeholder={'Select visibility'}
                options={options}
                value={value}
                onChange={handleSelectChange}
                textFieldStyle={styles.visibilitySelectInput}
                listBoxHeight={380}
            />
        </Box>
    )
}

const styles = {
    visibilitySelectContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
    },
    visibilitySelectInput : {
        height: '42px',
        [`& fieldset`]: {
            borderRadius: '8px',
        },
        '& .MuiInputBase-input': {
            height: '10px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#687979',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },
}