import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';
import { SERVER_URL } from 'config';

export const adCommentsService = {
    activatePoll
};

function activatePoll(params) {
    return fetch(`${SERVER_URL}/api/ad-comments/activate-poll?${objectToQueryString(params)}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

