import {freshdeskAuthService} from '_services';

import {oauthConstants, alertMessages} from '_constants';
import {SnackBar} from "../components/common";

/**
 * This method handles verification of the user's credentials
 * @param params
 * @returns {Function}
 */
function verifyKey(params) {
    function request() {
        return {type: oauthConstants.FRESHDESK_AUTH_REQUEST};
    }

    function success(payload) {
        return {type: oauthConstants.FRESHDESK_AUTH_SUCCESS, payload }
    }

    function failure() {
        return {type: oauthConstants.FRESHDESK_AUTH_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        freshdeskAuthService.verifyApiKey(params)
            .then(
                (res) => {
                    dispatch(success(res));
                    SnackBar.showMessage(alertMessages.FRESHDESK_AUTH_SUCCESS);
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.FRESHDESK_AUTH_FAILURE);
                },
            );
    };
}

export const freshdeskAuthActions = {
    verifyKey
};