import { SERVER_URL } from "config";
import {authHeader, getJSON, handleHttpResponse, history} from '_helpers';
import { oauthConstants } from "_constants";
import MetaSetupModal from "../../components/common/Onboarding/Modal/MetaSetupModal";
import ChannelErrorModal from "../../components/common/Onboarding/Modal/ChannelErrorModal";

export const instagramOauthService = {
    fbLogin,
    submitSelectedPage,
    getPages,
    facebookLogin
};

export function fbLogin() {
    //do this check to avoid errors in the component when
    //fb sdk has not loaded
    //network issues may cause this particular problem
    if (window.FB) {
        window.FB.login((response) => {
            if (response.status === "connected") {
                const authResponse = response.authResponse;
                verifyPermissions(authResponse);
            } else {
                alert("Something went wrong as we couldn't authenticate your login credentials");
            }
        }, { scope: oauthConstants.TEAMS_FACEBOOK_APP_PERMISSIONS });
    }
}

export function facebookLogin() {
    //do this check to avoid errors in the component when
    //fb sdk has not loaded
    //network issues may cause this particular problem
    if (window.FB) {
        window.FB.login((response) => {
            if (response.status === "connected") {
                const authResponse = response.authResponse;
                validatePermissions(authResponse);
            } else {
                ChannelErrorModal.open('We are unable to authenticate your login credentials');
            }
        }, { scope: oauthConstants.TEAMS_FACEBOOK_APP_PERMISSIONS });
    }
}



function verifyPermissions(authResponse) {
    getPermissions(authResponse).then(response => {
        /**
         * for the list of requested permission, check that all these permissions
         * have been granted for the current request
         * if not notify the user and end oauthFlow
         *
         * every is an ES6 array method that returns false if a single instance
         * violates a given check rule
         */
        const allpermissionsGranted = response.data.every((data) => {
            return data.status === "granted"
        });

        if (!allpermissionsGranted) {
            alert("All requested permissions are required for best results. Click ok to try again.");
            const callbackUrl = localStorage.getItem('redirectUrl');
            history.push(callbackUrl.pathname);
            return
        }

        const accessToken = authResponse.accessToken;
        const currentUser = JSON.parse(localStorage.user);
        const data = {
            accessToken: accessToken,
            fbUserId: authResponse.userID,
            userId: currentUser.id
        };
        return submitAccessToken(data)
    }).catch(err => {
        console.log(err)
    })
}

function validatePermissions(authResponse) {
    getPermissions(authResponse).then(response => {
        /**
         * for the list of requested permission, check that all these permissions
         * have been granted for the current request
         * if not notify the user and end oauthFlow
         *
         * every is an ES6 array method that returns false if a single instance
         * violates a given check rule
         */
        const allpermissionsGranted = response.data.every((data) => {
            return data.status === "granted"
        });

        if (!allpermissionsGranted) {
            ChannelErrorModal.open('All requested permissions are required');
            return
        }

        const accessToken = authResponse.accessToken;
        const currentUser = JSON.parse(localStorage.user);
        const data = {
            accessToken: accessToken,
            fbUserId: authResponse.userID,
            userId: currentUser.id
        };
        return saveAccessToken(data)
    }).catch(err => {
        console.log(err)
    })
}

const getPermissions = (payload) => {
    const permissionsUrl = `https://graph.facebook.com/v19.0/${payload.userID}/permissions`
    const authHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${payload.accessToken}`,
    }

    const options = {
        method: 'GET',
        headers: authHeader
    }

    return getJSON(permissionsUrl, options)
}

function submitAccessToken(data) {

    return fetch(`${SERVER_URL}/api/instagram/genAccessToken`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse)
        .then(
            response => {
                if (response) {
                    history.push("instagram/pages")
                }
            }
        )
}

function saveAccessToken(data) {

    return fetch(`${SERVER_URL}/api/instagram/genAccessToken`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse)
        .then(
            response => {
                if(response) MetaSetupModal.open(oauthConstants.INSTAGRAM_CHANNEL);
            }
        )
}

function getPages() {

    return fetch(`${SERVER_URL}/api/instagram/pages`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse)

}

function submitSelectedPage(page) {

    return fetch(`${SERVER_URL}/api/instagram/submitpage`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(page)
        })
        .then(handleHttpResponse)

}
