import {jiraService} from '_services';
import {jiraConstants, alertMessages} from '_constants';
import {SnackBar} from "../components/common";
import { history } from '_helpers'

function jiraIssueCreation(payload, formData) {
    function request() {
        return {type: jiraConstants.JIRA_BUG_REQUEST};
    }

    function success(oauthUrl) {
        return {type: jiraConstants.JIRA_BUG_SUCCESS, payload: oauthUrl};
    }

    function failure(error) {
        return {type: jiraConstants.JIRA_BUG_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        jiraService.jiraIssueCreation(payload)
            .then(
                response => {
                    if (formData && [...formData].length) {
                        dispatch(jiraActions.uploadIssueAttachments(formData, response.key));
                    }
                    dispatch(success(response));
                    SnackBar.showMessage(alertMessages.JIRA_BUG_SUCCESS)
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.JIRA_BUG_FAILURE)
                }
            );
    };
}

function uploadIssueAttachments(formData, issueKey) {
    function failure(error) {
        return {type: jiraConstants.JIRA_BUG_ATTACHMENT_FAILURE, payload: error};
    }

    return (dispatch) => {
        jiraService.jiraIssueAttachment(formData, issueKey)
            .then(
                (error) => {
                    dispatch(failure(error));
                }
            );
    };
}

function oauth(redirectHost) {
    return () => {
        jiraService.jiraOauth(redirectHost)
            .then(
                response => {
                    window.location.replace(response.url);
                },
            );
    };
}

function completeOauthProcess(redirectHost, code) {
    return () => {
        jiraService.completeJiraOauth(redirectHost, code)
            .then(
                () => {
                    SnackBar.showMessage(alertMessages.JIRA_OAUTH_SUCCESS)
                    history.push('/admin/settings')
                },
                () => {
                    SnackBar.showMessage(alertMessages.JIRA_OAUTH_FAILURE)
                }
            );
    };
}

function clearToggle() {
    function request() {
        return {type: jiraConstants.JIRA_CLEAR_TOGGLE};
    }

    return (dispatch) => {
        dispatch(request());
    };
}

export const jiraActions = {
    jiraIssueCreation,
    uploadIssueAttachments,
    oauth,
    completeOauthProcess,
    clearToggle
};