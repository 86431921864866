import Tooltip from '@mui/material/Tooltip';
import {withStyles} from "@mui/styles";

const BasicTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#ffffff',
        maxWidth: 700,
        zIndex: 200,
        borderRadius: '4px',
        border: 'solid 1px #dee2e0',
        boxShadow: '0 2px 6px 2px rgba(196, 201, 199, 0.6)',
        wordWrap: 'break-word',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 12,
        '-webkit-box-orient': 'vertical',
        marginTop: '-10px'
    },
    popper: {
        opacity: 1,
    }
}))(Tooltip);

export default BasicTooltip;