import React, {Fragment} from "react";
import BasicSelect from "./BasicSelect";

export default function AttributeTypeSelect({name, label, placeholder, handleChange, input, meta }) {
    const options = [
        { 'id': 'LIST', 'name': 'List' },
        { 'id': 'TEXT', 'name': 'Text' },
    ]

    return(
        <Fragment>
            <BasicSelect
                name={name}
                label={label}
                placeholder={placeholder}
                options={options}
                onChange={handleChange}
                input={input}
                meta={meta} />
        </Fragment>
    )
}