import {useState} from "react";
import {useDispatch} from "react-redux";
import {Box} from "@mui/system";
import LikeActiveIcon from "@mui/icons-material/Favorite";
import LikeInactiveIcon from '@mui/icons-material/FavoriteBorder';
import _ from "lodash";
import {dispatcherActions} from "_actions";

export default function LikeAction({message}) {
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();
    const messageId = _.get(message, 'id');
    const sourceName = _.get(message, 'sourceName');

    const handleClick = (value) => (event) => {
        setIsActive(value);
        dispatch(dispatcherActions.likeMessageFromSource(messageId, sourceName));
    }

    const Content = () => {
        if(isActive) return <LikeActiveIcon sx={styles.activeLikeIcon}/>; //NOTE: There is currently no `unlike` implementation in the backend
        return <LikeInactiveIcon sx={styles.inactiveLikeIcon} onClick={handleClick(true)}/>;
    }

    return(
        <Box sx={styles.container}>
            <Content/>
        </Box>
    )
}

const styles = {
    container: {
        height: '20px',
        marginRight: '10px'
    },
    activeLikeIcon: {
        color: '#07857C',
        fontSize: '20px',
    },
    inactiveLikeIcon: {
        color: '#07857C',
        fontSize: '20px',
        cursor: 'pointer'
    },
}