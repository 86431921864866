import React from 'react';
import AttachmentChip from "./AttachmentChip";
import Box from "@mui/material/Box";
import {getFileExtFromName} from "_helpers";
import {SnackBar} from "../SnackBar";
import {getBase64StringFromData, getMimeFromFile} from "_actions";


export default function EditorAttachment({ selectedAttachments, handleSelectFile, handleDeselectAttachment }) {
  const fileMaxSize = 10485760; // bytes

  const verifyFile = (files) => {
    if (files && files.length > 0){
      const currentFile = files[0];
      const currentFileSize = currentFile.size;
      if(currentFileSize > fileMaxSize) {
        SnackBar.showMessage('File size exceeded maximum limit of 10mb');
        return false
      }
      return true
    }
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      const isVerified = verifyFile(files);

      if (isVerified){
        const currentFile = files[0];
        const fileName = currentFile.name;
        const fileSize = currentFile.size;
        const id = `file-${Date.now()}`;
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const result = reader.result;
          const data = {
            id: id,
            base64Data: result,
            file: currentFile,
            fileName: fileName,
            fileExtension: getFileExtFromName(fileName),
            mimeType: getMimeFromFile(fileName),
            fileSize: fileSize
          };
          handleSelectFile(data);
        }, false);
        reader.readAsDataURL(e.target.files[0]);
      }
      document.getElementById('fileInput').value = '';
    }
  };

  return(
    <Box className={'attachment-container'}>
      {selectedAttachments && selectedAttachments.map( (attachment) => {
        return <AttachmentChip
          id={attachment.id}
          fileName={attachment.fileName}
          fileSize={attachment.fileSize}
          action={handleDeselectAttachment}
        />
      })}
      <input type="file" id="fileInput" style={{display: "none"}} onChange={ onSelectFile } multiple={false}/>
    </Box>
  )
}