import React, {Fragment} from "react";
import logoGladly from 'components/images/logos/logo_gladly.svg'
import ChannelCard from '../../ChannelCard'
import useGladlySetup from "./useGladlySetup";
import GladlySetupModal from "./GladlySetupModal";

export default function GladlyEntry() {
	const { initiateGladlySetup, gladlySetupOpen, handleGladlySetupOpen, handleGladlySetupClose } = useGladlySetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoGladly}
				label={'Gladly'}
				description={'Clear your email and SMS ticket backlog'}
				onClick={initiateGladlySetup}
			/>

			<GladlySetupModal
				open={gladlySetupOpen}
				handleOpen={handleGladlySetupOpen}
				handleClose={handleGladlySetupClose}
			/>
		</Fragment>
	)
}