import React from 'react'
import {styles} from "components/Settings";
import {Col, Row} from "react-bootstrap";
import DatasourceStatusContextMenu from "components/common/NavContextMenu/DatasourceStatusContextMenu";

export const DataSourceHandle = ({handle, submitHandleStatus}) => {
    const {panelRowWrapper, panelCol} = styles;
    const dispatchStatus = handle.dispatchStatus === "LIVE" ? "On" : handle.dispatchStatus;
    return (
        <div className="color-grey-dark" style={panelRowWrapper} key={handle.id}>
            <Row>
                <Col sm={6} className="font-14" style={panelCol}>
                    <span className="trds-ellipsis padding-right-20">{handle.name}</span>
                </Col>
                <Col sm={3} style={panelCol}>
                    Status -
                    <span className="text-transform-upper-first" style={{'paddingLeft': '4px'}}>
                                    {handle.dispatchStatus === "LIVE" ? "On" : dispatchStatus}
                    </span>
                </Col>
                <Col sm={3} style={{...panelCol, justifyContent: "flex-end"}}>
                    <DatasourceStatusContextMenu onClick={submitHandleStatus} handle={handle}/>
                </Col>
            </Row>
        </div>
    )

};

export const DataSourceHandles = ({handles, onClick}) => {
    return (
        handles.map(handle => {
            return (
                <DataSourceHandle handle={handle} submitHandleStatus={onClick} key={handle.id}/>
            )
        })
    )
};