import React, { Component, Fragment } from 'react'
import { GenericModal } from 'components/common';
import {connect} from 'react-redux'
import { Button } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";


let modalStyles = {
    checkBox: {
        position: 'absolute',
        left: 0,
        top: 9,
    },
    checkMark: {
        top: 2,
        left:15,
    }
}

const AddDataItem = (props) => {
    const { autoSenderData, onDataChange, saveAutoSenderData, clearUpdate, key, currentCount } = props;
    return (
        <Fragment>
            <tr key={key || 0}>
                <td className="vertical-align-middle">
                    <textarea className="form-control" cols="2" name="recipientsList" onChange={onDataChange} value={autoSenderData.recipientsList}></textarea>
                </td>
                <td className="vertical-align-middle">
                    <input className="form-control" type="text" name="sender" onChange={onDataChange} value={autoSenderData.sender} />
                </td>
                <td className="vertical-align-middle text-center">
                    <div className="tms-check-box font-14">
                        <label>
                            <input type="checkbox" name="isDefault"
                                checked={!!autoSenderData.isDefault}
                                onChange={onDataChange}
                            />
                            <span className="tms-check-mark" style={modalStyles.checkBox}>{""}</span>
                        </label>
                    </div>
                </td>
                <td className="vertical-align-middle">
                    <Button className="btn-info btn-sm" onClick={saveAutoSenderData}>Save</Button>
                    {((key !== null || key !== 'undefined') || currentCount !== 0) &&
                        <Button className="btn-danger btn-sm" onClick={clearUpdate}>X</Button>
                    }
                </td>
            </tr>
            <tr>
                <td colSpan="4">
                    <span className="text-sm">Seperate multiple email addresses using comma <kbd>,</kbd></span>
                </td>
            </tr>
        </Fragment>
    )
}

class AddAutoSenderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            autoSenderData: {},
            editDataId: null,
            addData: false,
            deleteId: null,
            confirmDelete: false
        }
    }

    componentDidUpdate() {
        const { updated, dispatch } = this.props
        if (updated) {
            this.clearUpdate();
            dispatch(msgDataSourceActions.clearUpdatedFlag())
        }
    }

    editAutoSenderData = (autoSenderData, editDataId) => {
        this.setState({
            autoSenderData, editDataId
        });
    };

    onDataChange = (e) => {
        this.setState((prevState) => ({
            autoSenderData: {
                ...prevState.autoSenderData,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
            }
        }));
    };

    saveAutoSenderData = () => {
        const { dataSourceId, dispatch } = this.props
        const { autoSenderData, editDataId } = this.state
        dispatch(msgDataSourceActions.saveAutoSender(autoSenderData, dataSourceId, editDataId ))
    };

    clearUpdate = () => {
        this.setState({
            autoSenderData: {},
            editDataId: null,
            addData: false,
            deleteId: null,
            confirmDelete: false,
        });
    };

    addData = () => {
        this.setState({
            addData: true,
            autoSenderData: {},
            editDataId: null,
        })
    };

    deleteAutoSenderData = (itemId) => {
        this.setState({
            confirmDelete: true,
            deleteId: itemId
        })
    };

    executeDelete = () => {
        const { deleteId } = this.state;
        const { dispatch, dataSourceId } = this.props;
        dispatch(msgDataSourceActions.deleteAutoSenderData(dataSourceId, deleteId))
    };

    render() {
        const { autoSenderData, editDataId, addData, deleteId, confirmDelete } = this.state
        const { showData, autoSenders, closeModal, dataType } = this.props

        return (
            <GenericModal
                headerText={`Update ${dataType} Auto Sender`}
                hideFooter
                show={showData}
                onHide={closeModal}
                size="lg"
                isConversationThread={true}
            >
                <table className="table">
                    <thead className="font-12-bold">
                        <tr>
                            <th className="col-md-6 vertical-align-middle">
                                Recipients
                                {dataType !== 'Gorgias' &&
                                    <div>(Leave blank to set for messages)</div>
                                }
                            </th>
                            <th className="col-md-3 vertical-align-middle">Sender</th>
                            <th className="col-md-1 vertical-align-middle">Is Default</th>
                            <th className="col-md-2 vertical-align-middle"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {autoSenders && autoSenders.length > 0 && autoSenders.map((item) =>
                            <Fragment>
                                {item.id !== editDataId &&
                                    <tr key={item.id}>
                                        {item.id === deleteId &&
                                            <td colSpan="4">
                                                <span className="">
                                                    Are you sure you want to delete? <br />
                                                    <Button className="btn-info btn-sm margin-top-5" onClick={this.executeDelete}>Yes</Button>
                                                    <Button className="btn-danger btn-sm margin-left-5 margin-top-5" onClick={this.clearUpdate}>No</Button>
                                                </span>
                                            </td>
                                        }
                                        {item.id !== deleteId &&
                                            <Fragment>
                                                <td className="vertical-align-middle">{item.recipientsList ? item.recipientsList.split(',').join(', ') : <b>All Recipients</b>}</td>
                                                <td className="vertical-align-middle">{item.sender}</td>
                                                <td className="vertical-align-middle">
                                                    {item.isDefault && 
                                                        <div className="tms-check-box font-14">
                                                            <span className="tms-check-mark display-block" style={modalStyles.checkMark}></span>
                                                        </div>        
                                                    }
                                                </td>
                                                <td className="vertical-align-middle">
                                                    <Button className="btn-info btn-sm" onClick={() => this.editAutoSenderData(item, item.id)}>Edit</Button>
                                                    <Button className="btn-danger btn-sm" onClick={() => this.deleteAutoSenderData(item.id)}>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                </td>
                                            </Fragment>
                                        }
                                    </tr>
                                }
                                {item.id === editDataId &&
                                    <AddDataItem
                                        autoSenderData={autoSenderData}
                                        onDataChange={this.onDataChange}
                                        saveAutoSenderData={this.saveAutoSenderData}
                                        clearUpdate={this.clearUpdate}
                                        key={item.id}
                                        currentCount = {autoSenders.length}
                                    />
                                }
                            </Fragment>
                        )}
                        {((addData || !autoSenders || autoSenders.length === 0) && !editDataId) &&
                            <AddDataItem
                                autoSenderData={autoSenderData}
                                onDataChange={this.onDataChange}
                                saveAutoSenderData={this.saveAutoSenderData}
                                clearUpdate={this.clearUpdate}
                                currentCount={autoSenders && autoSenders.length}
                            />
                        }
                        {(!addData && (autoSenders && autoSenders.length > 0)) &&
                            <tr>
                                <td colSpan="4">
                                    <Button className="btn-info btn-sm float-right" onClick={this.addData}>Add Auto Sender</Button>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </GenericModal>
        )
    }
}

function mapStateToProps(state) {
    const { gorgiasAuth: { updated } } = state;
    return {
        updated, 
    };
}

const connectedAddInbox = connect(mapStateToProps)(AddAutoSenderModal)
export { connectedAddInbox as AddGorgiasAutoSenderModal }