/**
 * @author Chris Okebata
 */

export const agentScoreConstants = {
  GET_AGENT_SCORE_REQUEST: 'GET_AGENT_SCORE_REQUEST',
  GET_AGENT_SCORE_SUCCESS: 'GET_AGENT_SCORE_SUCCESS',
  GET_AGENT_SCORE_FAILURE: 'GET_AGENT_SCORE_FAILURE',

  GET_AGENT_MESSAGE_SCORE_REQUEST: 'GET_AGENT_MESSAGE_SCORE_REQUEST',
  GET_AGENT_MESSAGE_SCORE_SUCCESS: 'GET_AGENT_MESSAGE_SCORE_SUCCESS',
  GET_AGENT_MESSAGE_SCORE_FAILURE: 'GET_AGENT_MESSAGE_SCORE_FAILURE',
};