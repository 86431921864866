import React, {Component, Fragment} from "react";
import {alertActions, messageActions} from "../../_actions";
import {connect} from "react-redux";
import {GenericModal, Loading} from "../common";
import {alertMessages} from "../../_constants";

class MessageHasReplyOrIsDeleted extends Component {
  state = {
    showModal: false,
  };

  hideModal = () => {
    this.setState({showModal: false});
  };

  openModal = () => {
    this.setState({showModal: true});
  };

  showMessageStatus = (message) => {
    const {dispatch} = this.props;
    dispatch(messageActions.confirmMessageHasReplyOrIsDeleted({messageId: message.id}));
    this.openModal()
  };

  performRequiredAction = (message) => {
    const { status, actionMessage, dispatch } = this.props;
    switch (status) {
      case "Reply present":
        actionMessage(message.id, "COMPANY_REPLIED")
        break
      case "Message deleted":
        actionMessage(message.id, "USER_DELETE")
        break
      case "No reply yet":
        dispatch(alertActions.success(alertMessages.NO_REPLY_ON_MESSAGE_ACTION))
        break
      default:
        dispatch(alertActions.error(alertMessages.GENERIC_ERROR))
        break
    }
    this.hideModal()
  }

  render = () => {
    const { message, style, status, loading } = this.props
    const { showModal } = this.state;

    return (
      <Fragment>
        <button
          className="font-15 color-green tms-no-underline transparent-button"
          type="button"
          style={style ? style : {padding: 0, outline: 'none'}}
          onClick={() => this.showMessageStatus(message)}>
          Has Reply Or Deleted
        </button>

        <GenericModal
          headerText="Company Replied or Comment Deleted"
          show={showModal}
          onHide={this.hideModal}
          size="lg"
          enforceFocus={false}
          hideFooter={loading}
          buttonText={"Action Message"}
          onSave={() => this.performRequiredAction(message)}
        >
          {loading ? <Loading height={150}/> : <h4 className="text-center">{status}</h4>}
        </GenericModal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {messages: {messageHasReplyOrIsDeleted}} = state
  return {
    status: messageHasReplyOrIsDeleted.status,
    loading: messageHasReplyOrIsDeleted.loading
  }
}

export default connect(mapStateToProps)(MessageHasReplyOrIsDeleted);

