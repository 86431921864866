import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import {auth0Actions} from "_actions";
import {Loading} from "../common";
import {history} from "_helpers";


function Auth0Callback () {
  const {isLoading, loginWithRedirect, getIdTokenClaims, isAuthenticated} = useAuth0();

  if(isLoading){
    return <Loading/>
  }

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
    return;
  }

  const getTokenClaims = async () => {
    return await getIdTokenClaims();
  };

  if(!isLoading && isAuthenticated){
    const dispatch = useDispatch();
    getTokenClaims()
      .then(function (claims) {
        const payload = {idToken: claims.__raw};
        dispatch(auth0Actions.authenticateUser(payload))
      })
      .catch(function (error) {
        console.log(error)
      });

  }else if(!isLoading && !isAuthenticated){
    history.push('/');
  }

  return (
    <Loading/>
  );
}

export default Auth0Callback;
