import {Box} from "@mui/system";
import speechBubbleIcon from "components/images/icons/speech_bubble.svg";
import heartPinIcon from "components/images/icons/heart_pin.svg";
import starIcon from "components/images/icons/stars.svg";
import React from "react";

export default function BackgroundIcons() {

  return(
    <Box sx={{position: 'absolute', top: '0', width: '100%', height: '70%'}}>
      <Box width={{xs: '100px', sm: '100px', md: '120px', lg: '120px'}}
           sx={{position: 'relative', left: '60%', zIndex: '15'}}>
        <img src={speechBubbleIcon} style={{width: '100%'}} alt={`speech bubble icon`}/>
      </Box>
      <Box top={{xs: '35%', sm: '35%', md: '10%', lg: '10%'}}
           width={{xs: '40px', sm: '40px', md: '70px', lg: '70px'}}
           sx={{position: 'relative', marginLeft: 'auto', right: '5%', zIndex: '15'}}>
        <img src={heartPinIcon} style={{width: '100%'}} alt={`heart pin icon`}/>
      </Box>
      <Box top={{xs: '70%', sm: '70%', md: '55%', lg: '55%'}}
           width={{xs: '100px', sm: '100px', md: '120px', lg: '120px'}}
           sx={{position: 'relative', zIndex: '15'}}>
        <img src={starIcon} style={{width: '100%'}} alt={`star icon`}/>
      </Box>
    </Box>
  )
}