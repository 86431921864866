import React, {Component, Fragment} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from '@material-ui/core/styles';


/**
 * @author Chris Okebata
 */

const styles = {
  label: {
    marginTop: '4px',
    fontSize: '14px'
  },
};

class CheckboxMat extends Component {

  render() {

    const {input, checkedStatus, styleStatus, handleOnChange, classes, label} = this.props;

    return(
      <Fragment>
        <FormControlLabel
          classes={{
            label: classes.label
          }}

          control={
            <Checkbox
              {...input}
              onChange={(event)=>{handleOnChange(event)}}
              style={ styleStatus ?
                {color: '#008751', fontSize: 2, margin: '5px 0 0 -2px'} :
                {color: '#aeaeae', fontSize: 2, margin: '5px 0 0 -2px'}}
              checked={checkedStatus}
            />
          }
          label={label}
        />
      </Fragment>
    )
  }
}

const styledCheckboxMat = withStyles(styles)(CheckboxMat);
export {styledCheckboxMat as CheckboxMat}

