import React from "react";
import ResponseContainer from "./ResponseContainer";
import ResponseContainerEmptyState from "../../../EmptyStates/ResponseContainerEmptyState";

export default function Response({ message, loadingMessage, isScrollComplete, editedResponseObj, editedSubjectObj }) {

    if(loadingMessage) return <ResponseContainerEmptyState />;
    return <ResponseContainer
        message={message}
        isScrollComplete={isScrollComplete}
        editedResponseObj={editedResponseObj}
        editedSubjectObj={editedSubjectObj}
    />;
}