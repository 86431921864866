import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";


const Transition = React.forwardRef(function Fade(props, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: isMobile ? {
      position: 'absolute',
      top: '200%'
    } : {
      opacity: 0
    },

    to: isMobile ? {
      width: '100%',
      height: '100%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      position: 'absolute',
      top: open ? '15%' : '200%'
    } : {
      left: '50%',
      transform: 'translate(-50%, 0%)',
      position: 'absolute',
      top: '20%',
      opacity: open ? 1 : 0
    },

    config: { duration: 100 },

    onStart: () => {
      if (open && onEnter) { onEnter(); }
    },
    onRest: () => {
      if (!open && onExited) { onExited(); }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default function BasicModal(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        sx={{overflowY: "scroll"}}
      >
        <Transition in={props.open}>
          <Box sx={ isMobile ? styles.containerMobile : styles.container }>
            <Box sx={{width: '100%', height: 'fit-content', display: 'flex', justifyContent: 'space-between'}}>
              { props.withoutTitle ? <Fragment/> : <Box sx={isMobile ? styles.titleMobile : styles.title}>{props.title}</Box> }
              <Box marginTop={{xs: '2px', sm: '2px', md: '-15px', lg: '-15px'}} sx={{marginLeft: 'auto'}}>
                {
                  !props.withoutCloseIcon &&
                  <Button onClick={props.handleClose} sx={{padding: '0', minWidth: "30px", minHeight: '30px'}}>
                    <CloseIcon sx={{fontSize: '24px', color: '#4F4F4F'}}/>
                  </Button>
                }
              </Box>
            </Box>

            <Box> {props.children} </Box>
          </Box>
        </Transition>
      </Modal>
    </div>
  );
}

Transition.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const styles = {
 container: {
   position: 'absolute',
   top: '0%',
   left: '50%',
   transform: 'translate(-50%, 0%)',
   width: 650,
   height: 'auto',
   bgcolor: '#fff',
   borderRadius: '10px',
   boxShadow: 24,
   padding: '32px',
 },
 containerMobile: {
   width: '100%',
   height: '100%',
   bgcolor: '#fff',
   borderRadius: '18px',
   boxShadow: 24,
   padding: '18px 20px',
 },
  title: {
    color: '#0C2728',
    fontSize: '24px',
    fontFamily: 'larsseitRegular',
    marginTop: '4px',
    marginBottom: '15px',
    paddingRight: '35px',
    lineHeight: '120%',
    letterSpacing: '-0.01em',
  },
  titleMobile: {
    color: '#0C2728',
    fontSize: '22px',
    fontFamily: 'larsseitRegular',
    marginTop: '4px',
    marginBottom: '15px',
    paddingRight: '35px',
    lineHeight: '120%',
    letterSpacing: '-0.01em',
  },
};

