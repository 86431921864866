import {Box} from "@mui/system";
import {Fragment} from "react";
import React from "react";


export default function WhatToExpectIndicator({label, icon}) {
  return(
    <Fragment>
      <Box style={styles.iconContainer}>
        <img src={icon} style={{height: '100%'}} alt={'icon'}/>
      </Box>
      <Box style={styles.label}>
        {label}
      </Box>
    </Fragment>
  )
}

const styles = {
  label: {
    width: 'fit-content',
    color: '#0C2728',
    fontFamily: 'LarsseitRegular',
    fontSize: '18px',
    marginTop: '18px',
    lineHeight: '120%',
    textAlign: 'center'
  },
  iconContainer: {
    width: '82px',
    height: '82px',
    backgroundColor: '#ffffff',
    zIndex: '10'
  }
};

