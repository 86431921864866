import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {companyResourcesActions} from "_actions";
import _ from "lodash";
import {ResourcesBuilder} from "../objects/ResourcesBuilder";
import ResourcesActions from "../ResourcesActions"

export default function (params) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(companyResourcesActions.getAll(params));
    }, []);

    const resource = useSelector(state => state.companyResources);
    const loading = resource.loadingResources;
    const resourceObj = resource.allResources;
    const resourceList = _.get(resourceObj, 'resources') || [];
    const totalCount = _.get(resourceObj, 'count') || 0;
    const rows = [];

    if(!loading && resourceList.length > 0) {

        resourceList.map( resource => {
            const builder = new ResourcesBuilder();
            builder.setName(_.get(resource, 'name') || '-');
            builder.setOrganizationName(_.get(resource, 'organization.name') || '-');
            builder.setLink(_.get(resource, 'link') || '-')
            builder.setStatus(_.get(resource, 'isActive') || false)
            builder.setActions(<ResourcesActions id={_.get(resource, 'id')}/>);

            rows.push(builder.resource);
        });
    }

    const columns = [
        {
            id: 'name',
            label: 'Resource name',
            width: 150
        },
        {
            id: 'organizationName',
            label: 'Organization',
            width: 150
        },
        {
            id: 'link',
            label: 'Link',
            width: 140
        },
        {
            id: 'isActive',
            label: 'Status',
            width: 100,
        },
        {
            id: 'actions',
            label: 'Actions',
            width: 20,
        },
    ];

    return { rows, columns, totalCount, loading };
}