export const utilityConstants = {
    GET_VERTICALS_SUCCESS: 'GET_VERTICALS_SUCCESS',
    GET_VERTICALS_REQUEST: 'GET_VERTICALS_REQUEST',
    GET_VERTICALS_FAILURE: 'GET_VERTICALS_FAILURE',

    GET_LANGUAGES_SUCCESS: 'GET_LANGUAGES_SUCCESS',
    GET_LANGUAGES_REQUEST: 'GET_LANGUAGES_REQUEST',
    GET_LANGUAGES_FAILURE: 'GET_LANGUAGES_FAILURE',

    SET_VERTICAL_SCROLL_COMPLETE: 'SET_VERTICAL_SCROLL_COMPLETE'
};

export const requestOrigin = {
  INBOX_INDEX: 'inboxIndex',
  INBOX_DETAILS: 'inboxDetails',

  MESSAGE_INDEX: 'messageIndex',
  MESSAGE_DETAILS: 'messageDetails',

  VOTE_INDEX: 'voteIndex',
  AGENT_HOME: 'agentHome',

  ADMIN_CAMPAIGNS_VIEW: 'adminCampaignsView'
};

export const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-76788111-1';
