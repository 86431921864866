import {Box} from "@mui/system";
import styles from "../organizationMainStyles";

export default function NoDataView() {
    return(
        <Box sx={styles.sectionWrapper}>
            <Box sx={{fontSize: '16px'}}>
                Please select an organization to view details
            </Box>
        </Box>
    )
}