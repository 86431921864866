import {useEffect, useMemo} from 'react';
import {proactiveActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {CompanyTemplateBuilder} from "../objects/CompanyTemplateBuilder";
import {buildGetCompanyTemplatePayload} from "../CampaignUtils";
import {checkProperties} from "_helpers";

export default function (params) {
    const dispatch = useDispatch();

    useEffect(() => {
        getCompanyTemplates(params);
    }, [params.organizationId, params.channel]);

    const getCompanyTemplates = (params) => {
        const requiredProperties = ['channel','organizationId'];
        if(checkProperties(params, requiredProperties)) {
            const payload = buildGetCompanyTemplatePayload(params.organizationId, params.channel)
            dispatch(proactiveActions.getCompanyTemplates(payload));
        }
        if(_.has(params, 'organizationId')) {
            if(params.channel) {
                const payload = buildGetCompanyTemplatePayload(params.organizationId, params.channel)
                dispatch(proactiveActions.getCompanyTemplates(payload));
            }
        }
        if(_.has(params, 'channel')) {
            if(params.organizationId) {
                const payload = buildGetCompanyTemplatePayload(params.organizationId, params.channel)
                dispatch(proactiveActions.getCompanyTemplates(payload));
            }
        }
    }

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingOrganizationTemplates;
    const templatesObj = proactive.organizationTemplates;
    const templateList = _.get(templatesObj, 'items') || [];

    const templates = useMemo(() => {
        const templateArr = [];
        if(!loading && templateList.length > 0) {
            templateList.map( template => {
                const builder = new CompanyTemplateBuilder();
                builder.setId(_.get(template, 'id') || '-');
                builder.setName(_.get(template, 'name') || '-');

                templateArr.push(builder.template);
            });
        }
        return templateArr
    }, [ templateList ]) || [];

    return {templates};
}