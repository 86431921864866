import React, {Fragment} from 'react'
import { GenericModal} from "components/common";

class OauthWebhookModal extends React.Component {
    render() {
        const { showWebhookUrl, webhookUrl, platformUrl, modalTitle, closeModal, copyToClipboard, modalStyles } = this.props
        return (
            <GenericModal
                headerText = {modalTitle}
                hideFooter
                show = {showWebhookUrl}
                onHide = {closeModal}
                size = "lg"
            >
                {showWebhookUrl &&
                    <Fragment>
                        <div className="col-md-10">
                            <textarea className="form-control"
                              rows={2} col={10}
                              defaultValue={webhookUrl} id="input-text"
                              ref={(textarea) => this.textArea = textarea}></textarea>
                            <div>
                                Add it <a href={platformUrl} rel="nofollow noreferrer noopener" target="_blank">here</a>
                            </div>
                        </div>
                        <div className="col-md-1">
                            {
                                document.queryCommandSupported('copy') &&
                                <div>
                                    <button className='btn btn-success btn-sm font-16 copy-text'
                                        onClick={(event) => copyToClipboard(event, this.textArea)}>
                                        <i className="fa fa-copy"></i>
                                    </button>
                                </div>
                            }
                        </div>
                    </Fragment>
                }
                <div className="row col-12" style={modalStyles.buttonContainer}>
                    {showWebhookUrl &&
                        <button className='btn btn-success btn-sm font-16 msg-source-submit-button'
                            style={modalStyles.centeredStyle}
                            onClick={closeModal}>
                            Close
                        </button>
                    }
                </div>
            </GenericModal>
        )
    }
}

export { OauthWebhookModal }