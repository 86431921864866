export const selectStyles = {
    outlinedInput: {
        width: '100%',
        fontSize: '16px !important',
        [`& fieldset`]: {
            border: '1px solid #0C2728', borderRadius: '10px',
        },
    },
    icon: {
        width: '30px !important',
        height: '30px !important',
        margin: '-3px 8px 0 0'
    },

    textField: {
        width: '100%',
        height: '45px',
        fontSize: '16px !important',
        color: '#0C2728',
        [`& fieldset`]: {
            border: '1px solid #0C2728',
            borderRadius: '8px',
        },
        [`& label`]: {
            marginTop: '-5px',
            fontSize: '16px',
        },

        '& label.Mui-focused': {
            color: '#0C2728',
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#0C2728',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },

    textFieldError: {
        width: '100%',
        fontSize: '16px !important',
        color: '#0C2728',
        [`& fieldset`]: {
            border: '1px solid #EB5757',
            borderRadius: '10px',
        },
        [`& label`]: {
            fontSize: '16px',
        },
    },
}