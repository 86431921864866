import React, { Component, Fragment } from 'react'
import { GenericModal } from 'components/common';
import {connect} from 'react-redux'
import {frontAppOauthActions, alertActions, utilityActions} from "_actions";

const addModalStyles = {
    buttonContainer: {
        marginTop: '15px'
    },
    checkBox: {
        position: 'absolute',
        left: 0,
        top: 9,
    },
    label: {
        paddingLeft: 10
    },
    centeredStyle: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}

class AddInboxModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inboxList: [],
            messageDataSourceId: '',
        }
    }

    componentDidMount() {
        const { showInboxes, inboxList, messageDataSourceId } = this.props
        if (showInboxes) {
            this.setState({
                inboxList: inboxList,
                messageDataSourceId: messageDataSourceId,
            })
        }
    }

    componentDidUpdate() {
        const { showInboxes, inboxList, messageDataSourceId } = this.props
        const { inboxList: stateInboxes } = this.state
        if (showInboxes && inboxList && inboxList.length !== stateInboxes.length) {
            this.setState({
                inboxList: inboxList,
                messageDataSourceId: messageDataSourceId,
            })
        }
    }

    handleItemSelection = (itemIndex, event) => {
        const { target: { checked }} = event
        this.setState(prevState => {
            return {
                inboxList: prevState.inboxList.map((item, index) => {
                    if (itemIndex === index) {
                        item.checked = checked
                    }
                    return item
                })
            }
        })
    }

    handleSaveInboxes = (event) => {
        event.preventDefault()
        const { messageDataSourceId, inboxList } = this.state
        const { dispatch } = this.props
        const formData = {
            messageDataSourceId,
            inboxes: inboxList.filter(item => item.checked),
        }
        dispatch(frontAppOauthActions.createInboxes(formData))
    }

    closeInboxModal = () => {
        const { dispatch } = this.props
        dispatch(frontAppOauthActions.closeInboxModal())
    }

    copyToClipBoard = (event, textArea) => {
        const { dispatch } = this.props;
        dispatch(utilityActions.copyToClipboard(event, textArea));
        dispatch(alertActions.success('Copied!'));
    };

    render() {
        const { inboxList } = this.state
        const { showWebhookUrl, showInboxes, webhookUrl, platformUrl } = this.props
        const modalTitle = showWebhookUrl ? 'Copy Webhook URL' : 'Add Inboxes'
        return (
            <GenericModal
                headerText={modalTitle}
                hideFooter
                show={showInboxes}
                onHide={this.closeInboxModal}
                size="lg"
            >
                {! showWebhookUrl &&
                    inboxList && inboxList.map((inbox, index) =>
                        <div className="tms-check-box font-14" key={index}>
                            <label style={addModalStyles.label}>
                                <input type="checkbox" autoComplete="off"
                                       id={inbox.id} name="selected"
                                       checked={!!inbox.checked}
                                       onChange={(e) => this.handleItemSelection(index, e)}
                                />
                                <span className="tms-check-mark" style={addModalStyles.checkBox}>{""}</span>{inbox.name}
                            </label>
                        </div>
                    )
                }
                {showWebhookUrl &&
                    <Fragment>
                        <div className="col-md-10" style={{padding: "0"}}>
                            <textarea className="form-control"
                                      rows={2} col={10}
                                      defaultValue={webhookUrl} id="input-text"
                                      ref={(textarea) => this.textArea = textarea}></textarea>
                            <div>
                                Copy your Webhook URL and paste in Front
                                {platformUrl &&
                                    <span>
                                        View Guide <a href={platformUrl} rel="nofollow noreferrer noopener" target="_blank">here</a>
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="col-md-1">
                            {
                                document.queryCommandSupported('copy') &&
                                <div>
                                    <button className='btn btn-success btn-sm font-16 copy-text'
                                            onClick={(event) => this.copyToClipBoard(event, this.textArea)}>
                                        <i className="fa fa-copy"></i>
                                    </button>
                                </div>
                            }
                        </div>
                    </Fragment>
                }
                <div className="row col-xs-12" style={addModalStyles.buttonContainer}>
                    {! showWebhookUrl &&
                        <button className="btn btn-info" onClick={this.handleSaveInboxes}>Save Inboxes</button>
                    }
                    {showWebhookUrl &&
                        <button className="btn btn-info" style={addModalStyles.centeredStyle}
                                onClick={this.closeInboxModal}>Close</button>
                    }
                </div>
            </GenericModal>
        )
    }
}

function mapStateToProps(state) {
    const { frontAppOauth : { showInboxes, inboxList, messageDataSourceId, webhookUrl, showWebhookUrl, platformUrl }} = state
    return {
        showInboxes, inboxList, messageDataSourceId, webhookUrl, showWebhookUrl, platformUrl
    }
}

const connectedAddInbox = connect(mapStateToProps)(AddInboxModal)
export { connectedAddInbox as AddInboxModal }