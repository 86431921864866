import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";

export class Loading extends React.Component {
    render() {

        const { height, size, color } = this.props;
        const { progressContainer } = styles;
        let containerHeight = typeof height === 'undefined' ? '100vh' : `${height}px`;
        const bgColor = color || '#008751';

        return(
            <div style={{...progressContainer, height: `${containerHeight}`}}>
                <CircularProgress style={{color: bgColor}} size={size}/>
            </div>
        )
    }
}

const styles = {
    progressContainer: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    }
};

Loading.propTypes = {
    height: PropTypes.number,
};