import React from 'react';
import { Button, Modal } from 'react-bootstrap'

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/10/2018 : 11:52 AM
 */

const style = {
    bodyTextHolder: {display: "flex", alignItem: "center"}
};

const ConfirmationModal = (props) => {
    const {payload, onSave, onHide, show, headerText, bodyText, buttonText} = props;
    return (
        <Modal show={show} onHide={onHide} className="tms-modal-dialog">
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={style.bodyTextHolder}>
                    <div style={{lineHeight: "20px"}}>
                        <span>{bodyText}</span>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} className="btn btn-default">Cancel</Button>
                <Button bsStyle="success" onClick={() => onSave(payload)}>{buttonText}</Button>
            </Modal.Footer>
        </Modal>);
};

export default ConfirmationModal;
