import React, {Fragment} from "react";
import CustomerIoSetupModalForm from "./CustomerIoSetupModalForm";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";

export default function CustomerIoSetupModal({
	open,
	handleClose,
	handleOpen
}){
  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with customer.io'}>

        <CustomerIoSetupModalForm
          description={'Please copy and paste your customer.io credentials here:'}
          buttonLabel={'Submit'}
          handleClose={handleClose} />
      </BasicModal>
    </Fragment>
  )
}