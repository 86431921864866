export const styles = {
    tmsNotifierMsg: {
        padding: "0px 30px",
        color: "#fff",
        fontSize: "15px",
        fontFamily: "Roboto",
        fontWeight: "400",
        lineHeight: "41px",
        background: "#63636d",
        display: "inline-block",
        border: "1px solid #63636d",
        borderRadius: "4px",
        boxShadow: "0 1px 2px 1px rgba(0, 0, 0, 0.1)"
    },

    tmsNotifier: {
        width: "50%",
        textAlign: "center",
        position: "fixed",
        padding: "0",
        top: "10px",
        margin: "0 auto",
        left: "0",
        right: "0",
        zIndex: "1500"
    }
};