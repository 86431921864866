import { userConstants, alertMessages } from '_constants';
import { userService} from '_services';
import {alertActions, authActions} from '_actions';
import { history } from "_helpers";
import { SnackBar } from "../components/common";
import {Auth} from "../components/Authorization";
import _ from "lodash";

function getAll(params) {
    return dispatch => {
        dispatch(request());

        userService.getAll(params)
            .then(
                users => {
                    dispatch(success(users))
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_ALL_REQUEST }
    }

    function success(users) {
        return { type: userConstants.GET_ALL_SUCCESS, users }
    }

    function failure(error) {
        return { type: userConstants.GET_ALL_FAILURE, error }
    }
}

/**
 * Dispatches actions to fetch the experts paid messages with dates
 * @param orgId
 * @returns {function(*)}
 */
function getExpertRecentActivity(orgId) {
    return dispatch => {
        dispatch(request());

        userService.getExpertRecentActivity(orgId)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_EXPERT_RECENT_ACTIVITY_DATA_REQUEST }
    }

    function success(users) {
        return { type: userConstants.GET_EXPERT_RECENT_ACTIVITY_DATA_SUCCESS, users }
    }

    function failure (error) {
        return { type: userConstants.GET_EXPERT_RECENT_ACTIVITY_DATA_FAILURE, error}
    }
}

/**
 * Dispatches actions to fetch the admin performance
 * @returns {function(*)}
 * @param data
 */
function getAdminPerformance(data) {
    return dispatch => {
        dispatch(request());

        userService.getAdminPerformance(data)
            .then(
                admins => dispatch(success(admins)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_ADMIN_PERFORMANCE_REQUEST }
    }

    function success(admins) {
        return { type: userConstants.GET_ADMIN_PERFORMANCE_SUCCESS, admins }
    }

    function failure (error) {
        return { type: userConstants.GET_ADMIN_PERFORMANCE_FAILURE, error}
    }
}

/**
 * Dispatches actions to fetch active users in the past 3 days by default
 * @param orgId
 * @returns {function(*)}
 */
function getActiveExperts(data) {
    return dispatch => {
        dispatch(request());

        userService.getActiveExperts(data)
            .then(
                experts => dispatch(success(experts)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_ACTIVE_EXPERT_REQUEST }
    }

    function success(experts) {
        return { type: userConstants.GET_ACTIVE_EXPERT_SUCCESS, experts }
    }

    function failure (error) {
        return { type: userConstants.GET_ACTIVE_EXPERT_FAILURE, error}
    }
}

/**
 * Dispatches actions to fetch inactive users in the past 3 days by default
 * @param orgId
 * @returns {function(*)}
 */
function getInActiveExperts(data) {
    return dispatch => {
        dispatch(request());

        userService.getInActiveExperts(data)
            .then(
                experts => dispatch(success(experts)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_INACTIVE_EXPERT_REQUEST }
    }

    function success(experts) {
        return { type: userConstants.GET_INACTIVE_EXPERT_SUCCESS, experts }
    }

    function failure (error) {
        return { type: userConstants.GET_INACTIVE_EXPERT_FAILURE, error}
    }
}

/**
 * Dispatches actions to get all the practicing experts sorted by their scores
 * @param orgId
 * @returns {function(*)}
 */
function getPracticingExperts(orgId) {
    return dispatch => {
        dispatch(request());

        userService.getPracticingExperts(orgId)
            .then(
                experts => dispatch(success(experts)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_PRACTICING_EXPERT_REQUEST }
    }

    function success(experts) {
        return { type: userConstants.GET_PRACTICING_EXPERT_SUCCESS, experts }
    }

    function failure (error) {
        return { type: userConstants.GET_PRACTICING_EXPERT_FAILURE, error}
    }
}

function getQualityScores(data) {
    return dispatch => {
        dispatch(request());

        userService.getQualityScores(data)
            .then(
                experts => dispatch(success(experts)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.QUALITY_SCORES_REQUEST }
    }

    function success(qualityScores) {
        return { type: userConstants.QUALITY_SCORES_SUCCESS, qualityScores: qualityScores }
    }

    function failure (error) {
        return { type: userConstants.QUALITY_SCORES_FAILURE, error}
    }
}

function getQualityReviews(data) {
    return dispatch => {
        dispatch(request());

        userService.getQualityReviews(data)
            .then(
                experts => dispatch(success(experts)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.QUALITY_REVIEWS_REQUEST }
    }

    function success(qualityReviews) {
        return { type: userConstants.QUALITY_REVIEWS_SUCCESS, qualityReviews: qualityReviews }
    }

    function failure (error) {
        return { type: userConstants.QUALITY_REVIEWS_FAILURE, error}
    }
}

/**
 * Dispatches actions involves in a user
 * submission, processing and potential failure.
 *
 * @param user
 * @returns {function(*)}
 */
function updateUser(user) {
    return dispatch => {
        dispatch(request(user));

        userService.updateUser(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.SAVE_USER_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(user) {
        return { type: userConstants.SAVE_USER_REQUEST, user }
    }

    function success(user) {
        return { type: userConstants.SAVE_USER_SUCCESS, user }
    }

    function failure(error) {
        return { type: userConstants.SAVE_USER_FAILURE, error }
    }
}

function updateUserByAdmin(user, password) {
    const userId = user.id

    return dispatch => {
        dispatch(request(user));

        userService.updateUser(user)
            .then(
                user => {
                    userService.adminUpdateUserPassword(userId, password)
                        .then(user => {
                                dispatch(success(user));
                                dispatch(alertActions.clear());
                                SnackBar.showMessage(alertMessages.SAVE_USER_BY_ADMIN_SUCCESS);
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.clear());
                                SnackBar.showMessage(alertMessages.SAVE_USER_BY_ADMIN_PASSWORD_FAILURE)
                            }
                        )
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.SAVE_USER_BY_ADMIN_FAILURE)
                }
            );
    };

    function request(user) {
        return {type: userConstants.SAVE_USER_BY_ADMIN_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.SAVE_USER_BY_ADMIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.SAVE_USER_BY_ADMIN_FAILURE, error}
    }
}

function searchUser(user) {
    return dispatch => {
        dispatch(request())

        userService.searchUser(user)
            .then(
                users => {
                    dispatch(success(users));
                    SnackBar.showMessage(alertMessages.SEARCH_USER_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    }
    function request() {
        return { type: userConstants.SEARCH_USER_REQUEST }
    }

    function success(users) {
        return { type: userConstants.SEARCH_USER_SUCCESS, users }
    }

    function failure(error) {
        return { type: userConstants.SEARCH_USER_FAILURE, error }
    }
}

/**
 * Dispatches actions involves in a user
 * submission, processing and potential failure.
 *
 * @param user
 * @returns {function(*)}
 */
function createUser(data) {
    return dispatch => {
        dispatch(request(data));

        userService.createUser(data)
            .then(
                user => {
                    dispatch(success(user));
                    if (! data.userToOrganization) {
                        history.push(`/users`);
                    }
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.SAVE_USER_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.SAVE_USER_FAILURE);
                }
            );
    };

    function request(user) {
        return { type: userConstants.SAVE_USER_REQUEST, user }
    }

    function success(user) {
        return { type: userConstants.SAVE_USER_SUCCESS, user }
    }

    function failure(error) {
        return { type: userConstants.SAVE_USER_FAILURE, error }
    }
}

/**
 * Dispatches the 'getUser' action.
 * @param id
 * @returns {function(*)}
 */
function getUser(id) {
    return dispatch => {
        dispatch(request(id));

        userService.getUser(id)
            .then(
                user => dispatch(success(user)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(id) {
        return { type: userConstants.GET_DETAILS_REQUEST, payload: id }
    }

    function success(user) {
        return { type: userConstants.GET_DETAILS_SUCCESS, payload: user }
    }

    function failure(error) {
        return { type: userConstants.GET_DETAILS_FAILURE, payload: error }
    }
}

/**
 * Dispatches the 'getAgentMessages' action.
 * @param agentId
 * @param params
 * @returns {function(*)}
 */
function getAgentMessages(agentId, params, isAgentHistoryPage = false) {
    const page = (params.offset/10) + 1;
    return (dispatch, getState) => {

        if (isAgentHistoryPage) {
            const visitedPages = getState().users.visitedPages;

            if(visitedPages[`page${page}`]){
                dispatch(success(visitedPages[`page${page}`], page))
                return
            }
        }

        dispatch(request());

        userService.getAgentMessages(agentId, params)
            .then(
                messages => dispatch(success(messages, page)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_AGENT_MESSAGES_REQUEST }
    }

    function success(messages) {
        return { type: userConstants.GET_AGENT_MESSAGES_SUCCESS, payload: { messages, page} }
    }

    function failure(error) {
        return { type: userConstants.GET_AGENT_MESSAGES_FAILURE, payload: error }
    }
}

/**
 * Get an agent message history
 * @param agentId
 * @param params
 * @param isAgentHistoryPage
 * @returns {Function}
 */
function getAgentMessageHistory(agentId, params, isAgentHistoryPage = false) {
    const page = (params.offset/10) + 1;
    return (dispatch, getState) => {

        if (isAgentHistoryPage) {
            const visitedPages = getState().users.visitedPages;

            if(visitedPages[`page${page}`]){
                dispatch(success(visitedPages[`page${page}`], page));
                return
            }
        }

        dispatch(request());

        userService.getAgentMessageHistory(agentId, params)
            .then(
                messages => dispatch(success(messages, page)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_AGENT_MESSAGES_REQUEST }
    }

    function success(messages) {
        return { type: userConstants.GET_AGENT_MESSAGES_SUCCESS, payload: { messages, page} }
    }

    function failure(error) {
        return { type: userConstants.GET_AGENT_MESSAGES_FAILURE, payload: error }
    }
}

/**
 * Dispatches the 'clearVisitedPages' action.
 * It clears the visited pages if the query parameter is different from  the previous one
 * @returns {function(*)}
 */
function clearVisitedPages() {
    return { type: userConstants.DELETE_VISITED_PAGES };
}

/**
 * Dispatches the 'getCurrentUser' action.
 * Gets the details of the currently logged in user.
 * @param id
 * @returns {function(*)}
 */
function getCurrentUser(id) {
    return dispatch => {
        dispatch(request(id));

        userService.getCurrentUser(id)
            .then(
                user => {
                    dispatch(authActions.saveUpdatedUser(user))
                    dispatch(success(user))
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request(id) {
        return { type: userConstants.GET_CURRENT_USER_REQUEST, payload: id }
    }

    function success(user) {
        return { type: userConstants.GET_CURRENT_USER_SUCCESS, payload: user }
    }

    function failure(error) {
        return { type: userConstants.GET_CURRENT_USER_FAILURE, payload: error }
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) {
        return { type: userConstants.DELETE_REQUEST, id }
    }

    function success(id) {
        return { type: userConstants.DELETE_SUCCESS, id }
    }

    function failure(id, error) {
        return { type: userConstants.DELETE_FAILURE, id, error }
    }
}

/**
 * Dispatches the 'updateUserPhoto' action.
 * Gets the details of the currently logged in user.
 * @returns {function(*)}
 * @param userId
 * @param type
 * @param formData
 */
function updateUserPhoto(userId, type, formData) {
    return dispatch => {
        dispatch(request());

        userService.updateUserPhoto(userId, type, formData)
            .then(
                user => {
                    userService.updateCurrentStoredUser(type, user);
                    dispatch(success(user));
                    if (type === 'users') {
                        dispatch(authActions.updateAuthUserPhoto(user));
                    } else {
                        dispatch(authActions.updateAuthOrgPhoto(user));
                    }
                    SnackBar.showMessage(alertMessages.UPDATE_USER_PHOTO_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.UPDATE_USER_PHOTO_FAILURE);
                }
            );
    };

    function request() {
        return { type: userConstants.UPDATE_USER_PHOTO_REQUEST }
    }

    function success(user) {
        return { type: userConstants.UPDATE_USER_PHOTO_SUCCESS, user }
    }

    function failure(error) {
        return { type: userConstants.UPDATE_USER_PHOTO_FAILURE, error }
    }
}

/**
 * Dispatches the 'updatePassword' action.
 * @returns {function(*)}
 * @param userId
 * @param type
 * @param formData
 */
function updatePassword(userId, formData, history) {
    return dispatch => {
        userService.updatePassword(userId, formData)
            .then(
                user => {
                    history.push('/profile');
                    SnackBar.showMessage(alertMessages.UPDATE_PASSWORD_SUCCESS);
                },
                error => {
                    SnackBar.showMessage(alertMessages.UPDATE_PASSWORD_FAILURE);
                }
            );
    };
}

/**
 * Dispatches the 'deleteUserPhoto' action.
 * @returns {function(*)}
 * @param userId
 */
function deleteUserPhoto(userId) {
    return dispatch => {
        dispatch(request());

        userService.deleteUserPhoto(userId)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(authActions.updateAuthUserPhoto(user));
                    SnackBar.showMessage(alertMessages.DELETE_USER_PHOTO_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.DELETE_USER_PHOTO_FAILURE);
                }
            );
    };

    function request() {
        return { type: userConstants.UPDATE_USER_PHOTO_REQUEST }
    }

    function success(user) {
        return { type: userConstants.UPDATE_USER_PHOTO_SUCCESS, user }
    }

    function failure(error) {
        return { type: userConstants.UPDATE_USER_PHOTO_FAILURE, error }
    }
}

/**
 * Dispatches the 'deleteUserPhoto' action.
 * Gets the details of the currently logged in user.
 * @returns {function(*)}
 * @param userId
 * @param type
 * @param formData
 */
function confirmUserAccount(queryString) {
    return dispatch => {
        dispatch(request());

        userService.confirmUserAccount(queryString)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: userConstants.USER_CONFIRM_ACCOUNT_REQUEST }
    }

    function success(response) {
        return { type: userConstants.USER_CONFIRM_ACCOUNT_SUCCESS, response}
    }

    function failure(error) {
        return { type: userConstants.USER_CONFIRM_ACCOUNT_FAILURE, error }
    }
}

const getChatdeskUser = () => {
    function request() {
        return { type: userConstants.GET_CHATDESK_USER_REQUEST };
    }

    function success(response) {
        return { type: userConstants.GET_CHATDESK_USER_SUCCESS, payload: response };
    }

    function failure(error) {
        return { type: userConstants.GET_CHATDESK_USER_FAILURE, payload: error };
    }
    return (dispatch) => {
        dispatch(request());
        userService.getChatdeskUser()
          .then(
            response => dispatch(success(response.data))
          ).catch((error) => {
          if(_.get(error, 'response.status') === 401){
            Auth.processLogout();
            return;
          }

          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    };
};
function getMessagesForAgent(agentId, params, isAgentHistoryPage = false) {
    const page = (params.offset/10) + 1;
    return (dispatch, getState) => {

        if (isAgentHistoryPage) {
            const visitedPages = getState().users.visitedPages;
            if(visitedPages[`page${page}`]){
                dispatch(success(visitedPages[`page${page}`], page))
                return
            }
        }

        dispatch(request());
        userService.getMessagesForAgent(agentId, params)
            .then(
                messages => dispatch(success(messages, page)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_MESSAGES_FOR_AGENT_REQUEST }
    }
    function success(messages) {
        return { type: userConstants.GET_MESSAGES_FOR_AGENT_SUCCESS, payload: { messages, page} }
    }
    function failure(error) {
        return { type: userConstants.GET_MESSAGES_FOR_AGENT_FAILURE, payload: error }
    }
}

const markInboxMsgAsDone = (messageId, userId) => {
  const request = () => ({
    type: userConstants.MARK_COMPANY_INBOX_MSG_AS_DONE_REQUEST
  });

  const success = (response) => ({
    type: userConstants.MARK_COMPANY_INBOX_MSG_AS_DONE_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: userConstants.MARK_COMPANY_INBOX_MSG_AS_DONE_FAILURE, error,
    payload: {
      error
    }
  });

  return dispatch => {
    dispatch(request());

    userService.markInboxMsgAsDone(messageId, userId)
      .then(response => {
        dispatch(success(response.data));
        SnackBar.showMessage(alertMessages.MARK_AS_DONE_SUCCESS)

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};

function getLessActiveAgents(data) {
    return dispatch => {
        dispatch(request());

        userService.getLessActiveAgents(data)
            .then(
                experts => dispatch(success(experts)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return { type: userConstants.GET_LESS_ACTIVE_AGENT_REQUEST }
    }

    function success(experts) {
        return { type: userConstants.GET_LESS_ACTIVE_AGENT_SUCCESS, experts }
    }

    function failure (error) {
        return { type: userConstants.GET_LESS_ACTIVE_AGENT_FAILURE, error}
    }
}

export const userActions = {
    createUser,
    updateUser,
    updateUserByAdmin,
    updatePassword,
    getAll,
    getUser,
    getAgentMessages,
    getAgentMessageHistory,
    getCurrentUser,
    delete: _delete,
    updateUserPhoto,
    deleteUserPhoto,
    confirmUserAccount,
    getExpertRecentActivity,
    getActiveExperts,
    getInActiveExperts,
    getPracticingExperts,
    getQualityScores,
    getQualityReviews,
    clearVisitedPages,
    searchUser,
    getChatdeskUser,
    getMessagesForAgent,
    getAdminPerformance,
    markInboxMsgAsDone,
    getLessActiveAgents,
};
