import {TextField} from "@mui/material";
import React from "react";
import {makeStyles} from "@mui/styles";


export default function BasicTextField({input, label, id, name, handleChange, meta: { touched, error }}) {
  const classes = useStyles();
  const hasError = touched && !_.isEmpty(error);

  return(
    <div>
      <TextField className={ hasError ? classes.textFieldError : classes.textField }
                 InputProps={{ classes: { root: classes.textField } }}
                 id={id}
                 name={name}
                 label={label}
                 variant="outlined"
                 onChange={handleChange}
                 {...input}
                 error={ hasError } />
    </div>

  )
}

const useStyles = makeStyles({
  textField: {
    width: '100%',
    fontSize: '16px !important',
    backgroundColor: 'unset !important',
    color: '#0C2728',
    [`& fieldset`]: {
      border: '1px solid #0C2728',
      borderRadius: '10px',
    },
    [`& label`]: {
      fontSize: '16px',
    },

    '& label.Mui-focused': {
      color: '#0C2728',
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0C2728',
      },
      '&:hover fieldset': {
        borderColor: '#0C2728',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#368C86',
      },
    },
  },

  textFieldError: {
    width: '100%',
    fontSize: '16px !important',
    backgroundColor: 'unset !important',
    color: '#0C2728',
    [`& fieldset`]: {
      border: '1px solid #EB5757',
      borderRadius: '10px',
    },
    [`& label`]: {
      fontSize: '16px',
    },
  },
});