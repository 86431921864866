import {Box} from "@mui/system";
import styles from "../organizationMainStyles";
import {getAccessibilityMode, getLabel} from "../Utilities/organizationSettingsUtils";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import MessageResponse from "../Utilities/MessageResponses/MessageResponse";
import useGetAutoActionsInfo from "../hooks/useGetAutoActionsInfo";
import _ from "lodash";
import NoDataView from "../EmptyStates/NoDataView";
import {fragmentId} from "../Utilities/organizationSettingsConstants";

const loadingInProgress = (
    <Box sx={styles.bodyLoading}>
        <CircularProgress sx={styles.bodyLoadingSpinner} />
    </Box>
);


export default function MessageResponses({organizationId}) {
    const label = getLabel(fragmentId.RESPONSES);
    const {autoActionsInfo, loading} = useGetAutoActionsInfo(organizationId);
    const mode = getAccessibilityMode();

    if(_.isEmpty(organizationId) && _.isEmpty(autoActionsInfo)) {
        return <NoDataView />
    }

    return(
        <Box sx={styles.sectionWrapper}>
            { loading && !autoActionsInfo
                ? loadingInProgress
                : autoActionsInfo.items && autoActionsInfo.items.map((autoAction) =>
                <MessageResponse
                    key={autoAction.dataSourceHandle.id}
                    autoAction={autoAction}
                    organizationId={organizationId}
                    mode={mode}
                    label={label}
                />
            )
            }
        </Box>
    )
}