import {Fragment} from "react";
import {getDuration} from "_helpers";
import moment from "moment";

/**
 * @param date {string}
 * @returns {JSX.Element}
 * @constructor
 */
export default function MessageDate({date}) {
    const duration = getDuration(date);
    const formattedDate = moment(date).format('MMMM D, YYYY h:mm a');

    return(
        <Fragment>
            {`${duration} (${formattedDate})`}
        </Fragment>
    )
}