import React from 'react';
import { Col, Grid, Row, Table } from 'react-bootstrap';
import { userActions } from "_actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment/moment'
import { Loading } from "components/common";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pluck } from 'underscore'
import UserSearchForm from "./UserSearchForm";

class UserIndex extends React.Component {

    componentDidMount() {
        this.props.dispatch(userActions.getAll());
    }

    searchUser = (props) => {
        this.props.dispatch(userActions.searchUser(props))
    }

    render() {

        const { users } = this.props;

        /**
         * Renders a single row of user information on the index table.
         * @param user
         * @returns {*}
         */
        function renderUserRow(user) {

            const { roles } = user._embedded;
            const { organization } = user._embedded;

            return (
                <tr key={user.id}>
                    <td>
                        <Link to={`users/${user.id}/show`}>{user.username}</Link>
                    </td>
                    <td>{pluck(roles, 'authority').join(',')}</td>
                    <td>{organization.name}</td>
                    <td>{moment(user.dateCreated).format('MMMM D, YYYY')}</td>
                    <td>{user.enabled.toString()}</td>
                    <td>{user.accountLocked.toString()}</td>
                </tr>
            );
        }

        if (Object.keys(users.users).length === 0) {
            return <Loading/>
        } else {

            const { users, params, count } = this.props.users.users;
            const totalPages = Math.ceil(count / params.max);

            /**
             * Handles click on a pagination button/link.
             * @param page
             */
            const handleSelect = (page) => {
                params.offset = params.max * ( page.selected );
                this.props.dispatch(userActions.getAll(params))
            };



            return (

                <Grid className='top-rel-100'
                      style={{ backgroundColor: 'white', border: 'solid 1px lightGrey', borderRadius: '1%' }}>
                    <br/>
                    <br/>
                    <Row >
                        <Col md={12}>
                            <Link className="btn btn-success pull-left" to="/users/add">
                                <FontAwesomeIcon icon='user-plus'/> Add User
                            </Link>
                            <UserSearchForm onFormSubmit={this.searchUser} />
                        </Col>
                    </Row>
                    <br/>

                    <Row>
                        <Col md={12}>
                            <Table striped bordered hover responsive>
                                <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Roles</th>
                                    <th>Organization</th>
                                    <th>Date</th>
                                    <th>Enabled</th>
                                    <th>Account Locked</th>
                                </tr>
                                </thead>
                                <tbody>

                                {users.map(renderUserRow)}

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col >
                            <ReactPaginate previousLabel={"previous"}
                                           nextLabel={"next"}
                                           breakLabel={<span>...</span>}
                                           breakClassName={"break-me"}
                                           pageCount={totalPages}
                                           marginPagesDisplayed={2}
                                           disableInitialCallback={true}
                                           pageRangeDisplayed={5}
                                           onPageChange={(page) => handleSelect(page)}
                                           containerClassName={"pagination"}
                                           subContainerClassName={"pages pagination"}
                                           activeClassName={"active"}
                            />
                        </Col>
                    </Row>

                </Grid>
            );
        }
    }
}

function mapStateToProps(state) {
    const { users } = state;
    return {
        users: users
    };
}



const connectedUserIndex = connect(mapStateToProps)(UserIndex);
export { connectedUserIndex as UserIndex };
