import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {walletActions} from "_actions";
import _ from "lodash";

export default function () {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            getAgentWallet();
            return () => effectRan.current = true;
        }
    }, []);

    const getAgentWallet = () => {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const agentId = _.get(currentUser, "id");
        dispatch(walletActions.getAgentWalletBalance(agentId));
    }

    const wallet = useSelector((state) => state.wallet);
    const loadingWallet = wallet.walletBalanceLoading;
    const walletObj = wallet.walletBalance;

    return { walletObj, loadingWallet };
}