import React, {Fragment} from "react";
import UserDisplayInitials from "../../common/UserDisplayInitials";
import htmlParser from "he";
import {personalizeResponse} from "_helpers";
import moment from "moment";
import {CircularProgress} from "@mui/material";
import ProactiveMetricsMessageIframe from "./Utilities/ProactiveMetricsMessageIframe";

const ProactiveMetricsConversation = ({message, loadingConversation, index}) => {
    const messageAuthor = message?.authorName;
    const zone_name =  moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();

    const conversationItemUserDisplayInitials = (name) => {
        return (<UserDisplayInitials
            userName={name}
            size={'default'}
            variant={'rounded'}
            background={'#E6F3F2'}
            color={'#0C2728'}
        />)
    }
    const initials = conversationItemUserDisplayInitials(messageAuthor)
    const dateCreated =  message?.dateCreatedFromSource || message?.dateCreated;
    const text = htmlParser.decode(personalizeResponse(message.text, messageAuthor, "", null));

    return(
        <Fragment>
            {loadingConversation &&
            <div style={{ background: 'transparent', top: 0, left: 0, width: '100%', height: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <CircularProgress style={{color: '#143F42'}}/>
                </div>
            </div>
            }

            {!loadingConversation &&
            <div className="md-conversation-container" style={{width: '100%', color: '#0C2728'}}>
                <div className="md-msg-container">
                    <div className="row conversation-item-container">
                        <div className="col-2 metricsDetail-dp">
                            <div className="metricsDetail-avatar">{initials}</div>
                        </div>
                        <div className="col-8 conversation-item-row">
                            <div style={{display: 'flex', width: '100%', paddingBottom: '5px'}}>
                                <div style={{width: '30%', textAlign: 'left'}}
                                     className="metricsDetail-fullName">{messageAuthor}</div>
                                <div style={{width: '70%', textAlign: 'right'}} className="metricsDetail-dateTime"><span
                                    className="metrics-detail-date">
                                    {moment.utc(dateCreated).fromNow()}
                                    {` (${moment(dateCreated).format('MMMM Do YYYY HH:mm')} ${timezone})`}
                                        </span></div>
                            </div>
                            <div className="metricsDetail-full-msg"
                                 style={message.direction.toLowerCase() === "outgoing" ? {
                                     background: '#F3F5F6',
                                     border: '1px solid #DCE2E3'
                                 } : {}}>
                                <ProactiveMetricsMessageIframe text={text} index={index} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </Fragment>
    )
}

export default ProactiveMetricsConversation;

