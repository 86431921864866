import {Fragment} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import styles from "./conversationStyles";
import EmailInfoItem from "./EmailInfoItem";
import {getMessageDirection, shouldShowCopyIcon} from "../../../utilities/ConversationUtils";
import {stripNumbersFromUsername} from "_helpers";
import {supportedSourcesToExcludeAuthorId} from "_constants";

export default function AuthorInfo({ conversationItem, message }) {
    let {authorName, authorEmail, isTrendsSource} = conversationItem;
    const sourceName = _.toString(_.get(message, 'sourceName')).toLowerCase();
    const isEmail = !isTrendsSource;
    const direction = getMessageDirection(conversationItem, message);
    const withCopyAction = shouldShowCopyIcon(direction);
    const tooltipLabel = 'Copy name';
    const toastLabel = 'Copied name to clipboard';
    const excludeAuthorId = !supportedSourcesToExcludeAuthorId.includes(sourceName);
    authorName = stripNumbersFromUsername(authorName);

    return(
        <Fragment>
            {
                isEmail ?
                    <Box sx={{display: 'flex',}}>
                        {
                            excludeAuthorId &&
                            <Box sx={{marginRight: '5px'}}>
                                <EmailInfoItem
                                    label={
                                        <Fragment>From:<Box sx={styles.fromName} component={'span'}> {authorName} </Box></Fragment>
                                    }
                                    copyText={authorName}
                                    withCopyAction={withCopyAction}
                                    tooltipLabel={tooltipLabel}
                                    toastLabel={toastLabel}
                                />
                            </Box>
                        }
                        {
                            authorEmail &&
                            <EmailInfoItem
                                label={`${excludeAuthorId ? "" : "From:"} <${authorEmail}>`}
                                copyText={authorEmail}
                                withCopyAction={withCopyAction}
                            />
                        }
                    </Box>
                    :
                    <EmailInfoItem
                        label={authorName}
                        copyText={authorName}
                        withCopyAction={withCopyAction}
                        tooltipLabel={tooltipLabel}
                        toastLabel={toastLabel}
                    />
            }
        </Fragment>
    )
}