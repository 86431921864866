import { oauthConstants, msgDataSourceConstants } from "_constants";

const INITIAL_STATE = {
    oauthParams: {},
    oauthParamsRequest: false,
    error: '',
    loading: true,
    channel:'trustpilot',
    messageDataSourceId: '',
    webhookUrl: '',
    showWebhookUrl: false,
    platformUrl: 'https://businessapp.b2b.trustpilot.com/webhooks/',
    list: [],
    businessUserLoading: false
};

export function trustpilotReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.TRUSTPILOT_OAUTH_INITIATE_REQUEST:
            return {...state, loading: true, oauthParamsRequest: true};
        case oauthConstants.TRUSTPILOT_OAUTH_INITIATE_SUCCESS:
            return {...state, oauthParams: action.payload, loading: false, oauthParamsRequest: false};
        case oauthConstants.TRUSTPILOT_OAUTH_INITIATE_FAILURE:
            return {...state, error: action.error, loading: false};

        case oauthConstants.TRUSTPILOT_SAVE_CLIENT_APP_DATA_REQUEST:
            return {...state, loading: true};
        case oauthConstants.TRUSTPILOT_SAVE_CLIENT_APP_DATA_SUCCESS:
            return {...state, oauthParams: action.payload, loading: false};
        case oauthConstants.TRUSTPILOT_SAVE_CLIENT_APP_DATA_FAILURE:
            return {...state, error: action.error, loading: false};

        case oauthConstants.TRUSTPILOT_ACCESSTOKEN_REQUEST:
            return {...state, loading: true};
        case oauthConstants.TRUSTPILOT_ACCESSTOKEN_SUCCESS:
            return {...state, showWebhookUrl: true, webhookUrl: action.payload.webhookUrl, loading: false};
        case oauthConstants.TRUSTPILOT_ACCESSTOKEN_FAILURE:
            return {...state, error: action.error, loading: false};

        case oauthConstants.TRUSTPILOT_OAUTH_CLEAR_REQUEST_PARAMS:
            return {...state, oauthParamsRequest: false};
        case oauthConstants.CLOSE_WEBHOOK_MODAL:
            return {...state, showWebhookUrl: false};

        case msgDataSourceConstants.GET_ALL_DATA_SOURCE:
            return {...state, loading: true};
        case msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS:
            return {
                ...state,
                list: action.payload ? action.payload.filter(item => item.dataSourceType === 'trustpilot') : [],
                loading: false
            };
        case msgDataSourceConstants.ALL_DATA_SOURCE_ERROR:
            return {...state, error: action.error, loading: false};
        case msgDataSourceConstants.SAVE_MSG_DATA_SOURCE:
            return {...state, businessUserLoading: true};
        case msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_SUCCESS:
            return {
                ...state,
                list: state.list.map(item => {
                    if (item.trendsId === action.payload.payload.dataSourceId) {
                        item.chatdeskBusinessUserId = action.payload.payload.chatdeskBusinessUserId
                    }
                    return item;
                }),
                businessUserLoading: false
            };
        case msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_ERROR:
            return {...state, error: action.error, businessUserLoading: false};
        default:
            return state
    }
}
