/**
 * This function will be used by the `useXXSetup` files for all the auth providers.
 * It sets a localStorage `redirectUrl` item, which is then later being used by the
 * redux actions to redirect the user to the correct page.
 * @param {string | undefined} redirectUrl 
 */
export default function setRedirectUrl(redirectUrl = undefined) {
	if (redirectUrl) {
		try {
			// Try to get an URL object from the given redirect url. If this fails, set it to undefined.
			const generatedUrlObj = new URL(redirectUrl)
			redirectUrl = generatedUrlObj ? JSON.stringify(redirectUrl) : undefined
		} catch (_) {}
	}

	// If there is now still no redirect url, use curr window location.
	if (!redirectUrl)
		redirectUrl = JSON.stringify(window.location)

	localStorage.setItem('redirectUrl', redirectUrl)
}