import React, { Component } from 'react'
import { Col, Form, Grid, Panel, PanelGroup, Row, ToggleButtonGroup } from 'react-bootstrap'
import { ToggleButtonField } from "components/common";
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'


const style = { background: 'green', color: '#FFF' };
const colStyle = { border: '1px solid #bbb' };

class Billing extends Component {


    addFunds() {
        return (
            <Form>
                <ToggleButtonGroup type="radio" name="sample">
                    <Field
                        component={ToggleButtonField}
                        label="Add Funds"
                        style={style}

                    />
                </ToggleButtonGroup>
            </Form>
        )
    }

    render() {

        const currentBalance = 4.0;
        const messageCount = 50;
        return (
            <Grid className="company-admin">
                <h4 className="text-right">Settings</h4>
                <Row className="show-grid">
                    <Col sm={4} style={colStyle}>
                        <h4>Settings</h4>
                        <PanelGroup>
                            <Panel className="panel-no-css">
                                <Panel.Heading>
                                    <Panel.Title toggle>Current Balance</Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>$ {currentBalance}</Panel.Body>
                            </Panel>

                            <Panel className="panel-no-css">
                                <Panel.Body>{this.addFunds()}</Panel.Body>
                            </Panel>

                            <Panel className="panel-no-css">
                                <Panel.Heading>
                                    <Panel.Title toggle>Usage</Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>Messages so far this month: {messageCount}</Panel.Body>
                            </Panel>
                        </PanelGroup>
                    </Col>
                    <Col sm={6}>

                    </Col>
                </Row>
            </Grid>
        )
    }

}

const formConfig = {
    form: 'billsForm',
};

Billing = connect(
    null,
    null
)(Billing);

const connectedBilling = reduxForm(formConfig)(Billing);
export { connectedBilling as Billing }