import {Box} from "@mui/system";
import ViewInSource from "./ViewInSource";
import styles from "../../voteMainStyles"
import CannotResolve from "./CannotResolve/CannotResolve";

export default function VoteFooterSection({ isScrollComplete }) {

    return (
        <Box sx={styles.footerContainer}>
            <ViewInSource />
            <CannotResolve isScrollComplete={isScrollComplete} />
        </Box>
    )
}