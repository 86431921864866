export default {
	root: {
		fontFamily: 'LarsseitRegular',
		fontSize: 16,
		lineHeight: 1.2,
		letterSpacing: '-0.02em',
		color: '#0C2728',
	},
	headingBox: {
		display: 'flex',
		justifyContent:'space-between',
		alignItems: 'center'
	},
	h1: { 
		mt: 0,
		mb: { xs: 0.5, sm: 1 },
		fontFamily: 'LarsseitMedium',
		fontSize: 24,
		lineHeight: 1.2,
		letterSpacing: '-0.02em',
		color: '#0C2728',
	},
}