import {authHeader, fileUploadHeader, objectToQueryString} from "_helpers";
import {SERVER_URL} from "config";

export const screenshotServices = {
    getAll,
    getScreenshot,
    addScreenshot,
    updateScreenshot,
    viewScreenshot,
    sendMail
};

function getAll(params){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/v1/screenshots?${objectToQueryString(params)}`, requestOptions)
        .then(handleHttpResponse);
}

function viewScreenshot(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/v1/screenshots/${id}/view`, requestOptions)
        .then(handleHttpResponse);
}

function getScreenshot(id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/v1/screenshots/${id}`, requestOptions)
        .then(handleHttpResponse);
}

function addScreenshot(data){
    const requestOptions = {
        method: 'POST',
        headers: fileUploadHeader(),
        body: data
    };

    return fetch(`${SERVER_URL}/api/v1/screenshots`, requestOptions)
        .then(handleHttpResponse);
}

function sendMail(id, params){
    const requestOptions = {
        method: 'POST',
        headers: fileUploadHeader(),
    };

    return fetch(`${SERVER_URL}/api/v1/screenshots/${id}/sendMail?${objectToQueryString(params)}`, requestOptions)
        .then(handleHttpResponse);
}

function updateScreenshot(props){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(props)
    };

    return fetch(`${SERVER_URL}/api/v1/screenshots/${props.id}`, requestOptions)
        .then(handleHttpResponse)
}

/**
 * handles the showing custom errors from the server when specified
 * @param response
 * @returns {*}
 */
export const handleHttpResponse = (response) => {
    if (!response.ok) {
        return response.text().then(text => {
            const parsedError = JSON.parse(text);
            const message = parsedError ? parsedError.message : '';
            throw new Error(message)
        })
    }

    return response.text().then(function (text) {
        return text ? JSON.parse(text) : {}
    })
};
