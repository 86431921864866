import { history } from '_helpers';
import { frontAppOauthService } from '_services';
import { oauthConstants, alertMessages } from '_constants';
import {SnackBar} from "../components/common";

function initiateOauth(callbackUrl) {
  function request() {
    return { type: oauthConstants.FRONTAPP_OAUTH_URL_REQUEST };
  }

  function success(authUrl) {
    return { type: oauthConstants.FRONTAPP_OAUTH_URL_SUCCESS, payload: authUrl };
  }

  function failure(error) {
    return { type: oauthConstants.FRONTAPP_OAUTH_URL_FAILURE, payload: error };
  }

  return (dispatch) => {
    dispatch(request());

    frontAppOauthService.initiateOauth(callbackUrl)
      .then(
        authUrl => dispatch(success(authUrl)),
        (error) => {
          dispatch(failure());
        },
      );
  };
}

function genAccessToken(params, callbackUrl = '/company/channels', onErrorCallbackUrl = '/company/connectchannels') {
  function request() {
    return { type: oauthConstants.FRONTAPP_ACCESSTOKEN_REQUEST };
  }
  function success(res) {
    return { type: oauthConstants.FRONTAPP_ACCESSTOKEN_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: oauthConstants.FRONTAPP_ACCESSTOKEN_FAILURE, payload: error };
  }

  return (dispatch) => {
    dispatch(request());
    frontAppOauthService.oauthAccessToken(params)
      .then(
        (res) => {
          if (res) {
            history.push(callbackUrl);
            dispatch(success(res));
            SnackBar.showMessage(alertMessages.FRONTAPP_OAUTH_SUCCESS);
          }
        },
        (error) => {
          history.push(onErrorCallbackUrl);
          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.FRONTAPP_OAUTH_FAILURE);
        },
      );
  };
}

function createInboxes(formData) {
  let callbackUrl = localStorage.getItem('redirectUrl');
  callbackUrl = JSON.parse(callbackUrl).pathname;

  function request() {
    return { type: oauthConstants.FRONTAPP_INBOX_REQUEST };
  }

  function success(res) {
    return { type: oauthConstants.FRONTAPP_INBOX_SUCCESS, payload: res };
  }

  function failure(error) {
    return { type: oauthConstants.FRONTAPP_INBOX_FAILURE, payload: error };
  }

  return (dispatch) => {

    dispatch(request());

    frontAppOauthService.companyInboxes(formData)
      .then(
        (res) => {
          if (res) {
            history.push(callbackUrl);

            dispatch(success(res));
            SnackBar.showMessage(alertMessages.FRONTAPP_INBOX_SUCCESS);
          }
        },
        (error) => {
          history.push(callbackUrl);

          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.FRONTAPP_INBOX_FAILURE);
        },
      );
  };
}

function closeInboxModal() {
  function success() {
    return { type: oauthConstants.FRONTAPP_CLOSE_MODAL };
  }

  return (dispatch) => {
    dispatch(success());
  }
}

export const frontAppOauthActions = {
  initiateOauth,
  genAccessToken,
  createInboxes,
  closeInboxModal,
};
