import {dixaConstants, tagConstants} from "_constants";

const INITIAL_STATE = {
    tags: {},
    listTagsLoading: false,
    listTagsError: '',
};

export function tagReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case tagConstants.LIST_TAGS_REQUEST:
            return {...state, listTagsLoading: true};
        case tagConstants.LIST_TAGS_SUCCESS:
            return {...state, tags: action.payload, listTagsLoading: false};
        case tagConstants.LIST_TAGS_FAILURE:
            return {...state, listTagsError: action.error, listTagsLoading: false};

        case tagConstants.CLEAR_TAGS:
            return {...state, tags: {}, listTagsError: '', listTagsLoading: false};

        default:
            return state
    }
}