import React from 'react'
import connect from "react-redux/es/connect/connect";
import { SchedulesFilterForm, AdminScheduleList } from "components/Profile";
import { scheduleActions, organizationActions, payoutsActions, pricingActions } from "_actions";
import { objectToQueryString, queryStringToObject } from "_helpers";

class AllCompaniesSchedules extends React.Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);

        this.state = {
            filterParams: {
                organization: '',
                ...filterParams
            },
        };
    }

    /**
     * Handles the updating state on params change and refresh page data
     * @param event
     */
    handleUpdateForm = (event) => {
        const { id } = event;
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams,
                    organization: id
                }
            }
        }, function() {
            const { filterParams } = this.state
            this.getSchedules(filterParams)
        });
    }

    /**
     * Handles the change of url to retrieve schedules based on query params for filtering
     * @param filterParams The parameters for filtering the schedules to be retrieved
     */
    getSchedules = (filterParams) => {
        const queryString = objectToQueryString(filterParams);
        const { history } = this.props;
        history.push({
            pathname: '/schedules',
            search: `?${queryString}`
        });
    }

    componentDidMount() {
        let { dispatch } = this.props
        // @TODO check why the success return with empty array keeps causing re-render of component
        // dispatch(scheduleActions.getOrgWithSchedules());
        const visibility = ['PUBLIC', 'FEATURED', 'PRIVATE'];
        dispatch(organizationActions.getActiveOrganizations({visibility}));
        const { filterParams: { organization }} = this.state
        if (organization) {
            dispatch(scheduleActions.getAll(organization))
        }
        dispatch(payoutsActions.getAll());
        dispatch(pricingActions.getAll());
    }

    render() {
        const { filterParams: { organization }} = this.state;
        const { schedules, organizations, loading } = this.props;

        return (
            <div className="container-pane" style={{display:"", padding:"0 38px"}}>
                <div className="side-bar-pane shadow-border" >
                    <SchedulesFilterForm
                        organization={organization}
                        organizations={organizations}
                        onChange={this.handleUpdateForm}
                    />
                </div>

                <div className="container-right">
                    <div className="shadow-border margin-bottom-50" style={{marginLeft: "38px", padding: "20px 33px 33px 33px"}}>
                        <div>
                            <AdminScheduleList
                                organization={organization}
                                schedules={schedules}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { schedules: { data }, organizations: { loading, activeOrganizations }} = state;

    return {
        organizations: activeOrganizations.organizations,
        schedules: data,
        loading
    };
}

const schedulesPage = connect(mapStateToProps)(AllCompaniesSchedules);
export { schedulesPage as AllCompaniesSchedules };