import React, { Component, Fragment} from "react";
import { GenericContextMenu} from "components/common";



class OrganizationsContextMenu extends Component {

    state = {
        checkedOrganizationNames: [],
        companies: [],
        isAllOrganizationsChecked: false
    };

    componentDidMount() {
        const { checkedOrganizations, ids} = this.props;

        if (checkedOrganizations.length > 0) {
            const organizationNames = this.getOrganizationNames(checkedOrganizations);
            this.setState({
                checkedOrganizationNames: organizationNames
            });

            if (checkedOrganizations.length === ids.length) {
                this.setState({
                    isAllOrganizationsChecked: true
                })
            }
        }
    }

    /**Computes the organization names to be displayed in the context menu parent box
     **params organizationsIds array
     **/

    getOrganizationNames = (organizationIds) => {
        const { options } = this.props;
        let organizationNames = [];
        organizationIds.join("").split(',');

        options.forEach(option => {
            if (organizationIds.indexOf(String(option.value)) !==-1) {
                organizationNames.push(option.name)
            }
        });

        return organizationNames
    };


    /** Convert Organizations to Strings **/
    convertArrayItemsToString = (array) => {
        return array.map(organization => {
            return String(organization)
        });
    };


    /** Handles the checkbox event
     **/
    handleCheckBoxChange = (event) => {
        const { ids, checkedOrganizations, handleStatus } = this.props;
        let { companies, isAllOrganizationsChecked } = this.state;
        const target = event.target;

        if(checkedOrganizations.length === 0 && isAllOrganizationsChecked) {
            companies = [...ids];
        }else{
            companies = checkedOrganizations;
        }

        /** Values of organizations are stored as strings in the HTML body
         so there is a need to convert all array items to string to properly use
         the splice method.
         **/
        companies = this.convertArrayItemsToString(companies);

        if (target.checked) {
           companies.push(target.value);
        } else {
            companies.splice(companies.indexOf(target.value), 1);
            this.setState({
                isAllOrganizationsChecked: false
            })
        }

        const organizationsNames = this.getOrganizationNames(companies);
        this.setState({
            checkedOrganizationNames: organizationsNames, companies
        });

        if(companies.length === ids.length) {
            this.setState({
                isAllOrganizationsChecked: true
            })
        }

        if(handleStatus){
            this.props.updateHandles(companies, handleStatus);
            this.props.handleChange(companies)
        } else{
            this.props.handleChange(companies)
        }
    };

    handleAllOrganizationsChange = (event) => {
        const { ids } = this.props;
        const { isAllOrganizationsChecked, isOrganizationsUpdated } = this.state;

        let companies;
        if (!isOrganizationsUpdated) {
            this.setState({
                isOrganizationsUpdated: true
            })
        }

        if(!isAllOrganizationsChecked) {
            this.setState({
                isAllOrganizationsChecked: true
            });
            companies = [...ids];
        }else{
            this.setState({
                isAllOrganizationsChecked: false
            });
            companies = []
        }

        this.props.handleChange(companies)
    };

    render() {

        let { options, checkedOrganizations, ids, style, caretPosition } = this.props;
        const { checkedOrganizationNames, isAllOrganizationChecked} = this.state;

        const selectedOrganizationNames = checkedOrganizationNames.length !== 0 ? checkedOrganizationNames.join(", "): "Select Organization";

        checkedOrganizations = this.convertArrayItemsToString(checkedOrganizations);

        let isAllOrganizationsChecked = isAllOrganizationChecked || checkedOrganizations.length === ids.length;

        return (
            <Fragment>
                <GenericContextMenu  trianglePosition={caretPosition ? caretPosition : "left"} style={style}>
                    <div className="select-field tms-ellipsis color-grey-dark" key="trigger">{ selectedOrganizationNames} </div>
                    <ul key="body">
                        <label className="context-menu-label font-15 padding-bottom-10 align-center">
                            <input type="checkbox" name="allOrganizations"  checked={isAllOrganizationsChecked} onChange={this.handleAllOrganizationsChange}  className="tms-checkbox tms-child-checkbox"/>
                            <span className="tms-check-mark"></span>
                            All Organizations
                        </label>

                        {options && options.map((option) => {
                            let isChecked;
                            if ( checkedOrganizations.length !== 0) {
                                isChecked = checkedOrganizations.includes(String(option.value));
                            } else if (checkedOrganizations.length === 0 && !isAllOrganizationsChecked ) {
                                isChecked = false
                            }

                            return (
                                <label key={option.value} className="context-menu-label font-15 padding-bottom-10 align-center">
                                    <input type="checkbox" name={option.name} checked={isChecked} value={option.value} onChange={this.handleCheckBoxChange}  className="tms-checkbox tms-child-checkbox"/>
                                    <span className="tms-check-mark"></span>
                                    {option.name}
                                </label>
                            )
                        })}
                    </ul>
                </GenericContextMenu>
            </Fragment>
        )
    }
}

export {OrganizationsContextMenu};