import React, { Component } from 'react'
import { GenericModal } from 'components/common';
import {connect} from 'react-redux'
import { Button } from 'react-bootstrap';
import { trustpilotOauthActions } from "_actions";

class TrustpilotCredentialsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credentials: {},
        }
    }

    handleChange = (e) => {
        this.setState((prevState) => ({
            credentials: {
                ...prevState.credentials,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
            }
        }));
    };

    saveCredentials = () => {
        const { credentials } = this.state;
        const { dispatch, dataSourceId } = this.props;
        dispatch(trustpilotOauthActions.saveCredentials({ ...credentials, dataSourceId }))
    };

    render() {
        const { credentials } = this.state;
        const { showData, closeModal } = this.props;

        return (
            <GenericModal
                headerText={`Update Trustpilot Credentials`}
                hideFooter
                show={showData}
                onHide={closeModal}
                size="lg"
                isConversationThread={true}
            >
                <div className="content">
                    <div className="form-group">
                        <label htmlFor="accessToken">Access Token</label>
                        <input type="text" className="form-control" id="accessToken" name="accessToken" value={credentials.accessToken || ''} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="refreshToken">Refresh Token</label>
                        <input type="text" className="form-control" id="refreshToken" name="refreshToken" value={credentials.refreshToken || ''} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="expiresIn">Expires in</label>
                        <input type="text" className="form-control" id="expiresIn" name="expiresIn" value={credentials.expiresIn || ''} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="issuedAt">Issued At</label>
                        <input type="text" className="form-control" id="issuedAt" name="issuedAt" value={credentials.issuedAt || ''} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="appEnduser">App End User</label>
                        <input type="text" className="form-control" id="appEnduser" name="appEnduser" value={credentials.appEnduser || ''} onChange={this.handleChange} />
                    </div>
                    <hr />
                    <div className="form-group">
                        <label htmlFor="clientId">Client Id</label>
                        <input type="text" className="form-control" id="clientId" name="clientId" value={credentials.clientId || ''} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="clientSecret">Client Secret</label>
                        <input type="text" className="form-control" id="clientSecret" name="clientSecret" value={credentials.clientSecret || ''} onChange={this.handleChange} />
                    </div>
                    <Button className="margin-top-5" onClick={this.saveCredentials}>
                        Save
                    </Button>
                </div>
            </GenericModal>
        )
    }
}

const connectedAddInbox = connect(null)(TrustpilotCredentialsModal)
export { connectedAddInbox as TrustpilotCredentialsModal }