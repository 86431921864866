import React, {Component} from "react";
import { MenuItem, MenuList } from "@material-ui/core";
import {screenshotActions} from "_actions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";


class ScreenshotUserActions extends Component {

    state = {
        redirect: null
    };

    updateScreenshot = (payload) => {
        this.props.updateScreeshot(payload)
    };

    viewScreenshot = (id) => {
        this.setState({redirect: `screenshots/${id}/viewFile`})
    };

    sendMail = (id) => {
        //remove query strings from the current string if present
        const urlArray = window.location.href ? window.location.href.split('?') : [];
        const clientBaseUrl = urlArray.length > 0 ? urlArray[0] : '';
        const props = {clientBaseUrl: clientBaseUrl};
        this.props.sendMail(id, props)
    };

    render() {
        const {handleClose, screenshot} = this.props;

        if (this.state.redirect){
            return (<Redirect to={this.state.redirect} />)
        }

        return (
            <MenuList>
                <MenuItem onClick={handleClose}>
                    <button type="button" className="btn btn-default btn-block" onClick={() => this.sendMail(screenshot.id)}>
                        Send Email
                    </button>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <button type="button" className="btn btn-default btn-block" onClick={() => this.updateScreenshot({isVisible: !screenshot.isVisible, id: screenshot.id})}>
                        {screenshot.visibility}
                    </button>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <button type="button" className="btn btn-default btn-block"onClick={() => this.viewScreenshot(screenshot.id)} >
                        <span className="glyphicon glyphicon-eye-open"/> VIEW SCREENSHOT
                    </button>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <button type="button"  className="btn btn-danger btn-block">
                        <span className="glyphicon glyphicon-trash"/> DELETE SCREENSHOT
                    </button>
                </MenuItem>

            </MenuList>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateScreeshot: (props) => dispatch(screenshotActions.updateScreenshot(props)),
        viewScreenshot: (id) => dispatch(screenshotActions.viewScreenshot(id)),
        sendMail: (id, props) => dispatch(screenshotActions.sendMail(id, props))
    }
};



const connectedComponent = connect(null, mapDispatchToProps)(ScreenshotUserActions);

export {connectedComponent as ScreenshotUserActions}