import React, {Fragment} from "react";
import logoReamaze from 'components/images/logos/logo_reamaze.png'
import ChannelCard from '../../ChannelCard'
import useReamazeSetup from "./useReamazeSetup";
import ReamazeSetupModal from "./ReamazeSetupModal";

export default function ReamazeEntry() {
	const { initiateReamazeSetup, reamazeSetupOpen, handleReamazeSetupOpen, handleReamazeSetupClose } = useReamazeSetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoReamaze}
				label={'Reamaze'}
				description={'Clear your email and chat ticket backlog'}
				onClick={initiateReamazeSetup}
			/>

			<ReamazeSetupModal
				open={reamazeSetupOpen}
				handleOpen={handleReamazeSetupOpen}
				handleClose={handleReamazeSetupClose}
			/>
		</Fragment>
	)
}