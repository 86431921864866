import Checkbox from "@mui/material/Checkbox/Checkbox";
import {Box} from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import React from "react";

export default function TermsCheckboxLabel({checked}) {

  return(
    <FormControlLabel control={<Checkbox checked={checked}  sx={{ '& .MuiSvgIcon-root': { fontSize: '20px', color: '#368C86' } }} />}
        label={
          <Box className="larsseit-regular"
               fontSize={{xs: '12px', sm: '12px', md: '14px', lg: '14px'}}
               style={{color: '#0C2728'}}>
                I agree to Chatdesk’s <a href="https://chatdesk.com/terms" style={{color: '#368C86'}} rel="noopener" target="_blank">
                Terms and Conditions
               </a> & <a href="https://chatdesk.com/privacy" style={{color: '#368C86'}} rel="noopener" target="_blank">
                Privacy
               </a>
          </Box>
        }
    />
  )
}