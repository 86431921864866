import React, {useState} from "react";
import {Box} from "@mui/system";
import styles from "../../organizationMainStyles";
import {Switch} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";
import {accessibilityMode} from "../organizationSettingsConstants";
import {buildUpdateMobileHidePayload} from "../organizationSettingsUtils";
import {SnackBar} from "../../../../../common";

export default function HideInMobileToggleContainer({mode, organization, label}) {
    const disable = mode === accessibilityMode.READ_ONLY;
    const hideInMobile = _.get(organization, 'hideInMobile');
    const organizationSettings = useSelector(state => state.organizationSettings);
    const organizationAttribute = organizationSettings.organizationAttributeUpdate;
    const isHidden = _.isEmpty(organizationAttribute) ? hideInMobile : organizationAttribute.hideInMobile;
    const organizationId = _.get(organization, 'id');
    const dispatch = useDispatch();

    const handleToggle = (event) => {
        const value = _.get(event, 'target.checked');
        handleUpdate(value);
    }

    const handleUpdate = (value) => {
        const payload = buildUpdateMobileHidePayload(value);
        if(!organizationId || !payload) return SnackBar.showMessage('Invalid parameters');
        dispatch(organizationSettingsActions.updateOrganizationAttribute(organizationId, payload));
    }

    return(
        <Box>
            <Box component={'h2'} sx={styles.heading}>
                {label.HIDE_IN_MOBILE}
            </Box>
            <Box sx={{mt: '8px'}}>
                <Switch disabled={disable} checked={isHidden} onChange={handleToggle} sx={styles.switch} />
            </Box>
        </Box>
    )
}