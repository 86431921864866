
export const organizationConstants = {
  GET_ALL_REQUEST: 'GET_ALL_ORGANIZATIONS_REQUEST',
  GET_ALL_SUCCESS: 'GET_ALL_ORGANIZATIONS_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_ORGANIZATIONS_FAILURE',

  GET_ORG_PAID_MESSAGES_REQUEST: 'GET_ORG_PAID_MESSAGES_REQUEST',
  GET_ORG_PAID_MESSAGES_SUCCESS: 'GET_ORG_PAID_MESSAGES_SUCCESS',
  GET_ORG_PAID_MESSAGES_FAILURE: 'GET_ORG_PAID_MESSAGES_FAILURE',

  GET_ORG_PAID_TICKETS_REQUEST: 'GET_ORG_PAID_TICKETS_REQUEST',
  GET_ORG_PAID_TICKETS_SUCCESS: 'GET_ORG_PAID_TICKETS_SUCCESS',
  GET_ORG_PAID_TICKETS_FAILURE: 'GET_ORG_PAID_TICKETS_FAILURE',

  UPDATE_ORGANIZATION_REQUEST: 'UPDATE_ORGANIZATIONS_REQUEST',
  UPDATE_ORGANIZATION_SUCCESS: 'UPDATE_ORGANIZATIONS_SUCCESS',
  UPDATE_ORGANIZATION_FAILURE: 'UPDATE_ORGANIZATIONS_FAILURE',

  GET_DETAILS_REQUEST: 'GET_ORGANIZATION_DETAILS_REQUEST',
  GET_DETAILS_SUCCESS: 'GET_ORGANIZATION_DETAILS_SUCCESS',
  GET_DETAILS_FAILURE: 'GET_ORGANIZATION_DETAILS_FAILURE',

  GET_ORGANIZATION_USERS_REQUEST: 'GET_ORGANIZATION_USERS_REQUEST',
  GET_ORGANIZATION_USERS_SUCCESS: 'GET_ORGANIZATION_USERS_SUCCESS',
  GET_ORGANIZATION_USERS_FAILURE: 'GET_ORGANIZATION_USERS_FAILURE',

  GET_AGENT_EMPLOYERS_REQUEST: 'GET_AGENT_EMPLOYERS_REQUEST',
  GET_AGENT_EMPLOYERS_SUCCESS: 'GET_AGENT_EMPLOYERS_SUCCESS',
  GET_AGENT_EMPLOYERS_FAILURE: 'GET_AGENT_EMPLOYERS_FAILURE',

  GET_SUMMARY_STATS_DATA_REQUEST: 'GET_ORGANIZATION_STAT_SUMMARY_REQUEST',
  GET_SUMMARY_STATS_DATA_SUCCESS: 'GET_ORGANIZATION_STAT_SUMMARY_SUCCESS',
  GET_SUMMARY_STATS_DATA_FAILURE: 'GET_ORGANIZATION_STAT_SUMMARY_FAILURE',

  EXPORT_ORGANIZATION_STATS: 'EXPORT_ORGANIZATION_STATS',
  EXPORT_ORGANIZATION_STATS_SUCCESS: 'EXPORT_ORGANIZATION_STATS_SUCCESS',
  EXPORT_ORGANIZATION_STATS_FAILURE: 'EXPORT_ORGANIZATION_STATS_FAILURE',

  GET_ORG_PERFECT_RESPONSES_SUMMARY_REQUEST: "GET_ORG_PERFECT_RESPONSES_SUMMARY_REQUEST",
  GET_ORG_PERFECT_RESPONSES_SUMMARY_SUCCESS: "GET_ORG_PERFECT_RESPONSES_SUMMARY_SUCCESS",
  GET_ORG_PERFECT_RESPONSES_SUMMARY_FAILURE: "GET_ORG_PERFECT_RESPONSES_SUMMARY_FAILURE",

  GET_ORGANIZATION_PROFITABILITY_REQUEST:'GET_ORGANIZATION_PROFITABILITY_REQUEST',
  GET_ORGANIZATION_PROFITABILITY_SUCCESS:'GET_ORGANIZATION_PROFITABILITY_SUCCESS',
  GET_ORGANIZATION_PROFITABILITY_FAILURE:'GET_ORGANIZATION_PROFITABILITY_FAILURE',

  GET_POSITIVE_SCREENSHOTS_REQUEST: "GET_POSITIVE_SCREENSHOTS_REQUEST",
  GET_POSITIVE_SCREENSHOTS_SUCCESS: "GET_POSITIVE_SCREENSHOTS_SUCCESS",
  GET_POSITIVE_SCREENSHOTS_FAILURE: "GET_POSITIVE_SCREENSHOTS_FAILURE",

  SAVE_ORG_VERTICALS_REQUEST: "SAVE_ORG_VERTICALS_REQUEST",
  SAVE_ORG_VERTICALS_SUCCESS: "SAVE_ORG_VERTICALS_SUCCESS",
  SAVE_ORG_VERTICALS_FAILURE: "SAVE_ORG_VERTICALS_FAILURE",

  GET_TEAM_LEADS_REQUEST: "GET_TEAM_LEADS_REQUEST",
  GET_TEAM_LEADS_SUCCESS: "GET_TEAM_LEADS_SUCCESS",
  GET_TEAM_LEADS_FAILURE: "GET_TEAM_LEADS_FAILURE",

  GET_CSM_REQUEST: "GET_CSM_REQUEST",
  GET_CSM_SUCCESS: "GET_CSM_SUCCESS",
  GET_CSM_FAILURE: "GET_CSM_FAILURE",

  GET_SLACK_STATUS_REQUEST: 'GET_SLACK_STATUS_REQUEST',
  GET_SLACK_STATUS_SUCCESS: 'GET_SLACK_STATUS_SUCCESS',
  GET_SLACK_STATUS_FAILURE: 'GET_SLACK_STATUS_FAILURE',

  TRIGGER_ESCALATIONS_VIA_EMAIL_REQUEST: 'TRIGGER_ESCALATIONS_VIA_EMAIL_REQUEST',
  TRIGGER_ESCALATIONS_VIA_EMAIL_SUCCESS: 'TRIGGER_ESCALATIONS_VIA_EMAIL_SUCCESS',
  TRIGGER_ESCALATIONS_VIA_EMAIL_FAILURE: 'TRIGGER_ESCALATIONS_VIA_EMAIL_FAILURE',

  GET_COMPANY_ADMIN_REQUEST: "GET_COMPANY_ADMIN_REQUEST",
  GET_COMPANY_ADMIN_SUCCESS: "GET_COMPANY_ADMIN_SUCCESS",
  GET_COMPANY_ADMIN_FAILURE: "GET_COMPANY_ADMIN_FAILURE",

  GET_ORGANIZATIONS_CSAT_REQUEST: "GET_ORGANIZATIONS_CSAT_REQUEST",
  GET_ORGANIZATIONS_CSAT_SUCCESS: "GET_ORGANIZATIONS_CSAT_SUCCESS",
  GET_ORGANIZATIONS_CSAT_FAILURE: "GET_ORGANIZATIONS_CSAT_FAILURE",

  GET_ORGANIZATIONS_ACTIVE_EXPERTS_REQUEST: "GET_ORGANIZATIONS_ACTIVE_EXPERTS_REQUEST",
  GET_ORGANIZATIONS_ACTIVE_EXPERTS_SUCCESS: "GET_ORGANIZATIONS_ACTIVE_EXPERTS_SUCCESS",
  GET_ORGANIZATIONS_ACTIVE_EXPERTS_FAILURE: "GET_ORGANIZATIONS_ACTIVE_EXPERTS_FAILURE",

  GET_ORGANIZATIONS_REVIEWS_STATS_REQUEST: "GET_ORGANIZATIONS_REVIEWS_STATS_REQUEST",
  GET_ORGANIZATIONS_REVIEWS_STATS_SUCCESS: "GET_ORGANIZATIONS_REVIEWS_STATS_SUCCESS",
  GET_ORGANIZATIONS_REVIEWS_STATS_FAILURE: "GET_ORGANIZATIONS_REVIEWS_STATS_FAILURE",

  DELETE_ORGANIZATION_LOGO_REQUEST: "DELETE_ORGANIZATION_LOGO_REQUEST",
  DELETE_ORGANIZATION_LOGO_SUCCESS: "DELETE_ORGANIZATION_LOGO_SUCCESS",
  DELETE_ORGANIZATION_LOGO_FAILURE: "DELETE_ORGANIZATION_LOGO_FAILURE",

  GET_ACTIVE_ORGANIZATIONS_REQUEST: "GET_ACTIVE_ORGANIZATIONS_REQUEST",
  GET_ACTIVE_ORGANIZATIONS_SUCCESS: "GET_ACTIVE_ORGANIZATIONS_SUCCESS",
  GET_ACTIVE_ORGANIZATIONS_FAILURE: "GET_ACTIVE_ORGANIZATIONS_FAILURE",

  GET_SALESFORCE_ORG_CONFIG_REQUEST: 'GET_SALESFORCE_ORG_CONFIG_REQUEST',
  GET_SALESFORCE_ORG_CONFIG_SUCCESS: 'GET_SALESFORCE_ORG_CONFIG_SUCCESS',
  GET_SALESFORCE_ORG_CONFIG_FAILURE: 'GET_SALESFORCE_ORG_CONFIG_FAILURE',

};

export const publicAndFeaturedVisibility = [
  'PUBLIC', 'FEATURED'
]
