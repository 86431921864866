import {Box} from "@mui/system";
import React from "react";
import _ from "lodash";
import useGetUserAddedResponseOptions from "../../../Utilities/hooks/useGetUserAddedResponseOptions";
import LoadingPlaceholderBlock from "../../../../../../common/LoadingPlaceholderBlock";

export default function AddedResponseCount() {
    const {count, loadingUserAddedResponseOptions} = useGetUserAddedResponseOptions();

    if(loadingUserAddedResponseOptions) {
        return (
            <LoadingPlaceholderBlock
                width={'100px'}
                height={'14px'}
                borderRadius={'3px'}
                backgroundColor={'#EFEAE5'}
            />
        )
    }

    return(
        <Box sx={style.addedResponseCount}>
            {_.isNumber(count) && `${count} response${count > 1 ? `s` : ''} added`}
        </Box>
    )
}

const style = {
    addedResponseCount: {
        fontSize: '14px',
        color: '#0C2728',
        textAlign: 'right',
        fontFamily: 'LarsseitRegular',
    },
}