import { useEffect, useState, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Box, Stack, Link } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import moment from 'moment';
import useLocationQueryParams from 'components/v2/hooks/useLocationQueryParams'
import AccordionItem from 'components/v2/common/AccordionItem';
import ChannelListItem from './Utilities/ChannelListItem';
import useFilteredMsgSources from './Utilities/hooks/useFilteredMsgSources'
import importedStyles from './Utilities/styles'
import ListMenu from './Utilities/ListMenu';
import _ from 'lodash';

// V1 Components, replace these maybe with V2 Components later
import { CopyrightFooter } from "components/common";
import ScheduleMsgDataSourceTable from './Utilities/ScheduleMsgDataSourceTable';
import useScheduleDetails from "./Utilities/hooks/useScheduleDetails";

const MetricsScheduleDetails = () => {
	const { filteredMsgSources: msgSources } = useFilteredMsgSources()
	const scheduleData = useScheduleDetails();
	const timezone = moment.tz(_.get(scheduleData, 'extraData.timezone') || '').zoneAbbr();

	const days = [
		{ label: 'Sunday', value: 1 },
		{ label: 'Monday', value: 2 },
		{ label: 'Tuesday', value: 3 },
		{ label: 'Wednesday', value: 4 },
		{ label: 'Thursday', value: 5 },
		{ label: 'Friday', value: 6 },
		{ label: 'Saturday', value: 7 },
	]

	useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	
	// Filter params
	const { params, setParams } = useLocationQueryParams()
	const [scheduleWeekday, _setScheduleWeekday] = useState(() => {
		if (params && params.scheduleWeekday && typeof params.scheduleWeekday === 'string' && params.scheduleWeekday.length > 0)
		return parseInt(params.scheduleWeekday);
		const filteredDay = days.find(day => day.label === moment().format('dddd'));
		return _.get(filteredDay, 'value') || 0;
	})

	const setScheduleWeekday = (newVal) => {
		setParams({ scheduleWeekday: parseInt(newVal) })
		_setScheduleWeekday(newVal)
	}
	
	const currDayLabel = useMemo(() => {
		const foundEntry = days.find(day => day.value === scheduleWeekday)
		return foundEntry ? foundEntry.label : ''
	}, [scheduleWeekday])

	const scheduleDataForCurrWeekday = useMemo(() => {
		if (!scheduleData.data) return null
		const foundEntry = scheduleData.data.find(entry => {
			return entry.dayId == scheduleWeekday
		})
		if (!foundEntry || !foundEntry.channels) return null
		return foundEntry.channels
	}, [scheduleWeekday, scheduleData, msgSources])

	return (
		<>
			<Box sx={styles.root}>
				<Box sx={styles.container}>
					<Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
						<RouterLink to='/metrics'>
							<Box sx={styles.backLink}>
								<ArrowBackIosNewIcon sx={{ fontSize: '16px', color: ' #368C86' }} />
								Back to Teams Metrics
							</Box>
						</RouterLink>
					</Box>

					<Stack direction={{ xs: 'column', md: 'row' }} sx={{ justifyContent: 'space-between' }}>
						<Stack direction='column' sx={styles.hero} spacing={1}>
							<Box component={'h1'} sx={importedStyles.headingLarge}>
								Schedule Details
							</Box>
							<Box sx={importedStyles.root}>
								Email{' '}
								<Link
									sx={importedStyles.textLink}
									underline='none'
									href={'mailto:support@chatdesk.com'}>
									support@chatdesk.com
								</Link>
								{' '}to make schedule changes
							</Box>
						</Stack>
						<Stack direction={{ xs: 'column', md: 'row' }} spacing={1} sx={styles.headingBox}>
							<Box component={'h2'} sx={{ ...importedStyles.headingSmall, ...styles.heading }}>
								Viewing schedule details for
							</Box>
							<ListMenu
								label={'Weekday'}
								id={'ticket-volume-channel-filter'}
								variant='filled'
								options={days}
								selectedValue={scheduleWeekday}
								onChange={setScheduleWeekday}
							/>
							</Stack>
					</Stack>
						<Box sx={styles.card}>
							{!scheduleData.loading ? (
								!_.isEmpty(scheduleData.data) && scheduleDataForCurrWeekday && scheduleDataForCurrWeekday.length ? scheduleDataForCurrWeekday.map(scheduleData => {
									// Get the matching msgSource entry for this scheduleData schedule entry.
									const msgSource = msgSources.find(msgSource => msgSource.id === scheduleData.id)
									return (
										<AccordionItem key={scheduleData.id} keyNumber={scheduleData.id} defaultExpanded summaryComponent={
											<ChannelListItem
												label={(msgSource && msgSource.name) || ''}
												description={(scheduleData.msgDataSources && scheduleData.msgDataSources.length) ?
													`${scheduleData.msgDataSources.length} account${scheduleData.msgDataSources.length > 1 ? 's' : ''} scheduled` : ''}
											/>
										}>
											<Stack direction={'column'} spacing={3} sx={{ mb: 1.5 }}>
												{scheduleData.msgDataSources.length ? scheduleData.msgDataSources.map(msgDataSource => {
													// Get the matching msgSource.msgDataSources entry for this msgDataSource schedule entry.
													const msgDataSourceData = msgSource ? msgSource.msgDataSources.find(entry => entry.id === msgDataSource.id) : null
													return (
														<ScheduleMsgDataSourceTable
															key={msgDataSource.id}
															msgDataSource={msgDataSource}
															msgDataSourceData={msgDataSourceData}
															timezone={timezone}
														/>
													)
												}) : null}
											</Stack>
										</AccordionItem>
									)
								}) : (
									<Box sx={styles.emptyState}>
										No schedules for {currDayLabel}. Try to select another day.
									</Box>
								)
							) : (
								<Box sx={styles.emptyState}>
									Loading schedules for {currDayLabel}...
								</Box>
							)}
						</Box>
				</Box>
			</Box>

			<CopyrightFooter />
		</>
	)
}

export default MetricsScheduleDetails

const styles = {
	root: { backgroundColor: '#F8F9F9', pt: 16, pb: 10, minHeight: '100vh', },
  container: { px: { xs: 2, sm: 6 },  maxWidth: 980, position: 'relative' },
	hero: {
		justifyContent: 'space-between',
		mb: { xs: '24px', sm: '40px', },
	},
	heading: {
		mb: '0!important',
		mr: 1,
		fontFamily: 'LarsseitRegular',
	},
	headingBox: {
		alignItems: { xs: 'flex-start', md: 'center' 	},
		height: { xs: 'auto', md: '20px' },
		mb: { xs: 4, md: 3 },
	},
	backLink: {
		...importedStyles.root,
		display: 'flex',
		fontFamily: 'LarsseitMedium',
		alignItems: 'center',
		mt: '-24px',
		mb: '24px',
		whiteSpace: 'nowrap',
	},
	card: {
		border: '1px solid #b6bebf',
		backgroundColor: '#fff',
		borderRadius: '10px',
		overflow: 'hidden',
	},
	emptyState: {
		...importedStyles.root,
		p: 4,
		textAlign: 'center',
	},
}