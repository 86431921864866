import React from 'react'
import { connect } from "react-redux";
import { payoutsActions, currencyActions, alertActions } from "_actions";
import PaymentForm from 'components/Payments/PaymentForm'

class EditPayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentId: '',
            payoutData: {
                currency: '',
                amount: '',
                label: '',
            },
            submitting: false,
            updated: false,
        };
    }

    componentDidMount() {
        const { dispatch, match: { params: { itemId }}} = this.props
        dispatch(payoutsActions.getPayout(itemId))
        dispatch(currencyActions.getAll())
    }

    componentDidUpdate(prevProps, prevState) {
        const { payout, match: { params: { itemId }}} = this.props
        const { updated } = this.state
        if (payout.id === itemId && ! updated) {
            this.setState({
                currentId: payout.id,
                payoutData: {
                    currency: payout.currency.id,
                    amount: payout.amount,
                    label: payout.label,
                },
                updated: true
            })
        }
    }


    handleChange = (event) => {
        const { target: {name, value}} = event
        this.setState(prevState => ({
            payoutData: {
                ...prevState.payoutData,
                [name]: value
            }
        }))
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { payoutData, currentId } = this.state
        const { dispatch } = this.props
        if (! payoutData.currency || ! payoutData.amount || ! payoutData.label) {
            dispatch(alertActions.error('All Form Fields are required'))
            return
        }
        this.setState({ submitting: true })
        dispatch(payoutsActions.updatePayout(payoutData, currentId))
    }

    render() {
        const { currencies } = this.props
        const { payoutData, submitting } = this.state

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <PaymentForm
                    title="Edit Payout Rate"
                    formData={payoutData}
                    currencies={currencies}
                    submitting={submitting}
                    url="/payouts-rates"
                    actionType="Update"
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { currency: { all_currency }, payout: { payout }} = state;

    return {
        currencies: all_currency,
        payout
    };
}

const editPayoutPage = connect(mapStateToProps)(EditPayout);
export { editPayoutPage as EditPayout };