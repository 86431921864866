import logoInstagram from 'components/images/logos/logo_instagram.svg'
import useInstagramSetup from './useInstagramSetup'
import ChannelCard from '../../ChannelCard'

export default function InstagramEntry() {
	const { initiateInstagramSetup } = useInstagramSetup()
	return (
		<ChannelCard
			icon={logoInstagram}
			label={'Instagram'}
			description={'Respond to comments on Instagram Ads, Mentions, Posts and DMs. Hide and moderate spam and negative comments. Reels are not currently supported'}
			onClick={initiateInstagramSetup}
		/>
	)
}