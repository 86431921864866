import React, { Component } from "react";
import {  Col, Grid, Row } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { required} from "../../_helpers";
import {SearchableSelect} from "../common/formFields/SearchableSelect";

class FilterForm extends Component {

    render (){
        let{
            companies,
            handleChange,

        }=this.props;


        return (
                <Grid>

                    <Row style={{display: 'flex', justifyContent: 'center'}}>

                        <Col className="inbox-context-menu" style = {{marginBottom: "5px"}} md={3}>
                            <Field
                                options={companies}
                                validate={required}
                                component={SearchableSelect}
                                name="organization"
                                clearable={true}
                                onChange={handleChange}
                                placeholder="Select Organization"
                            />

                        </Col>
                    </Row>
                </Grid>
        );
    }
}

const filterForm = reduxForm({
    form: 'selectOrgForm'
})(FilterForm);

export { filterForm as FilterForm }