import React, {Fragment} from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {Box} from "@mui/system";
import {makeStyles} from "@mui/styles";

export default function CharacterChecker({label, isChecked, hasError}) {
  const classes = useStyles();

  return(
    <Fragment>
      {isChecked ?
        <CheckCircleIcon className={ hasError ? classes.iconError : classes.icon } /> :
        <RadioButtonUncheckedIcon className={ hasError ? classes.iconError : classes.icon } />
      }
      <Box className={ hasError ? classes.labelError : classes.label}>
        {label}
      </Box>
    </Fragment>
  )
}

const useStyles = makeStyles({
  icon: {
    fontSize: '17px',
    color: '#0C2728'
  },
  iconError: {
    fontSize: '17px',
    color: '#ffffff'
  },
  label: {
    margin: '1px 0 0 9px',
    fontSize: '14px',
    color: '#0C2728',
    lineHeight: '120%',
    fontFamily: 'larsseitRegular'
  },
  labelError: {
    margin: '1px 0 0 9px',
    fontSize: '14px',
    color: '#ffffff',
    lineHeight: '120%',
    fontFamily: 'larsseitRegular'
  }
});