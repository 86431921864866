import React, {Fragment} from "react";
import logoZendesk from 'components/images/logos/logo_zendesk.svg'
import useZendeskChatSetup from './useZendeskChatSetup'
import ChannelCard from '../../ChannelCard'
import ZendeskChatSetupModal from './ZendeskChatSetupModal'

export default function ZendeskChatEntry() {
	const { initiateZendeskChatSetup, zendeskChatSetupOpen, handleZendeskChatSetupClose, handleZendeskChatSetupOpen } = useZendeskChatSetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoZendesk}
				label={'Zendesk Chat'}
				description={'Integrate Zendesk Chat with Teams. Zendesk is one of our most popular integrations.'}
				onClick={initiateZendeskChatSetup}
			/>

			<ZendeskChatSetupModal
				open={zendeskChatSetupOpen}
				handleOpen={handleZendeskChatSetupOpen}
				handleClose={handleZendeskChatSetupClose}
			/>
		</Fragment>
	)
}