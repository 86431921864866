import React, {Component} from 'react';
import {userActions} from '_actions';
import connect from 'react-redux/es/connect/connect';
import {Loading} from 'components/common';
import _, {isEmpty} from 'lodash';
import ReactPaginate from 'react-paginate';
import moment from 'moment/moment';
import htmlParser from "he";

/**
 * Renders a list of messages that an agent has been voting on.
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 12/11/2018 : 5:11 PM
 * @author Chris Okebata
 */
class AgentsMessages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            offset: 0,
            max: 10
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { agent, organization, filterBy, dispatch } = this.props;
        const prevAgent = prevProps.agent;
        const prevOrganization = prevProps.organization;
        const prevFilterBy = prevProps.filterBy;

        if (agent !== prevAgent || organization !== prevOrganization || filterBy !== prevFilterBy) {
            const id = _.get(agent, "id");

            if(id) {
                dispatch(userActions.getMessagesForAgent(id, {
                    organization: organization?.id,
                    responseType: filterBy,
                }));
            }
        }
    }

    handleSelect = (page, params) => {
        const {agent: {id}, organization, dispatch} = this.props;
        const offset = params.max * (page.selected);

        const queryParameters = {...params, offset};
        this.setState({currentPage: page.selected});

        dispatch(userActions.getMessagesForAgent(id, {
            ...queryParameters,
            organization: organization.id,
        }));

        this.props.clearConversation()
    };

    renderMessageRow = (message) => {
        let {text} = message;
        const {agent, selectedConversation, onConversationSelect} = this.props;
        text = htmlParser.decode(text);
        const id = _.get(agent, 'id');

        return (
            <div
                role="button"
                className="message-row row-update-onclick"
                key={message.id}
                id={message.id}
                style={selectedConversation === message.id ? {
                    borderRight: '3px solid #008751',
                    background: '#d3eabd',
                } : {}}
                onClick={() => onConversationSelect(message.id, id)}
            >
                <div
                    style={{width: '265px', display: 'inline-block'}}
                    className="tms-ellipsis font-14 color-black-fade font-15"
                >
                    <span>{text}</span>
                </div>
                <div
                    style={{
                        width: '57px',
                        display: 'inline-block',
                        float: 'right',
                        textAlign: 'right',
                        marginLeft: '5px',
                        lineHeight: '23px',
                    }}
                    className="tms-ellipsis font-13 color-grey-mid"
                >
                    {moment(message.dateCreated).format('MMM D')}
                </div>
            </div>
        );
    };

    getNextPage = (queryOffset, queryMax, messageCount) => {
        queryOffset = parseInt(queryOffset, 10);
        queryMax = parseInt(queryMax, 10);
        messageCount = parseInt(messageCount, 10);

        if (!messageCount) return 0;

        if ([0, 10, 20, 30, 40, 50, 60, 70, 80, 90].includes(queryOffset) && messageCount === 10) {
            return messageCount + 1;
        } else if (messageCount / queryMax === 1) {
            return (queryOffset / messageCount) + 2;
        } else if (messageCount / queryMax < 1) {
            return (queryOffset / queryMax) + 1;
        } else {
            return 100 // fallback
        }
    };

    render() {
        const {agentMessages, loading} = this.props;
        const {currentPage} = this.state;

        if (isEmpty(agentMessages) || loading) {
            return <Loading/>;
        }

        const messages = _.get(agentMessages, 'agentMessage.messages')
        const params = _.get(agentMessages, 'params')

        const totalPages = this.getNextPage(params.offset, params.max, messages.length);
        let messageCountStart = params.offset + 1;
        let messageCountEnd = params.offset + messages.length

        if (isEmpty(messages)) {
            return <p className="textEmptyState text-center font-15 color-grey-mid">No Messages</p>;
        }

        return (
            <div>
                <div>
                    {messages.map(this.renderMessageRow)}
                </div>


                {
                    totalPages > 1
                    && (
                        <div className="numberless-pagination">
                            <div className="align-center" id="message-paginate-container">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={<span>...</span>}
                                    breakClassName="break-me"
                                    pageCount={totalPages}
                                    marginPagesDisplayed={2}
                                    disableInitialCallback
                                    pageRangeDisplayed={2}
                                    forcePage={currentPage}
                                    onPageChange={page => this.handleSelect(page, params)}
                                    containerClassName="pagination"
                                    subContainerClassName="pages pagination"
                                    activeClassName="active"
                                />
                                <span
                                    className="margin-left-10 color-grey-dark">{`${messageCountStart} - ${messageCountEnd} of total messages`} </span>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {users: {user, loading}} = state;

    return {
        agentMessages: user.messages,
        loading,
    };
}

export default connect(mapStateToProps)(AgentsMessages);
