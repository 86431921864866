import {roleConstants} from '_constants';


const INITIAL_STATE = {
    roles: [],
    role: {},
    currentRoleId: '',
    loading: true,
    error: '',
};

export function rolesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case roleConstants.GET_ALL_REQUEST:
            return {...state, loading: true};
        case roleConstants.GET_ALL_SUCCESS:
            return {...state, roles: action.roles, loading: false};
        case roleConstants.GET_ALL_FAILURE:
            return {...state, error: action.error, loading: false};

        case roleConstants.GET_DETAILS_REQUEST:
            return {...state, loading: true};
        case roleConstants.GET_DETAILS_SUCCESS:
            return {...state, role: action.payload, loading: false};
        case roleConstants.GET_DETAILS_FAILURE:
            return {...state, error: action.payload, loading: false};

        case roleConstants.SAVE_ROLE_REQUEST:
            return {...state, loading: true};
        case roleConstants.SAVE_ROLE_SUCCESS:
            return {...state, role: action.payload, loading: false};
        case roleConstants.SAVE_ROLE_FAILURE:
            return {...state, error: action.payload, loading: false};


        case roleConstants.DELETE_REQUEST:
            // add 'deleting:true' property to role being deleted
            return {
                ...state,
                items: state.items.map(role =>
                    role.id === action.id
                        ? {...role, deleting: true}
                        : role
                )
            };
        case roleConstants.DELETE_SUCCESS:
            // remove deleted role from state
            return {
                items: state.items.filter(role => role.id !== action.id)
            };
        case roleConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to role
            return {
                ...state,
                items: state.items.map(role => {
                    if (role.id === action.id) {
                        // make copy of role without 'deleting:true' property
                        const {...roleCopy} = role;
                        // return copy of role with 'deleteError:[error]' property
                        return {...roleCopy, deleteError: action.error};
                    }

                    return role;
                })
            };
        case roleConstants.SET_CURRENT_ROLE:
            return {
                ...state, currentRoleId: action.payload
            }
        default:
            return state
    }
}