export class CampaignBuilder {
    constructor() {
        this.campaign = {};
    }

    setId(id){
        this.campaign.id = id;
        return this;
    }

    //Required for clickable table rows
    setParam(param){
        this.campaign.param = param;
        return this;
    }

    setName(name){
        this.campaign.name = name;
        return this;
    }

    setOrganizationName(organizationName){
        this.campaign.organizationName = organizationName;
        return this;
    }

    setChannel(channel){
        this.campaign.channel = channel;
        return this;
    }

    setSegments(segments){
        this.campaign.segments = segments;
        return this;
    }

    setTickets(tickets){
        this.campaign.tickets = tickets;
        return this;
    }

    setConversations(conversations){
        this.campaign.conversations = conversations;
        return this;
    }

    setEngagement(engagement){
        this.campaign.engagement = engagement;
        return this;
    }

    setFollow(follow) {
        this.campaign.follow = follow;
        return this;
    }

    setConversion(conversion){
        this.campaign.conversion = conversion;
        return this;
    }

    setLikesCount(likesCount){
        this.campaign.likesCount = likesCount;
        return this;
    }

    setRevenue(revenue) {
        this.campaign.revenue = revenue;
        return this;
    }

    setCalculatedAt(calculatedAt) {
        this.campaign.calculatedAt = calculatedAt;
        return this;
    }

    setOrganizationId(organizationId){
        this.campaign.organizationId = organizationId;
        return this;
    }

    setIsActive(isActive){
        this.campaign.isActive = isActive;
        return this;
    }

    setDescription(description){
        this.campaign.description = description;
        return this;
    }

    setTemplateId(templateId){
        this.campaign.templateId = templateId;
        return this;
    }

    setStartDate(startDate){
        this.campaign.startDate = startDate;
        return this;
    }

    setEndDate(endDate){
        this.campaign.endDate = endDate;
        return this;
    }

    setIsStartDateEditable(isStartDateEditable) {
        this.campaign.isStartDateEditable = isStartDateEditable;
        return this;
    }

    setIsEndDateEditable(isEndDateEditable) {
        this.campaign.isEndDateEditable = isEndDateEditable;
        return this;
    }

    setActions(actions){
        this.campaign.actions = actions;
        return this;
    }

    setTooltipTitle(tooltipTitle){
        this.campaign.tooltipTitle = tooltipTitle;
        return this;
    }

    setDateFilter(dateFilter) {
        this.campaign.dateFilter = dateFilter
        return this;
    }

    setMsgDataSourceIds(msgDataSourceIds) {
        this.campaign.msgDataSourceIds = msgDataSourceIds
        return this;
    }

    setMediaTypes(mediaTypes) {
        this.campaign.mediaTypes = mediaTypes
        return this;
    }

    setTextSegment(textSegment) {
        this.campaign.textSegment = textSegment
        return this;
    }

    setIsFollowUpEnabled(isFollowUpEnabled) {
        this.campaign.isFollowUpEnabled = isFollowUpEnabled;
        return this;
    }

    setIsPresaleEnabled(isPresaleEnabled) {
        this.campaign.isPresaleEnabled = isPresaleEnabled;
        return this;
    }

    setFollowUpTemplate(followUpTemplate) {
        this.campaign.followUpTemplate = followUpTemplate;
        return this;
    }

    setConversionCountAfterFollowUp(conversionCountAfterFollowUp) {
        this.campaign.conversionCountAfterFollowUp = conversionCountAfterFollowUp;
        return this;
    }

    setRevenueAfterFollowUp(revenueAfterFollowUp) {
        this.campaign.revenueAfterFollowUp = revenueAfterFollowUp;
        return this;
    }

    setTotalReplies(totalReplies){
        this.campaign.totalReplies = totalReplies
    }

    setTotalFollows(totalFollows) {
        this.campaign.totalFollows = totalFollows
    }

    setTotalEngagement(totalEngagement) {
        this.campaign.totalEngagement = totalEngagement
    }

    build(){
        return this.campaign;
    }
}
