import { conversationConstants } from '_constants';

const INITIAL_STATE = {
  conversation: [],
  loading: false,
  error: '',
};

export function originalConversationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case conversationConstants.GET_ORIGINAL_CONVERSATION_THREAD_REQUEST:
      return { ...state, loading: true, error: '', conversation: [] };
    case conversationConstants.GET_ORIGINAL_CONVERSATION_THREAD_SUCCESS:
      return { ...state, conversation: action.payload, loading: false };
    case conversationConstants.GET_ORIGINAL_CONVERSATION_THREAD_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
