import React, {useEffect, useState} from "react";
import {trustpilotOauthActions} from "_actions";
import {CLIENT_URL} from "config";
import {utilityService} from "_services";
import {useDispatch, useSelector} from "react-redux";
import setRedirectUrl from "../../Utilities/setRedirectUrl";

export default function useTrustpilotSetup({ callbackPath }){
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const trustpilotAuth = useSelector((state) => state.trustpilotAuth);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const redirectUrl = `${CLIENT_URL}${callbackPath}`;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const performAction = () => {
        setShouldRedirect(true);
        setRedirectUrl(redirectUrl);
        dispatch(trustpilotOauthActions.initiateOauth(redirectUrl));
    };

    const closeWebhook = () => {
        dispatch(trustpilotOauthActions.clearWebhook());
    };

    useEffect(() => {
        if (!trustpilotAuth.loading && trustpilotAuth.oauthParams?.url && shouldRedirect){
            setShouldRedirect(false);
            utilityService.redirectToAuthUrl(trustpilotAuth.oauthParams.url)
        }
        if (!trustpilotAuth.loading && trustpilotAuth.oauthParamsRequest) {
            dispatch(trustpilotOauthActions.clearParamsRequest());
            setOpen(true)
        }
    }, [trustpilotAuth]);

    return {
        initiateTrustpilotSetup: performAction,
        trustpilotSetupOpen: open,
        handleTrustpilotSetupOpen: handleOpen,
        handleTrustpilotSetupClose: handleClose,
        closeWebhookModal: closeWebhook,
        redirectUrl
    };
}