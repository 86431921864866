import React from 'react'
import {IconButton, Menu, MenuItem, MenuList} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ConversationThread from "../ConversationThread/ConversationThread";
import {utilityActions} from "_actions";
import {ScreenshotModal} from "components/Screenshots/ScreenshotModal";
import {screenshotConstants} from "_constants";
import _ from "lodash";

const ITEM_HEIGHT = 48

export class ToggleMenu extends React.Component {

    state = {
        anchorElement: null,
        showConversation: false,
        screenshotModal: {
            type: "",
            show: false
        }
    }

    openMenu = (event) => {
        this.setState({anchorElement: event.currentTarget});
    };

    showScreenshotModal = (type) => {
        this.setState({screenshotModal: {show: true, type: type}});
        this.closeMenu()
    };

    hideScreenshotModal = (type) => {
        this.setState({screenshotModal: {show: false, type: type}});
    };

    showConversationModal = () => {
        this.setState({showConversation: true})
        this.closeMenu()
    }

    hideConversationModal = () => {
        this.setState({showConversation: false})
    }

    closeMenu = () => {
        this.setState({anchorElement: null});
    };

    render() {
        const {anchorElement, screenshotModal: {show, type}} = this.state
        const {selectedMessage, agent, nickname, positiveFeedbackData} = this.props
        const open = Boolean(anchorElement)
        let mistakeModal;
        const firstName = _.get(agent, 'firstName');
        const lastName = _.get(agent, 'lastName');
        const username = _.get(agent, 'username');
        const id = _.get(agent, 'id');
        const currentPath = utilityActions.getCurrentPath()
        const headerText = type === screenshotConstants.SCREENSHOT_TYPE_MISTAKE
            ? "Constructive Feedback" : "Submit Feedback"


        let dispatchFunction, data, currPath
        let isPositiveFeedback = false
        if (positiveFeedbackData && Object.entries(positiveFeedbackData).length !== 0) {
            dispatchFunction = positiveFeedbackData.dispatchFunction
            data = positiveFeedbackData.data
            currPath = positiveFeedbackData.currPath
            isPositiveFeedback = true
        }


        if (show) {
            mistakeModal = <ScreenshotModal
                agentInfo={
                    {
                        agentNickname: nickname,
                        email: username,
                        name: `${firstName ? firstName : lastName}`,
                        id: id
                    }
                }
                onHide={this.hideScreenshotModal}
                show={show}
                headerText={headerText}
                closeModalHandler={this.closeModalHandler}
                messageId={selectedMessage.id}
                currentPath={currentPath}
                screenshotType={type}
            />

        }

        return (
            <div>
                <IconButton
                    aria-label="More"
                    aria-owns={open ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.openMenu}
                    // style={{marginRight: "100%"}}
                >
                    <MoreVertIcon size="small"/>
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorElement}
                    open={open}
                    onClose={this.closeMenu}
                    className="message-details-menu"
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            minWidth: 150,
                            border: "solid 1px #dee2e0",
                            boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)",
                        }
                    }}

                >
                    <MenuList style={{outline:'none'}}>
                        <MenuItem className="update-menu-item">
                            <div className="expert-view-conversation-link">
                                {
                                    selectedMessage &&
                                    <
                                        ConversationThread
                                        message={selectedMessage}
                                        style={{padding: 0, outline: 'none', marginLeft: '33%'}}
                                    />
                                }
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.closeMenu} className="update-menu-item">
                            <button type="button" className="btn btn-default btn-block"
                                    onClick={() => this.showScreenshotModal(screenshotConstants.SCREENSHOT_TYPE_MISTAKE)}>
                                     REPORT MISTAKE
                            </button>
                        </MenuItem>
                        {isPositiveFeedback &&
                        <MenuItem className="update-menu-item">
                            <button type="button" className="btn btn-default btn-block"
                                    onClick={
                                        (e) =>
                                            dispatchFunction(data, currPath)
                                    }>
                                <span className="glyphicon glyphicon-camera"/> POSITIVE FEEDBACK
                            </button>
                        </MenuItem>
                        }
                    </MenuList>
                </Menu>
                {mistakeModal}
            </div>
        )
    }
}
