import React from 'react';
import { Box } from '@mui/material';
import importedStyles from './styles';

/**
 * @param {object} params
 * @param {React.ReactNode} params.children
 * @param {'primary' | 'secondary'} params.type - (optional, defaults to `primary`)
 * @param {boolean} params.fullWidth - (optional, defaults to `false`)
 */
export default function Badge({
	children,
	type = 'primary',
	fullWidth = false,
}) {
	return (
		<Box sx={{ 
			...importedStyles, 
			...styles.root, 
			...(type === 'primary' ? styles.rootPrimary : {}), 
			...(type === 'secondary' ? styles.rootSecondary : {}),
			...(fullWidth ? styles.rootFullWidth : {}),
		}}
		>
			{children}
		</Box>
	)
}

const styles = {
	root: {
		fontFamily: 'LarsseitMedium',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#0C2728',
	},
	rootPrimary: {
		px: '4px',
		fontSize: '14px',
		height: '24px',
		backgroundColor: '#EFEAE7',
		borderRadius: '2px',
	},
	rootSecondary: {
		fontSize: '12px',
		px: '12px',
		height: '20px',
		backgroundColor: '#DBD8FF',
		borderRadius: '10px',
	},
	rootFullWidth: {
		width: '100%',
		display: 'flex',
	}
}