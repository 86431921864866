import {getActionData} from "_helpers";
import {alertMessages, organizationSettingsConstants, scheduleConstants} from "_constants";
import {organizationSettingsService, scheduleServices} from "_services";
import {SnackBar} from "../components/common";
import {Auth} from "../components/Authorization";
import _ from "lodash";

function getOrganizationInfo(id) {
    return dispatch => {
        dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_INFO_REQUEST));
        organizationSettingsService.getOrganizationInfo(id)
            .then((response) => {
                dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_INFO_SUCCESS, response.data));
            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_INFO_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function deleteSchedule(params) {
    return dispatch => {
        dispatch(getActionData(scheduleConstants.DELETE_REQUEST));
        organizationSettingsService.deleteSchedule(params)
            .then((response) => {
                dispatch(getActionData(scheduleConstants.DELETE_SUCCESS));
            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(scheduleConstants.DELETE_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getOrganizationCSMs(id) {
    return dispatch => {
        dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_CSM_LIST_REQUEST));
        organizationSettingsService.getOrganizationCSMs(id)
            .then((response) => {
                dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_CSM_LIST_SUCCESS, response.data));
            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_CSM_LIST_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function updateOrganizationAttribute(organizationId, payload, actionType = null) {
    return dispatch => {
        dispatch(getActionData(organizationSettingsConstants.UPDATE_ORGANIZATION_ATTRIBUTE_REQUEST, {actionType, payload}));
        organizationSettingsService.updateOrganizationAttribute(organizationId, payload)
            .then((response) => {
                dispatch(getActionData(organizationSettingsConstants.UPDATE_ORGANIZATION_ATTRIBUTE_SUCCESS, response.data));
                SnackBar.showMessage(alertMessages.ORGANIZATION_ATTRIBUTE_UPDATE_SUCCESS);
            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(organizationSettingsConstants.UPDATE_ORGANIZATION_ATTRIBUTE_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.ORGANIZATION_ATTRIBUTE_UPDATE_FAILURE);
        });
    }
}

function updateTicketBudget(organizationId, payload) {
    return dispatch => {
        dispatch(getActionData(organizationSettingsConstants.UPDATE_TICKET_BUDGET_REQUEST, payload));
        organizationSettingsService.updateTicketBudget(organizationId, payload)
            .then((response) => {
                dispatch(getActionData(organizationSettingsConstants.UPDATE_TICKET_BUDGET_SUCCESS, response.data));
                SnackBar.showMessage(alertMessages.TICKET_BUDGET_UPDATE_SUCCESS);
            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(organizationSettingsConstants.UPDATE_TICKET_BUDGET_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.TICKET_BUDGET_UPDATE_FAILURE);
        });
    }
}

function dataSourceHandleInfoResponseWithOrganizationId(responseData, organizationId) {
    const itemsWithOrganizationId = responseData.items.map((item) => _.merge(item, { organizationId }));
    return _.merge(responseData, { items: itemsWithOrganizationId });
}

function getDataSourceHandleInfo(organizationId, requestType) {
    return dispatch => {
        dispatch(getActionData(requestType));
        organizationSettingsService.getDataSourceHandleInfo(organizationId)
            .then((response) => {
                const data = dataSourceHandleInfoResponseWithOrganizationId(response.data, organizationId);
                dispatch(getActionData(organizationSettingsConstants.GET_DATA_SOURCE_HANDLE_INFO_SUCCESS, data));
            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(organizationSettingsConstants.GET_DATA_SOURCE_HANDLE_INFO_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    };
}

function fetchDataSourceHandleInfo(organizationId) {
    return getDataSourceHandleInfo(organizationId, organizationSettingsConstants.GET_DATA_SOURCE_HANDLE_INFO_FETCH_REQUEST);
}

function getAutoActionsInfo(organizationId, requestType) {
    return dispatch => {
        dispatch(getActionData(requestType));
        organizationSettingsService.getAutoActionsInfo(organizationId)
            .then((response) => {
                const data = dataSourceHandleInfoResponseWithOrganizationId(response.data, organizationId);
                dispatch(getActionData(organizationSettingsConstants.GET_AUTO_ACTIONS_INFO_SUCCESS, data));
            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(organizationSettingsConstants.GET_AUTO_ACTIONS_INFO_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    };
}

function fetchAutoActionsInfo(organizationId) {
    return getAutoActionsInfo(organizationId, organizationSettingsConstants.GET_AUTO_ACTIONS_INFO_FETCH_REQUEST);
}


function refreshAutoActionsInfo(organizationId) {
    return getAutoActionsInfo(organizationId, organizationSettingsConstants.GET_AUTO_ACTIONS_INFO_REFRESH_REQUEST);
}

function refreshDataSourceHandleInfo(organizationId) {
    return getDataSourceHandleInfo(organizationId, organizationSettingsConstants.GET_DATA_SOURCE_HANDLE_INFO_REFRESH_REQUEST);
}

function fetchOrganizationRemovalProcesses(organizationId) {
    return dispatch => {
        dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_REMOVAL_PROCESSES_REQUEST));
        organizationSettingsService.getOrganizationRemovalProcesses(organizationId)
            .then((response) => {
                dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_REMOVAL_PROCESSES_SUCCESS, response.data));
            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_REMOVAL_PROCESSES_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function initiateRemovalProcess(params) {
    return dispatch => {
        dispatch(getActionData(organizationSettingsConstants.INITIATE_ORGANIZATION_REMOVAL_PROCESS_REQUEST));
        return organizationSettingsService.initiateRemovalProcess(params)
            .then(() => {
                dispatch(getActionData(organizationSettingsConstants.INITIATE_ORGANIZATION_REMOVAL_PROCESS_SUCCESS));
                SnackBar.showMessage(alertMessages.INITIATE_ORGANIZATION_REMOVAL_PROCESS_SUCCESS);
            }).catch((error) => {
                if (_.get(error, 'response.status') === 401) {
                    Auth.processLogout();
                    return;
                }
                dispatch(getActionData(organizationSettingsConstants.GET_ORGANIZATION_REMOVAL_PROCESSES_FAILURE, {}, error));
                SnackBar.showMessage(alertMessages.INITIATE_ORGANIZATION_REMOVAL_PROCESS_FAILURE);
            });
    }
}

export const organizationSettingsActions = {
    getOrganizationInfo,
    fetchDataSourceHandleInfo,
    fetchAutoActionsInfo,
    refreshDataSourceHandleInfo,
    refreshAutoActionsInfo,
    getOrganizationCSMs,
    updateOrganizationAttribute,
    updateTicketBudget,
    fetchOrganizationRemovalProcesses,
    initiateRemovalProcess,
    deleteSchedule
}
