import React, { Fragment } from 'react'
import {Box, Button, CircularProgress, Grid, Stack} from '@mui/material';
import importedStyles from '../Utilities/styles'
import DashboardCard from '../Utilities/DashboardCard';
import DataTeaser from '../Utilities/DataTeaser';
import usePerformanceMetrics from '../Utilities/hooks/usePerformanceMetrics';
import useQualityScoreMetrics from '../Utilities/hooks/useQualityScoreMetrics';
import {formatDuration} from "_helpers";
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

const InfoModalContent = () => (
	<Box component={'dl'} sx={importedStyles.definitionList}>
		<Fragment>
			<Box component={'dt'} sx={importedStyles.definitionTitle}>
				Responses in less than 90 minutes
			</Box>
			<Box component={'dd'} sx={importedStyles.definitionText}>
				We calculate the median first response time across all messages. Our industry benchmarking data is from TimeToReply.com.
			</Box>
		</Fragment>
		<Fragment>
			<Box component={'dt'} sx={importedStyles.definitionTitle}>
				Customer questions solved
			</Box>
			<Box component={'dd'} sx={importedStyles.definitionText}>
				Total number of customer questions where your Chatdesk team replied to the customer. This excludes customer questions that did not need a response.
			</Box>
		</Fragment>
		<Fragment>
			<Box component={'dt'} sx={importedStyles.definitionTitle}>
				CSAT
			</Box>
			<Box component={'dd'} sx={importedStyles.definitionText}>
				The customer satisfaction score comes from your help desk.
			</Box>
		</Fragment>
	</Box>
)

export default function PerformanceSection({ startDate, endDate }) {
	const performanceMetrics = usePerformanceMetrics(startDate, endDate);
	const qualityScoreMetrics = useQualityScoreMetrics(startDate, endDate);
	const duration = performanceMetrics.data ? formatDuration(performanceMetrics.data.answerRateCompareToAvg) : '-';
	
	return (
		<DashboardCard
			isLoading={performanceMetrics.loading}
			headerTitle='Performance metrics'
			infoModalTitle='Conversion metrics calculations'
			infoModalChildren={<InfoModalContent />}>

			{
				!performanceMetrics.loading &&
				<Grid container columns={4} spacing={3} sx={styles.root}>
					<Grid item xs={4} md={1}>
						<DataTeaser
							stackStyle={styles.dataTeaser}
							label={'Responses less than 90 minutes'}
							entry={{ value: performanceMetrics.data ? performanceMetrics.data.responsesLessThan90Minutes : null, formatStyle: 'percent' }}
							descriptionChildren={<>
								<Box>First response time is { duration || '0 m' }.</Box>
								<Box>Industry average is 17 h.</Box>
							</>}
						/>
						<Button sx={styles.viewDetailsBtn}
							component={RouterLink}
							to={{ pathname: `/metrics-details`,
									search: `?startDate=${startDate}&endDate=${endDate}&pageFilter=responseTime`
							}}
							variant="text">
								View details
						</Button>
					</Grid>
					<Grid item xs={4} md={1}>
						<DataTeaser
							stackStyle={styles.dataTeaser}
							label={'Customer questions solved'}
							entry={{ value: performanceMetrics.data ? performanceMetrics.data.answerRate : null, formatStyle: 'percent' }}
							descriptionChildren={<>
								<Box>
									Total amount of tickets answered
								</Box>
							</>}
						/>
						<Button sx={styles.viewDetailsBtn}
								component={RouterLink}
								to={{ pathname: `/metrics-details`,
									search: `?startDate=${startDate}&endDate=${endDate}&pageFilter=answerRate`
								}}
								variant="text">
							View details
						</Button>
					</Grid>
					{
					    qualityScoreMetrics.loading ? (
                            <Grid item xs={4} md={1}>
                                <Stack sx={styles.dataTeaser}>
                                    <Box component={'h4'} sx={styles.label}>Customer Satisfaction</Box>
                                    <Box sx={styles.bodyLoading}>
                                        <CircularProgress sx={styles.bodyLoadingSpinner} />
                                    </Box>
                                </Stack>
                        	</Grid>
                        ) : (
                            _.get(qualityScoreMetrics.data, 'csat') >= 0 && <Grid item xs={4} md={1}>
                                <DataTeaser
                                    stackStyle={styles.dataTeaser}
                                    label={'Customer Satisfaction'}
                                    entry={{ value: qualityScoreMetrics.data ? qualityScoreMetrics.data.csat : null, formatStyle: 'percent' }}
                                    // @TODO: Enable section when feature is ready
                                    // descriptionChildren={<>
                                    // 	<Box>Any CSAT score above 80% is good. Keep it up!</Box>
                                    // </>}
                                />
                                <Button sx={styles.viewDetailsBtn}
                                        component={RouterLink}
                                        to={{ pathname: `/metrics-details`,
                                            search: `?startDate=${startDate}&endDate=${endDate}&pageFilter=csat`
                                        }}
                                        variant="text">
                                    View details
                                </Button>
                            </Grid>
                        )
					}
					<Grid item xs={4} md={1}>
						<DataTeaser
							stackStyle={styles.dataTeaser}
							label={'Pre-Sale Questions'}
							entry={{ value: performanceMetrics.data ? performanceMetrics.data.preSaleQuestions : null, formatStyle: 'percent' }}
							descriptionChildren={<>
								<Box>Customer questions about pricing, product availability, etc</Box>
							</>}
						/>
						<Button sx={styles.viewDetailsBtn}
								component={RouterLink}
								to={{ pathname: `/metrics-details`,
									search: `?startDate=${startDate}&endDate=${endDate}&pageFilter=presale`
								}}
								variant="text">
							View details
						</Button>
					</Grid>
				</Grid>
			}

		</DashboardCard>
	)
}

const styles = {
	root: {
		py: { xs: 2, md: 4 },
	},
	viewDetailsBtn: {
		color: '#07857C',
		textTransform: 'capitalize',
		padding: '0px !important',
		height: '25%',
		margin:'0 auto',
		position: 'absolute',
		bottom: '20px',
		':hover': {
			backgroundColor: 'unset',
			color: '#07857C',
		}
	},
	dataTeaser: {
		marginBottom: '35px'
	},
	bodyLoading: {
        py: 4,
        display: 'flex',
        justifyContent: 'center',
    },
	bodyLoadingSpinner: {
		color: '#143F42',
	},
	label: {
        m: 0,
        fontSize: '14px',
        fontFamily: 'LarsseitBold',
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
	},
}