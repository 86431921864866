import {autoActionConstants} from '_constants';

const INITIAL_STATE = {
    autoAction: {},
    loadingAutoAction: true,
    autoActionError: '',

    createAutoAction: {},
    loadingCreateAutoAction: false,
    createAutoActionError: '',
};

export function autoActionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case autoActionConstants.GET_AUTO_ACTION_REQUEST:
            return {...state, loadingAutoAction: true, autoActionError: ''};
        case autoActionConstants.GET_AUTO_ACTION_SUCCESS:
            return {...state, autoAction: action.payload, loadingAutoAction: false};
        case autoActionConstants.GET_AUTO_ACTION_FAILURE:
            return {...state, autoActionError: action.error, loadingAutoAction: false};

        case autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_REQUEST:
            return {...state, loadingCreateAutoAction: true, createAutoActionError: ''};
        case autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_SUCCESS:
            return {...state, createAutoAction: action.payload, loadingCreateAutoAction: false};
        case autoActionConstants.CREATE_OR_UPDATE_AUTO_ACTION_FAILURE:
            return {...state, createAutoActionError: action.error, loadingCreateAutoAction: false};

        default:
            return state
    }
}
