import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {earningsActions} from "_actions";
import _ from "lodash";
import {EarningsHistoryBuilder} from "../objects/EarningsHistoryBuilder"
import {formatInitiatedDate} from "../EarningUtils";

export default function (params, agentId) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(earningsActions.getEarningsHistory(params, agentId))
    },[])

    const earnings = useSelector(state => state.earnings);
    const loading = earnings.loadingEarningsHistory;
    const earningsObj = earnings.earningsHistory;
    const resourceList = _.get(earningsObj, 'earnings') || [];
    const totalCount = _.get(earningsObj, 'totalCount') || 0;
    const rows = []

    if(!loading && resourceList.length > 0) {

        resourceList.map( earnings => {
            const builder = new EarningsHistoryBuilder();

            builder.setDate(formatInitiatedDate(_.get(earnings, 'dateCreated')) || '-');
            builder.setCompany(_.get(earnings, 'organization') || '-');
            builder.setChannel(_.get(earnings, 'handleName') || '-');
            builder.setMessageId(_.get(earnings, 'messageId') || '-');
            builder.setAmountEarned(`${_.get(earnings, 'currencySymbol')}${_.get(earnings, 'amountPaid')}` || '-');

            rows.push(builder.earningsHistory)
        })
    }

    const columns = [
        {
            id: 'date',
            label: 'Date',
            width: 140
        },
        {
            id: 'company',
            label: 'Company',
            width: 140
        },
        {
            id: 'channel',
            label: 'Channel',
            width: 140
        },
        {
            id: 'messageId',
            label: 'Message ID',
            width: 140,
        },
        {
            id: 'amountEarned',
            label: 'Amount Earned',
            width: 120,
        },
    ];

    return { columns, rows, totalCount, loading };
}