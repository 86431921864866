const styles = {
    inputFieldContainer: {
        resetPasswordEmail: {
            margin: "0px auto",
            height: "58px"
        },
        button: {
            width: "83%",
            margin: "5px auto"
        },
        registerLink: {
            width: "83%",
            margin: "15px auto"
        },

        emailFieldContainer: {
            margin: "0px auto",
            height: "66px"
        },
        passwordFieldContainer: {
            margin: "0px auto",
            height: "58px"
        },

        signUpSection: {
            borderTop:"1px solid #dee2e0",
            width:"83%",
            margin:"0 auto",
            textAlign:"center",
            paddingTop: "25px"
        },

        newPasswordContainer: {
            margin: "0px auto",
            height: "66px"
        },

        confirmPasswordContainer: {
            margin: "0px auto",
            height: "58px"
        }
    }
};

export {styles}