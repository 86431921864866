import React, {useEffect} from "react";
import {zendeskOauthActions} from "_actions";
import {useDispatch} from "react-redux";

export default function useZendeskChatSetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const performAction = () => {
    handleOpen();
  };

  useEffect(() => {
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const zendeskAuthCode = values.get('code');
    const partner = values.get('partner');
    const channel = values.get('channel');

    if(!partner && zendeskAuthCode && channel == 'ZENDESK_CHAT') {
      const params = {code: zendeskAuthCode, type: 'CHAT'};
      submitToken(params);
    }
  }, []);

  const submitToken = (params) => {
    dispatch(zendeskOauthActions.generateAccessToken(params, '/company/channels', '/company/connectchannels'));
  };

	return {
		initiateZendeskChatSetup: performAction,
		zendeskChatSetupOpen: open,
		handleZendeskChatSetupOpen: handleOpen,
		handleZendeskChatSetupClose: handleClose
	};
}