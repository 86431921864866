import {SERVER_URL} from "../config";
import axios from "axios";
import {authHeader} from "_helpers";

function getOrganizationInfo(id) {
    const endpoint = `${SERVER_URL}/api/v1/organizations/${id}/settings/info`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function getOrganizationCSMs(organizationId) {
    const endpoint = `${SERVER_URL}/api/organizations/${organizationId}/csms`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function updateOrganizationAttribute(organizationId, payload) {
    const endpoint = `${SERVER_URL}/api/v1/organizations/${organizationId}/settings/update`;
    return axios.put(endpoint, payload, {
        headers: authHeader()
    });
}

function updateTicketBudget(organizationId, payload) {
    const endpoint = `${SERVER_URL}/api/v1/organizations/${organizationId}/settings/ticket-budget`;
    return axios.put(endpoint, payload, {
        headers: authHeader()
    });
}

function getDataSourceHandleInfo(organizationId) {
    const endpoint = `${SERVER_URL}/api/organizations/${organizationId}/data-source-handles`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function getAutoActionsInfo(organizationId) {
    const endpoint = `${SERVER_URL}/api/organizations/${organizationId}/auto-actions`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function getOrganizationRemovalProcesses(organizationId) {
    const endpoint = `${SERVER_URL}/api/organizations/${organizationId}/removal-processes`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function initiateRemovalProcess(params) {
    const endpoint = `${SERVER_URL}/api/organizations/removal-processes`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

const deleteSchedule = (params) => {
    const endpoint = `${SERVER_URL}/api/organizations/${params.orgId}/schedules/${params.id}`;
    return axios.delete(endpoint, {
        headers: authHeader()
    });
};

export const organizationSettingsService = {
    getOrganizationInfo,
    getDataSourceHandleInfo,
    getAutoActionsInfo,
    getOrganizationCSMs,
    updateOrganizationAttribute,
    updateTicketBudget,
    getOrganizationRemovalProcesses,
    initiateRemovalProcess,
    deleteSchedule
}
