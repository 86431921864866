import React, {useState} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {
    formattedResponseText, formatText,
    getCurrentAgentName, getCurrentEmployment,
    getEditorConfig,
    getObjectProperty,
    isObjectEmpty, parseHtmlWithImage,
    setObjectProperty
} from "_helpers";
import ResponseEditor from "./ResponseEditor";
import {dispatcherConstants, handleType, msgSource, responseStatus, workMode} from "_constants";
import {dispatcherActions, voteActions} from "_actions";
import ResponseActions from "./ResponseActions";
import useViewHtmlBody from "../../../Utilities/hooks/useViewHtmlBody";
import useViewNextMessage from "../../../Utilities/hooks/useViewNextMessage";
import {
    buildSubmitAndHidePayload,
    buildSubmitPerfectResponse,
    buildSubmitResponsePayload
} from "../../../Utilities/VoteUtils";
import useViewSubmitVote from "../../../Utilities/hooks/useViewSubmitVote";
import MessageSubject from "../../../../../common/Message/MessageSubject";
import {CrossDispatchWarningModal} from "../../../../Messages/CrossDispatchWarningModal";


export default function ResponseItem({
    response,
    index,
    count,
    isScrollComplete,
    isResponseSelected,
    isEditing,
    handleOpenEditResponse,
    selectedResponseId,
    editedResponseObj,
    handleCloseEditResponse,
    editedSubjectObj
}) {
    const dispatch = useDispatch();
    const vote = useSelector(state => state.vote);
    const [attachments, setAttachments] = useState([]);
    const [crossDispatchWarningModal, setCrossDispatchWarningModal] = useState(false);
    const [crossDispatchWarningDismissed, setCrossDispatchWarningDismissed] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const { message } = useViewNextMessage();
    const {htmlBody, loadingHtmlBody} = useViewHtmlBody();
    const {submitVoteInit, submitVoteLoading} = useViewSubmitVote();
    const { dataSourceHandle, author, sourceName, msgDataSource, canBeHidden } = message;
    const {id, responseMessageId, text, addedById, subject} = response;
    const agentName = getCurrentAgentName();
    const messageHandleType = dataSourceHandle.type;
    const responseText = formattedResponseText(text, author, agentName, messageHandleType);
    const isMacro = _.get(msgDataSource, 'isPlatformMacroEnabled');
    const {isCharDisplay, charLength} = getEditorConfig(sourceName);
    const isEmail = messageHandleType === _.get(handleType, 'email');
    let isSelected = isResponseSelected && selectedResponseId === id;
    const isEditSelected = isEditing && selectedResponseId === id;
    const isEditingText = !isObjectEmpty(getObjectProperty(editedResponseObj, id));
    const responseLabel = isMacro ? response.title : `Response ${index + 1} of ${count}`;
    const includedResponseId = Object.keys(editedResponseObj || {}).includes(id);
    const currentEmployment = getCurrentEmployment();
    const isLiveAgent = _.get(currentEmployment, 'mode') === workMode.ACTIVE;
    let status;
    let openEditor = false;
    let editedResponse = '';
    const hasSubject = !_.isEmpty(subject);
    let closeTicket = false;

    if((isEmail && isEditingText) || !isEmail) {
        openEditor = isEditSelected;
    }
    else if(isEmail) {
        openEditor = isEditSelected && !loadingHtmlBody && !_.isEmpty(htmlBody);
    }

    if(!_.isEmpty(addedById) && !includedResponseId) {
        status = responseStatus.JUST_ADDED;
    }
    else if(includedResponseId) {
        status = responseStatus.EDITED;
    }

    const resetCrossDispatchWarningModal = () => {
        setCrossDispatchWarningModal(true);
    }

    const closeCrossDispatchWarningModal = () => {
        setCrossDispatchWarningModal(false);
        setCrossDispatchWarningDismissed(true);
    }

    const clearCrossDispatchWarningDismissed = () => {
        setCrossDispatchWarningDismissed(false);
    }

    const isPossibleCrossDispatchDetected = () => {
        return !vote?.loading && vote?.error?.error?.errorCode === dispatcherConstants.POSSIBLE_CROSS_DISPATCH_ERROR_CODE;
    }

    /**
     * Get the html body for a particular message using the response Id.
     * This triggers an event to the server to make a request to the external source of a message
     * to retrieve the html body
     * @param responseId
     */
    const getHtmlBody = (responseId) => {
        dispatch(dispatcherActions.getHtmlBody(responseId));
    };

    const handleCloseEditor = () => {
        handleCloseEditResponse();
    }

    const handleEditedResponse = (editorText) => {
        editedResponse = editorText;
    }

    const handleSubmitResponse = () => {
        const subjectObj = getSubject();
        editedResponse = editedResponse || _.get(getObjectProperty(editedResponseObj, id), 'text');
        const payload = buildSubmitResponsePayload(message, response, editedResponse, attachments, subjectObj, closeTicket, crossDispatchWarningDismissed, selectedTags);
        dispatch(voteActions.submitResponse(payload)).then(() => resetCrossDispatchWarningModal());
    }

    const handleSubmitPerfectResponse = () => {
        const subjectObj = getSubject();
        const payload = buildSubmitPerfectResponse(message, response, subjectObj, closeTicket, crossDispatchWarningDismissed);
        dispatch(voteActions.submitResponse(payload)).then(() => resetCrossDispatchWarningModal());
    }

    const handleSubmitAndHide = () => {
        const payload = buildSubmitAndHidePayload(message);
        dispatch(voteActions.submitAndHide(message.id, payload));
    }

    const handleSubjectChange = (responseId, subject) => {
        setObjectProperty(editedSubjectObj, responseId, { subject: subject });
    }

    const getSubject = () => {
        return {subject, editedSubject: _.get(getObjectProperty(editedSubjectObj, id), 'subject')};
    }

    const handleOpenEditor = (responseId, isEditingText, msgDataSource, responseOption) => {
        dispatch(dispatcherActions.clearHtmlBody());
        if (isMacro) {
            if (isObjectEmpty(getObjectProperty(editedResponseObj, _.get(responseOption, 'id')))) {
                let text = _.get(responseOption, 'htmlBody') || _.get(responseOption, 'text');
                text = formatText(text, sourceName, isMacro);
                setObjectProperty(editedResponseObj, _.get(responseOption, 'id'), { text: text });
            }
        }
        else if(!isEditingText && messageHandleType === _.get(handleType, 'email')) getHtmlBody(responseId);
        handleOpenEditResponse(responseId);
    }

    const handleCloseTicket = (props) => {
        closeTicket = props.value
    }

    const handleTagSelection = (tags) => {
        setSelectedTags(tags);
    };

    const handleRemoveTag = (tagName) => {
        setSelectedTags(() =>
            selectedTags.filter((selectedTag) => _.get(selectedTag, 'name') !== tagName)
        );
    };

    const Content = () => {
        if(openEditor) {
            return(
                <ResponseEditor
                    responseId={id}
                    text={responseText}
                    messageHandleType={messageHandleType}
                    sourceName={sourceName}
                    editedResponseObj={editedResponseObj}
                    isEmail={isEmail}
                    charLength={charLength}
                    isCharDisplay={isCharDisplay}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    handleEditedResponse={handleEditedResponse}
                    isMacro={isMacro}
                    selectedTags={selectedTags}
                    handleRemoveTag={handleRemoveTag}
                />
            );
        }
        return <Box>{ parseHtmlWithImage(text) }</Box>;
        {/*@TODO://Add `show more` component*/}
    }

    return(
        <Box key={index} component={'li'} sx={{...style.responseItem, ... openEditor && style.responseSelected}}>
            <Box sx={{marginBottom: '7px',}}>
                <Box sx={style.responseDescContainer}>
                    <Box sx={style.responseLabel}>{responseLabel}</Box>
                    {status && <Box sx={style.responseStatus}>{status}</Box>}
                </Box>
                { hasSubject &&
                    <MessageSubject
                        subjectParentId={id}
                        subject={subject}
                        handleSubjectChange={handleSubjectChange}
                        editedSubjectObj={editedSubjectObj}
                        isEnabled={isScrollComplete}
                    />
                }
            </Box>

            <Box sx={style.responseText}>
                <Content />
            </Box>
            <Box sx={{marginTop: '10px'}}>
                <ResponseActions
                    responseId={id}
                    isEditSelected={isEditSelected}
                    loadingHtmlBody={loadingHtmlBody}
                    isScrollComplete={isScrollComplete}
                    handleCloseEditor={handleCloseEditor}
                    handleOpenEditor={handleOpenEditor}
                    isEditingText={isEditingText}
                    msgDataSource={msgDataSource}
                    canBeHidden={canBeHidden}
                    response={response}
                    openEditor={openEditor}
                    handleSubmitResponse={handleSubmitResponse}
                    handleSubmitAndHide={handleSubmitAndHide}
                    submitVoteLoading={submitVoteLoading}
                    isLiveAgent={isLiveAgent}
                    handleSubmitPerfectResponse={handleSubmitPerfectResponse}
                    sourceName={sourceName}
                    handleCloseTicket={handleCloseTicket}
                    handleTagSelection={handleTagSelection}
                    selectedTags={selectedTags}
                />
            </Box>
            <CrossDispatchWarningModal
                open={crossDispatchWarningModal && isPossibleCrossDispatchDetected()}
                handleClose={closeCrossDispatchWarningModal}
            />
        </Box>
    )
}

const style = {
    responseItem: {
        padding: '8px 10px 10px 10px',
        backgroundColor: '#F3F5F6',
        borderStyle: 'solid',
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: '#DCE2E3',
        '&:first-of-type': {borderTopLeftRadius: '6px', borderTopRightRadius: '6px', borderTopWidth: '1px'},
        '&:last-of-type': {borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px'},
    },
    responseSelected: {
        backgroundColor: '#FFFFFF'
    },
    responseLabel: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        color: '#495D5E',
        py: '0px'
    },
    responseStatus: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        backgroundColor: '#DBD8FF',
        color: '#0C2728',
        borderRadius: '3px',
        p: '3px 10px'
    },
    responseText: {
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#0C2728',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    responseDescContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1px',
    }
}
