import {Box} from "@mui/system";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";

export default function CompanyInfoHeaderEmptyState() {
    return(
        <Box sx={{display: {xs: 'block', md: 'flex'}}}>
            <Box sx={{mr: '15px', mb: {xs: '10px', md: 0}}}>
                <Box sx={{width: '160px', height: '160px'}}>
                    <LoadingPlaceholderBlock
                        height={'160px'}
                        borderRadius={'10px'}
                        backgroundColor={'#d7d2cf'}
                    />
                </Box>
            </Box>
            <Box sx={{mt: '8px'}}>
                <Box sx={style.companyName}>
                    <Box sx={{width: '110px'}}>
                        <LoadingPlaceholderBlock
                            height={'18px'}
                            borderRadius={'5px'}
                            backgroundColor={'#d7d2cf'}
                        />
                    </Box>
                </Box>
                <Box sx={style.contactEmailDesc}>
                    <Box sx={{width: '95px',}}>
                        <LoadingPlaceholderBlock
                            height={'14px'}
                            borderRadius={'5px'}
                            backgroundColor={'#d7d2cf'}
                        />
                    </Box>
                </Box>
                <Box sx={style.email}>
                    <Box sx={{width: '50px', mt: '10px'}}>
                        <LoadingPlaceholderBlock
                            height={'14px'}
                            borderRadius={'5px'}
                            backgroundColor={'#d7d2cf'}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const style = {
    companyName: {
        width: '24px',
        fontFamily: 'larsseitMedium',
        whiteSpace: 'pre',
    },
    contactEmailDesc: {
        width: {xs: '14px', md: '16px'},
        fontFamily: 'larsseitMedium',
        whiteSpace: 'pre',
        mt: {xs: '10px', md: '27px'}
    },
    email: {
        width: '16px',
    },
}