import {billingConstants} from "../_constants/billing.constants";


const INITIAL_STATE = {
    url: '',
    error: ''
};

export function billingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case billingConstants.GET_BILLING_SETTINGS_REQUEST:
            return { ...state };
        case billingConstants.GET_BILLING_SETTINGS_SUCCESS:
            return { ...state, url: action.url };
        case billingConstants.GET_BILLING_SETTINGS_FAILURE:
            return { ...state, error: action.error };
        default:
            return state
    }
}
