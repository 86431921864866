import { oauthConstants, msgDataSourceConstants } from '_constants';

const INITIAL_STATE = {
    data: [],
    error: '',
    loading: true,
    status: '',
    channel: 'kustomer',
    showWebhookUrl: false,
    webhookUrl: '',
    list: []
};

export function kustomerOauthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.KUSTOMER_VERIFY_KEY_REQUEST:
            return { ...state, loading: true };
        case oauthConstants.KUSTOMER_VERIFY_KEY_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case oauthConstants.KUSTOMER_VERIFY_KEY_FAILURE:
            return { ...state, error: action.error, loading: false };

        case oauthConstants.KUSTOMER_CREATE_ACCOUNT_REQUEST:
            return { ...state, loading: true };
        case oauthConstants.KUSTOMER_CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                showWebhookUrl: true,
                data: action.payload,
                loading: false,
                webhookUrl: action.payload.webhookUrl
            };
        case oauthConstants.KUSTOMER_CREATE_ACCOUNT_FAILURE:
            return {...state, error:action.error, loading: false };
        case msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS:
            return {
                ...state,
                list: action.payload ? action.payload.filter(item => item.dataSourceType === 'kustomer') : [],
                loading: false
            };
        case msgDataSourceConstants.TICKET_STATUS_UPDATE_REQUEST:
          return {...state, loading: true};
        case msgDataSourceConstants.TICKET_STATUS_UPDATE_SUCCESS:
          return {
            ...state,
            list: state.list.map(item => {
              if (item.id === action.payload.dataSourceId) {
                item.ticketStatusOnReply = action.payload.currentStatus
              }
              return item
            }),
            loading: false
          };
        case msgDataSourceConstants.TICKET_STATUS_UPDATE_FAILURE:
          return {...state, error: action.error, loading: false};
        case msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_SUCCESS:
            let dataSources = state.list.map(item => {
                if (item.id === action.payload.payload.dataSourceId) {
                    item.orgSupportEmail = action.payload.payload.data.orgSupportEmail
                }
                return item
            });
            return { ...state, list: dataSources, loading: false };
        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES:
            return {...state, loading: true};
        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_SUCCESS:
            return {
                ...state,
                list: state.list.map(item => {
                    if (item.id === action.payload.msgDataSourceId) {
                        item[action.payload.updateField] = action.payload.enable
                    }
                    return item
                }),
                loading: false
            };
        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_ERROR:
            return {...state, error: action.error, loading: false};
        case oauthConstants.CLOSE_WEBHOOK_MODAL:
            return {...state, showWebhookUrl: false};
        default:
            return state;
    }
}