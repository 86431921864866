import React from 'react';
import { Form } from 'react-bootstrap';
import { DropDownSelect } from 'components/common/formFields/DropdownField'

const OrganizationSubscribeFilterForm = (props) => {

    const { input, organization, organizations } = props;

    return (
        <Form>
            <span className="font-14 color-grey-dark">Organization</span>
            <div style={{marginTop:"3px", display:"flex"}}>
                <DropDownSelect
                    name="organization"
                    type="text"
                    placeHolder="Select Organization"
                    input={{...input, value: organization}}
                    meta={{error: '', warning: ''}}
                    options={organizations} />
            </div>
        </Form>
    );
}

export { OrganizationSubscribeFilterForm }
