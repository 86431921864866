import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {proactiveActions} from "_actions";
import _ from "lodash";
import {CampaignBuilder} from "../objects/CampaignBuilder";
import {useParams} from "react-router-dom";
import {attributes, supportedChannelsForFollowup, supportedChannelsForTextSegment} from "_constants";

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const {id} = params;

    useEffect(() => {
        if (id) {
            dispatch(proactiveActions.getCampaign(id));
        }
    }, [id]);

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingCampaign;
    const campaign = proactive.campaign;
    let campaignObj = {};

    if(!loading && !_.isEmpty(campaign)) {
        const channel = _.get(campaign, 'channel') || '-'
        const builder = new CampaignBuilder();
        const followupDefaultValue = supportedChannelsForFollowup.includes(channel) ? '-' : undefined
        builder.setId(_.get(campaign, 'id') || '-');
        builder.setName(_.get(campaign, 'name') || '-');
        builder.setChannel(channel);
        builder.setDescription(_.get(campaign, 'description') || '-');
        builder.setOrganizationId(_.get(campaign, 'organizationId') || '-');
        builder.setTemplateId(_.get(campaign, 'templateId') || '-');
        builder.setIsActive(_.get(campaign, 'isActive') || false);
        builder.setStartDate(_.get(campaign, 'startDate') || '-');
        builder.setEndDate(_.get(campaign, 'endDate') || '-');
        builder.setIsStartDateEditable(_.get(campaign, 'isStartDateEditable') || false);
        builder.setIsEndDateEditable(_.get(campaign, 'isEndDateEditable') || false);
        builder.setDateFilter(extractDateFilter(campaign));
        builder.setMsgDataSourceIds(extractMsgDataSourceIds(campaign));
        builder.setMediaTypes(extractMediaTypes(campaign));
        builder.setTextSegment(extractTextSegment(campaign));
        builder.setSegments(_.get(campaign, 'segments') || []);
        builder.setIsFollowUpEnabled(_.get(campaign, 'isFollowUpEnabled') || false);
        builder.setFollowUpTemplate(_.get(campaign, 'followUpTemplate') || followupDefaultValue);
        builder.setIsPresaleEnabled(_.get(campaign, 'isPresaleEnabled') || false);
        campaignObj = builder.build();
    }

    return { campaignObj, loading };
}

function extractDateFilter(campaign) {
    const campaignAttributes = _.get(campaign, 'attributes')
    if (!campaignAttributes) {
        return null
    }
    const dateFilter = _.find(campaignAttributes, ['name', attributes.CREATED_AT_SOURCE_AFTER_DATE_ATTRIBUTE])
    return _.isEmpty(dateFilter) ? null : _.get(dateFilter, 'value')
}

function extractMsgDataSourceIds(campaign) {
    const msgDataSources = _.get(campaign, 'msgDataSources')
    if (!msgDataSources || _.isEmpty(msgDataSources)) {
        return []
    }
    return _.map(msgDataSources, (msgDataSource) => msgDataSource.msgDataSourceId);
}

function extractMediaTypes(campaign) {
    const mediaTypes = _.get(campaign, 'mediaTypes')
    if (!mediaTypes || _.isEmpty(mediaTypes)) {
        return []
    }
    return _.map(mediaTypes, (mediaType) => mediaType.id);
}

function extractTextSegment(campaign) {
    const channel = _.get(campaign, 'channel')
    if (!supportedChannelsForTextSegment.includes(channel)) {
        return null
    }
    const segments = _.get(campaign, 'segments')
    if (!segments || _.isEmpty(segments)) {
        return null
    }
    const activeSegments = _.filter(segments, ['isActive', true])
    if (!activeSegments || _.isEmpty(activeSegments)) {
        return null
    }
    return _.join(_.map(activeSegments, (activeSegment) => activeSegment.keyword), ',');
}