import  React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Grid} from "@material-ui/core";
import {
  chatdeskUpdatesActions,
  dataSourceHandleActions,
  messageActions,
  organizationActions,
  responseOptionActions,
} from "_actions";
import {ConversationItem} from "./ConversationItem";
import {Search} from "./Search";
import {ResponsePanel} from "./ResponsePanel";
import {SearchResultPanel} from "./SearchResultPanel";
import {VoteEmptyState} from "./VoteEmptyState";
import {
  getCapitalizedWords,
  getMessageSource,
  getNextMessage,
  isObjectEmpty,
  queryStringToObject,
  getVotingOrganization,
  websocket
} from '_helpers';
import {handleType, navigationType, responseType, supportedSourcesForViewHtmlAndPhotos} from '_constants';
import _ from "lodash";
import {OriginalConversation} from "./OriginalConversation";
import htmlToText from "html-to-text";
import {voteActions} from "_actions/vote.actions";
import {SearchResultConversation} from "./SearchResultConversation";
import {AgentUpdatesDisplayModal} from "components/ChatdeskUpdates/modals/AgentDisplayModal";
import {chatdeskUpdatesService} from "_services";
import WebsocketMessage from "./WebsocketMessage";

/**
 * @author Chris Okebata
 */

const style = {
    rootWrapper: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        top: '60px',
    },
};

const navigation = {
    type: navigationType.AGENT_SELECTION,
};

const APPROVE_TAG = 'APPROVE';


class VoteIndex extends Component {

    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        let filterParams = queryStringToObject(search);
        let {channels, organizations} = filterParams;
        channels = channels ? channels.split(",").map(Number) : [];
        organizations = organizations ? organizations : [];
        filterParams.channels = channels;
        filterParams.organization = organizations;

        this.state = {
            currentUser: JSON.parse(localStorage.getItem('user')),
            isResponseSelected: false,
            selectedResponseId: '',
            selectedEditResponse: '',
            loadingProps: false,
            isEditing: false,
            displaySearchPanel: false,
            displaySearchConversation: false,
            searchText: '',
            max: 10,
            filterParams: {
                direction: 'ALL',
                searchString: '',
                ...filterParams
            },
        };
        this.editedResponseObj = {};
    }

    getActiveCompanyUpdate() {
        const pageName = "voteIndex"
        const showCount = chatdeskUpdatesService.getShowCount(pageName)

        if (showCount > 0) {
            const org = getVotingOrganization();
            const id = _.get(org, 'id')
            const filterParams = {
                isActive: true,
                organizationId: id,
                max: 1,
            }
            this.props.dispatch(chatdeskUpdatesActions.getAll(filterParams))
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;
        const org = getVotingOrganization();

        getNextMessage(dispatch, false);
        dispatch(organizationActions.getOrganization(_.get(org, 'id')));
        dispatch(dataSourceHandleActions.getAll({organization: _.get(org, 'id')}));

        document.addEventListener("keydown", this.onEscKey, false);
        this.getActiveCompanyUpdate();
        websocket.connect();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onEscKey, false);
        this.props.dispatch(responseOptionActions.clearAddedResponseOption());
        websocket.disconnect();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({loadingProps: false})
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let channelIds = nextState.filterParams.channels;
        let allChannels = this.getChannels(nextProps.dataSourceHandles, 'ids');
        let isSubset;

        isSubset = channelIds.every(function (val) {
            return allChannels.indexOf(val) >= 0;
        });

        if ((!nextState.channelsInitialized && !!nextProps.dataSourceHandles.length && channelIds.length === 0) || !isSubset) {

            this.setState({
                channelsInitialized: true,
                filterParams: {
                    ...nextState.filterParams,
                    channels: allChannels
                }
            });
        }

        return true;
    }

    getObjectProperty = (obj, prop) => {
        return obj[prop];
    };

    setObjectProperty = (obj, prop, value) => {
        if (isObjectEmpty(prop)) return;

        obj[prop] = value;
    };

    onSelectResponse = (id) => {
        this.setState({isEditing: false, isResponseSelected: true, selectedResponseId: id});
    };

    onSelectEditResponse = (responseId) => {
        this.setState({isEditing: true, isResponseSelected: true, selectedResponseId: responseId})
    };

    closeModal = () => {
        if (!this.props.loadingHtmlBody) {
            this.setState({isEditing: false, isResponseSelected: false, selectedResponseId: ''});
        }
    };

    getChannels = (dataSourceHandles, returnValue) => {
        return dataSourceHandles && dataSourceHandles.map(({
                                                               trendsId,
                                                               name
                                                           }) => returnValue === 'ids' ? trendsId : ({
            value: trendsId,
            name
        }));
    };

    /**
     * Handles the update of a form element on the filter form.
     * @param event
     */
    handleFormUpdate = (event) => {
        const target = event.target;
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams,
                    [target.name]: target.value
                }
            }
        });
    };

    populateParams(key, channels) {
        const mappedChannels = channels.map(Number);
        this.setState(prevState => ({
            key,
            filterParams: {
                ...prevState.filterParams,
                channels: mappedChannels
            },
        }));
        return {
            ...this.state.filterParams
        };
    };

    handleChannelsFilterChange = (channels) => {
        const {key} = this.state;
        this.populateParams(key, channels);
    };

    onEscKey = (event) => {
        if (event.keyCode === 27) {
            this.closeModal();
        }
    };

    /**
     * Submit vote payload
     * @param votesArray
     * @param response_id
     * @param response_type
     * @param response_text
     */
    submitVoteData = (votesArray, response_id = null, response_type = null, response_text = null) => {
        const payload = this.buildVotePayload(votesArray, response_id, response_type, response_text);
        this.props.dispatch(voteActions.submitVote(payload));

        //Reset state to default in order to process the next message
        this.setState(prevState => ({
            filterParams: {
                ...prevState.filterParams,
                channels: this.getChannels(this.props.dataSourceHandles, 'ids')
            },
            loadingProps: true,
            selectedEditResponse: '',
            isResponseSelected: false,
            selectedResponseId: '',
            isEditing: false,
            displaySearchPanel: false,
            displaySearchConversation: false,
        }));

        this.editedResponseObj = {};

        this.props.dispatch(responseOptionActions.clearAddedResponseOption());
    };

    /**
     * This builds the payload for submitting a vote
     * @param votes
     * @param response_id
     * @param response_type
     * @param response_text
     * @returns {*}
     */
    buildVotePayload = (votes, response_id = null, response_type = null, response_text = null) => {

        const {nextMessage: {message: {id, dataSourceHandle}}} = this.props;
        const isEmail = [handleType.email, handleType.proactive_email].includes(dataSourceHandle.type);

        let _votes = [], plainResponseText = response_text;

        if (isEmail) {
            plainResponseText = htmlToText.fromString(response_text, {wordwrap: false});
        }

        switch (response_type) {
            case responseType.singleApprove:
                _votes = votes.map(voteObj =>
                    voteObj.response_id === response_id ?
                        {
                            ...voteObj,
                            is_best_response: true,
                            messageId: id
                        } : {
                            ...voteObj,
                            messageId: id
                        }
                );

                return {
                    userVote: {
                        votes: _votes,
                        isLastResponseVote: true
                    },
                    navigation,
                };

            case responseType.editedText:
                _votes = votes.map(voteObj =>
                    voteObj.response_id === response_id ?
                        {
                            ...voteObj,
                            tag: APPROVE_TAG,
                            is_best_response: true,
                            edited_text: plainResponseText,
                            is_text_edited: true,
                            messageId: id
                        } : {
                            ...voteObj,
                            messageId: id
                        }
                );

                return {
                    userVote: {
                        votes: _votes,
                        isLastResponseVote: true
                    },
                    navigation,
                };

            case responseType.editedHtml:
                _votes = votes.map(voteObj =>
                    voteObj.response_id === response_id ?
                        {
                            ...voteObj,
                            tag: APPROVE_TAG,
                            is_best_response: true,
                            edited_text: plainResponseText,
                            edited_html_body: response_text,
                            is_text_edited: true,
                            messageId: id
                        } : {
                            ...voteObj,
                            messageId: id
                        }
                );

                return {
                    userVote: {
                        votes: _votes,
                        isLastResponseVote: true
                    },
                    navigation,
                };

            default:
                return {
                    userVote: {
                        votes: votes.map(voteObj =>
                            ({
                                ...voteObj,
                                messageId: id,
                            })
                        ),
                        isLastResponseVote: true //This property will be available in the payload temporarily for backward compatibility
                    },
                    navigation,
                };
        }
    };

    skipMessage = (event, messageId) => {
        event.preventDefault();

        const agentId = this.state.currentUser.id;
        const skipMessageParams = {
            userMessageAction: {
                user: agentId,
                actionType: 'NO_RESPONSE_NEEDED',
                message: messageId,
            },
            navigation
        };
        this.props.dispatch(messageActions.skipMessage(skipMessageParams));
        this.props.dispatch(responseOptionActions.clearAddedResponseOption());
    };

    escalateMessage = (event, messageId) => {
        event.preventDefault();

        const agentId = this.state.currentUser.id;
        const escalationParams = {
            userMessageAction: {
                user: agentId,
                actionType: 'ESCALATE',
                message: messageId,
            },
            navigation,
        };
        this.props.dispatch(messageActions.escalate(escalationParams));
        this.props.dispatch(responseOptionActions.clearAddedResponseOption());
    };

    searchMessage = (searchText) => {
        this.setState({
            displaySearchPanel: true,
            searchText: searchText,
            filterParams: {
                ...this.state.filterParams,
                searchString: searchText,
                page: 0,
                offset: 0
            }
        }, () => {
            this.searchAdminMessages();
            this.props.dispatch(messageActions.clearVisitedPages());
        });
    };

    /**
     * Calls the searchAdminMessages action with the current state as the payload
     */
    searchAdminMessages = () => {
        const {organization, dispatch} = this.props;
        const orgId = _.get(organization, 'trendsId');
        this.setState({
            displayConversations: false,
            displaySearchResults: true,
            filterParams: {
                ...this.state.filterParams,
                organizations: [orgId],
            }
        }, () => {
            const searchParams = this.state.filterParams;
            dispatch(messageActions.searchAdminMessages(searchParams));
        });
    };

    handlePageSelect = (page) => {
        this.setState({
            filterParams: {
                ...this.state.filterParams,
                offset: page.selected * this.state.max,
                page: page.selected
            }
        }, () => {
            this.searchAdminMessages();
        });
    };

    closeSearchPanel = () => {
        this.setState({
            displaySearchPanel: false,
            displaySearchConversation: false
        });
    };

    showSearchConversation = (searchedMessageId) => {
        this.setState({
            displaySearchPanel: false,
            displaySearchConversation: true,
            searchedMessageId: searchedMessageId,
        });
    };

    closeSearchConversation = () => {
        this.setState({
            displaySearchPanel: true,
            displaySearchConversation: false
        });
    };


    render() {

        const {
            isResponseSelected, isEditing, displaySearchPanel, selectedResponseId, searchText,
            filterParams, displaySearchConversation, searchedMessageId, currentUser
        } = this.state;

        const {
            nextMessage, reduxStateLoading, conversation, loadingConversation,
            dataSourceHandles, searchedData, loadingSearch, loadingHtmlBody, chatdeskUpdates
        } = this.props;
        const shouldDisable = loadingSearch || loadingHtmlBody;

        let {direction} = filterParams;
        let channels, channelsIds;

        if (isObjectEmpty(nextMessage)) {
            return <VoteEmptyState/>;
        }

        const {message} = nextMessage;
        const {response_options, dataSourceHandle, sourceName, link, msgDataSource, isProactive } = message;

        if (reduxStateLoading || this.state.loadingProps) {
            return <VoteEmptyState/>;
        }

        if (!(message && response_options) || loadingConversation) {
            return <VoteEmptyState/>;
        }

        if (dataSourceHandles) {
            channels = this.getChannels(dataSourceHandles);
            channelsIds = this.getChannels(dataSourceHandles, 'ids');
        }

        let displayInApp = _.get(dataSourceHandle, 'displayInApp');
        let messageHandleType = _.get(dataSourceHandle, 'type');
        let messageHandleName = _.get(dataSourceHandle, 'name');
        messageHandleName = messageHandleName ? messageHandleName.toLowerCase() : 'Message';

        let _messageHandleType = messageHandleType && messageHandleType.toLowerCase();
        let _conversation = [], renderPanel;
        const source = message && getMessageSource((message.dataSourceHandle && message.dataSourceHandle.name) || message.channel);

        const conversationThread = conversation && conversation.constructor === Array ?
            _.get(conversation, '0.conversation') : _.get(conversation, 'conversation');


        if (['email', 'chat'].includes(_messageHandleType) && conversationThread.length > 1) {
            _conversation.push(conversationThread);
            _conversation = _.get(_conversation, '0');
        } else {
            _conversation.push(message);
        }

    if(displaySearchPanel){
      renderPanel = <SearchResultPanel
                      closeSearchPanel={this.closeSearchPanel}
                      searchText={searchText}
                      onFormUpdate={this.handleFormUpdate}
                      channelsFilter={channels}
                      channelsIds={channelsIds}
                      handleChannelsFilterChange={this.handleChannelsFilterChange}
                      direction={direction}
                      searchedData={searchedData}
                      loadingSearch={loadingSearch}
                      filterParams={filterParams}
                      handlePageSelect={this.handlePageSelect}
                      max={this.state.max}
                      showSearchConversation={this.showSearchConversation}
                      currentUser={currentUser}
                      message={message}
                    />
    }else if(displaySearchConversation){
      renderPanel = <SearchResultConversation
                      searchedMessageId={searchedMessageId}
                      closeSearchConversation={this.closeSearchConversation}
                    />
    } else{
      renderPanel = <ResponsePanel
                      onSelectResponse={this.onSelectResponse}
                      onSelectEditResponse={this.onSelectEditResponse}
                      isResponseSelected={isResponseSelected}
                      isEditing={isEditing}
                      selectedResponseId={selectedResponseId}
                      submitVoteData={this.submitVoteData}
                      closeModal={this.closeModal}
                      message={message}
                      editedResponseObj={this.editedResponseObj}
                      getObjectProperty={this.getObjectProperty}
                      setObjectProperty={this.setObjectProperty}
                      organization={getVotingOrganization()}
                      msgDataSource={msgDataSource}
                    />
    }

        const pageName = "voteIndex"
        const showCount = chatdeskUpdatesService.getShowCount(pageName)
        const agentUpdatesModalPresent = showCount > 0 && !chatdeskUpdates?.loading &&
            !isObjectEmpty(chatdeskUpdates?.updatesList) &&
            chatdeskUpdates.updatesList?.data?.length > 0

        return (
            <Fragment>
                <WebsocketMessage message={message}/>
                <div>
                    {agentUpdatesModalPresent &&
                    <AgentUpdatesDisplayModal
                        companyUpdates={chatdeskUpdates.updatesList}
                        fromPage="voteIndex"
                    />
                    }
                </div>
                <div style={style.rootWrapper}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <div style={{
                                height: 'calc(100vh - 60px)',
                                borderRight: '1px solid #CED2D0',
                                position: 'relative'
                            }}>
                                <div style={{
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0px 35px',
                                    borderBottom: '1px solid #dee2e0',
                                    backgroundColor: '#f3f3f3'
                                }}>
                                    <div className="font-14" style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <span
                                            className="color-grey-dark tms-ellipsis text-transform-capitalize">{`${messageHandleName}`}</span>

                                        <div style={{display: 'flex', justifyContent: 'space-between',}}>
                                            {displayInApp &&
                                            supportedSourcesForViewHtmlAndPhotos.includes(source && source.toLowerCase()) &&
                                            <OriginalConversation message={message}/>
                                            }

                                            {link && (
                                                <span style={{marginLeft: '15px'}}>
                            <a className="font-14 color-green tms-no-underline"
                               href={link}
                               target="_blank"
                               rel="noreferrer noopener nofollow"
                               style={{float: 'right', fontWeight: 'bold'}}
                            >
                                {`View in ${getCapitalizedWords(sourceName) || 'source'}`}
                            </a>
                          </span>
                                            )}
                                        </div>

                                    </div>
                                </div>
                                <div style={{height: 'calc(100% - 110px)', padding: '0 0 17px 35px'}}>
                                    <div style={{overflowY: 'auto', height: '100%', padding: '5px 35px 15px 0'}}>
                                        {/*BEGIN LOOP HERE*/}
                                        {
                                            _conversation.map((conversation) => {
                                                return (
                                                    <ConversationItem
                                                        key={_.get(conversation, 'id')}
                                                        message={conversation}
                                                    />
                                                )
                                            })
                                        }
                                        {/*END LOOP HERE*/}
                                    </div>
                                </div>
                                {
                                    !isProactive && <div style={styles.actionsSectionContainer}>
                                        <div style={{width: '100%', marginRight: '10px',}}>
                                            <button onClick={(e) => this.escalateMessage(e, message.id)}
                                                    className="btn btn-block btn-default font-15 color-green"
                                                    disabled={shouldDisable} style={{padding: '8px 0'}}>
                                                Escalate to manager
                                            </button>
                                        </div>

                                        <div style={{width: '100%', marginLeft: '10px'}}>
                                            <button onClick={(e) => this.skipMessage(e, message.id)}
                                                    className="btn btn-block btn-default font-15 color-green"
                                                    disabled={shouldDisable} style={{padding: '8px 0'}}>
                                                No response needed
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <div style={{height: 'calc(100vh - 60px)', position: 'relative'}}>
                                {renderPanel}
                                <div>
                                    <div style={styles.searchSectionContainer}>
                                        <Search searchMessage={this.searchMessage} loadingSearch={loadingSearch}
                                                loadingHtmlBody={loadingHtmlBody}/>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Fragment>
        )
    }
}

const styles = {
    actionsSectionContainer: {
        width: '100%',
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 35px',
        position: 'absolute',
        bottom: '0',
        borderTop: '1px solid #CED2D0'
    },
    searchSectionContainer: {
        width: '100%',
        height: '70px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0px 35px',
        position: 'absolute',
        bottom: '0',
        borderTop: '1px solid #CED2D0',
    }
}

function mapStateToProps(state) {
    const {
        messages,
        conversation,
        dataSourceHandles,
        organizations,
        search,
        dispatcher,
        chatdeskUpdates
    } = state;

    return {
        nextMessage: messages.nextMessage,
        reduxStateLoading: messages.loadingNextMessage,
        conversation,
        loadingConversation: conversation.loading,
        dataSourceHandles: dataSourceHandles.dataSourceHandles,
        loadingHandles: dataSourceHandles.loading,
        organization: organizations.organization,
        searchedData: search.searchData,
        loadingSearch: search.loading,
        loadingHtmlBody: dispatcher.loading,
        chatdeskUpdates
    };
}

const connectedVoteIndex = connect(mapStateToProps)(VoteIndex);
export { connectedVoteIndex as VoteIndex };
