import {Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import {Box} from "@mui/system";
import _ from "lodash";
import {handleType} from "_constants";
import {dispatcherActions} from "_actions";
import {dispatcherConstants} from "_constants";
import DirectMessageEditor from "./DirectMessageEditor";
import {CrossDispatchWarningModal} from "../../../../../Messages/CrossDispatchWarningModal";
import ActionButton from "../../../../../../common/ActionButton";
import useViewNextMessage from "../../../../Utilities/hooks/useViewNextMessage";
import {buildPrivateReplyPayload} from "../../../../Utilities/VoteUtils";

export default function DirectMessageContent({handleClose, crossDispatchWarningDismissed, onDispatchSuccess, onDispatchFailure}) {
    const [text, setText] = useState('');

    const dispatch = useDispatch();
    const {message} = useViewNextMessage();
    const isEmail = [handleType.email, handleType.proactive_email].includes(message.dataSourceHandle.type);

    const handleTextChange = (editorText) => {
        setText(editorText);
    }

    const handleSubmit = () => {
        if (_.isEmpty(text)) {
            return;
        }
        const payload = buildPrivateReplyPayload(message, text, crossDispatchWarningDismissed);

        dispatch(dispatcherActions.dispatchMessage(message.id, payload))
            .then((result) => {
                handleClose();
                if (result.success) {
                    onDispatchSuccess(result?.response);
                } else {
                    onDispatchFailure(result?.response);
                }
            }).catch((error) => console.error('An error occurred when dispatching message: ' + error));
    }

    return(
        <Fragment>
            <DirectMessageEditor
                isEmail={isEmail}
                handleTextChange={handleTextChange}
            />
            <Box sx={style.buttonContainer}>
                <Box sx={style.cancelButtonContainer}>
                    <ActionButton
                        label={'Cancel'}
                        action={handleClose}
                        buttonBordered
                        useBorderColor={'#07857C'}
                        withIcon={false}
                        sx={style.cancelButton}
                    />
                </Box>
                <Box>
                    <ActionButton
                        label={'Send'}
                        action={handleSubmit}
                        withIcon={false}
                        sx={style.sendButton} />
                </Box>
            </Box>
        </Fragment>
    )
}

const style = {
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '15px'
    },
    cancelButtonContainer: {
        width: '84px',
        marginRight: '15px'
    },
    cancelButton: {
        height: '35px',
    },
    sendButton: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}