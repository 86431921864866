import React from 'react'
import {Link} from 'react-router-dom'
/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/01/2019 : 12:49 PM
 */

const miniPanel = (props) => (
    <Link to={props.linkTo} className='trds-no-underline'>
        <div style={{ backgroundColor: 'white', border: 'solid 1px lightGray', borderRadius: '2%', padding: '20px' }}>
            <div className="icon-container">
                <img src={props.image} alt={props.text}/>
            </div>
            <hr/>
            <h4>{props.text}</h4>
        </div>
    </Link>
);

export default miniPanel