import {Fragment} from "react";
import {Box} from "@mui/system";
import AttachmentContainer from "./AttachmentContainer";
import {isTypeEmail, parseHtml} from "_helpers";
import _ from "lodash";
import MoreInfo from "./MoreInfo";
import {hasAttachment} from "../Utilities/MessageUtils";
import ReactionEntry from "../Reactions/ReactionEntry";
import MessageIframe from "./MessageIframe";
import {isOEmbedSupported} from "../../../views/Vote/Sections/BodySection/Conversation/OEmbed/EmbedUtils";
import {isTiktokShopPost} from "../../../utilities/ConversationUtils";
import TikTokShopContent from "../TikTokShop/TikTokShopContent";
import {msgSource} from "_constants";

/**
 * @param text {string}
 * @param message {object}
 * @param attachments {array}
 * @param other {object}
 * @param background {'teal' | 'grey'} - (optional) Background color
 * @param refProp
 * @param index
 * @returns {JSX.Element}
 * @constructor
 */
export default function MessageContainer(
    { text, message, attachments, other, background = 'teal', refProp, index}) {

    const getBackgroundStyle = () => {
        switch (background) {
            case 'teal': return { backgroundColor: '#E6F3F2'};
            case 'grey': return { backgroundColor: '#F3F5F6'};
        }
    }
    const excludeFromIframe = [msgSource.DIXA]
    const containerStyle = {...styles.container, ...getBackgroundStyle()}
    const containsAttachment = hasAttachment(attachments);
    const handleType = _.get(message, 'dataSourceHandle.type');
    const isEmail = isTypeEmail(handleType);
    const isTTSPost = isTiktokShopPost(handleType);
    const sourceName = _.toString(_.get(message, 'sourceName')).toLowerCase();
    const dataSourceHandle = _.get(message, 'dataSourceHandle');
    const displayInApp = _.get(dataSourceHandle, 'displayInApp');
    const isEmbed = isOEmbedSupported(sourceName, displayInApp);
    const useIframe = isEmail && !excludeFromIframe.includes(sourceName);

    const Content = () => {
        if (isEmbed || useIframe) {
            return <MessageIframe text={text} message={message} refProp={refProp} index={index}/>;
        } else if(isTTSPost) {
            return <TikTokShopContent text={text} sourceName={sourceName}/>;
        } else {
            return parseHtml(text, sourceName);
        }
    }

    return(
        <Fragment>
            <Box sx={containerStyle}>
                <Content/>
            </Box>
            <ReactionEntry message={message} />
            {
                !_.isEmpty(other) &&
                <Box sx={{marginTop: '10px'}}>
                    <Box sx={{...containerStyle, ...{padding: '7px 12px'}}}>
                        <MoreInfo other={other} />
                    </Box>
                </Box>
            }
            {
                !_.isEmpty(message) && containsAttachment &&
                <Box sx={{marginTop: '10px'}}>
                    <Box sx={{...containerStyle, ...{padding: '0 12px'}}}>
                        <AttachmentContainer attachments={attachments} message={message} />
                    </Box>
                </Box>
            }
        </Fragment>
    )
}

const styles = {
    container: {
        border: '1px solid #DCE2E3',
        borderRadius: '6px',
        padding: '15px 12px',
        lineHeight: '140%',
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#0C2728',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        blockquote: {
            border: '1px solid #DCE2E3',
            borderRadius: '3px',
        }
    }
}