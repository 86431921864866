import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux'
import moment from 'moment/moment'
import ReactPaginate from "react-paginate";

const spanStyle = {
    fontWeight: 'bold',
    marginRight: '15px',
    display: 'block'
};
const dataStyle = {
    marginTop: '3px',
    marginBottom: '3px',
};
const dataContainer = {
    display: 'flex',
    alignItems: 'center',
};

class SubscriptionHistory extends Component {

    render() {
        const { history, data, organization, openModal, max, offset, pageSelected } = this.props;
        const total = data.count;
        const getOffsetInt = parseInt(offset,10);
        const getMaxInt = parseInt(max, 10);
        let page = getOffsetInt > 0 ? getOffsetInt / getMaxInt : getOffsetInt;
        const totalPages = total && max ? Math.ceil(total / max) : false;
        return (
            <div className="content">
                {organization &&
                    <div style={dataContainer}>
                        <div className="col-md-3 text-center">
                            <span style={spanStyle}>
                                SUBSCRIPTIONS COUNT:
                            </span>
                            <h2 style={dataStyle}>{data.count}</h2>
                        </div>
                        <div className="col-md-3 text-center">
                            <span style={spanStyle}>
                                CURRENT BALANCE:
                            </span>
                            <h2 style={dataStyle}>{data.currentBalance}</h2>
                        </div>
                        <div className="col-md-3 text-center">
                            <span style={spanStyle}>
                                LIFETIME SPENDING:
                            </span>
                            <h2 style={dataStyle}>{data.lifeTimeSpending}</h2>
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-info" onClick={openModal}>
                                Add Subscription
                            </button>
                        </div>
                    </div>
                }
                {history && history.length > 0 &&
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Subscription</th>
                                <th>Amount</th>
                                <th>Subscribed By</th>
                                <th>Date Subscribed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.map((item, index) =>
                                <tr key={index}>
                                    <td>{item.subscription.label}</td>
                                    <td>({item.subscription.currency.code}) {item.subscription.amount}</td>
                                    <td>{item.subscribedBy}</td>
                                    <td>
                                        {moment(item.dateCreated).format('dddd, MMMM Do YYYY, h:mm a')} <br />
                                        ({moment(item.dateCreated).from(moment())})
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        {totalPages && totalPages > 1 &&
                            <ReactPaginate previousLabel={"<"}
                               nextLabel={">"}
                               breakLabel={<span>...</span>}
                               breakClassName={"break-me"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               disableInitialCallback={true}
                               pageRangeDisplayed={5}
                               forcePage={page}
                               onPageChange={(page) => pageSelected(page)}
                               containerClassName={"pagination"}
                               subContainerClassName={"pages pagination"}
                               activeClassName={"active"}
                               activePage={offset / max}
                               handleSelect={this.handleSelect}
                            />
                        }
                    </Table>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { subscription: { history, data }} = state
    return {
        history, data
    }
}

const connectedHistory = connect(mapStateToProps)(SubscriptionHistory)

export {connectedHistory as SubscriptionHistory }