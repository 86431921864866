import React from 'react';
import {alertActions, dataSourceHandleActions, messageActions, organizationActions} from "_actions";
import {connect} from "react-redux";
import { SearchForm } from "./SearchForm";
import { SearchMessagesTable } from "./SearchMessagesTable";
import SearchInput from "./SearchInput";
import {SearchLoading} from "./SearchLoading";
import {objectToQueryString, queryStringToObject } from "_helpers";
import "components/Message/messages.css";
import ReactPaginate from 'react-paginate';
import {ConfirmationModal} from "../common";
import {FindAndReplaceModal} from "./FindAndReplaceModal";
import {Button} from "react-bootstrap";
import {userRoles as roles} from "../../_constants";


/**
 * @author Okpala Oluchukwu Chioma <oluchukwu@chatdesk>
 */
class AdminSearch extends React.Component {
    constructor(props) {
        super(props);
        const search = this.props.location.search.substring(1);
        let filterParams = queryStringToObject(search);
        let { channels, organizations } = filterParams;
        channels =  channels ? channels.split(",").map(Number) : [];
        organizations = organizations ? organizations.split(",").map(Number) : [];
        filterParams.channels = channels;
        filterParams.organizations = organizations;
        filterParams.selectedMessages = new Set();
        this.handleMessageCheck = this.handleMessageCheck.bind(this);
        this.handleAllMessagesCheck = this.handleAllMessagesCheck.bind(this);
        this.state = {
            channelsInitialized: false,
            max: 10,
            showBlacklistConfirmationModal: false,
            showBlacklistAllConfirmationModal: false,
            anchorElement: null,
            findReplaceModal: {
                show: false
            },
            isAllChecked: false,
            filterParams: {
                direction: 'ALL',
                searchString: '',
                show: false,
                offset: 0,
                page: 0,
                ...filterParams
            },
        };
    }

    getDataSourceHandles = (organizations) => {
        this.props.dispatch(dataSourceHandleActions.getAll({
            organizations
        }));
    };

    /**
     * Updates the state searchString when the input value changes
     */
    updateStateSearchString = (e) => {
        const { name, value } = e.target;
        this.setState({
            filterParams: {
                ...this.state.filterParams,
                [name]: value
            }
        });
    };

    /**
     * Search admin messages when enter key is pressed
     */
    searchOnKeyEnter = (e) => {
        if(e.keyCode === 13){
            this.onSearch()
        }
    };

    /**
     * Search admin messages and resets page and offset to 0 on fresh search
     */
    onSearch = () => {
        this.setState({
            filterParams: {
                ...this.state.filterParams,
                page: 0,
                offset : 0
            }
        }, () => {
            this.searchAdminMessages();
            this.props.dispatch(messageActions.clearVisitedPages());
        });
    };

    /**
     * Calls the searchAdminMessages action with the current state as the payload
     */
    searchAdminMessages = () => {
        this.setState({
            filterParams: {
                ...this.state.filterParams,
                show : true
            }
        },() => {
            const searchParams = this.state.filterParams;
            const queryString = objectToQueryString(searchParams);
            this.props.history.push({
                pathname: '/search',
                search: `?${queryString}`
            });
            this.props.dispatch(messageActions.searchAdminMessages(searchParams));
        });
    };

    /**
     * Downloads the .csv generated by the backend
     */
    exportCSV  = () => {
        const searchParams = this.state.filterParams;
        this.props.dispatch(messageActions.exportAdminSearch(searchParams));
    };

    handleBlacklistModalClose = () => {
        this.setState({ showBlacklistConfirmationModal: false });
    }

    popBlacklistMessageConfirmationModal = () => {
        const {selectedMessages} = this.state.filterParams
        if (selectedMessages.size === 0) {
            this.props.dispatch(alertActions.error("Select at least one message to blacklist"));
            return
        }
        this.setState({showBlacklistConfirmationModal: true});
    }

    blacklistCheckedMessages = () => {
        const {selectedMessages} = this.state.filterParams
        this.props.dispatch(messageActions.blacklistMessages({selectedMessages: [...selectedMessages]}))
        this.searchAdminMessages();
    }

    popBlacklistAllMessageConfirmationModal = () => {
        this.setState({showBlacklistAllConfirmationModal: true});
    }

    blacklistAllMessages = () => {
        const searchParams = this.state.filterParams;
        this.props.dispatch(messageActions.blacklistAllMessages(searchParams))
        this.handleBlacklistAllModalClose()
    }

    handleBlacklistAllModalClose = () => {
        this.setState({ showBlacklistAllConfirmationModal: false });
    }

    showFindReplaceModal = () => {
        this.setState({findReplaceModal: {show: true}});
        this.closeMenu()
    }

    hideFindReplaceModal = () => {
        this.setState({findReplaceModal: {show: false}});
    }

    closeMenu = () => {
        this.setState({anchorElement: null});
    };

    componentDidMount() {
        const { dispatch } = this.props;
        const visibility = ['PUBLIC', 'FEATURED', 'PRIVATE'];
        dispatch(organizationActions.getAll({visibility}));
        this.getDataSourceHandles(this.state.filterParams.organizations);
    }

    /**
     * This loads all channels on first page load, sets channelsInitialized to true, and only updates when channels is empty
     * and channelsInitialized is false
     * @param props
     */
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let channelIds = nextState.filterParams.channels;
        if (!nextState.channelsInitialized && !!nextProps.dataSourceHandles.length && channelIds.length === 0 ) {
            this.setState({
                channelsInitialized: true,
                filterParams: {
                    ...nextState.filterParams,
                    channels: this.getChannels(nextProps.dataSourceHandles, 'ids')
                }
            });
        }

        return true;
    }

    getChannels = (dataSourceHandles, returnValue) => {
        return dataSourceHandles.map(({ trendsId, name }) => returnValue === 'ids' ? trendsId : ({ value: trendsId, name }));
    };

    getOrganizations = (organizations, returnValue) => {
        return organizations
            .filter(o => o && o.trendsId)
            .map(({ trendsId, name }) => returnValue === 'ids' ? trendsId : ({ value: trendsId, name }));
    };

    /**
     * Handles the update of a form element on the filter form.
     * @param event
     */
    handleFormUpdate = (event) => {
        const target = event.target;
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams,
                    [target.name]: target.value}
            }
        });
    };

    handleOrganizationsFilterChange = (organizations) => {
        const { key } = this.state;
        this.populateOrgParams(key, organizations);
    };

    handleChannelsFilterChange = (channels) => {
        const { key } = this.state;
        this.populateParams(key, channels);
    };

    /**
     * Updates the state organizations with the selected organizations
     */
    populateOrgParams(key, organizations) {
        const mappedOrganizations = organizations.map(Number);
        this.setState(prevState => ({
            key,
            filterParams: {
                ...prevState.filterParams,
                organizations: mappedOrganizations
            },
        }), () => {
            this.getDataSourceHandles(this.state.filterParams.organizations);
        });
        return {
            ...this.state.filterParams
        };
    }

    /**
     * Updates the state channels with the selected channels
     */
    populateParams(key, channels) {
        const mappedChannels = channels.map(Number);
        this.setState(prevState => ({
            key,
            filterParams: {
                ...prevState.filterParams,
                channels: mappedChannels
            },
        }));
        return {
            ...this.state.filterParams
        };
    };

    /**
     * Get the page number from react paginate and multiply with the max to get the offset.
     * Update the state and get the messages using the current offset
     */
    handlePageSelect = (page) => {
        this.setState({
            filterParams: {
                ...this.state.filterParams,
                offset : page.selected * this.state.max,
                page: page.selected
            }
        },() => {
            this.searchAdminMessages();
        });
    };

    handleMessageCheck(event, checked) {
        const messageId = event.target.value;
        const selectedMessages = this.state.filterParams.selectedMessages;
        if (checked) selectedMessages.add(messageId)
        else selectedMessages.delete(messageId)
        this.setState({
            isAllChecked: false,
            filterParams: {
                ...this.state.filterParams,
                selectedMessages: selectedMessages
            }
        });
    }

    handleAllMessagesCheck(event, isChecked) {
        const messages = this.props.messages ? this.props.messages : [];
        let selectedMessages = this.state.filterParams.selectedMessages

        if (isChecked) {
            messages.forEach((message) => {
                if (message.direction === "Outgoing" && !message.hasDetractor) {
                    selectedMessages.add(message.messageId)
                }
            });
        } else {
            selectedMessages = new Set();
        }

        this.setState({
            isAllChecked: isChecked,
            filterParams: {
                ...this.state.filterParams,
                selectedMessages: selectedMessages
            }
        });
    }

    pageHasBlacklistableMessages(messages, direction) {
        if (["ALL", "OUTGOING"].includes(direction)) {
            for (let message of messages) {
                if (message.direction === "Outgoing" && !message.hasDetractor) return true
            }
        }
        return false
    }

    render(){
        const messages = this.props.messages ? this.props.messages : [];
        const { isAllChecked, filterParams: { organizations, msgDataSource, direction, selectedMessages } } = this.state;

        const input = {onChange: this.handleFormUpdate}
        const showSelectAllCheckbox = this.pageHasBlacklistableMessages(messages, direction);

        const {findReplaceModal: {show}} = this.state
        let findModal;
        let channels, channelsIds, companies, companiesIds;
        const { dataSourceHandles, orgList} = this.props;
        let finalOrgList = orgList.organizations

        if (dataSourceHandles) {
            channels = this.getChannels(dataSourceHandles);
            channelsIds = this.getChannels(dataSourceHandles, 'ids');
        }
        if (finalOrgList) {
            companies = this.getOrganizations(finalOrgList);
            companiesIds = this.getOrganizations(finalOrgList, 'ids');
        }
        const loggedInUser = JSON.parse(localStorage.getItem("user"));
        const currentUserRoles = loggedInUser && loggedInUser.roles && loggedInUser.roles[0];
        const isBillingAdmin = loggedInUser && currentUserRoles === roles.BILLING_ADMIN_ROLE;

        //Pagination : Get the total number of pages using resultCount and the page number from react paginate
        const { max, filterParams } = this.state;
        const page = parseInt(filterParams.page, 10);
        const count = this.props.resultCount;
        const totalPages = Math.ceil(count / max);

        if (show) {
            findModal = <FindAndReplaceModal
                headerText="Find And Replace"
                buttonText="Submit"
                onHide={this.hideFindReplaceModal}
                show={show}
                closeModalHandler={this.closeModalHandler}
                organizations={this.state.filterParams.organizations}
            />
        }
        return (
            <div className="container-pane">
                <div className="side-bar-pane shadow-border">
                    <SearchForm
                        onFormUpdate={this.handleFormUpdate}
                        organizations={organizations}
                        input={input}
                        channelsFilter={channels}
                        channelsAction={this.state.filterParams.channels}
                        channelsIds={channelsIds}
                        companiesIds={companiesIds}
                        companies={companies}
                        handleChannelsFilterChange={this.handleChannelsFilterChange}
                        handleOrgFilterChange={this.handleOrganizationsFilterChange}
                        msgDataSource={msgDataSource}
                        direction={direction}
                    />
                </div>

                <div className="container-right messages-container-right">
                    {
                        <div>
                            <SearchInput
                                searchAdminMessages={this.onSearch}
                                searchOnKeyEnter={this.searchOnKeyEnter}
                                loading={this.props.loading}
                                searchString={this.state.filterParams.searchString}
                                onFormFieldChange={this.updateStateSearchString}
                            />
                        </div>
                    }

                    {this.props.loading &&
                    <SearchLoading />
                    }

                    {isBillingAdmin &&
                    <Button
                        onClick={(e) => this.showFindReplaceModal(e)}
                        className="btn btn-success font-15"
                        type="submit"
                        style={{width: '15%', padding: '12px', marginTop: '15px', float: 'right', marginBottom: '15px'}}>
                        Find & Replace
                    </Button>
                    }

                    {!this.props.loading && this.state.filterParams.show &&
                    <div>
                        <SearchMessagesTable
                            messages={messages}
                            resultCount={this.props.resultCount}
                            exportCSV={this.exportCSV}
                            exporting={this.props.exporting}
                            blacklistCheckedMessages={this.popBlacklistMessageConfirmationModal}
                            handleMessageCheck={this.handleMessageCheck}
                            selectedMessages={selectedMessages}
                            handleAllMessagesCheck={this.handleAllMessagesCheck}
                            isAllChecked={isAllChecked}
                            showSelectAllCheckbox={showSelectAllCheckbox}
                            blacklistAllMessages={this.popBlacklistAllMessageConfirmationModal}
                        />
                        <ConfirmationModal
                          onSave={this.blacklistCheckedMessages}
                          bodyText="Are you sure you want to blacklist the selected messages?"
                          headerText="Admin Message Search"
                          buttonText="Continue"
                          onHide={this.handleBlacklistModalClose}
                          show={this.state.showBlacklistConfirmationModal}
                        />
                        <ConfirmationModal
                          onSave={this.blacklistAllMessages}
                          bodyText="Are you sure you want to blacklist all of these OUTGOING messages?"
                          headerText="Billing Admin Message Search"
                          buttonText="Continue"
                          onHide={this.handleBlacklistAllModalClose}
                          show={this.state.showBlacklistAllConfirmationModal}
                        />
                    </div>
                    }

                    {totalPages > 1 &&
                    <div className="align-center">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={<span>...</span>}
                            breakClassName={"break-me"}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            disableInitialCallback={true}
                            pageRangeDisplayed={5}
                            forcePage={page}
                            onPageChange={(page) => this.handlePageSelect(page)}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                    }
                </div>
                {findModal}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { search: { searchData, loading, exporting }, dataSourceHandles, organizations} = state;
    return {
        messages: searchData.searchResult,
        loading,
        exporting,
        resultCount: searchData.resultCount,
        orgList: organizations.organizations,
        dataSourceHandles: dataSourceHandles.dataSourceHandles
    };
}

const connectedMessageIndex = connect(mapStateToProps)(AdminSearch);
export {connectedMessageIndex as AdminSearch};
