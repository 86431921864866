import React, { useEffect } from "react";
import {Box} from "@mui/system";
import Link from "@mui/material/Link";
import ReactGA from "react-ga";
import { useSelector } from 'react-redux'
import { GOOGLE_ANALYTICS_TRACKING_ID } from "_constants"
import {
  FacebookEntry,
  InstagramEntry,
  TwitterEntry,
  TikTokEntry,
  TikTokAdsEntry,
  ShopifyEntry,
  ZendeskEntry,
  ZendeskChatEntry,
  HelpScoutEntry,
  GorgiasEntry,
  ReamazeEntry,
  FreshdeskEntry,
  KustomerEntry,
  GladlyEntry,
  FrontappEntry,
  SalesforceEntry,
  TrustpilotEntry,
  CustomerIoEntry,
  YouTubeEntry,
  TikTokShopEntry,
  DixaEntry
} from './providers'

// V1 Components, replace these maybe with V2 Components later
import { CopyrightFooter } from "components/common";

export default function ConnectChannels(){
  const msgDataSources = useSelector(state => state.msgDataSources)

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID)
    ReactGA.pageview(window.location.pathname)
  }, [])

  return(
    <>
      <Box sx={styles.root}>
        <Box sx={styles.container}>
          {/* Headings */}
          <Box component={'h1'} sx={styles.h1}>
            Connect channels
          </Box>
          <Box component={'p'} sx={styles.p}>
            Connect to channels supported by Chatdesk.
            Don't see what you're looking for?<br />
            Contact&nbsp;
            <Link sx={styles.pLink} href='mailto:support@chatdesk.com' target='_blank' rel="noopener" underline="none">
              support@chatdesk.com
            </Link>
            &nbsp;for help.
          </Box>

          {msgDataSources?.length && (
            <Box>
              <a href="/v2/company/team">Next (to be styled)</a>
            </Box>
          )}

          {/* Cards */}
          <Box component={'ul'} sx={styles.cardUl}>
            <DixaEntry />
            <FacebookEntry />
            <InstagramEntry />
            <TwitterEntry />
            <TikTokEntry />
            <TikTokAdsEntry />
            <TikTokShopEntry />
            <ShopifyEntry />
            <ZendeskEntry />
            <ZendeskChatEntry />
            <GorgiasEntry />
            <FrontappEntry />
            <ReamazeEntry />
            <KustomerEntry />
            <FreshdeskEntry />
            <GladlyEntry />
            <HelpScoutEntry />
            <SalesforceEntry />
            <TrustpilotEntry />
            <CustomerIoEntry />
            <YouTubeEntry />
          </Box>
        </Box>
      </Box>

      <CopyrightFooter/>
    </>
  )
}

const styles = {
  root: { backgroundColor: '#F8F9F9', pt: 16, pb: 10, minHeight: '100vh', },
  container: { px: { xs: 2, sm: 6 },  maxWidth: 980, position: 'relative' },
  h1: { mt: 0, mb: { xs: 0.5, sm: 1 }, fontFamily: 'LarsseitMedium', fontSize: 24, lineHeight: 1.2, letterSpacing: '-0.02em', color: '#0C2728'},
  p: { m: 0, fontFamily: 'LarsseitRegular', fontSize: 18, lineHeight: 1.2, letterSpacing: '-0.02em', color: '#0C2728' },
  pLink: { color: '#368C86', fontFamily: 'LarsseitMedium', ':hover': { color: '#368C86', backgroundColor: 'unset' } },
  cardUl: { mt: { xs: 4, sm: 6 }, listStyle: 'none', mb: 0, p: 0, },
}