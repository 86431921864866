import BasicTable from "../../../common/BasicTable";
import {Box} from "@mui/system";
import styles from "../../Proactive/Utilities/templateStyles";
import CircularProgress from "@mui/material/CircularProgress";

export default function ResourcesTable({ rows, columns, totalCount, page, loading, handlePageChange, clickable }) {
    if(loading && _.isEmpty(rows)) {
        return(
            <Box sx={styles.emptyContainer}>
                <CircularProgress size={25} sx={{ color: '#07857C'}}/>
            </Box>
        )
    }

    return (
        <BasicTable
            rows={rows}
            columns={columns}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
            page={page}
            isPaginationDisabled={loading}
            clickable={clickable}
        />
    );
}
