import _ from "lodash";
import {getActionData} from "_helpers";
import {alertMessages, tiktokShopConstants} from "_constants";
import {Auth} from "../components/Authorization";
import {SnackBar} from "../components/common";
import {tiktokShopService} from "_services";
import TikTokShopSetupModal from "../components/v2/views/Channels/ConnectChannels/providers/TikTokShop/TikTokShopSetupModal";

function initiateAuth() {
    return (dispatch) => {
        dispatch(getActionData(tiktokShopConstants.INITIATE_AUTH_REQUEST));
        tiktokShopService.initiateAuth()
            .then(response => {
                console.log("response: ",response);
                dispatch(getActionData(tiktokShopConstants.INITIATE_AUTH_SUCCESS, response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(tiktokShopConstants.INITIATE_AUTH_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        })
    }
}

function createAccount(params) {
    return (dispatch) => {
        dispatch(getActionData(tiktokShopConstants.CREATE_ACCOUNT_REQUEST));
        tiktokShopService.createAccount(params)
            .then(response => {
                const status = _.get(response, 'status');
                if(status === 200) {
                    const shopCount = _.get(response, 'data.data.count');
                    if(shopCount > 1) {
                        dispatch(getActionData(tiktokShopConstants.SELECT_SHOP_RESPONSE, response.data));
                        TikTokShopSetupModal.open();
                    } else if(shopCount === 1) {
                        dispatch(getActionData(tiktokShopConstants.CREATE_ACCOUNT_SUCCESS, response.data));
                        SnackBar.showMessage(alertMessages.TIKTOK_SHOP_CREATE_ACCOUNT_SUCCESS);
                    }
                } else {
                    dispatch(getActionData(tiktokShopConstants.NO_ACCOUNT_FOUND, response.data));
                    SnackBar.showMessage(_.get(response, 'data.message'));
                }
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(tiktokShopConstants.CREATE_ACCOUNT_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        })
    }
}

function saveShop(payload) {
    return (dispatch) => {
        dispatch(getActionData(tiktokShopConstants.SAVE_SHOP_REQUEST));
        tiktokShopService.saveShop(payload)
            .then(response => {
                dispatch(getActionData(tiktokShopConstants.SAVE_SHOP_SUCCESS, response.data));
                SnackBar.showMessage(alertMessages.TIKTOK_SHOP_CREATE_ACCOUNT_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(tiktokShopConstants.SAVE_SHOP_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        })
    }
}

function getTikTokShopProduct(params) {
    return (dispatch) => {
        dispatch(getActionData(tiktokShopConstants.GET_PRODUCT_REQUEST));
        tiktokShopService.getTikTokShopProduct(params)
            .then(response => {
                dispatch(getActionData(tiktokShopConstants.GET_PRODUCT_SUCCESS, response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(tiktokShopConstants.GET_PRODUCT_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getTikTokShopOrderDetails(params) {
    return (dispatch) => {
        dispatch(getActionData(tiktokShopConstants.GET_ORDER_DETAILS_REQUEST));
        tiktokShopService.getTikTokShopOrderDetails(params)
            .then(response => {
                dispatch(getActionData(tiktokShopConstants.GET_ORDER_DETAILS_SUCCESS, response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(tiktokShopConstants.GET_ORDER_DETAILS_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getTikTokShopOrders(params) {
    return (dispatch) => {
        dispatch(getActionData(tiktokShopConstants.GET_ORDERS_REQUEST));
        tiktokShopService.getTikTokShopOrders(params)
            .then(response => {
                dispatch(getActionData(tiktokShopConstants.GET_ORDERS_SUCCESS, response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(tiktokShopConstants.GET_ORDERS_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

export const tiktokShopActions = {
    initiateAuth,
    createAccount,
    saveShop,
    getTikTokShopProduct,
    getTikTokShopOrderDetails,
    getTikTokShopOrders
};