
import React, { Component } from 'react'
import {CopyrightFooter} from "components/common";
import chatdeskLogo from "components/images/chatdesk-logo-dark.svg";
import {Link} from "react-router-dom";

class ConfirmRegistration extends Component{

    render(){
        return (
            <div>
                <div className="logo-pos-login">
                    <img src={chatdeskLogo} alt="Chatdesk logo" className="logo"/>
                </div>

                <div style={{textAlign:"center", padding:"40px 0"}} className="color-grey-dark">
                    <p className="font-35-heavy">
                        Thank you for registering
                    </p>
                    <p className="font-22-bold margin-top-12">
                        We'll reach out to you soon.
                    </p>
                    <p className="margin-top-50">
                        <Link to="/" className="btn btn-link">
                            Back to login
                        </Link>
                    </p>
                </div>

                <CopyrightFooter/>
            </div>
        )
    }
}

export { ConfirmRegistration  }