import React from 'react';
import { Form } from 'react-bootstrap';
import BasicFilterSelect from "../../v2/common/BasicFilterSelect";
import LoadingPlaceholderBlock from "../../common/LoadingPlaceholderBlock";

const SchedulesFilterForm = (props) => {
    const { organization, organizations,onChange } = props;
    const organizationName = organizations?.find((item) => item.id === organization)

    return (
        <Form>
            <span className="font-14 color-grey-dark">Organization</span>
            <div style={{marginTop: "3px"}}>
                {organizations?.length > 0 ?
                    <BasicFilterSelect
                        placeholder={'Select organization'}
                        options={organizations}
                        onChange={onChange}
                        value={organizationName}
                        listBoxHeight={380}
                    />
                    :
                    <LoadingPlaceholderBlock
                        width={'100%'}
                        height={'45px'}
                        borderRadius={'8px'}
                        backgroundColor={'#E3E2E1'}
                    />
                }
            </div>
        </Form>
    );
}

export { SchedulesFilterForm }
