import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';
import { SERVER_URL } from 'config';
import axios from "axios";

/**
 * Makes a request to get all messages that based on
 * the provided params, which are converted to a query string.
 *
 * @param params: filter parameters
 * @example {organization: 1232, handle: 32342, startDate: 2018-05-10, endDate: 2018-07-20}
 * @returns {Promise<Response>}
 */
function getAll(params) {
  return fetch(`${SERVER_URL}/api/messages?${objectToQueryString(params)}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

/**
 * Makes a request to get all priority messages that based on
 * the provided params, which are converted to a query string.
 *
 * @param params: filter parameters
 * @example {organization: 1232, handle: 32342, startDate: 2018-05-10, endDate: 2018-07-20}
 * @returns {Promise<Response>}
 */

function getPriorityMessages(params) {
  return fetch(`${SERVER_URL}/api/messages/priority?${objectToQueryString(params)}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}


/**
 * Retrieve a specific message by ID
 * @param id: String
 * @example '50m3r4nd0m-5tr1n9-451D0f'
 * @returns {Promise<Response>}
 */
function getMessage(id) {
  return fetch(`${SERVER_URL}/api/messages/${id}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

/**
 * Retrieve a specific message history by ID
 * @param messageId: String
 * @example '50m3r4nd0m-5tr1n9-451D0f'
 * @returns {Promise<Response>}
 */
function getMessageHistory(messageId) {
  return fetch(`${SERVER_URL}/api/messages/${messageId}/responseHistory`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}


/**
 * Retrieve a conversation that a message belongs to
 * @param messageId: String
 * @example '50m3r4nd0m-5tr1n9-451D0f'
 * @param params: Filter parameters
 * @returns {Promise<Response>}
 */
function getConversation(messageId, params) {
  return fetch(`${SERVER_URL}/api/messages/${messageId}/conversation?${objectToQueryString(params)}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

function getFullEmailConversation(messageId, platform) {
  const endpoint = `${SERVER_URL}/api/v1/conversations/email`;

  return axios.get(endpoint, {
    params: { messageId, platform },
    headers: authHeader()
  });
}

function downloadAttachment(messageId, attachmentURL) {
  const endpoint = `${SERVER_URL}/api/msgDataSource/downloadAttachment`;

  return axios.get(endpoint, {
    params: { messageId, attachmentURL },
    headers: authHeader()
  });
}

/**
 * Retrieve a conversation thread for a particular message to be displayed
 * @param messageId: String
 * @example '50m3r4nd0m-5tr1n9-451D0f'
 * @param params: Filter parameters
 * @returns {Promise<Response>}
 */
function getConversationFromTrends(messageId, params) {
    const endpoint = `${SERVER_URL}/api/messages/${messageId}/conversationFromTrends?${objectToQueryString(params)}`
    return axios.get(endpoint,{
        headers: authHeader()
    })
}

function getConversationFromTeams(messageId, conversationThreadView = false) {
    const endpoint = `${SERVER_URL}/api/messages/${messageId}/conversationFromTeams?conversationThreadView=${conversationThreadView}`
    return axios.get(endpoint,{
        headers: authHeader()
    })
}

/**
 * Get a next message for an agent to vote on.
 * @param params: An object containing the organization
 * @example {organization: 123nkjn-3232-23222dfdf}
 * @returns {Promise<Response>}
 */
function getNext(params) {
  return fetch(`${SERVER_URL}/api/v2/messages/next`,
    {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(params)
    })
    .then(handleHttpResponse);
}

function showNextVoteMessage(params) {
    const endpoint = `${SERVER_URL}/api/v2/messages/vote`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

function submitInternalNote(params) {
    const endpoint = `${SERVER_URL}/api/v2/userMessageActions/internalNote/create`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

function getInternalNoteAssignees(messageId) {
    const endpoint = `${SERVER_URL}/api/v2/userMessageActions/internalNote/listAssigneeCandidates`
    return axios.get(endpoint, {
        params: { messageId },
        headers: authHeader()
    })
}

function rerouteMessage(params) {
    const endpoint = `${SERVER_URL}/api/v2/userMessageActions/reroute`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

/**
 * Submit reviewer votes on a message
 * @param vote
 * @returns {Promise<Response | never>}
 */
function review(vote) {
  return fetch(`${SERVER_URL}/api/messages/review`,
    {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(vote),
    })
    .then(handleHttpResponse);
}

function update(message) {
  return fetch(`${SERVER_URL}/api/messages/${message.id}`,
    {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(message),
    })
    .then(handleHttpResponse);
}

function saveUserAction(payload) {
    let url = `${SERVER_URL}/api/userMessageActions/${payload.isReview ? 'reviewerAction' : 'expertAction'}`;
    if (payload.platform === "trends") {
        url = `${SERVER_URL}/api/userMessageActions/trendsMessageExpertAction`;
    }
  return fetch(url,
    {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload),
    })
    .then(handleHttpResponse);
}

/**
 * @param params
 * @param id
 * @returns {Promise<Response | never>}
 */
function getAllFromTrends(params, id) {
  const fetchUrl = !id && params.querySource && params.querySource === 'admin'
    ? `${SERVER_URL}/api/messages/messagesFromTrends?${objectToQueryString(params)}`
    : `${SERVER_URL}/api/organizations/${id}/messages?${objectToQueryString(params)}`
  return fetch(fetchUrl,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

/**
 * @param params
 * @param id
 * @returns {Promise<Response | never>}
 */
function getResponsePayload(id) {
  return fetch(`${SERVER_URL}/api/messages/messageResponsePayload?messageId=${id}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

/**
 * Get List of authors for messages (filtered by organizations if exist)
 * @param organizationId
 * @returns {Promise<Response | never>}
 */
function getAuthors(params) {
  return fetch(`${SERVER_URL}/api/messages/authors?${objectToQueryString(params)}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

/**
 * Get Billing Data for a specific month for a given organization
 * @param organizationId
 * @returns {Promise<Response | never>}
 */
function getBilling(params) {
  const queryParams = params.month ? `?month=${params.month}` : '';
  return fetch(`${SERVER_URL}/api/organizations/${params.organization}/billing${queryParams}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

function searchAdminMessages(searchParams) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(searchParams)
    };

    return fetch(`${SERVER_URL}/api/search`, requestOptions).then(handleHttpResponse);
}


/**
 * Lock a specific message by ID
 * @param message: String
 * @example '50m3r4nd0m-5tr1n9-451D0f'
 * @returns {Promise<Response>}
 */
function lockMessage(messageId, userId) {
    return fetch(`${SERVER_URL}/api/messages/lock`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                messageId,
                userId
            })
        })
        .then(handleHttpResponse);
}

function exportAdminSearch(searchParams) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(searchParams)
    };

    return fetch(`${SERVER_URL}/api/search/export`, requestOptions).then(response => {
            response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'Teams Search.csv';
                anchor.click();
        });
    });
}

function blacklistMessages(searchParams) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(searchParams)
  };
  return fetch(`${SERVER_URL}/api/search/blacklist`, requestOptions).then(handleHttpResponse)
}

function blacklistAllMessages(searchParams) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(searchParams)
  };
  return fetch(`${SERVER_URL}/api/search/blacklistAllMessages`, requestOptions).then(handleHttpResponse)
}

function getResponseTimes(params) {
    return fetch(`${SERVER_URL}/api/responseTimes?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

const getAllMessages = (params) => {
  const endpoint = `${SERVER_URL}/api/messages/all?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
};

const getAllMessagesFromTrends = (params) => {
  const endpoint = `${SERVER_URL}/api/messages/trends/all?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  })
};

const getPaidMessageAction = (params) => {
  const endpoint = `${SERVER_URL}/api/messages/paid/actions?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
};

function undoMessage (messageId){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: messageId
    };

    return fetch(`${SERVER_URL}/api/messages/${messageId}/undo`, requestOptions)
        .then(handleHttpResponse);
}

const getMessageStatus = (params) => {
  const endpoint = `${SERVER_URL}/api/messages/status?${objectToQueryString(params)}`;

  return axios.get(endpoint, {
    headers: authHeader()
  });
};

const getTrendsCorrespondingMessages = (trendsMessageIds) => {
  const endpoint = `${SERVER_URL}/api/messages/trendsCorrespondingMessages`;

  return axios.get(endpoint, {
    params: { trendsMessageIds },
    headers: authHeader()
  });
};

const getMessagesInTicket = (ticketId, organizationId, isTrends) => {
  const endpoint = `${SERVER_URL}/api/messages/ticket`;

  return axios.get(endpoint, {
    params: { ticketId, organizationId, isTrends },
    headers: authHeader()
  });
};

const getCompanyReplied = (trendsMessageId) => {
  const endpoint = `${SERVER_URL}/api/userMessageActions/companyReplied`;

  return axios.get(endpoint, {
    params: { trendsMessageId },
    headers: authHeader()
  });
};

const sendForResponseGeneration = (requestObject) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(requestObject)
    };

    return fetch(`${SERVER_URL}/api/messages/sendForResponseGeneration`, requestOptions)
        .then(handleHttpResponse);
};

const messagesForResponseGeneration = (requestData) => {
    const endpoint = `${SERVER_URL}/api/messages/messagesForResponseGeneration?${objectToQueryString(requestData)}`;

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(endpoint, requestOptions)
        .then(handleHttpResponse);
};

const getMessagesRequiringAction = (requestData) => {
  const endpoint = `${SERVER_URL}/api/messagesRequiringAction?${objectToQueryString(requestData)}`;

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(endpoint, requestOptions)
    .then(handleHttpResponse);
}

const deleteUserMessages = (requestData) => {
    const endpoint = `${SERVER_URL}/api/messages/deleteUserMessages?${objectToQueryString(requestData)}`;

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(endpoint, requestOptions)
        .then(handleHttpResponse);
};

const confirmMessageHasReplyOrIsDeleted = (requestData) => {
  const endpoint = `${SERVER_URL}/api/messageHasReplyOrIsDeleted?${objectToQueryString(requestData)}`;

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(endpoint, requestOptions)
    .then(handleHttpResponse);
}

function tickMessageAsActioned(messageIds, platform) {
    let url = `${SERVER_URL}/api/messages/action`;
    const data = {messageIds, platform}
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(url, requestOptions)
        .then(handleHttpResponse);

}

function findAndReplaceText(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${SERVER_URL}/api/search/findAndReplace`, requestOptions).then(handleHttpResponse)
}

function patchMessageReview(messageId, isReviewed) {
    const requestBody = {
        "isReviewed": isReviewed
    }

    return fetch(`${SERVER_URL}/api/messages/${messageId}/review`,
        {
            method: 'PATCH',
            headers: authHeader(),
            body: JSON.stringify(requestBody),
        })
        .then(handleHttpResponse);
}

export const messageService = {
  getAll,
  getMessage,
  getMessageHistory,
  getNext,
  update,
  review,
  saveUserAction,
  getConversation,
  getConversationFromTeams,
  getConversationFromTrends,
  getAllFromTrends,
  getAuthors,
  getPriorityMessages,
  getResponsePayload,
  getBilling,
  searchAdminMessages,
  exportAdminSearch,
  blacklistMessages,
  blacklistAllMessages,
  getFullEmailConversation,
  getAllMessages,
  getAllMessagesFromTrends,
  getPaidMessageAction,
  lockMessage,
  undoMessage,
  getMessageStatus,
  getTrendsCorrespondingMessages,
  getCompanyReplied,
  messagesForResponseGeneration,
  sendForResponseGeneration,
  getMessagesInTicket,
  getResponseTimes,
  getMessagesRequiringAction,
  deleteUserMessages,
  confirmMessageHasReplyOrIsDeleted,
  downloadAttachment,
  findAndReplaceText,
  tickMessageAsActioned,
  patchMessageReview,
  showNextVoteMessage,
  submitInternalNote,
  rerouteMessage,
  getInternalNoteAssignees
};
