import _ from "lodash";
import {getArrayFromObjectArray, removeItemFromArray} from "_helpers";

/**
 * @param searchWord {string}
 * @param options {array}
 * @returns {*[]}
 */
export function search(searchWord, options) {
    if(!searchWord || _.isEmpty(options)) return [];

    return options && options.filter(option => {
        if (!_.has(option, 'name')) return false;
        return _.toString(option.name).toLowerCase().includes(_.toString(searchWord).toLowerCase());
    });
}

/**
 * @param event {object}
 * @param checkedItemsValues {array}
 */
export function selectItem(event, checkedItemsValues) {
    let value = _.get(event, 'target.value');
    let checked = _.get(event, 'target.checked');
    if(checked) {
        checkedItemsValues && checkedItemsValues.push(value);
        checkedItemsValues = [...new Set(checkedItemsValues)];
    }
    else checkedItemsValues && removeItemFromArray(checkedItemsValues, value);
    return checkedItemsValues;
}

/**
 * @param clickedIndex {number}
 * @param clickedIndexSet {set}
 * @param checkedItemsValues {array}
 * @param optionArr {array}
 */
export function selectOnShiftKeyDown(clickedIndex, clickedIndexSet, checkedItemsValues, optionArr) {
    const range = [...clickedIndexSet].sort();
    const slicedArrObj = optionArr.slice(range[0], range[1] + 1);
    const slicedArrVal = _.toString(getArrayFromObjectArray(slicedArrObj, 'value')).split(',');
    const lastClickedItem = optionArr[clickedIndex];
    const lastClickedValue = _.toString(lastClickedItem.value);
    if(checkedItemsValues.includes(lastClickedValue)) {
        const result = new Set(checkedItemsValues.concat(slicedArrVal));
        return [...result];
    }
    return checkedItemsValues.filter(value => !slicedArrVal.includes(value));
}

/**
 * @param options {array}
 * @param event {object}
 */
export function selectAll(options, event) {
    let checked = _.get(event, 'target.checked');
    if(!checked) return [];
    const values = []
    options.map(item => values.push(_.toString(_.get(item, 'value'))));
    return values;
}

/**
 * @param allItems {array}
 * @param selectedItems {array}
 * @returns {boolean}
 */
export function isAllSelected(allItems, selectedItems) {
    let _isAllChecked = selectedItems.length !== 0 && selectedItems.length === allItems.length;
    let isSubset = false;
    if(_isAllChecked) {
        let _allValues = _.toString(getArrayFromObjectArray(allItems, 'value')).split(',');
        isSubset = selectedItems.every(function(val) {
            return _allValues.indexOf(val) >= 0;
        });
    }
    return isSubset;
}
