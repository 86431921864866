import { SERVER_URL } from 'config';
import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';


function initiateOauth(callbackUrl) {
    return fetch(`${SERVER_URL}/api/helpscout/oauthCode?callbackUrl=${callbackUrl}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function oauthAccessToken(params) {
    return fetch(`${SERVER_URL}/api/helpscout/oauthToken?${objectToQueryString(params)}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}



export const helpScoutOauthService = {
    initiateOauth,
    oauthAccessToken,
}