import {Fragment} from "react";
import {Box} from "@mui/system";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";

export default function ExpertManagementEmptyState() {
    return(
        <Fragment>
            <Box sx={{width: '132px', mt: '5px'}}>
                <LoadingPlaceholderBlock
                    height={'18px'}
                    borderRadius={'5px'}
                    backgroundColor={'#d7d2cf'}
                />
            </Box>
            <Box sx={{width: '100px', mt: '40px'}}>
                <LoadingPlaceholderBlock
                    height={'12px'}
                    borderRadius={'4px'}
                    backgroundColor={'#d7d2cf'}
                />
            </Box>
            <Box sx={{width: '150px', mt: '10px'}}>
                <LoadingPlaceholderBlock
                    height={'14px'}
                    borderRadius={'5px'}
                    backgroundColor={'#d7d2cf'}
                />
            </Box>
        </Fragment>
    )
}