import facebookLogo from "components/images/logos/logo_facebook.svg";
import ConnectChannelButton from "../Utilities/ConnectChannelButton";
import React, {Fragment, useEffect} from "react";
import {appCredentiasActions} from "_actions";
import {facebookOauthService} from "_services";
import {useDispatch, useSelector} from "react-redux";


export default function FacebookSetup(){
  const dispatch = useDispatch();
  const credentials = useSelector((state) => state.appcredentials);

  useEffect(() => {
    dispatch(appCredentiasActions.getFacebookappCredentials());
  }, []);

  useEffect(() => {
    const facebookCreds = credentials.data;
    const {appId} = facebookCreds;
    if (appId) {
      facebookOauthService.loadFacebookApi(appId);
    }
  });

  const performAction = () => {
    facebookOauthService.facebookLogin();
  };

  return(
    <Fragment>
      <ConnectChannelButton
        label={'Connect with Facebook'}
        channelIcon={facebookLogo}
        useCircleAvatar={true}
        action={performAction}/>
    </Fragment>
  )
}