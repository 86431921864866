import React, {Component} from 'react';
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";
import ChannelSelectionModalContent from "./ChannelSelectionModalContent";

let _this;

export default class YoutubeSetupModal extends Component{
    constructor(props){
        super(props);

        this.state = { open: false, channel: '' };
        _this = this;
    }

    static open(){
        _this.setState({ open: true }) //this is invoked from outside the component
    }
    static close(){
        _this.setState({ open: false }); //this is invoked from outside the component
    }
    handleClose = () => {
        _this.setState({ open: false }); //this is invoked within the component
    };

    render() {
        return(
            <BasicModal
                open={this.state.open}
                handleClose={this.handleClose}
                title={`Select a channel to connect with Chatdesk`}>
                <ChannelSelectionModalContent handleClose={this.handleClose}/>
            </BasicModal>
        )
    }
}