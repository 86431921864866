import {slackOauthService} from '_services';
import {oauthConstants, alertMessages} from '_constants';
import {SnackBar} from "../components/common";

function initiateOauth() {
    function request() {
        return {type: oauthConstants.SLACK_OAUTH_URL_REQUEST};
    }

    function success(oauthUrl) {
        return {type: oauthConstants.SLACK_OAUTH_URL_SUCCESS, payload: oauthUrl};
    }

    function failure(error) {
        return {type: oauthConstants.SLACK_OAUTH_URL_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        slackOauthService.getOauthUrl()
            .then(
                oauthUrl => dispatch(success(oauthUrl)),
                (error) => {
                    dispatch(failure());
                },
            );
    };
}

function processOauthCode(params) {
    function request() {
        return {type: oauthConstants.SLACK_OAUTH_CODE_REQUEST};
    }

    function success(res) {
        return {type: oauthConstants.SLACK_OAUTH_CODE_SUCCESS, payload: res};
    }

    function failure(error) {
        return {type: oauthConstants.SLACK_OAUTH_CODE_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        slackOauthService.processOauthCode(params)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.SLACK_OAUTH_SUCCESS)
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SLACK_OAUTH_FAILURE)
                },
            );
    };
}

function disconnectSlack(id){
    function request(){
        return {type: oauthConstants.SLACK_OAUTH_DISCONNECT_REQUEST }
    }
    function success(response) {
        return {type: oauthConstants.SLACK_OAUTH_DISCONNECT_SUCCESS, payload: response };
    }

    function failure(error) {
        return {type: oauthConstants.SLACK_OAUTH_DISCONNECT_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        slackOauthService.disconnectSlack(id)
            .then(
                (response) => {
                    if (response) {
                        dispatch(success(response));
                        SnackBar.showMessage(alertMessages.SLACK_OAUTH_DISCONNECT_SUCCESS)
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SLACK_OAUTH_DISCONNECT_FAILURE)
                },
            );
    };

}



export const slackOauthActions = {
    initiateOauth,
    processOauthCode,
    disconnectSlack,
};