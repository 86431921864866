import {Fragment} from "react";
import {Box} from "@mui/system";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";

export default function QueueManagementEmptyState() {
    return(
        <Fragment>
            <Box sx={{width: '132px'}}>
                <LoadingPlaceholderBlock
                    height={'16px'}
                    borderRadius={'5px'}
                    backgroundColor={'#d7d2cf'}
                />
            </Box>
            <Box sx={{width: '50px', mt: '15px'}}>
                <LoadingPlaceholderBlock
                    height={'16px'}
                    borderRadius={'5px'}
                    backgroundColor={'#d7d2cf'}
                />
            </Box>
        </Fragment>
    )
}