import {Box} from "@mui/system";
import ConversationItem from "../../ConversationItem";

export default function ConversationEntry({ message = {}, conversation, refProp }) {
    return(
        <Box>
            {
                conversation.map((item, index) => (
                    <ConversationItem
                        refProp={refProp}
                        key={index}
                        conversationItem={item}
                        message={message}
                        index={index}
                    />
                ))
            }
        </Box>
    )
}