export const youtubeConstants = {
    INITIATE_OAUTH_REQUEST: 'INITIATE_OAUTH_REQUEST',
    INITIATE_OAUTH_SUCCESS: 'INITIATE_OAUTH_SUCCESS',
    INITIATE_OAUTH_FAILURE: 'INITIATE_OAUTH_FAILURE',

    CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
    CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
    CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',

    SAVE_CHANNEL_REQUEST: 'SAVE_CHANNEL_REQUEST',
    SAVE_CHANNEL_SUCCESS: 'SAVE_CHANNEL_SUCCESS',
    SAVE_CHANNEL_FAILURE: 'SAVE_CHANNEL_FAILURE',

    NO_ACCOUNT_FOUND: 'NO_ACCOUNT_FOUND',
}