import React, {Component} from 'react';
import chatdeskLogo from 'components/images/chatdesk-logo-dark.svg'
import {Grid, Row} from "react-bootstrap";
import { CopyrightFooter } from "components/common";
import {styles} from 'components/LoginPage/styles'
import {authService} from "_services";
import { connect } from 'react-redux';

/**
 * @author Chris Okebata
 */


class ResetPassword extends Component{

    constructor(props) {
        super(props);

        this.state = {
            newPassword: '',
            confirmPassword: '',
            loading: false
        };
    }

    handleChange = (e) =>  {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ loading: true });
        const { dispatch } = this.props;
        const { newPassword, confirmPassword } = this.state;

        if (newPassword.trim() && confirmPassword.trim()) {
            dispatch(authService.resetPassword(newPassword, confirmPassword, this.props.encryptedUserId))
        }
    };


    render(){

        const { newPassword, confirmPassword } = this.state;
        const { inputFieldContainer } = styles;
        const { button, confirmPasswordContainer, newPasswordContainer} = inputFieldContainer;

        return(
            <Grid fluid={true} className="main-content-wrapper">
                <div className="logo-pos-login">
                    <img src={chatdeskLogo} alt="Chatdesk logo" className="logo"/>
                </div>

                <Row className='container-fluid'>
                    <div className="form-container form-container-reset-password">
                        <div className="color-grey-dark font-18-bold" style={{padding: "40px 0 30px 0", textAlign:"center"}}>
                            Create a new password
                        </div>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div style={newPasswordContainer}>
                                <input type="password"
                                       className="input-field"
                                       name="newPassword"
                                       value={newPassword}
                                       onChange={this.handleChange}
                                       required/>
                                <label htmlFor="newPassword"><span>New Password</span></label>
                            </div>
                            <div style={confirmPasswordContainer}>
                                <input type="password"
                                       className="input-field"
                                       name="confirmPassword"
                                       value={confirmPassword}
                                       onChange={this.handleChange}
                                       required/>
                                <label htmlFor="confirmPassword"><span>Confirm Password</span></label>
                            </div>
                            <div style={button}>
                                <button className="btn btn-block btn-success font-15" type="submit" disabled={this.props.resettingPassword}>Create Password</button>
                            </div>
                        </form>
                    </div>
                </Row>
                <CopyrightFooter/>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    const { auth: { resettingPassword, resetPasswordResponse } } = state;
    return {
        resettingPassword,
        resetPasswordResponse
    };
}

const connectedForgotPassword = connect(mapStateToProps)(ResetPassword);
export { connectedForgotPassword as ResetPassword };
