import {useMemo} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";

export default function (productId) {
    const tiktokShop = useSelector(state => state.tiktokShop);
    const products = _.get(tiktokShop, 'products') || [];
    const loading = _.get(tiktokShop, 'loadingProduct');
    const error = _.get(tiktokShop, 'productError');

    const product = useMemo(() => {
        if(!loading && products.length > 0) {
            return products.find( item => {
                return item.id === productId
            });
        }
    }, [loading, products]) || [];

    return { loading, product, error };
}