import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {helpScoutOauthActions} from "_actions";
import {Loading} from 'components/common';
import {history} from "_helpers";

export default function HelpScoutUrlRedirect() {
  const dispatch = useDispatch();
	const url = new URL(document.location);
	const values = new URLSearchParams(url.search);
	const helpScoutAuthCode = values.get('code');

  useEffect(() => {
		if (helpScoutAuthCode) {
			const params = { code: helpScoutAuthCode};
			dispatch(helpScoutOauthActions.genAccessToken(params));
		}
  }, []);

	const helpScout = useSelector((state) => state.helpScoutOauth);

  if (helpScout.loading) {
    return <Loading />;
  }

  history.push('/company/connectchannels');

  return(
    <Fragment/>
  )
}