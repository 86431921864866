import {alertMessages, screenshotConstants} from "_constants";
import {history} from '_helpers';
import {SnackBar} from "components/common";
import {screenshotServices} from "_services";

export const screenshotActions = {
    getAll,
    getScreenshot,
    addScreenshot,
    updateScreenshot,
    viewScreenshot,
    sendMail,
};

function getAll(params) {
    return dispatch => {
        dispatch(request());

        screenshotServices.getAll(params)
            .then(
                screenshots => dispatch(success(screenshots)),
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.SCREENSHOT_FETCH_FAILURE)
                }
            );
    };

    function request() {
        return {type: screenshotConstants.GET_ALL_REQUEST}
    }

    function success(screenshots) {
        return {type: screenshotConstants.GET_ALL_SUCCESS, payload: screenshots}
    }

    function failure(error) {
        return {type: screenshotConstants.GET_ALL_FAILURE, payload: error}
    }
}

function getScreenshot(id) {
    return dispatch => {
        dispatch(request());

        screenshotServices.getScreenshot(id)
            .then(
                screenshot => dispatch(success(screenshot)),
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.SCREENSHOT_FETCH_FAILURE)
                }
            );
    };

    function request() {
        return {type: screenshotConstants.GET_SCREENSHOT_REQUEST}
    }

    function success(screenshot) {
        return {type: screenshotConstants.GET_SCREENSHOT_SUCCESS, payload: screenshot}
    }

    function failure(error) {
        return {type: screenshotConstants.GET_SCREENSHOT_FAILURE, payload: error}
    }
}

function addScreenshot(props, currentPath) {
    return dispatch => {
        dispatch(request());

        screenshotServices.addScreenshot(props)
            .then(
                screenshot => {
                    if (screenshot){
                        history.push(`${currentPath}`); //@todo redirect to index here
                        dispatch(success(screenshot));
                        SnackBar.showMessage(alertMessages.SCREENSHOT_SUCCESS);
                    }
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.SCREENSHOT_FAILURE)
                }
            );
    };

    function request() {
        return {type: screenshotConstants.SAVE_SCREENSHOT_REQUEST}
    }

    function success(result) {
        return {type: screenshotConstants.SAVE_SCREENSHOT_SUCCESS, payload: result}
    }

    function failure(error) {
        return {type: screenshotConstants.SAVE_SCREENSHOT_FAILURE, payload: error}
    }
}



function updateScreenshot(props) {

    return dispatch => {
        dispatch(request());

        screenshotServices.updateScreenshot(props)
            .then(
                response => {
                    // history.push(`/schedules`); //@TODO REDIRECT TO INDEX AFTER SUCCESS
                    dispatch(success(response));
                    SnackBar.showMessage(alertMessages.SCREENSHOT_UPDATE_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.SCREENSHOT_UPDATE_FAILURE)
                }
            );
    };

    function request() {
        return {type: screenshotConstants.UPDATE_SCREENSHOT_REQUEST}
    }

    function success(response) {
        return {type: screenshotConstants.UPDATE_SCREENSHOT_SUCCESS, payload: response}
    }

    function failure(error) {
        return {type: screenshotConstants.UPDATE_SCREENSHOT_FAILURE, payload: error}
    }
}

function viewScreenshot(id) {
    return dispatch => {
        dispatch(request());

        screenshotServices.viewScreenshot(id)
            .then(
                screenshot => dispatch(success(screenshot)),
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.DOWNLOAD_SCREENSHOT_FAILURE)
                }
            );
    };

    function request() {
        return {type: screenshotConstants.DOWNLOAD_SCREENSHOT_REQUEST}
    }

    function success(screenshot) {
        return {type: screenshotConstants.DOWNLOAD_SCREENSHOT_SUCCESS, payload: screenshot}
    }

    function failure(error) {
        return {type: screenshotConstants.DOWNLOAD_SCREENSHOT_FAILURE, payload: error}
    }
}

function sendMail(id, props) {

    return dispatch => {
        dispatch(request());

        screenshotServices.sendMail(id, props)
            .then(
                screenshot => {
                    if (screenshot){
                        history.push(`/screenshots`); //@todo redirect to index here
                        dispatch(success(screenshot));
                        SnackBar.showMessage(alertMessages.SCREENSHOT_MAIL_SUCCESS);
                    }
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.SCREENSHOT_MAIL_FAILURE)
                }
            );
    };

    function request() {
        return {type: screenshotConstants.SEND_EMAIL_REQUEST}
    }

    function success(result) {
        return {type: screenshotConstants.SEND_EMAIL_SUCCESS, payload: result}
    }

    function failure(error) {
        return {type: screenshotConstants.SEND_EMAIL_FAILURE, payload: error}
    }
}

const displayErrorMessage = (error, fallback) => {
    const message = error && error.message ? error.message : fallback;
    SnackBar.showMessage(message)
};
