import { Box, Grid, Divider } from '@mui/material';
import DashboardCard from '../Utilities/DashboardCard';
import ExpertInfoSectionSchedule from './ExpertInfoSectionSchedule';
import ExpertInfoSectionPerformance from './ExpertInfoSectionPerformance';
import useExpertsTicketMetrics from "../Utilities/hooks/useExpertsTicketMetrics";
import useScheduleSummary from "../Utilities/hooks/useScheduleSummary";

export default function ExpertInfoSection({ startDate, endDate }) {
	const scheduleSummaryData = useScheduleSummary()
	const { expertsData, loadingExpertsTicketMetrics } = useExpertsTicketMetrics(startDate, endDate);

	return (
		<DashboardCard
			isLoading={loadingExpertsTicketMetrics}
			headerTitle='Ticket information'>

			<Divider orientation={'vertical'} flexItem sx={styles.sectionDivider} />

			<Grid container columns={2} spacing={8}>
				{/* Schedule Table */}
				<Grid item sm={2} md={1}>
					<Box sx={styles.section}>
						<ExpertInfoSectionSchedule scheduleSummaryData={scheduleSummaryData} />
					</Box>
				</Grid>

				{/* Table */}
				<Grid item sm={2} md={1} sx={styles.gridItemForTable}>
					<Box sx={styles.section}>
						<ExpertInfoSectionPerformance data={expertsData} />
					</Box>
				</Grid>
			</Grid>
		</DashboardCard>
	)
}

const styles = {
	gridItemForTable: {
		width: '100%',
	},
	section: {
		py: { xs: 2, md: 3 },
	},
	sectionDivider: {
		display: { xs: 'none', md: 'block' },
		position: 'absolute',
		height: '100%',
		left: '50%',
	},
}