import {useEffect, useRef, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {responseOptionActions} from "_actions";

export default function () {
    const dispatch = useDispatch();
    const effectRan = useRef(false);
    const message = useSelector(state => state.messages.nextMessage);
    const userAddedResponseOptions = useSelector(state => state.responseOptions.userAddedResponseOptions);
    const loadingUserAddedResponseOptions = useSelector(state => state.responseOptions.loadingUserAddedResponseOptions);
    const loadingAddResponseOption = useSelector(state => state.responseOptions.loadingAddResponseOption);
    const addedCount = useSelector(state => state.responseOptions.addedCount);
    const loading = loadingUserAddedResponseOptions || loadingAddResponseOption;
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const responseOptions = _.get(userAddedResponseOptions, 'responseOptions') || [];
    const count = _.get(userAddedResponseOptions, 'count') || 0;

    useEffect(() => {
        if(effectRan.current === false) {
            getUserAddedResponseOptions();
            return () => effectRan.current = true;
        }
    }, []);

    const getUserAddedResponseOptions = () => {
        const messageId = _.get(message, 'id');
        const userId = _.get(currentUser, 'id');
        dispatch(responseOptionActions.getUserAddedResponses(messageId, userId));
    }

    const data = useMemo(() => {
        return {responseOptions, count: _.max([count, addedCount])}
    }, [loading]);

    return { responseOptions, count: data.count, loadingUserAddedResponseOptions };
}