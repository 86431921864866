import {fragmentId} from "./organizationSettingsConstants";

export default function() {
    return {
        sections: [
            {
                name: fragmentId.INFO,
                label: {
                    COMPANY_INFORMATION: 'Company Information',
                    CONTACT_EMAIL: 'Contact Email',
                    CONTRACTED_VOLUME: 'Contracted volume',
                    CSM: 'Customer success manager',
                    VISIBILITY: 'Visibility',
                    HIDE_IN_MOBILE: 'Hide in mobile',
                    TICKET_BUDGET: 'Ticket budget',
                    USED_TICKETS: 'Used tickets',
                    PRODUCTS: 'Products',
                }
            },
            {
                name: fragmentId.QUEUE,
                label: {
                    TRIAGE_AND_QUEUE_MANAGEMENT: 'Triage and Queue Management',
                    MESSAGE_RETENTION_DURATION: 'Message retention duration',
                    ENABLE_REQUIRED_TAGS: 'Enable required tags'
                }
            },
            {
                name: 'expert',
                label: {
                    EXPERT_MANAGEMENT: 'Expert Management',
                    SCHEDULES: 'Schedules'
                }
            },
            {
                name: fragmentId.NOTIFICATIONS,
                label: {
                    NOTIFICATIONS: 'Notifications',
                    WEEKLY_METRICS_EMAIL: 'Weekly metrics email',
                    SHOPIFY_REVENUE_TRACKING: 'Shopify revenue tracking',
                    GORGIAS_REVENUE_TRACKING: 'Gorgias revenue tracking',
                    EMAIL_ESCALATION: 'Escalations (Email)',
                    PRESALE_REMAINDER: 'Proactive presale',
                }
            },
            {
                name: fragmentId.DATA_SOURCES,
                label: {
                    ACTIVE_DATA_SOURCES: 'Active Data Sources',
                    SUPERVISED_CHAT: 'Supervised chat',
                    BASIC_CHAT: 'Basic chat',
                    AGENT_EDITS: 'Agent edits',
                    VISIBILITY: 'Visibility',
                    GORGIAS: 'Gorgias',
                    CUSTOM_FIELDS_ON_CLOSE: 'Custom fields on close',
                }
            },
            {
                name: fragmentId.RESPONSES,
                label: {
                    MESSAGE_RESPONSES: 'Message Responses',
                    AUTO_ACTION: 'AUTO ACTION IS ACTIVE',
                    SKIP_ENABLED: 'IS SKIP ENABLED',
                    AUTO_REPLY_ENABLED: 'IS AUTO-REPLY ENABLED',
                    TOPICS: 'TOPICS',
                    AUTO_REPLIES: 'AUTO REPLIES',
                    FIRST_RESPONSES: 'ENABLE AUTOMATIC FIRST RESPONSES WHEN QUEUE IS BUSY',
                    AUTO_RESPONDER: 'MINIMUM QUEUE VOLUME (MESSAGES) TO TRIGGER AUTO-RESPONDER',
                    FIRST_RESPONSE_FOOTER: 'FIRST RESPONSE FOOTER'
                }
            },
            {
                name: fragmentId.OFF_BOARD,
                label: {
                    OFF_BOARD: 'Offboard',
                    REMOVE_COMPANY: 'Remove company'
                }
            },
        ]
    }
}