import React from 'react'
import LoadingPlaceholderBlock from "../../../../../components/common/LoadingPlaceholderBlock";

const MetricsMessageListLoader = () => {
    return (
        <div>
            <div className="message-list-container" style={{borderColor: 'rgb(239, 234, 229)'}}>
                <div className="margin-right-15">
                    <LoadingPlaceholderBlock width={'403px'} height={'38px'} borderRadius={'3px'} backgroundColor={'rgb(239, 234, 229)'} />
                </div>
                <div className="margin-right-15">
                    <LoadingPlaceholderBlock height={'18px'} width={'80px'} borderRadius={'3px'} backgroundColor={'rgb(239, 234, 229)'} />
                </div>
            </div>
            <div className="message-list-container" style={{borderColor: 'rgb(239, 234, 229)'}}>
                <div className="margin-right-15">
                    <LoadingPlaceholderBlock width={'403px'} height={'38px'} borderRadius={'3px'} backgroundColor={'rgb(239, 234, 229)'} />
                </div>
                <div className="margin-right-15">
                    <LoadingPlaceholderBlock height={'18px'} width={'80px'} borderRadius={'3px'} backgroundColor={'rgb(239, 234, 229)'} />
                </div>
            </div>
            <div className="message-list-container" style={{borderColor: 'rgb(239, 234, 229)'}}>
                <div className="margin-right-15">
                    <LoadingPlaceholderBlock width={'403px'} height={'38px'} borderRadius={'3px'} backgroundColor={'rgb(239, 234, 229)'} />
                </div>
                <div className="margin-right-15">
                    <LoadingPlaceholderBlock height={'18px'} width={'80px'} borderRadius={'3px'} backgroundColor={'rgb(239, 234, 229)'} />
                </div>
            </div>
        </div>
    )
}

export { MetricsMessageListLoader }