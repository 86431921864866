import ConnectChannelButton from "../Utilities/ConnectChannelButton";
import React, {Fragment, useEffect} from "react";
import instagramLogo from "components/images/logos/logo_instagram.svg";
import {useDispatch, useSelector} from "react-redux";
import {appCredentiasActions} from "_actions";
import {facebookOauthService, instagramOauthService} from "_services";


export default function InstagramSetup(){
  const dispatch = useDispatch();
  const credentials = useSelector((state) => state.appcredentials);

  useEffect(() => {
    dispatch(appCredentiasActions.getFacebookappCredentials());
  }, []);

  useEffect(() => {
    const facebookCreds = credentials.data;
    const {appId} = facebookCreds;
    if (appId) {
      facebookOauthService.loadFacebookApi(appId);
    }
  });

  const performAction = () => {
    instagramOauthService.facebookLogin();
  };

  return(
    <Fragment>
      <ConnectChannelButton
        label={'Connect with Instagram'}
        channelIcon={instagramLogo}
        action={performAction}/>
    </Fragment>
  )
}