import React, {Component} from 'react'
import {connect} from 'react-redux'
import {DeleteButton} from "components/common";
import {Row, Col} from "react-bootstrap";
import {msgDataSourceActions} from "_actions";

class MsgDataSources extends Component {

    constructor(props){
        super(props);
        this.deleteDataSource = this.deleteDataSource.bind(this)
    }

    deleteDataSource(id){
        this.props.dispatch(msgDataSourceActions.deleteMsgDataSource(id))
    }

    render() {
        const {msgDataSources} = this.props;

        if (msgDataSources.length === 0){
            return (
                <div className="main-content-wrapper">
                    <div className="content-pane-60 margin-left-150" style={{marginTop:'80px'}}>
                        No message data sources available for this message source
                    </div>
                </div>
            )
        }
        else
            return (
                <div className="main-content-wrapper">
                    <div className="content-pane-60 margin-left-150" style={{marginTop: '80px'}}>
                        {msgDataSources.map(msgDataSource => {
                            return (
                                <Row key={msgDataSource.id}>
                                    <Col sm={5}>
                                        <span>{msgDataSource.name}</span>
                                    </Col>
                                    <Col sm={7}>
                                        <DeleteButton
                                            onClick={() => this.deleteDataSource(msgDataSource.id)}
                                        />
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>
                </div>
            );
    }

}


function mapStateToProps(state) {

    return {
        msgDataSources: state.msgDataSources.msgDataSourcesForMsgSource
    }
}

const connectedMsgDataSources = connect(mapStateToProps, null)(MsgDataSources);
export {connectedMsgDataSources as MsgDataSources}
