import { organizationConstants } from '_constants';

const INITIAL_STATE = {
  organizations: [],
  activeOrganizations: {},
  perfectResponsesSummary: [],
  organization: {},
  organizationDetails: {
    users: [],
    usersCount: 0,
    params: { offset: 0, max: 0 },
    teamLead: [],
    csm: [],
    companyAdminForEscalationMails: []
  },
  summaryStats: { solvedTickets: 0, lowPriority: 0, highPriority: 0, summaryStats: [] },
  loading: false,
  organizationsPaidMessages: [],
  getOrganizationsPaidTickets: [],
  organizationsProfitability: [],
  organizationSlackStatus: {},
  error: '',

  orgPaidMessageLoading: false,

  organizationsCsat: [],
  organizationsCsatLoading: false,
  organizationsCsatError: '',

  organizationsActiveExperts: [],
  organizationsActiveExpertsLoading: false,
  organizationsActiveExpertsError: '',

  organizationsReviewsStats: [],
  organizationsReviewsStatsLoading: false,
  organizationsReviewsStatsError: '',

  deleteOrganizationLogo: '',
  deleteOrganizationLogoLoading: false,
  deleteOrganizationLogoError: ''
};

export function organizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case organizationConstants.GET_SUMMARY_STATS_DATA_REQUEST:
    case organizationConstants.GET_DETAILS_REQUEST:
    case organizationConstants.GET_ORGANIZATION_USERS_REQUEST:
    case organizationConstants.GET_ALL_REQUEST:
    case organizationConstants.EXPORT_ORGANIZATION_STATS:
    case organizationConstants.GET_ACTIVE_ORGANIZATIONS_REQUEST:
      return { ...state, loading: true, error: '' };

    case organizationConstants.GET_SUMMARY_STATS_DATA_FAILURE:
    case organizationConstants.GET_DETAILS_FAILURE:
    case organizationConstants.GET_ORGANIZATION_USERS_FAILURE:
    case organizationConstants.GET_ALL_FAILURE:
    case organizationConstants.EXPORT_ORGANIZATION_STATS_FAILURE:
    case organizationConstants.GET_ACTIVE_ORGANIZATIONS_FAILURE:
      return { ...state, error: action.error, loading: false };

    case organizationConstants.GET_ALL_SUCCESS:
      return { ...state, organizations: action.payload, loading: false };

    case organizationConstants.GET_SUMMARY_STATS_DATA_SUCCESS:
      return { ...state, summaryStats: action.payload, loading: false };

    case organizationConstants.EXPORT_ORGANIZATION_STATS_SUCCESS:
      return { ...state, loading: false };

    case organizationConstants.GET_DETAILS_SUCCESS:
      return { ...state, organization: action.payload, loading: false };

    case organizationConstants.GET_ACTIVE_ORGANIZATIONS_SUCCESS:
      return { ...state, activeOrganizations: action.payload, loading: false };

    case organizationConstants.GET_ORG_PERFECT_RESPONSES_SUMMARY_REQUEST:
      return { ...state, loading: true };
    case organizationConstants.GET_ORG_PERFECT_RESPONSES_SUMMARY_SUCCESS:
      return { ...state, perfectResponsesSummary: action.payload, loading: false };
    case organizationConstants.GET_ORG_PERFECT_RESPONSES_SUMMARY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case organizationConstants.GET_AGENT_EMPLOYERS_REQUEST:
      return { ...state, loading: true };
    case organizationConstants.GET_AGENT_EMPLOYERS_SUCCESS:
      return { ...state, organizations: action.payload.organizations, loading: false };
    case organizationConstants.GET_AGENT_EMPLOYERS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case organizationConstants.GET_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        organizationDetails: {
          users: action.payload.users,
          usersCount: action.payload.usersCount,
          params: action.payload.params,
        },
        loading: false,
      };

    case organizationConstants.GET_ORG_PAID_MESSAGES_REQUEST:
      return { ...state, orgPaidMessageLoading: true, error: '' };
    case organizationConstants.GET_ORG_PAID_MESSAGES_SUCCESS:
      return { ...state, orgPaidMessageLoading: false, organizationsPaidMessages: action.payload };
    case organizationConstants.GET_ORG_PAID_MESSAGES_FAILURE:
      return { ...state, error: action.error, orgPaidMessageLoading: false };

    case organizationConstants.GET_ORG_PAID_TICKETS_REQUEST:
      return { ...state, loading: true, error: '' };
    case organizationConstants.GET_ORG_PAID_TICKETS_SUCCESS:
      return { ...state, loading: false, organizationsPaidTickets: action.payload };
    case organizationConstants.GET_ORG_PAID_TICKETS_FAILURE:
      return { ...state, error: action.error, loading: false };

    case organizationConstants.GET_ORGANIZATION_PROFITABILITY_REQUEST:
      return { ...state, loading: true, error: ''};
    case organizationConstants.GET_ORGANIZATION_PROFITABILITY_SUCCESS:
      return { ...state, loading: false, organizationsProfitability: action.payload };
    case organizationConstants.GET_ORGANIZATION_PROFITABILITY_FAILURE:
      return { ...state, error: action.error, loading: false };

    case organizationConstants.GET_POSITIVE_SCREENSHOTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case organizationConstants.GET_POSITIVE_SCREENSHOTS_SUCCESS:
      return { ...state, loading: false, positiveScreenshots: action.payload };
    case organizationConstants.GET_POSITIVE_SCREENSHOTS_FAILURE:
      return { ...state, error: action.error, loading: false };

    case organizationConstants.GET_TEAM_LEADS_REQUEST:
      return {...state, error: '', loading: false}
    case organizationConstants.GET_TEAM_LEADS_SUCCESS:
      return {...state, loading: false, organizationDetails: {...state.organizationDetails, teamLead: action.payload}}
    case organizationConstants.GET_TEAM_LEADS_FAILURE:
      return {...state, error: action.error, loading: false}

    case organizationConstants.GET_CSM_REQUEST:
      return {...state, error: '', loading: false}
    case organizationConstants.GET_CSM_SUCCESS:
      return {...state, loading: false, organizationDetails: {...state.organizationDetails, csm: action.payload}}
    case organizationConstants.GET_CSM_FAILURE:
      return {...state, error: action.error, loading: false}

    case organizationConstants.GET_COMPANY_ADMIN_REQUEST:
      return {...state, error: '', loading: false}
    case organizationConstants.GET_COMPANY_ADMIN_SUCCESS:
      return {...state, loading: false, organizationDetails: {...state.organizationDetails, companyAdminForEscalationMails: action.payload}}
    case organizationConstants.GET_COMPANY_ADMIN_FAILURE:
      return {...state, error: action.error, loading: false}

    case organizationConstants.GET_SLACK_STATUS_REQUEST:
      return {...state, error: "", loading: true}
    case organizationConstants.GET_SLACK_STATUS_SUCCESS:
      return { ...state, organizationSlackStatus: action.payload, loading: false };
    case organizationConstants.GET_SLACK_STATUS_FAILURE:
      return {...state, error: action.error, loading: false}

    case organizationConstants.GET_ORGANIZATIONS_CSAT_REQUEST:
      return { ...state, organizationsCsatLoading: true, error: '' };
    case organizationConstants.GET_ORGANIZATIONS_CSAT_SUCCESS:
      return { ...state, organizationsCsatLoading: false, organizationsCsat: action.payload };
    case organizationConstants.GET_ORGANIZATIONS_CSAT_FAILURE:
      return { ...state, organizationsCsatError: action.error, organizationsCsatLoading: false };

    case organizationConstants.GET_ORGANIZATIONS_ACTIVE_EXPERTS_REQUEST:
      return {...state, error: "", organizationsActiveExpertsLoading: true}
    case organizationConstants.GET_ORGANIZATIONS_ACTIVE_EXPERTS_SUCCESS:
      return { ...state, organizationsActiveExperts: action.payload, organizationsActiveExpertsLoading: false };
    case organizationConstants.GET_ORGANIZATIONS_ACTIVE_EXPERTS_FAILURE:
      return {...state, organizationsActiveExpertsError: action.error, organizationsActiveExpertsLoading: false}

    case organizationConstants.GET_ORGANIZATIONS_REVIEWS_STATS_REQUEST:
      return {...state, error: "", organizationsReviewsStatsLoading: true}
    case organizationConstants.GET_ORGANIZATIONS_REVIEWS_STATS_SUCCESS:
      return { ...state, organizationsReviewsStats: action.payload, organizationsReviewsStatsLoading: false };
    case organizationConstants.GET_ORGANIZATIONS_REVIEWS_STATS_FAILURE:
      return {...state, organizationsReviewsStatsError: action.error, organizationsReviewsStatsLoading: false}

    case organizationConstants.DELETE_ORGANIZATION_LOGO_REQUEST:
      return {...state, error: "", deleteOrganizationLogoLoading: true}
    case organizationConstants.DELETE_ORGANIZATION_LOGO_SUCCESS:
      return { ...state, deleteOrganizationLogo: action.payload, deleteOrganizationLogoLoading: false };
    case organizationConstants.DELETE_ORGANIZATION_LOGO_FAILURE:
      return {...state, deleteOrganizationLogoError: action.error, deleteOrganizationLogoLoading: false}

    default:
      return state;
  }
}
