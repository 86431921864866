import React from 'react';
import { Col, Grid, Row, Table } from 'react-bootstrap';
import { roleActions } from "_actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment/moment'
import { Loading } from "components/common";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class RoleIndex extends React.Component {

    componentDidMount() {
        this.props.dispatch(roleActions.getAll());
    }

    render() {

        const { roles } = this.props;

        function renderRoleRow(role) {
            return (
                <tr key={role.id}>
                    <td>
                        <Link to={`roles/${role.id}/show`}>{role.authority}</Link>
                    </td>
                    <td>{moment(role.dateCreated).format('MMMM D, YYYY')}</td>
                    <td>{moment(role.lastUpdated).format('MMMM D, YYYY')}</td>
                </tr>
            );
        }

        if (Object.keys(roles.roles).length === 0) {
            return <Loading/>
        } else {

            const { roles, params, count } = this.props.roles.roles;
            const totalPages = Math.ceil(count / params.max);

            const handleSelect = (page) => {
                params.offset = params.max * ( page.selected );
                this.props.dispatch(roleActions.getAll(params))
            };

            return (

                <Grid>
                    <br/>
                    <br/>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <Link className="btn btn-success pull-left" to="/roles/add">
                                <FontAwesomeIcon icon='user-plus'/> Add Role
                            </Link>
                        </Col>
                    </Row>
                    <br/>

                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Authority</th>
                                    <th>Date Created</th>
                                    <th>Last Updated</th>
                                </tr>
                                </thead>
                                <tbody>

                                {roles.map(renderRoleRow)}

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col md={12} sm={12} xs={12}>
                            <ReactPaginate previousLabel={"previous"}
                                           nextLabel={"next"}
                                           breakLabel={<span>...</span>}
                                           breakClassName={"break-me"}
                                           pageCount={totalPages}
                                           marginPagesDisplayed={2}
                                           disableInitialCallback={true}
                                           pageRangeDisplayed={5}
                                           onPageChange={(page) => handleSelect(page)}
                                           containerClassName={"pagination"}
                                           subContainerClassName={"pages pagination"}
                                           activeClassName={"active"}
                            />
                        </Col>
                    </Row>

                </Grid>
            );
        }
    }
}

function mapStateToProps(state) {
    const { roles } = state;
    return {
        roles: roles
    };
}

const connectedRoleIndex = connect(mapStateToProps)(RoleIndex);
export { connectedRoleIndex as RoleIndex };
