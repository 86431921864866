import React, {Component} from 'react';
import moment from "moment";
import _ from "lodash";


class MessageActivityDetailV2 extends Component {

    constructor(props) {
        super(props);
    }

    activityUser(activity) {
        if (!_.isNil(activity.user)) {
            return activity.user
        }
        return ''
    }

    activityDescription(activity) {
        if (!_.isNil(activity.user)) {
            return ' ' + activity.description
        }
        return activity.description
    }

    render() {
        const {message} = this.props;
        const messageActivities = _.get(message, 'messageActivities');

        if (!messageActivities) {
            return '';
        }

        return (
            <div className="font-13 color-grey-mid" style={{marginTop: "20px"}}>
                {
                    messageActivities.map((activity, key) =>
                        <div className='margin-right-10' key={key}>
                            <span className='margin-right-10'>
                                <span style={{fontWeight: "500"}}>{ this.activityUser(activity) }</span>
                                { this.activityDescription(activity) }
                            </span>
                            {`${moment(activity.dateCreated).format('MMM D, hh:mm a z')}`}
                        </div>
                    )
                }
            </div>
        )
    }
}

export { MessageActivityDetailV2};
