import React, {Fragment} from "react";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";
import DixaSetupModalForm from "./DixaSetupModalForm";

export default function DixaSetupModal({open, handleClose, handleOpen}){
    return(
        <Fragment>
            <BasicModal
                open={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
                title={'Connect with Dixa'}>

                <DixaSetupModalForm
                    description={'Paste your Dixa API Token here:'}
                    buttonLabel={'Submit'}
                    openInNewTab={true}
                    handleClose={handleClose}
                    extraInfoLabel={'View setup instructions'}
                />
            </BasicModal>
        </Fragment>
    )
}