import {Box} from "@mui/system";
import {createElement} from "react";
import _ from "lodash";
import {getEmbed} from "../OEmbed/EmbedUtils";
import styles from "../../../../voteMainStyles";

export default function EmbeddedConversation({ message }) {
    let sourceName = _.get(message, 'sourceName') || _.get(message, 'msgSource');
    sourceName = _.toString(sourceName).toLowerCase();
    const Embed = getEmbed(sourceName);

    return(
        <Box sx={styles.conversationPanelRoot}>
            { createElement(Embed, {message: message}) }
        </Box>
    )
}