
export const fragmentId = {
    INFO: 'info',
    QUEUE: 'queue',
    EXPERT: 'expert',
    NOTIFICATIONS: 'notifications',
    DATA_SOURCES: 'datasources',
    RESPONSES: 'responses',
    OFF_BOARD: 'offboard',
}

export const accessibilityMode = {
    EDIT: 'edit',
    READ_ONLY: 'read_only',
}

export const visibility = {
    CLOSED: {
        name: 'Closed',
        value: 'CLOSED',
    },
    FEATURED: {
        name: 'Featured',
        value: 'FEATURED',
    },
    PRIVATE: {
        name: 'Private',
        value: 'PRIVATE',
    },
    PUBLIC: {
        name: 'Public',
        value: 'PUBLIC',
    },
}