import React, {Fragment} from 'react';
import 'components/Vote/vote.css';
import { Button } from 'react-bootstrap';
import { formattedResponseText } from "_helpers";
import {styles} from "./VoteStyles";
import {responseType} from "_constants";

/**
 * @author Chris Okebata
 */


const approveResponse = (props) => {

  const {
    allVotes, onApproveResponseSelect, approveVote, index, selectedResponse,
    messageAuthor, agentName, messageHandleType, submitApproveResponse,
  } = props;

  const { buttonActive, buttonDefault, checkboxActive, checkboxDefault, buttonSubmitShow, buttonSubmitHide, responseText, radioButtonContainer } = styles;
  const { response_id, text } = approveVote;
  let isSelected = selectedResponse === response_id;
  const responseOptionText = formattedResponseText(text, messageAuthor, agentName, messageHandleType);

  return(
    <Fragment>
      <div className="font-14 color-grey-mid margin-bottom-10">
        {`Perfect Response #${index}`}
      </div>

      <div style={{ marginBottom: '20px' }} onClick={event => onApproveResponseSelect(event, response_id)}>
        <div style={isSelected ? buttonActive : buttonDefault} className='no-select'>
          <div style={radioButtonContainer}>
            <div style={isSelected ? checkboxActive : checkboxDefault}>
              {isSelected ? <i className="fa fa-check color-white" /> : <i />}
            </div>
          </div>

          <div className="font-16" style={responseText}>
            {responseOptionText}
          </div>

          <div style={isSelected ? buttonSubmitShow : buttonSubmitHide}>
            <Button
              onClick={() => submitApproveResponse(allVotes, response_id, responseType.multiApprove)}
              id="submitApproveButton"
              className="btn btn-block btn-primary"
              style={{ height: '35px', lineHeight: '0px' }}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export {approveResponse as ApproveResponse}