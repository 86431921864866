import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form } from 'react-bootstrap';
import { DropDownSelect, Loading } from 'components/common/index';
import { Field, reduxForm } from 'redux-form';
import { employmentActions } from '_actions/index';
import { Link } from 'react-router-dom';
import { getCurrentUser, history, } from '_helpers/index';
import { map } from 'underscore';
import styles from 'pages/Dashboards/ReviewerDashboard/styles'
import { setCurrentEmployment } from '_helpers';

class ReviewerDashboard extends Component {

  componentDidMount() {

    const { id } = getCurrentUser();

    /**
     * When page loads, get all user's employments
     * and populate organization dropdown with associated organizations.
     */
    this.props.dispatch(employmentActions.getUserEmployments(id,
      { visibility: ['PUBLIC', 'PRIVATE', 'FEATURED'], withScore: false  }
    ));
  }

  /**
   * Handles the 'submission' of the 'form' used in
   * selecting organization that agent will vote for.
   *
   * @param props
   * @example {organization: 233454323ed234rfd34}
   */
  handleSubmit = (props) => {

    /**
     * Filter employments to get the one corresponding to the
     * organization that the agent selected to vote on and stores that in local storage.
     */
    const employment = this.props.employments.filter((employment) => {
      return employment.organization.id === props.organization
    })[0];

    setCurrentEmployment(employment);
    history.push('/review');
  };

  /**
   * Extract organizations from user's employment and
   * transform them to a format that can be rendered by the dropdown.
   * @param employments
   */
  getEmploymentsOrganizations = (employments) => {
    return map(employments, function (employment) {
      return {
        id: employment.organization.id,
        name: employment.organization.name
      }
    });
  };

  /**
   * Get the total number of messages an agent has voted on for an organization.
   * @param employments
   * @returns {number}
   */
  getMessageCount = (employments) => {
    let totalMessagesCount = 0;

    map(employments, function (employment) {
      totalMessagesCount += employment.agent.messagesCount;
    });

    return totalMessagesCount;
  };

  render() {

    const { loadingEmployments, submitting, handleSubmit, employments, totalMessagesEvaluated } = this.props;
    const { rootWrapper, containerElement, formContainer, inputContainer, textContainer, inputElement } = styles;

    if (loadingEmployments) {
      return <Loading/>
    }

    let organizations = [];

    if (employments) {
      organizations = this.getEmploymentsOrganizations(employments);
    }

    return (

      <div style={rootWrapper}>
        <div style={containerElement}>
          <div style={formContainer}>
            <div style={{ padding: '0px 0 10px 0' }}
                 className="font-24-heavy color-grey-dark">Get started
            </div>

            <Form onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
              <div style={inputContainer}>
                <div style={{ width: '400px' }}>
                  <Field
                    style={inputElement}
                    className="select-field"
                    name="organization"
                    label=""
                    component={DropDownSelect}
                    onChange={this.handleOrgChange}
                    type="select"
                    options={organizations}
                    placeHolder='Choose a new organization'
                  />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <button className="btn btn-success font-15"
                          style={{ padding: '9px 30px' }}
                          type="submit" disabled={submitting}>Start
                  </button>
                </div>
              </div>
            </Form>
            <div style={{ width: '400px' }}>
              <Link to='/employers' className="font-16-bold color-green">Browse more
                companies</Link>
            </div>
          </div>
          <div style={textContainer}>
            <div className="font-64-heavy color-grey-dark">
              {totalMessagesEvaluated}
            </div>
            <div className="font-20-bold color-grey-dark">
              Customer messages evaluated
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Validates the form for selecting an organization
 * that an agent will be voting for.
 *
 * @param values
 * @returns {{}}
 */
function validate(values) {
  const errors = {};

  if (!values.organization) {
    errors.organization = 'Select an Organization'
  }

  return errors;
}

function mapStateToProps(state) {
  const { employment: { userEmployments, loading } } = state;

  const { employments, totalMessagesEvaluated } = userEmployments;

  return {
    employments,
    loadingEmployments: loading,
    totalMessagesEvaluated
  };
}

ReviewerDashboard = connect(mapStateToProps)(ReviewerDashboard);

const reviewerDashboard = reduxForm({
  form: 'chooseVotingOrganization',
  validate,
  touchOnBlur: false
})(ReviewerDashboard);

export { reviewerDashboard as ReviewerDashboard };
