import React from 'react'
import {Button, Col, Form, Grid, Row,} from 'react-bootstrap'
import { userActions } from "_actions";
import { NormalTextField } from "components/common/formFields/NormalTextField";
import connect from "react-redux/es/connect/connect";
import { currentUser } from '_helpers'

const style = { padding: '40px 20px 20px', marginBottom: '50px', backgroundColor: 'white', borderRadius: '1%', border: '1px solid lightGray' };

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/01/2019 : 2:26 PM
 */
class UpdatePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formFields: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            submitting: false
        }
    }

    updateFormField = (event) => {
        const target = event.target
        this.setState((prevState) => {
            return {
                formFields: {
                    ...prevState.formFields, [target.name]: target.value
                }
            }
        })
    }

    handleSubmit = event => {
        event.preventDefault()
        this.setState({
            submitting: true
        })
        this.props.dispatch(userActions.updatePassword(currentUser.id, this.state.formFields, this.props.history))
    }

    render () {
        const { formFields: { currentPassword, newPassword, confirmPassword }, submitting } = this.state
        return (
            <Grid className='top-rel-100'>
                <Row>
                    <Col md={4} style={style}>
                        <Form onSubmit={this.handleSubmit}>
                            <Row style={{margin: 0}}>
                                <NormalTextField
                                    type='password'
                                    label='Current Password'
                                    onChange={this.updateFormField}
                                    name='currentPassword'
                                    id='currentPassword'
                                    value={currentPassword} />
                                <NormalTextField
                                    type='password'
                                    label='New Password'
                                    onChange={this.updateFormField}
                                    name='newPassword'
                                    id='newPassword'
                                    value={newPassword} />
                                <NormalTextField
                                    type='password'
                                    label='Confirm Password'
                                    onChange={this.updateFormField}
                                    name='confirmPassword'
                                    id='confirmPassword'
                                    value={confirmPassword} />
                            </Row>
                            <Row style={{ marginTop: '20px' }} className='text-center'>
                                <Col md={6} sm={6} xs={6}>
                                    <Button bsStyle="success" type="submit"
                                            className='btn btn-success'
                                            disabled={submitting}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const connectedUpdatePassword = connect(null)(UpdatePassword);
export { connectedUpdatePassword as UpdatePassword }
