import React, {Component} from 'react';
import { Button, Image, Modal } from 'react-bootstrap'
import picturePlaceholder from 'components/images/picture-placeholder.svg';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {alertActions, userActions} from '_actions';
import {base64StringtoFile, extractImageFileExtensionFromBase64 } from '_helpers/image.utils'

/**
 * @author Chris Okebata
 */


const imageMaxSize = 1048576; // bytes
const acceptedFileTypes = ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml'];

class ImageEditor extends Component{

    constructor(props) {
        super(props);
        this.inputFile = React.createRef();
        this.openBrowseDialog = this.openBrowseDialog.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this)
    }

    state = {
        src: null,
        imageExtension: null,
        useDimension: false,
        count: 0,
        crop: {
            aspect: 1,
            width: 50,
            x: 0,
            y: 0,
        },
    };

    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0];
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;
            if(currentFileSize > imageMaxSize) {
                this.props.dispatch(alertActions.error('File size exceeded maximum limit of 1mb'));
                return false
            }
            if (!acceptedFileTypes.includes(currentFileType)){
                this.props.dispatch(alertActions.error("File is not supported."));
                return false
            }
            return true
        }
    };

    onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
          const files = e.target.files;
            const isVerified = this.verifyFile(files);

            if (isVerified){
                this.processDimension(files);
                const imageName = e.target.files[0].name;
                const croppedImageName = "cropped-" + Date.now() + e.target.files[0].name;
                const reader = new FileReader();
                reader.addEventListener('load', () => {

                    const result = reader.result;
                    this.setState(
                        {
                            src: result,
                            imageExtension: extractImageFileExtensionFromBase64(result),
                            fileName: imageName,
                            croppedFileName: croppedImageName
                        }
                    )
                }, false);
                reader.readAsDataURL(e.target.files[0]);
            }
        }
    };

    processDimension = (files) => {
      if(files[0].type === 'image/svg+xml') this.setState({ useDimension: true });
      else this.setState({ useDimension: false });
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
    };

    onCropChange = crop => {
        const { count } = this.state;
        const { useAspectRation } = this.props;
        if(!useAspectRation && count > 0) { crop.aspect = 0 }
        this.setState({ crop, count: count + 1 });
    };

    async makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImage = await this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                this.state.croppedFileName,
            );
            this.setState({ croppedImage });
        }
    }

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height,
        );

        const imageData64 = canvas.toDataURL('image/' + this.state.imageExtension);
        return base64StringtoFile(imageData64, fileName);
    }

    openBrowseDialog() {
        this.inputFile.current.click();
    }

    handleCancel() {
        this.setState({
            src: null,
            imageExtension: null,
            fileName: null,
            croppedFileName: null,
            croppedImage: null,
            useDimension: false,
        });
        this.props.onHide()
    }

    uploadImage(imageData){
        const formData = new FormData();
        formData.append('photo', imageData);

        this.props.dispatch(userActions.updateUserPhoto(this.props.userId, this.props.type, formData));
        this.handleCancel();
    }

    render(){
        const { show, headerText, buttonText} = this.props;
        const { crop, croppedImage, fileName, src, count } = this.state;

        if (count === 0 && (this.props.cropRatio || this.props.cropRatio === 0)) {
            crop.aspect = this.props.cropRatio
        }
        return (
            <Modal show={show} onHide={this.handleCancel} className="tms-upload-picture-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{headerText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!src ?
                        <div style={{height:"300px", paddingBottom:"30px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection:"column"}}>
                            <Image responsive
                                   src={picturePlaceholder}
                                   circle
                                   style={{maxWidth:"125px", height:"125px", alignSelf:"center"}}
                            />

                            <Button onClick={this.openBrowseDialog}
                                    style={{padding: '7px 20px'}}
                                    className="btn btn-default btn-transparent">Browse picture</Button>

                            <input ref={this.inputFile} type="file" style={{display: "none"}} onChange={this.onSelectFile} multiple={false}/>
                        </div>
                        :
                        <div>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                keepSelection
                                imageStyle={ this.state.useDimension ? { minHeight:"200px", maxHeight:"700px" } : {} }
                            />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <div style={{width:"300px", display:"flex", alignItems:"center"}} className="tms-ellipsis font-14 color-grey-dark margin-right-20">{fileName}</div>
                        <div style={{marginLeft:"auto"}}>
                            <Button onClick={this.handleCancel} style={{padding: '7px 12px', width:"80px"}} className="btn btn-default">Cancel</Button>
                            <Button disabled={src === null}
                                    className="btn btn-success"
                                    style={{padding: '7px 12px', width:"80px"}}
                                    onClick={() => {this.uploadImage(croppedImage)}}>{buttonText}</Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default ImageEditor;
