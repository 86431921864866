import {Box} from "@mui/material";
import _ from "lodash";
import LoadingPlaceholderBlock from "../../../../../common/LoadingPlaceholderBlock";
import BasicFilterSelect from "../../../../common/BasicFilterSelect";

export default function CSMFilterSelect({csmList, loadingCSMInfo, selectedCSM, handleSelectChange, updatedCSM}) {
    const value = csmList?.find((item) => item.id === (updatedCSM || _.get(selectedCSM, 'id')));

    return(
        <Box sx={styles.csmSelectContainer}>
            {loadingCSMInfo ?
                <LoadingPlaceholderBlock
                    width={'100%'}
                    height={'42px'}
                    borderRadius={'8px'}
                    backgroundColor={'#E3E2E1'}
                /> :
                <BasicFilterSelect
                    placeholder={'Select CSM'}
                    options={csmList}
                    value={value}
                    onChange={handleSelectChange}
                    textFieldStyle={styles.csmSelectInput}
                    listBoxHeight={380}
                />
            }
        </Box>
    )
}

const styles = {
    csmSelectContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
    },
    csmSelectInput : {
        height: '42px',
        [`& fieldset`]: {
            borderRadius: '8px',
        },
        '& .MuiInputBase-input': {
            height: '10px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#687979',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },
}