import { pricingConstants, alertMessages } from '_constants';
import { pricingService } from '_services';
import {SnackBar} from "../components/common";

function getAll(params) {
    function request() {
        return { type: pricingConstants.GET_ALL_PRICING_REQUEST };
    }

    function success(pricing) {
        return { type: pricingConstants.GET_ALL_PRICING_SUCCESS, payload: pricing };
    }

    function failure(error) {
        return { type: pricingConstants.GET_ALL_PRICING_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        pricingService.getAll(params)
            .then(
                pricing => dispatch(success(pricing)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };
}

function getPricing(id) {
    return (dispatch) => {
        dispatch(request());

        pricingService.getPricing(id)
            .then(
                pricing => dispatch(success(pricing)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request() {
        return { type: pricingConstants.GET_PRICING_REQUEST};
    }

    function success(pricing) {
        return { type: pricingConstants.GET_PRICING_SUCCESS, payload: pricing };
    }

    function failure(error) {
        return {type: pricingConstants.GET_PRICING_FAILURE, payload: error};
    }
}

function createPricing(data) {
    return (dispatch) => {
        dispatch(request());

        pricingService.createPricing(data)
            .then(
                pricing => {
                    dispatch(success(pricing));
                    SnackBar.showMessage(alertMessages.CREATE_PRICING_SUCCESS);
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request(id) {
        return {type: pricingConstants.CREATE_PRICING_REQUEST, payload: id};
    }

    function success(pricing) {
        return {type: pricingConstants.CREATE_PRICING_SUCCESS, payload: pricing};
    }

    function failure(error) {
        return {type: pricingConstants.CREATE_PRICING_FAILURE, payload: error};
    }
}

function updatePricing(data, id) {
    return (dispatch) => {
        dispatch(request());

        pricingService.updatePricing(data, id)
            .then(
                pricing => {
                    dispatch(success(pricing));
                    SnackBar.showMessage(alertMessages.UPDATE_PRICING_SUCCESS);
                },
                error=> {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                },
            );
    };

    function request(id) {
        return {type: pricingConstants.UPDATE_PRICING_REQUEST, payload: id};
    }

    function success(pricing) {
        return {type: pricingConstants.UPDATE_PRICING_SUCCESS, payload: pricing};
    }

    function failure(error) {
        return {type: pricingConstants.UPDATE_PRICING_FAILURE, payload: error};
    }
}

export const pricingActions = {
    getAll,
    getPricing,
    createPricing,
    updatePricing
};