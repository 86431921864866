import React, {Component} from "react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {connect} from "react-redux";

/**
 * this select field allows users to search and select multiple dropdown checkboxes
 */
class OrganizationDropDownSelect extends Component {

    constructor(props) {
        super(props);
        this.state={
            selectedValues: '',
            checkedPreviousValues: false
        }
    }

    componentDidUpdate = () => {
        const {options, checkedOrganizations} = this.props;
        if (!this.state.checkedPreviousValues){
            const prevChecked = this.filterPreviouslyCheckedOrgs(options, checkedOrganizations)
            this.setState({
                selectedValues: prevChecked,
                checkedPreviousValues: true
            })
        }
    };

    handleChange = value => {
        this.setState({selectedValues: value, previousValues: value});
        this.updateParent(value)
    };

    filterPreviouslyCheckedOrgs = (options, prevCheckedOptions) => {
        prevCheckedOptions = prevCheckedOptions.map(id => {return Number(id)});
        const result = [];
        options.forEach(currOption => {
            if (prevCheckedOptions.includes(currOption.value) > 0) {
                result.push(currOption)
            }
        });
        return result
    };


    /**
     * perform necessary updates in MessageIndex when onCheck event is fired here
     * @param companies
     */
    updateParent(companies){
        const currentIdsList = companies && companies.length > 0 ?
            companies.map(company => {return company.value}) : [];
       const {updateHandles, handleStatus, handleChange} = this.props;
        if (handleStatus ){
            updateHandles(currentIdsList, handleStatus);
        }
        if (currentIdsList.length > 0){
            /**
             * handles organization filter change
             * keeps track of selected organizations to maintain persistence
             * between page refreshes
             * i.e. maintain the previously selected organizations in the dropdown select after
             * the page refreshes
             */
            handleChange(currentIdsList)
        }
    }
    render = () => {

        const {options} = this.props;
        return (
            <ReactMultiSelectCheckboxes
                options={options}
                value={this.state.selectedValues}
                onChange={this.handleChange}
                width="100%"
            />
        )
    }
}


const mapStateToProps = state => {
    const {organizations} = state;
    return {
        organizations: organizations.organizations
    }
};

const connectedComponent = connect(mapStateToProps)(OrganizationDropDownSelect)
export {connectedComponent as OrganizationDropDownSelect}
