import { Fragment } from 'react'
import { Link, Stack, Box, Grid } from '@mui/material';
import importedStyles from '../Utilities/styles'
import DashboardCard from '../Utilities/DashboardCard';
import DataTeaser, { formatEntry } from '../Utilities/DataTeaser';
// import Badge from '../Utilities/Badge';
import useImpactMetrics from "../Utilities/hooks/useImpactMetrics";
import {formatDuration} from "_helpers";
import moment from "moment";

const InfoModalContent = () => (
	<Box component={'dl'} sx={importedStyles.definitionList}>
		<Fragment>
			<Box component={'dt'} sx={importedStyles.definitionTitle}>Number of tickets solved</Box>
			<Box component={'dd'} sx={importedStyles.definitionText}>This reflects the number of tickets solved in during your selected time frame, along with the number tickets solved this month, and number of remaining tickets.</Box>
		</Fragment>
	</Box>
)

export default function ImpactSection({ startDate, endDate }) {
	const { data, loadingImpactMetrics } = useImpactMetrics(startDate, endDate);
	const duration = data ? formatDuration(data.estimatedTimeSavedPerCustomerInMin) : null;

	return (
		<DashboardCard
			isLoading={loadingImpactMetrics}
			headerTitle='Impact metrics'
			infoModalTitle='Impact metrics calculations'
			infoModalChildren={<InfoModalContent />}>

			<Grid container columns={4} spacing={3} sx={styles.root}>
				<Grid item xs={4} md={1}>
					<DataTeaser
						label={'Estimated time saved'}
						entry={{ value: duration, formatStyle: null }}
						descriptionChildren={<>
							<Box>It takes about 3 minutes on average to solve a ticket.</Box>
						</>}
					/>
				</Grid>

				{/*@TODO: Enable section when feature is ready*/}
				{/*<Grid item xs={4} md={1}>*/}
				{/*	<DataTeaser*/}
				{/*		label={'Estimiated revenue generated'}*/}
				{/*		entry={{ value: data ? data.estimatedRevenueGeneratedUSD : null, formatStyle: 'currency' }}*/}
				{/*		descriptionChildren={<>*/}
				{/*			<Box>{*/}
				{/*				data */}
				{/*					? `Presale question conversion rate lead to a*/}
				{/*					${formatEntry({ value: data.estimatedRevenueGeneratedPercent, formatStyle: 'percent' })}*/}
				{/*					increase in revenue generated.` : ``*/}
				{/*				}*/}
				{/*			</Box>*/}
				{/*		</>}*/}
				{/*	/>*/}
				{/*</Grid>*/}

				<Grid item xs={4} md={1}>
					<DataTeaser
						label={'Number of tickets solved'}
						entry={{ value: data ? data.ticketsSolvedAmount : null, formatStyle: 'number' }}
						descriptionChildren={<>
							<Stack spacing={0.5}>
								<Stack direction={'row'} flexWrap={'wrap'}>
									<Box sx={{ fontFamily: 'LarsseitMedium' }}>{new moment().format('MMMM YYYY')}:&nbsp;</Box>
									<Box>{data ? (
										<>
											<Box>
												{formatEntry({ value: data.ticketsSolvedMonthToDateAmount, formatStyle: 'number' })} tickets
												{/*@TODO: Enable section when feature is ready*/}
												{/*({formatEntry({ value: data.ticketsRemainingMonthToDateAmount, formatStyle: 'number' })} remaining)*/}
											</Box>
										</>
									) : <>&ndash;</>}</Box>
								</Stack>

								{/*@TODO: Enable section when feature is ready*/}
								{/*<Stack direction={'row'} spacing={0.5}>*/}
								{/*	<Badge type={'secondary'}>*/}
								{/*	{ data ? (*/}
								{/*		formatEntry({ value: data.ticketsSolvedMonthToDateCompareLastMonthPercent, formatStyle: 'percent' })*/}
								{/*	) : <>&ndash;</>}*/}
								{/*	</Badge>*/}
								{/*	<Box>since last month</Box>*/}
								{/*</Stack>*/}

								<Stack direction={'row'}>
									<Box sx={{ fontFamily: 'LarsseitMedium' }}>All-time total:&nbsp;</Box>
									<Box>{data ? (
										<>
											{formatEntry({ value: data.ticketsSolvedAllTime, formatStyle: 'number' })} tickets
										</>
									) : <>&ndash;</>}</Box>
								</Stack>

								<Box>
									Email{' '}
									<Link
										sx={importedStyles.textLink}
										underline='none'
										href={'mailto:support@chatdesk.com'}>
										support@chatdesk.com
									</Link>
									{' '}to add more tickets
								</Box>
							</Stack>
						</>}
					/>
				</Grid>

				<Grid item xs={4} md={1}>
					<DataTeaser
						label={'Hidden tickets'}
						entry={{ value: data ? data.ticketsHiddenAmount : null, formatStyle: 'number' }}
					/>
				</Grid>
			</Grid>
		</DashboardCard>
	)
}

const styles = {
	root: {
		py: { xs: 2, md: 4 },
	}
}