import React from "react";
import {Stack} from "@mui/material";
import {styles} from '../Utilities/ProactiveMetricsDetailStyles';
import {HeaderLabel} from "./HeaderLabel";
import {HeaderFormFields} from "../Forms/HeaderFormFields";

export const Header = ({
    pageFilter,
    isFilterDropdownOpen,
    setIsFilterDropdownOpen,
    isCampaignNameDropdownOpen,
    setIsCampaignNameDropdownOpen,
    selectedCriteria,
    setSelectedCriteria,
    filterRef,
    filterPageRef,
    handleExport,
    selectedDropdownOption,
    onSelectChangeHandler,
    onFilterFormSubmit,
    handleFilterReset,
    dateRange,
    setDateRange,
    extractedCampaignList,
    setLiked,
    setReplied,
    setNoEngagement,
    setFollowed,
    setConverted,
    setFollowUpSent,
    setConvertedAfterFollowUpSent
}) => {

    return (
        <>
            <Stack direction={'column'} sx={styles.hero} spacing={2} style={{paddingTop: '55px'}}>
               <HeaderLabel pageFilter={pageFilter}  />
               <HeaderFormFields
                   pageFilter={pageFilter}
                   isFilterDropdownOpen={isFilterDropdownOpen}
                   setIsFilterDropdownOpen={setIsFilterDropdownOpen}
                   isCampaignNameDropdownOpen={isCampaignNameDropdownOpen}
                   setIsCampaignNameDropdownOpen={setIsCampaignNameDropdownOpen}
                   selectedCriteria={selectedCriteria}
                   setSelectedCriteria={setSelectedCriteria}
                   filterRef={filterRef}
                   filterPageRef={filterPageRef}
                   handleExport={handleExport}
                   selectedDropdownOption={selectedDropdownOption}
                   onSelectChangeHandler={onSelectChangeHandler}
                   onFilterFormSubmit={onFilterFormSubmit}
                   handleFilterReset={handleFilterReset}
                   dateRange={dateRange}
                   setDateRange={setDateRange}
                   extractedCampaignList={extractedCampaignList}
                   setLiked={setLiked}
                   setReplied={setReplied}
                   setNoEngagement={setNoEngagement}
                   setFollowed={setFollowed}
                   setConverted={setConverted}
                   setFollowUpSent={setFollowUpSent}
                   setConvertedAfterFollowUpSent={setConvertedAfterFollowUpSent}
               />
            </Stack>
        </>
    )
}