import TimezonePicker from "react-timezone";
import { ControlLabel, FormGroup } from "react-bootstrap";
import React from "react";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 14/01/2019 : 1:46 PM
 */

const timezonePicker = (props) => {

    const { placeHolder, label, hidden, input, ...rest } = props;

    return (
        <FormGroup controlId="formControlsSelect" hidden={hidden}>
            <ControlLabel>{label}</ControlLabel>

            <TimezonePicker
                {...rest}
                {...input}
                placeholder={placeHolder}
                onChange={input.onChange}
                label='User Name'
                style={{ width: '100%' }}
                name={input.name}
            />

        </FormGroup>
    )
};

export default timezonePicker