import React, { Component, Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgDataSourceActions, trustpilotOauthActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import { TrustpilotCredentialsModal } from 'components/MsgDataSources';
import {Box} from "@mui/system";
import {Typography} from "@mui/material";
import { GenericModal } from "../common";
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
    tooltipTypographyContainer: {
        minWidth: '250px',
        maxWidth: '500px',
        padding: '10px'
    },
    tooltipTypography: {
        width: '100%',
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        color: '#0C2728',
    }
};

const TooltipDisplay = ({showData, closeModal}) => {
    return (
        <GenericModal
            headerText="More Information"
            hideFooter
            show={showData}
            onHide={closeModal}
        >
            <Box sx={styles.tooltipTypographyContainer}>
                <Typography sx={styles.tooltipTypography}>
                    <span>
                        Check for Chatdesk User in Trustpilot <a href="https://businessapp.b2b.trustpilot.com/usermanagement" target="_blank" rel="nofollow noreferrer noopener">here</a> and copy id
                        from the User's page e.g. on clicking on Chatdesk User to view profile, and URL is
                        https://businessapp.b2b.trustpilot.com/usermanagement/user/abcdefghijklmnopqrstuvwxyz, Copy only abcdefghijklmnopqrstuvwxyz and paste here to save
                    </span>
                </Typography>
            </Box>
        </GenericModal>
    )
}

/**
 * @author Aniekan Offiong <aniekan@chatdesk.com>
 * @date 05/03/2023 : 01:45 PM
 */
class ListTrustpilotDataSources extends Component {

    state = {
        showModal: false,
        dataSourceId: '',
        chatdeskUserId: '',
        updateChatdeskUserId: false,
        currentDisplay: false,
        currentIndex: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAllDataSources({dataSource: 'Trustpilot'}));
    }

    sendToTrends = (msgDataSourceId) => {
        const { dispatch } = this.props;
        dispatch(trustpilotOauthActions.sendToTrends(msgDataSourceId));
    };

    refreshToken = (msgDataSourceId) => {
        const { dispatch } = this.props;
        dispatch(trustpilotOauthActions.refreshToken(msgDataSourceId));
    };

    showCredentialsModal = (dataSourceId) => {
        this.setState({
            dataSourceId,
            showModal: true
        });
    };

    refreshAllTokens = () => {
        const { dispatch } = this.props;
        dispatch(trustpilotOauthActions.refreshAllTokens());
    };

    handleChange = (e) => {
        this.setState({
            chatdeskUserId: e.target.value
        });
    };

    saveSupportUser = (dataSourceId) => {
        const { dispatch } = this.props;
        const { chatdeskUserId } = this.state;
        dispatch(msgDataSourceActions.saveSupportEmail({dataSourceId, chatdeskBusinessUserId: chatdeskUserId, msgDataSource: 'trustpilot'}));
        this.setState({updateChatdeskUserId: false, chatdeskUserId: '', currentIndex: null})
    };

    updateBusinessUserId = (currentIndex, chatdeskUserId) => {
        this.setState({
            currentIndex, updateChatdeskUserId: true, chatdeskUserId: chatdeskUserId || '',
        })
    };

    showMoreInfo = (e) => {
        e.preventDefault();
        this.setState({currentDisplay: true})
    };

    render() {
        const { trustpilotList, loading, businessUserLoading } = this.props;
        const { showModal, dataSourceId, chatdeskUserId, currentIndex, updateChatdeskUserId, currentDisplay } = this.state;
        if (loading) {
            return <Loading/>
        }
        return (
            <div>
                <Row>
                    <Col xs={10} md={10}>
                        <table className='table trds-table'>
                            <thead className="font-18-bold">
                            <tr>
                                <th className="col-md-1">S/N</th>
                                <th className="col-md-2">Name</th>
                                <th className="col-md-2">Organization</th>
                                <th className="col-md-3">
                                    Save Chatdesk User Id <br />
                                    <a href="#" onClick={this.showMoreInfo}>(More Information)</a>
                                </th>
                                <th className="col-md-4">
                                    <Button className='btn-info margin-top-5' onClick={this.refreshAllTokens}>
                                        Trigger Refresh All Tokens
                                    </Button>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {trustpilotList.map((dataSource, index) => (
                                <tr key={index}>
                                    <td className="vertical-align-middle">{index + 1}</td>
                                    <td className="vertical-align-middle">{dataSource.name}</td>
                                    <td className="vertical-align-middle">{dataSource.orgName}</td>
                                    <td className="vertical-align-middle">
                                        {businessUserLoading &&
                                            <CircularProgress style={{color: '#008751'}} size={30}/>
                                        }
                                        {(!updateChatdeskUserId || currentIndex !== index) && !businessUserLoading &&
                                            <Fragment>
                                                {dataSource.chatdeskBusinessUserId && <span>{dataSource.chatdeskBusinessUserId}</span>}
                                                {!dataSource.chatdeskBusinessUserId && <span>NOT SET</span>}
                                                <br />
                                                <Button className='btn-info btn-sm float-right' onClick={() => this.updateBusinessUserId(index, dataSource.chatdeskBusinessUserId)}>
                                                    Update
                                                </Button>
                                            </Fragment>
                                        }
                                        {updateChatdeskUserId && currentIndex === index && !businessUserLoading &&
                                            <Fragment>
                                                <input type="text" className="form-control" name="chatdeskUserId" value={chatdeskUserId} onChange={this.handleChange} />
                                                <Button className='btn-info btn-sm float-right margin-top-5' onClick={() => this.saveSupportUser(dataSource.trendsId)}>
                                                    Save
                                                </Button>
                                            </Fragment>
                                        }
                                    </td>
                                    <td>
                                        <Button className='btn-info margin-top-5' onClick={() => this.showCredentialsModal(dataSource.id)}>
                                            Update Credentials
                                        </Button>
                                        <Button className='btn-info margin-top-5' onClick={() => this.sendToTrends(dataSource.id)}>
                                            Send To Trends
                                        </Button>
                                        <Button className='btn-info margin-top-5' onClick={() => this.refreshToken(dataSource.id)}>
                                            Refresh Token
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <TooltipDisplay showData={currentDisplay} closeModal={() => this.setState({currentDisplay: false})} />
                    </Col>
                </Row>
                <TrustpilotCredentialsModal
                    showData={showModal}
                    dataSourceId={dataSourceId}
                    closeModal={() => this.setState({showModal:false})}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { trustpilotAuth: { list, loading, businessUserLoading } } = state;
    return {
        trustpilotList: list, loading, businessUserLoading
    };
}

const connectedListTrustpilotDataSources = connect(mapStateToProps)(ListTrustpilotDataSources);
export { connectedListTrustpilotDataSources as ListTrustpilotDataSources};
