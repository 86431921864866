import {alertMessages} from "../_constants";
import {SnackBar} from "../components/common";
import {applicationsService} from "../_services/applications.service";
import {applicationsConstants} from "../_constants/applications.constants";

function getApplicationDetails(applicationId) {
    function request() {
        return {type: applicationsConstants.APPLICATIONS_GET_DETAILS_REQUEST};
    }

    function success(res) {
        return {type: applicationsConstants.APPLICATIONS_GET_DETAILS_SUCCESS, payload: res};
    }

    function failure(error) {
        return {type: applicationsConstants.APPLICATIONS_GET_DETAILS_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        applicationsService.getApplicationDetails(applicationId)
            .then(
                (res) => {
                    if (res) {
                        dispatch(success(res));
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.APPLICATIONS_GET_DETAILS_FAILURE);
                },
            );
    };
}

function clear() {
    return { type: applicationsConstants.APPLICATIONS_CLEAR };
}

export const applicationsActions = {
    getApplicationDetails,
    clear
};