import React from 'react'
import {Button, Form} from "react-bootstrap";
import {Link} from "react-router-dom";

const PaymentForm = (props) => {
    const { onSubmit, title, onChange, currencies,
        formData: {currency, amount, label}, submitting,
        url, actionType } = props
    return (
        <div style={{width: '50%', background: '#fff', padding: '20px 40px'}}>
            <Link to={url}
                  className="btn btn-sm"
                  style={{border: '1px solid #ededed', fontSize: '17px'}}>
                <i className="fa fa-arrow-left color-green"/>
            </Link>
            <h4 className="font-16-bold color-grey-dark" style={{marginBottom: '15px'}}>
                {title}
            </h4>
            <Form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <span className="font-14 color-grey-dark">Currency</span>
                        <div style={{marginTop:"3px", display:"flex"}}>
                            <select name="currency" className="form-control" value={currency} onChange={onChange}>
                                <option value="" hidden>Select Currency</option>
                                {currencies && currencies.map(item =>
                                    <option key={item.id} value={item.id}>{item.label}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-8 form-group">
                        <span className="font-14 color-grey-dark">Amount</span>
                        <div style={{marginTop:"3px", display:"flex"}}>
                            <input type="text" name="amount" className="form-control" value={amount} onChange={onChange} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <span className="font-14 color-grey-dark">Label</span>
                    <div style={{marginTop:"3px", display:"flex"}}>
                        <textarea value={label} name="label" onChange={onChange} className="form-control" maxLength="200" />
                    </div>
                </div>
                <div style={{marginTop:"15px"}}>
                    <Button className="btn btn-success btn-block font-15" type="submit" disabled={submitting}>
                        {actionType}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default PaymentForm