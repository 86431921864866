import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { earningsActions, walletActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import LeadAgentsList from "./LeadAgentsList";
import { getCapitalizedWords } from "../../../../../_helpers";
import {calculateProgress} from "../Utilities/EarningUtils";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";

export default function EarningsSummary() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const earnings = useSelector((state) => state.earnings);

  const walletLoading = wallet.walletBalanceLoading;
  const earningsLoading = earnings.leadAgentsLoading;

  const walletBalance = wallet.walletBalance;
  const leadAgents = earnings.leadAgents;

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const agentId = _.get(currentUser, "id");

  const excludedUsername = "@chatdesk.com";

  useEffect(() => {
    dispatch(walletActions.getAgentWalletBalance(agentId));
    dispatch(earningsActions.getLeadAgents(agentId));
  }, []);

  const roundTo = (value, roundLimit) => {
    if (typeof value !== "number" || typeof roundLimit !== "number") return 0;
    let rem = value % roundLimit;
    let num = value - rem;

    return num + roundLimit;
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box mb={3}>
          {walletLoading ? (
              <Box sx={{maxWidth: "500px"}}>
                <LoadingPlaceholderBlock
                    width={'100%'}
                    height={'200px'}
                    borderRadius={'5px'}
                    backgroundColor={'#E3E2E1'}
                />
              </Box>
          ) : (
            <Box sx={styles.cardContainer}>
              <Box sx={styles.headerContainer}>
                <Box sx={styles.headerText}>Total Earnings</Box>
                <Box sx={styles.headerText}>
                  ${walletBalance?.wallet?.lifeTimeEarning || 0}
                </Box>
              </Box>
              <Box sx={styles.cardBody}>
                <Box sx={styles.textField}>Current balance</Box>
                <Box sx={styles.textField} mb={2}>
                  ${walletBalance?.wallet?.balance || 0}
                </Box>
                <Box sx={styles.progressBarContainer}>
                  <Box sx={{...styles.progressBarForeground,
                    width: calculateProgress(walletBalance?.wallet?.balance, roundTo(walletBalance?.wallet?.balance, 50)) + '%'}} />
                </Box>
                <Box
                  sx={styles.textField}
                  mt={1}
                >{`Answer more messages to earn $${roundTo(walletBalance?.wallet?.balance, 50)}`}</Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box>
          {earningsLoading ? (
              <Box sx={{maxWidth: "500px"}}>
                <LoadingPlaceholderBlock
                    width={'100%'}
                    height={'118px'}
                    borderRadius={'5px'}
                    backgroundColor={'#E3E2E1'}
                />
              </Box>
          ) : (
            <>
              {Object.entries(leadAgents.data || {}).map(
                ([key, value], index) => {
                  const orgName = key.split("_")[1];

                  return (
                    <Box key={index} sx={styles.cardContainer}>
                      <Box sx={styles.headerContainer}>
                        <Box sx={styles.headerText}>
                          {getCapitalizedWords(orgName)}
                        </Box>
                      </Box>

                      {value.map((item, index) => {
                        if (item.agentUsername.includes(excludedUsername))
                          return;

                        return (
                          <LeadAgentsList
                            key={index}
                            agent={item}
                            index={index + 1}
                          />
                        );
                      })}
                    </Box>
                  );
                }
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export const styles = {
  root: {
    fontFamily: "LarsseitRegular",
    letterSpacing: "-0.02em",
    color: "#0C2728",
    backgroundColor: "#EFEAE7",
    pt: 16,
    pb: 10,
    minHeight: "100vh",
  },

  container: {
    px: { xs: 2, sm: 6 },
    maxWidth: "1440px",
    position: "relative",
  },

  cardContainer: {
    backgroundColor: "#ffffff",
    maxWidth: "500px",
    boxShadow: "0 1px 2px 0 rgba(196, 201, 199, 0.4)",
    border: "solid 1px rgba(224, 224, 224, 1)",
    borderRadius: "5px",
    mb: 3,
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    borderBottom: "solid 1px rgba(224, 224, 224, 1)",
  },

  headerText: {
    fontFamily: "LarsseitMedium",
    fontSize: 18,
    color: "#0C2728",
  },

  cardBody: {
    padding: 2,
  },

  textField: {
    fontSize: 16,
    fontFamily: "LarsseitMedium",
    color: "#495D5E",
  },

  progressBarContainer: {
    height: '18px',
    borderWidth: 1,
    borderRadius: "5px",
    border: "solid 1px rgba(224, 224, 224, 1)",
    backgroundColor: '#f2f2f2',
  },

  progressBarForeground: {
    backgroundColor: '#07857C',
    height: '100%',
  }
};
