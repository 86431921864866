import React from "react";

const labelStyle = {
    fontSize: '12px',
    fontFamily: 'Roboto',
    color: '#8a8a89',
    fontWeight: 400,
    padding: '0 15px',
    width: '83%',
    margin: '0 auto',
    display: 'block'
};

const textAreaStyle =  {
    minHeight: '140px'
}

const TextArea = ({ input, label, type, readOnly, meta: {touched, error, warning} }) => {
    return (
        <div>
            <label htmlFor={label} style={labelStyle}><span>{label}</span></label>
            <textarea {...input} type={type}
                   className="textarea"
                   required
                   style={textAreaStyle}
                   readOnly={readOnly}
            />
        </div>
    );
};

export default TextArea;