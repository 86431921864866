import React, {Fragment, useEffect} from "react";
import { Grid } from "@mui/material";
import { InlineWidget } from "react-calendly";
import {Box} from "@mui/system";
import {buttonContained} from "../Utilities/ButtonContained";
import BasicModal from "../Utilities/BasicModal";
import ScheduleConfirmationModal from "./ScheduleConfirmationModal";
import {history} from "_helpers";
import {Helmet} from "react-helmet";


export default function ScheduleCallSetup() {
  const [open, setOpen] = React.useState(false);
  const [schedule, setSchedule] = React.useState(false);

  const SCHEDULED_EVENT = 'calendly.event_scheduled';
  const STORAGE_KEY = 'is_scheduled';
  const CALENDLY_URL = "https://calendly.com/chatdeskdemo/30min?hide_event_type_details=1&hide_gdpr_banner=1&text_color=0c2728&primary_color=368c86";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {ActionButton} = buttonContained;

  useEffect(() => {
    window.addEventListener( 'message', processEvent);

    return () => {
      window.removeEventListener('message', processEvent);
    };
  });

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }

  function processEvent (e) {
    if (isCalendlyEvent(e)){
      const isScheduled = e.data.event === SCHEDULED_EVENT;
      setSchedule(isScheduled);
      //Store data in local storage if user is scheduling a call for the first time
      if(!JSON.parse(localStorage.getItem(STORAGE_KEY))){
        localStorage.setItem(STORAGE_KEY, isScheduled);
      }
    }
  }

  function performAction() {
    if(schedule || JSON.parse(localStorage.getItem(STORAGE_KEY))){
      history.push('/signup/dashboard'); //Navigate to dashboard if user already scheduled a call
    }else{
      handleOpen(); //Open confirmation modal
    }
  }

  return(
    <Fragment>
      <Grid container sx={styles.container}
            mt={{xs: '50px', sm: '50px', md: '60px', lg: '60px'}}>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
        </Helmet>
        <Box sx={{zIndex: '2'}}>
          <InlineWidget url={CALENDLY_URL} />
        </Box>
      </Grid>
      <Box width={{xs: '100%', sm: '100%', md: '160px', lg: '160px'}}
           mb={{xs: '40px', sm: '40px', md: '0px', lg: '0px'}}
           sx={{marginLeft: 'auto'}}>
        <ActionButton
          label={"Complete"}
          action={performAction}/>
      </Box>

      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen} title={'Are you sure?'}>

        <ScheduleConfirmationModal handleClose={handleClose}/>
      </BasicModal>
    </Fragment>
  )
}

const styles = {
  container: {
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
    position: 'relative'
  }
};