import { useEffect } from 'react'
import {useDispatch, useSelector} from "react-redux";
import {metricsActions} from "_actions";
import {ExpertsTicketMetricsBuilder} from "../objects/ExpertsTicketMetricsBuilder";
import _ from "lodash";

export default function (startDate, endDate) {
  const dispatch = useDispatch();

  useEffect(() => {
    const { organization_id = '' } = JSON.parse(localStorage.user);
    dispatch(metricsActions.getExpertsTicketMetrics(organization_id, startDate, endDate));
  }, [startDate, endDate])

  const metrics = useSelector(state => state.metrics);
  const expertsTicketMetrics = metrics.expertsTicketMetrics;
  const loadingExpertsTicketMetrics = metrics.loadingExpertsTicketMetrics;
  const expertsTicketVolumeList = _.get(expertsTicketMetrics, 'expertsTicketVolumeList') || [];
  const expertsData = [];

  if(!loadingExpertsTicketMetrics && expertsTicketVolumeList.length > 0) {
    expertsTicketVolumeList.map( expertsTicketVolume => {
      const builder = new ExpertsTicketMetricsBuilder();
      builder.setExpertName(expertsTicketVolume.expertName || '');
      builder.setVolumeTicketsHandled(expertsTicketVolume.volumeTicketsHandled || 0);

      expertsData.push(builder.metrics);
    })
  }

  return { expertsData, loadingExpertsTicketMetrics }
}