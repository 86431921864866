import React, {useEffect} from "react";
import {Box, Button} from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {useDispatch, useSelector} from "react-redux";
import {walletActions} from "_actions";
import _ from "lodash";
import {calculateProgress} from "../ExpertEarnings/Utilities/EarningUtils";

export default function WalletBalance(){
    const dispatch = useDispatch();
    const walletBalance = useSelector((state) => state.wallet.walletBalance);
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const agentId = _.get(currentUser, "id");

    const roundTo = (value, roundLimit) => {
        if (typeof value !== "number" || typeof roundLimit !== "number") return 0;
        let rem = value % roundLimit;
        let num = value - rem;

        return num + roundLimit;
    };

    const refreshBalance = () => {
        dispatch(walletActions.getAgentWalletBalance(agentId));
    }

    useEffect(() => {
        dispatch(walletActions.getAgentWalletBalance(agentId));
    }, []);

    return(
        <Box sx={{p: 2, borderRadius: '5px', backgroundColor: '#FFFFFF', border: '1px solid #CED4D4'}}>
            <Box sx={{textTransform: "capitalized", color: "#0C2728", fontSize: "14px", fontFamily: "LarsseitBold", mb: 1}}>
                CURRENT BALANCE
            </Box>
            <Box sx={{textTransform: 'capitalize', color: "#0C2728", fontSize: '48px', fontFamily: "LarsseitRegular", mb: 1}}>
                ${walletBalance?.wallet?.balance || 0}
            </Box>

            <Box sx={styles.progressBarContainer}>
                <Box sx={{...styles.progressBarForeground,
                    width: calculateProgress(walletBalance?.wallet?.balance, roundTo(walletBalance?.wallet?.balance, 50)) + '%'}} />
            </Box>

            <Box sx={{color: "#0C2728", fontSize: 14, fontFamily: 'larsseitRegular', mt: 2}}>
                {`You’re almost there! Keep answering messages to get to your $${roundTo(walletBalance?.wallet?.balance, 50)}`} payout.
            </Box>

            <Button variant="text" sx={styles.refreshBtn} onClick={refreshBalance}>
                <AutorenewIcon fontSize={'small'}/>
                Refresh
            </Button>
        </Box>
    )
}

const styles = {
    progressBarContainer: {
        height: '15px',
        borderWidth: 1,
        borderRadius: "3px",
        border: "solid 1px rgba(224, 224, 224, 1)",
        backgroundColor: 'transparent',
    },

    progressBarForeground: {
        backgroundColor: '#DBD8FF',
        height: '100%',
        borderLeftRadius: "3px",
    },

    refreshBtn: {
        color: '#07857C',
        textTransform: 'none',
        px: 0,
        letterSpacing: '-0.02em',
        ':hover': {
            backgroundColor: 'transparent',
        },
    }
}