import React from "react";
import {Box, Stack} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {styles} from '../Utilities/ProactiveMetricsDetailStyles';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {pageFilterToTitleMapper} from "../../MetricsDetail/MetricsDetailUtils";

export const HeaderLabel = ({ pageFilter }) => {
    const headerText = `Back to ${pageFilterToTitleMapper[pageFilter]}`;
    const adminLink = '/admin/campaign';
    const companyLink = '/company/campaign';
    const link = pageFilter === 'allCampaigns' ? adminLink : companyLink

    return (
        <Stack direction={'column'} style={{background: '#E5E5E5', position: 'absolute', left: '0', top: '30px'}}>
            <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <RouterLink to={link} className="tms-no-underline">
                    <Box sx={styles.backLink}>
                        <ArrowBackIosNewIcon sx={styles.arrowBack}/>
                        <span style={{ marginLeft: '20px'}}>{headerText}</span>
                    </Box>
                </RouterLink>
            </Box>
        </Stack>
    )
}