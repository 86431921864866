import React from "react";
import Stack from "@mui/material/Stack";
import {proactiveCriteriaOptions} from "../ProactiveMetricsDetailUtil";
import {ProactiveResponseButtons} from "../ProactiveResponseButtons";

export const ProactiveDropdownForm = ({ selectedCriteria, setSelectedCriteria, setLiked, setReplied, setNoEngagement, setFollowed, setConverted , setFollowUpSent, setConvertedAfterFollowUpSent }) => {

    return (
        <Stack direction={'row'} >
            <div className="filter-all-criteria" style={{marginLeft: '15px'}}>
                <ProactiveResponseButtons
                    selectedCriteria={selectedCriteria}
                    setSelectedCriteria={setSelectedCriteria}
                    criteriaOptions={proactiveCriteriaOptions}
                    setLiked={setLiked}
                    setReplied={setReplied}
                    setNoEngagement={setNoEngagement}
                    setFollowed={setFollowed}
                    setConverted={setConverted}
                    setFollowUpSent={setFollowUpSent}
                    setConvertedAfterFollowUpSent={setConvertedAfterFollowUpSent}
                />
            </div>
        </Stack>
    )
}