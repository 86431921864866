import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {ResponseOption} from "./ResponseOption";
import {getCurrentAgentName, isObjectEmpty, getArrayFromObjectArray, sortResponseOptions,getCurrentEmployment} from "_helpers";
import {votingOptions} from "_constants/vote.constants";
import _ from "lodash";
import {dispatcherActions} from "_actions";
import {workMode} from "_constants/common.constants";

/**
 * @author Chris Okebata
 */


class ResponsePanel extends Component{

  handleAllBadResponses = (responseOptions) => {
    let _votePayload = [];
    responseOptions.map(responseOption => {
      const votePayload = {
        response_id: responseOption.id,
        tag: votingOptions.REJECT,
        text: responseOption && responseOption.text,
        is_best_response: false,
        edited_text: '',
        edited_html_body: '',
        is_text_edited: false,
      };

      return _votePayload.push(votePayload);
    });

    this.props.submitVoteData(_votePayload);
  };

  updateMessageFromSource = () => {
    const { message } = this.props;
    const payload = {
      updateFields: {
        is_hidden: true
      },
      isV2Enabled: message.isV2Enabled,
      source: message.sourceName,
      actor: "voter"
    }
    this.props.dispatch(dispatcherActions.updateMessageFromSource(message.id, payload))
  }


  render() {

    const currentEmployment = getCurrentEmployment();
    const isLiveAgent = _.get(currentEmployment, 'mode') === workMode.ACTIVE;
    const {
      isResponseSelected, isEditing, onSelectResponse, selectedResponseId, submitVoteData, msgDataSource,
      closeModal, message, onSelectEditResponse, addedResponseOption, editedResponseObj, getObjectProperty, setObjectProperty } = this.props;
    const { response_options, dataSourceHandle, author, sourceName } = message;
    const agentName = getCurrentAgentName();
    const responseCount = _.get(response_options, 'length');

    const sortedResponseOptions = sortResponseOptions(response_options)

    if(!(responseCount > 0)){
      return(
        <Fragment>
          <div className="font-14" style={{width: '100%', height: '40px', display: 'flex', alignItems: 'center', padding: '0px 35px', borderBottom: '1px solid #dee2e0', backgroundColor: '#f3f3f3'}}>
            <span className="color-grey-dark">No response options found</span>
          </div>
        </Fragment>
      )
    }

    let responseOptionids = getArrayFromObjectArray(response_options, 'id');
    let addedResponseOptionids = getArrayFromObjectArray(addedResponseOption, 'id');

    if(!isObjectEmpty(_.get(addedResponseOption, '0')) && (addedResponseOptionids.filter(it => !responseOptionids.includes(it)))){
      addedResponseOption.filter( it => {
        if(!isObjectEmpty(_.get(it, 'id')) && !responseOptionids.includes(it.id)){
           return response_options.unshift(it);
        }
        return null
      });
    }

    return(
      <Fragment>
        <div className="font-14" style={{width: '100%', height: '40px', display: 'flex', alignItems: 'center', padding: '0px 35px', borderBottom: '1px solid #dee2e0', backgroundColor: '#f3f3f3'}}>
          <span className="color-grey-dark">Possible Responses</span>
        </div>

        <div style={{ height: 'calc(100% - 110px)', position: 'relative', backgroundColor: '#fafafa'}}>
          <div className="response-modal" onClick={ () => closeModal() } style={ isResponseSelected ? {display: 'block'} : {}}/>

          <div style={{padding: '25px 35px 0 35px', width: '100%', height: 'calc(100% - 45px)', position: 'absolute', top: '0'}}>
            <div style={{border: '1px solid #CED2D0', borderRadius: '4px', backgroundColor: '#ffffff', overflowY: 'auto', height: 'calc(100%)', padding: '0 0 10px 0'}}>

              {
                /*BEGIN LOOP HERE*/
                sortedResponseOptions.map((responseOption, index)=>{
                  return(
                    <ResponseOption
                      key={index}
                      index={index + 1}
                      responseOption={responseOption}
                      agentName={agentName}
                      messageHandleType={dataSourceHandle.type}
                      author={author}
                      sourceName={sourceName}
                      onSelectResponse={onSelectResponse}
                      onSelectEditResponse={onSelectEditResponse}
                      isResponseSelected={isResponseSelected}
                      isEditing={isEditing}
                      selectedResponseId={selectedResponseId}
                      submitVoteData={submitVoteData}
                      responseCount={responseCount}
                      editedResponseObj={editedResponseObj}
                      getObjectProperty={getObjectProperty}
                      setObjectProperty={setObjectProperty}
                      closeModal={closeModal}
                      msgDataSource={msgDataSource}
                      message={message}
                    />
                  )
                })
                /*END LOOP HERE*/
              }

            </div>
          </div>

          <div className="font-14 color-grey-dark" style={{width: '100%', position: 'absolute', bottom: '0', padding: '10px 35px',}}>
            <span>{`${response_options.length} Responses`}</span>
            <span style={{marginLeft: '40px'}}>All bad responses,</span>
            <button className="transparent-button" onClick={() => this.handleAllBadResponses(response_options)}>skip this message</button>
            {isLiveAgent && message && message.canBeHidden &&
              <button style={{color: '#1B508F'}} className="transparent-button" onClick={this.updateMessageFromSource}>Hide this message</button>
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { responseOptions } = state;

  return {
    addedResponseOption: responseOptions.addedResponseOption,
    loadingAddResponseOption: responseOptions.loadingAddResponseOption
  };
}

const connectedResponsePanel = connect(mapStateToProps)(ResponsePanel);

export { connectedResponsePanel as ResponsePanel };
