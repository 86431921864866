import {Fragment, useCallback, useEffect} from "react";
import {ClickAwayListener} from "@mui/material";
import {Box} from "@mui/system";

export default function SettingsSearchResultProvider(props) {
    const ESC_KEY_CODE = 27;
    const KEY_DOWN = 'keydown';
    const ON_MOUSE_DOWN = 'onMouseDown';
    const ON_TOUCH_START = 'onTouchStart';
    const {open, handleClose, children} = props;

    const handleEsc = useCallback((event) => {
        onEscKey(event);
    }, []);

    const onEscKey = (event) => {
        if (event.keyCode === ESC_KEY_CODE) {
            handleClose();
        }
    };

    const handleClickAway = () => {
        handleClose();
    };

    useEffect(() => {
        document.addEventListener(KEY_DOWN, handleEsc, false);
        return () => document.removeEventListener(KEY_DOWN, handleEsc, false);
    }, [handleEsc]);

    if(!open) {
        return <Fragment />
    }

    return(
        <ClickAwayListener mouseEvent={ON_MOUSE_DOWN} touchEvent={ON_TOUCH_START} onClickAway={handleClickAway}>
            <Box>{children}</Box>
        </ClickAwayListener>
    )
}