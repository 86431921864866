/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 13/09/2018 : 11:04 PM
 */

import {alertMessages, statsConstants} from '_constants';
import { statsService } from '_services';
import {SnackBar} from "../components/common";

function getData(params) {
  function request() {
    return { type: statsConstants.GET_DATA_REQUEST };
  }

  function success(data) {
    return { type: statsConstants.GET_DATA_SUCCESS, data };
  }

  function failure(error) {
    return { type: statsConstants.GET_DATA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    statsService.getData(params)
      .then(
        data => dispatch(success(data)),
        (error) => {
          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        },
      );
  };
}

function getAnalyticsData(params) {
  function request() {
    return { type: statsConstants.GET_ANALYTICS_DATA_REQUEST };
  }

  function success(data) {
    return { type: statsConstants.GET_ANALYTICS_DATA_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: statsConstants.GET_ANALYTICS_DATA_FAILURE, payload: error };
  }

  return (dispatch) => {
    dispatch(request());

    statsService.getAnalyticsData(params)
      .then(
        data => dispatch(success(data)),
        (error) => {
          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        },
      );
  };
}

const getSimilarityScoreStats = (data) =>{
  const request = () => ({
    type: statsConstants.GET_SIMILARITY_STATS_REQUEST
  });

  const success = (similarityScoreStats) => ({
    type: statsConstants.GET_SIMILARITY_STATS_SUCCESS, payload: similarityScoreStats
  });

  const failure = (error) => ({
     type: statsConstants.GET_SIMILARITY_STATS_FAILURE, payload: error
  });

  return (dispatch) => {
    dispatch(request());

    statsService.getSimilarityScoreStats(data)
        .then(
            response => {if (response && Object.keys(response).length === 0) SnackBar.showMessage(alertMessages.NO_DATA_FOR_FILTER)
                dispatch(success(response))
            },
            (error) => {
              dispatch(failure(error));
              SnackBar.showMessage(alertMessages.GENERIC_ERROR)
            },
        );
  };
}

export const statsActions = {
  getData,
  getAnalyticsData,
  getSimilarityScoreStats
};
