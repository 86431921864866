export class ScheduleBuilder {
    constructor() {
        this.schedule = {}
    }

    setId(id) {
        this.schedule.id = id;
        return this;
    }

    setName(name) {
        this.schedule.name = name;
        return this;
    }

    setDays(days) {
        this.schedule.days = days;
        return this;
    }

    setHandleId(handleId) {
        this.schedule.handleId = handleId;
        return this;
    }

    setHandleName(handleName) {
        this.schedule.handleName = handleName;
        return this;
    }

    setOrganizationId(organizationId) {
        this.schedule.organizationId = organizationId;
        return this;
    }

    setOrganizationName(organizationName) {
        this.schedule.organizationName = organizationName;
        return this;
    }

    setStartTime(startTime) {
        this.schedule.startTime = startTime;
        return this;
    }

    setEndTime(endTime) {
        this.schedule.endTime = endTime;
        return this;
    }

    setPayout(payout) {
        this.schedule.payout = payout;
        return this;
    }

    setPricing(pricing) {
        this.schedule.pricing = pricing;
        return this;
    }

    setActions(actions) {
        this.schedule.actions = actions;
        return this;
    }

    build(){
        return this.schedule;
    }
}