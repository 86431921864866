import React from 'react'
import {Field} from 'redux-form'
import {RowCheckbox} from "./RowCheckbox";

export const PagesForm = ({pages, handleSubmit, buttonHolder, pageTitle, pageType}) => {

    return (
        <div className="main-content-wrapper">
            <div className="content-pane-60 margin-0-auto">
                <div className="color-black-fade-mid font-15-bold margin-top-15">
                    {pageTitle}
                </div>

                <div className="padding-20-0">
                    <form name="facebook-pages" onSubmit={handleSubmit}>
                        <ul className="ul-list">
                            {pages.map((page) => {
                                let cannotAddPage = page.hasOwnProperty('canAddPage') && !page.canAddPage
                                return (
                                    //passing numerics as name props dosen't work with redux
                                    <Field
                                        type="checkbox"
                                        name={"page_" + page.id}
                                        label={page.name}
                                        value={page.id}
                                        key={page.id}
                                        component={RowCheckbox}
                                        pageType={pageType}
                                        disabled={cannotAddPage}
                                        disabledText={cannotAddPage ? " (Moderator or higher role needed)" : ""}
                                        disabledStyle={cannotAddPage ? {backgroundColor: '#fafafa'} : {}}
                                        pageId={page.pageId ? page.pageId : page.id}
                                    />
                                )
                            })}
                        </ul>
                        <div style={buttonHolder}>
                            <button className="btn btn-success" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};