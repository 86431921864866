import React, {Fragment} from "react";
import BasicMultiSelect from "./BasicMultiSelect";
import useGetMediaTypes from "../views/Proactive/Utilities/hooks/useGetMediaTypes";

export default function MediaTypeMultiSelect({ name, label, placeholder, handleFormSelectChange, handleChange, queryParam, input, meta, defaultValues }) {
    const { mediaTypes } = useGetMediaTypes(queryParam);

    return(
        <Fragment>
            <BasicMultiSelect
                name={name}
                label={label}
                placeholder={placeholder}
                options={mediaTypes}
                handleFormSelectChange={handleFormSelectChange}
                onChange={handleChange}
                input={input}
                meta={meta}
                defaultValues={defaultValues}
            />
        </Fragment>
    )
}