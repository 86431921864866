import {showDataSourceStatusService} from "../_services";
import { SnackBar } from "../components/common";
import { showDataSourceStatusConstants} from "../_constants";

function showDataSourceStatus(organizationId) {
    return dispatch => {
        dispatch(request());

        showDataSourceStatusService.showDataSourceStatus(organizationId)
            .then(
                data => dispatch(success(data)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(error)
                }
            );
    };

    function request() {
        return {type: showDataSourceStatusConstants.GET_ALL_REQUEST}
    }

    function success(data) {
        return {type: showDataSourceStatusConstants.GET_ALL_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: showDataSourceStatusConstants.GET_ALL_FAILURE, payload: error}
    }


}


export const showDataSourceStatusActions = {
    showDataSourceStatus,
};