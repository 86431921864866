
export class SegmentBuilder {
    constructor() {
        this.segment = {};
    }

    setId(id){
        this.segment.id = id;
        return this;
    }

    setType(type){
        this.segment.type = type;
        return this;
    }

    setKeyword(keyword){
        this.segment.keyword = keyword;
        return this;
    }

    setCustomerDataFileId(customerDataFileId){
        this.segment.customerDataFileId = customerDataFileId
    }

    setPhrase(phrase){
        this.segment.phrase = phrase;
        return this;
    }

    setCampaignId(campaignId) {
        this.segment.campaignId = campaignId;
        return this;
    }

    setIsActive(isActive) {
        this.segment.isActive = isActive;
        return this;
    }

    setActions(actions) {
        this.segment.actions = actions;
        return this;
    }

    setCustomerDataFileName(customerDataFileName) {
        this.segment.customerDataFileName = customerDataFileName;
        return this;
    }
    setCustomerDataFileStatus(customerDataFileStatus) {
        this.segment.customerDataFileStatus = customerDataFileStatus;
        return this;
    }

    build(){
        return this.segment;
    }
}