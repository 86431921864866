import React from "react";
import { Stack } from "@mui/material";
import {Header} from './Sections/Header';
import {ProactiveMessagesBoxContainer} from './Sections/ProactiveMessagesBoxContainer';
import {styles} from './Utilities/ProactiveMetricsDetailStyles';
import {MetricsDetailFooter as Footer} from "../MetricsDetail/Sections/MetricsDetailFooter";

export const CampaignMetricsDetailComponent = ({
                                                   pageFilter,
                                                   isFilterDropdownOpen,
                                                   setIsFilterDropdownOpen,
                                                   isCampaignNameDropdownOpen,
                                                   setIsCampaignNameDropdownOpen,
                                                   selectedCheckboxes,
                                                   setSelectedCheckboxes,
                                                   selectedCriteria,
                                                   setSelectedCriteria,
                                                   filterRef,
                                                   filterPageRef,
                                                   handleExport,
                                                   pageParams,
                                                   setPageParams,
                                                   selectedDropdownOption,
                                                   onSelectChangeHandler,
                                                   onFilterFormSubmit,
                                                   handleFilterReset,
                                                   dateRange,
                                                   setDateRange,
                                                   extractedCampaignList,
                                                   setLiked,
                                                   setReplied,
                                                   setNoEngagement,
                                                   setFollowed,
                                                   setConverted,
                                                   setFollowUpSent,
                                                   setConvertedAfterFollowUpSent
                                               }) => {
    const renderMetricsMessageBody = () => {
        return (
            <Stack direction={'row'} sx={styles.hero} spacing={1}>
                <ProactiveMessagesBoxContainer
                    pageFilter={pageFilter}
                    pageParams={pageParams}
                    setPageParams={setPageParams}
                />
            </Stack>
        )
    }
    return (
        <>
            <Header
                pageFilter={pageFilter}
                isFilterDropdownOpen={isFilterDropdownOpen}
                setIsFilterDropdownOpen={setIsFilterDropdownOpen}
                isCampaignNameDropdownOpen={isCampaignNameDropdownOpen}
                setIsCampaignNameDropdownOpen={setIsCampaignNameDropdownOpen}
                selectedCriteria={selectedCriteria}
                setSelectedCriteria={setSelectedCriteria}
                filterRef={filterRef}
                filterPageRef={filterPageRef}
                handleExport={handleExport}
                selectedDropdownOption={selectedDropdownOption}
                onSelectChangeHandler={onSelectChangeHandler}
                onFilterFormSubmit={onFilterFormSubmit}
                handleFilterReset={handleFilterReset}
                dateRange={dateRange}
                setDateRange={setDateRange}
                extractedCampaignList={extractedCampaignList}
                setLiked={setLiked}
                setReplied={setReplied}
                setNoEngagement={setNoEngagement}
                setFollowed={setFollowed}
                setConverted={setConverted}
                setFollowUpSent={setFollowUpSent}
                setConvertedAfterFollowUpSent={setConvertedAfterFollowUpSent}
            />
            {renderMetricsMessageBody()}
            <Footer />
        </>
    )
}