import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from 'components/common';
import {history} from "_helpers";
import {salesforceOauthActions} from "../../../../../../../_actions/salesforce.oauth.actions";

export default function SalesforceUrlRedirect() {
    const dispatch = useDispatch();
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const salesforceAuthCode = values.get('code');

    useEffect(() => {
        if (salesforceAuthCode) {
            const params = {code: salesforceAuthCode};
            dispatch(salesforceOauthActions.genAccessToken(params));
        }
    }, []);

    const salesforce = useSelector((state) => state.salesforceOauth);

    if (salesforce.loading) {
        return <Loading/>;
    }

    history.push('/company/channels');

    return (
        <Fragment/>
    )
}