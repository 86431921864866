import React, {Fragment, useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {getCurrentUser} from "_helpers";
import {msgSourceActions, freshdeskAuthActions} from "_actions";
import {Box} from "@mui/material";
import {Input, InputSubdomain} from "../../Utilities/Input";
import basicModalStyles from "../../Utilities/basicModalStyles";
import SetupModalBottomBar from "../../Utilities/SetupModalBottomBar";

function FreshdeskSetupModalForm({description, buttonLabel, extraInfoLabel, openInNewTab, handleSubmit}){
  const dispatch = useDispatch();
  const freshdesk = useSelector((state) => state.freshdeskAuth)

  const [disabled, setDisabled] = React.useState(true);
  const loading = React.useMemo(() => freshdesk.loading && !disabled, [freshdesk]);

	const placeholders = {
		supportEmail: 'Freshdesk Support Email Address',
		subdomain: 'yoursubdomain',
		apiKey: 'API Key',
	}

  useEffect(() => {
		dispatch(msgSourceActions.getMsgSourceLink('freshdesk'))
  }, []);

  const performAction = (value) => {
    setDisabled(isEmpty(value));
  };

  const onSubmit = (props) => {
		const { id } = getCurrentUser();
		const params = {
			apiKey: props.apiKey,
			subdomain: props.subdomain,
			supportEmail: props.supportEmail,
			userId: id
		};
		dispatch(freshdeskAuthActions.verifyKey(params))
  };

  return(
    <Fragment>
      <Box sx={basicModalStyles.container}>
        <Box sx={basicModalStyles.description}>
          {description}
        </Box>

        <form name="freshdeskForm" onSubmit={ handleSubmit(onSubmit) }>
					<Field
            name={'subdomain'}
            component={InputSubdomain}
            endAdornmentDomainValue={'.freshdesk.com'}
            label='Freshdesk Subdomain'
            placeholder={placeholders.subdomain}
            performAction={performAction}
          />

          <Field
            name={'supportEmail'}
            component={Input}
            placeholder={placeholders.supportEmail}
          />

					<Field
            name={'apiKey'}
            component={Input}
            placeholder={placeholders.apiKey}
          />

          <SetupModalBottomBar
            extraInfoLabel={extraInfoLabel}
            openInNewTab={openInNewTab}
            buttonLabel={buttonLabel}
            disabled={loading || disabled}
            loading={loading}
          />
        </form>
      </Box>
    </Fragment>
  )
}

const formConfig = {
  form: 'freshdeskForm',
};

export default reduxForm(formConfig)(FreshdeskSetupModalForm);