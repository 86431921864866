import {msgDataSourceConstants, oauthConstants} from "_constants";

const INITIAL_STATE = {
    data: {},
    error: '',
    loading: true,
    status: '',
    channel: 'reamaze',
    showWebhookUrl:false,
    list: []
};

export function reamazeAuthReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case oauthConstants.REAMAZE_AUTH_REQUEST:
            return {...state, loading: true};
        case oauthConstants.REAMAZE_AUTH_SUCCESS:
            return {
                ...state,
                showWebhookUrl: true,
                data: action.payload,
                loading: false,
            };
        case oauthConstants.REAMAZE_AUTH_FAILURE:
            return {...state, error:action.error, loading:false };
        case oauthConstants.REAMAZE_CLOSE_MODAL:
            return {...state, showWebhookUrl: false, loading: false};

        case msgDataSourceConstants.GET_ALL_DATA_SOURCE:
            return {...state, loading: true};
        case msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS:
            return {
                ...state,
                list: action.payload ? action.payload.filter(item => item.dataSourceType === state.channel) : [],
                loading: false
            };
        case msgDataSourceConstants.ALL_DATA_SOURCE_ERROR:
            return {...state, error: action.error, loading: false};

        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES:
            return {...state, loading: true};
        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_SUCCESS:
            return {
                ...state,
                list: state.list.map(item => {
                    if (item.id === action.payload.msgDataSourceId) {
                        item[action.payload.updateField] = action.payload.enable
                    }
                    return item
                }),
                loading: false
            };
        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_ERROR:
            return {...state, error: action.error, loading: false};
        case oauthConstants.CLOSE_WEBHOOK_MODAL:
            return {...state, showWebhookUrl: false};
        default:
            return state;
    }
}
