export const metricsConstants = {
  GET_PERFORMANCE_REQUEST: 'GET_PERFORMANCE_REQUEST',
  GET_PERFORMANCE_SUCCESS: 'GET_PERFORMANCE_SUCCESS',
  GET_PERFORMANCE_FAILURE: 'GET_PERFORMANCE_FAILURE',

  GET_QUALITY_SCORE_METRICS_REQUEST: 'GET_QUALITY_SCORE_METRICS_REQUEST',
  GET_QUALITY_SCORE_METRICS_SUCCESS: 'GET_QUALITY_SCORE_METRICS_SUCCESS',
  GET_QUALITY_SCORE_METRICS_FAILURE: 'GET_QUALITY_SCORE_METRICS_FAILURE',

  GET_CHANNELS_REQUEST: 'GET_CHANNELS_REQUEST',
  GET_CHANNELS_SUCCESS: 'GET_CHANNELS_SUCCESS',
  GET_CHANNELS_FAILURE: 'GET_CHANNELS_FAILURE',

  GET_EXPERTS_TICKETS_REQUEST: 'GET_EXPERTS_TICKETS_REQUEST',
  GET_EXPERTS_TICKETS_SUCCESS: 'GET_EXPERTS_TICKETS_SUCCESS',
  GET_EXPERTS_TICKETS_FAILURE: 'GET_EXPERTS_TICKETS_FAILURE',

  GET_IMPACT_REQUEST: 'GET_IMPACT_REQUEST',
  GET_IMPACT_SUCCESS: 'GET_IMPACT_SUCCESS',
  GET_IMPACT_FAILURE: 'GET_IMPACT_FAILURE',

  GET_SCHEDULE_SUMMARY_REQUEST: 'GET_SCHEDULE_SUMMARY_REQUEST',
  GET_SCHEDULE_SUMMARY_SUCCESS: 'GET_SCHEDULE_SUMMARY_SUCCESS',
  GET_SCHEDULE_SUMMARY_FAILURE: 'GET_SCHEDULE_SUMMARY_FAILURE',

  GET_SCHEDULE_DETAILS_REQUEST: 'GET_SCHEDULE_DETAILS_REQUEST',
  GET_SCHEDULE_DETAILS_SUCCESS: 'GET_SCHEDULE_DETAILS_SUCCESS',
  GET_SCHEDULE_DETAILS_FAILURE: 'GET_SCHEDULE_DETAILS_FAILURE',

  GET_RESPONSE_TIME_DETAILS_REQUEST: 'GET_RESPONSE_TIME_DETAILS_REQUEST',
  GET_RESPONSE_TIME_DETAILS_SUCCESS: 'GET_RESPONSE_TIME_DETAILS_SUCCESS',
  GET_RESPONSE_TIME_DETAILS_FAILURE: 'GET_RESPONSE_TIME_DETAILS_FAILURE',

  GET_METRICS_DETAIL_CONVERSATION_REQUEST: 'GET_METRICS_DETAIL_CONVERSATION_REQUEST',
  GET_METRICS_DETAIL_CONVERSATION_SUCCESS: 'GET_METRICS_DETAIL_CONVERSATION_SUCCESS',
  GET_METRICS_DETAIL_CONVERSATION_FAILURE: 'GET_METRICS_DETAIL_CONVERSATION_FAILURE',

  GET_QUALITY_SCORE_MESSAGES_REQUEST: 'GET_QUALITY_SCORE_MESSAGES_REQUEST',
  GET_QUALITY_SCORE_MESSAGES_SUCCESS: 'GET_QUALITY_SCORE_MESSAGES_SUCCESS',
  GET_QUALITY_SCORE_MESSAGES_FAILURE: 'GET_QUALITY_SCORE_MESSAGES_FAILURE',

  GET_PRESALE_MESSAGES_REQUEST: 'GET_PRESALE_MESSAGES_REQUEST',
  GET_PRESALE_MESSAGES_SUCCESS: 'GET_PRESALE_MESSAGES_SUCCESS',
  GET_PRESALE_MESSAGES_FAILURE: 'GET_PRESALE_MESSAGES_FAILURE',

  GET_CUSTOMER_ANSWERED_MESSAGES_REQUEST: 'GET_CUSTOMER_ANSWERED_MESSAGES_REQUEST',
  GET_CUSTOMER_ANSWERED_MESSAGES_SUCCESS: 'GET_CUSTOMER_ANSWERED_MESSAGES_SUCCESS',
  GET_CUSTOMER_ANSWERED_MESSAGES_FAILURE: 'GET_CUSTOMER_ANSWERED_MESSAGES_FAILURE',

  EXPORT_METRICS_MESSAGES_REQUEST: 'EXPORT_METRICS_MESSAGES_REQUEST',
  EXPORT_METRICS_MESSAGES_SUCCESS: 'EXPORT_METRICS_MESSAGES_SUCCESS',
  EXPORT_METRICS_MESSAGES_FAILURE: 'EXPORT_METRICS_MESSAGES_FAILURE',

  SET_MESSAGE_PRESALE_VALUE_REQUEST: 'SET_MESSAGE_PRESALE_VALUE_REQUEST',
  SET_MESSAGE_PRESALE_VALUE_SUCCESS: 'SET_MESSAGE_PRESALE_VALUE_SUCCESS',
  SET_MESSAGE_PRESALE_VALUE_FAILURE: 'SET_MESSAGE_PRESALE_VALUE_FAILURE',

  CLEAR_CONVERSATION: 'CLEAR_CONVERSATION',
}