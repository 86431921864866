import React, {Component} from "react";
import {GenericModal, SnackBar} from "components/common";
import {FindAndReplaceForm} from "./FindAndReplaceForm";
import {messageActions} from "../../_actions";
import {connect} from "react-redux";

class FindAndReplaceModal extends Component {
    submitForm = (values) => {
        const organizations = this.props.organizations
        const data = {...values, organizations: organizations}

        if (!organizations) {
            SnackBar.showMessage("No organization selected")
            return false
        }
        const submitForm = window.prompt("Are you sure you want to replace this?", "yes")
        if ((submitForm.toLowerCase() === 'sure') || (submitForm.toLowerCase() === 'yes')) {
            this.props.findAndReplaceText(data)
        }
    };

    render() {
        const {onHide, show, headerText, buttonText, submitButtonText} = this.props;
        return (
            <GenericModal
                show={show}
                onHide={onHide}
                headerText={headerText}
                buttonText={buttonText}
                hideFooter={true}
            >
                <div>
                    <FindAndReplaceForm
                        onSubmit={this.submitForm}
                        submitButtonText={submitButtonText}
                    />
                </div>
            </GenericModal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        findAndReplaceText: (values) => dispatch(messageActions.findAndReplaceText(values))
    }
}
const connectedComponent = connect(null, mapDispatchToProps)(FindAndReplaceModal);

export { connectedComponent as FindAndReplaceModal}