/**
 * @author Chris Okebata
 */


const stylesOrganizationTable = ({
  root: {
    width: '100%',
    overflowX: 'auto',
    borderRadius: '3px',
    border: 'solid 1px #dee2e0',
    boxShadow: '0 0px 1px 0 rgba(196, 201, 199, 0.5)',
  },
  table: {
    minWidth: 700,
    tableLayout: 'fixed',
  },
  tableRow: {
    height: '47px',
    cursor: 'pointer',
    '&:hover': {
      background: '#fbfbfb',
    },
  },
  tableHeadCell: {
    width: '15%',
    color: '#879190',
    paddingRight: '0',
    '&:first-child': {
      width: '20%',
      textAlign: 'left',
    },
    '&:last-child': {
      width: '5%',
      textAlign: 'right',
    },
  },
  tableCell: {
    width: '15%',
    color: '#464a48',
    paddingRight: '0',
    '&:first-child': {
      width: '20%',
      textAlign: 'left',
    },
    '&:last-child': {
      width: '5%',
      textAlign: 'right',
    },
  },
  typography15: {
    width: '100%',
    fontSize: '15px',
    color: '#2C302E',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  typography14: {
    fontSize: '13px',
    color: '#5a5f5e',
  },
});

export default stylesOrganizationTable;