import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {dataSourceHandleActions, organizationActions, portalActions} from "_actions";
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import stylesPortal from './stylesPortal'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Loading} from "../common";
import {
  AddPortalModal,
  EditPortalModal,
  DeletePortalModal,
  OrganizationPortal,
  ViewPortalModal
} from "components/Portal";
import {isObjectEmpty, groupDataBy} from "_helpers";
import {publicAndFeaturedVisibility} from "../../_constants";

/**
 * @author: Chris Okebata
 */

class Portal extends Component {

  constructor(props){
    super(props);

    this.state = {
      expanded: false,
      expandedOrg: '',
      addPortalModalStatus: false,
      editPortalModalStatus: false,
      deletePortalModalStatus: false,
      viewPortalModalStatus: false,
      selectedPortal: {},
    }
  }

  componentDidMount() {
    const {dispatch} = this.props;

    dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}));
    dispatch(portalActions.getAll());
    dispatch(portalActions.getAllIntegratedPortals());
    dispatch(dataSourceHandleActions.getAll({ handleStatus: 'LIVE' }));
  }

  resetSelection = () => {
    this.setState({
      selectedPortal: {},
    })
  };

  closePortalModal = (name) => {
    this.setState({
      [name]: false,
    });

    this.resetSelection();
  };

  openPortalModal = (event, name, data) => {
    event.stopPropagation();

    this.setState({
      [name]: true,
      selectedPortal: data,
    });
  };

  renderOrganizationRow = (item, classes) => {

    const orgName = item[0];

    return(
      <div className={classes.root} key={orgName}>
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
            className={classes.expansionPanelSummary}
          >
            <Typography className={classes.typography15}>
              {orgName || ''}
            </Typography>
          </ExpansionPanelSummary>
          <OrganizationPortal portalArr={item[1]} openModal={this.openPortalModal}/>
        </ExpansionPanel>
      </div>
    )
  };

  render() {

    const { allPortals, classes } = this.props;

    if (isObjectEmpty(allPortals)) {
      return <Loading />
    }

    const groupedData = groupDataBy(allPortals.portal, 'organization', 'name');

    return(
      <Fragment>
        <AddPortalModal
          headerText="Add portal"
          buttonText="Add portal"
          onHide={() => {this.closePortalModal('addPortalModalStatus')}}
          show={this.state.addPortalModalStatus}
        />

        <EditPortalModal
          headerText="Edit portal"
          buttonText="Edit portal"
          onHide={() => {this.closePortalModal('editPortalModalStatus')}}
          show={this.state.editPortalModalStatus}
          portalData={this.state.selectedPortal}
        />

        <DeletePortalModal
          headerText="Delete portal"
          buttonText="Delete portal"
          onHide={() => {this.closePortalModal('deletePortalModalStatus')}}
          show={this.state.deletePortalModalStatus}
          portalData={this.state.selectedPortal}
        />

        <ViewPortalModal
          headerText="View portal"
          buttonText="OK"
          onHide={() => {this.closePortalModal('viewPortalModalStatus')}}
          show={this.state.viewPortalModalStatus}
          portalData={this.state.selectedPortal}
        />

        <div style={{ padding: '0 38px' }}>
          <div className="content-pane-90" style={{marginBottom: 80}}>
            <div style={{margin: "20px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: '16px',
              fontWeight: 'bold'
            }}>
              <span>
                Portal
              </span>

              <button className="btn btn-success font-16" onClick={(e)=>{this.openPortalModal(e, 'addPortalModalStatus')}}
                      style={{textAlign: 'center', padding: '8px 20px', maxHeight: 40}}>
                Add Portal
              </button>
            </div>

            {
              allPortals.portal.length === 0 && <Fragment>
                <div className={classes.root}>
                  <Typography className={classes.typography15}>
                    <span style={{padding: '20px'}}>
                      No portals available
                    </span>
                  </Typography>
                </div>
              </Fragment>
            }

            <Fragment>
              {Object.entries(groupedData).map((groupedItem) => this.renderOrganizationRow(groupedItem, classes))}
            </Fragment>
          </div>
        </div>
      </Fragment>

      )
  }
}

function mapStateToProps(state) {
  const { portals: { allPortalData, error, loading } } = state;
  return {
    loading,
    allPortals : allPortalData,
    error,
  };
}

Portal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedPortal = connect(mapStateToProps)(withStyles(stylesPortal)(Portal));
export { connectedPortal as Portal };