import { Box, Button } from '@mui/material';
import { history } from '_helpers';
import styles from './styles';
import ChannelListItem from 'components/v2/views/Channels/Utilities/ChannelListItem';
import getChannelImgUrl from 'components/v2/views/Channels/Utilities/getChannelImgUrl';

const LocalItem = ({ msgSource }) => {
	const sourceImg = getChannelImgUrl(msgSource.name);
	const dataSources = msgSource?.msgDataSources?.map((source) => source.name)

	return (
		<Box sx={{
			backgroundColor: '#fff',
			p: { xs: '16px', sm: '24px' },
			borderTop: '1px solid #b6bebf',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			flexWrap: 'no-wrap',
			gap: '20px',
		}}>
			<ChannelListItem 
				label={msgSource.name}
				description={`Reconnect to ${msgSource.name.toLowerCase()}`}
				iconImageSrc={sourceImg}
				iconSize='small'
				dataSources={dataSources}
			/>

			<Box sx={{ mt: { xs: '12px', sm: 0 }, width: { xs: '100%', sm: 'auto' }, }}>
				<Button 
					sx={{
						width: '100%',
						height: '40px',
						fontSize: '16px',
						padding: '12px 24px',
						color: '#368C86',
						fontFamily: 'LarsseitMedium',
						textTransform: 'none',
						borderRadius: '50px',
						backgroundColor: '#ffffff',
						border: '1px solid #368C86',
						':hover': {
							backgroundColor: '#ffffff',
							color: '#368C86',
						},
						':disabled': {
							color: '#368C8680',
							backgroundColor: '#f5f5f5',
							border: '1px solid #368C8680'
						},
						':focus': {
							outline: '0'
						}
					}}
					onClick={() => history.push(`/company/connectchannels`)}
					variant="contained"
					disableElevation>
					Reconnect
				</Button>
			</Box>
		</Box>
	)
}

export default function ReconnectChannelsSection({
	msgSourcesInactive
}) {
	return (
		<Box sx={styles.card}>
			<Box sx={styles.cardHeader}>
				Reconnect channels ({msgSourcesInactive.length})
			</Box>

			<Box>
				{ msgSourcesInactive.map((msgSourceInactive, key) => (
					<LocalItem
						key={key}
						msgSource={msgSourceInactive}
					/>
				)) }
			</Box>
		</Box>
	)
}