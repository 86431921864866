import {Box, Grid} from "@mui/material";
import {Field} from "redux-form";
import CustomerIoDataSourceSelect from "./CustomerIoDataSourceSelect";
import CustomerIoSegmentSelect from "./CustomerIoSegmentSelect";
import {useState} from "react";

export default function CustomerIoSegment({queryParam, handleSegmentSelectChange}) {
    const selectPlaceholder = 'Choose';
    const [segmentQueryParam, setSegmentQueryParam] = useState({});

    const handleCustomerIoDataSourceSelectChange = (event) => {
        if (event.target.name === 'customerIoDataSource') {
            setSegmentQueryParam({...segmentQueryParam, msgDataSourceId: event.target.value });
        }
    }

    const handleCustomerIoSegmentSelectChange = (event) => {
        if (event.target.name === 'segments') {
            handleSegmentSelectChange(event.target.value);
        }
    }

    return(
        <Grid container item sx={{}}>
            <Grid item xs={12} md={6} sx={{}}>
                <Box mr={{md: '20px', lg: '20px'}} mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                    <Box sx={styles.inputDesc}>Customer.io data source</Box>
                    <Field
                        name="customerIoDataSource"
                        component={CustomerIoDataSourceSelect}
                        placeholder={selectPlaceholder}
                        queryParam={queryParam}
                        handleChange={handleCustomerIoDataSourceSelectChange}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{}}>
                <Box mt={{xs: '10px', sm: '10px', md: '15px', lg: '15px'}}>
                    <Box sx={styles.inputDesc}>Customer.io segment</Box>
                    <Field
                        name="segments"
                        component={CustomerIoSegmentSelect}
                        placeholder={selectPlaceholder}
                        queryParam={segmentQueryParam}
                        handleChange={handleCustomerIoSegmentSelectChange}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
}