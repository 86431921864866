import {Box} from "@mui/system";
import RadioButtonLabel from "../../../../../common/RadioButtonLabel";

export default function CannotResolveOption({option, onChange}) {
    return(
        <Box sx={style.radioContainer}>
            <RadioButtonLabel
                label={option.label}
                value={option.value}
                onChange={onChange}
            />
        </Box>
    )
}

const style = {
    radioContainer: {
        marginTop: '5px',
        whiteSpace: 'nowrap', //@TODO: remove on mobile
    }
}