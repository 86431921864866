import * as moment from 'moment-timezone'
import humanizeDuration from 'humanize-duration';
import {between} from "duration-fns";
import _ from "lodash";


export const adjustTimeZone = (date, dateWasUpdated) => {
        /*
         * The Logic here is to handle difference in timezone by
         * setting the endDate to today + 1 when the month is the same
         *
         * However, if the current endDate is already today + 1 or greater,
         * it is re-initialized to become today + 1 again, instead of adding another
         * 1 day that would make it become today + 2 or more
         */

    let newEndDate = moment(date);
    if (moment().isSame(date, 'month')) {
        if (moment(date).add(1, 'd').isSameOrAfter(moment().add(1, 'd'))) {
            newEndDate = moment().add(1, 'd')
        } else {
            if (dateWasUpdated) {
                newEndDate = moment(date).add(1, 'd')
            }
        }
    }
    return newEndDate
};

/**
 * Format duration
 * @param duration
 * @returns {null|*}
 */
export function formatDuration (duration) {
    if(!duration || typeof duration !== 'number') return null;

    return humanizeDuration(toMilliseconds(duration), {
        units: parseDurationUnits(duration),
        round: false,
        language: "shortEn",
        maxDecimalPoints: 2,
        languages: {
            shortEn: {
                d: () => "d",
                h: () => "h",
                m: () => "m",
            },
        },
    });
}

function parseDurationInMinutes(durationInMinutes){
    const _2hours = 120; // in minutes
    if (durationInMinutes <= 1) return 'min';
    if (durationInMinutes <= _2hours) return 'mins';
    return 'm';
}

function parseDurationInDays(durationInMinutes){
    const oneDay = 1440, _15Minutes = 15; // in minutes
    return (durationInMinutes >= oneDay && durationInMinutes < oneDay + _15Minutes) ? 'day' : 'days';
}

function parseDurationUnits(durationInMinutes){
    const _24hours = 1440, _2hours = 120; // in minutes
    if(durationInMinutes <= _2hours) return ["m"];
    if(durationInMinutes > _2hours && durationInMinutes < _24hours) return ["h", "m"];
    if(durationInMinutes >= _24hours) return ["d"];
}

/**
 * Convert minutes to milliseconds
 * @param durationInMinutes {number}
 * @returns {null|number}
 */
export function toMilliseconds (durationInMinutes) {
    if(!durationInMinutes && typeof durationInMinutes !== 'number') return null;
    return durationInMinutes * 60 * 1000;
}

/**
 * Convert time to 12 hours format, e.g. 2021-02-19 23:59:38.0 to 11:59pm
 * @param time
 * @param timezone
 * @returns {string}
 */
export function to12Hours(time, timezone = ''){
    if(typeof time !== 'string') return '';
    return `${moment(time).format('h:mma')} ${timezone}`;
}

/**
 * @param {number} hour Floating hour in 24hr format, e.g. 8.25 for 8:15am, 16.5 for 4:30pm, 22 for 10pm
 * @returns {string} Formatted time string, e.g. 8:15am, 4:30pm, 10pm
 */
const format24to12Hour = (hour) => {
    if (typeof hour !== 'number')
        return 0

    let minutes = 0

    let hourWithoutFloating = Math.floor(hour)
    const floating = hour - hourWithoutFloating
    if (floating)
        minutes = Math.floor(floating * 60)

    const str = `${hourWithoutFloating}:${minutes}`
    const format = minutes ? 'h:mma' : 'ha'
    return moment(str, 'hh:m').format(format)
}

export function parseDates(startDate, endDate) {
    return {
        startDate: startDate.startOf('day').toISOString(),
        endDate: endDate.endOf('day').toISOString()
    }
}

export function isValidDate (startDate, endDate) {
    return moment(startDate).isBefore(endDate);
}

export function isTimeToday(date) {
    return moment().isSame(moment(date), 'day') &&
        moment().isSame(moment(date), 'month')
}

/**
 * @param date {string}
 * @returns {string}
 */
export function getDuration(date) {
    if(!date) return '';
    const formattedDate = moment(date).format('MMMM D, YYYY h:mm:ss a');
    const now = moment().format('MMMM D, YYYY h:mm:ss a');
    const timeDifferenceObj = between(formattedDate, now);
    const timeArr = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']

    for(let index = 0; index < timeArr.length; index++) {
        if(timeDifferenceObj[timeArr[index]] !== 0) {
            let label = timeArr[index];
            let duration = timeDifferenceObj[timeArr[index]]
            label = duration > 1 ? label : label.substr(0,label.length - 1);
            return `${duration} ${label} ago`;
        }
    }
}

export function getRecentDateFromArray(dateArr) {
    const moments = dateArr.map(date => moment(date));
    const dateObj = moment.max(moments);
    return _.get(dateObj, '_i');
}

export function getTimeZone() {
    return moment.tz.guess();
}

/**
 * @param yearCount {number}
 * @returns {{endDate: moment.Moment, label: string, startDate: moment.Moment}}
 */
export function getYearRange(yearCount) {
    return {
        label: `Last ${yearCount} Years`,
        startDate: moment().subtract(yearCount, 'years'),
        endDate: moment()
    }
}

export function formatToDate(dateTime) {
    if (!dateTime)
        return '-';
    const zone_name =  moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();
    return `${moment(dateTime).format('MMM D, YYYY hh:mm a z')}${timezone}`;
}