import React, { Component, Fragment } from "react";

import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { DateRange } from 'components/common/formFields/DateRange'
import { DropDownSelect } from "../common";
import { ChannelsContextMenu } from "components/Inbox";
import PropTypes from "prop-types";


/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 10/12/2018 : 1:58 PM
 */

const dropdownStyles = {
    borderColor: "#c4c9c7",
    boxShadow: "none",
    color: "#5a5f5e"
};

class FilterForm extends Component {

    render() {

        const {
            submitting,
            handleSubmit,
            onFormUpdate,
            onFormSubmit,
            organization,
            organizations,
            channelsFilter,
            channelsIds,
            channelsAction,
            handleChannelsFilterChange,
            startDate,
            endDate,
        } = this.props;

        const organizationInput = {onChange: onFormUpdate, value: organization};
        const channelsInput = {onChange: onFormUpdate, value: channelsAction};

        return (
            <Fragment>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <div>
                        <span className="font-14 color-grey-dark">Date</span>
                        <label className="calendar-today tms-inbox-calendar" style={{display: "block"}}>
                            <Field
                                submittedStartDate={startDate}
                                submittedEndDate={endDate}
                                name="dateRange"
                                type="text"
                                values={startDate}
                                component={DateRange}/>
                        </label>
                    </div>
                    <div style={{marginTop: "10px", display: "block"}}>
                        <span className="font-14 color-grey-dark">Organization</span>
                        <label className="" style={{display: "block"}}>
                            <Field
                                name="organization"
                                fieldName="organization"
                                type="text"
                                input={organizationInput}
                                style={dropdownStyles}
                                options={organizations.organizations}
                                placeHolder="Select Organization"
                                placeHolderValue="select_organization"
                                placeholderVisible={false}
                                value={organization}
                                component={DropDownSelect}/>
                        </label>
                    </div>
                    
                    <div style={{marginTop: "10px", display: "block"}}>
                        <span className="font-14 color-grey-dark">Channels</span>
                        <div className="inbox-context-menu" style={{display: "block"}}>
                            <Field
                                name="channels"
                                fieldName="channels"
                                type="text"
                                input={channelsInput}
                                style={dropdownStyles}
                                options={channelsFilter}
                                checkedChannels={channelsAction}
                                ids={channelsIds}
                                handleChange={handleChannelsFilterChange}
                                component={ChannelsContextMenu} />
                        </div>
                    </div>

                    <div style={{marginTop:"20px"}}>
                        <Button className="btn btn-success btn-block font-15" type="submit" disabled={submitting}>
                            Apply
                        </Button>
                    </div>
                </Form>
            </Fragment>
        );
    }
}


function validate(values) {
    const errors = {};

    if (!values.dateRange || !values.organization) {
        errors.error = "No valid input"
    }

    return errors;
}


const mapStateToProps = (state) => {
    const { auth, organizations, dataSourceHandles } = state;
    return {
        currentUser: auth,
        organizations: organizations.organizations,
        dataSourceHandles: dataSourceHandles.dataSourceHandles,
    };
};

FilterForm = connect(mapStateToProps)(FilterForm);

const filterForm = reduxForm({
    form: 'FilterForm',
    validate,
    touchOnBlur: false
})(FilterForm);

export { filterForm as FilterForm }

FilterForm.propTypes = {
    submitting: PropTypes.bool,
    dispatch: PropTypes.func,
    onFormUpdate: PropTypes.func,
    onFormSubmit: PropTypes.func,
    organization: PropTypes.string,
    organizations: PropTypes.array,
    onDatePickerUpdate: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    channelsFilter: PropTypes.array,
    channelsIds: PropTypes.array,
    channelsAction: PropTypes.array,
    sortBy: PropTypes.string,
    handleChannelsFilterChange: PropTypes.func,
    handleChange: PropTypes.func,
};
