import React from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import styles from "../../../../voteMainStyles";
import ConversationInfo from "../../../../../../common/Message/Conversation/ConversationInfo";
import ConversationEmptyState from "../../../../../../common/Message/Conversation/EmptyStates/ConversationEmptyState";
import ConversationEntry from "../../../../../../common/Message/Conversation/ConversationDisplayType/InAppConversation/ConversationEntry";
import FirebaseMessageListener from "../../../../Utilities/firebase/FirebaseMessageListener";

export default function InAppConversation({message, conversation, loading, withDm, refProp}) {
    const isLoading = _.isEmpty(conversation) && loading;
    const {conversationPanelRoot, conversationPanelRootWithDM, panelContent, conversationScrollArea} = styles;

    return(
        <Box sx={withDm ? conversationPanelRootWithDM : conversationPanelRoot}>
            <ConversationInfo message={message} loading={loading} />
            <Box sx={panelContent}>
                {
                    isLoading ?
                        <Box sx={conversationScrollArea}>
                            <ConversationEmptyState/>
                        </Box> :
                        <Box ref={refProp} sx={conversationScrollArea}>
                            <FirebaseMessageListener message={message} />
                            <ConversationEntry message={message} conversation={conversation} refProp={refProp} />
                        </Box>
                }
            </Box>
        </Box>
    )
}