import {agentScoreConstants} from "_constants";

/**
 * @author Chris Okebata
 */

const INITIAL_STATE = {
  messageScore: [],
  loading: false,
  error: '',
};

export function agentScoreReducer(state = INITIAL_STATE, action) {

  switch (action.type) {

    case agentScoreConstants.GET_AGENT_MESSAGE_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case agentScoreConstants.GET_AGENT_MESSAGE_SCORE_SUCCESS:
      return {
        ...state,
        messageScore: action.payload,
        loading: false
      };
    case agentScoreConstants.GET_AGENT_MESSAGE_SCORE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state
  }
}