import React, {Component, Fragment} from 'react';
import { msgDataSourceActions } from '_actions';
import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {SnackBar, Loading} from "components/common";
import {alertMessages} from "_constants";
import { queryStringToObject } from "_helpers";

const contentStyles = {
    width: '50%',
    padding: '20px 40px',
    minHeight: '40vh'
};

class OrgViewsList extends Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);
        let { org, msgSource, views } = filterParams;

        this.state = {
            currentOrg: org,
            msgSource: msgSource,
            viewSelectionList: views && views.split(',') || []
        };
    }

    componentDidMount() {
        const { currentOrg, msgSource } = this.state;
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getOrganizationViews(currentOrg, msgSource));
    }

    handleViewSelection = (e) => {
        this.setState((prevState) => {
            let viewSelectionList = prevState.viewSelectionList;
            if (e.target.checked) {
                viewSelectionList.push(e.target.value)
            } else {
                viewSelectionList = viewSelectionList.filter(val => val !== e.target.value)
            }
            return {
                viewSelectionList
            }
        })
    };

    getOpenTicketsCount = () => {
        const { viewSelectionList, currentOrg } = this.state;
        const { gorgiasDataSourceId, history } = this.props;
        if (viewSelectionList.length === 0) {
            SnackBar.showMessage(alertMessages.SELECT_VIEWS)
        }
        history.push(`/open-tickets-list?dataSource=${gorgiasDataSourceId}&org=${currentOrg}&views=${viewSelectionList.join(',')}`);
    };

    updateOrganization = () => {
        const { history } = this.props;
        history.push('/open-tickets')
    };

    render() {
        const { gorgiasPlatformViews, msgDataSourceOrgList, gorgiasDataSourceId, loading } = this.props;
        const { viewSelectionList, currentOrg } = this.state;
        const orgData = msgDataSourceOrgList.find(item => item.orgId === currentOrg);
        const viewsList = gorgiasPlatformViews[gorgiasDataSourceId]

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <div className="row col-md-4 background-white" style={contentStyles}>
                    <Fragment>
                        <Button bsStyle="success" className="margin-top-15 btn-sm" type="submit" onClick={this.updateOrganization}>
                            Change Organization
                        </Button>
                        <hr />
                        <h4 className="col-12 margin-bottom-10">{orgData && orgData.orgName} Views</h4>
                        {loading && <Loading />}
                        {!loading && viewsList && viewsList.map(item => (
                            <label htmlFor={item.id} key={item.id} className="margin-top-5 display-block">
                                <input type="checkbox" className="form-check" name="platform"
                                       value={item.id} id={item.id} checked={viewSelectionList.includes(item.id.toString())}
                                       onChange={this.handleViewSelection}/>
                                <span className="margin-left-5">{item.name}</span>
                            </label>
                        ))}
                        <Button bsStyle="success" className="margin-top-15" type="submit" onClick={this.getOpenTicketsCount}>
                            Count Open Tickets
                        </Button>
                    </Fragment>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { msgDataSources: { gorgiasPlatformViews, msgDataSourceOrgList, gorgiasDataSourceId, loading }} = state;
    return {
        gorgiasPlatformViews, msgDataSourceOrgList, gorgiasDataSourceId, loading
    };
}

const connectedListGorgiasDataSources = connect(mapStateToProps)(OrgViewsList);
export { connectedListGorgiasDataSources as GorgiasOrgViewsList };
