import React from "react";
import {Box} from "@mui/system";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import _ from "lodash";
import TextButton from "../../../../../common/TextButton";
import styles from "../../../voteMainStyles";
import ConversationEntry
    from "../../../../../common/Message/Conversation/ConversationDisplayType/InAppConversation/ConversationEntry";
import ConversationEmptyState from "../../../../../common/Message/Conversation/EmptyStates/ConversationEmptyState";
import useGetTrendsConversation from "../../../../../hooks/Message/Conversation/useGetTrendsConversation";
import voteViews from "../../../Utilities/voteViews";

export default function SearchedMessageConversation({handleBackToResult}) {
    const {conversation, loading} = useGetTrendsConversation();

    const handleBackButton = () => {
        handleBackToResult(voteViews.searchResult);
    }

    const Content = () => {
        if(loading) return <ConversationEmptyState/>;
        if(!loading && _.isEmpty(conversation)) return <Box sx={style.noMessagesContainer}>No conversation found</Box>;
        if(!loading && !_.isEmpty(conversation)) return <ConversationEntry conversation={conversation} />;
    }

    return(
        <Box sx={styles.responsePanelRoot}>
            <Box sx={styles.searchedResultConversationHeading}>
                <Box sx={style.backButtonContainer}>
                    <TextButton
                        label={'Back to search result'}
                        action={handleBackButton}
                        sx={style.backButton}
                        rootStyle={style.backButtonRoot}
                        startIcon={<ChevronLeftOutlinedIcon sx={style.backButtonIcon}/>}
                    />
                </Box>
            </Box>
            <Box sx={styles.panelContent}>
                <Box sx={styles.conversationScrollArea}>
                    <Content />
                </Box>
            </Box>
        </Box>
    )
}

const style = {
    backButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        height: '25px'
    },
    backButtonRoot: {
        fontSize: '14px',
        height: '20px',
        color: '#0C2728',
        textAlign: 'left',
        fontFamily: 'LarsseitMedium',
        ':hover': {
            color: '#081a1a',
            backgroundColor: 'unset'
        },
        ':disabled': {
            color: '#8c8f8f',
        },
        ':active': {
            outline: '0',
            color: '#0C2728',
        },
    },
    addedResponseCountContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    backButtonIcon: {
        mr: '-5px',
    },
    filterContainer: {
        width: '131px',
        marginLeft: '12px',
        border: '1px solid'
    },
    noMessagesContainer: {
        fontSize: '15px',
        color: '#0C2728',
        textAlign: 'left',
        fontFamily: 'LarsseitRegular',
        border: '1px solid #DCE2E3',
        p: '10px',
        borderRadius: '5px'
    }
}