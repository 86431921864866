import {Fragment, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {Box, Link, useTheme, useMediaQuery} from "@mui/material";
import {utilityActions, msgDataSourceActions} from "_actions";
import {history} from '_helpers';
import Input from './Input';
import basicModalStyles from "./basicModalStyles";
import {SnackBar} from "components/common";
import {buttonContained} from "components/v2/views/Onboarding/Utilities/ButtonContained";
import BasicModal from 'components/v2/views/Onboarding/Utilities/BasicModal';

/**
 * This modal will show after the user has entered some API data into a channel setup modal,
 * we verified them and generated a webhook URL.
 * When the whole process is successful, we show this modal so the user can copy the newly generated webhook URL.
 * @param {Object} props
 * @param {boolean} props.showWebhookUrl Boolean to show the modal or not. Should come from store.
 * @param {string} props.webhookUrl
 * @param {string} props.platformUrl
 * @param {string} props.dataSourceName
 */
export default function DataSourceWebhookModal({
	showWebhookUrl, webhookUrl, platformUrl, dataSourceName,
}) {
  const {ActionButton, SignupButton} = buttonContained;
	const dispatch = useDispatch()
	const inputRef = useRef(null)

	const closeModal = () => {
		history.push('/company/channels');
		dispatch(msgDataSourceActions.clearWebhookModal())
	}

	const copyToClipBoard = (event, textArea) => {
		dispatch(utilityActions.copyToClipboard(event, textArea));
		SnackBar.showMessage('Copied!');
	}

	return (
		<Fragment>
			<BasicModal
        open={showWebhookUrl}
        handleClose={closeModal}
        title={`Copy Webhook URL and paste in ${dataSourceName}`}>

				<Box sx={styles.inputContainer}>
					<Box sx={styles.input}>
						<Input
							ref={inputRef}
							placeholder={'Webhook URL'}
							value={webhookUrl}
							multiline
							readOnly
						/>
					</Box>

					<Box sx={styles.actionButton}>
						<ActionButton label="Copy" buttonBordered action={(event) => copyToClipBoard(event, inputRef.current)} />
					</Box>
				</Box>

				{platformUrl && (
					<Box sx={styles.bottomBar}>
						<Box sx={basicModalStyles.description}>
							Add it <Link sx={styles.link} href={platformUrl} target='_blank' rel="noopener" underline="none">here</Link>
						</Box>

						<SignupButton label="Continue" action={closeModal} />
					</Box>
				)}
      </BasicModal>
		</Fragment>
	)
}

const styles = {
	inputContainer: {
		display: 'flex',
		flexDirection: { xs: 'column', sm: 'row' },
	},
	input: {
		flexGrow: 1,
	},
	actionButton: {
		marginTop: { xs: 2, sm: 2.5 },
		marginLeft: { xs: 0, sm: 1.5 },
	},
	bottomBar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 2.5,
	},
	link: {
		color: '#368C86',
		fontFamily: 'LarsseitMedium',
		':hover': {
			color: '#368C86',
			backgroundColor: 'unset'
		}
	},
}