import React from 'react'
import Checkbox from "@material-ui/core/Checkbox"

export const MaterialCheckbox = ({ input, meta, label, type, value}) => {
    return (
        <Checkbox
            {...input}
            onChange={input.onChange}
            value={value}
            name={input.name}><label>{label ? label: null}</label>
            type={type}
            format="h:mm a"
            className="xxx"
        </Checkbox>
    );
};