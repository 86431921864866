import React, {useEffect, useState} from "react";
import {
    convertUtcToTimezone,
    formatDateTime,
    getCurrentUser,
    processAdminMessage, queryStringToObject,
    timeDifferenceInSeconds
} from "_helpers";
import moment from "moment";
import useLocationQueryParams from "../../../../hooks/useLocationQueryParams";
import {useDispatch, useSelector} from "react-redux";
import {dataSourceHandleActions, messageActions} from '_actions';
import _ from "lodash";
import {AllMessagesBuilder} from "../objects/AllMessagesBuilder";
import {LOCK_DURATION_IN_SECS} from "_constants";
import MessageLockIcon from '@material-ui/icons/Lock';
import CheckMarkIcon from "../../../../../images/check_circle.svg";
import ParsedText from "../ParsedText";

export default function(){
    const ALL_MESSAGES = 'all_messages';
    const dispatch = useDispatch();
    const user = getCurrentUser();
    const { params } = useLocationQueryParams();
    const search = queryStringToObject(window.location.search.substring(1));
    const messageType = _.get(search, 'userAction');
    let { startDate, endDate, userAction, channels } = params;

    channels =  channels ? channels.split(",") : [];
    const currentUser = JSON.parse(localStorage.user);
    const {organization: {timezone}} = currentUser;

    const allMessages = useSelector((state) => state.messages.messages)
    const loading = useSelector((state) => state.messages.loading)

    const offset = params.offset || 0;
    const formattedStartDate = startDate ? moment(startDate).startOf('day') : moment().startOf('year');
    const formattedEndDate = endDate ? moment(endDate).endOf('day') : moment();
    startDate = formattedStartDate.format('YYYY-MM-DD HH:mm:ss');
    endDate = formattedEndDate.format('YYYY-MM-DD HH:mm:ss');

    const [key, setKey] = useState(() => {return userAction === ALL_MESSAGES ? 1 : 0})
    const [filterParams, setFilterParams] = useState({
        startDate,
        endDate,
        handleStatus: 'LIVE',
        organization: currentUser.organization_id,
        userAction,
        channels,
        offset
    })

    const populateParams = (key, channels, offset, startDate, endDate) => {

        startDate = moment(startDate)
            .format('YYYY-MM-DD HH:mm:ss');
        endDate = moment(endDate)
            .format('YYYY-MM-DD HH:mm:ss');
        let userAction;

        if (parseInt(key, 10) === 0)
            userAction =  'priority';
        else
            userAction = ALL_MESSAGES;

        offset = offset || 0;

        setKey(key)
        setFilterParams({
            ...filterParams,
            startDate,
            endDate,
            userAction,
            channels,
            offset
        })

        return {
            channels,
            startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            userAction,
            offset,
            handleStatus: filterParams.handleStatus,
            organization: filterParams.organization
        }
    }

    const selectRequestAction = (userAction, params) => {
        if (userAction === ALL_MESSAGES){
            const currentUser = JSON.parse(localStorage.getItem('user'));
            const { organization : {id} } = currentUser._embedded;
            dispatch(messageActions.getAllFromTrends(params, id))
        }
        else{
            dispatch(messageActions.getPriorityMessages(params));
        }
    };

    useEffect(() => {
        const {userAction, channels, offset, startDate, endDate, handleStatus} = filterParams
        const params = populateParams(key, channels, offset, startDate, endDate)

        const currentUser = JSON.parse(localStorage.getItem('user'));
        const { organization : { id } } = currentUser._embedded;

        selectRequestAction(userAction, params)
        dispatch(dataSourceHandleActions.getAll({organization: id, max: 100, handleStatus}));
    },[params])

    let { escalatedMessageCount, escalatedMessages } = allMessages
    let messages, count,totalCount;

    if(_.get(allMessages, 'params.userAction') === ALL_MESSAGES){
        let result = processAdminMessage(allMessages);
        messages = result.messages;
        count = result.count;
    }

    if(escalatedMessageCount) {
        totalCount = escalatedMessageCount
    }

    if (count) {
        totalCount = count;
    }

    const rows = []
    let isEscalations = false

    if(!loading && escalatedMessages?.length > 0){
        escalatedMessages.map( message => {
            const builder = new AllMessagesBuilder();
            const formattedDate = formatDateTime(convertUtcToTimezone(message.dateCreated, timezone))
            const timeDiff = timeDifferenceInSeconds(moment(_.get(message, 'lockedAtTime')).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
            const lockedByCurrentUser = _.get(message, 'lockedByUser') === user.id;

            builder.setId(_.get(message, "id") || "")
            builder.setText(<ParsedText messageId={message.id} text={message.text} messageType={messageType || filterParams?.userAction} />)
            builder.setDate(formattedDate)
            builder.setIsLocked((_.get(message, 'lockedAtTime') && timeDiff < LOCK_DURATION_IN_SECS && !lockedByCurrentUser) ?
                <MessageLockIcon className="color-grey-mid" fontSize={'small'} style={{marginTop: '6px'}}/> : ""
            )
            builder.setStatus((_.get(message, "hasCompanyReplied") || _.get(message, "isDispatchedSuccessfully")) ?
                <img src={CheckMarkIcon} alt="actioned"/> : ""
            )

            isEscalations = true
            rows.push(builder.messages)
        })
    }

    if(!loading && messages?.length > 0){
        messages?.map( message => {
            const builder = new AllMessagesBuilder();
            const formattedDate = formatDateTime(convertUtcToTimezone(message.dateCreated, timezone))
            const timeDiff = timeDifferenceInSeconds(moment(_.get(message, 'lockedAtTime')).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
            const lockedByCurrentUser = _.get(message, 'lockedByUser') === user.id;

            builder.setId(_.get(message, "id") || "")
            builder.setText(<ParsedText messageId={message.id} text={message.text} messageType={messageType || filterParams?.userAction} />)
            builder.setDate(formattedDate)
            builder.setIsLocked((_.get(message, 'lockedAtTime') && timeDiff < LOCK_DURATION_IN_SECS && !lockedByCurrentUser) ?
                <MessageLockIcon className="color-grey-mid" fontSize={'small'} style={{marginTop: '6px'}}/> : ""
            )
            builder.setStatus((_.get(message, "hasCompanyReplied") || _.get(message, "isDispatchedSuccessfully")) ?
                <img src={CheckMarkIcon} alt="actioned"/> : ""
            )

            rows.push(builder.messages)
        })
    }

    const columns = [
        {
            id: 'text',
            label: 'Messages',
            width: 320
        },
        {
            id: 'date',
            label: 'Date',
            width: 150
        },
        {
            id: 'isLocked',
            label: '',
            width: 50
        },
        {
            id: 'status',
            label: '',
            width: 50
        },
    ];

    return {rows, columns, loading, totalCount, isEscalations}
}