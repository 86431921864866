

/**
 * @author Chris Okebata
 */

const styles = {

  author: {
    borderRight: '1px solid #CED2D0',
    width: '50%',
    height: '',
    textAlign: 'left',
    paddingRight: '15px'
  },
  dateWithAuthor: {
    width: '50%',
    height: '',
    marginLeft: '15px'
  },
  dateWithoutAuthor: {
    width: '50%',
    height: '',
    textAlign: 'left',
    paddingRight: '15px'
  },

  buttonDefault: {
    border: '2px solid #dee2e0',
    background: '#fff',
    color: '#5a5f5e',
    borderRadius: '5px',
    minHeight: '75px',
    padding: '20px 0',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },

  buttonActive: {
    border: '2px solid #008751',
    background: '#7fbf7a',
    color: '#fff',
    borderRadius: '5px',
    minHeight: '75px',
    padding: '20px 0',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },

  checkboxDefault: {
    border: '2px solid #008751',
    width: '28px',
    height: '28px',
    borderRadius: '100%',
    margin: '0 20px',
    fontSize: '18px',
    textAlign: 'center',
  },

  checkboxActive: {
    border: '2px solid #008751',
    background: '#008751',
    width: '28px',
    height: '28px',
    borderRadius: '100%',
    margin: '0 20px',
    fontSize: '18px',
    textAlign: 'center',
  },

  buttonSubmitShow: {
    width: '150px',
    height: '35px',
    paddingRight: '20px',
    marginLeft: 'auto',
  },

  buttonSubmitHide: {
    display: 'none',
  },

  responseText: {
    marginRight: '20px',
    width: '100%',
    minHeight: '35px',
    display: 'flex',
    alignItems: 'center',
  },

  editTextContainer: {
    marginRight: '20px',
    width: 'calc(100% - 150px)',
    minHeight: '35px',
  },

  editTextWrapper: {
    border: '2px solid #dee2e0',
    background: '#fff',
    color: '#5a5f5e',
    borderRadius: '5px',
    minHeight: '75px',
    padding: '20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  progressContainer: {
    width: '35px',
    height: '35px',
    zIndex: '10',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px'
  },

  radioButtonContainer: {
    height: '35px',
    display: 'flex',
    alignItems: 'center'
  },

};


export { styles }