import React from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import { ProfilePicture } from "components/common";
import ImageEditor from './../UserProfile/ImageEditor'

import Logo from 'components/images/icon_business.svg'
import LinkIcon from 'components/images/icon_link.svg'
import LocationIcon from 'components/images/icon_location.svg'
import TwitterIcon from 'components/images/icon_twitter.svg'
import LinkedinIcon from 'components/images/icon_linkedin.svg'
import FacebookIcon from 'components/images/icon_facebook.svg'
import InstagramIcon from 'components/images/icon_instagram.svg'




const style = { border: "none" };

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/01/2019 : 4:43 PM
 */

class CompanyDetails extends React.Component {
    state = {
        showPictureUpload: false,
        showCoverUpload: false
    };

    openPictureUpload = (event) => {
        event.preventDefault();
        this.setState({
            showPictureUpload: true
        });
    };

    openCoverUpload = (event) => {
        event.preventDefault();
        this.setState({
            showCoverUpload: true
        });
    };

    closePictureUpload = () => {
        this.setState({ showPictureUpload: false });
        this.setState({ showCoverUpload: false });
    };

    render() {
        const props = this.props;
        const imageCropRatio = 151/40;

        const { name, overview, location, timezone, website, twitter, linkedin, facebook, instagram } = props.company;

        let vertical;
        if ( props.company._embedded && props.company._embedded.vertical) {
             vertical = props.company._embedded.vertical.name;
        }
        return (
            <div>
                <ImageEditor
                    headerText="Upload picture"
                    buttonText="Save"
                    onHide={this.closePictureUpload}
                    show={this.state.showPictureUpload}
                    dispatch={this.props.dispatch}
                    userId={props.id}
                    type="organizations"
                    cropRatio={imageCropRatio}
                />

                <ImageEditor
                    headerText="Upload cover image"
                    buttonText="Save"
                    onHide={this.closePictureUpload}
                    show={this.state.showCoverUpload}
                    dispatch={this.props.dispatch}
                    userId={props.id}
                    type="employments"
                    cropRatio={imageCropRatio}
                />
                <Row>
                    <Col md={3} className="company-profile-picture">
                        <div className="profile-picture-container" onClick={(e) => this.openPictureUpload(e)}>
                            <ProfilePicture defaultPhoto={Logo} picture={props.company.logo}
                                            userId={props.company.id} type="organizations"
                                            imgAlt={`${props.company.name}'s Logo`} {...this.props} />
                        </div>

                    </Col>
                    <Col md={6} className="tms-company-profile-overview margin-top-0">
                        <h2 className="color-grey-dark font-24-bold">{props.company.name}
                        </h2>
                        <Button className='className="btn btn-default font-14 color-grey-dark' onClick={props.onEditClick}>Edit profile</Button>

                    </Col>
                    <Col md={3} className="company-cover-picture">
                        <Button className='className="btn btn-default font-14 color-grey-dark cover-picture-container' onClick={(e) => this.openCoverUpload(e)}>Edit cover image</Button>
                    </Col>
                </Row>

                <Row className="company-details-row">
                    <Col md={7} className="padding-left-0 padding-right-40 border-right-grey-mid">
                        <div className="margin-bottom-25">
                            <h4 className="color-grey-mid">Overview</h4>
                            <p className="font-15 color-grey-dark">
                                {overview ? overview : "No overview"}
                            </p>
                        </div>

                        <div className="margin-bottom-25">
                            <h4 className="color-grey-mid">Timezone</h4>
                            <p className="font-15 color-grey-dark ">
                                {timezone}
                            </p>
                        </div>

                    </Col>

                    <Col md={5} className=" tms-company-details-right">
                        <ul className="list-group">
                            <li className="list-group-item padding-10-0 " style={style}>
                                <span className="font-15 color-grey-dark ">
                                    <img src={LocationIcon} alt=""/>{location}
                                </span>
                            </li>
                            <li className="list-group-item padding-10-0 " style={style}>
                                <span className="font-15 color-grey-dark">
                                    <img src={Logo} alt=""/>{vertical}
                                </span>
                            </li>
                            <li className="list-group-item padding-10-0 " style={style}>
                                <span className="font-15 ">
                                    <img src={LinkIcon} alt=""/>
                                    <a href={`https://${website}`} target="_blank" rel="noopener noreferrer" className="color-green">{website}</a>
                                </span>
                            </li>
                        </ul>
                        <div className="tms-company-details-social">
                            <a href={twitter !== undefined ? `https://twitter.com/${twitter}`: ''} target='_blank' rel="noopener noreferrer">
                                <img src={TwitterIcon} title="Twitter" alt={`Link to ${name}'s twitter page.`}/>
                            </a>
                            <a href={linkedin !== undefined ? `https://linkedin.com/${linkedin}` : ''} target='_blank' rel="noopener noreferrer">
                                <img src={LinkedinIcon} title="Linkedin" alt={`Link to ${name}'s LinkedIn profile.`}/>
                            </a>
                            <a href={facebook !== undefined ? `https://facebook.com/${facebook}` : ''} target='_blank' rel="noopener noreferrer">
                                <img src={FacebookIcon} title="Facebook" alt={`Link to ${name}'s Facebook page.`}/>
                            </a>
                            <a href={instagram !== undefined ? `https://instagram.com/${instagram}`: ''} target='_blank' rel="noopener noreferrer">
                                <img src={InstagramIcon} title="Instagram" alt={`Link to ${name}'s Instagram page`}/>
                            </a>

                        </div>
                    </Col>

                </Row>

            </div>
        );
    }
}

export default CompanyDetails