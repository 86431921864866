import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {auth0Actions, utilityActions} from "_actions";
import {Loading, SnackBar} from "../common";
import {isValidEmail, required, fieldsMatch,isValidNumberForCountries, isVerticalSelected} from "_helpers";
import spinner from '../images/spinner.gif';
import { CopyrightFooter, DropDownSelect } from "components/common";
import chatdeskLogo from "components/images/chatdesk-logo-dark.svg";
import {regFormContainer} from 'components/RegisterPage'
import {alertMessages} from "_constants";
import ReactGA from 'react-ga';
import {GOOGLE_ANALYTICS_TRACKING_ID} from "_constants";



const validate = (values) => {
  const errors = {};
  const {phoneNumber, password, confirmPassword, username} = values;
  const validCountries = ['US', 'AU', 'GB', 'CA'];
  if (!fieldsMatch(password, confirmPassword)){
    errors.confirmPassword = "passwords don't match"
  }

  if (!isValidNumberForCountries(validCountries, phoneNumber)){
    errors.phoneNumber = `Please enter a US, AU, UK or CA phone number`
  }

  if (!isValidEmail(username)){
    errors.username = `invalid email`
  }
  return errors
};

const InputField = ({ input, label, type, meta: {touched, error, warning} }) => {
  return (
    <div>
      <div style={regFormContainer.error} className="font-12 color-danger">
        {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
      </div>
      <input {...input} type={type}
             className="input-field"
             required/>
      <label htmlFor={label}><span>{label}</span></label>
    </div>
  );
};

const Checkbox = ({ input, meta, label, type, checked }) => {
  return (
    <div>
      <input type={type}
             className="tms-checkbox"
             {...input}
             checked={checked}
             autoComplete="off"/>
      <span className="tms-check-mark">
            </span>
    </div>
  );
};



function Auth0ProfileSetup (props) {
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname);

    dispatch(utilityActions.getVerticals());
  }, []);

  const utility = useSelector((state) => state.utility);
  const auth0 = useSelector((state) => state.auth0);
  let {data: {verticalsList: verticals}, loading} = utility;
  let profileLoading = auth0.profileLoading;
  const { handleSubmit } = props;

  if (verticals) {
    verticals = verticals.map((vertical) => {
      return { 'id': vertical.id, 'name': vertical.name }
    })
  }

  const { isLoading, loginWithRedirect, isAuthenticated, getIdTokenClaims } = useAuth0();

  if(isLoading){
    return <Loading/>
  }

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }

  const getTokenClaims = async () => {
    return await getIdTokenClaims();
  };

  const onSubmit = (payload) => {
    const {vertical} = payload;
    if(!isVerticalSelected(vertical)){
      SnackBar.showMessage(alertMessages.NO_VERTICAL_ERROR);
      return
    }
    if (!isLoading && isAuthenticated) {
      getTokenClaims()
        .then(function (claims) {
          const idToken = claims.__raw;
          const data = {...payload, frontEndBaseURL: window.location.origin.toString(), idToken: idToken};
          dispatch(auth0Actions.createProfile(data));
        })
        .catch(function (error) {
          console.log(error)
        });
    }
  };

  return (
    <div className="tms-sign-up-body">
      <div className="margin-top-50 logo-pos-sign-up">
        <img src={chatdeskLogo} alt="Chatdesk logo" className="logo"/>
      </div>
      <div className="tms-signup-wrapper">
        <div className="tms-signup-container">
          <div className="tms-signup-title-container">
            <span className="font-22-heavy color-grey-dark">Create your free account</span>
          </div>
          <form name="form" onSubmit={handleSubmit(onSubmit.bind(this))}>
            <div className="tms-signup-form-content">
              <div className="tms-signup-form-section-1" style={{marginBottom: '35px'}}>
                <div style={regFormContainer.inputField}>
                  <Field
                    name="firstName"
                    label="First Name"
                    component={InputField}
                    validate={required}
                    type="text"
                  />
                </div>
                <div style={regFormContainer.inputField}>
                  <Field
                    name="lastName"
                    label="Last Name"
                    component={InputField}
                    validate={required}
                    type="text"
                  />
                </div>
                <div className="tms-phoneLabel" style={regFormContainer.inputField}>
                  <Field
                    name="phoneNumber"
                    label="Phone Number"
                    component={InputField}
                    validate={required}
                    type="text"
                  />
                </div>
              </div>
              <div className="tms-signup-form-section-2">
                <div style={regFormContainer.inputField}>
                  <Field
                    name="company"
                    label="Company Name"
                    component={InputField}
                    validate={required}
                    type="text"
                  />
                </div>
                <div style={regFormContainer.inputField}>
                  <div style={{...regFormContainer.width83, margin: "17px auto 0 auto"}}>
                    <Field
                      style={{background: "#fff", cursor: "pointer"}}
                      className="select-field"
                      name="vertical"
                      label="Industry"
                      component={DropDownSelect}
                      type="text"
                      options={verticals}
                      placeHolder={'Select Industry'}
                    />
                  </div>
                </div>
                <div style={regFormContainer.checkbox} className="tms-signup-subscribe">
                  <div style={{...regFormContainer.width83, display: "flex"}}>
                    <div style={regFormContainer.checkboxSubscribe}>
                      <Field
                        name="subscribeToCompanyInfo"
                        component={Checkbox}
                        type="checkbox"
                      />
                    </div>

                    <div style={regFormContainer.subscribeText}
                         className="font-13-bold color-grey-dark">
                      Subscribe to receive industry-leading content, product updates, and company
                      news.
                    </div>
                  </div>
                </div>
                <div style={regFormContainer.checkbox} className="tms-signup-privacy">
                  <div style={{...regFormContainer.width83, display: "flex"}}>
                    <div style={regFormContainer.checkboxAccept}>
                      <Field
                        name="acceptTerms"
                        component={Checkbox}
                        type="checkbox"
                        checked="checked"
                      />
                    </div>
                    <div style={regFormContainer.acceptText}
                         className="font-13-bold color-grey-dark">
                      By signing up, I agree to Chatdesk's <a
                      href="https://chatdesk.com/terms.html"
                      rel="noopener noreferrer" target="_blank"
                      style={{color: "#13a0ba"}}>
                      Terms and Conditions
                    </a> & <a
                      href="https://chatdesk.com/privacy.html"
                      rel="noopener noreferrer" target="_blank"
                      style={{color: "#13a0ba"}}>Privacy Policy.</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tms-signup-button-wrapper">
              <div className="tms-signup-button-container">
                <div className="tms-signup-link-login">
                                        <span className="">
                                            <Link to="/logout" className="btn btn-link color-grey-dark"
                                                  style={{lineHeight: "0", padding: "0"}}>Login</Link>
                                        </span>
                </div>
                <div className="tms-signup-button-holder">
                  <button className="btn btn-block btn-success font-15" type="submit" disabled={loading || profileLoading}>
                    Signup {(loading || profileLoading) && <img alt='loading' src={spinner}/>}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <CopyrightFooter/>
    </div>
  );
}

Auth0ProfileSetup = reduxForm({
  form: 'RegistrationForm',
  initialValues: { isCompanyAdmin: true, terms: true },
  validate
})(Auth0ProfileSetup);

export default Auth0ProfileSetup;
