import React from "react";
import {Box} from "@mui/material";
import lockIcon from "../../../images/icon_lock_green.svg";
import userIcon from "../../../images/icon_user_green.svg";
import companyIcon from "../../../images/icon_company_green.svg";
import {userRoles} from "_constants";
import calendarIcon from "../../../images/icon_date_range_green.svg";
import { Link } from 'react-router-dom';


export default function Profile(){
    const allRoles = [
        userRoles.COMPANY_ADMIN_ROLE,
        userRoles.CHATDESK_ADMIN_ROLE,
        userRoles.CHATDESK_AGENT_ROLE,
        userRoles.COMPANY_USER_ROLE,
        userRoles.CHATDESK_REVIEWER_ROLE
    ];

    const profileContents = (organizationId, userId) => ([
        {
            name: "Change Password",
            icon: `${lockIcon}`,
            link: `/update-password`,
            permission: allRoles
        },

        {
            name: "My Profile",
            icon: `${userIcon}`,
            link: `/users/${userId}/profile`,
            permission: allRoles
        },

        {
            name: "Company Profile",
            icon: `${companyIcon}`,
            link: `/company/${organizationId}/profile`,
            permission: [userRoles.COMPANY_ADMIN_ROLE]
        },

        {
            name: "Team Schedule",
            icon: `${calendarIcon}`,
            link: `/company/${organizationId}/schedules`,
            permission: [userRoles.COMPANY_ADMIN_ROLE]
        }

    ]);

    const currentUser = JSON.parse(localStorage.getItem('user'));

    const { organization_id, id, roles } = currentUser;
    const currentUserRole = roles[0];

    return(
        <Box sx={styles.rootWrapper}>
            <Box sx={styles.container}>
                <Box component={'h1'} sx={styles.headingText}>Profile</Box>

                <Box sx={styles.cardsContainer}>
                    {profileContents(organization_id, id).map((profile, it) => {
                        if(profile.permission.includes(currentUserRole)){
                            return(
                                <Link to={profile.link} key={it} style={{textDecoration: 'none'}}>
                                    <Box sx={styles.card}>
                                        <Box sx={styles.iconContainer}>
                                            <img src={profile.icon} alt={profile.name} style={{width: '70px', height: '70px'}} />
                                        </Box>

                                        <Box sx={styles.cardLabel}>
                                            <Box sx={styles.labelText}>{profile.name}</Box>
                                        </Box>
                                    </Box>
                                </Link>
                            )
                        } else{
                            return null
                        }
                    })}
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    rootWrapper: {
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
    },
    headingText: {
        m: 0,
        fontFamily: 'LarsseitMedium',
        fontSize: 24,
        lineHeight: 1.2,
        letterSpacing: '-0.02em',
        color: '#0C2728'
    },
    cardsContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: "5px",
        border: "solid 1px rgba(224, 224, 224, 1)",
        padding: 5,
        width: 'fit-content',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        marginTop: '30px'
    },
    card: {
        border: '1px solid #a4a9a7',
        height: '180px',
        width: {xs: '200px', md: '180px'},
        minWidth: '180px',
        borderRadius: '4px',
        '&:hover': {
            border: '1px solid #a4a9a7',
            boxShadow: 'rgba(0, 1, 0, 0.1) -0px 1px 2px 1px',
        },
    },
    iconContainer: {
        height: '130px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardLabel: {
        borderTop: '1px solid #c4c9c7',
        margin: '0 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    labelText: {
        fontSize: 14,
        fontFamily: "LarsseitMedium",
        color: "#495D5E",
        lineHeight: '45px'
    }
}