import {alertMessages, escalationConstants, responseOptionConstants} from '_constants';
import { responseOptionService } from '_services';
import {SnackBar} from "../components/common";
import {Auth} from "../components/Authorization";
import _ from "lodash";

function getActionData(actionType, payload = {}) {
  return {type: actionType, payload};
}

function getAll(params) {
  function request() {
    return { type: responseOptionConstants.LIST_RESPONSE_OPTIONS_REQUEST };
  }

  function success(responseOptions) {
    return {
      type: responseOptionConstants.LIST_RESPONSE_OPTIONS_SUCCESS,
      payload: responseOptions,
    };
  }

  function failure(error) {
    return { type: responseOptionConstants.LIST_RESPONSE_OPTIONS_FAILURE, payload: error };
  }
  return (dispatch) => {
    dispatch(request());

    responseOptionService.getAll(params)
      .then(
        responseOptions => dispatch(success(responseOptions)),
        (error) => {
          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        },
      );
  };
}


/**
 * Get Conversation Dispatches the 'getConversation' actions.
 * @param messageId
 * @param expertId
 * @param params
 * @returns {function(*)}
 */
function getExpertMessageResponses(messageId, expertId, params) {
  function request() {
    return { type: responseOptionConstants.LIST_RESPONSE_OPTIONS_REQUEST };
  }

  function success(responses) {
    return { type: responseOptionConstants.LIST_RESPONSE_OPTIONS_SUCCESS, payload: responses };
  }

  function failure(error) {
    return { type: responseOptionConstants.LIST_RESPONSE_OPTIONS_FAILURE, payload: error };
  }

  return (dispatch) => {
    dispatch(request(messageId));

    responseOptionService.getExpertMessageResponses(messageId, expertId, params)
      .then(
        responses => dispatch(success(responses)),
        (error) => {
          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        },
      );
  };
}

const addResponseOption = (messageId, responseMessageId, text, userId) => {
  const request = () => ({
    type: responseOptionConstants.ADD_RESPONSE_OPTION_REQUEST, responseMessageId
  });

  const success = (response) => ({
    type: responseOptionConstants.ADD_RESPONSE_OPTION_SUCCESS,
    payload: {
      ...response
    }
  });

  const failure = (error) => ({
    type: responseOptionConstants.ADD_RESPONSE_OPTION_FAILURE, error,
    payload: {
      error
    }
  });


  return dispatch => {
    dispatch(request());

    responseOptionService.addResponseOption(messageId, responseMessageId, text, userId)
      .then(response => {
        const{status, data} = response;

        if(status === 201) {
          dispatch(success(data));
          SnackBar.showMessage(alertMessages.ADD_RESPONSE_SUCCESS);
        }
        else if(status === 200) {
          dispatch(success(data));
          SnackBar.showMessage(alertMessages.MAX_ADD_RESPONSE_LIMIT);
        }

      }).catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};

function getUserAddedResponses(messageId, expertId) {
  return (dispatch) => {
    dispatch(getActionData(responseOptionConstants.GET_USER_ADDED_RESPONSE_OPTIONS_REQUEST));

    responseOptionService.getUserAddedResponses(messageId, expertId)
        .then((response) => {
          dispatch(getActionData(responseOptionConstants.GET_USER_ADDED_RESPONSE_OPTIONS_SUCCESS, response.data));
        }).catch((error) => {
          dispatch(getActionData(responseOptionConstants.GET_USER_ADDED_RESPONSE_OPTIONS_FAILURE, error));
          SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        });
  };
}

/**
 * Clear the added response option from the redux state
 * @returns {Function}
 */
const clearAddedResponseOption = () => {

  const clear = () => ({
    type: responseOptionConstants.CLEAR_ADDED_RESPONSE_OPTION,
    payload: []
  });


  return dispatch => {
    dispatch(clear());
  }
};

export const responseOptionActions = {
  getAll,
  getExpertMessageResponses,
  addResponseOption,
  getUserAddedResponses,
  clearAddedResponseOption
};
