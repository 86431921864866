import {SERVER_URL} from 'config';
import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';

function verifyApiKey(params) {
    return fetch(`${SERVER_URL}/api/gladly/apiKey?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

export const gladlyAuthService = {
    verifyApiKey
};
