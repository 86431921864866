import {Fragment} from "react";
import BasicSelect from "./BasicSelect";

export default function RegionSelect({name, label, placeholder, handleFormSelectChange, defaultValue, queryParam, input, meta}) {
    //@TODO: Create an endpoint to fetch these options (ISO 3166-1 alpha-2)
    const options = [
        {id: 'US', name: 'United States of America, (EN)'},
    ];

    return(
        <Fragment>
            <BasicSelect
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={defaultValue}
                options={options}
                handleFormSelectChange={handleFormSelectChange}
                input={input}
                meta={meta} />
        </Fragment>
    )
}