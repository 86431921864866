import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import chatdeskLogo from 'components/images/logos/chatdesk_wordmark_dark_teal.svg'
import { authActions } from '_actions';
import { getLogoUrl, history } from '_helpers';
import { userRoles } from '_constants';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import ProfileContextMenu from 'components/common/NavContextMenu/ProfileContextMenu'
import userAvatar from 'components/images/user_avatar_.svg'
import _ from "lodash";
import ProactiveNavMenu from "../v2/views/Proactive/Utilities/ProactiveNavMenu";

let tmsNavBarTop = {
    width: '100%',
    height: '200px',
    background: '#fff',
    border: 'solid 1px #dee2e0',
    position: 'absolute'
};

class NavBar extends React.Component {

    logout = (event) => {
        event.preventDefault();
        this.props.dispatch(authActions.logout())
    };

    currentUserName = () => {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        let name = '';

        if(currentUser){
            if (currentUser.firstName || currentUser.lastName) {
                if (currentUser.firstName) {
                    name += currentUser.firstName + ' '
                }
                if (currentUser.lastName) {
                    name += currentUser.lastName
                }
            } else {
                name = currentUser.username
            }
        }

        return name
    };

    addDefaultSrc = (ev) => {
        ev.target.src = chatdeskLogo
    };

    render() {
        const { currentUser: { loggedIn, user } } = this.props;
        const { pathname } = history.location;

        let profilePicture = userAvatar;
        if (user && user.profilePicture) {
            profilePicture = getLogoUrl(user.profilePicture);
        }

        let hasMoreThanOneMsgDataSource;

        const loggedInUser = JSON.parse(localStorage.getItem('user'));
        const organizationExists = loggedInUser && loggedInUser._embedded && loggedInUser._embedded.organization;

        if (!organizationExists) {
            hasMoreThanOneMsgDataSource = false
        } else {
            hasMoreThanOneMsgDataSource = organizationExists.hasMsgDataSource
        }

        const currentUserRole = loggedInUser && loggedInUser.roles && loggedInUser.roles[ 0 ];
        const isCompanyAdmin = loggedInUser && currentUserRole === userRoles.COMPANY_ADMIN_ROLE;
        const isChatdeskAgent = loggedInUser && currentUserRole === userRoles.CHATDESK_AGENT_ROLE;
        const isChatdeskReviewer = loggedInUser && currentUserRole === userRoles.CHATDESK_REVIEWER_ROLE;
        const isChatdeskAdmin = loggedInUser && currentUserRole === userRoles.CHATDESK_ADMIN_ROLE;
        const isBillingAdmin = loggedInUser && currentUserRole === userRoles.BILLING_ADMIN_ROLE;

        let showNavBar = ( loggedIn && pathname !== '/confirm_registration' );
        let companyLogo = chatdeskLogo;
        let navbar, isVoteV2 = ['/vote', '/v2/vote'].includes(pathname);
        let isConnectChannelV2 = pathname === '/company/connectchannels';
        let isChannelsViewV2 = pathname === '/company/channels';
        let isUsersViewV2 = pathname === '/company/users';
        let isCompanyTeamViewV2 = pathname === '/v2/company/team';
        let isExpertDetailsViewV2 = pathname.startsWith('/v2/company/experts/');
        let isMetricsDashboard = pathname === '/metrics' || pathname === '/metrics/schedule-details';
        let proactiveUrls = ['/company/campaign', '/company/template', '/company/create-template', '/admin/create-campaign', '/company/edit-template', '/proactive-metrics-details']
        let adminProactiveUrls = ['/admin/campaign']
        let isMetricsDetails = pathname === '/metrics-details' || pathname === '/metrics-details';
        let isProactiveView = proactiveUrls.includes(pathname) || pathname.includes('/company/edit-template');
        let isAdminProactiveView = adminProactiveUrls.includes(pathname);
        const logoUrl = _.get(loggedInUser, '_embedded.organization.logo');
        let showAdminCampaign = isChatdeskAdmin && isAdminProactiveView;
        let showCompanyMenu = !isChatdeskAgent && !isChatdeskReviewer && !isChatdeskAdmin && !isBillingAdmin && hasMoreThanOneMsgDataSource;
        let isCompanyInboxViewV2 = pathname.startsWith('/inbox');
        let isProfilePage = pathname === ('/profile') || pathname === ('/update-password') || pathname.startsWith('/users/') || pathname.startsWith('/company/')

        if(['/vote'].includes(pathname)) {
            return <Fragment/>
        }

        if(logoUrl && isCompanyAdmin){
            companyLogo = getLogoUrl(logoUrl);
        }

        let onboardingLinkPattern =  new RegExp('/signup/*', 'g');

        if (onboardingLinkPattern.test(pathname || '')) {
            showNavBar = false;
        }

        if(isVoteV2){
            navbar = <div className="contextContainer right-rel-38">
                        <Link to="/dashboard" className="font-16 color-green" style={{
                            height: '22px',
                            position: 'relative',
                            top: '18px'
                        }}>
                            Exit
                        </Link>
                    </div>
        }else if(pathname === '/vote' || pathname === '/review'){
            navbar = <div className="contextContainer right-rel-38" style={{ height: '78px' }}>
                        <Link to="/dashboard" className="font-16 color-green" style={{
                            height: '22px',
                            position: 'relative',
                            top: '25px'
                        }}>
                            Exit
                        </Link>
                    </div>
        }else{
            navbar = <div className="top-rel-15 right-rel-38">
                        <ProfileContextMenu
                          userName={this.currentUserName()}
                          profilePhoto={profilePicture}
                          logoutHandler={this.logout}
                          userRole={currentUserRole}
                        />
                    </div>
        }

        return (
            <div className="no-select">
                {showNavBar && (
                    <div style={
                        !isChatdeskAgent
                        && !isChatdeskReviewer
                        && !isChatdeskAdmin
                        && !isBillingAdmin
                        && !isConnectChannelV2
                        && !isChannelsViewV2
                        && !isUsersViewV2
                        && !isMetricsDashboard
                        && !isProactiveView
                        && !isCompanyTeamViewV2
                        && !isExpertDetailsViewV2
                        && !isMetricsDetails
                        && !isCompanyInboxViewV2
                        && !isProfilePage
                        ? tmsNavBarTop
                        : ( isVoteV2 ? { ...tmsNavBarTop, height: '60px'} : { ...tmsNavBarTop, height: '80px'})
                    }>
                        <div className="tms-logo-container" style={ isVoteV2 ? { height: '60px' } : {} }>
                            <div className="tms-logo-frame" style={ isVoteV2 ? { lineHeight: '40px' } : {} }>
                                <span className="tms-helper" />
                                <a href={loggedIn ? '/dashboard' : '/'}>
                                    <img src={companyLogo}
                                         onError={this.addDefaultSrc}
                                         alt="chatdesk logo" className="tms-logoImg"
                                         style={ isVoteV2 ? { maxHeight: '40px' } : {} }/>
                                </a>
                            </div>
                        </div>

                        <div className="nav-menu">
                            {showCompanyMenu &&
                            <div className="trds-nav-links-container" id="trds-nav-links-container">
                                <ul>
                                    <li>
                                        <a href="/metrics"
                                           className="font-16-larsseit-medium color-green">Metrics</a>
                                        <div className="trds-nav-inactive"/>
                                    </li>
                                    <li>
                                        <a href="/v2/company/team"
                                           className="font-16-larsseit-medium color-green">Team</a>
                                        <div className="trds-nav-inactive"/>
                                    </li>
                                    <li>
                                        <div style={{position: 'relative', top: '-1.5px'}}>
                                            <ProactiveNavMenu/>
                                        </div>
                                    </li>
                                    <li>
                                        <a href="/inbox"
                                           className="font-16-larsseit-medium color-green">Inbox</a>
                                        <div className="trds-nav-inactive" />
                                    </li>
                                </ul>
                            </div>
                            }

                            {showAdminCampaign &&
                            <div className="trds-nav-links-container" id="trds-nav-links-container">
                                <ul>
                                    <li>
                                        <a href="/admin/create-campaign"
                                           className="font-16-larsseit-medium color-green">Create campaign</a>
                                        <div className="trds-nav-inactive"/>
                                    </li>
                                </ul>
                            </div>
                            }

                            {isChatdeskAgent && !isVoteV2 &&
                            <div className="trds-nav-links-container" id="trds-nav-links-container">
                                <ul>
                                    <li>
                                        <Link to="/dashboard"
                                        className="font-16-larsseit-medium color-green">Home</Link>
                                        <div className="trds-nav-inactive" />
                                    </li>
                                    <li>
                                        <Link to="/earnings-summary"
                                              className="font-16-larsseit-medium color-green">Earnings Summary</Link>
                                        <div className="trds-nav-inactive" />
                                    </li>
                                    <li>
                                        <Link to="/earnings-history"
                                              className="font-16-larsseit-medium color-green">Earnings History</Link>
                                        <div className="trds-nav-inactive" />
                                    </li>
                                    <li>
                                        <Link to={`/expert-payouts`}
                                              className="font-16-larsseit-medium color-green">Payouts</Link>
                                        <div className="trds-nav-inactive" />
                                    </li>
                                </ul>
                            </div>
                            }
                            { navbar }
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth } = state;
    return {
        currentUser: auth,
    };
}

export default withRouter(connect(mapStateToProps)(NavBar));



