import {oauthConstants} from "../_constants/oauth.constants";
import {msgDataSourceConstants} from "../_constants";

const INITIAL_STATE = {
    data: [],
    error: '',
    loading: false,
    channel: 'instagram',
    list: []
};

export function instagramReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case oauthConstants.INSTAGRAM_PAGES_REQUEST:
            return {...state, loading: true};

        case oauthConstants.INSTAGRAM_PAGES_SUCCESS:
            return {...state, data: action.payload, loading: false};

        case oauthConstants.INSTAGRAM_PAGES_FAILURE:
            return {...state, error: action.error, loading: false};

        case oauthConstants.INSTAGRAM_PAGE_SUBMISSION_REQUEST:
            return {...state, loading: true, error: ''};

        case oauthConstants.INSTAGRAM_PAGE_SUBMISSION_SUCCESS:
            return {...state, data: action.payload, loading: false};

        case oauthConstants.INSTAGRAM_PAGE_SUBMISSION_FAILURE:
            return {...state, error: action.error, loading: false};

        case msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false
            };

        case msgDataSourceConstants.ALL_DATA_SOURCE_ERROR:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}