import {SERVER_URL, CLIENT_URL} from 'config';
import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';

function getOauthUrl() {
    return fetch(`${SERVER_URL}/api/stripe/oauthUrl?redirectUri=${CLIENT_URL}/stripe/connect`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function processOauthCode(params) {
    return fetch(`${SERVER_URL}/api/stripe/oauthCode?${objectToQueryString(params)}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function stripeAccountStatus(params) {
    return fetch(`${SERVER_URL}/api/users/${params}/payout/accounts`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function stripeOnboardingLink() {
    return fetch(`${SERVER_URL}/api/users/stripe/connect/initiateOauth?redirectUri=${window.location.origin}/expert-payouts`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function stripeTokenExchange(authCode) {
    return fetch(`${SERVER_URL}/api/users/stripe/connect/oauthToken?authCode=${authCode}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

export const stripeOauthService  = {
    getOauthUrl,
    processOauthCode,
    stripeAccountStatus,
    stripeOnboardingLink,
    stripeTokenExchange
};