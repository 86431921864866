import _ from "lodash";
import {accessibilityMode, fragmentId} from "./organizationSettingsConstants";
import CompanyInfo from "../Sections/CompanyInfo";
import QueueManagement from "../Sections/QueueManagement";
import Offboard from "../Sections/Offboard";
import ExpertManagement from "../Sections/ExpertManagement";
import Notifications from "../Sections/Notifications";
import DataSources from "../Sections/DataSources";
import MessageResponses from "../Sections/MessageResponses";
import {userRoles as roles} from "_constants";
import {getCapitalizedWords} from "_helpers";
import companySettingsLabels from "./companySettingsLabels";

export function getUrlSlug() {
    const url = new URL(document.location);
    const hash = _.get(url, 'hash') || '';
    const slug = hash && hash.split("#")[1];
    return slug && slug.split("/")[0];
}

export function navigate(fragmentId) {
    window.history.pushState(null, '', `#${fragmentId}`);
}

export function getSection(slug) {
    switch (slug) {
        case fragmentId.INFO:
            return CompanyInfo;
        case fragmentId.QUEUE:
            return QueueManagement;
        case fragmentId.EXPERT:
            return ExpertManagement;
        case fragmentId.NOTIFICATIONS:
            return Notifications;
        case fragmentId.DATA_SOURCES:
            return DataSources;
        case fragmentId.RESPONSES:
            return MessageResponses;
        case fragmentId.OFF_BOARD:
            return Offboard;
        default:
            navigate(fragmentId.INFO);
            return CompanyInfo;
    }
}

export function getAccessibilityMode() {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    const currentUserRole = loggedInUser && loggedInUser.roles && loggedInUser.roles[0];
    switch (currentUserRole) {
        case roles.BILLING_ADMIN_ROLE:
            return accessibilityMode.EDIT;
        case roles.CHATDESK_ADMIN_ROLE:
            return accessibilityMode.READ_ONLY;
        default:
            return accessibilityMode.READ_ONLY;
    }
}

/**
 * @param name {string}
 * @returns {string}
 */
export function parseProductName(name) {
    if (!name) return '';
    return getCapitalizedWords(name.replace(/_/g, ' '));
}

/**
 * @param user {object}
 * @returns {string}
 */
export function useUserFullName(user) {
    if(_.isEmpty(user)) return 'User';
    return getCapitalizedWords(`${_.get(user, 'firstName')} ${_.get(user, 'lastName')}`);
}

/**
 * @param organizationCSM {object}
 * @returns {{organizationId, customerSuccessManager: (*|undefined)}|null}
 */
export function buildUpdateCSMPayload(organizationCSM) {
    if(_.isEmpty(organizationCSM)) return null;
    const userId = _.get(organizationCSM, 'id');
    return {
        customerSuccessManager: userId
    }
}


/**
 * @param visibility {object}
 * @returns {{visibility}|null}
 */
export function buildUpdateVisibilityPayload(visibility) {
    if(_.isEmpty(visibility)) return null;
    const visibilityStatus = _.get(visibility, 'id');
    return {
        visibility: visibilityStatus
    }
}

/**
 * @param value {boolean}
 * @returns {{hideInMobile}|null}
 */
export function buildUpdateMobileHidePayload(value) {
    if(_.isNil(value)) return null;
    return {
        hideInMobile: value
    }
}

/**
 * @param value {boolean}
 * @returns {{hideInMobile}|null}
 */
export function buildUpdateWeeklyMetricsEmailPayload(value) {
    if(_.isNil(value)) return null;
    return {
        isWeeklyMetricsEmailEnabled: value
    }
}

/**
 * @param value {boolean}
 * @returns {{hideInMobile}|null}
 */
export function buildPresaleRemainderPayload(value) {
    if(_.isNil(value)) return null;
    return {
        presaleRemainder: value
    }
}

/**
 * @param value {boolean}
 * @returns {{hideInMobile}|null}
 */
export function buildUpdateShopifyRevenueTrackingPayload(value) {
    if(_.isNil(value)) return null;
    return {
        isShopifyRevenueTrackingOn: value
    }
}

/**
 * @param value {boolean}
 * @returns {{hideInMobile}|null}
 */
export function buildUpdateGorgiasRevenueTrackingPayload(value) {
    if(_.isNil(value)) return null;
    return {
        isGorgiasRevenueTrackingOn: value
    }
}

/**
 * @param value {boolean}
 * @returns {{hideInMobile}|null}
 */
export function buildUpdateEscalationViaEmailPayload(value) {
    if(_.isNil(value)) return null;
    return {
        escalationsViaEmail: value
    }
}

/**
 * @param ticketBudget {number}
 * @param billingPlanId {string}
 * @returns {{ticketBudget, billingPlanId}|null}
 */
export function buildUpdateTicketBudgetPayload(ticketBudget, billingPlanId) {
    if(_.isNil(ticketBudget) || _.isNaN(ticketBudget) || !billingPlanId) return null;
    return {
        ticketBudget,
        billingPlanId,
    }
}

/**
 * @param section {string}
 * @returns {*}
 */
export function getLabel(section) {
    const sections = companySettingsLabels().sections;
    const sectionObj = _.find(sections, {name: section});
    return _.get(sectionObj, 'label');
}

/**
 * @param messageValidity {number}
 * @returns {{messageValidityDurationInDays}|null}
 */
export function buildMessageValidityPayload(messageValidity) {
    if (_.isNil(messageValidity) || _.isNaN(messageValidity)) return null;
    return {
        messageValidityDurationInDays: messageValidity
    }
}