import React, { Component, Fragment } from 'react'
import { GenericModal } from 'components/common';
import {connect} from 'react-redux'
import { Button } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";
import {Box} from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import {Switch, Typography} from "@mui/material";
import LoadingSpinner from "../v2/views/Channels/ConnectChannels/Utilities/LoadingSpinner";
import { Remarkable } from 'remarkable';
import BasicTooltip from "../v2/common/BasicTooltip";

let styles = {
    checkBox: {
        position: 'absolute',
        left: 0,
        top: 9,
    },
    checkMark: {
        top: 2,
        left:15,
    },
    instanceLabel: {
        m: 0,
        fontFamily: 'LarsseitRegular',
        fontSize: 16, lineHeight: 1.2,
        letterSpacing: '-0.02em',
        color: '#0C2728'
    },
    switch: {
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: '#368c86'
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: '#368C86'
        },
        "&:hover .MuiSwitch-switchBase": {
            color: '#368c86'
        },
    },
    tooltipTypographyContainer: {
        minWidth: '250px',
        maxWidth: '500px',
        padding: '10px'
    },
    tooltipTypography: {
        width: '100%',
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        color: '#0C2728',
    }
};
const SENSITIVE_ACTION_INFO = `Sensitive actions include performing refund on Shopify, Recharge, etc...
    Only enable when this type of actions by the Macro is acceptable`;

const ZENDESK = 'Zendesk';
const GORGIAS = 'Gorgias';
const KUSTOMER = 'Kustomer';
const HTML_DISPLAY_TYPE = [ZENDESK, GORGIAS, KUSTOMER];
const MARKDOWN_CONTENT = [KUSTOMER];
var md = new Remarkable();

class PlatformMacroModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMacros: [],
            deleteId: null,
            editId: null,
            rankValue: 0,
            displayHtml: false,
            displayAsHtml: false
        }
    }

    saveZendeskMacroData = () => {
        const { msgDataSourceId, dispatch, macrosList } = this.props;
        const { selectedMacros } = this.state;
        if (selectedMacros.length > 0) {
            const macros = macrosList.filter(item => selectedMacros.includes(item.macroId));
            dispatch(msgDataSourceActions.saveSelectedPlatformMacros({ msgDataSourceId, macros }));
        }
        this.setState({ selectedMacros: [] })
        dispatch(msgDataSourceActions.clearMacrosList())
    };

    handleSelection = (macroId) => {
        this.setState((prevState) => {
            if (prevState.selectedMacros.includes(macroId)) {
                prevState.selectedMacros = prevState.selectedMacros.filter(it => it !== macroId)
            } else {
                prevState.selectedMacros.push(macroId);
            }
            return {
                selectedMacros: prevState.selectedMacros
            }
        });
    };

    pullZendeskMacros = () => {
        const { dispatch, msgDataSourceId, nextPage, platformMacroType } = this.props;
        dispatch(msgDataSourceActions.retrievePlatformMacros({msgDataSourceId, nextPage, platformMacroType}))
    };

    editMacro = (editId, rankValue) => {
        this.setState({ editId, rankValue })
    };

    updateMacro = (macroId) => {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.updateMacro(macroId))
    };

    updateRankvalue = (e) => {
        this.setState({
            rankValue: e.target.value
        })
    };

    performRankUpdate = (macroRank) => {
        const { rankValue, editId } = this.state;
        if (macroRank !== rankValue) {
            const { dispatch, savedMacros, msgDataSourceId } = this.props;
            let currentMacro = savedMacros.find(item => item.id === editId);
            currentMacro = {...currentMacro, rank: rankValue};
            dispatch(msgDataSourceActions.saveSelectedPlatformMacros({ msgDataSourceId, macros: [ currentMacro ] }))
        }
        this.setState({ editId: null, rankValue: 0 })
    };

    confirmDelete = (deleteId) => {
        this.setState({ deleteId })
    };

    resetDelete = () => {
        this.setState({deleteId: null})
    };

    executeDelete = () => {
        const { deleteId } = this.state;
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.deletePlatformMacro(deleteId));
        this.resetDelete();
    };

    toggleZendeskMacro = (e, updateField) => {
        let enable = e.target.checked;
        const { dataType, dispatch, msgDataSourceId, sensitiveActionsEnabled } = this.props;
        let postData = {msgDataSourceId: msgDataSourceId, updateField, enable};
        dispatch(msgDataSourceActions.toggleClosedTicketActioning({...postData, msgDataSourceLabel: dataType }));
        if (updateField === 'isPlatformMacroEnabled' && !enable && sensitiveActionsEnabled) {
            dispatch(msgDataSourceActions.toggleClosedTicketActioning({...postData, msgDataSourceLabel: dataType, updateField: 'sensitiveActionsEnabled' }));
        }
    };

    toggleHtmlDisplay = (displayType) => {
        this.setState((prevState) => ({
            [displayType]: !prevState[displayType]
        }));
    };

    backToSavedMacros = () => {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.clearMacrosList())
    };

    render() {
        const { selectedMacros, deleteId, editId, rankValue, displayHtml, displayAsHtml } = this.state;
        const { savedMacros, macrosList, msgDataSource, dataType, showData, sensitiveActionsEnabled,
            closeModal, platformMacroEnabled, macroLoading, initialLoad } = this.props;
        const savedMacrosIdList = savedMacros && savedMacros.map(item => item.macroId)
        const organizationName = initialLoad && msgDataSource.organizationName ? `${msgDataSource.organizationName}'s ` : ' '
        return (
            <GenericModal
                headerText={`Macros for ${organizationName}${dataType} account`}
                hideFooter
                show={showData}
                onHide={closeModal}
                size="xl"
                classes="maximum-width-modal"
                isConversationThread={true}
            >
                {macroLoading &&
                    <div className="flex-column align-center justify-content-center">
                        <LoadingSpinner />
                    </div>
                }
                {(!macroLoading && (!macrosList || (macrosList && macrosList.length === 0))) &&
                    savedMacros && savedMacros.length > 0 &&
                    <table className="table">
                        <thead className="font-12-bold">
                            <tr>
                                <th colSpan="5" className="tms-align-text-center position-relative">
                                    <Box className="position-left-absolute">
                                        <FormControlLabel control={<Switch onChange={(e) => this.toggleZendeskMacro(e, 'isPlatformMacroEnabled')} sx={styles.switch} checked={platformMacroEnabled} />}
                                              label={<Typography sx={styles.instanceLabel}>Enable {dataType} Macro</Typography>}/>
                                        {dataType === 'Gorgias' && platformMacroEnabled &&
                                        <BasicTooltip title={
                                            <Box sx={styles.tooltipTypographyContainer}>
                                                <Typography sx={styles.tooltipTypography}>
                                                    {SENSITIVE_ACTION_INFO}
                                                </Typography>
                                            </Box>
                                        }>
                                            <FormControlLabel control={<Switch onChange={(e) => this.toggleZendeskMacro(e, 'sensitiveActionsEnabled')} sx={styles.switch} checked={sensitiveActionsEnabled} />}
                                                      label={<Typography sx={styles.instanceLabel}>Enable Sensitive Macro Actions</Typography>}
                                            />
                                        </BasicTooltip>
                                        }
                                    </Box>

                                    <span className="font-15-bold">Saved Macros</span>
                                    <Button className="btn-info btn-sm position-right-absolute" onClick={this.pullZendeskMacros}>Update Macros Selection</Button>
                                </th>
                            </tr>
                            <tr>
                                <th className="col-md-1 vertical-align-middle">S/N</th>
                                <th className="col-md-2 vertical-align-middle">Macro Title</th>
                                <th className="col-md-5 tms-align-text-center vertical-align-middle">
                                    Macro Content
                                    {HTML_DISPLAY_TYPE.includes(dataType) &&
                                        <div className="tms-check-box font-14 margin-left-20 display-inline-block">
                                            <label>
                                                <input type="checkbox" name="macroSelection"
                                                       checked={displayHtml}
                                                       onChange={() => this.toggleHtmlDisplay('displayHtml')}
                                                />
                                                <span className="tms-check-mark" style={styles.checkBox}>{""}</span>
                                                <span className="margin-left-5">Display HTML</span>
                                            </label>
                                        </div>
                                    }
                                </th>
                                <th className="col-md-2 vertical-align-middle padding-0 tms-align-text-center">Macro Rank</th>
                                <th className="col-md-2 vertical-align-middle">{""}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {savedMacros.map((macro, index) =>
                                <tr key={index}>
                                    {macro.id === deleteId &&
                                        <td colSpan="5">
                                            <span className="flex-container flex-column tms-align-text-center">
                                                Are you sure you want to remove Macro from list? <br />
                                                <div className="button-container">
                                                    <Button className="btn-info btn-sm margin-top-5" onClick={this.executeDelete}>Yes</Button>
                                                    <Button className="btn-danger btn-sm margin-left-5 margin-top-5" onClick={this.resetDelete}>No</Button>
                                                </div>
                                            </span>
                                        </td>
                                    }
                                    {macro.id !== deleteId &&
                                        <Fragment>
                                            <td>{index + 1}</td>
                                            <td>{macro.title}</td>
                                            <td>
                                                {!displayHtml && macro.text}
                                                {HTML_DISPLAY_TYPE.includes(dataType) && displayHtml && <div dangerouslySetInnerHTML={{ __html: MARKDOWN_CONTENT.includes(dataType) ? md.render(macro.content) : macro.content }}></div>}
                                            </td>
                                            <td className="tms-align-text-center">
                                                {editId !== macro.id &&
                                                    <span>{macro.rank}</span>
                                                }
                                                {editId === macro.id &&
                                                    <Fragment>
                                                        <input type="text" className="col-md-6 padding-2-5" name="rank" id="" value={rankValue} onChange={this.updateRankvalue} />
                                                        <Button className="btn-success btn-sm" onClick={() => this.performRankUpdate(macro.rank)}>
                                                            <i className="fa fa-check">{""}</i>
                                                        </Button>
                                                    </Fragment>
                                                }
                                            </td>
                                            <td className="padding-x-0 padding-top-10">
                                                <Button className="btn-info btn-sm" onClick={() => this.updateMacro(macro.id)} title="Update Macro">
                                                    Update
                                                </Button>
                                                <Button className="btn-info btn-sm margin-left-5" onClick={() => this.editMacro(macro.id, macro.rank)} title="Update Rank">
                                                    <i className="fa fa-pencil">{""}</i>
                                                </Button>
                                                <Button className="btn-danger btn-sm margin-left-5" onClick={() => this.confirmDelete(macro.id)} title="Delete Macro">
                                                    <i className="fa fa-trash">{""}</i>
                                                </Button>
                                            </td>
                                        </Fragment>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
                {!macroLoading && macrosList && macrosList.length > 0 &&
                    <table className="table">
                        <thead className="font-12-bold">
                            <tr>
                                <td colSpan="7" className="tms-align-text-center position-relative">
                                    <span>
                                        Macros in {dataType} <br />
                                        <small>(Saved macros will not show here)</small>
                                    </span>
                                    <div className="position-right-absolute">
                                        <div className="tms-check-box font-14">
                                            <label>
                                                <input type="checkbox" name="macroSelection" checked={true} readOnly={true} />
                                                <span className="tms-check-mark table-selected-item" style={styles.checkBox}>{""}</span>
                                                <span className="margin-left-5">Current Selection</span>
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {macrosList.filter(macro => !savedMacrosIdList.includes(macro.macroId)).length > 0 &&
                                <tr>
                                    <th className="col-md-1 vertical-align-middle">{""}</th>
                                    <th className="col-md-2 vertical-align-middle">Macro Title</th>
                                    <th className="col-md-8 vertical-align-middle">
                                        Macro Content
                                        {HTML_DISPLAY_TYPE.includes(dataType) &&
                                            <div className="tms-check-box font-14 margin-left-20 display-inline-block">
                                                <label>
                                                    <input type="checkbox" name="macroSelection"
                                                           checked={displayAsHtml}
                                                           onChange={() => this.toggleHtmlDisplay('displayAsHtml')}
                                                    />
                                                    <span className="tms-check-mark" style={styles.checkBox}>{""}</span>
                                                    <span className="margin-left-5">Display As HTML</span>
                                                </label>
                                            </div>
                                        }
                                    </th>
                                    <th className="col-md-1 vertical-align-middle">Usage</th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            {macrosList.filter(macro => !savedMacrosIdList.includes(macro.macroId))
                                .map((macro, index) =>
                                <tr key={index} onClick={() => this.handleSelection(macro.macroId)}
                                    className={`${selectedMacros.includes(macro.macroId) ? 'table-selected-item' : ''}`}>
                                    <td className="col-md-1">
                                        <div className="tms-check-box font-14">
                                            <label>
                                                <input type="checkbox" name="macroSelection"
                                                   checked={selectedMacros.includes(macro.macroId)}
                                                   onChange={() => this.handleSelection(macro.macroId)}
                                                />
                                                <span className="tms-check-mark" style={styles.checkBox}>{""}</span>
                                            </label>
                                        </div>
                                    </td>
                                    <td className="col-md-3">{macro.title}</td>
                                    <td className="col-md-4">
                                        {!displayAsHtml && <div className="word-wrap">{macro.content}</div>}
                                        {HTML_DISPLAY_TYPE.includes(dataType) && displayAsHtml && <div dangerouslySetInnerHTML={{ __html: MARKDOWN_CONTENT.includes(dataType) ? md.render(macro.content) : macro.content }}></div>}
                                    </td>
                                    <td className="col-md-1">{macro.usage}</td>
                                </tr>
                            )}
                        </tbody>
                        {macrosList.filter(macro => !savedMacrosIdList.includes(macro.macroId)).length > 0 &&
                            <tfoot>
                                <tr>
                                    <td colSpan="7">
                                        <Button className="btn-danger btn-sm margin-left-5" onClick={this.saveZendeskMacroData}>
                                            Save & Complete Selections
                                        </Button>
                                    </td>
                                </tr>
                            </tfoot>
                        }
                    </table>
                }
                {macrosList.length > 0 && macrosList.filter(macro => !savedMacrosIdList.includes(macro.macroId)).length === 0 &&
                    <div className="tms-align-text-center">
                        There are no Macros to be saved
                        <div>
                            <Button className="btn-info btn-sm margin-left-5 margin-top-15" onClick={this.backToSavedMacros}>Back to Saved Macros</Button>
                        </div>
                    </div>
                }
                {!macroLoading && savedMacros && savedMacros.length === 0 && macrosList && macrosList.length === 0 &&
                    <div className="flex-container flex-column justify-content-center align-items-center">
                        <h4 className="margin-bottom-10">There are no saved Macros yet.</h4>
                        <Button className="btn-info btn-sm" onClick={this.pullZendeskMacros}>Start Macros Selection</Button>
                    </div>
                }
            </GenericModal>
        )
    }
}

function mapStateToProps(state) {
    const { msgDataSources: { savedMacros, macrosList, msgDataSource, currentPage, nextPage, macroLoading, initialLoad }} = state;
    return {
        savedMacros, macrosList, msgDataSource, currentPage, nextPage, macroLoading, initialLoad
    };
}

const connectedMacroModal = connect(mapStateToProps)(PlatformMacroModal)
export { connectedMacroModal as PlatformMacroModal }