import React, {Fragment} from "react";
import {Checkbox, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {makeStyles} from "@mui/styles";
import {removeItemFromArray} from "_helpers";
import _ from "lodash";
import {selectStyles} from "./selectStyles";

export default function BasicMultiSelect({input, label, placeholder, options, onChange, handleFormSelectChange, id, name, meta: {touched, error, warning}, defaultValues}) {
    const classes = useStyles();
    const placeholderValue = 'none';
    const getInitialState = () => {
        if (_.isEmpty(defaultValues)) {
            return [placeholderValue]
        } else {
            return defaultValues
        }
    };
    const [formState, setFormState] = React.useState({
        [input.name]: getInitialState()
    });
    const [selectOptions, setSelectOptions] = React.useState(options);
    const hasError = touched && !_.isEmpty(error);

    const handleFieldChange = event => {
        const values = event.target.value;
        if(values.length > 1 && values.includes(placeholderValue)) removeItemFromArray(values, placeholderValue);
        else if(values.length === 0 && !values.includes(placeholderValue)) values.push(placeholderValue);
        setFormState((formState) => ({
            ...formState,
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        }));
        if (typeof onChange === 'function') {
            onChange(event);
        }
    };

    const handleRenderValue = (selected) => {
        setSelectOptions(options);
        //This removes the selected item from the redux-form when the value changes
        if(!_.isEmpty(options) && !_.isEmpty(selectOptions)) {
            if(!(_.isEqual(_.sortBy(options || []), _.sortBy(selectOptions || [])))) {
                handleValuesDeselect([placeholderValue]);
                return;
            }
        }

        const selectedItems = selected.map(val => {
            const res = options.find(item => item.id  === val)
            return res && res.name;
        })
        return selectedItems && selectedItems.join(", ") || placeholder;
    };

    const handleValuesDeselect = (value)=>{
        delete formState[input.name];
        setFormState({
            ...formState,
            [input.name]: value
        });
        handleFormSelectChange(value);
    }

    const renderMenuItems = (item) => {
        return <MenuItem
            key={item.id}
            input={input}
            value={item.id}
            style={{fontSize: '16px'}}>
            <Checkbox checked={formState[input.name].indexOf(item.id) > -1}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '20px', color: '#368C86' } }}/>
            {item.name}
        </MenuItem>
    };

    return(
        <Fragment>
            <TextField className={ (hasError || warning) ? classes.textFieldError : classes.textField }
                       InputProps={{ classes: { root: classes.textField } }}
                       inputProps={{ classes: { icon: classes.icon } }}
                       SelectProps={{
                           multiple: true,
                           value: formState[input.name],
                           onChange: handleFieldChange,
                           renderValue: handleRenderValue
                       }}
                       id={id}
                       name={name}
                       label={label}
                       variant="outlined"
                       select
                       {...input}
                       error={ hasError }>
                { options ? options.map(renderMenuItems) : <MenuItem/> }
            </TextField>
        </Fragment>
    )
}

const useStyles = makeStyles(selectStyles);