import {connect} from "react-redux";
import { msgDataSourceActions } from '_actions';
import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {alertMessages} from "_constants";
import { queryStringToObject } from "_helpers";
import {DotLoading} from "components/common";
import moment from "moment";

const contentStyles = {
    width: '50%',
    padding: '20px 40px',
    minHeight: '40vh'
};

class OrgOpenTicketsList extends Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);
        let { org, views, dataSource } = filterParams;

        this.state = {
            dataSource,
            currentOrg: org,
            viewsList: views.split(','),
            platform: 'gorgias'
        };
    }

    componentDidMount() {
        const { currentOrg, viewsList, dataSource } = this.state;
        const { dispatch } = this.props;
        viewsList.map(viewId =>
            dispatch(msgDataSourceActions.getViewsTickets(viewId, currentOrg, dataSource))
        );
    }

    handleViewSelection = (e) => {
        this.setState((prevState) => {
            let viewSelectionList = prevState.viewSelectionList
            if (e.target.checked) {
                viewSelectionList.push(Number(e.target.value))
            } else {
                viewSelectionList = viewSelectionList.filter(val => val !== e.target.value)
            }
            return {
                viewSelectionList
            }
        })
    };

    updateViewsSelection = () => {
        const { history } = this.props;
        const { currentOrg, viewsList, platform } = this.state;
        history.push(`/open-tickets-views?org=${currentOrg}&views=${viewsList.join(',')}&msgSource=${platform}`)
    };

    render() {
        const { gorgiasPlatformViews, msgDataSourceOrgList, gorgiasDataSourceId, gorgiasViewTickets } = this.props;
        const { viewsList, currentOrg } = this.state;
        const orgData = msgDataSourceOrgList.find(item => item.orgId === currentOrg);
        const selectedViews = gorgiasPlatformViews[gorgiasDataSourceId] && gorgiasPlatformViews[gorgiasDataSourceId].filter(item => viewsList.includes(item.id.toString()));
        console.log(gorgiasViewTickets);
        const momentNow = moment();

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <div className="row col-md-4 background-white" style={contentStyles}>
                    <Button bsStyle="success" className="margin-top-15 btn-sm" type="submit" onClick={this.updateViewsSelection}>
                        Update Views Selection
                    </Button>
                    <hr />
                    <h4 className="col-12 margin-bottom-10">{orgData && orgData.orgName} Views</h4>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="col-4">View</th>
                                <th className="col-3">Open Tickets</th>
                                <th className="col-5">Oldest Message Received on Open Tickets</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedViews && selectedViews.map(current => (
                                <tr key={current.id}>
                                    <td>
                                        {gorgiasViewTickets[current.id] && gorgiasViewTickets[current.id].viewLink &&
                                            <a href={gorgiasViewTickets[current.id].viewLink} target="_blank" rel="nofollow noreferrer noopener">{current.name}</a>
                                        }
                                        {(!gorgiasViewTickets[current.id] || (gorgiasViewTickets[current.id] && gorgiasViewTickets[current.id].loading)) && current.name }
                                    </td>
                                    <td>
                                        {(!gorgiasViewTickets[current.id] || (gorgiasViewTickets[current.id] && gorgiasViewTickets[current.id].loading)) && <DotLoading />}
                                        {gorgiasViewTickets[current.id] && gorgiasViewTickets[current.id].tickets && gorgiasViewTickets[current.id].tickets.length}
                                    </td>
                                    <td>
                                        {(!gorgiasViewTickets[current.id] || (gorgiasViewTickets[current.id] && gorgiasViewTickets[current.id].loading)) && <DotLoading />}
                                        {gorgiasViewTickets[current.id] && gorgiasViewTickets[current.id].lastMessageDate && `${momentNow.diff(moment(gorgiasViewTickets[current.id].lastMessageDate), "days")} days ago`}
                                        {gorgiasViewTickets[current.id] && !gorgiasViewTickets[current.id].lastMessageDate && 'N/A'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { msgDataSources: { gorgiasPlatformViews, msgDataSourceOrgList, gorgiasDataSourceId, gorgiasViewTickets }} = state;
    return {
        gorgiasPlatformViews, msgDataSourceOrgList, gorgiasDataSourceId, gorgiasViewTickets
    };
}

const connectedListGorgiasDataSources = connect(mapStateToProps)(OrgOpenTicketsList);
export { connectedListGorgiasDataSources as GorgiasOrgOpenTicketsList };
