import TimezonePicker from "react-timezone";
import React from "react";

const Timezone = (props) => {

    const { placeHolder, label, input, ...rest } = props;

    return (
        <div>
            <label htmlFor={label} className="field-label"><span>{label}</span></label>
            <TimezonePicker
                {...rest}
                {...input}
                placeholder={placeHolder}
                onChange={input.onChange}
                className="timezone"
                name={input.name}
                required
            />
        </div>
    );
};

export default Timezone