import React, {Fragment, useState} from "react";
import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import {reduxForm} from 'redux-form';
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import CreateCriteriaForm from "./CreateCriteriaForm";
import CreateReplies from "./CreateReplies";
import ActionButton from "../../../common/ActionButton";
import AnchorButton from "../../../common/AnchorButton";
import styles from "../Utilities/templateStyles";
import {buildCreateTemplatePayload, validateTopic} from "../Utilities/CampaignUtils";
import {proactiveActions} from "_actions";
import {checkProperties} from "_helpers";
import {disabledGenerateResponsesChannels} from "_constants";

function CreateTemplateForm({ handleSubmit }) {
    const dispatch = useDispatch();
    const [criteriaValues, setCriteriaValues] = useState({});
    const [selectedReplies, setSelectedReplies] = useState([]);
    const [generateRepliesVisible, setGenerateRepliesVisible] = useState(undefined);
    const [selectedChannel, setSelectedChannel] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(true);
    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingCreateTemplate;
    const repliesObj = proactive.replies;
    let disableSubmitButton = generateRepliesVisible ? _.isEmpty(repliesObj) : disableSubmit;
    disableSubmitButton = disableSubmitButton || loading;

    const handleCreateTemplate = () => {
        if(!isValidCriteria(criteriaValues)) return;
        const payload = buildCreateTemplatePayload(criteriaValues, selectedReplies);
        console.log(payload)
        dispatch(proactiveActions.createTemplate(payload));
    };

    const handleChannelSelection = (channel) => {
        if (!channel) return;
        setSelectedChannel(channel);
        if (disabledGenerateResponsesChannels.includes(channel)) {
            setGenerateRepliesVisible(false);
        } else {
            setGenerateRepliesVisible(true)
        }
    }

    const isValidCriteria = (values) => {
        const requiredProperties = ['name', 'topic', 'template_0', 'minDaysBetweenSameReplyUsages', 'channel'];
        return checkProperties(values, requiredProperties) && validateTopic(values);
    }

    const handleCriteria = (values) => {
        setCriteriaValues(values);
        handleDisableButton(values);
    };

    const handleDisableButton = (values) => {
        if(isValidCriteria(values)) {
            setDisableSubmit(false);
        }else if(!isValidCriteria(values)) {
            setDisableSubmit(true);
        }
    };

    const handleReplies = (value) => {
        setSelectedReplies(value);
    };

    const clearSelectedReplies = () => {
        setSelectedReplies([]);
    }

    return(
        <>
            <Box>
                <Grid container>
                    <CreateCriteriaForm
                        handleCriteria={handleCriteria}
                        clearSelectedReplies={clearSelectedReplies}
                        handleChannelSelection={handleChannelSelection}
                    />
                    { !disabledGenerateResponsesChannels.includes(selectedChannel) &&
                        <CreateReplies
                            handleReplies={handleReplies}
                            selectedReplies={selectedReplies}
                            displayGenerateReplies={generateRepliesVisible}
                            selectedChannel={selectedChannel}
                        />
                    }
                </Grid>
            </Box>
            <Fragment>
                <form name="form" onSubmit={handleSubmit(handleCreateTemplate)} autoComplete="off">
                    <Box sx={styles.footer}>
                        <Box sx={{width: '100px', marginRight: '18px',}}>
                            <ActionButton
                                label={'Save'}
                                disabled={disableSubmitButton}
                                withIcon={loading}
                            />
                        </Box>
                        <Box sx={{width: '100px', height: '40px'}}>
                            <AnchorButton
                                label={'Cancel'}
                                link={'/company/template'}
                                buttonBordered={true}
                            />
                        </Box>
                    </Box>
                </form>
            </Fragment>
        </>
    )
}

const formConfig = {
    form: 'CreateTemplateForm',
};

export default reduxForm(formConfig)(CreateTemplateForm);