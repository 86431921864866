import {Box} from "@mui/system";
import styles from "../conversationStyles";
import LoadingPlaceholderBlock from "../../../../../common/LoadingPlaceholderBlock";
import {Fragment} from "react";

export default function ConversationEmptyState() {
    const EmptyState = () => {
        return(
            <Fragment>
                <Box sx={styles.root}>
                    <Box sx={styles.icon}>
                        <Box sx={{ width: '40px', height: '40px'}}>
                            <LoadingPlaceholderBlock
                                height={'40px'}
                                borderRadius={'5px'}
                                backgroundColor={'#EFEAE5'}
                            />
                        </Box>
                    </Box>
                    <Box sx={styles.messageContainer}>
                        <Box sx={styles.infoArea}>
                            <Box sx={styles.infoContent}>
                                <Box sx={styles.infoItem}>
                                    <Box sx={{width: '100px', height: '13px', margin: '3px 0 7px 0'}}>
                                        <LoadingPlaceholderBlock
                                            height={'13px'}
                                            borderRadius={'3px'}
                                            backgroundColor={'#EFEAE5'}
                                        />
                                    </Box>

                                    <Box sx={styles.dateContainer}>
                                        <LoadingPlaceholderBlock
                                            width={'150px'}
                                            height={'13px'}
                                            borderRadius={'3px'}
                                            backgroundColor={'#EFEAE5'}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{...styles.dateContainerMobile, ...{marginBottom: '12px'}}}>
                                <LoadingPlaceholderBlock
                                    width={'150px'}
                                    height={'13px'}
                                    borderRadius={'3px'}
                                    backgroundColor={'#EFEAE5'}
                                />
                            </Box>
                        </Box>
                        <LoadingPlaceholderBlock
                            height={'100px'}
                            borderRadius={'6px'}
                            backgroundColor={'#EFEAE5'}
                        />
                    </Box>
                </Box>
            </Fragment>

        )
    }

    return(
        <Fragment>
            {
                [1,2].map((item) => {
                    return <EmptyState key={item}/>
                })
            }
        </Fragment>
    )
}