export class PayoutsBuilder {

    constructor() {
        this.payouts = {}
    }

    setInitiatedAt(initiatedAt){
        this.payouts.initiatedAt = initiatedAt
        return this;
    }

    setArrivalDate(arrivalDate){
        this.payouts.arrivalDate = arrivalDate
        return this;
    }

    setExternalAccount(destination){
        this.payouts.destination = destination
        return this;
    }

    setAmount(amount){
        this.payouts.amount = amount
        return this;
    }

    build(){
        return this.payouts;
    }
}