import React from "react";
import * as moment from 'moment-timezone';
import {Typography} from "@material-ui/core";
import {formatCustomerName, parseEscapedText} from "_helpers";
import {MessageActionDetailV2} from "./MessageActionDetailV2";
import _ from 'lodash';
import {MessageFollowupParser} from "../common";
import {MessageActivityDetailV2} from "./MessageActivityDetailV2";
import TikTokShopContent from "../v2/common/Message/TikTokShop/TikTokShopContent";

function RenderMessageV2(props) {
    const {message, organization, isTrends} = props;
    const OUTGOING = 'outgoing';
    let text = parseEscapedText(message.text);
    const dateCreated = message.dateCreatedFromSource ? message.dateCreatedFromSource : (message.dateCreated || message.createdDate);
    const zone_name = moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();
    const author = message.authorName ? message.authorName : message.author;
    const msgSource = _.get(message, 'sourceName') || _.get(message, 'messageSource.name');
    const formattedAuthor = message.direction && message.direction.toString().toLowerCase() === OUTGOING ? author : formatCustomerName(author, "", msgSource);
    let textContent;

    if(_.toString(msgSource).toLowerCase() === 'tiktok shop') {
        textContent = <TikTokShopContent text={text} sourceName={msgSource}/>;
    } else {
        textContent = <MessageFollowupParser
            text={text}
            msgSource={msgSource}
            organization={organization}
            isTrends={isTrends}
        />;
    }

    return (
        <div key={message.id} className="message">
            <p className="font-13 color-grey-mid" style={{display: 'flex', justifyContent: 'space-between'}}>
              <span className='margin-right-10'>
                {formattedAuthor &&
                <span>
                    <span className="margin-right-10">{formattedAuthor}</span>
                    {' '}
                  </span>
                }

                  {`${moment(dateCreated).format('MMM D, YYYY hh:mm a z')} ${timezone}`}

                  {message.isNew &&
                  <span className="badge btn-danger" style={{marginLeft: '10px', marginBottom: '3px'}}>New</span>}
              </span>
                {/*TODO: remove below line when migration to MessageActivity is done*/}
                <MessageActionDetailV2 message={message}/>
            </p>
            <Typography style={{fontSize: "15px", marginRight: "20px"}}>
                <span className="no-break-out  margin-right-20">
                  {textContent}
                </span>
            </Typography>
            <MessageActivityDetailV2 message={message}/>
        </div>
    );
}


export {RenderMessageV2}
