import { Box, Button } from '@mui/material';

// copied from /v2/views/Onboarding/Utilities/ButtonContained
export default function ActionButton({ label, action, disabled, sx }) {
  return(
    <Box sx={{...sx, ...{height: '40px'}}}>
      <Button 
        sx={{
          width: '100%',
          fontSize: '16px',
          padding: '12px 24px',
          color: '#fff',
          fontFamily: 'LarsseitMedium',
          lineHeight: 1,
          textTransform: 'none',
          borderRadius: '50px',
          backgroundColor: '#368C86',
          ':hover': {
            backgroundColor: '#34756f',
            color: 'white',
          },
          ':disabled': {
            color: '#fff',
            backgroundColor: '#368C8680'
          },
          ':focus': {
            outline: '0'
          }
        }}
        type="submit"
        onClick={action}
        variant="contained"
        disableElevation
        disabled={disabled}>
        {label}
      </Button>
    </Box>
  )
}