import { authHeader, handleHttpResponse } from '_helpers';
import {SERVER_URL} from 'config';

function update(msgDataSourceId, data) {
    return fetch(`${SERVER_URL}/api/msgDataSources/gorgias/${msgDataSourceId}`,
        {
            method: 'PATCH',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

export const gorgiasMsgDataSourceService = {
    update
};
