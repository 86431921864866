import React from 'react'
import { ToggleButton } from "react-bootstrap";

class ToggleButtonField extends React.Component {
    render() {

        const {style, label, input, ...rest} = this.props;

        return (
            <ToggleButton
                {...rest}
                onChange={input ? input.onChange : this.props.onChange}
                style={style} value={input ? input.value : this.props.value}>
                {label}
            </ToggleButton>
        )
    }
}

export {ToggleButtonField}