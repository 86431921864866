import React from 'react';
import {attributesActions} from "../../_actions";
import _ from "lodash";
import {Button} from "react-bootstrap";
import {connect} from "react-redux";

class UserAttributes extends React.Component {
    state = {
        selectedAttributes: [],
        allAttributes: [],
        isChanged: false,
    };

    onSaveClick = (e) => {
        e.preventDefault();
        this.props.dispatch(attributesActions.putAttributes({userId: this.props.userId, selectedAttributes: this.state.selectedAttributes}));
    };

    handleCheckBoxChange = (event) => {
        let { selectedAttributes } = this.state;
        if(this.props.attributes && !this.isChanged) {
            selectedAttributes = this.props.attributes.selectedAttributes

            this.setState({
                allAttributes: this.props.attributes.allAttributes
            })
            this.isChanged = true;
        }

        const target = event.target;
        const value = _.get(target, 'value');

        if(target.checked) {
            selectedAttributes.push(value);
        } else {
            selectedAttributes.splice(selectedAttributes.indexOf(value), 1);
        }

        this.setState({ selectedAttributes });
    };

    renderAttributes = () => {
        let selectedAttributes = this.props.attributes.selectedAttributes
        let allAttributes = this.props.attributes.allAttributes
        if(this.isChanged) {
            selectedAttributes = this.state.selectedAttributes
            allAttributes = this.state.allAttributes
        }

        return allAttributes && allAttributes.map((option) => {
            let isChecked = false;
            if (selectedAttributes) {
                isChecked = selectedAttributes.includes(String(option.value));
            }

            return (
                <label key={option.value} className="context-menu-label font-15 padding-bottom-10 align-center">
                    <input type="checkbox" name={option.name} checked={isChecked} value={option.value} onChange={this.handleCheckBoxChange} className="tms-checkbox tms-child-checkbox"/>
                    <span className="tms-check-mark" style={{top: 0, right: 5}}></span>
                    {option.name}
                </label>
            )
        })
    };

    render() {
        return (
            <React.Fragment>
                <div><span className="font-22-bold color-grey-dark tms-ellipsis" style={{marginTop: 10}}>Agent attributes</span><Button className='btn-info' onClick={this.onSaveClick} style={{marginLeft: 5, marginTop: -5}}>Save</Button></div>
                <div>{this.renderAttributes()}</div>
            </React.Fragment>
        )
    }
}

const userAttributes = connect(null)(UserAttributes);
export {userAttributes as UserAttributes};