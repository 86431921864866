import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";
import {CSMInfoBuilder} from "../objects/CSMInfoBuilder";

export default function (organizationId) {
    const dispatch = useDispatch();

    useEffect(() => {
        if(!_.isEmpty(organizationId)) {
            dispatch(organizationSettingsActions.getOrganizationCSMs(organizationId));
        }
    }, [organizationId]);

    const organizationSettings = useSelector(state => state.organizationSettings);
    const loadingCSMInfo = organizationSettings.loadingOrganizationCSMs;
    const csmArr = _.get(organizationSettings, 'organizationCSMs.csmList') || [];
    const csmList = [];

    if(!loadingCSMInfo && csmArr.length > 0) {
        csmArr.map(csm => {
            const builder = new CSMInfoBuilder();
            builder.setId(csm.userId);
            builder.setName(csm.username);
            csmList.push(builder.build());
        });
    }

    return {loadingCSMInfo, csmList}
}