import Avatar from "@mui/material/Avatar";
import {Box} from "@mui/system";
import Button from "@mui/material/Button";
import React from "react";
import {makeStyles} from "@mui/styles";

export default function ConnectChannelButton({label, channelIcon, useCircleAvatar, action}) {
  const classes = useStyles();

  return(
    <Button className={classes.button}
      variant="outlined"
      disableElevation
      onClick={action}
      startIcon={ useCircleAvatar ?
      <Avatar src={channelIcon} style={{width: '35px', height: '35px'}}/> :
      <img src={channelIcon} style={{width: '35px', height: '35px'}}/> }>
      <Box fontSize={{xs: '18px', sm: '18px', md: '18px', lg: '18px'}}>
        {label}
      </Box>
    </Button>
  )
}

const useStyles = makeStyles({
  button: {width: '100%', padding: '8px 10px 8px 12px', color: '#000000', fontFamily: 'LarsseitRegular',
    textTransform: 'none', borderRadius: '10px', backgroundColor: '#ffffff', borderColor: '#0C2728',
    justifyContent: "flex-start", textAlign: 'left', lineHeight: '250%',
    '&:hover': {
      backgroundColor: '#f7f7f7',
      borderColor: '#0C2728',
      color: '#000000',
    }
  }
});