import React, {useState, useEffect, useRef} from "react";
import {Box, Grid, Button, Link} from "@mui/material";
import MessageLockIcon from "@material-ui/icons/Lock";
import {Loading, QuillEditor} from "../../../../common";
import ActionButton from "../../../common/ActionButton";
import TextButton from "../../../common/TextButton";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Conversation from "../Utilities/Conversation";
import {CrossDispatchWarningModal} from "../../Messages/CrossDispatchWarningModal";
import _ from "lodash";
import {handleType, dispatcherConstants, LOCK_DURATION_IN_SECS, LOCK_EMAIL_DURATION_IN_SECS} from "_constants";
import {useDispatch} from "react-redux";
import {cleanResponseText, getCurrentUser, isObjectEmpty, timeDifferenceInSeconds} from "_helpers";
import moment from "moment";
import {messageActions, userActions, dispatcherActions} from "_actions";
import CheckMarkIcon from "../../../../images/check_circle.svg";
import { useHistory } from 'react-router-dom'

export default function InboxConversation(props){
    const {notificationType, loadingMessage, conversation: _conversation, loadingCompanyReplied, openedMessageId} = props

    const dispatch = useDispatch();
    const editorRef = useRef(null)
    const { id: userId } = getCurrentUser();
    const history = useHistory()

    const [conversation, setConversation] = useState({})
    const [replyText, setReplyText] = useState("")
    const [lockedMessage, setLockedMessage] = useState(false)
    const [crossDispatchWarningModal, setCrossDispatchWarningModal] = useState(false)
    const [crossDispatchWarningDismissed, setCrossDispatchWarningDismissed] = useState(false)

    const isEmail = _.get(props, 'notificationType') === 'priority' ?
        [handleType.email, handleType.proactive_email].includes(_.get(props, 'conversation.dataSourceHandle.type')) :
        [handleType.email, handleType.proactive_email].includes(_.get(props, 'conversation.0.dataSourceHandle.type'));

    const handleBackButton = () => {
        history.goBack()
    }

    const handleTextFieldUpdate = (text) => {
        setReplyText(text)
    }

    const lockMessage = () => {
        const message = _.get(props, 'trendsCorrespondingMessages.teamsMessages.0');
        const isLocked = _.get(message, 'isLocked');
        let timeDiff = timeDifferenceInSeconds(moment(_.get(message, 'lockedAtTime')).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));

        let lockTime = isEmail ? LOCK_EMAIL_DURATION_IN_SECS : LOCK_DURATION_IN_SECS;

        if (!isLocked || (isLocked && timeDiff > lockTime)) {
            dispatch(messageActions.lockMessage(_.get(message, 'id'), userId));
        }
        setLockedMessage(true)
    }

    const handleMarkAsDone = () => {
        const messageId = conversation && conversation.constructor === Array ? _.get(conversation, '0.id') : _.get(conversation, 'id');
        dispatch(userActions.markInboxMsgAsDone(messageId, userId))
    }

    const showCrossDispatchWarningModal = () => {
        setCrossDispatchWarningModal(true);
    }

    const closeCrossDispatchWarningModal = () => {
        setCrossDispatchWarningModal(false);
        setCrossDispatchWarningDismissed(true);
    }

    const clearCrossDispatchWarningDismissed = () => {
        setCrossDispatchWarningDismissed(false);
    }

    const handleSubmit = (messageId, response) => {
        const sourceName = _.get(conversation, 'sourceName');
        const v2Status = _.get(conversation, 'isV2Enabled');
        response = cleanResponseText(response, sourceName);
        const payload = {
            openedMessageId,
            isDispatched: false,
            reply: response,
            userId,
            source: sourceName,
            isV2Enabled: v2Status,
            draftId: conversation.draft?.id,
            crossDispatchWarningDismissed: crossDispatchWarningDismissed
        };

        dispatch(dispatcherActions.dispatchMessage(messageId, payload))
            .then((result) => {
                if (result.success) {
                    handleTextFieldUpdate("");
                    editorRef.current.clearEditor();
                    clearCrossDispatchWarningDismissed();
                } else if (!result.success && result?.response?.errorCode === dispatcherConstants.POSSIBLE_CROSS_DISPATCH_ERROR_CODE) {
                    showCrossDispatchWarningModal();
                }
            }).catch((error) => console.error('An error occurred when dispatching message: ' + error))
    }

    const msg = _.get(props, 'trendsCorrespondingMessages.teamsMessages.0');
    const companyReplied = _.get(props, 'companyReplied.id');
    const markAsDone = _.get(props, 'markAsDone.id');
    const link = _.get(conversation, "link");

    let timeDiff = timeDifferenceInSeconds(moment(_.get(msg, 'lockedAtTime')).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
    const lockedByCurrentUser = _.get(msg, 'lockedByUser') === userId;

    useEffect(() => {
        if(notificationType === "all_messages") {
            setConversation(_conversation[0])
        }else{
            setConversation(_conversation)
        }
    },[])

    useEffect(() => {
        if(!replyText){
            let defaultText = '';
            if (notificationType === "priority" && ! _.isEmpty(conversation)) {
                if(conversation && conversation._embedded && conversation._embedded.sendForEditResponse) {
                    defaultText = conversation._embedded.sendForEditResponse.text;
                    setReplyText(defaultText)
                }
            }
            if (notificationType === "review" && ! _.isEmpty(conversation)) {
                if (conversation && conversation._embedded && conversation._embedded.selectedResponseOption) {
                    defaultText = conversation._embedded.selectedResponseOption.text;
                    setReplyText(defaultText)
                }
            }

        }

    },[replyText])


    if(!lockedMessage){
        lockMessage()
    }

    if ( loadingMessage || loadingCompanyReplied ){
        return (
            <Loading/>
        )
    }

    return(
        <Grid container>
            <Grid item md={8} sm={12} xs={12} style={styles.container}>
                <Box>
                    <Box sx={styles.headingContainer}>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <Box>
                                <TextButton
                                    label={'Back to all messages'}
                                    action={handleBackButton}
                                    sx={styles.backButton}
                                    rootStyle={styles.backButtonRoot}
                                    startIcon={<ChevronLeftOutlinedIcon sx={styles.backButtonIcon}/>}
                                />
                            </Box>

                            { _.get(msg, 'lockedAtTime') && timeDiff < LOCK_DURATION_IN_SECS && !lockedByCurrentUser &&
                                <MessageLockIcon className="color-grey-mid" fontSize={'small'} style={{margin: '0 15px 2px 15px'}}/>
                            }
                        </Box>
                        <Box sx={{display: "flex", alignItems: "center", gap: "20px"}}>
                            <Box>
                                { !loadingCompanyReplied && (
                                    (!isObjectEmpty(markAsDone) || !isObjectEmpty(companyReplied) || _.get(msg, 'isDispatchedSuccessfully')) ?
                                        <img src={CheckMarkIcon} alt="actioned" /> :
                                        <Button variant="text" sx={styles.textStyle} onClick={handleMarkAsDone}>
                                            Mark as done
                                        </Button>
                                )
                                }
                            </Box>

                            {
                                link &&
                                <Link target="_blank" rel="noopener noreferrer" href={`${link}`} sx={styles.textStyle} underline="none">
                                    View on source
                                </Link>
                            }
                        </Box>
                    </Box>

                    <Conversation
                        conversation={conversation}
                        notificationType={notificationType}
                    />

                    <Box>
                        <Box sx={styles.editorStyle}>
                            <QuillEditor
                                defaultText={conversation.draft ? conversation.draft.text : ''}
                                handleTextChange={handleTextFieldUpdate}
                                isEmail={isEmail}
                                ref={editorRef}
                            />
                        </Box>

                        <Box sx={{width: '100px', marginTop: '15px'}}>
                            <ActionButton
                                label={'Send'}
                                action={() => handleSubmit(conversation.id, replyText)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <CrossDispatchWarningModal
                open={crossDispatchWarningModal}
                handleClose={closeCrossDispatchWarningModal}
            />
        </Grid>
    )
}

const styles = {
    container: {
        backgroundColor: "#FFFFFF",
        border: "solid 1px #dee2e0",
        boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)",
        borderRadius: "5px",
        padding: "18px 38px"
    },
    headingContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    textStyle: {
        fontSize: '16px',
        padding: '0',
        color: '#07857C',
        textTransform: 'none',
        fontFamily: 'LarsseitMedium',
        letterSpacing: "-0.14px",
        ':hover': {
            color: "#285a55",
            backgroundColor: "transparent",
            textDecoration: 'none',
        },
        ':focus': {
            color: '#07857C',
            textDecoration: 'none',
        },
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
    },
    backButtonRoot: {
        fontSize: '14px',
        height: '20px',
        color: '#0C2728',
        textAlign: 'left',
        fontFamily: 'LarsseitMedium',
        ':hover': {
            color: '#081a1a',
            backgroundColor: 'unset'
        },
        ':disabled': {
            color: '#8c8f8f',
        },
        ':active': {
            outline: '0',
            color: '#0C2728',
        },
    },
    backButtonIcon: {
        mr: '-5px'
    },
    editorStyle: {
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#0C2728',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
}