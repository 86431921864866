import {Box} from "@mui/system";
import userAvatar from 'components/images/user_avatar_.svg'
import React from "react";
import {styles as importedStyles} from "./EarningsSummary";
import {generatePhotoLink} from "_helpers";

/**
 *
 * @param agent
 * @param index
 * @returns {JSX.Element}
 * @constructor
 */
export default function LeadAgentsList({agent, index}){
    const {agentFirstName, agentPhotoUrl, totalEarnings } = agent

    const photo = agentPhotoUrl ? generatePhotoLink(agentPhotoUrl) : userAvatar

    return(
        <Box sx={styles.container}>
            <Box sx={styles.imageContainer}>
                <Box sx={importedStyles.textField}>{index}</Box>
                <Box sx={styles.agentImage}>
                    <img src={photo} style={{height: '100%'}} alt={agentFirstName}/>
                </Box>
                <Box sx={importedStyles.textField}>{agentFirstName}</Box>
            </Box>
            <Box sx={importedStyles.textField}>${totalEarnings}</Box>
        </Box>
    )
}

const styles = {
    container: {
        padding: '10px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    imageContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 2
    },

    agentImage: {
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        overflow: 'hidden'
    }
}