export class EarningsHistoryBuilder {

    constructor() {
        this.earningsHistory = {}
    }

    setDate(date){
        this.earningsHistory.date = date
        return this;
    }

    setCompany(company){
        this.earningsHistory.company = company
        return this;
    }

    setChannel(channel){
        this.earningsHistory.channel = channel
        return this;
    }

    setMessageId(messageId){
        this.earningsHistory.messageId = messageId
        return this;
    }

    setAmountEarned(amountEarned){
        this.earningsHistory.amountEarned = amountEarned
        return this;
    }

    build(){
        return this.earningsHistory;
    }
}