import React, {Component, Fragment} from 'react';
import {Grid} from "@material-ui/core";
import LoadingPlaceholderBlock from "../../common/LoadingPlaceholderBlock";
import {ResponseEmptyState} from "./ResponseEmptyState";


/**
 * @author Chris Okebata
 */

const style = {
  rootWrapper: {
    width: '100%',
    height: 'auto',
    position: 'relative',
    top: '60px',
  },
  bgColor: '#e8e7e7',
};


class VoteEmptyState extends Component{

  constructor(props){
    super(props);

    this.state = {
      isResponseSelected: false,
      selectedResponseId: '',
    }
  }

  render() {

    const {bgColor} = style;

    return(
      <Fragment>
        <div style={style.rootWrapper}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <div style={{height: 'calc(100vh - 60px)', borderRight: '1px solid #CED2D0', position: 'relative'}}>
                <div style={{height: '40px', display: 'flex', alignItems: 'center', padding: '0px 35px', borderBottom: '1px solid #dee2e0', backgroundColor: '#f3f3f3'}}>
                  <div className="font-14" style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="color-grey-dark tms-ellipsis" style={{width: '30%',}}>
                      <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <span className="color-green" style={{width: '70px'}}>
                        <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                      </span>
                      <span style={{marginLeft: '15px', width: '100px'}} className="color-green">
                        <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                      </span>
                    </div>

                  </div>
                </div>

                <div style={{height: 'calc(100% - 110px)', padding: '0 0 17px 35px'}}>
                  <div style={{overflowY: 'auto', height: '100%', padding: '5px 35px 15px 0'}}>

                    {/*BEGIN LOOP HERE*/}
                    <div style={{borderBottom: '1px solid #CED2D0', marginBottom: '0px', padding: '15px 0 5px 0'}}>
                      <div className="font-14 color-grey-dark" style={{display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{width: '150px'}} className="tms-ellipsis">
                          <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                        </div>
                        <div style={{width: '120px', textAlign: 'right'}} className="tms-ellipsis">
                          <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                        </div>
                      </div>

                      <div style={{padding: '20px 0 10px 0'}}>
                        <LoadingPlaceholderBlock height={'80px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                      </div>
                    </div>
                    {/*END LOOP HERE*/}

                  </div>
                </div>

                <div style={{width: '100%', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                  padding: '0px 35px', position: 'absolute', bottom: '0', borderTop: '1px solid #CED2D0'}}>
                  <div style={{ width: '100%', marginRight: '10px', }}>
                    <LoadingPlaceholderBlock height={'40px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                  </div>

                  <div style={{ width: '100%', marginLeft: '10px'}}>
                    <LoadingPlaceholderBlock height={'40px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7}>
              <div style={{height: 'calc(100vh - 60px)', position: 'relative'}}>

                <ResponseEmptyState/>

                <div>
                  <div style={{width: '100%', height: '70px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                    padding: '0px 35px', position: 'absolute', bottom: '0', borderTop: '1px solid #CED2D0',}}>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                      <div style={{width: '50%',}}>
                        <LoadingPlaceholderBlock height={'40px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                      </div>
                      <div style={{width: '100px', marginLeft: '10px'}}>
                        <LoadingPlaceholderBlock height={'40px'} borderRadius={'3px'} backgroundColor={bgColor}/>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    )
  }

}

export { VoteEmptyState };