import {utilityService, salesforceOrgConfigService} from '_services';
import {alertMessages, organizationConstants} from "../_constants";
import {SnackBar} from "../components/common";

function update(organizationId, data, handleId) {
    function request(handleId) {
        return {type: organizationConstants.GET_SALESFORCE_ORG_CONFIG_REQUEST, handleId: handleId};
    }

    function success() {
        return {type: organizationConstants.GET_SALESFORCE_ORG_CONFIG_SUCCESS};
    }

    function failure(error) {
        return {type: organizationConstants.GET_SALESFORCE_ORG_CONFIG_FAILURE, error: error};
    }

    return (dispatch) => {
        dispatch(request(handleId));
        return salesforceOrgConfigService.update(organizationId, data)
            .then(
                response => {
                    dispatch(success());
                    SnackBar.showMessage(alertMessages.UPDATE_ORGANIZATION_SUCCESS);
                },
                errorPromise => {
                    errorPromise.then(error => {
                        dispatch(failure(error));
                        SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                    });
                },
            );
    };
}

export const salesforceOrgConfigActions = {
    update
};
