import { systemSettingsConstants } from '_constants';

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 29/01/2019 : 5:56 PM
 */

const INITIAL_STATE = {
    systemSettings: {},
    loading: true,
    error: '',
};

export function systemSettingsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        
        case systemSettingsConstants.SHOW_REQUEST:
        case systemSettingsConstants.UPDATE_REQUEST:
            return { ...state, loading: true  };

        case systemSettingsConstants.SHOW_SUCCESS:
            return { ...state, systemSettings: action.payload, loading: false };

        case systemSettingsConstants.UPDATE_FAILURE:
        case systemSettingsConstants.SHOW_FAILURE:
            return { ...state, error: action.payload, loading: false };

        case systemSettingsConstants.UPDATE_SUCCESS:
            return { ...state, loading: false };

            
        default:
            return state
    }
}