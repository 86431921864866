export const companyResourcesConstants = {

    GET_ALL_COMPANY_RESOURCES_REQUEST: 'GET_ALL_COMPANY_RESOURCES_REQUEST',
    GET_ALL_COMPANY_RESOURCES_SUCCESS: 'GET_ALL_COMPANY_RESOURCES_SUCCESS',
    GET_ALL_COMPANY_RESOURCES_FAILURE: 'GET_ALL_COMPANY_RESOURCES_FAILURE',

    CREATE_RESOURCE_REQUEST: 'CREATE_RESOURCE_REQUEST',
    CREATE_RESOURCE_SUCCESS: 'CREATE_RESOURCE_SUCCESS',
    CREATE_RESOURCE_FAILURE: 'CREATE_RESOURCE_FAILURE',

    GET_RESOURCE_REQUEST: 'GET_RESOURCE_REQUEST',
    GET_RESOURCE_SUCCESS: 'GET_RESOURCE_SUCCESS',
    GET_RESOURCE_FAILURE: 'GET_RESOURCE_FAILURE',

    UPDATE_RESOURCE_REQUEST: 'UPDATE_RESOURCE_REQUEST',
    UPDATE_RESOURCE_SUCCESS: 'UPDATE_RESOURCE_SUCCESS',
    UPDATE_RESOURCE_FAILURE: 'UPDATE_RESOURCE_FAILURE',

    GET_ALL_BY_ORG_RESOURCE_REQUEST: 'GET_ALL_BY_ORG_RESOURCE_REQUEST',
    GET_ALL_BY_ORG_RESOURCE_SUCCESS: 'GET_ALL_BY_ORG_RESOURCE_SUCCESS',
    GET_ALL_BY_ORG_RESOURCE_FAILURE: 'GET_ALL_BY_ORG_RESOURCE_FAILURE',

};