import moment from "moment";
import React, {Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import {metricsActions} from "_actions/metrics.actions";
import {CircularProgress} from "@mui/material";
import _ from "lodash";
import {oEmbedActions} from "_actions";
import {isTimeToday, shouldDisplayInApp} from "_helpers";
import {isMetaSourcesForOEmbed} from "../Vote/Sections/BodySection/Conversation/OEmbed/EmbedUtils";

const MessageList = ({msgList, selectedChannels, setMessageLink, setMessageSource, loading, setMessageClicked}) => {
    const dispatch = useDispatch();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const orgId = currentUser._embedded.organization.id;
    const [selectedMessage, setSelectedMessage ] = useState(null);

    const onMessageSelect = ({messageId, agentId, link, msgSource, handleTypeName, externalParentId}) => {
        msgSource = _.toString(msgSource).toLowerCase();
        handleTypeName = _.toString(handleTypeName).toLowerCase();
        const displayInApp = shouldDisplayInApp(msgSource, handleTypeName);
        if(isMetaSourcesForOEmbed(msgSource, displayInApp)) {
            dispatch(oEmbedActions.getMetaEmbed(messageId));
        }

        setMessageLink(link)
        setMessageSource(msgSource.name ? msgSource.name : msgSource)
        setSelectedMessage(messageId)
        setMessageClicked(true)
        dispatch(metricsActions.getMetricsDetailConversation({
            messageId,
            agentId,
            organization: orgId,
            channels: selectedChannels,
            msgSource,
            handleTypeName,
            externalParentId
        }))
    };

    return msgList.map(msg => {
        return (
            <Fragment>
                {loading &&
                    <div style={{ background: 'transparent', top: 0, left: 0, width: '100%', height: '100%'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                            <CircularProgress style={{color: '#143F42'}}/>
                        </div>
                    </div>
                }
                {!loading &&
                    <div
                        role="button"
                        key={msg.messageId}
                        id={msg.messageId}
                        style={{
                            backgroundColor: selectedMessage === msg.messageId ? '#F3F5F6' : 'white'
                        }}
                        onClick={() => onMessageSelect(msg)}
                    >
                    <div className="message-list-container" >
                        <div className="tms-truncate-2-lines message-list-text">
                            {msg.msg}
                        </div>
                        <div className="message-list-date" >
                            {isTimeToday(msg.date) ?
                                moment(msg.date).format('HH:mm a') :
                                moment(msg.date).format('DD-MMM-YYYY')}
                        </div>
                    </div>
                </div>
                }
            </Fragment>
        )
    })
}

export default MessageList