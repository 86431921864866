import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {sortArrayOfDays} from "_helpers";
import React from "react";

export const ScheduleTable = ({schedules, classes, deleteSchedule, orgId}) => {
    const heads = ["Schedule name", "Days", "Start time", "End time"];

    return (
        <Paper className={classes.table}>
            <Table style={{width: '100%'}}>
                <TableHead>
                    <TableRow>
                        {heads.map(head => {
                            return <TableCell className={classes.tableHeadCell} key={head}>{head}</TableCell>
                        })}
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {schedules.map(schedule => {
                        const days = schedule.days.map((day) => {
                            return day.name
                        });
                        const sortedDays = sortArrayOfDays(days)

                        return (
                            <TableRow key={schedule.id}>
                                <TableCell align="left" className={classes.tableCell}
                                           style={{width: "24%"}}>{schedule.name}</TableCell>
                                <TableCell align="left" className={classes.tableCell}
                                           style={{width: "40%"}}>{sortedDays.join(', ')}</TableCell>
                                <TableCell align="left" className={classes.tableCell}
                                           style={{width: "12%"}}>{schedule.startTime}</TableCell>
                                <TableCell align="left" className={classes.tableCell}
                                           style={{width: "12%"}}>{schedule.endTime}</TableCell>
                                <TableCell align="left" className={classes.tableCell} style={{width: "12%"}}>
                                    <button type="button"
                                            className="btn btn-danger btn-sm trends-table-header-width-100"
                                            onClick={() => {
                                                const props = {id: schedule.id, orgId: orgId};
                                                deleteSchedule(props)
                                            }}> Delete
                                    </button>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Paper>
    )
}

