import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";
import "./editorStyles.css";
import EditorAttachment from "./EditorAttachment";
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { connect } from 'react-redux';
import {uploadActions} from "_actions";
import UploadsLoading from "./UploadsLoading";
import htmlToText from "html-to-text";
import EditorTag from "./EditorTag/EditorTag";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji } = quillEmoji;
Quill.register({
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji
}, true);
Quill.register('modules/counter', function(quill, options) {
  let container = document.querySelector(options.container);
  quill.on('text-change', function() {
    let text = quill.getText();
    if (options.show){
      if (options.unit === 'word') {
        container.innerText  = text.split(/\s+/).length + ' words';
      } else {
        container.innerText  = options.limit + 1 - text.length + " characters remaining";
        if (quill.getLength() > options.limit){
          quill.deleteText(options.limit, quill.getLength());
        }
      }
    }
  });
});
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
let _this;

/**
 * @author: Okpala Oluchukwu
 */
class QuillEditor extends Component {
  constructor(props) {
    super(props);

    let defaultHtml = this.props.defaultText || '';
    _this = this;
    this.state = {
      html: defaultHtml,
      attachments: [],
    }
  }

  updateAttachments = (attachments) => {
    this.setState({attachments: attachments});
  };

  clearEditor = () => {
    this.setState({html: '', attachments: []});
  };

  handleAttachment = () => {
    this.openBrowseDialog();
  };

  uploadImage(file, quill) {
    this.setState({ quill: quill });
    const { dispatch } = this.props;
    const formData = new FormData();
    formData.append('image', file);
    dispatch(uploadActions.uploadImage(formData))
      .then(() => { this.embedImage() })
      .catch(() => console.error('An error occurred while uploading image'));
  }

  embedImage() {
    const { quill } = this.state;
    const { uploadImage, loadingUploadImage } = this.props;
    const range = quill.getSelection(true);
    quill.setSelection(range.index + 1);
    if(!loadingUploadImage && !_.isEmpty(uploadImage))
    quill.insertEmbed(range.index, 'image', _.get(uploadImage, "imageUrl"));
  }

  dragAndDropImageHandler(dataUrl, type, imageData) {
    const file = imageData.toFile(imageData.name);
    _this.uploadImage(file, this.quill);
  }

  imageInputHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      _this.uploadImage(file, this.quill);
    };
  }

  handleChange = (html) => {
    const plainText = htmlToText.fromString(html, {wordwrap: false})
    this.setState({ html });
    const {isEmail, handleTextChange, input, useWithRedux} = this.props;

    if (isEmail) {
      handleTextChange(html)
    } else {
      if (handleTextChange) {
        handleTextChange(plainText)
      } else {
        if (useWithRedux) {
          input.onChange(plainText)
        }
      }
    }
  };

  openBrowseDialog = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput && fileInput.click();
  };

  getTools = (isEmail, isAttachmentSupported, isImageEmbedSupported) => {
    let toolsArray = [
      ['bold', 'italic', 'underline', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['emoji'],
      ['clean'],
    ];
    isEmail && toolsArray.push(["link"]);
    isAttachmentSupported && toolsArray.push(['attachment']);
    isImageEmbedSupported && toolsArray.push(['image']);
    return toolsArray;
  };

  modules = {
    toolbar: {
      container: this.getTools(this.props.isEmail, this.props.isAttachmentSupported, this.props.isImageEmbedSupported),
      handlers: {
        "attachment": this.handleAttachment,
        "image": this.imageInputHandler
      },
    },
    'emoji-toolbar': true,
    "emoji-shortname": true,
    counter: {
      container: '#counter',
      unit: 'character',
      show: this.props.isCharDisplay,
      limit: this.props.length
    },
    imageDropAndPaste: {
      handler: this.dragAndDropImageHandler
    },
  };
  formats = ['bold', 'italic', 'underline', 'blockquote', 'list', 'align', "link", 'clean', 'emoji', 'attachment', 'image'];

  render() {
    const { isCharDisplay, handleSelectFile, selectedAttachments, handleDeselectAttachment, selectedTags, handleRemoveTag } = this.props;
    return (
      <div>
        <div className="text-editor">
          <ReactQuill
            ref={el => {
              this.quill = el;
            }}
            theme="snow"
            modules={this.modules}
            formats={this.formats}
            value={this.state.html}
            onChange={this.handleChange}
          />
          <div className={'modules-container'}>
            <UploadsLoading isLoading={this.props.loadingUploadImage}/>
            <EditorAttachment
              handleSelectFile={handleSelectFile}
              selectedAttachments={selectedAttachments}
              handleDeselectAttachment={handleDeselectAttachment}/>
          </div>
          <div>
            <EditorTag
                selectedTags={selectedTags}
                handleRemoveTag={handleRemoveTag}
            />
          </div>
        </div>
        {isCharDisplay &&
          <div className={'counter-container'}>
             <div className="counter" id="counter"/>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { uploads: {uploadImage, loadingUploadImage, uploadImageError} } = state;
  return {
    uploadImage,
    loadingUploadImage,
    uploadImageError,
  };
}

const connectedQuillEditor = connect(mapStateToProps, null, null, {forwardRef: true})(QuillEditor);
export { connectedQuillEditor as QuillEditor };