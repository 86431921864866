import {Fragment} from "react";
import {Box} from "@mui/system";
import {Divider, Grid} from "@mui/material";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";

export default function DataSourcesEmptyState() {

    const Content = () => {
        return (
            <Grid container columns={2}>
                <Grid item xs={4} md={1} sx={styles.nameContainer}>
                    <Box sx={{width: '132px', mt: '28px'}}>
                        <LoadingPlaceholderBlock
                            height={'15px'}
                            borderRadius={'5px'}
                            backgroundColor={'#d7d2cf'}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} md={1} sx={styles.statusContainer}>
                    <Box sx={{width: '70px', mt: '28px', mr: '11px'}}>
                        <LoadingPlaceholderBlock
                            height={'15px'}
                            borderRadius={'5px'}
                            backgroundColor={'#d7d2cf'}
                        />
                    </Box>
                    <Box sx={{width: '205px', mt: '15px'}}>
                        <LoadingPlaceholderBlock
                            height={'40px'}
                            borderRadius={'5px'}
                            backgroundColor={'#d7d2cf'}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
    }

    return(
        <Fragment>
            <Content />
            <Divider orientation={'horizontal'} flexItem sx={styles.sectionDivider} />
            <Content />
        </Fragment>
    )
}

const styles = {
    nameContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 4,
        alignItems: 'flex-start',
    },
    sectionDivider: {
        mt: '18px'
    }
};