import {Box} from "@mui/system";

export default function ProactiveMetricsMessageIframe({text, index}) {
    const offset = 20;

    const onLoad = () => {
        return setTimeout(() => {
            const obj = document.getElementById(`${index}-pmc-messageFrame`);
            if(obj) {
                obj.style.height = obj.contentWindow.document.body.scrollHeight + offset + 'px';
            }
        }, 50);
    }

    return(
        <Box component={'iframe'}
             id={`${index}-pmc-messageFrame`}
             scrolling={'no'}
             sx={styles.iframe}
             srcDoc={text}
             onLoad={onLoad}
        />
    )
}

const styles = {
    iframe: {
        width: '100%',
        border: 0,
    },
}