import {getActionData} from "../_helpers";
import {alertMessages, dixaConstants} from "_constants";
import {dixaService} from "_services";
import _ from "lodash";
import {Auth} from "../components/Authorization";
import {SnackBar} from "../components/common";

function verifyApiToken(apiToken) {
    return (dispatch) => {
        dispatch(getActionData(dixaConstants.VERIFY_API_TOKEN_REQUEST));
        dixaService.verifyApiToken(apiToken)
            .then(response => {
                dispatch(getActionData(dixaConstants.VERIFY_API_TOKEN_SUCCESS, response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(dixaConstants.VERIFY_API_TOKEN_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function createAccount(apiToken) {
    return (dispatch) => {
        dispatch(getActionData(dixaConstants.CREATE_ACCOUNT_REQUEST));
        dixaService.createAccount(apiToken)
            .then(response => {
                dispatch(getActionData(dixaConstants.CREATE_ACCOUNT_SUCCESS, response.data));
                SnackBar.showMessage(alertMessages.DIXA_CREATE_ACCOUNT_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(dixaConstants.CREATE_ACCOUNT_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function resetApiTokenStatus() {
    return (dispatch) => {
        dispatch(getActionData(dixaConstants.CLEAR_VERIFY_API_TOKEN));
    }
}

export const dixaActions = {
    verifyApiToken,
    resetApiTokenStatus,
    createAccount,
};