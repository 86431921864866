/**
 * @param ref {object}
 * @returns {boolean}
 */
export function hasVerticalScroll(ref) {
    if(!ref) return false;
    const { current } = ref;
    if(!current) return false;
    return  current.scrollHeight > current.clientHeight;
}

export function componentControl(shouldEnable) {
    return {
        opacity: shouldEnable ? 1 : 0.5,
        pointerEvents: shouldEnable ? 'initial' : 'none'
    }
}