import {Fragment} from "react";
import {Box} from "@mui/system";
import {Field} from "redux-form";
import BasicMultilineTextField from "../../../common/BasicMultilineTextField";
import styles from "../Utilities/templateStyles";


export default function TemplateField({required, index, handleChange}) {
    return(
        <Fragment>
            <Box sx={{margin: '15px 0 10px 0'}}>
                <Box sx={styles.inputDesc}>Template</Box>
                <Field
                    name={`template_${index}`}
                    component={BasicMultilineTextField}
                    rowsCount={4}
                    placeholder={'Enter template'}
                    onChange={handleChange}
                    validate={required}
                />
            </Box>
        </Fragment>
    )
}