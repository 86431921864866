import React from 'react';

const CriteriaResponseButtons = ({ setSelectedCriteria, criteriaOptions, selectedCriteria }) => {
    const handleCriteriaChange = (event) => {
        setSelectedCriteria(event.target.value);
    }

    return (
        <div className="criteria-filter">
            <span className="criteria-heading">Criteria</span>
            {criteriaOptions.map((option, index) =>
                <label key={index} className="criteria-radio-label" htmlFor={option.name}
                       onClick={(e) => handleCriteriaChange}>
                    {option.value}
                    <input
                        type="radio"
                        name="radio"
                        value={option.name}
                        id={option.name}
                        onChange={handleCriteriaChange}
                        checked={selectedCriteria === option.name}
                    />
                    <span className="radio-checkmark"/>
                </label>
            )}
        </div>
    )
}

export {CriteriaResponseButtons}