export const walletConstants = {
    GET_ALL_AGENT_WALLET_REQUEST: 'GET_ALL_AGENT_WALLET_REQUEST',
    GET_ALL_AGENT_WALLET_SUCCESS: 'GET_ALL_AGENT_WALLET_SUCCESS',
    GET_ALL_AGENT_WALLET_FAILURE: 'GET_ALL_AGENT_WALLET_FAILURE',

    AGENT_PAYOUT_HISTORY_REQUEST: 'AGENT_PAYOUT_HISTORY_REQUEST',
    AGENT_PAYOUT_HISTORY_SUCCESS: 'AGENT_PAYOUT_HISTORY_SUCCESS',
    AGENT_PAYOUT_HISTORY_FAILURE: 'AGENT_PAYOUT_HISTORY_FAILURE',

    PAYOUT_TO_AGENT_REQUEST: 'PAYOUT_TO_AGENT_REQUEST',
    PAYOUT_TO_AGENT_SUCCESS: 'PAYOUT_TO_AGENT_SUCCESS',
    PAYOUT_TO_AGENT_FAILURE: 'PAYOUT_TO_AGENT_FAILURE',

    AGENT_WALLET_DETAILS_REQUEST: 'AGENT_WALLET_DETAILS_REQUEST',
    AGENT_WALLET_DETAILS_SUCCESS: 'AGENT_WALLET_DETAILS_SUCCESS',
    AGENT_WALLET_DETAILS_FAILURE: 'AGENT_WALLET_DETAILS_FAILURE',

    UPDATE_AGENT_BALANCE: 'UPDATE_AGENT_BALANCE',
    UPDATE_HANDLE_MODAL: 'UPDATE_HANDLE_MODAL',

    GET_AGENT_WALLET_BALANCE_REQUEST: 'GET_AGENT_WALLET_BALANCE_REQUEST',
    GET_AGENT_WALLET_BALANCE_SUCCESS: 'GET_AGENT_WALLET_BALANCE_SUCCESS',
    GET_AGENT_WALLET_BALANCE_FAILURE: 'GET_AGENT_WALLET_BALANCE_FAILURE',
}