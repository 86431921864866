import { Box } from '@mui/material';
import Badge from '../Utilities/Badge';
import DashboardTable, { DashboardTableRow, DashboardTableCell, tableStyles } from './DashboardTable';
import {to12Hours} from "_helpers";

/**
 * 
 * @param {object} params
 * @param {import('./hooks/useScheduleData').MsgDataSource} params.msgDataSource The reduced `msgDataSource` object, which includes the schedules.
 * @param {any} params.msgDataSourceData The default `msgDataSource` object, which includes everything but not the schedules.
 */
export default function ScheduleMsgDataSourceTable({ msgDataSource, msgDataSourceData, timezone }) {
	return (
		<DashboardTable key={msgDataSource.id} label={'Schedule'}>
			{msgDataSource && msgDataSource.msgDataSourceHandles ? msgDataSource.msgDataSourceHandles.map(msgDataSourceHandle => {
				const msgDataSourceHandleData = msgDataSourceData && msgDataSourceData.dataSourceHandles && msgDataSourceData.dataSourceHandles.find(entry => entry.id === msgDataSourceHandle.id)
				return (
					<DashboardTableRow key={msgDataSourceHandle.id}>
						<DashboardTableCell sx={{ ...tableStyles.cellVerticalDivider, minWidth: { xs: '200px', md: '350px' }, maxWidth: { xs: '200px', md: '350px' } }}>
							<Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
								{ (msgDataSourceHandleData && msgDataSourceHandleData.name) || 'Unnamed Channel'}
							</Box>
						</DashboardTableCell>
						<DashboardTableCell sx={tableStyles.cellWithBadges}>
							<Box sx={styles.badgesWrapper}>
								{msgDataSourceHandle.schedules && msgDataSourceHandle.schedules.map((scheduleEntry, key) => {
									return (
										<Box key={key} sx={styles.badge}>
											<Badge>
												<Box component={'span'} sx={styles.badgeValue}>
													<Box component={'span'} sx={{ fontFamily: 'LarsseitMedium' }}>{to12Hours(scheduleEntry.hourStart)}</Box>
													{' - '}
													<Box component={'span'} sx={{ fontFamily: 'LarsseitMedium' }}>{to12Hours(scheduleEntry.hourEnd, timezone)}</Box>
												</Box>
											</Badge>
										</Box>
									)
								})}
							</Box>
						</DashboardTableCell>
					</DashboardTableRow>
				)
			}) : null}
		</DashboardTable>
	)
}

const styles = {
	badgesWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
		m: -0.5,
		minHeight: '32px',
	},
	badge: {
		m: 0.5,
	},
	badgeValue: {
		fontFamily: 'LarsseitRegular',
	},
}