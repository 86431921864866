import React from 'react';
import {Link, Redirect, Route} from "react-router-dom";
import {Grid, Row} from "react-bootstrap";

/**
 * This is a higher order component that renders a component (WrappedComponent)
 * based on the provided allowedRoles prop. I.e, authorization.
 * @param allowedRoles
 * @returns {function(*)}
 * @constructor
 */
export const Authorization = (allowedRoles) =>
    (WrappedComponent) => {

        function isAuthorized(currentUser) {
            return currentUser.roles.some(role => allowedRoles.includes(role));
        }

        return class AuthorizedComponent extends React.Component {

            render() {

                const currentUser = localStorage.user;

                if (!currentUser) {
                    return <Route
                        render={props => (<Redirect to={{pathname: '/', state: {from: props.location}}}/>)}/>
                } else {

                    if (isAuthorized(JSON.parse(localStorage.user))) {
                        return <WrappedComponent {...this.props}/>
                    } else {
                        return <Grid>
                            <Row className='text-center'>
                                <i className='fa fa-warning fa-5x text-danger'/>
                                <h1 className='text-danger'>RESTRICTED AREA</h1>
                                <h3>AUTHORIZED PERSONNEL ONLY!</h3>
                                <small><Link to='/dashboard' className='btn btn-info btn-xs'>Go Back</Link></small>
                            </Row>
                        </Grid>
                    }
                }
            }
        }
    };



