import React, {Component} from 'react'
import {GenericModal, TextEditor} from "components/common";
import {handleType} from '_constants'

class EditMessageModalV2 extends Component {

    constructor(props){
        super(props);
        this.state = {
            message: ''
        };
    }

    handleSubmit = () => {
        const {updateMessageFromSource, platform, message, closeModalHandler} = this.props;
        const updatedText = this.state.message;
        const updateFields = {message: updatedText ? updatedText : message.text}
        const payload = {
            updateFields,
            platform,
            source: message.sourceName,
            isV2Enabled: message.isV2Enabled
        };
        updateMessageFromSource(message.id, payload);
        closeModalHandler()

    };

    handleChange = (text) => {
        this.setState({message: text})
    };

    render() {
        const {show, onHide, headerText, buttonText, message} = this.props;
        const isEmail = message.dataSourceHandle && [handleType.email, handleType.proactive_email].includes(message.dataSourceHandle.type);
        return (

            <GenericModal
                show={show}
                onHide={onHide}
                headerText={headerText}
                buttonText={buttonText}
                onSave={this.handleSubmit}
                className="modal-edit-message"
                enforceFocus={false}
            >

                <TextEditor
                    handleTextChange={this.handleChange}
                    isEmail={isEmail}
                    defaultText={message && message.text ? message.text : ''}

                />
            </GenericModal>
        )
    }
}

export default EditMessageModalV2