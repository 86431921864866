import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FilterForm } from 'components/Inbox';
import { messageActions, dataSourceHandleActions } from '_actions';
import ReactPaginate from 'react-paginate';
import moment from 'moment/moment';
import 'moment-timezone';
import {isObjectEmpty, objectToQueryString, processAdminMessage, queryStringToObject} from '_helpers';
import MessagesTable from 'components/Inbox/MessagesTable';
import {Loading} from "../common";
import _ from "lodash";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 13/09/2018 : 10:31 AM
 * @author Chris Okebata
 */

const ALL_MESSAGES = 'all_messages';

class InboxPage extends Component {

    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);

        let { startDate, endDate, userAction, channels } = filterParams;
        let key = userAction === ALL_MESSAGES ? 1 : 0;
        channels =  channels ? channels.split(",") : [];
        const currentUser = JSON.parse(localStorage.user);

        const offset = filterParams.offset || 0;
        const max = 10;
        const pageSelected = Math.ceil(offset / max) ;
        const messagesFilter = [{id: 0, name: 'Priority'}, {id: 1, name: 'All Messages'}]

        /**
         * By Default, if there is no selected start and end date,
         * we set the start date to the first day of the year, and the endDate today
         */
        const formattedStartDate = startDate ? moment(startDate).startOf('day') : moment().startOf('year');
        const formattedEndDate = endDate ? moment(endDate).endOf('day') : moment();

        startDate = formattedStartDate.format('YYYY-MM-DD HH:mm:ss');
        endDate = formattedEndDate.format('YYYY-MM-DD HH:mm:ss');

        this.state = {
            key,
            pageSelected,
            messagesFilter,
            filterParams: {
                startDate,
                endDate,
                handleStatus: 'LIVE',
                organization: currentUser.organization_id,
                userAction,
                channels,
                offset
            }
        };

    }

    /**
     * A lifecycle method that fetches the initial batch of
     * messages and message sources to populate the view as the page loads.
     */
    componentDidMount() {

        const { key, filterParams: { userAction, channels, offset, startDate, endDate, handleStatus } } = this.state;
        /**
         * Get the default filter parameters from state and use
         * them to retrieve initial messages to populate view
         * @type {{startDate : string, endDate : string}}
         */

        const params = this.populateParams(key, channels, offset, startDate, endDate);
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const { organization : { id } } = currentUser._embedded;

        this.selectRequestAction(userAction, params);
        this.props.dispatch(dataSourceHandleActions.getAll({organization: id, max: 100, handleStatus}));
    }

    selectRequestAction = (userAction, params) => {

        if (userAction === ALL_MESSAGES){
            const currentUser = JSON.parse(localStorage.getItem('user'));
            const { organization : {id} } = currentUser._embedded;
            this.props.dispatch(messageActions.getAllFromTrends(params, id))
        }
        else{
            this.props.dispatch(messageActions.getPriorityMessages(params));
        }

    };

    /**
     * Handles the submission of a date range from the filter form.
     * @param props
     */
    handleFilterFormSubmit = (props) => {
        const { startDate, endDate, userAction, channels } = this.state.filterParams;

        if (props.dateRange) {
            props.startDate = props.dateRange.startDate.format('YYYY-MM-DD HH:mm:ss');
            props.endDate = props.dateRange.endDate.format('YYYY-MM-DD HH:mm:ss');

        } else {
            props.startDate = startDate;
            props.endDate = endDate;
        }
        delete props.dateRange;

        props.userAction = userAction;
        props.channels = channels;

        if (channels.length === 0) {
            props.channels = this.getChannels(this.props.dataSourceHandles, "ids");
        }

        const params = {
            ...this.state.filterParams,
            offset: 0
        };

        /**
         * Combine filter parameters from state with currently selected
         * start and end date and use that to filter a new set of messages.
         * @type {{}}
         */

        const filterParams = { ...params, ...props };
        const queryString = objectToQueryString(filterParams);

        /**
         * This applies the query parameters from form to browser URL
         */
        this.props.history.push({
            pathname: '/inbox',
            search: `?${queryString}`
        });

    };

    /**
     * Handles the selection of a navigation tab.
     * In this case, switching between escalations and reviews tab
     * @param key
     * @param startDate
     * @param endDate
     */

    populateParams(key, channels, offset, startDate, endDate){

        startDate = moment(startDate)
            .format('YYYY-MM-DD HH:mm:ss');
        endDate = moment(endDate)
            .format('YYYY-MM-DD HH:mm:ss');
        let userAction;

        if (parseInt(key, 10) === 0)
            userAction =  'priority';
        else
            userAction = ALL_MESSAGES;

        offset = offset || 0;

        this.setState({
            key,
            filterParams: {
                ...this.state.filterParams,
                startDate,
                endDate,
                userAction,
                channels,
                offset
            }
        });

        const { filterParams } = this.state;
        const params = {
            ...filterParams,
            startDate: moment(filterParams.startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(filterParams.endDate).format('YYYY-MM-DD HH:mm:ss'),
            userAction,
            offset
        };
        return params
    }
    /**
     * Extracts message channels List from dataSourceHandles
     * @param dataSourceHandles
     * @param returnValue (either ids or object)
     */
    getChannels = (dataSourceHandles, returnValue) => {
        let channels = [];

        dataSourceHandles.forEach(dataSourceHandle => {
            if (returnValue === "ids") {
                channels.push(dataSourceHandle.trendsId )
            } else {
                let dataSource =  {
                    value: dataSourceHandle.trendsId,
                    name : dataSourceHandle.name
                };
                channels.push(dataSource)
            }
        });

        return channels
    };

    /**
     * Handles a change in the messages filter.
     * @param event
     * @param startDate
     * @param endDate
     */
    handleMessageFilterChange = (event, startDate, endDate) => {
        const target = event.target;
        let key;

        if (target.name === "userActions") {
            key =  target.value;
        } else {
            key = this.state.key;
        }

        const { channels, offset } = this.state.filterParams;
        this.populateParams(key, channels, offset, startDate, endDate);
    };

    /**
     * Handles a change in the Channels filter.
     * params channels
     */
    handleChannelsFilterChange = (channels) => {
        const { key } = this.state;
        const { startDate, endDate, offset } = this.state.filterParams;
        this.populateParams(key, channels, offset, startDate, endDate);
    };

    /**
     * Handles the selection of a button on the response needed pagination button list.
     * @param page
     * @param params
     */
    handlePageSelect = (page, params) => {
        if (page.selected < this.state.pageSelected) {
            params.offset -= params.max;
        } else {
            params.offset += params.max
        }

        const key = this.state;

        let { channels, startDate, endDate } = this.state.filterParams;

        if (channels.length === 0) {
            channels =  this.getChannels(this.props.dataSourceHandles, "ids");
        }

        this.populateParams(key, channels, params.offset, startDate, endDate);

        /**
         * Combine filter parameters from state with currently selected
         * start and end date and use that to filter a new set of messages.
         * @type {{}}
         */
        const filterParams = { ...this.state.filterParams, offset: params.offset };
        const queryString = objectToQueryString(filterParams);

        /**
         * This applies the query parameters from form to browser URL
         */
        this.props.history.push({
            pathname: '/inbox',
            search: `?${queryString}`
        });
    };

    getPageCount = (totalCount, max) => {
        return max ? Math.ceil(totalCount / max) : 0;
    };

    render() {

        if(isObjectEmpty(this.props.messages)) return <Loading/>;

        let { messages: { escalatedMessageCount, escalatedMessages, params }, dataSourceHandles } = this.props;
        let messages, count;

        if(_.get(this.props.messages, 'params.userAction') === ALL_MESSAGES){
            let result = processAdminMessage(this.props.messages);
            messages = result.messages;
            count = result.count;
            params = result.params;
        }

        let pageCount,
            messageCountStart,
            messageCountEnd,
            totalCount;

        if (escalatedMessageCount) {
            totalCount = escalatedMessageCount;
            pageCount = this.getPageCount(escalatedMessageCount, params.max);
            messageCountStart = params.offset + 1;
            messageCountEnd = params.offset + escalatedMessages.length;
        }

        //This is the count of messages coming from trends
        if (count) {
            totalCount = count;
            pageCount = this.getPageCount(count, params.max);
            messageCountStart = params.offset + 1;
            messageCountEnd = params.offset + messages.length
        }
        const {
            filterParams: {
                startDate,
                endDate,
                userAction: messageType
            },
            messagesFilter
        } = this.state;

        let channels, channelsIds;

        if (dataSourceHandles) {
            channels = this.getChannels(dataSourceHandles);
            channelsIds = this.getChannels(dataSourceHandles, "ids");
        }

        return (

            <div className="container-pane">
                <div className="side-bar-pane shadow-border">
                    <FilterForm
                        startDate={startDate}
                        endDate={endDate}
                        messagesFilter={messagesFilter}
                        messagesAction={this.state.key}
                        channelsFilter={channels}
                        channelsAction={this.state.filterParams.channels}
                        channelsIds={channelsIds}
                        handleChange={(event) => this.handleMessageFilterChange(event, startDate, endDate) }
                        handleChannelsFilterChange={this.handleChannelsFilterChange}
                        onSubmit={this.handleFilterFormSubmit.bind(this)}
                    />
                </div>

                <div className="container-right">
                    <div style={{marginLeft: '38px'}}>
                        <div className="numberless-pagination">
                            {messages && <MessagesTable tableBody={messages} messageType={messageType}/>}
                            {(escalatedMessages && escalatedMessages.length !== 0 ) &&
                                <div>
                                    <h4 className="font-16 color-grey-dark margin-top-0">Escalations</h4>
                                    <MessagesTable tableBody={escalatedMessages} messageType={messageType}/>
                                </div>
                            }

                            {pageCount && pageCount > 1 &&
                                <div className="align-center">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={<span>...</span>}
                                        breakClassName={'break-me'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        disableInitialCallback={true}
                                        pageRangeDisplayed={5}
                                        onPageChange={(page) => this.handlePageSelect(page, params)}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        forcePage={this.state.pageSelected}
                                    />

                                    <span className="margin-left-10 color-grey-dark">{`${messageCountStart} - ${messageCountEnd} of ${totalCount} `} </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { messages, dataSourceHandles } = state;

    return {
        loading: messages.loading,
        messages: messages.messages,
        dataSourceHandles: dataSourceHandles.dataSourceHandles
    };
}

InboxPage = connect(mapStateToProps)(InboxPage);
export { InboxPage };
