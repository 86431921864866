import {Fragment} from "react";
import { Box } from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EmbeddedConversation from "./ConversationType/EmbeddedConversation";
import {componentControl} from "../../../../../utilities/Component.utils";
import BackArrow from '@mui/icons-material/ArrowBackIosOutlined';
import ForwardArrow from '@mui/icons-material/ArrowForwardIosOutlined';
import {carouselBreakpointConfig} from "./OEmbed/EmbedUtils";

export default function ConversationCarousel({InAppContent, message, loading, isScrollComplete, usePadding}) {
    const isEnabled = !loading;

    const CustomLeftArrow = ({ onClick }) => (
        <BackArrow onClick={() => onClick()} sx={styles.backArrow}/>
    );
    const CustomRightArrow = ({ onClick }) => (
        <ForwardArrow onClick={() => onClick()} sx={styles.forwardArrow}/>
    );

    if(!isScrollComplete) return InAppContent;

    return(
        <Fragment>
            <Box sx={componentControl(isEnabled)}>
                <Carousel
                    ssr={true}
                    customLeftArrow={<CustomLeftArrow/>}
                    customRightArrow={<CustomRightArrow/>}
                    responsive={carouselBreakpointConfig}
                >
                    <InAppContent />
                    <Box sx={usePadding ? styles.p10 : {}}>
                        <EmbeddedConversation message={message}/>
                    </Box>
                </Carousel>
            </Box>
        </Fragment>
    )
}

const styles = {
    backArrow: {
        fontSize: '27px',
        position: 'absolute',
        left: '0px',
        zIndex: 1,
        display: 'inline-block',
        padding: '6px 6px 6px 3px',
        cursor: 'pointer',
        borderRadius: '100px',
        color: '#144042',
        backgroundColor: '#DCE2E3',
        '&:hover': {
            backgroundColor: '#d5e0e1',
        },
    },
    forwardArrow: {
        fontSize: '27px',
        position: 'absolute',
        right: '10px',
        zIndex: 1,
        display: 'inline-block',
        padding: '6px 4px 6px 6px',
        cursor: 'pointer',
        borderRadius: '100px',
        color: '#144042',
        backgroundColor: '#DCE2E3',
        '&:hover': {
            backgroundColor: '#d5e0e1',
        },
    },
    p10:{p: '10px'}
}