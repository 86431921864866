import {Auth} from "../components/Authorization";
import {SnackBar} from "../components/common";
import {uploadConstants, alertMessages} from "_constants";
import {uploadService} from "_services";
import _ from "lodash";

function uploadImage (formData) {
  const request = () => ({
    type: uploadConstants.UPLOAD_IMAGE_REQUEST
  });
  const success = (response) => ({
    type: uploadConstants.UPLOAD_IMAGE_SUCCESS,
    payload: { ...response }
  });
  const failure = (error) => ({
    type: uploadConstants.UPLOAD_IMAGE_FAILURE, error,
    payload: { error }
  });

  return dispatch => {
    dispatch(request());
    return uploadService.uploadImage(formData)
      .then(response => {
        dispatch(success(response.data));
      })
      .catch((error) => {
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
}

export const uploadActions = {
  uploadImage,
};