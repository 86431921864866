import {scheduleServices} from '_services'
import {alertMessages, requestOrigin, scheduleConstants} from "_constants";
import {history, setCurrentScheduledOrgs, getArrayFromObjectArray, getCurrentUser, internalAccounts} from '_helpers';
import {SnackBar} from "components/common";
import {Auth} from "../components/Authorization";
import _ from "lodash";
import { employmentActions } from '_actions/index';


function getAll(orgId, params) {
    return dispatch => {
        dispatch(request());

        scheduleServices.getAll(orgId, params)
            .then(
                schedules => dispatch(success(schedules)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return {type: scheduleConstants.GET_ALL_REQUEST}
    }

    function success(schedules) {
        return {type: scheduleConstants.GET_ALL_SUCCESS, payload: schedules}
    }

    function failure(error) {
        return {type: scheduleConstants.GET_ALL_FAILURE, payload: error}
    }
}

function getSchedule(orgId, scheduleId) {
    return dispatch => {
        dispatch(request());

        scheduleServices.getSchedule(orgId, scheduleId)
            .then(
                schedules => dispatch(success(schedules)),
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                }
            );
    };

    function request() {
        return {type: scheduleConstants.GET_SCHEDULE_REQUEST}
    }

    function success(schedule) {
        return {type: scheduleConstants.GET_SCHEDULE_SUCCESS, payload: schedule}
    }

    function failure(error) {
        return {type: scheduleConstants.GET_SCHEDULE_FAILURE, payload: error}
    }
}

function addSchedule(props) {

    return dispatch => {
        dispatch(request());

        scheduleServices.addSchedule(props)
            .then(
                schedules => {
                    if (schedules){
                        history.push(`/company/${props.organization}/schedules`);
                        dispatch(success(schedules));
                        SnackBar.showMessage(alertMessages.SCHEDULE_SUCCESS);
                    }
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SCHEDULE_FAILURE);
                }
            );
    };

    function request() {
        return {type: scheduleConstants.SUBSCRIBE_REQUEST}
    }

    function success(schedules) {
        return {type: scheduleConstants.SUBSCRIBE_SUCCESS, payload: schedules}
    }

    function failure(error) {
        return {type: scheduleConstants.SUBSCRIBE_FAILURE, payload: error}
    }
}

function deleteSchedule(props) {

    return dispatch => {
        dispatch(request());

        scheduleServices.deleteSchedule(props)
            .then(
                response => {
                    history.push(`/company/${props.orgId}/schedules`);
                    dispatch(success());
                    SnackBar.showMessage(alertMessages.SCHEDULE_DELETE_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SCHEDULE_DELETE_FAILURE);
                }
            );
    };

    function request() {
        return {type: scheduleConstants.DELETE_REQUEST}
    }

    function success() {
        return {type: scheduleConstants.DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: scheduleConstants.DELETE_FAILURE, payload: error}
    }
}

function updateSchedule(props) {

    return dispatch => {
        dispatch(request());

        scheduleServices.updateSchedule(props)
            .then(
                response => {
                    history.push(`/schedules`);
                    dispatch(success());
                    SnackBar.showMessage(alertMessages.SCHEDULE_UPDATE_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SCHEDULE_UPDATE_FAILURE);
                }
            );
    };

    function request() {
        return {type: scheduleConstants.UPDATE_REQUEST}
    }

    function success() {
        return {type: scheduleConstants.UPDATE_SUCCESS}
    }

    function failure(error) {
        return {type: scheduleConstants.UPDATE_FAILURE, payload: error}
    }
}


function getOrgWithSchedules() {

    return dispatch => {
        dispatch(request());

        scheduleServices.getOrgWithSchedules()
            .then(
                response => {
                    dispatch(success(response));
                    SnackBar.showMessage(alertMessages.ORGS_SCHEDULE_SUCCESS);
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.ORGS_SCHEDULE_FAILURE);
                }
            );
    };

    function request() {
        return {type: scheduleConstants.ORGS_SCHEDULE_REQUEST}
    }

    function success(payload) {
        return {type: scheduleConstants.ORGS_SCHEDULE_SUCCESS, payload}
    }

    function failure(error) {
        return {type: scheduleConstants.ORGS_SCHEDULE_FAILURE, payload: error}
    }
}

const getScheduledOrganizations = (params, reqOrigin = null) => {
    const request = () => ({
        type: scheduleConstants.GET_SCHEDULED_ORG_REQUEST
    });

    const success = (response) => ({
        type: scheduleConstants.GET_SCHEDULED_ORG_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: scheduleConstants.GET_SCHEDULED_ORG_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        scheduleServices.getScheduledOrganizations(params)
          .then(response => {
              dispatch(success(response.data));

              if(reqOrigin === requestOrigin.AGENT_HOME) {
                  const {id} = getCurrentUser();
                  if(!internalAccounts.includes(id)) {
                    let scheduledOrganizations = _.get(response, 'data.scheduledOrganizations');
                    let handleIdArr = getArrayFromObjectArray(scheduledOrganizations, 'handleId', true);
                    dispatch(employmentActions.getOrgWithRecentMessages(id, handleIdArr));
                  }
              }

              if(reqOrigin === requestOrigin.VOTE_INDEX){
                  setCurrentScheduledOrgs(_.get(response, 'data.scheduledOrganizations'));
              }

          }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

const saveBulkPayoutPricing = (params) => {
    const request = () => ({
        type: scheduleConstants.BULK_PAYOUT_PRICING_REQUEST
    });

    const success = (response) => ({
        type: scheduleConstants.BULK_PAYOUT_PRICING_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: scheduleConstants.BULK_PAYOUT_PRICING_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        scheduleServices.bulkPayoutPricing(params)
          .then(response => {
              dispatch(success(response));
              SnackBar.showMessage(alertMessages.BULK_SCHEDULE_UPDATE_SUCCESS);
          }).catch(error => {
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.BULK_SCHEDULE_UPDATE_FAILURE);
        })
    }
};

export const scheduleActions = {
    getAll,
    getSchedule,
    addSchedule,
    deleteSchedule,
    getOrgWithSchedules,
    updateSchedule,
    getScheduledOrganizations,
    saveBulkPayoutPricing,
};

