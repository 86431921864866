import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import { Paper, Table, TableBody } from '@material-ui/core';
import {stylesTable} from 'components/Inbox/StylesTable'
import {InboxMessageRow} from "./InboxMessageRow";


/**
 * @author Chris Okebata
 */

class MessagesTable extends Component {

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(){

        const { classes, tableBody, messageType } = this.props;

        if (tableBody.length === 0) {
            return <p className="shadow-border" style={{padding: "30px 20px"}}/>
        } else {
            return (
              <Paper className={classes.root}><Table className={classes.table}>
                  <TableBody>
                      {
                          tableBody && tableBody.map(message => {
                              return (
                                <InboxMessageRow
                                  key={message?.id}
                                  message={message}
                                  messageType={messageType}
                                />
                              )
                          })
                      }
                  </TableBody>
              </Table>
              </Paper>
            );
        }
    }
}

MessagesTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(stylesTable)(MessagesTable);
