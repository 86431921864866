 import React from "react";
 import {Stack} from "@mui/material";
 import {styles} from "../MetricsDetailsStyles";

 export const MetricsDetailFooter = () => {
     const date = new Date()

     return (
         <Stack direction={'column'} sx={{width: '100%', height: '60px'}}>
             <div style={{width: '100%', height: '60px', marginTop: '60px'}}>
                 <span style={styles.footerLogin}>&copy; {date.getFullYear()} Chatdesk</span>
             </div>
         </Stack>
     )
 }