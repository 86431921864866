export const pricingConstants = {
    GET_ALL_PRICING_REQUEST: 'GET_ALL_PRICING_REQUEST',
    GET_ALL_PRICING_SUCCESS: 'GET_ALL_PRICING_SUCCESS',
    GET_ALL_PRICING_FAILURE: 'GET_ALL_PRICING_FAILURE',

    GET_PRICING_REQUEST: 'GET_PRICING_REQUEST',
    GET_PRICING_SUCCESS: 'GET_PRICING_SUCCESS',
    GET_PRICING_FAILURE: 'GET_PRICING_FAILURE',

    CREATE_PRICING_REQUEST: 'CREATE_PRICING_REQUEST',
    CREATE_PRICING_SUCCESS: 'CREATE_PRICING_SUCCESS',
    CREATE_PRICING_FAILURE: 'CREATE_PRICING_FAILURE',

    UPDATE_PRICING_REQUEST: 'UPDATE_PRICING_REQUEST',
    UPDATE_PRICING_SUCCESS: 'UPDATE_PRICING_SUCCESS',
    UPDATE_PRICING_FAILURE: 'UPDATE_PRICING_FAILURE',

};
