import {useState, useEffect, useMemo} from 'react';
import {proactiveActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {buildGetMsgDataSourcePayload} from "../CampaignUtils";
import {MsgDataSourceBuilder} from "../objects/MsgDataSourceBuilder";

export default function (params) {
    const dispatch = useDispatch();

    useEffect(() => {
        getMsgDataSources(params);
    }, [params]);

    const getMsgDataSources = (params) => {
        if(_.has(params, 'organizationId')) {
            if(params.channel) {
                const payload = buildGetMsgDataSourcePayload(params.organizationId, params.channel)
                dispatch(proactiveActions.getMsgDataSources(payload));
            }
        }
        if(_.has(params, 'channel')) {
            if(params.organizationId) {
                const payload = buildGetMsgDataSourcePayload(params.organizationId, params.channel)
                dispatch(proactiveActions.getMsgDataSources(payload));
            }
        }
    }

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingMsgDataSources;
    const msgDataSourcesObj = proactive.msgDataSources;
    const msgDataSourceList = _.get(msgDataSourcesObj, 'items') || [];

    const msgDataSources = useMemo(() => {
        const msgDataSourceArr = [];
        if(!loading && msgDataSourceList.length > 0) {
            msgDataSourceList.map( msgDataSource => {
                const builder = new MsgDataSourceBuilder();
                builder.setId(_.get(msgDataSource, 'id') || '-');
                builder.setName(_.get(msgDataSource, 'name') || '-');
                builder.setAccountName(_.get(msgDataSource, 'accountName') || '-');

                msgDataSourceArr.push(builder.build());
            });
        }
        return msgDataSourceArr
    }, [ msgDataSourceList ]) || [];

    return {msgDataSources};
}