/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 29/01/2019 : 5:46 PM
 */

export const systemSettingsConstants = {
    SHOW_REQUEST: 'SHOW_SYSTEM_SETTINGS_REQUEST',
    SHOW_SUCCESS: 'SHOW_SYSTEM_SETTINGS_SUCCESS',
    SHOW_FAILURE: 'SHOW_SYSTEM_SETTINGS_FAILURE',

    UPDATE_REQUEST: 'UPDATE_SYSTEM_SETTINGS_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_SYSTEM_SETTINGS_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_SYSTEM_SETTINGS_FAILURE'
};