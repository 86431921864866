import {SegmentBuilder} from "../objects/SegmentBuilder";
import {segmentTypes} from "../../../../../../_constants";

export default function(segments){
    let rows = []

    if(segments && segments.length > 0) {
        segments.map( segment => {
            if(segment.type === segmentTypes.customerDataFile){
                const builder = new SegmentBuilder();
                builder.setCustomerDataFileName(_.get(segment, 'customerDataFile.name'));
                builder.setCustomerDataFileStatus(_.get(segment, 'customerDataFile.processingStatus'))
                rows.push(builder.build())
            }
        })
    }

    const columns = [
        {
            id: 'customerDataFileName',
            label: 'CSV Files',
            width: 100,
        },
        {
            id: 'customerDataFileStatus',
            label: 'Status',
            width: 50,
            minWidth: 50,
            align: 'right',
        },
    ];
    return {rows, columns};
}