import {pricingConstants} from '_constants';

const INITIAL_STATE = {
    all_pricing: [],
    pricing: {},
    loading: true,
    error: '',
};

export function pricingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case pricingConstants.GET_ALL_PRICING_REQUEST:
            return {...state, loading: true, error: ''};
        case pricingConstants.GET_ALL_PRICING_SUCCESS:
            return {...state, all_pricing: action.payload, loading: false};
        case pricingConstants.GET_ALL_PRICING_FAILURE:
            return {...state, error: action.error, loading: false};

        case pricingConstants.GET_PRICING_REQUEST:
            return {...state, loading: true, error: ''};
        case pricingConstants.GET_PRICING_SUCCESS:
            return {...state, pricing: action.payload, loading: false};
        case pricingConstants.GET_PRICING_FAILURE:
            return {...state, error: action.error, loading: false};

        case pricingConstants.CREATE_PRICING_REQUEST:
            return {...state, loading: true, error: ''};
        case pricingConstants.CREATE_PRICING_SUCCESS:
            return {...state, pricing: action.payload, loading: false};
        case pricingConstants.CREATE_PRICING_FAILURE:
            return {...state, error: action.error, loading: false};

        case pricingConstants.UPDATE_PRICING_REQUEST:
            return {...state, loading: true, error: ''};
        case pricingConstants.UPDATE_PRICING_SUCCESS:
            return {...state, pricing: action.payload, loading: false};
        case pricingConstants.UPDATE_PRICING_FAILURE:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}
