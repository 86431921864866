import React, {Component} from "react";
import {CompanyUpdatesAddForm} from "./Form/CreateForm";
import {chatdeskUpdatesActions, organizationActions, screenshotActions} from "_actions";
import {connect} from "react-redux";
import {Loading} from "components/common";
import {getCurrentUser, isObjectEmpty} from "_helpers";
import {publicAndFeaturedVisibility} from "../../_constants";

class CreateChatdeskUpdate extends Component {

    componentDidMount() {
        this.props.getAllOrganizations()
    }

    createUpdate(data){
        const currentUser = getCurrentUser()
        data.createdBy = currentUser.id
        data.isActive = true
        data.organization = data.organization.value
        this.props.saveUpdate(data)
    }

    render() {
        const {organizations, loading, savingUpdates} = this.props;
        const organizationSelectOptions = organizations && organizations.map(org => {
            return {value: org.id, label: org.name}
        })

       if (loading || isObjectEmpty(organizations) || savingUpdates) return <Loading />

        return (
            <div>
                <CompanyUpdatesAddForm
                    organizations={organizationSelectOptions}
                    createUpdate={this.createUpdate.bind(this)}
                />
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        getAllOrganizations: () => dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility})),
        saveUpdate: (data) => dispatch(chatdeskUpdatesActions.save(data))
    }
}

function mapStateToProps(state) {
    const {organizations: {loading, organizations}, chatdeskUpdates} = state;

    return {
        organizations: organizations.organizations,
        savingUpdates: chatdeskUpdates.loading
    }
}


const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CreateChatdeskUpdate);
export {connectedComponent as CreateChatdeskUpdate}