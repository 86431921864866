import logoTwitter from 'components/images/logos/logo_twitter.svg'
import useTwitterSetup from './useTwitterSetup'
import ChannelCard from '../../ChannelCard'

export default function TwitterEntry() {
	const { initiateTwitterSetup } = useTwitterSetup({
		callbackPath: '/company/connectchannels'
	})
	return (
		<ChannelCard
			icon={logoTwitter}
			label={'Twitter'}
			description={'Reply to DMs and messages on your Timeline'}
			onClick={initiateTwitterSetup}
		/>
	)
}