import {Box} from "@mui/system";
import _ from "lodash";
import {getCapitalizedWords} from "_helpers";
import useViewNextMessage from "../../Utilities/hooks/useViewNextMessage";
import LoadingPlaceholderBlock from "../../../../../common/LoadingPlaceholderBlock";
import BasicLink from "../../../../common/BasicLink";
import styles from "../../../../common/Message/Conversation/conversationStyles";
import CopyAction from "../../../../common/CopyAction";

export default function ViewInSource() {
    const { message, loading } = useViewNextMessage();
    const link = _.get(message, 'link');
    let messageSource = _.get(message, 'sourceName') || '';
    messageSource = getCapitalizedWords(messageSource);

    const EmptyState = () => {
        return(
            <LoadingPlaceholderBlock
                width={'120px'}
                height={'18px'}
                borderRadius={'3px'}
                backgroundColor={'#EFEAE5'}/>
        )
    }

    return(
        <Box sx={style.container}>
            {
                loading ? <EmptyState /> :
                    (
                        link &&
                        <Box sx={style.content}>
                            <BasicLink label={`View in ${messageSource || 'source'}`} link={link} openInNewTab/>
                            <Box sx={style.copyIconContainer}>
                                <CopyAction
                                    sx={styles.copyIcon}
                                    textValue={link}
                                    tooltipLabel={'Copy link'}
                                    toastLabel={'Link copied to clipboard'} />
                            </Box>
                        </Box>
                    )
            }
        </Box>
    )
}

const style = {
    container: {
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        display: 'flex'
    },
    copyIconContainer: {
        margin: '-1px 0 0 7px'
    },
}