import React, { Component } from 'react';
import { getCurrentUser } from '_helpers';
import { reamazeAuthActions } from '_actions';
import connect from 'react-redux/es/connect/connect';
import { Field, reduxForm } from 'redux-form';
import { TextField, InputGroup } from 'components/common';

const inputWidth = {
    width: '60%',
    minWidth: 300,
};
class ReamazeConnect extends Component {

    onSubmit(props) {
        const { id } = getCurrentUser();
        const { dispatch } = this.props;
        const params = { ...props, userId: id };
        dispatch(reamazeAuthActions.verifyKey(params));
    }

    render() {
        const { handleSubmit, valid, submitting } = this.props;

        return (
            <div className="main-content-wrapper">
                <div className="content-pane-60 margin-0-auto">
                    <div className="padding-20-0">
                        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                            <div>
                                <h1 className="msg-source-submit-header margin-bottom-30">Connect your Reamaze Account</h1>
                                <div style={inputWidth}>
                                    <Field
                                        component={TextField}
                                        label="Reamaze Login Email Address"
                                        placeHolder="e.g. support@companyname.com"
                                        addonClassName="background-white"
                                        className="font-16 width-60"
                                        name="loginEmail"
                                    />

                                    <Field
                                        component={InputGroup}
                                        label="Reamaze Subdomain"
                                        placeHolder="e.g. companyname"
                                        addonText=".reamaze.com"
                                        addonClassName="background-white"
                                        className="font-16 width-60"
                                        name="subdomain"
                                    />

                                    <Field
                                        component={TextField}
                                        label="API Key"
                                        placeHolder="e.g ABCDEFGHI23456789"
                                        className="font-16 width-60"
                                        name="apiKey"
                                    />
                                </div>
                                <button type="submit" className="btn btn-success font-16 msg-source-submit-button" disabled={! valid || submitting}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function validate(values) {
    const errors = {};

    if (!values.subdomain) {
        errors.subdomain = 'Field cannot be null!!!';
    }

    if (!values.apiKey) {
        errors.apiKey = 'Field cannot be null!!!';
    }

    if (!values.loginEmail) {
        errors.loginEmail = 'Field cannot be null!!!;'
    }

    return errors;
}

const formConfig = {
    form: 'reamazeForm',
    validate,
    touchOnBlur: false,
};

const mapStateToProps = (state) => {
    const {reamazeAuth: {data: {webhookUrl}, showWebhookUrl}, user} = state;

    return {
        webhookUrl,
        showWebhookUrl,
        user,
    }
};

const reamazeConnect = connect(mapStateToProps)(ReamazeConnect);

export default reduxForm(formConfig)(reamazeConnect);
