import React, { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import {formatNumber} from "./helpers/metrics.helper";

/**
 * @typedef {{ value: number; formatStyle: 'percent' | 'number' | 'currency' | 'minutes' }} Entry
 */

/**
 * @param {Entry} params
 */
export const formatEntry = ({ value, formatStyle = 'number' }) => {
	if (!value || typeof value !== 'number')
		return value;

	switch (formatStyle) {
		case 'minutes':
			return `${value.toLocaleString('en-US')} minutes`;
		case 'percent':
			return value.toLocaleString('en-US', { style: 'percent' });
		case 'currency':
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
		case 'number': 
		default:
			return value.toLocaleString('en-US');
	}
}

/**
 * 
 * @param {object} params
 * @param {string} params.label
 * @param {Entry} params.entry – Data entry
 * @param {React.ReactNode} params.descriptionChildren
 * @param {React.ReactNode} params.stackStyle - (optional) style for the stack container
 */
export default function DataTeaser({
	label,
	entry,
	descriptionChildren,
	stackStyle= {},
}) {
	const formattedValue = useMemo(() => {
		return formatNumber(formatEntry(entry));
	}, [entry])

	return (
		<Stack sx={stackStyle}>
			<Box component={'h4'} sx={styles.label}>{label}</Box>
			<Box component={'h3'} sx={styles.value}>{formattedValue}</Box>
			{descriptionChildren && (
				<Box>
					{descriptionChildren}
				</Box>
			)}
		</Stack>
	)
}

const styles = {
	label: {
		m: 0,
		fontSize: '14px',
		fontFamily: 'LarsseitBold',
		letterSpacing: '0.05em',
		textTransform: 'uppercase',
	},
	value: {
		mt: { xs: 1, md: 1.5 },
		mb: { xs: 0, md: 2.5 },
		fontSize: { xs: '40px', md: '48px' },
	}
}