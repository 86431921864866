export const msgDataSourceConstants = {
    GET_ALL_REQUEST: 'GET_ALL_MSG_DATA_SOURCES_REQUEST',
    GET_ALL_SUCCESS: 'GET_ALL_MSG_DATA_SOURCES_SUCCESS',
    GET_ALL_FAILURE: 'GET_ALL_MSG_DATA_SOURCES_FAILURE',

    GET_ALL_FOR_ORGANIZATION_REQUEST: 'GET_ALL_MSG_DATA_SOURCES_FOR_ORGANIZATION_REQUEST',
    GET_ALL_FOR_ORGANIZATION_SUCCESS: 'GET_ALL_MSG_DATA_SOURCES_FOR_ORGANIZATION_SUCCESS',
    GET_ALL_FOR_ORGANIZATION_FAILURE: 'GET_ALL_MSG_DATA_SOURCES_FOR_ORGANIZATION_FAILURE',

    GET_DETAILS_REQUEST: 'GET_MSG_DATA_SOURCE_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_MSG_DATA_SOURCE_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_MSG_DATA_SOURCE_DETAILS_FAILURE',

    UPDATE_DETAILS_REQUEST: 'UPDATE_MSG_DATA_SOURCE_DETAILS_REQUEST',
    UPDATE_DETAILS_SUCCESS: 'UPDATE_MSG_DATA_SOURCE_DETAILS_SUCCESS',
    UPDATE_DETAILS_FAILURE: 'UPDATE_MSG_DATA_SOURCE_DETAILS_FAILURE',

    DELETE_REQUEST: 'DELETE_MSG_DATA_SOURCE_DETAILS_REQUEST',
    DELETE_SUCCESS: 'DELETE_MSG_DATA_SOURCE_DETAILS_SUCCESS',
    DELETE_FAILURE: 'DELETE_MSG_DATA_SOURCE_DETAILS_FAILURE',
    GET_MSG_DATA_SOURCES_FOR_MSG_SOURCE_SUCCESS: "GET_MSG_DATA_SOURCES_FOR_MSG_SOURCE_SUCCESS",
    GET_MSG_DATA_SOURCES_FOR_MSG_SOURCE_REQUEST: "GET_MSG_DATA_SOURCES_FOR_MSG_SOURCE_REQUEST",
    GET_MSG_DATA_SOURCES_FOR_MSG_SOURCE_FAILURE: "GET_MSG_DATA_SOURCES_FOR_MSG_SOURCE_FAILURE",

    GET_ALL_DATA_SOURCE: 'GET_ALL_DATA_SOURCE',
    ALL_DATA_SOURCE_SUCCESS: 'ALL_DATA_SOURCE_SUCCESS',
    ALL_DATA_SOURCE_ERROR: 'ALL_DATA_SOURCE_ERROR',

    TOGGLE_ACTION_MSGDATASOURCE_MESSAGES: 'TOGGLE_ACTION_MSGDATASOURCE_MESSAGES',
    TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_SUCCESS: 'TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_SUCCESS',
    TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_ERROR: 'TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_ERROR',

    GET_FRESHDESK_DATA_SOURCE: 'GET_FRESHDESK_DATA_SOURCE',
    FRESHDESK_DATA_SOURCE_SUCCESS: 'FRESHDESK_DATA_SOURCE_SUCCESS',
    FRESHDESK_DATA_SOURCE_ERROR: 'FRESHDESK_DATA_SOURCE_ERROR',

    SAVE_MSG_DATA_SOURCE: 'SAVE_MSG_DATA_SOURCE',
    MSG_DATA_SOURCE_SAVE_SUCCESS: 'MSG_DATA_SOURCE_SAVE_SUCCESS',
    MSG_DATA_SOURCE_SAVE_ERROR: 'MSG_DATA_SOURCE_SAVE_ERROR',

    MSG_DATA_SOURCE_INBOXES_REQUEST: 'MSG_DATA_SOURCE_INBOXES_REQUEST',
    MSG_DATA_SOURCE_INBOXES_SUCCESS: 'MSG_DATA_SOURCE_INBOXES_SUCCESS',
    MSG_DATA_SOURCE_INBOXES_FAILURE: 'MSG_DATA_SOURCE_INBOXES_FAILURE',

    RESET_INBOX_DATA: 'RESET_INBOX_DATA',

    TICKET_STATUS_UPDATE_REQUEST: 'TICKET_STATUS_UPDATE_REQUEST',
    TICKET_STATUS_UPDATE_SUCCESS: 'TICKET_STATUS_UPDATE_SUCCESS',
    TICKET_STATUS_UPDATE_FAILURE: 'TICKET_STATUS_UPDATE_FAILURE',

    ORGANIZATIONS_FOR_MSGSOURCE_REQUEST: 'ORGANIZATIONS_FOR_MSGSOURCE_REQUEST',
    ORGANIZATIONS_FOR_MSGSOURCE_SUCCESS: 'ORGANIZATIONS_FOR_MSGSOURCE_SUCCESS',
    ORGANIZATIONS_FOR_MSGSOURCE_FAILURE: 'ORGANIZATIONS_FOR_MSGSOURCE_FAILURE',

    ORGANIZATION_VIEWS_REQUEST: 'ORGANIZATION_VIEWS_REQUEST',
    ORGANIZATION_VIEWS_SUCCESS: 'ORGANIZATION_VIEWS_SUCCESS',
    ORGANIZATION_VIEWS_FAILURE: 'ORGANIZATION_VIEWS_FAILURE',

    VIEW_TICKETS_REQUEST: 'VIEW_TICKETS_REQUEST',
    VIEW_TICKETS_SUCCESS: 'VIEW_TICKETS_SUCCESS',
    VIEW_TICKETS_FAILURE: 'VIEW_TICKETS_FAILURE',

    MSG_DATA_SOURCE_ZENDESK_MACRO_SUCCESS: 'MSG_DATA_SOURCE_ZENDESK_MACRO_SUCCESS',
    MSG_DATA_SOURCE_ZENDESK_MACRO_FAILURE: 'MSG_DATA_SOURCE_ZENDESK_MACRO_FAILURE',

    PLATFORM_MACROS_SELECTION_REQUEST: 'PLATFORM_MACROS_SELECTION_REQUEST',
    PLATFORM_MACROS_SELECTION_SUCCESS: 'PLATFORM_MACROS_SELECTION_SUCCESS',
    PLATFORM_MACROS_SELECTION_FAILURE: 'PLATFORM_MACROS_SELECTION_FAILURE',

    PLATFORM_MACROS_LIST_REQUEST: 'PLATFORM_MACROS_LIST_REQUEST',
    PLATFORM_MACROS_LIST_SUCCESS: 'PLATFORM_MACROS_LIST_SUCCESS',
    PLATFORM_MACROS_LIST_FAILURE: 'PLATFORM_MACROS_LIST_FAILURE',

    PLATFORM_SAVE_MACROS_REQUEST: 'PLATFORM_SAVE_MACROS_REQUEST',
    PLATFORM_SAVE_MACROS_SUCCESS: 'PLATFORM_SAVE_MACROS_SUCCESS',
    PLATFORM_SAVE_MACROS_FAILURE: 'PLATFORM_SAVE_MACROS_FAILURE',

    PLATFORM_REMOVE_MACROS_REQUEST: 'PLATFORM_REMOVE_MACROS_REQUEST',
    PLATFORM_REMOVE_MACROS_SUCCESS: 'PLATFORM_REMOVE_MACROS_SUCCESS',
    PLATFORM_REMOVE_MACROS_FAILURE: 'PLATFORM_REMOVE_MACROS_FAILURE',

    PLATFORM_UPDATE_MACROS_REQUEST: 'PLATFORM_UPDATE_MACROS_REQUEST',
    PLATFORM_UPDATE_MACROS_SUCCESS: 'PLATFORM_UPDATE_MACROS_SUCCESS',
    PLATFORM_UPDATE_MACROS_FAILURE: 'PLATFORM_UPDATE_MACROS_FAILURE',

    PLATFORM_CLEAR_MACROS_SUCCESS: 'PLATFORM_CLEAR_MACROS_SUCCESS',

    UPDATE_GORGIAS_MSG_DATA_SOURCE_REQUEST: 'UPDATE_GORGIAS_MSG_DATA_SOURCE_REQUEST',
    UPDATE_GORGIAS_MSG_DATA_SOURCE_SUCCESS: 'UPDATE_GORGIAS_MSG_DATA_SOURCE_SUCCESS',
    UPDATE_GORGIAS_MSG_DATA_SOURCE_FAILURE: 'UPDATE_GORGIAS_MSG_DATA_SOURCE_FAILURE',

    UPDATE_KUSTOMER_MSG_DATA_SOURCE_REQUEST: 'UPDATE_KUSTOMER_MSG_DATA_SOURCE_REQUEST',
    UPDATE_KUSTOMER_MSG_DATA_SOURCE_SUCCESS: 'UPDATE_KUSTOMER_MSG_DATA_SOURCE_SUCCESS',
    UPDATE_KUSTOMER_MSG_DATA_SOURCE_FAILURE: 'UPDATE_KUSTOMER_MSG_DATA_SOURCE_FAILURE',
};