export class SegmentTypeBuilder {
    constructor() {
        this.segmentType = {};
    }

    setId(id){
        this.segmentType.id = id;
        return this;
    }

    setName(name){
        this.segmentType.name = name;
        return this;
    }

    build(){
        return this.segmentType;
    }
}
