import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import spinner from '../images/spinner.gif'
import { authActions, utilityActions, alertActions } from "_actions";
import {CopyrightFooter, DropDownSelect, SnackBar} from "components/common";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import chatdeskLogo from "components/images/chatdesk-logo-dark.svg";
import {isValidEmail, required, fieldsMatch, isValidNumberForCountries, isVerticalSelected} from "_helpers";
import {regFormContainer} from 'components/RegisterPage'
import {alertMessages} from "../../_constants";



const validate = (values) => {
    const errors = {};
    const {phoneNumber, password, confirmPassword, username} = values;
    const validCountries = ['US', 'AU', 'GB', 'CA']
    if (!fieldsMatch(password, confirmPassword)){
        errors.confirmPassword = "passwords don't match"
    }

    if (!isValidNumberForCountries(validCountries, phoneNumber)){
        errors.phoneNumber = `Please enter a US, AU, UK or CA phone number`
    }

    if (!isValidEmail(username)){
        errors.username = `invalid email`
    }
    return errors
};

const InputField = ({ input, label, type, meta: {touched, error, warning} }) => {
    return (
        <div>
            <div style={regFormContainer.error} className="font-12 color-danger">
                {touched &&
                ((error && <span>{error}</span>) ||
                    (warning && <span>{warning}</span>))}
            </div>
            <input {...input} type={type}
                   className="input-field"
                   required/>
            <label htmlFor={label}><span>{label}</span></label>
        </div>
    );
};

const Checkbox = ({ input, meta, label, type, checked }) => {
    return (
        <div>
            <input type={type}
                   className="tms-checkbox"
                   {...input}
                   checked={checked}
                   autoComplete="off"/>
            <span className="tms-check-mark">
            </span>
        </div>
    );
};


class RegisterPage extends Component {

    constructor(props) {
        super(props);
        this.props.dispatch(authActions.logout('/register'));
    }

    onSubmit(props) {
        const {vertical} = props;
        if(!isVerticalSelected(vertical)){
            SnackBar.showMessage(alertMessages.NO_VERTICAL_ERROR);
            return
        }
        const { dispatch } = this.props;
        const emailAddress = props.username;
        const pattern = '@(hotmail)|(yahoo)|(ymail)|(outlook)|(gmail)|(aol)|(live)';
        if (emailAddress.match(pattern)){
            dispatch(alertActions.error("Please enter your work email address"));
        } else {
            dispatch(authActions.register(props));
        }
    }

    componentDidMount() {
        this.props.dispatch(utilityActions.getVerticals())
    }

    render() {
        let verticals = this.props.verticals.data.verticalsList;
        if (verticals) {
            verticals = verticals.map((vertical) => {
                return { 'id': vertical.id, 'name': vertical.name }
            })
        }

        const { handleSubmit, registering } = this.props;
        return (
            <div className="tms-sign-up-body">
                <div className="margin-top-50 logo-pos-sign-up">
                    <img src={chatdeskLogo} alt="Chatdesk logo" className="logo"/>
                </div>
                <div className="tms-signup-wrapper">
                    <div className="tms-signup-container">
                        <div className="tms-signup-title-container">
                            <span className="font-22-heavy color-grey-dark">Create your free account</span>
                        </div>
                        <form name="form" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                            <div className="tms-signup-form-content">
                                <div className="tms-signup-form-section-1">
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="firstName"
                                            label="First Name"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="lastName"
                                            label="Last Name"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div class="tms-phoneLabel" style={regFormContainer.inputField}>
                                        <Field
                                            name="phoneNumber"
                                            label="Phone Number"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="password"
                                            label="Password"
                                            component={InputField}
                                            validate={required}
                                            type="password"
                                        />
                                    </div>
                                    <div style={regFormContainer.password}>
                                        <Field
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            component={InputField}
                                            validate={required}
                                            type="password"
                                        />
                                    </div>
                                </div>
                                <div className="tms-signup-form-section-2">
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="username"
                                            label="Email"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="company"
                                            label="Company Name"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <div style={{ ...regFormContainer.width83, margin: "17px auto 0 auto" }}>
                                            <Field
                                                style={{ background: "#fff", cursor: "pointer" }}
                                                className="select-field"
                                                name="vertical"
                                                label="Industry"
                                                component={DropDownSelect}
                                                type="text"
                                                options={verticals}
                                                placeHolder={'Select Industry'}
                                            />
                                        </div>
                                    </div>
                                    <div style={regFormContainer.checkbox} className="tms-signup-subscribe">
                                        <div style={{...regFormContainer.width83, display:"flex"}}>
                                            <div style={regFormContainer.checkboxSubscribe}>
                                                <Field
                                                    name="subscribeToCompanyInfo"
                                                    component={Checkbox}
                                                    type="checkbox"
                                                />
                                            </div>

                                            <div style={regFormContainer.subscribeText}
                                                 className="font-13-bold color-grey-dark">
                                                Subscribe to receive industry-leading content, product updates, and company
                                                news.
                                            </div>
                                        </div>
                                    </div>
                                    <div style={regFormContainer.checkbox} className="tms-signup-privacy">
                                        <div style={{...regFormContainer.width83, display:"flex"}}>
                                            <div style={regFormContainer.checkboxAccept}>
                                                <Field
                                                    name="acceptTerms"
                                                    component={Checkbox}
                                                    type="checkbox"
                                                    checked="checked"
                                                />
                                            </div>
                                            <div style={regFormContainer.acceptText}
                                                 className="font-13-bold color-grey-dark">
                                                By signing up, I agree to Chatdesk's <a
                                                href="https://chatdesk.com/terms.html"
                                                    rel="noopener noreferrer" target="_blank"
                                                    style={{ color: "#13a0ba" }}>
                                                    Terms and Conditions
                                                </a> & <a
                                                href="https://chatdesk.com/privacy.html"
                                                rel="noopener noreferrer" target="_blank"
                                                style={{ color: "#13a0ba" }}>Privacy Policy.</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tms-signup-button-wrapper">
                                <div className="tms-signup-button-container">
                                    <div className="tms-signup-link-login">
                                        <span className="">
                                            <Link to="/" className="btn btn-link color-grey-dark"
                                                  style={{ lineHeight: "0", padding: "0" }}>Login</Link>
                                        </span>
                                    </div>
                                    <div className="tms-signup-button-holder">
                                        <button className="btn btn-block btn-success font-15" type="submit">
                                            Signup
                                            {registering && <img alt='loading' src={spinner}/>}
                                        </button>
                                     </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <CopyrightFooter/>
            </div>
        );
    }
}

const  mapStateToProps = ({registering, utility}) => {

    return {
        registering: registering,
        verticals: utility
    };
};

const formConfig = {
    form: 'RegistrationForm',
    initialValues: { isCompanyAdmin: true, terms: true },
    validate
};


RegisterPage = connect(
    mapStateToProps,
    null
)(RegisterPage);


export default reduxForm(formConfig)(RegisterPage);
