import React from "react";
import {Box} from "@mui/system";
import {useSelector} from "react-redux";
import _ from "lodash";
import styles from "../../../voteMainStyles";
import TextButton from "../../../../../common/TextButton";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import SearchedMessageTable from "./SearchedMessageTable";
import SearchResultContainerEmptyState from "../../../EmptyStates/SearchResultContainerEmptyState";
import AddedResponseCount from "./AddedResponseCount";
import ChannelsFilterEntry from "../../../../../common/Filters/ChannelsFilter/ChannelsFilterEntry";
import voteViews from "../../../Utilities/voteViews";

export default function SearchResultContainer({ handleBackToResponses, handleSearchedConversationViewToggle }) {
    const loadingSearch = useSelector(state => state.search.loading);
    const message = useSelector(state => state.messages.nextMessage) || {};
    const isProactive = _.get(message, 'isProactive');

    const handleBackButton = () => {
        handleBackToResponses(voteViews.response);
    }

    if(loadingSearch) {
        return (
            <SearchResultContainerEmptyState />
        )
    }

    return(
        <Box sx={styles.searchResultPanelRoot}>
            <Box sx={styles.searchResultHeading}>
                <Box sx={style.backButtonContainer}>
                    <TextButton
                        label={'Back to responses'}
                        action={handleBackButton}
                        sx={style.backButton}
                        rootStyle={style.backButtonRoot}
                        startIcon={<ChevronLeftOutlinedIcon sx={style.backButtonIcon}/>}
                    />
                </Box>
                <Box sx={{display: 'flex'}}>
                    {
                        !isProactive &&
                        <Box sx={style.addedResponseCountContainer}>
                            <AddedResponseCount />
                        </Box>
                    }
                    <Box sx={style.filterContainer}>
                        <ChannelsFilterEntry />
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.searchResultPanelContent}>
                <Box sx={styles.searchResultScrollArea}>
                    <SearchedMessageTable handleSearchedConversationViewToggle={handleSearchedConversationViewToggle} />
                </Box>
            </Box>
        </Box>
    )
}

const style = {
    backButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
    },
    backButtonRoot: {
        fontSize: '14px',
        height: '20px',
        color: '#0C2728',
        textAlign: 'left',
        fontFamily: 'LarsseitMedium',
        ':hover': {
            color: '#081a1a',
            backgroundColor: 'unset'
        },
        ':disabled': {
            color: '#8c8f8f',
        },
        ':active': {
            outline: '0',
            color: '#0C2728',
        },
    },
    addedResponseCountContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    backButtonIcon: {
        mr: '-5px'
    },
    filterContainer: {
        width: '131px',
        marginLeft: '12px',
        // border: '1px solid'
    }
}