import React, {Component} from 'react';
import { msgDataSourceActions } from '_actions';
import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {SnackBar} from "components/common";
import {alertMessages} from "_constants";

const contentStyles = {
    width: '50%',
    padding: '20px 40px',
    minHeight: '40vh'
};

class GorgiasListOrganizations extends Component {

    state = {
        selectedOrganization: '',
        platform: 'gorgias'
    };

    componentDidMount() {
        const { dispatch } = this.props;
         const { platform } = this.state;
        dispatch(msgDataSourceActions.getOrganizationsForMsgSource(platform));
    }

    handleOrgSelection = (e) => {
        this.setState({ selectedOrganization: e.target.value })
    };

    getOrgViews = () => {
        const { selectedOrganization, platform } = this.state;
        const { history } = this.props;
        if (!selectedOrganization) {
            SnackBar.showMessage(alertMessages.SELECT_AN_ORGANIZATION)
            return
        }
        history.push(`/open-tickets-views?org=${selectedOrganization}&msgSource=${platform}`);
    };

    render() {
        const { msgDataSourceOrgList } = this.props;
        const { selectedOrganization } = this.state;

        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <div className="row col-md-4 background-white" style={contentStyles}>
                    <select className="form-control" value={selectedOrganization} onChange={this.handleOrgSelection}>
                        <option value="" hidden>Select Organization</option>
                        {msgDataSourceOrgList && msgDataSourceOrgList.length > 0 && msgDataSourceOrgList.map((item) => (
                            <option key={item.orgId} value={item.orgId}>{item.orgName}</option>
                        ))}
                    </select>
                    <Button bsStyle="success" className="margin-top-15" type="submit" onClick={this.getOrgViews}>
                        Get Views
                    </Button>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { msgDataSources: { msgDataSourceOrgList }} = state;
    return {
        msgDataSourceOrgList
    };
}

const connectedListGorgiasDataSources = connect(mapStateToProps)(GorgiasListOrganizations);
export { connectedListGorgiasDataSources as GorgiasListOrganizations };
