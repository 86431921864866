import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { DropDownSelect, InputGroupButton } from 'components/common';

const AgentPayoutHistoryList = (props) => {
  const {
    agents, openModal, handlePageSelect, params, handleBtnSubmit,
    total, input, search, sortResult, sortOrder,
  } = props;
  let page = 0;
  let pageCount = 0;
  if (params) {
    const offset = parseInt(params.parameterMap.offset, 10);
    const max = parseInt(params.parameterMap.max, 10);
    pageCount = Math.ceil(parseInt(total, 10) / max);
    page = offset / max;
  }

  const sortResultsList = [
    { id: 'name', name: 'Sort By Name' },
    { id: 'current_balance', name: 'Sort By Current Balance' },
    { id: 'lifetime_earnings', name: 'Sort By Lifetime Earnings' },
  ];

  const sortTypeList = [
    { id: 'asc', name: 'Ascending' },
    { id: 'desc', name: 'Decending' },
  ];

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-3">
          <h4>AGENT PAYOUTS</h4>
        </div>
        <form onSubmit={handleBtnSubmit}>
          <div className="col-md-3">
            <InputGroupButton
              name="search"
              type="text"
              style={{ left: 0 }}
              placeHolder="Search Name..."
              handleBtnSubmit={handleBtnSubmit}
              btnAddonText={<i className="fa fa-search" />}
              input={{ ...input, value: search }}
              meta={{ error: '', warning: '' }}
            />
          </div>
          <div className="col-md-3">
            <DropDownSelect
              name="sortResult"
              type="text"
              placeHolder="Sort Results"
              input={{ ...input, value: sortResult}}
              meta={{ error: '', warning: '' }}
              options={sortResultsList}
            />
          </div>
          <div className="col-md-3">
            <DropDownSelect
              name="sortOrder"
              type="text"
              placeHolder="Select Sort Order"
              input={{ ...input, value: sortOrder }}
              meta={{ error: '', warning: '' }}
              options={sortTypeList}
            />
          </div>
        </form>
      </div>
      {(!agents || agents.length === 0)
                && (
                <div className="text-center row" style={{ margin: '10px auto' }}>
                  {search
                        && (
                        <h3>
                          <small>There are no Agent Data available for search: </small>
                          <br />
                          <strong>{search}</strong>
                        </h3>
                        )
                    }
                  {!search
                        && <h3>There are no Agent Data available</h3>
                    }
                </div>
                )
            }
      {agents && agents.length > 0
                && (
                <Fragment>
                  <Table striped bordered hover className="agent-payout-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Current Balance</th>
                        <th>Lifetime Earning</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {agents.map((item, index) => (
                        <tr key={index}>
                          <td>{`${item.agentFirstName} ${item.agentLastName}`}</td>
                          <td>{item.agentUserName}</td>
                          <td>{item.balance}</td>
                          <td>{item.lifeTimeEarning}</td>
                          <td>
                            <div style={{width: '210px'}}>
                              <Link to={`/agents/payouts/${item.agentId}`} className="btn btn-info btn-sm">
                                View History
                              </Link>
                              <button
                                  type="button"
                                  className="btn btn-info btn-sm"
                                  style={{ marginLeft: 15 }}
                                  onClick={() => openModal(item)}
                              >
                                Payout Agent
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {agents && total > 10
                            && (
                            <tfoot>
                              <tr>
                                <td colSpan={5}>
                                  <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={<span>...</span>}
                                    breakClassName="break-me"
                                    pageCount={pageCount}
                                    marginPagesDisplayed={3}
                                    disableInitialCallback
                                    pageRangeDisplayed={3}
                                    forcePage={page}
                                    onPageChange={(page) => handlePageSelect(page, params)}
                                    containerClassName="pagination"
                                    subContainerClassName="pages pagination"
                                    activeClassName="active"
                                  />
                                </td>
                              </tr>
                            </tfoot>
                            )
                        }
                  </Table>
                </Fragment>
                )
            }
    </div>
  );
};

function mapStateToProps(state) {
  const {
    wallet: {
      payoutHistory, agentDetails, agents, loading,
    },
  } = state;
  return {
    payoutHistory,
    agentDetails,
    params: agents.params,
    total: agents.count,
    loading,
  };
}

const connectedHistory = connect(mapStateToProps)(AgentPayoutHistoryList);
export { connectedHistory as AgentPayoutHistoryList };
