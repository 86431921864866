
export const formStyles = {
    input: {
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        borderRadius: '8px',
        backgroundColor: '#fff',
        color: '#144042 !important',
        border: '1.5px solid #9EA9A9 !important',
        zIndex: '10',

        '*': {
            fontFamily: 'LarsseitRegular',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500 !important',
            backgroundColor: 'transparent',
            border: 'none!important',
            outline: 'none!important',
            cursor: 'pointer',
            '::placeholder': {
                color: '#144042',
            }
        },

        '.react-bootstrap-daterangepicker-container': {
            width: '100%',

            'input': {
                color: '#fff',
                paddingLeft: '12px',
                paddingRight: '8px',
                width: '195px',
            }
        }
    },
    inputIcon: {
        fontSize: '26px!important',
        flexShrink: 0,
        color: '#144042',
        mr: 2,
        ml: 'auto',
        pointerEvents: 'none',
        marginLeft: '-5px',
    },
    '.daterangepicker.opensright:before': {
        left: '20px !important'
    }
}