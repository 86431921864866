import { responseOptionConstants } from '_constants';

const INITIAL_STATE = {
  responseOptions: [],
  addedResponseOption: [],
  loadingAddResponseOption: false,
  responseMessageId: '',
  addedCount: 0,
  error: '',

  userAddedResponseOptions: {},
  loadingUserAddedResponseOptions: false,
  userAddedResponseOptionsError: ''
};

export function responseOptionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case responseOptionConstants.LIST_RESPONSE_OPTIONS_REQUEST:
      return { ...state, loading: true, error: '' };
    case responseOptionConstants.LIST_RESPONSE_OPTIONS_SUCCESS:
      return { ...state, responseOptions: action.payload, loading: false };
    case responseOptionConstants.LIST_RESPONSE_OPTIONS_FAILURE:
      return { ...state, error: action.error, loading: false };

    case responseOptionConstants.ADD_RESPONSE_OPTION_REQUEST:
      return { ...state, loadingAddResponseOption: true, error: '', responseMessageId: action.responseMessageId };
    case responseOptionConstants.ADD_RESPONSE_OPTION_SUCCESS:
      return { ...state, addedResponseOption: [...state.addedResponseOption, action.payload], addedCount: action.payload.addedCount , loadingAddResponseOption: false };
    case responseOptionConstants.ADD_RESPONSE_OPTION_FAILURE:
      return { ...state, error: action.error, loadingAddResponseOption: false };

    case responseOptionConstants.GET_USER_ADDED_RESPONSE_OPTIONS_REQUEST:
      return { ...state, userAddedResponseOptionsError: '', loadingUserAddedResponseOptions: true };
    case responseOptionConstants.GET_USER_ADDED_RESPONSE_OPTIONS_SUCCESS:
      return { ...state, userAddedResponseOptions: action.payload , loadingUserAddedResponseOptions: false };
    case responseOptionConstants.GET_USER_ADDED_RESPONSE_OPTIONS_FAILURE:
      return { ...state, userAddedResponseOptionsError: action.error, loadingUserAddedResponseOptions: false };

    case responseOptionConstants.CLEAR_ADDED_RESPONSE_OPTION:
      return { ...state, addedResponseOption: action.payload, responseMessageId: '', addedCount: 0, userAddedResponseOptions: {} };

    default:
      return state;
  }
}
