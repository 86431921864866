import React, {Fragment, Component} from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import {filterObjectValue, isObjectEmpty} from "../../_helpers";

/**
 * @author Chris Okebata
 */

class MessageActionDetailV2 extends Component {


    render() {

        const { message, paidMessages, loadingPaidMessage, conversation, loadingConversation, originalMessage } = this.props;
        const paidMessageActions = _.get(paidMessages, 'paidMessageActions');
        const isActioned = _.get(originalMessage, 'actioned');
        const isAutoEscalated = _.get(originalMessage, 'isAutoEscalated');
        let messageAction;

        if((isObjectEmpty(paidMessageActions) && loadingPaidMessage) || loadingConversation){
            return(
                <span>
            Loading...
          </span>
            )
        }
        if(isObjectEmpty(paidMessageActions) && !loadingPaidMessage && isActioned && isAutoEscalated){
            return 'Actioned by Auto Escalator';
        }
        if(isObjectEmpty(paidMessageActions) && !loadingPaidMessage){
            return '';
        }

        if(paidMessageActions.length > 0){
            const trendsConversation = originalMessage && _.get(originalMessage, 'conversation');
            const dataSourceHandle = message && _.get(message, 'dataSourceHandle');
            const displayInApp = dataSourceHandle && _.get(dataSourceHandle, 'displayInApp');
            if((conversation.length > 0 && displayInApp !== false) || !isObjectEmpty(trendsConversation)){
                messageAction = filterObjectValue(paidMessageActions || '', 'trendsMessageId', _.get(message, 'id'));
            } else {
                messageAction = filterObjectValue(paidMessageActions || '', 'messageId', _.get(message, 'id'));
            }
        }

        const hasData = messageAction.length > 0;
        const { firstName, lastName, actionTaken } = messageAction[0] || {};

        return(
            <Fragment>
                {
                    hasData &&
                    <span>
              {`Actioned by ${firstName || 'User'} ${lastName || ''} - ${actionTaken || ''}`}
            </span>
                }
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { messages: { paidMessages, loadingPaidMessage, message }, conversation: {conversation, loading}} = state;
    return {
        paidMessages,
        originalMessage: message,
        loadingPaidMessage,
        conversation,
        loadingConversation: loading
    };
}

const connectedMessageActionDetailV2 = connect(mapStateToProps)(MessageActionDetailV2);
export { connectedMessageActionDetailV2 as MessageActionDetailV2 };
