import React, {Fragment} from "react";
import {CLIENT_URL} from "config";
import GorgiasSetupModalForm from "./GorgiasSetupModalForm";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";

export default function GorgiasSetupModal({
	open,
	handleClose,
	handleOpen
}){
  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Gorgias'}>

        <GorgiasSetupModalForm
          description={'Provide your subdomain from Gorgias'}
          buttonLabel={'Submit'}
          channelDomain={'.gorgias.com'}
          extraInfoLabel={'View setup instructions'}
          openInNewTab={true}
          handleClose={handleClose}
          callbackUrl={`${CLIENT_URL}/company/connectchannels`} />
      </BasicModal>
    </Fragment>
  )
}