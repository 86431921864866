/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 13/09/2018 : 11:08 PM
 */

export const statsConstants = {
  GET_DATA_REQUEST: 'STATS_GET_DATA_REQUEST',
  GET_DATA_SUCCESS: 'STATS_GET_DATA_SUCCESS',
  GET_DATA_FAILURE: 'STATS_GET_DATA_FAILURE',

  GET_ANALYTICS_DATA_REQUEST: 'GET_ANALYTICS_DATA_REQUEST',
  GET_ANALYTICS_DATA_SUCCESS: 'GET_ANALYTICS_DATA_SUCCESS',
  GET_ANALYTICS_DATA_FAILURE: 'GET_ANALYTICS_DATA_FAILURE',

  GET_SIMILARITY_STATS_REQUEST: "SIMILARITY_STATS_GET_DATA_REQUEST",
  GET_SIMILARITY_STATS_SUCCESS: "SIMILARITY_STATS_GET_DATA_SUCCESS",
  GET_SIMILARITY_STATS_FAILURE: "SIMILARITY_STATS_GET_DATA_FAILURE",

};
