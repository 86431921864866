import {Fragment} from "react";
import TikTokEmbed from "./TikTokEmbed";
import {metaMsgSources, msgSource, supportedSourcesForOEmbed} from "_constants";
import _ from "lodash";
import MetaEmbed from "./MetaEmbed";
import YoutubeEmbed from "./YoutubeEmbed";

export function getEmbed(sourceName) {
    switch (sourceName) {
        case msgSource.TIKTOK: return TikTokEmbed;
        case msgSource.FACEBOOK:
        case msgSource.INSTAGRAM: return MetaEmbed;
        case msgSource.YOUTUBE: return YoutubeEmbed;
        default:
            return Fragment;
    }
}

export function buildTikTokVideoEmbedUrl(message) {
    const externalParentId = _.get(message, 'externalParentId');
    return `https://www.tiktok.com/embed/v2/${externalParentId}`
}

export function buildYoutubeVideoEmbedUrl(message) {
    const externalParentId = _.get(message, 'externalParentId');
    return `https://www.youtube.com/embed/${externalParentId}`;
}

/**
 * @param sourceName {string}
 * @param isInApp {boolean}
 * @returns {boolean}
 */
export function isOEmbedSupported(sourceName, isInApp) {
    return !isInApp && supportedSourcesForOEmbed.includes(sourceName);
}

/**
 * @param sourceName {string}
 * @param isInApp {boolean}
 * @returns {boolean}
 */
export function isMetaSourcesForOEmbed(sourceName, isInApp) {
    return !isInApp && metaMsgSources.includes(sourceName);
}

export const carouselBreakpointConfig = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
}