import {history} from '_helpers';
import {alertMessages, oauthConstants} from "../_constants";
import {SnackBar} from "../components/common";
import {salesforceOauthService} from "../_services/salesforce/salesforce.oauth.service";

function isTestEnvironment(callbackUrl) {
    return callbackUrl.includes('salesforce-test')
}

function initiateOauth(callbackUrl) {
    function request() {
        return {type: oauthConstants.SALESFORCE_OAUTH_URL_REQUEST};
    }

    function success(res) {
        return {type: oauthConstants.SALESFORCE_OAUTH_URL_SUCCESS, payload: res};
    }

    function failure(error) {
        return {type: oauthConstants.SALESFORCE_OAUTH_URL_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        salesforceOauthService.initiateOauth(callbackUrl, isTestEnvironment(callbackUrl))
            .then(
                authUrl => dispatch(success(authUrl)),
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

function genAccessToken(params) {
    let redirectUrl = JSON.parse(localStorage.getItem('redirectUrl'));
    let callbackUrl = redirectUrl.pathname;
    function request() {
        return {type: oauthConstants.SALESFORCE_ACCESSTOKEN_REQUEST};
    }

    function success(res) {
        return {type: oauthConstants.SALESFORCE_ACCESSTOKEN_SUCCESS, payload: res};
    }

    function failure(error) {
        return {type: oauthConstants.SALESFORCE_ACCESSTOKEN_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());
        salesforceOauthService.oauthAccessToken(params, redirectUrl, isTestEnvironment(redirectUrl))
            .then(
                (res) => {
                    if (res) {
                        history.push(callbackUrl);
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.SALESFORCE_OAUTH_SUCCESS, 10000);
                    }
                },
                (error) => {
                    history.push(callbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.SALESFORCE_OAUTH_FAILURE);
                },
            );
    };
}

export const salesforceOauthActions = {
    initiateOauth,
    genAccessToken,
};