import React from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        border: '1.3px solid #9EA9A9',
        borderRadius: '10px',
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            fontSize: '16px',
            fontFamily: 'LarsseitMedium',
            color:'#188C84',
            '&:hover': {
                backgroundColor: '#E6E9E9',
            },
        },
    },
}));

export default function ProactiveNavMenu({}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={openMenu}
                endIcon={<KeyboardArrowDownIcon />}
                sx={styles.menu}
            >
                Proactive
            </Button>
            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem component={Link} to='/company/campaign' sx={styles.menuItem} onClick={closeMenu}>
                    Campaigns
                </MenuItem>
                <MenuItem component={Link} to='/company/template' sx={styles.menuItem} onClick={closeMenu}>
                    Templates
                </MenuItem>
            </StyledMenu>
        </div>
    );
}

const styles = {
    menu: {
        width: '100%', fontSize: '16px', padding: '0 5px', color: '#188C84', fontFamily: 'LarsseitMedium',
        textTransform: 'none',
        ':hover': {
            color: '#285a55',
            backgroundColor: 'unset'
        }
    },
    menuItem: { textDecoration: 'none!important', ':focus':{outline: 'none'} }
}