import {adminSearchConstants} from '_constants';

const INITIAL_STATE = {
    loading: false,
    searchData: [],
    exporting: false,
    visitedPages: {},
    queryParams: {},
};

export function searchReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case adminSearchConstants.ADMIN_SEARCH_REQUEST:
            return {...state, loading: true, error: ''};
        case adminSearchConstants.DELETE_VISITED_PAGES:
            return { ...state, visitedPages: {} };
        case adminSearchConstants.ADMIN_SEARCH_SUCCESS:
            return { ...state,
                searchData: action.payload.searchParams,
                visitedPages: {...state.visitedPages, [`page${action.payload.page}`]:action.payload.searchParams},
                queryParams: action.payload.queryParams,
                loading: false };
        case adminSearchConstants.ADMIN_SEARCH_FAILURE:
            return {...state, error: action.error, loading: false};

        case adminSearchConstants.EXPORT_ADMIN_SEARCH_REQUEST:
            return {...state, exporting: true, error: ''};
        case adminSearchConstants.EXPORT_ADMIN_SEARCH_SUCCESS:
            return { ...state, exporting: false };
        case adminSearchConstants.EXPORT_ADMIN_SEARCH_FAILURE:
            return {...state, error: action.error, exporting: false};

        default:
            return state
    }
}