import {Autocomplete, Box, Chip, FormControl, Grid, TextField, Tooltip} from "@mui/material";
import Icon from '@mui/material/Icon';
import { useState } from "react";
import {useDispatch} from "react-redux";
import _ from "lodash";
import AccordionItem from 'components/v2/common/AccordionItem';
import {accessibilityMode} from "../organizationSettingsConstants";
import useSalesforceOrgConfigUpdateInfo from "../../hooks/useSalesforceOrgConfigUpdateInfo";
import BasicButton from "../../../../common/BasicButton";
import {salesforceOrgConfigActions, organizationSettingsActions} from "_actions";
import commonStyles from "../commonStyles";

export default function SalesforceSection({handle, mode, label}) {
    const dispatch = useDispatch();
    const {lastUpdatedHandleId, error} = useSalesforceOrgConfigUpdateInfo();

    const emptyField = {value: null, touched: false};

    const [state, setState] = useState(
        {
            dispatchCaseUri: emptyField,
            defaultReplyEmail: emptyField,
            approvedOwnerIds: emptyField,
            htmlTemplate: emptyField,
            updateFieldsMapJson: emptyField,
            escalateFieldsMapJson: emptyField,
            noActionNeededFieldsMapJson: emptyField
        }
    );

    const resetTouchedForAllFields = () => {
        const fields = _.keys(state);
        const allFieldsUntouched = {};
        _.forEach(fields, (field) => allFieldsUntouched[field] = {touched: false});
        setState(_.merge({}, state, allFieldsUntouched));
    };

    const saveEditedValue = (field, value) => {
        const updatedField = {};
        updatedField[field] = {value: value, touched: true};
        setState(_.assign({}, state, updatedField));
    };

    const getEffectiveValue = (field) => {
        const valueInState = state[field].value;
        if (valueInState !== null) {
            return valueInState;
        } else if (handle.salesforce) {
            return handle.salesforce[field];
        }
        return undefined;
    };

    const formatError = (error) => {
        if (error === 'INVALID_JSON_SYNTAX') {
            return "JSON syntax is invalid.";
        } else if (error === 'INVALID_OWNER_ID') {
            return "OwnerId is invalid. It should be 15 or 18 characters long.";
        } else if (error === 'INVALID_STATUS') {
            return "Status is invalid. Valid statuses are: New, In Progress, Escalated, Merged, Closed.";
        } else if (error === 'MISSING_TEXT_PLACEHOLDER') {
            return "The {{TEXT}} placeholder is missing.";
        } else if (error === 'INVALID_DEFAULT_REPLY_EMAIL') {
            return "Invalid e-mail address.";
        } else if (error === 'MISSING_VALUE') {
            return "Missing required value.";
        } else if (error === 'INVALID_VALUE') {
            return "Invalid value.";
        }
        return "Invalid value.";
    };

    const errorDescriptions = (formattedErrors) => {
        if (_.isEmpty(formattedErrors)) {
            return '';
        } else if (formattedErrors.length === 1) {
            return formattedErrors[0];
        } else {
            return (
                <ul style={styles.errorList}>
                    { formattedErrors.map((e) => <li>{e}</li>) }
                </ul>
            );
        }
    };

    const getErrorMessage = (field) => {
        const errorCodes = error?.errorList
            ?.filter((error) => error.field == field)
            ?.flatMap((error) => error.errorCodes);

        if (lastUpdatedHandleId == handle.id && errorCodes && !state[field].touched) {
            return errorDescriptions(errorCodes.map((error) => formatError(error)));
        }
        return '';
    };

    const isEditMode = () => {
        return mode === accessibilityMode.EDIT;
    };

    const updateSalesforceOrgConfig = () => {
        const payload = {
            dispatchCaseUri: getEffectiveValue('dispatchCaseUri'),
            defaultReplyEmail: getEffectiveValue('defaultReplyEmail'),
            approvedOwnerIds: getEffectiveValue('approvedOwnerIds'),
            htmlTemplate: getEffectiveValue('htmlTemplate'),
            updateFieldsMapJson: getEffectiveValue('updateFieldsMapJson'),
            escalateFieldsMapJson: getEffectiveValue('escalateFieldsMapJson'),
            noActionNeededFieldsMapJson: getEffectiveValue('noActionNeededFieldsMapJson')
        };
        dispatch(salesforceOrgConfigActions.update(handle.organizationId, payload, handle.id)).then(() => {
            resetTouchedForAllFields();
            dispatch(organizationSettingsActions.refreshDataSourceHandleInfo(handle.organizationId));
        });
    };

    return (
        <Box>
            <Box component={'h4'} sx={commonStyles.labelBold}>
                Salesforce
                {
                    isEditMode() && (
                        <Tooltip title={'These settings are organization-wide, so other data sources in other channels will be updated too.'}>
                            <Icon sx={styles.infoIcon} color={'info'}>info</Icon>
                        </Tooltip>
                    )
                }
            </Box>
            <Grid container>
                <Grid item xs={6}>
                    <Box>
                        <Box component={'h4'} sx={commonStyles.labelMedium}>Dispatch Case URI</Box>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                value={getEffectiveValue('dispatchCaseUri')}
                                onChange={isEditMode() && ((event) => saveEditedValue('dispatchCaseUri', event.target.value))}
                                disabled={!isEditMode()}
                                error={!_.isEmpty(getErrorMessage('dispatchCaseUri'))}
                                helperText={getErrorMessage('dispatchCaseUri')} />
                        </FormControl>
                    </Box>
                    <Box>
                        <Box component={'h4'} sx={commonStyles.labelMedium}>Default Reply Email</Box>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                value={getEffectiveValue('defaultReplyEmail')}
                                onChange={isEditMode() && ((event) => saveEditedValue('defaultReplyEmail', event.target.value))}
                                disabled={!isEditMode()}
                                error={!_.isEmpty(getErrorMessage('defaultReplyEmail'))}
                                helperText={getErrorMessage('defaultReplyEmail')} />
                        </FormControl>
                    </Box>
                    <Box>
                        <Box component={'h4'} sx={commonStyles.labelMedium}>Approved Owner Ids</Box>
                        <FormControl fullWidth>
                            <Autocomplete
                                multiple
                                options={[]}
                                value={getEffectiveValue('approvedOwnerIds')}
                                onChange={isEditMode() && ((event, value) => saveEditedValue('approvedOwnerIds', value))}
                                disabled={!isEditMode()}
                                readOnly={!isEditMode()}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                  ))
                                }
                                renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        placeholder={isEditMode() && 'Type and press Enter to add items...'}
                                        error={!_.isEmpty(getErrorMessage('approvedOwnerIds'))}
                                        helperText={getErrorMessage('approvedOwnerIds')}
                                        {...params}
                                      />
                                )} />
                        </FormControl>
                    </Box>
                    <Box>
                        <Box component={'h4'} sx={commonStyles.labelMedium}>Templates & JSONs</Box>
                    </Box>
                    <Box>
                        <AccordionItem
                                key={'htmlTemplate'}
                                summaryComponent={<Box component={'h4'} sx={commonStyles.labelMedium}>HTML Template</Box>}>
                            <FormControl fullWidth>
                                <TextField
                                      multiline
                                      minRows={4}
                                      inputProps={{style: styles.htmlInput}}
                                      value={getEffectiveValue('htmlTemplate')}
                                      onChange={isEditMode() && ((event) => saveEditedValue('htmlTemplate', event.target.value))}
                                      disabled={!isEditMode()}
                                      error={!_.isEmpty(getErrorMessage('htmlTemplate'))}
                                      helperText={getErrorMessage('htmlTemplate')} />
                            </FormControl>
                        </AccordionItem>
                    </Box>
                    <Box>
                        <AccordionItem
                                key={'updateFieldsMapJson'}
                                summaryComponent={<Box component={'h4'} sx={commonStyles.labelMedium}>Update Fields JSON</Box>}>
                            <FormControl fullWidth>
                                <TextField
                                      multiline
                                      minRows={4}
                                      inputProps={{style: styles.jsonInput}}
                                      value={getEffectiveValue('updateFieldsMapJson')}
                                      onChange={isEditMode() && ((event) => saveEditedValue('updateFieldsMapJson', event.target.value))}
                                      disabled={!isEditMode()}
                                      error={!_.isEmpty(getErrorMessage('updateFieldsMapJson'))}
                                      helperText={getErrorMessage('updateFieldsMapJson')} />
                            </FormControl>
                        </AccordionItem>
                    </Box>
                    <Box>
                        <AccordionItem
                                key={'escalateFieldsMapJson'}
                                summaryComponent={<Box component={'h4'} sx={commonStyles.labelMedium}>Escalate Fields JSON</Box>}>
                            <FormControl fullWidth>
                                <TextField
                                      multiline
                                      minRows={4}
                                      inputProps={{style: styles.jsonInput}}
                                      value={getEffectiveValue('escalateFieldsMapJson')}
                                      onChange={isEditMode() && ((event) => saveEditedValue('escalateFieldsMapJson', event.target.value))}
                                      disabled={!isEditMode()}
                                      error={!_.isEmpty(getErrorMessage('escalateFieldsMapJson'))}
                                      helperText={getErrorMessage('escalateFieldsMapJson')} />
                            </FormControl>
                        </AccordionItem>
                    </Box>
                    <Box>
                        <AccordionItem
                                key={'noActionNeededFieldsMapJson'}
                                summaryComponent={<Box component={'h4'} sx={commonStyles.labelMedium}>No Action Needed Fields JSON</Box>}>
                            <FormControl fullWidth>
                                <TextField
                                      multiline
                                      minRows={4}
                                      inputProps={{style: styles.jsonInput}}
                                      value={getEffectiveValue('noActionNeededFieldsMapJson')}
                                      onChange={isEditMode() && ((event) => saveEditedValue('noActionNeededFieldsMapJson', event.target.value))}
                                      disabled={!isEditMode()}
                                      error={!_.isEmpty(getErrorMessage('noActionNeededFieldsMapJson'))}
                                      helperText={getErrorMessage('noActionNeededFieldsMapJson')} />
                            </FormControl>
                        </AccordionItem>
                    </Box>
                    {
                        isEditMode() && (
                            <Box>
                                <FormControl sx={styles.saveButton}>
                                    <BasicButton
                                        label={'Save Salesforce settings'}
                                        action={isEditMode() && updateSalesforceOrgConfig} />
                                </FormControl>
                            </Box>
                        )
                    }
                </Grid>
            </Grid>
        </Box>
    );
}

const styles = {
    errorList: {
        paddingLeft: '14px',
        paddingRight: '14px'
    },
    htmlInput: {
        fontFamily: 'Monospace'
    },
    infoIcon: {
        ml: 0.5,
        verticalAlign: 'top',
        fontSize: '14px'
    },
    jsonInput: {
        fontFamily: 'Monospace'
    },
    saveButton: {
        mt: 2
    }
};
