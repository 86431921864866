import { Box } from '@mui/material'

/**
 * 
 * @param {object} params
 * @param {boolean} params.enabled
 */
export function UserStatusBadge({ enabled }) {
	return (
		<Box>
			{enabled ? 'Enabled' : 'Disabled'}
		</Box>
	)
}

/**
 * 
 * @param {object} params
 * @param {string} params.role
 */
export function UserRoleBadge({ role }) {
	return (
		<Box>
			{role}
		</Box>
	)
}