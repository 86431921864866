import {
    adminSearchConstants,
    alertMessages,
    companyRepliedConstants,
    conversationConstants, downloadAttachmentConstants,
    escalationConstants,
    messageAuthorConstants,
    messageConstants,
    messageStatusConstants,
    paidMessageConstants,
    platformConstants,
    requestOrigin,
    responseTimeConstants,
    skipMessagesConstants,
    ticketThreadConstants,
    undoMessageConstants,
    voteConstants,
    workMode,
    internalNoteConstants, rerouteMessageConstants,
} from '_constants';
import {messageService} from '_services';
import {
    getArrayFromObjectArray,
    getArrayFromObjectArrayWithFilters,
    getNextMessage,
    getVotingOrganization,
    history, isValidJson,
    shouldCheckDeleteStatus,
    websocket
} from '_helpers';
import {SnackBar} from "../components/common";
import {alertActions} from "./alert.actions";
import _ from "lodash";
import moment from "moment";
import {
    dispatcherActions,
    downloadBase64File,
    getMimeFromFile, oEmbedActions,
    responseOptionActions,
    scheduleActions, tagActions,
    websocketActions
} from "_actions";
import {Auth} from "../components/Authorization";
import {actionMessagesConstants} from "../_constants";
import {isTiktokShopPost, isViewOriginalSupported} from "../components/v2/utilities/ConversationUtils";
import {isMetaSourcesForOEmbed} from "../components/v2/views/Vote/Sections/BodySection/Conversation/OEmbed/EmbedUtils";
import {processTiktokShopPost} from "../components/v2/utilities/TikTokShopDataUtils";
import {isTaggingSupported} from "../components/v2/views/Vote/Sections/BodySection/Response/Tag/TagUtils";

/**
 * Returns the payload for the action to be dispatched.
 * @param actionType
 * @param payload
 * @param error
 * @returns {{payload: {}, type, error: {}}}
 */
function getActionData(actionType, payload = {}, error = {}) {
    return {type: actionType, payload, error};
}

/**
 * Dispatches action creators for message escalation.
 * @param payload
 * @returns {Function}
 */
function escalate(payload) {
    return (dispatch) => {
        dispatch(getActionData(escalationConstants.ESCALATE_REQUEST));

        messageService.saveUserAction(payload)
            .then(
                (message) => {
                    dispatch(getActionData(escalationConstants.ESCALATE_SUCCESS, message));
                    dispatch(messageActions.clearConversation());
                    dispatch(alertActions.clear()); //This forces a page reload to fetch a new message
                    SnackBar.showMessage(alertMessages.ESCALATION_SUCCESS);
                },
                (error) => {
                    dispatch(getActionData(escalationConstants.ESCALATE_FAILURE, error));
                    dispatch(messageActions.clearConversation());
                    dispatch(alertActions.clear()); //This forces a page reload to fetch a new message
                    SnackBar.showMessage(alertMessages.MESSAGE_SKIP_UNSUCCESSFUL)
                },
            );
    };
}

/**
 * Dispatches action creators for skip message.
 * @param params
 * @returns {Function}
 */
function skipMessage(params) {
    function request(message) {
        return {type: skipMessagesConstants.SKIP_MESSAGE_REQUEST, message};
    }

    function success(message) {
        return {type: skipMessagesConstants.SKIP_MESSAGE_SUCCESS, message};
    }

    function failure(error) {
        return {type: skipMessagesConstants.SKIP_MESSAGE_FAILURE, error};
    }

    return (dispatch) => {
        dispatch(request(params));

        messageService.saveUserAction(params)
            .then(
                (message) => {
                    dispatch(success(message));
                    dispatch(messageActions.clearConversation());
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.MESSAGE_SKIP_SUCCESSFUL)
                },
                (error) => {
                    dispatch(failure(error));
                    dispatch(alertActions.clear());
                    SnackBar.showMessage(alertMessages.MESSAGE_SKIP_UNSUCCESSFUL)
                },
            );
    };
}


/**
 * Dispatches the 'getAllMessages' action.
 * @param params
 * @returns {function(*)}
 */
function getAll(params) {
    function request() {
        return {type: messageConstants.GET_ALL_REQUEST};
    }

    function success(messages) {
        return {type: messageConstants.GET_ALL_SUCCESS, payload: messages};
    }

    function failure(error) {
        return {type: messageConstants.GET_ALL_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());
        messageService.getAll(params)
            .then(
                messages => dispatch(success(messages)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

function connectWebsocket(eventId, subscriptionId, params, dispatch) {
    const data = Object.assign(params, {subscriptionId});
    websocket.subscribe(dispatch, subscriptionId, websocketActions.setMessage);
    websocket.sendMessage(eventId, data);
}

const getAllMessages = (params) => {
    function request() {
        return {type: messageConstants.GET_MESSAGES_ALL_REQUEST};
    }

    function success(messages) {
        return {type: messageConstants.GET_MESSAGES_ALL_SUCCESS, payload: messages};
    }

    function failure(error) {
        return {type: messageConstants.GET_MESSAGES_ALL_FAILURE, payload: error};
    }

    return (dispatch) => {
        const newEndDate = new moment(params.endDate, "YYYY-MM-DD HH:mm:ss").utc().format();
        const newStartDate = new moment(params.startDate, "YYYY-MM-DD HH:mm:ss").utc().format();
        params.endDate = newEndDate;
        params.startDate = newStartDate;

        dispatch(request());
        messageService.getAllMessages(params)
            .then(
                (response) => {
                    dispatch(success(response.data));

                    let result = _.get(response.data, 'messages');
                    let orgIdArr = [...new Set(getArrayFromObjectArray(result, 'organizationId'))];

                    orgIdArr && orgIdArr.length > 0 && dispatch(scheduleActions.getScheduledOrganizations({
                        organizationIds: orgIdArr
                    }));

                    let idArr = getArrayFromObjectArray(result, 'id');
                    // dispatch an action to get messages status
                    idArr && idArr.length > 0 && dispatch(getMessageStatus({
                        messageIds: idArr,
                        platform: platformConstants.TEAMS
                    }));

                    // Filter list of messages for incoming and actioned messages
                    let responseGenArray = getArrayFromObjectArrayWithFilters(result, 'id', [['direction', 'INCOMING']]);
                    // dispatch an action to get messages status
                    responseGenArray && responseGenArray.length > 0 && dispatch(messagesForResponseGeneration({
                        messageIds: JSON.stringify(responseGenArray)
                    }))

                }).catch((error) => {
            if (_.get(error, "response.status") === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    };
};

const getAllMessagesFromTrends = (params) => {
    function request() {
        return {type: messageConstants.GET_TRENDS_MESSAGES_ALL_REQUEST};
    }

    function success(messages) {
        return {type: messageConstants.GET_TRENDS_MESSAGES_ALL_SUCCESS, payload: messages};
    }

    function failure(error) {
        return {type: messageConstants.GET_TRENDS_MESSAGES_ALL_FAILURE, payload: error};
    }

    return (dispatch) => {
        const newEndDate = new moment(params.endDate, "YYYY-MM-DD HH:mm:ss").utc().format();
        const newStartDate = new moment(params.startDate, "YYYY-MM-DD HH:mm:ss").utc().format();
        params.endDate = newEndDate;
        params.startDate = newStartDate;
        dispatch(request());
        messageService.getAllMessagesFromTrends(params)
            .then(
                (response) => {
                    dispatch(success(response.data));

                    let result = _.get(response.data, 'trendsMessages.messages');
                    let trendsOrgIdArr = [...new Set(getArrayFromObjectArray(result, 'organizationId'))];

                    trendsOrgIdArr && trendsOrgIdArr.length > 0 && dispatch(scheduleActions.getScheduledOrganizations({
                        trendsOrgIds: trendsOrgIdArr
                    }));

                    let idArr = getArrayFromObjectArray(result, 'id');
                    // dispatch an action to get messages status
                    idArr && idArr.length > 0 && dispatch(getMessageStatus({
                        messageIds: idArr,
                        platform: platformConstants.TRENDS
                    }));
                }
            ).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    };
};

function getAllFromTrends(params, id) {
    function request() {
        return {type: messageConstants.GET_ALL_FROM_TRENDS_REQUEST};
    }

    function success(messages) {
        return {type: messageConstants.GET_ALL_FROM_TRENDS_SUCCESS, payload: messages};
    }

    function failure(error) {
        return {type: messageConstants.GET_ALL_FROM_TRENDS_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.getAllFromTrends(params, id)
            .then(
                messages => dispatch(success(messages)),
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

/**
 * Dispatches the 'getPriorityMessages' action.
 * A Priority message is a message that meets any of the following criteria:
 *  1. A message that was escalated by all agents
 *  2. A message that was marked as bad response by all agents
 *  3. A message that was sent for review by all agents
 * @param params
 * @returns {function(*)}
 */
function getPriorityMessages(params) {
    function request() {
        return {type: messageConstants.GET_PRIORIY_MESSAGES_REQUEST};
    }

    function success(messages) {
        return {type: messageConstants.GET_PRIORIY_MESSAGES_SUCCESS, payload: messages};
    }

    function failure(error) {
        return {type: messageConstants.GET_PRIORIY_MESSAGES_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.getPriorityMessages(params)
            .then(
                (messages) => {
                    dispatch(success(messages));
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

/**
 * Dispatches the 'getMessage' action.
 * @param id
 * @returns {function(*)}
 */
function getMessage(id, checkDeleteStatus=false, connectWebsocket=false) {

    function request() {
        return {type: messageConstants.GET_DETAILS_REQUEST};
    }

    function success(message) {
        return {type: messageConstants.GET_DETAILS_SUCCESS, payload: message};
    }

    function failure(error) {
        return {type: messageConstants.GET_DETAILS_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request(id));

        messageService.getMessage(id)
            .then(
                (message) => {
                    const sourceName = _.get(message, 'sourceName')
                    const handleName = _.get(message, 'channel')
                    if (checkDeleteStatus && shouldCheckDeleteStatus(sourceName, handleName)) {
                        const id = _.get(message, 'id')
                        dispatch(dispatcherActions.messageDeleteStatus(id, sourceName))
                    }
                    const direction = _.get(message, 'direction');
                    const teamsOnly = _.get(message, 'isProactive') || _.get(message, 'isSupervisedChat');

                    const msgId = (teamsOnly || (direction && direction.toString().toLowerCase() === 'incoming'))
                        ? _.get(message, 'id')
                        : _.get(message, 'isReply.id');

                    if (connectWebsocket && message?.externalParentId) {
                        websocket.subscribe(
                            (data) => {
                                if (websocket.isSameMessageThread(message, data)) {
                                    dispatch(websocketActions.addTeamsMessage(data));
                                }
                            },
                            message?.externalParentId,
                            (data) => data
                        );
                    }

                    if (teamsOnly) {
                        dispatch(messageActions.getConversationFromTeams(msgId));
                    } else {
                        dispatch(messageActions.getConversationFromTrends(msgId, {}, message));
                    }
                    let responseGenArray = [id];
                    dispatch(messagesForResponseGeneration({
                        messageIds: JSON.stringify(responseGenArray)
                    }));

                    dispatch(success(message));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

/**
 * Dispatches the 'getMessageHistory' action.
 * @param id
 * @returns {function(*)}
 */
function getMessageHistory(id) {
    function request() {
        return {type: messageConstants.GET_HISTORY_REQUEST};
    }

    function success(message) {
        return {type: messageConstants.GET_HISTORY_SUCCESS, payload: message};
    }

    function failure(error) {
        return {type: messageConstants.GET_HISTORY_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.getMessageHistory(id)
            .then(
                message => dispatch(success(message)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}


/**
 * Dispatches the 'getNext' action.
 * @param params
 * @returns {function(*)}
 */
function getNext(params) {
    return (dispatch) => {
        dispatch(getActionData(messageConstants.GET_NEXT_MESSAGE_REQUEST));

        messageService.getNext(params)
            .then(
                (message) => {
                    const msgId = _.get(message, 'id');
                    dispatch(getActionData(messageConstants.GET_NEXT_MESSAGE_SUCCESS, message));
                    dispatch(messageActions.getConversationFromTrends(msgId, null, null, requestOrigin.VOTE_INDEX));

                    if (params.mode === workMode.ACTIVE) {
                        const orgId = _.get(getVotingOrganization(), 'id');
                        dispatch(scheduleActions.getScheduledOrganizations({organizationIds: [orgId]}, requestOrigin.VOTE_INDEX));
                        websocket.subscribe(dispatch, msgId, websocketActions.setVoteMessage);
                    }
                },
                (error) => {
                    history.push('/dashboard');
                    dispatch(getActionData(messageConstants.GET_NEXT_MESSAGE_FAILURE, error));
                    SnackBar.showMessage(alertMessages.GET_NEXT_MESSAGE_FAILURE);
                },
            );
    };
}

function showNextVoteMessage(params) {
    return (dispatch) => {
        dispatch(getActionData(messageConstants.GET_NEXT_MESSAGE_REQUEST));
        dispatch(responseOptionActions.clearAddedResponseOption());
        dispatch(tagActions.resetTagsList());

        messageService.showNextVoteMessage(params)
            .then((response) => {
                const message = _.get(response, 'data.message');
                dispatch(getActionData(messageConstants.GET_NEXT_MESSAGE_SUCCESS, message));
                nextMessageAsyncRequests(dispatch, message, params);
            }).catch((err) => {
                history.push('/dashboard');
                dispatch(getActionData(messageConstants.GET_NEXT_MESSAGE_FAILURE, err));
                SnackBar.showMessage(alertMessages.GET_NEXT_MESSAGE_FAILURE);
            });
    };
}

function nextMessageAsyncRequests(dispatch, message, params) {
    let {id, sourceName, dataSourceHandle, isProactive, externalParentId} = message;
    sourceName = sourceName && sourceName.toLowerCase();
    const displayInApp = _.get(dataSourceHandle, 'displayInApp');
    const handleName = _.get(dataSourceHandle, 'name');

    if(isMetaSourcesForOEmbed(sourceName, displayInApp)) {
        dispatch(oEmbedActions.getMetaEmbed(id));
    }

    if (params.mode === workMode.ACTIVE) {
        if(isViewOriginalSupported(sourceName, handleName, displayInApp, isProactive)) {
            dispatch(messageActions.getFullEmailConversation(id, platformConstants.TRENDS));
        } else if(!isProactive) {
            dispatch(messageActions.getConversationFromTrends(id, null, message, requestOrigin.VOTE_INDEX));
        }

        if(isTaggingSupported(sourceName)) {
            dispatch(tagActions.listTags(_.get(message, 'msgDataSource.id')));
        }

        const orgId = _.get(getVotingOrganization(), 'id');
        dispatch(scheduleActions.getScheduledOrganizations({organizationIds: [orgId]}, requestOrigin.VOTE_INDEX));
    }
}

function submitInternalNote(params) {
    return (dispatch) => {
        dispatch(getActionData(internalNoteConstants.SUBMIT_INTERNAL_NOTE_REQUEST));

        messageService.submitInternalNote(params)
            .then((response) => {
                    dispatch(getActionData(internalNoteConstants.SUBMIT_INTERNAL_NOTE_SUCCESS, response.data));
                    dispatch(messageActions.clearConversation());
                    dispatch(alertActions.clear());
                },
            ).catch((err) => {
                dispatch(getActionData(internalNoteConstants.SUBMIT_INTERNAL_NOTE_FAILURE, err));
                SnackBar.showMessage(alertMessages.GENERIC_ERROR);
            });
    };
}

function getInternalNoteAssignees(messageId) {
    return (dispatch) => {
        dispatch(getActionData(internalNoteConstants.GET_INTERNAL_NOTE_ASSIGNEES_REQUEST));

        messageService.getInternalNoteAssignees(messageId)
            .then((response) => {
                    dispatch(getActionData(internalNoteConstants.GET_INTERNAL_NOTE_ASSIGNEES_SUCCESS, response.data));
                },
            ).catch((err) => {
                dispatch(getActionData(internalNoteConstants.GET_INTERNAL_NOTE_ASSIGNEES_FAILURE, {}, err));
                SnackBar.showMessage(alertMessages.GENERIC_ERROR);
            });
    };
}

function rerouteMessage(params) {
    return (dispatch) => {
        dispatch(getActionData(rerouteMessageConstants.REROUTE_MESSAGE_REQUEST));

        messageService.rerouteMessage(params)
            .then((response) => {
                    dispatch(getActionData(rerouteMessageConstants.REROUTE_MESSAGE_SUCCESS, response.data));
                    dispatch(messageActions.clearConversation());
                    dispatch(alertActions.clear());
                },
            ).catch((err) => {
                dispatch(getActionData(rerouteMessageConstants.REROUTE_MESSAGE_FAILURE, err));
                SnackBar.showMessage(alertMessages.GENERIC_ERROR);
            });
    };
}

/**
 * Dispatch action that saves message review
 * @param vote
 * @returns {Function}
 */
function review(vote) {
    function request() {
        return {type: voteConstants.SUBMIT_VOTE_REQUEST};
    }

    function success(responseData) {
        return {type: voteConstants.SUBMIT_VOTE_SUCCESS, responseData};
    }

    function failure(error) {
        return {type: voteConstants.SUBMIT_VOTE_FAILURE, error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.review(vote)
            .then(
                (response) => {
                    dispatch(success(response));

                    if (vote.isLastResponseVote) {
                        getNextMessage(dispatch, true);
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}


/**
 * Get Conversation Dispatches the 'getConversation' actions.
 * @param messageId
 * @param params
 * @returns {function(*)}
 */
function getConversation(messageId, params) {
    function request() {
        return {type: conversationConstants.GET_CONVERSATION_REQUEST};
    }

    function success(conversation) {
        return {type: conversationConstants.GET_CONVERSATION_SUCCESS, payload: conversation};
    }

    function failure(error) {
        return {type: conversationConstants.GET_CONVERSATION_FAILURE, error: error};
    }

    return (dispatch) => {
        dispatch(request(messageId));

        messageService.getConversation(messageId, params)
            .then(
                (conversation) => {
                    dispatch(success(conversation));
                    const trendsMessageId = conversation && conversation.constructor === Array ?
                        _.get(conversation, '0.conversation.0.id') : _.get(conversation, 'conversation.0.id');

                    dispatch(getTrendsCorrespondingMessages(trendsMessageId));
                    dispatch(getCompanyReplied(trendsMessageId));
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

/**
 * Dispatches the 'getConversationFromTrends' action.
 * @param messageId
 * @params finalParams
 * @returns {function(*)}
 */
function getConversationFromTrends(messageId, finalParams = {}, message = null, reqOrigin = null) {
    function request() {
        return {type: conversationConstants.GET_CONVERSATION_THREAD_REQUEST};
    }

    function success(conversation) {
        return {type: conversationConstants.GET_CONVERSATION_THREAD_SUCCESS, payload: conversation};
    }

    function failure(error) {
        return {type: conversationConstants.GET_CONVERSATION_THREAD_FAILURE, error: error};
    }

    return (dispatch) => {
        dispatch(request(messageId));

        messageService.getConversationFromTrends(messageId, finalParams)
            .then(
                (response) => {
                    const conversation = response.data;
                    dispatch(success(conversation));
                    if(reqOrigin === requestOrigin.INBOX_DETAILS) {
                        const message = _.get(conversation, '0');
                        const conversationData = _.get(message, 'conversation');
                        processConversationFromTrends(dispatch, conversationData, message);
                    } else {
                        processConversationFromTrends(dispatch, conversation, message);
                    }

                    if (reqOrigin === requestOrigin.INBOX_DETAILS) {
                        const trendsMessageId = conversation && conversation.constructor === Array ?
                            _.get(conversation, '0.conversation.0.id') : _.get(conversation, 'conversation.0.id');

                        if(trendsMessageId) {
                            dispatch(getTrendsCorrespondingMessages(trendsMessageId));
                            dispatch(getCompanyReplied(trendsMessageId));
                        }
                    } else if (reqOrigin === requestOrigin.VOTE_INDEX) { /** Skip, do nothing */
                    } else {

                        let trendsMessageIds = [];
                        let messageIds = [];

                        if (conversation && conversation.length > 0) {
                            conversation.map(index => {
                                let msgDirection = _.get(index, 'direction');

                                if (msgDirection && msgDirection.toString().toLowerCase() === 'incoming') {
                                    trendsMessageIds.push(_.get(index, 'id'));
                                }
                                return trendsMessageIds
                            });
                        }

                         let msgDirection = _.get(message, 'direction');
                         if (msgDirection && msgDirection.toString().toLowerCase() === 'incoming') {
                            messageIds.push(_.get(message, 'id'));
                         }

                        // dispatch an action to get the actions for incoming paid messages
                        (trendsMessageIds.length > 0 || messageIds.length > 0) && dispatch(getPaidMessageAction({trendsMessageIds: trendsMessageIds, messageIds: messageIds}));
                    }
                },
                (error) => {
                    dispatch(failure(error.response));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

function processConversationFromTrends(dispatch, conversation, message) {
    let dataSourceHandle = _.get(message, 'dataSourceHandle');

    const isMsgTiktokShopPost = isTiktokShopPost(_.get(dataSourceHandle, 'type'));
    if(isMsgTiktokShopPost) {
        processTiktokShopPost(dispatch, conversation, message);
    }
}

function getConversationFromTeamsInternal(messageId, conversationThreadView, request, success, failure) {
    return (dispatch) => {
        dispatch(request(messageId));

        messageService.getConversationFromTeams(messageId, conversationThreadView)
            .then(
                (response) => {
                    const conversation = response.data;
                    dispatch(success(conversation));
                        let messageIds = [];

                        if (conversation && conversation.length > 0) {
                            conversation.map(index => {
                                let msgDirection = _.get(index, 'direction');

                                if (msgDirection && msgDirection.toString().toLowerCase() === 'incoming') {
                                    messageIds.push(_.get(index, 'id'));
                                }
                                return messageIds
                            });
                        }

                        // dispatch an action to get the actions for incoming paid messages
                        (messageIds.length > 0) && dispatch(getPaidMessageAction({messageIds: messageIds}));
                },
                (error) => {
                    dispatch(failure(error.response));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

function getConversationFromTeams(messageId) {
    function request() {
        return {type: conversationConstants.GET_CONVERSATION_THREAD_REQUEST};
    }

    function success(conversation) {
        return {type: conversationConstants.GET_CONVERSATION_THREAD_SUCCESS, payload: conversation};
    }

    function failure(error) {
        return {type: conversationConstants.GET_CONVERSATION_THREAD_FAILURE, error: error};
    }

    return getConversationFromTeamsInternal(messageId, false, request, success, failure);
}

function getOriginalConversationFromTeams(messageId) {
    function request() {
        return {type: conversationConstants.GET_ORIGINAL_CONVERSATION_THREAD_REQUEST};
    }

    function success(conversation) {
        return {type: conversationConstants.GET_ORIGINAL_CONVERSATION_THREAD_SUCCESS, payload: conversation};
    }

    function failure(error) {
        return {type: conversationConstants.GET_ORIGINAL_CONVERSATION_THREAD_FAILURE, error: error};
    }

    return getConversationFromTeamsInternal(messageId, true, request, success, failure);
}

/**
 * Retrieves JSON payload of message as saved on Microsoft Azure when the message
 * was initially sent from trends to teams
 * @param messageId
 * @param params
 * @returns {function(*)}
 */
function getResponsePayload(messageId) {
    function request() {
        return {type: messageConstants.GET_PAYLOAD_REQUEST};
    }

    function success(payload) {
        return {type: messageConstants.GET_PAYLOAD_SUCCESS, payload: payload.fileContent};
    }

    function failure(error) {
        return {type: messageConstants.GET_PAYLOAD_FAILURE, error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.getResponsePayload(messageId)
            .then(
                conversation => dispatch(success(conversation)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

/*
 * Dispatches the 'getAuthors' action.
 * @param params
 * @returns {function(*)}
 */
function getAuthors(params) {
    function request() {
        return {type: messageAuthorConstants.GET_MESSAGE_AUTHOR_REQUEST};
    }

    function success(authors) {
        return {type: messageAuthorConstants.GET_MESSAGE_AUTHOR_SUCCESS, payload: authors};
    }

    function failure(error) {
        return {type: messageAuthorConstants.GET_MESSAGE_AUTHOR_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.getAuthors(params)
            .then(
                authors => dispatch(success(_.get(authors, 'authors'))),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

/*
 * Dispatches the 'getBilling' action.
 * @param params
 * @returns {function(*)}
 */
function getBilling(params) {
    function request() {
        return {type: messageConstants.GET_ORG_BILLING_REQUEST};
    }

    function success(authors) {
        return {type: messageConstants.GET_ORG_BILLING_SUCCESS, payload: authors};
    }

    function failure(error) {
        return {type: messageConstants.GET_ORG_BILLING_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.getBilling(params)
            .then(
                authors => dispatch(success(authors)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

/*
 * Dispatches the 'getDetailsFromTrends' action.
 * @param params
 * @returns {function(*)}
 */
function getDetailsFromTrends(id, params = {}, reqOrigin = null, checkDeleteStatus=false) {
    function request() {
        return {type: messageConstants.GET_TRENDS_DETAILS_REQUEST};
    }

    function success(payload) {
        return {type: messageConstants.GET_TRENDS_DETAILS_SUCCESS, payload};
    }

    function failure(error) {
        return {type: messageConstants.GET_TRENDS_DETAILS_FAILURE, error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.getConversationFromTrends(id, params)
            .then(
                (response) => {
                    const message = response.data;
                    const messageObject = message[0]
                    const sourceName = _.get(messageObject, 'sourceName')
                    const handleName = _.get(messageObject, 'channel')
                    if (checkDeleteStatus && shouldCheckDeleteStatus(sourceName, handleName)) {
                        const id = _.get(messageObject, 'id')
                        dispatch(dispatcherActions.messageDeleteStatus(id, sourceName))
                    }
                    const conversation = _.get(message, '0.conversation');
                    const msg = _.get(message, '0');
                    processConversationFromTrends(dispatch, conversation, msg);

                    websocket.subscribe(dispatch, _.get(messageObject, 'externalParentId'), websocketActions.setTrendsMessage);
                    dispatch(success(messageObject));
                    //@TODO: Get information about this request. Aniekan, what is this used for?
                    dispatch(messageActions.getAdditionalTeamsDetails(id));

                    let result = _.get(messageObject, 'conversation');
                    let idArr = [];

                    if (result && result.length > 0) {
                        result.map(index => {
                            if (_.get(index, 'direction').toString().toLowerCase() === 'incoming') {
                                idArr.push(_.get(index, 'id'));
                            }
                            return idArr
                        });
                    } else {
                        let msgDirection = _.get(message[0], 'direction');

                        if (msgDirection && msgDirection.toString().toLowerCase() === 'incoming') {
                            idArr.push(_.get(messageObject, 'id'));
                        }
                    }

                    if (reqOrigin === requestOrigin.MESSAGE_DETAILS) {
                        // dispatch an action to get the actions for incoming paid messages
                        idArr && idArr.length > 0 && dispatch(getPaidMessageAction({trendsMessageIds: idArr}));
                    }
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            );
    };
}

/**
 * Retrieve additional details for trends OUTGOING message that has
 */
function getAdditionalTeamsDetails(id) {
    function request() {
        return {type: messageConstants.GET_ADDITIONAL_DETAILS_REQUEST};
    }

    function success(message) {
        return {type: messageConstants.GET_ADDITIONAL_DETAILS_SUCCESS, payload: message};
    }

    function failure(error) {
        return {type: messageConstants.GET_ADDITIONAL_DETAILS_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request(id));

        messageService.getMessage(id)
            .then(
                (message) => {
                    dispatch(success(message));
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };

}

/**
 * Dispatches the action that passes the payload to the searchAdminMessages service
 * @param user
 * @returns {function(*)}
 */
function searchAdminMessages(data) {
    return (dispatch, getState) => {
        const page = data.page;
        const visitedPages = getState().search.visitedPages;

        if (visitedPages[`page${page}`]) {
            dispatch(success(visitedPages[`page${page}`], page))
            return
        }

        dispatch(request());

        messageService.searchAdminMessages(data)
            .then(
                searchParams => {
                    dispatch(success(searchParams, page));
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.ADMIN_SEARCH_FAILURE);
                }
            );
    };

    function request() {
        return {type: adminSearchConstants.ADMIN_SEARCH_REQUEST}
    }

    function success(searchParams, page) {
        return {type: adminSearchConstants.ADMIN_SEARCH_SUCCESS, payload: {searchParams, page}}
    }

    function failure(error) {
        return {type: adminSearchConstants.ADMIN_SEARCH_FAILURE, error}
    }
}

function searchMessages(data) {
    return (dispatch) => {
        const page = data.page;
        dispatch(getActionData(adminSearchConstants.ADMIN_SEARCH_REQUEST));
        messageService.searchAdminMessages(data)
            .then(
                searchParams => {
                    dispatch(getActionData(adminSearchConstants.ADMIN_SEARCH_SUCCESS, {searchParams, page, queryParams: data}));
                },
                error => {
                    dispatch(getActionData(adminSearchConstants.ADMIN_SEARCH_FAILURE, error));
                    SnackBar.showMessage(alertMessages.ADMIN_SEARCH_FAILURE);
                }
            );
    };
}

/**
 * This clears the visited pages if the query parameter is different from  the previous one
 * @returns {{type: *}}
 */
function clearVisitedPages() {
    return {type: adminSearchConstants.DELETE_VISITED_PAGES}
}

/**
 * Dispatches actions that sends the export search payload to the service method
 *
 * @param user
 * @returns {function(*)}
 */
function exportAdminSearch(data) {
    return dispatch => {
        dispatch(request(data));

        messageService.exportAdminSearch(data)
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.EXPORT_ADMIN_SEARCH_FAILURE);
                }
            );
    };

    function request() {
        return {type: adminSearchConstants.EXPORT_ADMIN_SEARCH_REQUEST}
    }

    function success() {
        return {type: adminSearchConstants.EXPORT_ADMIN_SEARCH_SUCCESS}
    }

    function failure(error) {
        return {type: adminSearchConstants.EXPORT_ADMIN_SEARCH_FAILURE, error}
    }
}

function blacklistMessages(data) {
    return dispatch => {
        dispatch(request(data));

        messageService.blacklistMessages(data)
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                }
            );
    };

    function request() {
        return {type: adminSearchConstants.BLACKLIST_ADMIN_SEARCH_MESSAGE_REQUEST}
    }

    function success() {
        return {type: adminSearchConstants.BLACKLIST_ADMIN_SEARCH_MESSAGE_SUCCESS}
    }

    function failure(error) {
        return {type: adminSearchConstants.BLACKLIST_ADMIN_SEARCH_MESSAGE_FAILURE, error}
    }
}

function blacklistAllMessages(data) {
  return dispatch => {
    dispatch(request(data));

    messageService.blacklistAllMessages(data)
      .then(
        () => {
          dispatch(success(data));
          SnackBar.showMessage(alertMessages.BLACKLIST_SEARCH_MESSAGE_SUCCESS)
        },
        error => {
          dispatch(failure(error));
          SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        }
      );
  };

  function request() {
    return { type: adminSearchConstants.BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_REQUEST }
  }

  function success() {
    return { type: adminSearchConstants.BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_SUCCESS }
  }

  function failure(error) {
    return { type: adminSearchConstants.BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_FAILURE, error }
  }
}

const getFullEmailConversation = (messageId, platform) => {
    const request = () => ({
        type: conversationConstants.GET_FULL_EMAIL_CONVERSATION_REQUEST
    });

    const success = (response) => ({
        type: conversationConstants.GET_FULL_EMAIL_CONVERSATION_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: conversationConstants.GET_FULL_EMAIL_CONVERSATION_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.getFullEmailConversation(messageId, platform)
            .then(response => {
                /**
                 * The messageId is passed alongside the response into redux in order to keep track of
                 * the message whose conversation was fetched. This in turn would be used to prevent
                 * fetching the same data if the messageId has not changed in => ConversationList.jsx
                 * The platform is used to know which service to call, basically for trends messages
                 */
                dispatch(success({...response.data, messageId}));

            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(failure(error.response));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

const downloadAttachment = (messageId, attachmentURL, fileName) => {
    const request = () => ({
        type: downloadAttachmentConstants.DOWNLOAD_ATTACHMENT_REQUEST
    });

    const success = (response) => ({
        type: downloadAttachmentConstants.DOWNLOAD_ATTACHMENT_SUCCESS,
        payload: response
    });

    const failure = (error) => ({
        type: downloadAttachmentConstants.DOWNLOAD_ATTACHMENT_FAILURE, error,
        payload: {
            error
        }
    });

    return dispatch => {
        dispatch(request());
        messageService.downloadAttachment(messageId, attachmentURL)
            .then(response => {
              dispatch(success(response.data));
              const bas64String = response.data;
              const mimeType = getMimeFromFile(fileName);
              downloadBase64File(bas64String, mimeType);
            }).catch((error) => {
            if (_.get(error, "response.status") === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

const getPaidMessageAction = (params) => {

    const request = () => ({
        type: paidMessageConstants.PAID_MESSAGE_ACTION_REQUEST
    });

    const success = (response) => ({
        type: paidMessageConstants.PAID_MESSAGE_ACTION_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: paidMessageConstants.PAID_MESSAGE_ACTION_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.getPaidMessageAction(params)
            .then(response => {
                dispatch(success(response.data));

            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

const getMessageStatus = (params) => {
    const request = () => ({
        type: messageStatusConstants.MESSAGE_STATUS_REQUEST
    });

    const success = (response) => ({
        type: messageStatusConstants.MESSAGE_STATUS_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: messageStatusConstants.MESSAGE_STATUS_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.getMessageStatus(params)
            .then(response => {
                dispatch(success(response.data));

            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

function lockMessage(messageId, userId) {
    function request() {
        return {type: messageConstants.LOCK_MESSAGE_REQUEST};
    }

    function success(message) {
        return {type: messageConstants.LOCK_MESSAGE_SUCCESS, payload: message};
    }

    function failure(error) {
        return {type: messageConstants.LOCK_MESSAGE_FAILURE, payload: error};
    }

    return (dispatch) => {
        dispatch(request());

        messageService.lockMessage(messageId, userId)
            .then(
                (message) => {
                    dispatch(success(message));
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

const undoMessage = (messageId) => {
    const request = () => ({
        type: undoMessageConstants.UNDO_MESSAGE_REQUEST
    });

    const success = (response) => ({
        type: undoMessageConstants.UNDO_MESSAGE_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: undoMessageConstants.UNDO_MESSAGE_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.undoMessage(messageId)
            .then(response => {
                dispatch(success(response.data));
                dispatch(alertActions.clear()); //This forces a page reload
                SnackBar.showMessage(alertMessages.UNDO_MESSAGE_SUCCESS);


            }).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.clear()); //This forces a page reload
            SnackBar.showMessage(alertMessages.UNDO_MESSAGE_FAILURE);
        })
    }
};

const getTrendsCorrespondingMessages = (trendsMessageIds) => {
    const request = () => ({
        type: messageConstants.TRENDS_CORRESPONDING_MESSAGES_REQUEST
    });

    const success = (response) => ({
        type: messageConstants.TRENDS_CORRESPONDING_MESSAGES_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: messageConstants.TRENDS_CORRESPONDING_MESSAGES_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.getTrendsCorrespondingMessages(trendsMessageIds)
            .then(response => {
                dispatch(success(response.data));


            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};


const getMessagesInTicket = (ticketId, organizationId, isTrends) => {
    const request = () => ({
        type: ticketThreadConstants.TICKET_THREAD_REQUEST
    });

    const success = (response) => ({
        type: ticketThreadConstants.TICKET_THREAD_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: ticketThreadConstants.TICKET_THREAD_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.getMessagesInTicket(ticketId, organizationId, isTrends)
            .then(response => {
                dispatch(success(response.data));

            }).catch((error) => {
            if (_.get(error, "response.status") === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

const getCompanyReplied = (trendsMessageId) => {
    const request = () => ({
        type: companyRepliedConstants.COMPANY_REPLIED_REQUEST
    });

    const success = (response) => ({
        type: companyRepliedConstants.COMPANY_REPLIED_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: companyRepliedConstants.COMPANY_REPLIED_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.getCompanyReplied(trendsMessageId)
            .then(response => {
                dispatch(success(response.data));

            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

const sendForResponseGeneration = (requestObject) => {
    const request = () => ({
        type: messageConstants.RESEND_FOR_RESPONSE_GENERATION_REQUEST
    });

    const success = (response) => ({
        type: messageConstants.RESEND_FOR_RESPONSE_GENERATION_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: messageConstants.RESEND_FOR_RESPONSE_GENERATION_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.sendForResponseGeneration(requestObject)
            .then(response => {
                dispatch(success(response));
                SnackBar.showMessage(alertMessages.SEND_RESPONSE_GENERATION_SUCCESS);
            }).catch(error => {
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.SEND_RESPONSE_GENERATION_FAILURE);
        })
    }
};

const getResponseTimes = (data) => {
    const request = () => ({
        type: responseTimeConstants.GET_MESSAGES_RESPONSE_TIMES_REQUEST
    });

    const success = (responseTimeStats) => ({
        type: responseTimeConstants.GET_MESSAGES_RESPONSE_TIMES_SUCCESS, payload: responseTimeStats
    });

    const failure = (error) => ({
        type: responseTimeConstants.GET_MESSAGES_RESPONSE_TIMES_FAILURE, payload: error
    });

    return dispatch => {
        dispatch(request());

        messageService.getResponseTimes(data)
            .then(response => {
                    if (response && Object.keys(response).length === 0) SnackBar.showMessage(alertMessages.NO_DATA_FOR_FILTER)
                    dispatch(success(response))
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            )
    };
}

const messagesForResponseGeneration = (requestObject) => {
    const request = () => ({
        type: messageConstants.GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_REQUEST
    });

    const success = (response) => ({
        type: messageConstants.GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: messageConstants.GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.messagesForResponseGeneration(requestObject)
            .then(response => {
                dispatch(success(response));
            }).catch(error => {
            dispatch(failure(error));
        })
    }
};

const getMessagesRequiringAction = (data) => {
    const request = () => ({
        type: messageConstants.GET_MESSAGES_REQUIRING_ATTENTION_REQUEST
    });

    const success = (payload) => ({
        type: messageConstants.GET_MESSAGES_REQUIRING_ATTENTION_SUCCESS, payload: payload
    });

    const failure = (error) => ({
        type: messageConstants.GET_MESSAGES_REQUIRING_ATTENTION_FAILURE, payload: error
    });

    return dispatch => {
        dispatch(request());
        messageService.getMessagesRequiringAction(data)
            .then(response => {
                    dispatch(success(response))
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            )
    };
}

const deleteUserMessages = (requestObject) => {
    const request = () => ({
        type: messageConstants.DELETE_USER_MESSAGES_REQUEST
    });

    const success = (response) => ({
        type: messageConstants.DELETE_USER_MESSAGES_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: messageConstants.DELETE_USER_MESSAGES_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        messageService.deleteUserMessages(requestObject)
            .then(
                (message) => {
                    dispatch(success(message));
                    SnackBar.showMessage(alertMessages.DELETE_USER_MESSAGES_SUCCESS);

                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.DELETE_USER_MESSAGES_FAILURE);
                },
            );
    };
};

const confirmMessageHasReplyOrIsDeleted = (data) => {
    const request = () => ({
        type: messageConstants.CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_REQUEST
    });

    const success = (payload) => ({
        type: messageConstants.CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_SUCCESS, payload: payload
    });

    const failure = (error) => ({
        type: messageConstants.CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_FAILURE, payload: error
    });

    return dispatch => {
        dispatch(request());
        messageService.confirmMessageHasReplyOrIsDeleted(data)
            .then(response => {
                    dispatch(success(response))
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            )
    };
}

function tickMessageAsActioned(messageIds, platform, reqOrigin = '') {
    function request() {
       return { type: actionMessagesConstants.ACTION_MESSAGE_REQUEST }
    }

    function success(actionMessages) {
        return { type: actionMessagesConstants.ACTION_MESSAGE_SUCCESS, payload: actionMessages }
    }

    function failure(error) {
       return { type: actionMessagesConstants.ACTION_MESSAGE_FAILURE, payload: error }
    }

    return dispatch => {
        dispatch(request(messageIds));
        messageService.tickMessageAsActioned(messageIds, platform)
            .then(actionMessages => {
                dispatch(success(actionMessages))
                dispatch(alertActions.clear());
                if(reqOrigin !== requestOrigin.VOTE_INDEX) {
                    SnackBar.showMessage(alertMessages.MESSAGE_ACTIONED_SUCCESS);
                }
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.clear());
                SnackBar.showMessage(alertMessages.GENERIC_ERROR);
            },
            )
    };
}

function findAndReplaceText(data) {
    return dispatch => {
        dispatch(request(data));

        messageService.findAndReplaceText(data)
            .then(
                () => {
                    dispatch(success(data));
                    SnackBar.showMessage(alertMessages.FIND_AND_REPLACE_TEXT_SUCCESS)
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.FIND_AND_REPLACE_TEXT_FAILURE);
                }
            );
    };

    function request() {
        return { type: adminSearchConstants.FIND_AND_REPLACE_TEXT_REQUEST }
    }

    function success() {
        return { type: adminSearchConstants.FIND_AND_REPLACE_TEXT_SUCCESS }
    }

    function failure(error) {
        return { type: adminSearchConstants.FIND_AND_REPLACE_TEXT_FAILURE, error }
    }
}

const changeMessagePracticePriority = (messageId, isReviewed) => {
    const request = () => ({
        type: messageConstants.CHANGE_MESSAGE_PRACTICE_PRIORITY_REQUEST
    });

    const success = (payload, isReviewed) => ({
        type: messageConstants.CHANGE_MESSAGE_PRACTICE_PRIORITY_SUCCESS, payload: payload, updatedIsReviewed: isReviewed
    });

    const failure = (error) => ({
        type: messageConstants.CHANGE_MESSAGE_PRACTICE_PRIORITY_FAILURE, payload: error
    });

    return dispatch => {
        dispatch(request());
        messageService.patchMessageReview(messageId, isReviewed)
            .then(response => {
                    dispatch(success(response, isReviewed))
                    SnackBar.showMessage(alertMessages.MESSAGE_PRACTICE_PRIORITY_UPDATE_SUCCESS);
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR);
                },
            )
    };
}

const clearUpdatedPracticePriority = () => {
    return { type: messageConstants.CLEAR_UPDATED_MESSAGE_PRACTICE_PRIORITY };
}

const clearConversation = () => {
    return { type: conversationConstants.CLEAR_CONVERSATION };
}

export const messageActions = {
  getNext,
  getAll,
  getMessage,
  getMessageHistory,
  review,
  escalate,
  skipMessage,
  getConversation,
  getConversationFromTeams,
  getOriginalConversationFromTeams,
  getConversationFromTrends,
  getAllFromTrends,
  getAuthors,
  getPriorityMessages,
  getResponsePayload,
  getBilling,
  getDetailsFromTrends,
  getAdditionalTeamsDetails,
  searchAdminMessages,
  exportAdminSearch,
  blacklistMessages,
  blacklistAllMessages,
  getFullEmailConversation,
  clearVisitedPages,
  getAllMessages,
  getAllMessagesFromTrends,
  getPaidMessageAction,
  lockMessage,
  undoMessage,
  getTrendsCorrespondingMessages,
  getCompanyReplied,
  messagesForResponseGeneration,
  sendForResponseGeneration,
  getMessagesInTicket,
  getResponseTimes,
  getMessagesRequiringAction,
  deleteUserMessages,
  confirmMessageHasReplyOrIsDeleted,
  downloadAttachment,
  findAndReplaceText,
  tickMessageAsActioned,
  changeMessagePracticePriority,
  clearUpdatedPracticePriority,
  showNextVoteMessage,
  searchMessages,
  submitInternalNote,
  rerouteMessage,
  clearConversation,
  getMessageStatus,
  getInternalNoteAssignees
};
