import {Box} from "@mui/system";
import React from "react";
import Grid from "@mui/material/Grid";
import FacebookSetup from "../Meta/FacebookSetup";
import ShopifySetup from "../Shopify/ShopifySetup";
import TwitterSetup from "../Twitter/TwitterSetup";
import ZendeskSetup from "../Zendesk/ZendeskSetup";
import ZendeskChatSetup from "../Zendesk/ZendeskChatSetup";
import InstagramSetup from "../Meta/InstagramSetup";
import TikTokSetup from "../TikTok/TikTokSetup";

export default function ChannelsList() {

  return(
    <Grid container sx={{height: 'auto', marginBottom: '40px'}}
         mt={{xs: '30px', sm: '30px', md: '155px', lg: '155px'}}>
      <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginBottom: '20px'}}>
        <Box sx={{height: 'auto'}}
             marginRight={style.marginX}>
          <FacebookSetup/>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginBottom: '20px'}}>
        <Box sx={{height: 'auto'}}
             marginLeft={style.marginX}>
          <TikTokSetup/>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginBottom: '20px'}}>
        <Box sx={{height: 'auto'}}
             marginRight={style.marginX}>
          <InstagramSetup/>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginBottom: '20px'}} >
        <Box sx={{height: 'auto'}}
             marginLeft={style.marginX}>
          <TwitterSetup/>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginBottom: '20px'}}>
        <Box sx={{height: 'auto'}}
             marginRight={style.marginX}>
          <ZendeskSetup/>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginBottom: '0px'}}>
        <Box sx={{height: 'auto'}}
             marginLeft={style.marginX}>
          <ZendeskChatSetup/>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginBottom: '0px'}}
            marginTop={{xs: '20px', sm: '20px', md: '0', lg: '0'}}>
        <Box sx={{height: 'auto'}}
             marginRight={style.marginX}>
          <ShopifySetup/>
        </Box>
      </Grid>
    </Grid>
  )
}

const style = {
        marginX: {
          xs: '0',
          sm: '0',
          md: '12px',
          lg: '12px'
        }
}