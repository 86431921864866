import React, {Fragment} from "react";
import {Box} from "@mui/system";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {makeStyles} from "@mui/styles";
import businessLogo from "components/images/business.svg"

export default function PageRow({ page, pageId, key, value, label, input, disabled, disabledText }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  const addDefaultSrc = (ev) => {
    ev.target.src = businessLogo
  };

  return(
    <Fragment>
      <Box className={classes.container} key={key} {...input}>
        <Box className={classes.pageInfoContainer}>
          <Box className={classes.imageContainer}>
            <img onError={addDefaultSrc} src={"http://graph.facebook.com/" + pageId + "/picture?type=square"}
                 style={{width: '100%'}} alt="page thumbnail"/>
          </Box>
          <Box className={isMobile ? classes.pageNameMobile : classes.pageName}>
            <span style={disabled ? {color: '#a7a7a7'} : {}}>{label}</span>
            <span style={{fontStyle: 'italic', fontSize: '14px', color: '#a7a7a7'}}>{disabledText}</span>
          </Box>
        </Box>
        <Box className={classes.checkboxContainer}>
          <Checkbox
            value={value}
            disabled={disabled}
            sx={disabled ?
              {'& .MuiSvgIcon-root': {fontSize: '25px', color: '#c5c5c5'}} :
              {'& .MuiSvgIcon-root': {fontSize: '25px', color: '#368C86'}}} />
        </Box>
      </Box>
    </Fragment>
  )
}

const useStyles = makeStyles({
  pageName: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '18px',
    lineHeight: '120%',
    width: 'calc(100% - 47px)',
    padding: '0 15px',
  },
  pageNameMobile: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '16px',
    lineHeight: '120%',
    width: 'calc(100% - 47px)',
    padding: '0 10px'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0 20px 0'
  },
  pageInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    width: '100%'
  },
  imageContainer: {
    width: '47px',
    height: '47px',
    maxWidth: '47px',
    maxHeight: '47px',
    border: '1px solid #fafafa'
  },
  checkboxContainer: {
    width: 'fit-content',
    height: 'fit-content'
  },
});