import { kustomerAuthService } from '_services';
import {oauthConstants, alertMessages} from '_constants';
import {SnackBar} from "../components/common";


/**
 * This method handles verification of the user's credentials
 * @param params
 * @returns {Function}
 */

export const kustomerAuthActions = {
    verifyKey
};

function verifyKey(params) {
    function request() {
        return {type: oauthConstants.KUSTOMER_VERIFY_KEY_REQUEST};
    }

    function success() {
        return {type: oauthConstants.KUSTOMER_VERIFY_KEY_SUCCESS}
    }

    function failure() {
        return {type: oauthConstants.KUSTOMER_VERIFY_KEY_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        kustomerAuthService.verifyApiKey(params)
            .then(
                (res) => {
                    dispatch(success(res));

                    if (res === true){
                        SnackBar.showMessage(alertMessages.KUSTOMER_VERIFY_KEY_SUCCESS);
                        dispatch(createAccount(params))
                    }

                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.KUSTOMER_VERIFY_KEY_FAILURE);
                },
            );
    };
}

function createAccount (params){
    function request() {
        return {type: oauthConstants.KUSTOMER_CREATE_ACCOUNT_REQUEST};
    }

    function success(payload) {
        return {type: oauthConstants.KUSTOMER_CREATE_ACCOUNT_SUCCESS,payload}
    }

    function failure() {
        return {type: oauthConstants.KUSTOMER_CREATE_ACCOUNT_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        kustomerAuthService.createAccount(params)
            .then(
    (res) => {

                    dispatch(success(res));
                    kustomerAuthService.savePayload(res);
                    SnackBar.showMessage(alertMessages.KUSTOMER_CREATE_ACCOUNT_SUCCESS);
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.KUSTOMER_CREATE_ACCOUNT_FAILURE);
                },
            );
    };
}
