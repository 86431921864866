import {Box, Grid} from "@mui/material";
import commonStyles from "../commonStyles";
import CompletedIcon from '@mui/icons-material/CheckCircleOutline';
import InProgressIcon from '@mui/icons-material/QueryBuilder';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import {Fragment} from "react";
import {formatToDate} from "../../../../../../_helpers";

function RemovalProcessTitle({removalProcess}) {
    return (<Box component={'h5'}>Removal triggered at {formatToDate(removalProcess.dateCreated)}</Box>);
}

function RemovalStatus({removalProcess}) {
    switch (removalProcess.status) {
        case 'COMPLETED':
            return <CompletedIcon sx={styles.completedStatusIcon} />;
        case 'IN_PROGRESS':
            return <InProgressIcon sx={styles.inProgressStatusIcon} />;
        case 'FAILED':
            return <ErrorIcon sx={styles.failedStatusIcon} />;
        default:
            return <Fragment/>;

    }
}

export default function RemovalProcessSummary({removalProcess}) {
    return (
        <Grid container columns={2}>
            <Grid item xs={4} md={1} sx={styles.nameContainer}>
                <RemovalProcessTitle removalProcess={removalProcess}/>
            </Grid>
            <Grid item xs={4} md={1} sx={styles.statusContainer}>
                <Box compontent={'h5'} sx={styles.statusLabel}>Status</Box>
                <RemovalStatus removalProcess={removalProcess}/>
            </Grid>
        </Grid>
    );
}

const styles = {
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    statusLabel: {
        ...commonStyles.labelMedium,
        padding: 1,
    },
    inProgressStatusIcon: {
        color: '#07857C'
    },
    completedStatusIcon: {
        color: '#036006'
    },
    failedStatusIcon: {
        color: '#770000'
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: 4
    }
};