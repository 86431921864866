import React from 'react'
import {ControlLabel, FormGroup} from "react-bootstrap";
import FormControl from "react-bootstrap/es/FormControl";

class TextField extends React.Component {
    render() {

        const {placeHolder, label, style, input, ...rest} = this.props;

        return (

            <FormGroup controlId="formControlsSelect" hidden={this.props.hidden}>
                {label && <ControlLabel>{label}</ControlLabel>}
                <FormControl
                    className="form-control select-field color-grey-dark"
                    {...rest}
                    {...input}
                    placeholder={placeHolder}
                    onChange={input.onChange}
                    label='User Name'
                    style={style}
                    name={input.name}/>
            </FormGroup>

        )
    }
}


export {TextField}