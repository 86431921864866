const _getModule = (msgSourceName) => {
	let sourceName = msgSourceName ? msgSourceName.toLowerCase() : '';
	if (sourceName === 'front')
		sourceName = 'frontapp'
	if (sourceName === 'salesforce service cloud')
		sourceName = 'salesforce'
	if (sourceName === 'tiktok shop')
		sourceName = 'tiktok_shop'

	try {
		return require(`components/images/logos/logo_${sourceName}.svg`)
	} catch (error) {
		try {
			return require(`components/images/logos/logo_${sourceName}.png`)
		} catch (error) {
			return require('components/images/icon_business.png')
		}
	}
}

/**
 * Tries to fetch a matching logo for a given `msgSource.name` channel name. (e.g. Facebook, Instagram, etc.)
 * When it can't find a matching logo, it returns the default placeholder.
 * @param {string} msgSourceName From `msgSource.name`
 * @returns {string} URL of the logo.
 */
export default function getChannelImgUrl(msgSourceName) {
	try {
		const moduleData = _getModule(msgSourceName)
		if (!(moduleData && moduleData.default))
			throw new Error('No image returned')

		return moduleData.default
	} catch (error) {
		return null
	}
};