import { useEffect } from 'react'
import {useDispatch, useSelector} from "react-redux";
import {metricsActions} from "_actions";
import {ChannelMetricsBuilder} from "../objects/ChannelMetricsBuilder";
import _ from "lodash";
import {capitalize} from "_helpers";

export default function (startDate, endDate) {
  const dispatch = useDispatch();

  useEffect(() => {
    const { organization_id = '' } = JSON.parse(localStorage.user);
    dispatch(metricsActions.getChannelsMetrics(organization_id, startDate, endDate));
  }, [startDate, endDate])

  const metrics = useSelector(state => state.metrics);
  const channelsMetrics = metrics.channelsMetrics;
  const loading = metrics.loadingChannelsMetrics;
  const channelsTicketVolumeList = _.get(channelsMetrics, 'channelsTicketVolumeList') || [];
  const channelsData = [];

  if(!loading && channelsTicketVolumeList.length > 0) {
    channelsTicketVolumeList.map( channelsTicketVolume => {
      const builder = new ChannelMetricsBuilder();
      const msgSource = _.get(channelsTicketVolume, 'msgSource') || '';
      builder.setChannel(msgSource.toLowerCase());
      builder.setLabel(capitalize(msgSource));
      builder.setVolumeByUs(_.get(channelsTicketVolume, 'totalVolume') || 0);
      builder.setSubChannels(_.get(channelsTicketVolume, 'channels') || []);

      channelsData.push(builder.metrics);
    })
  }

  const _originalData = channelsData;
  return { _originalData, loading }
}