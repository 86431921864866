import {payoutsConstants} from '_constants';

const INITIAL_STATE = {
    all_payouts: [],
    payout: {},
    loading: true,
    error: '',
};

export function payoutsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case payoutsConstants.GET_ALL_PAYOUTS_REQUEST:
            return {...state, loading: true, error: ''};
        case payoutsConstants.GET_ALL_PAYOUTS_SUCCESS:
            return {...state, all_payouts: action.payload, loading: false};
        case payoutsConstants.GET_ALL_PAYOUTS_FAILURE:
            return {...state, error: action.error, loading: false};

        case payoutsConstants.GET_PAYOUT_REQUEST:
            return {...state, loading: true, error: ''};
        case payoutsConstants.GET_PAYOUT_SUCCESS:
            return {...state, payout: action.payload, loading: false};
        case payoutsConstants.GET_PAYOUT_FAILURE:
            return {...state, error: action.error, loading: false};

        case payoutsConstants.CREATE_PAYOUT_REQUEST:
            return {...state, loading: true, error: ''};
        case payoutsConstants.CREATE_PAYOUT_SUCCESS:
            return {...state, payout: action.payload, loading: false};
        case payoutsConstants.CREATE_PAYOUT_FAILURE:
            return {...state, error: action.error, loading: false};

        case payoutsConstants.UPDATE_PAYOUT_REQUEST:
            return {...state, loading: true, error: ''};
        case payoutsConstants.UPDATE_PAYOUT_SUCCESS:
            return {...state, payout: action.payload, loading: false};
        case payoutsConstants.UPDATE_PAYOUT_FAILURE:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}
