import React from 'react';
import { connect } from 'react-redux';
import { organizationActions, userActions } from '_actions';
import { getCurrentUser } from '_helpers';
import ReactPaginate from 'react-paginate';
import { Edit } from '@material-ui/icons'
import {Button} from "react-bootstrap";
import {GenericModal, Loading} from 'components/common';
import { NormalTextField } from 'components/common';
import generator from 'generate-password';

let styles = {
    teamInfoContainer: {
        lineHeight: '50px',
        paddingRight: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    breadcrumbContainer: {
        width: 'auto',
        height: 'auto',
        border: '1px solid transparent',
        marginBottom: '10px'
    },
    tableContainer: {
        width: 'auto',
        height: 'auto',
        border: '1px solid #dee2e0',
        borderRadius: '3px',
        marginBottom: '5px'
    },
};

class CompanyUsers extends React.Component {

    constructor(props) {
        super(props);

        const currentUser = getCurrentUser()

        this.state = {
            showModal: false,
            userId: null,
            modalHeaderText: '',
            modalForm: {
                firstName: '',
                lastName: '',
                username: '',
                role: '',
                organization: currentUser.organization_id ? currentUser.organization_id : '',
                enabled: false
            },
            params: {
                offset: 0,
                max: 10
            }
        }
    }

    componentDidMount() {
        const currentUser = getCurrentUser()
        const { params } = this.state
        if (currentUser.organization_id) {
            this.props.dispatch(organizationActions.getOrgUsers(currentUser.organization_id, params))
        }
    }

    handleFormChange = (e) => {
        let target = e.target
        this.setState((prevState) => {
            return {
                modalForm: {...prevState.modalForm,
                    [target.name]: target.value}
            }
        });
    }

    editUser = (userId) => {
        const { organizationDetails: { users }, currentRole } = this.props
        let user = users.find(user => user.id === userId)
        this.setState((prevState) => {
            return {
                showModal: true,
                userId: userId,
                modalHeaderText: 'Edit User',
                modalForm: {...prevState.modalForm,
                    firstName: user.firstName ? user.firstName : '',
                    lastName: user.lastName ? user.lastName : '',
                    username: user.username,
                    enabled: user.enabled,
                    role: currentRole,
                }
            }
        })
    }

    addUser = () => {
        const { currentRole } = this.props
        this.setState((prevState) => {
            return {
                showModal: true,
                userId: null,
                modalHeaderText: 'Add User',
                modalForm: {
                    ...prevState.modalForm,
                    firstName: '',
                    lastName: '',
                    username: '',
                    role: currentRole,
                }
            }
        })
    }

    saveUser = () => {
        const { userId, modalForm } = this.state
        const password = generator.generate({
            length: 12,
            numbers: true,
            uppercase: true,
            symbols: true,
            lowercase: true,
            strict: true,
        })

        if (userId) {
            const getCurrentUser = {...modalForm}
            const user = {...getCurrentUser, id: userId}
            this.props.dispatch(userActions.updateUser(user))
        } else {
            const user = {...modalForm, password: password, userToOrganization: true}
            this.props.dispatch(userActions.createUser(user))
        }
    }

    handleClose = () => {
        this.setState({
            showModal: false
        })
    }

    handlePageSelect = (page, params) => {
        const currentUser = getCurrentUser()
        this.setState((prevState) => {
            return {
                params: {
                    ...prevState.params,
                    offset: page.selected * prevState.params.max
                }
            }
        }, function() {
            this.props.dispatch(organizationActions.getOrgUsers(currentUser.organization_id, this.state.params))
        })
    }

    renderUserRow = (user) => {
        const { teamInfoContainer } = styles;

        return (
            <tr className="tms-cursor-pointer" key={user.id}>
                <td valign="center" style={teamInfoContainer}>{user.firstName ? user.firstName : 'N/A'}</td>
                <td valign="center">{user.lastName ? user.lastName : 'N/A'}</td>
                <td valign="center">{user.username}</td>
                <td valign="center">ADMIN</td>
                <td valign="center">{user.enabled ? 'Enabled' : 'Disabled'}</td>
                <td valign="center" className="trds-action-buttons-container">
                    <div>
                        <span className="span-table-data">
                            <Edit style={{position: 'relative', top: '6px'}}
                                  onClick={() => this.editUser(user.id)} />
                        </span>
                    </div>
                </td>
            </tr>
        )
    }

    render() {
        const { organizationDetails: { users, usersCount, params }, userLoading} = this.props
        const { offset, max } = params
        const { breadcrumbContainer, tableContainer } = styles;
        const { userId, modalForm, showModal, modalHeaderText } = this.state
        const totalPages = usersCount && max ? Math.ceil(usersCount / max) : false;
        const loader = <Loading height={20} color={'#fff'} size={20} />

        let modalContent = <form>
            <NormalTextField name="firstName" label="First Name"
                 value={modalForm.firstName} onChange={this.handleFormChange} />
            <NormalTextField name="lastName" label="Last Name"
                 value={modalForm.lastName} onChange={this.handleFormChange} />
            <NormalTextField name="username" label="Username" autoComplete="randomstring"
                value={modalForm.username} onChange={this.handleFormChange} />
        </form>

        let addToTeamModal;
        if (showModal) {
            addToTeamModal = <GenericModal
                onSave={this.saveUser}
                headerText={modalHeaderText}
                buttonText={userLoading ? loader : modalHeaderText}
                children={modalContent}
                onHide={this.handleClose}
                disabled={userLoading}
                show={this.state.showModal} />
        }

        return (
            <div style={{ padding: '0 38px' }}>
                {addToTeamModal}
                <div className="content-pane-90">
                    <div style={breadcrumbContainer}>
                        <span style={{
                            padding: '10px 0',
                            display: 'block'
                        }} className="color-grey-dark font-30-bold">Users</span>
                    </div>
                    <div style={tableContainer}>
                        <table className="trds-table users-table font-14 color-grey-dark">
                            <thead>
                            <tr>
                                <th className="trends-table-header-width-20">First Name</th>
                                <th className="trends-table-header-width-20">Last Name</th>
                                <th className="trends-table-header-width-25">Username</th>
                                <th className="trends-table-header-width-15">Role</th>
                                <th className="trends-table-header-width-10">Status</th>
                                <th className="trends-table-header-width-5"></th>
                            </tr>
                            </thead>
                            <tbody className="font-15">
                                {users.map(this.renderUserRow)}
                            </tbody>
                        </table>
                    </div>
                    <Button
                        bsStyle='success'
                        style={{ padding: '7px 12px' }}
                        onClick={this.addUser}>
                        Add User
                    </Button>

                    <div style={{ textAlign: 'left' }}>
                        {totalPages && totalPages > 1 &&
                            <ReactPaginate previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={<span>...</span>}
                            breakClassName={"break-me"}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            disableInitialCallback={true}
                            pageRangeDisplayed={5}
                            onPageChange={(page) => this.handlePageSelect(page, params)}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            params={params}
                            activePage={offset / max}
                            handleSelect={this.handleSelect}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { organizations: { organizationDetails }, users, roles, auth: { user }} = state;

    return {
        currentRole: roles.currentRoleId,
        organization: user._embedded.organization.id,
        organizationDetails,
        userLoading: users.loading
    };
}

const connectedCompanyUsers = connect(mapStateToProps)(CompanyUsers);
export { connectedCompanyUsers as CompanyUsers };

