import React, {Fragment} from "react";
import logoKustomer from 'components/images/logos/logo_kustomer.svg'
import ChannelCard from '../../ChannelCard'
import useKustomerSetup from "./useKustomerSetup";
import KustomerSetupModal from "./KustomerSetupModal";
import DataSourceWebhookModal from "../../Utilities/DataSourceWebhookModal";
import { useSelector } from "react-redux";

export default function KustomerEntry() {
	const kustomer = useSelector((state) => state.kustomerOauth)
	const { initiateKustomerSetup, kustomerSetupOpen, handleKustomerSetupOpen, handleKustomerSetupClose } = useKustomerSetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoKustomer}
				label={'Kustomer'}
				description={'Clear your email ticket backlog'}
				onClick={initiateKustomerSetup}
			/>

			<KustomerSetupModal
				open={kustomerSetupOpen}
				handleOpen={handleKustomerSetupOpen}
				handleClose={handleKustomerSetupClose}
			/>

			<DataSourceWebhookModal
				showWebhookUrl={kustomer?.showWebhookUrl}
				webhookUrl={kustomer?.data?.webhookUrl}
				platformUrl={kustomer?.data?.platformUrl}
				dataSourceName={'Kustomer'}
			/>
		</Fragment>
	)
}