import {msgDataSourceConstants, oauthConstants} from "_constants";

const INITIAL_STATE = {
    data: [],
    pagesNoAccess: [],
    credentials: {},
    error: '',
    loading: false,
    channel: 'facebook',
    list: []
};

export function facebookOauthReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case oauthConstants.FACEBOOK_PAGES_REQUEST:
            return {...state, loading: true};

        case oauthConstants.FACEBOOK_PAGES_SUCCESS:
            return {...state,
                data: action.payload,
                loading: false
            };

        case oauthConstants.FACEBOOK_PAGES_FAILURE:
            return {...state, error: action.payload, loading: false};

        case oauthConstants.FACEBOOK_PAGE_SUBMISSION_REQUEST:
            return {...state, loading: true};

        case oauthConstants.FACEBOOK_PAGE_SUBMISSION_SUCCESS:
            return {...state, loading: false, data: action.payload};

        case oauthConstants.FACEBOOK_PAGE_SUBMISSION_FAILURE:
            return {...state, loading: false, error: action.error};

        case msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false
            };

        case msgDataSourceConstants.ALL_DATA_SOURCE_ERROR:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}