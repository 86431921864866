import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AnalyticsTable = (props) => {
  const {
    analytics: {
      trendsList, teamsIncomingMessages,teamsPaidmessageList, paidMessagesChatDeskAdmin, paidMessagesExpert
    },

  } = props;
  return (
    <Fragment>
      {trendsList
                && (
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Total incoming messages in Trends</td>
                      <td>{trendsList.Incoming ? trendsList.Incoming : 0}</td>
                    </tr>
                    <tr>
                      <td>Total outgoing messages in Trends</td>
                      <td>{trendsList.Outgoing ? trendsList.Outgoing : 0}</td>
                    </tr>
                    <tr>
                      <td>Incoming messages  in Teams</td>
                      <td>{teamsIncomingMessages ? teamsIncomingMessages : 0 }</td>
                    </tr>

                    <tr>
                        <td>Total Paid messages in Teams</td>
                        <td>{teamsPaidmessageList ? teamsPaidmessageList : 0}</td>
                    </tr>
                    <tr>
                        <td>Total Paid messages sent by Chatdesk admin</td>
                        <td>{paidMessagesChatDeskAdmin}</td>
                    </tr>
                    <tr>
                        <td>Total Paid messages sent by experts</td>
                        <td>{paidMessagesExpert}</td>
                    </tr>
                  </tbody>
                </table>
                )
            }
    </Fragment>
  );
};

export default AnalyticsTable;

AnalyticsTable.propTypes = {
  analytics: PropTypes.object,
};
