const showConversationStyle = {
    typography: {
        fontSize:"15px",
    },

    messageContent: {
        borderTop:"1px solid #dee2e0",
        padding:"15px 0"
    },

    menuItem:{
        fontSize:"14px",
        margin:"0 20px",
        padding:"7px 2px",
        background:"#fff",
        borderBottom:"1px solid transparent"
    }
};

export { showConversationStyle }