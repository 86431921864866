import React, { Component, Fragment } from 'react'
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux'
import ReactPaginate from "react-paginate";
import { walletActions } from '_actions'
import {Link} from "react-router-dom";
import { AgentPayoutModal } from 'components/Payments'
import moment from 'moment/moment'

const spanStyle = {
    fontWeight: 'bold',
    marginRight: '15px',
    display: 'block'
};
const dataStyle = {
    marginTop: '3px',
    marginBottom: '3px',
};
const dataContainer = {
    display: 'flex',
    alignItems: 'center',
};

class PayoutHistoryDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            amount: '',
            showModal: false,
        };
    }

    handleChange = (event) => {
        const { target: { value }} = event
        this.setState({
            amount: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { amount } = this.state
        const { dispatch, agentDetails: { agentId }} = this.props
        dispatch(walletActions.payoutToAgent(agentId, amount))
    }

    componentDidMount() {
        const { dispatch, match: { params: { agentId }}} = this.props
        dispatch(walletActions.agentPayoutHistory(agentId))
    }

    componentDidUpdate() {
        const { handleModal } = this.props
        const { showModal } = this.state
        if (handleModal && showModal) {
            this.setState({showModal: false},
                function() {
                    const { dispatch } = this.props
                    dispatch(walletActions.handleModal())
                })
        }
    }

    render() {
        const { payoutHistory, agentDetails, params, total } = this.props
        const { showModal, amount } = this.state
        return (
            <div className="container-pane" style={{padding:"0 38px"}}>
                <div className="container">
                    <div className="shadow-border margin-bottom-50"
                         style={{marginLeft: "38px", padding: "20px 33px 33px 33px"}}>
                        <div className="content">
                            <div style={dataContainer}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to='/agents/payouts'
                                              className="btn btn-sm"
                                              style={{border: '1px solid #ededed', fontSize: '17px'}}>
                                            <i className="fa fa-arrow-left color-green"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4 text-center">
                                    <h2 style={dataStyle}>
                                        {agentDetails.name}
                                    </h2>
                                    <span style={spanStyle}>
                                        {agentDetails.username}
                                    </span>
                                </div>
                                <div className="col-md-4 text-center">
                                    <h5 style={spanStyle}>
                                        CURRENT BALANCE:
                                        {agentDetails.balance}
                                    </h5>
                                    <h5 style={spanStyle}>
                                        LIFETIME EARNING:
                                        {agentDetails.lifeTimeSpending}
                                    </h5>
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-info"
                                        onClick={() => this.setState({showModal: true})}>
                                        Payout {agentDetails.name}
                                    </button>
                                </div>
                            </div>
                            <hr />
                            {payoutHistory && payoutHistory.length > 0 &&
                                <Fragment>
                                    <h4>AGENT PAYOUTS HISTORY</h4>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Payout Date</th>
                                                <th>Payout By</th>
                                                <th>Payout Amount</th>
                                                <th>Balance After Payout</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {payoutHistory.map((item, index) =>
                                            <tr key={index}>
                                                <td>
                                                    {moment(item.dateCreated).format('dddd, MMMM Do YYYY, h:mm a')} <br />
                                                    ({moment(item.dateCreated).from(moment())})
                                                </td>
                                                <td>{`${item.payoutBy.name} (${item.payoutBy.username})`}</td>
                                                <td>{item.amount}</td>
                                                <td>{item.balanceAfterPayout}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                        {payoutHistory.length > 10 &&
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={5}>
                                                        {payoutHistory &&
                                                        <ReactPaginate previousLabel={"<"}
                                                               nextLabel={">"}
                                                               breakLabel={<span>...</span>}
                                                               breakClassName={"break-me"}
                                                               pageCount={total}
                                                               marginPagesDisplayed={3}
                                                               disableInitialCallback={true}
                                                               pageRangeDisplayed={3}
                                                            // forcePage={page}
                                                               onPageChange={(page) => this.handlePageSelect(page, params)}
                                                               containerClassName={"pagination"}
                                                               subContainerClassName={"pages pagination"}
                                                               activeClassName={"active"}
                                                        />
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        }
                                    </Table>
                                </Fragment>
                            }
                            {(! payoutHistory || (payoutHistory && payoutHistory.length === 0)) &&
                                <h5 className="text-center">No History Available</h5>
                            }
                        </div>
                    </div>
                </div>
                <AgentPayoutModal
                    amount={amount}
                    agent={agentDetails}
                    showModal={showModal}
                    onChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    currentBalance={agentDetails.balance}
                    closeModal={() => this.setState({showModal: false})}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { wallet: { payoutHistory, loading, handleModal }} = state
    return {
        payoutHistory: payoutHistory.payoutHistory,
        agentDetails: {
            name: payoutHistory.agentName,
            username: payoutHistory.username,
            agentId: payoutHistory.agentId,
            balance: payoutHistory.currentBalance,
            lifeTimeSpending: payoutHistory.lifeTimeSpending
        },
        loading, handleModal
    }
}

const connectedHistory = connect(mapStateToProps)(PayoutHistoryDetails)
export {connectedHistory as PayoutHistoryDetails }