import React, {Component} from 'react'
import { OauthWebhookModal } from 'components/AccountSettings/OauthWebhookModal'
import {connect} from 'react-redux'
import {history} from '_helpers';
import {alertActions, utilityActions} from "_actions";

const addModalStyles = {
    buttonContainer : {
        marginTop: '15px'
    },
    label: {
        paddingLeft: 10
    },
    centeredStyle: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
};

class DataSourceWebhookModal extends  Component {

    closeWebhookModal = () => {
        history.push('/company/channels')
    };

    copyToClipBoard = (event, textArea) => {
        const { dispatch } = this.props;
        dispatch(utilityActions.copyToClipboard(event, textArea));
        dispatch(alertActions.success('Copied!'));
    };

    render() {
        const { showWebhookUrl, webhookUrl, platformUrl, dataSourceName } = this.props;
        const modalTitle = `Copy Webhook URL and paste in ${dataSourceName}`;
        return(
            <OauthWebhookModal
                showWebhookUrl={showWebhookUrl}
                webhookUrl={webhookUrl}
                platformUrl={platformUrl}
                modalTitle={modalTitle}
                closeModal={this.closeWebhookModal}
                copyToClipboard={this.copyToClipBoard}
                modalStyles={addModalStyles}
            />
        )
    }
}

const connectedAddWebhook = connect(null)(DataSourceWebhookModal);
export { connectedAddWebhook as DataSourceWebhookModal }