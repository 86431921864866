import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { userActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import { UserForm } from "components/User";
import { formModes } from "_constants"
import { history } from "_helpers";


class EditUser extends Component {

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.dispatch(userActions.getUser(id));
    }

    saveClickHandler = (params) => {
        const updatePasswordRequest = {
            password: params.password
        }
        delete params.password
        this.props.dispatch(userActions.updateUserByAdmin(params, updatePasswordRequest));
    };

    cancelClickHandler = () => {
        history.push('/users')
    };

    render() {

        if (!this.props.user || !this.props.user.id) {
            return <Loading/>
        }

        const { user } = this.props;

        return (
            <Grid>

                <br/>
                <h1>Edit User</h1>
                <br/>
                <hr/>

                <Row>
                    <Col xs={8} xsOffset={2}>
                        <UserForm
                            editedBy='ADMIN'
                            onSave={this.saveClickHandler}
                            onCancelClick={this.cancelClickHandler}
                            user={user}
                            mode={formModes.EDIT}/>
                    </Col>
                </Row>

            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const { users: { user } } = state;

    return {
        user
    };
}

const connectedEditUser = connect(mapStateToProps)(EditUser);
export { connectedEditUser as EditUser };
