import {Fragment} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import BasicLink from "../../../../../common/BasicLink";
import CompanyResourcesMenu from "./CompanyResourcesMenu";
import {separateResources} from "../../../../Resources/Utilities/CompanyResourceUtils";
import useGetAllResourcesByOrganization from "../../../../Resources/Utilities/hooks/useGetAllResourcesByOrganization";
import Tooltip from "@mui/material/Tooltip";

export default function CompanyResources() {
    const {resources} = useGetAllResourcesByOrganization();

    if(_.isEmpty(resources)) {
        return <Fragment />
    }

    const {visibleList, hiddenList} = separateResources(resources);

    const Content = ({visibleResources}) => {
        return(
            visibleResources.map(resource => {
                const resourceName = resource.name;
                return (
                    <Box sx={styles.resourceLabel} component={'span'}>
                        <Tooltip
                            title={resourceName}
                            componentsProps={{
                                tooltip: { sx: styles.tooltip },
                            }}>
                            <Box component={'span'}>
                                <BasicLink label={`${resourceName}`} link={`${resource.link}`} openInNewTab/>
                            </Box>
                        </Tooltip>

                    </Box>
                )
            })
        )
    }

    return(
        <Box sx={styles.container}>
            <Box sx={styles.headingText}>
                COMPANY RESOURCES
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={styles.resourcesContainer}>
                    <Content visibleResources={visibleList} />
                </Box>
                <Box sx={{}}>
                    <CompanyResourcesMenu resources={hiddenList} />
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        p: {
            xs: '5px 20px 5px 20px',
            sm: '5px 20px 5px 20px',
            md: '20px 20px 20px 10px',
            lg: '20px 20px 20px 10px',
        },
    },
    headingText: {
        fontFamily: 'LarsseitBold',
        fontSize: '14px',
        lineHeight: 1.2,
        letterSpacing: '0.04em',
        color: '#0C2728',
        mb: '5px'
    },
    resourceLabel: {
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        lineHeight: 1.2,
        letterSpacing: '0.01em',
        color: '#07857C',
        mr: '20px'
    },
    resourcesContainer: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tooltip: {
        backgroundColor: '#DBD8FF',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        fontSize: '13px',
    }
}