import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {shopifyOauthActions} from "_actions";
import {Loading} from 'components/common';
import {history} from "_helpers";

export default function ShopifyUrlRedirect() {
    const dispatch = useDispatch();
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const isSignupFlow = url.pathname == '/signup/shopify-redirect'

    useEffect(() => {
        const shopifyHmac = values.get('hmac');
        const shopifyShop = values.get('shop');
        const successRedirectUrl = isSignupFlow ? '/signup/schedule' : null
        const errorRedirectUrl = isSignupFlow ? '/signup/add-channel' : null

        if (shopifyHmac && shopifyShop) {
            const params = {
                code: values.get('code'),
                hmac: shopifyHmac,
                host: values.get('host'),
                shop: shopifyShop,
                state: values.get('state'),
                timestamp: values.get('timestamp'),
            };
            dispatch(shopifyOauthActions.generateAccessToken(params, successRedirectUrl, errorRedirectUrl));
        }
    }, []);

    const shopify = useSelector((state) => state.shopifyOauth);

    if (shopify.loading) {
        return <Loading/>;
    }

    if (!isSignupFlow) {
        history.push('/company/connectchannels');
    }

    return (
        <Fragment/>
    )
}