import {IconButton, Menu, TableCell, TableRow, Typography} from "@material-ui/core";
import React, {Component} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {ScreenshotUserActions} from "./Actions";
import moment from "moment";
import {Link} from "react-router-dom";
import {HtmlTooltip} from "components/common";

export class ScreenshotRow extends Component{

    state = {
        anchorElement: null,
    };

    /**
     * Identifies the clicked item and opens the popup menu for the three dots
     * @param event
     */
    handleClick = event => {
        this.setState({anchorElement: event.currentTarget});
    };

    /**
     * Responsible for closing the popup menu
     */
    handleClose = () => {
        this.setState({anchorElement: null});
    };

    render(){

        const {screenshot, classes} = this.props;
        const {message: {text, id}, dateCreated, submittedBy, submittedFor} = screenshot;
        const zone_name =  moment.tz.guess();
        const timezone = moment.tz(zone_name).zoneAbbr();

        const { anchorElement } = this.state;
        const open = Boolean(anchorElement);
        let reporterName;
        let agentName;
        if (submittedFor) {
            const firstName = submittedFor.firstName;
            const lastName = submittedFor.firstName;
            agentName = firstName || lastName ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}` : submittedFor.username;
        }

        if (submittedBy) {
            const firstName = submittedBy.firstName;
            const lastName = submittedBy.lastName;
            reporterName = firstName || lastName ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}` : submittedBy.username;
        }

        const agentUsername = submittedFor ? submittedFor.username : ''

        return (
            <TableRow className={classes.tableRow}>
                <HtmlTooltip
                    title={
                        <div style={{width: 'fit-content', maxWidth: '700px', padding: '10px'}}>
                            <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                                {text}
                            </Typography>
                        </div>
                    }
                    placement={'bottom'}
                    style={{opacity: 1}}
                    enterDelay={700}
                >
                    <TableCell className={classes.tableCell} style={{width: "30%"}}>
                        <Link to={`messages/${id}?platform=teams-only`}>
                            <Typography className={classes.typography13}>
                                <span>{text}</span>
                            </Typography>
                        </Link>
                    </TableCell>
                </HtmlTooltip>
                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography13} title={agentName}>
                        <span>{agentName}</span>
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography13} title={agentUsername}>
                        <span>{agentUsername}</span>
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography13} title={reporterName}>
                        <span>{reporterName ? reporterName : ''}</span>
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography13}>
                        <span>
                            {`${moment(dateCreated).format('MMM DD hh:mm A z ')} ${timezone}`}
                        </span>
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        style={{padding: "5px"}}
                    >
                        <MoreVertIcon size="small"/>
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorElement}
                        open={open}
                        onClose={this.handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                                minWidth: 150,
                                border: "solid 1px #dee2e0",
                                boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)"
                            },
                        }}
                    >
                        <ScreenshotUserActions
                            screenshot={screenshot}
                            handleClose={this.handleClose}
                        />
                    </Menu>
                </TableCell>
            </TableRow>
        )
    }
}
