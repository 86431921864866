import styles from "./conversationStyles";
import {Box} from "@mui/system";
import AuthorInfo from "./AuthorInfo";
import MessageDate from "./MessageDate";
import _ from "lodash";
import EmailInfoList from "./EmailInfoList";

export default function MessageInfoContainer({conversationItem, message}) {
    return(
        <Box sx={styles.infoArea}>
            <Box sx={styles.infoContent}>
                <Box sx={styles.infoItem}>
                    <Box sx={styles.authorInfoContainer}>
                        <AuthorInfo conversationItem={conversationItem} message={message} />
                    </Box>
                    <Box sx={styles.dateContainer}>
                        <MessageDate date={_.get(conversationItem, 'createdDate')} />
                    </Box>
                </Box>
                <EmailInfoList conversationItem={conversationItem}  />
            </Box>
            <Box sx={styles.dateContainerMobile}>
                <MessageDate date={_.get(conversationItem, 'createdDate')} />
            </Box>
        </Box>
    )
}