import React, {useEffect, useState} from "react";
import {Box, useTheme, Tabs, Tab} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import Team from "./Team";
import ContinuePracticing from "./ContinuePracticing";
import WalletBalance from "./WalletBalance";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {
    filterObjectValue,
    getCurrentUser,
    history,
    setCurrentEmployment,
    setCurrentScheduledOrgs
} from "_helpers";
import {employmentActions} from "_actions";
import {clientType, requestOrigin} from "_constants";
import _ from "lodash";
import EastOutlinedIcon from '@mui/icons-material/EastOutlined'
import BasicButton from "../../common/BasicButton"
import {Link} from "react-router-dom";

export default function ExpertDashboard(){
    const theme = useTheme();
    const dispatch = useDispatch();
    const { id } = getCurrentUser();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [tabValue, setTabValue] = useState(0);
    const employments = useSelector(state => state.employment.userEmployments.employments);
    const organizationWithMessages = useSelector(state => state.employment.organizationWithMessages.organizationList);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const StyledTabs = styled((props) => (
        <Tabs
            {...props}
            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        />
    ))(
        {
            '& .MuiTabs-indicator': {
                backgroundColor: '#144042',
            },
        });

    const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
            textTransform: 'none',
            minWidth: isMobile ? "" : "50%",
            alignItems: 'flex-start',
            borderRadius: '0px',
            height: '55px',
            fontFamily: 'LarsseitMedium',
            fontSize: '18px',
            fontWeight: 500,
            color: '#495D5E',
            letterSpacing: '-0.02em',
            '&.Mui-selected': {
                color: '#495D5E',
            },
        }),
    );

    function handleSubmit(organizationId, scheduledOrgObj){
        const employment = employments.filter((employment) => {
            return employment.organization.id === organizationId
        })[0];

        setCurrentEmployment(employment);
        setCurrentScheduledOrgs(scheduledOrgObj);

        if(employment){
            history.push('/vote');
        }
    }

    let hiredOrgs = filterObjectValue(employments || [], 'status', 'HIRED');
    let unemployedOrgs = filterObjectValue(employments || [], 'status', 'UNEMPLOYED');

    if(organizationWithMessages && organizationWithMessages.length > 0){
        for (const property of hiredOrgs) {
            property.messageCount = 0;

            for(const item of organizationWithMessages){
                if(_.get(property, 'organization.id') === _.get(item, 'organization')){
                    property.messageCount = _.get(item, 'messageCount')
                }
            }
        }
    }

    hiredOrgs.sort((arg_1, arg_2) => (_.get(arg_1, 'messageCount') < _.get(arg_2, 'messageCount')) ? 1 : -1);

    useEffect(() => {
        dispatch(employmentActions.getUserEmployments(id,
            { visibility: ['PUBLIC', 'PRIVATE', 'FEATURED'], clientType: clientType.WEB_APP }, requestOrigin.AGENT_HOME
        ));
    },[])

    return(
        <Box sx={styles.rootWrapper}>
            <Box sx={{display: 'flex', gap: 3, alignItems: 'flex-start', flexDirection: isMobile ? 'column-reverse' : 'row'}}>
                <Box sx={{width: isMobile ? '100%' : '70%', paddingBottom: '100px'}}>
                    <Box sx={{backgroundColor: '#FFFFFF', border: '1px solid #CED4D4', borderBottom: 'none', borderRadius: '5px 5px 0px 0px'}}>
                        <StyledTabs value={tabValue} onChange={handleChange} aria-label="dashboard tabs">
                            <StyledTab label="On the team" />
                            <StyledTab label="Continue practicing" />
                        </StyledTabs>
                    </Box>

                    { tabValue === 0 && <Team employmentList={hiredOrgs} handleSubmit={handleSubmit} /> }
                    { tabValue === 1 && <ContinuePracticing employmentList={unemployedOrgs} handleSubmit={handleSubmit} /> }

                    <Link to="/employers" >
                        <BasicButton
                            label='View all companies'
                            endIcon={<EastOutlinedIcon/>}
                            style={styles.anchorBtnStyles}
                        />
                    </Link>
                </Box>

                <Box sx={isMobile ? {width: '100%'} : {width: '30%', maxWidth: '370px'}}>
                    <WalletBalance/>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    rootWrapper: {
        fontFamily: 'LarsseitRegular',
        color: '#0C2728',
        backgroundColor: '#EFEAE7',
        pt: 14,
        px: 4,
        minHeight: '100vh',
    },
    anchorBtnStyles: {
        border: '1px solid #368C86',
        backgroundColor: '#ffffff',
        width: 'fit-content',
        color: '#368C86',
        fontFamily: "LarsseitMedium",
    }
}