import React from "react";
import { Link } from "react-router-dom";
import { Row } from 'react-bootstrap';
import { userRoles as roles } from "_constants";
import messageIcon from "components/images/icon_message.svg";
import expertIcon from "components/images/icon_expert.svg";
import manageuserIcon from "components/images/icon_managuser.svg";
import roleIcon from "components/images/icon_role.svg";
import notificationsIcon from "components/images/notificationsIcon.svg";
import manageOrganisationIcon from "components/images/icon_organization.svg";
import scheduleIcon from "components/images/icon_schedule.svg";
import organizationSubcriptionIcon from "components/images/icon_organizationsubcription.svg";
import payoutIcon from "components/images/icon_payout.svg";
import searchIcon from "components/images/icon_search.svg";
import cameraIcon from "components/images/icon_camera.svg"
import perfectResponsesIcon from "components/images/icon_perfect.svg";
import adminPerformanceIcon from "components/images/icon_performance.svg";
import portalIcon from "components/images/icon_portal.svg";
import responseTimeIcon from "components/images/icon_responseTime.svg";
import msgDataSourceIcon from "components/images/icon_msgDataSource.svg";
import request_quoteIcon from "components/images/icon_request_quote.svg";
import monitorDataSourceStatus from "components/images/icon_analytics.svg";
import ProfitabilityIcon from "components/images/icon_insights.svg";
import messagesRequiringActionIcon from "components/images/icon_messagesRequiringAction.svg"
import openTicketIcon from "components/images/icon_ticket_open.svg"
import campaignIcon from "components/images/icons/icon_campaign.svg"
import companyResourcesIcon from "components/images/icon_company_resources.svg"
import organizationConfigIcon from "components/images/icon_settings.svg";

const ChatdeskAdminDashboard = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const currentUserRoles = loggedInUser && loggedInUser.roles && loggedInUser.roles[0];
  const isBillingAdmin = loggedInUser && currentUserRoles === roles.BILLING_ADMIN_ROLE;
  const isChatDeskAdmin = loggedInUser && currentUserRoles === roles.CHATDESK_ADMIN_ROLE;
  const allRoles = isBillingAdmin || isChatDeskAdmin;
  const containerStyle = {backgroundColor: '#fff', paddingTop: 30, paddingBottom: 30}

  const adminDashboardItems = [
    {
      name: "Messages",
      icon: `${messageIcon}`,
      link: `/messages`,
      permission: allRoles
    },
    {
      name: "Experts",
      icon: `${expertIcon}`,
      link: `/experts`,
      permission: allRoles
    },
    {
      name: "Perfect Responses Summary",
      icon: `${perfectResponsesIcon}`,
      link: `/perfectResponses`,
      permission: allRoles
    },
    {
      name: "Admin Performance",
      icon: `${adminPerformanceIcon}`,
      link: `/adminPerformance`,
      permission: allRoles
    },
    {
      name: "Manage Users",
      icon: `${manageuserIcon}`,
      link: `/users`,
      permission: isBillingAdmin
    },
    {
      name: "Manage Roles",
      icon: `${roleIcon}`,
      link: `/roles`,
      permission: allRoles
    },
    {
      name: "Manage Organisations",
      icon: `${manageOrganisationIcon}`,
      link: `/organizations`,
      permission: allRoles
    },
    {
      name: "Schedules",
      icon: `${scheduleIcon}`,
      link: `/schedules`,
      permission: allRoles
    },
    {
      name: "Organisations Subscription",
      icon: `${organizationSubcriptionIcon}`,
      link: "/organizations/subscriptions",
      permission: isBillingAdmin
    },
    {
      name: "Agents Payouts",
      icon: `${payoutIcon}`,
      link: "/agents/payouts",
      permission: isBillingAdmin
    },
    {
      name: "Search",
      icon: `${searchIcon}`,
      link: "/search",
      permission: allRoles
    },
    {
      name: "Portal",
      icon: `${portalIcon}`,
      link: "/portal",
      permission: isBillingAdmin
    },
    {
      name: "Message Data Source",
      icon: `${msgDataSourceIcon}`,
      link: "/dataSource",
      permission: allRoles
    },
    {
      name: "Message Data Source Handles",
      icon: `${msgDataSourceIcon}`,
      link: "/dataSourceHandles",
      permission: allRoles
    },
    {
      name: "Billing Summary",
      icon: `${request_quoteIcon}`,
      link: "/billing-summary",
      permission: isBillingAdmin
    },

    {
      name: "Screenshots",
      icon: `${cameraIcon}`,
      link: `/screenshots`,
      permission: isChatDeskAdmin
    },

    {
      name: "Monitor Integrations",
      icon: `${monitorDataSourceStatus}`,
      link: "/showDataSourceStatus",
      permission: allRoles
    },

    {
      name: "Profitability",
      icon: `${ProfitabilityIcon}`,
      link: "/profitability",
      permission: isBillingAdmin
    },
    {
      name: "Slipping Report",
      icon: `${adminPerformanceIcon}`,
      link: "/slipping-report",
      permission: isBillingAdmin
    },
    {
      name: "Messages Requiring Action",
      icon: `${messagesRequiringActionIcon}`,
      link: "/messagesRequiringAction",
      permission: allRoles
    },
    {
      name: "Response Times",
      icon: `${responseTimeIcon}`,
      link: "/responseTimes",
      permission: allRoles
    },
    {
      name: "Similarity Score Stats",
      icon: `${adminPerformanceIcon}`,
      link:  "/similarityScore",
      permission: allRoles
    },
    {
      name: "Company Updates",
      icon: `${notificationsIcon}`,
      link:  "/companyUpdates",
      permission: allRoles
    },
    {
      name: "Open Tickets Count",
      icon: `${openTicketIcon}`,
      link:  "/open-tickets",
      permission: allRoles
    },
    {
      name: "Campaign",
      icon: `${campaignIcon}`,
      link:  "/admin/campaign",
      permission: isChatDeskAdmin
    },
    {
      name: "Company Resources",
      icon: `${companyResourcesIcon}`,
      link:  "/company-resources",
      permission: allRoles
    },
    {
      name: "Organizations Configuration",
      icon: `${organizationConfigIcon}`,
      link:  "/organizations/settings",
      permission: allRoles
    }
  ];

  return (

    <div className="main-content-wrapper top-rel-100" style={containerStyle}>
        <div className="container">
            <Row>
                {adminDashboardItems
                    .filter(item => item.permission)
                    .map((itemData, index) => (
                    <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                        <Link to={itemData.link}
                            className="admin-card-container tms-cursor-pointer shadow-border">
                            <div className="icon-container">
                                <img src={itemData.icon} alt={itemData.name} />
                            </div>
                            <div className="admin-card-label color-grey-dark capitalize font-14">
                                {itemData.name}
                            </div>
                        </Link>
                    </div>
                ))}
            </Row>
        </div>
    </div>
  );
};

export { ChatdeskAdminDashboard };
