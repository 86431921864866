import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Box} from "@mui/system";
import {RadioGroup} from "@mui/material";
import _ from "lodash";
import {processCannotResolveOptions} from "_helpers";
import CannotResolveOption from "./CannotResolveOption";
import ActionButton from "../../../../../common/ActionButton";
import cannotResolveOptions from "./CannotResolveOptions";
import useViewNextMessage from "../../../Utilities/hooks/useViewNextMessage";
import {buildCannotResolvePayload} from "../../../Utilities/VoteUtils";
import {submitReason} from "./CannotResolveUtils";
import {cannotResolveOptionType} from "_constants";
import InternalNoteEntry from "./InternalNote/InternalNoteEntry";

export default function CannotResolveContainer({handleClose, handleOptionSubmitted}) {
    const [optionType, setOptionType] = useState('');
    const {message} = useViewNextMessage();
    const messageHandleType = _.get(message, 'dataSourceHandle.type');
    const sourceName = _.toString(_.get(message, 'sourceName')).toLowerCase();
    const options = processCannotResolveOptions(cannotResolveOptions, messageHandleType, sourceName);
    const dispatch = useDispatch();
    const [internalNoteSelected, setInternalNoteSelected] = useState(false);

    const handleChange = (event) => {
        setOptionType(_.get(event, 'target.value'));
    }

    const handleSubmit = () => {
        if(_.isEmpty(optionType)) return;
        const payload = buildCannotResolvePayload(message, optionType);
        submitReason(dispatch, payload, optionType);
        handleOptionSubmitted(true);
    }

    const handleOptionChange = (event) => {
        const defaultValue = _.get(event, 'target.defaultValue');
        handleInternalNote(defaultValue);
    }

    const handleInternalNote = (selectedOption) => {
        const isInternalNote = selectedOption === cannotResolveOptionType.INTERNAL_NOTE;
        setInternalNoteSelected(isInternalNote);
    }

    const Content = ({options}) => {
        return options.map((option, index) => {
            return(
                <Box key={index}>
                    <CannotResolveOption option={option} onChange={handleOptionChange} />
                </Box>
            )
        });
    }

    const ActionButtonOption = ({isInternalNote}) => {
        if(isInternalNote) return <InternalNoteEntry handleOptionSubmitted={handleOptionSubmitted} />
        return (
            <ActionButton
                label={'Submit reason'}
                action={handleSubmit}
                withIcon={false}
                sx={style.submitReason}
            />
        )
    }

    return(
        <Box sx={{marginTop: '-8px'}}>
            <Box sx={style.heading}>Select a reason that this message cannot be resolved:</Box>
            <RadioGroup name="cannot-resolve-options-group" onChange={handleChange}>
                <Content options={options} />
            </RadioGroup>
            <Box sx={style.buttonContainer}>
                <ActionButton
                    label={'Cancel'}
                    action={handleClose}
                    buttonBordered
                    useBorderColor={'#07857C'}
                    withIcon={false}
                    sx={style.cancel}
                />
                <ActionButtonOption isInternalNote={internalNoteSelected} />
            </Box>
        </Box>
    )
}

const style = {
    heading: {
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#0C2728',
        letterSpacing: '-0.01em',
        marginBottom: '12px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '27px'
    },
    cancel: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '8px'
    },
    submitReason: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}