import {oauthConstants} from "_constants";


const INITIAL_STATE = {
    data: [],
    error: '',
    loading: false,
    status: '',
    channel: 'twitter'
};

export function twitterOauthReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case oauthConstants.TWITTER_OAUTH_URL_REQUEST:
            return {...state, loading: true};
        case oauthConstants.TWITTER_OAUTH_URL_SUCCESS:
            return {...state, data: action.payload, loading: false,};
        case oauthConstants.TWITTER_OAUTH_URL_FAILURE:
            return {...state, error: action.error, loading: false};

        case oauthConstants.TWITTER_ACCESS_TOKEN_REQUEST:
            return {...state, loading: true};
        case oauthConstants.TWITTER_ACCESS_TOKEN_SUCCESS:
            return {...state, loading: false, data: action.payload};
        case oauthConstants.TWITTER_ACCESS_TOKEN_FAILURE:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}