export const oauthConstants = {
    TWITTER_OAUTH_URL_REQUEST: 'TWITTER_GET_AUTH_URL_REQUEEST',
    TWITTER_OAUTH_URL_SUCCESS: 'TWITTER_AUTH_URL_SUCCESS',
    TWITTER_OAUTH_URL_FAILURE: 'TWITTER_AUTH_URL_FAILURE',

    TWITTER_ACCESS_TOKEN_REQUEST: 'TWITTER_ACCESS_TOKEN_REQUEST',
    TWITTER_ACCESS_TOKEN_SUCCESS: 'TWITTER_ACCESS_TOKEN_SUCCESS',
    TWITTER_ACCESS_TOKEN_FAILURE: 'TWITTER_ACCESS_TOKEN_FAILURE',

    FACEBOOK_CREDENTIALS_REQUEST: 'FACEBOOK_CREDENTIALS_REQUEST',
    FACEBOOK_CREDENTIALS_SUCCESS: 'FACEBOOK_CREDENTIALS_SUCCESS',
    FACEBOOK_CREDENTIALS_FAILURE: 'FACEBOOK_CREDENTIALS_FAILURE',

    FACEBOOK_PAGES_REQUEST: 'FACEBOOK_PAGES_REQUEST',
    FACEBOOK_PAGES_SUCCESS: 'FACEBOOK_PAGES_SUCCESS',
    FACEBOOK_PAGES_FAILURE: 'FACEBOOK_PAGES_FAILURE',

    FACEBOOK_PAGE_SUBMISSION_REQUEST: 'FACEBOOK_PAGES_SUBMISSION_REQUEST',
    FACEBOOK_PAGE_SUBMISSION_SUCCESS: 'FACEBOOK_PAGES_SUBMISSION_SUCCESS',
    FACEBOOK_PAGE_SUBMISSION_FAILURE: 'FACEBOOK_PAGES_SUBMISSION_FAILURE',

    INSTAGRAM_CREDENTIALS_REQUEST: 'INSTAGRAM_CREDENTIALS_REQUEST',
    INSTAGRAM_CREDENTIALS_SUCCESS: 'INSTAGRAM_CREDENTIALS_SUCCESS',
    INSTAGRAM_CREDENTIALS_FAILURE: 'INSTAGRAM_CREDENTIALS_FAILURE',

    INSTAGRAM_PAGES_REQUEST: 'INSTAGRAM_PAGES_REQUEST',
    INSTAGRAM_PAGES_FAILURE: 'IINSTAGRAM_PAGES_FAILURE',
    INSTAGRAM_PAGES_SUCCESS: 'INSTAGRAM_PAGES_SUCCESS',

    INSTAGRAM_PAGE_SUBMISSION_REQUEST: 'INSTAGRAM_PAGES_SUBMISSION_REQUEST',
    INSTAGRAM_PAGE_SUBMISSION_FAILURE: 'INSTAGRAM_PAGES_SUBMISSION_FAILURE',
    INSTAGRAM_PAGE_SUBMISSION_SUCCESS: 'INSTAGRAM_PAGES_SUBMISSION_SUCCESS',

    ZENDESK_SUBMIT_SUBDOMAIN_REQUEST: 'ZENDESK_SUBDOMAIN_REQUEST',
    ZENDESK_SUBMIT_SUBDOMAIN_SUCCESS: 'ZENDESK_SUBDOMAIN_SUCCESS',
    ZENDESK_SUBMIT_SUBDOMAIN_FAILURE: 'ZENDESK_SUBDOMAIN_FAILURE',

    ZENDESK_GENTOKEN_REQUEST: 'ZENDESK_GENTOKEN_REQUEST',
    ZENDESK_GENTOKEN_SUCCESS: 'ZENDESK_GENTOKEN_SUCCESS',
    ZENDESK_GENTOKEN_FAILURE: 'ZENDESK_GENTOKEN_FAILURE',

    GORGIAS_SUBMIT_SUBDOMAIN_REQUEST: 'GORGIAS_SUBDOMAIN_REQUEST',
    GORGIAS_SUBMIT_SUBDOMAIN_SUCCESS: 'GORGIAS_SUBDOMAIN_SUCCESS',
    GORGIAS_SUBMIT_SUBDOMAIN_FAILURE: 'GORGIAS_SUBDOMAIN_FAILURE',

    GORGIAS_GENTOKEN_REQUEST: 'GORGIAS_GENTOKEN_REQUEST',
    GORGIAS_GENTOKEN_SUCCESS: 'GORGIAS_GENTOKEN_SUCCESS',
    GORGIAS_GENTOKEN_FAILURE: 'GORGIAS_GENTOKEN_FAILURE',

    FRONTAPP_OAUTH_URL_REQUEST: 'FRONTAPP_GET_AUTH_URL_REQUEST',
    FRONTAPP_OAUTH_URL_SUCCESS: 'FRONTAPP_GET_AUTH_URL_SUCCESS',
    FRONTAPP_OAUTH_URL_FAILURE: 'FRONTAPP_GET_AUTH_URL_FAILURE',

    FRONTAPP_ACCESSTOKEN_REQUEST: 'FRONTAPP_GET_ACCESSTOKEN_REQUEST',
    FRONTAPP_ACCESSTOKEN_SUCCESS: 'FRONTAPP_GET_ACCESSTOKEN_SUCCESS',
    FRONTAPP_ACCESSTOKEN_FAILURE: 'FRONTAPP_GET_ACCESSTOKEN_FAILURE',

    FRONTAPP_INBOX_REQUEST: 'FRONTAPP_GET_INBOX_REQUEST',
    FRONTAPP_INBOX_SUCCESS: 'FRONTAPP_GET_INBOX_SUCCESS',
    FRONTAPP_INBOX_FAILURE: 'FRONTAPP_GET_INBOX_FAILURE',

    STRIPE_OAUTH_URL_REQUEST: 'STRIPE_OAUTH_URL_REQUEST',
    STRIPE_OAUTH_URL_SUCCESS: 'STRIPE_OAUTH_URL_SUCCESS',
    STRIPE_OAUTH_URL_FAILURE: 'STRIPE_OAUTH_URL_FAILURE',

    STRIPE_OAUTH_CODE_REQUEST: 'STRIPE_OAUTH_CODE_REQUEST',
    STRIPE_OAUTH_CODE_SUCCESS: 'STRIPE_OAUTH_CODE_SUCCESS',
    STRIPE_OAUTH_CODE_FAILURE: 'STRIPE_OAUTH_CODE_FAILURE',

    SLACK_OAUTH_URL_REQUEST: 'SLACK_OAUTH_URL_REQUEST',
    SLACK_OAUTH_URL_SUCCESS: 'SLACK_OAUTH_URL_SUCCESS',
    SLACK_OAUTH_URL_FAILURE: 'SLACK_OAUTH_URL_FAILURE',

    SLACK_OAUTH_CODE_REQUEST: 'SLACK_OAUTH_CODE_REQUEST',
    SLACK_OAUTH_CODE_SUCCESS: 'SLACK_OAUTH_CODE_SUCCESS',
    SLACK_OAUTH_CODE_FAILURE: 'SLACK_OAUTH_CODE_FAILURE',

    SLACK_OAUTH_DISCONNECT_REQUEST: 'SLACK_OAUTH_DISCONNECT_REQUEST',
    SLACK_OAUTH_DISCONNECT_SUCCESS: 'SLACK_OAUTH_DISCONNECT_SUCCESS',
    SLACK_OAUTH_DISCONNECT_FAILURE: 'SLACK_OAUTH_DISCONNECT_FAILURE',

    GORGIAS_AUTH_REQUEST: 'GORGIAS_AUTH_REQUEST',
    GORGIAS_AUTH_SUCCESS: 'GORGIAS_AUTH_SUCCESS',
    GORGIAS_AUTH_FAILURE: 'GORGIAS_AUTH_FAILURE',

    TEAMS_FACEBOOK_APP_PERMISSIONS: `pages_show_list,pages_messaging,read_page_mailboxes,ads_management,instagram_basic,
    instagram_manage_comments,instagram_manage_insights,business_management,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,
    pages_manage_posts,pages_manage_engagement,instagram_manage_messages`,

    FRESHDESK_AUTH_REQUEST: 'FRESHDESK_AUTH_REQUEST',
    FRESHDESK_AUTH_SUCCESS: 'FRESHDESK_AUTH_SUCCESS',
    FRESHDESK_AUTH_FAILURE: 'FRESHDESK_AUTH_FAILURE',

    KUSTOMER_VERIFY_KEY_REQUEST: 'KUSTOMER_VERIFY_KEY_REQUEST',
    KUSTOMER_VERIFY_KEY_SUCCESS: 'KUSTOMER_VERIFY_KEY_SUCCESS',
    KUSTOMER_VERIFY_KEY_FAILURE: 'KUSTOMER_VERIFY_KEY_FAILURE',

    KUSTOMER_CREATE_ACCOUNT_REQUEST: 'KUSTOMER_CREATE_ACCOUNT_REQUEST',
    KUSTOMER_CREATE_ACCOUNT_SUCCESS: 'KUSTOMER_CREATE_ACCOUNT_SUCCESS',
    KUSTOMER_CREATE_ACCOUNT_FAILURE: 'KUSTOMER_CREATE_ACCOUNT_FAILURE',

    KUSTOMER_CLOSE_MODAL: 'KUSTOMER_CLOSE_MODAL',
    FRONTAPP_CLOSE_MODAL: 'FRONTAPP_CLOSE_MODAL',
    FRESHDESK_CLOSE_MODAL: 'FRESHDESK_CLOSE_MODAL',

    HELPSCOUT_OAUTH_URL_REQUEST: 'HELPSCOUT_GET_AUTH_URL_REQUEEST',
    HELPSCOUT_OAUTH_URL_SUCCESS: 'HELPSCOUT_AUTH_URL_SUCCESS',
    HELPSCOUT_OAUTH_URL_FAILURE: 'HELPSCOUT_AUTH_URL_FAILURE',

    HELPSCOUT_ACCESSTOKEN_REQUEST: 'HELPSCOUT_GET_ACCESSTOKEN_REQUEST',
    HELPSCOUT_ACCESSTOKEN_SUCCESS: 'HELPSCOUT_GET_ACCESSTOKEN_SUCCESS',
    HELPSCOUT_ACCESSTOKEN_FAILURE: 'HELPSCOUT_GET_ACCESSTOKEN_FAILURE',

    REAMAZE_AUTH_REQUEST: 'REAMAZE_AUTH_REQUEST',
    REAMAZE_AUTH_SUCCESS: 'REAMAZE_AUTH_SUCCESS',
    REAMAZE_AUTH_FAILURE: 'REAMAZE_AUTH_FAILURE',
    REAMAZE_CLOSE_MODAL:  'REAMAZE_CLOSE_MODAL',

    SALESFORCE_OAUTH_URL_REQUEST: 'SALESFORCE_GET_AUTH_URL_REQUEST',
    SALESFORCE_OAUTH_URL_SUCCESS: 'SALESFORCE_AUTH_URL_SUCCESS',
    SALESFORCE_OAUTH_URL_FAILURE: 'SALESFORCE_AUTH_URL_FAILURE',

    SALESFORCE_ACCESSTOKEN_REQUEST: 'SALESFORCE_GET_ACCESSTOKEN_REQUEST',
    SALESFORCE_ACCESSTOKEN_SUCCESS: 'SALESFORCE_GET_ACCESSTOKEN_SUCCESS',
    SALESFORCE_ACCESSTOKEN_FAILURE: 'SALESFORCE_GET_ACCESSTOKEN_FAILURE',

    GLADLY_AUTH_REQUEST: 'GLADLY_AUTH_REQUEST',
    GLADLY_AUTH_SUCCESS: 'GLADLY_AUTH_SUCCESS',
    GLADLY_AUTH_FAILURE: 'GLADLY_AUTH_FAILURE',
    GLADLY_CLOSE_MODAL:  'GLADLY_CLOSE_MODAL',

    TIKTOK_OAUTH_URL_REQUEST: 'TIKTOK_GET_AUTH_URL_REQUEST',
    TIKTOK_OAUTH_URL_SUCCESS: 'TIKTOK_AUTH_URL_SUCCESS',
    TIKTOK_OAUTH_URL_FAILURE: 'TIKTOK_AUTH_URL_FAILURE',

    TIKTOK_ACCESSTOKEN_REQUEST: 'TIKTOK_GET_ACCESSTOKEN_REQUEST',
    TIKTOK_ACCESSTOKEN_SUCCESS: 'TIKTOK_GET_ACCESSTOKEN_SUCCESS',
    TIKTOK_ACCESSTOKEN_FAILURE: 'TIKTOK_GET_ACCESSTOKEN_FAILURE',

    TIKTOK_SEARCHKEYWORD_REQUEST: 'TIKTOK_UPDATE_SEARCHKEYWORD_REQUEST',
    TIKTOK_SEARCHKEYWORD_SUCCESS: 'TIKTOK_UPDATE_SEARCHKEYWORD_SUCCESS',
    TIKTOK_SEARCHKEYWORD_FAILURE: 'TIKTOK_UPDATE_SEARCHKEYWORD_FAILURE',

    TIKTOK_ADS_OAUTH_URL_REQUEST: 'TIKTOK_ADS_GET_AUTH_URL_REQUEST',
    TIKTOK_ADS_OAUTH_URL_SUCCESS: 'TIKTOK_ADS_AUTH_URL_SUCCESS',
    TIKTOK_ADS_OAUTH_URL_FAILURE: 'TIKTOK_ADS_AUTH_URL_FAILURE',

    TIKTOK_ADS_ACCESSTOKEN_REQUEST: 'TIKTOK_ADS_GET_ACCESSTOKEN_REQUEST',
    TIKTOK_ADS_ACCESSTOKEN_SUCCESS: 'TIKTOK_ADS_GET_ACCESSTOKEN_SUCCESS',
    TIKTOK_ADS_ACCESSTOKEN_FAILURE: 'TIKTOK_ADS_GET_ACCESSTOKEN_FAILURE',

    SHOPIFY_SUBMIT_SUBDOMAIN_REQUEST: 'SHOPIFY_SUBDOMAIN_REQUEST',
    SHOPIFY_SUBMIT_SUBDOMAIN_SUCCESS: 'SHOPIFY_SUBDOMAIN_SUCCESS',
    SHOPIFY_SUBMIT_SUBDOMAIN_FAILURE: 'SHOPIFY_SUBDOMAIN_FAILURE',

    SHOPIFY_GENTOKEN_REQUEST: 'SHOPIFY_GENTOKEN_REQUEST',
    SHOPIFY_GENTOKEN_SUCCESS: 'SHOPIFY_GENTOKEN_SUCCESS',
    SHOPIFY_GENTOKEN_FAILURE: 'SHOPIFY_GENTOKEN_FAILURE',

    GORGIAS_SUPPORT_EMAIL_REQUEST: 'GORGIAS_SUPPORT_EMAIL_REQUEST',
    GORGIAS_SUPPORT_EMAIL_SUCCESS: 'GORGIAS_SUPPORT_EMAIL_SUCCESS',
    GORGIAS_SUPPORT_EMAIL_FAILURE: 'GORGIAS_SUPPORT_EMAIL_FAILURE',

    FACEBOOK_CHANNEL: 'Facebook',
    INSTAGRAM_CHANNEL: 'Instagram',

    GLADLY_INBOX_REQUEST: 'GLADLY_INBOX_REQUEST',
    GLADLY_INBOX_SUCCESS: 'GLADLY_INBOX_SUCCESS',
    GLADLY_INBOX_FAILURE: 'GLADLY_INBOX_FAILURE',

    MSG_DATA_SOURCE_AUTO_SENDER_SUCCESS: 'MSG_DATA_SOURCE_AUTO_SENDER_SUCCESS',
    MSG_DATA_SOURCE_AUTO_SENDER_FAILURE: 'MSG_DATA_SOURCE_AUTO_SENDER_FAILURE',

    MSG_DATA_SOURCE_AUTO_SENDER_SAVE_REQUEST: 'MSG_DATA_SOURCE_AUTO_SENDER_SAVE_REQUEST',
    MSG_DATA_SOURCE_AUTO_SENDER_SAVE_SUCCESS: 'MSG_DATA_SOURCE_AUTO_SENDER_SAVE_SUCCESS',
    MSG_DATA_SOURCE_AUTO_SENDER_SAVE_FAILURE: 'MSG_DATA_SOURCE_AUTO_SENDER_SAVE_FAILURE',
    MSG_DATA_SOURCE_AUTO_SENDER_SAVE_CLEAR: 'MSG_DATA_SOURCE_AUTO_SENDER_SAVE_CLEAR',
    MSG_DATA_SOURCE_AUTO_SENDER_DELETE_SUCCESS: 'MSG_DATA_SOURCE_AUTO_SENDER_DELETE_SUCCESS',
    MSG_DATA_SOURCE_AUTO_SENDER_DELETE_FAILURE: 'MSG_DATA_SOURCE_AUTO_SENDER_DELETE_FAILURE',

    TRUSTPILOT_OAUTH_INITIATE_REQUEST: 'TRUSTPILOT_OAUTH_INITIATE_REQUEST',
    TRUSTPILOT_OAUTH_INITIATE_SUCCESS: 'TRUSTPILOT_OAUTH_INITIATE_SUCCESS',
    TRUSTPILOT_OAUTH_INITIATE_FAILURE: 'TRUSTPILOT_OAUTH_INITIATE_FAILURE',

    TRUSTPILOT_SAVE_CLIENT_APP_DATA_REQUEST: 'TRUSTPILOT_SAVE_CLIENT_APP_DATA_REQUEST',
    TRUSTPILOT_SAVE_CLIENT_APP_DATA_SUCCESS: 'TRUSTPILOT_SAVE_CLIENT_APP_DATA_SUCCESS',
    TRUSTPILOT_SAVE_CLIENT_APP_DATA_FAILURE: 'TRUSTPILOT_SAVE_CLIENT_APP_DATA_FAILURE',

    TRUSTPILOT_ACCESSTOKEN_REQUEST: 'TRUSTPILOT_ACCESSTOKEN_REQUEST',
    TRUSTPILOT_ACCESSTOKEN_SUCCESS: 'TRUSTPILOT_ACCESSTOKEN_SUCCESS',
    TRUSTPILOT_ACCESSTOKEN_FAILURE: 'TRUSTPILOT_ACCESSTOKEN_FAILURE',

    TRUSTPILOT_OAUTH_CLEAR_REQUEST_PARAMS: 'TRUSTPILOT_OAUTH_CLEAR_REQUEST_PARAMS',
    CLOSE_WEBHOOK_MODAL: 'CLOSE_WEBHOOK_MODAL',

    STRIPE_ACCOUNT_STATUS_REQUEST: 'STRIPE_ACCOUNT_STATUS_REQUEST',
    STRIPE_ACCOUNT_STATUS_SUCCESS: 'STRIPE_ACCOUNT_STATUS_SUCCESS',
    STRIPE_ACCOUNT_STATUS_FAILURE: 'STRIPE_ACCOUNT_STATUS_FAILURE',

    STRIPE_ONBOARDING_LINK_REQUEST: 'STRIPE_ONBOARDING_LINK_REQUEST',
    STRIPE_ONBOARDING_LINK_SUCCESS: 'STRIPE_ONBOARDING_LINK_SUCCESS',
    STRIPE_ONBOARDING_LINK_FAILURE: 'STRIPE_ONBOARDING_LINK_FAILURE',

    STRIPE_TOKEN_EXCHANGE_REQUEST: 'STRIPE_TOKEN_EXCHANGE_REQUEST',
    STRIPE_TOKEN_EXCHANGE_SUCCESS: 'STRIPE_TOKEN_EXCHANGE_SUCCESS',
    STRIPE_TOKEN_EXCHANGE_FAILURE: 'STRIPE_TOKEN_EXCHANGE_FAILURE',

    CUSTOMER_IO_SAVE_ACCOUNT_REQUEST: 'CUSTOMER_IO_SAVE_ACCOUNT_REQUEST',
    CUSTOMER_IO_SAVE_ACCOUNT_SUCCESS: 'CUSTOMER_IO_SAVE_ACCOUNT_SUCCESS',
    CUSTOMER_IO_SAVE_ACCOUNT_FAILURE: 'CUSTOMER_IO_SAVE_ACCOUNT_FAILURE'
};
