import Box from "@mui/material/Box";
import {TextField, InputAdornment} from "@mui/material";
import React from "react";

export const InputSubdomain = React.forwardRef(({endAdornmentDomainValue, ...props}, ref) => {
  return <Input 
    {...props}
    ref={ref}
    startAdornment={<InputAdornment position="start">https://</InputAdornment>}
    endAdornment={<InputAdornment position="end">{endAdornmentDomainValue}</InputAdornment>}
  />
})

export const Input = React.forwardRef(({
  value: controlledValue, type = 'text', input, performAction, label, placeholder, multiline, disabled, readOnly, 
  startAdornment, endAdornment,
  // redux-form props
  meta,
}, ref) => {
  const [_, setValue] = React.useState('');
  const isControlled = React.useMemo(() => controlledValue !== undefined, [controlledValue]);

  const handleChange = (event) => {
    // When there is no controlled value, which means the input is uncontrolled
    const value = event.target.value;
    setValue(value);
    performAction?.(value);
  }

  return(
    <Box sx={styles.formSection} {...input}>
      <Box sx={{width: '100%'}}>
        <TextField inputRef={ref}
                   sx={styles.textField}
                   InputProps={{ 
                     sx: styles.textField,
                     startAdornment,
                     endAdornment,
                   }}
                   type={type}
                   label={label || placeholder}
                   placeholder={placeholder}
                   value={isControlled ? controlledValue : undefined}
                   onChange={handleChange}
                   variant="outlined"
                   multiline={multiline}
                   disabled={disabled}
                   readOnly={readOnly}
                   error={meta && meta.touched && meta.error ? true : false}
                   helperText={meta && meta.touched && meta.error ? meta.error : null} />
      </Box>
    </Box>
  )
})

export default Input

const styles = {
  textField: {
    width: '100%',
    fontSize: '16px !important',
    color: '#0C2728',
    [`& fieldset`]: {
      border: '1px solid #0C2728', borderRadius: '10px',
    },
    [`& label`]: {
      fontSize: '16px',
    },
    [`& .MuiInputAdornment-root p`]: {
      fontSize: '16px',
    },
  },
  formSection:{
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
  },
}