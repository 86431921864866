import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";

export default function useGetRemovalProcesses(organizationId) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!_.isEmpty(organizationId)) {
            dispatch(organizationSettingsActions.fetchOrganizationRemovalProcesses(organizationId));
        }
    }, [organizationId]);

    const organizationSettings = useSelector(state => state.organizationSettings);
    const loading = organizationSettings.loadingRemovalProcesses;
    const removalProcesses = organizationSettings.removalProcesses;

    return {removalProcesses, loading};
}
