import {Fragment} from "react";
import {Box} from "@mui/system";
import {darken, Divider} from "@mui/material";
import _ from "lodash";
import {navigate} from "../organizationSettingsUtils";

export default function SettingsSearchResultContent({searchResult, setLocation, handleClose}) {

    function handleNavigation(fragmentId) {
        navigate(fragmentId);
        setLocation(fragmentId);
        handleClose();
    }

    const Content = () => {
        if(_.isEmpty(searchResult) || searchResult.length === 0) {
            return <Fragment/>
        }
        return (
            <Fragment>
                {
                    searchResult.map( item =>
                        <Box sx={styles.searchedItem}
                             onClick={() => handleNavigation(item.section)}>
                            {item.label}
                        </Box>
                    )
                }
            </Fragment>
        )
    }

    return(
        <Box sx={styles.container}>
            <Box sx={styles.content}>
                <Box sx={styles.desc}>
                    Settings searches and suggestions
                </Box>
                <Divider orientation={'horizontal'} flexItem sx={styles.divider} />
                <Box sx={styles.searchedItemContainer}>
                    <Content/>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        minWidth: '200px',
        maxWidth: '350px',
        p: '15px',
        backgroundColor: '#FFFFFF',
        border: "solid 1px #B9C6C6",
        borderRadius: '8px',
        boxShadow: '0px 4px 4px rgba(12, 39, 40, 0.25)',
        position: 'absolute',
        zIndex: 10
    },
    content: {
        fontSize: '15px'
    },
    divider: {
        display: 'block',
        mt: '10px',
        mb: '12px',
        backgroundColor: '#E7E9E9'
    },
    searchedItem: {
        color: '#414f4f',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#414f4f',
        borderRadius: '8px',
        px: '7px',
        py: '2px',
        cursor: 'pointer',
        ':hover': {
            color: darken('#2a3434', 0.9),
            borderColor: darken('#2a3434', 0.9)
        },
        ':active': {
            color: '#2a3434',
            backgroundColor: '#EEF4FC'
        }
    },
    searchedItemContainer: {
        display: 'flex',
        gap: '10px',
        flexWrap: 'wrap'
    },
    desc: {
        fontFamily: 'LarsseitMedium'
    }
}