import {authHeader, handleHttpResponse} from '_helpers';
import {SERVER_URL} from 'config';

function getAll(params = {}) {

    return fetch(`${SERVER_URL}/api/currencies`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

export const currencyService = {
    getAll,
};

