import axios from "axios";
import {authHeader} from "_helpers";
import {SERVER_URL} from "../config";

function getMetaEmbed(messageId) {
    const endpoint = `${SERVER_URL}/api/v2/metaEmbed/message/${messageId}`
    return axios.get(endpoint,{
        headers: authHeader()
    })
}

export const oEmbedService = {
    getMetaEmbed
}