import React, { useState, Fragment } from 'react';
import { Box, Link, Stack, CircularProgress, Divider } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import importedStyles from './styles';
import BasicModal from 'components/v2/views/Onboarding/Utilities/BasicModal';

/**
 * 
 * @param {object} params
 * @param {React.ReactNode} params.children
 * @param {boolean} params.isLoading
 * @param {string} params.headerTitle
 * @param {React.ReactNode} params.headerChildren - (optional)
 * @param {string} params.infoModalTitle - (optional) Title text of the info modal. It will be only required when the `infoModalChildren` is set.
 * @param {React.ReactNode} params.infoModalChildren - (optional) Content of the info modal.
 */
export default function DashboardCard({
	children,
	isLoading,
	headerTitle,
	headerChildren,
	infoModalTitle,
	infoModalChildren,
}) {
	const [infoModalOpen, setInfoModalOpen] = useState(false)

	const handleOpenModal = e => {
		e.preventDefault();
		setInfoModalOpen(true);
	}

	return (
		<Box component={'section'}>
			{infoModalChildren && (
				<BasicModal
					open={infoModalOpen}
					handleClose={() => setInfoModalOpen(false)}
					handleOpen={() => setInfoModalOpen(true)}
					title={infoModalTitle}>

					{infoModalChildren}
				</BasicModal>
			)}

			<Stack 
				sx={styles.root}
				direction={'column'}
				divider={<Divider orientation={'horizontal'} flexItem />}>
				<Stack direction={{ xs: 'column', md: 'row' }} spacing={1} sx={styles.header}>
					<Box component={'h2'} sx={styles.headerTitle}>
						{headerTitle}
					</Box>
					{(headerChildren || infoModalChildren) ? (
						<Stack direction={'row'} spacing={2} sx={{ width: { xs: '100%', sm: 'auto' } }}>
							{headerChildren}
							{infoModalChildren && (
								<Stack direction={'row'} spacing={0.5} sx={styles.headerInfo}>
									<HelpIcon sx={styles.headerInfoIcon} />
									<Box component={'p'}>
										Where did these numbers come from?
										{' '}
										<Link
											sx={importedStyles.textLink}
											underline='none'
											href={'#'}
											onClick={handleOpenModal}>
											Learn more here
										</Link>
									</Box>
								</Stack>
							)}
						</Stack>
					): null}
				</Stack>

				<Box sx={styles.body}>
					{isLoading ? (
						<Box sx={styles.bodyLoading}>
							<CircularProgress sx={styles.bodyLoadingSpinner} />
						</Box>
					) : (
						(!children) ? (
							<Box sx={styles.bodyEmptyState}>
								No data available.
							</Box>
						) : (
							children
						)
					)}
				</Box>
			</Stack>
		</Box>
	)
}

const styles = {
	root: {
		borderRadius: '10px',
		border: '1px solid #B6BEBF',
		background: '#fff',
	},

	header: {
		alignItems: { xs: 'flex-start', md: 'center' },
		justifyContent: 'space-between',
		px: { xs: 2, md: 4 },
		py: { xs: 2, md: 0 },
		minHeight: { xs: 'auto', md: '72px' },
		zIndex: 10, // MUI draws a big padding over the header, so we need to make sure the header is on top so it's still interactable.
	},
	headerTitle: {
		m: 0,
		fontFamily: 'LarsseitMedium',
		fontSize: { xs: '16px', md: '20px' },
		color: '#0C2728',
	},
	headerInfo: {
		fontFamily: 'LarsseitMedium',
		alignItems: 'center',
	},
	headerInfoIcon: {
		fontSize: '18px',
	},

	body: {
		px: { xs: 2, md: 4 },
		position: 'relative',
	},
	bodyLoading: {
		py: 4,
		display: 'flex',
		justifyContent: 'center',
	},
	bodyLoadingSpinner: {
		color: '#143F42',
	},
	bodyEmptyState: {
		fontFamily: 'LarsseitMedium',
		fontSize: 16,
		color: '#0C2728',
		textAlign: 'center',
		py: 4,
	}
}