import React, {useRef} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {BillingInfoBuilder} from "../objects/BillingInfoBuilder";
import {getAccessibilityMode, parseProductName} from "../Utilities/organizationSettingsUtils";
import {accessibilityMode} from "../Utilities/organizationSettingsConstants";
import CompanyInfoActions from "../Utilities/CompanyInfo/CompanyInfoActions";
import {TicketBudgetTextField} from "../Utilities/CompanyInfo/TicketBudgetTextField";
import {BillingInfoColumnBuilder} from "../objects/BillingInfoColumnBuilder";

export default function (label) {
    const organizationSettings = useSelector(state => state.organizationSettings);
    const loading = organizationSettings.loadingOrganizationInfo;
    const organizationInfo = organizationSettings.organizationInfo;
    const selectedBillingPlan = _.get(organizationSettings, 'updatingBilling');
    const ticketBudgetUpdate = organizationSettings.ticketBudgetUpdate;
    const loadingTicketBudgetUpdate = organizationSettings.loadingTicketBudgetUpdate;
    const billingInfoArr = _.get(organizationInfo, 'billingInfo') || [];
    const organizationId = _.get(organizationInfo, 'organization.id');
    let rows = [];
    let columns = [];
    const mode = getAccessibilityMode();
    const isEdit = mode === accessibilityMode.EDIT;

    if(!loading && billingInfoArr.length > 0) {
        const refs = useRef([]);
        billingInfoArr.map((billingInfoItem, index) => {
            const id = _.get(billingInfoItem, 'id');
            const ticketBudget = _.get(billingInfoItem, 'ticketBudget') || (isEdit ? 0 : '-');
            const ticketBudgetView = isEdit ?
                            <TicketBudgetTextField
                                key={id}
                                defaultValue={ticketBudget}
                                billingPlanId={id}
                                selectedBillingPlan={selectedBillingPlan}
                                organizationId={organizationId}
                                ticketBudgetUpdate={ticketBudgetUpdate}
                                ref={(element) => refs.current.push(element)}
                            /> : ticketBudget;
            const actions = <CompanyInfoActions
                                key={id}
                                id={id}
                                selectedId={selectedBillingPlan.billingPlanId}
                                performAction={(e) => refs.current[index].handleBudgetUpdate(e)}
                                performCancel={() => refs.current[index].handleCancelUpdate()}
                                loading={loadingTicketBudgetUpdate}
                            />;
            const builder = new BillingInfoBuilder();
            builder.setId(id);
            builder.setProduct(parseProductName(_.get(billingInfoItem, 'product')) || '-');
            builder.setUsedTickets(_.get(billingInfoItem, 'usedTickets') || '-');
            builder.setTicketBudget(ticketBudgetView);
            builder.setActions(actions);
            rows.push(builder.build());
        });
    }

    const productBuilder = new BillingInfoColumnBuilder();
    productBuilder.setId('product');
    productBuilder.setLabel(label.PRODUCTS);
    productBuilder.setWidth(250);
    columns.push(productBuilder.build());

    const usedTicketsBuilder = new BillingInfoColumnBuilder();
    usedTicketsBuilder.setId('usedTickets');
    usedTicketsBuilder.setLabel(label.USED_TICKETS);
    usedTicketsBuilder.setWidth(100);
    columns.push(usedTicketsBuilder.build());

    const ticketBudgetBuilder = new BillingInfoColumnBuilder();
    ticketBudgetBuilder.setId('ticketBudget');
    ticketBudgetBuilder.setLabel(label.TICKET_BUDGET);
    ticketBudgetBuilder.setWidth(100);
    if(!isEdit) ticketBudgetBuilder.setAlignment('right');
    columns.push(ticketBudgetBuilder.build());

    if(isEdit) {
        const actionBuilder = new BillingInfoColumnBuilder();
        actionBuilder.setId('actions');
        actionBuilder.setLabel('Actions');
        actionBuilder.setWidth(90);
        actionBuilder.setAlignment('right');
        columns.push(actionBuilder.build());
    }

    return {rows, columns}
}