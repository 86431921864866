import {oauthConstants} from "_constants";
import {appcredentialsService} from "_services";


export const appCredentiasActions = {
    getFacebookappCredentials,
};


function getFacebookappCredentials() {

    return dispatch => {
        dispatch(request);

        appcredentialsService.getAppCredentials()
            .then(
                credentials => dispatch(success(credentials)),
                error => {
                    dispatch(failure(error))
                }
            );

        function request() {
            return {type: oauthConstants.FACEBOOK_CREDENTIALS_REQUEST}
        }

        function success(credentials) {
            return {type: oauthConstants.FACEBOOK_CREDENTIALS_SUCCESS, payload: credentials}
        }

        function failure(error) {
            return {type: oauthConstants.FACEBOOK_CREDENTIALS_FAILURE, payload: error}
        }
    }
}