import React, {Fragment, useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {msgSourceActions} from "_actions";
import {Box} from "@mui/material";
import {CLIENT_URL} from "config";
import basicModalStyles from "../../Utilities/basicModalStyles";
import SetupModalBottomBar from "../../Utilities/SetupModalBottomBar";
import Input from 'components/v2/views/Channels/ConnectChannels/Utilities/Input';
import {trustpilotOauthActions} from "_actions";

function TrustpilotAppModalForm({description, buttonLabel, extraInfoLabel, openInNewTab, handleSubmit, redirectUrl, callbackUrl = `${CLIENT_URL}/signup/add-channel`}){
    const dispatch = useDispatch();
    const trustpilot = useSelector(state => state.trustpilotAuth);

    const [formData, setFormData] = React.useState({});

    useEffect(() => {
        dispatch(msgSourceActions.getMsgSourceLink('trustpilot'))
    }, []);

    const onSubmit = (props) => {
        const params = { clientId: props.clientId, clientSecret: props.clientSecret, redirectUrl };
        dispatch(trustpilotOauthActions.saveClientAppData(params));
    };

    return(
        <Fragment>
            <Box sx={basicModalStyles.container}>
                <Box sx={basicModalStyles.description}>
                    {description}
                </Box>
                <form name="gorgiasForm" onSubmit={ handleSubmit(onSubmit) }>
                    <Field
                        component={Input}
                        name={'clientId'}
                        id='clientIdField'
                        placeholder='Api Key'
                        performAction={(value) => setFormData({...formData, clientId: value})}
                    />
                    <Field
                        component={Input}
                        name={'clientSecret'}
                        id='clientSecretField'
                        placeholder='Secret'
                        performAction={(value) => setFormData({...formData, clientSecret: value})}
                    />
                    <SetupModalBottomBar
                        extraInfoLabel={extraInfoLabel}
                        openInNewTab={openInNewTab}
                        buttonLabel={buttonLabel}
                        extraInfoLink={`https://businessapp.b2b.trustpilot.com/applications/`}
                        disabled={trustpilot.loading || (isEmpty(formData.clientId) || isEmpty(formData.clientSecret))}
                        loading={trustpilot.loading}
                    />
                </form>
            </Box>
        </Fragment>
    )
}

const formConfig = {
    form: 'trustpilotForm',
};

export default reduxForm(formConfig)(TrustpilotAppModalForm);