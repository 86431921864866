import {SERVER_URL, CLIENT_URL} from 'config';
import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';

function getOauthUrl() {
    return fetch(`${SERVER_URL}/api/slack/oauthUrl?redirectUri=${CLIENT_URL}/slack/connect`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function processOauthCode(params) {
    params.redirectUri = `${CLIENT_URL}/slack/connect`;
    return fetch(`${SERVER_URL}/api/slack/oauthCode?${objectToQueryString(params)}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function disconnectSlack(id) {
    return fetch(`${SERVER_URL}/api/organizations/${id}/slack`,
        {
            method: 'DELETE',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}



export const slackOauthService  = {
    getOauthUrl,
    processOauthCode,
    disconnectSlack
};