import {useState} from "react";
import {useDispatch} from "react-redux";
import {Box} from "@mui/system";
import HideInactiveIcon from '@mui/icons-material/VisibilityOffOutlined';
import HideActiveIcon from '@mui/icons-material/VisibilityOutlined';
import _ from "lodash";
import {dispatcherActions} from "_actions";
import {buildHidePayload} from "../../../views/Vote/Utilities/VoteUtils";

export default function HideAction({message}) {
    const hiddenFromSource = _.get(message, 'hiddenFromSource');
    const [isActive, setIsActive] = useState(hiddenFromSource);
    const dispatch = useDispatch();
    const messageId = _.get(message, 'id');

    const handleClick = (value) => (event) => {
        setIsActive(value);
        const payload = buildHidePayload(message, value);
        dispatch(dispatcherActions.updateMessageFromSource(messageId, payload));
    }

    const Content = () => {
        if(isActive) return <HideInactiveIcon sx={styles.hideIcon} onClick={handleClick(false)}/>;
        return <HideActiveIcon sx={styles.hideIcon} onClick={handleClick(true)}/>;
    }

    return(
        <Box sx={styles.container}>
            <Content/>
        </Box>
    )
}

const styles = {
    container: {
        height: '20px',
        marginRight: '10px'
    },
    hideIcon: {
        color: '#07857C',
        fontSize: '20px',
        cursor: 'pointer'
    },
}