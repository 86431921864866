import React from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";

class SelectMultipleCheckbox extends React.Component {
    state = {
        [this.props.orgId] : false
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
        this.props.setValues(name, event.target.checked)
    };

    render() {

        const {checkedItem, checkedItemList, itemName} = this.props;

        return (
            <div style={{display: 'flex', alignItems: 'flex-start', padding: '6px 0'}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkedItemList.includes(checkedItem)}
                            onChange={this.handleChange(checkedItem)}
                            style={
                                checkedItemList.includes(checkedItem) ?
                                    {color: '#008751', fontSize: '24px', padding: '0 0 0 15px'} :
                                    {color: '#aeaeae', fontSize: '24px', padding: '0 0 0 15px'}
                            }
                        />
                    }
                    label={<div style={{marginLeft: '10px', fontSize: '16px', lineHeight: '18px'}}>{itemName}</div>}/>
            </div>
        );
    }
}

export { SelectMultipleCheckbox };