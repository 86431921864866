import {Box} from "@mui/system";
import commonStyles from "../commonStyles";
import {Grid} from "@mui/material";
import {Fragment} from "react";
import {formatToDate} from "../../../../../../_helpers";

function RemovalProcessDetailsList({details}) {
    return (
        <Fragment>
            <Grid item xs={12} sx={styles.labelContainer}>
                <Box sx={commonStyles.labelMedium}>Details</Box>
            </Grid>
            <Grid item xs={12}>
                <Box component="ul" sx={styles.detailsList} >
                    {details.map((detail, index) => (
                        <Box key={index} component="li">{detail}</Box>
                    ))}
                </Box>
            </Grid>
        </Fragment>
    );
}

export default function RemovalProcessDetails({removalProcess}) {
    return (
        <Box>
            <Box component={'h5'} sx={commonStyles.labelBold}>Removal Process Details</Box>
            <Grid container>
                <Grid item xs={2} sx={styles.labelContainer}>
                    <Box sx={commonStyles.labelMedium}>Date Created</Box>
                </Grid>
                <Grid item xs={10}>
                    <Box>{formatToDate(removalProcess.dateCreated)}</Box>
                </Grid>
                <Grid item xs={2} sx={styles.labelContainer}>
                    <Box sx={commonStyles.labelMedium}>Last Updated</Box>
                </Grid>
                <Grid item xs={10}>
                    <Box>{formatToDate(removalProcess.lastUpdated)}</Box>
                </Grid>
                {removalProcess.details && <RemovalProcessDetailsList details={removalProcess.details}/>}
            </Grid>
        </Box>
    );
}

const styles = {
    labelContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    detailsList: {
        marginTop: '5px'
    }
}