import ChannelCard from "../../ChannelCard";
import logoTikTok from "../../../../../../images/logos/logo_tiktok_shop.png";
import {useTikTokShopSetup} from "../index";

export default function TikTokShopEntry() {
    const { initiateTikTokSetup } = useTikTokShopSetup({ callbackPath: '/tiktokshop/accounts' })
    return (
        <ChannelCard
            icon={logoTikTok}
            label={'TikTok Shop'}
            description={'Connect to your customers on your TikTok Shop'}
            onClick={initiateTikTokSetup}
        />
    )
}