import {oauthConstants} from "_constants";

const INITIAL_STATE = {
    authUrl: '',
    error: '',
    loading: true,
    channel: 'salesforce'
};

export function salesforceOauthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.SALESFORCE_OAUTH_URL_REQUEST:
            return {...state, loading: true};
        case oauthConstants.SALESFORCE_OAUTH_URL_SUCCESS:
            return {...state, authUrl: action.payload, loading: false};
        case oauthConstants.SALESFORCE_OAUTH_URL_FAILURE:
            return {...state, error: action.error, loading: false};

        case oauthConstants.SALESFORCE_ACCESSTOKEN_REQUEST:
            return {...state, loading: true};
        case oauthConstants.SALESFORCE_ACCESSTOKEN_SUCCESS:
            return {...state, loading: false};
        case oauthConstants.SALESFORCE_ACCESSTOKEN_FAILURE:
            return {...state, error: action.error, loading: false};
        default:
            return state;
    }
}