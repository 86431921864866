import {Box} from "@mui/material";
import React, {Fragment, useState} from "react";
import RemoveCompanyConfirmationModal from "./RemoveCompanyConfirmationModal";
import InfoIcon from '@mui/icons-material/Info';
import {HtmlTooltip} from "../../../../../common";
import {Tooltip, Typography} from "@material-ui/core";
import useGetRemovalProcesses from "../../hooks/useGetRemovalProcesses";
import CircularProgress from "@material-ui/core/CircularProgress";
import commonStyles from "../../organizationMainStyles";
import RemovalProcess from "./RemovalProcess";
import ActionButton from "../../../../common/ActionButton";
import {accessibilityMode, fragmentId} from "../organizationSettingsConstants";
import {getLabel} from "../organizationSettingsUtils";
import InformationTooltip from "../InformationTooltip";

function RemovalProcesses({removalProcesses}) {
    return (
        removalProcesses ?
            <Box sx={styles.sectionWrapper}>
                {removalProcesses.map((removalProcess) => (
                    <RemovalProcess key={removalProcess.id} removalProcess={removalProcess}/>
                ))}
            </Box> : <Fragment/>
    )
}

function RemoveCompanyTitle({label}) {
    return (
        <Box sx={styles.text}>
            {label.REMOVE_COMPANY}
            <InformationTooltip label={"Remove the company, data integrations, and all attached users from Teams. They will no longer be able to log in, and will no longer be visible in the\n" +
                " expert or admin Teams application. This should be done when the company is offboarded."} />
        </Box>
    )
}

export default function RemoveCompany({organizationId, mode}) {
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const {removalProcesses, loading} = useGetRemovalProcesses(organizationId);
    const label = getLabel(fragmentId.OFF_BOARD);
    const toggleConfirmationModal = () => setOpenConfirmationModal(!openConfirmationModal);

    const handleRemoval = () => {
        toggleConfirmationModal()
    };

    const isRemovalDisabled = loading || removalProcesses.removalInProgress;

    const BodyContent = () => {
        if (loading) {
            return (
                <Box sx={commonStyles.bodyLoading}>
                    <CircularProgress sx={commonStyles.bodyLoadingSpinner}/>
                </Box>
            )
        }
        return (
            <Fragment>
                <RemoveCompanyConfirmationModal
                    openConfirmationModal={openConfirmationModal}
                    closeConfirmationModal={toggleConfirmationModal}
                    organizationName={removalProcesses.organizationName}
                    organizationId={organizationId}
                />
                <Box sx={styles.headerSection}>
                    <RemoveCompanyTitle label={label}/>
                    {mode === accessibilityMode.EDIT &&
                        <Box>
                            <Tooltip title="The removal process is in progress"
                                     disableHoverListener={!isRemovalDisabled}>
                            <span>
                                <ActionButton
                                    label={'Remove'}
                                    action={handleRemoval}
                                    withIcon={false}
                                    disabled={isRemovalDisabled}
                                    sx={styles.removeButton}
                                />
                            </span>
                            </Tooltip>
                        </Box>
                    }
                </Box>
                <RemovalProcesses removalProcesses={removalProcesses.items}/>
            </Fragment>
        )
    }

    return <BodyContent/>
}

const styles = {
    headerSection: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        justifyContent: 'space-between'
    },
    text: {
        fontSize: '16px',
        fontFamily: 'LarsseitMedium',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    infoIcon: {
        fontSize: '18px'
    },
    removeButton: {
        width: '100px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}