import {Box} from "@mui/system";
import {buildTikTokVideoEmbedUrl} from "./EmbedUtils";

export default function TikTokEmbed({message}) {
    const url = buildTikTokVideoEmbedUrl(message);
    return <Box component={'iframe'} sx={style.iframe} src={url}/>
}

const style = {
    iframe: {
        width: '100%',
        height: '100%',
        border: 0,
    }
}