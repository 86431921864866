import React, {Fragment} from 'react';
import 'components/Vote/vote.css';
import moment from 'moment/moment';
import ConversationThread from "components/ConversationThread/ConversationThread";
import {stringContainsData, stripNumbersFromUsername} from "_helpers";
import htmlParser from 'he';
import {styles} from 'components/Vote'

/**
 * @author Chris Okebata
 */

const VoteMessage = (props) => {

  const { message, conversationCount } = props;
  const { text, dataSourceHandle: { name }, author, dateCreated, dateCreatedFromSource } = message;
  let date = dateCreatedFromSource ? dateCreatedFromSource : dateCreated;
  date = moment(date).format('MMMM D, YYYY');

  return (
        <div className="margin-top-87">
            <div className="tms-ellipsis font-14 color-grey-mid margin-bottom-10">
                {name}
            </div>
            <div className="msg-body font-16 color-black-fade">
                {htmlParser.decode(text)}
            </div>
            <div className="msg-details-container font-14 color-grey-dark">
              {
                stringContainsData(author) ?
                  <Fragment>
                    <div className="tms-ellipsis" style={styles.author}>
                        {stripNumbersFromUsername(author)}
                    </div>
                    <div style={styles.dateWithAuthor}>
                        {date}
                    </div>
                  </Fragment>
                  :
                  <div className="tms-ellipsis" style={styles.dateWithoutAuthor}>
                    {date}
                  </div>
              }
            </div>
            <div className="msg-src-container" style={{ padding: '8px 0' }}>
                <ConversationThread message={message} conversationCount={conversationCount} />
            </div>
        </div>
    )
};

export { VoteMessage }
