import {alertMessages} from '_constants';
import {SnackBar} from "../components/common";
import {billingConstants} from "../_constants/billing.constants";
import {billingService} from "../_services/billing.service";

export const billingActions = {
    getBillingSettingsLink
};

function getBillingSettingsLink() {
    return dispatch => {
        dispatch(request());

        billingService.getBillingSettingsLink()
            .then(
                response => {
                    dispatch(success(response))
                    window.open(response.url, '_blank', 'noopener,noreferrer');
                },
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.BILLING_SETTINGS_ERROR)
                }
            );
    };

    function request() {
        return {type: billingConstants.GET_BILLING_SETTINGS_REQUEST}
    }

    function success(url) {
        return {type: billingConstants.GET_BILLING_SETTINGS_SUCCESS, url}
    }

    function failure(error) {
        return {type: billingConstants.GET_BILLING_SETTINGS_FAILURE, error}
    }
}