import React, {Fragment} from "react";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import {makeStyles} from "@mui/styles";
import {isValidPassword} from "_helpers";

export default function Password({ input, label, performAction, meta: { touched, error } }) {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const hasError = touched && !_.isEmpty(error) && !isValidPassword(values.password);

  const handleChange = (prop) => (event) => {
    const value = event.target.value;
    setValues({ ...values, [prop]: value });
    performAction(value);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return(
    <Fragment>
      <FormControl sx={{ width: '100%' }} variant="outlined">
        <InputLabel htmlFor="password" style={{fontSize: '16px'}}
                    className={ hasError ? classes.inputLabelError : classes.inputLabel }
                    error={ hasError }>{ label }</InputLabel>
        <OutlinedInput
          className={ classes.outlinedInput }
          classes={ hasError ? {} : outlinedInputClasses}
          id="password"
          {...input}
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          error={ hasError }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                sx={{marginRight: '1px'}}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ?
                  <VisibilityOff sx={{width: '20px', height: '20px'}} /> :
                  <Visibility sx={{width: '20px', height: '20px'}} />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
    </Fragment>
  )
}

const useStyles = makeStyles({
  outlinedInput: {
    width: '100%',
    fontSize: '16px !important',
    [`& fieldset`]: {
      border: '1px solid #0C2728', borderRadius: '10px',
    },
  },
  inputLabel: {
    fontSize: '16px',
    '&.Mui-focused': {
      color: '#0C2728'
    }
  },
  inputLabelError: {
    fontSize: '16px',
    color: '#0C2728',
    '&.Mui-focused': {
      color: '#EB5757'
    }
  },
});

const useOutlinedInputStyles = makeStyles({
  root: {
    "&$focused $notchedOutline": {
      borderColor: "#368c86"
    },
  },
  focused: {},
  notchedOutline: {}
});