import React from "react";
import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import DatetimeRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const FilterForm = (props) => {

    const {
        submitting,
        onSubmit,
        onDatePickerUpdate,
        setStartDate,
        setEndDate,
    } = props;

    return (
        <Form onSubmit={onSubmit}>
            <span className="font-14 color-grey-dark">Date</span>
            <div style={{marginTop:"3px", display:"flex"}}>
                <label className="calendar-today">
                    <DatetimeRangePicker
                        startDate={setStartDate}
                        endDate={setEndDate}
                        onEvent={onDatePickerUpdate}>
                        <input className="input-popup color-grey-dark font-14 tms-ellipsis"
                               type="text"
                               name="date-picker"
                               placeholder={`${setStartDate} - ${setEndDate}`}
                        />
                    </DatetimeRangePicker>
                </label>
            </div>
            <div style={{marginTop:"10px"}}>
                <Button className="btn btn-success btn-block font-15" type="submit" disabled={submitting}>
                    Apply
                </Button>
            </div>
        </Form>
    );
}

export default connect(null)(FilterForm);
