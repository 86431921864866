import {alertMessages, auth0Constants, authConstants} from "_constants";
import {auth0Service, authService, chatdeskUpdatesService} from "_services";
import {Auth} from "../components/Authorization";
import {SnackBar} from "../components/common";
import _ from "lodash";
import {userActions} from "_actions";
import {history} from "_helpers";

const authenticateUser = (payload) => {
  const request = () => ({
    type: auth0Constants.AUTHENTICATE_USER_REQUEST
  });

  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    auth0Service.authenticateUser(payload)
      .then(response => {
        const userData = _.get(response, "data");
        let {message, user} = userData;

        if (user) {
          localStorage.setItem('user', user);
          chatdeskUpdatesService.setDisplayOptions()
          user = JSON.parse(user || '{}');
          dispatch(success(user));
          dispatch(userActions.getCurrentUser(user.id));
          history.push('/dashboard')

        }else if(message === 'CONTACT_SUPPORT'){
          history.push('/contact-support');
        }else if(message === 'EMAIL_NOT_VERIFIED'){
          history.push('/verify-email');
        }else if(message === 'USER_NOT_ENABLED'){
          history.push('/logout');
        }else if(message === 'PROFILE_NOT_SETUP'){
          history.push('/profile/setup');
        }else{
          history.push('/logout');
        }

      }).catch((error) => {
      console.log("error >>> ", error);
      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }
      if(error){
        history.push('/logout');
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};

const logoutUser = () => {
  const request = () => ({
    type: auth0Constants.LOGOUT_USER_REQUEST
  });

  function success() {
    return { type: auth0Constants.LOGOUT_USER_REQUEST };
  }

  function failure(error) {
    return { type: auth0Constants.LOGOUT_USER_FAILURE, error };
  }


  return dispatch => {
    // dispatch(request());

    // auth0Service.logoutUser()
    //   .then(() => {
    //     dispatch(success());
    //     authService.logout()
    //   }).catch((error) => {
    //
    //   if(_.get(error, 'response.status') === 401){
    //     Auth.processLogout();
    //     return;
    //   }
    //
    //   dispatch(failure(error));
    //   SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    // });

    authService.logout();
  }
};

const createProfile = (payload) => {
  const request = () => ({
    type: auth0Constants.CREATE_PROFILE_REQUEST
  });

  function success(response) {
    return { type: auth0Constants.CREATE_PROFILE_SUCCESS, payload: { ...response } };
  }

  function failure(error) {
    return { type: auth0Constants.CREATE_PROFILE_FAILURE, error };
  }


  return dispatch => {
    dispatch(request());

    auth0Service.createProfile(payload)
      .then((response) => {
        if(_.get(response.data, 'status') !== 201){
          SnackBar.showMessage(_.get(response.data, 'message'));
          dispatch(success({}));
          return;
        }

        const userData = _.get(response, "data");
        let {user} = userData;

        if (user) {
          history.push('/profile-success');
        }

      }).catch((error) => {

      if(_.get(error, 'response.status') === 401){
        Auth.processLogout();
        return;
      }

      dispatch(failure(error));
      SnackBar.showMessage(alertMessages.GENERIC_ERROR);
    });
  }
};

export const auth0Actions = {
  authenticateUser,
  logoutUser,
  createProfile
};