import { companyResourcesConstants } from '_constants';

const INITIAL_STATE = {
    allResources: [],
    loadingResources: true,
    error: '',

    createResource: {},
    loadingCreateResource: false,
    createResourceError: '',

    resource: {},
    loadingResource: true,
    resourceError: '',

    updateResource: {},
    loadingUpdateResource: false,
    updateResourceError: '',

    organizationResources: {},
    loadingAllOrganizationResources: false,
    organizationResourcesError: '',
};

export function companyResourcesReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case companyResourcesConstants.GET_ALL_COMPANY_RESOURCES_REQUEST:
            return {...state, loadingResources: true};
        case companyResourcesConstants.GET_ALL_COMPANY_RESOURCES_SUCCESS:
            return {...state, allResources: action.payload, loadingResources: false};
        case companyResourcesConstants.GET_ALL_COMPANY_RESOURCES_FAILURE:
            return {...state, error: action.error, loadingResources: false};

        case companyResourcesConstants.CREATE_RESOURCE_REQUEST:
            return {...state, loadingCreateResource: true};
        case companyResourcesConstants.CREATE_RESOURCE_SUCCESS:
            return {...state, createResource: action.payload, loadingCreateResource: false};
        case companyResourcesConstants.CREATE_RESOURCE_FAILURE:
            return {...state, createResourceError: action.error, loadingCreateResource: false};

        case companyResourcesConstants.GET_RESOURCE_REQUEST:
            return {...state, loadingResource: true};
        case companyResourcesConstants.GET_RESOURCE_SUCCESS:
            return {...state, resource: action.payload, loadingResource: false};
        case companyResourcesConstants.GET_RESOURCE_FAILURE:
            return {...state, resourceError: action.error, loadingResource: false};

        case companyResourcesConstants.UPDATE_RESOURCE_REQUEST:
            return {...state, loadingUpdateResource: true};
        case companyResourcesConstants.UPDATE_RESOURCE_SUCCESS:
            return {...state, updateResource: action.payload, loadingUpdateResource: false};
        case companyResourcesConstants.UPDATE_RESOURCE_FAILURE:
            return {...state, updateResourceError: action.error, loadingUpdateResource: false};

        case companyResourcesConstants.GET_ALL_BY_ORG_RESOURCE_REQUEST:
            return {...state, organizationResources: {}, loadingAllOrganizationResources: true};
        case companyResourcesConstants.GET_ALL_BY_ORG_RESOURCE_SUCCESS:
            return {...state, organizationResources: action.payload, loadingAllOrganizationResources: false};
        case companyResourcesConstants.GET_ALL_BY_ORG_RESOURCE_FAILURE:
            return {...state, organizationResourcesError: action.payload, loadingAllOrganizationResources: false};

        default:
            return state
    }
}