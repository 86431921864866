import {CircularProgress} from "@mui/material";

export default function LoadingSpinner() {
	return (
		<CircularProgress sx={styles.loadingSpinner} />
	);
}

const styles = {
	loadingSpinner: {
		color: '#143F42',
	}
}