import {
    messageConstants,
    messageAuthorConstants,
    paidMessageConstants,
    messageStatusConstants,
    ticketThreadConstants, downloadAttachmentConstants, websocketConstants, internalNoteConstants
} from '_constants';
import {processAdminMessage, processWebsocketMessage} from "_helpers";

const INITIAL_STATE = {
    messages: [],
    message: {},
    nextMessage: {},
    conversationThread: [],
    responsePayload: '',
    authors: [],
    loading: false,
    loadingNextMessage: false,
    downloadingAttachment: false,
    error: '',
    organizationBilling: {},
    allMessages: [],
    paidMessages: [],
    loadingPaidMessage: false,
    messageStatus: [],
    loadingMessageStatus: false,
    trendsCorrespondingMessages: [],
    loadingTrendsCorrespondingMessages: false,
    loadingTicketThread: false,
    ticketThread: [],
    messagesRequiringActionSummary: [],
    messageHasReplyOrIsDeleted: {},
    attachment: "",
    updatedIsReviewed: undefined,
    internalNoteAssignees: {},
    loadingInternalNoteAssignees: false,
    internalNoteAssigneesError: '',
};

export function messageReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case messageConstants.GET_ALL_REQUEST:
            return {...state, loading: true, error: ''};
        case messageConstants.GET_ALL_SUCCESS:
            return {...state, messages: action.payload, loading: false};
        case messageConstants.GET_ALL_FAILURE:
            return {...state, error: action.error, loading: false};

        case messageConstants.GET_NEXT_MESSAGE_REQUEST:
            return {...state, loadingNextMessage: true, error: '', nextMessage: {}};
        case messageConstants.GET_NEXT_MESSAGE_SUCCESS:
            return {...state, loadingNextMessage: false, nextMessage: action.payload};
        case messageConstants.GET_NEXT_MESSAGE_FAILURE:
            return {...state, error: action.error, loadingNextMessage: false};

        case messageConstants.GET_DETAILS_REQUEST:
        case messageConstants.GET_TRENDS_DETAILS_REQUEST:
        case messageConstants.GET_ADDITIONAL_DETAILS_REQUEST:
            return {...state, loading: true, error: ''};
        case messageConstants.GET_DETAILS_SUCCESS:
        case messageConstants.GET_TRENDS_DETAILS_SUCCESS:
            return {...state, message: action.payload, loading: false};
        case messageConstants.GET_ADDITIONAL_DETAILS_SUCCESS:
            return {...state,
                message: {
                    ...state.message,
                    agent: action.payload.agent
                },
                loading: false
            };
        case messageConstants.GET_DETAILS_FAILURE:
        case messageConstants.GET_TRENDS_DETAILS_FAILURE:
        case messageConstants.GET_ADDITIONAL_DETAILS_FAILURE:
            return {...state, error: action.error, loading: false};

        case messageConstants.GET_HISTORY_REQUEST:
            return {...state, loading: true, error: ''};
        case messageConstants.GET_HISTORY_SUCCESS:
            return {...state, message: action.payload, loading: false};
        case messageConstants.GET_HISTORY_FAILURE:
            return {...state, error: action.error, loading: false};

        case messageConstants.GET_ALL_FROM_TRENDS_REQUEST:
            return {...state, loading: true, error: ''};
        case messageConstants.GET_ALL_FROM_TRENDS_SUCCESS:
            return {...state, messages: action.payload, loading: false};
        case messageConstants.GET_ALL_FROM_TRENDS_FAILURE: {
            return {...state, messages: action.payload, error: action.error, loading: false};
        }

        case messageConstants.GET_PAYLOAD_REQUEST:
            return {...state, loading: true, error: ''};
        case messageConstants.GET_PAYLOAD_SUCCESS:
            return {...state, responsePayload: action.payload, loading: false};
        case messageConstants.GET_PAYLOAD_FAILURE:
            return {...state, error: action.error, loading: false};

        case messageConstants.CHANGE_MESSAGE_PRACTICE_PRIORITY_REQUEST:
            return {...state, loading: true, error: ''};
        case messageConstants.CHANGE_MESSAGE_PRACTICE_PRIORITY_SUCCESS:
            return {...state, loading: false, updatedIsReviewed: action.updatedIsReviewed };
        case messageConstants.CHANGE_MESSAGE_PRACTICE_PRIORITY_FAILURE:
            return {...state, error: action.error, loading: false};
        case messageConstants.CLEAR_UPDATED_MESSAGE_PRACTICE_PRIORITY:
            return {...state, error: action.error, updatedIsReviewed: undefined};

        case messageAuthorConstants.GET_MESSAGE_AUTHOR_REQUEST:
            return {...state, error: ''};
        case messageAuthorConstants.GET_MESSAGE_AUTHOR_SUCCESS:
            return {...state, authors: action.payload };
        case messageAuthorConstants.GET_MESSAGE_AUTHOR_FAILURE: {
            return {...state, authors: action.payload, error: action.error};
        }

        case messageConstants.GET_PRIORIY_MESSAGES_REQUEST:
            return {...state, loading: true, error: ''};
        case messageConstants.GET_PRIORIY_MESSAGES_SUCCESS:
            return {...state, messages: action.payload, loading: false};
        case messageConstants.GET_PRIORIY_MESSAGES_FAILURE: {
            return {...state, messages: action.payload, error: action.error, loading: false};
        }

        case messageConstants.GET_ORG_BILLING_REQUEST:
            return { ...state, loading: true, error: '' };
        case messageConstants.GET_ORG_BILLING_SUCCESS:
            return { ...state, organizationBilling: action.payload, loading: false };
        case messageConstants.GET_ORG_BILLING_FAILURE:
            return { ...state, loading: false, error: action.error }

        case messageConstants.GET_MESSAGES_ALL_REQUEST:
            return { ...state, loading: true, error: '' };
        case messageConstants.GET_MESSAGES_ALL_SUCCESS:
            const processTeamsMessage = processAdminMessage(action.payload)

            return { ...state,
                allMessages: processTeamsMessage,
                loading: false
            };

        case websocketConstants.GET_MESSAGE_SUCCESS:
            const websocketMessage = processWebsocketMessage(action.payload, state.allMessages.messages, state.allMessages.count, state.allMessages.params.platform);
            const {processedMessages, processedMessageCount} = websocketMessage;
            return {...state,
                allMessages: {
                    ...state.allMessages,
                    messages: processedMessages,
                    count: processedMessageCount,
                },
                loading: false
            };
        case messageConstants.GET_MESSAGES_ALL_FAILURE:
            return { ...state, loading: false, error: action.error };

        case messageConstants.GET_TRENDS_MESSAGES_ALL_REQUEST:
            return { ...state, loading: true, error: '' };
        case messageConstants.GET_TRENDS_MESSAGES_ALL_SUCCESS:
            const processedTrendsMessage = processAdminMessage(action.payload)
            return { ...state,
                allMessages: processedTrendsMessage,
                loading: false
            };
        case messageConstants.GET_TRENDS_MESSAGES_ALL_FAILURE:
            return { ...state, loading: false, error: action.error };

        case ticketThreadConstants.TICKET_THREAD_REQUEST:
            return { ...state, loadingTicketThread: true, error: '' };
        case ticketThreadConstants.TICKET_THREAD_SUCCESS:
            return { ...state, ticketThread: action.payload, loadingTicketThread: false };
        case ticketThreadConstants.TICKET_THREAD_FAILURE:
            return { ...state, loadingTicketThread: false, error: action.error };

        case paidMessageConstants.PAID_MESSAGE_ACTION_REQUEST:
            return { ...state, loadingPaidMessage: true, error: '' };
        case paidMessageConstants.PAID_MESSAGE_ACTION_SUCCESS:
            return { ...state, paidMessages: action.payload, loadingPaidMessage: false };
        case paidMessageConstants.PAID_MESSAGE_ACTION_FAILURE:
            return { ...state, loadingPaidMessage: false, error: action.error };

        case messageStatusConstants.MESSAGE_STATUS_REQUEST:
            return { ...state, loadingMessageStatus: true, error: '' };
        case messageStatusConstants.MESSAGE_STATUS_SUCCESS:
            return { ...state, messageStatus: action.payload, loadingMessageStatus: false };
        case messageStatusConstants.MESSAGE_STATUS_FAILURE:
            return { ...state, loadingMessageStatus: false, error: action.error };

        case messageConstants.TRENDS_CORRESPONDING_MESSAGES_REQUEST:
            return { ...state, loadingTrendsCorrespondingMessages: true, error: '' };
        case messageConstants.TRENDS_CORRESPONDING_MESSAGES_SUCCESS:
            return { ...state, trendsCorrespondingMessages: action.payload, loadingTrendsCorrespondingMessages: false };
        case messageConstants.TRENDS_CORRESPONDING_MESSAGES_FAILURE:
            return { ...state, loadingTrendsCorrespondingMessages: false, error: action.error };
        case messageConstants.GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_SUCCESS:
            return { ...state,
                allMessages: {
                    ...state.allMessages,
                    resendForResponseGenList: action.payload.messageIds,
                },
                loadingTrendsCorrespondingMessages: false
            };
        case messageConstants.RESEND_FOR_RESPONSE_GENERATION_SUCCESS:
            return { ...state,
                allMessages: {
                    ...state.allMessages,
                    resendForResponseGenList: state.allMessages.resendForResponseGenList.filter((item) => item !== action.payload.messageId),
                    messages: state.allMessages && state.allMessages.messages && state.allMessages.messages.map((item) => {
                        if (item.id === action.payload.messageId) {
                            item.actioned = false;
                            item.isDispatchedSuccessfully = false;
                            item.hasEmployeeSeen = false
                        }
                        return item
                    })
                },
                loadingTrendsCorrespondingMessages: false
            };
        case messageConstants.GET_MESSAGES_REQUIRING_ATTENTION_REQUEST:
            return {...state, error: '', loading: true};
        case messageConstants.GET_MESSAGES_REQUIRING_ATTENTION_SUCCESS:
            return {...state, messagesRequiringActionSummary: action.payload, loading: false};
        case messageConstants.GET_MESSAGES_REQUIRING_ATTENTION_FAILURE:
            return {...state, error: action.error, loading: false};

        case messageConstants.CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_REQUEST:
            return {...state, error: '', loading: true};
        case messageConstants.CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_SUCCESS:
            return {...state, messageHasReplyOrIsDeleted: action.payload, loading: false};
        case messageConstants.CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_FAILURE:
            return {...state, error: action.error, loading: false};

        case downloadAttachmentConstants.DOWNLOAD_ATTACHMENT_REQUEST:
            return {...state, error: '', downloadingAttachment: true};
        case downloadAttachmentConstants.DOWNLOAD_ATTACHMENT_SUCCESS:
            return {...state, attachment: action.payload, downloadingAttachment: false};
        case downloadAttachmentConstants.DOWNLOAD_ATTACHMENT_FAILURE:
            return {...state, error: action.error, downloadingAttachment: false};

        case messageConstants.ACTION_MESSAGE_REQUEST:
            return { ...state, loading: true, error: '' };
        case messageConstants.ACTION_MESSAGE_SUCCESS:
            return { ...state, actionMessages: action.payload, loading: false };
        case messageConstants.ACTION_MESSAGE_FAILURE:
            return { ...state, loading: false, error: action.error };

        case internalNoteConstants.GET_INTERNAL_NOTE_ASSIGNEES_REQUEST:
            return { ...state, loadingInternalNoteAssignees: true, internalNoteAssignees: {}, internalNoteAssigneesError: '' };
        case internalNoteConstants.GET_INTERNAL_NOTE_ASSIGNEES_SUCCESS:
            return { ...state, internalNoteAssignees: action.payload, loadingInternalNoteAssignees: false };
        case internalNoteConstants.GET_INTERNAL_NOTE_ASSIGNEES_FAILURE:
            return { ...state, loadingInternalNoteAssignees: false, internalNoteAssignees: {}, internalNoteAssigneesError: action.error };

        default:
            return state
    }
}