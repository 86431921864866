import React, {Component, Fragment} from "react";
import {formatDixaText, processSpacing, processText, sanitizeTextForDixa} from "_helpers";
import {FollowupModal} from "components/common"
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";


/**
 * @author Chris Okebata
 */

class MessageFollowupParser extends Component{

  render() {
    let {text, msgSource, organization, isTrends} = this.props;
    if(sanitizeTextForDixa(_.toString(msgSource).toLowerCase())) {
      text = formatDixaText(text);
      text = processSpacing(text);
    }
    const splitText = processText(text, msgSource);

    let content = null;
    if (splitText.length === 1) {
      content = <span>{ReactHtmlParser(splitText[0] || '')}</span>;
    }
    else {
      let [prefix, target, surfix] = splitText;

      content = (
        <>
          <span>{prefix}</span>
          <FollowupModal
            target={target}
            organization={organization}
            isTrends={isTrends}
          />
          <span>{surfix}</span>
        </>
      );
    }

    return (
      <Fragment>
        <>{content}</>
      </Fragment>
    )
  }

}

export { MessageFollowupParser }