import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {dataSourceHandleActions, organizationSettingsActions} from "_actions";
import {accessibilityMode} from "../organizationSettingsConstants";
import {useDispatch} from "react-redux";

export default function DataSourceStatusButtons({handle, mode}) {
    const dispatch = useDispatch();
    const statusValues = ['LIVE', 'PRACTICE', 'OFF'];

    const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: string) => {
        if (newStatus) {
            dispatch(dataSourceHandleActions.update({dispatchStatus: newStatus}, handle.id)).then(() => {
                dispatch(organizationSettingsActions.refreshDataSourceHandleInfo(handle.organizationId));
            });
        }
        event.stopPropagation();
    };

    return (
        <Box>
            <ToggleButtonGroup
                    exclusive
                    value={handle.dispatchStatus}
                    color="primary"
                    disabled={mode !== accessibilityMode.EDIT}
                    onChange={mode === accessibilityMode.EDIT && handleStatusChange}>
                { statusValues.map((status) => (<ToggleButton size="small" key={status} value={status}>{status}</ToggleButton>)) }
            </ToggleButtonGroup>
        </Box>
    );
}
