import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { RoleForm } from "components/Role";
import { formModes } from "_constants";

class AddRole extends React.Component {
    render() {

        return (
            <Grid>

                <Row>
                    <Col xs={8} xsOffset={2}>
                        <RoleForm role={{}} mode={formModes.ADD}/>
                    </Col>
                </Row>

            </Grid>
        );
    }
}

export {AddRole};
