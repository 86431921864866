import {Box} from "@mui/system";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";

export default function TikTokShopCardEmptyState({}) {
    return (
        <Box>
            <Box sx={{...styles.container, ...{width: `${300 + 21.5}px`,}}}>
                <Box sx={{ width: '300px', height: '200px'}}>
                    <LoadingPlaceholderBlock
                        height={'200px'}
                        borderRadius={'5px'}
                        backgroundColor={'#EFEAE5'}
                    />
                </Box>
                <Box sx={styles.title}>
                    <Box sx={{ width: '300px', height: '30px'}}>
                        <LoadingPlaceholderBlock
                            height={'30px'}
                            borderRadius={'5px'}
                            backgroundColor={'#EFEAE5'}
                        />
                    </Box>
                </Box>
                <Box sx={styles.description}>
                    <Box sx={{ width: '300px', height: '30px'}}>
                        <LoadingPlaceholderBlock
                            height={'30px'}
                            borderRadius={'5px'}
                            backgroundColor={'#EFEAE5'}
                        />
                    </Box>
                </Box>
                <Box sx={styles.price}>
                    <Box sx={{ width: '40px', height: '30px'}}>
                        <LoadingPlaceholderBlock
                            height={'30px'}
                            borderRadius={'5px'}
                            backgroundColor={'#EFEAE5'}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        backgroundColor: '#FFF',
        border: '1px solid #C1C1C1',
        p: '10px',
        borderRadius: '8px'
    },
    image: {
        borderRadius: '5px',
        border: '1px solid #C1C1C1'
    },
    label: {
        mb: '6px',
        fontFamily: 'LarsseitMedium',
        fontSize: '15px'
    },
    title: {
        mt: '10px',
        fontFamily: 'LarsseitMedium',
        fontSize: '16px'
    },
    description: {
        color: '#495D5E',
        mt: '10px',
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        maxWidth: '100%',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    price: {
        mt: '10px',
        fontFamily: 'LarsseitMedium',
        fontSize: '20px',
        color: '#07857C'
    }
}