import { Fragment } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Badge from '../Utilities/Badge';
import { capitalize } from '_helpers'
import importedStyles from '../Utilities/styles'
import DashboardTable, { DashboardTableRow, DashboardTableCell, tableStyles } from '../Utilities/DashboardTable';
import _ from 'lodash';

export default function ExpertInfoSectionSchedule({
 scheduleSummaryData: {loadingScheduleSummary, scheduleSummaryObj}
}) {
	const days = [
		{ label: 'Sunday', value: 1 },
		{ label: 'Monday', value: 2 },
		{ label: 'Tuesday', value: 3 },
		{ label: 'Wednesday', value: 4 },
		{ label: 'Thursday', value: 5 },
		{ label: 'Friday', value: 6 },
		{ label: 'Saturday', value: 7 },
	]

	return (
		<Fragment>
			<Box sx={styles.headingBox}>
				<Box component={'h2'} sx={{ ...importedStyles.headingSmall, ...styles.heading }}>
					Schedule overview
				</Box>
			</Box>

			<DashboardTable
				label={'x'}>

				{(!loadingScheduleSummary && !_.isEmpty(scheduleSummaryObj) ?
					days.sort((a, b) => a.value - b.value)
							.map((dayObj, key) => {
								const data = _.get(scheduleSummaryObj, `${dayObj.value}`);
								return (
									<DashboardTableRow key={key}>
										<DashboardTableCell sx={tableStyles.cellVerticalDivider}>
											{dayObj.label}
										</DashboardTableCell>
										<DashboardTableCell sx={tableStyles.cellWithBadges}>
											<Box sx={styles.badgesWrapper}>
												{data && data.map(obj => {
													const sourceName = _.get(obj, 'sourceName');
													const numberOfSchedules = _.get(obj, 'numberOfSchedules');
													return (
														<Box sx={styles.badge} key={sourceName}>
															<Badge>
																{capitalize(sourceName)} {' '}
															</Badge>
														</Box>
													)
												})}
											</Box>
										</DashboardTableCell>
									</DashboardTableRow>
								)
				}) : null)}
			</DashboardTable>

			<Box sx={styles.moreLink}>
				<Button 
					sx={styles.buttonBordered}
					component={RouterLink}
					to={'/metrics/schedule-details'}
					variant="contained"
					disableElevation>
					View schedule details
					<ArrowForwardIcon sx={{ fontSize: '22px', marginLeft: '8px' }} />
				</Button>
			</Box>
		</Fragment>
	)
}

const styles = {
	headingBox: {
		display: 'flex',
		alignItems: 'center',
		height: '20px',
		mb: { xs: 2, md: 3 },
	},
	heading: {
		mb: '0!important',
		mr: 1,
	},
	badgesWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
		m: -0.5,
		minHeight: '32px',
	},
	badge: {
		m: 0.5,
	},
	badgeLabel: {
		maxWidth: '150px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	badgeValue: {
		fontFamily: 'LarsseitRegular',
	},
	emptyCell: {
		height: '24px', // same height as badge.
		m: 0.5,
	},
	moreLink: {
		mt: 2,
	},
	buttonBordered: {
		width: { xs: '100%', md: 'auto' },
    fontSize: '16px',
    padding: '8px 16px',
    color: '#368C86',
    fontFamily: 'LarsseitMedium',
    textTransform: 'none',
    borderRadius: '50px',
    backgroundColor: '#ffffff',
		lineHeight: '120%',
		letterSpacing: '0px',
    border: '1px solid #368C86',
    ':hover': {
      backgroundColor: '#ffffff',
      color: '#368C86',
    },
    ':disabled': {
      color: '#368C8680',
      backgroundColor: '#f5f5f5',
      border: '1px solid #368C8680'
    },
    ':focus': {
      outline: '0'
    }
	}
}