import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

class OrganizationCheckbox extends React.Component {
    state = {
        [this.props.orgId] : false
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
        this.props.setValues(name, event.target.checked)
    };

    render() {

        const {orgId, selectedOrganizations} = this.props;

        return (
            <div>
                <Checkbox
                    checked={selectedOrganizations.includes(orgId)} //check if data contains in array then mark as checked
                    onChange={this.handleChange(orgId)}
                    style={
                        selectedOrganizations.includes(orgId) ?
                            {color: '#008751', fontSize: 2} :
                            {color: '#aeaeae', fontSize: 2}
                    }
                />
            </div>
        );
    }
}

export { OrganizationCheckbox };