import React, {Fragment} from "react";
import BasicSelect from "./BasicSelect";
import useGetChannels from "../views/Proactive/Utilities/hooks/useGetChannels";

export default function ChannelSelect({ name, label, placeholder, handleChange, input, meta, defaultValue }) {
    const { channels } = useGetChannels();

    return(
        <Fragment>
            <BasicSelect
                name={name}
                label={label}
                placeholder={placeholder}
                options={channels}
                onChange={handleChange}
                input={input}
                defaultValue={defaultValue}
                meta={meta} />
        </Fragment>
    )
}