import React from "react";

export default function useReamazeSetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const performAction = () => {
    handleOpen();
  };

	return {
		initiateReamazeSetup: performAction,
		reamazeSetupOpen: open, 
		handleReamazeSetupOpen: handleOpen,
		handleReamazeSetupClose: handleClose
	};
}