import React, {Fragment, useEffect, useState} from "react";
import {Box} from "@mui/system";
import styles from "../Vote/voteMainStyles";
import VoteHeaderSection from "./Sections/HeaderSection/VoteHeaderSection";
import VoteBodySection from "./Sections/BodySection/VoteBodySection";
import VoteFooterSection from "./Sections/FooterSection/VoteFooterSection";
import _ from "lodash";
import {websocket} from "_helpers";
import useViewVerticalScrollStatus from "./Utilities/hooks/useViewVerticalScrollStatus";
import CompanyUpdate from "./CompanyUpdate/CompanyUpdate";
import {componentControl} from "../../utilities/Component.utils";
import BackToTeams from "./Sections/HeaderSection/BackToTeams";

export default function VoteEntry() {
    if(_.get(window, 'location.pathname') !== '/vote') return <Fragment/>;
    const {isVerticalScrollComplete} = useViewVerticalScrollStatus();
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        websocket.connect();
        return () => websocket.disconnect();
    }, []);

    const handleIsEnabled = (value) => {
        setIsEnabled(value);
    }

    let editedResponseObj = {};
    let editedSubjectObj = {};

    return (
        <Box sx={styles.root}>
            <CompanyUpdate handleIsEnabled={handleIsEnabled}/>
            <BackToTeams />
            <Box sx={{...styles.container, ...componentControl(isEnabled)}}>
                <Box sx={styles.contentArea}>
                    <VoteHeaderSection />
                    <VoteBodySection
                        isScrollComplete={isVerticalScrollComplete}
                        editedResponseObj={editedResponseObj}
                        editedSubjectObj={editedSubjectObj}
                    />
                    <VoteFooterSection
                        isScrollComplete={isVerticalScrollComplete}
                    />
                </Box>
            </Box>
        </Box>
    )
}