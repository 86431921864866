import React from 'react'
import StarRatings from 'react-star-ratings';

/**
 * @author Chris Okebata
 */

const starRating = (props) => {

    const { rating, numberOfStars, starDimension } = props;

    return (
        <StarRatings
            rating={rating}
            numberOfStars={numberOfStars || 5}
            starDimension={starDimension || "17px"}
            starSpacing={"1px"}
            starRatedColor={"rgb(224, 122, 0)"}
            name='rating'
        />
    );
};


export default starRating

