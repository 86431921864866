export const currencyConstants = {
    GET_ALL_CURRENCY_REQUEST: 'GET_ALL_CURRENCY_REQUEST',
    GET_ALL_CURRENCY_SUCCESS: 'GET_ALL_CURRENCY_SUCCESS',
    GET_ALL_CURRENCY_FAILURE: 'GET_ALL_CURRENCY_FAILURE',

    GET_CURRENCY_REQUEST: 'GET_CURRENCY_REQUEST',
    GET_CURRENCY_SUCCESS: 'GET_CURRENCY_SUCCESS',
    GET_CURRENCY_FAILURE: 'GET_CURRENCY_FAILURE',

};
