import _ from "lodash";
import {getLogoUrl, isValidUrl} from "_helpers";
import Avatar from "../../Avatar";

/**
 *
 * @param name {string}
 * @param iconUrl {string}
 * @param {'teal' | 'grey'} background - (optional) Background color
 * @param initialsCount {number} - (optional)
 * @returns {JSX.Element}
 * @constructor
 */
export default function AuthorIcon({name, iconUrl = '', background = 'teal', initialsCount=2}) {
    const companyIcon = !_.isEmpty(iconUrl) ? getLogoUrl(iconUrl) : '';
    const showIcon = isValidUrl(companyIcon);

    return(
        <Avatar
            src={showIcon ? companyIcon : undefined}
            userName={name}
            background={background}
            initialsCount={initialsCount}
            style={styles.avatar}
        />
    )
}

const styles = {
    avatar: {
        width: '40px',
        height: '40px',
        borderRadius: '5px',
        fontSize: '16px',
        textTransform: 'capitalize'
    }
}