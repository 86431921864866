import { Button } from '@mui/material'

export default function TextButton({
	label,
	onClick,
}) {
	return (
		<Button 
			variant='text'
			onClick={onClick}
			sx={{
				color: '#368C86',
				fontSize: '16px',
				fontWeight: '500',
				fontFamily: 'LarsseitMedium',
				lineHeight: 1,
				textTransform: 'none',
				borderRadius: '50px',
				px: 1,
				mx: -1,
			}}>
			{label}
		</Button>
	)
}