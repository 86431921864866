import {Box} from "@mui/system";
import {QuillEditor} from "../../../../../../../common";

export default function InternalNoteEditor({isEmail, handleTextChange}) {
    return(
        <Box>
            <QuillEditor
                handleTextChange={handleTextChange}
                isEmail={isEmail}
            />
        </Box>
    )
}