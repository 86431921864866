import { oauthConstants } from '_constants';

const INITIAL_STATE = {
    data: [],
    error: '',
    loading: true,
    status: '',

    stripeAccountStatus: {},
    loadingStripeAccountStatus: true,
    stripeAccountStatusError: '',

    stripeOnboardingLink: {},
    loadingStripeOnboardingLink: false,
    stripeOnboardingLinkError: '',

    stripeTokenExchange: {},
    loadingStripeTokenExchange: false,
    stripeTokenExchangeError: '',
};

export function stripeOauthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.STRIPE_OAUTH_URL_REQUEST:
            return { ...state, loading: true };
        case oauthConstants.STRIPE_OAUTH_URL_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case oauthConstants.STRIPE_OAUTH_URL_FAILURE:
            return { ...state, error: action.error, loading: false };
        case oauthConstants.STRIPE_OAUTH_CODE_REQUEST:
            return { ...state, loading: true };
        case oauthConstants.STRIPE_OAUTH_CODE_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case oauthConstants.STRIPE_OAUTH_CODE_FAILURE:
            return { ...state, error: action.error, loading: false };

        case oauthConstants.STRIPE_ACCOUNT_STATUS_REQUEST:
            return { ...state, loadingStripeAccountStatus: true };
        case oauthConstants.STRIPE_ACCOUNT_STATUS_SUCCESS:
            return { ...state, stripeAccountStatus: action.payload, loadingStripeAccountStatus: false };
        case oauthConstants.STRIPE_ACCOUNT_STATUS_FAILURE:
            return { ...state, stripeAccountStatusError: action.error, loadingStripeAccountStatus: false };

        case oauthConstants.STRIPE_ONBOARDING_LINK_REQUEST:
            return { ...state, loadingStripeOnboardingLink: true };
        case oauthConstants.STRIPE_ONBOARDING_LINK_SUCCESS:
            return { ...state, stripeOnboardingLink: action.payload, loadingStripeOnboardingLink: false };
        case oauthConstants.STRIPE_ONBOARDING_LINK_FAILURE:
            return { ...state, stripeOnboardingLinkError: action.error, loadingStripeOnboardingLink: false };

        case oauthConstants.STRIPE_TOKEN_EXCHANGE_REQUEST:
            return { ...state, loadingStripeTokenExchange: true };
        case oauthConstants.STRIPE_TOKEN_EXCHANGE_SUCCESS:
            return { ...state, stripeTokenExchange: action.payload, loadingStripeTokenExchange: false };
        case oauthConstants.STRIPE_TOKEN_EXCHANGE_FAILURE:
            return { ...state, stripeTokenExchangeError: action.error, loadingStripeTokenExchange: false };

        default:
            return state;
    }
}
