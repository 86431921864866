export class BillingInfoColumnBuilder {
    constructor() {
        this.billingInfoColumn = {};
    }

    setId(id) {
        this.billingInfoColumn.id = id;
        return this;
    }

    setLabel(label) {
        this.billingInfoColumn.label = label;
        return this;
    }

    setWidth(width) {
        this.billingInfoColumn.width = width;
        return this;
    }

    setAlignment(alignment) {
        this.billingInfoColumn.align = alignment;
        return this;
    }

    build(){
        return this.billingInfoColumn;
    }
}