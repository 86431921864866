
export const screenshotConstants = {
    GET_ALL_REQUEST: 'SCREENSHOTS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'SCREENSHOTS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'SCREENSHOTS_GET_ALL_FAILURE',

    GET_SCREENSHOT_REQUEST: 'SCREENSHOTS_GET_DETAIL_REQUEST',
    GET_SCREENSHOT_SUCCESS: 'SCREENSHOTS_GET_DETAIL_SUCCESS',
    GET_SCREENSHOT_FAILURE: 'SCREENSHOTS_GET_DETAIL_FAILURE',

    SAVE_SCREENSHOT_REQUEST: 'SAVE_SCREENSHOT_REQUEST',
    SAVE_SCREENSHOT_SUCCESS: 'SAVE_SCREENSHOT_SUCCESS',
    SAVE_SCREENSHOT_FAILURE: 'SAVE_SCREENSHOT_FAILURE',

    SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE',

    UPDATE_SCREENSHOT_REQUEST: 'UPDATE_SCREENSHOT_REQUEST',
    UPDATE_SCREENSHOT_SUCCESS: 'UPDATE_SCREENSHOT_SUCCESS',
    UPDATE_SCREENSHOT_FAILURE: 'UPDATE_SCREENSHOT_FAILURE',

    DOWNLOAD_SCREENSHOT_REQUEST: 'DOWNLOAD_SCREENSHOT_REQUEST',
    DOWNLOAD_SCREENSHOT_SUCCESS: 'DOWNLOAD_SCREENSHOT_SUCCESS',
    DOWNLOAD_SCREENSHOT_FAILURE: 'DOWNLOAD_SCREENSHOT_FAILURE',

    SCREENSHOT_TYPE_OPTIONS: [
        {'id': 'MISTAKE', name: 'MISTAKE'},
        {'id': 'POSITIVE', name: 'POSITIVE'}
    ],

    SCREENSHOT_TYPE_POSITIVE: "POSITIVE",
    SCREENSHOT_TYPE_MISTAKE: "MISTAKE"

};