import React, {Component, Fragment} from 'react';
import connect from "react-redux/es/connect/connect";
import {filterObjectValue, filterObjectTwoValues, isObjectEmpty} from "_helpers";
import {Typography} from "@material-ui/core";
import {platformConstants} from "_constants";
import _ from "lodash";
import LoadingPlaceholderBlock from "../common/LoadingPlaceholderBlock";


/**
 * @author: Chris Okebata
 */

class MessageStatus extends Component {

  render() {

    const {message, classes, loadingScheduledOrgs, scheduledOrganizations, loadingMessageStatus, messageStatus, platform} = this.props;
    const { id, direction, actioned, needsAdminAction, hasEmployeeSeen, isAutoEscalated } = message;
    const ESCALATE = ['Escalate'];
    const NO_RESPONSE_NEEDED = ['No Response Needed'];
    const REPLIED = ['VOTE', 'TRENDS', 'INBOX'];
    const SKIPPED = ['SKIPPED'];
    const INCOMING = 'incoming';
    const OUTGOING = 'outgoing';
    let status, scheduled;

    if(loadingMessageStatus || loadingScheduledOrgs){
      return(
        <LoadingPlaceholderBlock height={'10px'} borderRadius={'3px'}/>
      )
    }

    if(platform === platformConstants.TEAMS){
      status = filterObjectValue(messageStatus || [], 'messageId', _.get(message, 'id'));
      scheduled = filterObjectTwoValues(scheduledOrganizations || [], 'handleId', _.get(message, 'handleId'),
        'id', _.get(message, 'organizationId'));

    }else if(platform === platformConstants.TRENDS){
      status = filterObjectValue(messageStatus || [], 'trendsMessageId', _.get(message, 'id'));
      scheduled = filterObjectTwoValues(scheduledOrganizations || [], 'trendsHandleId', _.get(message, 'handleId'),
        'trendsId', _.get(message, 'organizationId'));
    }

    return(
      <Fragment key={id}>
        {/*SKIPPED*/}
        {(direction && direction.toString().toLowerCase() === INCOMING) &&
        (SKIPPED.includes(_.get(status[0], 'actionTaken')) &&
        hasEmployeeSeen && !actioned) &&
        <Typography className={classes.typography14}>
        <span className="badge btn-danger" style={{padding: '4px'}}>
            SKIPPED
        </span>
        </Typography>
        }

        {/*ESCALATED*/}
        {ESCALATE.includes(_.get(status[0], 'actionTaken')) &&
        (direction && direction.toString().toLowerCase() === INCOMING) &&
        (actioned || hasEmployeeSeen || isAutoEscalated) &&
        <Typography className={classes.typography14}>
          <span>ESCALATED</span>
        </Typography>
        }

        {/*NO RESPONSE NEEDED*/}
        {NO_RESPONSE_NEEDED.includes(_.get(status[0], 'actionTaken')) &&
        (direction && direction.toString().toLowerCase() === INCOMING) &&
        (actioned || hasEmployeeSeen || isAutoEscalated) &&
        <Typography className={classes.typography14}>
          <span>NO RESPONSE NEEDED</span>
        </Typography>
        }

        {/*REPLIED*/}
        {REPLIED.includes(_.get(status[0], 'actionTaken')) &&
        (direction && direction.toString().toLowerCase() === INCOMING) &&
        (hasEmployeeSeen || actioned) &&
        <Typography className={classes.typography14}>
          <span>REPLIED</span>
        </Typography>
        }

        {/*OUTGOING*/}
        {direction && direction.toString().toLowerCase() === OUTGOING &&
        <Typography className={classes.typography14}>
          <span>OUTGOING</span>
        </Typography>
        }

        {/*NOT SCHEDULED*/}
        {isObjectEmpty(scheduled[0]) &&
        !actioned && !hasEmployeeSeen && !needsAdminAction && !isAutoEscalated &&
        (direction && direction.toString().toLowerCase() === INCOMING) &&
        <Typography className={classes.typography14}>
          <span>NOT SCHEDULED</span>
        </Typography>
        }

        {/*NEW*/}
        {!(isObjectEmpty(scheduled[0])) &&
        (direction && direction.toString().toLowerCase() === INCOMING) &&
        (!(SKIPPED.includes(_.get(status[0], 'actionTaken')))) &&
        ((!actioned && !hasEmployeeSeen && !needsAdminAction && !isAutoEscalated) ||
        (needsAdminAction && (!actioned || !hasEmployeeSeen)) ||
        ((ESCALATE.includes(_.get(status[0], 'actionTaken'))) && (!actioned && !hasEmployeeSeen && !isAutoEscalated)) ||
        (NO_RESPONSE_NEEDED.includes(_.get(status[0], 'actionTaken')) && (!actioned && !hasEmployeeSeen && !isAutoEscalated)) ||
        ((REPLIED.includes(_.get(status[0], 'actionTaken'))) && (!actioned && !hasEmployeeSeen && !isAutoEscalated))) &&
        <Typography className={classes.typography14}>
          <span className="badge btn-danger" style={{padding: '4px'}}>NEW</span>
        </Typography>
        }

        {/*ACTIONED*/}
        {(actioned || isAutoEscalated) && (direction && direction.toString().toLowerCase() === INCOMING) &&
        !(ESCALATE.includes(_.get(status[0], 'actionTaken'))) &&
        !(NO_RESPONSE_NEEDED.includes(_.get(status[0], 'actionTaken'))) &&
        !(REPLIED.includes(_.get(status[0], 'actionTaken'))) &&
        <Typography className={classes.typography14}>
          <span>ACTIONED</span>
        </Typography>
        }
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {
    schedules: {scheduledOrganizations, loadingScheduledOrgs},
    messages: {loadingMessageStatus, messageStatus}
  } = state;

  return {
    loadingScheduledOrgs,
    scheduledOrganizations: scheduledOrganizations.scheduledOrganizations,
    loadingMessageStatus,
    messageStatus: messageStatus.messageStatus,
  };
}

const connectedMessageStatus = connect(mapStateToProps)(MessageStatus);
export {connectedMessageStatus as MessageStatus}