import React, {Fragment} from "react";
import {Box} from "@mui/material";
import _ from "lodash";
import BasicTable from "../../../../common/BasicTable";
import AccordionItem from "../../../../common/AccordionItem";
import useSchedulesTableData from "../../hooks/useSchedulesTableData";

export default function ScheduleAccordionItem({handleName, schedules, organizationId, payoutRates, pricingRates, mode}) {

    const {rows, columns} = useSchedulesTableData(organizationId, schedules, payoutRates, pricingRates, mode);
    const extractHandleName = (handleName) => {
        if (handleName === "null") return "All Channels";
        return handleName.split('_')[1];
    }

    const formattedHandleName = _.capitalize(extractHandleName(handleName));

    const AccordionDescription = () => {
        const schedulesCount = rows?.length;
        return(
            <Box>
                <Box component={'h2'} sx={styles.labelHeading}>
                    {formattedHandleName}
                </Box>
                <Box component={'p'} sx={styles.labelDescription}>
                    {schedulesCount} Schedule{schedulesCount > 1 ? "s" : ""}
                </Box>
            </Box>
        )
    }

    return(
        <Fragment>
            <AccordionItem
                key={1}
                keyNumber={33}
                summaryComponent={<AccordionDescription/>}>
                <Box>
                    <BasicTable
                        rows={rows}
                        columns={columns}
                        withPagination={false}
                    />
                    <Box sx={styles.expertDesc}>
                        Active experts
                    </Box>
                    <Box component={'a'} sx={styles.expertLink} href={`/experts/${organizationId}`}>
                        {`All experts for ${formattedHandleName}`}
                    </Box>
                </Box>
            </AccordionItem>
        </Fragment>
    )
}

const styles = {
    labelHeading: {
        fontFamily: 'LarsseitBold',
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: '0.05em',
        color: '#0C2728',
        textTransform: 'uppercase',
        mt: 0,
        mb: { xs: 0, sm: 0.5 },
    },
    labelDescription: {
        m: 0,
        fontFamily: 'LarsseitRegular',
        fontSize: 18,
        lineHeight: 1.2,
        letterSpacing: '-0.02em',
        color: '#0C2728'
    },
    expertDesc: {
        pt: '20px',
        pb: '10px',
        fontFamily: 'LarsseitMedium',
        fontSize: '16px',
        textTransform: 'capitalize'
    },
    expertLink: {
        fontSize: '16px',
        textDecoration: 'underline'
    }
}
