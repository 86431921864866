import React, {Component, Fragment} from 'react';
import 'components/Vote/vote.css';
import { Button } from 'react-bootstrap';
import { formattedResponseText, isObjectEmpty, stripFigureTagFromHtml } from "_helpers";
import {styles} from "./VoteStyles";
import {QuillEditor} from "../common";
import {dispatcherActions} from "_actions";
import { connect } from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress";
import {handleType, responseType} from "_constants";


/**
 * @author Chris Okebata
 */

class EditResponse extends Component {

  constructor(props){
    super(props);

    this.state = {
      editedHtmlBody: ''
    }
  }

  /**
   * Get the html body for a particular message using the response Id.
   * This triggers an event to the server to make a request to the external source of a message
   * to retrieve the html body
   * @param responseId
   */
  getHtmlBody = (responseId) => {
    this.props.dispatch(dispatcherActions.getHtmlBody(responseId));
  };

  /**
   * This open the editor
   * @param responseId
   */
  openEditor = (responseId) => {
    const {dispatch, messageHandleType, onEditResponseSelect} = this.props;
    dispatch(dispatcherActions.clearHtmlBody());

    if([handleType.email, handleType.proactive_email].includes(messageHandleType)){
      this.getHtmlBody(responseId);
    }

    onEditResponseSelect(responseId);
  };

  /**
   * This handles the state change of a text in the editor
   * @param text
   */
  handleTextChange = (text) => {
    this.setState({
      editedHtmlBody: text,
    })
  };

  /**
   * This prepares the edited response for submission
   * @param response_id
   */
  parseEditResponse = (response_id) => {
    const { allVotes, messageHandleType, submitEditResponse, htmlBodyData, messageAuthor, agentName, editVote } = this.props;
    const { text } = editVote;
    const responseOptionText = formattedResponseText(text, messageAuthor, agentName, messageHandleType);

    submitEditResponse(
      allVotes,
      response_id,
      [handleType.email, handleType.proactive_email].includes(messageHandleType) ? responseType.editedHtml : responseType.editedText,
      this.state.editedHtmlBody || htmlBodyData.html_body || responseOptionText
    );
  };

  render(){

    const {
      editVote, index, selectedResponse, messageAuthor, agentName, messageHandleType, htmlBodyData, loading
    } = this.props;

    const { responseText, editTextWrapper, editTextContainer, progressContainer } = styles;
    const { response_id, text } = editVote;
    let isSelected = selectedResponse === response_id;
    const responseOptionText = formattedResponseText(text, messageAuthor, agentName, messageHandleType);
    const isEmail = [handleType.email, handleType.proactive_email].includes(messageHandleType);
    let openEditor = isEmail ? (isSelected && !loading && !isObjectEmpty(htmlBodyData)) : isSelected;

    return(
      <Fragment>
        <div className="font-14 color-grey-mid margin-bottom-10">
          {`Response #${index}`}
        </div>

        <div style={{ marginBottom: '20px' }}>
          <div style={editTextWrapper}>
            <div className="font-16" style={editTextContainer}>
              {
                openEditor ?
                  <QuillEditor
                    defaultText={stripFigureTagFromHtml(htmlBodyData.html_body || responseOptionText)}
                    handleTextChange={this.handleTextChange}
                    isEmail={isEmail}
                  />
                  :
                  <div style={responseText} className='no-break-out'>
                    {responseOptionText}
                  </div>
              }
            </div>

            <div style={{width: '130px', marginLeft: 'auto'}}>
              <div>
                <div>
                  {
                    isSelected && loading &&
                    <div style={progressContainer}>
                      <CircularProgress size={20} style={{color: '#008751'}}/>
                    </div>
                  }
                  <Button
                    onClick={() => this.openEditor(response_id)}
                    id="submitEditButton"
                    className="btn btn-block btn-default"
                    style={{ height: '35px', lineHeight: '0px' }}
                    type="button"
                    disabled={loading ? loading : isSelected}
                  >
                    Edit
                  </Button>
                </div>

                {
                  openEditor ?

                  <div>
                    <div style={{marginTop: '8px'}}>
                      <Button
                        onClick={() => this.parseEditResponse(response_id)}
                        id="submitEditButton"
                        className="btn btn-block btn-primary"
                        style={{ height: '35px', lineHeight: '0px' }}
                        type="submit"
                        disabled={loading ? loading : !isSelected}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>

                    : ''
                }
              </div>

            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { dispatcher: { htmlBody, loading } } = state;
  return {
    htmlBodyData: htmlBody,
    loading,
  };
}

const connectedEditResponse = connect(mapStateToProps)(EditResponse);
export { connectedEditResponse as EditResponse };
