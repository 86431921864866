import {dixaConstants} from "_constants";

const INITIAL_STATE = {
    apiTokenStatus: {},
    verifyApiTokenLoading: false,
    verifyApiTokenError: '',

    account: {},
    createAccountLoading: false,
    createAccountError: '',
};

export function dixaReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case dixaConstants.VERIFY_API_TOKEN_REQUEST:
            return {...state, verifyApiTokenLoading: true};
        case dixaConstants.VERIFY_API_TOKEN_SUCCESS:
            return {...state, apiTokenStatus: action.payload, verifyApiTokenLoading: false};
        case dixaConstants.VERIFY_API_TOKEN_FAILURE:
            return {...state, verifyApiTokenError: action.error, verifyApiTokenLoading: false};
        case dixaConstants.CLEAR_VERIFY_API_TOKEN:
            return {...state, apiTokenStatus: {}, verifyApiTokenError: '', verifyApiTokenLoading: false};

        case dixaConstants.CREATE_ACCOUNT_REQUEST:
            return {...state, createAccountLoading: true};
        case dixaConstants.CREATE_ACCOUNT_SUCCESS:
            return {...state, account: action.payload, createAccountLoading: false};
        case dixaConstants.CREATE_ACCOUNT_FAILURE:
            return {...state, createAccountError: action.error, createAccountLoading: false};

        default:
            return state
    }
}