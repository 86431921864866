import React, {Fragment, useState} from "react";
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import {Box} from "@mui/material";
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DatetimeRangePicker from "react-bootstrap-daterangepicker";
import _ from 'lodash';
import {SnackBar} from "../../common";

export default function SingleDatePicker({ withIcon, handleChange, defaultDate, enabled = true, isClearable = false, meta = {} }) {
    const [date, setDate] = useState(_.isEmpty(defaultDate) ? (isClearable ? null : moment()) : moment(defaultDate));
    const touched = _.get(meta, 'touched');
    const error = _.get(meta, 'error');
    const warning = _.get(meta, 'warning');
    const hasError = touched && !_.isEmpty(error);

    function handleEvent(event, picker) {
        if(_.get(event, 'handleObj.type') === 'apply'){
            const formattedDate = picker.startDate;
            setDate(formattedDate);
            handleChange(formattedDate);
        }
    }

    function onDisabledInputClick() {
        SnackBar.showMessage('The date is not editable');
    }

    function formatDate() {
        if (date) {
            return date.format('MM/DD/YYYY');
        } else {
            return ""
        }
    }

    function handleClear() {
        setDate(null);
        handleChange(null);
    }

    return(
        <Fragment>
            <Box sx={ (hasError || warning) ? {...styles.input, ...styles.error} : styles.input}>
                { withIcon && <CalendarMonthIcon sx={styles.inputIcon} /> }
                { enabled && <DatetimeRangePicker
                    onEvent={handleEvent}
                    singleDatePicker>
                    <input className="input-popup color-grey-dark font-14 tms-ellipsis"
                        type="text"
                        placeholder={formatDate()}
                        tabIndex="-1" />
                    </DatetimeRangePicker>
                }
                { isClearable &&
                    <IconButton onClick={handleClear} sx={{m: '5px'}} >
                        <ClearIcon sx={styles.clearIcon} />
                    </IconButton>}
                { !enabled && <input
                    className="input-popup color-grey-dark font-14 tms-ellipsis"
                    type="text"
                    value={formatDate()}
                    tabIndex="-1"
                    onClick={onDisabledInputClick} />
                }
            </Box>
        </Fragment>
    );
}

const styles = {
    heading: {
        fontSize: '18px',
        mr: 2,
        display: { xs: 'none', sm: 'block' }
    },
    input: {
        borderRadius: '8px',
        overflow: 'hidden',
        width: { xs: '100%', sm: 'auto' },
        position: 'relative',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        border: '1.5px solid #9EA9A9',
        '*': {
            fontFamily: 'LarsseitMedium',
            fontSize: '16px',
            backgroundColor: 'transparent',
            border: 'none!important',
            outline: 'none!important',
            cursor: 'pointer',
            '::placeholder': {
                color: '#144042',
            }
        },
        '.react-bootstrap-daterangepicker-container': {
            width: '100%',

            'input': {
                color: '#0C2728',
                paddingLeft: '16px',
                marginRight: '-68px',
                width: '280px',
            }
        }
    },
    inputIcon: {
        fontSize: '25px!important',
        flexShrink: 0,
        color: '#144042',
        mr: '-5px',
        ml: '10px',
        pointerEvents: 'none',
    },
    clearIcon: {
        fontSize: '16px!important',
        flexShrink: 0,
        color: '#144042',
        pointerEvents: 'none',
    },
    error: {
        border: '1px solid #EB5757'
    }
}