
import React from 'react'
import chatdeskLogo from 'components/images/chatdesk-logo-dark.svg'
import { connect } from 'react-redux'
import {CopyrightFooter} from "components/common";

const messageContainer = {
    textAlign:"center",
    padding: "30px 50px",
    borderRadius: '3',
    margin: '30px auto',
    background: '#fff',
    boxShadow: '0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    width: 600
};

const logoContainerStyle = {
    paddingTop: 40,
    paddingBottom: 20,
    textAlign: 'center',
    position: 'relative'
};

const RegisterSuccessPage  = (props) => {
    const { email } = props
    return (
        <div>
            <div style={logoContainerStyle}>
                <img src={chatdeskLogo} alt="Chatdesk logo" className="logo"/>
            </div>

            <div style={messageContainer}
                 className="color-grey-dark">
                <p className="font-24-heavy">
                    Check your email inbox
                </p>
                <div style={{textAlign: 'center', width: '50%'}}>
                    <p className="font-18-bold margin-top-12">
                        We just emailed you a link to {email}
                    </p>
                    <p className="font-18-bold margin-top-12">
                        Click the link to activate your account and sign in
                    </p>
                </div>
            </div>

            <CopyrightFooter/>
        </div>
    )
}

function mapStateToProps(state) {
    const { auth: { email }} = state
    return { email }
}

const connectedRegisterSuccessPage = connect(mapStateToProps)(RegisterSuccessPage);
export { connectedRegisterSuccessPage as RegisterSuccessPage }