import {SERVER_URL} from "config";
import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';

function getAgentWallets(params) {

    return fetch(`${SERVER_URL}/api/wallets/agents?${objectToQueryString(params)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleHttpResponse)
}

function agentPayoutHistory(agentId) {

    return fetch(`${SERVER_URL}/api/wallets/agents/${agentId}/payout/history`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleHttpResponse)
}

function payoutToAgent(agentId, amount) {

    return fetch(`${SERVER_URL}/api/wallets/agents/${agentId}/payout`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ amount })
    }).then(handleHttpResponse)
}


function getAgentWalletBalance(agentId) {

    return fetch(`${SERVER_URL}/api/wallets/agents/${agentId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleHttpResponse)
}

export const walletService = {
    getAgentWallets,
    agentPayoutHistory,
    payoutToAgent,
    getAgentWalletBalance
};
