import React, {Fragment} from "react";
import {Box} from "@mui/system";
import ActionButton from "../../../common/ActionButton";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./templateStyles";

export default function SuggestedResponseActions({
    suggestedResponseId,
    openEditor,
    handleOpenEditor,
    handleCloseEditor,
    handleResponse,
    isRejected,
    loadingUpdateSuggestedResponse,
    handleEditSuggestedResponse,
    updatingResponseId
}) {

    const showProgress = loadingUpdateSuggestedResponse && updatingResponseId === suggestedResponseId;

    return(
        <Fragment>
            {
                !openEditor ?
                    <Box sx={styles.suggestedResponseButtonContainer}>
                        <ActionButton
                            label={ 'Edit' }
                            action={() => { handleOpenEditor(suggestedResponseId) }}
                            buttonBordered
                            useBorderColor={'#144042'}
                            sx={{height: '32px'}}
                            disabled={loadingUpdateSuggestedResponse}
                            withIcon={false}
                        />
                        <Box sx={{ml: '15px'}}>
                            <ActionButton
                                label={showProgress ?
                                    <CircularProgress size={15} sx={isRejected ? { color: '#0C2728'} : {color: '#FFFFFF'}}/> : isRejected ? 'Use' : 'Remove'
                                }
                                action={() => { handleResponse() }}
                                buttonBordered={isRejected}
                                useBorderColor={'#0C2728'}
                                sx={{height: '32px'}}
                                disabled={loadingUpdateSuggestedResponse}
                                withIcon={false}
                            />
                        </Box>
                    </Box>
                    :
                    <Box sx={styles.editorButtonsContainer}>
                        <Box sx={styles.cancelEditedSuggestedResponseButtonContainer}>
                            <ActionButton
                                label={'Cancel'}
                                action={handleCloseEditor}
                                buttonBordered
                                useBorderColor={'#07857C'}
                                disabled={loadingUpdateSuggestedResponse}
                                withIcon={false}
                                sx={{height: '32px'}}
                            />
                        </Box>
                        <Box sx={styles.saveEditedSuggestedResponseButtonContainer}>
                            <ActionButton
                                label={loadingUpdateSuggestedResponse ? <CircularProgress size={15} sx={{ color: '#FFFFFF'}}/> : 'Save'}
                                action={handleEditSuggestedResponse}
                                disabled={loadingUpdateSuggestedResponse}
                                withIcon={false}
                                sx={{height: '32px'}}
                            />
                        </Box>
                    </Box>
            }
        </Fragment>
    )
}