import React from "react";
import {useSelector} from "react-redux";
import {ProactiveMessagesBox} from "./ProactiveMessagesBox";

export const ProactiveMessagesBoxContainer = ({pageParams, setPageParams, pageFilter}) => {
    const proactiveMetricsDetailData = useSelector(state => state.proactive.proactiveMessagesList);

    const filterCampaignToMessageDetails = {
        [pageFilter]: {
            messages: proactiveMetricsDetailData.items,
            count: proactiveMetricsDetailData.totalCount,
            loading: proactiveMetricsDetailData.loadingProactiveMessagesList
        }
    }

    return (
        <ProactiveMessagesBox
            messageList={filterCampaignToMessageDetails[pageFilter].messages}
            allMessagesCount={filterCampaignToMessageDetails[pageFilter].count}
            eachPageMessagesCount={filterCampaignToMessageDetails[pageFilter].messages?.length}
            loading={filterCampaignToMessageDetails[pageFilter].loading}
            setPageParams={setPageParams}
            pageParams={pageParams}
            pageFilter={pageFilter}
        />
    )
}