import React, {Fragment, useEffect} from "react";
import BasicSelect from "../../Utilities/BasicSelect";
import {useDispatch, useSelector} from "react-redux";
import {utilityActions} from "_actions";

export default function VerticalSelect({ name, label, input, meta }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(utilityActions.getVerticals());
  }, []);

  const utility = useSelector((state) => state.utility);
  let { data: { verticalsList: verticals }, loading } = utility;

  if (verticals) {
    verticals = verticals.map((vertical) => {
      return { 'id': vertical.id, 'name': vertical.name }
    })
  }

  return(
    <Fragment>
      <BasicSelect
        name={name}
        label={label}
        options={verticals}
        input={input}
        meta={meta} />
    </Fragment>
  )
}