import React from 'react';
import DatetimeRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { change } from "redux-form";
import { connect } from "react-redux";

class DateRange extends React.Component {

    constructor(props) {
        super(props);

        this.handleEvent = this.handleEvent.bind(this);

        const ranges = {
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Month to Date': [moment().startOf('month'), moment()],
            'Year to date': [moment().startOf('year'), moment()],
            'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        }
        if (props && props.additionalRanges) {
            props.additionalRanges.forEach((range) => {
                ranges[range.label] = [range.startDate, range.endDate]
            });
        }

        this.state = {
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            ranges: ranges,
        };
    }


    handleEvent(event, picker) {
        if (this.props.onEvent) {
            this.props.onEvent(event, picker)
        } else {
            //Update the values in redux-form on change
            this.props.dispatch(change('FilterForm', 'dateRange', {
                startDate: picker.startDate,
                endDate: picker.endDate
            }));

        }

    }

    render() {
        const { submittedStartDate, submittedEndDate, filterForm } = this.props;
        let { placeholder } = this.props;
        let values;
        if (filterForm) {
            values = filterForm.values
        }

        /**
         * Check if start and end dates have been picked. If so, use those to set the values (controlled form) else
         * Check if page is reloading form a filter submission. If so, use the dates to set values and labels else
         * Set the dates to initial values which are startDate: 30 days back, endDate: Now().
         */

        let startDate = ( values && values.dateRange ) ? values.dateRange.startDate :
            submittedStartDate ? moment(submittedStartDate) : moment().subtract(29, 'days');

        let endDate = ( values && values.dateRange ) ? values.dateRange.endDate :
            submittedEndDate ? moment(submittedEndDate) : moment();

        let start = startDate.format('MM/DD/YYYY');
        let end = endDate.format('MM/DD/YYYY');

        if (!placeholder) {
            placeholder = start + ' - ' + end;

            if (start === end) {
                placeholder = start;
            }
        }

        return (
            <DatetimeRangePicker
                startDate={startDate}
                endDate={endDate}
                ranges={this.state.ranges}
                onEvent={this.handleEvent}
            >
                <input className="input-popup color-grey-dark font-14 tms-ellipsis"
                       type="text"
                       placeholder={placeholder}
                />
            </DatetimeRangePicker>
        );
    }

}

function mapStateToProps(state) {
    const { form: { FilterForm } } = state;
    return {
        filterForm: FilterForm,
    };
}

const connectedDateRange = connect(mapStateToProps)(DateRange);
export { connectedDateRange as DateRange };
