import AccordionItem from "../../../common/AccordionItem";
import {Box} from "@mui/material";
import React from "react";
import _ from "lodash";
import {sortArrayOfDays} from "../../../../../_helpers";
import BasicTable from "../../../common/BasicTable";
import ScheduleActions from "./ScheduleActions";

export default function ScheduleItem({handleId, schedules, deleteSchedule, orgId}){
    const extractHandleName = (handleId) => {
        if (handleId === "null") return "All Channels"
        return handleId.split('_')[1]
    }

    const columns = [
        {
            id: 'name',
            label: 'Schedule name',
            width: 100
        },
        {
            id: 'days',
            label: 'Days',
            width: 300
        },
        {
            id: 'startTime',
            label: 'Start time',
            width: 70
        },
        {
            id: 'endTime',
            label: 'End time',
            width: 70,
        },
        {
            id: 'actions',
            label: '',
            width: 20,
        },
    ];

    const tableRows = schedules.map((item) => {
        const days = item.days.map((day) => { return day.name });
        const sortedDays = sortArrayOfDays(days)

        return {
            name: item.name,
            days: sortedDays.join(', '),
            startTime: item.startTime,
            endTime: item.endTime,
            actions: <ScheduleActions deleteSchedule={deleteSchedule} id={item.id} orgId={orgId} />
        }
    })

    const schedulesCount = tableRows?.length

    const AccordionLabel = ({handleId, description}) => {
        return(
            <Box>
                <Box component={'h2'} sx={styles.labelHeading}>
                    {_.capitalize(extractHandleName(handleId))}
                </Box>
                <Box component={'p'} sx={styles.labelDescription}>
                    {description} Schedule{description > 1 ? "s" : ""}
                </Box>
            </Box>
        )
    }

    return(
        <AccordionItem key={1} keyNumber={33} summaryComponent={AccordionLabel({handleId, description: schedulesCount})}>
            <BasicTable
                rows={tableRows}
                columns={columns}
                withPagination={false}
            />
        </AccordionItem>
    )
}

const styles = {
    labelHeading: {
        fontFamily: 'LarsseitBold',
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: '0.05em',
        color: '#0C2728',
        textTransform: 'uppercase',
        mt: 0,
        mb: { xs: 0, sm: 0.5 },
    },
    labelDescription: {
        m: 0,
        fontFamily: 'LarsseitRegular',
        fontSize: 18,
        lineHeight: 1.2,
        letterSpacing: '-0.02em',
        color: '#0C2728'
    },
}
