import { oauthConstants, msgDataSourceConstants } from '_constants';

const INITIAL_STATE = {
  data: [],
  error: '',
  loading: true,
  status: '',
  channel: 'zendesk',
  list: []
};

export function zendeskOauthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_REQUEST:
      return { ...state, loading: true };
    case oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case oauthConstants.ZENDESK_SUBMIT_SUBDOMAIN_FAILURE:
      return { ...state, error: action.error, loading: false };

    case oauthConstants.ZENDESK_GENTOKEN_REQUEST:
      return { ...state, loading: true };
    case oauthConstants.ZENDESK_GENTOKEN_SUCCESS:
      return { ...state, authUrl: action.payload, loading: false };
    case oauthConstants.ZENDESK_GENTOKEN_FAILURE:
      return { ...state, error: action.error, loading: false };
    case msgDataSourceConstants.GET_ALL_DATA_SOURCE:
      return {...state, loading: true};
    case msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS:
      return {
        ...state,
        list: action.payload ? action.payload.filter(item => item.dataSourceType === state.channel) : [],
        loading: false
      };
    case msgDataSourceConstants.ALL_DATA_SOURCE_ERROR:
      return {...state, error: action.error, loading: false};
    case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES:
      return {...state, loading: true};
    case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_SUCCESS:
      return {
          ...state,
          list: state.list.map(item => {
              if (item.id === action.payload.msgDataSourceId) {
                  item[action.payload.updateField] = action.payload.enable
              }
              return item
          }),
          loading: false
      };
    case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_ERROR:
      return {...state, error: action.error, loading: false};
    case msgDataSourceConstants.TICKET_STATUS_UPDATE_REQUEST:
      return {...state, loading: true};
    case msgDataSourceConstants.TICKET_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload.dataSourceId) {
            item.ticketStatusOnReply = action.payload.currentStatus
          }
          return item
        }),
        loading: false
      };
    case msgDataSourceConstants.TICKET_STATUS_UPDATE_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
}
