import React, {Fragment} from "react";
import {Box} from "@mui/system";
import {buttonContained} from "../Utilities/ButtonContained";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {makeStyles} from "@mui/styles";

export default function ScheduleConfirmationModal({ handleClose }) {

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {ActionButton, LinkButton} = buttonContained;

  return(
    <Fragment>
      <Box sx={isMobile ? {marginTop: '10px'} : {}}>
        <Box className={isMobile ? classes.npsDescriptionMobile : classes.npsDescription}>
          Higher NPS and CSAT ratings are just one step away.
        </Box>
        <Box className={isMobile ? classes.scheduleDescriptionMobile : classes.scheduleDescription}>
          Schedule an onboarding call with one of our talented Chatdesk Leads to complete your set up.
        </Box>
        <Box className={isMobile ? classes.buttonContainerMobile : classes.buttonContainer}>
          <Box className={isMobile ? classes.buttonLeftMobile : classes.buttonLeft}>
            <LinkButton
              label={'Schedule call later'}
              to={'/signup/dashboard'}
              buttonBordered/>
          </Box>
          <Box className={isMobile ? classes.buttonRightMobile : classes.buttonRight}>
            <ActionButton
              label={'Schedule call'}
              action={handleClose}/>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

const useStyles = makeStyles({
  buttonLeft: {
    color: '#368C86',
    fontFamily: 'larsseitMedium',
    fontSize: '18px',
    lineHeight: '120%',
    marginRight: '15px'
  },
  buttonLeftMobile: {
    marginRight: 'auto',
    color: '#368C86',
    fontFamily: 'larsseitMedium',
    fontSize: '18px',
    lineHeight: '120%',
    width: '100%'
  },
  buttonRight: {},
  buttonRightMobile: {
    marginTop: '25px',
    width: '100%'
  },
  npsDescription: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '18px',
    lineHeight: '120%',
  },
  npsDescriptionMobile: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '16px',
    lineHeight: '120%',
  },
  scheduleDescription: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '18px',
    lineHeight: '120%',
    marginTop: '20px'
  },
  scheduleDescriptionMobile: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '16px',
    lineHeight: '120%',
    marginTop: '25px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '40px'
  },
  buttonContainerMobile: {
    display: 'flex',
    marginTop: '45px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
});