import React from 'react'
import {Button, Form, Modal, Row} from 'react-bootstrap'
import { Field, reduxForm } from "redux-form";
import { InputField, Timezone, TextArea } from "components/common";
import { connect } from "react-redux";
import {regFormContainer} from "../../RegisterPage";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/01/2019 : 5:33 PM
 */
const companyForm = (props) => (

    <Modal show={props.show} onHide={props.close} className="tms-edit-profile-modal">
        <Form onSubmit={props.handleSubmit(props.onSave)}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Company Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{zIndex: "5", padding:"20px 0 35px 0", width:"100%"}}>
                    <div className="tms-signup-form-content" >
                        <div className="tms-signup-form-section-1">
                            <div style={regFormContainer.inputField}>
                                <Field
                                    type='text'
                                    name='name'
                                    placeHolder='Our Company Name'
                                    label='Company Name'
                                    component={InputField}/>
                            </div>

                            <div style={regFormContainer.inputField}>
                                <Field
                                    type='text'
                                    name='website'
                                    placeHolder='www.example.com'
                                    label='Website'
                                    component={InputField}/>
                            </div>

                            <div style={regFormContainer.inputField}>
                                <Field
                                    type='text'
                                    name='location'
                                    placeHolder='California, USA'
                                    label='Location'
                                    component={InputField}/>
                            </div>

                            <div>
                                <Field
                                    type="textarea"
                                    name='overview'
                                    placeHolder='Company Overview'
                                    label='Overview'
                                    component={TextArea}/>
                            </div>
                        </div>

                        <div className="tms-signup-form-section-2">

                            <div style={regFormContainer.inputField}>
                                <Field
                                    type='text'
                                    name='facebook'
                                    placeHolder='Facebook username'
                                    label='Facebook'
                                    isRequired={false}
                                    component={InputField}/>
                            </div>

                            <div style={regFormContainer.inputField}>
                                <Field
                                    type='text'
                                    name='twitter'
                                    placeHolder='Twitter username'
                                    label='Twitter'
                                    isRequired={false}
                                    component={InputField}/>
                            </div>

                            <div style={regFormContainer.inputField}>
                                <Field
                                    type='text'
                                    name='instagram'
                                    placeHolder='Instagram username'
                                    label='Instagram'
                                    isRequired={false}
                                    component={InputField}/>
                            </div>

                            <div style={regFormContainer.inputField}>
                                <Field
                                    type='text'
                                    name='linkedin'
                                    placeHolder='LinkedIn username'
                                    label='LinkedIn'
                                    isRequired={false}
                                    component={InputField}/>
                            </div>

                            <div style={{ width: '83%', margin:"0 auto"}}>
                                <Field
                                    type='select'
                                    name='timezone'
                                    placeHolder='Select Timezone...'
                                    label='Timezone'
                                    component={Timezone}/>
                            </div>
                        </div>
                    </div>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div style={{display:"flex", justifyContent:"flex-start"}}>
                    <div style={{marginLeft:"auto"}}>
                        <Button onClick={props.close} style={{padding: '7px 12px', width:"80px"}} className="btn btn-default">Cancel</Button>
                        <Button className="btn btn-success"
                                type="submit"
                                style={{padding: '7px 12px', width:"80px"}}
                                disabled={props.pristine || props.submitting}>Save</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Form>
    </Modal>
);

const mapStateToProps = (_, props) => {
    return {
        initialValues: { ...props.company }
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: 'companyForm',
    enableReinitialize: true,
    touchOnBlur: false
})(companyForm))

