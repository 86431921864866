import React, { Fragment } from 'react'
import { Link } from "react-router-dom";
import GenericContextMenu from 'components/common/NavContextMenu/GenericContextMenu'
import { userRoles } from '_constants';
import Auth0LogoutButton from 'components/LoginPage/Auth0LogoutButton';
import CompanyBillingButton from "../../CompanyBilling/CompanyBillingButton";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 08/01/2019 : 4:53 PM
 * @author Chris Okebata
 */


const profileContextMenu = (props) => {

    const {profilePhoto, userName, userRole} = props;
    const isCompanyAdmin = userRole === userRoles.COMPANY_ADMIN_ROLE;
    const isChatdeskAdmin = userRole === userRoles.CHATDESK_ADMIN_ROLE;
    const isBillingAdmin = userRole === userRoles.BILLING_ADMIN_ROLE;

    return (
        <GenericContextMenu trianglePosition="top">
            <div className="user-profile-container" key="trigger">
                <div className="trds-user-image">
                    <img src={profilePhoto} style={{height: '100%'}}
                         className="trds-char" alt={`${userName}'s Profile`}/>
                </div>
                <span className="trds-username top-rel-10 font-16 color-green">
                {userName}
            </span>
                <i className="fa fa-caret-down top-rel-10 color-green"/>
            </div>

            <ul key="body">
                {isCompanyAdmin &&
                <li>
                    <a className="color-grey-dark" href="/company/channels">
                        Channels
                    </a>
                </li>
                }
                {(isChatdeskAdmin || isBillingAdmin) &&
                    <Fragment>
                        <li>
                            <Link className="color-grey-dark" to="/admin/settings">
                                Settings
                            </Link>
                        </li>
                        <li>
                            <Link className="color-grey-dark" to="/analytics">
                                Analytics
                            </Link>
                        </li>
                    </Fragment>
                }
                {isBillingAdmin &&
                    <Fragment>
                        <li>
                            <Link to="/payouts-rates" className="color-grey-dark">
                                Payouts
                            </Link>
                        </li>
                        <li>
                            <Link to="/pricing-rates" className="color-grey-dark">
                                Pricing Rates
                            </Link>
                        </li>
                        <li>
                            <Link to="/subscriptions" className="color-grey-dark">
                                Subscriptions
                            </Link>
                        </li>
                    </Fragment>
                }
                <li>
                    <Link className="color-grey-dark" to="/profile">
                        Profile
                    </Link>
                </li>
                {isCompanyAdmin &&
                <Fragment>
                    <li>
                        <Link className="color-grey-dark" to="/company/users">
                            Users
                        </Link>
                    </li>
                </Fragment>
                }
                <li>
                    <a
                      href="http://help.chatdesk.com/en/"
                      rel="noopener noreferrer" target="_blank"
                      className="color-grey-dark">
                        Help
                    </a>
                </li>
                {isCompanyAdmin &&
                    <li>
                        <CompanyBillingButton/>
                    </li>
                }
                <li>
                    <Auth0LogoutButton isLink={true}/>
                </li>
            </ul>
        </GenericContextMenu>
    )
};

export default profileContextMenu
