import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { withStyles  } from '@material-ui/core/styles';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import {OrganizationCheckbox, OrganizationMenu} from "../../components/common";
import stylesOrganizationTable from "./StylesOrganizationTable";
import connect from 'react-redux/es/connect/connect';
import { Button } from 'react-bootstrap';
import { organizationActions, utilityActions } from '_actions/index'
import { Loading, Paginate } from 'components/common';
import {removeItemFromArray, getCapitalizedWords} from "_helpers";
import {OrganizationModal} from "./OrganizationModal";
import {publicAndFeaturedVisibility} from "../../_constants";


/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 28/02/2019 : 3:25 PM
 * @author Chris Okebata
 */

let selectedOrganizations = [];
const selectAll = 'selectAll';

class Organizations extends Component {

    state = {
        orgColumn: 'Organization'
    };

    componentDidMount() {
        this.props.dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}));
        this.props.dispatch(utilityActions.getLanguages());
    }

    /**
     * Handles click on a pagination button/link.
     * @param page
     * @param params
     */
    paginationClickHandler = (page, params) => {
        params.offset = params.max * ( page.selected );
        this.props.dispatch(organizationActions.getAll(params))
    };

    isAllChecked = (orgArr, checked) => {
        checked = removeItemFromArray(selectedOrganizations, selectAll);

        if(orgArr.length === checked.length){
            selectedOrganizations.push(selectAll);
            return true
        }

        return false;
    };

    handleCheckboxSelect = (name, value) => {
        const {organizations} = this.props.organizations;

        if(value){
            if(name === selectAll){
                selectedOrganizations.push(name);
                for (let org in organizations) {
                    selectedOrganizations.push(organizations[org].id);
                }

            }else{

                selectedOrganizations.push(name);
                let _isAllChecked = this.isAllChecked(organizations, selectedOrganizations);

                if(_isAllChecked){
                    selectedOrganizations.push(selectAll);
                }
            }
        }
        else{

            if(name === selectAll){
                selectedOrganizations = []
            }else{
                let _isAllChecked = this.isAllChecked(organizations, selectedOrganizations);

                if(_isAllChecked){
                    selectedOrganizations = removeItemFromArray(selectedOrganizations, selectAll)
                }
                selectedOrganizations = removeItemFromArray(selectedOrganizations, name)
            }
        }
        selectedOrganizations = [...new Set(selectedOrganizations)];
        this.renderColumnText()
    };

    renderColumnText = () => {
        let orgColumnText = 'Organization';
        let {organizations} = this.props.organizations;
        let _isAllChecked = this.isAllChecked(organizations, selectedOrganizations);

        if(selectedOrganizations.length === 1){
            orgColumnText = selectedOrganizations.length + ' selected organization'
        }else if(selectedOrganizations.length < organizations.length && selectedOrganizations.length !== 0){
            orgColumnText = selectedOrganizations.length + ' selected organizations'
        }
        else if(_isAllChecked){
            orgColumnText = organizations.length + ' selected organizations'
        }
        else if(selectedOrganizations.length === 0){
            orgColumnText = 'Organization';
        }

        this.setState({
            orgColumn: orgColumnText
        });
    };

    handleClose = () => {
        this.setState({ showModal: false });
        this.resetSelection();
    };

    handleShowModal = (event) => {
        event.preventDefault();

        this.setState({
            showModal: true,
        });
    };

    handleUpdate = (data) => {
        const payload = {
            visibility: data.visibility,
            messageNotification: data.messageNotification,
            qualityScore: data.qualityScore,
            selectedOrganizations: selectedOrganizations,
            languages: data.languages,
            shopifyRevenueTracking: data.shopifyRevenueTracking,
            gorgiasRevenueTracking: data.gorgiasRevenueTracking,
            closeTicketsOnSource: data.closeTicketsOnSource,
            teamLead: data.teamLead,
            csm: data.csm,
            weeklyMetricsEmail: data.weeklyMetricsEmail,
            escalationsViaEmail: data.escalationsViaEmail,
            hideInMobile: data.hideInMobile,
            messageValidityDurationInDays: data.messageValidityDurationInDays,
        };

        this.props.dispatch(organizationActions.updateMultipleOrganizations(payload));
        this.resetSelection();
        this.setState({
            showModal: false,
        });
    };

    resetSelection = () => {
        selectedOrganizations = [];
        this.renderColumnText();
    };

    singleSelectedOrg = (selectedOrganizations, organizations) => {
        if(selectedOrganizations.length === 1){
            let obj = organizations.find(org => org.id === selectedOrganizations[0]);
            return obj
        }else{
            return null
        }
    };

    renderOrganizationRow = (organization) => {
        const { vertical } = organization._embedded;
        const { classes } = this.props;

        return (

            <TableRow className={classes.tableRow} key={organization.id}>
                <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography className={classes.typography15}>
                        {getCapitalizedWords(organization.name)}
                    </Typography>
                </TableCell>
                <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography className={classes.typography15}>
                        {vertical ? vertical.name : 'Not specified'}
                    </Typography>
                </TableCell>
                <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography className={classes.typography15}>
                        {organization.timezone ? organization.timezone : 'Not specified'}
                    </Typography>
                </TableCell>
                <TableCell component="th" scope="row" className={classes.tableCell}>
                    <Typography className={classes.typography15}>
                        {organization.notifications ? "On" : "Off"}
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography15}>
                        {organization.visibility ? getCapitalizedWords(organization.visibility) : 'Public'}
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <Typography className={classes.typography15}>
                        {organization.qualityScore ? "On" : "Off"}
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} style={{width: "10%"}}>
                    <span className="font-13 color-green"/>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <span className="font-13 color-green" style={{float:"right"}}>
                        <OrganizationCheckbox
                            orgId={organization.id}
                            selectedOrganizations={selectedOrganizations}
                            setValues={this.handleCheckboxSelect}
                        />
                    </span>
                </TableCell>
            </TableRow>
        );
    };

    render() {

        const { classes, languages } = this.props;
        const {breadcrumbContainer} = styles;

        let { organizations: { organizations, params, count }, loading } = this.props;

        if (loading || !organizations) {
            return <Loading />
        }

        const totalPages = Math.ceil(count / params.max);
        const activePage = Math.ceil(params.offset / params.max);

        return(
            <Fragment>

                {this.state.showModal && <OrganizationModal
                    onSave={this.handleUpdate}
                    bodyText="Adding this agent to your team will enable them to respond to customers"
                    headerText="Settings"
                    buttonText="Save changes"
                    organizationParam={this.singleSelectedOrg(selectedOrganizations, organizations)} //@TODO: If only one organization is selected, send the current settings to the modal: else set a default
                    onHide={this.handleClose}
                    show={this.state.showModal}
                    languages={languages}
                /> }

                <div style={{ padding: '0 38px' }}>
                    <div className="content-pane-90">
                        <div style={{...breadcrumbContainer, display: 'flex', justifyContent: 'space-between', padding: '5px 0'}}>
                        <span style={{padding: '10px 0', display: 'block'}} className="color-green font-15-bold">
                            Organizations
                        </span>
                        </div>

                        <Paper className={classes.root}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeadCell}>
                                        <span className={this.state.orgColumn === 'Organization' ? `` : `color-green`}>
                                            { this.state.orgColumn }
                                        </span>
                                        </TableCell>
                                        <TableCell className={classes.tableHeadCell}>
                                            { selectedOrganizations.length > 0 ? '' : 'Vertical'}
                                        </TableCell>
                                        <TableCell className={classes.tableHeadCell} style={{width:"20%"}}>
                                            { selectedOrganizations.length > 0 ? '' : 'Timezone'}
                                        </TableCell>
                                        <TableCell className={classes.tableHeadCell}>
                                            { selectedOrganizations.length > 0 ? '' : 'Notifications'}
                                        </TableCell>
                                        <TableCell className={classes.tableHeadCell} style={{width: "12%"}}>
                                            { selectedOrganizations.length > 0 ? '' : 'Visibility'}
                                        </TableCell>
                                        <TableCell className={classes.tableHeadCell} style={{width: "12%"}}>
                                            { selectedOrganizations.length > 0 ? '' : 'Quality Score'}
                                        </TableCell>
                                        <TableCell className={classes.tableHeadCell} style={{width: "10%"}}>
                                        <span className="font-13 color-green">
                                            { selectedOrganizations.length > 0 ? <OrganizationMenu openModal={this.handleShowModal} /> : ''}
                                        </span>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                        <span className="font-13 color-green" style={{float:"right", }}>
                                            <OrganizationCheckbox
                                                orgId={selectAll}
                                                selectedOrganizations={selectedOrganizations}
                                                setValues={this.handleCheckboxSelect}
                                            />
                                        </span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {organizations.map(this.renderOrganizationRow)}
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>
                </div>
            </Fragment>
        )
    }
}

let styles = {
    breadcrumbContainer: {
        width: 'auto',
        height: 'auto',
        border: '1px solid transparent',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between'
    }
};

const mapStateToProps = (state) => {
    const { organizations: { loading, organizations }, utility: {languages} } = state;
    return {
        loading,
        organizations,
        languages
    };
};

Organizations.propTypes = {
    classes: PropTypes.object.isRequired,
};

const connectedOrganizations = connect(mapStateToProps)(withStyles(stylesOrganizationTable)(Organizations));
export { connectedOrganizations as Organizations };
