import axios from "axios";
import {SERVER_URL} from "../../config";
import {authHeader} from "_helpers";

function initiateAuth() {
    const endpoint = `${SERVER_URL}/api/v1/tiktokshop/auth`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function createAccount(payload) {
    const endpoint = `${SERVER_URL}/api/v1/tiktokshop/accounts`;
    return axios.post(endpoint, payload,{
        headers: authHeader()
    });
}

function saveShop(payload) {
    const endpoint = `${SERVER_URL}/api/v1/tiktokshop/shops`;
    return axios.post(endpoint, payload,{
        headers: authHeader()
    });
}

function getTikTokShopProduct(params) {
    const { productId, msgDataSourceId } = params;
    const endpoint = `${SERVER_URL}/api/v1/tiktokshop/products/${productId}`;
    return axios.get(endpoint, {
        params: { msgDataSourceId },
        headers: authHeader()
    });
}

function getTikTokShopOrderDetails(params) {
    const { orderId, msgDataSourceId } = params;
    const endpoint = `${SERVER_URL}/api/v1/tiktokshop/orders`;
    return axios.get(endpoint, {
        params: { ids: orderId, msgDataSourceId },
        headers: authHeader()
    });
}

function getTikTokShopOrders(params) {
    const { buyerUserId, msgDataSourceId } = params;
    const endpoint = `${SERVER_URL}/api/v1/tiktokshop/orders/search`;
    return axios.get(endpoint, {
        params: { buyerUserId, msgDataSourceId },
        headers: authHeader()
    });
}

export const tiktokShopService = {
    initiateAuth,
    createAccount,
    saveShop,
    getTikTokShopProduct,
    getTikTokShopOrderDetails,
    getTikTokShopOrders,
};