import {alertMessages, dispatcherConstants} from "_constants";
import {dispatcherService} from "_services";
import {SnackBar} from "../components/common";
import {Auth} from "../components/Authorization";
import _ from "lodash";


function dispatchMessage(messageId, payload) {
    return dispatch => {
        dispatch(request(messageId));
        SnackBar.showMessage(alertMessages.DISPATCH_REQUEST);

        return dispatcherService.dispatchMessage(messageId, payload)
            .then(
                message => {
                    dispatch(success(message));
                    if (message) {
                        SnackBar.showMessage(alertMessages.DISPATCH_SUCESS);
                    }
                    return { success: true, response: message };
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.DISPATCH_FAILURE)
                    return { success: false, response: error.cause };
                }
            )
    };

    function request(messageId) {
        return {type: dispatcherConstants.DISPATCH_REQUEST, payload: messageId};
    }

    function success(message) {
        return {type: dispatcherConstants.DISPATCH_SUCESS, payload: message};
    }

    function failure(error) {
        return {type: dispatcherConstants.DISPATCH_FAILURE, payload: error};
    }
}

function deleteMessageFromSource(messageId, params) {

    return dispatch => {
        dispatch(request(messageId));

        dispatcherService.deleteMessageFromSource(messageId, params)
            .then(
                message => {
                    dispatch(success(message));
                    if (message) {
                        SnackBar.showMessage(alertMessages.DISPATCH_DELETE_SUCCESS);
                    }
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.DISPATCH_DELETE_FAILURE)
                }
            );
    };

    function request(messageId) {
        return {type: dispatcherConstants.DISPATCHER_DELETE_REQUEST, payload: messageId}
    }

    function success(message) {
        return {type: dispatcherConstants.DISPATCHER_DELETE_SUCCESS, payload: message}
    }

    function failure(error) {
        return {type: dispatcherConstants.DISPATCHER_DELETE_FAILURE, payload: error}
    }
}

function likeMessageFromSource(messageId, sourceName) {
    return dispatch => {
        dispatch(request(messageId));

        dispatcherService.likeMessageFromSource(messageId, sourceName)
            .then(
                message => {
                    dispatch(success(message));
                    if (message) {
                        SnackBar.showMessage(alertMessages.LIKE_MESSAGE_SUCCESS);
                    }
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.LIKE_MESSAGE_FAILURE)
                }
            );
    };

    function request(messageId) {
        return {type: dispatcherConstants.DISPATCHER_LIKE_MESSAGE_REQUEST, payload: messageId}
    }

    function success(message) {
        return {type: dispatcherConstants.DISPATCHER_LIKE_MESSAGE_SUCCESS, payload: message}
    }

    function failure(error) {
        return {type: dispatcherConstants.DISPATCHER_LIKE_MESSAGE_FAILURE, payload: error}
    }
}

function updateMessageFromSource(messageId, params) {

    return dispatch => {
        dispatch(request(messageId));

        dispatcherService.updateMessageFromSource(messageId, params)
            .then(
                message => {
                    dispatch(success(message));
                    if (message) {
                        SnackBar.showMessage(alertMessages.DISPATCH_UPDATE_SUCCESS);
                    }
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.DISPATCH_UPDATE_FAILURE)
                }
            );
    };

    function request(messageId) {
        return {type: dispatcherConstants.DISPATCHER_UPDATE_REQUEST, payload: messageId}
    }

    function success(message) {
        return {type: dispatcherConstants.DISPATCHER_UPDATE_SUCCESS, payload: message}
    }

    function failure(error) {
        return {type: dispatcherConstants.DISPATCHER_UPDATE_FAILURE, payload: error}
    }
}


function blacklist(messageId, msgSource) {

    return dispatch => {
        dispatch(request(messageId));

        dispatcherService.blacklist(messageId, msgSource)
            .then(
                message => {
                    dispatch(success(message));
                    if (message) {
                        SnackBar.showMessage(alertMessages.DISPATCH_BLACKLIST_SUCCESS);
                    }
                },
                error => {
                    dispatch(failure(error));
                    displayErrorMessage(error, alertMessages.DISPATCH_BLACKLIST_FAILURE)
                }
            );
    };

    function request(messageId) {
        return {type: dispatcherConstants.DISPATCHER_BLACKLIST_REQUEST, payload: messageId}
    }

    function success(message) {
        return {type: dispatcherConstants.DISPATCHER_BLACKLIST_SUCCESS, payload: message}
    }

    function failure(error) {
        return {type: dispatcherConstants.DISPATCHER_BLACKLIST_FAILURE, payload: error}
    }
}

const clearHtmlBody = () => {

    return dispatch => {
        dispatch({
            type: dispatcherConstants.CLEAR_HTML_BODY
        });
    }
};

const getHtmlBody = (responseId) => {
    const request = () => ({
        type: dispatcherConstants.GET_HTML_BODY_REQUEST
    });

    const success = (response) => ({
        type: dispatcherConstants.GET_HTML_BODY_SUCCESS,
        payload: {
            ...response
        }
    });

    const failure = (error) => ({
        type: dispatcherConstants.GET_HTML_BODY_FAILURE, error,
        payload: {
            error
        }
    });


    return dispatch => {
        dispatch(request());

        dispatcherService.getHtmlBody(responseId)
            .then(response => {
                dispatch(success(response.data));

            }).catch((error) => {
            if (_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }

            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
};

const messageDeleteStatus = (messageId, sourceName) => {

    return dispatch => {
        dispatcherService.messageDeleteStatus(messageId, sourceName)
            .then(
                response => {
                    if (response && response.removedFromSource) {
                        SnackBar.showMessage(alertMessages.MESSAGE_DELETED_FROM_SOURCE);
                    }
                }
            );
    };
};


const displayErrorMessage = (error, fallback) => {
    let message = fallback
    if (error && (error.userMessage || error.message)) {
        message = error.userMessage ? error.userMessage : error.message
    }
    SnackBar.showMessage(message)
};


export const dispatcherActions = {
    dispatchMessage,
    deleteMessageFromSource,
    updateMessageFromSource,
    blacklist,
    likeMessageFromSource,
    clearHtmlBody,
    getHtmlBody,
    messageDeleteStatus
};


