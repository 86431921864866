import React, { Component, Fragment } from 'react'
import BillingFilterForm from 'components/CompanyBilling/BillingFilterForm'
import UsageDetails from "components/CompanyBilling/UsageDetails";
import { queryStringToObject, objectToQueryString } from "_helpers";
import moment from "moment";

class Usage extends Component {
    constructor(props) {
        super(props)
        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);

        /**
         * By Default, if there is no selected start and end date,
         * we set the start date to the start of 2 years previously and the endDate today
         */
        let startDate = filterParams.startDate ? moment(filterParams.startDate) : moment().startOf('month');
        let endDate = filterParams.endDate ? moment(filterParams.endDate) : moment().format('YYYY-MM-DD');

        filterParams.startDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        filterParams.endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

        this.state = {
            filterParams: filterParams
        }
    }

    handleDatePickerUpdate = (event, data) => {
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, ...{
                        startDate: data.startDate.format('YYYY-MM-DD HH:mm:ss'),
                        endDate: data.endDate.format('YYYY-MM-DD HH:mm:ss'),
                    }
                }
            }
        })
    }

    /**
     * Handles the change of url to retrieve messages based on query params for filtering
     * @param filterParams The parameters for filtering the messages to be retrieved
     */
    getUsageData = (filterParams) => {
        const queryString = objectToQueryString(filterParams);

        this.props.history.push({
            pathname: '/billing',
            search: `?${queryString}&page=usage`
        });
    }

    handleHistoryFormSubmit = (event) => {
        event.preventDefault()
        const { filterParams: { endDate }} = this.state
        const newEndDate = moment().isSame(endDate, 'month') ?
            moment().add(1, 'd').format('YYYY-MM-DD HH:mm:ss') : endDate
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams,
                    endDate: newEndDate
                }
            }
        }, function() {
            const { filterParams } = this.state
            this.getUsageData(filterParams)
        })
    }


    render() {
        const { usage_details, total_amount } = this.props
        const { filterParams: {startDate, endDate}} = this.state;
        let setStartDate = moment(startDate).format('M/D/YYYY');
        const setEndDate = moment(endDate).format('M/D/YYYY');

        return (
            <Fragment>
                <div className="side-bar-pane" >
                    <BillingFilterForm
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onDatePickerUpdate={this.handleDatePickerUpdate}
                        onSubmit={this.handleHistoryFormSubmit}
                    />
                </div>

                <div className="container-right">
                    <div className="margin-bottom-50" style={{marginLeft: "38px", padding: "20px 33px 33px 33px"}}>
                        <div>
                            <UsageDetails
                                total_amount={total_amount}
                                usage_details={usage_details}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Usage