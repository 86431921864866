import React, {useEffect} from "react";
import {Box, Stack} from "@mui/material";
import ActionButton from "../../../common/ActionButton";
import {useDispatch, useSelector} from "react-redux";
import {stripeOauthActions, walletActions} from "_actions";
import PayoutsList from "./PayoutsList"
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import useLocationQueryParams from "../../../hooks/useLocationQueryParams";

export default function ExpertsPayouts(){
    const dispatch = useDispatch();
    const { params } = useLocationQueryParams()
    const {code} = params;

    const currentUser = JSON.parse(localStorage.getItem('user'));
    const userId = _.get(currentUser, 'id');

    useEffect(() => {
        dispatch(stripeOauthActions.stripeAccountStatus(userId))
        dispatch(walletActions.getAgentWalletBalance(userId));
    },[])

    const stripe = useSelector(state => state.stripeOauth);

    const statusLoading = stripe.loadingStripeAccountStatus
    const loadingOnboardingLink = stripe.loadingStripeOnboardingLink
    const loadingStripeTokenExchange = stripe.loadingStripeTokenExchange
    const status = stripe.stripeAccountStatus
    const wallet = useSelector((state) => state.wallet.walletBalance);
    const lifeTimeEarning = wallet?.wallet?.lifeTimeEarning

    const minAmount = 45;

    const {supported, stripeAccountId} = status

    useEffect(() => {
        if(code){
            dispatch(stripeOauthActions.stripeTokenExchange(code))
        }
    },[code])

    if(statusLoading || loadingStripeTokenExchange) {
        return(
            <Box sx={styles.loadingContainer}>
                <CircularProgress size={30} sx={{ color: '#07857C'}}/>
            </Box>
        )
    }

    const handleStripeOnboarding = () => {
        dispatch(stripeOauthActions.stripeOnboardingLink())
    }

    return(
        <Box sx={styles.root}>
            <Box sx={styles.container}>
                <Stack direction={{ xs: 'column', md: 'row' }} sx={styles.hero} spacing={2}>
                    <a href="https://experts.chatdesk.com/hc/en-us/sections/4438332805521-Getting-Paid"
                       rel="noopener noreferrer" target="_blank" className="font-16-bold color-green trd-nav-hover"
                    >
                        Learn more about payouts
                    </a>
                    <Box sx={{ width: { xs: '100%', sm: 'auto' }, height: '40px' }}>
                        { supported && !stripeAccountId &&
                            <ActionButton
                                label={'Connect to Stripe'}
                                disabled={loadingOnboardingLink || lifeTimeEarning < minAmount}
                                withIcon={lifeTimeEarning > minAmount}
                                action={handleStripeOnboarding}
                            />
                        }

                        { !supported &&
                            <Box sx={styles.textField}>It looks like you are based outside the USA. <br/> Please contact {" "}
                                <a href="mailto:experts@chatdesk.com" className="color-green trd-nav-hover">
                                    experts@chatdesk.com
                                </a> {" "} to setup your Stripe account
                            </Box>
                        }
                    </Box>
                </Stack>

                { supported && <PayoutsList/> }
            </Box>
        </Box>
    )
}

export const styles = {
    root: {
        fontFamily: 'LarsseitRegular',
        letterSpacing: '-0.02em',
        color: '#0C2728',
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },

    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
    },

    hero: {
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        mb: { xs: '24px', sm: '40px', },
    },

    loadingContainer: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EFEAE7',
    },

    textField: {
        fontSize: 16,
        fontFamily: 'LarsseitMedium',
        color: '#495D5E'
    },
}