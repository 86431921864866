import _ from "lodash";
import {getActionData} from "_helpers";
import {alertMessages, dixaConstants, tagConstants} from "_constants";
import {tagService} from "_services";
import {SnackBar} from "../components/common";
import {Auth} from "../components/Authorization";

function listTags(msgDataSourceId) {
    return (dispatch) => {
        dispatch(getActionData(tagConstants.LIST_TAGS_REQUEST));
        tagService.listTags(msgDataSourceId)
            .then(response => {
                dispatch(getActionData(tagConstants.LIST_TAGS_SUCCESS, response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401) {
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(tagConstants.LIST_TAGS_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.LIST_TAGS_ERROR);
        });
    }
}

function resetTagsList() {
    return (dispatch) => {
        dispatch(getActionData(tagConstants.CLEAR_TAGS));
    }
}

export const tagActions = {
    listTags,
    resetTagsList
};