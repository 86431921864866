import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {DateRange} from "components/common/formFields/DateRange";
import {Button, Form} from "react-bootstrap";
import {DropDownSelect} from "components/common";
import {OrganizationDropDownSelect} from "components/Message/OrganizationDropDownSelect";

const dropdownStyles = {
    borderColor: "#c4c9c7",
    boxShadow: "none",
    color: "#5a5f5e",
    width: "100%"
};

class FilterForm extends Component {

    render() {

        const {
            startDate,
            endDate,
            onDatePickerUpdate,
            typeOptions,
            organizationOptions,
            handleOrgFilterChange,
            submitting,
            onFormUpdate,
            checkedOrganizations,
            handleChange,
            onFormSubmit,
            screenshotType
        } = this.props;

        const typeInput = {onChange: onFormUpdate, value: screenshotType};
        const companiesInput = {onChange: handleChange};

        return (
            <Form inline onSubmit={onFormSubmit} className="messages-page-filter-form">
                <div>
                    <span className="font-14 color-grey-dark">Date</span>
                    <label className="calendar-today tms-inbox-calendar" style={{display: "block"}}>
                        <Field
                            submittedStartDate={startDate}
                            submittedEndDate={endDate}
                            id="datePicker"
                            name="datePicker"
                            defaultDateRange="today"
                            onEvent={onDatePickerUpdate}
                            values={startDate}
                            stype={dropdownStyles}
                            // placeholder={`${startDate} - ${endDate}`}
                            component={DateRange}
                        />
                    </label>
                </div>

                <div style={{marginTop: "10px", backgroundColor: "red !important"}} >
                    <span className="font-14 color-grey-dark">Type</span>
                    <label className="" style={{display: "block"}} >
                        <Field
                            name="type"
                            fieldName="type"
                            style={dropdownStyles}
                            input={typeInput}
                            options={typeOptions}
                            value={screenshotType}
                            component={DropDownSelect}
                        />
                    </label>
                </div>

                <div style={{marginTop: "10px"}}>
                    <span className="font-14 color-grey-dark">Organizations</span>
                    <div className="inbox-context-menu" style={{display: "block"}}>
                        <Field
                            name="organizations"
                            fieldName="organizations"
                            type="text"
                            input={companiesInput}
                            options={organizationOptions}
                            checkedOrganizations={checkedOrganizations}
                            // checkedOrganizations={organizations}
                            style={dropdownStyles}
                            caretPosition="left"
                            handleChange={handleOrgFilterChange}
                            component={OrganizationDropDownSelect}
                            placeHolder="Select Organization"
                        />
                    </div>
                </div>

                <div style={{marginTop: "15px"}}>
                    <Button bsStyle="success" type="submit" disabled={submitting} style={{width: "100%"}}>
                        <i className='fa fa-filter'/> Filter
                    </Button>
                </div>
            </Form>
        )
    }
}

const connectedForm = reduxForm({
    form: 'screenshotFilterForm'
})(FilterForm);

export {connectedForm as ScreenshotFilterForm}
