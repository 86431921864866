import React, { Component } from "react";
import { Button, Form, Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { OrganizationsContextMenu } from "../Message";

class FilterForm extends Component {

    render (){
        let{
            submitting,
            onFormSubmit,
            company,
            companiesIds,
            companies,
            handleOrgFilterChange,
            handleChange
        }=this.props;

        const companiesInput = {onChange: handleChange, value: companies};

        return (
            <Form inline onSubmit = {onFormSubmit}>
                <Grid>

                    <Row style={{display: 'flex', justifyContent: 'center'}}>

                        <Col className="inbox-context-menu" style = {{marginBottom: "5px"}} md={3}>
                            <label htmlFor="company">Organization</label>
                            <Field
                                name="company"
                                fieldName="company"
                                type="text"
                                input={companiesInput}
                                options={companies}
                                checkedOrganizations={company}
                                ids={companiesIds}
                                caretPosition="top"
                                handleChange={handleOrgFilterChange}
                                component={OrganizationsContextMenu}
                            />
                        </Col>
                        <Col style={{marginBottom: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                            paddingTop: '25px'
                        }}
                             md={2}>
                            <Button bsStyle="success" type="submit" disabled={submitting}><i
                                className='fa fa-filter'/> Filter</Button>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        currentUser: auth
    };
};

FilterForm = connect(mapStateToProps)(FilterForm);
const filterForm = reduxForm({
    form: 'FilterForm'
})(FilterForm);

export { filterForm as FilterForm }