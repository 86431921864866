import Select from 'react-select'
import React, {Component} from "react";

/**
 * this select field allows users to search dropdown
 */
class SearchableSelect extends Component {
    render() {
        const {
            options,
            placeholder,
            input: { value, onChange, onBlur },
        } = this.props;

        return (
            <Select
                {...this.props}
                value={
                    typeof value === "string"
                        ? options.filter(option => option.label === value)
                        : value
                }
                onChange={option => onChange(option)}
                onBlur={() => onBlur(value)}
                options={options}
                placeholder={placeholder}
            />
        );
    }
}

export {SearchableSelect}
