import {useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {organizationActions} from "_actions";
import _ from "lodash";
import {OrganizationBuilder} from "../objects/OrganizationBuilder";

export default function (visibility  = ['PUBLIC', 'FEATURED']) {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            dispatch(organizationActions.getAll({visibility}));
            return () => effectRan.current = true;
        }
    }, []);

    const organizationsObj = useSelector(state => state.organizations);
    const loading = organizationsObj.loading;
    const organizationObjList = _.get(organizationsObj.organizations, 'organizations') || []
    const organizations = useMemo(() => {
        const organizationArr = [];
        if(!loading && organizationObjList.length > 0) {
            organizationObjList.map( organization => {
                const builder = new OrganizationBuilder();
                builder.setId(_.get(organization, 'id') || '-');
                builder.setName(_.get(organization, 'name') || '-');

                organizationArr.push(builder.organization);
            });
        }
        return organizationArr
    }, [ organizationObjList ]) || [];

    return {organizations, loading}
}