import _ from "lodash";
import {getArrayFromObjectArray, getRecentDateFromArray, getTimeZone, getVotingOrganization} from "_helpers";
import moment from "moment";

const companyUpdateKey = 'companyUpdate';
export function buildGetCompanyUpdatePayload() {
    const organization = getVotingOrganization();
    const orgId = _.get(organization, 'id');
    return {
        isActive: true,
        organizationId: orgId,
        max: 1,
    }
}

/**
 * Returns true in the shouldViewUpdate property if the selected company's update has not yet been viewed
 * @param companyUpdate
 * @returns {{shouldViewUpdate: boolean, selectedUpdate: unknown}|boolean}
 */
export function shouldViewCompanyUpdate(companyUpdate) {
    const organization = getVotingOrganization();
    const orgId = _.get(organization, 'id');
    const viewedCompanies = getViewedCompanies();
    const update = filterCompanyUpdate(companyUpdate) || {};
    const dateCreated = _.get(update, 'dateCreated');
    const companyObj = viewedCompanies[`${orgId}`] || {};

    if(_.isEmpty(update) || (companyObj.postedDate === dateCreated && companyObj.isViewed)) {
        return {shouldViewUpdate: false, selectedUpdate: update};
    }

    viewedCompanies[orgId] = {
        postedDate: dateCreated,
        isViewed: false,
    };

    localStorage.setItem(companyUpdateKey, JSON.stringify(viewedCompanies));
    return {shouldViewUpdate: true, selectedUpdate: update};
}

/**
 * retrieves a list of viewed company updates from localstorage if they exist
 * @returns {any|*[]}
 */
export function getViewedCompanies() {
    if (localStorage.hasOwnProperty(companyUpdateKey)) {
        return JSON.parse(localStorage.getItem(companyUpdateKey));
    }
    return {};
}

export function formatDate(dateCreated) {
    const timezone = getTimeZone();
    return `${moment.tz(dateCreated, timezone).format('MM/DD/YYYY')} at ${moment.tz(dateCreated, timezone).format('hh:mm A z')}`;
}

function filterCompanyUpdate(companyUpdate) {
    const companyUpdateArr = _.get(companyUpdate, 'data');
    const dateCreatedArr = getArrayFromObjectArray(companyUpdateArr, 'dateCreated');
    const date = getRecentDateFromArray(dateCreatedArr);
    return _.find(companyUpdateArr, {'dateCreated': date});
}

export function setAsViewed() {
    const viewedCompanies = getViewedCompanies();
    const organization = getVotingOrganization();
    const orgId = _.get(organization, 'id');
    Object.assign(viewedCompanies[orgId], { isViewed: true });
    localStorage.setItem(companyUpdateKey, JSON.stringify(viewedCompanies));
}