import React, {useMemo} from 'react';
import {Box, Stack, Divider} from '@mui/material';
import { employmentStatus } from '_constants'
import HeadlineArrow from '../Utilities/HeadlineArrow';
import PersonRating from '../Utilities/PersonRating';

/**
 *
 * @param {object} props
 * @param {object} props.employment
 * @param {object} props.conversationStats
 */
export default function EmploymentDetailsOverview({conversationStats, employment}) {

	const employmentAgent = useMemo(() => {
		if (!employment || !employment._embedded || !employment._embedded.agent)
			return null

		return employment._embedded.agent
	}, [employment])

	return (
		<Box sx={styles.root}>
			<HeadlineArrow headline={'Overview'} />

			<Box sx={styles.container}>

				<Stack spacing={{ xs: 2, md: 3 }} divider={<Divider orientation="vertical" sx={{ borderWidth: '0.5px' }} flexItem />}>
					<Box>
						<Box sx={styles.smallHeadline}>About {employment.nickname || (employmentAgent && employmentAgent.firstName) || 'this person'}
						</Box>
						<Box sx={styles.bioDescription}>{employment.bio}</Box>
					</Box>

					<Stack spacing={{xs: 2, md: 3}} direction={{xs: 'column', md: 'row'}}>
						<Box sx={{flex: 1}}>
							<Box sx={styles.smallHeadline}>Location</Box>
							<Box sx={styles.smallDescription}>{(employmentAgent && employmentAgent.city) ?
								`${employmentAgent.city}${employmentAgent.state ? `, ${employmentAgent.state}` : ""}`
								: (employmentAgent?.city ?? employmentAgent?.state ?? "N/A")}</Box>
						</Box>
						<Box sx={{flex: 1}}>
							<Box sx={styles.smallHeadline}>Quality Score</Box>
							<Box sx={styles.smallDescription}>
								<PersonRating score={conversationStats && conversationStats.score}/>
							</Box>
						</Box>
						<Box sx={{flex: 1}}>
							<Box sx={styles.smallHeadline}>Volume</Box>
							<Box sx={styles.smallDescription}>
								{conversationStats && conversationStats.messageCount} messages
							</Box>
						</Box>
						<Box sx={{flex: 1}}>
							<Box sx={styles.smallHeadline}>Status</Box>
							<Box sx={styles.smallDescription}>
								{employment && employment.status === employmentStatus.HIRED ? 'Added to team' : 'Practicing'}
							</Box>
						</Box>
					</Stack>

				</Stack>
			</Box>
		</Box>
	)
}

const styles = {
	root: {
		flex: 1,
	},
	container: {
		backgroundColor: '#fff',
		border: '1px solid #D0D9D9',
		borderRadius: '10px',
		overflow: 'hidden',
		flex: 1,
		px: { xs: 2, md: 3 },
		py: { xs: 2, md: 4 },
	},
	smallHeadline: {
		fontFamily: 'larsseitBold',
		fontSize: { xs: '14px', md: '16px' },
		letterSpacing: '0.05em',
		textTransform: 'uppercase',
		mb: 1,
	},
	bioDescription: {
		fontSize: { xs: '16px', md: '20px' },
		mt: 1.5,
	},
	smallDescription: {
		fontSize: { xs: '16px', md: '18px' },
	},
	bodyLoading: {
		py: 2,
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: '0',
		paddingTop: '24px'
	},
	bioBodyLoading: {
		py: 2,
		display: 'flex',
		justifyContent: 'left',
		marginLeft: '45px',
		paddingTop: '0',
		paddingBottom: '0'
	},
	bodyLoadingSpinner: {
		color: '#143F42'
	}
}