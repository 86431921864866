import React from 'react';
import greenStatusIcon from "components/images/icon_status_green.png";
import redStatusIcon from "components/images/icon_status_red.png";
import yellowStatusIcon from "components/images/icon_status_yellow.png";
import {Col, Row, Table} from "react-bootstrap";

const getStatusIcon = (statusColor) => {
    if (statusColor === "Yellow"){
        return <img src={yellowStatusIcon} alt="status Yellow"/>
    } else if (statusColor === "Green")
    {
        return <img src={greenStatusIcon} alt="status Green"/>
    } else{
        return <img src={redStatusIcon} alt="status Red"/>
    }
}

const HandleStatusDisplay = ({sourceId, orgName, handleInfo}) => {
    const lastActivityDate = handleInfo.lastActivityDate
    const statusColor = handleInfo.status
    const handleName = handleInfo.name
    const statusIcon = getStatusIcon(statusColor)

    return (
        <tr key = {sourceId}>
            <td>
                {orgName}
            </td>
            <td>
                {handleName}
            </td>
            <td>
                { lastActivityDate ? lastActivityDate : " there is no message for this handle"}

            </td>
            <td>
                {statusIcon}
            </td>
        </tr>
    )
}

const SingleDataSourceStatusDisplay = ({statusInfo}) => {
    const dataSourceId = statusInfo.id
    const handles = statusInfo.handlesList
    const orgName = statusInfo.organization.name
    return (
        handles ? handles.map(handleStatus => {
            return <HandleStatusDisplay
                sourceId={dataSourceId}
                orgName={orgName}
                handleInfo={handleStatus}
            />
        }) : null
    )
}

const DataSourcesStatusesRow = ({dataSources}) => {
    return (
        dataSources ? dataSources.map(dataSource => {
            return <SingleDataSourceStatusDisplay
                statusInfo={dataSource}
            />
        }) : null
    )
}

export const DataSourcesStatusesTable = ({dataSources}) => {
    return (
        <Row>
            <Col md={12}>
                <Table striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th>Organization</th>
                        <th>Handle</th>
                        <th>Last Activity</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <DataSourcesStatusesRow dataSources={dataSources}/>
                    </tbody>
                </Table>

            </Col>
        </Row>
    )
}
