import {Logo} from "../Utilities/ChatdeskLogo";
import {footer} from "../Utilities/Footer";
import {Grid, useTheme} from "@mui/material";
import {Box} from "@mui/system";
import React, {Fragment, useEffect} from "react";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import BackgroundIcons from "./BackgroundIcons";
import WhatToExpectSteps from "./WhatToExpectSteps";
import Testimonial from "./Testimonial";
import {authorizationService} from "_services";
import ReactGA from "react-ga";
import {GOOGLE_ANALYTICS_TRACKING_ID} from "_constants";


export default function Dashboard() {
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname);

    authorizationService.completeOnBoarding();
  }, []);

  const {ChatdeskSymbol, ChatdeskWordMark} = Logo;
  const {DashboardFooter} = footer;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return(
    <Grid container height={{xs: 'auto', sm: 'auto', md: '100vh', lg: '100vh'}}>

      {/**************** Parent Left *****************/}
      <Grid item xs={12} sm={12} md={6} lg={6}
            order={{xs: 1, sm: 1, md: 1, lg: 1}}
            height={{xs: 'fit-content', sm: 'fit-content', md: 'auto', lg: 'auto'}}
            sx={{backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative'}}>

        <Box sx={{marginBottom: '50px'}}>
          <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
               px={{md: '35px', lg: '50px'}}
               sx={{height: 'auto'}}>
            <ChatdeskSymbol/>
          </Box>
          <Box px={{xs: '20px', sm: '20px', md: '35px', lg: '50px'}}
               py={{xs: '25px', sm: '25px', md: '25px', lg: '25px'}}
               display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none'}}
               sx={{}}>
            <ChatdeskWordMark/>
          </Box>
          <BackgroundIcons/>

          <Box sx={{height: 'auto', marginTop: '0px',}}
               px={{xs: '20px', sm: '20px', md: '35px', lg: '50px'}}>
            <Box mt={{xs: '20px', sm: '20px', md: '50px', lg: '50px'}}
                 mb={{xs: '45px', sm: '45px', md: '50px', lg: '50px'}}
                 style={ isMobile ? styles.descParagraphHeaderMobile : styles.descParagraphHeader}>
              What to expect
            </Box>

            <Box sx={{height: 'auto'}}>
              <Fragment>
                <Grid container style={styles.whatToExpectSection}>
                  <WhatToExpectSteps/>
                </Grid>
              </Fragment>
            </Box>
          </Box>
        </Box>
        <DashboardFooter/>
      </Grid>

      {/**************** Parent right *****************/}
      <Grid item xs={12} sm={12} md={6} lg={6}
            order={{xs: 2, sm: 2, md: 2, lg: 2}}
            px={{xs: '20px', sm: '20px', md: '35px', lg: '50px'}}
            py={{xs: '50px', sm: '50px', md: '157px', lg: '157px'}}
            height={{xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto'}}
            sx={{backgroundColor: '#144042'}}>

        <Box fontSize={{xs: '22px', sm: '22px', md: '32px', lg: '32px'}}
             sx={{color: '#ffffff', fontFamily: 'larsseitMedium', lineHeight: '120%'}}>
          Learn more about Chatdesk
        </Box>
        <Box mt={{xs: '12px', sm: '12px', md: '20px', lg: '20px'}}
             fontSize={{xs: '18px', sm: '18px', md: '24px', lg: '24px'}}
             sx={{color: '#ffffff', fontFamily: 'larsseitRegular', lineHeight: '120%'}}>
          Supercharge your support team and transform customer service into sales
        </Box>
        <Testimonial/>
      </Grid>
    </Grid>
  )
}

const styles = {
  descParagraphHeader: {
    color: '#0C2728',
    fontSize: '32px',
    lineHeight: '120%',
    fontFamily: 'LarsseitMedium',
    textAlign: 'left'
  },
  descParagraphHeaderMobile: {
    color: '#0C2728',
    fontSize: '28px',
    lineHeight: '100%',
    fontFamily: 'LarsseitMedium',
    textAlign: 'center'
  },
  whatToExpectSection:{
    display: 'flex',
    justifyContent:'space-between',
    flexDirection: 'row',
    position: 'relative',
  }
};