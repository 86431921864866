import {reduxForm} from "redux-form";
import React, {useState} from "react";
import Form from "react-bootstrap/lib/Form";
import {CriteriaResponseButtons} from "../Fields/CriteriaResponseButtons";
import {responseTimeCriteriaOptions} from "../MetricsDetailUtils";
import {ChannelsCheckboxes} from "../Fields/ChannelsCheckboxes";
import Stack from "@mui/material/Stack";
import {MetricsDetailsComponent} from "../MetricsDetailsComponent";

const ResponseTimeFilterDropdownForm = ({
                                            handles,
                                            selectedCriteria,
                                            setSelectedCriteria,
                                            setSelectedChannels,
                                            selectedChannels,
                                            allChannelsSelected,
                                            setAllChannelsSelected,
}) => {

    return (
        <Stack direction={'row'} spacing={1}>
            <ChannelsCheckboxes
                handles={handles}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                allChannelsSelected={allChannelsSelected}
                setAllChannelsSelected={setAllChannelsSelected}
            />
            <div className="filter-all-criteria" style={{marginLeft: '15px'}}>
                <CriteriaResponseButtons
                    selectedCriteria={selectedCriteria}
                    setSelectedCriteria={setSelectedCriteria}
                    criteriaOptions={responseTimeCriteriaOptions}
                />
            </div>
        </Stack>
    )
}

const filterDropdownForm = reduxForm({
    form: 'FilterDropdownForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
})(ResponseTimeFilterDropdownForm);

export { filterDropdownForm as FilterDropdownForm }