import React from 'react'
import {Checkbox, FormGroup} from "react-bootstrap";

class CheckBox extends React.Component {
    render() {

        const {placeHolder, label, style, input, ...rest} = this.props;

        return (

            <FormGroup controlId="formControlsSelect" hidden={this.props.hidden}>
                <Checkbox
                    {...rest}
                    {...input}
                    checked={input.value}
                    placeholder={placeHolder}
                    onChange={input.onChange}
                    style={style}
                    name={input.name}>{label ? label: null}</Checkbox>
            </FormGroup>

        )
    }
}

export {CheckBox}