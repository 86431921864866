import {authHeader, handleHttpResponseAndReturnError} from '_helpers';
import {SERVER_URL} from 'config';
import axios from "axios";

function supportsSingleV2Endpoint(msgSourceName) {
    return msgSourceName === "dixa";
}

function dispatchUrl(id, isV2Enabled, source) {
    const msgSourceName = source?.toLowerCase();

    if (supportsSingleV2Endpoint(msgSourceName)) {
        return `/api/v2/universal/messages/dispatch`;
    }

    return (isV2Enabled && source)
        ? `api/v2/${msgSourceName.replace(/\s/g, '-')}/messages/${id}/dispatch`
        : `api/messages/${id}/dispatch`;
}

function dispatchPayload(id, payload) {
    const params = payload ? payload : {};
    if (supportsSingleV2Endpoint(payload?.source?.toLowerCase())) {
        params.messageId = id;
    }
    return params;
}

function dispatchMessage(id, payload) {
    payload = dispatchPayload(id, payload);
    const {isV2Enabled, source} = payload;
    const url = dispatchUrl(id, isV2Enabled, source);

    return fetch(`${SERVER_URL}/${url}`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(payload)
        })
        .then(handleHttpResponseAndReturnError);
}

function deleteMessageFromSource(id, params) {
    params = params ? params : {}; //safe fallback if nothing was passed in params
    const {isV2Enabled, source} = params;
    const url = isV2Enabled && source ? `api/v2/${source.toLowerCase().replace(/\s/g, '-')}/messages/${id}/dispatch` : `api/messages/${id}/dispatch`
    return fetch(`${SERVER_URL}/${url}`,
        {
            method: 'DELETE',
            headers: authHeader(),
        })
        .then(handleHttpResponseAndReturnError);
}

function updateMessageFromSource(id, params) {

    params = params ? params : {};
    const {isV2Enabled, source} = params;
    let url = isV2Enabled && source ? `${SERVER_URL}/api/v2/${source.toLowerCase().replace(/\s/g, '-')}/messages/${id}/dispatch` : `${SERVER_URL}/api/messages/${id}/dispatch`;
    if (params.platform === 'trends' && (!isV2Enabled || !source)) {
        url = `${SERVER_URL}/api/messages/${id}/updateTrendsMessage`
    }
    return fetch(url,
        {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(params)
        })
        .then(handleHttpResponseAndReturnError);
}

function blacklist(id, msgSource) {
    const url = `${SERVER_URL}/api/v2/${msgSource.toLowerCase()}/messages/${id}/blacklist`
    return fetch(url,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponseAndReturnError);
}

function likeMessageFromSource(messageId, sourceName) {
    const url = sourceName && `${SERVER_URL}/api/v2/${sourceName.toLowerCase().replace(/\s/g, '-')}/messages/${messageId}/like`
    return fetch(url,
        {
            method: 'PUT',
            headers: authHeader(),
        })
        .then(handleHttpResponseAndReturnError);
}

function messageDeleteStatus(messageId, sourceName) {
    const url = sourceName && `${SERVER_URL}/api/v2/${sourceName.toLowerCase().replace(/\s/g, '-')}/messages/${messageId}/deleteStatus`
    return fetch(url,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponseAndReturnError);
}

const getHtmlBody = (responseId) => {
    const endpoint = `${SERVER_URL}/api/v1/dispatcher/htmlbody`;

    return axios.get(endpoint, {
        params: {
            responseId: responseId
        },
        headers: authHeader()
    });
};

export const dispatcherService = {
    dispatchMessage,
    deleteMessageFromSource,
    updateMessageFromSource,
    blacklist,
    likeMessageFromSource,
    getHtmlBody,
    messageDeleteStatus
};

