import _ from "lodash";
import {getPlaceholder} from "../../../utilities/TikTokShopDataUtils";

/**
 * @param attachments {array}
 * @returns {boolean}
 */
export function hasAttachment(attachments=[]) {
    if(_.isEmpty(attachments)) return false;
    return attachments.some(attachment => {
        return (!_.isEmpty(_.get(attachment, 'file_name')) &&
            !_.isEmpty(_.get(attachment, 'content_url')))
    })
}

export function getAttachment(attachment={}) {
    if(_.isEmpty(attachment)) return null;
    if(_.isEmpty(_.get(attachment, 'file_name')) ||
        _.isEmpty(_.get(attachment, 'content_url'))) return null
    return attachment;
}

export default function processPlaceholder(text) {
    return getPlaceholder(text);
}