import React, {useState, useRef, useEffect} from "react";
import {Box} from "@mui/material";
import { extractImageFileExtensionFromBase64,base64StringtoFile } from '_helpers/image.utils'
import ActionButton from "../../../common/ActionButton";
import {useDispatch} from "react-redux";
import {alertActions, userActions} from "_actions";
import {Image} from "react-bootstrap";
import picturePlaceholder from 'components/images/picture-placeholder.svg';
import ReactCrop from "react-image-crop";
import BasicModal from "../../Onboarding/Utilities/BasicModal";

const imageMaxSize = 1048576;
const acceptedFileTypes = ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml'];

function ImageEditor(props){
    const {openImageEditor, handleCloseEditImage, useAspectRation, userId, type, headerText = "Upload picture", cropRatio} = props
    const inputFile = useRef()
    const dispatch = useDispatch()
    const [useDimension, setUseDimension] = useState(false)
    const [count, setCount] = useState(0)
    const [imageDetails, setImageDetails] = useState({
        src: null,
        imageExtension: null,
        fileName: "",
        croppedFileName: "",
        croppedImage: {},
    })
    const [crop, setCrop] = useState({
        aspect: 1,
        width: 50,
        x: 0,
        y: 0,
    })

    useEffect(() => {
        if(count === 0 && (cropRatio || cropRatio === 0)) {
            setCrop({...crop, aspect: cropRatio})
        }
    },[count, cropRatio])

    const openBrowseDialog = () => {
        inputFile?.current?.click();
    }

    const processDimension = (files) => {
        if(files[0].type === 'image/svg+xml') setUseDimension(true)
        else setUseDimension(false)

    }

    const verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0];
            const currentFileType = currentFile.type;
            const currentFileSize = currentFile.size;
            if(currentFileSize > imageMaxSize) {
                dispatch(alertActions.error('File size exceeded maximum limit of 1mb'));
                return false
            }
            if (!acceptedFileTypes.includes(currentFileType)){
                dispatch(alertActions.error("File is not supported."));
                return false
            }
            return true
        }
    }

    const onFileSelect = (e) => {
        if (e.target.files && e.target.files.length > 0){
            const files = e.target.files;
            const isVerified = verifyFile(files);

            if(isVerified){
                processDimension(files)
                const imageName = e.target.files[0].name;
                const croppedImageName = "cropped-" + Date.now() + e.target.files[0].name;
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    const result = reader.result
                    setImageDetails({
                        ...imageDetails,
                        src: result,
                        imageExtension: extractImageFileExtensionFromBase64(result),
                        fileName: imageName,
                        croppedFileName: croppedImageName
                    })
                }, false);
                reader.readAsDataURL(e.target.files[0]);
            }
        }
    }

    let imageRef = useRef(null);

    const handleCloseModal = () => {
        setUseDimension(false)
        setImageDetails({
            src: "",
            imageExtension: "",
            fileName: "",
            croppedFileName: "",
            croppedImage: {},
        })
        setCrop({
            aspect: 1,
            width: 50,
            x: 0,
            y:0
        })

        handleCloseEditImage()
    }

    const onImageLoaded = (image) => {
        imageRef.current = image;
    };

    const getCroppedImg = (image, pixelCrop, fileName) => {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image.current,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height,
        );

        const imageData64 = canvas.toDataURL('image/' + imageDetails.imageExtension);
        return base64StringtoFile(imageData64, fileName);
    }

    const makeClientCrop = async(crop, pixelCrop) => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedImage = await getCroppedImg(
                imageRef,
                pixelCrop,
                imageDetails.croppedFileName,
            );
            setImageDetails({...imageDetails, croppedImage})
        }
    }

    const onCropComplete = (crop, pixelCrop) => {
        makeClientCrop(crop, pixelCrop);
    };

    const onCropChange = crop => {
        if(!useAspectRation && count > 0) { crop.aspect = 0 }
        setCount(count + 1)
        setCrop(crop)
    };

    const uploadImage = () => {
        const formData = new FormData();
        formData.append('photo', imageDetails?.croppedImage);
        dispatch(userActions.updateUserPhoto(userId, type, formData));
        handleCloseEditImage();
    }

    return(
        <BasicModal
            open={openImageEditor}
            handleClose={handleCloseEditImage}
            title={headerText}
        >
            <Box>
                { !imageDetails.src ?
                    <Box sx={styles.imageContainer}>
                        <Image responsive
                               src={picturePlaceholder}
                               style={{maxWidth:"125px", height:"125px", alignSelf:"center"}}
                        />
                        <Box sx={{width: 'fit-content'}}>
                            <Box sx={{width: '170px'}}>
                                <ActionButton
                                    label={'Browse picture'}
                                    buttonBordered={true}
                                    useBorderColor="#368C86"
                                    action={openBrowseDialog}
                                    type={'button'}
                                />
                            </Box>
                            <input ref={inputFile} style={{display: 'none'}} type='file' multiple={false} onChange={onFileSelect}/>
                        </Box>
                    </Box> :
                    <ReactCrop
                        src={imageDetails?.src}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        keepSelection
                        imageStyle={ useDimension ? { minHeight:"200px", maxHeight:"700px" } : {} }
                    />
                }
                <Box sx={styles.footer}>
                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Cancel'}
                            buttonBordered={true}
                            useBorderColor="#368C86"
                            action={handleCloseModal}
                            type={'button'}
                        />
                    </Box>

                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Save'}
                            action={uploadImage}
                        />
                    </Box>
                </Box>
            </Box>
        </BasicModal>
    )
}

const styles = {
    imageContainer: {
        padding: '60px 20px',
        height: '300px',
        paddingBottom:"30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:"column"
    },
    footer: {
        display: 'flex',
        gap: '18px',
        justifyContent: 'flex-end',
        paddingTop: '20px'
    },
};

export default ImageEditor