
import {chatdeskUpdatesConstants} from "_constants";


const INITIAL_STATE = {
    updatesList: [],
    update: {},
    loading: false,
    error: ''
}

export function chatdeskUpdatesReducer(state = INITIAL_STATE, action){
    switch (action.type){
        case chatdeskUpdatesConstants.FETCH_ALL_REQUEST:
            return { ...state, updatesList: [], loading: true, error: '' };
        case chatdeskUpdatesConstants.FETCH_ALL_FAILURE:
            return {...state, error: action.error, loading: false}
        case chatdeskUpdatesConstants.FETCH_ALL_SUCCESS:
            return {...state, updatesList: action.payload, loading: false}

        case chatdeskUpdatesConstants.SAVE_ALL_REQUEST:
            return { ...state, loading: true, error: '' };
        case chatdeskUpdatesConstants.SAVE_ALL_FAILURE:
            return {...state, error: action.error, loading: false}
        case chatdeskUpdatesConstants.SAVE_ALL_SUCCESS:
            return {...state, update: action.payload, loading: false}

        case chatdeskUpdatesConstants.EDIT_REQUEST:
            return { ...state, loading: true, error: '' };
        case chatdeskUpdatesConstants.EDIT_FAILURE:
            return {...state, error: action.error, loading: false}
        case chatdeskUpdatesConstants.EDIT_SUCCESS:
            return {...state, update: action.payload, loading: false}

        default:
            return state
    }
}