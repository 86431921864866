import React, { Component } from 'react';
import { scheduleActions, payoutsActions, pricingActions } from '_actions';
import AdminSchedulesEditForm from  './AdminSchedulesEditForm'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

class EditSchedule extends Component {

    constructor(props){
        super(props);

        this.state = {
            currentSchedule: {
                id: '',
                orgId: '',
                pricingRate: '',
                payoutRate: ''
            },
            submitting: false,
            updatedSchedule: false,
        }
    }

    componentDidMount() {
        const { match: { params: { orgId, scheduleId }}, dispatch } = this.props
        this.setState(prevState => ({ 
            currentSchedule: {
                ...prevState.currentSchedule,
                orgId: orgId, id: scheduleId
            },
        }))
        dispatch(scheduleActions.getSchedule(orgId, scheduleId))
        dispatch(payoutsActions.getAll())
        dispatch(pricingActions.getAll())
    }

    componentDidUpdate() {
        const { schedule } = this.props
        const { currentSchedule: { payoutRate, pricingRate }, updatedSchedule } = this.state
        if (! isEmpty(schedule) && ! payoutRate && ! pricingRate && ! updatedSchedule) {
            this.setState(prevState => ({ 
                currentSchedule: {
                    ...prevState.currentSchedule,
                    payoutRate: schedule.payoutRate ? schedule.payoutRate.id : '',
                    pricingRate: schedule.pricingRate ? schedule.pricingRate.id : '',
                },
                updatedSchedule: true
            }))
        }
    }

    handleUpdate = (event) => {
        const { target: { value, name }} = event
        this.setState(prevState => ({
            currentSchedule: {
                 ...prevState.currentSchedule,
                 [name]: value
            },
        }))
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { currentSchedule } = this.state
        const { dispatch } = this.props
        dispatch(scheduleActions.updateSchedule(currentSchedule))
        this.setState({ submitting: true })
    }

    render() {
        const { payoutRates, pricingRates, schedule } = this.props;
        const { currentSchedule: { pricingRate, payoutRate, orgId }, submitting } = this.state
        let input = {onChange: this.handleUpdate}
        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <div style={{width: '50%', background: '#fff', padding: '20px 40px'}}>
                    <AdminSchedulesEditForm
                        handleSubmit={this.handleSubmit}
                        input={input}
                        orgId={orgId}
                        schedule={schedule}
                        onChange={this.handleUpdate}
                        pricingRate={pricingRate}
                        payoutRate={payoutRate}
                        pricingRates={pricingRates}
                        payoutRates={payoutRates}
                        submitting={submitting}
                    />
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { pricing: { all_pricing }, payout: { all_payouts }, schedules: { current }} = state
    return {
        payoutRates: all_payouts,
        pricingRates: all_pricing,
        schedule: current
    }
}

const connectedComponent = connect(mapStateToProps)(EditSchedule);
export {connectedComponent as EditSchedule}