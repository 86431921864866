import React, {Fragment} from 'react'
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const BillingDetails = (props) => {
    const { billing, loading } = props
    return (
        <Fragment>
            <Row style={{marginTop: '20px'}}>
                <Col md={4} sm={12}>
                    <h4>Monthly balance remaining</h4>
                    <h1 className="color-green">{loading ? `0 USD` : `${billing.balance} USD`}</h1>
                </Col>
                <Col md={4} sm={12}>
                    <h4>Tickets solved by your Chatdesk Team</h4>
                    <h1>{billing.total_tickets}</h1>
                </Col>
                <Col xs={12} style={{marginTop: '15px'}}>
                    {/*<p>Starting balance: <strong>200 USD</strong></p>*/}
                    <button className="btn btn-success">Update</button>
                </Col>
            </Row>
            <hr style={{margin: '40px 0'}} />
            <h4 style={{marginBottom: '15px'}}>Billing History</h4>
            <ul style={{listStyle: 'none', paddingLeft: '15px'}}>
                <li style={{marginBottom: '10px'}}>
                    <Link to={"/billing/may-2019"}>May 2019</Link>
                </li>
                <li style={{marginBottom: '10px'}}>
                    <Link to={"/billing/april-2019"}>April 2019</Link>
                </li>
                <li style={{marginBottom: '10px'}}>
                    <Link to={"/billing/march-2019"}>March 2019</Link>
                </li>
                <li style={{marginBottom: '10px'}}>
                    <Link to={"/billing/february-2019"}>February 2019</Link>
                </li>
                <li style={{marginBottom: '10px'}}>
                    <Link to={"/billing/january-2019"}>January 2019</Link>
                </li>
                <li style={{marginBottom: '10px'}}>
                    <Link to={"/billing/december-2018"}>December 2018</Link>
                </li>
            </ul>
        </Fragment>
    )
}

export default BillingDetails