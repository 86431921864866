import {Box} from "@mui/material";
import React, {Fragment} from "react";
import BasicTable from "../../../../common/BasicTable";
import useViewCampaignAttributes from "../hooks/useViewCampaignAttributes";
import _ from "lodash";

export default function CampaignAttributeTable() {
    const {rows, columns} = useViewCampaignAttributes();

    if(_.isEmpty(rows)) return <Fragment/>;

    return(
        <Box>
            <BasicTable
                rows={rows}
                columns={columns}
                paperStyle={{borderRadius: '5px'}}
                tableCellStyle={style.tableCell}
                withPagination={false}
            />
        </Box>
    )
}

const style = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },

    tableCell: {
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        fontWeight: 500,
        color: '#0C2728',
        padding: '8px 5px',
        '&:first-of-type': {
            paddingLeft: '18px'
        },
        '&:last-of-type': {
            paddingRight: '18px',
        },
    },
    pagination: {
        overflow: 'hidden',
        borderTop: '1px solid #EEEFEF',
        height: '35px',
        '.MuiTablePagination-toolbar': {
            inlineSize: 'max-content',
            margin: 'auto',
            height: '10px',
            padding: '0'
        },
        '.MuiTablePagination-actions': {
            color: '#0C2728',
            height: '27px',
            button: {
                width: '25px',
                height: '25px',
                mx: '5px'
            },
            position: 'relative',
            top: '-7px',
        },
        '.MuiTablePagination-displayedRows': {
            position: 'relative',
            top: '-3px',
            fontFamily: 'LarsseitMedium',
            fontSize: '14px',
            color: '#0C2728',
        },
    },
}