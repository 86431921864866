import importedStyles from "../Utilities/styles";

export default {
    root: {
        ...importedStyles.root,
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    header: {
        borderBottom: '1px solid #CED4D4',
        padding: '15px 20px',
        fontFamily: 'LarsseitMedium',
        fontSize: '18px',
        fontWeight: 500,
        color: '#0C2728',
    },
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1150px',
        position: 'relative',
    },
    containerBody: {
        backgroundColor: '#FFF',
        border: '1px solid #CED4D4',
        borderRadius: '5px',
        overflow: 'hidden',
    },
    heading: {
        fontFamily: 'LarsseitMedium',
        fontSize: '18px',
        fontWeight: 500,
        color: '#0C2728',
    },
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    sectionDivider: {
        display: { xs: 'none', md: 'block' },
        mt: '20px',
        backgroundColor: '#E7E9E9'
    },
    repliesContainerEmptyState: {
        marginTop: '12px',
        border: '1px solid #DCE2E3',
        backgroundColor: '#F3F5F6',
        borderRadius: '6px',
        padding: '0 20px',
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#0C2728',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: {xs:'200px', sm: '200px', md: '200px', lg: '200px'}
    },
    footer: {
        borderTop: '1px solid #CED4D4',
        padding: '12px 20px',
        display: 'flex'
    },
    nameInputContainer: {
        marginTop: '9px'
    },
    channelInputContainer: {
        marginTop: '15px'
    },
    topicInputContainer: {
        marginTop: '15px'
    },
    durationInputContainer: {
        margin: '15px 0 0 0'
    },
    generateRepliesButtonContainer: {
        width: '192px',
        margin: '0px 0 20px 0'
    },
    replyItem: {
        padding: '10px',
        backgroundColor: '#F3F5F6',
        borderStyle: 'solid',
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: '#D0D9D9',
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        color: '#0C2728',
        '&:first-of-type': {borderTopLeftRadius: '6px', borderTopRightRadius: '6px', borderTopWidth: '1px'},
        '&:last-of-type': {borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px'},
    },
    replyItemTitle: {
        color: '#495D5E',
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
    },
    replyItemBody: {
        marginTop: '8px',
        color: '#0C2728',
        fontFamily: 'LarsseitRegular',
        fontSize: '16px',
        lineHeight: '19.52px'
    },
    replyButtonContainer: {
        marginTop: '20px',
        width: '85px',
    },
    suggestedResponseActionsContainer: {
        marginTop: '15px'
    },
    suggestedResponseButtonContainer: {
        border: '1px',
        display: 'flex'
    },
    editorButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    saveEditedSuggestedResponseButtonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    cancelEditedSuggestedResponseButtonContainer: {
        width: '84px'
    },
    repliesSection: {
        p: '21px 20px 60px 20px',
        borderTop: {xs: '1px solid #DCE2E3', sm: '1px solid #DCE2E3', md: '1px solid transparent', lg: '1px solid transparent'}
    },
    criteriaContainer: {
        p: {xs: '22px 20px 10px 20px', sm: '22px 20px 10px 20px', md: '22px 20px 60px 20px', lg: '22px 20px 60px 20px'},
        borderRight: {md: '1px solid #CED4D4', lg: '1px solid #CED4D4'}
    },
    desc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '16px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    emptyContainer: {
        backgroundColor: '#FFF',
        border: '1px solid #CED4D4',
        borderRadius: '5px',
        overflow: 'hidden',
        padding: '30px 35px',
    },
    editReplyItem: {
        backgroundColor: '#FFFFFF'
    }
}