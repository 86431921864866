import {Fragment} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DeleteScheduleModal from "./DeleteScheduleModal";
import * as React from "react";
import {useDispatch} from "react-redux";
import useModalSetup from "../../../../hooks/useModalSetup";
import {organizationSettingsActions} from "_actions";

export default function DeleteScheduleAction({scheduleId, organizationId}) {
    const dispatch = useDispatch();
    const {handleOpenModal, handleCloseModal, openModal} = useModalSetup();
    const handleDelete = () => {
        const params = { id: scheduleId, orgId: organizationId };
        dispatch(organizationSettingsActions.deleteSchedule(params));
    }

    return(
        <Fragment>
            <Tooltip title={'Delete schedule'} componentsProps={{tooltip: { sx: style.tooltip }}}>
                <IconButton component={'button'} size={'small'} onClick={handleOpenModal}>
                    <DeleteIcon sx={style.deleteIcon}/>
                </IconButton>
            </Tooltip>
            <DeleteScheduleModal
                title={'Delete schedule'}
                open={openModal}
                handleClose={handleCloseModal}
                handleDelete={handleDelete}
            />
        </Fragment>
    )
}

const style = {
    deleteIcon: {
        color:'#AC2925',
        fontSize: '22px',
        textAlign: 'right'
    },
    tooltip: {
        backgroundColor: '#DBD8FF',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        fontSize: '13px',
    },
}