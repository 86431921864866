import {Box, Stack} from '@mui/material'
import { getCurrentUser } from '_helpers';
import PageContainer from 'components/v2/common/PageContainer';
import '../Utilities/styles.css';
import importedStyles from '../Utilities/styles'

// Local
import PersonHeader from '../Utilities/PersonHeader';
import EmploymentList from './EmploymentList';
import HelpWidget from './HelpWidget';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo } from "react";
import { employmentActions } from "_actions";
import _ from "lodash";
import { employmentStatus } from '_constants';

export default function CompanyTeam() {
	const currentUser = getCurrentUser();
	const dispatch = useDispatch();
	const employmentState = useSelector(state => state.employment);
	const teamLead = employmentState.orgTeamLead;
	const loadingOrgTeamLead = employmentState.loadingOrgTeamLead;
	const csm = employmentState.orgCsm;
	const loadingOrgCsm = employmentState.loadingOrgCsm;

	useEffect(() => {
		dispatch(employmentActions.getOrgTeamLead(currentUser.organization_id))
		dispatch(employmentActions.getOrgCsm(currentUser.organization_id))
		dispatch(employmentActions.getOrgEmployments({ orgId: getCurrentUser().organization_id }));
	}, [])

	const teamAdminData = useMemo(() => {
		if (teamLead || loadingOrgTeamLead)
			return {
				profilePicture: _.get(teamLead, "photo") || "",
				firstName: _.get(teamLead, "firstName") || "",
				lastName: _.get(teamLead, "lastName") || "",
				nickname: _.get(teamLead, "nickname") || "",
				dateCreated: _.get(teamLead, "dateCreated") || "",
				organization: currentUser.organization,
				personHeaderType: "companyTeam"
		}
	}, [teamLead])

	const csmData = useMemo(() => {
		if (loadingOrgCsm || csm)
			return {
				firstName: _.get(csm, "firstName") || "",
				lastName: _.get(csm, "lastName") || "",
				username: _.get(csm, "username") || "",
				photo: _.get(csm, "photo") || "",
			}
	}, [csm]);

	return (
		<PageContainer backgroundColor='#EFEAE7' size='large'>
			<Stack spacing={{ xs: 4, md: 6 }} sx={importedStyles.root}>
				<PersonHeader {...teamAdminData} />

				<Stack direction={{ xs: 'column', lg: 'row' }} spacing={3} alignItems={'flex-start'}>
					<Stack spacing={{ xs: 4, md: 6 }} sx={{ flex: 1, width: '100%' }}>
						<EmploymentList headline='Your Team' status={employmentStatus.HIRED} />
						<EmploymentList headline='Practicing' status={employmentStatus.UNEMPLOYED} />
					</Stack>
					<Box>
						<HelpWidget {...csmData}/>
					</Box>
				</Stack>
			</Stack>
		</PageContainer>
	)
}