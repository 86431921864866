import {Box} from '@mui/system';

/**
 * Can be used as a page root container for v2 pages.
 * @param {object} props
 * @param {import('react').ReactNode}	props.children
 * @param {('default' | 'large' | 'fullpage')?} props.size The container width.
 * @param {string?} props.backgroundColor The page background color.
 */
export default function PageContainer({ children, size = 'default', backgroundColor = '#F8F9F9' }) {
	const getContainerStyles = () => {
		switch (size) {
			case 'default': return styles.containerSizeDefault
			case 'large': return styles.containerSizeLarge 
			case 'fullpage': return styles.containerSizeFullpage
		}
	}

	return (
		<Box sx={{...styles.root, backgroundColor }}>
			<Box sx={{ ...styles.container, ...getContainerStyles() }}>
				{children}
			</Box>
		</Box>
	)
}

const styles = {
  root: { pt: 16, pb: 10, minHeight: '100vh' },
  container: { px: { xs: 2, sm: 6 }, position: 'relative' },
	containerSizeDefault: { maxWidth: 980 },
	containerSizeLarge: { maxWidth: 1440, mx: 'auto' },
	containerSizeFullpage: { maxWidth: '100%' },
}