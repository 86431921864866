import React from 'react';
import LoadingPlaceholderBlock from "../../../../../components/common/LoadingPlaceholderBlock";
import ConversationCsatLoader from "./ConversationCsatLoader";
import PreSaleLoader from "./PreSaleLoader";
import {CSAT} from "../MetricsDetailUtils";

const MetricsConversationLoader = ({pageFilter}) => {
    const conversationRow = <div className="row conversation-item-container">
        <div className="col-2 metricsDetail-dp">
            <LoadingPlaceholderBlock width={'40px'} height={'40px'} borderRadius={'4px'} backgroundColor={'rgb(239, 234, 229)'} />
        </div>
        <div className="col-8 conversation-item-row">
            <div style={{display: 'flex', width: '100%', paddingBottom: '5px'}}>
                <LoadingPlaceholderBlock width={'96px'} height={'18px'} borderRadius={'3px'} backgroundColor={'rgb(239, 234, 229)'} />
                <div style={{ marginLeft: '342px'}}>
                    <LoadingPlaceholderBlock width={'245px'} height={'18px'} borderRadius={'3px'} backgroundColor={'rgb(239, 234, 229)'} />
                </div>
            </div>
            <LoadingPlaceholderBlock height={'90px'} borderRadius={'6px'} backgroundColor={'rgb(239, 234, 229)'} />
            <PreSaleLoader />
        </div>
    </div>
    return (
        <div style={{width: '100%', color: '#0C2728'}}>
            <div className="md-msg-container">
                {conversationRow}
            </div>
            { pageFilter === CSAT ? <ConversationCsatLoader /> :
                <div className="md-msg-container">
                    {conversationRow}
                </div>
            }
        </div>
    )
}

export { MetricsConversationLoader }