import {applicationsConstants} from "../_constants/applications.constants";

const INITIAL_STATE = {
    applicationDetails: null,
    loading: false,
    error: null
};

export function applicationsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case applicationsConstants.APPLICATIONS_GET_DETAILS_REQUEST:
            return { ...state, loading: true };
        case applicationsConstants.APPLICATIONS_GET_DETAILS_SUCCESS:
            return { ...state, applicationDetails: action.payload, loading: false };
        case applicationsConstants.APPLICATIONS_GET_DETAILS_FAILURE:
            return { ...state, error: action.error, loading: false };
        case applicationsConstants.APPLICATIONS_CLEAR:
            return {...INITIAL_STATE}
        default:
            return state
    }
}
