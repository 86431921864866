import BasicTable from "../../../common/BasicTable";
import {Box} from "@mui/material";
import useLocationQueryParams from "../../../hooks/useLocationQueryParams";
import useGetAllEarningsHistory from "../Utilities/hooks/useGetAllEarningsHistory";
import React, {useState} from "react";
import {earningsActions} from "../../../../../_actions";
import {useDispatch} from "react-redux";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";

export default function EarningsHistory(){
    const dispatch = useDispatch();
    const { params, setParams } = useLocationQueryParams();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const agentId = _.get(currentUser, 'id');
    const offsetBase = 10;
    const [page, setPage] = useState(() => {
        const offset = Number(params.offset);
        return offset ? (offset / offsetBase) : 0;
    });

    const payload = {
        offset: page * offsetBase
    }

    const { rows, columns, totalCount, loading } = useGetAllEarningsHistory(payload, agentId);
    const handlePageChange = (event, nextPage) => {
        setPage(nextPage);
        const queryParam = {...params, offset: nextPage * offsetBase}
        setParams({...params, ...queryParam});
        dispatch(earningsActions.getEarningsHistory(queryParam, agentId));
    };

    return(
        <Box sx={styles.root}>
            <Box sx={styles.container}>
                <Box component={'h1'} sx={styles.heading}>
                    Earnings History
                </Box>
                <Box>
                    { loading ? (
                        <Box sx={{height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress size={30} sx={{ color: '#07857C'}}/>
                        </Box>
                    ) : (
                        <>
                            { totalCount === 0 ? (
                                    <Box sx={{height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Box sx={styles.textField}>
                                            No Data
                                        </Box>
                                    </Box>
                                ) : (
                                    <BasicTable
                                        rows={rows}
                                        columns={columns}
                                        totalCount={totalCount}
                                        page={page}
                                        loading={loading}
                                        clickableRow={false}
                                        handlePageChange={handlePageChange}
                                    />
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    root: {
        fontFamily: 'LarsseitRegular',
        letterSpacing: '-0.02em',
        color: '#0C2728',
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
    },
    heading: {
        fontFamily: 'LarsseitMedium',
        fontSize: 24,
        letterSpacing: '-0.02em',
        color: '#0C2728',
        mb: { xs: '24px', sm: '40px', },
        mt: 0
    },
    textField: {
        fontSize: 16,
        fontFamily: 'LarsseitMedium',
        color: '#495D5E'
    },
}