import React from 'react'
import { useSelector } from "react-redux";
import {Box, Link} from "@mui/material";
import {buttonContained} from "components/v2/views/Onboarding/Utilities/ButtonContained";
import basicModalStyles from "./basicModalStyles";
import LoadingSpinner from './LoadingSpinner';

export default function SetupModalBottomBar({
	extraInfoLabel, openInNewTab, buttonLabel, disabled, loading, extraInfoLink
}){
	const {ActionButton, SignupButton} = buttonContained;
  const msgSourceLink = useSelector((state) => state.msgSources.msgSourceLink.msgSourceLink);

	return (
		<Box sx={basicModalStyles.buttonContainer}>
			<Box sx={basicModalStyles.buttonLeft}>
				{ (msgSourceLink || extraInfoLink) && (
					<Link 
						href={msgSourceLink || extraInfoLink}
						sx={{ color: '#368C86', fontFamily: 'LarsseitMedium', ':hover': { color: '#368C86', backgroundColor: 'unset' } }}
						target={openInNewTab ? '_blank' : '_self'}
						rel="noopener"
						underline="none">
						{extraInfoLabel || 'View setup instructions'}
					</Link>
				) }
			</Box>
			<Box sx={basicModalStyles.buttonRight}>
				{loading && (<Box sx={basicModalStyles.loadingSpinner}><LoadingSpinner /></Box>)}
				<SignupButton label={buttonLabel} disabled={loading} />
			</Box>
		</Box>
	)
}