import React, {Component, Fragment} from 'react';
import {calculateRating, filterObjectValue} from "_helpers";
import {StarRating} from "../index";
import connect from "react-redux/es/connect/connect";


/**
 * @author: Chris Okebata
 */

class Score extends Component {

  render() {

    const { messageId, scoreData } = this.props;



    if(scoreData.length === 0){
      return(
        <div>
          Loading...
        </div>
      )
    }

    const { messages } = scoreData;

    const message = filterObjectValue(messages || '', 'id', messageId);
    const score = message[0] && message[0].score;

    return(
      <Fragment>
        {calculateRating(score || 0)} &nbsp;
        <StarRating starDimension={"20px"} numberOfStars={1} rating={score || 0} />
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {
    agentScore: { messageScore }
  } = state;

  return { scoreData : messageScore };
}

const connectedScore = connect(mapStateToProps)(Score);
export {connectedScore as Score}