import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import { msgSourceActions } from '_actions';
import PageContainer from 'components/v2/common/PageContainer';
import ChannelAccordionItem from './ChannelAccordionItem';
import ReconnectChannelsSection from './ReconnectChannelsSection';
import styles from './styles'
import './ListChannels.css';

// V1 Components, replace these maybe with V2 Components later
import { Loading } from 'components/common';
import { history } from '_helpers';

/**
 * Filter out the ones where `msgSources[0].msgDataSources[0].isActive` equals true or false.
 */
const getFilteredMsgSources = ({ msgSources, returnCredentialsExpired }) => {
	return msgSources?.filter(msgSource => {
		const filteredMsgDataSources = msgSource.msgDataSources?.filter(msgDataSource => {
			return msgDataSource.isCredentialsExpired === returnCredentialsExpired
		})

		if (!filteredMsgDataSources.length)
			return false

		return {
			...msgSource,
			msgDataSources: filteredMsgDataSources
		}
	})
};

const getFilteredInactiveMsgSources = ({ msgSources, returnCredentialsExpired }) => {
	return msgSources.map((msgSource) => {
		const filteredMsgDataSources = msgSource.msgDataSources?.filter(
			(msgDataSource) => msgDataSource.isCredentialsExpired === returnCredentialsExpired
		) || [];

		if (!filteredMsgDataSources.length) return null;

		return { ...msgSource, msgDataSources: filteredMsgDataSources };
	}).filter((item) => item !== null);
};

// copied from /v2/views/Onboarding/Utilities/ButtonContained
function ActionButton({ label, action, disabled, sx }) {
  return(
    <Box sx={{...sx, ...{height: '40px'}}}>
      <Button 
        sx={{
          width: '100%',
          fontSize: '16px',
          padding: '12px 24px',
          color: '#fff',
          fontFamily: 'LarsseitMedium',
          lineHeight: 1,
          textTransform: 'none',
          borderRadius: '50px',
          backgroundColor: '#368C86',
          ':hover': {
            backgroundColor: '#34756f',
            color: 'white',
          },
          ':disabled': {
            color: '#fff',
            backgroundColor: '#368C8680'
          },
          ':focus': {
            outline: '0'
          }
        }}
        type="submit"
        onClick={action}
        variant="contained"
        disableElevation
        disabled={disabled}>
        {label}
      </Button>
    </Box>
  )
}

export default function ListChannels() {
	const dispatch = useDispatch()
	const _msgSourcesFromState = useSelector((state) => state.msgSources.msgSources)
	const loading = useSelector((state) => state.msgSources.loading)

	const msgSources = useMemo(() => getFilteredMsgSources({ msgSources: _msgSourcesFromState ?? [], returnCredentialsExpired: false }), [_msgSourcesFromState])
	const msgSourcesInactive = useMemo(() => getFilteredInactiveMsgSources({ msgSources: _msgSourcesFromState ?? [], returnCredentialsExpired: true }), [_msgSourcesFromState])

	useEffect(() => {
		// Dispatch `getAll` to fetch all msgSources into state.
		const currentUser = JSON.parse(localStorage.user);
		const organizationId = currentUser._embedded.organization.id;
		dispatch(msgSourceActions.getAll({ organizationId: organizationId }))
	}, [])

	if (loading)
		return <Loading />

	return (
		<PageContainer>
			<Box sx={styles.headingBox}>
				<Box component={'h1'} sx={{ ...styles.h1, mb: 0 }}>
					Channels
				</Box>
				
				<ActionButton
					label={'Connect new channel'}
					action={() => history.push('/company/connectchannels')}
				/>
			</Box>

			{(msgSources && msgSources.length) ? (
				<Box sx={{ ...styles.card, mt: '32px' }}>
					<Box sx={styles.cardHeader}>
						Currently connected ({msgSources.length})
					</Box>
					{ msgSources.map((msgSource, key) => (
						<ChannelAccordionItem
							msgSource={msgSource}
							key={msgSource.id}
							keyNumber={key}	
						/>
					)) }
				</Box>
			) : null}

			{(msgSourcesInactive && msgSourcesInactive.length) ? (
				<ReconnectChannelsSection msgSourcesInactive={msgSourcesInactive} />
			) : null}
		</PageContainer>
	)
}
