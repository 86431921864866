export default {
    filterLabel: {
        marginBottom: '7px',
        color: '#495D5E',
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        letterSpacing: '-0.02em'
    },
    emptyResultDescription: {
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        color: '#0C2728',
        letterSpacing: '-0.01em'
    },
    textField: {
        height: '40px',
        [`& input`]: {
            fontSize: '14px',
            fontFamily: 'LarsseitMedium',
        },
    },
    textFieldContainer: {
        marginBottom: '7px',
        // border: '1px solid'
    },
    sectionDivider: {
        display: { xs: 'none', md: 'block' },
        margin: '7px 0 7px 0',
        backgroundColor: '#B6BEBF'
    },
    valuesContainer: {
        height: '325px',
    },
    valuesScrollArea: {
        overflowY: 'auto',
        height: '100%',
        paddingRight: '10px',
        marginLeft: '-10px',
    }
}