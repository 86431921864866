import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Box} from "@mui/system";
import { Typography } from '@mui/material';
import BasicTooltip from "./BasicTooltip";
import TableSortLabel from "@mui/material/TableSortLabel";

export default function BasicTable({
   rows,
   columns,
   totalCount,
   handlePageChange,
   page,
   handleTableRowClick,
   handleTableCellClick,
   isPaginationDisabled,
   clickableRow,
   tableContainerStyle,
   tablePaginationStyle,
   paperStyle,
   tableCellStyle,
   tableHeadStyle,
   withPagination=true
}) {
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    const handleRowClick = (param) => {
        if(clickableRow) handleTableRowClick(param);
    }

    const handleCellClick = (param, clickable) => {
        if(clickable) handleTableCellClick(param);
    }

    const displayColumnValue = (rowId, styles, column, value) => {
        return <Box sx={styles.ellipsis}>
            {column.format && typeof value === 'number'
                ? column.format(value)
                : value}
        </Box>
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const convertRowValue = (arr, convertFrom, convertTo) => {
        return arr.map(row => {
            const updatedRow = {...row};
            Object.keys(updatedRow).forEach(key => {
                if (updatedRow[key] === convertFrom) {
                    updatedRow[key] = convertTo;
                }
            });
            return updatedRow;
        });
    }

    const sortedRows = React.useMemo(
        () => {
            const updatedRows = stableSort(convertRowValue(rows, "-", "0"), getComparator(order, orderBy))
            return convertRowValue(updatedRows, "0", "-")
        },
        [order, orderBy, rows],
    );

    return (
        <Paper sx={ withPagination ? {...styles.paperWithPagination, ...paperStyle} : {...styles.paper, ...paperStyle}}>
            <TableContainer sx={{...styles.tableContainer, ...tableContainerStyle}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    align={column.align}
                                    sx={{...styles.tableCell, width: column.width, ...tableCellStyle, ...tableHeadStyle}}
                                >
                                    { column.isSortable ?
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : "asc"}
                                            onClick={(e) => handleRequestSort(e, column.id)}
                                            sx={styles.ellipsis}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                        : column.label
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}
                                              onClick={()=>handleRowClick(row.param)} sx={clickableRow ? styles.tablePointer: {}}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} onClick={()=>handleCellClick(row.param, column.clickable)}
                                                           sx={{...styles.tableCell, ...column.clickable && styles.tablePointer, maxWidth: column.width, minWidth: column.minWidth, ...tableCellStyle}}>
                                                    {
                                                        column.withTooltip ?
                                                            <BasicTooltip title={
                                                                <Box sx={styles.tooltipTypographyContainer}>
                                                                    <Typography sx={styles.tooltipTypography}>
                                                                        {displayColumnValue(row.id, styles, column, value)}
                                                                    </Typography>
                                                                </Box>
                                                            }>
                                                                {displayColumnValue(row.id, styles, column, value)}
                                                            </BasicTooltip> :
                                                            displayColumnValue(row.id, styles, column, value)
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                withPagination &&
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    sx={{...styles.pagination, ...tablePaginationStyle}}
                    backIconButtonProps={
                        isPaginationDisabled
                            ? {
                                disabled: isPaginationDisabled
                            }
                            : undefined
                    }
                    nextIconButtonProps={
                        isPaginationDisabled
                            ? {
                                disabled: isPaginationDisabled
                            }
                            : undefined
                    }
                />
            }
        </Paper>
    );
}

const styles = {
    paperWithPagination: {
        width: '100%',
        overflow: 'hidden',
        borderRadius: '8px',
        border: '1px solid #CED4D4',
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0.5px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0px',
    },
    paper: {
        width: '100%',
        overflow: 'hidden',
        borderRadius: '8px',
        borderTop: '1px solid #CED4D4',
        borderLeft: '1px solid #CED4D4',
        borderRight: '1px solid #CED4D4',
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0.5px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0px',
    },
    pagination: {
        '.MuiTablePagination-toolbar': {
            inlineSize: 'max-content',
            margin: 'auto'
        },
        '.MuiTablePagination-actions': {
            color: '#0C2728',
        },
        '.MuiTablePagination-displayedRows': {
            position: 'relative',
            top: '5px',
            fontFamily: 'LarsseitMedium',
            fontSize: '14px',
            color: '#0C2728'
        },
    },
    tableContainer: {
        maxHeight: 740,
    },
    tableCell: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#0C2728',
        '&:first-of-type': {
            paddingLeft: '30px'
        },
        '&:last-of-type': {
            paddingRight: '30px',
        },
    },
    tablePointer: {
        cursor: 'pointer'
    },
    ellipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tooltipTypographyContainer: {
        minWidth: '250px',
        maxWidth: '500px',
        padding: '10px'
    },
    tooltipTypography: {
        width: '100%',
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        color: '#0C2728',
    }
}
