import React from 'react';
import Link from "@mui/material/Link";


export default function BasicLink({label, link, openInNewTab}) {

    return(
        <Link sx={{
            width: '100%',
            fontSize: '16px',
            padding: '0',
            color: '#07857C',
            fontFamily: 'LarsseitMedium',
            textTransform: 'none',
            ':hover': {
                color: '#285a55',
                textDecoration: 'none',
            },
            ':focus': {
                color: '#07857C',
                textDecoration: 'none',
            },
        }} href={link} target={openInNewTab ? '_blank' : ''} rel="noopener" underline="none">
            {label}
        </Link>
    )
}