import React, {Fragment} from 'react';
import  { connect } from "react-redux";
import moment from 'moment';
import { userActions } from "_actions";
import {objectToQueryString, queryStringToObject} from "_helpers";
import { FilterForm } from "components/SlippingReport"
import { Loading } from "../common";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import _ from "lodash";


class SlippingReportIndex extends React.Component {
    constructor(props) {
        super(props);

        const search =  this.props.location.search.substring(1)
        const filterParams = queryStringToObject(search)
        let startDate = filterParams.startDate ? moment(filterParams.startDate) : moment().startOf('day');
        let endDate = filterParams.endDate ? moment(filterParams.endDate) : moment().endOf('day');

        filterParams.startDate = moment(startDate).format("YYYY-MM-DD");
        filterParams.endDate = moment(endDate).format("YYYY-MM-DD");

        this.state = {
            filterParams: {
                ...{
                    offset: 0,
                    max: 10,
                }, ...filterParams
            }
        };

    }

    componentDidMount() {
        const { filterParams } = this.state;
        this.props.dispatch(userActions.getLessActiveAgents(filterParams))
    }

    handleDatePickerUpdate = (event, data) => {
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, ...{
                        startDate: data.startDate.format('YYYY-MM-DD'),
                        endDate: data.endDate.format('YYYY-MM-DD')
                    }
                }
            }
        });
    };

    getReportDetails = (filterParams) => {
        const queryString = objectToQueryString(filterParams);

        this.props.history.push({
            pathname: '/slipping-report',
            search: `?${queryString}`
        });
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams, ...{
                        offset: 0,
                    }
                }
            }
        }, function(){
            const { filterParams } = this.state;
            this.getReportDetails(filterParams)
        })
    }

    render(){
        const {
            filterParams: {
                startDate, endDate
            }
        } = this.state;

        let { lessActiveAgents } = this.props;
        lessActiveAgents = lessActiveAgents || [];

        const setStartDate = moment(startDate).format('YYYY-MM-DD');
        const setEndDate = moment(endDate).format('YYYY-MM-DD');
        const input = {onChange: this.handleFormUpdate}


        return (
            <div className="container-pane">
                <div className="side-bar-pane shadow-border">
                    <FilterForm
                        onFormSubmit={this.handleFormSubmit}
                        input={input}
                        startDate={setStartDate}
                        endDate={setEndDate}
                        onDatePickerUpdate={this.handleDatePickerUpdate}
                     />
                </div>
                <div className="container-right messages-container-right">
                    { this.props.loading &&
                        <Loading/>
                    }
                    {(!this.props.loading) &&
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th className="tms-width-50">Most Recent Vote Date</th>
                            <th className="tms-width-50">Expert</th>
                            <th className="tms-width-50">Username</th>
                            <th className="tms-width-50">Amount Paid</th>
                        </tr>
                        </thead>
                        <tbody>
                        {lessActiveAgents.length > 0 ? lessActiveAgents.map(user => {
                            return (
                                <tr key={user.id}>
                                    <td>
                                        {moment(user.mostRecentVoteDate).format('MMMM Do YYYY, h:mm:ss a')}
                                    </td>
                                    <td>
                                        <Link to={`experts/details/${_.get(user, 'id')}`}>
                                            {user.firstName + " " + user.lastName}
                                        </Link>
                                    </td>
                                    <td>{user.username}</td>
                                    <td>{user.amountPaid}</td>
                                </tr>)
                        }) : <Fragment/>}
                        </tbody>
                    </Table>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    const {
        users: {
            loading,
            lessActiveAgents,
        }} = state;

    return {
        loading,
        lessActiveAgents
    };
}

const slippingReportIndex = connect(mapStateToProps)(SlippingReportIndex)
export { slippingReportIndex as SlippingReportIndex };
