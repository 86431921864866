import {jiraConstants} from "_constants";

const INITIAL_STATE = {
    closeJiraModal: false,
    loading: false,
    link: ''
};

export function jiraReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case jiraConstants.JIRA_BUG_REQUEST:
            return {...state, loading: true};
        case jiraConstants.JIRA_BUG_SUCCESS:
            return {...state, loading: false, closeJiraModal:true, link: action.payload.link};
        case jiraConstants.JIRA_BUG_FAILURE:
            return {...state, data: action.payload, loading: false};
        case jiraConstants.JIRA_CLEAR_TOGGLE:
            return {...state, closeJiraModal: false};
        default:
            return state
    }
}