import {useState} from "react";
import {Box, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import _ from "lodash";
import NavigationPanel from "./Utilities/NavigationPanel";
import styles from "./organizationMainStyles";
import SectionEntry from "./Sections/SectionEntry";
import OrganizationFilterSelect from "./Utilities/OrganizationFilterSelect";
import useOrganizationURI from "./hooks/useOrganizationURI";
import {useParams} from "react-router-dom";
import SettingsSearchEntry from "./Utilities/Search/SettingsSearchEntry";
import useGetOrganizations from "../Proactive/Utilities/hooks/useGetOrganizations";

export default function OrganizationSettingsEntry() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    const currentUserRole = loggedInUser && loggedInUser.roles && loggedInUser.roles[0];
    const { setUri } = useOrganizationURI();
    const visibility = ['PUBLIC', 'PRIVATE', 'FEATURED', 'CLOSED']
    const { organizations, loading: loadingOrganizations } = useGetOrganizations(visibility)
    const [location, setLocation] = useState("");
    const params = useParams();
    const organizationIdParam = _.get(params, 'id');
    const [organizationId, setOrganizationId] = useState(_.isEmpty(organizationIdParam) ? "" : organizationIdParam);
    const selectedOrganization = organizations?.find((item) => item.id === organizationId);

    const handleOrganizationSelectChange = (event) => {
        const organizationId = event?.id;
        setUri(organizationId);
        setOrganizationId(organizationId);
    }

    return(
        <>
            <Box sx={styles.root}>
                <Box sx={styles.container}>
                    <Box sx={{...style.headerWrapper, ... isMobile ? style.flexColumnRev : style.flexRow}} >
                        <Box sx={{minWidth: '285px'}}>
                            <SettingsSearchEntry setLocation={setLocation}/>
                        </Box>
                        <Box sx={{minWidth: '285px'}}>
                            <OrganizationFilterSelect
                                organizations={organizations || []}
                                loading={loadingOrganizations}
                                value={selectedOrganization || null}
                                handleOrganizationSelectChange={handleOrganizationSelectChange}
                            />
                        </Box>
                    </Box>
                    <Box sx={{...style.bodyWrapper, ... isMobile ? style.flexColumn  : style.flexRow}} >
                        <NavigationPanel userRole={currentUserRole} setLocation={setLocation} />
                        <SectionEntry organizationId={organizationId} />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const style = {
    headerWrapper: {
        display: 'flex',
        gap: 4,
        alignItems: 'flex-start',
        mb: '25px'
    },
    bodyWrapper: {
        display: 'flex',
        gap: 4,
        alignItems: 'flex-start',
    },
    flexColumn: {
        flexDirection: 'column'
    },
    flexColumnRev: {
        flexDirection: 'column-reverse'
    },
    flexRow: {
        flexDirection: 'row'
    },
}