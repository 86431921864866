import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import QueueIcon from '@mui/icons-material/Queue';
import PeopleIcon from '@mui/icons-material/People';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SourceIcon from '@mui/icons-material/Source';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {userRoles as roles} from "_constants";
import {fragmentId} from "./organizationSettingsConstants";

export default function (userRole) {
    const isBillingAdmin = userRole && userRole === roles.BILLING_ADMIN_ROLE;
    const isChatDeskAdmin = userRole && userRole === roles.CHATDESK_ADMIN_ROLE;
    const allRoles = isBillingAdmin || isChatDeskAdmin;

    return [
        {
            name: 'Company Information',
            icon: AutoAwesomeMosaicIcon,
            permission: allRoles,
            fragmentId: fragmentId.INFO,
        },
        {
            name: 'Triage and Queue Management',
            icon: QueueIcon,
            permission: allRoles,
            fragmentId: fragmentId.QUEUE,
        },
        {
            name: 'Expert Management',
            icon: PeopleIcon,
            permission: allRoles,
            fragmentId: fragmentId.EXPERT,
        },
        {
            name: 'Notifications',
            icon: NotificationsIcon,
            permission: allRoles,
            fragmentId: fragmentId.NOTIFICATIONS,
        },
        {
            name: 'Active Data Sources',
            icon: SourceIcon,
            permission: allRoles,
            fragmentId: fragmentId.DATA_SOURCES,
        },
        {
            name: 'Message Responses',
            icon: QuestionAnswerIcon,
            permission: allRoles,
            fragmentId: fragmentId.RESPONSES,
        },
        {
            name: 'Offboard',
            icon: DeleteForeverIcon,
            permission: isBillingAdmin,
            fragmentId: fragmentId.OFF_BOARD,
        },
    ]
}