import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import spinner from '../images/spinner.gif'
import {authActions} from "_actions";
import {CopyrightFooter, TimezonePicker} from "components/common";
import { Link } from 'react-router-dom';
import chatdeskLogo from "components/images/chatdesk-logo-dark.svg";
import {isValidEmail, required, fieldsMatch, countries} from "_helpers";
import {regFormContainer } from 'components/RegisterPage';
import {SearchSelect} from "../common/formFields/SearchSelect";
import {passwordStrength} from "check-password-strength";


const validate = (values) => {
    const errors = {};
    const {password, confirmPassword, username, firstName, lastName} = values;
    const pwdStrength = passwordStrength(password);
    const firstNameStrength = passwordStrength(firstName);
    const lastNameStrength = passwordStrength(lastName);

    if (!fieldsMatch(password, confirmPassword)){
        errors.confirmPassword = "passwords don't match"
    }

    if (!isValidEmail(username)){
        errors.username = `invalid email`
    }

    if (firstName) {
        if (firstNameStrength.contains.includes('number')) {
            errors.firstName = "First Name must contain only letters";
        } else if (firstNameStrength.contains.includes('symbol')) {
            errors.firstName = "First Name must contain only letters";
        } else if (firstName.includes(" ")) {
            errors.firstName = "Space is not allowed!";
        }
    }

    if (lastName) {
        if (lastNameStrength.contains.includes('number')) {
            errors.lastName = "Last Name must contain only letters";
        } else if (lastNameStrength.contains.includes('symbol')) {
            errors.lastName = "Last Name must contain only letters";
        } else if (lastName.includes(" ")) {
            errors.lastName = "Space is not allowed!"
        }
    }

    if (password) {
        if (pwdStrength.length < 8) {
            errors.password = "Password should be at least 8 characters long";
        } else if (!pwdStrength.contains.includes('uppercase')) {
            errors.password = "Should contain at least one uppercase letter";
        } else if (!pwdStrength.contains.includes('lowercase')) {
            errors.password = "Should contain at least one lowercase letter";
        } else if (!pwdStrength.contains.includes('symbol')) {
            errors.password = "should contain at least one symbol (ex. !@#$%^*)";
        } else if (!pwdStrength.contains.includes('number')) {
            errors.password = "Password should contain a number (0-9)";
        }
    }
    if (!errors.password) {
        errors.pwdStrength = "Strong";
    }
    return errors
};

const InputField = ({ input, label, type, meta: {touched, error, warning} }) => {
    const errorText = touched && error;
    const isStrongPassword = input.name === "password" && !error && input.value !== "";

    return (
        <div>
            {!isStrongPassword && (
                <div style={regFormContainer.error} className="font-12 color-danger">
                {(errorText && <span>{errorText}</span>) ||
                 (touched && warning && <span>{warning}</span>)}
            </div>
            )}
            {isStrongPassword && (
                <div style={regFormContainer.normalMessage} className="font-12 color-green">
                    <span>Strong password</span>
                </div>
            )}
            <input {...input} type={type}
                   className="input-field"
                   required/>
            <label htmlFor={label}><span>{label}</span></label>
        </div>
    );
};


class AgentSignup extends Component {

    constructor(props) {
        super(props);
        this.props.dispatch(authActions.logout('/expert-signup'));
    }

    onSubmit(params) {
        this.props.dispatch(authActions.registerAgent(params));
    }

    render() {
        const { handleSubmit, registering } = this.props;
        return (
            <div className="tms-sign-up-body">
                <div className="margin-top-50 logo-pos-sign-up">
                    <img src={chatdeskLogo} alt="Chatdesk logo" className="logo"/>
                </div>
                <div className="tms-signup-wrapper">
                    <div className="tms-container">
                        <div className="tms-signup-container" style={{padding:"10px"}}>
                        <div className="tms-signup-title-container">
                            <span className="font-22-heavy color-grey-dark">Create your account</span>
                        </div>
                        <form name="form" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                            <div className="tms-signup-form-content">
                                <div className="tms-signup-form-section-1">
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="firstName"
                                            label="First Name"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="lastName"
                                            label="Last Name"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="phoneNumber"
                                            label="Phone Number"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="username"
                                            label="Email"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="password"
                                            label="Password"
                                            component={InputField}
                                            validate={required}
                                            type="password"
                                        />
                                    </div>
                                    <div style={regFormContainer.password}>
                                        <Field
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            component={InputField}
                                            validate={required}
                                            type="password"
                                        />
                                    </div>
                                </div>
                                <div className="tms-signup-form-section-2">
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="address"
                                            label="Address"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="city"
                                            label="City"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>
                                        <Field
                                            name="state"
                                            label="State"
                                            component={InputField}
                                            validate={required}
                                            type="text"
                                        />
                                    </div>
                                    <div style={regFormContainer.inputField}>

                                          <div className="tms-selectField" style={{ width: '83%', margin:"0 auto"}}>
                                              <span className="countryLabel">Country</span>
                                            <Field
                                                options={countries}
                                                validate={required}
                                                component={SearchSelect}
                                                name="country"
                                                clearable={true}
                                                placeholder="Select a country"
                                            />
                                          </div>
                                    </div>

                                    <div style={regFormContainer.inputField}>
                                        <div className="tms-selectField" style={{ width: '83%', margin:"0 auto"}} >
                                            <Field
                                                name="timezone"
                                                label="Timezone"
                                                component={TimezonePicker}
                                                validate={required}
                                                inputProps={{
                                                    placeholder: 'Select a timezone'
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tms-signup-inner-wrapper">
                                <div className="tms-signup-button-wrapper">
                                    <div className="tms-signup-button-container">
                                    <div className="tms-signup-link-login">
                                        <span className="">
                                            <Link to="/" className="btn btn-link color-grey-dark"
                                                  style={{ lineHeight: "0", padding: "0" }}>Cancel</Link>
                                        </span>
                                    </div>
                                    <div className="tms-signup-button-holder">
                                        <button className="btn btn-block btn-success font-15" type="submit">
                                            Signup
                                            {registering && <img alt='loading' src={spinner}/>}
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
                <CopyrightFooter/>
            </div>
        );
    }
}

const formConfig = {
    form: 'AgentRegistrationForm',
    validate
};



export default reduxForm(formConfig)(AgentSignup);
