import { SERVER_URL } from "config";
import { authHeader, handleHttpResponse } from '_helpers';


export const twitterOauthService = {
    getOauthUrl,
    generateOauthToken,
};

function getOauthUrl(callbackUrl) {

    return fetch(`${SERVER_URL}/api/twitter/twitterAuthUrl?callbackUrl=${callbackUrl}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function generateOauthToken(data) {

    return fetch(`${SERVER_URL}/api/twitter/genAccessToken`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

