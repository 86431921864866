import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { DateRange } from 'components/common/formFields/DateRange';

const StatsFilterForm = (props) => {
  const {
    submitting,
    handleSubmit,
    exportClicked,
    setStartDate,
    setEndDate,
    onSubmit,
    summaryStats: { solvedTickets, lowPriority, highPriority },
  } = props;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <span className="font-14 color-grey-dark">Date</span>
        <span className="calendar-today tms-inbox-calendar" style={{ display: 'block' }}>
          <Field
            submittedStartDate={setStartDate}
            submittedEndDate={setEndDate}
            name="dateRange"
            type="text"
            values={setStartDate}
            component={DateRange}
          />
        </span>
      </div>

      <div style={{ marginTop: '20px' }}>
        <Button className="btn btn-success btn-block font-15" type="submit" disabled={submitting}>
          Apply
        </Button>
        <Button
          onClick={exportClicked}
          className="btn btn-success btn-block font-15"
          disabled={solvedTickets + lowPriority + highPriority === 0}
          style={{color: '#005A37', backgroundColor: '#fff', borderColor:'#fff'}}
        >
          Export Data
        </Button>
      </div>
    </Form>
  );
};


function validate(values) {
  const errors = {};

  if (!values.dateRange) {
    errors.error = 'No valid input';
  }

  return errors;
}


const mapStateToProps = (state) => {
  const { auth, organizations: { summaryStats } } = state;

  return {
    currentUser: auth,
    summaryStats,
  };
};

const FilterForm = connect(mapStateToProps)(StatsFilterForm);

const filterForm = reduxForm({
  form: 'FilterForm',
  validate,
  touchOnBlur: false,
})(FilterForm);

export default filterForm;

StatsFilterForm.propTypes =  {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  exportClicked: PropTypes.func,
  setStartDate: PropTypes.string,
  setEndDate: PropTypes.string,
  onSubmit: PropTypes.func,
};