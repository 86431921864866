import {Fragment} from "react";
import TagContent from "./TagContent";
import useViewNextMessage from "../../../../Utilities/hooks/useViewNextMessage";

export default function TagContainer({handleClose, handleSelectedTags, selectedTags}) {

    return(
        <Fragment>
            <TagContent
                handleClose={handleClose}
                handleSelectedTags={handleSelectedTags}
                selectedTags={selectedTags}
            />
        </Fragment>
    )
}