import {Box} from "@mui/system";
import React from "react";
import CharacterChecker from "./CharacterChecker";
import {makeStyles} from "@mui/styles";
import {isValidPassword} from "_helpers";

export default function PasswordStrengthIndicator({ value, isVisible, passwordStrength, meta: {touched, error, warning} }) {
  const classes = useStyles();
  const isValidCharCount = passwordStrength.length >= 8;
  const isValidOptions = passwordStrength.contains.length >= 3;
  const hasError = touched && !_.isEmpty(error) && !isValidPassword(value);

  return(
    <Box className={ isVisible ? ( hasError ? classes.containerError : classes.container ) : classes.hide }>
      <Box className={ hasError ? classes.headerError : classes.header }>
        Your password must contain:
      </Box>
      <Box className={classes.checkerContainer}>
        <CharacterChecker
          label={'At least 8 characters'}
          isChecked={isValidCharCount}
          hasError={hasError} />
      </Box>
      <Box className={classes.checkerContainer}>
        <CharacterChecker
          label={'At least 3 of the following:'}
          isChecked={isValidOptions}
          hasError={hasError} />
      </Box>
      <Box sx={{margin: '8px 23px 0 23px'}}>
        <Box className={classes.checkerContainer}>
          <CharacterChecker
            label={'Lower case letters (a-z)'}
            isChecked={passwordStrength.contains.includes('lowercase')}
            hasError={hasError}/>
        </Box>
        <Box className={classes.checkerContainer}>
          <CharacterChecker
            label={'Upper case letters (A-Z)'}
            isChecked={passwordStrength.contains.includes('uppercase')}
            hasError={hasError} />
        </Box>
        <Box className={classes.checkerContainer}>
          <CharacterChecker
            label={'Numbers (0-9)'}
            isChecked={passwordStrength.contains.includes('number')}
            hasError={hasError} />
        </Box>
        <Box className={classes.checkerContainer}
             sx={{marginBottom: '0px !important'}}>
          <CharacterChecker
            label={'Special characters (ex. !@#$%^*)'}
            isChecked={passwordStrength.contains.includes('symbol')}
            hasError={hasError} />
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  hide:{
    opacity: 0,
    height: 0,
    display: 'none'
  },
  container: {
    border: '1px solid #0C2728',
    height: 'auto',
    color: '#0C2728',
    borderRadius: '10px',
    padding: '18px',
    opacity: 1,
    transition: 'opacity 1000ms',
    display: 'block'
  },
  containerError: {
    border: '1px solid #EB5757',
    height: 'auto',
    color: '#0C2728',
    borderRadius: '10px',
    backgroundColor: '#EB5757',
    padding: '18px',
    opacity: 1,
    transition: 'opacity 1000ms',
    display: 'block'
  },
  header: {
    fontFamily: 'larsseitRegular',
    fontSize: '14px',
    color: '#0C2728',
    lineHeight: '120%',
    marginBottom: '8px'
  },
  headerError: {
    fontFamily: 'larsseitRegular',
    fontSize: '14px',
    color: '#ffffff',
    lineHeight: '120%',
    marginBottom: '8px'
  },
  checkerContainer: {
    marginBottom: '8px',
    display: 'flex'
  }
});