import React from "react";

export const CampaignNamesDropdown = ({ onSelectChangeHandler, dropdownOptions }) => {
    return (
        <div
            style={{
            display: 'inline-block',
            width: '237px',
            height: 'auto',
            borderRadius: '8px',
            marginRight: '12px',
            fontFamily: 'LarsseitRegular',
            fontSize: '14px',
            fontWeight: '500',
            border: '1px solid #9EA9A9',
            backgroundColor: '#FFF',
            position: 'absolute',
            left: '0',
            top: '46px',
            zIndex: '10'
        }}
            className="scrollable-campaign-list">
            {dropdownOptions && dropdownOptions.map(option =>
                <div
                    key={option.id}
                    className="filter-options-div"
                    onClick={() => onSelectChangeHandler(option)}>
                    {option.name}
                </div>
            )}

        </div>
    )
}