import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import _ from "lodash";
import DirectionFilterItem from "./DirectionFilterItem";
import Filter from "../FilterUtililties/Filter";
import BasicButton from "../../BasicButton";
import TextButton from "../../TextButton";
import {messageDirection} from "_constants";
import {messageActions} from "_actions";
import {filterChannels} from "../../../views/Vote/Utilities/VoteUtils";
import useViewOrgDataSourceHandles from "../../../views/Vote/Utilities/hooks/useViewOrgDataSourceHandles";

export default function TestChannelsFilter({rootStyle, withOptional= true, handleClose}) { //@TODO: This should be a reusable template
    const {dataSourceHandles} = useViewOrgDataSourceHandles();
    const queryParams = useSelector(state => state.search.queryParams);
    const dispatch = useDispatch();
    const ALL_DIRECTIONS = 'ALL';

    const channelObjArr = filterChannels(dataSourceHandles, 'trendsId');
    const channelsParam = _.get(queryParams, 'channels') || [];
    const directionsParam = _.get(queryParams, 'direction');
    const directions = directionsParam === ALL_DIRECTIONS ? [messageDirection.INCOMING, messageDirection.OUTGOING] : [directionsParam];

    const [checkedDirections, setCheckedDirections] = useState(directions);
    const [checkedItemsValues, setCheckedItemsValues] = useState(() => {
        const checkedItems = channelsParam.map((item) => _.toString(item));
        return checkedItems || [];
    });

    const directionObjArr = [
        {
            name: 'Incoming',
            value: 'INCOMING',
        },
        {
            name: 'Outgoing',
            value: 'OUTGOING',
        }
    ]

    const searchMessageWithFilter = () => {
        const selectedDirection = (checkedDirections.length > 1 ? ALL_DIRECTIONS : checkedDirections[0]) || ALL_DIRECTIONS;
        const channelsParams = checkedItemsValues.map(i=>Number(i));
        const payload = {...queryParams, channels: channelsParams, direction: selectedDirection}
        dispatch(messageActions.searchMessages(payload));
    }

    const applyFilters = () => {
        searchMessageWithFilter()
        handleClose();
    };

    const resetFilters = () => {
        setCheckedDirections(directions);
        setCheckedItemsValues(_.toString(channelsParam).split(','));
    };

    return(
        <Box sx={{...styles.root, ...rootStyle}}>
            <Box sx={styles.filterSection}>
                <Box sx={styles.title}>Filter by</Box>
                <Grid container>
                    <Grid item sx={{}}
                          xs={12} sm={12} md={withOptional ? 8 : 12} lg={withOptional ? 8 : 12}
                          order={{xs: 2, sm: 2, md: 1, lg: 1}}>
                        <Box sx={styles.primaryFilterContainer}>
                            <Filter
                                label={'Channel'}
                                options={channelObjArr}
                                checkedItemsValues={checkedItemsValues}
                                setCheckedItemsValues={setCheckedItemsValues}
                            />
                        </Box>
                    </Grid>
                    <Grid item sx={{}} xs={12} sm={12} md={4} lg={4} order={{xs: 1, sm: 1, md: 2, lg: 2}}>
                        <Box sx={styles.secondaryFilterContainer}>
                            <DirectionFilterItem
                                options={directionObjArr}
                                checkedDirections={checkedDirections}
                                setCheckedDirections={setCheckedDirections}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={styles.footerSection}>
                <Box>
                    <TextButton
                        label={'Reset filters'}
                        sx={{height: '20px', marginTop: '17px'}}
                        rootStyle={{fontSize: '14px', height: '18px'}}
                        action={resetFilters}
                    />
                </Box>
                <Box>
                    <BasicButton
                        style={{fontSize: '14px'}}
                        action={applyFilters}
                        label={'Apply filters'}/>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    root: {
        minWidth: '500px',
        maxWidth: '700px',
    },
    title: {
        marginBottom: '20px',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
    },
    filterSection: {
        padding: '20px 20px 7px 20px',
        borderBottom: '1px solid #B6BEBF',
    },
    footerSection: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    primaryFilterContainer: {
        width: '100%',
    },
    secondaryFilterContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}