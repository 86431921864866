import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { GenericModal } from 'components/common';

const AgentPayoutModal = (props) => {
  const {
    handleSubmit, showModal, closeModal, amount,
    submitting, onChange, currentBalance,
  } = props;

  return (
    <GenericModal
      headerText="Agent Payout"
      hideFooter
      show={showModal}
      onHide={closeModal}
    >
      <Form onSubmit={handleSubmit}>
        {parseFloat(amount, 10) > parseFloat(currentBalance, 10)
                    && (
                    <div className="text-danger text-center">
                        You cannot pay out an amount greater than the Agent's Balance
                    </div>
                    )
                }
        <h4 className="text-warning text-center">
            Current Balance:
            {currentBalance}
        </h4>
        <span className="font-14 color-grey-dark">Amount</span>
        <div className="form-group has-success">
          <input
            name="amount"
            className="form-control color-grey-dark"
            value={amount}
            onChange={onChange}
          />
        </div>
        <div className="col-12">
          <Button
            className="btn btn-success btn-block font-15"
            type="submit"
            disabled={submitting || amount > currentBalance}
          >
                        Make Payout
          </Button>
        </div>
      </Form>
    </GenericModal>
  );
};

export { AgentPayoutModal };
