import React, {Fragment} from "react";
import ReamazeSetupModalForm from "./ReamazeSetupModalForm";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";

export default function ReamazeSetupModal({
	open,
	handleClose,
	handleOpen
}){
  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Reamaze'}>

        <ReamazeSetupModalForm
          description={'Please copy and paste your Reamaze credentials here:'}
          buttonLabel={'Submit'}
          openInNewTab={true}
          handleClose={handleClose} />
      </BasicModal>
    </Fragment>
  )
}