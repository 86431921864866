import _ from "lodash";
import {cleanResponseText, getArrayFromObjectArray} from "_helpers";
import {cannotResolveOptionType, handleType, msgSource, navigationType, votingOptions} from "_constants";
import htmlToText from "html-to-text";

const navigation = {
    type: navigationType.AGENT_SELECTION,
};

export function buildSearchPayload(params, searchField, organizationId, dataSourceHandles) {
    const channels = [...new Set(getArrayFromObjectArray(dataSourceHandles, 'trendsId'))];
    return {
        ...params, ...{
            channels,
            searchString: searchField.searchMessage,
            organizations: [organizationId],
        }
    }
}

export function filterChannels(dataSourceHandles, filterValue = 'id') {
    if(_.isEmpty(dataSourceHandles)) return [];
    return dataSourceHandles.map(item => {
        return {
            value: item[filterValue],
            name: item.name,
        };
    })
}

/**
 * @param message {object}
 * @param response {object}
 * @param editedResponse {string}
 * @param attachments {array}
 * @param subjectObj {object}
 * @param closeTicket {boolean}
 * @param crossDispatchWarningDismissed {boolean}
 * @param selectedTags {array}
 * @returns {{navigation: {type: string}, crossDispatchWarningDismissed, userVote: {isLastResponseVote: boolean, votes: {editedSubject: undefined, attachments, is_text_edited: boolean, is_best_response: boolean, response_id: undefined, subject: undefined, closeTicket, messageId: undefined, tag: string, text: undefined, edited_text: *}[]}}}
 */
export function buildSubmitResponsePayload(
    message,
    response,
    editedResponse,
    attachments,
    subjectObj,
    closeTicket,
    crossDispatchWarningDismissed,
    selectedTags
) {
    const type = _.get(message, 'dataSourceHandle.type');
    const source = _.get(message, 'sourceName');
    const isEmail = [handleType.email, handleType.proactive_email].includes(type) || (type === handleType.chat && source === msgSource.GORGIAS);
    const tagNameArr = _.map(selectedTags, 'name') || [];
    editedResponse = cleanResponseText(editedResponse, source);
    const voteObj = {
        attachments: attachments,
        edited_text: isEmail ? htmlToText.fromString(editedResponse, {wordwrap: false}) : editedResponse,
        is_best_response: true,
        is_text_edited: true,
        messageId: _.get(message, 'id'),
        response_id: _.get(response, 'id'),
        tag: votingOptions.APPROVE,
        text: _.get(response, 'text'),
        subject: _.get(subjectObj, 'subject'),
        editedSubject: _.get(subjectObj, 'editedSubject'),
        closeTicket,
        tagNamesToAdd: tagNameArr
    }
    if(isEmail) voteObj.edited_html_body = editedResponse;

    return {
        navigation: {
            type: navigationType.AGENT_SELECTION,
        },
        userVote: {
            isLastResponseVote: true,
            votes: [voteObj]
        },
        crossDispatchWarningDismissed
    }
}

/**
 * @param message {object}
 * @param response {object}
 * @param subjectObj {object}
 * @param closeTicket {boolean}
 * @returns {{navigation: {type: string}, userVote: {isLastResponseVote: boolean, votes: {editedSubject: undefined, is_best_response: boolean, response_id: undefined, subject: undefined, closeTicket, messageId: undefined, tag: string, text: undefined}[]}}}
 */
export function buildSubmitPerfectResponse(message, response, subjectObj, closeTicket, crossDispatchWarningDismissed) {
    const voteObj = {
        is_best_response: true,
        messageId: _.get(message, 'id'),
        response_id: _.get(response, 'id'),
        tag: votingOptions.APPROVE,
        text: _.get(response, 'text'),
        subject: _.get(subjectObj, 'subject'),
        editedSubject: _.get(subjectObj, 'editedSubject'),
        closeTicket
    }

    return {
        navigation: {
            type: navigationType.AGENT_SELECTION,
        },
        userVote: {
            isLastResponseVote: true,
            votes: [voteObj]
        },
        crossDispatchWarningDismissed
    }
}

export function buildSubmitAndHidePayload(message) {
    return  {
        updateFields: {
            is_hidden: true
        },
        isV2Enabled: message.isV2Enabled,
        source: message.sourceName,
        actor: "voter"
    }
}

export function buildHidePayload(message, hideStatus) {
    return  {
        updateFields: {
            is_hidden: hideStatus
        },
        isV2Enabled: message.isV2Enabled,
        source: message.sourceName,
        actor: "voter"
    }
}

export function buildCannotResolvePayload(message, optionType, props = {}) {
    if(_.isEmpty(message)) return;
    switch (optionType) {
        case cannotResolveOptionType.ESCALATE: return buildEscalatePayload(message);
        case cannotResolveOptionType.NO_RESPONSE_NEEDED: return buildNoResponseNeededPayload(message);
        case cannotResolveOptionType.SKIPPED: return buildSkippedPayload(message);
        case cannotResolveOptionType.INTERNAL_NOTE: return buildInternalNotePayload(message, props);
        case cannotResolveOptionType.REROUTE: return buildReroutePayload(message);
    }
}

export function buildPrivateReplyPayload(message, text, crossDispatchWarningDismissed) {
    return {
        'isPrivateComment': true,
        platform: 'teams-only',
        isV2Enabled: message.isV2Enabled,
        source: message.sourceName,
        reply: text,
        openedMessageId: message.id,
        crossDispatchWarningDismissed: crossDispatchWarningDismissed
    };
}

export function buildAssigneeObject(assignee) {
    if(_.isEmpty(assignee)) return null;

    return {
        id: _.get(assignee, 'id'),
        email: _.get(assignee, 'email'),
        name: _.get(assignee, 'name'),
    }
}

function buildEscalatePayload(message) {
    return userActionPayload(message.id, cannotResolveOptionType.ESCALATE);
}

function buildNoResponseNeededPayload(message) {
    return userActionPayload(message.id, cannotResolveOptionType.NO_RESPONSE_NEEDED);
}

function buildInternalNotePayload(message, props = {}) {
    return userActionPayload(message.id, cannotResolveOptionType.INTERNAL_NOTE, props);
}

function buildSkippedPayload(message) {
    const responseOptions =_.get(message, 'response_options') || [];
    if(_.isEmpty(responseOptions)) return;
    let voteArr = [];
    responseOptions.map(responseOption => {
        const votePayload = {
            response_id: responseOption.id,
            tag: votingOptions.REJECT,
            text: responseOption.text,
            is_best_response: false,
            edited_text: '',
            edited_html_body: '',
            is_text_edited: false,
            messageId: _.get(message, 'id'),
        };
        return voteArr.push(votePayload);
    });
    return {
        navigation: {
            type: navigationType.AGENT_SELECTION,
        },
        userVote: {
            isLastResponseVote: true,
            votes: voteArr
        }
    };
}

function buildReroutePayload(message) {
    return userActionPayload(message.id, cannotResolveOptionType.REROUTE);
}

function getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
}

function userActionPayload(messageId, actionType, props = {}) {
    return {
        userMessageAction: {
            user: getCurrentUser().id,
            actionType: actionType,
            message: messageId,
            partyUser: props.assignee,
            text: props.text,
        },
        navigation
    }
}

/**
 * @param searchWord {string}
 * @param options {array}
 * @param pathName {string}
 * @returns {*|*[]}
 */
export function search(searchWord, options, pathName) {
    if(!searchWord || _.isEmpty(options)) return [];

    return options && options.filter(option => {
        if (!_.has(option, pathName)) return false;
        return _.toString(option[pathName]).toLowerCase().includes(_.toString(searchWord).toLowerCase());
    });
}