import {MenuList, MenuItem} from "@material-ui/core";
import React from "react";
import {useDispatch} from "react-redux";
import {chatdeskUpdatesActions} from "_actions";

export const ChatdeskUpdatesActions = ({update}) => {

    const dispatch = useDispatch()
    const toggleActive = () => {
        const data = {isActive: !update.isActive}
        dispatch(chatdeskUpdatesActions.edit(update.id, data))
    }

    return (
        <MenuList>
            <MenuItem>
                <button type="button" className="btn btn-default btn-block" onClick={toggleActive}>
                    {update.isActive ? 'DEACTIVATE' : 'ACTIVATE'}
                </button>
            </MenuItem>
        </MenuList>
    )
}