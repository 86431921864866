import React, {Component} from 'react';
import connect from 'react-redux/es/connect/connect';
import {reduxForm} from "redux-form";
import {getCurrentUser} from '_helpers';
import {kustomerAuthActions, msgSourceActions} from '_actions';
import { WebHookModal } from "components/AccountSettings";
import {SetupLink} from "components/OauthComponents";


function validate (apiKey, subDomain){
    return{
        apiKey: apiKey.length === 0,
        subDomain: subDomain.length === 0
    }
}

class KustomerConnect extends Component {
    constructor() {
        super();
        this.state = {
            apiKey: '',
            subDomain: '',
        };
    }

    componentDidMount() {
        this.props.dispatch(msgSourceActions.getMsgSourceLink('kustomer'));
    }

    handleApiKeyChange =evt =>{
        this.setState({apiKey: evt.target.value})
    };

    handleSubDomainChange = evt =>{
        this.setState({subDomain: evt.target.value})
    };

    handleSubmitData = evt =>{
        if(!this.canBeSubmitted){
            evt.preventDefault();
            return;
        }

        const currentUser  = getCurrentUser();
        const {apiKey, subDomain} = this.state
        const params = { apiKey: apiKey, subDomain: subDomain, organizationId: currentUser._embedded.organization.trendsId };
        this.props.dispatch(kustomerAuthActions.verifyKey(params))
    };

    canBeSubmitted (){
        /**
         * Checks that the input of the apiKey and subDomain are not null
         * If both fields are null, it disables the button
         */
        const error =  validate(this.state.apiKey, this.state.subDomain)
        const isDisabled = Object.keys(error).some(x=>(error)[x])
        return !isDisabled
    }


    render() {
        const error =  validate(this.state.apiKey, this.state.subDomain)
        const isDisabled = Object.keys(error).some(x=>(error)[x])
        const {handleSubmit,msgSourceLink} = this.props;
        return (
            <div className="main-content-wrapper">
                <div className="content-pane-60 margin-0-auto">
                    <div className="padding-20-0">
                        <form onSubmit={handleSubmit(this.handleSubmitData.bind(this))}>
                            <div>
                                <h1 className="msg-source-submit-header margin-bottom-30">Connect your Kustomer
                                    Account</h1>
                                <h4>Sub Domain</h4>
                                <div>
                                    <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">
                                        <span> https://</span>
                                        <input
                                            type="text"
                                            className={error.subDomain?'error':''}
                                            name="subdomain"
                                            onChange={this.handleSubDomainChange}
                                        />
                                        <span>.kustomerapp.com</span>
                                    </label>

                                    <h4>API Key</h4>
                                    <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">
                                        <input
                                            type="text"
                                            className={error.apiKey?'error':''}
                                            name="apiKey"
                                            onChange={this.handleApiKeyChange}
                                        />
                                    </label>
                                </div>
                                <button type="submit" className='btn btn-success font-16 msg-source-submit-button'
                                        disabled={isDisabled}>
                                    Submit
                                </button>
                                <SetupLink msgSourceLink={msgSourceLink}/>
                            </div>
                        </form>
                    </div>
                </div>
                <WebHookModal />
            </div>
        );
    }
}

const formConfig = {
    form: 'kustomerForm',
    validate,
    touchOnBlur: false
};

const mapStateToProps = (state) => {
    const {kustomerAuth, user, msgSources: { msgSourceLink }} = state;

    return {
        kustomerAuth,
        user,
        msgSourceLink: msgSourceLink.msgSourceLink
    }
};

const kustomerConnect = connect(mapStateToProps)(KustomerConnect);
export default reduxForm(formConfig)(kustomerConnect);