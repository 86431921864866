import React from 'react';
import {Col, Grid, Image, Row, Table} from 'react-bootstrap'
import {attributesActions, employmentActions} from "_actions";
import {connect} from "react-redux";
import {LocationOn} from "@material-ui/icons";
import {styles} from "components/Profile/UserProfile/StylesEditProfile";
import {calculateRating, generatePhotoLink} from "_helpers";
import userAvatar160 from "components/images/user-160.svg";
import {Link} from "react-router-dom";
import {Loading} from "../common";
import moment from "moment/moment";
import {UserAttributes} from "./UserAttributes";

class ExpertDetails extends React.Component {

    componentDidMount() {
        const {userId} = this.props.match.params;
        this.props.dispatch(employmentActions.getAll({userId}));
        this.props.dispatch(employmentActions.getEmploymentMetrics({userId}))
        this.props.dispatch(attributesActions.getAttributes({userId}));
    }

    renderDetailsRow = (employment) => {
        const {employmentMetrics} = this.props;
        const {id, bio, status, _embedded:{ agent, organization} } = employment;
        let score = (agent.score) / 5 || 0

        const matchEmploymentMetrics = employmentMetrics?.employments?.find((data) => data?.id === id);

        return(
            <tr key={id}>
                <td>
                    <Link to={`/company/experts/${id}/show`}>{organization.name}</Link>
                </td>
                <td>{status}</td>
                <td>{bio ? bio: " N/A"}</td>
                <td>{agent.messagesCount}</td>
                <td>{calculateRating(score)}</td>
                <td>{(matchEmploymentMetrics?.recentActivity)
                    ? moment(matchEmploymentMetrics?.recentActivity).format('MMMM Do YYYY, h:mm:ss a')
                    : "N/A"}
                </td>
                <td>{matchEmploymentMetrics?.positiveMessagesCount}</td>
                <td>{matchEmploymentMetrics?.negativeMessagesCount}</td>
            </tr>
        )
    };

    renderEmploymentData = () => {
        const {employmentData, loading} = this.props;
        const {nameHolder, emailHolder, locationIcon, stateHolder} = styles;

        if (loading) {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Loading height={150} />
                </div>
            )
        }

        const {employments} = employmentData;

        if (!employments.length) {
            return (
                <div style={{textAlign: 'center', padding: "50px 0px"}}>
                    No data
                </div>
            )
        }

        const agent = employments[0]._embedded.agent

        let photo = userAvatar160;

        if(agent.photo){
            photo = generatePhotoLink(agent.photo)
        }

        return(
            <>
                <Grid style={{paddingLeft : "1080px"}}>
                    <Link to={"/experts"}
                          className="btn btn-sm"
                          style={{border: '1px solid #ededed', fontSize: '17px'}}>
                        <i className="fa fa-arrow-left color-green"/>
                    </Link>
                </Grid>
                <Col md={2}>
                    <Grid style={{paddingBottom : "50px"}}>
                        <div className="profile-photo-container">
                            <Image responsive
                                   src={photo}
                                   alt={agent.username}
                                   circle
                            />
                        </div>
                    </Grid>
                </Col>
                <Col md={10}>
                    <Grid style={{paddingTop : "40px"}}>
                        <div style={nameHolder} className="font-22-bold color-grey-dark tms-ellipsis">
                            {agent.firstName + " " + agent.lastName}
                        </div>
                        <div style={emailHolder} className="font-15 color-green tms-ellipsis">
                            {agent.email}
                        </div>
                        <div className="font-15 color-grey-mid tms-ellipsis">
                            <i className="fa fa-phone-square" aria-hidden="true"/>
                            {agent.phoneNumber ? agent.phoneNumber: "  N/A"}
                        </div>
                        <div className="font-15 color-grey-mid tms-ellipsis">
                            <i className="fa fa-clock-o" aria-hidden = "true"/>
                            {agent.timezone ? agent.timezone: "  N/A"}
                        </div>
                        <div style={{display: "flex"}}>
                            <div>
                                <LocationOn className="color-grey-mid" style={locationIcon}/>
                            </div>
                            <div className="font-15 color-grey-mid tms-ellipsis" style={stateHolder}>
                                {agent.city + ", " + agent.state}
                            </div>
                        </div>
                    </Grid>
                </Col>
            </>
        )

    }

    render() {
        const {employmentData, loadingAttributes, loading} = this.props;
        const {employments} = employmentData;

        return (
            <Grid justify="center">
                <Grid  xs={12} sm={12} md={10} className="shadow-border" style={{minHeight: '100vh'}}>
                    <Grid xs={12} md={7}>
                        <Row style={{paddingTop : "100px"}}>
                            {this.renderEmploymentData()}
                        </Row>
                        <Row>
                            {loading ? (
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Loading height={150} />
                                </div>
                            ) : (
                                <Table striped bordered hover style={{ width: "95%" }}>
                                    <thead>
                                    <tr>
                                        <th>Organization</th>
                                        <th>Status</th>
                                        <th style={{ width: "30%" }}>Bio</th>
                                        <th>Messages Count</th>
                                        <th>Quality Score</th>
                                        <th>Recent Vote Date</th>
                                        <th style={{ width: "5%" }}>No. of Positive Messages</th>
                                        <th style={{ width: "5%" }}>No. of Mistakes</th>
                                    </tr>
                                    </thead>
                                    <tbody>{employments?.map(this.renderDetailsRow)}</tbody>
                                </Table>
                            )}
                        </Row>
                        <Row>
                            {loadingAttributes ? (
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Loading height={150} />
                                </div>
                            ) : (
                                <UserAttributes attributes={this.props.attributes} userId={this.props.match.params.userId}/>
                            )}
                        </Row>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

}

function mapStateToProps(state) {
    const {employment: {employments, loading,employmentMetrics,metricsLoading}, attributes: {attributes, loadingAttributes}} = state;
    return {
        employmentData: employments,
        attributes,
        employmentMetrics,
        loading,
        loadingAttributes,
        metricsLoading
    };
}

const expertDetails = connect(mapStateToProps)(ExpertDetails);
export {expertDetails as ExpertDetails};