import React from 'react';
import GenericModal from "../common/GenericModal";
import TextArea from "../common/CustomFormFields/TextArea";
import InputField from "../common/CustomFormFields/InputField";
import {SnackBar} from "../common";

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 09/10/2018 : 11:52 AM
 */

const style = {
    bodyTextHolder: {display: "flex", alignItem: "center", marginBottom: "15px"},

}
const minBioLength = 50;

class JoinOrganizationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nickname: this.props.payload.employmentNickname,
            bio: this.props.payload.employmentBio,
            bioTextarea: {
                value: '',
                count: 0,
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBioChange = this.handleBioChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    handleBioChange(e) {
        this.setState({[e.target.name]: {value: e.target.value, count: e.target.value.length }});
    }

    handleSubmit() {
        const payload = {
            ...this.props.payload,
            bio: this.state.bioTextarea.value,
            nickname: this.state.nickname,
            id: this.props.payload.employmentId
        };

        const bioText = payload.bio;
        const bioTextCount = this.state.bioTextarea.count;

        if (bioTextCount >= 50)  {
            this.props.onSave(payload);
        } else if (bioText === undefined || bioTextCount === 0) {
            SnackBar.showMessage("Please enter your bio");
        } else if (bioTextCount < 50) {
            SnackBar.showMessage(`${bioTextCount} characters entered. Please enter a longer bio!`);
        }
    }
    render() {

        const {show, onHide, payload} = this.props;
        const { employmentBio, employmentNickname, employmentId, organizationName } = payload;
        const { bioTextarea } = this.state;

        let joinOrgText;
        let headerText;
        let bio;
        let nickname;
        let buttonText;

        if (!employmentId) {
            joinOrgText = <div className="join-organization-modal-text">
                <div style={{lineHeight: "20px"}}>
                    <span>Your nickname will be displayed to the company and their customers. Use the bio to share a few sentences about yourself and why you are excited to work with the company.</span>
                </div>
            </div>;
            headerText = `Join ${organizationName} and start practicing`;
            buttonText = "Join";
        } else {
            bio = employmentBio;
            nickname = employmentNickname;
            headerText = `Edit Bio for ${organizationName}`;
            buttonText = "Save";
        }

        return (
            <GenericModal
                show={show}
                onHide={onHide}
                onSave={this.handleSubmit}
                headerText={headerText}
                buttonText={buttonText}
                classes="join-organization-modal"
            >

                {joinOrgText}

                <form onSubmit={this.handleSubmit} style={style.form} className="join-organization-modal-form">
                    <InputField className="input-field" type="text" name="nickname" label="Nickname"
                                onChange={this.handleChange} initialValue={nickname}/>
                    <p style={{marginBottom: '-10px', fontWeight: 'bold'}}>Bio
                        <span style={{fontWeight: 'normal'}}> [{minBioLength} minimum characters. Count: {bioTextarea.count}]</span>
                    </p>
                    <TextArea type="textarea" className="textarea" name="bioTextarea" onChange={this.handleBioChange}
                              value={bioTextarea.value} initialValue={bio}/>
                </form>
            </GenericModal>
        );
    }
}

export default JoinOrganizationModal ;
