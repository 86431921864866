import React, {Fragment} from "react";
import {Box} from "@mui/system";
import styles from "../../../voteMainStyles";
import Conversation from "./Conversation";
import DirectMessageEntry from "./DirectMessage/DirectMessageEntry";

export default function ConversationContainer({conversation, message, loading, isScrollComplete, withDm}) {
    return(
        <Fragment>
            <Box sx={{...styles.conversationContainer, ... withDm && styles.conversationContainerWithDm}}>
                <Conversation
                    conversation={conversation}
                    message={message}
                    loading={loading}
                    withDm={withDm}
                    isScrollComplete={isScrollComplete}
                />
            </Box>
            <DirectMessageEntry isActive={withDm} isScrollComplete={isScrollComplete} />
        </Fragment>
    )
}