import React, {Component} from 'react';
import { Button, Modal } from 'react-bootstrap'
import {reduxForm} from "redux-form";
import _ from 'lodash';
import {portalActions} from "_actions";


/**
 * @author Chris Okebata
 */

class DeletePortalModal extends Component {

  handleDelete = () => {
    this.props.dispatch(portalActions.deletePortal(_.get(this.props.portalData, 'id')));
  };

  render () {

    const {onHide, show, headerText, buttonText, handleSubmit, portalData} = this.props;

    return (
      <Modal show={show} onHide={onHide} className="tms-modal-dialog">
        <form onSubmit={handleSubmit(this.handleDelete)}>
          <Modal.Header closeButton>
            <Modal.Title>{headerText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="font-15">
              {`Are you sure you want to delete ${_.get(portalData, 'displayLabel')}?`}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide} style={{ padding: '7px 12px' }} className="btn btn-default">Cancel</Button>
            <Button bsStyle="success" style={{ padding: '7px 12px' }} type="submit">
              {buttonText}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const formConfig = {
  form: 'DeletePortalForm'
};

const reduxDeletePortalModal = reduxForm(formConfig)(DeletePortalModal);
export { reduxDeletePortalModal as DeletePortalModal };
