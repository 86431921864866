import {msgDataSourceConstants} from '_constants';

const INITIAL_STATE = {
    loading: true,
    error: '',
    lastUpdatedHandleId: null
};

export function kustomerMsgDataSourceReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case msgDataSourceConstants.UPDATE_KUSTOMER_MSG_DATA_SOURCE_REQUEST:
            return {...state, loading: true, error: '', lastUpdatedHandleId: action.handleId};
        case msgDataSourceConstants.UPDATE_KUSTOMER_MSG_DATA_SOURCE_SUCCESS:
            return {...state, dataSourceHandles: action.payload, loading: false};
        case msgDataSourceConstants.UPDATE_KUSTOMER_MSG_DATA_SOURCE_FAILURE:
            return {...state, error: action.error, loading: false};

        default:
            return state
    }
}
