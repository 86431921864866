import {Box} from "@mui/system";
import DataSource from "../Utilities/DataSources/DataSource";
import styles from "../organizationMainStyles";
import {getAccessibilityMode, getLabel} from "../Utilities/organizationSettingsUtils";
import useGetDataSourceHandleInfo from "../hooks/useGetDataSourceHandleInfo";
import {fragmentId} from "../Utilities/organizationSettingsConstants";
import _ from "lodash";
import NoDataView from "../EmptyStates/NoDataView";
import React from "react";
import DataSourcesEmptyState from "../EmptyStates/DataSourcesEmptyState";

export default function DataSources({organizationId}) {
    const label = getLabel(fragmentId.DATA_SOURCES);
    const {handleInfo, loading} = useGetDataSourceHandleInfo(organizationId);
    const mode = getAccessibilityMode();

    if(_.isEmpty(organizationId) && _.isEmpty(handleInfo)) {
        return <NoDataView />
    }

    if(loading) {
        return (
            <Box sx={styles.sectionWrapper}>
                <DataSourcesEmptyState/>
            </Box>
        )
    }

    return (
        <Box sx={styles.sectionWrapper}>
            {
                handleInfo.items && handleInfo.items.map((handle) =>
                    <DataSource
                        key={handle.id}
                        handle={handle}
                        mode={mode}
                        label={label}
                    />
                )
            }
        </Box>
    )
}