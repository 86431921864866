import {customerIoAuthService} from '_services';

import {oauthConstants, alertMessages} from '_constants';
import {SnackBar} from "../components/common";
import {history} from "../_helpers";

function saveAccount(params) {
    function request() {
        return {type: oauthConstants.CUSTOMER_IO_SAVE_ACCOUNT_REQUEST};
    }

    function success(payload) {
        return {type: oauthConstants.CUSTOMER_IO_SAVE_ACCOUNT_SUCCESS, payload }
    }

    function failure() {
        return {type: oauthConstants.CUSTOMER_IO_SAVE_ACCOUNT_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        customerIoAuthService.saveAccount(params)
            .then(
                (res) => {
                    dispatch(success(res));
                    SnackBar.showMessage(alertMessages.CUSTOMER_IO_AUTH_SUCCESS);
                    history.push('/company/channels')
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.CUSTOMER_IO_AUTH_FAILURE);
                },
            );
    };
}

export const customerIoAuthActions = {
    saveAccount
};