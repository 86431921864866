import React, {Fragment} from "react";
import BasicSelect from "./BasicSelect";
import useGetTemplates from "../views/Proactive/Utilities/hooks/useGetAllTemplates";

export default function TemplateSelect({ name, label, placeholder, handleFormSelectChange, defaultValue, queryParam, input, meta }) {
    const { templates } = useGetTemplates(queryParam);

    return(
        <Fragment>
            <BasicSelect
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={defaultValue}
                options={templates}
                handleFormSelectChange={handleFormSelectChange}
                input={input}
                meta={meta} />
        </Fragment>
    )
}