import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import { Grid } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { reduxForm } from "redux-form";
import {dispatcherActions, messageActions, userActions} from "_actions";
import _ from "lodash";
import { withRouter } from 'react-router-dom'
import {notificationInboxStyle} from 'components/Inbox/StylesNotificationInbox'
import {ShowConversation} from 'components/Inbox'
import {CrossDispatchWarningModal} from "../v2/views/Messages/CrossDispatchWarningModal";
import {Loading, QuillEditor} from "../common";
import {cleanResponseText, currentUser, getCurrentUser, isObjectEmpty, timeDifferenceInSeconds} from "_helpers";
import {handleType, dispatcherConstants, LOCK_DURATION_IN_SECS, LOCK_EMAIL_DURATION_IN_SECS} from "_constants";
import moment from "moment";
import MessageLockIcon from '@material-ui/icons/Lock';
import CheckMarkIcon from "../images/check_circle.svg";


/**
 * @author Chris Okebata
 */

const btnStyle = {
    backgroundColor: 'transparent',
    border: '0',
    margin: '2px',
    color: '#337ab7',
    padding: '0',
    fontWeight: 600,
};

class InboxConversation extends React.Component{

    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editorRef = React.createRef();

        this.state = {
            replyText: '',
            lockedMessage: false,
            crossDispatchWarningModal: false,
            crossDispatchWarningDismissed: false
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleSubmit(messageId, response){
        const {id} = getCurrentUser();
        const { data:{ conversation, openedMessageId }} = this.props;
        const sourceName = _.get(conversation, '0.sourceName');
        const v2Status = _.get(conversation, '0.isV2Enabled');
        response = cleanResponseText(response, sourceName);

        const payload = {
            openedMessageId: openedMessageId,
            isDispatched: false,
            reply: response,
            userId: id,
            source: sourceName,
            isV2Enabled: v2Status,
            draftId: conversation.draft?.id,
            crossDispatchWarningDismissed: this.state.crossDispatchWarningDismissed
        };
        this.props
            .dispatch(dispatcherActions.dispatchMessage(messageId, payload))
            .then((result) => {
                if (result.success) {
                    this.handleTextFieldUpdate('');
                    this.editorRef.current.clearEditor();
                    this.clearCrossDispatchWarningDismissed();
                } else if (!result.success && result?.response?.errorCode === dispatcherConstants.POSSIBLE_CROSS_DISPATCH_ERROR_CODE) {
                    this.showCrossDispatchWarningModal();
                }
            }).catch(error => console.error('An error occurred when dispatching message: ' + error));
    }

    handleBackNavigation() {
        this.props.history.goBack();
    }

    handleTextFieldUpdate = (text) => {
        this.setState({replyText: text});
    };

    lockMessage = () => {

        const {data, dispatch} = this.props;
        const { id: userId } = getCurrentUser();
        const message = _.get(data, 'trendsCorrespondingMessages.teamsMessages.0');
        const isLocked = _.get(message, 'isLocked');

        let timeDiff = timeDifferenceInSeconds(moment(_.get(message, 'lockedAtTime')).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
        const isEmail = _.get(data, 'notificationType') === 'priority' ?
            [handleType.email, handleType.proactive_email].includes(_.get(data, 'conversation.dataSourceHandle.type')) :
            [handleType.email, handleType.proactive_email].includes(_.get(data, 'conversation.0.dataSourceHandle.type'));

        let lockTime = isEmail ? LOCK_EMAIL_DURATION_IN_SECS : LOCK_DURATION_IN_SECS;

        if (!isLocked || (isLocked && timeDiff > lockTime)) {
            dispatch(messageActions.lockMessage(_.get(message, 'id'), userId));
        }

        this.setState({lockedMessage: true});
    };

    markAsDone = () => {
        const { data, dispatch } = this.props;
        const userId = _.get(currentUser, 'id');
        let conversation = _.get(data, 'conversation');
        const messageId = conversation && conversation.constructor === Array ? _.get(conversation, '0.id') : _.get(conversation, 'id');

        dispatch(userActions.markInboxMsgAsDone(messageId, userId))
    };

    componentDidUpdate() {
        const { replyText } = this.state;
        const { data: { conversation, notificationType }} = this.props;

        if (! replyText) {
            let defaultText = '';
            if (notificationType === "priority" && ! _.isEmpty(conversation)) {
                if (conversation && conversation._embedded && conversation._embedded.sendForEditResponse) {
                    defaultText = conversation._embedded.sendForEditResponse.text;
                    this.setState({
                        replyText: defaultText
                    })
                }
            }
            if (notificationType === 'review' && ! _.isEmpty(conversation)) {
                if (conversation && conversation._embedded && conversation._embedded.selectedResponseOption) {
                    defaultText = conversation._embedded.selectedResponseOption.text;
                    this.setState({
                        replyText: defaultText
                    })
                }
            }
        }
    }

    showCrossDispatchWarningModal = () => {
        this.setState({crossDispatchWarningModal: true});
    }

    closeCrossDispatchWarningModal = () => {
        this.setState({crossDispatchWarningModal: false, crossDispatchWarningDismissed: true});
    }

    clearCrossDispatchWarningDismissed = () => {
        this.setState({crossDispatchWarningDismissed: false});
    }

    render(){
        const { classes, data} = this.props;
        const { notificationType, loadingMessage } = data;
        const isEmail = _.get(data, 'notificationType') === 'priority' ?
            [handleType.email, handleType.proactive_email].includes(_.get(data, 'conversation.dataSourceHandle.type')) :
            [handleType.email, handleType.proactive_email].includes(_.get(data, 'conversation.0.dataSourceHandle.type'));

        let { conversation, loadingCompanyReplied } = data;
        if (notificationType === "all_messages") {
            conversation = conversation[0];
        }

        if ( loadingMessage || loadingCompanyReplied ){
            return (
                <Loading/>
            )
        }

        if(!this.state.lockedMessage)this.lockMessage();

        const msg = _.get(data, 'trendsCorrespondingMessages.teamsMessages.0');
        const companyReplied = _.get(data, 'companyReplied.id');
        const markAsDone = _.get(data, 'markAsDone.id');

        let timeDiff = timeDifferenceInSeconds(moment(_.get(msg, 'lockedAtTime')).format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss"));
        const user = getCurrentUser();
        const lockedByCurrentUser = _.get(msg, 'lockedByUser') === user.id;

        return (
            <div className={classes.root}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Breadcrumbs className={classes.paper} separator={<ArrowRight style={{fontSize:"25px"}} />} arial-label="Breadcrumb">
                    <span onClick={()=> this.handleBackNavigation()} className={classes.colorGreen}>
                        <span className={classes.navLink} >
                            Back to all messages
                        </span>
                    </span>
                    </Breadcrumbs>
                    <span style={{marginTop: '11px'}}>
                        {
                            _.get(msg, 'lockedAtTime') && timeDiff < LOCK_DURATION_IN_SECS && !lockedByCurrentUser &&
                            <MessageLockIcon className="color-grey-mid" fontSize={'small'} style={{margin: '0 15px 2px 15px'}}/>
                        }
                    </span>


                    <Grid item style={{marginLeft: 'auto', display: 'flex', flexDirection: 'row'}}>
                        <div style={{display: 'flex', alignItems: 'center', color: '#337ab7', fontWeight: 600,}}>
                            <div className='padding-right-20'>
                                {
                                    !loadingCompanyReplied && (
                                      (!isObjectEmpty(markAsDone) || !isObjectEmpty(companyReplied) || _.get(msg, 'isDispatchedSuccessfully')) ?
                                        <img src={CheckMarkIcon} alt="actioned"/> :
                                        <button type="button" className="btn padding-right-20" style={btnStyle} onClick={this.markAsDone}>
                                            Mark as done
                                        </button>
                                    )
                                }
                            </div>
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href={`${_.get(conversation, 'link')}`}>View on source</a>
                            </div>
                        </div>
                    </Grid>
                </div>

                <ShowConversation
                    conversation={conversation}
                    notificationType={notificationType}
                />

                <Fragment>
                    <QuillEditor
                        defaultText={conversation.draft ? conversation.draft.text : ''}
                        handleTextChange={this.handleTextFieldUpdate}
                        isEmail={isEmail}
                        ref={this.editorRef}
                    />

                    <Grid container className={classes.buttonContainer}>
                        <Grid item md={2} sm={2} xs={12}>
                            <button onClick={() => {this.handleSubmit(conversation.id, this.state.replyText)}}
                                    type="submit" className="btn btn-success btn-block font-15"
                                    style={{padding:"7px"}}
                            >
                                Send
                            </button>
                        </Grid>
                    </Grid>
                </Fragment>
                <CrossDispatchWarningModal
                    open={this.state.crossDispatchWarningModal}
                    handleClose={this.closeCrossDispatchWarningModal}
                />
            </div>
        )
    }
}

InboxConversation = reduxForm({
    form: 'replyForm',
    enableReinitialize: true,
})(InboxConversation);


InboxConversation.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.object,

};

export default withRouter(withStyles(notificationInboxStyle)(InboxConversation));
