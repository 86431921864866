import {websocketConstants} from "_constants";
import _ from "lodash";

/**
 * @author Chris Okebata
 */

const INITIAL_STATE = {
  trendsMessage : {},
  teamsMessages: { messages: [] },
  voteMessage: {},
  message: {}
};

export const websocketReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case websocketConstants.GET_TRENDS_MESSAGE_SUCCESS:
      return {
        ...state,
        trendsMessage: action.payload,
      };

    case websocketConstants.CLEAR_TRENDS_MESSAGE:
      return {
        ...state,
        trendsMessage: action.payload,
      };

    case websocketConstants.ADD_TEAMS_MESSAGE_SUCCESS:
      return {
        ...state,
        teamsMessages: {
          messages: [...state.teamsMessages.messages, action.payload.message]
        },
      };

    case websocketConstants.REMOVE_TEAMS_MESSAGES_SUCCESS:
      const messageIds = _.map(action.payload.messages, (message) => message.id);
      return {
        ...state,
        teamsMessages: {
          messages: _.filter(state.teamsMessages.messages, (message) => !messageIds.includes(message.id))
        },
      };

    case websocketConstants.GET_VOTE_MESSAGE_SUCCESS:
      return {
        ...state,
        voteMessage: action.payload,
      };

    case websocketConstants.CLEAR_VOTE_MESSAGE:
      return {
        ...state,
        voteMessage: action.payload,
      };

    case websocketConstants.GET_MESSAGE_SUCCESS:
      return {...state, message: action.payload};

    case websocketConstants.CLEAR_MESSAGE:
      return {...state, message: action.payload};

    default:
      return state
  }
};