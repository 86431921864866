import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Loading } from 'components/common/Loading'
import {connect} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import {SetPayoutPricingModal} from "./SetPayoutPricingModal";
import { scheduleActions} from "_actions";

const selectAll = "selectAll";
const payoutRateText = "Payout Rate";
const pricingRateText = "Pricing Rate";

class AdminScheduleList extends Component {

    state = {
        selectedSchedules: [],
        payoutPricingModal: false,
        payoutRate: '',
        pricingRate: '',
        type: ''
    };

    handleCheckboxSelection = (scheduleId, value) => {
        const { schedules } = this.props;
        this.setState((prevState) => {
            let schedulesList = prevState.selectedSchedules;
            if (value) {
                if(scheduleId === selectAll){
                    for (let index in schedules) {
                        if (!schedulesList.includes(schedules[index].id)) {
                            schedulesList.push(schedules[index].id);
                        }
                    }
                } else {
                    if (! schedulesList.includes(scheduleId)) {
                        schedulesList.push(scheduleId)
                    }
                }
            } else {
                if(scheduleId === selectAll){
                    schedulesList = [];
                } else {
                    schedulesList = schedulesList.filter((item) => item !== scheduleId)
                }
            }
            return {
                selectedSchedules: schedulesList
            }
        })
    }

    selectPayoutPricing = (type) => {
        this.setState({
            payoutPricingModal: true,
            type
        })
    }

    updatePayoutPricing = (evt) => {
        const { target: { value }} = evt;
        this.setState((prevState) => ({
            payoutRate: prevState.type === payoutRateText ? value : prevState.payoutRate,
            pricingRate: prevState.type === pricingRateText ? value : prevState.pricingRate,
        }))
    }

    savePayoutPricing = (evt) => {
        evt.preventDefault()
        const { payoutRate, pricingRate, type, selectedSchedules } = this.state;
        const { dispatch, organization } = this.props
        let actionType = type === payoutRateText ? 'payoutRate' : 'pricingRate';
        let actionValue = type === payoutRateText ? payoutRate : pricingRate;
        dispatch(scheduleActions.saveBulkPayoutPricing({org: organization, actionValue, actionType, selectedSchedules}))
        this.setState({
            payoutPricingModal: false
        })
    }

    render() {
        const { schedules, organization, loading } = this.props;
        const { selectedSchedules, payoutPricingModal, type, pricingRate, payoutRate } = this.state;

        return (
            <Fragment>
                {loading && <Loading />}
                {!loading && schedules && schedules.length > 0 &&
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Days</td>
                            <td>Start Time</td>
                            <td>End Time</td>
                            <td>Payout Rate</td>
                            <td>Pricing Rate</td>
                            <td>Channel</td>
                            <td colSpan="2">
                                <div className="flex-container flex-row justify-content-end">
                                    <Checkbox
                                        checked={selectedSchedules.length === schedules.length}
                                        onChange={(e) => this.handleCheckboxSelection(selectAll, e.target.checked)}
                                        style={
                                            selectedSchedules.length === schedules.length ?
                                                {color: '#008751', fontSize: 2} :
                                                {color: '#aeaeae', fontSize: 2}
                                        }
                                    />
                                    <div>
                                        {selectedSchedules.length > 0 &&
                                            <Fragment>
                                                <button type="button"
                                                    className="btn btn-info btn-sm margin-top-2"
                                                    onClick={() => this.selectPayoutPricing(payoutRateText)}>Bulk Payout Rate</button>
                                                <button type="button"
                                                    className="btn btn-info btn-sm margin-top-2"
                                                    onClick={() => this.selectPayoutPricing(pricingRateText)}>Bulk Pricing Rate</button>
                                            </Fragment>
                                        }
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {schedules && schedules.map(schedule =>
                            <tr key={schedule.id}>
                                <td style={{verticalAlign: 'middle'}}>{schedule.name}</td>
                                <td style={{verticalAlign: 'middle', maxWidth: '20%'}}>
                                    {schedule.days.map(day =>
                                        <span style={{padding: '0 3px', display: 'inline-block'}} key={day.id}>
                                                {day.name}
                                            </span>
                                    )}
                                </td>
                                <td style={{verticalAlign: 'middle'}}>{schedule.startTime}</td>
                                <td style={{verticalAlign: 'middle'}}>{schedule.endTime}</td>
                                <td style={{verticalAlign: 'middle'}}>
                                    {schedule.payoutRate &&
                                        <span>
                                            {schedule.payoutRate.label} <br />
                                            ({schedule.payoutRate.currency} {schedule.payoutRate.amount})
                                        </span>
                                    }
                                    {! schedule.payoutRate && <span>N/A</span>}
                                </td>
                                <td style={{verticalAlign: 'middle'}}>
                                    {schedule.pricingRate &&
                                        <span>
                                            {schedule.pricingRate.label} <br />
                                            ({schedule.pricingRate.currency} {schedule.pricingRate.amount})
                                        </span>
                                    }
                                    {! schedule.pricingRate && <span>N/A</span>}
                                </td>
                                <td style={{verticalAlign: 'middle'}}>
                                    {schedule.datasourceHandle &&
                                    <span>
                                            {schedule.datasourceHandle.name}
                                        </span>
                                    }
                                    {!schedule.datasourceHandle && <span>N/A</span>}
                                </td>
                                <td style={{verticalAlign: 'middle'}}>
                                    <Link className="btn btn-info" to={`edit-schedule/${organization}/${schedule.id}`}>
                                        Edit Schedule
                                    </Link>
                                </td>
                                <td style={{verticalAlign: 'middle'}}>
                                    <Checkbox
                                        checked={selectedSchedules.includes(schedule.id)}
                                        onChange={(e) => this.handleCheckboxSelection(schedule.id, e.target.checked)}
                                        style={
                                            selectedSchedules.includes(schedule.id) ?
                                                {color: '#008751', fontSize: 2} :
                                                {color: '#aeaeae', fontSize: 2}
                                        }
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                }
                {!loading && organization && schedules && schedules.length === 0 &&
                    <span>
                        There are no schedules for the given Company.
                    </span>
                }
                <SetPayoutPricingModal
                    type={type}
                    value={type === pricingRateText ? pricingRate : payoutRate}
                    title={`Set ${type} for ${selectedSchedules.length} schedules`}
                    handleSubmit={this.savePayoutPricing}
                    handleChange={this.updatePayoutPricing}
                    showModal={payoutPricingModal}
                    closeModal={() => this.setState({payoutPricingModal: false})}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { payout: { all_payouts }, pricing: { all_pricing }} = state
    return {
        all_pricing, all_payouts
    }
};

const connectedSchedulesTable = connect(mapStateToProps)(AdminScheduleList);
export { connectedSchedulesTable as AdminScheduleList }