import {msgSourceConstants} from "_constants";
import {msgSourceService} from "_services";


export const msgSourceActions = {
    getAll,
    getAllMsgSources,
    saveSetupLink,
    getMsgSourceLink
};


function getAll(params) {
    return dispatch => {
        dispatch(request());

        msgSourceService.getAll(params)
            .then(
                msgSources => dispatch(success(msgSources)),
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: msgSourceConstants.GET_ALL_REQUEST}
    }

    function success(msgSources) {
        return {type: msgSourceConstants.GET_ALL_SUCCESS, payload: msgSources}
    }

    function failure(error) {
        return {type: msgSourceConstants.GET_ALL_FAILURE, payload: error}
    }
}

/**
 * Dispatches actions that gets all the message sources
 * @returns String setupLink
 */
function getAllMsgSources() {
    return dispatch => {
        dispatch(request());

        msgSourceService.getAllMsgSources()
            .then(
                msgSources => dispatch(success(msgSources)),
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: msgSourceConstants.GET_ALL_MSG_SOURCES_REQUEST}
    }

    function success(msgSources) {
        return {type: msgSourceConstants.GET_ALL_MSG_SOURCES_SUCCESS, payload: msgSources}
    }

    function failure(error) {
        return {type: msgSourceConstants.GET_ALL_MSG_SOURCES_FAILURE, payload: error}
    }
}

/**
 * Dispatches actions that gets the setup link for a message source using the name
 * @returns Map
 */
function getMsgSourceLink(msgSrcName) {
    return dispatch => {
        dispatch(request());

        msgSourceService.getMsgSourceLink(msgSrcName)
            .then(
                msgSourceLink => dispatch(success(msgSourceLink)),
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: msgSourceConstants.GET_A_MSG_SOURCE_REQUEST}
    }

    function success(msgSourceLink) {
        return {type: msgSourceConstants.GET_A_MSG_SOURCE_SUCCESS, payload: msgSourceLink}
    }

    function failure(error) {
        return {type: msgSourceConstants.GET_A_MSG_SOURCE_FAILURE, payload: error}
    }
}

/**
 * Dispatches actions that saves or updates setup link for a message source
 */
function saveSetupLink(msgSourceId, link) {
    return dispatch => {
        dispatch(request());

        return msgSourceService.saveSetupLink(msgSourceId, link)
            .then(
                () => dispatch(success()),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return {type: msgSourceConstants.SAVE_MSG_SOURCES_LINK_REQUEST}
    }

    function success() {
        return {type: msgSourceConstants.SAVE_MSG_SOURCES_LINK_SUCCESS}
    }

    function failure(error) {
        return {type: msgSourceConstants.SAVE_MSG_SOURCES_LINK_FAILURE, payload: error}
    }
}
