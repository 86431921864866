import React from "react";
import AddIcon from '@mui/icons-material/Add';
export default function IconAdd({size = 15, style}) {
    return(
        <AddIcon size={size} sx={{...styles.icon, ...style}}/>
    )
}

const styles = {
    icon: {
        color: '#07857C',
        ':hover': {
            color: '#34756f',
            backgroundColor: 'unset'
        },
        ':disabled': {
            color: '#368C8680',
        },
        ':active': {
            outline: '0',
            color: '#54afa9',
        },
    }
}