import React, {Fragment} from "react";
import BasicSelect from "./BasicSelect";

export default function AttributeNameSelect({name, label, placeholder, handleChange, input, meta }) {
    const options = [
        { 'id': 'AuthorId', 'name': 'Author id' },
        { 'id': 'DaysSinceLastOrder', 'name': 'Days since last order' },
        { 'id': 'DaysUntilFollowUp', 'name': 'Days until follow up' },
        { 'id': 'Sender', 'name': 'Sender' },
        { 'id': 'SenderName', 'name': 'Sender name' },
        { 'id': 'Subject', 'name': 'Subject' },
        { 'id': 'Tags', 'name': 'Tags' }
    ]

    return(
        <Fragment>
            <BasicSelect
                name={name}
                label={label}
                placeholder={placeholder}
                options={options}
                onChange={handleChange}
                input={input}
                meta={meta} />
        </Fragment>
    )
}