import {SERVER_URL} from "../config";
import {authHeader, handleHttpResponse} from "../_helpers";

export const showDataSourceStatusService = {
    showDataSourceStatus,
};

/**
 *
 * @param params
 * @returns {Promise<Response | never>}
 */
function showDataSourceStatus(organizationId) {
    return fetch(`${SERVER_URL}/api/showDataSourceStatus?organizationId=${organizationId}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}



