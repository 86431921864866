import {Fragment} from "react";
import BasicModal from "../../../../../../common/BasicModal";
import InternalNoteContainer from "./InternalNoteContainer";

export default function InternalNoteModal({title, open, handleClose, handleOptionSubmitted}) {

    return(
        <Fragment>
            <BasicModal
                title={title}
                open={open}
                style={{width: '700px'}}
                handleClose={handleClose}>

                <InternalNoteContainer
                    handleClose={handleClose}
                    handleOptionSubmitted={handleOptionSubmitted}
                />
            </BasicModal>
        </Fragment>
    )
}