import styles from "./conversationStyles";
import {Box} from "@mui/system";
import CopyAction from "../../CopyAction";

export default function EmailInfoItem({ label, copyText, withCopyAction, tooltipLabel, toastLabel }) {
    return(
        <Box sx={styles.emailListItem}>
            <Box sx={styles.emailInfoItem}>{label}</Box>
            {
                withCopyAction &&
                <Box sx={styles.copyIconContainer}>
                    <CopyAction
                        sx={styles.copyIcon}
                        textValue={copyText}
                        tooltipLabel={tooltipLabel || 'Copy email'}
                        toastLabel={toastLabel || 'Copied email to clipboard'} />
                </Box>
            }
        </Box>
    )
}