import React, {Fragment, useState} from "react";
import {useSelector} from "react-redux";
import {Box} from "@mui/system";
import {dispatcherConstants} from "_constants";
import ActionButton from "../../../../../../common/ActionButton";
import CircularProgress from "@mui/material/CircularProgress";
import useModalSetup from "../../../../../../hooks/useModalSetup";
import DirectMessageModal from "./DirectMessageModal";
import {CrossDispatchWarningModal} from "../../../../../Messages/CrossDispatchWarningModal";

export default function DirectMessageContainer({isScrollComplete}) {
    const [crossDispatchWarningModal, setCrossDispatchWarningModal] = useState(false);
    const [crossDispatchWarningDismissed, setCrossDispatchWarningDismissed] = useState(false);

    const {handleOpenModal, handleCloseModal, openModal} = useModalSetup();
    const loading = useSelector(state => state.dispatcher.request);
    const shouldDisable = loading || !isScrollComplete;

    const showCrossDispatchWarningModal = () => {
        setCrossDispatchWarningModal(true);
    }

    const closeCrossDispatchWarningModal = () => {
        setCrossDispatchWarningModal(false);
        setCrossDispatchWarningDismissed(true);
    }

    const clearCrossDispatchWarningDismissed = () => {
        setCrossDispatchWarningDismissed(false);
    }

    const onDirectMessageDispatchFailure = (response) => {
        if (response?.errorCode === dispatcherConstants.POSSIBLE_CROSS_DISPATCH_ERROR_CODE) {
            showCrossDispatchWarningModal();
        }
    }

    return(
        <Fragment>
            <Box sx={{display: 'flex'}}>
                <ActionButton
                    label={loading ? <CircularProgress size={15} sx={{ color: '#144042'}}/> : 'Send DM'}
                    action={handleOpenModal}
                    disabled={shouldDisable}
                    withIcon={false}
                    buttonBordered
                    sx={styles.sendButton} />

                <DirectMessageModal
                    title={'Send a direct message'}
                    open={openModal}
                    handleClose={handleCloseModal}
                    crossDispatchWarningDismissed={crossDispatchWarningDismissed}
                    onDispatchSuccess={clearCrossDispatchWarningDismissed}
                    onDispatchFailure={onDirectMessageDispatchFailure}
                />
            </Box>
            <CrossDispatchWarningModal
                open={crossDispatchWarningModal}
                handleClose={closeCrossDispatchWarningModal}
            />
        </Fragment>
    )
}

const styles = {
    icon: {
        color: '#495D5E'
    },
    sendButton: {
        width: '125px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        letterSpacing: '-0.02em',
    },
};