import {Box} from "@mui/system";
import _ from "lodash";

export default function TikTokShopImageCard({typeObj}) {
    const url = _.get(typeObj, 'url');
    const width = _.get(typeObj, 'width');

    const addDefaultSrc = (ev) => {
        ev.target.src = '';
    };

    return(
        <Box>
            <img src={`${url}`}
                 onError={addDefaultSrc}
                 alt={'image'}
                 style={{...styles.image, ...{width: `${width}px`, maxWidth: `300px`}}}
            />
        </Box>
    )
}

const styles = {
    image: {
        borderRadius: '5px',
        border: '1px solid #C1C1C1'
    },
}