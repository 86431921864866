import {useMemo} from 'react';
import {useSelector} from "react-redux";
import _ from "lodash";
import moment from "moment";
import IncomingArrow from '@mui/icons-material/EastOutlined';
import OutgoingArrow from '@mui/icons-material/WestOutlined';
import {messageDirection} from "_constants";
import {parseHtml, stripEmTagFromHtml} from "_helpers";
import AddResponseAction from "../../../../common/AddResponseAction";
import {SearchMessageBuilder} from "../objects/SearchMessageBuilder";

export default function () {
    const searchData = useSelector(state => state.search.searchData);
    const searchList = _.get(searchData,'searchResult') || [];
    const totalCount = _.get(searchData,'resultCount') || 0;
    const loadingSearch = useSelector(state => state.search.loading);
    const queryParams = useSelector(state => state.search.queryParams);
    const message = useSelector(state => state.messages.nextMessage) || {};
    const isProactive = _.get(message, 'isProactive');

    const getDirectionIcon = (direction) => {
        if(!direction) return undefined;
        direction = _.toString(direction).toUpperCase();
        return (direction === messageDirection.INCOMING) ?
            <IncomingArrow sx={style.incomingDirectionIcon}/> : <OutgoingArrow sx={style.outgoingDirectionIcon}/>;
    }

    const rows = useMemo(() => {
        const messageArr = [];
        if(!loadingSearch && searchList.length > 0) {
            searchList.map( searchedMessage => {
                const shouldAdd = !isProactive && (_.toString(_.get(searchedMessage, 'direction')).toUpperCase() === messageDirection.OUTGOING);
                const builder = new SearchMessageBuilder();
                builder.setId(_.get(searchedMessage, 'messageId') || '-');
                builder.setParam(_.get(searchedMessage, 'messageId'));
                builder.setDirection(getDirectionIcon(_.get(searchedMessage, 'direction')) || '-');
                builder.setDate(moment(_.get(searchedMessage, 'messageDate')).format('MMM D, YYYY') || '-');
                builder.setText(_.get(searchedMessage, 'messageText') || '-');
                builder.setPhrase(stripEmTagFromHtml(_.get(searchedMessage, 'phrase')) || '-');
                builder.setAction(shouldAdd && <AddResponseAction message={message} searchedMessage={searchedMessage}/>);

                messageArr.push(builder.build());
            });
        }
        return messageArr
    }, [ searchList ]) || [];

    const columns = [
        {
            id: 'text',
            label: 'Messages',
            width: 345,
            withTooltip: true,
            clickable: true
        },
        {
            id: 'direction',
            label: 'Direction',
            width: 20,
            align: 'center',
        },
        {
            id: 'date',
            label: 'Date',
            width: 100,
            minWidth: 100,
        },
        {
            id: 'action',
            label: '  ',
            width: 58,
            minWidth: 58,
            align: 'right',
        },
    ];

    return { rows, columns, loadingSearch, queryParams, totalCount };
}

const style = {
    incomingDirectionIcon: {
        color: '#0C2728',
        fontSize: '20px',
    },
    outgoingDirectionIcon: {
        color: '#9EA9A9',
        fontSize: '20px',
    }
}