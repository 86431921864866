import {oauthConstants, msgDataSourceConstants} from '_constants';

const INITIAL_STATE = {
    data: {},
    list: [],
    closedTicketData: {},
    error: '',
    loading: true,
    showWebhookUrl: false,
    channel: 'gorgias',
    status: '',
    updated: false
};

export function gorgiasAuthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case oauthConstants.GORGIAS_SUBMIT_SUBDOMAIN_REQUEST:
          return { ...state, loading: true };
        case oauthConstants.GORGIAS_SUBMIT_SUBDOMAIN_SUCCESS:
          return { ...state, data: action.payload, loading: false };
        case oauthConstants.GORGIAS_SUBMIT_SUBDOMAIN_FAILURE:
          return { ...state, error: action.error, loading: false };

        case oauthConstants.GORGIAS_GENTOKEN_REQUEST:
          return { ...state, loading: true };
        case oauthConstants.GORGIAS_GENTOKEN_SUCCESS:
          return { ...state, authUrl: action.payload, loading: false };
        case oauthConstants.GORGIAS_GENTOKEN_FAILURE:
          return { ...state, error: action.error, loading: false };

        case oauthConstants.GORGIAS_AUTH_REQUEST:
            return {...state, loading: true};
        case oauthConstants.GORGIAS_AUTH_SUCCESS:
            return {
                ...state,
                showWebhookUrl: true,
                data: action.payload,
                loading: false
            };
        case oauthConstants.GORGIAS_AUTH_FAILURE:
            return {...state, error: action.error, loading: false};
        case msgDataSourceConstants.GET_ALL_DATA_SOURCE:
            return {...state, loading: true};
        case msgDataSourceConstants.ALL_DATA_SOURCE_SUCCESS:
            return {
                ...state,
                list: action.payload ? action.payload.filter(item => item.dataSourceType === 'gorgias') : [],
                loading: false
            };
        case msgDataSourceConstants.ALL_DATA_SOURCE_ERROR:
            return {...state, error: action.error, loading: false};
        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES:
            return {...state, loading: true};
        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_SUCCESS:
            return {
                ...state,
                list: state.list.map(item => {
                    if (item.id === action.payload.msgDataSourceId) {
                        item[action.payload.updateField] = action.payload.enable
                    }
                    return item
                }),
                loading: false
            };
        case msgDataSourceConstants.TOGGLE_ACTION_MSGDATASOURCE_MESSAGES_ERROR:
            return {...state, error: action.error, loading: false};
        case msgDataSourceConstants.MSG_DATA_SOURCE_SAVE_SUCCESS:
            let dataSources = state.list.map(item => {
                if (item.trendsId === action.payload.msgDataSource.id) {
                    item.orgSupportEmail = action.payload.msgDataSource.orgSupportEmail
                }
                return item
            });
            return { ...state, list: dataSources, loading: false };
        case oauthConstants.GORGIAS_SUPPORT_EMAIL_SUCCESS:
            return {
                ...state,
                list: state.list.map(item => {
                    if (action.payload && action.payload[item.trendsId]) {
                        item.orgSupportEmail = action.payload[item.trendsId]
                    }
                    return item
                }),
            };
        case oauthConstants.GORGIAS_SUPPORT_EMAIL_FAILURE:
            return {...state, loading: false};
        case oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SUCCESS:
            return {
                ...state,
                list: state.list.map(item => {
                    let currentItems = action.payload.filter(current => current.msgDataSourceId === item.id)
                    if (currentItems) {
                        item.autoSenders = currentItems
                    }
                    return item
                }),
            };
        case oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SAVE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SAVE_SUCCESS:
            let autoSenderData = action.payload
            return {
                ...state,
                loading: false,
                list: state.list.map(item => {
                    if (!item.autoSenders) {
                        item.autoSenders = []
                    }
                    if (autoSenderData.isDefault) {
                        item.autoSenders = item.autoSenders.map(current => {
                            current.isDefault = false
                            return current
                        })
                    }
                    if (item.id === autoSenderData.msgDataSourceId) {
                        if (!autoSenderData.editedId) {
                            item.autoSenders.push(autoSenderData)
                        } else {
                            item.autoSenders = item.autoSenders.map(current => {
                                if (current.id === autoSenderData.id) {
                                    current = autoSenderData
                                }
                                return current
                            })
                        }
                    }
                    return item
                }),
                updated: true,
            };
        case oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SAVE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_SAVE_CLEAR:
            return {
                ...state,
                updated: false,
            };
        case oauthConstants.MSG_DATA_SOURCE_AUTO_SENDER_DELETE_SUCCESS:
            let payload = action.payload
            return {
                ...state,
                loading: false,
                list: state.list.map(item => {
                    if (item.id === payload.dataSourceId) {
                        item.autoSenders = item.autoSenders.filter(current => current.id !== payload.deleteId)
                    }
                    return item
                }),
                updated: true,
            };
        case oauthConstants.CLOSE_WEBHOOK_MODAL:
            return {...state, showWebhookUrl: false};
        default:
            return state;
    }
}