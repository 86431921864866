import {useMemo} from 'react';
import {useSelector} from "react-redux";
import {buildConversation} from "../../../utilities/ConversationUtils";
import _ from "lodash";

export default function () {
    const message = useSelector(state => state.messages.message);
    const loading = useSelector(state => state.messages.loading);
    const error = useSelector(state => state.messages.error);
    const conversationFromTrendsList = _.get(message, 'conversation') || [];

    const conversation = useMemo(() => {
        if(!loading && conversationFromTrendsList.length > 0) {
            return buildConversation(conversationFromTrendsList);
        }
    }, [loading, message]) || [];

    return {message, conversation, loading, error };
}