import React, {Fragment, Component} from 'react';
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import stylesPortal from "./stylesPortal";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

/**
 * @author: Chris Okebata
 */


const renderOrganizationPortalRow = (portal, classes, openModal) => {

  return(
    <ExpansionPanelDetails key={portal.id} className={classes.expansionPanelDetails}>
      <Typography className={classes.typography15}>
        {portal.displayLabel}
      </Typography>

      <div style={{margin: '0 0 0 20px', display: 'flex', flexDirection: 'row'}}>
        <button className="btn btn-default font-16" onClick={(e)=>{openModal(e, 'viewPortalModalStatus', portal)}}
                style={{textAlign: 'center', padding: '5px 20px', maxHeight: 32}}>
          View
        </button>
        <button className="btn btn-info font-16" onClick={(e)=>{openModal(e, 'editPortalModalStatus', portal)}}
                style={{margin: '0 7px', textAlign: 'center', padding: '5px 20px', maxHeight: 32}}>
          Edit
        </button>
        <button className="btn btn-danger font-16" onClick={(e)=>{openModal(e, 'deletePortalModalStatus', portal)}}
                style={{textAlign: 'center', padding: '5px 20px', maxHeight: 32}}>
          Delete
        </button>
      </div>
    </ExpansionPanelDetails>
  );
};


class OrganizationPortal extends Component {

  render() {
    const { classes, portalArr, openModal } = this.props;
    return(
      <Fragment>
        {portalArr.map((portal) => renderOrganizationPortalRow( portal, classes, openModal))}
      </Fragment>
    )
  }
}

OrganizationPortal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styledOrganizationPortal = withStyles(stylesPortal)(OrganizationPortal);
export { styledOrganizationPortal as OrganizationPortal };