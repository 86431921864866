import {authHeader, handleHttpResponse, objectToQueryString} from "_helpers";
import {SERVER_URL} from "../config";
import axios from "axios";


function getAll(orgId, params){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/organizations/${orgId}/schedules?${objectToQueryString(params)}`, requestOptions)
        .then(handleHttpResponse);
}

function getSchedule(orgId, scheduleId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/organizations/${orgId}/schedules/${scheduleId}`, requestOptions)
        .then(handleHttpResponse);
}

function addSchedule(props){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(props)
    };

    return fetch(`${SERVER_URL}/api/organizations/${props.organization}/schedules`, requestOptions)
        .then(handleHttpResponse);
}

function updateSchedule(props){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(props)
    };

    return fetch(`${SERVER_URL}/api/organizations/${props.orgId}/schedules/${props.id}`, requestOptions)
        .then(handleHttpResponse);
}

function deleteSchedule(props){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`${SERVER_URL}/api/organizations/${props.orgId}/schedules/${props.id}`, requestOptions)
        .then(handleHttpResponse);
}

function getOrgWithSchedules(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${SERVER_URL}/api/organizationsWithSchedules`, requestOptions)
        .then(handleHttpResponse);
}

const getScheduledOrganizations = (params) => {
    const endpoint = `${SERVER_URL}/api/scheduledOrganizations?${objectToQueryString(params)}`;

    return axios.get(endpoint, {
        headers: authHeader()
    });
};

const bulkPayoutPricing = (params) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    const endpoint = `${SERVER_URL}/api/schedules/bulkPayoutPricing`;

    return fetch(endpoint, requestOptions)
        .then(handleHttpResponse);
};

export const scheduleServices = {
    getAll,
    getSchedule,
    addSchedule,
    updateSchedule,
    deleteSchedule,
    getOrgWithSchedules,
    getScheduledOrganizations,
    bulkPayoutPricing
};