import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

function getAll(params = {}) {

    return fetch(`${SERVER_URL}/api/pricingRates?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getPricing(id) {

    return fetch(`${SERVER_URL}/api/pricingRates/${id}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function createPricing(data) {

    return fetch(`${SERVER_URL}/api/pricingRates`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

function updatePricing(data, id) {

    return fetch(`${SERVER_URL}/api/pricingRates/${id}`,
        {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

export const pricingService = {
    getAll,
    getPricing,
    createPricing,
    updatePricing,
};

