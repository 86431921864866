import React, {Fragment} from "react";
import logoCustomerIo from 'components/images/logos/logo_customerio.svg'
import ChannelCard from '../../ChannelCard'
import useCustomerIoSetup from "./useCustomerIoSetup";
import CustomerIoSetupModal from "./CustomerIoSetupModal";

export default function CustomerIoEntry() {
	const { initiateCustomerIoSetup, customerIoSetupOpen, handleCustomerIoSetupOpen, handleCustomerIoSetupClose } = useCustomerIoSetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoCustomerIo}
				label={'customer.io'}
				description={'Connect your customer.io instance'}
				onClick={initiateCustomerIoSetup}
			/>

			<CustomerIoSetupModal
				open={customerIoSetupOpen}
				handleOpen={handleCustomerIoSetupOpen}
				handleClose={handleCustomerIoSetupClose}
			/>
		</Fragment>
	)
}