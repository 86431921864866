import {withStyles} from '@material-ui/core/styles';
import React from 'react'
import {Loading} from "components/common";
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core";
import {Link} from 'react-router-dom'
import {SchedulePanel} from "./SchedulePanel";

import {getCurrentUser, isObjectEmpty} from "_helpers";
import {scheduleStyles} from "./styles";

const {
    styles,
    panelStyles,
} = scheduleStyles

const SchedulesList = ({isLoading, orgSchedules, deleteSchedule, classes}) => {
    if (isLoading) {
        return <Loading/>
    } else {
        const currentUser = getCurrentUser();
        const {id, timezone} = currentUser._embedded.organization;
        const schedulePanel = !isObjectEmpty(orgSchedules) &&
            orgSchedules.map(([keyName, value]) => {
                return <SchedulePanel
                    handleId={keyName}
                    schedules={value}
                    classes={classes}
                    deleteSchedule={deleteSchedule}
                    panelStyles={panelStyles}
                    key={keyName}
                    orgId={id}
                />
            })

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <h3 className={classes.header}>Team Schedule</h3>
                    <Grid item xs={12} container>
                        <Grid item xs={9}>
                            <Typography gutterBottom variant="subtitle1" className={classes.timezone}>
                                <b className={classes.bold}>Timezone:</b> {timezone ? timezone : "No timezone set"}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} className="text-right">
                            <Link to={`/company/${id}/schedules/add`}>
                                <button className="btn btn-success btn-small">
                                    Add Schedule
                                </button>
                            </Link>
                        </Grid>
                    </Grid>
                    {schedulePanel}
                </Paper>
            </div>
        )
    }
};

const schedulesListWithStyles = withStyles(styles)(SchedulesList);
schedulesListWithStyles.propTypes = {
    classes: PropTypes.object.isRequired,
};
export {schedulesListWithStyles as SchedulesList}