import React from 'react'
import { alertActions } from "_actions/index";
import connect from "react-redux/es/connect/connect";
import {styles} from './StylesNotifier'


class Notifier extends React.Component {
    hideNotifier() {
        setTimeout(() => {
            const { dispatch } = this.props;
            dispatch(alertActions.clear());
        }, 3000);
    }

    render() {
        const { tmsNotifierMsg, tmsNotifier} = styles;

        this.hideNotifier();

        return (
            <div style={tmsNotifier} id="notifier">
                <p style={tmsNotifierMsg}>{this.props.msg}</p>
            </div>
        );
    }
}

const connectedNotifier = connect(null)(Notifier);
export { connectedNotifier as Notifier };