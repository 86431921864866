import React from 'react';
import { Avatar as MUIAvatar } from '@mui/material';

/**
 *
 * @param {object} params
 * @param {string} params.userName – (required) User name, used for 'John Doe -> JD' letter display when no image is present
 * @param {string | undefined} params.src - (optional)
 * @param {string} params.alt - (required) Alt description of image
 * @param {string} params.background - Background color of avatar
 * @param {string} params.color - color of avatar text
 * @param {'default' | 'medium' | 'large' | 'xlarge'} params.size - (optional) Size of avatar
 * @param {import('@mui/material/Avatar').AvatarProps['variant']} params.variant - (optional) Variant of avatar
 * @param {boolean?} params.hasBorder - (optional) Variant of avatar
 */
export default function UserDisplayInitials({
       userName,
       src,
       alt,
       background,
       color,
       size = 'default',
       variant = 'circle',
       hasBorder = false,
       }) {
    const initial = userName ? getUserInitials(userName) : null;

    const getSizeStyle = () => {
        switch (size) {
            case 'default': return {
                width: '25px', height: '19px', fontSize: '16px',
                borderRadius: variant !== 'circle' ? '4px' : undefined,
                border: 'none', background: '#E6F3F2',

        };
            case 'medium': return {
                width: '40px', height: '40px', fontSize: '20px',
                borderRadius: variant !== 'circle' ? '4px' : undefined,
                border: hasBorder && '1px solid #fff',
                boxSizing: 'border-box',
            };
            case 'large': return {
                width: '72px', height: '72px', fontSize: '24px',
                borderRadius: variant !== 'circle' ? '4px' : undefined,
                border: hasBorder && '2px solid #fff',
            };
            case 'xlarge': return {
                width: '160px', height: '160px', fontSize: '64px',
                borderRadius: variant !== 'circle' ? '10px' : undefined,
                border: hasBorder && '4px solid #fff',
            };
        }
    }

    return (
        <MUIAvatar
            sx={{
                ...styles.root,
                ...getSizeStyle(),
                backgroundColor: background,
                color: color,
                position:"inherit",
            }}
            alt={alt}
            src={src}
            variant={variant}
        >
            {initial}
        </MUIAvatar>
    )
}

const styles = {
    root: {
        fontFamily: 'LarsseitMedium',
        lineHeight: 1.2,
    },
}

const getUserInitials = (username) => {
    let fullInitials = ""
    const splitUsername = username.split(" ")
    for(const item in splitUsername) {
        const initials = splitUsername[item].split('')[0]
        fullInitials += initials?.toUpperCase()
    }
    return fullInitials
}













