import React, {Component} from 'react'
import {Button} from "react-bootstrap";


class DeleteButton extends Component {

    render() {

        const {onClick, style} = this.props;
        return (
            <Button type="button"
                    className="btn-danger btn-sm"
                    style={style}
                    onClick={onClick} >
                <span>Delete</span>
            </Button>

        );
    }
}

export { DeleteButton }