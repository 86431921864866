import React, { Component } from 'react';
import { connect } from 'react-redux';
import { employmentActions } from '_actions';
import { Button, Image } from 'react-bootstrap';
import { ConfirmationModal, Loading, StarRating } from 'components/common';
import {
    generatePhotoLink,
    getCurrentUser, isObjectEmpty,
    objectToQueryString,
    queryStringToObject
} from '_helpers';
import { employmentStatus as status, workMode as mode } from '_constants';
import userAvatar from 'components/images/user_avatar_.svg'

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 04/10/2018 : 3:36 PM
 * @author Chris Okebata
 */

let styles = {
    teamInfoContainer: {
        lineHeight: '50px',
        paddingRight: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    teamAvatar: {
        width: '50px',
        height: '50px',
        borderRadius: '25px',
        float: 'left',
        marginRight: '15px'
    },
    breadcrumbContainer: {
        width: 'auto',
        height: 'auto',
        border: '1px solid transparent',
        marginBottom: '10px'
    },
    tableContainer: {
        width: 'auto',
        height: 'auto',
        border: '1px solid #dee2e0',
        borderRadius: '3px',
        marginBottom: '5px'
    },
    ratingContainer: {
        position: 'relative',
        top: '2px',
        paddingLeft: '10px'
    }
};

class CompanyTeam extends Component {

    constructor(props, context) {
        super(props, context);

        /**
         * Get the query string from the current url.
         * @type {string}
         */
        const queryString = this.props.location.search.substring(1);
        const queryStringAsObject = queryStringToObject(queryString);

        this.state = {
            showModal: false,
            employmentId: '',
            start: queryStringAsObject.start ? queryStringAsObject.start : 0,
            end: queryStringAsObject.end ? queryStringAsObject.end : 10,
            max: 10,
            addToTeamPayload: null
        }

    }

    componentDidMount() {
        this.props.dispatch(employmentActions.getOrgEmployments({ orgId: getCurrentUser().organization_id }));
    }

    handleClose = () => {
        this.setState({ showModal: false });
    };

    handleShowModal = (event, employmentId) => {
        event.preventDefault();

        const addToTeamPayload = {
            id: employmentId,
            mode: mode.ACTIVE,
            status: status.HIRED
        };
        this.setState({
            showModal: true,
            addToTeamPayload
        });
    };

    handleNavigation = (event, employment) => {
        if(event.target.tagName !== "BUTTON"){
            this.props.history.push({
                pathname: `experts/${employment.id}/show`
            });
        }
    };

    saveEmployment = (params) => {
        this.props.dispatch(employmentActions.updateEmployment(params));
        this.handleClose();
    };

    /**
     * Handles click on a pagination button/link.
     * @param page
     */
    handleSelect = (page) => {

        const start = page.selected * this.state.max;
        const end = start + this.state.max;

        const queryString = objectToQueryString({
            start,
            end
        });

        /**
         * This applies the query parameters from form to browser URL
         */
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?${queryString}`
        });

    };

    renderRow = (employment) => {

        const hired = employment.status === status.HIRED;
        const score = parseFloat(employment.score.toFixed(1));
        const { ratingContainer, teamInfoContainer, teamAvatar } = styles;

        return (
            <tr key={employment.id} onClick={(event) => {this.handleNavigation(event, employment)}} className="tms-cursor-pointer">
                <td valign="center" style={teamInfoContainer}>
                    <div>
                        <div style={teamAvatar}>
                            <Image responsive
                                   src={employment.photo ? generatePhotoLink(employment.photo) : userAvatar}
                                   circle />
                        </div>
                        <div className="tms-ellipsis color-green">
                            {employment.nickname ? employment.nickname : employment.firstName}
                        </div>
                    </div>
                </td>
                <td valign="center">
                    <div className="margin-top-2">
                        <span className="color-orange">{score}</span>
                        <span style={ratingContainer}>
                            <StarRating rating={score} />
                        </span>
                    </div>
                </td>
                <td valign="center">
                    <Button
                        disabled={hired}
                        bsStyle={hired ? 'default' : 'success'}
                        style={hired ? {
                            padding: '0px',
                            border: 'none',
                            background: 'none'
                        } : { padding: '7px 12px' }}
                        onClick={(e) => this.handleShowModal(e, employment.id)}>
                        {hired ? 'Added to team' : 'Add to Team'}
                    </Button>
                </td>
            </tr>

        );
    };

    render() {
        const { employmentData } = this.props;
        const { breadcrumbContainer, tableContainer } = styles;

        if (isObjectEmpty(employmentData)) {
            return ( <Loading/> )
        }

        let { organizationEemployment } = employmentData;

        /**
         * Retrieve the data to be presented per page
         */
        // const employmentsPerPage = employments.slice(this.state.start, this.state.end);
        // const { params, count } = employmentData;
        // const totalPages = Math.ceil(count / this.state.max);

        let addToTeamModal;
        if (this.state.showModal && this.state.addToTeamPayload) {
            addToTeamModal = <ConfirmationModal
                onSave={this.saveEmployment}
                bodyText="Adding this agent to your team will enable them to respond to customers"
                headerText="Add to your Team"
                buttonText="Add to Team"
                payload={this.state.addToTeamPayload}
                onHide={this.handleClose}
                show={this.state.showModal} />
        }

        return (
            <div style={{ padding: '0 38px' }}>
                {addToTeamModal}
                <div className="content-pane-90">
                    <div style={breadcrumbContainer}>
                        <span style={{
                            padding: '10px 0',
                            display: 'block'
                        }}
                              className="color-grey-dark font-15-bold">My Team</span>
                    </div>
                    <div style={tableContainer}>
                        <table className="trds-table font-14 color-grey-dark">
                            <thead>
                            <tr>
                                <th className="trends-table-header-width-40">
                                </th>
                                <th className="trends-table-header-width-20">Quality Score</th>
                                <th className="trends-table-header-width-20">
                                </th>
                            </tr>
                            </thead>
                            <tbody className="font-15">
                            {organizationEemployment.map(this.renderRow)}
                            </tbody>
                        </table>
                    </div>

                    {/*@TODO: Disabled pagination temporarily*/}

                    {/*<div style={{ textAlign: 'left' }}>*/}
                        {/*{*/}
                            {/*totalPages > 1 &&*/}
                            {/*(*/}
                                {/*<Paginate totalPages={totalPages}*/}
                                          {/*params={params}*/}
                                          {/*activePage={this.state.start / this.state.max}*/}
                                          {/*handleSelect={this.handleSelect}*/}
                                {/*/>*/}
                            {/*)*/}
                        {/*}*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

    function mapStateToProps(state) {
        const { employment: { orgEmployments } } = state;
        return {
            employmentData: orgEmployments,
        };
    }

    const connectedCompanyTeam = connect(mapStateToProps)(CompanyTeam);
    export { connectedCompanyTeam as CompanyTeam };

