import React from 'react';

import { Col, Grid, Panel } from 'react-bootstrap';
import { userActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { pluck } from "underscore";


class UserDetails extends React.Component {

    componentDidMount() {
        const {userId} = this.props.match.params;
        this.props.dispatch(userActions.getUser(userId));
    }

    render() {

        if (!this.props.user || !this.props.user.id) {
            return <Loading/>
        }

        const {user} = this.props;
        const {roles} = user._embedded;

        return (
            <Grid>

                <br/>
                <br/>
                <hr/>

                <Col xs={8} xsOffset={2}>

                    <Panel>
                        <Panel.Heading>User Details</Panel.Heading>
                        <Panel.Body>
                            <strong>User Name:</strong> {user.username}
                            <hr/>
                            <strong>Roles:</strong> {pluck(roles, 'authority').join(',')}
                            <hr/>
                            <strong>Date Created: </strong>{moment(user.dateCreated).format('MMMM D, YYYY')}
                            <hr/>
                            <strong>Account Locked: </strong>{user.accountLocked.toString()}
                            <hr/>
                            <strong>Enabled: </strong>{user.enabled.toString()}
                        </Panel.Body>
                        <Panel.Footer>
                            <Link className='btn btn-success' to='/users'>Back</Link>
                            <Link className='btn btn-success pull-right' to={`/users/${user.id}/edit`}>Edit User</Link>
                        </Panel.Footer>
                    </Panel>

                </Col>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const {users: {user}} = state;
    return {
        user
    };
}

const connectedUserDetails = connect(mapStateToProps)(UserDetails);
export {connectedUserDetails as UserDetails};
