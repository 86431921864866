import {alertMessages, oauthConstants} from "../_constants";
import {tiktokAdsOauthService} from "../_services/tiktok/tiktokads.oauth.service";
import {tiktokOauthService} from "../_services";
import {history} from "../_helpers";
import {SnackBar} from "../components/common";

function initiateOauth() {
    function request(){
        return { type: oauthConstants.TIKTOK_ADS_OAUTH_URL_REQUEST };
    }

    function success(authUrl){
        return { type: oauthConstants.TIKTOK_ADS_OAUTH_URL_SUCCESS, payload:authUrl };
    }

    function failure(error){
        return { type: oauthConstants.TIKTOK_ADS_OAUTH_URL_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        tiktokAdsOauthService.initiateOauth()
            .then(
                authUrl => dispatch(success(authUrl)),
                (error) => {
                    dispatch(failure());
                },
            );
    };
}

function generateAccessToken(params) {
    let callbackUrl = localStorage.getItem('redirectUrl');
    callbackUrl = JSON.parse(callbackUrl).pathname;

    function request() {
        return { type: oauthConstants.TIKTOK_ADS_ACCESSTOKEN_REQUEST };
    }

    function success(res) {
        return { type: oauthConstants.TIKTOK_ADS_ACCESSTOKEN_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: oauthConstants.TIKTOK_ADS_ACCESSTOKEN_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        tiktokAdsOauthService.generateAccessToken(params)
            .then(
                (res) => {
                    if (res) {
                        history.push(callbackUrl);
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.TIKTOK_ADS_OAUTH_SUCCESS);
                    }
                },
                (error) => {
                    history.push(callbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TIKTOK_ADS_OAUTH_FAILURE);
                },
            );
    };
}

export const tiktokAdsOauthActions = {
    initiateOauth,
    generateAccessToken
};
