import React, {Fragment, useEffect} from "react";
import {Box, Stack} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import LinkIcon from "@mui/icons-material/Link";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import ActionButton from "../../../common/ActionButton";
import {useDispatch, useSelector} from "react-redux";
import CompanyDropdownOptions from "../Utilities/CompanyDropdownOptions"
import {slackOauthActions} from "../../../../../_actions";
import _ from "lodash"

export default function CompanyProfileDetails({organization}) {
    const organizationSlackStatus = useSelector((state) => state.organizations.organizationSlackStatus)
    const slackOauth = useSelector((state) => state.slackOauth)
    const dispatch = useDispatch()

    const disconnectSlack = () => {
        dispatch(slackOauthActions.disconnectSlack(organization.id))
    }

    const initiateOauth = () => {
        dispatch(slackOauthActions.initiateOauth());
    };

    const oauthRedirect = (authUrl) => {
        window.open(authUrl);
    }

    let vertical;
    if(organization._embedded && organization._embedded.vertical) {
        vertical = organization._embedded.vertical.name;
    }

    const link = _.isEmpty(organization.website) ? '#' : `https://${organization.website}`

    useEffect(() => {
        if (slackOauth.data.oauthUrl) {
            const url = new URL(slackOauth.data.oauthUrl);
            const searchParams = url.searchParams;
            localStorage.setItem("slackOAuthState", searchParams.get("state"));
            oauthRedirect(slackOauth.data.oauthUrl);
        }
    },[slackOauth])

    return(
        <Fragment>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 1}}>
                <Box sx={{ fontSize: '24px', fontFamily: 'larsseitMedium', whiteSpace: 'pre', mb: '10px', lineHeight: '19.2px' }}>
                    {organization?.name}
                </Box>

                <Stack spacing={0} justifyContent={"space-between"} height={'100%'}>
                    <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
                        <Box sx={styles.iconContainer}><LocationOnIcon /></Box>
                        <Box sx={styles.contactText}>{organization.location}</Box>
                    </Stack>
                    <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
                        <Box sx={styles.iconContainer}> <AccessTimeIcon /></Box>
                        <Box sx={styles.contactText}>{organization?.timezone}</Box>
                    </Stack>
                    <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
                        <Box sx={styles.iconContainer}><BusinessIcon /></Box>
                        <Box sx={styles.contactText}>{vertical}</Box>
                    </Stack>
                    <Stack direction={"row"} alignItems={"flex-end"} spacing={1}>
                        <Box sx={styles.iconContainer}><LinkIcon /></Box>
                        <Box sx={styles.contactText}>
                            <a href={link} target="_blank" rel="noopener noreferrer" className="color-green">{organization.website}</a>
                        </Box>
                    </Stack>
                </Stack>
            </Box>

            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 2}}>
                <Box>
                    <Box sx={styles.connectedChannelText}>
                        <Box sx={styles.connectedChannelText }>CONNECTED CHANNELS</Box>
                    </Box>

                    <Stack direction="row" mt={2} spacing={2} className="tms-company-details-social">
                        <a style={{fontSize: '20px', color: '#07857C'}} href={organization.twitter !== undefined ? `https://twitter.com/${organization.twitter}`: ''} target='_blank' rel="noopener noreferrer">
                            <TwitterIcon/>
                        </a>
                        <a style={{fontSize: '20px', color: '#07857C'}} href={organization.linkedin !== undefined ? `https://linkedin.com/${organization.linkedin}` : ''} target='_blank' rel="noopener noreferrer">
                            <LinkedInIcon/>
                        </a>
                        <a style={{fontSize: '20px', color: '#07857C'}} href={organization.facebook !== undefined ? `https://facebook.com/${organization.facebook}` : ''} target='_blank' rel="noopener noreferrer">
                            <FacebookIcon/>
                        </a>
                        <a style={{fontSize: '20px', color: '#07857C'}} href={organization.instagram !== undefined ? `https://instagram.com/${organization.instagram}`: ''} target='_blank' rel="noopener noreferrer">
                            <InstagramIcon/>
                        </a>
                    </Stack>
                </Box>
                <Box sx={{width: "fit-content"}}>
                    { organizationSlackStatus?.isActive ?
                        <ActionButton label={'Disconnect slack account'} action={disconnectSlack} /> :
                        <ActionButton label={'Connect slack account'} action={initiateOauth} />
                    }
                </Box>
            </Box>

            <Box>
                <CompanyDropdownOptions />
            </Box>
        </Fragment>
    )
}

const styles = {
    iconContainer: {
        color: '#07857C',
        fontSize: '20px',
        width: '25px',
        height: '22px',
    },
    contactText: {
        fontSize: "14px",
        fontFamily: "LarsseitMedium",
        color: "#495D5E",
    },
    connectedChannelText: {
        Width : '198px',
        Height : '19px',
        Top: '412px',
        Left : '858px',
        fontSize: '16px',
        fontFamily: 'larsseitMedium',
        lineHeight: '19.2px',
        fontWeight: '700',
    },
}
