import React, {Fragment} from "react";
import {CLIENT_URL} from "config";
import TrustpilotAppModalForm from "./TrustpilotAppModalForm";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";

export default function TrustpilotAppModal({ open, handleClose, handleOpen, redirectUrl }){
    return(
        <Fragment>
            <BasicModal
                open={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
                title={'Add Trustpilot App details'}>

                <TrustpilotAppModalForm
                    description={'Please copy and paste your Api Key and Secret'}
                    extraInfoLabel={'Create App on Trustpilot'}
                    buttonLabel={'Submit'}
                    openInNewTab={true}
                    handleClose={handleClose}
                    redirectUrl={redirectUrl}
                    callbackUrl={`${CLIENT_URL}/company/connectchannels`}
                />
            </BasicModal>
        </Fragment>
    )
}