import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {billingActions} from "../../_actions/billing.actions";

export default function CompanyBillingButton() {
    const dispatch = useDispatch()
    const url = useSelector((state) => state.billing.url)

    const getLink = () => {
        dispatch(billingActions.getBillingSettingsLink())
    };

    return (
        <Fragment>
            <div className="color-grey-dark" onClick={getLink}>Billing</div>
        </Fragment>
    );
};

