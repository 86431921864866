import { history } from '_helpers';
import { tiktokOauthService } from "../_services";
import { alertMessages, oauthConstants } from "../_constants";
import { SnackBar } from "../components/common";

function initiateOauth(callbackUrl) {
    function request(){
        return { type: oauthConstants.TIKTOK_OAUTH_URL_REQUEST };
    }

    function success(authUrl){
        return { type: oauthConstants.TIKTOK_OAUTH_URL_SUCCESS, payload:authUrl };
    }

    function failure(error){
        return { type: oauthConstants.TIKTOK_OAUTH_URL_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        tiktokOauthService.initiateOauth(callbackUrl)
            .then(
                authUrl => dispatch(success(authUrl)),
                (error) => {
                    dispatch(failure());
                },
            );
    };
}

function genAccessToken(params) {
    let callbackUrl = localStorage.getItem('redirectUrl');
    callbackUrl = JSON.parse(callbackUrl).pathname;

    function request() {
        return { type: oauthConstants.TIKTOK_ACCESSTOKEN_REQUEST };
    }

    function success(res) {
        return { type: oauthConstants.TIKTOK_ACCESSTOKEN_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: oauthConstants.TIKTOK_ACCESSTOKEN_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());

        tiktokOauthService.oauthAccessToken(params)
            .then(
                (res) => {
                    if (res) {
                        history.push(callbackUrl);
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.TIKTOK_OAUTH_SUCCESS);
                    }
                },
                (error) => {
                    history.push(callbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TIKTOK_OAUTH_FAILURE);
                },
            );
    };
}

function generateAccessToken(params, callbackUrl = '/signup/schedule', onErrorCallbackUrl = '/signup/add-channel') {
    function request() {
        return { type: oauthConstants.TIKTOK_ACCESSTOKEN_REQUEST };
    }
    function success(res) {
        return { type: oauthConstants.TIKTOK_ACCESSTOKEN_SUCCESS, payload: res };
    }
    function failure(error) {
        return { type: oauthConstants.TIKTOK_ACCESSTOKEN_FAILURE, payload: error };
    }

    return (dispatch) => {
        dispatch(request());
        tiktokOauthService.oauthAccessToken(params)
            .then(
                (res) => {
                    if (res) {
                        history.push(callbackUrl);
                        dispatch(success(res));
                        SnackBar.showMessage(alertMessages.TIKTOK_OAUTH_SUCCESS);
                    }
                },
                (error) => {
                    history.push(onErrorCallbackUrl);
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TIKTOK_OAUTH_FAILURE);
                },
            );
    };
}


function updateSearchKeyword(params){
    function request() {
        return { type: oauthConstants.TIKTOK_SEARCHKEYWORD_REQUEST };
    }

    function success(res) {
        return { type: oauthConstants.TIKTOK_SEARCHKEYWORD_SUCCESS, payload: res };
    }

    function failure(error) {
        return { type: oauthConstants.TIKTOK_SEARCHKEYWORD_FAILURE, payload: error };
    }
    return (dispatch) => {
        dispatch(request());

        tiktokOauthService.updateSearchKeyword(params)
            .then(
                msgDataSource => {dispatch(success(msgDataSource));
                SnackBar.showMessage(alertMessages.TIKTOK_UPDATE_SEARCH_SUCCESS)},
                error => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.TIKTOK_UPDATE_SEARCH_FAILURE)
                }
            );
    }
};

export const tiktokOauthActions = {
    initiateOauth,
    genAccessToken,
    updateSearchKeyword,
    generateAccessToken
};
