import {useState} from 'react';
import {Box} from "@mui/system";
import _ from "lodash";
import CheckboxLabel from "../../CheckboxLabel";

export default function FilterCheckbox({value, label, index, checkedItemsValues=[], onChange}) {
    const [isChecked, setIsChecked] = useState(() => {
        return checkedItemsValues.includes(_.toString(value));
    });

    const handleChange = (clickedIndex) => (event) => {
        setIsChecked(_.get(event, 'target.checked'));
        onChange(clickedIndex, event);
    }

    return (
        <Box>
            <CheckboxLabel
                name={label}
                checked={isChecked}
                value={_.toString(value)}
                label={label}
                onChange={handleChange(index)}
            />
        </Box>
    )
}