
export default {
    root: {
        display: 'flex',
        width: '100%',
        marginBottom: '17px',
    },
    icon: {
        marginRight: '8px',
        display: {
            xs: 'none',
            sm: 'block',
            md: 'block',
            lg: 'block',
        },
    },
    messageContainer: {
        width: {
            xs: '100%',
            sm: 'calc(100% - 48px)',
            md: 'calc(100% - 48px)',
            lg: 'calc(100% - 48px)',
        },
    },
    infoArea: {
        marginBottom: '4px',
    },
    authorName: {
        width: '100%',
        marginBottom: '1px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    authorInfoContainer: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: '2px 0 3px 0'
    },
    infoContent: {
        fontSize: '14px',
        color: '#0C2728',
        fontFamily: 'LarsseitRegular',
        lineHeight: 1.2,
    },
    infoItem: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1px',
        '&:last-of-type': { marginBottom: '0px' },
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    date: {
        fontSize: '13px',
        color: '#495D5E',
        fontFamily: 'LarsseitRegular',
        lineHeight: 1.2,
        textAlign: {
            xs: 'left',
            sm: 'left',
            md: 'right',
            lg: 'right',
        },
        marginBottom: {
            xs: '5px',
            sm: '5px',
            md: '4px',
            lg: '4px',
        },
        paddingTop: {
            xs: '0',
            sm: '0',
            md: '2px',
            lg: '2px',
        },
        border: '1px solid'
    },
    emailListItem: {
        display: 'flex',
        height: '20px',
    },
    emailInfoItem: {
        position: 'relative',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    fromName: {
        fontFamily: 'LarsseitMedium',
    },
    copyIcon: {
        fontSize: '15px',
    },
    copyIconContainer: {
        margin: '-2px 0 0 3px'
    },
    dateContainer: {
        fontSize: '13px',
        color: '#495D5E',
        fontFamily: 'LarsseitRegular',
        lineHeight: 1.2,
        textAlign: 'right',
        whiteSpace: 'nowrap',
        padding: '2.7px 0 3px 0',
        display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
        }
    },
    dateContainerMobile: {
        fontSize: '13px',
        color: '#495D5E',
        fontFamily: 'LarsseitRegular',
        lineHeight: 1.2,
        margin: '4px 0 10px 0',
        display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
        }
    },
    attachmentContainer: {
        listStyle: 'none', m: 0, p: 0
    },
    attachment: {
        marginTop: '7px',
        border: '1px solid #DCE2E3',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        padding: '6px 12px',
        '&:last-of-type': { marginBottom: '7px' },
        a: {
            fontFamily: 'LarsseitRegular',
            fontSize: '16px',
            color: '#07857C',
            textDecoration: 'none!important',
            ':focus':{outline: 'none'},
            ':hover': {
                color: '#05645d',
                backgroundColor: 'unset'
            }
        },
        button: {
            fontFamily: 'LarsseitRegular',
            fontSize: '16px',
            color: '#07857C',
            ':hover': {
                color: '#05645d',
                backgroundColor: 'unset'
            },
            ':active': {
                color: '#07857C',
                backgroundColor: 'unset'
            },
            ':focus': {
                color: '#07857C',
                backgroundColor: 'unset'
            }
        }
    }
}