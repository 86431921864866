import React, { forwardRef } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import importedStyles from './styles'

export const DashboardTableRow = forwardRef(( props, ref ) => {
	return (
		<TableRow ref={ref} {...props} sx={{ ...props.sx, '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
			{props.children}
		</TableRow>
	)
})

export const DashboardTableCell = forwardRef(({ borderVertical, ...props }, ref ) => {
	return (
		<TableCell ref={ref} {...props} sx={{
			...tableStyles.tableBodyCell,
			...importedStyles.root,
			...(borderVertical ? tableStyles.borderVertical : {}),
			...props.sx,
		}}>
			{props.children}
		</TableCell>
	)
})

/**
 * @param {object} params
 * @param {React.ReactNode} params.children
 * @param {string} params.label
 * @param {string[]} params.headLabels - (optional) array of strings for labelling the table head (e.g. ['Name', 'Ticket Volume', 'Since last week'])
 * @param {import('react').ReactNode[]?} params.headRowComponents — (optional) array of React components for the table head (e.g. [<TableCell />, <TableCell />, <TableCell />])
 * @param {boolean} params.borderVertical - (optional, defaults to `false`) if the table should have a vertical+horizontal border.
 * @param {any} params.sx
 */
export default function DashboardTable({
	children,
	label,
	headLabels,
	headRowComponents = undefined,
	borderVertical = false,
	sx,
}) {
	return (
		<TableContainer sx={{ ...tableStyles.root, ...sx, }}>
			<Table aria-label={label} size={'small'}>
				{headLabels && headLabels.length ? (
					<TableHead>
						<TableRow>
							{headLabels.map((headLabel, key) => (
								<TableCell
									key={key}
									align={key === 0 ? 'left' : 'right'}
									sx={{
										...importedStyles.root,
										...tableStyles.tableHeadCell,
										...(key === 0 ? {} : { width: '1%', whiteSpace: 'nowrap' }),
										...(borderVertical ? tableStyles.borderVertical : {})	}}>
									{headLabel}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
				): null}
				{headRowComponents ? (
					<TableHead>
						<TableRow>
							{headRowComponents}
						</TableRow>
					</TableHead>
				): null}

				<TableBody>
          {children}
        </TableBody>
			</Table>
		</TableContainer>
	)
}

export const tableStyles = {
	root: {
		border: '1px solid #B6BEBF',
		borderRadius: '5px',
		background: '#fff',
	},
	tableHeadCell: {
		py: '16px',
		px: '12px',
		whiteSpace: 'nowrap',
		fontSize: '14px!important',
		fontFamily: 'LarsseitMedium',
	},
	tableBodyCell: {
		py: '10px',
		px: '12px',
		whiteSpace: 'nowrap',
		fontSize: '14px!important',
	},
	borderVertical: {
		':not(:last-child)': { borderRight: '1px solid rgba(224, 224, 224, 1)' }
	},
	cellVerticalDivider: {
		borderRight: '1px solid #D7DBDC!important',
	},
	cellWithBadges: {
		width: '100%',
		p: '16px!important',
	},
}