import React, {useState} from "react";
import {Box} from "@mui/system";
import styles from "../../organizationMainStyles";
import {Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";
import {accessibilityMode} from "../organizationSettingsConstants";
import {buildUpdateShopifyRevenueTrackingPayload} from "../organizationSettingsUtils";
import {SnackBar} from "../../../../../common";

export default function ShopifyRevenueTrackingToggleContainer({mode, organization, label}) {
    const disable = mode === accessibilityMode.READ_ONLY;
    const isShopifyRevenueTrackingOn = _.get(organization, 'isShopifyRevenueTrackingOn');
    const [enableShopifyRevenueTracking, setEnableShopifyRevenueTracking] = useState(isShopifyRevenueTrackingOn);
    const organizationId = _.get(organization, 'id');
    const dispatch = useDispatch();

    const handleToggle = (event) => {
        const value = _.get(event, 'target.checked');
        setEnableShopifyRevenueTracking(value);
        handleUpdate(value);
    }

    const handleUpdate = (value) => {
        const payload = buildUpdateShopifyRevenueTrackingPayload(value);
        if(!organizationId || !payload) return SnackBar.showMessage('Invalid parameters');
        dispatch(organizationSettingsActions.updateOrganizationAttribute(organizationId, payload));
    }

    return(
        <Box sx={styles.notificationToggleContainer}>
            <Box component={'h2'} sx={styles.notificationToggleLabel}>
                {label.SHOPIFY_REVENUE_TRACKING}
            </Box>
            <Box>
                <Switch disabled={disable} checked={enableShopifyRevenueTracking} onChange={handleToggle} sx={styles.switch} />
            </Box>
        </Box>
    )
}