import {useEffect, useState} from "react";
import {helpScoutOauthActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_URL} from "config";
import {utilityService} from "_services";
import setRedirectUrl from "../../Utilities/setRedirectUrl";

export default function useHelpScoutSetup({
	callbackPath,
}) {
	const dispatch = useDispatch();
  const helpScout = useSelector((state) => state.helpScoutOauth);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const performAction = () => {
    setShouldRedirect(true)
    const callbackUrl = `${CLIENT_URL}${callbackPath}`; 
    setRedirectUrl(callbackUrl)
    dispatch(helpScoutOauthActions.initiateOauth(callbackUrl));
  };

  useEffect(() => {
    if (!helpScout.loading && helpScout.authUrl.url && shouldRedirect){
      setShouldRedirect(false)
      utilityService.redirectToAuthUrl(helpScout.authUrl.url)
    }
  });

	return { initiateHelpScoutSetup: performAction };
}
