import React from "react";
import {Box, Stack} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {styles} from "../MetricsDetailsStyles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const MetricsDetailHeaderLabel = () => {
    return (
        <Stack direction={'column'} style={{background: '#E5E5E5', position: 'absolute', left: '0', top: '30px'}}>
            <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <RouterLink to='/metrics' className="tms-no-underline">
                    <Box sx={styles.backLink}>
                        <ArrowBackIosNewIcon sx={styles.arrowBack}/>
                        <span style={{ marginLeft: '20px'}}>Back to Teams Metrics</span>
                    </Box>
                </RouterLink>
            </Box>
        </Stack>
    )
}