import logoTikTokAds from 'components/images/logos/logo_tiktokads.svg'
import ChannelCard from '../../ChannelCard'
import useTikTokAdsSetup from "./useTikTokAdsSetup";

export default function TikTokAdsEntry() {
	const { initiateTikTokSetup } = useTikTokAdsSetup({ callbackPath: '/tiktok/accounts' })
	return (
		<ChannelCard
			icon={logoTikTokAds}
			label={'TikTok Ads'}
			description={'Reply to comments on your TikTok Ads'}
			onClick={initiateTikTokSetup}
		/>
	)
}