import {WEBSOCKET_SERVER_URL} from "../config";
import _ from "lodash";

/**
 * @author Chris Okebata
 */

let socket;

export const websocket = {

  connect: () => {
    var io = require('socket.io-client');
    socket = io(WEBSOCKET_SERVER_URL);
    var patch = require('socketio-wildcard')(io.Manager);
    patch(socket);
  },

  disconnect: () => {
    socket && socket.disconnect();
  },

  subscribe: (dispatch, subscriptionId, action) => {
    console.log("Subscribing to websocket - subscriptionId: ", subscriptionId);
    socket && socket.on(`${subscriptionId}`, data => {
      dispatch(action(data));
    });
  },

  sendMessage: (eventId, data) => {
    if(_.isEmpty(eventId) || _.isEmpty(data)) return;
    console.log("Emitting message to websocket - eventId: ", eventId, ' data: ', data);
    socket && socket.emit(eventId,  JSON.stringify(data));
  },

  isSameMessageThread: (message, wsMessage) => {
    const threadIdsMatch = websocket.isNonEmptyString(message?.threadId) && message?.threadId === wsMessage?.threadId;
    const teamsHandlesMatch = websocket.isNonEmptyString(message?.dataSourceHandle?.id) && message?.dataSourceHandle?.id === wsMessage.dataSourceHandle;
    const trendsHandlesMatch = _.isNumber(message?.dataSourceHandle?.trendsId) && message?.dataSourceHandle?.trendsId === wsMessage?.handleId;
    const parentsMatch = websocket.isNonEmptyString(message?.externalParentId) && message?.externalParentId === wsMessage?.externalParentId;

    return threadIdsMatch || ((teamsHandlesMatch || trendsHandlesMatch) && parentsMatch);
  },

  isNonEmptyString: (str) => {
    return _.isString(str) && str !== '';
  },
};
