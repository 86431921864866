import {alertMessages, metaOEmbedConstants} from "_constants";
import {oEmbedService} from "_services";
import {SnackBar} from "../components/common";

function getActionData(actionType, payload = {}) {
    return {type: actionType, payload};
}

function getMetaEmbed(messageId) {
    return (dispatch) => {
        dispatch(getActionData(metaOEmbedConstants.GET_META_OEMBED_REQUEST));
        oEmbedService.getMetaEmbed(messageId)
            .then((response) => {
                    dispatch(getActionData(metaOEmbedConstants.GET_META_OEMBED_SUCCESS, response.data));
                },
            ).catch((err) => {
            dispatch(getActionData(metaOEmbedConstants.GET_META_OEMBED_FAILURE, err));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    };
}

export const oEmbedActions = {
    getMetaEmbed
}