export const messageConstants = {
    GET_ALL_REQUEST: 'GET_ALL_MESSAGES_REQUEST',
    GET_ALL_SUCCESS: 'GET_ALL_MESSAGES_SUCCESS',
    GET_ALL_FAILURE: 'GET_ALL_MESSAGES_FAILURE',

    GET_PRIORIY_MESSAGES_REQUEST: 'GET_PRIORIY_MESSAGES_REQUEST',
    GET_PRIORIY_MESSAGES_SUCCESS: 'GET_PRIORIY_MESSAGES_SUCCESS',
    GET_PRIORIY_MESSAGES_FAILURE: 'GET_PRIORIY_MESSAGES_FAILURE',

    GET_ALL_FROM_TRENDS_REQUEST: 'GET_ALL_FROM_TRENDS_REQUEST',
    GET_ALL_FROM_TRENDS_SUCCESS: 'GET_ALL_FROM_TRENDS_SUCCESS',
    GET_ALL_FROM_TRENDS_FAILURE: 'GET_ALL_FROM_TRENDS_FAILURE',

    GET_DETAILS_REQUEST: 'GET_MESSAGE_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_MESSAGE_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_MESSAGE_DETAILS_FAILURE',

    GET_ADDITIONAL_DETAILS_REQUEST: 'GET_ADDITIONAL_MESSAGE_DETAILS_REQUEST',
    GET_ADDITIONAL_DETAILS_SUCCESS: 'GET_ADDITIONAL_MESSAGE_DETAILS_SUCCESS',
    GET_ADDITIONAL_DETAILS_FAILURE: 'GET_ADDITIONAL_MESSAGE_DETAILS_FAILURE',

    GET_HISTORY_REQUEST: 'GET_MESSAGE_HISTORY_REQUEST',
    GET_HISTORY_SUCCESS: 'GET_MESSAGE_HISTORY_SUCCESS',
    GET_HISTORY_FAILURE: 'GET_MESSAGE_HISTORY_FAILURE',

    GET_NEXT_MESSAGE_REQUEST: 'GET_NEXT_MESSAGE_REQUEST',
    GET_NEXT_MESSAGE_SUCCESS: 'GET_NEXT_MESSAGE_SUCCESS',
    GET_NEXT_MESSAGE_FAILURE: 'GET_NEXT_MESSAGE_FAILURE',

    GET_PAYLOAD_REQUEST: 'GET_PAYLOAD_REQUEST',
    GET_PAYLOAD_SUCCESS: 'GET_PAYLOAD_SUCCESS',
    GET_PAYLOAD_FAILURE: 'GET_PAYLOAD_FAILURE',

    GET_ORG_BILLING_REQUEST: 'GET_ORG_BILLING_REQUEST',
    GET_ORG_BILLING_SUCCESS: 'GET_ORG_BILLING_SUCCESS',
    GET_ORG_BILLING_FAILURE: 'GET_ORG_BILLING_FAILURE',

    GET_TRENDS_DETAILS_REQUEST: 'GET_TRENDS_DETAILS_REQUEST',
    GET_TRENDS_DETAILS_SUCCESS: 'GET_TRENDS_DETAILS_SUCCESS',
    GET_TRENDS_DETAILS_FAILURE: 'GET_TRENDS_DETAILS_FAILURE',

    GET_MESSAGES_ALL_REQUEST: 'GET_MESSAGES_ALL_REQUEST',
    GET_MESSAGES_ALL_SUCCESS: 'GET_MESSAGES_ALL_SUCCESS',
    GET_MESSAGES_ALL_FAILURE: 'GET_MESSAGES_ALL_FAILURE',

    GET_TRENDS_MESSAGES_ALL_REQUEST: 'GET_TRENDS_MESSAGES_ALL_REQUEST',
    GET_TRENDS_MESSAGES_ALL_SUCCESS: 'GET_TRENDS_MESSAGES_ALL_SUCCESS',
    GET_TRENDS_MESSAGES_ALL_FAILURE: 'GET_TRENDS_MESSAGES_ALL_FAILURE',

    LOCK_MESSAGE_REQUEST: 'LOCK_MESSAGE_REQUEST',
    LOCK_MESSAGE_SUCCESS: 'LOCK_MESSAGE_SUCCESS',
    LOCK_MESSAGE_FAILURE: 'LOCK_MESSAGE_FAILURE',

    TRENDS_CORRESPONDING_MESSAGES_REQUEST: 'TRENDS_CORRESPONDING_MESSAGES_REQUEST',
    TRENDS_CORRESPONDING_MESSAGES_SUCCESS: 'TRENDS_CORRESPONDING_MESSAGES_SUCCESS',
    TRENDS_CORRESPONDING_MESSAGES_FAILURE: 'TRENDS_CORRESPONDING_MESSAGES_FAILURE',

    GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_REQUEST: 'GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_REQUEST',
    GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_SUCCESS: 'GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_SUCCESS',
    GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_FAILURE: 'GET_MESSAGE_ELIGIBLE_FOR_RESPONSE_GENERATION_FAILURE',

    RESEND_FOR_RESPONSE_GENERATION_REQUEST: 'RESEND_FOR_RESPONSE_GENERATION_REQUEST',
    RESEND_FOR_RESPONSE_GENERATION_SUCCESS: 'RESEND_FOR_RESPONSE_GENERATION_SUCCESS',
    RESEND_FOR_RESPONSE_GENERATION_FAILURE: 'RESEND_FOR_RESPONSE_GENERATION_FAILURE',

    DELETE_USER_MESSAGES_REQUEST: 'DELETE_USER_MESSAGES_REQUEST',
    DELETE_USER_MESSAGES_SUCCESS: 'DELETE_USER_MESSAGES_SUCCESS',
    DELETE_USER_MESSAGES_FAILURE: 'DELETE_USER_MESSAGES_FAILURE',


    GET_MESSAGES_REQUIRING_ATTENTION_REQUEST: 'GET_MESSAGES_REQUIRING_ATTENTION_REQUEST',
    GET_MESSAGES_REQUIRING_ATTENTION_SUCCESS: 'GET_MESSAGES_REQUIRING_ATTENTION_SUCCESS',
    GET_MESSAGES_REQUIRING_ATTENTION_FAILURE: 'GET_MESSAGES_REQUIRING_ATTENTION_FAILURE',

    CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_REQUEST: 'CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_REQUEST',
    CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_SUCCESS: 'CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_SUCCESS',
    CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_FAILURE: 'CONFIRM_MESSAGE_HAS_REPLY_OR_IS_DELETED_FAILURE',

    CHANGE_MESSAGE_PRACTICE_PRIORITY_REQUEST: 'CHANGE_MESSAGE_PRACTICE_PRIORITY_REQUEST',
    CHANGE_MESSAGE_PRACTICE_PRIORITY_SUCCESS: 'CHANGE_MESSAGE_PRACTICE_PRIORITY_SUCCESS',
    CHANGE_MESSAGE_PRACTICE_PRIORITY_FAILURE: 'CHANGE_MESSAGE_PRACTICE_PRIORITY_FAILURE',

    CLEAR_UPDATED_MESSAGE_PRACTICE_PRIORITY: 'CLEAR_UPDATED_MESSAGE_PRACTICE_PRIORITY'
};

export const escalationConstants = {
    ESCALATE_REQUEST: 'ESCALATE_MESSAGE_REQUEST',
    ESCALATE_SUCCESS: 'ESCALATE_MESSAGE_SUCCESS',
    ESCALATE_FAILURE: 'ESCALATE_MESSAGE_FAILURE',
};

export const skipMessagesConstants = {
    SKIP_MESSAGE_REQUEST: 'SKIP_MESSAGE_REQUEST',
    SKIP_MESSAGE_SUCCESS: 'SKIP_MESSAGE_SUCCESS',
    SKIP_MESSAGE_FAILURE: 'SKIP_MESSAGE_FAILURE',
};

export const companyRepliedConstants = {
    COMPANY_REPLIED_REQUEST: 'COMPANY_REPLIED_REQUEST',
    COMPANY_REPLIED_SUCCESS: 'COMPANY_REPLIED_SUCCESS',
    COMPANY_REPLIED_FAILURE: 'COMPANY_REPLIED_FAILURE',
};

export const internalNoteConstants = {
    SUBMIT_INTERNAL_NOTE_REQUEST: 'SUBMIT_INTERNAL_NOTE_REQUEST',
    SUBMIT_INTERNAL_NOTE_SUCCESS: 'SUBMIT_INTERNAL_NOTE_SUCCESS',
    SUBMIT_INTERNAL_NOTE_FAILURE: 'SUBMIT_INTERNAL_NOTE_FAILURE',

    GET_INTERNAL_NOTE_ASSIGNEES_REQUEST: 'GET_INTERNAL_NOTE_ASSIGNEES_REQUEST',
    GET_INTERNAL_NOTE_ASSIGNEES_SUCCESS: 'GET_INTERNAL_NOTE_ASSIGNEES_SUCCESS',
    GET_INTERNAL_NOTE_ASSIGNEES_FAILURE: 'GET_INTERNAL_NOTE_ASSIGNEES_FAILURE',
};

export const rerouteMessageConstants = {
    REROUTE_MESSAGE_REQUEST: 'REROUTE_MESSAGE_REQUEST',
    REROUTE_MESSAGE_SUCCESS: 'REROUTE_MESSAGE_SUCCESS',
    REROUTE_MESSAGE_FAILURE: 'REROUTE_MESSAGE_FAILURE',
};

export const metaOEmbedConstants = {
    GET_META_OEMBED_REQUEST: 'GET_META_OEMBED_REQUEST',
    GET_META_OEMBED_SUCCESS: 'GET_META_OEMBED_SUCCESS',
    GET_META_OEMBED_FAILURE: 'GET_META_OEMBED_FAILURE',
};

export const conversationConstants = {
    GET_CONVERSATION_REQUEST: 'GET_CONVERSATION_REQUEST',
    GET_CONVERSATION_SUCCESS: 'GET_CONVERSATION_SUCCESS',
    GET_CONVERSATION_FAILURE: 'GET_CONVERSATION_FAILURE',

    GET_CONVERSATION_THREAD_REQUEST: 'GET_CONVERSATION_THREAD_REQUEST',
    GET_CONVERSATION_THREAD_SUCCESS: 'GET_CONVERSATION_THREAD_SUCCESS',
    GET_CONVERSATION_THREAD_FAILURE: 'GET_CONVERSATION_THREAD_FAILURE',

    GET_ORIGINAL_CONVERSATION_THREAD_REQUEST: 'GET_ORIGINAL_CONVERSATION_THREAD_REQUEST',
    GET_ORIGINAL_CONVERSATION_THREAD_SUCCESS: 'GET_ORIGINAL_CONVERSATION_THREAD_SUCCESS',
    GET_ORIGINAL_CONVERSATION_THREAD_FAILURE: 'GET_ORIGINAL_CONVERSATION_THREAD_FAILURE',

    GET_FULL_EMAIL_CONVERSATION_REQUEST: 'GET_FULL_EMAIL_CONVERSATION_REQUEST',
    GET_FULL_EMAIL_CONVERSATION_SUCCESS: 'GET_FULL_EMAIL_CONVERSATION_SUCCESS',
    GET_FULL_EMAIL_CONVERSATION_FAILURE: 'GET_FULL_EMAIL_CONVERSATION_FAILURE',
    CLEAR_CONVERSATION: 'CLEAR_CONVERSATION',
};

export const messageAuthorConstants = {
    GET_MESSAGE_AUTHOR_REQUEST: 'GET_MESSAGE_AUTHOR_REQUEST',
    GET_MESSAGE_AUTHOR_SUCCESS: 'GET_MESSAGE_AUTHOR_SUCCESS',
    GET_MESSAGE_AUTHOR_FAILURE: 'GET_MESSAGE_AUTHOR_FAILURE',
};

export const downloadAttachmentConstants = {
    DOWNLOAD_ATTACHMENT_REQUEST: 'DOWNLOAD_ATTACHMENT_REQUEST',
    DOWNLOAD_ATTACHMENT_SUCCESS: 'DOWNLOAD_ATTACHMENT_SUCCESS',
    DOWNLOAD_ATTACHMENT_FAILURE: 'DOWNLOAD_ATTACHMENT_FAILURE',
};

export const adminSearchConstants = {
    ADMIN_SEARCH_REQUEST: 'ADMIN_SEARCH_REQUEST',
    DELETE_VISITED_PAGES: 'DELETE_VISITED_PAGES',
    ADMIN_SEARCH_SUCCESS: 'ADMIN_SEARCH_SUCCESS',
    ADMIN_SEARCH_FAILURE: 'ADMIN_SEARCH_FAILURE',

    EXPORT_ADMIN_SEARCH_REQUEST: 'EXPORT_ADMIN_SEARCH_REQUEST',
    EXPORT_ADMIN_SEARCH_SUCCESS: 'EXPORT_ADMIN_SEARCH_SUCCESS',
    EXPORT_ADMIN_SEARCH_FAILURE: 'EXPORT_ADMIN_SEARCH_FAILURE',

    BLACKLIST_ADMIN_SEARCH_MESSAGE_REQUEST: 'BLACKLIST_ADMIN_SEARCH_MESSAGE_REQUEST',
    BLACKLIST_ADMIN_SEARCH_MESSAGE_SUCCESS: 'BLACKLIST_ADMIN_SEARCH_MESSAGE_SUCCESS',
    BLACKLIST_ADMIN_SEARCH_MESSAGE_FAILURE: 'BLACKLIST_ADMIN_SEARCH_MESSAGE_FAILURE',

    BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_REQUEST: 'BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_REQUEST',
    BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_SUCCESS: 'BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_SUCCESS',
    BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_FAILURE: 'BLACKLIST_ALL_ADMIN_SEARCH_MESSAGE_FAILURE',

    FIND_AND_REPLACE_TEXT_REQUEST: 'FIND_AND_REPLACE_TEXT_REQUEST',
    FIND_AND_REPLACE_TEXT_SUCCESS: 'FIND_AND_REPLACE_TEXT_SUCCESS',
    FIND_AND_REPLACE_TEXT_FAILURE: 'FIND_AND_REPLACE_TEXT_FAILURE',
};

export const paidMessageConstants = {
    PAID_MESSAGE_ACTION_REQUEST: 'PAID_MESSAGE_ACTION_REQUEST',
    PAID_MESSAGE_ACTION_SUCCESS: 'PAID_MESSAGE_ACTION_SUCCESS',
    PAID_MESSAGE_ACTION_FAILURE: 'PAID_MESSAGE_ACTION_FAILURE',
};

export const undoMessageConstants = {
    UNDO_MESSAGE_REQUEST: 'UNDO_MESSAGE_REQUEST',
    UNDO_MESSAGE_SUCCESS: 'UNDO_MESSAGE_SUCCESS',
    UNDO_MESSAGE_FAILURE: 'UNDO_MESSAGE_FAILURE',
};

export const messageStatusConstants = {
    MESSAGE_STATUS_REQUEST: 'MESSAGE_STATUS_REQUEST',
    MESSAGE_STATUS_SUCCESS: 'MESSAGE_STATUS_SUCCESS',
    MESSAGE_STATUS_FAILURE: 'MESSAGE_STATUS_FAILURE',
};

export const ticketThreadConstants = {
    TICKET_THREAD_REQUEST: 'TICKET_THREAD_REQUEST',
    TICKET_THREAD_SUCCESS: 'TICKET_THREAD_SUCCESS',
    TICKET_THREAD_FAILURE: 'TICKET_THREAD_FAILURE',
};

export const LOCK_DURATION_IN_SECS = 1800; // 30 Minutes
export const LOCK_EMAIL_DURATION_IN_SECS = 1800; // 30 Minutes
export const TOTAL_ROUTING_DURATION_IN_SECS = 1800; // 30 Minutes
export const EMAIL_DURATION_IN_SECS = 14400; // 4 Hours
export const NON_EMAIL_DURATION_IN_SECS = 5400; // 90 Minutes

export const platformConstants = {
    TEAMS : 'teams-only',
    TRENDS : 'trends'
};

export const responseTimeConstants = {
    GET_MESSAGES_RESPONSE_TIMES_REQUEST: 'GET_MESSAGES_RESPONSE_TIMES_REQUEST',
    GET_MESSAGES_RESPONSE_TIMES_SUCCESS: 'GET_MESSAGES_RESPONSE_TIMES_SUCCESS',
    GET_MESSAGES_RESPONSE_TIMES_FAILURE: 'GET_MESSAGES_RESPONSE_TIMES_FAILURE'
}

export const actionMessagesConstants = {
    ACTION_MESSAGE_REQUEST: 'ACTION_MESSAGE_REQUEST',
    ACTION_MESSAGE_SUCCESS: 'ACTION_MESSAGE_SUCCESS',
    ACTION_MESSAGE_FAILURE: 'ACTION_MESSAGE_FAILURE',
};

export const messageDirection = {
    INCOMING: 'INCOMING',
    OUTGOING: 'OUTGOING'
}