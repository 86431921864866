import React from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import styles from "../organizationMainStyles";
import {getAccessibilityMode, getLabel} from "../Utilities/organizationSettingsUtils";
import useGetOrganizationInfo from "../hooks/useGetOrganizationInfo";
import WeeklyEmailMetricsToggleContainer from "../Utilities/Notifications/WeeklyEmailMetricsToggleContainer";
import EscalationEmailToggleContainer from "../Utilities/Notifications/EscalationEmailToggleContainer";
import GorgiasRevenueTrackingToggleContainer from "../Utilities/Notifications/GorgiasRevenueTrackingToggleContainer";
import ShopifyRevenueTrackingToggleContainer from "../Utilities/Notifications/ShopifyRevenueTrackingToggleContainer";
import {fragmentId} from "../Utilities/organizationSettingsConstants";
import NoDataView from "../EmptyStates/NoDataView";
import NotificationsEmptyState from "../EmptyStates/NotificationsEmptyState";
import PresaleRemainderToggleContainer from "../Utilities/Notifications/PresaleRemainderToggleContainer";

export default function Notifications({organizationId}) {
    const label = getLabel(fragmentId.NOTIFICATIONS);
    const {organizationInfo, loading} = useGetOrganizationInfo(organizationId);
    const mode = getAccessibilityMode();
    const organization = _.get(organizationInfo, 'organization');

    if(_.isEmpty(organizationId) && _.isEmpty(organizationInfo)) {
        return <NoDataView />
    }

    if(loading) {
        return <NotificationsEmptyState/>
    }

    return(
        <Box sx={styles.sectionWrapper}>
            <WeeklyEmailMetricsToggleContainer
                mode={mode}
                organization={organization}
                label={label}
            />
            <Box sx={styles.mt10}>
                <ShopifyRevenueTrackingToggleContainer
                    mode={mode}
                    organization={organization}
                    label={label}
                />
            </Box>
            <Box sx={styles.mt10}>
                <GorgiasRevenueTrackingToggleContainer
                    mode={mode}
                    organization={organization}
                    label={label}
                />
            </Box>
            <Box sx={styles.mt10}>
                <EscalationEmailToggleContainer
                    mode={mode}
                    organization={organization}
                    label={label}
                />
            </Box>
            <Box sx={styles.mt10}>
                <PresaleRemainderToggleContainer
                    mode={mode}
                    organization={organization}
                    label={label}
                />
            </Box>
        </Box>
    )
}