import React, {Component} from "react";
import {Button} from "react-bootstrap";
import "components/Message/messages.css";

/**
 * @author Ogunyale Richard Kehinde <kehinde@chatdesk.com>
 */
class SearchInput extends Component {
    render() {
        const { searchString, onFormFieldChange, searchAdminMessages, searchOnKeyEnter, loading } = this.props;
        return (
            <div className="search-messages-table">
                <div style ={{width:'410px'}}>
                    <input
                        type="text"
                        autoFocus
                        placeholder="Search"
                        className="search-input"
                        name="searchString"
                        value={searchString}
                        onChange={(event)=>onFormFieldChange(event)}
                        onKeyDown={searchOnKeyEnter}
                        required/>
                </div>
                <div style={{paddingTop:'15px'}}>
                    <Button
                        onClick={searchAdminMessages}
                        className="btn btn-success font-15"
                        type="submit"
                    >{loading ? 'Searching...':'Search'}
                    </Button>
                </div>
            </div>
        )
    }
}

export default SearchInput