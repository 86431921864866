import React, {useState} from "react";
import {Box} from "@mui/system";
import htmlParser from "he";
import _ from "lodash";
import styles from "./templateStyles";
import ActionButton from "../../../common/ActionButton";
import {removeItemFromArray} from "_helpers";

export default function UseReplyButton ({item, index, replies, handleReplies, selectedReplies}) {
    const [isSelected, setIsSelected] = useState(false);

    const isAdded = (value) => {
        return !_.isEmpty(_.find(selectedReplies, value));
    }

    const addReply = (value) => {
        selectedReplies.push(value);
        setIsSelected(true);
        handleReplies(selectedReplies);
    }

    const removeReply = (value) => {
        const replyArr = removeItemFromArray(selectedReplies, value);
        setIsSelected(false);
        handleReplies(replyArr);
    }

    const handleReply = (value) => {
        const added = isAdded(value);
        if(!added) {
            addReply(value);
        }
        else removeReply(value);
    }

    return (
        <Box component={'li'} sx={styles.replyItem}>
            <Box sx={styles.replyItemTitle}>
                { `Reply ${index + 1} of ${replies.length}` }
            </Box>
            <Box sx={styles.replyItemBody}>
                { htmlParser.decode(item.text) }
            </Box>
            <Box sx={styles.replyButtonContainer}>
                <ActionButton
                    label={ !isSelected ? 'Use' : 'Remove'}
                    action={() => { handleReply(item) }}
                    buttonBordered={!isSelected}
                    useBorderColor={'#0C2728'}
                />
            </Box>
        </Box>
    )
}