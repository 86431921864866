import {Box} from "@mui/system";
import _ from "lodash";
import useGetAgentWallet from "../../../Utilities/hooks/useGetAgentWallet";
import CurrentBalanceEmptyState from "./CurrentBalanceEmptyState";
import styles from "./currentBalanceStyles";

export default function CurrentBalance() {
    const {walletObj, loadingWallet} = useGetAgentWallet();
    const balance = _.get(walletObj, 'wallet.balance');

    if(loadingWallet) return <CurrentBalanceEmptyState />;

    return(
        <Box sx={styles.container}>
            <Box sx={styles.headingText}>
                CURRENT BALANCE
            </Box>
            <Box sx={styles.balanceText}>
                ${balance}
            </Box>
        </Box>
    )
}