import React from "react";

export default function() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const performAction = () => {
        handleOpen();
    };

    return {
        handleOpenPopup: performAction,
        openPopup: open,
        handleClosePopup: handleClose
    };
}