import React, {Fragment} from "react";
import KustomerSetupModalForm from "./KustomerSetupModalForm";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";

export default function KustomerSetupModal({
	open,
	handleClose,
	handleOpen
}){
  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Kustomer'}>

        <KustomerSetupModalForm
          description={'Please copy and paste your Kustomer credentials here:'}
          buttonLabel={'Submit'}
          openInNewTab={true}
				  extraInfoLabel={'View setup instructions'}
          handleClose={handleClose} />
      </BasicModal>
    </Fragment>
  )
}