import React from 'react'
import 'components/css/dot-loading.css'

export function DotLoading() {
    return (
        <div className="col-3 padding-left-15">
            <div className="stage">
                <div className="dot-flashing"></div>
            </div>
        </div>
    )
};
