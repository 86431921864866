import {Fragment} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import EditorTagChip from "./EditorTagChip";

export default function EditorTag({selectedTags, handleRemoveTag}) {

    const hasTags = () => {
        selectedTags = selectedTags || [];
        return selectedTags.length > 0;
    }

    const Content = ({selectedTags}) => {
        return selectedTags.map( (tag, index) => {
            return (
                <EditorTagChip
                    key={index}
                    tag={_.get(tag, 'name')}
                    handleDeselect={handleRemoveTag}
                />
            )
        })
    }

    return(
        <Fragment>
            {
                hasTags() &&
                <Box sx={styles.tagsContainer}>
                    <Content selectedTags={selectedTags} />
                </Box>
            }
        </Fragment>
    )
}

const styles = {
    tagsContainer: {display: 'flex', flexWrap: 'wrap', mb: '7px'}
}