import { Fragment } from 'react'
import { Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import importedStyles from '../Utilities/styles'
import DashboardTable, { DashboardTableRow, DashboardTableCell } from '../Utilities/DashboardTable';
import Popover from '../Utilities/Popover';

export default function ExpertInfoSection({ data }) {
	return (
		<Fragment>
			<Box component={'h2'} sx={importedStyles.headingSmall}>
				Your support team performance for all channels
			</Box>

			<DashboardTable
				label={'Your support team performance for all channels'}
				headLabels={['Expert', 'Tickets solved']}
				sx={{ maxWidth: 360 }}>

				{data && data.length ? data.map((entry, key) => (
					<DashboardTableRow key={key}>
						<DashboardTableCell>
							{entry.expertName}
						</DashboardTableCell>
						<DashboardTableCell align='right' sx={styles.cellWithIcon}>
							{entry.volumeTicketsHandled}
							{entry.sentForReTraining ? (
								<Popover 
									id={`expert-sentForReTraining-infoPopover-${key}`}
									ButtonComponent={Box}
									ButtonProps={{ component: 'button', sx: styles.buttonSentRetraining }}
									ButtonChildren={<ErrorIcon sx={styles.buttonSentRetrainingIcon} />}
								>
									<Box component={'p'} sx={styles.popoverSentRetrainingTitle}>Expert performance</Box>
									<Box component={'p'} sx={styles.popoverSentRetrainingBody}>This Expert has been sent for re-training</Box>
								</Popover>
							) : null}
						</DashboardTableCell>
					</DashboardTableRow>
				)) : null}
			</DashboardTable>

			{/*@TODO: Add description when needed*/}
			{/*<Box sx={styles.info}>*/}
			{/*	Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
			{/*</Box>*/}
		</Fragment>
	)
}

const styles = {
	cellWithIcon: {
		position: 'relative',
		paddingRight: '32px!important',
	},
	buttonSentRetraining: {
		position: 'absolute',
		right: '0',
		top: 'calc(50% - calc(16px / 2))',
		appearance: 'none',
		backgroundColor: 'transparent',
		border: 'none',
		cursor: 'pointer',
		p: '8px',
		my: '-8px',
		height: 'calc(16px + 8px * 2)', // 8px*2 = padding
		width: 'calc(16px + 8px * 2)', // 8px*2 = padding
	},
	buttonSentRetrainingIcon: {
		fontSize: '16px',
	},
	popoverSentRetrainingTitle: {
		fontSize: '12px',
		color: '#6D7D7E',
	},
	popoverSentRetrainingBody: {
		m: 0,
	},

	info: {
		mt: 2,
	}
}