import { employmentConstants } from '_constants';

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 03/10/2018 : 3:06 PM
 */

const INITIAL_STATE = {
    employments: [],
    employmentMetrics: [],
    userEmployments: [],
    orgEmployments: [],
    employment: {},
    organizationScores: [],
    loadingOrgScores: true,
    organizationWithMessages: [],
    loadingOrgWithMsg: false,
    loading: true,
    metricsLoading: true,
    error: '',
    orgTeamLead: {},
    orgCsm: {},
    loadingOrgCsm: true,
    loadingOrgTeamLead: true,
    removeEmployment: {},
    removeEmploymentLoading: false,
    conversationStats: {},
    conversationStatsLoading: true,
    conversationStatsError: '',
    loadingEmploymentUpdate: false
};

export function employmentsReducer(state = INITIAL_STATE, action) {
    /**
     * TODO: Refactor case statements to remove
     * TODO: repetitions by putting cases that
     * TODO: result to same return together and
     * TODO: having a single return.
     *
     * E.G.
     * case A
     * case B
     * case C
     *      return {..}
     *
     */
    switch (action.type) {
        case employmentConstants.GET_ALL_REQUEST:
            return {...state, loading: true};
        case employmentConstants.GET_ALL_SUCCESS:
            return {...state, employments: action.employments, loading: false};
        case employmentConstants.GET_ALL_FAILURE:
            return {...state, error: action.error, loading: false};

        case employmentConstants.GET_ORG_EMPLOYMENTS_REQUEST:
            return {...state, loading: true};
        case employmentConstants.GET_ORG_EMPLOYMENTS_SUCCESS:
            return {...state, orgEmployments: action.employments, loading: false};
        case employmentConstants.GET_ORG_EMPLOYMENTS_FAILURE:
            return {...state, error: action.error, loading: false};

        case employmentConstants.GET_USER_EMPLOYMENTS_REQUEST:
            return {...state, loading: true};
        case employmentConstants.GET_USER_EMPLOYMENTS_SUCCESS:
            return {...state, userEmployments: action.employments, loading: false};
        case employmentConstants.GET_USER_EMPLOYMENTS_FAILURE:
            return {...state, error: action.error, loading: false};

        case employmentConstants.GET_DETAILS_REQUEST:
            return {...state, loading: true};
        case employmentConstants.GET_DETAILS_SUCCESS:
            return {...state, employment: action.payload, loading: false};
        case employmentConstants.GET_DETAILS_FAILURE:
            return {...state, error: action.payload, loading: false};


        case employmentConstants.SAVE_EMPLOYMENT_REQUEST:
            return {...state, loading: true, loadingEmploymentUpdate: true};
        case employmentConstants.SAVE_EMPLOYMENT_SUCCESS:
            return {...state, employment: action.employment, loading: false, loadingEmploymentUpdate: false};
        case employmentConstants.SAVE_EMPLOYMENT_FAILURE:
            return {...state, error: action.payload, loading: false, loadingEmploymentUpdate: false};

        case employmentConstants.REMOVE_EMPLOYMENT_REQUEST:
            return {...state, removeEmploymentLoading: true};
        case employmentConstants.REMOVE_EMPLOYMENT_SUCCESS:
            return {...state, removeEmployment: action.payload, removeEmploymentLoading: false};
        case employmentConstants.REMOVE_EMPLOYMENT_FAILURE:
            return {...state, error: action.payload, removeEmploymentLoading: false};


        case employmentConstants.DELETE_REQUEST:
            // add 'deleting:true' property to employment being deleted
            return {
                ...state,
                items: state.items.map(employment =>
                    employment.id === action.id
                        ? {...employment, deleting: true}
                        : employment
                )
            };
        case employmentConstants.DELETE_SUCCESS:
            // remove deleted employment from state
            return {
                items: state.items.filter(employment => employment.id !== action.id)
            };
        case employmentConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to employment
            return {
                ...state,
                items: state.items.map(employment => {
                    if (employment.id === action.id) {
                        // make copy of employment without 'deleting:true' property
                        const {...employmentCopy} = employment;
                        // return copy of employment with 'deleteError:[error]' property
                        return {...employmentCopy, deleteError: action.error};
                    }

                    return employment;
                })
            };


        case employmentConstants.GET_ORG_SCORE_REQUEST:
            return {...state, loadingOrgScores: true};
        case employmentConstants.GET_ORG_SCORE_SUCCESS:
            return {...state, organizationScores: action.payload, loadingOrgScores: false};
        case employmentConstants.GET_ORG_SCORE_FAILURE:
            return {...state, error: action.payload, loadingOrgScores: false};

        case employmentConstants.GET_ORG_WITH_MESSAGE_REQUEST:
            return {...state, loadingOrgWithMsg: true};
        case employmentConstants.GET_ORG_WITH_MESSAGE_SUCCESS:
            return {...state, organizationWithMessages: action.payload, loadingOrgWithMsg: false};
        case employmentConstants.GET_ORG_WITH_MESSAGE_FAILURE:
            return {...state, error: action.payload, loadingOrgWithMsg: false};

        case employmentConstants.GET_ORG_TEAM_LEAD_REQUEST:
            return {...state, loadingOrgTeamLead: true}
        case employmentConstants.GET_ORG_TEAM_LEAD_SUCCESS:
            return {...state, loadingOrgTeamLead: false, orgTeamLead: action.payload}
        case employmentConstants.GET_ORG_TEAM_LEAD_FAILURE:
            return {...state, error:action.payload, loadingOrgTeamLead: false};

        case employmentConstants.GET_ORG_CSM_REQUEST:
            return {...state, loadingOrgCsm: true}
        case employmentConstants.GET_ORG_CSM_SUCCESS:
            return {...state, loadingOrgCsm: false, orgCsm: action.payload}
        case employmentConstants.GET_ORG_CSM_FAILURE:
            return {...state, error:action.payload, loadingOrgCsm: false};

        case employmentConstants.GET_EMPLOYMENT_METRICS_REQUEST:
            return {...state, metricsLoading: true}
        case employmentConstants.GET_EMPLOYMENT_METRICS_SUCCESS:
            return {...state, metricsLoading: false, employmentMetrics: action.payload}
        case employmentConstants.GET_EMPLOYMENT_METRICS_FAILURE:
            return {...state, error:action.payload, metricsLoading: false};

        case employmentConstants.GET_CONVERSATION_STATS_REQUEST:
            return {...state, conversationStatsLoading: true}
        case employmentConstants.GET_CONVERSATION_STATS_SUCCESS:
            return {...state, conversationStatsLoading: false, conversationStats: action.payload}
        case employmentConstants.GET_CONVERSATION_STATS_FAILURE:
            return {...state, conversationStatsError: action.payload, conversationStatsLoading: false};

        default:
            return state
    }
}