import {auth0Constants} from "_constants";

/**
 * @author Chris Okebata
 */

const INITIAL_STATE = {
  userProfile: [],
  newProfile: [],
  loading: false,
  logoutLoading: true,
  profileLoading: false,
  error: '',
};

export function auth0Reducer(state = INITIAL_STATE, action) {

  switch (action.type) {

    case auth0Constants.AUTHENTICATE_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case auth0Constants.AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        loading: false
      };
    case auth0Constants.AUTHENTICATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };


    case auth0Constants.LOGOUT_USER_REQUEST:
      return {
        ...state,
        logoutLoading: true
      };
    case auth0Constants.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        logoutLoading: false
      };
    case auth0Constants.LOGOUT_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        logoutLoading: false
      };


    case auth0Constants.CREATE_PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true
      };
    case auth0Constants.CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        newProfile: action.payload,
      };
    case auth0Constants.CREATE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        profileLoading: false
      };

    default:
      return state
  }
}