
export default {
    root: {
        backgroundColor: '#EFEAE7',
        pt: '10px',
        minHeight: '100vh',
        letterSpacing: '-0.01em',
    },
    container: {
        px: { xs: 2, sm: 4, md: 4, lg: 4 },
        maxWidth: '1500px',
        mx: 'auto',
        position: 'relative',
    },
    contentArea: {
        backgroundColor: '#FFF',
        border: '1px solid #CED4D4',
        borderRadius: '5px',
    },
    rootPanel: {
        borderBottom: '1px solid #CED4D4'
    },
    conversationContainer: {
        height: 'calc(100vh - 200px)',
        p: {
            xs: '18px 10px 10px 20px',
            sm: '18px 10px 10px 20px',
            md: '18px 2px 60px 20px',
            lg: '18px 2px 60px 20px'
        },
        borderRight: {
            md: '1px solid #CED4D4',
            lg: '1px solid #CED4D4'
        },
    },
    conversationContainerWithDm: {
        height: 'calc(100vh - 260px)'
    },
    conversationPanelRoot: {
        height: 'calc(100vh - 218px)',
    },
    conversationPanelRootWithDM: {
        height: 'calc(100vh - 278px)',
    },
    responsePanelRoot: {
        height: 'calc(100vh - 278px)',
    },
    searchResultPanelRoot: {
        height: 'calc(100vh - 278px)',
        // border: '1px solid red'
    },
    panelContent: {
        height: 'calc(100% - 37px)',
        marginTop: '12px',
    },
    responsePanelContentWithSearch: {
        height: 'calc(100% - 44px)',
        marginTop: '12px',
    },
    searchResultPanelContent: {
        height: 'calc(100% - 70px)',
        marginTop: '12px',
        // border: '1px solid blue'
    },
    searchResultPanelContentItem: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: '#DBDFDF',
        fontFamily: 'LarsseitRegular',
        // display: 'flex',
        '&:first-of-type': {borderTopLeftRadius: '5px', borderTopRightRadius: '5px', borderTopWidth: '1px'},
        '&:last-of-type': {borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'},
    },
    conversationScrollArea: { //@TODO: merge scrollArea styling
        overflowY: 'auto',
        height: '100%',
        padding: '0px 10px 0 0',
        // border: '1px solid'
    },
    responseScrollArea: { //@TODO: merge scrollArea styling
        overflowY: 'auto',
        height: '100%',
        padding: '0px 10px 0 0',
        // border: '1px solid'
    },
    searchResultScrollArea: {
        overflowY: 'auto',
        height: '100%',
    },
    responseContainer: {
        height: 'calc(100vh - 260px)',
        p: {
            xs: '17px 10px 60px 20px',
            sm: '17px 10px 60px 20px',
            md: '17px 10px 60px 12px',
            lg: '17px 10px 60px 12px'
        },
        borderTop: {
            xs: '1px solid #DCE2E3',
            sm: '1px solid #DCE2E3',
            md: '1px solid transparent',
            lg: '1px solid transparent'
        },
        // border: '1px solid'
    },
    searchedMessageConversationContainer: {
        height: 'calc(100vh - 260px)',
        p: {
            xs: '17px 10px 60px 20px',
            sm: '17px 10px 60px 20px',
            md: '17px 10px 60px 12px',
            lg: '17px 10px 60px 12px'
        },
        borderTop: {
            xs: '1px solid #DCE2E3',
            sm: '1px solid #DCE2E3',
            md: '1px solid transparent',
            lg: '1px solid transparent'
        },
        // border: '1px solid'
    },
    searchResultContainer: {
        height: 'calc(100vh - 260px)',
        p: {
            xs: '17px 20px 60px 20px',
            sm: '17px 20px 60px 20px',
            md: '17px 20px 60px 12px',
            lg: '17px 20px 60px 12px'
        },
        borderTop: {
            xs: '1px solid #DCE2E3',
            sm: '1px solid #DCE2E3',
            md: '1px solid transparent',
            lg: '1px solid transparent'
        },
        // border: '1px solid'
    },
    searchMessageConversationContainer: {
        height: 'calc(100vh - 260px)',
        p: {
            xs: '17px 20px 60px 20px',
            sm: '17px 20px 60px 20px',
            md: '17px 20px 60px 12px',
            lg: '17px 20px 60px 12px'
        },
        borderTop: {
            xs: '1px solid #DCE2E3',
            sm: '1px solid #DCE2E3',
            md: '1px solid transparent',
            lg: '1px solid transparent'
        },
        // border: '1px solid'
    },
    heading: {
        height: '25px',
        fontFamily: 'LarsseitMedium',
        fontSize: '18px',
        fontWeight: 500,
        color: '#0C2728',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        // border: '1px solid'
    },
    searchedResultConversationHeading: {
        // height: '25px',
        fontFamily: 'LarsseitMedium',
        fontSize: '18px',
        fontWeight: 500,
        color: '#0C2728',
        // overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        // border: '1px solid'
    },
    searchResultHeading: {
        height: '40px',
        // border: '1px solid',
        display: 'flex',
        justifyContent: 'space-between'
    },
    footerContainer: {
        padding: '15px 20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    cannotResolveButton: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    searchContainer: {
        borderTop: '1px solid #D0D9D9',
        padding: '10px 20px 10px 10px'
    },
    directMessageContainer: {
        borderTop: '1px solid #D0D9D9',
        padding: '10px 10px 10px 20px',
        borderRight: {
            xs: '1px solid transparent',
            sm: '1px solid transparent',
            md: '1px solid #D0D9D9',
            lg: '1px solid #D0D9D9',
        },
    }
}