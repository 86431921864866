import Box from "@mui/material/Box";
import React, {Fragment} from 'react';
import {Loading} from "../Loading";

export default function UploadsLoading({ isLoading }) {

  return(
    <Fragment>
      {
        isLoading &&
        <Box style={{margin: '10px 15px'}}>
          <Loading height={15} size={15} />
        </Box>
      }
    </Fragment>
  )
}