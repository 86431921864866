import {Box} from "@mui/system";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import {copyValueToClipboard} from "_actions";

export default function CopyAction({ textValue, tooltipLabel, toastLabel, sx }) {
    const copy = () => {
        copyValueToClipboard(textValue, toastLabel)
    }

    return(
        <Box>
            <Tooltip
                title={tooltipLabel}
                componentsProps={{
                    tooltip: { sx: styles.tooltip },
                }}>
                <IconButton size={'small'} onClick={copy}>
                    <FileCopyOutlinedIcon sx={{color:'#07857C', fontSize: '20px', ...sx}}/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

const styles = {
    tooltip: {
        backgroundColor: '#DBD8FF',
        color: '#0C2728',
        fontFamily: 'LarsseitMedium',
        fontSize: '13px',
    }
}