import {authHeader} from '_helpers';
import {SERVER_URL} from 'config';
import axios from "axios";

function getAll(params) {
    const endpoint = `${SERVER_URL}/api/organizations/resources/all`;
    return axios.get(endpoint,{
        params: params,
        headers: authHeader()
    })
}

function createResource(params) {
    const endpoint = `${SERVER_URL}/api/organizations/resources`;
    return axios.post(endpoint, params,{
        headers: authHeader()
    })
}

function getResource(id) {
    const endpoint = `${SERVER_URL}/api/organizations/resources/${id}`;
    return axios.get(endpoint, {
        headers: authHeader()
    });
}

function updateResource(params) {
    const {id, values} = params;
    const endpoint = `${SERVER_URL}/api/organizations/resources/${id}`;
    return axios.put(endpoint, values,{
        headers: authHeader()
    })
}

function getAllResourcesByOrganization(params) {
    const endpoint = `${SERVER_URL}/api/organizations/${params.organizationId}/resources/list`;
    return axios.get(endpoint, {
        params: params,
        headers: authHeader()
    });
}

export const companyResourcesService = {
    getAll,
    createResource,
    getResource,
    updateResource,
    getAllResourcesByOrganization
};