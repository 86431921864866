export class PerformanceMetricsBuilder {
  constructor() {
    this.metrics = {};
  }

  setResponsesLessThan90Minutes(responsesLessThan90Mins){
    this.metrics.responsesLessThan90Minutes = responsesLessThan90Mins;
    return this;
  }

  setAnswerRate(answerRate){
    this.metrics.answerRate = answerRate;
    return this;
  }

  setAnswerRateCompareToAvg(answerRateCompareToAvg){
    this.metrics.answerRateCompareToAvg = answerRateCompareToAvg;
    return this;
  }

  setCSAT(csat){
    this.metrics.csat = csat;
    return this;
  }

  setPreSaleQuestions(preSaleQuestions){
    this.metrics.preSaleQuestions = preSaleQuestions;
    return this;
  }

  build(){
    return this.metrics;
  }
}
