import htmlParser from "he";
import {personalizeResponse} from "./dataUtility";
import _ from "lodash";
import {isObjectEmpty} from "./components.helper";
import {
    handleType,
    msgSource, supportedHandleTypesForDisplayInApp,
    supportedSourcesForAttachmentDownload, supportedSourcesForDisplayInApp,
    supportedSourcesForMarkdown
} from "_constants";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import linkifyHtml from "linkify-html";
import dompurify from "dompurify";
import { Remarkable } from 'remarkable';

/**
 * Shortens a given string to an n-character long string and
 * adds ellipses at the end of the resulting string.
 * @example shorten('The quick brown fox jumps over the lazy dog', 19);  // The quick brown fox...
 * @param str
 * @param n: Length of the given string after it is shortened.
 * @returns {*}
 */
export const shortenString = (str, n) => {
    return ( str.match(RegExp('.{' + n + '}\\S*')) || [ str ] )[ 0 ];
};

/**
 * Given an imageId, this function generates and returns a
 * link to the photo in the s3 bucket where it is stored.
 * @param imageId
 * @returns {string}
 */
export const generatePhotoLink = (imageId) => {
  if(!imageId) return '';

  return imageId.includes('teams/') ?
      `https://chatdesk-assets.s3.amazonaws.com/${imageId}` :
      `https://chatdesk-assets.s3.amazonaws.com/teams/${imageId}`
};

export const getLogoUrl = (url) => {
  if(!url) return '';

  const bucketLink = 'https://chatdesk-assets.s3.amazonaws.com';
  const logoLink = `${bucketLink}/${url}`;

  if(_.get(window,'location.hostname') === 'teams.chatdesk.com'){
    return url.includes('organizationLogos/') ? logoLink :
      (url.includes('teams/') ? logoLink : `${bucketLink}/teams/${url}`)
  }
  return logoLink
};

export const getImageUrl = (url) => {
    if(!url) return '';
    const bucketLink = 'https://chatdesk-assets.s3.amazonaws.com';
    const imageLink = `${bucketLink}/${url}`;
    return imageLink
};

/**
 * Remove ? from emoji html entities
 * E.g. &#10084;? turns to &#10084;
 *      &#x2764;? turns to &#x2764;
 *      &#x263a;? turns to &#x263a;
 *
 * @param text
 * @returns {*}
 */
export const stripTrailingQuestionMarkFromHtmlEntity = (text) => {
    let pattern = /&#[0-9]+;[?]+|&#[a-z]+[0-9]+;[?]+|&#[a-z]+[0-9]+[a-z]+;[?]+/gi;
    text = _.toString(text).replace(pattern, (res) => {
        return _.toString(res).replace(/;[?]/gi, ';');
    });

    return text
};

/**
 * @param text {string}
 * @param sourceName {string}
 * @param isEmail {boolean}
 * @returns {*}
 */
export const parseHtml = (text, sourceName = '', isEmail= false, canContainHtml = false) => {
    text = prepareTextForRendering(text, sourceName, isEmail, canContainHtml);
    return ReactHtmlParser(text);
}

/**
 * This finds any occurrence of an img tag then appends an anchor tag to it
 * @param text
 * @returns {*}
 */
export const changeImgTagToLink = (text) => {
    if (!text) {
        return ''
    }
    text = _.toString(text);
    let pattern = /<img.*?src="(.*?)".*?>/gi;
    text = text.replace(pattern, (res) => {
      return res.replace(pattern, `<a href="${pattern.exec(res)[1]}" target="_blank" class="margin-right-10">View image</a>`);
    });

  return text
};

/**
 * This finds any occurrence of an img tag and resizes it for rendering
 * @param text
 * @param imageSize
 * @returns {string}
 */
export const resizeImgTag = (text, imageSize) => {
    if (!text) {
        return ''
    }
    text = _.toString(text);
    let pattern = /<img.*?src="(.*?)".*?>/gi;
    text = text.replace(pattern, (res) => {
      return res.replace(pattern, `<img src="${pattern.exec(res)[1]}" style="max-width: ${imageSize}"/>`);
    });

  return text
};

export const stripFigureTagFromHtml = (html) => {
  if(!html) return '';

  let strippedHtml = html;

  if (html.indexOf('<figure') !== -1) {
    strippedHtml = html.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
  }

  return strippedHtml;
};

/**
 * @param html {string}
 * @param tag {string}
 * @returns {*|string}
 */
export const stripTagFromHtml = (html, tag) => {
  if(!html || !tag) return html;
  let strippedHtml = html;
  html = _.toString(html);
  if (html.indexOf(tag) !== -1) {
    const expression = `(<\\/?)${tag}((?:\\s+.*?)?>)`;
    const regExp = new RegExp(expression, 'g');
    strippedHtml = html.replace(regExp, '');
  }
  return strippedHtml;
};

export const stripStyleFromHtml = (html) => {
  if(!html) return '';
  html = _.toString(html);
  let strippedHtml = html;

  if (html.indexOf('<style') !== -1) {
    strippedHtml = html.replace(/(<\/?)style((?:\s+.*?)?>)/g, '');
  }

  return strippedHtml;
};

export const stripInlineStyleFromHtml = (html) => {
  if(!html) return '';
  html = _.toString(html);
  let strippedHtml = html;
  if (html.indexOf('style=') !== -1) {
    strippedHtml = html.replace(/(style="([^"]*)")/g, '');
  }
  return strippedHtml;
};

export const stripTrailingStyling = (html) => {
  if(!html) return '';
  html = _.toString(html);
  let strippedHtml = html;
  if (html.indexOf('p{margin-top:0px; margin-bottom:0px;}') !== -1) {
    strippedHtml = html.replace(/(p{margin-top:0px; margin-bottom:0px;})/g, '');
  }
  return strippedHtml;
};

export const stripNbspAndNewLineFromHtml = (html) => {
  if(!html) return '';

  return html.replace(/<p>&nbsp;<\/p>/g, '<p><br></p>').replace(/&nbsp;/g, '').replace(/\\n/g, '');
};

export const stripHashTag = (text) => {
  if(!text) return '';

  return text.replace(/#/g, '');
};

export const pTagsToDoubleBr = (html, sourceName) => {
  if(!html) return '';

  let srcName = sourceName || '';

  if(supportedSourcesForMarkdown.includes(srcName.toLowerCase())){
    if (html.indexOf('<p') !== -1) {
      html = html.replace(/(<p)/im, '<div')
            .replace(/<\/p>/im, '</div>')
            .replace(/(<\/?)p((?:\s+.*?)?>)/g, '<br>');
    }
  }else{
    if (html.indexOf('<p') !== -1) {
      html = html.replace(/(<p)/im, '<div')
            .replace(/<\/p>/im, '</div>')
            .replace(/(<p)/igm, '<br').replace(/<\/p>/igm, '');
    }
  }

  return html;
};

export const processSpacing = (text) => {
    if(!text) return '';
    return text.replace(/\n|\\n/g, '<br>');
};

export const replaceHttp = (text) => {
    if(!text) return '';
    return text.replace(/<http:\/\//g, ' ')
        .replace(/<https:\/\//g, ' ');
};

/**
 * Returns a capital case of each word in a text
 * @param text
 * @returns {string}
 */
export const getCapitalizedWords = (text) => {
    if(!text)return '';

    return text.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};

/**
 * Remove numbers from a username
 * E.g. Chris Okebata (1234567890) to Chris Okebata
 * @param text
 * @returns {*}
 */
export const stripNumbersFromUsername = (text) => {
  if(!text){
    return '';
  }

  let pattern = /\([0-9]+\)|\(#[0-9]+\)/gi;
  text = text.replace(pattern, '');

  return text;
};

/**
 * Converts html tags to a text
 *
 * @param htmlTag
 * @returns {string}
 */
export const escapeHTML = (htmlTag) => {
    if(!htmlTag) return '';
    return htmlTag.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
};


/**
 * Removes extra characters from string
 * @param string
 * @returns {string}
 **/
export const removeChars = (string) => {
    if (string) {
        return string.replace(/ *\([^)]*\) */g, "")
    }
    return string
};

/**
 * This method parses text to display emoji
 * First, it unescapes the text (&amp;#129315; becomes &#129315; )
 * Next, it decodes it to emoji(&#129315; becomes 🤣)
 **/
export const parseEscapedText = (text) => {
    if (!text) {
        return ''
    }
    let escapedText = htmlParser.unescape(text);
    return htmlParser.decode(stripTrailingQuestionMarkFromHtmlEntity(escapedText));
};

/**
 * Display the conversation count when it is more than one message in the thread
 * @param value
 * @param displayInApp
 * @returns {boolean}
 */
export const canShowConversationCount = (value, displayInApp) => {
  if(!displayInApp) return false;
  if(typeof value !== 'number') return false;

  return value > 1;
};

/**
 * Filter an array of objects by key and value
 * @param objectArray
 * @param key
 * @param value
 * @returns {*}
 */
export const filterObjectValue = (objectArray, key, value) => {
  return objectArray.filter((object) => {
    return object[key] === value
  });
};

/**
 * Filter an array of objects by 2 keys and values
 * @param objectArray
 * @param key
 * @param value
 * @param key2
 * @param value2
 * @returns {*}
 */
export const filterObjectTwoValues = (objectArray, key, value, key2, value2) => {
  return objectArray.filter((object) => {
    if(!_.isEmpty(object[key])){
      return object[key] === value
    }else {
      return object[key2] === value2
    }
  });
};

/**
 * Format the response text to be customer specific
 * @param text
 * @param messageAuthor
 * @param agentName
 * @param messageHandleType
 * @returns {*}
 */
export const formattedResponseText = (text, messageAuthor, agentName, messageHandleType) => {
  if(!text) return '';

  const personalizedResponse = personalizeResponse(text, messageAuthor, agentName, messageHandleType);
  return htmlParser.decode(stripTrailingQuestionMarkFromHtmlEntity(personalizedResponse));
};

/**
 * This tests if a string is an email
 * @param email
 * @returns {boolean}
 */
export const testForEmail = (email) => {
  const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
  return re.test(email);
};

/**
 * Retrieve a channel name from a data-source handle
 * @param text
 * @returns {string}
 */
export const getMessageSource = (text) => {
  if(!text) return '';
  let pattern = /Facebook|Instagram|Twitter|Zendesk|Gorgias|Front|Helpscout|Freshdesk|Reamaze|LiveChat|Kustomer|Gladly|Service Cloud|TikTok|Shopify/gi;
  let result = pattern.exec(text);

  return result && result[0]
};

/**
 * This gets an array of properties for a given array of objects
 * @param objectArray
 * @param property
 * @param includeNull
 * @returns {Array}
 */
export const getArrayFromObjectArray = (objectArray, property, includeNull = false) => {
  let propertyArr = [];

  if(objectArray && objectArray.length > 0){
    objectArray.map(index => {
      if(!includeNull)
      if(isObjectEmpty(_.get(index, property))) return null;

      propertyArr.push(_.get(index, property));
      return propertyArr
    });
  }

  return propertyArr
};

/**
 * This converts a string ('true' or 'false') to boolean (true or false)
 * @param string
 * @returns {*}
 */
export const stringToBoolean = (string) => {
  if (!string) return 'undefined';
  if (typeof string === 'string') return (string === 'true');
  else return string
};

/**
 * Strip html tags from text
 * @param text
 * @returns {*}
 */
export const stripHtmlFromText = (text) => {
  if(!text) return '';
  const regex = /(<([^>]+)>)/ig;
  return text.replace(regex, '');
};

/**
 * This injects html elements to an element
 * @param html
 * @returns {{__html: string}}
 */
export const handleRawHtml = (html) => {
  return { __html: `${html}`}
};

/**
 * This injects an anchor tag to an element
 * @param attachment
 * @returns {{__html: string}}
 */
export const handleRawAnchorTag = (attachment) => {
  const {content_url, file_name} = attachment;
  return {__html: `<a href="${content_url}" target="_blank">${file_name}</a>`}
};

/**
 * This renders an attachment link
 * @param attachment
 * @returns {JSX.Element}
 */
export const handleAttachmentLink = (attachment) => {
  const {content_url, file_name} = attachment;
  return <a href={content_url} target="_blank">{file_name}</a>
};

/**
 * Download attachment button
 * @param attachment
 * @param params
 * @param downloadAttachment
 * @param downloadingAttachment
 * @returns {JSX.Element}
 */
export const handleAttachmentButton = (attachment, params, downloadAttachment, downloadingAttachment) => {
  const {content_url, file_name} = attachment;
  const { messageId } = params;
  return <div style={{display: 'flex'}}>
      <button type="button"
              className={'transparent-button-default'}
              disabled={downloadingAttachment}
              onClick={() => downloadAttachment(messageId, content_url, file_name)}>
        {file_name}
      </button>
  </div>
}

/**
 * Handles attachment conditional
 * @param attachment
 * @param params
 * @param downloadAttachment
 * @param downloadingAttachment
 * @returns {JSX.Element}
 */
export const handleAttachment = (attachment, params, downloadAttachment, downloadingAttachment) => {
  const { msgSource } = params;
  if(supportedSourcesForAttachmentDownload.includes(msgSource && msgSource.toLowerCase())){
    return handleAttachmentButton(attachment, params, downloadAttachment, downloadingAttachment);
  }
  return handleAttachmentLink(attachment);
}

/**
 * Retrieves an Array from the objectArray containing only the property
 * per each objectArray item that fulfill the conditions in the filters array...
 * Filter array follows the form: [['direction', 'INCOMING'],...], as individual
 * filter items that should be applied to the objectArray item, using index 0 as
 * a property of objectArray and index 1, as the desired value
 * @param objectArray
 * @param property
 * @param filters
 * @returns {Array}
 */
export const getArrayFromObjectArrayWithFilters = (objectArray, property, filters) => {
    let propertyArr = [];

    if(objectArray && objectArray.length > 0){
        objectArray.map(item => {
            let addItemToArray = true;
            if(isObjectEmpty(_.get(item, property))) return null;
            filters.forEach(filterItem => {
                if (_.get(filterItem, 0) && _.get(filterItem, 1)) {
                   if (_.get(item, filterItem[0]) !== _.get(filterItem, 1)) {
                       addItemToArray = false
                   }
                } else {
                    addItemToArray = false
                }
            });
            if (addItemToArray) propertyArr.push(_.get(item, property));
            return propertyArr
        });
    }

    return propertyArr
};

export const stripEmTagFromHtml = (html) => {
    if(!html) return '';
    html = _.toString(html);
    let strippedHtml = html;

    if (html.indexOf('<em') !== -1) {
        strippedHtml = html.replace(/(<\/?)em((?:\s+.*?)?>)/g, '');
    }

    return strippedHtml;
};

export const processText = (text, messageSource) => {

  switch (messageSource && messageSource.toString().toLowerCase()) {
    case msgSource.ZENDESK:
      return detectFollowupZendeskEmail(text);

    //Add more cases for subsequent message sources

    default:
      return [text]
  }

};

export const detectFollowupZendeskEmail = (text) => {
  if (!text) {
    return [""];
  }

  let textTemplate = "This is a follow-up to your previous request";
  let hashTagTextTemplate = "#[0-9]+";
  let hashTagPattern = new RegExp(hashTagTextTemplate, 'gi');
  let pattern = new RegExp(`${textTemplate} ${hashTagTextTemplate}`, 'gi');

  if (!pattern.test(text)) {
    return [text];
  }

  let textTemplateInitPos = text.indexOf(textTemplate);
  let textTemplateLength = textTemplate.length;
  let targetInitPos = textTemplateInitPos + textTemplateLength + 1;
  let hashTagExec = hashTagPattern.exec(text);
  let hashTagText = hashTagExec && hashTagExec[0];
  let hashTagTextLength = hashTagText && hashTagText.length;
  let result = text.search(hashTagPattern);

  return [text.slice(0, result), hashTagText, text.slice(targetInitPos + hashTagTextLength)];
};

export const sortResponseOptions = (respOptions) => {
    if(!_.isArray(respOptions) || !respOptions) return []
    return respOptions.sort(function (a, b){
        return a.rank - b.rank
    });

};
export const listOfResponseOptions = (respOptions, organizationName) => {
    const eligibleOrganizations = ["Teams Fashion Company", "Moo"]
    let responseOption
    if (eligibleOrganizations.includes(organizationName)){
        responseOption = sortResponseOptions(respOptions)
    } else{
        responseOption = respOptions
    }
    return responseOption
};

export const isValueEmpty = (value) => {
    return  value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
};

export const checkProperties = (obj, requiredProperties = []) => {
    const useRequired = !_.isEmpty(requiredProperties);
    const isRequiredValues = compareArrays(requiredProperties, Object.keys(obj));
    if(useRequired && !isRequiredValues) return false;

    for (const key in obj) {
        if(useRequired) {
            if(isRequiredValues && isValueEmpty(obj[key])) return false;
        }
        else {
            if(isValueEmpty(obj[key])) return false;
        }
    }
    return true;
};

/**
 * Check if all the values of `arrayToCompare` are found in `baseArray`
 * @param baseArray {array}
 * @param arrayToCompare {array}
 * @returns {*}
 */
const compareArrays = (baseArray, arrayToCompare) => {
    return baseArray.every( r => arrayToCompare.includes(r))
}

export function getObjectProperty(obj, prop) {
    return obj[prop];
}

export function setObjectProperty (obj, prop, value) {
    if (_.isEmpty(prop)) return;
    obj[prop] = value;
}

/**
 * @param handleTypeName {string}
 * @returns {boolean}
 */
export function isTypeEmail(handleTypeName) {
    return [handleType.email, handleType.proactive_email].includes(handleTypeName);
}

export function parseMarkdown(text, canContainHtml = false) {
    const markdown = new Remarkable({html: canContainHtml});
    return markdown.render(text);
}

export function formatText(text, sourceName, isMacro = false) {
    if(isMacro && supportedSourcesForMarkdown.includes(_.toString(sourceName).toLowerCase())) {
        text = parseMarkdown(text);
    }
    return pTagsToDoubleBr(text, sourceName)
}

/**
 * @param text {string}
 * @param sourceName {string}
 * @param isEmail {boolean}
 * @param canContainHtml {boolean}
 * @returns {*}
 */
function prepareTextForRendering(text, sourceName, isEmail, canContainHtml = false) {
    if(supportedSourcesForMarkdown.includes(_.toString(sourceName).toLowerCase())) return parseMarkdown(text, canContainHtml);
    if(sanitizeTextForDixa(sourceName)) {
        text = formatDixaText(text);
    }
    text = stripTrailingStyling(text);
    if(isEmail) return text;
    text = stripTrailingStyling(text);
    text = stripInlineStyleFromHtml(text, sourceName);
    text = stripTrailingQuestionMarkFromHtmlEntity(text);
    text = parseEscapedText(text);
    text = processSpacing(text);
    text = replaceHttp(text);
    text = dompurify.sanitize(text);
    text = linkifyHtml(text);
    return changeImgTagToLink(text);
}

/**
 * @param url {string}
 * @returns {string}
 */
export function prependHttp(url) {
    const parseUrl = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    return parseUrl(url);
}

export function shouldDisplayInApp(msgSource, handleType) {
    if(!msgSource || !handleType) return true;
    return supportedSourcesForDisplayInApp.includes(msgSource) &&
        supportedHandleTypesForDisplayInApp.includes(handleType);
}

export function parseHtmlWithImage(text, imageSize = '100%') {
    if(!text) return '';
    return ReactHtmlParser(resizeImgTag(text, imageSize));
}

export function isValidJson(input) {
    try {
        JSON.parse(input);
    } catch (e) {
        return false;
    }
    return true;
}

export function getCurrencySymbol(currency) {
    if(currency === 'USD') {
        return '$'
    }
    return currency;
}

export function sanitizeTextForDixa(sourceName) {
    return sourceName === msgSource.DIXA
}

function removePattern(input) {
    if(!input) return input;
    const urlPattern = /\(https?:\/\/[^\)]+\)/g;
    return input.replace(urlPattern, '');
}

function wrapLinkInAnchorTag(input) {
    if (!input) return input;
    const urlPattern = /https?:\/\/[^\s\)\]]+/g;

    return input.replace(urlPattern, (url) => {
        // Clean up the URL by removing any trailing closing parentheses or brackets
        const cleanedUrl = url.replace(/[\)\]]+$/, '');
        return `<a href="${cleanedUrl}" target="_blank" rel="noopener noreferrer">view link</a>`;
    });
}

export function formatDixaText(input) {
    input = removePattern(input);
    input = wrapLinkInAnchorTag(input);
    return input
}

export function cleanResponseText(text, sourceName) {
    if(!text || !sourceName) return text;
    if(_.toString(sourceName).toLowerCase() === msgSource.DIXA) {
        return formatDixaResponseText(text);
    }
    return text;
}

export function formatDixaResponseText(text) {
    if(!text) return text;
    text = text.replace(/<p><br\s*\/?><\/p>/gi, '<br>');
    text = text.replace(/<p>/gi, '');
    text = text.replace(/<\/p>/gi, '<br>');
    return text.replace(/<br>$/, '');
}