import React from "react";
import {Box} from "@mui/system";
import styles from "../../../voteMainStyles";
import ActionButton from "../../../../../common/ActionButton";
import CannotResolveModal from "./CannotResolveModal";
import useModalSetup from "../../../../../hooks/useModalSetup";

export default function CannotResolve({ isScrollComplete }) {
    const {handleOpenModal, handleCloseModal, openModal} = useModalSetup();

    return(
        <Box sx={{marginLeft: '10px'}}>
            <ActionButton
                label={'Cannot resolve'}
                action={handleOpenModal}
                disabled={!isScrollComplete}
                withIcon={false}
                sx={styles.cannotResolveButton} />

            <CannotResolveModal
                title={'Cannot resolve'}
                open={openModal}
                handleClose={handleCloseModal}/>
        </Box>
    )
}