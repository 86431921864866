import React, {useState} from "react";
import {Button, Col, Grid, Modal, Row} from "react-bootstrap";
import {Link} from "@material-ui/core";
import moment from "moment";
import {chatdeskUpdatesService} from "../../../_services";

const AdminDisplayModal = ({companyUpdates, fromPage}) => {

    const [show, setShow] = useState(true)
    const handleClose = () => {
        if (fromPage) chatdeskUpdatesService.reduceDisplayCount(fromPage)
        setShow(false);
    }


    return (
        <Modal
            show={show}
            onHide={handleClose}
            className="modal-edit-message"
            hideFooter={false}
        >

            <Modal.Body className="container-fluid">
                <ModalContents
                    updates={companyUpdates}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={handleClose} className="btn btn-default">Cancel</Button>
            </Modal.Footer>

        </Modal>
    )
}

export const ModalContents = ({updates: {data}}) => {
    return (
        <div className="background-white centered with-border">
            <div className="container-fluid padding-top-30 padding-bottom-30">
                {data && data.map((update, index) => {
                    return (
                        <SingleContent
                            update={update}
                            index={index}
                            itemsCount={data.length}
                            key={index}
                        />
                    )
                })}
            </div>
        </div>
    )
}


const SingleContent = ({update, index, itemsCount}) => {

    const {summary, dateCreated, organization: {name}, googleDocsLink} = update
    const zone_name =  moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();
    return (
        <Grid className={index < itemsCount - 1 ? 'padding-bottom-30' : ''} style={{width: '100%'}}>
            <Row className="font-17 ">
                <Col className='pull-left'>
                    <span><span className="font-16-heavy">New Updates for {name}</span> - {summary}</span>
                    <div className="padding-top-15 font-italic"><em>Posted on {`${moment(dateCreated).format('MMM DD hh:mm A z ')} ${timezone}`}</em></div>
                </Col>

                <Col className='pull-right'>
                    <Link href={googleDocsLink ? googleDocsLink : "#"} target="_blank" className='pull-left'>
                        <Button className='btn btn-primary'>
                            Review new Updates
                        </Button>
                    </Link>

                </Col>
            </Row>
        </Grid>
    )
}

export {AdminDisplayModal as AdminUpdatesDisplayModal}