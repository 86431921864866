import React from 'react';
import {Col, Grid, Row} from 'react-bootstrap';
import { organizationActions, showDataSourceStatusActions} from "_actions";
import {FilterForm} from "components/DataSourceStatus";
import connect from "react-redux/es/connect/connect";
import {Loading} from "components/common";

import {DataSourcesStatusesTable} from "./DataSourceStatusDisplay";
import {publicAndFeaturedVisibility} from "../../_constants";



class ShowDataSourceStatus extends React.Component {


    handleOrgChange = (selectedOption) => {
        const organizationId = selectedOption['organization'].value
        this.props.dispatch(showDataSourceStatusActions.showDataSourceStatus(organizationId));
    };

    componentDidMount(){
        this.props.dispatch(organizationActions.getAll({visibility: publicAndFeaturedVisibility}));
        this.props.dispatch(showDataSourceStatusActions.showDataSourceStatus());
    }

    getOrganizations = (organizations) => {
        const companies = [];

        organizations.forEach((organization) => {
            const organizationData = {
                value: organization.id,
                label: organization.name
            }
            companies.push(organizationData);
        });
        return companies;
    };

    render() {
        const {showDataSourceStatusList, loading } = this.props;

        let companies;
        const {organizations} =  this.props;
        let finalOrgList = organizations.organizations? organizations.organizations : organizations;

        if (finalOrgList) {
            companies = this.getOrganizations(finalOrgList);
        }

        if ( loading ) {
            return (
                <div style = {{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '100px 0'}}>
                    <Loading/>
                </div>
            )
        }

        return (
            <Grid className='top-rel-100'
                  style={{ backgroundColor: 'white', border: 'solid 1px lightGrey', borderRadius: '1%' }}>
                <br/>
                <br/>
                <Row >
                    <Col md={12}>
                        <FilterForm
                            companies = {companies}
                            onChange={this.handleOrgChange}
                        />

                    </Col>
                </Row>
                <br/>
                <DataSourcesStatusesTable
                    dataSources={showDataSourceStatusList}
                />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
     const {
         showDataSourceStatus: { showDataSourceStatusList, error, loading } ,
             organizations: {  organizations }
     } = state;

    return {
        organizations: organizations,
        organizationsLoading: organizations && organizations.loading,
        showDataSourceStatusList: showDataSourceStatusList.data,
        dataSourcesLoading: loading,
        error: error
    };
}

const showDataSourceStatus = connect(mapStateToProps)(ShowDataSourceStatus);
export {showDataSourceStatus as ShowDataSourceStatus}
