import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '_reducers/rootReducer';

/**
 * Disable react-logger in production to prevent users from being able to view the logs
 */
let store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
    )
);

/**
 * Allow logging only in development
 * @type {Store<S>}
 */
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    const loggerMiddleware = createLogger();
    store = createStore(
        rootReducer,
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        )
    );
}

export { store }
