import React, {Fragment, useEffect} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {Field, reduxForm} from "redux-form";
import {getCurrentUser} from "_helpers";
import {msgSourceActions, zendeskOauthActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_URL} from "config";
import _ from "lodash";
import SubdomainInput from "../Utilities/SubdomainInput";
import {buttonContained} from "../../../Utilities/ButtonContained";
import BasicLink from "../../../Utilities/BasicLink";


function ZendeskSetupModalForm({description, placeholder, channelDomain, handleClose, buttonLabel, extraInfoLabel, openInNewTab, handleSubmit, callbackUrl = `${CLIENT_URL}/signup/add-channel`, type = null}){
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {ActionButton} = buttonContained;
  const msgSourceLink = useSelector((state) => state.msgSources.msgSourceLink.msgSourceLink);
  const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    dispatch(msgSourceActions.getMsgSourceLink('zendesk'));
  }, []);

  const performAction = (value) => {
    setDisabled(_.isEmpty(value));
  };

  const onSubmit = (props) => {
    const { id } = getCurrentUser();
    const params = { redirectUrl: callbackUrl, subdomain: props.subdomain, userId: id };
    if (type) {
      params.type = type;
    }
    dispatch(zendeskOauthActions.initOauth(params));
    handleClose();
  };

  return(
    <Fragment>
      <Box sx={isMobile ? {marginTop: '10px'} : {}}>
        <Box className={isMobile ? classes.descriptionMobile : classes.description}>
          {description}
        </Box>

        <form name="zendeskEmailForm" onSubmit={ handleSubmit(onSubmit) }>
          <Field
            name={'subdomain'}
            component={SubdomainInput}
            channelDomain={channelDomain}
            placeholder={placeholder}
            performAction={performAction}
          />
          <Box className={isMobile ? classes.buttonContainerMobile : classes.buttonContainer}>
            <Box className={isMobile ? classes.buttonLeftMobile : classes.buttonLeft }>
              { msgSourceLink && <BasicLink label={extraInfoLabel} link={msgSourceLink} openInNewTab={openInNewTab} /> }
            </Box>
            <Box className={isMobile ? classes.buttonRightMobile : classes.buttonRight}>
              <ActionButton label={buttonLabel} disabled={disabled} />
            </Box>
          </Box>
        </form>
      </Box>
    </Fragment>
  )
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttonContainerMobile: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  buttonLeft: {
    color: '#368C86',
    fontFamily: 'larsseitMedium',
    fontSize: '18px',
    lineHeight: '120%'
  },
  buttonLeftMobile: {
    marginRight: 'auto',
    color: '#368C86',
    fontFamily: 'larsseitMedium',
    fontSize: '18px',
    lineHeight: '120%'
  },
  buttonRight: {
    width: '160px'
  },
  buttonRightMobile: {
    marginTop: '40px',
    width: '100%'
  },
  description: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '18px',
    lineHeight: '120%'
  },
  descriptionMobile: {
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '16px',
    lineHeight: '120%',
  }
});

const formConfig = {
  form: 'zendeskEmailForm',
};

export default reduxForm(formConfig)(ZendeskSetupModalForm);