import styles from "../../organizationMainStyles";
import React, {useState, useEffect} from "react";
import {Box} from "@mui/system";
import {Grid, Switch, TextField} from "@mui/material";
import _ from "lodash";
import {useDispatch} from "react-redux";
import {autoActionActions, organizationSettingsActions} from "../../../../../../_actions";
import {accessibilityMode} from "../organizationSettingsConstants";
import BasicButton from "../../../../common/BasicButton";
import {HtmlTooltip} from "../../../../../common";
import {Typography} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import InformationTooltip from "../InformationTooltip";


export default function MessageResponseDetails({autoAction, organizationId, mode, label}) {
    const [isActive, setIsActive] = useState(autoAction.isActive);
    const [isSkipEnabled, setIsSkipEnabled] = useState(autoAction.isSkipEnabled);
    const [isAutoReplyEnabled, setIsAutoReplyEnabled] = useState(autoAction.isAutoReplyEnabled);
    const [topics, setTopics] = useState(autoAction.topics);
    const [autoReplyText, setAutoReplyText] = useState(autoAction.autoReplyText);
    const [isFirstResponseEnabled, setIsFirstResponseEnabled] = useState(autoAction.isFirstResponseEnabled);
    const [firstResponseThreshold, setFirstResponseThreshold] = useState(autoAction.firstResponseThreshold);
    const [firstResponseFooter, setFirstResponseFooter] = useState(autoAction.firstResponseFooter);

    const dispatch = useDispatch();

    const isEditMode = () => {
        return mode === accessibilityMode.EDIT;
    };
    const handleAutoActionToggle = (event) => {
        const value = _.get(event, 'target.checked');
        setIsActive(value);
    }

    const handleSkipToggle = (event) => {
        const value = _.get(event, 'target.checked');
        setIsSkipEnabled(value);
    }

    const handleAutoReplyToggle = (event) => {
        const value = _.get(event, 'target.checked');
        setIsAutoReplyEnabled(value);
    }

    const handleTopicsChange = (event, index) => {
        topics[index] = event.target.value;

        setTopics([...topics])
    }

    const handleAutoRepliesChange = (event, index) => {
        autoReplyText[index] = event.target.value;

        setAutoReplyText([...autoReplyText])
    }


    const addTopic = () => {
        setTopics([...topics, ""])
    }
    const addAutoReply = () => {
        setAutoReplyText([...autoReplyText, ""])
    }

    const handleFirstResponseToggle = (event) => {
        const value = _.get(event, 'target.checked');
        setIsFirstResponseEnabled(value);
    }

    const handleFirstResponseThresholdChange = (event) => {
        const value = _.get(event, 'target.value');
        setFirstResponseThreshold(value);
    }

    const handleFirstResponseFooterChange = (event) => {
        const value = _.get(event, 'target.value');
        setFirstResponseFooter(value);
    }

    const updateAutoAction = () => {
        const payload = {
            dataSourceHandleId: autoAction.dataSourceHandle.id,
            isActive: isActive,
            isSkipEnabled: isSkipEnabled,
            isAutoReplyEnabled: isAutoReplyEnabled,
            topics: topics,
            autoReplyText: autoReplyText,
            isFirstResponseEnabled: isFirstResponseEnabled,
            firstResponseThreshold: firstResponseThreshold,
            firstResponseFooter: firstResponseFooter
        }
        dispatch(autoActionActions.createOrUpdateAutoActionFromMessageResponse(payload)).then(() => {
            dispatch(organizationSettingsActions.refreshAutoActionsInfo(organizationId));
        });
    }

    const getFirstResponseThresholdError = () => {
        if (firstResponseThreshold === null || firstResponseThreshold === undefined || firstResponseThreshold === '') {
            return 'Minimum queue volume is required';
        }
        try {
            const number = parseInt(firstResponseThreshold);
            if (number < 0) {
                return 'Minimum queue volume must be greater than or equal to 0';
            }
        } catch (e) {
            return 'Minimum queue volume must be a number';
        }
        return null;
    }

    useEffect(() => {
        if(!autoAction.id) {
            setTopics([""])
            setAutoReplyText([""])
            setIsActive(true)
        }
    },[autoAction])

    return (
        <div>
            <Grid container>
                {autoAction.id &&
                    <React.Fragment>
                        <Grid item xs={4}>
                            {label.AUTO_ACTION}
                        </Grid>
                        <Grid item xs={8}>
                            <Switch disabled={!isEditMode()} checked={isActive} onChange={handleAutoActionToggle}
                                    sx={styles.switch}/>
                        </Grid>
                    </React.Fragment>
                }
                <Grid item xs={4}>
                    {label.SKIP_ENABLED}
                </Grid>
                <Grid item xs={8}>
                    <Switch disabled={!isEditMode()} checked={isSkipEnabled} onChange={handleSkipToggle}
                            sx={styles.switch}/>
                </Grid>
                <Grid item xs={4}>
                    {label.AUTO_REPLY_ENABLED}
                </Grid>
                <Grid item xs={8}>
                    <Switch disabled={!isEditMode()} checked={isAutoReplyEnabled} onChange={handleAutoReplyToggle}
                            sx={styles.switch}/>
                </Grid>
                <Grid item xs={4}>
                    {label.TOPICS}
                </Grid>
                <Grid item xs={8}>
                    {
                        topics.map((topic, index) =>
                            <div key={index}>
                                <TextField key={index} disabled={!isEditMode()}
                                           value={topic} onChange={(event) => {
                                    handleTopicsChange(event, index);
                                }}/>
                            </div>
                        )
                    }
                    {isEditMode() ?
                        <BasicButton disabled={!isEditMode()} label={"Add topic"}
                                     action={addTopic} style={{width: "200px", margin: "10px 0px"}}/>
                        : null}
                </Grid>
                <Grid item xs={4}>
                    {label.AUTO_REPLIES}
                </Grid>
                <Grid item xs={8}>
                    {
                        autoReplyText.map((topic, index) =>
                            <div key={index}>
                                <TextField key={index} disabled={!isEditMode()}
                                           value={topic} onChange={(event) => {
                                    handleAutoRepliesChange(event, index);
                                }}/>
                            </div>
                        )
                    }
                    {isEditMode() ?
                        <BasicButton disabled={!isEditMode()} label={"Add auto reply"}
                                     action={addAutoReply} style={{width: "200px", margin: "10px 0px"}}/>
                        : null}
                </Grid>
                <Grid item xs={4} sx={{margin: "10px 0px"}}>
                    {label.FIRST_RESPONSES}
                    <HtmlTooltip
                        title={
                            <div style={{minWidth: '250px', maxWidth: '450px', padding: '10px'}}>
                                <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                                    Enables an AI auto-responder to the first message
                                    in a given thread when the message queue is large.
                                    The AI auto-response will be based on the customer
                                    question.
                                </Typography>
                            </div>
                        }
                        placement={'bottom'}
                        style={{opacity: 1}}
                        enterDelay={700}
                    >
                        <Box sx={{display: 'inline-block', position: 'relative', top: '4px', ml: '5px'}}>
                            <InfoIcon sx={{fontSize: '18px'}}/>
                        </Box>
                    </HtmlTooltip>
                </Grid>
                <Grid item xs={8}>
                    <Switch disabled={!isEditMode()} checked={isFirstResponseEnabled}
                            onChange={handleFirstResponseToggle} sx={styles.switch}/>
                </Grid>
                <Grid item xs={4} sx={{margin: "10px 0px"}}>
                    {label.AUTO_RESPONDER}
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        disabled={!isEditMode()}
                        type={'number'}
                        min={0}
                        value={firstResponseThreshold}
                        error={getFirstResponseThresholdError() !== null}
                        helperText={getFirstResponseThresholdError()}
                        onChange={(event) => {
                            handleFirstResponseThresholdChange(event);
                        }}
                    />
                </Grid>
                <Grid item xs={4} sx={{margin: "10px 0px", display: 'flex', alignItems: 'center', gap: '5px'}}>
                    {label.FIRST_RESPONSE_FOOTER}
                    <InformationTooltip
                        label={"Appends a fixed custom signature to each response. This should be HTML formatted."}/>
                </Grid>
                <Grid item xs={8}>
                    <TextField disabled={!isEditMode()}
                               value={firstResponseFooter}
                               onChange={(event) => {
                                   handleFirstResponseFooterChange(event);
                               }}/>
                </Grid>
            </Grid>
            {isEditMode() ?
                <BasicButton action={updateAutoAction}
                             label={!autoAction.id ? "Create AutoAction" : "Update AutoAction"}
                             style={{width: "200px", margin: "10px 0px"}}/>
                : null}
        </div>
    );
}
