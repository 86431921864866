import React, {Fragment} from "react";
import GladlySetupModalForm from "./GladlySetupModalForm";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";

export default function GladlySetupModal({
	open,
	handleClose,
	handleOpen
}){
  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Gladly'}>

        <GladlySetupModalForm
          description={'Please copy and paste your Gladly credentials here:'}
          buttonLabel={'Submit'}
          openInNewTab={true}
          handleClose={handleClose} />
      </BasicModal>
    </Fragment>
  )
}