
import {scheduleConstants} from "_constants"

const INITIAL_STATE = {
    data: [],
    current: {},
    organizations: [],
    loading: false,
    error: '',
    scheduledOrganizations: [],
    loadingScheduledOrgs: false,
};


export const schedulesReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case scheduleConstants.GET_ALL_REQUEST:
            return {...state, loading: true};
        case scheduleConstants.GET_ALL_SUCCESS:
            return {...state, data: action.payload, loading: false};
        case scheduleConstants.GET_ALL_FAILURE:
            return {...state, error: action.error, loading: false};

        case scheduleConstants.GET_SCHEDULE_REQUEST:
            return {...state, loading: true};
        case scheduleConstants.GET_SCHEDULE_SUCCESS:
            return {...state, current: action.payload, loading: false};
        case scheduleConstants.GET_SCHEDULE_FAILURE:
            return {...state, error: action.error, loading: false};

        case scheduleConstants.ORGS_SCHEDULE_REQUEST:
            return { ...state, loading: true};
        case scheduleConstants.ORGS_SCHEDULE_SUCCESS:
            return { ...state, organizations: action.payload.organizations, loading: false};
        case scheduleConstants.ORGS_SCHEDULE_FAILURE:
            return { ...state, error: action.error, loading: false};

        case scheduleConstants.GET_SCHEDULED_ORG_REQUEST:
            return { ...state, loadingScheduledOrgs: true};
        case scheduleConstants.GET_SCHEDULED_ORG_SUCCESS:
            return { ...state, scheduledOrganizations: action.payload, loadingScheduledOrgs: false};
        case scheduleConstants.GET_SCHEDULED_ORG_FAILURE:
            return { ...state, error: action.error, loadingScheduledOrgs: false};

        case scheduleConstants.BULK_PAYOUT_PRICING_REQUEST:
            return { ...state, loadingScheduledOrgs: true};
        case scheduleConstants.BULK_PAYOUT_PRICING_SUCCESS:
            return { ...state,
                data: state.data.map((item) => {
                    if (action.payload.schedules && action.payload.schedules.includes(item.id)) {
                        item[action.payload.actionType] = action.payload.actionValue
                    }
                    return item
                }),
                loadingScheduledOrgs: false};
        case scheduleConstants.BULK_PAYOUT_PRICING_FAILURE:
            return { ...state, error: action.error, loadingScheduledOrgs: false};

        default:
            return state
    }
};