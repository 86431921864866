import React, {Component, Fragment} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import moment from 'moment'
import { AddGorgiasAutoSenderModal } from 'components/MsgDataSources';
import { PlatformMacroModal } from 'components/MsgDataSources'

/**
 * @author Offiong Aniekan <ekanoffiong@gmail.com>
 * @date 20/12/2018 : 11:07 AM
 */
class ListGorgiasDataSources extends Component {

    state = {
        updateDataSources: {},
        showAutoSenderModal: false,
        dataSourceId: null,
        showPlatformMacros: false,
        macroDataSourceId: null
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAllDataSources({dataSource: 'Gorgias'}));
    }

    toggleClosedTicketActioning = (postData) => {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.toggleClosedTicketActioning({...postData, msgDataSourceLabel: 'Gorgias'}));
    };

    saveSupportEmail = (dataSourceId, trendsId) => {
        const { dispatch } = this.props;
        const { updateDataSources } = this.state;
        dispatch(msgDataSourceActions.saveSupportEmail({dataSourceId: trendsId, data: updateDataSources[dataSourceId], msgDataSource: 'gorgias'}));
        this.cancelUpdate(dataSourceId)
    };

    editEmailData = (dataSource) => {
        this.setState((prevState) => ({
            updateDataSources: {
                ...prevState.updateDataSources,
                [dataSource.id]: {
                    orgSupportEmail: dataSource.orgSupportEmail,
                }
            }
        }))
    };

    handleEmailChange = (e, dataSourceId) => {
        this.setState((prevState) => ({
            updateDataSources: {
                ...prevState.updateDataSources,
                [dataSourceId]: {
                    ...prevState.updateDataSources[dataSourceId],
                    [e.target.name]: e.target.value
                }
            }
        }));
    };

    cancelUpdate = (dataSourceId) => {
        this.setState((prevState) => {
            let { [dataSourceId]: removed, ...restDataSources } = prevState.updateDataSources;
            return {
                updateDataSources: restDataSources
            }
        })
    };

    updateAutoSender = (dataSourceId) => {
        this.setState({
            showAutoSenderModal: true,
            dataSourceId
        })
    }

    viewPlatformMacros = (dataSourceId) => {
        this.setState({
            macroDataSourceId: dataSourceId,
            showPlatformMacros: true
        });
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getSavedPlatformMacros({ msgDataSourceId: dataSourceId }))
    };

    render() {
        const { gorgiasList, loading } = this.props;
        const { updateDataSources, showAutoSenderModal, dataSourceId, showPlatformMacros, macroDataSourceId } = this.state;
        const autoSendersData = gorgiasList.find(current => current.id === dataSourceId);
        const currentZendeskDataSource = gorgiasList.find(item => item.id === macroDataSourceId);
        const platformMacroEnabled = currentZendeskDataSource && currentZendeskDataSource.isPlatformMacroEnabled;
        const sensitiveActionsEnabled = currentZendeskDataSource && currentZendeskDataSource.sensitiveActionsEnabled;

        if (loading) {
            return <Loading/>
        }
        return (
            <div>
                <Row>
                    <Col xs={12} md={12}>
                        <table className='table trds-table'>
                            <thead className="font-18-bold">
                                <tr>
                                    <th className="col-md-1">S/N</th>
                                    <th className="col-md-2">Organization</th>
                                    <th className="col-md-3">Reply Email</th>
                                    <th className="col-md-2">Auto Reply Email/Macros</th>
                                    <th className="col-md-4">Actioning / Close Ticket on (Reply/No Response Needed) / Assign on Reply</th>
                                </tr>
                            </thead>
                            <tbody>
                            {gorgiasList.map((dataSource, index) => (
                                <tr key={index}>
                                    <td className="vertical-align-middle">{index + 1}</td>
                                    <td className="vertical-align-middle">{dataSource.orgName}</td>
                                    <td className="vertical-align-middle">
                                        {!dataSource.orgSupportEmail && !(dataSource.id in updateDataSources) &&
                                            <Button className='btn-info btn-sm' onClick={() => this.editEmailData(dataSource)}>
                                                Add Single Reply Email
                                            </Button>
                                        }
                                        {!(dataSource.id in updateDataSources) &&
                                            <Fragment>
                                                {dataSource.orgSupportEmail &&
                                                    <Fragment>
                                                        <span>
                                                            {dataSource.orgSupportEmail}
                                                        </span>
                                                        <Button className='btn-info btn-sm' onClick={() => this.editEmailData(dataSource)}>
                                                            Edit Reply Email
                                                        </Button>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }
                                        {(dataSource.id in updateDataSources &&
                                            'orgSupportEmail' in updateDataSources[dataSource.id]) && (
                                            <Fragment>
                                                <input type="text" className="form-control" name="orgSupportEmail" value={updateDataSources[dataSource.id].orgSupportEmail || ''} onChange={(e) => this.handleEmailChange(e, dataSource.id)} />
                                                <Button className="margin-top-5 btn-sm btn-success"
                                                    onClick={() => this.saveSupportEmail(dataSource.id, dataSource.trendsId)}>
                                                    Save Reply Email
                                                </Button>
                                                <Button className="margin-top-5 btn-danger btn-sm"
                                                    onClick={() => this.cancelUpdate(dataSource.id)}>
                                                    Cancel
                                                </Button>
                                            </Fragment>
                                        )}
                                    </td>
                                    <td className="vertical-align-middle" style={{wordWrap: "break-word"}}>
                                        <Button className='btn-info btn-sm margin-left-5' onClick={() => this.updateAutoSender(dataSource.id)}>
                                            Update Auto Sender
                                        </Button>
                                        <Button className='btn-primary btn-sm margin-left-5 margin-top-5' onClick={() => this.viewPlatformMacros(dataSource.id)}>
                                            Set up Macros
                                        </Button>
                                    </td>
                                    <td>
                                        {dataSource.isClosedTicketActioning &&
                                            <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'isClosedTicketActioning', enable: false})}>
                                                Disable Closed Ticket Actioning
                                            </Button>
                                        }
                                        {!dataSource.isClosedTicketActioning &&
                                            <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'isClosedTicketActioning', enable: true})}>
                                                Enable Closed Ticket Actioning
                                            </Button>
                                        }
                                        {dataSource.closeTicketOnReply &&
                                            <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnReply', enable: false})}>
                                                Disable Close Ticket on Reply
                                            </Button>
                                        }
                                        {!dataSource.closeTicketOnReply &&
                                            <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnReply', enable: true})}>
                                                Enable Close Ticket on Reply
                                            </Button>
                                        }
                                        {dataSource.closeTicketOnNoResponse &&
                                            <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnNoResponse', enable: false})}>
                                                Disable Close Ticket on No Response Needed
                                            </Button>
                                        }
                                        {!dataSource.closeTicketOnNoResponse &&
                                            <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnNoResponse', enable: true})}>
                                                Enable Close Ticket on No Response Needed
                                            </Button>
                                        }
                                        {dataSource.assignTicketOnReply &&
                                            <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'assignTicketOnReply', enable: false})}>
                                                Disable Assign Ticket on Reply
                                            </Button>
                                        }
                                        {!dataSource.assignTicketOnReply &&
                                            <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'assignTicketOnReply', enable: true})}>
                                                Enable Assign Ticket on Reply
                                            </Button>
                                        }
                                        {dataSource.isGorgiasSpamTicketEnabled &&
                                            <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'isGorgiasSpamTicketEnabled', enable: false})}>
                                                Disable Spam Ticket Checking
                                            </Button>
                                        }
                                        {!dataSource.isGorgiasSpamTicketEnabled &&
                                            <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'isGorgiasSpamTicketEnabled', enable: true})}>
                                                Enable Spam Ticket Checking
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <AddGorgiasAutoSenderModal
                    dataType="Gorgias"
                    showData={showAutoSenderModal}
                    autoSenders={autoSendersData && autoSendersData.autoSenders}
                    dataSourceId={dataSourceId}
                    closeModal={() => this.setState({showAutoSenderModal:false})}
                />
                <PlatformMacroModal
                    msgDataSourceId={macroDataSourceId}
                    showData={showPlatformMacros}
                    dataType="Gorgias"
                    platformMacroEnabled={platformMacroEnabled}
                    sensitiveActionsEnabled={sensitiveActionsEnabled}
                    closeModal={() => this.setState({showPlatformMacros:false})}
                    platformMacroType="GORGIAS_MACRO"
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { gorgiasAuth: { list, loading } } = state;
    return {
        gorgiasList: list, loading
    };
}

const connectedListGorgiasDataSources = connect(mapStateToProps)(ListGorgiasDataSources);
export { connectedListGorgiasDataSources as ListGorgiasDataSources };
