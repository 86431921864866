import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {Loading, MessageFollowupParser} from 'components/common';
import {
  stripNumbersFromUsername,
  parseEscapedText,
  testForEmail,
  changeImgTagToLink,
  getMessageSource,
  queryStringToObject,
  isObjectEmpty,
  stripStyleFromHtml,
  handleAttachment, handleRawHtml, formatCustomerName, sanitizeTextForDixa, formatDixaText, processSpacing,
} from '_helpers';
import { connect } from 'react-redux';
import {messageActions} from "_actions";
import {ViewConversationButton} from 'components/ConversationThread/ViewConversationButton';
import {
  handleTypeNames,
  supportedHandleTypesForViewOriginalButton,
  supportedSourcesForViewHtmlAndPhotos
} from "_constants";
import _ from "lodash";
import copyIcon from "../images/outline-file_copy-24px.svg";
import {copyTicketLinkToClipboard, copyTextToClipboard} from '_actions/utility.action';
import {Box} from "@mui/system";
import ReactHtmlParser from "react-html-parser";

class ConversationList extends Component {

  constructor(props){
    super(props);

    this.state = {
      displayViewDefaultButton: false,
      displayViewOriginalButton: true,
      displayConversation: true,
      displayOriginalConversation: false,
      displayFullEmailConversation: false,
    }
  }

  /**
   * This triggers an action for getting the full email conversation
   */
  getFullEmailConversation = () => {
    const { mainMessage, dispatch, emailConversationData } = this.props;
    const { messageId } = emailConversationData || [];

    /**
     * Check that the previous message id is not the same as the
     * current message id to prevent fetching the same data
     * Add platform to know when a message is trends message so that the controller calls the corresponding trends endpoint
     */
    const platform = this.props.platform || queryStringToObject(window.location.search.substring(1)).platform;
    if( messageId !== mainMessage.id ){
      dispatch(messageActions.getFullEmailConversation(mainMessage.id || '', platform));
    }

    this.setState({
      displayConversation: false,
      displayOriginalConversation: false,
      displayFullEmailConversation: true,
      displayViewDefaultButton: true,
      displayViewOriginalButton: false,
    })
  };

  getOriginalConversationFromTeams = () => {
    const { dispatch, mainMessage } = this.props;
    dispatch(messageActions.getOriginalConversationFromTeams(mainMessage.id));

    this.setState({
      displayConversation: false,
      displayOriginalConversation: true,
      displayFullEmailConversation: false,
      displayViewDefaultButton: true,
      displayViewOriginalButton: false,
    });
  };

  /**
   * Makes a request to the server to download the attachment
   * @param messageId
   * @param attachmentURL
   * @param fileName
   */
  downloadAttachment = (messageId, attachmentURL, fileName) => {
    const { dispatch } = this.props;
    dispatch(messageActions.downloadAttachment(messageId, attachmentURL, fileName));
  };

  /**
   * This handles different display states
   */
  showDefaultConversation = () => {
    this.setState({
      displayConversation: true,
      displayOriginalConversation: false,
      displayFullEmailConversation: false,
      displayViewDefaultButton: false,
      displayViewOriginalButton: true,
    })
  };

  /**
   * This renders any attachment found within the message
   * @param attachment
   * @param index
   * @param params
   * @returns {JSX.Element}
   */
  renderAttachments = (attachment, index, params) => {
    const { downloadingAttachment } = this.props;
    return(
      <div key={index} style={{margin: '4px 0'}}>
        {handleAttachment(attachment, params, this.downloadAttachment, downloadingAttachment)}
      </div>
      )
  };

  /**
   * This renders the conversation gotten from trends
   * @param message
   * @param index
   * @param msgSource
   * @param organization
   * @param isTrendsMessage
   * @returns {*}
   */
  renderConversation = (message, index, msgSource, organization, isTrendsMessage) => {
    let text = parseEscapedText(message.text);
    if(sanitizeTextForDixa(_.toString(msgSource).toLowerCase())) {
      text = formatDixaText(text);
      text = processSpacing(text);
    }
    return (
      <div key={message.id} style={{display: 'flex', padding: '20px', borderBottom: '1px solid #ededed', clear: 'both'}}>
        <div>
          <div style={{paddingBottom: '10px'}}>
            <span id="authorName" style={message.author ? {fontWeight: "bold", marginRight: '8px'} : {fontWeight: "bold", marginRight: '0'}}>
              {testForEmail(message.author) ? 'User' : stripNumbersFromUsername(message.author)}
            </span>
            {message.author && index === 0 && <img src={copyIcon} onClick={() => copyTextToClipboard('authorName')} style={{width: '18px', marginRight: '15px', cursor:'pointer'}} alt="Copy Customer Name"/> }

            <span>
              {moment(message.createdDate).format('MMM D h:mm a')}
            </span>
          </div>
          <div className="no-break-out">
            <MessageFollowupParser
              text={text}
              msgSource={msgSource}
              organization={organization}
              isTrends={isTrendsMessage}
            />
          </div>
        </div>
      </div>
    );
  };

  /**
   * This renders the full conversation gotten from the message source
   * @param emailConversation
   * @param params
   * @param msgSource {string}
   * @returns {JSX.Element}
   */
  renderFullEmailConversation = (emailConversation, params, msgSource) => {
    const { id, htmlBody, authorName, createdDate, attachments, authorEmail, recipientEmail, currentPage, assignee, other, authorPhoneNumber, recipientPhoneNumber } = emailConversation;
    let text = changeImgTagToLink(parseEscapedText(stripStyleFromHtml(htmlBody)));

    if(sanitizeTextForDixa(_.toString(msgSource).toLowerCase())) {
      text = formatDixaText(text);
      text = processSpacing(text);
    }

    return (
        <div key={id} style={{display: 'flex', padding: '20px', borderBottom: '1px solid #ededed', clear: 'both'}}>
        <div>
          <div style={{paddingBottom: '10px'}}>
            {authorEmail && <span style={{marginRight: '20px'}}>
              From:
            </span>}
            <span style={{fontWeight: "bold"}}>
              { authorPhoneNumber || (testForEmail(authorName) ? 'User' : stripNumbersFromUsername(authorName))}
            </span>
            {authorEmail &&
            <span style={{marginRight: '15px'}}>
              {` <${authorEmail}>`}
            </span>}
            <span>
              {moment(createdDate).format('MMM D h:mm a')}
            </span>
            { currentPage &&
              <div style={{paddingTop: '5px', width: '450px'}} className="tms-ellipsis">
                <a href={currentPage} target = "_blank" rel="noopener noreferrer">
                  {currentPage}
                </a>
              </div>
            }
          </div>
          {(recipientEmail || recipientPhoneNumber) &&
          <div style={{paddingBottom: '10px'}}>
            <span style={{ marginRight: '35px'}}>To:</span>
            <span>{`<${recipientPhoneNumber || recipientEmail}>`}</span>
          </div>}
          {assignee && assignee.name && assignee.email &&
          <div style={{paddingBottom: '10px'}}>
            <span style={{ marginRight: '35px'}}>Assigned To:</span>
            <span>{`<${assignee.name}>`} ({`<${assignee.email}>`})</span>
          </div>}

          <Box className="no-break-out">
            {ReactHtmlParser(text || '')}
          </Box>
          {
            other &&
                  <table style={{display: 'flex'}}>
                    <tbody>
                      {Object.entries(other).map(([key, value]) => {
                        return (
                            <tr key={key}>
                              <td style={{minWidth: '110px', padding: '10px 10px 0 0', wordBreak: 'break-all'}}>{key}:</td>
                              <td style={{wordBreak: 'break-all'}} dangerouslySetInnerHTML={handleRawHtml(value)}/>
                            </tr>
                        )
                      })}
                    </tbody>
                  </table>
          }

          {
            ( attachments && attachments.length > 0 ) &&
              <div style={{paddingTop: '15px'}}>
                <div style={{display: 'flex'}}>
                  <div style={{fontWeight: "bold"}}>Attachments</div>
                  { this.props.downloadingAttachment && <div style={{margin: '2px 0 0 8px'}}> <Loading height={15} size={15} /> </div> }
                </div>
                { attachments.map((attachment, index) => this.renderAttachments(attachment, index, params)) }
              </div>
          }
        </div>
      </div>
    );
  };

  render(){

    let conversation, viewButton, fullMessageLink, author;
    let { loading, messages, isTrendsMessage, emailConversationData, loadingEmailConversation, mainMessage, msgSource, organization, originalConversation } = this.props;
    const source = mainMessage && getMessageSource((mainMessage.dataSourceHandle &&  mainMessage.dataSourceHandle.name) || mainMessage.channel);
    const handleType = mainMessage && (mainMessage.dataSourceHandle && mainMessage.dataSourceHandle.type);
    const handleName = (mainMessage.dataSourceHandle &&  mainMessage.dataSourceHandle.name) || mainMessage.channel;
    const messagesCount = _.get(messages, 'length');
    const { emailConversation } = emailConversationData || [];
    const emailSubject = isObjectEmpty(emailConversation)  ? '' : (Object.entries(emailConversation)[0][1]).emailSubject;
    const showViewOriginalButton = this.state.displayViewOriginalButton && (supportedHandleTypesForViewOriginalButton.includes(handleType) ||
        _.toString(source).toLowerCase() === 'gorgias' || _.toString(msgSource).toLowerCase() === 'dixa' ||
        (handleName && handleName.includes("Instagram Messages")) ||
        (handleName && handleName.includes("Facebook Messages")))

    console.log("source: ",source);
    console.log("showViewOriginalButton: ",showViewOriginalButton);
    console.log("mainMessage: ",mainMessage);
    console.log("messages: ",messages);
    console.log("this.props: ",this.props);

    const params = {
      messageId: mainMessage.id,
      msgSource: msgSource,
    }

    if (messagesCount > 0) {
      if (isTrendsMessage) {
        messages = messages[0].conversation;
      }

      fullMessageLink = _.findLast(messages, message => message.fullMessageLink)?.fullMessageLink;
      author = messages[0].author;
    }

    if (loading || loadingEmailConversation || originalConversation?.loading) {
      return <Loading height={150}/>
    }

    /**
     * Conditions for rendering a button type
     */
    if (this.state.displayViewOriginalButton && mainMessage?.isSupervisedChat && !mainMessage?.link) {
        viewButton = <ViewConversationButton
            buttonText={"View original"}
            showConversation={this.getOriginalConversationFromTeams} />;
    } else if (showViewOriginalButton) {
      viewButton = supportedSourcesForViewHtmlAndPhotos.includes(_.toString(msgSource).toLowerCase()) ?
        <ViewConversationButton
            buttonText={"View original and photos"}
            showConversation={this.getFullEmailConversation} /> : '';
    } else if (this.state.displayViewDefaultButton) {
      viewButton = <ViewConversationButton
        buttonText={"View default"}
        showConversation={this.showDefaultConversation} />;
    }

    /**
     * Conditions for rendering a conversation type
     */
    if (this.state.displayConversation) {
      conversation = <Fragment>
        {
          messages && messages.constructor === Array && messages.map((message, index) => this.renderConversation(message, index, msgSource, organization, isTrendsMessage))
        }
      </Fragment>
    } else if (this.state.displayOriginalConversation) {
      conversation = <Fragment>
        {
          originalConversation?.conversation &&
            originalConversation?.conversation?.constructor === Array &&
            originalConversation?.conversation?.map((message, index) => this.renderConversation(message, index, msgSource, organization, isTrendsMessage))
        }
      </Fragment>
    } else if (this.state.displayFullEmailConversation) {
      conversation = <Fragment>
        {
          emailConversation && emailConversation.map((message) => this.renderFullEmailConversation(message, params, msgSource))
        }
      </Fragment>
    }

    return (
      <div>
        { messagesCount === 0 && (
          <h4 className="text-center">
            There are no messages in this conversation
          </h4>
        )
        }
        { messagesCount > 0 && <div className="conversation-list-container">
          <div className="title conversation-list-title" style={{padding: `2px 20px 0`, borderBottom: '1px solid #ededed'}}>
            <span className="tms-ellipsis" style={{width: '275px'}} title={author}> Conversation with {author}</span>
            <div style={{display: 'flex'}}>
              <span style={{marginRight: '20px'}}>
                      {viewButton}
              </span>
              {fullMessageLink && (
                  <div>
                  <span>
                  <a id="fullMessageLink"
                     className="font-14 color-green tms-no-underline"
                     href={fullMessageLink}
                     target="_blank"
                     rel="noreferrer noopener nofollow"
                     style={{ fontWeight: 'bold'}}
                  >
                      View on {' '} {messages[messagesCount - 1].messageSource.name}
                  </a>
                  <img src={copyIcon} onClick={() => copyTicketLinkToClipboard('fullMessageLink')} style={{width: '18px',marginLeft: '7px',cursor:'pointer'}} alt="Copy Ticket Link"/>
                </span>
                  </div>
              )}
              </div>
            </div>

          {emailSubject && this.state.displayFullEmailConversation && [handleTypeNames.EMAIL, handleTypeNames.PROACTIVE_EMAIL].includes(handleType) &&
          <div style={{padding: '10px 20px', borderBottom: '1px solid #ededed'}}>
            <span style={{marginRight: '8px'}}>Subject:</span>
            <span style={{fontWeight: "bold"}}>{emailSubject}</span>
          </div>
          }

          <div>
            { conversation }
          </div>
          <div style={{textAlign: 'center', padding: '20px 0'}}>End of conversation</div>
        </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    fullEmailConversation: { emailConversationData, loadingEmailConversation },
    messages: { downloadingAttachment },
    originalConversation
  } = state;
  return {
    emailConversationData,
    loadingEmailConversation,
    downloadingAttachment,
    originalConversation
  };
}

const connectedConversationList = connect(mapStateToProps)(ConversationList);
export { connectedConversationList as ConversationList };

ConversationList.propTypes = {
  loading: PropTypes.bool,
  messages: PropTypes.array,
};
