import { useEffect, useMemo } from 'react';
import { employmentActions } from '_actions';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useSelector, useDispatch } from 'react-redux'
import { Box, Stack } from '@mui/material';
import PageContainer from 'components/v2/common/PageContainer';
import '../Utilities/styles.css';
import importedStyles from '../Utilities/styles'
import PersonHeader from '../Utilities/PersonHeader';
import EmploymentDetailsOverview from './EmploymentDetailsOverview';

/**
 * @param {object} params
 * @param {import('react-router-dom').RouteComponentProps['match']} params.match Automatically provided by `react-router-dom`.
 */
export default function EmploymentDetails({ match }) {
	const dispatch = useDispatch()
	const employmentId = match.params.employmentId;
	const emp = useSelector(state => state.employment)

	const employment = emp.employment
	const conversationStats = emp.conversationStats
	const loading = emp.loading;
	const conversationStatsLoading = emp.conversationStatsLoading

	useEffect(() => {
		dispatch(employmentActions.getEmploymentDetails(employmentId))
		dispatch(employmentActions.getConversationStats(employmentId))
	}, [])

	const personHeaderProps = useMemo(() => {
		if (!employment || !employment._embedded || !employment._embedded.agent)
			return null

		return {
			profilePicture: employment._embedded.agent.photo,
			firstName: employment._embedded.agent.firstName,
			lastName: employment._embedded.agent.lastName,
			nickname: employment.nickname,
			organization: employment._embedded.organization,
			dateCreated: employment._embedded.agent.dateCreated,
			personHeaderType: "employmentDetails"
		}
	}, [employment])

	return (
		<PageContainer backgroundColor='#EFEAE7' size='large'>
			<Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
				<RouterLink to='/v2/company/team'>
					<Box sx={styles.backLink}>
						<ArrowBackIosNewIcon sx={{ fontSize: '16px', color: ' #368C86' }} />
						Back to team
					</Box>
				</RouterLink>
			</Box>

			<Stack spacing={{ xs: 4, md: 6 }} sx={importedStyles.root}>
				<PersonHeader {...personHeaderProps} />

				{(loading || conversationStatsLoading) ? null : <EmploymentDetailsOverview conversationStats={conversationStats} employment={employment} />}
			</Stack>
		</PageContainer>
	)
}

const styles = {
	backLink: {
		...importedStyles.root,
		display: 'flex',
		fontFamily: 'LarsseitMedium',
		alignItems: 'center',
		mt: '-24px',
		mb: '24px',
		whiteSpace: 'nowrap',
	},
}
