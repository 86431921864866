import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { shopifyOauthActions } from '_actions';
import {SetupLink} from "components/OauthComponents";

class ShopifyConnect extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let url = new URL(document.location);
        let values = new URLSearchParams(url.search);
        const currentUser = JSON.parse(localStorage.user);
        const orgId = currentUser._embedded.organization.id;
        const shopifyHmac = values.get('hmac')
        const shopifyShop = values.get('shop')

        if (shopifyHmac && shopifyShop){
            const info = {hmac: shopifyHmac, shop: shopifyShop, code: values.get('code'), state:values.get('state'), timestamp: values.get('timestamp'), orgId: orgId }
            this.props.dispatch(shopifyOauthActions.generateAccessToken(info))
        }

    }

    onSubmit(props) {
        /**
         * make request to backend with subdomain to generate credentials
         */
        let redirectUrl = new URL(document.location);

        const params = {  storeUrl: props.storeUrl, redirectUrl: redirectUrl.href };
        this.props.dispatch(shopifyOauthActions.initiateOauth(params));

    }

    oauthRedirect(authUrl) {
        window.location.replace(authUrl);
    }

    render() {
        const { handleSubmit, data, msgSourceLink } = this.props;

        if (data.redirectUrl) {
            this.oauthRedirect(data.redirectUrl);

        }

        return (
            <div className="main-content-wrapper">
                <div className="content-pane-60 margin-0-auto">
                    <div className="padding-20-0">
                        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                            <div>
                                <h1 className="msg-source-submit-header font-22-heavy color-grey-dark margin-bottom-30">Connect your Shopify Account</h1>
                                <div>
                                    <label className="color-grey-dark font-18 msg-source-submit-label margin-bottom-30">
                                        <span>https://</span>
                                        <Field
                                            component="input"
                                            type="text"
                                            className="input-field font-16 msg-source-submit-input"
                                            name="storeUrl"
                                        />
                                        <span>.myshopify.com</span>
                                    </label>
                                </div>
                                <button type="submit" className='btn btn-success font-16 msg-source-submit-button'>
                                    Connect
                                </button>
                                <SetupLink msgSourceLink={msgSourceLink}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const formConfig = {
    form: 'shopifyForm',
};

const mapStateToProps = (state) => {
    const { shopifyOauth, msgSources: { msgSourceLink } } = state;
    return {
        data: shopifyOauth.data,
        msgSourceLink: msgSourceLink.msgSourceLink
    };
};

const shopifyConnect = connect(
    mapStateToProps, null,
)(ShopifyConnect);

export default reduxForm(formConfig)(shopifyConnect);
