/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 13/09/2018 : 2:38 PM
 */

import React from 'react';
import connect from "react-redux/es/connect/connect";
import {Col, Grid, Row} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {map} from "underscore";


class Stats extends React.Component {
    _embedded;

    /**
     * Transforms roles to a format that is
     * processable by the dropdown.
     * @param organizations
     */
    transformData = (organizations) => {
        return map(organizations, function (organization) {
            return {...organization._embedded.summary, organization: organization.name, score: '12%'}
        });
    };

    render() {

        const {data} = this.props;

        const tableData = this.transformData(data.organizations);

        return (
            <Grid>
                <Row>
                    <Col md={12} xs={12}>
                        <h3>Statistics</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={12}>
                        <BootstrapTable data={tableData} responsive bordered={true} condensed>
                            <TableHeaderColumn isKey dataAlign='center'
                                               dataField='organization'>Company</TableHeaderColumn>
                            <TableHeaderColumn dataAlign='center' dataField='messages'>Messages</TableHeaderColumn>
                            <TableHeaderColumn dataAlign='center'
                                               dataField='escalations'>UserMessageActions</TableHeaderColumn>
                            <TableHeaderColumn dataAlign='center' dataField='score'>Score</TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>
            </Grid>
        );
    }
}


function mapStateToProps(state) {
    const {stats} = state;
    return {
        data: stats.data
    };
}

const connectedStats = connect(mapStateToProps)(Stats);
export {connectedStats as Stats};