import React from "react";
import {Box} from "@mui/material";
import {Field, reduxForm} from "redux-form";
import BasicFormTextField from "../../../common/BasicFormTextField";
import BasicMultilineTextField from "../../../common/BasicMultilineTextField";
import {required} from "_helpers";
import ActionButton from "../../../common/ActionButton";
import {connect} from "react-redux";
import {Timezone} from "../../../../common";
import BasicModal from "../../Onboarding/Utilities/BasicModal";
import TimezonePickerComponent from "./TimezonePicker";

function EditCompanyProfile(props){
    const {openEditProfile, handleCloseEditProfile, handleSubmit, handleSave} = props

    return(
        <BasicModal
            open={openEditProfile}
            handleClose={handleCloseEditProfile}
            title="Edit Company Profile"
        >
            <form name="form" onSubmit={handleSubmit(handleSave)}>
                <Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Company Name</Box>
                        <Field
                            type="text"
                            name="name"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Website</Box>
                        <Field
                            type="text"
                            name="website"
                            placeHolder="www.example.com"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Location</Box>
                        <Field
                            type="text"
                            name="location"
                            placeHolder="California, USA"
                            component={BasicFormTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Overview</Box>
                        <Field
                            name="overview"
                            placeholder="Company overview"
                            component={BasicMultilineTextField}
                            validate={required}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Facebook</Box>
                        <Field
                            type="text"
                            name="facebook"
                            placeHolder="Facebook username"
                            component={BasicFormTextField}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Twitter</Box>
                        <Field
                            type="text"
                            name="twitter"
                            placeHolder="Twitter username"
                            component={BasicFormTextField}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Instagram</Box>
                        <Field
                            type="text"
                            name="instagram"
                            placeHolder="Instagram username"
                            component={BasicFormTextField}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>LinkedIn</Box>
                        <Field
                            type="text"
                            name='linkedin'
                            placeHolder='LinkedIn username'
                            component={BasicFormTextField}
                        />
                    </Box>
                    <Box mb={{xs: "10px", sm: "10px", md: "15px", lg: "15px"}}>
                        <Box sx={styles.inputDesc}>Timezone</Box>
                        <Field
                            type="text"
                            name='timezone'
                            placeHolder='Select Timezone...'
                            component={TimezonePickerComponent}
                        />
                    </Box>
                </Box>

                <Box sx={styles.footer}>
                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Cancel'}
                            buttonBordered={true}
                            useBorderColor="#368C86"
                            type={"button"}
                            action={handleCloseEditProfile}
                        />
                    </Box>

                    <Box sx={{width: '100px'}}>
                        <ActionButton
                            label={'Save'}
                        />
                    </Box>
                </Box>
            </form>
        </BasicModal>
    )
}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px',
    },
    footer: {
        display: 'flex',
        gap: '18px',
        justifyContent: 'flex-end',
        paddingTop: '20px'
    },
};

const formConfig = {
    form: 'EditCompanyForm',
    enableReinitialize: true,
    touchOnBlur: false
};
EditCompanyProfile = reduxForm(formConfig)(EditCompanyProfile)

EditCompanyProfile = connect(
    state => ({
        initialValues: {
            ...state.organizations.organization,
        }
    }),
)(EditCompanyProfile)
export default EditCompanyProfile