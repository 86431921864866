import React, {useState} from "react";
import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";
import styles from "../../organizationMainStyles";
import VisibilityFilterSelect from "./VisibilityFilterSelect";
import {accessibilityMode} from "../organizationSettingsConstants";
import CompanyInfoActions from "./CompanyInfoActions";
import {getCapitalizedWords} from "_helpers";
import {buildUpdateVisibilityPayload} from "../organizationSettingsUtils";
import {SnackBar} from "../../../../../common";
import InformationTooltip from "../InformationTooltip";

export default function VisibilityContainer({mode, organization, label}) {
    const visibilityVal = _.get(organization, 'visibility');
    const defaultVisibility = {id: visibilityVal};
    const [visibility, setVisibility] = useState(defaultVisibility);
    const dispatch = useDispatch();
    const organizationId = _.get(organization, 'id');
    const organizationSettings = useSelector(state => state.organizationSettings);
    const loading = organizationSettings.loadingOrganizationAttributeUpdate;
    const actionType = _.get(organizationSettings, 'payloadRef.actionType');
    const updatedVisibility = _.get(organizationSettings, 'payloadRef.payload.visibility');
    const ACTION_ID = 'visibility_action';

    const handleSelectChange = (value) => {
        setVisibility(value);
    }

    const handleUpdate = () => {
        const payload = buildUpdateVisibilityPayload(visibility);
        if(!organizationId || !payload) return SnackBar.showMessage('Invalid parameters');
        dispatch(organizationSettingsActions.updateOrganizationAttribute(organizationId, payload, ACTION_ID));
    }

    const handleCancel = () => {
        setVisibility(defaultVisibility);
    }

    const Content = () => {
        if(mode === accessibilityMode.EDIT) {
            return (
                <Box sx={{display: 'flex'}}>
                    <Grid container item xs={12} md={6} sx={styles.editableInfo}>
                        <Grid item xs={12} md={12} mr={'15px'}>
                            <VisibilityFilterSelect
                                selectedVisibility={visibility}
                                updatedVisibility={updatedVisibility}
                                handleSelectChange={handleSelectChange}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{mt: '15px'}}>
                        <CompanyInfoActions
                            id={ACTION_ID}
                            selectedId={actionType}
                            performAction={handleUpdate}
                            performCancel={handleCancel}
                            loading={loading}
                        />
                    </Box>
                </Box>
            )
        }
        return <Box sx={styles.infoItem}>{getCapitalizedWords(visibilityVal)}</Box>;
    }

    return(
        <>
            <Box component={'h2'} sx={{...styles.heading, display: 'flex', alignItems: 'center', gap: '5px'}}>
                {label.VISIBILITY}
                <InformationTooltip label={"How the company appears to experts logged into Teams. If 'CLOSED', the company will not appear in the list of companies with available messages."} />
            </Box>
            <Content />
        </>
    )
}