import React, {Fragment} from "react";
import BasicSelect from "../../../common/BasicSelect";
import useGetCustomerIoSegments from "./hooks/useGetCustomerIoSegments";

export default function CustomerIoSegmentSelect({ name, label, placeholder, handleChange, handleFormSelectChange, defaultValue, queryParam, input, meta }) {
    const {customerIoSegments} = useGetCustomerIoSegments(queryParam);

    return(
        <Fragment>
            <BasicSelect
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={defaultValue}
                options={customerIoSegments}
                onChange={handleChange}
                handleFormSelectChange={handleFormSelectChange}
                input={input}
                meta={meta} />
        </Fragment>
    )
}