import { Box, Button } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import {darken, lighten} from "@mui/material";

export default function ActionButton({ type = "submit", label, action, disabled, sx, withIcon = true, buttonBordered, useBorderColor='#0C2728', useDark, iconStyle }) {
    return(
        <Box sx={{height: '40px', ...sx}}>
            <Button
                sx={buttonBordered ?
                    {
                        ...styles.buttonBordered, ...sx,
                        ...{
                            color: useBorderColor,
                            borderColor: useBorderColor,
                            ':hover': {
                                backgroundColor: '#ffffff',
                                color: darken(useBorderColor, 0.9),
                                borderColor: darken(useBorderColor, 0.9)
                            },
                            ':disabled': {
                                backgroundColor: '#ffffff',
                                color: lighten(useBorderColor, 0.55),
                                borderColor: lighten(useBorderColor, 0.55)
                            },
                            ':active': {
                                color: useBorderColor
                            },
                        }
                    }
                    : (useDark ? styles.buttonColoredDark : {...styles.buttonColored, ...sx})}
                type={type}
                onClick={action}
                variant="contained"
                disableElevation
                disabled={disabled}
                endIcon={disabled && withIcon &&
                    <CircularProgress size={15} sx={{ color: '#ffffff', marginLeft: '10px', ...iconStyle}}/>} >
                {label}
            </Button>
        </Box>
    )
}

const styles = {
    buttonColored: {
        width: '100%',
        fontSize: '16px',
        padding: '11px 24px',
        color: '#fff',
        fontFamily: 'LarsseitMedium',
        lineHeight: 1,
        textTransform: 'none',
        borderRadius: '50px',
        backgroundColor: '#07857C',
        ':hover': {
            backgroundColor: '#34756f',
            color: 'white',
        },
        ':disabled': {
            color: '#fff',
            backgroundColor: '#368C8680'
        },
        ':focus': {
            outline: '0'
        }
    },
    buttonColoredDark: {
        width: '100%',
        fontSize: '16px',
        padding: '11px 24px',
        color: '#fff',
        fontFamily: 'LarsseitMedium',
        lineHeight: 1,
        textTransform: 'none',
        borderRadius: '50px',
        backgroundColor: '#144042',
        ':hover': {
            backgroundColor: '#103536',
            color: 'white',
        },
        ':disabled': {
            color: '#fff',
            backgroundColor: '#A1B3B3'
        },
        ':focus': {
            outline: '0'
        }
    },
    buttonBordered: {
        width: '100%',
        fontSize: '16px',
        padding: '9px 25px',
        color: '#368C86',
        fontFamily: 'LarsseitMedium',
        lineHeight: 1,
        textTransform: 'none',
        borderRadius: '50px',
        backgroundColor: '#ffffff',
        border: '1.5px solid #368C86',
        textDecoration: 'none',
        ':hover': {
            backgroundColor: '#ffffff',
            color: '#368C86',
        },
        ':disabled': {
            color: '#368C8680',
            backgroundColor: '#f5f5f5',
            border: '1px solid #368C8680'
        },
        ':focus': {
            outline: '0'
        }
    },
}