import twitterLogo from 'components/images/twitter.png'
import facebookLogo from 'components/images/facebook.png'
import instagramLogo from 'components/images/instagram.png'
import zendeskLogo from 'components/images/icon_zendesk-support.svg'
import frontappLogo from 'components/images/front.png'
import helpscoutLogo from 'components/images/helpscout.png'
import tiktokLogo from 'components/images/tiktok.png'
import {handleTypeNames} from "./dataSourceHandle.constants";

export const msgSourceConstants = {
    GET_ALL_REQUEST: 'GET_ALL_MSG_SOURCES_REQUEST',
    GET_ALL_SUCCESS: 'GET_ALL_MSG_SOURCES_SUCCESS',
    GET_ALL_FAILURE: 'GET_ALL_MSG_SOURCES_FAILURE',

    GET_ALL_MSG_SOURCES_SUCCESS:  'GET_ALL_MESSAGE_SOURCES_SUCCESS',
    GET_ALL_MSG_SOURCES_REQUEST:  'GET_ALL_MESSAGE_SOURCES_REQUEST',
    GET_ALL_MSG_SOURCES_FAILURE:  'GET_ALL_MESSAGE_SOURCES_FAILURE',

    GET_A_MSG_SOURCE_SUCCESS:  'GET_A_MESSAGE_SOURCE_SUCCESS',
    GET_A_MSG_SOURCE_REQUEST:  'GET_A_MESSAGE_SOURCE_REQUEST',
    GET_A_MSG_SOURCE_FAILURE:  'GET_A_MESSAGE_SOURCE_FAILURE',

    SAVE_MSG_SOURCES_LINK_SUCCESS:  'SAVE_MSG_SOURCES_LINK_SUCCESS',
    SAVE_MSG_SOURCES_LINK_REQUEST:  'SAVE_MSG_SOURCES_LINK_REQUEST',
    SAVE_MSG_SOURCES_LINK_FAILURE:  'SAVE_MSG_SOURCES_LINK_FAILURE',

    MSG_SOURCES : [
        {
            id: 1,
            name: "facebook",
            image: facebookLogo
        },
        {
            id: 2,
            name: "instagram",
            image: instagramLogo
        },
        {
            id: 3,
            name: "twitter",
            image: twitterLogo
        },
        {
            id: 4,
            name: "zendesk",
            image: zendeskLogo
        },
        {
            id: 5,
            name: "frontapp",
            image: frontappLogo
        },
        {
            id: 6,
            name: "helpscout",
            image: helpscoutLogo
        },
        {
            id: 7,
            name: "tiktok",
            image: tiktokLogo
        }
    ]

};

export const supportedSourcesForViewHtmlAndPhotos = [
    'zendesk',
    'freshdesk',
    'gorgias',
    'kustomer',
    'helpscout',
    'reamaze',
    'front',
    'gladly',
    'instagram',
    'facebook',
    'service cloud',
    'salesforce service cloud',
    'dixa',
];

export const supportedHandleTypesForViewOriginalButton = [
    handleTypeNames.EMAIL,
    handleTypeNames.PROACTIVE_EMAIL,
    handleTypeNames.PROACTIVE_POST
]

export const supportedSourcesForMarkdown = [
    'kustomer',
    'reamaze',
];

export const supportedSourcesForAttachmentUpload = [
    'gorgias',
];

export const supportedSourcesForEmbedImage = [
    'gorgias',
    'zendesk',
    'kustmomer'
];

export const supportedSourcesForAttachmentDownload = [
    'front',
    'salesforce service cloud'
];

export const supportedSourcesForOEmbed = [
    'tiktok',
    'instagram',
    'facebook',
    'youtube',
];

export const metaMsgSources = [
    'facebook',
    'instagram'
];

export const supportedSourcesForCloseTicket = [
    'gorgias',
];

export const supportedSourcesForCsat = [
    'zendesk',
    'gorgias',
    'kustomer'
]

export const supportedSourcesForInternalNote = [
    'gorgias',
    'zendesk',
    'kustomer'
]

export const msgSource = {
    ZENDESK: 'zendesk',
    FRESHDESK: 'freshdesk',
    GORGIAS: 'gorgias',
    KUSTOMER: 'kustomer',
    REAMAZE: 'reamaze',
    TWITTER: 'twitter',
    TIKTOK: 'tiktok',
    FACEBOOK: 'facebook',
    TIKTOK_ADS: 'tiktokads',
    INSTAGRAM: 'instagram',
    YOUTUBE: 'youtube',
    TIKTOKSHOP: 'tiktokshop',
    DIXA: 'dixa',
};

export const parseHtmlExcludedSources = [
    'service cloud',
    'salesforce service cloud',
    'gorgias'
];

export const supportedSourcesForDisplayInApp = [
    'zendesk',
    'gorgias',
    'freshdesk',
    'front',
    'kustomer'
];
export const supportedHandleTypesForDisplayInApp = [
    'email',
    'chat'
];

export const supportedSourcesForTags = [
    msgSource.DIXA,
]

export const supportedSourcesToExcludeAuthorId = [
    msgSource.DIXA,
]
