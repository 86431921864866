import { oauthConstants } from '_constants';

const INITIAL_STATE = {
    data: [],
    error: '',
    loading: true,
    status: '',
};

export function slackOauthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case oauthConstants.SLACK_OAUTH_URL_REQUEST:
            return { ...state, loading: true };
        case oauthConstants.SLACK_OAUTH_URL_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case oauthConstants.SLACK_OAUTH_URL_FAILURE:
            return { ...state, error: action.error, loading: false };
        case oauthConstants.SLACK_OAUTH_CODE_REQUEST:
            return { ...state, loading: true };
        case oauthConstants.SLACK_OAUTH_CODE_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case oauthConstants.SLACK_OAUTH_CODE_FAILURE:
            return { ...state, error: action.error, loading: false };
        case oauthConstants.SLACK_OAUTH_DISCONNECT_REQUEST:
            return { ...state, loading: true };
        case oauthConstants.SLACK_OAUTH_DISCONNECT_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case oauthConstants.SLACK_OAUTH_DISCONNECT_FAILURE:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}
