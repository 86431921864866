import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CLIENT_URL} from "config";
import {utilityService} from "_services";
import setRedirectUrl from "../../Utilities/setRedirectUrl";
import {salesforceOauthActions} from "../../../../../../../_actions/salesforce.oauth.actions";

export default function useSalesforceSetup({callbackPath, testCallbackPath}) {
    const dispatch = useDispatch();
    const salesforce = useSelector((state) => state.salesforceOauth);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const performAction = (isTest) => {
        let callbackUrl
        if (isTest) {
            callbackUrl = `${CLIENT_URL}${testCallbackPath}`
        } else {
            callbackUrl = `${CLIENT_URL}${callbackPath}`
        }

        setShouldRedirect(true)
        setRedirectUrl(callbackUrl)
        dispatch(salesforceOauthActions.initiateOauth(callbackUrl));
    };

    useEffect(() => {
        if (!salesforce.loading && salesforce.authUrl.url && shouldRedirect) {
            setShouldRedirect(false)
            utilityService.redirectToAuthUrl(salesforce.authUrl.url)
        }
    });

    return {initiateSalesforceSetup: performAction};
}
