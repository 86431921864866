import { fileUploadHeader, authHeader, handleHttpResponse } from '_helpers';
import { SERVER_URL } from 'config';

const jiraIssueCreation = (requestData) => {
    const endpoint = `${SERVER_URL}/api/jira/createIssue`;

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(requestData)
    };

    return fetch(endpoint, requestOptions)
        .then(handleHttpResponse);
};

const jiraIssueAttachment = (attachments, issueKey) => {
    const endpoint = `${SERVER_URL}/api/jira/${issueKey}/attachment`;

    const requestOptions = {
        method: 'POST',
        headers: fileUploadHeader(),
        body: attachments
    };

    return fetch(endpoint, requestOptions)
        .then(handleHttpResponse);
};

const jiraOauth = (redirectHost) => {
    const endpoint = `${SERVER_URL}/api/jira/oauth?host=${redirectHost}`;

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions)
        .then(handleHttpResponse);
};

const completeJiraOauth = (redirectHost, code) => {
    const endpoint = `${SERVER_URL}/api/jira/oauth/redirect?host=${redirectHost}&code=${code}`;

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions)
        .then(handleHttpResponse);
};

export const jiraService = {
    jiraIssueCreation,
    jiraIssueAttachment,
    completeJiraOauth,
    jiraOauth
};
