import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/material";
import { frontAppOauthActions } from "_actions";
import BasicModal from "../../../../Onboarding/Utilities/BasicModal";
import Checkbox from "../../Utilities/Checkbox";
import { buttonContained } from "components/v2/views/Onboarding/Utilities/ButtonContained";

const styles = {
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: 1.5,
    color: '#0C2728',
    fontFamily: 'larsseitRegular',
    fontSize: '16px',
    lineHeight: '120%',
    letterSpacing: '-0.02em',
  },
}

export default function FrontappSetupInboxesModal({
	open, handleClose,
}) {
  const dispatch = useDispatch()
  const {SignupButton} = buttonContained;
  const frontApp = useSelector((state) => state.frontAppOauth)
  const [inboxList, setInboxList] = React.useState([]);
  const [messageDataSourceId, setMessageDataSourceId] = React.useState('');

  React.useEffect(() => {
    if (frontApp.showInboxes && frontApp.inboxList?.length > 0) {
      setInboxList(frontApp.inboxList);
      setMessageDataSourceId(frontApp.messageDataSourceId);
    }
  }, [frontApp])

  const handleItemSelection = (itemIndex, newVal) => {
    setInboxList(inboxList.map((item, index) => {
      if (itemIndex === index) 
        item.checked = newVal
      return item
    }))
  }

  const handleSaveInboxes = (event) => {
    event.preventDefault()
    const formData = {
      messageDataSourceId,
      inboxes: inboxList.filter(item => item.checked),
    }
    dispatch(frontAppOauthActions.createInboxes(formData))
  }

	return (
		<Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        title={'Select Frontapp Inboxes to connect with Chatdesk'}>

        {inboxList?.length > 0 
          ? inboxList.map((inbox, key) => (
            <Box key={key} sx={styles.item}>
              <Checkbox 
                id={inbox.id}
                name="selected"
                label={inbox.name}
                checked={!!inbox.checked}
                onChange={newVal => handleItemSelection(key, newVal)}
              />
            </Box>
          ))
          : <div>No inboxes found</div>}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2.5, width: '100%' }}>
          <SignupButton label="Connect" action={handleSaveInboxes} />
        </Box>
      </BasicModal>
    </Fragment>
	)
}