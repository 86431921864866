import {Box} from "@mui/system";
import styles from "../organizationMainStyles";
import {getAccessibilityMode, getLabel} from "../Utilities/organizationSettingsUtils";
import MessageValidity from "../Utilities/QueueManagement/MessageValidity";
import useGetOrganizationInfo from "../hooks/useGetOrganizationInfo";
import _ from "lodash";
import React from "react";
import {fragmentId} from "../Utilities/organizationSettingsConstants";
import NoDataView from "../EmptyStates/NoDataView";
import QueueManagementEmptyState from "../EmptyStates/QueueManagementEmptyState";

export default function QueueManagement({organizationId}) {
    const mode = getAccessibilityMode();
    const {organizationInfo, loading} = useGetOrganizationInfo(organizationId);
    const organization = _.get(organizationInfo, 'organization');
    const label = getLabel(fragmentId.QUEUE);

    if(_.isEmpty(organizationId) && _.isEmpty(organizationInfo)) {
        return <NoDataView />
    }

    if(loading) {
        return (
            <Box sx={styles.sectionWrapper}>
                <QueueManagementEmptyState/>
            </Box>
        )
    }

    return(
        <Box sx={styles.sectionWrapper}>
            <MessageValidity organization={organization} mode={mode} label={label} />
        </Box>
    )
}