import { authHeader, handleHttpResponse } from '_helpers';
import { SERVER_URL } from 'config';

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 29/01/2019 : 5:52 PM
 */
export const systemSettingsService = {
    updateSystemSettings,
    showSystemSettings,
    deleteAzurePayload
};


function showSystemSettings() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${SERVER_URL}/api/systemSettings/show`, requestOptions).then(handleHttpResponse);
}

function updateSystemSettings(settings) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(settings)
    };

    return fetch(`${SERVER_URL}/api/systemSettings/${settings.id}/update`, requestOptions).then(handleHttpResponse);
}

function deleteAzurePayload(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(`${SERVER_URL}/api/systemSettings/deleteAzurePayload`, requestOptions).then(handleHttpResponse);
}

