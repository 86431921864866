export const roleConstants = {

    GET_ALL_REQUEST: 'ROLES_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'ROLES_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'ROLES_GET_ALL_FAILURE',

    DELETE_REQUEST: 'ROLES_DELETE_REQUEST',
    DELETE_SUCCESS: 'ROLES_DELETE_SUCCESS',
    DELETE_FAILURE: 'ROLES_DELETE_FAILURE',

    GET_DETAILS_REQUEST: 'GET_ROLE_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_ROLE_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_ROLE_DETAILS_FAILURE',

    SET_CURRENT_ROLE: 'SET_CURRENT_ROLE',

    SAVE_ROLE_REQUEST: 'ROLES_SAVE_REQUEST',
    SAVE_ROLE_SUCCESS: 'ROLES_SAVE_SUCCESS',
    SAVE_ROLE_FAILURE: 'ROLES_SAVE_FAILURE',

};