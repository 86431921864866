import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {earningsActions} from "_actions";
import _ from "lodash";
import {PayoutsBuilder} from "../objects/PayoutsBuilder"
import {formatArrivalDate, formatInitiatedDate} from "../EarningUtils";

export default function (agentId) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(earningsActions.getAgentPayouts(agentId))
    },[])

    const payouts = useSelector(state => state.earnings);
    const loading = payouts.agentPayoutsLoading
    const payoutsObj = payouts.agentPayouts
    const payoutsList = _.get(payoutsObj, 'data') || [];
    const rows = []
    const totalCount = 0

    if(!loading && payoutsList.length > 0){

        payoutsList.map( payout => {
            const builder = new PayoutsBuilder()

            builder.setExternalAccount(_.get(payout, 'destination'))
            builder.setAmount(`${_.get(payout, 'currency')} ${_.get(payout, 'amount')}`)
            builder.setInitiatedAt(formatInitiatedDate(_.get(payout, 'initiatedAt')))
            builder.setArrivalDate(formatArrivalDate(_.get(payout, 'arrivalDate')))

            rows.push(builder.payouts)
        })
    }


    const columns = [
        {
            id: 'initiatedAt',
            label: 'Initiated',
            width: 150
        },
        {
            id: 'arrivalDate',
            label: 'Estimated Arrival',
            width: 150
        },
        {
            id: 'destination',
            label: 'External Account',
            width: 140
        },
        {
            id: 'amount',
            label: 'Amount',
            width: 120,
        },
    ];

    return { columns, rows, totalCount,loading };
}

