import React, {Component, Fragment} from "react";
import {IconButton} from "@material-ui/core";
import Settings from '@material-ui/icons/Settings';



class OrganizationMenu extends Component {


    render() {
        const {openModal} = this.props;

        return(
            <Fragment>
                <IconButton
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={(e) => openModal(e)}
                    style={{padding:"5px"}}
                >
                    <Settings style={{fontSize: '22px', color: '#008751'}} />
                </IconButton>
            </Fragment>
        )
    }
}


export {OrganizationMenu}

