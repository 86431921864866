import React, { Component } from "react";

import { Alert, Button, Col, Form, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { DropDownSelect, Loading, TextField, TimezonePicker } from "components/common";
import { organizationActions, roleActions } from "_actions";
import { CheckBox } from "components/common/formFields/CheckBox";
import moment from "moment/moment";
import {formModes, publicAndFeaturedVisibility} from "_constants"
import { map, pluck } from "underscore";
import { isEmpty } from "lodash";


class UserForm extends Component {

    constructor(props) {
        super(props);

        const fetchRoles = this.props.mode === formModes.ADD || ( this.props.mode === formModes.EDIT && isEmpty(this.props.user._embedded.roles) );
        const fetchOrganizations = this.props.mode === formModes.ADD || ( this.props.mode === formModes.EDIT && isEmpty(this.props.user._embedded.organization) );

        this.state = {
            fetchRoles,
            fetchOrganizations
        }
    }

    componentDidMount() {

        if (this.state.fetchRoles) {
            /**
             * Fetch roles to populate
             * the roles dropdown.
             * */
            this.props.dispatch(roleActions.getAll());
        }

        if (this.state.fetchOrganizations) {
            /**
             * Fetch roles to populate
             * the roles dropdown.
             * Since this is used in a dropdown, adding a large max "prevents" pagination.
             * E.g. if they're 12 organization, without a large max, only 10 will show in
             * the organization dropdown of the user form.
             * */
            this.props.dispatch(organizationActions.getAll({max: 10000, visibility: publicAndFeaturedVisibility}))
        }

        this.props.initialize({
            ...this.props.user,
            dateCreated: moment(this.props.user.dateCreated).format('MMMM D, YYYY'),
        });
    }

    /**
     * Transforms roles to a format that is
     * processable by the dropdown.
     * @param roles
     */
    transformRoles = (roles) => {
        return map(roles, function (role) {
            return { id: role.id, name: role.authority }
        });
    };

    /**
     * Transforms organizations to a format that is
     * processable by the dropdown.
     * @param organizations
     */
    transformOrganization = (organizations) => {
        return map(organizations.organizations, function (organization) {
            return { id: organization.id, name: organization.name }
        });
    };

    render() {

        let { submitting, error, handleSubmit, pristine, user, roles, mode, organizations } = this.props;

        if (( mode === formModes.EDIT && !user ) ||
            ( this.state.fetchOrganizations && !organizations ) ||
            ( this.state.fetchRoles && !roles )) {
            return <Loading/>
        }

        if (this.state.fetchRoles) {
            roles = this.transformRoles(roles.roles);
        }

        if (this.state.fetchOrganizations) {
            organizations = this.transformOrganization(organizations);
        }

        return (

            <div>
                <Form onSubmit={handleSubmit(this.props.onSave)}>

                    <Grid>

                        <Row>
                            {error &&
                            <Col xs={12} md={12}>
                                <Alert bsStyle="danger">
                                    <strong>Invalid Form:</strong> {error}
                                </Alert>
                            </Col>
                            }
                        </Row>
                    </Grid>

                    <Field
                        type='text'
                        name='firstName'
                        placeHolder='E.g John'
                        label='First Name'
                        component={TextField}/>

                    <Field
                        type='text'
                        name='lastName'
                        placeHolder='E.g Doe'
                        label='Last Name'
                        component={TextField}/>

                    <Field
                        type='email'
                        name='username'
                        disabled={mode === formModes.EDIT}
                        placeHolder='user@example.com'
                        label='Email'
                        component={TextField}/>

                    <Field
                        hidden={this.props.editedBy !== 'ADMIN'}
                        name="organization"
                        label="Organization"
                        type="select"
                        disabled={!this.state.fetchOrganizations}
                        placeHolder={this.state.fetchOrganizations ? 'User Organization' : user._embedded.organization.name}
                        component={DropDownSelect}
                        options={organizations}/>


                    <Field
                        hidden={this.props.editedBy !== 'ADMIN'}
                        name="role"
                        type="select"
                        label="Role"
                        disabled={!this.state.fetchRoles}
                        placeHolder={this.state.fetchRoles ? 'User Role' : pluck(user._embedded.roles, 'authority').join(',')}
                        component={DropDownSelect}
                        options={roles}/>

                    <Field
                        type='text'
                        name='phoneNumber'
                        placeHolder='E.g +123456789'
                        label='Phone'
                        component={TextField}/>

                    <Field
                        type='text'
                        name='address'
                        label='Address Line 1'
                        component={TextField}/>

                    <Field
                        type='text'
                        name='country'
                        label='Country'
                        component={TextField}/>

                    <Field
                        type='text'
                        name='city'
                        label='City'
                        component={TextField}/>

                    <Field
                        type='text'
                        name='state'
                        label='State'
                        component={TextField}/>

                    <Field
                        hidden={this.props.editedBy !== 'ADMIN'}
                        type='text'
                        name='password'
                        label='Password'
                        disabled={false}
                        value={user.password}
                        component={TextField}/>


                    <Field
                        hidden={true}
                        name='dateCreated'
                        placeHolder='Date Created'
                        label='Date Created'
                        disabled={true}
                        value={moment(user.dateCreated).format('MMMM D, YYYY')}
                        component={TextField}/>

                    <Field
                        type='select'
                        name='timezone'
                        placeHolder='Select Timezone...'
                        label='Timezone'
                        component={TimezonePicker}/>

                    <Field
                        hidden={this.props.editedBy !== 'ADMIN'}
                        name='accountLocked'
                        label='Locked'
                        component={CheckBox}/>


                    <Field
                        hidden={this.props.editedBy !== 'ADMIN'}
                        name='enabled'
                        label='Enabled'
                        component={CheckBox}/>


                    <Row className='text-center'>
                        <Col lg={6} xs={6} md={6}>
                            <Button className="btn btn-info pull-left"
                                    onClick={this.props.onCancelClick}>
                                Exit
                            </Button>
                        </Col>
                        <Col lg={6} xs={6} md={6}>
                            <Button bsStyle="success" type="submit"
                                    className='pull-right'
                                    disabled={pristine || submitting}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { roles, organizations: { organizations } } = state;

    return {
        roles: roles.roles,
        organizations: organizations
    };
};

UserForm = connect(mapStateToProps)(UserForm);

const userForm = reduxForm({
    form: 'UserForm',
    touchOnBlur: false
})(UserForm);

export { userForm as UserForm };
