import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { UserForm } from "components/User";
import { formModes } from "_constants";
import { userActions } from "_actions";
import { history } from "_helpers";
import connect from "react-redux/es/connect/connect";

class AddUser extends Component {

    saveClickHandler = (params) => {
        this.props.dispatch(userActions.createUser(params));
    };

    cancelClickHandler = () => {
        history.push('/users')
    };

    render() {

        return (
            <Grid>

                <br/>
                <h1>Create User</h1>
                <br/>
                <hr/>

                <Row>
                    <Col xs={8} xsOffset={2} style={{
                        padding: '30px',
                        borderRadius: '5px',
                        backgroundColor: 'white',
                        border: 'solid 1px lightGrey'
                    }}>
                        <UserForm
                            editedBy='ADMIN'
                            onSave={this.saveClickHandler}
                            onCancelClick={this.cancelClickHandler}
                            user={{}}
                            mode={formModes.ADD}/>
                    </Col>
                </Row>

            </Grid>
        );
    }
}

const connectedAddUser = connect(null)(AddUser);
export { connectedAddUser as AddUser };
