import {Box} from "@mui/material";
import React, {Fragment} from "react";
import _ from "lodash";
import BasicTable from "../../../common/BasicTable";
import useGetCustomerDataFile from "./hooks/useGetCustomerDataFile";

export default function CsvUploadsTable({segments}) {
    const {rows, columns} = useGetCustomerDataFile(segments)

    if(_.isEmpty(rows)) return <Fragment/>;

    return(
        <Box>
            <BasicTable
                rows={rows}
                columns={columns}
                paperStyle={{borderRadius: '5px'}}
                tableCellStyle={style.tableCell}
                withPagination={false}
            />
        </Box>
    )
}

const style = {
    tableCell: {
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        fontWeight: 500,
        color: '#0C2728',
        padding: '8px 5px',
        '&:first-of-type': {
            paddingLeft: '18px'
        },
        '&:last-of-type': {
            paddingRight: '18px',
        },
    },
}