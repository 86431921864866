import React, {Component, Fragment} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import moment from 'moment'

/**
 * @author Offiong Aniekan <ekanoffiong@gmail.com>
 * @date 20/05/2022 : 11:07 AM
 */
class ListGladlyDataSources extends Component {

    state = {
        updateDataSources: {},
        selectedInboxes: {}
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAllDataSources({dataSource: 'Gladly'}));
    }

    componentDidUpdate() {
        const { gladlyList, updating } = this.props
        if (updating) {
            let selectedInboxes = {}
            for (var i = 0; i < gladlyList.length; i++) {
                var item = gladlyList[i]
                if (item.selectedInboxes) {
                    selectedInboxes[item.id] = item.selectedInboxes.split(',')
                }
            }
            this.setState((prevState) => ({
                ...prevState,
                selectedInboxes
            }), function() {
                const { dispatch } = this.props
                dispatch(msgDataSourceActions.resetInboxData())
            })
        }
    }

    toggleClosedTicketActioning = (postData) => {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.toggleClosedTicketActioning({...postData, msgDataSourceLabel: 'Gladly'}));
    };

    getAccountInboxes(dataSourceId) {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAccountInboxes(dataSourceId))
}

    handleInboxSelection = (e, inboxId, dataSourceId) => {
        this.setState((prevState) => {
            let currentInbox = prevState.selectedInboxes[dataSourceId] || []
            if (e.target.checked) {
                currentInbox.push(inboxId)
            } else {
                currentInbox = currentInbox.filter(item => item !== inboxId)
            }
            return {
                ...prevState,
                selectedInboxes: {
                    ...prevState.selectedInboxes,
                    [dataSourceId]: currentInbox
                }
            }
        })
    };

    saveSelectedInboxes = (dataSourceId) => {
        const { dispatch } = this.props;
        const { selectedInboxes } = this.state;
        dispatch(msgDataSourceActions.saveSelectedInboxes({inboxes: selectedInboxes[dataSourceId].join(','), dataSourceId}));
    };

    render() {
        const { gladlyList, loading, inboxes } = this.props;
        const { updateDataSources, selectedInboxes } = this.state;
        if (loading) {
            return <Loading/>
        }
        return (
            <div>
                <Row>
                    <Col xs={12} md={12}>
                        <table className='table trds-table'>
                            <thead className="font-18-bold">
                                <tr>
                                    <th className="col-md-1">S/N</th>
                                    <th className="col-md-2">Organization</th>
                                    {/*<th>Last Successful Run</th>*/}
                                    <th className="col-md-3">Close Ticket on Reply</th>
                                    <th className="col-md-3">Inboxes</th>
                                </tr>
                            </thead>
                            <tbody>
                            {gladlyList.map((dataSource, index) => (
                                <tr key={index}>
                                    <td className="vertical-align-middle">{index + 1}</td>
                                    <td className="vertical-align-middle">{dataSource.orgName}</td>
                                    <td className="vertical-align-middle">
                                        {dataSource.closeTicketOnReply &&
                                            <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnReply', enable: false})}>
                                                Disable Close Ticket on Reply
                                            </Button>
                                        }
                                        {!dataSource.closeTicketOnReply &&
                                            <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnReply', enable: true})}>
                                                Enable Close Ticket on Reply
                                            </Button>
                                        }
                                    </td>
                                    <td className="vertical-align-middle">
                                        {inboxes[dataSource.id] &&
                                            <Fragment>
                                                <div>List of Inboxes</div>
                                                {inboxes[dataSource.id].map((inbox) => (
                                                    <div key={inbox.id}>
                                                        <label htmlFor={`inbox-check-${inbox.id}`}>
                                                            <input type="checkbox" id={`inbox-check-${inbox.id}`} name="inboxes"
                                                                defaultChecked={selectedInboxes[dataSource.id] && selectedInboxes[dataSource.id].includes(inbox.id)}
                                                                onChange={(e) => this.handleInboxSelection(e, inbox.id, dataSource.id)}
                                                            />
                                                            <span className="padding-left-5">{inbox.name}</span>
                                                        </label>
                                                    </div>
                                                ))}
                                                <Button className="margin-top-5" onClick={() => this.saveSelectedInboxes(dataSource.id)}>
                                                    Update Selected Inboxes
                                                </Button>
                                            </Fragment>
                                        }
                                        {!inboxes[dataSource.id] &&
                                            <Button className="margin-top-5" onClick={() => this.getAccountInboxes(dataSource.id)}>
                                                Get Inboxes
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        );

    }
}

function mapStateToProps(state) {
    const { gladlyAuth: { inboxes, list, loading, updating } } = state;
    return {
        gladlyList: list, loading, inboxes, updating
    };
}

const connectedListGladlyDataSources = connect(mapStateToProps)(ListGladlyDataSources);
export { connectedListGladlyDataSources as ListGladlyDataSources };
