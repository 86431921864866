import React, {Component} from 'react';
import {getCurrentUser} from '_helpers';
import {Redirect} from 'react-router-dom';
import {slackOauthActions, alertActions} from '_actions';
import {alertMessages} from '_constants';
import connect from "react-redux/es/connect/connect";
import {queryStringToObject} from "_helpers";

class SlackConnect extends Component {

    componentDidMount() {

        const search = this.props.location.search.substring(1);
        const values = queryStringToObject(search);

        if (values.code && values.state && isStateValueValid(values.state)) {
            const {organization_id} = getCurrentUser();
            const params = {code: values.code, state: values.state, organizationId: organization_id};
            this.props.dispatch(slackOauthActions.processOauthCode(params))
        } else {
            this.props.dispatch(alertActions.error(alertMessages.SLACK_OAUTH_FAILURE));
        }
    }


    render() {
        const {organization_id} = getCurrentUser();
        return <Redirect
            to={{
                pathname: `/company/${organization_id}/profile`,
                state: {from: this.props.location}
            }}
        />;
    }
}

const mapStateToProps = (state) => {
    const {slackOauth, user} = state;

    return {
        slackOauth,
        user
    }
};

const isStateValueValid = (stateValue) => {
    return stateValue == localStorage.getItem("slackOAuthState")
}

const slackConnect = connect(mapStateToProps)(SlackConnect);
export {slackConnect as SlackConnect};