import React from "react";

export default function MetricsMessageListEmptyState() {
    return(
        <div>
            <div className="message-list-container" >
                <div className="tms-truncate-2-lines empty-message-list-text">
                    No message found for current selection!
                </div>
            </div>
        </div>
    )
}