import React from "react";
import {Box} from "@mui/system";
import styles from "../../Vote/voteMainStyles";
import LoadingPlaceholderBlock from "../../../../common/LoadingPlaceholderBlock";
import {useSelector} from "react-redux";
import _ from "lodash";

export default function SearchResultContainerEmptyState() {
    const message = useSelector(state => state.messages.nextMessage) || {};
    const isProactive = _.get(message, 'isProactive');

    return(
        <Box sx={styles.searchResultPanelRoot}>
            <Box sx={styles.searchResultHeading}>
                <Box sx={style.backButtonContainer}>
                    <Box>
                        <LoadingPlaceholderBlock
                            width={'135px'}
                            height={'14px'}
                            borderRadius={'3px'}
                            backgroundColor={'#EFEAE5'}
                        />
                    </Box>
                </Box>
                <Box sx={{display: 'flex'}}>
                    {
                        !isProactive &&
                        <Box sx={style.addedResponseCountContainer}>
                            <LoadingPlaceholderBlock
                                width={'100px'}
                                height={'14px'}
                                borderRadius={'3px'}
                                backgroundColor={'#EFEAE5'}
                            />
                        </Box>
                    }
                    <Box sx={style.filterContainer}>
                        <LoadingPlaceholderBlock
                            width={'131px'}
                            height={'40px'}
                            borderRadius={'5px'}
                            backgroundColor={'#EFEAE5'}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.searchResultPanelContent}>
                <Box sx={styles.searchResultScrollArea}>
                    <Box>
                        <LoadingPlaceholderBlock
                            height={'100px'}
                            borderRadius={'5px'}
                            backgroundColor={'#EFEAE5'}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const style = {
    backButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center'
    },
    backButtonRoot: {
        fontSize: '14px',
        height: '20px',
        color: '#0C2728',
        fontFamily: 'LarsseitRegular',
        ':hover': {
            color: '#081a1a',
            backgroundColor: 'unset'
        },
        ':disabled': {
            color: '#8c8f8f',
        },
        ':active': {
            outline: '0',
            color: '#0C2728',
        },
    },
    addedResponseCountContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    backButtonIcon: {
        mr: '-5px'
    },
    filterContainer: {
        width: '131px',
        marginLeft: '12px'
    }
}