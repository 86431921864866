import React from "react";
import {Box} from "@mui/system";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import TextButton from "../../../../common/TextButton";

export default function BackToTeams() {
    const handleBackButton = () => {
        window.location.replace('/dashboard');
    }

    return(
        <Box sx={styles.backToTeams}>
            <TextButton
                label={'Back to list of teams'}
                action={handleBackButton}
                sx={styles.backButton}
                rootStyle={styles.backButtonRoot}
                startIcon={<ChevronLeftOutlinedIcon sx={styles.backButtonIcon}/>}
            />
        </Box>
    )
}

const styles = {
    backButton: {
        display: 'flex',
        alignItems: 'center',
        height: '10px'
    },
    backToTeams: {
        px: { xs: 2, sm: 4, md: 4, lg: 4 },
        maxWidth: '1500px',
        mx: 'auto',
        position: 'relative',
        mt: '3px',
        mb: '12px'
    },
    backButtonIcon: {
        mr: '-5px'
    },
    backButtonRoot: {
        fontSize: '14px',
        height: '20px',
        color: '#0C2728',
        textAlign: 'left',
        fontFamily: 'LarsseitMedium',
        ':hover': {
            color: '#081a1a',
            backgroundColor: 'unset'
        },
        ':disabled': {
            color: '#8c8f8f',
        },
        ':active': {
            outline: '0',
            color: '#0C2728',
        },
    },
}