import {InputAdornment, TextField} from "@mui/material";
import React from "react";

export default function BasicTextField({name, label, defaultValue, value, handleChange, placeholder, disabled, onKeyDown, onFocus, type, min, max, sx, startIcon}) {

    return(
        <div>
            <TextField sx={{...styles.textField, ...sx }}
                       InputProps={{
                           sx: {...styles.textField, ...{p: '19px 10px'}, ...sx},
                           startAdornment: (
                               <InputAdornment position="start">
                                   {startIcon}
                               </InputAdornment>
                           ),
                        }}
                       inputProps={{ min: min, max: max }}
                       label={label}
                       variant="outlined"
                       onChange={handleChange}
                       placeholder={placeholder}
                       name={name}
                       disabled={disabled}
                       onKeyDown={onKeyDown}
                       autoComplete='off'
                       onFocus={onFocus}
                       value={value}
                       defaultValue={defaultValue}
                       type={type || 'text'} />
        </div>

    )
}

const styles = {
    textField: {
        width: '100%',
        fontSize: '16px !important',
        height: '45px',
        fontFamily: 'LarsseitRegular',
        backgroundColor: 'unset !important',
        color: '#0C2728',
        [`& fieldset`]: {
            border: '1px solid #0C2728',
            borderRadius: '8px',

        },
        [`& label`]: {
            fontSize: '16px',
        },

        '& label.Mui-focused': {
            color: '#0C2728',
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#0C2728',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },
};