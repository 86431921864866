import {alertMessages, proactiveConstants, requestOrigin} from "_constants";
import {proactiveService} from "_services";
import {Auth} from "../components/Authorization";
import {SnackBar} from "../components/common";
import _ from "lodash";
import {getActionData, getArrayFromObjectArray, refreshPage} from '_helpers';
import {
    arrayToCommaSeparatedValues, buildCreateCampaignPayload,
    buildGetCampaignMetricsPayload, buildUpdateCampaignPayload
} from "../components/v2/views/Proactive/Utilities/CampaignUtils";

function getCompanyCampaigns(params, reqOrigin = '') {
    const request = () => ({
        type: proactiveConstants.GET_COMPANY_CAMPAIGNS_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_COMPANY_CAMPAIGNS_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_COMPANY_CAMPAIGNS_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getCompanyCampaigns(params)
            .then(response => {
                dispatch(success(response.data));
                const campaigns = _.get(response.data, 'items');
                const campaignIdArr = [...new Set(getArrayFromObjectArray(campaigns, 'id'))];
                if(_.isEmpty(campaignIdArr) && reqOrigin === requestOrigin.ADMIN_CAMPAIGNS_VIEW) {
                    SnackBar.showMessage(alertMessages.NO_CAMPAIGNS_FOR_ORG);
                    return;
                }
                const payload = buildGetCampaignMetricsPayload(params, arrayToCommaSeparatedValues(campaignIdArr, true));
                dispatch(getCampaignsMetrics(payload));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getCampaign(id) {
    const request = () => ({
        type: proactiveConstants.GET_CAMPAIGN_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_CAMPAIGN_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_CAMPAIGN_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getCampaign(id)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getCampaignsMetrics(params) {
    const request = () => ({
        type: proactiveConstants.GET_CAMPAIGNS_METRICS_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_CAMPAIGNS_METRICS_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_CAMPAIGNS_METRICS_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getCampaignsMetrics(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function updateCampaign(params) {
    const request = () => ({
        type: proactiveConstants.UPDATE_CAMPAIGN_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.UPDATE_CAMPAIGN_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.UPDATE_CAMPAIGN_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.updateCampaign(params)
            .then(response => {
                dispatch(success(response.data));
                SnackBar.showMessage(alertMessages.UPDATE_CAMPAIGN_SUCCESS);
                window.location.replace('/admin/campaign');
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(error.response.data.message || alertMessages.UPDATE_CAMPAIGN_ERROR);
        });
    }
}

function deleteCampaignAttribute(params) {
    const request = () => ({
        type: proactiveConstants.DELETE_CAMPAIGN_ATTRIBUTE_REQUEST,
        requestAttributeId: params.attributeId
    });
    const success = (response) => ({
        type: proactiveConstants.DELETE_CAMPAIGN_ATTRIBUTE_SUCCESS,
        payload: response
    });
    const failure = (error) => ({
        type: proactiveConstants.DELETE_CAMPAIGN_ATTRIBUTE_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.deleteCampaignAttribute(params)
            .then(() => {
                dispatch(success(params));
                SnackBar.showMessage(alertMessages.DELETE_CAMPAIGN_ATTRIBUTE_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.DELETE_CAMPAIGN_ATTRIBUTE_ERROR);
        });
    }
}


function updateCampaignSegmentStatus(payload) {
    return dispatch => {
        dispatch(getActionData(proactiveConstants.UPDATE_SEGMENT_STATUS_REQUEST));
        proactiveService.updateCampaignSegmentStatus(payload)
            .then((response) => {
                dispatch(getActionData(proactiveConstants.UPDATE_SEGMENT_STATUS_SUCCESS, response.data));
                SnackBar.showMessage(alertMessages.SEGMENT_UPDATE_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(proactiveConstants.UPDATE_SEGMENT_STATUS_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getAllCampaigns(params) {
    const request = () => ({
        type: proactiveConstants.GET_ALL_CAMPAIGNS_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_ALL_CAMPAIGNS_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_ALL_CAMPAIGNS_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getAllCampaigns(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getCompanyTemplates(params) {
    const request = () => ({
        type: proactiveConstants.GET_COMPANY_TEMPLATES_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_COMPANY_TEMPLATES_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_COMPANY_TEMPLATES_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getCompanyTemplates(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getAllTemplates() {
    const request = () => ({
        type: proactiveConstants.GET_ALL_TEMPLATES_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_ALL_TEMPLATES_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_ALL_TEMPLATES_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getAllTemplates()
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function createCampaign(params) {
    const request = () => ({
        type: proactiveConstants.CREATE_CAMPAIGN_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.CREATE_CAMPAIGN_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.CREATE_CAMPAIGN_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.createCampaign(params)
            .then(response => {
                dispatch(success(response.data));
                refreshPage();
                SnackBar.showMessage(alertMessages.CREATE_CAMPAIGN_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(error.response.data.message || alertMessages.CREATE_CAMPAIGN_ERROR);
        });
    }
}

function getMsgDataSources(params) {
    const request = () => ({
        type: proactiveConstants.GET_MSG_DATA_SOURCES_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_MSG_DATA_SOURCES_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_MSG_DATA_SOURCES_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getMsgDataSources(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getOrgMsgDataSourcesBySourceName(params) {
    const request = () => ({
        type: proactiveConstants.GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_SUCCESS,
        payload: response
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_MSG_DATA_SOURCES_BY_SOURCE_NAME_FAILURE, error,
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getOrgMsgDataSourcesBySourceName(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getCustomerIoSegments(params) {
    const request = () => ({
        type: proactiveConstants.GET_CUSTOMER_IO_SEGMENT_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_CUSTOMER_IO_SEGMENT_SUCCESS,
        payload: response
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_CUSTOMER_IO_SEGMENT_FAILURE, error,
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getCustomerIoSegments(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getChannels() {
    const request = () => ({
        type: proactiveConstants.GET_CHANNELS_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_CHANNELS_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_CHANNELS_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getChannels()
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function generateReplies(params) {
    const request = () => ({
        type: proactiveConstants.GENERATE_REPLIES_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GENERATE_REPLIES_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GENERATE_REPLIES_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.generateReplies(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function createTemplate(params) {
    const request = () => ({
        type: proactiveConstants.CREATE_TEMPLATE_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.CREATE_TEMPLATE_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.CREATE_TEMPLATE_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.createTemplate(params)
            .then(response => {
                dispatch(success(response.data));
                SnackBar.showMessage(alertMessages.CREATE_TEMPLATE_SUCCESS);
                refreshPage();
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.CREATE_TEMPLATE_ERROR);
        });
    }
}

function getTemplate(id) {
    const request = () => ({
        type: proactiveConstants.GET_TEMPLATE_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_TEMPLATE_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_TEMPLATE_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getTemplate(id)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function updateTemplate(params) {
    const request = () => ({
        type: proactiveConstants.UPDATE_TEMPLATE_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.UPDATE_TEMPLATE_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.UPDATE_TEMPLATE_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.updateTemplate(params)
            .then(response => {
                dispatch(success(response.data));
                SnackBar.showMessage(alertMessages.UPDATE_TEMPLATE_SUCCESS);
                window.location.replace('/company/template');
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.UPDATE_TEMPLATE_ERROR);
        });
    }
}

function updateResponse(id, params) {
    const request = (responseId) => ({
        type: proactiveConstants.UPDATE_RESPONSE_REQUEST, responseId
    });
    const success = (response) => ({
        type: proactiveConstants.UPDATE_RESPONSE_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.UPDATE_RESPONSE_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request(id));
        proactiveService.updateResponse(id, params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.UPDATE_RESPONSE_ERROR);
        });
    }
}

function getMediaTypes(params) {
    const request = () => ({
        type: proactiveConstants.GET_MEDIA_TYPES_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_MEDIA_TYPES_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_MEDIA_TYPES_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getMediaTypes(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getProactiveMessagesList(params) {
    const request = () => ({
        type: proactiveConstants.GET_PROACTIVE_MESSAGES_LIST_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_PROACTIVE_MESSAGES_LIST_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_PROACTIVE_MESSAGES_LIST_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getProactiveMessagesList(params)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function getProactiveMsgConversation(id) {
    const request = () => ({
        type: proactiveConstants.GET_PROACTIVE_MSG_CONVERSATION_REQUEST
    });
    const success = (response) => ({
        type: proactiveConstants.GET_PROACTIVE_MSG_CONVERSATION_SUCCESS,
        payload: { ...response }
    });
    const failure = (error) => ({
        type: proactiveConstants.GET_PROACTIVE_MSG_CONVERSATION_FAILURE, error,
        payload: { error }
    });

    return dispatch => {
        dispatch(request());
        proactiveService.getProactiveMsgConversation(id)
            .then(response => {
                dispatch(success(response.data));
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(failure(error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR);
        });
    }
}

function clearConversation() {
    function success() {
        return { type: proactiveConstants.CLEAR_CONVERSATION };
    }

    return (dispatch) => {
        dispatch(success());
    };
}

function exportProactiveMessages(payload) {
    function request() {
        return { type: proactiveConstants.EXPORT_PROACTIVE_MESSAGES_REQUEST };
    }

    function success(responses) {
        return { type: proactiveConstants.EXPORT_PROACTIVE_MESSAGES_SUCCESS, payload: responses };
    }

    function failure(error) {
        return { type: proactiveConstants.EXPORT_PROACTIVE_MESSAGES_FAILURE, error: error };
    }

    return (dispatch) => {
        dispatch(request());
        proactiveService.exportProactiveMessages(payload)
            .then(
                responses => dispatch(success(responses)),
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GENERIC_ERROR)
                },
            );
    };
}

function uploadCustomerDataFile(formData, values, parsedDate, id, channel) {
    function request() {
        return { type: proactiveConstants.UPLOAD_CUSTOMER_DATA_FILE_REQUEST };
    }
    function success(response) {
        return { type: proactiveConstants.UPLOAD_CUSTOMER_DATA_FILE_SUCCESS, payload: response };
    }
    function failure(error) {
        return { type: proactiveConstants.UPLOAD_CUSTOMER_DATA_FILE_FAILURE, error: error };
    }
    return (dispatch) => {
        dispatch(request());
        proactiveService.uploadCustomerDataFile(formData)
            .then((response) => {
                dispatch(success(response.data));
                let customerDataFileId = _.get(response, 'data.id');
                if(id){
                    const payload = buildUpdateCampaignPayload(id, {...values, customerDataFileId}, parsedDate, channel);
                    dispatch(proactiveActions.updateCampaign(payload));
                } else {
                    const payload = buildCreateCampaignPayload({...values, customerDataFileId}, parsedDate);
                    dispatch(proactiveActions.createCampaign(payload));
                }
            }).catch((error) => {
            dispatch(failure(error));
            SnackBar.showMessage(error.response.data.message)
        })
    };
}

function getSegmentTypes(params){
    function request() {
        return { type: proactiveConstants.GET_SEGMENT_TYPES_REQUEST };
    }
    function success(response) {
        return { type: proactiveConstants.GET_SEGMENT_TYPES_SUCCESS, payload: response };
    }
    function failure(error) {
        return { type: proactiveConstants.GET_SEGMENT_TYPES_FAILURE, error: error };
    }
    return (dispatch) => {
        dispatch(request())
        proactiveService.getSegmentTypes(params)
            .then((response) => {
                dispatch(success(response.data))
            }).catch((error) => {
            dispatch(failure(error))
            SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        })
    }
}


export const proactiveActions = {
    getCampaign,
    getCampaignsMetrics,
    getCompanyCampaigns,
    getAllCampaigns,
    getCompanyTemplates,
    getAllTemplates,
    createCampaign,
    updateCampaign,
    getMsgDataSources,
    getOrgMsgDataSourcesBySourceName,
    getChannels,
    generateReplies,
    createTemplate,
    getTemplate,
    updateTemplate,
    updateResponse,
    getMediaTypes,
    getProactiveMessagesList,
    getProactiveMsgConversation,
    clearConversation,
    exportProactiveMessages,
    getCustomerIoSegments,
    deleteCampaignAttribute,
    updateCampaignSegmentStatus,
    uploadCustomerDataFile,
    getSegmentTypes
}