import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

function getAll(params = {}) {

    return fetch(`${SERVER_URL}/api/subscriptions?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function getSubscription(id) {

    return fetch(`${SERVER_URL}/api/subscriptions/${id}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function createSubscription(data) {

    return fetch(`${SERVER_URL}/api/subscriptions`,
        {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

function updateSubscription(data, id) {

    return fetch(`${SERVER_URL}/api/subscriptions/${id}`,
        {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}

function subscriptionHistory(orgId, params) {

    return fetch(`${SERVER_URL}/api/organizations/${orgId}/subscriptions/history?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function addOrgSubscription(organization, subscription) {

    return fetch(`${SERVER_URL}/api/organizations/${organization}/subscriptions/${subscription}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

export const subscriptionsService = {
    getAll,
    getSubscription,
    createSubscription,
    updateSubscription,
    subscriptionHistory,
    addOrgSubscription
};

