import React from 'react';
import { Field, reduxForm } from "redux-form";
import { Table, TableRow, TableBody, TableCell, TableHead } from '@material-ui/core';
import moment from "moment";
import {organizationActions} from "../../_actions";
import {Loading} from "../common";
import {DateRange} from "../common/formFields/DateRange";
import {isEmpty} from "lodash";
import connect from "react-redux/es/connect/connect";


class Profitability extends React.Component {
    constructor(props) {
      super(props);
        this.state = {
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().add(2, 'd').format('YYYY-MM-DD HH:mm:ss')
        };
    }

    componentDidMount() {
        this.props.dispatch(organizationActions.getOrganizationsProfitability(this.state));
    }

    /**
     * Handles date picker update. This method update the selected dates to the state
     * and dispatches the getOrganizationsProfitability action for the date selected
     * @returns {function(*)}
     */
    handleDatePickerUpdate = (event, data) => {
        if(event.type === 'apply'){
            this.setState({
                startDate : data.startDate.format('YYYY-MM-DD HH:mm:ss'),
                endDate   : data.endDate.format('YYYY-MM-DD HH:mm:ss')
            }, () => {
                this.props.dispatch(organizationActions.getOrganizationsProfitability(this.state))
            });
        }
    };

    render() {
        const { loading, organizationsProfitability } = this.props;
        const { startDate, endDate } = this.state;
        return (

            <div className='container-pane container-pane-probability-page'>
                {loading && <Loading/>}

                <label className="calendar-today tms-inbox-calendar profitability-page-date-picker">
                    <Field
                        submittedStartDate={startDate}
                        submittedEndDate={endDate}
                        id="datePicker"
                        name="datePicker"
                        defaultDateRange="today"
                        onEvent={this.handleDatePickerUpdate}
                        values={startDate}
                        component={DateRange}
                    />
                </label>

                {!loading  && isEmpty(organizationsProfitability) &&
                <div className="font-20-bold tms-align-text-center padding-20-0 profitability-page-table">
                    No companies available for this industry
                </div>}

                {!loading && !isEmpty(organizationsProfitability) &&
                <Table className="tms-profitability-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Company</TableCell>
                            <TableCell>Profitability</TableCell>
                            <TableCell>Amount Charged</TableCell>
                            <TableCell>Amount Paid</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organizationsProfitability && organizationsProfitability.map(org => {
                            return (
                                <TableRow key={org.id}>
                                    <TableCell>{org.name}</TableCell>
                                    <TableCell>{org.profitability}</TableCell>
                                    <TableCell>{org.amt_charged}</TableCell>
                                    <TableCell>{org.amt_paid}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {organizations: { organizationsProfitability, loading}} = state
    return {
        loading,
        organizationsProfitability
    };
}
Profitability = connect(mapStateToProps)(Profitability);
const profitabilityPage = reduxForm({
    form: 'Profitability'
})(Profitability);
export {profitabilityPage as Profitability};