export const dataSourceHandleConstants = {
    GET_ALL_REQUEST: 'GET_ALL_DATA_SOURCE_HANDLES_REQUEST',
    GET_ALL_SUCCESS: 'GET_ALL_DATA_SOURCE_HANDLES_SUCCESS',
    GET_ALL_FAILURE: 'GET_ALL_DATA_SOURCE_HANDLES_FAILURE',

    DEACTIVATE_ALL_REQUEST: 'DEACTIVATE_ALL_DATA_SOURCE_HANDLES_REQUEST',
    DEACTIVATE_ALL_SUCCESS: 'DEACTIVATE_ALL_DATA_SOURCE_HANDLES_SUCCESS',
    DEACTIVATE_ALL_FAILURE: 'DEACTIVATE_ALL_DATA_SOURCE_HANDLES_FAILURE',

    GET_DETAILS_REQUEST: 'GET_DATA_SOURCE_HANDLE_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_DATA_SOURCE_HANDLE_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_DATA_SOURCE_HANDLE_DETAILS_FAILURE',

    UPDATE_HANDLE_REQUEST: 'UPDATE_DATA_SOURCE_HANDLE_REQUEST',
    UPDATE_HANDLE_SUCCESS: 'UPDATE_DATA_SOURCE_HANDLE_SUCCESS',
    UPDATE_HANDLE_FAILURE: 'UPDATE_DATA_SOURCE_HANDLE_FAILURE',

    HANDLE_TOGGLE_STATES: [
        {
            displayName: 'On',
            value: "LIVE",
            id: 1
        },
        {
            displayName: 'Practice',
            value: "PRACTICE",
            id: 2
        },
        {
            displayName: 'Off',
            value: "OFF",
            id: 3
        }
    ]
};

export const handleTypeNames = {
    CHAT: 'CHAT',
    EMAIL: 'EMAIL',
    SOCIAL_FEED: 'SOCIAL_FEED',
    SOCIAL_FEED_WITH_LINKS_AND_HANDLE_MENTIONS: 'SOCIAL_FEED_WITH_LINKS_AND_HANDLE_MENTIONS',
    SOCIAL_FEED_WITH_HANDLE_MENTIONS_BUT_NO_LINKS: 'SOCIAL_FEED_WITH_HANDLE_MENTIONS_BUT_NO_LINKS',
    PROACTIVE_EMAIL: 'PROACTIVE_EMAIL',
    PROACTIVE_POST: 'PROACTIVE_POST',
    TIKTOK_SHOP_POST: 'TIKTOK_SHOP_POST'
};
