import {Box} from "@mui/system";
import defaultLogo from "components/images/business.svg";
import {getLogoUrl} from "_helpers";
import _ from 'lodash';

export default function CompanyLogo({ logoUrl = '' }) {
    const addDefaultSrc = (ev) => {
        ev.target.src = defaultLogo
    };

    const companyLogo = !_.isEmpty(logoUrl) ? getLogoUrl(logoUrl) : '';

    return(
        <Box sx={styles.imgContainer}>
            <img src={companyLogo}
                 onError={addDefaultSrc}
                 alt="company logo"
                 style={styles.img}/>
        </Box>
    )
}

const styles = {
    imgContainer: {
        minWidth: '40px',
        maxWidth: '200px',
        height: '50px',
        py: '3px',
        display: 'flex',
        alignItems: 'center',
    },
    img: {
        minWidth: '40px',
        maxWidth: '200px',
        maxHeight: '40px',
        borderRadius: '5px',
    }
}