import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {organizationSettingsActions} from "_actions";

export default function useGetDataSourceHandleInfo(organizationId) {
    const dispatch = useDispatch();

    useEffect(() => {
        if(!_.isEmpty(organizationId)) {
            dispatch(organizationSettingsActions.fetchDataSourceHandleInfo(organizationId));
        }
    }, [organizationId]);

    const organizationSettings = useSelector(state => state.organizationSettings);
    const loading = organizationSettings.loadingHandleInfo;
    const handleInfo = organizationSettings.handleInfo;

    return {handleInfo, loading};
}
