import { useEffect, useMemo, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {proactiveActions} from "_actions";
import _ from "lodash";

export default function () {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            dispatch(proactiveActions.getChannels());
            return () => effectRan.current = true;
        }
    }, []);

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingChannels;
    const channelsObj = proactive.channels;

    const channels = useMemo(() => {
        if(!loading && !_.isEmpty(channelsObj)) {
            return _.get(channelsObj, 'items');
        }
    }, [ channelsObj ]) || [];

    return { channels, loading };
}