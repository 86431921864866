import React, {useState} from "react";
import {Box, useTheme} from "@mui/material";
import FilterForm from "../Utilities/FilterForm"
import moment from "moment";
import useLocationQueryParams from "../../../hooks/useLocationQueryParams";
import {useDispatch, useSelector} from "react-redux";
import {dataSourceHandleActions, messageActions} from '_actions';
import useGetMessages from "../Utilities/hooks/useGetMessages";
import BasicTable from "../../../common/BasicTable";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {requestOrigin} from "_constants";

export default function Inbox(){
    const ALL_MESSAGES = 'all_messages';
    const offsetBase = 10;
    const dispatch = useDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { params, setParams } = useLocationQueryParams();
    let { startDate, endDate, userAction, channels } = params;

    channels =  channels ? channels.split(",") : [];
    const currentUser = JSON.parse(localStorage.user);

    const dataSourceHandles = useSelector((state) => state.dataSourceHandles.dataSourceHandles)

    const offset = params.offset || 0;
    const messagesFilter = [{id: 0, name: 'Priority'}, {id: 1, name: 'All Messages'}]
    const formattedStartDate = startDate ? moment(startDate).startOf('day') : moment().startOf('year');
    const formattedEndDate = endDate ? moment(endDate).endOf('day') : moment();
    startDate = formattedStartDate.format('YYYY-MM-DD HH:mm:ss');
    endDate = formattedEndDate.format('YYYY-MM-DD HH:mm:ss');

    const [key, setKey] = useState(() => {return userAction === ALL_MESSAGES ? 1 : 0})
    const [filterParams, setFilterParams] = useState({
        startDate,
        endDate,
        handleStatus: 'LIVE',
        organization: currentUser.organization_id,
        userAction,
        channels,
        offset
    })

    const populateParams = (key, channels, offset, startDate, endDate) => {

        startDate = moment(startDate)
            .format('YYYY-MM-DD HH:mm:ss');
        endDate = moment(endDate)
            .format('YYYY-MM-DD HH:mm:ss');
        let userAction;

        if (parseInt(key, 10) === 0)
            userAction =  'priority';
        else
            userAction = ALL_MESSAGES;

        offset = offset || 0;

        setKey(key)
        setFilterParams({
            ...filterParams,
            startDate,
            endDate,
            userAction,
            channels,
            offset
        })

        return {
            channels,
            startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            userAction,
            offset,
            handleStatus: filterParams.handleStatus,
            organization: filterParams.organization
        }
    }

    const getChannels = (dataSourceHandles, returnValue) => {
        let channels = [];

        dataSourceHandles.forEach(dataSourceHandle => {
            if (returnValue === "ids") {
                channels.push(dataSourceHandle.trendsId )
            } else {
                let dataSource =  {
                    value: dataSourceHandle.trendsId,
                    name : dataSourceHandle.name
                };
                channels.push(dataSource)
            }
        });

        return channels
    }

    const handleFilterFormSubmit = (props) => {
        let { startDate, endDate, userAction, channels,handleStatus } = filterParams
        const offset = 0

        if (props.dateRange) {
            props.startDate = props.dateRange.startDate.format('YYYY-MM-DD HH:mm:ss');
            props.endDate = props.dateRange.endDate.format('YYYY-MM-DD HH:mm:ss');
        } else {
            props.startDate = startDate;
            props.endDate = endDate;
        }

        delete props.dateRange;

        props.userAction = userAction;
        props.channels = channels;

        if (channels.length === 0) {
            props.channels = getChannels(dataSourceHandles, "ids");
        }

        const params = {
            ...filterParams,
            offset
        };

        const {channels: _channels, startDate: _startDate, endDate: _endDate} = props

        const populatedParams = populateParams(key, _channels, offset, _startDate, _endDate);
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const { organization : { id } } = currentUser._embedded;

        setParams({ ...params, ...props })
        selectRequestAction(userAction, populatedParams)
        dispatch(dataSourceHandleActions.getAll({organization: id, max: 100, handleStatus}));
    }

    const handleMessageFilterChange = (event, startDate, endDate) => {
        const target = event.target;
        let _key;

        if (target.name === "userActions") {
            _key =  target.value;
        } else {
            _key = key;
        }

        const { channels, offset } = filterParams;
        populateParams(_key, channels, offset, startDate, endDate);
    };

    const handleChannelsFilterChange = (channels) => {
        const { startDate, endDate, offset } = filterParams;
        populateParams(key, channels, offset, startDate, endDate);
    };

    const [page, setPage] = useState(() => {
        const offset = Number(params.offset);
        return offset ? (offset / offsetBase) : 0;
    });

    const handlePageSelect = (event, params) => {
        setPage(params)
        let { channels, startDate, endDate, userAction } = filterParams

        if (channels.length === 0) {
            channels =  getChannels(dataSourceHandles, "ids");
        }

        const _offset = params * offsetBase
        const populatedParams = populateParams(key, channels, _offset, startDate, endDate)

        selectRequestAction(userAction, populatedParams)
    };

    let _channels, channelsIds;

    if (dataSourceHandles) {
        _channels = getChannels(dataSourceHandles);
        channelsIds = getChannels(dataSourceHandles, "ids");
    }

    const selectRequestAction = (userAction, params) => {
        if (userAction === ALL_MESSAGES){
            const currentUser = JSON.parse(localStorage.getItem('user'));
            const { organization : {id} } = currentUser._embedded;
            dispatch(messageActions.getAllFromTrends(params, id));
        }
        else{
            dispatch(messageActions.getPriorityMessages(params));
        }
    };

    const {rows, columns, totalCount, loading,isEscalations } = useGetMessages()

    return(
        <Box sx={styles.rootWrapper}>
            <Box sx={{...styles.container, flexDirection: isMobile ? "column" : "row"}}>
                <Box sx={styles.containerLeft}>
                    <FilterForm
                        startDate={filterParams.startDate}
                        endDate={filterParams.endDate}
                        messagesAction={key}
                        messagesFilter={messagesFilter}
                        channelsFilter={_channels}
                        channelsAction={filterParams.channels}
                        channelsIds={channelsIds}
                        handleChange={(event) => handleMessageFilterChange(event, filterParams.startDate, filterParams.endDate) }
                        handleChannelsFilterChange={handleChannelsFilterChange}
                        onSubmit={(e) => handleFilterFormSubmit(e)}
                    />
                </Box>
                <Box sx={styles.containerRight}>
                    { loading &&
                        <Box sx={{height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress size={30} sx={{ color: '#07857C'}}/>
                        </Box>
                    }

                    { !loading && totalCount > 0 &&
                        <>
                            { isEscalations &&
                                <Box component={'h1'} sx={styles.headingText}>Escalations</Box>
                            }

                            <BasicTable
                                rows={rows}
                                columns={columns}
                                totalCount={totalCount}
                                page={page}
                                loading={loading}
                                isPaginationDisabled={loading}
                                handlePageChange={handlePageSelect}
                            />
                        </>
                    }
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    rootWrapper: {
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
        display: 'flex',
        gap: "40px",
        alignItems: "flex-start"
    },
    containerLeft: {
        minWidth: "290px",
        maxWidth: "290px",
        padding: "33px",
        background: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #dee2e0",
        boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)"
    },
    containerRight: {
        width: "100%"
    },
    headingText: {
        m: 0,
        fontFamily: 'LarsseitMedium',
        fontSize: 24,
        lineHeight: 0.9,
        letterSpacing: '-0.02em',
        color: '#0C2728',
        mb: 2
    }
}