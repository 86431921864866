import {gladlyAuthService} from '_services';
import {oauthConstants, alertMessages} from "_constants";
import {SnackBar} from "components/common";
import {history} from "_helpers";

function verifyKey(params){
    function request() {
        return {type: oauthConstants.GLADLY_AUTH_REQUEST};
    }

    function success(payload) {
        return {type: oauthConstants.GLADLY_AUTH_SUCCESS, payload};
    }

    function failure() {
        return {type: oauthConstants.GLADLY_AUTH_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        gladlyAuthService.verifyApiKey(params)
            .then(
                (res) => {
                    dispatch(success(res));
                    SnackBar.showMessage(alertMessages.GLADLY_AUTH_SUCCESS)
                    history.push('/company/channels')
                },
                (error) => {
                    dispatch(failure(error));
                    SnackBar.showMessage(alertMessages.GLADLY_AUTH_FAILURE)
                },
            );
    };
}

function getInboxes(params){
    function request() {
        return {type: oauthConstants.GLADLY_INBOX_REQUEST};
    }

    function success(payload) {
        return {type: oauthConstants.GLADLY_INBOX_SUCCESS, payload};
    }

    function failure() {
        return {type: oauthConstants.GLADLY_INBOX_FAILURE}
    }

    return (dispatch) => {
        dispatch(request());

        gladlyAuthService.getInboxes(params)
            .then(
                (res) => {
                    dispatch(success(res));
                },
                (error) => {
                    dispatch(failure(error));
                },
            );
    };
}

export const gladlyAuthActions = {
    verifyKey,
    getInboxes
}
