import {Box} from "@mui/system";
import _ from "lodash";
import {isValidJson, parseHtml} from "_helpers";
import {tiktokShopMessageType} from "../../../utilities/TikTokShopDataUtils";
import TikTokShopProductCard from "./TikTokShopProductCard";
import TikTokShopOrderCard from "./TikTokShopOrderCard";
import TikTokShopImageCard from "./TikTokShopImageCard";
import TikTokShopVideoCard from "./TikTokShopVideoCard";

export default function TikTokShopContent({text, sourceName}) {

    const Content = () => {
        return (
            <Box>
                { parseHtml(text, sourceName) }
            </Box>
        )
    }

    if(isValidJson(text)) {
        let typeObj = JSON.parse(text || '{}');
        const type = _.get(typeObj, 'type');

        switch (type) {
            case tiktokShopMessageType.PRODUCT_CARD:
            case tiktokShopMessageType.BUYER_ENTER_FROM_PRODUCT:
                return <TikTokShopProductCard typeObj={typeObj} />;

            case tiktokShopMessageType.ORDER_CARD:
            case tiktokShopMessageType.BUYER_ENTER_FROM_ORDER:
                return <TikTokShopOrderCard typeObj={typeObj} />;

            case tiktokShopMessageType.IMAGE:
                return <TikTokShopImageCard typeObj={typeObj} />;

            case tiktokShopMessageType.VIDEO:
                return <TikTokShopVideoCard typeObj={typeObj} />;

            default:
                return <Content />;
        }
    }

    return <Content />;
}