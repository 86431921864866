import React, {Fragment} from "react";
import {Box} from "@mui/system";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {makeStyles} from "@mui/styles";
import BasicModal from "../../../Utilities/BasicModal";
import {buttonContained} from "../../../Utilities/ButtonContained";
import chatdeskLogo from "components/images/logos/logo_chatdesk.svg";

export default function ConnectChannelErrorModal({ open, handleOpen, handleClose, action, message }) {

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {ActionButton} = buttonContained;

  return(
    <Fragment>
      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        withoutTitle>

        <Box sx={isMobile ? {marginTop: '10px'} : {}}>
          <Box sx={{display: 'flex', justifyContent: 'center', }}>
            <img src={chatdeskLogo} style={{height: '100%'}} alt={`Chatdesk logo`}/>
          </Box>
          <Box className={isMobile ? classes.headerTextMobile : classes.headerText}>
            Channel connection failed
          </Box>
          <Box className={isMobile ? classes.descriptionTextMobile : classes.descriptionText}>
            {message}
          </Box>

          <Box className={classes.buttonContainer}>
            <Box className={isMobile ? classes.buttonMobile : {}}>
              <ActionButton label={'Try again'} action={handleClose}/>
            </Box>
          </Box>
        </Box>
      </BasicModal>
    </Fragment>
  )
}

const useStyles = makeStyles({
  buttonMobile: {
    width: '100%'
  },
  headerText: {
    marginTop: '25px',
    textAlign: 'center',
    fontSize: '24px',
    fontFamily: 'larsseitMedium'
  },
  headerTextMobile: {
    marginTop: '25px',
    textAlign: 'center',
    fontSize: '22px',
    fontFamily: 'larsseitMedium'
  },
  descriptionText: {
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '24px',
    fontFamily: 'larsseitRegular'
  },
  descriptionTextMobile: {
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '22px',
    fontFamily: 'larsseitRegular'
  },
  buttonContainer: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'center'
  }
});
