import React, {useEffect} from "react";
import {frontAppOauthActions} from "_actions";
import {CLIENT_URL} from "config";
import {useDispatch, useSelector} from "react-redux";
import {utilityService} from "_services";
import setRedirectUrl from "../../Utilities/setRedirectUrl";

export default function useFrontappSetup({
	callbackPath
}){
	const dispatch = useDispatch();
  const frontapp = useSelector((state) => state.frontAppOauth);
  const showInboxes = React.useMemo(() => frontapp.showInboxes, [frontapp.showInboxes]);
  const handleClose = () => {
    dispatch(frontAppOauthActions.closeInboxModal())
  }

	const performAction = () => {
		const callbackUrl = `${CLIENT_URL}${callbackPath}`;
    setRedirectUrl(callbackUrl)
    dispatch(frontAppOauthActions.initiateOauth(callbackUrl));
	}

  useEffect(() => {
    if (!frontapp.loading && frontapp.authUrl.url){
      utilityService.redirectToAuthUrl(frontapp.authUrl.url)
    }
  }, [frontapp]);

  return {
    initiateFrontappSetup: performAction,
    frontappSetupInboxesOpen: showInboxes, 
    handleFrontappSetupInboxesClose: handleClose
  }
}






