import {Auth} from "../components/Authorization";
import {SnackBar} from "../components/common";

/**
 * Takes an object and generates a url query string from its entries and also encodes the values.
 * encodeURIComponent encodes every other special character except %, hence the replacement with the encode value %25
 * @param params
 * @returns {string}
 */
export const objectToQueryString = (params) => {
    return params && true
        ? Object.keys(params)
            .map(key => key + '=' + encodeURIComponent(`${params[key]}`.replace(/%/g, "%25")))
            .join('&')
        : ''
};

/**
 * Given an encoded query string, this method converts the query string to a JSON object, maps each key/value pairs and decodes the value.
 * This was done to stop special characters like "&" from breaking the code
 * @param queryString : "a=1&b=2&c=anotherthing"
 * @returns {object} : {a: 1, b: 2, c: "anotherThing"}
 */
export const queryStringToObject = (queryString) => {
    let params = new URLSearchParams(queryString);
    const obj = {};
    for (const [key, value] of params.entries()) {
        obj[key] = decodeURIComponent(value);
    }
    return obj
};

/**
 * A utility method for handling Promises (Promise<Response>).
 * @param response
 */
export const handleHttpResponse = (response) => {
    if (response.status === 401){
        Auth.processLogout();
        return;
    } else if (!response.ok) {
        return Promise.reject(response.text().then(function (text) {
            return text ? JSON.parse(text) : {}
        }));
    }
    return response.text().then(function (text) {
        return text ? JSON.parse(text) : {}
    })
};

export const handleHttpResponseAndReturnError = (response) => {

    if(response.status === 401){
        Auth.processLogout();
        return;
    }

    if (!response.ok) {
        return response.text().then(text => {
            const parsedError = JSON.parse(text);
            let message = ''
            if (parsedError){
                message = parsedError.userMessage ? parsedError.userMessage : parsedError.message
            }
            throw new Error(message, { cause: { errorCode: parsedError?.errorCode } })
        })
    }

    return response.text().then(function (text) {
        return text ? JSON.parse(text) : {}
    })
}


export const displayErrorMessage = (error, fallback) => {
    let message = fallback
    if (error && (error.userMessage || error.message)){
        message = error.userMessage ? error.userMessage : error.message
    }
    SnackBar.showMessage(message)
};


export const getJSON = async (url, options) => {
    const response = await fetch(url, options)

    if (!response.ok){
        return Promise.reject(response)
    }

    return response.json()
}
