import {Box} from "@mui/system";
import LoadingPlaceholderBlock from "../../../../../../common/LoadingPlaceholderBlock";
import styles from "./currentBalanceStyles";

export default function CurrentBalanceEmptyState() {
    return(
        <Box sx={styles.container}>
            <Box sx={styles.headingText}>
                <Box sx={styles.headingTextPlaceholder}>
                    <LoadingPlaceholderBlock
                        height={'14px'}
                        borderRadius={'3px'}
                        backgroundColor={'#EFEAE5'}
                    />
                </Box>
            </Box>
            <Box sx={styles.balanceText}>
                <LoadingPlaceholderBlock
                    width={'50px'}
                    height={'24px'}
                    borderRadius={'3px'}
                    backgroundColor={'#EFEAE5'}
                />
            </Box>
        </Box>
    )
}