import React, {Fragment} from 'react';
import Slider from "react-slick";
import moment from 'moment/moment'
import { Row} from 'react-bootstrap';
import './SummaryPageStyles.css';


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 2,
};

function TicketsSlider(props) {
    const { screenshotsMessages } = props;

    return (
        <div>
            <Row className="text-left color-grey-dark" style={{ margin: '0 1% calc(3% - 10px)', height: '30px' }}>
                <p className="font-18">Sample tickets from your Chatdesk Team</p>
            </Row>
            <Slider {...settings} slidesToShow={screenshotsMessages.length > 1 ? 2 : 1 }>
                { screenshotsMessages.map((screenshot, index) => {
                    const { incomingMessageText, outgoingMessageText, dateCreated, message: {link} } = screenshot;

                    const content = <div className="ticket-card shadow-border">
                                        <p className='color-grey-dark font-13'>{moment(dateCreated).format('MMM D, YYYY')}</p>
                                        <h4 className='color-green font-16'>Customer Message</h4>
                                        <p className='color-grey-dark margin-bottom-30 truncate-overflow'>{incomingMessageText}</p>

                                        <h4 className='color-green font-16'>Chatdesk Response</h4>
                                        <p className='color-grey-dark truncate-overflow'>{outgoingMessageText}</p>
                                    </div>;
                    return (
                        <div key={index}>
                            <Fragment>
                                <a
                                    className="font-15 color-green tms-no-underline"
                                    href={link ? link : '#'}
                                    target="_blank"
                                    rel="noreferrer noopener nofollow"
                                >
                                    {content ? content : "View Conversation" }
                                </a>
                            </Fragment>
                        </div>
                        )
                })

                }
            </Slider>
        </div>
    )
}

export { TicketsSlider }
