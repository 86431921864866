import React, {useState} from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import styles from "../../../voteMainStyles";
import ResponseContent from "./ResponseContent";
import {processResponseOptions} from "_helpers";
import BasicTextField from "../../../../../common/BasicTextField";
import {search} from "../../../Utilities/VoteUtils";

export default function ResponseContainer({message, isScrollComplete, editedResponseObj, editedSubjectObj}) {
    const responseOptions = processResponseOptions(_.get(message, 'response_options'));
    const withMacroSearch = _.get(message, 'msgDataSource.isPlatformMacroEnabled');
    const [responses, setResponses] = useState(responseOptions);

    const Content = ({responseOptions}) => {
        if(_.isEmpty(responseOptions)) return <Box sx={style.noResponseContainer}>No response found</Box>;
        return <ResponseContent
            responseOptions={responseOptions}
            isScrollComplete={isScrollComplete}
            editedResponseObj={editedResponseObj}
            editedSubjectObj={editedSubjectObj}
        />;
    }

    const handleSearchField = (event) => {
        const pathName = 'title';
        const value = _.get(event, 'target.value');
        const searchedResult = search(value, responseOptions, pathName);
        if(_.isEmpty(value) && _.isEmpty(searchedResult)) {
            setResponses(responseOptions);
            return
        }
        setResponses(search(value, responseOptions, pathName))
    }

    return(
        <Box sx={styles.responsePanelRoot}>
            <Box sx={style.responseHeaderContainer}>
                <Box sx={styles.heading}>
                    Responses
                </Box>
                {
                    withMacroSearch &&
                    <Box sx={style.searchContainer}>
                        <BasicTextField
                            placeholder={'Search macros responses'}
                            handleChange={handleSearchField}
                            disabled={!isScrollComplete}
                            sx={style.textField}
                        />
                    </Box>
                }
            </Box>

            <Box sx={withMacroSearch ? styles.responsePanelContentWithSearch : styles.panelContent}>
                <Box sx={styles.responseScrollArea}>
                    <Box sx={{marginBottom: '10px'}}>
                        <Content responseOptions={responses} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const style = {
    noResponseContainer: {
        fontSize: '15px',
        color: '#0C2728',
        textAlign: 'left',
        fontFamily: 'LarsseitRegular',
        border: '1px solid #DCE2E3',
        p: '10px',
        borderRadius: '5px'
    },
    textField: {
        height: '35px',
        [`& fieldset`]: {
            borderRadius: '5px',

        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#D0D9D9',
            },
            '&:hover fieldset': {
                borderColor: '#9EA9A9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },
    searchContainer: {
        width: '60%',
        m: '-6px 10px 3px 0'
    },
    responseHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}