import React from 'react';
import 'components/Vote/vote.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingPlaceholderBlock from "../common/LoadingPlaceholderBlock";


/**
 * @author Chris Okebata
 */

const VoteLoading = () => {

    return(
      <div className="content-wrapper top-rel-80 wrapper-body">
        <div className="vote-container">
          <div className="vote-left-panel">
            <div className="margin-top-87">
              <div className="tms-ellipsis font-14 color-grey-mid margin-bottom-10">
                <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'}/>
              </div>
              <div style={{paddingRight: '0px'}} className="msg-body font-16 color-black-fade">
                <LoadingPlaceholderBlock height={'150px'} borderRadius={'3px'}/>
              </div>
              <div className="msg-details-container font-14 color-grey-dark">
                <div className="tms-ellipsis" style={{
                  borderRight: '1px solid #CED2D0',
                  width: '50%',
                  height: '',
                  textAlign: 'left',
                  paddingRight: '15px'
                }}>
                  <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'}/>
                </div>
                <div style={{width: '50%', height: '', marginLeft: '15px'
                }}>
                  <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'}/>
                </div>
              </div>
              <div className="msg-src-container" style={{ padding: '8px 0' }}>
                <LoadingPlaceholderBlock height={'14px'} borderRadius={'3px'}/>
              </div>
            </div>

            <div className="msg-action-button-container" style={{ marginTop: '50px' }}>
              <div style={{ marginBottom: '20px' }}>
                <LoadingPlaceholderBlock height={'47px'} borderRadius={'3px'}/>
              </div>
              <div>
                <LoadingPlaceholderBlock height={'47px'} borderRadius={'3px'}/>
              </div>
            </div>
          </div>

          <div className="vote-right-panel">
            <div className="progress-bar-container">
              <LoadingPlaceholderBlock height={'27px'} borderRadius={'3px'}/>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{width: 'calc(100% - 350px)', height: '300px'}}>
                <div className="font-14 color-grey-mid margin-bottom-10">
                  <LoadingPlaceholderBlock width={'80px'} height={'14px'} borderRadius={'3px'}/>
                </div>
                <div style={{marginRight: '35px', maxHeight: '450px'}} className="font-16 color-black-fade">
                  <LoadingPlaceholderBlock width={'90%'} height={'50px'} borderRadius={'3px'}/>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '100px 0'}}>
                  <CircularProgress style={{color: '#008751'}}/>
                </div>
              </div>

              <div style={{width: '400px', height: '350px'}}>
                <div className="font-14 color-grey-mid margin-bottom-10">
                  <LoadingPlaceholderBlock width={'160px'} height={'14px'} borderRadius={'3px'}/>
                </div>
                <div style={{ marginBottom: '20px'}}>
                  <LoadingPlaceholderBlock height={'75px'} borderRadius={'5px'}/>
                </div>
                <div style={{ marginBottom: '20px'}}>
                  <LoadingPlaceholderBlock height={'75px'} borderRadius={'5px'}/>
                </div>
                <div style={{ marginBottom: '20px', }}>
                  <LoadingPlaceholderBlock height={'75px'} borderRadius={'5px'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export {VoteLoading}