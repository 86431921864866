export const responseOptionConstants = {
  LIST_RESPONSE_OPTIONS_REQUEST: 'LIST_RESPONSE_OPTIONS_REQUEST',
  LIST_RESPONSE_OPTIONS_SUCCESS: 'LIST_RESPONSE_OPTIONS_SUCCESS',
  LIST_RESPONSE_OPTIONS_FAILURE: 'LIST_RESPONSE_OPTIONS_FAILURE',

  ADD_RESPONSE_OPTION_REQUEST: 'ADD_RESPONSE_OPTION_REQUEST',
  ADD_RESPONSE_OPTION_SUCCESS: 'ADD_RESPONSE_OPTION_SUCCESS',
  ADD_RESPONSE_OPTION_FAILURE: 'ADD_RESPONSE_OPTION_FAILURE',

  GET_USER_ADDED_RESPONSE_OPTIONS_REQUEST: 'GET_USER_ADDED_RESPONSE_OPTIONS_REQUEST',
  GET_USER_ADDED_RESPONSE_OPTIONS_SUCCESS: 'GET_USER_ADDED_RESPONSE_OPTIONS_SUCCESS',
  GET_USER_ADDED_RESPONSE_OPTIONS_FAILURE: 'GET_USER_ADDED_RESPONSE_OPTIONS_FAILURE',

  CLEAR_ADDED_RESPONSE_OPTION: 'CLEAR_ADDED_RESPONSE_OPTION',
};
