import ConnectChannelButton from "../Utilities/ConnectChannelButton";
import React, {Fragment, useEffect} from "react";
import {CLIENT_URL} from "config";
import zendeskLogo from "components/images/logos/logo_zendesk.svg";
import BasicModal from "../../../Utilities/BasicModal";
import ZendeskSetupModalForm from "./ZendeskSetupModalForm";
import {zendeskOauthActions} from "_actions";
import {useDispatch} from "react-redux";


export default function ZendeskChatSetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const performAction = () => {
    handleOpen();
  };

  useEffect(() => {
    const url = new URL(document.location);
    const values = new URLSearchParams(url.search);
    const zendeskAuthCode = values.get('code');
    const partner = values.get('partner');
    const channel = values.get('channel');

    if (!partner && zendeskAuthCode && channel == 'ZENDESK_CHAT') {
      const params = {code: zendeskAuthCode, type: 'CHAT'};
      submitToken(params);
    }
  }, []);

  const submitToken = (params) => {
    dispatch(zendeskOauthActions.generateAccessToken(params));
  };

  return(
    <Fragment>
      <ConnectChannelButton
        label={'Connect with Zendesk Chat'}
        channelIcon={zendeskLogo}
        action={performAction}/>

      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Zendesk Chat'}>

        <ZendeskSetupModalForm
          description={'Please copy and paste your Zendesk subdomain here:'}
          placeholder={'yoursubdomain'}
          channelDomain={'.zendesk.com'}
          extraInfoLabel={'View setup instructions'}
          buttonLabel={'Submit'}
          openInNewTab={true}
          handleClose={handleClose}
          callbackUrl={`${CLIENT_URL}/company/connectchannels?channel=ZENDESK_CHAT`}
          type={'CHAT'}
        />

      </BasicModal>
    </Fragment>
  )
}
