import CheckboxLabel from "./CheckboxLabel";
import _ from "lodash";
import useViewNextMessage from "../views/Vote/Utilities/hooks/useViewNextMessage";
import {getCapitalizedWords} from "_helpers";

export default function CloseTicketCheckbox({checked, value, handleCloseTicket}) {
    const {message} = useViewNextMessage();
    const sourceName = getCapitalizedWords(_.get(message, 'sourceName'));

    const onChange = (event) => {
        const name = _.get(event, 'target.name');
        const value = _.get(event, 'target.checked');
        handleCloseTicket({name, value})
    }

    return(
        <CheckboxLabel
            name={'closeTicket'}
            checked={checked}
            value={value}
            label={`Close ticket on ${sourceName}`}
            onChange={onChange}
        />
    )
}