import React from "react";
import {Box} from "@mui/system";
import whistleLogo from "components/images/logos/logo_whistle.svg";
import profilePic from "components/images/profile_pic_tara.png";
import { buttonContained } from "./ButtonContained";


function GetStartedFooter() {
  return(
    <Box item xs={12} sm={12} md={12} lg={12}
         px={{xs: '20px', sm: '20px', md: '35px', lg: '50px'}}
         py={{xs: '45px', sm: '45px', md: '45px', lg: '45px'}}
         sx={{backgroundColor: '#DBD8FF'}}>
      <Box fontSize={{xs: '18px', sm: '18px', md: '24px', lg: '24px'}}>
        <p className="larsseit-regular" style={{color: '#0C2728', lineHeight: '120%'}}>
          "Chatdesk Teams helps us achieve high NPS scores while significantly reducing our customer support costs."
        </p>
      </Box>
      <Box sx={{marginTop: '25px', display: 'flex'}}>
        <Box sx={{width: '65%'}}>
          <Box fontSize={{xs: '18px', sm: '18px', md: '24px', lg: '24px'}}
               className="larsseit-bold"
               style={{color: '#0C2728', lineHeight: '120%'}}>
            Kelvin Nester
          </Box>
          <Box fontSize={{xs: '14px', sm: '14px', md: '20px', lg: '20px'}}
               className="larsseit-light"
               style={{color: '#0C2728', lineHeight: '120%'}}>
            Director of Customer Experience
          </Box>
        </Box>
        <Box sx={{marginLeft: 'auto', width: '35%'}}>
          <Box sx={{display: 'flex'}}>
            <img src={whistleLogo} style={{maxWidth: '100%', marginLeft: 'auto'}} alt="Whistle logo"/>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function ScheduleCallFooter() {
  return(
    <Box item xs={12} sm={12} md={12} lg={12}
         px={{xs: '20px', sm: '20px', md: '35px', lg: '50px'}}
         py={{xs: '45px', sm: '45px', md: '55px', lg: '55px'}}
         sx={{backgroundColor: '#DBD8FF', display: 'flex'}}>

      <Box sx={{width: 'fit-content', height: 'fit-content'}}>
        <img src={profilePic} style={{maxWidth: '100%', marginLeft: 'auto'}} alt="Profile pic"/>
      </Box>

      <Box sx={{width: '100%', paddingLeft: '20px'}}>
        <Box fontSize={{xs: '18px', sm: '18px', md: '24px', lg: '24px'}}>
          <p className="larsseit-regular" style={{color: '#0C2728', lineHeight: '120%'}}>
            "It’s our job to save you time. We learn your brand voice, handle training, and take tickets off of your plate."
          </p>
        </Box>
        <Box sx={{}} mt={{xs: '15px', sm: '15px', md: '18px', lg: '18px'}}>
          <Box fontSize={{xs: '18px', sm: '18px', md: '24px', lg: '24px'}}
               className="larsseit-bold"
               style={{color: '#0C2728', lineHeight: '120%'}}>
            Tara Ellington
          </Box>
          <Box fontSize={{xs: '14px', sm: '14px', md: '20px', lg: '20px'}}
               className="larsseit-light"
               style={{color: '#0C2728', lineHeight: '120%'}}>
            Chatdesk Customer Success
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function DashboardFooter() {
  const {AnchorButton} = buttonContained;

  return(
    <Box item xs={12} sm={12} md={12} lg={12}
         px={{xs: '20px', sm: '20px', md: '35px', lg: '50px'}}
         py={{xs: '65px', sm: '65px', md: '50px', lg: '50px'}}
         sx={{backgroundColor: '#EFEAE7'}}>

      <Box fontSize={{xs: '22px', sm: '22px', md: '32px', lg: '32px'}}
           sx={{fontFamily: 'LarsseitMedium', color: '#0C2728', lineHeight: '120%'}}>
        Add more channels
      </Box>
      <Box fontSize={{xs: '16px', sm: '16px', md: '24px', lg: '24px'}}
           mt={{xs: '10px', sm: '10px', md: '20px', lg: '20px'}}
           sx={{fontFamily: 'LarsseitRegular', color: '#0C2728', lineHeight: '120%',}}>
        Connecting more channels will let us start analyzing your tickets. Don’t worry, we never post to any of your channels without your permission.
      </Box>
      <Box fontSize={{xs: '16px', sm: '16px', md: '24px', lg: '24px'}}
           mt={{xs: '25px', sm: '25px', md: '30px', lg: '30px'}}
           mb={{xs: '0px', sm: '0px', md: '30px', lg: '30px'}}
           sx={{fontFamily: 'LarsseitRegular', color: '#0C2728', lineHeight: '120%', width: 'fit-content'}}>

        <AnchorButton label={'Add more channels'} link={'/company/connectchannels'}/>
      </Box>
    </Box>
  )
}

export const footer = {
  GetStartedFooter,
  ScheduleCallFooter,
  DashboardFooter
};