import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {payoutsActions} from "_actions";

class Payouts extends Component {
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(payoutsActions.getAll())
    }

    render() {
        const { all_payouts } = this.props
        return (
            <div className="main-content-wrapper top-rel-100 margin-0-40">
                <div style={{width: '50%', background: '#fff', padding: '20px 40px'}}>
                    <Link to="/create-payout-rate" className="btn btn-success pull-right" style={{marginBottom: '15px'}}>
                        Create Payout Rate
                    </Link>
                    <table className="table table-responsive clear-both">
                        <thead>
                        <tr>
                            <th>Currency</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>{''}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {all_payouts && all_payouts.length > 0 && all_payouts.map(payout =>
                            <tr key={payout.id}>
                                <td style={{verticalAlign: 'middle'}}>
                                    {payout.currency &&
                                    `${payout.currency.label} (${payout.currency.code})`
                                    }
                                    {! payout.currency &&
                                    'Unavailable'
                                    }
                                </td>
                                <td style={{verticalAlign: 'middle'}}>{payout.amount}</td>
                                <td style={{verticalAlign: 'middle'}}>{payout.label}</td>
                                <td style={{verticalAlign: 'middle'}}>
                                    <Link to={`/edit-payout-rate/${payout.id}`} className="btn btn-info btn-sm">
                                        Edit Pricing
                                    </Link>
                                </td>
                            </tr>
                        )}
                        {all_payouts && all_payouts.length === 0 &&
                            <tr>
                                <td style={{textAlign: 'center', padding: '10px 0'}} colSpan="3">
                                    No Payout Rates have been previously added
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { payout: { all_payouts }} = state
    return {
        all_payouts
    }
}

let payoutConnect = connect(mapStateToProps)(Payouts)

export { payoutConnect as Payouts }