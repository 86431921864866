import React from 'react';
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {Tab,Table, TableRow, TableBody, TableCell, TableHead, Tabs,} from "@mui/material"
import { styled } from '@mui/material/styles';
import { organizationActions } from "_actions";
import connect from "react-redux/es/connect/connect";
import { Loading, MultiSelectContextMenu } from "components/common";
import { isEmpty } from "lodash";
import { DateRange } from "components/common/formFields/DateRange";
import {Button} from "react-bootstrap";
import moment from "moment";

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(
    {
        '& .MuiTabs-indicator': {
            backgroundColor: '#008751',
        },
    });

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 72,
        fontSize: 16,
        fontFamily: 'LarsseitMedium',
        '&.Mui-selected': {
            color: '#333333',
        },
    }),
);


class PaidMessages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: [],
            startDate: moment().subtract(29, 'days').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            tab: 0
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const visibility = ['PUBLIC', 'PRIVATE', 'FEATURED'];
        dispatch(organizationActions.getActiveOrganizations({max: 1000, visibility}));
    }

    /**
     * Handles date picker update. This method update the selected dates to the state
     * and dispatches the getOrganizationsPaidMessages action for the date selected
     * @param orgId
     * @returns {function(*)}
     */
    handleDatePickerUpdate = (event, data) => {
        if(event.type === 'apply'){
            this.setState({
                startDate : data.startDate.format('YYYY-MM-DD HH:mm:ss'),
                endDate   : data.endDate.format('YYYY-MM-DD HH:mm:ss')
            });
        }
    };

    loadTabData = (tab) => {
        const { startDate, endDate, organizations} = this.state;
        const params = {startDate, endDate, organizations}

        if(tab === 0)
            this.props.dispatch(organizationActions.getOrganizationsPaidMessages(params))
        else if (tab === 1)
            this.props.dispatch(organizationActions.getOrganizationsPaidMessages(params))
        else if (tab === 2)
            this.props.dispatch(organizationActions.getOrganizationsCsat(params))
        else if (tab === 3)
            this.props.dispatch(organizationActions.getOrganizationsReviewsStat(params))
        else if (tab === 4)
            this.props.dispatch(organizationActions.getOrganizationsActiveExperts(params))
    }

    handleTabChange = (event, tabValue) => {
        this.setState({tab: tabValue})

        if (this.state.startDate && this.state.endDate) {
            this.loadTabData(tabValue);
        }
    }

    getOrganizations = (organizations, returnValue) => {
        const orgList = [];
        organizations?.forEach((organization) => {
            if (returnValue === 'ids') {
                orgList.push(organization.id);
            } else {
                const organizationData = {
                    value: organization.id,
                    name: organization.name,
                };
                orgList.push(organizationData);
            }
        });
        return orgList;
    };

    handleOrganizationChange = (organizations) => {
        this.setState({organizations})
    }

    handleSubmit = () => {
        const {tab}= this.state
        this.loadTabData(tab);
    }

    render() {
        const {
            loading,
            organizationsPaidMessages,
            organizationsCsat,
            organizationsCsatLoading,
            organizationsActiveExperts,
            organizationsActiveExpertsLoading,
            organizationsReviewsStats,
            organizationsReviewsStatsLoading,
            orgList,
            orgPaidMessageLoading
        } = this.props;
        const  { organizations, startDate, endDate, tab } = this.state;

        let companies, companiesIds;
        let finalOrgList = orgList?.organizations

        if (finalOrgList) {
            companies = this.getOrganizations(finalOrgList);
            companiesIds = this.getOrganizations(finalOrgList, 'ids');
        }

        return (
            <div className='container-pane container-pane-expert-page'>
                <div style={{display: "flex", alignItems: "center", gap: "15px", paddingTop: "20px"}}>
                    <label className="calendar-today tms-inbox-calendar expert-page-date-range-picker">
                        <Field
                            submittedStartDate={startDate}
                            submittedEndDate={endDate}
                            id="datePicker"
                            name="datePicker"
                            defaultDateRange="today"
                            onEvent={this.handleDatePickerUpdate}
                            values={startDate}
                            component={DateRange}
                        />
                    </label>
                    <label className="inbox-context-menu expert-page-inbox-context-menu" style={{display: "block", width: "250px"}}>
                        <Field
                            name="organizations"
                            fieldName="organizations"
                            type="text"
                            displayName={'Organizations'}
                            options={companies}
                            checkedOptions={organizations}
                            ids={companiesIds}
                            handleChange={this.handleOrganizationChange}
                            component={MultiSelectContextMenu}
                        />
                    </label>
                    <Button bsStyle="success" style={{width: "150px"}} onClick={this.handleSubmit} disabled={!organizations.length}>
                        Apply
                    </Button>
                </div>

                <div style={{ marginTop: '25px'}} >
                    <StyledTabs
                        value={tab}
                        onChange={this.handleTabChange}
                        aria-label="styled tabs example"
                    >
                        <StyledTab label="Volume - Total paid messages" />
                        <StyledTab label="% from Experts" />
                        <StyledTab label="CSAT" />
                        <StyledTab label="Reviews" />
                        <StyledTab label="Number of Active Experts" />
                    </StyledTabs>

                    <div>
                        { tab === 0 &&
                            <>
                                {orgPaidMessageLoading && <Loading height={200} />}
                                {!orgPaidMessageLoading && !isEmpty(organizationsPaidMessages) &&
                                    <Table className="expert-page-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company</TableCell>
                                                <TableCell>Total Paid Messages</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {organizationsPaidMessages && organizationsPaidMessages.map(org => {
                                                return (
                                                    <TableRow key={org.id}>
                                                        <TableCell><Link to={`experts/${org.id}?orgName=${org.name}`}>{org.name}</Link></TableCell>
                                                        <TableCell>{org.totalCount}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>}
                            </>
                        }

                        { tab === 1 &&
                            <>
                                {orgPaidMessageLoading && <Loading height={200} />}
                                {!orgPaidMessageLoading && !isEmpty(organizationsPaidMessages) &&
                                    <Table className="expert-page-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company</TableCell>
                                                <TableCell>% from Experts</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {organizationsPaidMessages && organizationsPaidMessages.map(org => {
                                                return (
                                                    <TableRow key={org.id}>
                                                        <TableCell><Link to={`experts/${org.id}?orgName=${org.name}`}>{org.name}</Link></TableCell>
                                                        <TableCell>{`${org.expertCountPercent}%`}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                }
                            </>

                        }

                        { tab === 2 &&
                            <>
                                {organizationsCsatLoading && <Loading height={200} />}
                                {!organizationsCsatLoading && !isEmpty(organizationsCsat) &&
                                    <Table className="expert-page-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company</TableCell>
                                                <TableCell>CSAT</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {organizationsCsat && organizationsCsat.map(org => {
                                                return (
                                                    <TableRow key={org.id}>
                                                        <TableCell><Link to={`experts/${org.id}?orgName=${org.name}`}>{org.name}</Link></TableCell>
                                                        <TableCell>{`${org.csat}%`}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                }
                            </>
                        }

                        { tab === 3 &&
                            <>
                                {organizationsReviewsStatsLoading && <Loading height={200}/>}
                                {!organizationsReviewsStatsLoading && !isEmpty(organizationsReviewsStats) &&
                                    <Table className="expert-page-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company</TableCell>
                                                <TableCell>Number of Constructive Reviews</TableCell>
                                                <TableCell>Number of positive reviews</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {organizationsReviewsStats && organizationsReviewsStats.map(org => {
                                                return (
                                                    <TableRow key={org.id}>
                                                        <TableCell><Link to={`experts/${org.id}?orgName=${org.name}`}>{org.name}</Link></TableCell>
                                                        <TableCell>{`${org.negativeReviewsCount}`}</TableCell>
                                                        <TableCell>{`${org.positiveReviewsCount}`}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>}
                            </>

                        }

                        { tab === 4 &&
                            <>
                                {organizationsActiveExpertsLoading && <Loading height={200} />}
                                {!organizationsActiveExpertsLoading && !isEmpty(organizationsActiveExperts) &&
                                    <Table className="expert-page-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company</TableCell>
                                                <TableCell>Number of Active Experts</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {organizationsActiveExperts && organizationsActiveExperts.map(org => {
                                                return (
                                                    <TableRow key={org.id}>
                                                        <TableCell><Link to={`experts/${org.id}?orgName=${org.name}`}>{org.name}</Link></TableCell>
                                                        <TableCell>{`${org.usersCount}`}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>}
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {organizations: {
        loading,
        organizationsPaidMessages,
        organizationsCsat,
        organizationsCsatLoading,
        organizationsActiveExperts,
        organizationsActiveExpertsLoading,
        organizationsReviewsStats,
        organizationsReviewsStatsLoading,
        orgPaidMessageLoading,
        activeOrganizations
    }} = state;

    return {
        loading,
        organizationsPaidMessages,
        organizationsCsat,
        organizationsCsatLoading,
        organizationsActiveExperts,
        organizationsActiveExpertsLoading,
        organizationsReviewsStats,
        organizationsReviewsStatsLoading,
        orgList: activeOrganizations,
        orgPaidMessageLoading
    };
}

PaidMessages = connect(mapStateToProps)(PaidMessages);
const expertPage = reduxForm({
    form: 'PaidMessages'
})(PaidMessages);

export { expertPage as PaidMessages };