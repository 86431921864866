import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {messageDirection} from "_constants";
import {messageActions} from "_actions";
import {filterChannels} from "../../../views/Vote/Utilities/VoteUtils";
import useViewOrgDataSourceHandles from "../../../views/Vote/Utilities/hooks/useViewOrgDataSourceHandles";
import FilterEntry from "../FilterEntry";
import DirectionFilterItem from "./DirectionFilterItem";

export default function ChannelsFilter({handleClose}) {
    const {dataSourceHandles} = useViewOrgDataSourceHandles();
    const queryParams = useSelector(state => state.search.queryParams);
    const dispatch = useDispatch();
    const ALL_DIRECTIONS = 'ALL';
    const channelObjArr = filterChannels(dataSourceHandles, 'trendsId') || [];
    const channelsParam = _.get(queryParams, 'channels') || [];
    const directionsParam = _.get(queryParams, 'direction');
    const directions = directionsParam === ALL_DIRECTIONS ? [messageDirection.INCOMING, messageDirection.OUTGOING] : [directionsParam];

    const directionObjArr = [
        {
            name: 'Incoming',
            value: messageDirection.INCOMING,
        },
        {
            name: 'Outgoing',
            value: messageDirection.OUTGOING,
        }
    ]

    const searchMessageWithFilter = (selectedChannels, selectedDirections) => {
        const selectedDirection = (selectedDirections.length > 1 ? ALL_DIRECTIONS : selectedDirections[0]) || ALL_DIRECTIONS;
        const channelsParams = selectedChannels.map(i=>Number(i));
        const payload = {...queryParams, channels: channelsParams, direction: selectedDirection}
        dispatch(messageActions.searchMessages(payload));
    }

    const applyFilters = (selectedChannels, selectedDirections) => {
        searchMessageWithFilter(selectedChannels, selectedDirections);
        handleClose();
    };

    return(
        <FilterEntry
            label={'Channels'}
            primaryOptions={channelObjArr}
            primaryOptionsDefaultValues={channelsParam}
            secondaryOptions={directionObjArr}
            secondaryOptionsDefaultValues={directions}
            handleApplyFilters={applyFilters}
            SecondaryFilter={DirectionFilterItem}
        />
    )
}