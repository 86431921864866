export class SingleResourceBuilder {
    constructor() {
        this.resource = {};
    }

    setId(id){
        this.resource.id = id;
        return this;
    }

    setUrl(url){
        this.resource.url = url;
        return this;
    }

    setName(name){
        this.resource.name = name;
        return this;
    }

    setOrganizationName(organizationName){
        this.resource.organizationName = organizationName;
        return this;
    }

    setOrganizationId(organizationId){
        this.resource.organizationId = organizationId;
        return this;
    }

    setLink(link){
        this.resource.link = link;
        return this;
    }

    setStatus(isActive){
        this.resource.isActive = isActive
        return this;
    }

    build(){
        return this.resource;
    }
}
