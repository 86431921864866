import React, {Component, Fragment} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { msgDataSourceActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";
import { AddGorgiasAutoSenderModal } from 'components/MsgDataSources';
import { PlatformMacroModal } from 'components/MsgDataSources'

/**
 * @author Offiong Aniekan <ekanoffiong@gmail.com>
 * @date 13/10/2022 : 11:07 AM
 */
const statusList = [
    "Open", "Snoozed", "Done",
];

class ListKustomerDataSources extends Component {

    state = {
        updateDataSources: {},
        showAutoSenderModal: false,
        showStatusOnReplySelection: false,
        currentStatus: '',
        dataSourceId: null,
        macroDataSourceId: null,
        showPlatformMacros: false
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getAllDataSources({dataSource: 'Kustomer'}));
    }

    saveSupportEmail = (dataSourceId) => {
        const { dispatch } = this.props;
        const { updateDataSources } = this.state;
        dispatch(msgDataSourceActions.saveSupportEmail({dataSourceId, data: updateDataSources[dataSourceId], msgDataSource: 'kustomer'}));
        this.cancelUpdate(dataSourceId)
    };

    editEmailData = (dataSource) => {
        this.setState((prevState) => ({
            updateDataSources: {
                ...prevState.updateDataSources,
                [dataSource.id]: {
                    orgSupportEmail: dataSource.orgSupportEmail,
                }
            }
        }))
    };

    handleEmailChange = (e, dataSourceId) => {
        this.setState((prevState) => ({
            updateDataSources: {
                ...prevState.updateDataSources,
                [dataSourceId]: {
                    ...prevState.updateDataSources[dataSourceId],
                    [e.target.name]: e.target.value
                }
            }
        }));
    };

    cancelUpdate = (dataSourceId) => {
        this.setState((prevState) => {
            let { [dataSourceId]: removed, ...restDataSources } = prevState.updateDataSources;
            return {
                updateDataSources: restDataSources
            }
        })
    };

    updateAutoSender = (dataSourceId) => {
        this.setState({
            showAutoSenderModal: true,
            dataSourceId
        })
    }

    toggleStatusOnReplySelection = (dataSource) => {
        this.setState({
            showStatusOnReplySelection: true,
            dataSourceId: dataSource.id,
            currentStatus: dataSource.ticketStatusOnReply || ''
        });
    };

    statusChange = (e) => {
        this.setState({
            currentStatus: e.target.value
        });
    };

    saveStatusOnReply = () => {
        const { dataSourceId, currentStatus } = this.state;
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.saveStatusOnReply({currentStatus, dataSourceId}));
        this.resetState()
    };

    resetState = () => {
        this.setState({
            showStatusOnReplySelection: false,
            currentStatus: '',
            dataSourceId: null
        })
    };

    toggleClosedTicketActioning = (postData) => {
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.toggleClosedTicketActioning({...postData, msgDataSourceLabel: 'Kustomer'}));
    };

    viewPlatformShortcuts = (dataSourceId) => {
        this.setState({
            macroDataSourceId: dataSourceId,
            showPlatformMacros: true
        });
        const { dispatch } = this.props;
        dispatch(msgDataSourceActions.getSavedPlatformMacros({ msgDataSourceId: dataSourceId }))
    };

    render() {
        const { kustomerList, loading } = this.props;
        const { showStatusOnReplySelection, currentStatus, updateDataSources, showAutoSenderModal, dataSourceId, macroDataSourceId, showPlatformMacros } = this.state;
        const autoSendersData = kustomerList.find(current => current.id === dataSourceId)
        const currentZendeskDataSource = kustomerList.find(item => item.id === macroDataSourceId);
        const platformMacroEnabled = currentZendeskDataSource && currentZendeskDataSource.isPlatformMacroEnabled

        if (loading) {
            return <Loading/>
        }
        return (
            <div>
                <Row>
                    <Col xs={12} md={12}>
                        <table className='table trds-table'>
                            <thead className="font-18-bold">
                                <tr>
                                    <th className="col-md-1">S/N</th>
                                    <th className="col-md-1">Organization</th>
                                    <th className="col-md-2">Auto Reply Email</th>
                                    <th className="col-md-1">Ticket Status on reply</th>
                                    <th className="col-md-1">Kustomer Shortcuts (Macros)</th>
                                    <th className="col-md-3">Close Ticket on No Response</th>
                                </tr>
                            </thead>
                            <tbody>
                            {kustomerList.map((dataSource, index) => (
                                <tr key={index}>
                                    <td className="vertical-align-middle">{index + 1}</td>
                                    <td className="vertical-align-middle">{dataSource.orgName}</td>
                                    <td className="vertical-align-middle" style={{wordWrap: "break-word"}}>
                                        {!dataSource.orgSupportEmail && !(dataSource.id in updateDataSources) &&
                                            <Button className='btn-info btn-sm' onClick={() => this.editEmailData(dataSource)}>
                                                Add Single Reply Email
                                            </Button>
                                        }
                                        {!(dataSource.id in updateDataSources) &&
                                            <Fragment>
                                                {dataSource.orgSupportEmail &&
                                                    <Fragment>
                                                        <span>
                                                            {dataSource.orgSupportEmail}
                                                        </span>
                                                        <Button className='btn-info btn-sm' onClick={() => this.editEmailData(dataSource)}>
                                                            Edit Reply Email
                                                        </Button>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }
                                        {(dataSource.id in updateDataSources &&
                                            'orgSupportEmail' in updateDataSources[dataSource.id]) && (
                                            <Fragment>
                                                <input type="text" className="form-control" name="orgSupportEmail" value={updateDataSources[dataSource.id].orgSupportEmail || ''} onChange={(e) => this.handleEmailChange(e, dataSource.id)} />
                                                <Button className="margin-top-5 btn-sm btn-success"
                                                    onClick={() => this.saveSupportEmail(dataSource.id)}>
                                                    Save Reply Email
                                                </Button>
                                                <Button className="margin-top-5 btn-danger btn-sm"
                                                    onClick={() => this.cancelUpdate(dataSource.id)}>
                                                    Cancel
                                                </Button>
                                            </Fragment>
                                        )}
                                    </td>
                                    <td>
                                        <div>
                                            {dataSourceId !== dataSource.id &&
                                                <Fragment>
                                                    <b>{dataSource.ticketStatusOnReply ? dataSource.ticketStatusOnReply : 'Not Set'}</b>
                                                    <Button className="btn-info margin-top-5 btn-sm display-block" onClick={() => this.toggleStatusOnReplySelection(dataSource)}>
                                                        Update
                                                    </Button>
                                                </Fragment>
                                            }
                                        </div>
                                        {showStatusOnReplySelection && dataSourceId === dataSource.id &&
                                            <Fragment>
                                                <select className="form-control form-control-inline" value={currentStatus} onChange={this.statusChange}>
                                                    <option hidden value="">Select Status</option>
                                                    {statusList.map((item, index) =>
                                                        <Fragment>
                                                            <option value={item} key={index}>Mark as {item}</option>
                                                        </Fragment>
                                                    )}
                                                </select>
                                                <Button className="btn-info margin-top-5 btn-sm" onClick={this.saveStatusOnReply}>
                                                    Save
                                                </Button>
                                                <Button className="btn-danger margin-left-5 margin-top-5 btn-sm" onClick={this.resetState}>
                                                    Cancel
                                                </Button>
                                            </Fragment>
                                        }
                                    </td>
                                    <td className="padding-0 vertical-align-middle">
                                        <Button className='btn-primary btn-sm margin-top-5' onClick={() => this.viewPlatformShortcuts(dataSource.id)}>
                                            Set up Shortcuts
                                        </Button>
                                    </td>
                                    <td className="margin-left-10">
                                        {dataSource.closeTicketOnNoResponse &&
                                            <Button className='btn-danger margin-top-5' onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnNoResponse', enable: false})}>
                                                Disable Close Ticket on No Response Needed
                                            </Button>
                                        }
                                        {!dataSource.closeTicketOnNoResponse &&
                                            <Button className="margin-top-5" onClick={() => this.toggleClosedTicketActioning({msgDataSourceId: dataSource.id, updateField: 'closeTicketOnNoResponse', enable: true})}>
                                                Enable Close Ticket on No Response Needed
                                            </Button>
                                        }
                                </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <AddGorgiasAutoSenderModal
                    dataType="Kustomer"
                    showData={showAutoSenderModal}
                    autoSenders={autoSendersData && autoSendersData.autoSenders}
                    dataSourceId={dataSourceId}
                    closeModal={() => this.setState({showAutoSenderModal:false,dataSourceId:null})}
                />
                <PlatformMacroModal
                    msgDataSourceId={macroDataSourceId}
                    showData={showPlatformMacros}
                    dataType="Kustomer"
                    platformMacroEnabled={platformMacroEnabled}
                    closeModal={() => this.setState({showPlatformMacros:false})}
                    platformMacroType="KUSTOMER_SHORTCUT"
                />
            </div>
        );

    }
}

function mapStateToProps(state) {
    const { kustomerOauth: { list, loading } } = state;
    return {
        kustomerList: list, loading
    };
}

const connectedListKustomerDataSources = connect(mapStateToProps)(ListKustomerDataSources);
export { connectedListKustomerDataSources as ListKustomerDataSources };
