export const tiktokShopConstants = {
    INITIATE_AUTH_REQUEST: 'INITIATE_AUTH_REQUEST',
    INITIATE_AUTH_SUCCESS: 'INITIATE_AUTH_SUCCESS',
    INITIATE_AUTH_FAILURE: 'INITIATE_AUTH_FAILURE',

    CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
    CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
    CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',

    SAVE_SHOP_REQUEST: 'SAVE_SHOP_REQUEST',
    SAVE_SHOP_SUCCESS: 'SAVE_SHOP_SUCCESS',
    SAVE_SHOP_FAILURE: 'SAVE_SHOP_FAILURE',

    NO_ACCOUNT_FOUND: 'NO_ACCOUNT_FOUND',
    SELECT_SHOP_RESPONSE: 'SELECT_SHOP_RESPONSE',

    GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
    GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',

    GET_ORDER_DETAILS_REQUEST: 'GET_ORDER_DETAILS_REQUEST',
    GET_ORDER_DETAILS_SUCCESS: 'GET_ORDER_DETAILS_SUCCESS',
    GET_ORDER_DETAILS_FAILURE: 'GET_ORDER_DETAILS_FAILURE',

    GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
    GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',
}