import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { organizationActions } from '_actions';
import { getCurrentUser } from '_helpers';
import PageContainer from 'components/v2/common/PageContainer';
import importedStyles from './Utilities/styles'
import ActionButton from './Utilities/ActionButton';
import UsersTable, { UsersTableRow, UsersTableCell } from './UsersTable';
import { UserStatusBadge, UserRoleBadge } from './UsersBadges';
import UsersAddModal, { useUsersAddModal } from './UsersAddModal';
import UserEntry from './UserEntry';
import './Users.css';

// V1 Components, replace these maybe with V2 Components later
import { Loading, CopyrightFooter } from 'components/common';

export default function Users() {
	const dispatch = useDispatch()

	const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const currentUser = getCurrentUser()
	const { initiateUsersAddModal, modalProps } = useUsersAddModal()
	const organizationDetails = useSelector((state) => state.organizations.organizationDetails)
	const loading = useMemo(() => organizationDetails.params && Number(organizationDetails.params.max) === 0, [ organizationDetails ])
	const users = useMemo(() => organizationDetails.users, [ organizationDetails ])

	useEffect(() => {
		if (currentUser.organization_id) {
			const params = { offset: 0, max: 9999 }
			dispatch(organizationActions.getOrgUsers(currentUser.organization_id, params))
		}
	}, [])

	if (loading)
		return <Loading />

	return (
		<Fragment>
			<PageContainer>
				<Box sx={importedStyles.root}>
					<Box sx={importedStyles.headingBox}>
						<Box component={'h1'} sx={{ ...importedStyles.h1, mb: 0 }}>
							Users
						</Box>
						
						<ActionButton
							label={'Add User'}
							action={initiateUsersAddModal}
						/>
					</Box>

					<Box sx={styles.content}>
						{ users && users.length ? (
							<UsersTable 
								label={'List of users'}
								headLabels={!isMobile ? ['User', 'Role', 'Status'] : []}>
								{users.map((user) => (
									<UsersTableRow key={user.id}>
										<UsersTableCell sx={{ maxWidth: '1px' }}>
											<UserEntry user={user} />
										</UsersTableCell>
										{!isMobile && (
											<>
												<UsersTableCell>
													<UserRoleBadge role={'Admin'} />
												</UsersTableCell>
												<UsersTableCell>
													<UserStatusBadge enabled={user.enabled} />
												</UsersTableCell>
											</>
										)}
									</UsersTableRow>
								))}
							</UsersTable>
						) : (
							<Box sx={styles.emptyState}>
								No Users created yet.
							</Box>
						) }
					</Box>
				</Box>
			</PageContainer>

			<UsersAddModal {...modalProps} />

      <CopyrightFooter />
		</Fragment>
	)
}

const styles = {
	content: {
		mt: 4,
	},
	emptyState: {
		background: '#fff',
		borderRadius: '10px',
		border: '1px solid #B6BEBF',
		textAlign: 'center',
		p: 4,
	},
}
