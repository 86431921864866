import _ from "lodash";

/**
 * @param searchWord {string}
 * @param options {array}
 * @returns {[]|*[]}
 */
export function search(searchWord, options) {
    if(!searchWord || _.isEmpty(options)) return [];
    const searchedResult = [];

    options && options.filter(option => {
        if (!_.has(option, 'label') || !_.has(option, 'name')) return false;
        const labelArr = Object.values(option && option.label).filter(label => {
            return _.toString(label).toLowerCase().includes(_.toString(searchWord).toLowerCase());
        }) || [];
        labelArr.map(label => searchedResult.push({ label: label, section: option.name }));
    });
    return searchedResult;
}