import React, {Fragment} from "react";
import InternalNoteModal from "./InternalNoteModal";
import ActionButton from "../../../../../../common/ActionButton";
import useModalSetup from "../../../../../../hooks/useModalSetup";

export default function InternalNoteEntry({handleOptionSubmitted}) {
    const {handleOpenModal, handleCloseModal, openModal} = useModalSetup();


    return(
        <Fragment>
            <ActionButton
                label={'Create internal note'}
                action={handleOpenModal}
                withIcon={false}
                sx={styles.actionButton}
            />
            <InternalNoteModal
                title={'Create internal note'}
                open={openModal}
                handleClose={handleCloseModal}
                handleOptionSubmitted={handleOptionSubmitted}
            />
        </Fragment>
    )
}

const styles = {
    actionButton: {
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}