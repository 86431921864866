import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {companyResourcesActions} from "_actions";
import _ from "lodash";
import {SingleResourceBuilder} from "../objects/SingleResourceBuilder";
import {useParams} from "react-router-dom";

export default function () {
    const dispatch = useDispatch();
    const params = useParams();
    const {id} = params;

    useEffect(() => {
        dispatch(companyResourcesActions.getResource(id));
    }, [id]);

    const resource = useSelector(state => state.companyResources);
    const loading = resource.loadingResource;
    const singleResource = resource.resource;
    let resourceObj = {};

    if(!loading && !_.isEmpty(resource)) {
        const builder = new SingleResourceBuilder();
        builder.setId(_.get(singleResource, 'id') || '-');
        builder.setName(_.get(singleResource, 'name') || '-');
        builder.setLink(_.get(singleResource, 'link') || '-')
        builder.setStatus(_.get(singleResource, 'isActive') || false)
        resourceObj = builder.build();
    }

    return { resourceObj, loading };
}