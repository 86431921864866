import AccordionItem from 'components/v2/common/AccordionItem';
import RemovalProcessSummary from "./RemovalProcessSummary";
import RemovalProcessDetails from "./RemovalProcessDetails";

export default function RemovalProcess({removalProcess}) {
    return (
        <AccordionItem
                key={removalProcess.id}
                summaryComponent={<RemovalProcessSummary removalProcess={removalProcess}/>}>
            <RemovalProcessDetails removalProcess={removalProcess} />
        </AccordionItem>
    );
}
