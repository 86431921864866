import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {chatdeskUpdatesActions} from "_actions";
import {buildGetCompanyUpdatePayload, shouldViewCompanyUpdate} from "../../utilities/CompanyUpdateUtils";
import _ from "lodash";

export default function() {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            getActiveCompanyUpdate();
            return () => effectRan.current = true;
        }
    }, []);

    const getActiveCompanyUpdate = () => {
        const payload = buildGetCompanyUpdatePayload();
        dispatch(chatdeskUpdatesActions.getAll(payload));
    }

    const companyUpdateObj = useSelector(state => state.chatdeskUpdates.updatesList);
    const loadingCompanyUpdates = useSelector(state => state.chatdeskUpdates.loading);
    let shouldView = false;
    let companyUpdate = {};

    if(!loadingCompanyUpdates && !_.isEmpty(companyUpdateObj)) {
        const {selectedUpdate, shouldViewUpdate} = shouldViewCompanyUpdate(companyUpdateObj);
        companyUpdate = selectedUpdate;
        shouldView = shouldViewUpdate;
    }
    return { companyUpdate, loadingCompanyUpdates, shouldView };
}