import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {Form} from "react-bootstrap";
import {DropDownSelect, MultiSelectContextMenu} from "components/common";


/**
 * @author Chris Okebata
 */


class SearchFilter extends Component{


  render() {

    const {onFormUpdate, channelsFilter, channelsAction, channelsIds, handleChannelsFilterChange, direction} = this.props;
    const messageDirectionInput = {onChange: onFormUpdate, value: direction};
    const messageDirectionOptions = [
      {name: 'All', id: 'ALL'},
      {name: 'Incoming', id: 'INCOMING'},
      {name: 'Outgoing', id: 'OUTGOING'}
    ];


    return(
      <Fragment>
        <Form className="messages-page-filter-form" style={{width: '100%',}}>
          <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <span className="color-grey-dark font-14" style={{width: '55px',marginRight: '10px', display: 'flex', alignItems: 'center'}}>Filter by</span>
            <div style={{width: '30%', height: '38px', borderRadius: '3px', marginRight: '15px'}}>
              <div className="inbox-context-menu" style={{display: "block", marginTop: '-1px' }}>
                <Field
                  name="channels"
                  fieldName="channels"
                  type="text"
                  displayName={'Channels'}
                  // input={channelsInput}
                  options={channelsFilter}
                  checkedOptions={channelsAction}
                  ids={channelsIds}
                  handleChange={handleChannelsFilterChange}
                  component={MultiSelectContextMenu}
                />
              </div>
            </div>
            <div style={{width: '30%', height: '38px', borderRadius: '3px', marginTop: '-2px'}}>
              <Field
                name="direction"
                fieldName="direction"
                input={messageDirectionInput}
                options={messageDirectionOptions}
                value={direction}
                placeHolder={'Select Direction'}
                component={DropDownSelect}/>
            </div>
          </div>
        </Form>
      </Fragment>
    )
  }

}

const searchFilter = reduxForm({
  form: 'SearchFilter'
})(SearchFilter);

export { searchFilter as SearchFilter };