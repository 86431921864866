import React from 'react'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';

export default ({input, type, showSecond, name, placeholder, applyValidation, meta:{touched, error, warning}}) => {
    return (
        <div>
            <TimePicker
                onChange={input.onChange}
                name={name}
                type={type}
                showSecond={showSecond}
                placeholder={placeholder}
            />
            <div>
                <span className="font-12 color-danger">{touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}</span>
            </div>
        </div>
    )
}