import React, {Fragment, useState} from "react";
import BasicTable from "../../../../../common/BasicTable";
import {useDispatch} from "react-redux";
import useViewSearchResult from "../../../Utilities/hooks/useViewSearchResult";
import {messageActions} from "_actions";
import voteViews from "../../../Utilities/voteViews";

export default function SearchedMessageTable({handleSearchedConversationViewToggle}) {
    const dispatch = useDispatch();
    const { rows, columns, loadingSearch, queryParams, totalCount } = useViewSearchResult();
    const [params, setParams] = useState({});
    const [page, setPage] = useState(() => {
        const offset = Number(queryParams.page);
        return offset ? offset : 0;
    });

    const handlePageChange = (event, nextPage) => {
        setPage(nextPage);
        searchMessage(nextPage);
    };

    const searchMessage = (nextPage) => {
        const payload = {...queryParams, offset: nextPage * 10, page: nextPage}
        setParams({...params, ...payload});
        dispatch(messageActions.searchMessages(payload));
    }

    const handleTableCellClick = (messageId) => {
        const params = { addedDetails: true };
        dispatch(messageActions.getDetailsFromTrends(messageId, params));
        handleSearchedConversationViewToggle(voteViews.searchedMessageConversation);
    }

    return(
        <Fragment>
            <BasicTable
                rows={rows}
                columns={columns}
                totalCount={totalCount}
                handlePageChange={handlePageChange}
                page={page}
                isPaginationDisabled={loadingSearch}
                tableContainerStyle={{maxHeight: 'calc(100vh - 385px)'}}
                paperStyle={{borderRadius: '5px'}}
                tableCellStyle={style.tableCell}
                tablePaginationStyle={style.pagination}
                handleTableCellClick={handleTableCellClick}
            />
        </Fragment>
    )
}

const style = {
    tableCell: {
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        fontWeight: 500,
        color: '#0C2728',
        padding: '8px 5px',
        '&:first-of-type': {
            paddingLeft: '18px'
        },
        '&:last-of-type': {
            paddingRight: '18px',
        },
    },
    pagination: {
        overflow: 'hidden',
        borderTop: '1px solid #EEEFEF',
        height: '35px',
        '.MuiTablePagination-toolbar': {
            inlineSize: 'max-content',
            margin: 'auto',
            height: '10px',
            padding: '0'
        },
        '.MuiTablePagination-actions': {
            color: '#0C2728',
            height: '27px',
            button: {
                width: '25px',
                height: '25px',
                mx: '5px'
            },
            position: 'relative',
            top: '-7px',
        },
        '.MuiTablePagination-displayedRows': {
            position: 'relative',
            top: '-3px',
            fontFamily: 'LarsseitMedium',
            fontSize: '14px',
            color: '#0C2728',
        },
    },
}