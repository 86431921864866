export const organizationSettingsConstants = {
    GET_DATA_SOURCE_HANDLE_INFO_FETCH_REQUEST: 'GET_DATA_SOURCE_HANDLE_INFO_FETCH_REQUEST',
    GET_DATA_SOURCE_HANDLE_INFO_REFRESH_REQUEST: 'GET_DATA_SOURCE_HANDLE_INFO_REFRESH_REQUEST',
    GET_DATA_SOURCE_HANDLE_INFO_SUCCESS: 'GET_DATA_SOURCE_HANDLE_INFO_SUCCESS',
    GET_DATA_SOURCE_HANDLE_INFO_FAILURE: 'GET_DATA_SOURCE_HANDLE_INFO_FAILURE',

    GET_AUTO_ACTIONS_INFO_FETCH_REQUEST: 'GET_AUTO_ACTIONS_INFO_FETCH_REQUEST',
    GET_AUTO_ACTIONS_INFO_REFRESH_REQUEST: 'GET_AUTO_ACTIONS_INFO_REFRESH_REQUEST',
    GET_AUTO_ACTIONS_INFO_SUCCESS: 'GET_AUTO_ACTIONS_INFO_SUCCESS',
    GET_AUTO_ACTIONS_INFO_FAILURE: 'GET_AUTO_ACTIONS_INFO_FAILURE',

    GET_ORGANIZATION_INFO_REQUEST: 'GET_ORGANIZATION_INFO_REQUEST',
    GET_ORGANIZATION_INFO_SUCCESS: 'GET_ORGANIZATION_INFO_SUCCESS',
    GET_ORGANIZATION_INFO_FAILURE: 'GET_ORGANIZATION_INFO_FAILURE',

    GET_ORGANIZATION_CSM_LIST_REQUEST: 'GET_ORGANIZATION_CSM_LIST_REQUEST',
    GET_ORGANIZATION_CSM_LIST_SUCCESS: 'GET_ORGANIZATION_CSM_LIST_SUCCESS',
    GET_ORGANIZATION_CSM_LIST_FAILURE: 'GET_ORGANIZATION_CSM_LIST_FAILURE',

    UPDATE_ORGANIZATION_ATTRIBUTE_REQUEST: 'UPDATE_ORGANIZATION_ATTRIBUTE_REQUEST',
    UPDATE_ORGANIZATION_ATTRIBUTE_SUCCESS: 'UPDATE_ORGANIZATION_ATTRIBUTE_SUCCESS',
    UPDATE_ORGANIZATION_ATTRIBUTE_FAILURE: 'UPDATE_ORGANIZATION_ATTRIBUTE_FAILURE',

    UPDATE_TICKET_BUDGET_REQUEST: 'UPDATE_TICKET_BUDGET_REQUEST',
    UPDATE_TICKET_BUDGET_SUCCESS: 'UPDATE_TICKET_BUDGET_SUCCESS',
    UPDATE_TICKET_BUDGET_FAILURE: 'UPDATE_TICKET_BUDGET_FAILURE',

    GET_ORGANIZATION_REMOVAL_PROCESSES_REQUEST: 'GET_ORGANIZATION_REMOVAL_PROCESSES_REQUEST',
    GET_ORGANIZATION_REMOVAL_PROCESSES_SUCCESS: 'GET_ORGANIZATION_REMOVAL_PROCESSES_SUCCESS',
    GET_ORGANIZATION_REMOVAL_PROCESSES_FAILURE: 'GET_ORGANIZATION_REMOVAL_PROCESSES_FAILURE',

    INITIATE_ORGANIZATION_REMOVAL_PROCESS_REQUEST: 'INITIATE_ORGANIZATION_REMOVAL_PROCESS_REQUEST',
    INITIATE_ORGANIZATION_REMOVAL_PROCESS_SUCCESS: 'INITIATE_ORGANIZATION_REMOVAL_PROCESS_SUCCESS',
    INITIATE_ORGANIZATION_REMOVAL_PROCESS_FAILURE: 'INITIATE_ORGANIZATION_REMOVAL_PROCESS_FAILURE',

    GET_ORGANIZATION_CSM_LIST_REQUEST: 'GET_ORGANIZATION_CSM_LIST_REQUEST',
    GET_ORGANIZATION_CSM_LIST_SUCCESS: 'GET_ORGANIZATION_CSM_LIST_SUCCESS',
    GET_ORGANIZATION_CSM_LIST_FAILURE: 'GET_ORGANIZATION_CSM_LIST_FAILURE',

    UPDATE_ORGANIZATION_ATTRIBUTE_REQUEST: 'UPDATE_ORGANIZATION_ATTRIBUTE_REQUEST',
    UPDATE_ORGANIZATION_ATTRIBUTE_SUCCESS: 'UPDATE_ORGANIZATION_ATTRIBUTE_SUCCESS',
    UPDATE_ORGANIZATION_ATTRIBUTE_FAILURE: 'UPDATE_ORGANIZATION_ATTRIBUTE_FAILURE',

    UPDATE_TICKET_BUDGET_REQUEST: 'UPDATE_TICKET_BUDGET_REQUEST',
    UPDATE_TICKET_BUDGET_SUCCESS: 'UPDATE_TICKET_BUDGET_SUCCESS',
    UPDATE_TICKET_BUDGET_FAILURE: 'UPDATE_TICKET_BUDGET_FAILURE',
}
