import React, {Fragment} from "react";
import {SearchableSelect} from "../../components/common/formFields/SearchableSelect";
import {required} from "_helpers";

const OrganizationForm = props => {

        const { teamLeadOptions, csmOptions, handleChange } = props;
        const input = {
            onChange: handleChange , onBlur: () => {
            }
        };
        return (
            <Fragment>
                <div style={{marginBottom: '10px', height: '40px'}}>
                    <SearchableSelect
                        name='teamLead'
                        options={teamLeadOptions}
                        placeholder='Select Team Lead'
                        style={{height: '40px', borderRadius: '3px', border: 'none !important', fontcolor: '#333'}}
                        type="text"
                        validate={required}
                        input={input}
                    />
                </div>

                <div style={{marginBottom: '10px', height: '40px'}}>
                    <SearchableSelect
                        name='csm'
                        options={csmOptions}
                        placeholder='Select CSM'
                        style={{height: '40px', borderRadius: '3px', border: 'none !important', fontcolor: '#5a5f5e'}}
                        type='text'
                        validate={required}
                        input={input}
                    />
                </div>
            </Fragment>
        )
}

export {OrganizationForm}
