import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {tiktokOauthActions} from "_actions";
import {Loading} from 'components/common';
import {history} from "_helpers";


export default function TikTokUrlRedirect() {
  const dispatch = useDispatch();
  const url = new URL(document.location);
  const values = new URLSearchParams(url.search);
  const tiktokAuthToken = values.get('access_token');

  useEffect(() => {
    if (tiktokAuthToken) {
      const params = { access_token: tiktokAuthToken};
      dispatch(tiktokOauthActions.generateAccessToken(params));
    }
  }, []);

  const tiktok = useSelector((state) => state.tiktokOauth);

  if (tiktok.loading) {
    return <Loading />;
  }

  history.push('/signup/schedule');

  return(
    <Fragment/>
  )
}