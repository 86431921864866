import React, {Component} from 'react';
import PageSelectionModal from "components/v2/views/Onboarding/ConnectChannel/Channels/Meta/PageSelectionModal";
import BasicModal from "../../../v2/views/Onboarding/Utilities/BasicModal";
import {connect} from 'react-redux'
import _ from 'lodash';
import {oauthConstants} from "_constants";


let _this;

class MetaSetupModal extends Component{
  constructor(props){
    super(props);

    this.state = { open: false, channel: '' };
    _this = this;
  }

  static open(channel){
    _this.setState({ open: true, channel: channel }) //this is invoked from outside the component
  }
  static close(){
    _this.setState({ open: false, channel: '' }); //this is invoked from outside the component
  }
  handleClose = () => {
    _this.setState({ open: false, channel: '' }); //this is invoked within the component
  };

  render() {
    const { channel } = this.state;
    const isFacebookChannel = channel === oauthConstants.FACEBOOK_CHANNEL;
    const { fbPages, fbLoading, igPages, igLoading } = this.props;
    let isPageEmpty, loading, BAD_REQUEST = 'BAD_REQUEST';
    isPageEmpty = isFacebookChannel ?
      _.isEmpty(fbPages) || (_.get(igPages, 'status') === BAD_REQUEST) :
      _.isEmpty(igPages) || (_.get(igPages, 'status') === BAD_REQUEST);
    loading = isFacebookChannel ? fbLoading : igLoading;

    return(
      <BasicModal
        open={this.state.open}
        handleClose={this.handleClose}
        title={loading ? '' : (isPageEmpty ? 'No page found' : `Select ${channel} pages to connect with Chatdesk`)}>
        <PageSelectionModal handleClose={this.handleClose} channel={channel} />
      </BasicModal>
    )
  }
}

function mapStateToProps(state) {
  return {
    fbPages: state.facebookOauth.data,
    fbLoading: state.facebookOauth.loading,
    igPages:state.instagramOauth.data,
    igLoading: state.instagramOauth.loading
  }
}

export default connect(mapStateToProps)(MetaSetupModal);