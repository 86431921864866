import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {Loading} from "../../../../common";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {messageActions} from "_actions";
import {requestOrigin} from "_constants";
import InboxConversation from "./InboxConversation"
import _ from "lodash";

export default function InboxDetails(){
    const dispatch = useDispatch();
    const params = useParams();
    const {id} = params;

    const urlParams = new URLSearchParams(window.location.search);
    const notificationType = urlParams.get('type');

    const conversation = useSelector((state) => state.conversation.conversation)
    const conversationLoading = useSelector((state) => state.conversation.loading)
    const trendsCorrespondingMessages = useSelector((state) => state.messages.trendsCorrespondingMessages)
    const loadingTrendsCorrespondingMessages = useSelector((state) => state.messages.loadingTrendsCorrespondingMessages)
    const companyReplied = useSelector((state) => state.users.companyReplied)
    const loadingCompanyReplied = useSelector((state) => state.users.loadingCompanyReplied)
    const markAsDone = useSelector((state) => state.users.markAsDone)
    const loadingMarkAsDone = useSelector((state) => state.users.loadingMarkAsDone)

    useEffect(() => {
        if(notificationType === "priority") {
            dispatch(messageActions.getConversation(id));
        }

        if(notificationType === "all_messages") {
            dispatch(messageActions.getConversationFromTrends(id, {}, null, requestOrigin.INBOX_DETAILS));
        }

    },[])

    if(loadingTrendsCorrespondingMessages || conversationLoading) {
        return <Loading/>
    }

    return(
        <Box sx={styles.rootWrapper}>
            <InboxConversation
                openedMessageId={id}
                conversation={conversation}
                notificationType={notificationType}
                loadingMessage={_.isEmpty(conversation)}
                trendsCorrespondingMessages={trendsCorrespondingMessages}
                companyReplied={companyReplied}
                loadingCompanyReplied={loadingCompanyReplied}
                markAsDone={markAsDone}
                loadingMarkAsDone={loadingMarkAsDone}
            />
        </Box>
    )
}

const styles = {
    rootWrapper: {
        backgroundColor: '#EFEAE7',
        minHeight: '100vh',
        pt: 16,
        pb: 10,
        px: { xs: 2, sm: 6 },
    }
}