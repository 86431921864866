import React from 'react'
import {FormGroup} from "react-bootstrap";

class RenderSelect extends React.Component {
    render() {

        const {placeHolder, placeHolderValue, options, style, selectValue,
            input, children, placeholderVisible, meta: {error, warning},fieldName , ...rest} = this.props;

        const getValidationState = () => {
            return error ? 'error' : warning ? 'warning' : 'success'
        };

        function renderSelectOptions(item) {
            return <option key={item.id} value={item.id}>{item.name}</option>
        }

        return <FormGroup controlId="formBasicText"  validationState={getValidationState()} hidden={this.props.hidden}>
            <label id="drop-down-arrow-down">
                <select
                    className="form-control select-field color-grey-dark"
                    name={fieldName ? fieldName : ""}
                    style={style}
                    value={selectValue}
                    {...input}
                    {...rest}
                    onChange={input.onChange}
                    children={children}>
                    <option key='' value={placeHolderValue ? placeHolderValue : ''} hidden={! placeholderVisible}>{placeHolder}</option>
                    {options ? options.map(renderSelectOptions) : null}
                </select>
            </label>
        </FormGroup>
    }
}


export {RenderSelect as DropDownSelect}