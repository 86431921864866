import React, {Component} from "react";
import {screenshotActions} from "_actions";
import {connect} from "react-redux";
import {Loading} from "components/common";
import "./screenshots.css";
import {TicketsSlider} from "../SummaryPage/TicketsSlider";

class ScreenshotFileView extends Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.viewScreenshot(params.id)
    }

    render() {
        const {screenshotsMessages, loading} = this.props;

        if (loading){
            return <Loading/>
        }

        return (
            <div>
                {screenshotsMessages && screenshotsMessages.length > 0 &&
                <div>
                    <TicketsSlider
                        screenshotsMessages = {screenshotsMessages}
                    />
                </div>
                }
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        viewScreenshot: (id) => dispatch(screenshotActions.viewScreenshot(id))
    }
};

const mapStateToProps = state => {
    const {screenshots: {fileData: {screenshotsMessages}, loading}} = state;
    return {
        screenshotsMessages,

        loading
    }
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ScreenshotFileView);

export {connectedComponent as ScreenshotFileView}




