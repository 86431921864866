import PropTypes from "prop-types";
import {Table, TableBody, TableCell, TableRow, Typography, Paper, withStyles} from "@material-ui/core";
import { stylesTable } from "components/Inbox/StylesTable";
import React from "react";
import {ChatdeskUpdatesRow} from "./Row";
import TableHead from "@material-ui/core/TableHead";

const ChatdeskUpdatesTable = ({data, classes}) => {
    return (
        <div className="search-messages-table">
            <Paper className={classes.root}>
                <Table >
                    <TableHead>
                        <TableRow className={classes.tableRow}>
                            <TableCell >
                                <Typography className={classes.typography13}>Company</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography className={classes.typography13}>Notification Date</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography className={classes.typography13}>User</Typography>
                            </TableCell>
                            <TableCell  >
                                <Typography className={classes.typography13} >Summary</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography className={classes.typography13}>Status</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography className={classes.typography13}>Link</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data && data.length === 0 &&
                            <TableRow className={classes.tableRow}>
                                <TableCell colSpan={4} className={classes.typography13}>There are no updates available</TableCell>
                            </TableRow>
                        }

                        {data.map(update => {
                            return <ChatdeskUpdatesRow
                                update={update}
                                key={update.id}
                                classes={classes}
                            />
                        })}


                    </TableBody>
                </Table>

            </Paper>
        </div>
    )
}


ChatdeskUpdatesTable.propStyles = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(stylesTable)(ChatdeskUpdatesTable)
