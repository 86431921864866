import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { ChannelsContextMenu } from "../Inbox";
import { Field, reduxForm } from "redux-form";
import {DropDownSelect, MultiSelectContextMenu} from "components/common";


/**
 * @author Okpala Oluchukwu Chioma <oluchukwu@chatdesk>
 */
class FilterForm extends Component {
    render() {
        let {
            onFormUpdate,
            direction,
            handleChange,
            channelsAction,
            channelsIds,
            organizations,
            companiesIds,
            channelsFilter,
            companies,
            handleChannelsFilterChange,
            handleOrgFilterChange,
        } = this.props;

        const dropdownStyles = { borderColor: "#c4c9c7", boxShadow: "none", color: "#5a5f5e", width: "100%" };
        const channelsInput = {onChange: handleChange, value: channelsAction};
        const messageDirectionInput = {onChange: onFormUpdate, value: direction};
        const organizationOptions = companies && companies.length > 0 ? companies.map(company => {
            return {name: company.name, value: company.value}
        }) : [];
        const messageDirectionOptions = [
            {name: 'All', id: 'ALL'},
            {name: 'Incoming', id: 'INCOMING'},
            {name: 'Outgoing', id: 'OUTGOING'}
        ];

        return (
            <Form inline className="messages-page-filter-form">
                <div style={{marginTop: "10px"}}>
                    <span className="font-14 color-grey-dark">Organizations</span>
                    <div className="inbox-context-menu" style={{display: "block"}}>
                        <Field
                            name="organizations"
                            fieldName="organizations"
                            type="text"
                            displayName={'Organizations'}
                            input={channelsInput}
                            options={organizationOptions}
                            checkedOrganizations={organizations}
                            ids={companiesIds}
                            style={dropdownStyles}
                            caretPosition="left"
                            handleChange={handleOrgFilterChange}
                            component={MultiSelectContextMenu} />
                    </div>
                </div>
                <div style={{marginTop: "10px"}}>
                    <span className="font-14 color-grey-dark">Data Source Handles</span>
                    <div className="inbox-context-menu" style={{display: "block"}}>
                        <Field
                            name="channels"
                            fieldName="channels"
                            type="text"
                            input={channelsInput}
                            options={channelsFilter}
                            checkedChannels={channelsAction}
                            ids={channelsIds}
                            style={dropdownStyles}
                            caretPosition="top"
                            handleChange={handleChannelsFilterChange}
                            component={ChannelsContextMenu} />
                    </div>
                </div>

                <div style={{marginTop: "10px"}}>
                    <span className="font-14 color-grey-dark">Message Direction</span>
                    <label className="" style={{display: "block"}}>
                        <Field
                            name="direction"
                            fieldName="direction"
                            input={messageDirectionInput}
                            style={dropdownStyles}
                            options={messageDirectionOptions}
                            value={direction}
                            component={DropDownSelect}/>
                    </label>
                </div>
            </Form>
        );
    }
}

const filterForm = reduxForm({
    form: 'FilterForm'
})(FilterForm);

export { filterForm as SearchForm }
