import Grid from "@material-ui/core/Grid";
import {Field} from "redux-form";
import {MaterialCheckbox} from "components/common/CustomFormFields/MaterialCheckbox";
import React from "react";

export const ShowDay = ({day}) => {
    return (
        <Grid item xs={3}>
            <Field
                name={day}
                id={day}
                value={day}
                component={MaterialCheckbox}
                className="add-schedule-checkbox"
                type="checkbox"
            />
            <label>{day}</label>
        </Grid>
    )
};