import {useEffect, useMemo} from 'react';
import {proactiveActions} from "_actions";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {buildGetCustomerIoSegmentPayload} from "../CampaignUtils";
import {CustomerIoSegmentBuilder} from "../objects/CustomerIoSegmentBuilder";

export default function (params) {
    const dispatch = useDispatch();

    useEffect(() => {
        getCustomerIoSegments(params);
    }, [params]);

    const getCustomerIoSegments = (params) => {
        if(_.has(params, 'msgDataSourceId')) {
            const payload = buildGetCustomerIoSegmentPayload(params.msgDataSourceId)
            dispatch(proactiveActions.getCustomerIoSegments(payload));
        }
    }

    const proactive = useSelector(state => state.proactive);
    const loading = proactive.loadingCustomerIoSegments;
    const customerIoSegmentList = _.get(proactive, 'customerIoSegments.segments') || [];

    const customerIoSegments = useMemo(() => {
        const customerIoSegmentArr = [];
        if(!loading && customerIoSegmentList.length > 0) {
            customerIoSegmentList.map( customerIoSegment => {
                const builder = new CustomerIoSegmentBuilder();
                builder.setId(_.get(customerIoSegment, 'id') || '-');
                builder.setName(_.get(customerIoSegment, 'name') || '-');

                customerIoSegmentArr.push(builder.build());
            });
        }
        return customerIoSegmentArr
    }, [ customerIoSegmentList ]) || [];

    return {customerIoSegments};
}