import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirebaseConfig} from "./config";

const firebaseConfig = getFirebaseConfig();

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;