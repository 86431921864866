import React, {Fragment} from "react";
import logoShopify from 'components/images/logos/logo_shopify.svg'
import useShopifySetup from './useShopifySetup'
import ChannelCard from '../../ChannelCard'
import ShopifySetupModal from './ShopifySetupModal'

export default function ShopifyEntry() {
  const { initiateShopifySetup, shopifySetupOpen, handleShopifySetupClose, handleShopifySetupOpen } = useShopifySetup()

	return (
		<Fragment>
			<ChannelCard
				icon={logoShopify}
				label={'Shopify'}
				description={'Analyze conversion for customers that convert from a pre-sales question to an order'}
				onClick={initiateShopifySetup}
			/>

			<ShopifySetupModal
				open={shopifySetupOpen}
				handleOpen={handleShopifySetupOpen}
				handleClose={handleShopifySetupClose}
			/>
		</Fragment>
	)
}