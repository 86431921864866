import {getActionData} from "_helpers";
import {youtubeOAuthService} from "_services";
import {alertMessages, youtubeConstants} from "_constants";
import {SnackBar} from "../components/common";
import {Auth} from "../components/Authorization";
import _ from "lodash";
import YoutubeSetupModal from "../components/v2/views/Channels/ConnectChannels/providers/YouTube/YoutubeSetupModal";

function initiateOauth() {
    return (dispatch) => {
        dispatch(getActionData(youtubeConstants.INITIATE_OAUTH_REQUEST));
        youtubeOAuthService.initiateOAuth()
            .then(response => {
                dispatch(getActionData(youtubeConstants.INITIATE_OAUTH_SUCCESS, response.data));
            }).catch((error) => {
                if(_.get(error, 'response.status') === 401){
                    Auth.processLogout();
                    return;
                }
                dispatch(getActionData(youtubeConstants.INITIATE_OAUTH_FAILURE, {}, error));
                SnackBar.showMessage(alertMessages.GENERIC_ERROR)
            })
    }
}

function createAccount(params) {
    return (dispatch) => {
        dispatch(getActionData(youtubeConstants.CREATE_ACCOUNT_REQUEST));
        youtubeOAuthService.createAccount(params)
            .then(response => {
                const channelCount = _.get(response, 'data.data.count');
                const statusText = _.get(response, 'data.data.statusText');
                if(channelCount > 1) {
                    YoutubeSetupModal.open();
                } else if(channelCount === 1) {
                    dispatch(getActionData(youtubeConstants.CREATE_ACCOUNT_SUCCESS, response.data));
                    SnackBar.showMessage(alertMessages.YOUTUBE_CREATE_ACCOUNT_SUCCESS);
                } else {
                    SnackBar.showMessage(statusText);
                }
            }).catch((error) => {
                if(_.get(error, 'response.status') === 401){
                    Auth.processLogout();
                    return;
                }
                dispatch(getActionData(youtubeConstants.CREATE_ACCOUNT_FAILURE, {}, error));
                SnackBar.showMessage(alertMessages.GENERIC_ERROR)
            })
    }
}

function saveChannel(payload) {
    return (dispatch) => {
        dispatch(getActionData(youtubeConstants.SAVE_CHANNEL_REQUEST));
        youtubeOAuthService.saveChannel(payload)
            .then(response => {
                dispatch(getActionData(youtubeConstants.SAVE_CHANNEL_SUCCESS, response.data));
                SnackBar.showMessage(alertMessages.YOUTUBE_CREATE_ACCOUNT_SUCCESS);
            }).catch((error) => {
            if(_.get(error, 'response.status') === 401){
                Auth.processLogout();
                return;
            }
            dispatch(getActionData(youtubeConstants.SAVE_CHANNEL_FAILURE, {}, error));
            SnackBar.showMessage(alertMessages.GENERIC_ERROR)
        })
    }
}

export const youtubeActions = {
    initiateOauth,
    createAccount,
    saveChannel
};