import { Box } from '@mui/material'

export default function HeadlineArrow({
	headline
}) {
	return (
		<Box sx={styles.heading}>
			<Box component={'span'} sx={styles.headingChild}>{headline}</Box>
		</Box>
	)
}

const styles = {
	heading: {
		p: 2,
		mb: 4,
		display: 'inline-flex',
		fontFamily: 'larsseitBold',
		letterSpacing: '0.05em',
		textTransform: 'uppercase',
		fontSize: { xs: '14px', md: '20px' },
		borderRadius: '5px',
		backgroundColor: '#DCD8FF',
		position: 'relative',

		'&:after': {
			content: '""',
			position: 'absolute',
			bottom: 0,
			height: '30px',
			width: '30px',
			borderRadius: '3px',
			backgroundColor: 'inherit',
			left: '50%',
			transform: 'rotate(55deg) skew(20deg) translateX(-6px) translateY(20px)',
		},
	},
	headingChild: {
		position: 'relative',
		zIndex: 10,
	}
}