import React, { useEffect, forwardRef, createElement, useImperativeHandle } from 'react';
import {Box} from "@mui/system";
import {Popover} from "@mui/material";

/**
 * {object} params
 * @param {id | string} params.id - unique id of the popover, for lists add the key into it (e.g. `my-cool-id-${key}`)
 * @param {React.ReactNode | string} params.ButtonComponent - component to be used as the trigger button
 * @param {React.ReactNode} params.ButtonProps - children content of the `props.ButtonComponent`
 * @param {React.ReactNode} params.children - children content of the popover
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{
 * readonly children?: *,
 * readonly ButtonProps?: *,
 * readonly id?: *,
 * readonly ButtonComponent?: *}>
 * & React.RefAttributes<unknown>>}
 */
export const BasicPopover = forwardRef(({id, ButtonComponent, ButtonProps, children}, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        if (!id) console.error('Popover component: `id` prop is required');
    }, []);

    useImperativeHandle(ref, () => ({
        close() {
            handleClose();
        }
    }));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const componentId = open ? id : undefined;

    return(
        <Box>
            {createElement(
                ButtonComponent,
                {
                    ...ButtonProps,
                    'aria-describedby': componentId,
                    onClick: handleClick,
                },
            )}
            <Popover
                id={componentId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        border: "solid 1px #B9C6C6",
                        borderRadius: '4px',
                        boxShadow: '0px 4px 4px rgba(12, 39, 40, 0.25)'
                    },
                }}>
                {children}
            </Popover>
        </Box>
    )
});