import React from "react";
import { queryStringToObject } from "_helpers";
import { ResetPassword, PasswordError } from "components/ResetPasswordPage";
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { authService } from "_services";
import connect from "react-redux/es/connect/connect";

/**
 * @author Chris Okebata
 */

class ConfirmEmail extends React.Component{
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount(){
        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);

        const { dispatch } = this.props;
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if(currentUser){
            dispatch(authService.logout());
        }
        dispatch(authService.confirmEmail(filterParams.userId, filterParams.token))
    }

    render() {

        const { confirmEmailResponse } = this.props;
        let renderAuthView;

        if(confirmEmailResponse && confirmEmailResponse.status !== 'error') {
            renderAuthView = (
                <BrowserRouter>
                    <div>
                        <Redirect push to="/resetPassword"/>
                        <Route path='/resetPassword'
                               render={(props) =>
                                   <ResetPassword
                                       {...props}
                                       encryptedUserId={confirmEmailResponse.encryptedUserId}
                                   />
                               }
                        />
                    </div>
                </BrowserRouter>
            );
        }
        else if((this.state.error) || (confirmEmailResponse && confirmEmailResponse.status === 'error')){
            renderAuthView = (
                <BrowserRouter>
                    <div>
                        <Redirect push to="/resetPassword"/>
                        <Route path='/resetPassword'
                               render={() => <PasswordError/>}
                        />
                    </div>
                </BrowserRouter>
            );
        }

        return(
            <div>
                {renderAuthView}
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { auth: { confirmingEmail, confirmEmailResponse } } = state;
    return {
        confirmingEmail,
        confirmEmailResponse
    };
}

const connectedConfirmEmail = connect(mapStateToProps)(ConfirmEmail);
export { connectedConfirmEmail as ConfirmEmail };