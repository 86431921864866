import React from "react";

export default function useShopifySetup(){
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const performAction = () => {
    handleOpen();
  };

	return {
		initiateShopifySetup: performAction,
		shopifySetupOpen: open, 
		handleShopifySetupOpen: handleOpen,
		handleShopifySetupClose: handleClose
	};
}