const baseLabelStyle = {
    fontSize: '14px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase'
};

export default {
   labelBold: {
       ...baseLabelStyle,
       fontFamily: 'LarsseitBold'
   },
   labelMedium: {
        ...baseLabelStyle,
       fontFamily: 'LarsseitMedium'
   },
};
