import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { dataSourceHandleActions } from "_actions";
import { connect } from "react-redux";
import { Loading } from "components/common";


/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 20/12/2018 : 11:07 AM
 */
class ListDataSourceHandles extends Component {

    componentDidMount() {
        this.props.dispatch(dataSourceHandleActions.getAll());
    }

    /**
     * Handles click on a pagination button/link.
     * @param page
     * @param params
     */
    handleSelect = (page, params) => {
        params.offset = params.max * ( page.selected );
        this.props.dispatch(dataSourceHandleActions.getAll(params))
    };

    /**
     * Renders a single row of dataSourceHandle information on the index table.
     * @param dataSourceHandle
     * @returns {*}
     */
    renderRow = (dataSourceHandle) => {

        return (
            <tr key={dataSourceHandle.id}>
                <td>{dataSourceHandle.name}</td>
                <td>{dataSourceHandle.dispatchStatus}</td>
                <td>[Placeholder]</td>
                <td>[Placeholder]</td>
            </tr>
        );
    };

    render() {

        const { dataSourceHandles: { dataSourceHandles } } = this.props;

        if (dataSourceHandles.loading) {
            return <Loading/>
        }

        return (

            <div>
                <br/>
                <br/>
                <Row>
                    <Col sm={4} md={4}>
                        <Button className='btn-danger' onClick={() => this.props.disableMsgSources()}>
                            Deactivate All Handles
                        </Button>
                    </Col>
                </Row>
                <br/>

                <Row>
                    <Col sm={12} md={12}>
                        <table className='table trds-table'>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Dispatch Status</th>
                                <th>MsgDataSource</th>
                                <th>Organization</th>
                            </tr>
                            </thead>
                            <tbody>

                            {dataSourceHandles.map(this.renderRow.bind(this))}

                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        );

    }
}

function mapStateToProps(state) {
    const { dataSourceHandles } = state;

    return {
        dataSourceHandles: dataSourceHandles
    };
}

const connectedListDataSourceHandles = connect(mapStateToProps)(ListDataSourceHandles);
export { connectedListDataSourceHandles as ListDataSourceHandles };
