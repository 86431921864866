import {Box} from "@mui/material";
import {QuillEditor} from "../../../../common";

export default function SuggestedResponseEditor({text, handleEditedSuggestedResponse, suggestedResponseId, isEmail}){

    const handleTextChange = (editorText) => {
        handleEditedSuggestedResponse(suggestedResponseId, editorText);
    }

    return(
        <Box sx={{marginTop: '9px'}}>
            <Box className="proactive-template-text-editor">
                <QuillEditor
                    defaultText={text}
                    handleTextChange={handleTextChange}
                    isEmail={isEmail}
                    isImageEmbedSupported={isEmail}
                />
            </Box>
        </Box>
    )
}