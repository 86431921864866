import { SERVER_URL } from 'config';
import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';


function initiateOauth(params) {
    return fetch(`${SERVER_URL}/api/shopify/initiateOauth?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

function generateAccessToken(params) {
    return fetch(`${SERVER_URL}/api/shopify/oauthToken?${objectToQueryString(params)}`,
        {
            method: 'POST',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

export const shopifyOauthService = {
    initiateOauth,
    generateAccessToken
};