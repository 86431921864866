import {Fragment} from "react";
import {TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";

export default function BasicMultilineTextField({input, label, id, name, handleChange,
                                                    placeholder, rowsCount= 2, meta: { touched, error }}) {
    const classes = useStyles();
    const hasError = touched && !_.isEmpty(error);
    return(
        <Fragment>
            <TextField className={ hasError ? classes.textFieldError : classes.textField }
                       InputProps={{ classes: { root: classes.textField } }}
                       FormHelperTextProps={{ className: classes.helperText }}
                       id={id}
                       name={name}
                       label={label}
                       variant="outlined"
                       onChange={handleChange}
                       placeholder={placeholder}
                       {...input}
                       error={ hasError }
                       helperText={ hasError ? error : undefined }
                       multiline
                       rows={rowsCount} />
        </Fragment>
    )
}

const useStyles = makeStyles({
    textField: {
        width: '100%',
        fontSize: '16px !important',
        backgroundColor: 'unset !important',
        color: '#0C2728',
        [`& fieldset`]: {
            border: '1px solid #0C2728',
            borderRadius: '8px',
        },
        [`& label`]: {
            fontSize: '16px',
        },

        '& label.Mui-focused': {
            color: '#0C2728',
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#0C2728',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
    },

    textFieldError: {
        width: '100%',
        fontSize: '16px !important',
        backgroundColor: 'unset !important',
        color: '#0C2728',
        [`& fieldset`]: {
            border: '1px solid #EB5757',
            borderRadius: '10px',
        },
        [`& label`]: {
            fontSize: '16px',
        },
    },
    helperText: {
        position: 'absolute',
        top: '-29px',
        right: '-14px'
    }
});