import {SegmentBuilder} from "./objects/SegmentBuilder";
import {attributes, channelTypes, excludedSegmentTypes, segmentTypes} from "_constants";
import {getArrayFromObjectArray} from "_helpers";
import _ from "lodash";
import {SnackBar} from "../../../../common";
import * as moment from 'moment-timezone'

/**
 * Build a create-campaign payload
 * @param params {Object}
 * @param parsedDate {Object}
 * @returns {*&{endDate, startDate, segments: ({}|[]|{})}}
 */
export function buildCreateCampaignPayload(params, parsedDate) {
    const {segments, channel, minDaysBetweenSameUserReplies, blockedAccounts, blockedKeywords, ticketLimit, tiktokDateFilter, customerDataFileId} = params;
    const {startDate, endDate} = parsedDate;
    const attributes = extractAttributes(params, tiktokDateFilter)
    params = sanitizePayload(params);

    return {
        ...params,
        startDate,
        endDate,
        attributes,
        blockedAccounts: splitCommaSeparatedValues(blockedAccounts),
        blockedKeywords: splitCommaSeparatedValues(blockedKeywords),
        minDaysBetweenSameUserReplies: parseInt(minDaysBetweenSameUserReplies) || null,
        segments: parseSegments(segments, channel, customerDataFileId)
    };
}

/**
 * @param id {string}
 * @param params {object}}
 * @param parsedDate {object}
 * @param channel {string}
 * @returns {{[p: string]: *}}
 */
export function buildUpdateCampaignPayload(id, params, parsedDate, channel) {
    const {minDaysBetweenSameUserReplies, blockedAccounts, blockedKeywords, segments, dateFilter, customerDataFileId} = params;
    const {startDate, endDate} = parsedDate;
    const newAttributes = extractAttributes(params, dateFilter)
    params = sanitizePayload(params);

    return {
        ...params,
        id,
        startDate,
        endDate,
        newAttributes,
        blockedAccounts: splitCommaSeparatedValues(blockedAccounts),
        blockedKeywords: splitCommaSeparatedValues(blockedKeywords),
        minDaysBetweenSameUserReplies: parseInt(minDaysBetweenSameUserReplies) || null,
        newSegments: parseSegments(segments, channel, customerDataFileId)
    };
}

/**
 * Process segments
 * @param segments {string}
 * @param channel {string}
 * @param customerDataFileId {string}
 */
function parseSegments(segments, channel, customerDataFileId) {
    if((!segments && !customerDataFileId) || !channel ) return [];
    const segmentArr = segments?.split(",") || [];
    switch (channel) {
        case channelTypes.tikTok: return processKeywordSegments(segmentArr);
        case channelTypes.kustomerEmail: return processCustomerIoSegment(segments, customerDataFileId);
        case channelTypes.shopify: return processQuerySegment(segments, customerDataFileId);
        case channelTypes.youtube: return processKeywordSegments(segmentArr);
        default: return [];
    }
}

/**
 * Process keyword segments for TIKTOK proactive channel
 * @param segmentArr {Array}
 * @returns {*[]}
 */
function processKeywordSegments(segmentArr) {
    const segments = [];
    segmentArr.map(segment => {
        const builder = new SegmentBuilder();
        builder.setKeyword(segment);
        builder.setType(segmentTypes.keyword);
        segments.push(builder.build());
    })
    return segments;
}

/**
 * Process customer.io segment for supported email proactive channel
 * @param segment
 * @param customerDataFileId
 * @returns {*[]}
 */
function processCustomerIoSegment(segment, customerDataFileId) {
    const segments = [];
    if(segment){
        const builder = new SegmentBuilder();
        builder.setKeyword(segment);
        builder.setType(segmentTypes.customerIo);
        segments.push(builder.build());
    }
    if(customerDataFileId){
        const builder = new SegmentBuilder();
        builder.setCustomerDataFileId(customerDataFileId);
        builder.setType(segmentTypes.customerDataFile);
        segments.push(builder.build());
    }
    return segments;
}

/**
 * Process query segments for SHOPIFY proactive channel
 * @param segment {string}
 * @param customerDataFileId {string}
 * @returns {*[]}
 */
function processQuerySegment(segment, customerDataFileId) {
    const segments = [];
    if(segment){
        const builder = new SegmentBuilder();
        builder.setKeyword(segment);
        builder.setType(segmentTypes.query);
        segments.push(builder.build());
    }
    if(customerDataFileId){
        const builder = new SegmentBuilder();
        builder.setCustomerDataFileId(customerDataFileId);
        builder.setType(segmentTypes.customerDataFile);
        segments.push(builder.build());
    }
    return segments;
}

/**
 * Build get msg data source payload
 * @param organizationId {string}
 * @param channel {string}
 * @returns {{organizationId, channel}}
 */
export function buildGetMsgDataSourcePayload(organizationId, channel) {
    return {
        organizationId: organizationId,
        channel: channel
    }
}

/**
 * Build get msg data source payload
 * @param organizationId {string}
 * @returns {{organizationId}}
 */
export function buildGetOrgMsgDataSourcesBySourceNamePayload(organizationId) {
    return {
        organizationId: organizationId,
        msgSourceName: 'CustomerIo'
    }
}

/**
 *
 * @param msgDataSourceId
 * @returns {{msgDataSourceId}}
 */
export function buildGetCustomerIoSegmentPayload(msgDataSourceId) {
    return {msgDataSourceId};
}

/**
 * Build get company template payload
 * @param organizationId {string}
 * @param channel {string}
 * @returns {{organizationId, channel}}
 */
export function buildGetCompanyTemplatePayload(organizationId, channel) {
    return {
        organizationId: organizationId,
        channel: channel
    }
}

/**
 * @param criteria
 * @param generatedReplies
 * @returns {{organizationId: string, generatedTexts: any[], userProvidedTexts: *[], name, channel: string, description, minDaysBetweenSameUserReplies}}
 */
export function buildCreateTemplatePayload(criteria, generatedReplies) {
    const { name, topic, minDaysBetweenSameReplyUsages, channel } = criteria;
    const templates = extractTemplates(criteria);
    const generatedTexts = [...new Set(getArrayFromObjectArray(generatedReplies, 'text'))];
    const { organization_id = '' } = JSON.parse(localStorage.user);
    return {
        name: name,
        description: topic,
        userProvidedTexts: templates,
        generatedTexts: generatedTexts,
        organizationId: organization_id,
        channel: channel,
        minDaysBetweenSameReplyUsages
    }
}

/**
 * @param id {string}
 * @param criteria {object}
 * @param generatedReplies {object}
 * @returns {{minDaysBetweenSameReplyUsages, newGeneratedTexts: any[], name, description, id, newUserProvidedTexts: *[]}}
 */
export function buildUpdateTemplatePayload(id, criteria, generatedReplies) {
    const { name, topic, minDaysBetweenSameReplyUsages } = criteria;
    const templates = extractTemplates(criteria);
    let generatedTexts = [...new Set(getArrayFromObjectArray(generatedReplies, 'text'))];
    return {
        id: id,
        name: name,
        description: topic,
        newUserProvidedTexts: templates,
        newGeneratedTexts: generatedTexts,
        minDaysBetweenSameReplyUsages
    }
}

/**
 * @param params {object}
 * @returns {{templates: *[], topic}}
 */
export function buildGenerateRepliesPayload(params) {
    const {topic} = params;
    const templates = extractTemplates(params);
    const { organization_id = '' } = JSON.parse(localStorage.user);
    return {
        organizationId: organization_id,
        topic: topic,
        templates: templates
    }
}

/**
 *
 * @param isRejected {boolean}
 * @returns {{isRejected}}
 */
export function buildUpdateSuggestedResponsePayload(isRejected) {
    return {
        isRejected: isRejected
    }
}

/**
 * @param object {object}
 * @returns {*[]}
 */
function extractTemplates(object) {
    const templates = [];
    for(const key in object) {
        if(key.includes('template')) templates.push(object[key]);
    }
    return templates
}

export function extractKeywordsFromSegments(segmentList) {
    const keywords = [];
    segmentList.map(segment => {
        if(excludedSegmentTypes.includes(_.get(segment, 'type'))) return;
        keywords.push(_.get(segment, 'keyword'));
    })
    return keywords.join(', ');
}

/**
 * @param object {object}
 * @returns {unknown[]}
 */
function extractAttributes(object, tiktokDateFilter = null) {
    const relevanceLanguage = _.get(object, 'relevanceLanguage'),
        regionCode= _.get(object, 'regionCode'),
        safeSearch= _.get(object, 'safeSearch'),
        publishedAfter = _.get(object, 'publishedAfter');

    const attributeObj = {};
    for(const key in object) {
        if(key.includes('attribute')) {
            const splitKey = key.split('_');
            const attrName = splitKey[1], attrIndex = splitKey[2], attrValue = object[key];
            if(_.isEmpty(attributeObj[attrIndex])) {
                attributeObj[attrIndex] = {[attrName]: attrValue}
            } else {
                Object.assign(attributeObj[attrIndex], {[attrName]: attrValue})
            }
        }
    }
    if (tiktokDateFilter) {
        const parsedDate = tiktokDateFilter.toISOString()
        attributeObj['dateFilter'] = {
            name: attributes.CREATED_AT_SOURCE_AFTER_DATE_ATTRIBUTE,
            type: 'DATE',
            value: parsedDate
        }
    } if (publishedAfter) {
        const parsedDate = publishedAfter.toISOString()
        attributeObj['dateFilter'] = {
            name: attributes.YOUTUBE_PUBLISHED_AFTER,
            type: 'DATE',
            value: parsedDate
        }
    } if (regionCode) {
        attributeObj['regionCode'] = {
            name: attributes.YOUTUBE_REGION_CODE,
            type: 'TEXT',
            value: regionCode
        }
    } if (safeSearch) {
        attributeObj['safeSearch'] = {
            name: attributes.YOUTUBE_SAFE_SEARCH,
            type: 'TEXT',
            value: safeSearch
        }
    } if (relevanceLanguage) {
        attributeObj['relevanceLanguage'] = {
            name: attributes.YOUTUBE_RELEVANCE_LANGUAGE,
            type: 'TEXT',
            value: relevanceLanguage
        }
    }
    return Object.values(attributeObj);
}

/**
 * Clean up unused properties from payload
 * @param object
 * @returns {*}
 */
function sanitizePayload(object) {
    for(const key in object) {
        if(key.includes('attribute_')) delete object[key];
        if(key.includes('customerDataFileId')) delete object[key];
    }
    return object;
}

/**
 * @param values {string}
 * @returns {*|*[]}
 */
function splitCommaSeparatedValues(values) {
    if(!values) return [];
    return values.split(",").map(value => value.trim());
}

/**
 * @param value {array}
 * @param withTrim {boolean}
 * @returns {*|string}
 */
export function arrayToCommaSeparatedValues(value, withTrim = false) {
    if(!value) return '';
    return withTrim ? value.join(',') : value.join(', ');
}

export function validateTopic(values) {
    const topic = _.get(values, 'topic') || '';
    return topic.length >= 3;
}

/**
 * @param ratio {float}
 * @returns {*|string}
 */
export function formatProactiveMetric(ratio) {
    const percentage = _.round(ratio * 100.0, 1);
    if (ratio > 0.0 && percentage == 0.0) {
        return '< 0.1%';
    }
    return percentage.toFixed(1).replace(/\.0+$/, '') + '%';
}

export function formatToDate(dateTime) {
    if (!dateTime)
        return '-';
    const zone_name =  moment.tz.guess();
    const timezone = moment.tz(zone_name).zoneAbbr();
    return `${moment(dateTime).format('MMM D, YYYY hh:mm a z')}${timezone}`;
}

export function buildMetricsDetailsUrl(campaign, pageFilter, params) {
    return (`/proactive-metrics-details?campaignIds=${_.get(campaign, 'id')}&pageFilter=${pageFilter}&organizationId=${_.get(campaign, 'organization.id')}
            &organizationId=${_.get(campaign, 'organization.id')}&startDate=${params.startDate}&endDate=${params.endDate}`);
}

export function buildGetCompanyCampaigns(params, dateRange) {
    return {
        organizationId: params.organizationId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }
}

export function buildGetCampaignMetricsPayload(params, campaignIds) {
    return {
        campaignIds: campaignIds,
        organizationId: params.organizationId
    }
}

export function buildUpdateSegmentStatusPayload(isActive, segmentId, campaignId) {
    return {
        isActive,
        segmentId,
        campaignId
    }
}

/**
 * @param editedText
 * @returns {{editedText}}
 */
export function buildEditSuggestedResponsePayload(editedText) {
    return {
        editedText
    }
}

export function verifyCsvFileSize(file) {
    const maxFileSize = 25 * 1024 * 1024
    const fileSize = file.size;
    if (fileSize > maxFileSize){
        SnackBar.showMessage('File size exceeded maximum limit of 25mb');
        return false
    }
    return true
}

export function buildCSVFormData(csvFile) {
    const formData = new FormData();
    formData.append('file', csvFile);
    return formData;
}


export const ALL_CAMPAIGNS = "allCampaigns";
export const COMPANY_CAMPAIGN = "companyCampaign";