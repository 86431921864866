import importedStyles from "./Utilities/rootStyles";

export default {
    root: {
        ...importedStyles.root,
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    container: {
        px: { xs: 2, sm: 4, md: 6 },
        maxWidth: '1370px',
        position: 'relative',
    },
    sectionWrapper: {
        width: '100%',
        p: '20px',
        backgroundColor: '#FFFFFF',
        borderRadius: '7px',
        border: '1px solid #CED4D4'
    },
    sectionDivider: {
        mt: '15px',
        mb: '15px',
        backgroundColor: '#E7E9E9'
    },
    heading: {
        m: 0,
        fontSize: { xs: '16px', md: '16px' },
        fontFamily: 'LarsseitMedium',
    },
    switch: {
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: '#368c86'
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: '#368C86'
        },
        "&:hover .MuiSwitch-switchBase": {
            color: '#368c86'
        },
    },
    infoItem: {
        fontSize: {xs: '15px', md: '16px'},
        fontFamily: 'larsseitRegular',
        mt: '8px',
        color: '#495D5E',
    },
    editableInfo: {
        mt: '10px',
    },
    bodyLoading: {
		py: 4,
		display: 'flex',
		justifyContent: 'center',
	},
    notificationToggleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '40px',
    },
    notificationToggleLabel: {
        m: 0,
        fontSize: { xs: '16px', md: '17px' },
        fontFamily: 'LarsseitMedium',
        width: '200px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    mt10: {
        mt: '10px'
    }
}