import React from "react";
import {Box} from "@mui/system";
import EngineeringIcon from '@mui/icons-material/Engineering';
import {useAuth0} from "@auth0/auth0-react";
import {authService} from "_services";
import {auth0Actions} from "_actions";
import {Loading} from "../common";
import {useDispatch} from "react-redux";
import chatdeskLogo from 'components/images/logos/logo_chatdesk.svg';

export default function OutOfService({}) {
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth0();
    const date = new Date()
    if(isAuthenticated){
        dispatch(auth0Actions.logoutUser());
        authService.logoutWithRedirect('out-of-service');
    }

    if(isAuthenticated){ // at this point, the user is still being logged out from Auth0
        return <Loading/>
    }

    return(
        <Box sx={styles.background}>
            <Box sx={styles.navBar}>
                <Box sx={styles.logoContainer}>
                    <img src={chatdeskLogo}
                         alt="chatdesk logo"
                         style={styles.logo}
                    />
                </Box>
            </Box>
            <Box sx={{mx: 'auto'}}>
                <Box sx={styles.iconContainer}>
                    <EngineeringIcon
                        sx={styles.icon}
                    />
                </Box>

                <Box sx={styles.mainText}>
                    Our site is under maintenance
                </Box>
                <Box sx={styles.subText}>
                    We'll be back shortly
                </Box>

                <Box sx={styles.buttonContainer}>
                    <Box>
                        <a href={'https://chatdesk.com'} style={styles.button}>
                            {'Back to home'}
                        </a>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.copyrightContainer}>
                <Box sx={{color: '#FFF'}}>
                    <span className="copyright">&copy; {date.getFullYear()}</span> Chatdesk | <a href="https://chatdesk.com/privacy.html" rel="noopener" style={styles.privacy}>Privacy</a>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    buttonContainer: {
        mt: '70px',
        mx: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: '100%',
        fontSize: '16px',
        padding: '15px 25px',
        height: '40px',
        color: '#082320',
        fontFamily: 'LarsseitMedium',
        textTransform: 'none',
        borderRadius: '50px',
        backgroundColor: '#ffffff',
        border: '1px solid #FFF',
        textDecoration: 'none',
        position: 'relative', top: '8px',
        ':hover': {
            backgroundColor: '#ffffff',
            color: '#368C86',
        },
        ':disabled': {
            color: '#368C8680',
            backgroundColor: '#f5f5f5',
            border: '1px solid #368C8680'
        },
        ':focus': {
            outline: '0'
        }
    },
    copyrightContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        top: '250px'
    },
    privacy: {
        color: '#c0c6c7',
        textDecoration: 'underline'
    },
    mainText: {
        mt: '35px',
        color: '#FFF',
        fontFamily: 'LarsseitBold',
        fontSize: '45px',
        textAlign: 'center'
    },
    subText: {
        mt: '10px',
        color: '#c0c6c7',
        fontFamily: 'LarsseitLight',
        fontSize: '18px',
        textAlign: 'center'
    },
    iconContainer: {
        width: '150px',
        mt: '100px',
        mx: 'auto'
    },
    icon: {
        width: '150px',
        height: '150px',
        color: '#E6FF53'
    },
    logo: {
        maxHeight: '40px',
        width: '50px'
    },
    logoContainer: {
        width: '50px',
        py: '18px'
    },
    navBar: {
        backgroundColor: '#FFF',
        px: '50px'
    },
    background: {
        backgroundColor: '#0C2728',
        height: '100vh'
    }
}
