export const earningsConstants = {
    GET_EARNINGS_HISTORY_REQUEST: 'GET_EARNINGS_HISTORY_REQUEST',
    GET_EARNINGS_HISTORY_SUCCESS: 'GET_EARNINGS_HISTORY_SUCCESS',
    GET_EARNINGS_HISTORY_FAILURE: 'GET_EARNINGS_HISTORY_FAILURE',

    GET_LEAD_AGENTS_REQUEST: 'GET_LEAD_AGENTS_REQUEST',
    GET_LEAD_AGENTS_SUCCESS: 'GET_LEAD_AGENTS_SUCCESS',
    GET_LEAD_AGENTS_FAILURE: 'GET_LEAD_AGENTS_FAILURE',

    GET_AGENT_PAYOUTS_REQUEST: 'GET_AGENT_PAYOUTS_REQUEST',
    GET_AGENT_PAYOUTS_SUCCESS: 'GET_AGENT_PAYOUTS_SUCCESS',
    GET_AGENT_PAYOUTS_FAILURE: 'GET_AGENT_PAYOUTS_FAILURE',
}