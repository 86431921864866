import React, {useState} from "react";
import {Box, Stack} from "@mui/material";
import importedStyles from "../Utilities/styles";
import useLocationQueryDateRange from "../../../hooks/useLocationQueryDateRange";
import DateRangeForm from "../../../common/DateRangeForm";
import CampaignsTable from "../Utilities/CampaignsTable";
import useLocationQueryParams from "../../../hooks/useLocationQueryParams";
import {useDispatch} from "react-redux";
import useGetCompanyCampaigns from "../Utilities/hooks/useGetCompanyCampaigns";
import {proactiveActions} from "_actions";
import moment from "moment";
import {getYearRange} from "../../../../../_helpers";

export default function CompanyCampaign() {
    const dispatch = useDispatch();
    const offsetBase = 10;
    const yearCount = 2
    const { startDate, endDate, setDates } = useLocationQueryDateRange(
        { initialStartDate: moment().subtract(yearCount, 'years') }
    );
    const { params, setParams } = useLocationQueryParams();
    const [page, setPage] = useState(() => {
        const offset = Number(params.offset);
        return offset ? (offset / offsetBase) : 0;
    });
    const { organization_id = '' } = JSON.parse(localStorage.user);
    const payload = {
        organizationId: organization_id,
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
        offset: page * offsetBase
    }
    const { rows, columns, totalCount, loading } = useGetCompanyCampaigns(payload);

    const handlePageChange = (event, nextPage) => {
        setPage(nextPage);
        const queryParams = {...payload, offset: nextPage * offsetBase}
        setParams({...params, ...queryParams});
        dispatch(proactiveActions.getCompanyCampaigns(queryParams));
    };

    const handleTableCellClick = (params) => {
        window.location.replace(params);
    }

    return(
        <>
            <Box sx={styles.root}>
                <Box sx={styles.container}>
                    <Stack direction={{ xs: 'column', md: 'row' }} sx={styles.hero} spacing={2}>
                        <Box component={'h1'} sx={importedStyles.headingLarge}>
                            Proactive Campaigns
                        </Box>
                        <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
                            <DateRangeForm
                                startDate={startDate}
                                endDate={endDate}
                                setDates={setDates}
                                label={'Viewing Campaigns for'}
                                additionalRanges={[
                                    getYearRange(yearCount)
                                ]}
                            />
                        </Box>
                    </Stack>

                    <Box>
                        <CampaignsTable
                            rows={rows}
                            columns={columns}
                            totalCount={totalCount}
                            page={page}
                            loading={loading}
                            handlePageChange={handlePageChange}
                            handleTableCellClick={handleTableCellClick}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const styles = {
    root: {
        ...importedStyles.root,
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },

    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1440px',
        position: 'relative',
    },

    hero: {
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        mb: { xs: '24px', sm: '40px', },
    },
}