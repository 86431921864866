import React from 'react'
import { Jumbotron, Row } from "react-bootstrap";


/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 11/12/2018 : 11:42 AM
 */
const NotFoundPage = () => (
    <div>
        <Jumbotron>
            <h1 className="text-center">Oh ow! You seem to be lost.</h1>
        </Jumbotron>
        <Row className='text-center'>
            <a className='btn btn-success' href='/'>Click here to head home</a>
        </Row>
    </div>
);

export default NotFoundPage