export class AllMessagesBuilder {
    constructor() {
        this.messages = {}
    }

    setId(id) {
        this.messages.id = id
        return this;
    }

    setText(text) {
        this.messages.text = text
        return this;
    }

    setDate(date) {
        this.messages.date = date
        return this;
    }

    setIsLocked(isLocked){
        this.messages.isLocked = isLocked
        return this;
    }

    setStatus(status) {
        this.messages.status = status
        return this;
    }

    build(){
        return this.messages;
    }
}