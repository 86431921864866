import React from 'react';
import { Field, reduxForm } from "redux-form";
import connect from "react-redux/es/connect/connect";
import { Table, TableRow, TableBody, TableCell, TableHead } from '@material-ui/core';
import { organizationActions } from "_actions";
import { Loading } from "components/common";
import { isEmpty } from "lodash";
import { DateRange } from 'components/common/formFields/DateRange'
import moment from "moment";

class PerfectResponses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().add(2, 'd').format('YYYY-MM-DD HH:mm:ss')
        };
    }

    componentDidMount() {
        this.props.dispatch(organizationActions.getPerfectResponsesSummary(this.state))
    }

    /**
     * Handles date picker update. This method update the selected dates to the state
     * and dispatches the getOrganizationsPaidMessages action for the date selected
     * @param orgId
     * @returns {function(*)}
     */
    handleDatePickerUpdate = (event, data) => {
        if(event.type === 'apply'){
            this.setState({
                startDate : data.startDate.format('YYYY-MM-DD HH:mm:ss'),
                endDate   : data.endDate.format('YYYY-MM-DD HH:mm:ss')
            }, () => {
                this.props.dispatch(organizationActions.getPerfectResponsesSummary(this.state))
            });
        }
    };

    render() {
        const { loading, perfectResponsesSummary } = this.props;
        const  { startDate, endDate } = this.state;

        return (
            <div className='container-pane container-pane-expert-page'>
                <label className="calendar-today tms-inbox-calendar expert-page-date-picker">
                    <Field
                        submittedStartDate={startDate}
                        submittedEndDate={endDate}
                        id="datePicker"
                        name="datePicker"
                        defaultDateRange="today"
                        onEvent={this.handleDatePickerUpdate}
                        values={startDate}
                        component={DateRange}
                    />
                </label>

                {loading && <Loading/>}

                {!loading && isEmpty(perfectResponsesSummary) &&
                <div className="font-20-bold tms-align-text-center padding-20-0 expert-page-table">
                    No companies with votes available
                </div>}

                {!loading && !isEmpty(perfectResponsesSummary) &&
                <Table className="expert-page-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Company</TableCell>
                            <TableCell>Total Paid Messages Sent By Voting</TableCell>
                            <TableCell>Messages With At Least 1 Perfect Response</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {perfectResponsesSummary && perfectResponsesSummary.map(org => {
                        return (
                            <TableRow key={org.id}>
                                <TableCell>{org.name}</TableCell>
                                <TableCell>{org.voteCount}</TableCell>
                                <TableCell>{org.perfectCount > 0 ? org.perfectCount : 0}</TableCell>
                            </TableRow>
                        );
                    })}
                    </TableBody>
                </Table>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {organizations: { perfectResponsesSummary,loading }} = state;

    return {
        loading,
        perfectResponsesSummary
    };
}

PerfectResponses = connect(mapStateToProps)(PerfectResponses);
const perfectResponses = reduxForm({
    form: 'PerfectResponses'
})(PerfectResponses);

export { perfectResponses as PerfectResponses }