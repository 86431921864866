export class AttributeBuilder {
    constructor() {
        this.attribute = {};
    }

    setName(name){
        this.attribute.name = name;
        return this;
    }

    setType(type){
        this.attribute.type = type;
        return this;
    }

    setValue(value){
        this.attribute.value = value;
        return this;
    }

    setActions(actions){
        this.attribute.actions = actions;
        return this;
    }

    build(){
        return this.attribute;
    }
}
