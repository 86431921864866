/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 11/02/2019 : 1:11 PM
 */

import {
    handleType,
    handleTypeNames,
    supportedSourcesForAttachmentUpload,
    supportedSourcesForEmbedImage
} from '_constants';

/**
 * Replaces placeholders for [AGENT_NAME] and [CUSTOMER_NAME] with actual values if provided,
 * or blanks if not provided.
 * @param message: The string to be customized
 * @param customerName: Name of the customer. This replaces [CUSTOMER_NAME]
 * @param agentName: Name of the agent or review voting on the message. This replaces [AGENT_NAME]
 * @param handleTypeName: The type of the dataSourceHandle to which the message belong. See {@link handleTypeNames}
 */
export const personalizeResponse = (message, customerName = '', agentName = '', handleTypeName) => {

    let formattedCustomerName = formatCustomerName(customerName, handleTypeName);
    let formattedExpertName = formatCustomerName(agentName);

    const agentRegEx = /(\[AGENT_NAME\])/g;
    const customerRegEx = /(\[CUSTOMER_NAME\])/g;

    try {
        return message.replace(customerRegEx, formattedCustomerName)
            .replace(agentRegEx, countCharAndConvertToUpperCase(formattedExpertName, 2))
    } catch (e) {
        return message
    }
};


export const formatCustomerName = (rawName, handleTypeName = null, msgSource = null) => {

    if (!rawName) {
        return ''
    }
    if(msgSource) {
        msgSource = msgSource.toLowerCase();
    }
    rawName = rawName.trim();

    if (rawName) {
        const filters = ['null', 'facebook user', 'none', 'anonymous', 'cancellation'];
        const nameExpression = filters.join('|');
        const regex = new RegExp( '\\b(' + nameExpression + ')\\b', "i" );
        const matchedRawName = regex.test( rawName );

        if (handleTypeName === handleTypeNames.CHAT) {
            const firstAndLastNames = rawName.split(' ');

            // For chat handle type, if the name is a single word, use a blank else, use the first name.
            if (!firstAndLastNames || firstAndLastNames.length === 1) {
                rawName = ''
            } else {
                rawName = countCharAndConvertToUpperCase(firstAndLastNames[0], 2)
            }
        }
        else if ([handleTypeNames.EMAIL, handleTypeNames.PROACTIVE_EMAIL, handleTypeNames.PROACTIVE_POST].includes(handleTypeName || "")) {
            const firstAndLastNames = rawName.split(' ');
            rawName = countCharAndConvertToUpperCase(firstAndLastNames[0], 2)
        }

        const prependAt = handleTypeName === handleTypeNames.SOCIAL_FEED_WITH_HANDLE_MENTIONS_BUT_NO_LINKS
            || handleTypeName === handleTypeNames.SOCIAL_FEED_WITH_LINKS_AND_HANDLE_MENTIONS;

        if (msgSource === 'instagram' || msgSource === "twitter"){
            rawName = matchedRawName ? '' : prependAt
                ? `@${rawName.split(' ')[0]}` : rawName.split(' ')[0];
            return rawName && rawName.toLowerCase()
        } else {
            rawName = matchedRawName ? '' : prependAt
                ? `@${rawName.split(' ')[0]}` : countCharAndConvertToUpperCase(rawName.split(' ')[0], 2);
        }
    }

    return rawName
};

/**
 * Convert all the characters in a string to uppercase
 * if string length matches the specified charCount parameter
 * @param text
 * @param charCount
 * @returns {*}
 */
export const countCharAndConvertToUpperCase = (text, charCount) => {
    if(!text) return '';
    text = text.length <= charCount ? text.toUpperCase() : capitalize(text);
    return text
};

/**
 * Convert the first character in a string to uppercase
 * @param text
 * @returns {*}
 */
export const capitalize = (text) => {
    return text.replace(/(?:^|\s)\S/g, (text) => { return text.toUpperCase(); });
};

/**
 * Convert string to lowercase
 * @param text {string}
 * @returns {*}
 */
export const toLowerCase = (text) => {
    if(!text || typeof text !== 'string') return text;
    return text.toLowerCase();
};


export const isInstagramDM = (handleName) => {
    return handleName && handleName.includes("Instagram Messages")
}

export const shouldCheckDeleteStatus = (sourceName, handleName) => {
    return sourceName && handleName && ["instagram"].includes(sourceName) && (handleName.includes("Comments"))
}

export const getFileExtFromName = (fileName) => {
    if(!fileName) return fileName;
    let splitFileName = fileName.split(".");
    return splitFileName[(splitFileName.length)-1];
};

export const canShowAttachment = (msgHandleType, msgSource) => {
    if(!msgHandleType || !msgSource) return false;
    return [handleType.email, handleType.proactive_email].includes(msgHandleType) && supportedSourcesForAttachmentUpload.includes(msgSource.toLowerCase());
};

export const canEmbedImage = (msgHandleType, msgSource) => {
    if(!msgHandleType || !msgSource) return false;
    return [handleType.email, handleType.proactive_email].includes(msgHandleType) && supportedSourcesForEmbedImage.includes(msgSource.toLowerCase());
};

export const addObjectFromArray = (array, objectProp) =>{
    if(!array || !objectProp) return array;
    array.push(objectProp);
    return array
};

export const removeObjectFromArray = (array, objectProp, objectKey) =>{
    if(!array || !objectProp || !objectKey) return array;
    const index = array.findIndex((item) => {
        return item[objectKey] === objectProp;
    });
    if(index === -1) return array;
    array.splice(index, 1);
    return array;
};