import {Fragment} from "react";
import {Box} from "@mui/material";
import _ from "lodash";
import useViewBillingInfo from "../../hooks/useViewBillingInfo";
import BasicTable from "../../../../common/BasicTable";

export default function BillingInfoTable({label}) {
    const {rows, columns} = useViewBillingInfo(label);

    if(_.isEmpty(rows)) return <Fragment/>;

    return(
        <Box>
            <Box component={'h2'} sx={style.heading}>
                {label.CONTRACTED_VOLUME}
            </Box>

            <BasicTable
                rows={rows}
                columns={columns}
                paperStyle={{borderRadius: '5px'}}
                tableCellStyle={style.tableCell}
                tableHeadStyle={style.tableHead}
                withPagination={false}
            />
        </Box>
    )
}

const style = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px'
    },
    heading: {
        m: 0,
        mb: '14px',
        fontSize: { xs: '16px', md: '16px' },
        fontFamily: 'LarsseitMedium',
    },
    tableCell: {
        fontFamily: 'LarsseitRegular',
        fontSize: '14px',
        fontWeight: 500,
        color: '#0C2728',
        padding: '8px 5px',
        '&:first-of-type': {
            paddingLeft: '18px'
        },
        '&:last-of-type': {
            paddingRight: '18px',
        },
    },
    tableHead: {
        fontFamily: 'LarsseitMedium',
    },
    pagination: {
        overflow: 'hidden',
        borderTop: '1px solid #EEEFEF',
        height: '35px',
        '.MuiTablePagination-toolbar': {
            inlineSize: 'max-content',
            margin: 'auto',
            height: '10px',
            padding: '0'
        },
        '.MuiTablePagination-actions': {
            color: '#0C2728',
            height: '27px',
            button: {
                width: '25px',
                height: '25px',
                mx: '5px'
            },
            position: 'relative',
            top: '-7px',
        },
        '.MuiTablePagination-displayedRows': {
            position: 'relative',
            top: '-3px',
            fontFamily: 'LarsseitMedium',
            fontSize: '14px',
            color: '#0C2728',
        },
    },
}