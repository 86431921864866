import { getLogoUrl } from '_helpers';
import { Box } from '@mui/material';

// Local
import handDecorationImage from './Utilities/hand_decoration.svg'
import chatdeskAdminProfilePhoto from './Utilities/chatdesk_admin_profile.svg'

export default function HelpWidget({
	firstName,
	lastName,
	username,
	photo,
}){
	const generatedPhoto = photo && getLogoUrl(photo);
	const name = `${firstName} ${lastName}`

	return (
		<Box sx={styles.root}>
			<Box component={'img'} sx={styles.handDecorationImage} src={handDecorationImage} />
			<Box sx={styles.box}>
				{ generatedPhoto &&
					<Box component={'img'} sx={styles.image} src={generatedPhoto}/>
				}
				<Box sx={styles.content}>
					<Box sx={styles.contentHeadline}>We’re here to help</Box>
					<Box>
						Do you have a question about experts, tickets, or how you can supercharge your customer support? Contact your CSM.
					</Box>
					<Box sx={styles.name}>{name}</Box>
					<Box>{username}</Box>
				</Box>
			</Box>
		</Box>
		
	)
}

const styles = {
	root: {
		mt: { xs: 0, md: 11 },
		position: 'relative',
		width: '100%',
	},
	box: {
		borderRadius: '10px',
		overflow: 'hidden',
		border: '1px solid #D0D9D9',
		backgroundColor: '#144042',
		width: '100%',
		maxWidth: { xs: 'auto', sm: '300px' },
		color: '#fff',
	},
	image: {
		width: '100%',
		height: 220,
		objectFit: 'cover',
		objectPosition: 'center',
	},
	content: {
		p: { xs: 2, lg: 3 },
		fontWeight: 'regular',
	},
	contentHeadline: {
		textTransform: 'uppercase',
		fontFamily: 'larsseitBold',
		letterSpacing: '0.05em',
		mb: 1.5,
	},
	name: {
		fontFamily: 'larsseitMedium',
		mt: 4,
		mb: 0.5,
	},
	handDecorationImage: {
		position: 'absolute',
		bottom: { xs: '-50px', sm: '-90px' },
		right: { xs: '20px', sm: '0' },
		width: { xs: '56px', sm: '80px' },
		height: { xs: '90px', sm: '110px' },
		objectFit: 'contain',
	}
}