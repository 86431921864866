import _ from 'lodash'

/**
 * this returns a list of msgSources grouped by msgDataSource
 * @param dataSources
 * @param msgSource
 * @returns {Array}
 */
export const groupMsgSources = (msgSource, dataSources) => {

    /** filter dataSources that have a msgSource*/
    msgSource.msgDataSources = [];
    dataSources.forEach((dataSource) => {
        if (!(_.isEmpty(dataSource.msgSource))){
            if (msgSource.id === dataSource.msgSource.id){
                msgSource.msgDataSources.push(dataSource)
            }
        }
    });
    return msgSource
};

/**
 * collects all the handles for all msgDataSources beloning
 * to a given msgSource
 * @param dataSources
 * @returns {*}
 */
export const collectHandles = (dataSources) => {
    return dataSources.reduce((handles, dataSource) => {
        if (dataSource.dataSourceHandles.length > 0) {
            handles.push(...dataSource.dataSourceHandles)
        }
        return handles
    }, []);
};

/**
 * we currently receive a group of msgDataSources, each with its msgSource
 * object from the server
 * this method returns a unique list of msgSources for all the msgDataSources
 * returned from the server
 * @param dataSources
 * @returns {Array}
 */
export const collectMsgSources = (dataSources) => {

    let filteredDataSources = dataSources.filter(dataSource => {
        return !(_.isEmpty(dataSource.msgSource))
    });
    const msgSources = filteredDataSources.map(dataSource => {
        return dataSource.msgSource
    });
    return _.uniqBy(msgSources, 'id')
};

/**
 * takes all handles belonging to a msgSource
 * and checks if at least one of them have been added
 * @param dataSources
 */
export const sourceAdded = (dataSources) => {

    return dataSources.some(dataSource => {
        return dataSource.isActive
    })
};
/**
 * a msgSource is marked deleted when it contains
 * no active dataSources
 * we currently dont purge msgDataSources from our db,
 * we toggle isActive fields to false and nullify credentials
 */
export const sourceDeleted = (dataSources) =>{
    return dataSources.every(dataSource => {
        return !dataSource.isActive
    })
};