import React, {useEffect, useRef, useState} from "react";
import {Box} from '@mui/material';
import {styles} from "./MetricsDetailsStyles";
import {useDispatch, useSelector} from "react-redux";
import {MetricsDetailsComponent} from "./MetricsDetailsComponent";
import {dataSourceHandleActions, metricsActions} from "_actions";
import moment from "moment";
import {objectToQueryString, queryStringToObject} from "_helpers";
import {filterOptions, PRESALE, RESPONSE_TIME, CSAT, ALL_RESPONSES} from "./MetricsDetailUtils";
import {
    ANSWER_RATE_PAGE_FILTER,
    CSAT_PAGE_FILTER,
    PRESALE_PAGE_FILTER,
    RESPONSE_TIME_PAGE_FILTER
} from "./Utilities/MetricsDetail.constants";

const defaultFormat = 'YYYY-MM-DD HH:mm:ss'
const startDateDefault = moment().startOf('month').format(defaultFormat);
const endDateDefault = moment().add(1, 'd').format(defaultFormat);

export const MetricsDetailContainer = ( { location, history }) => {
    const search = location.search.substring(1);
    const filterParams = queryStringToObject(search);

    let { pageFilter, channels, selectedCheckboxes: checkboxes, selectedCriteria: criteria } = filterParams;
    let initialStartDate = filterParams.startDate || startDateDefault;
    let initialEndDate = filterParams.endDate || endDateDefault;
    let initialChannels = channels ? channels.split(",") : [];
    let initialSelectedCheckboxes = checkboxes ? checkboxes.split(",") : [];
    let initialSelectedCriteria = criteria ? criteria : null;
    let offset = parseInt(filterParams.offset, 10) || 0;
    let currentPage = parseInt(filterParams.currentPage, 10) || 0;

    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const [isPageSelectionDropdownOpen, setIsPageSelectionDropdownOpen] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState(initialSelectedCriteria);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(initialSelectedCheckboxes);
    const [selectedChannels, setSelectedChannels] = useState(initialChannels);
    const [pageParams, setPageParams] = useState({offset, currentPage});
    const [dateRange, setDateRange] = useState({startDate: initialStartDate, endDate: initialEndDate});
    const [allChannelsSelected, setAllChannelsSelected] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isHandlesLoaded, setIsHandlesLoaded] = useState(false);
    const filterRef = useRef(null);
    const filterPageRef = useRef(null);

    const currentUser =  JSON.parse(localStorage.getItem('user'))
    const orgId = currentUser._embedded.organization.id;
    const dispatch = useDispatch()
    const maxItemsPerPage = 10;
    const { startDate, endDate } = dateRange;

    const { dataSourceHandles: allHandles } = useSelector(state => state.dataSourceHandles )
    const payload = {
        organization: orgId,
        channels: selectedChannels,
        startDate,
        endDate,
        selectedCriteria,
        max: maxItemsPerPage,
        currentPage: pageParams.currentPage,
        offset: pageParams.offset,
        pageFilter,
        selectedCheckboxes,
    }

    const filterToActionMapping = {
        [RESPONSE_TIME_PAGE_FILTER]: metricsActions.getResponseTimeDetails,
        [CSAT_PAGE_FILTER]: metricsActions.getQualityScoreMessages,
        [PRESALE_PAGE_FILTER]: metricsActions.getPreSaleMessages,
        [ANSWER_RATE_PAGE_FILTER]: metricsActions.getCustomerAnsweredMessages,
    }
    
    const handleFiltersSubmit = () => {
        dispatch(filterToActionMapping[pageFilter](payload))
        setIsFilterDropdownOpen(false)
        dispatch(metricsActions.clearConversation())
    }

    const onSelectChangeHandler = (currentSelectedOption) => {
        const initialPayload = { ...payload, pageFilter: currentSelectedOption.value, offset: 0, currentPage: 0 };

        if (currentSelectedOption.value === PRESALE) {
            let currentCheckboxes = [];
            currentCheckboxes.push(PRESALE)
            initialPayload.selectedCheckboxes = currentCheckboxes
            initialPayload.selectedCriteria = null
        } else {
            initialPayload.selectedCheckboxes = []
            if ([RESPONSE_TIME, CSAT].includes(currentSelectedOption.value)) {
                initialPayload.selectedCriteria = ALL_RESPONSES
            }
        }
        const queryString = objectToQueryString(initialPayload);

        history.push({
            pathname: location.pathname,
            search: `?${queryString}`
        });
        dispatch(metricsActions.clearConversation())
    };

    const handleFilterReset = () => {
        setSelectedChannels([])
        setSelectedCriteria(null)
        setAllChannelsSelected(false)
        setSelectedCheckboxes([])
    }

    const handleExport = () => {
        dispatch(metricsActions.exportMetricsMessages(payload))
    }

    const closeOpenFilterDropdown = (e)=>{
        if(filterRef.current && isFilterDropdownOpen && !filterRef.current.contains(e.target)){
            setIsFilterDropdownOpen(false)
        }
        if(filterPageRef.current && isPageSelectionDropdownOpen && !filterPageRef.current.contains(e.target)){
            setIsPageSelectionDropdownOpen(false)
        }
    }
    document.addEventListener('mousedown',closeOpenFilterDropdown)

    useEffect(() => {
        if (!orgId) return;
        handleFiltersSubmit()
    }, []);

    useEffect(() => {
        if (!isFirstLoad) {
            const queryString = objectToQueryString(payload);
            history.push({
                pathname: location.pathname,
                search: `?${queryString}`
            });
            return
        }
        if (!isHandlesLoaded) {
            dispatch(dataSourceHandleActions.getAll({organization: orgId}));
            setIsHandlesLoaded(true)
        }
        setIsFirstLoad(false)
    }, [startDate, endDate, pageParams])

    return (
        <Box sx={styles.root}>
            <Box sx={styles.container}>
                <MetricsDetailsComponent
                    pageFilter={pageFilter}
                    allHandles={allHandles}
                    selectedDropdownOption={filterOptions.find(op => op.value === pageFilter)}
                    isFilterDropdownOpen={isFilterDropdownOpen}
                    setIsFilterDropdownOpen={setIsFilterDropdownOpen}
                    isPageSelectionDropdownOpen={isPageSelectionDropdownOpen}
                    setIsPageSelectionDropdownOpen={setIsPageSelectionDropdownOpen}
                    selectedChannels={selectedChannels}
                    selectedCriteria={selectedCriteria}
                    setSelectedChannels={setSelectedChannels}
                    setSelectedCriteria={setSelectedCriteria}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    pageParams={pageParams}
                    setPageParams={setPageParams}
                    onFilterFormSubmit={handleFiltersSubmit}
                    onSelectChangeHandler={onSelectChangeHandler}
                    handleFilterReset={handleFilterReset}
                    handleExport={handleExport}
                    filterRef={filterRef}
                    filterPageRef={filterPageRef}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    allChannelsSelected={allChannelsSelected}
                    setAllChannelsSelected={setAllChannelsSelected}
                />
            </Box>
        </Box>      
    )
}
