import { useMemo } from 'react';
import { Box, Stack, useTheme, useMediaQuery } from '@mui/material';
import { UserStatusBadge, UserRoleBadge } from './UsersBadges';
import Avatar from 'components/v2/common/Avatar';

/**
 * @param {object} params
 * @param {object} params.user
 */
export default function UserEntry({ user }) {
	const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const fullName = useMemo(() => `${user.firstName} ${user.lastName}`, [ user ]);

	return (
		<Stack direction={'row'} spacing={1.5}>
			<Avatar alt={fullName} userName={fullName || user.username} />

			<Box sx={styles.userContent}>
				<Box sx={styles.userFirstLastName}>
					{fullName}
				</Box>
				<Box sx={styles.userName}>
					{user.username}
				</Box>
				{isMobile && (
					<Stack direction={'row'} spacing={1} sx={styles.userBadgesStackMobile}>
						<UserRoleBadge role={'Admin'} />
						<UserStatusBadge enabled={user.enabled} />
					</Stack>
				)}
			</Box>
		</Stack>
	)
}

const styles = {
	userContent: {
		width: 'calc(100% - 32px)',
	},
	userFirstLastName: {
		fontSize: { xs: '16px', md: '18px' },
		fontFamily: 'LarsseitMedium',
	},
	userName: {
		fontSize: '14px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	userBadgesStackMobile: {
		mt: 1,
	}
}