import React, { Component } from 'react'
import { walletActions } from '_actions'
import { AgentPayoutHistory } from 'components/Payments'
import { objectToQueryString, queryStringToObject } from '_helpers';
import { connect } from 'react-redux';

class AgentPayouts extends Component {
    constructor(props) {
        super(props);

        const search = this.props.location.search.substring(1);
        const filterParams = queryStringToObject(search);

        this.state = {
            filterParams: {
                max: 10,
                sortOrder: '',
                sortResult: '',
                search: '',
                ...filterParams
            },
            agent: '',
            amount: '',
            currentBalance: 0,
            showModal: false,
        };
    }

    /**
     * Handles the updating state on params change and refresh page data
     * @param event
     */
    handleUpdateForm = (event) => {
        const { target : { value }} = event;
        this.setState({
            amount: value
        });
    }

    /**
     * Handles the updating of the filterParams in state and refresh page
     * @param event
     */
    handleUpdateFilter = (event) => {
        const { target : { value, name }} = event;
        this.setState((prevState) => ({
            filterParams: {
                ...prevState.filterParams,
                offset: 0,
                [name]: value
            }
        }), function() {
            if (name === 'sortResult' || name === 'sortOrder') {
                const { filterParams } = this.state
                this.getAgentPayouts(filterParams)
            }
        });
    };

    /**
     * Handles submitting filter by search
     */
    handleBtnSubmit = () => {
        this.setState((prevState) => ({
            filterParams: {
                ...prevState.filterParams,
                offset: 0,
            }
        }), function() {
            const { filterParams } = this.state
            this.getAgentPayouts(filterParams)
        });
    };

    /**
     * Handles the change of url to retrieve payouts based on query params for filtering
     * @param filterParams The parameters for filtering the schedules to be retrieved
     */
    getAgentPayouts = (filterParams) => {
        const queryString = objectToQueryString(filterParams);
        const { history } = this.props;
        history.push({
            pathname: '/agents/payouts',
            search: `?${queryString}`
        });
    }

    /**
     * Handles the submission of the form by sending the agent and amount to be paid out
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();
        const { amount, agent } = this.state;
        const { dispatch } = this.props;
        dispatch(walletActions.payoutToAgent(agent, amount))
    }

    /**
     * Handles change of page for Admin Payout table
     * @param page
     * @param params
     */
    handlePageSelect = (page, params) => {
        this.setState((prevState) => {
            return {
                filterParams: {
                    ...prevState.filterParams,
                    offset: page.selected * prevState.filterParams.max,
                }
            }
        }, function() {
            const { filterParams } = this.state
            this.getAgentPayouts(filterParams)
        })
    }

    handleShowModal = (agent) => {
        this.setState({
            showModal: true,
            currentBalance: agent.balance,
            agent: agent.agentId,
        })
    }

    componentDidMount() {
        const { dispatch } = this.props
        const { filterParams } = this.state
        dispatch(walletActions.getAgentWallets(filterParams));
    }

    componentDidUpdate() {
        const { handleModal } = this.props
        const { showModal } = this.state
        if (handleModal && showModal) {
            this.setState({showModal: false},
    function() {
                const { dispatch } = this.props
                dispatch(walletActions.handleModal())
            })
        }
    }

    render() {
        const { filterParams: { search, sortResult, sortOrder }, agent, amount, showModal, viewPayout, currentBalance } = this.state;
        const { agents, loading } = this.props;
        const input = {onChange: this.handleUpdateFilter};
        return (
            <div className="container-pane" style={{display:"", padding:"0 38px"}}>
                <div className="container">
                    <div className="shadow-border margin-bottom-50" style={{marginLeft: "38px", padding: "20px 33px 33px 33px"}}>
                        <div>
                            <AgentPayoutHistory
                                closeModal={() => this.setState({ showModal: false})}
                                handlePageSelect={this.handlePageSelect}
                                handleBtnSubmit={this.handleBtnSubmit}
                                openModal={this.handleShowModal}
                                handleSubmit={this.handleSubmit}
                                onChange={this.handleUpdateForm}
                                currentBalance={currentBalance}
                                viewPayout={viewPayout}
                                sortResult={sortResult}
                                sortOrder={sortOrder}
                                showModal={showModal}
                                loading={loading}
                                search={search}
                                amount={amount}
                                agents={agents}
                                agent={agent}
                                input={input}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { wallet: { loading, agents, handleModal }} = state;

    return {
        agents: agents.wallets, loading, handleModal
    };
}

const AgentPayoutPage = connect(mapStateToProps)(AgentPayouts);
export { AgentPayoutPage as AgentPayouts };
