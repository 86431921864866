import importedStyles from "../MetricsDashboard/Utilities/styles";

export const styles = {
    root: {
        // Generic styling
        ...importedStyles.root,

        // Page related
        backgroundColor: '#E5E5E5',
        pt: 16,
        pb: 8,
        width: '100%',
        minHeight: '100vh',
    },
    container: {
        mx: 'auto',
        mt: '-50px',
        maxWidth: '100%',
        height: 'auto',
        position: 'relative',
        background: '#E5E5E5',
        borderBottom: '1px solid rgba(137, 159, 160, 0.25)',
    },

    hero: {
        width: '1344px',
        height: 'auto',
        margin: '0 auto',
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        background: '#E5E5E5',
        fontFamily: 'LarsseitRegular !important',
        fontSize: '14px !important',
        fontWeight: '500 !important',
        position: 'relative',
    },
    heading: {
        width: '311px',
        height: '29px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px',
        color: '#0C2728',
        justifyContent: 'flex-start',
        fontFamily: 'LarsseitRegular',
    },
    backLink:{
        color: '#0C2728',
        position: 'relative'
    },
    arrowBack: {
        color: ' #0C2728',
        marginRight:'8px',
        borderRadius: '0px',
        fontSize: '14px',
        position: 'absolute',
        left: '0',
        top: '3px'
    },
    footerLogin: {
        width: '100%',
        height: '60px',
        textAlign: 'left',
        fontSize: '13px',
        fontFamily: 'Roboto',
        fontWeight: '400',
        position: 'absolute',
        display: 'block',
        padding: '23px 48px',
    },
    tuneIcon: {
        width: '18px',
        height: '18px',
        flexShrink: 0,
        color: '#144042',
        pointerEvents: 'none',
        margin: '11px 10px 11px 5px',
    },
    arrowDownIcon: {
        fontSize: '26px!important',
        flexShrink: 0,
        color: '#144042',
        pointerEvents: 'none',
        borderRadius: '1px',
        position: 'absolute',
        right: '8px'
    }
}
