import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {alertActions, messageActions, organizationActions} from "../../_actions";
import {reduxForm} from "redux-form";
import {queryStringToObject} from "../../_helpers";
import {Button, Col, Form, Row} from "react-bootstrap";
import SummaryItem from "../SummaryPage/SummaryItem";
import DatetimeRangePicker from "react-bootstrap-daterangepicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SearchableSelect} from "../common/formFields/SearchableSelect";

class MessagesRequiringAction extends Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search.substring(1);
    const filterParams = queryStringToObject(search);

    filterParams.startDate = filterParams.startDate ?
      moment(filterParams.startDate) :
      moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
    filterParams.endDate = filterParams.endDate ?
      moment(filterParams.endDate) :
      moment().add(1, 'd').format('YYYY-MM-DD HH:mm:ss');


    this.state = {
      filterParams: {
        organization: filterParams.organization ? filterParams.organization : '',
        ...filterParams
      },
    };
  }

  componentDidMount() {
    const {dispatch} = this.props;
    const visibility = ['PUBLIC', 'FEATURED', 'PRIVATE'];
    dispatch(organizationActions.getActiveOrganizations({visibility}));
  }

  handleOrganizationUpdateForm = (data) => {
    this.setState((prevState) => {
      return {
        filterParams: {
          ...prevState.filterParams,
          organization: data
        },
      }
    });
  }

  handleDatePickerUpdate = (event, data) => {
    this.setState((prevState) => {
      return {
        filterParams: {
          ...prevState.filterParams, ...{
            startDate: data.startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDate: data.endDate.format('YYYY-MM-DD HH:mm:ss'),
          }
        }
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { organization, startDate, endDate } = this.state.filterParams;
    const { dispatch } = this.props;
    if (!organization) {
      dispatch(alertActions.error("Organization filed is required"));
      return;
    }
    dispatch(messageActions.getMessagesRequiringAction({...{organization: organization.value}, startDate, endDate }));
  }

  renderMessageRequiringActionSummary = (messagesRequiringActionSummary) => {
    let {trendsMessageCount, teamsMessageCount} = messagesRequiringActionSummary;
    trendsMessageCount = trendsMessageCount != null ? trendsMessageCount : '-';
    teamsMessageCount = teamsMessageCount!= null ? teamsMessageCount : '-';

    return (
      <div className="shadow-border margin-right-500"
           style={{marginLeft: '50px', padding: '20px 33px 33px 33px'}}>
        <Row className="text-center" style={{margin: '2%'}}>
          <Col md={4} style={{borderRight: '0.5px solid lightGrey'}}>
            <SummaryItem value={trendsMessageCount} label="New trends message count"/>
          </Col>
          <Col md={4}>
            <SummaryItem value={teamsMessageCount} label="New teams message count"/>
          </Col>
        </Row>
      </div>
    );
  }

  mapLabelAndValueToOrg = (item) => {
    item.value = item.id;
    item.label = item.name;
    return item;
  }

  render() {
    const { organization, startDate, endDate } = this.state.filterParams;
    const { organizations, messagesRequiringActionSummary, loading } = this.props;
    const input = {
      onChange: this.handleOrganizationUpdateForm, onBlur: () => {
      }
    };
    let setStartDate = moment(startDate).format('M/D/YYYY');
    const setEndDate = moment(endDate).format('M/D/YYYY');

    return (
      <Fragment>
        <div className="container-pane" style={{display: '', padding: '0 38px'}}>
          <div className="side-bar-pane shadow-border">
            <Form onSubmit={this.handleSubmit}>
              <span className="font-14 color-grey-dark">Organization</span>
              <div style={{marginTop: "3px", display: "block"}}>
                <SearchableSelect
                  name="organization"
                  type="text"
                  placeHolder="Select Organization"
                  input={{...input, value: organization}}
                  options={organizations ? organizations.map(this.mapLabelAndValueToOrg) : []} />
              </div>
              <div>
                <span className="font-14 color-grey-dark">Date</span>
                <span className="calendar-today tms-inbox-calendar" style={{ display: 'block' }}>
                                <DatetimeRangePicker
                                  startDate={setStartDate}
                                  endDate={setEndDate}
                                  onEvent={this.handleDatePickerUpdate}>
                                    <input className="input-popup color-grey-dark font-14 tms-ellipsis"
                                           type="text"
                                           name="date-picker"
                                           placeholder={`${setStartDate} - ${setEndDate}`}
                                    />
                                </DatetimeRangePicker>
                            </span>
              </div>
              <div style={{ marginTop: '20px' }}>
                <Button className="btn btn-success btn-block font-15" type="submit" disabled={loading}>
                  Apply
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="container-right header-pane-without-border-lines">
          { loading ?
            <div className="shadow-border margin-right-500"
                 style={{display: 'flex', justifyContent: 'center', marginLeft: '50px', padding: '20px 33px 33px 33px'}}>
              <CircularProgress size={40} style={{color: '#008751'}}/>
            </div>
            : this.renderMessageRequiringActionSummary(messagesRequiringActionSummary) }
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const {organizations: { activeOrganizations }, messages: { messagesRequiringActionSummary, loading }} = state;
  return {
    organizations: activeOrganizations.organizations,
    loading,
    messagesRequiringActionSummary,
  };
}


MessagesRequiringAction = connect(mapStateToProps)(MessagesRequiringAction);
const messagesRequiringAction = reduxForm({
  form: 'MessagesRequiringAction'
})(MessagesRequiringAction);
export {messagesRequiringAction as MessagesRequiringAction};