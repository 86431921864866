import {portalConstants} from "_constants";

/**
 * @author Chris Okebata
 */

const INITIAL_STATE = {
  organizationPortalData: [],
  allPortalData: [],
  allIntegratedPortalData: [],
  savedPortal: {},
  loading: true,
  error: '',
};

export function portalReducer(state = INITIAL_STATE, action) {

  switch (action.type) {

    case portalConstants.GET_ALL_PORTALS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case portalConstants.GET_ALL_PORTALS_SUCCESS:
      return {
        ...state,
        allPortalData: action.payload,
        loading: false
      };
    case portalConstants.GET_ALL_PORTALS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };


    case portalConstants.GET_PORTALS_FOR_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case portalConstants.GET_PORTALS_FOR_MESSAGE_SUCCESS:
      return {
        ...state,
        allPortalData: action.payload,
        loading: false
      };
    case portalConstants.GET_PORTALS_FOR_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };



    case portalConstants.GET_ORG_PORTAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case portalConstants.GET_ORG_PORTAL_SUCCESS:
      return {
        ...state,
        organizationPortalData: action.payload,
        loading: false
      };
    case portalConstants.GET_ORG_PORTAL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };


    case portalConstants.GET_ALL_INTEGRATED_PORTALS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case portalConstants.GET_ALL_INTEGRATED_PORTALS_SUCCESS:
      return {
        ...state,
        allIntegratedPortalData: action.payload,
        loading: false
      };
    case portalConstants.GET_ALL_INTEGRATED_PORTALS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };


    case portalConstants.POST_PORTAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case portalConstants.POST_PORTAL_SUCCESS:
      return {
        ...state,
        savedPortal: action.payload,
        loading: false
      };
    case portalConstants.POST_PORTAL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };


    case portalConstants.DELETE_PORTAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case portalConstants.DELETE_PORTAL_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case portalConstants.DELETE_PORTAL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state
  }
}