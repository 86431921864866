import React, {useState} from 'react';
import {Box} from "@mui/system";
import {Grid} from "@mui/material";
import _ from 'lodash';
import styles from "../../voteMainStyles";
import useGetNextMessage from "../../Utilities/hooks/useGetNextMessage";
import useGetConversation from "../../../../hooks/Message/Conversation/useGetConversation";
import Response from "./Response/Response";
import SearchContainer from "./Search/SearchContainer";
import SearchResultContainer from "./Search/SearchResultContainer";
import useGetOrgDataSourceHandles from "../../Utilities/hooks/useGetOrgDataSourceHandles";
import SearchedMessageConversation from "./Search/SearchedMessageConversation";
import voteViews from "../../Utilities/voteViews";
import useResetState from "../../Utilities/hooks/useResetState";
import ConversationContainer from "./Conversation/ConversationContainer";

export default function VoteBodySection({ isScrollComplete, editedResponseObj, editedSubjectObj }) {
    useResetState();
    const { message, conversation, loading } = useGetConversation();
    const { nextMessage, loadingNextMessage } = useGetNextMessage();
    useGetOrgDataSourceHandles();
    const withDm = _.get(message, 'canReplyPrivately');

    const [view, setView] = useState(voteViews.response);
    const toggleView = (view) => {
        setView(view);
    }

    return (
        <Grid container sx={styles.rootPanel}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <ConversationContainer
                    conversation={conversation}
                    message={message}
                    loading={loading}
                    isScrollComplete={isScrollComplete}
                    withDm={withDm}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                {
                    view === voteViews.response &&
                    <Box sx={styles.responseContainer}>
                        <Response
                            message={nextMessage}
                            loadingMessage={loadingNextMessage}
                            isScrollComplete={isScrollComplete}
                            editedResponseObj={editedResponseObj}
                            editedSubjectObj={editedSubjectObj}
                        />
                    </Box>
                }
                {
                    view === voteViews.searchResult &&
                    <Box sx={styles.searchResultContainer}>
                        <SearchResultContainer
                            handleBackToResponses={toggleView}
                            handleSearchedConversationViewToggle={toggleView}
                        />
                    </Box>
                }
                {
                    view === voteViews.searchedMessageConversation &&
                    <Box sx={styles.searchedMessageConversationContainer}>
                        <SearchedMessageConversation
                            handleBackToResult={toggleView}
                        />
                    </Box>
                }

                <Box sx={styles.searchContainer}>
                    <SearchContainer
                        handleSearchViewToggle={toggleView}
                        isScrollComplete={isScrollComplete}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}