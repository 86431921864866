import React from "react";
import { HtmlTooltip } from 'components/common';
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Box} from "@mui/material";
import processPlaceholder from "../../../common/Message/Utilities/MessageUtils";

export default function ParsedText({text, messageId, messageType}){
    let parsedMessage = processPlaceholder(text);

    return(
        <HtmlTooltip
            title={
                <div style={{minWidth: '250px', maxWidth: '700px', padding: '10px'}}>
                    <Typography style={{width: '100%', fontSize: '15px', color: '#2C302E'}}>
                        {parsedMessage}
                    </Typography>
                </div>
            }
            placement={'bottom'}
            style={{opacity: 1}}
            enterDelay={700}
        >
            <Link to={`/inbox/${messageId}?type=${messageType}`} className="tms-no-underline">
                <Box sx={{color: "#0C2728", fontSize: "14px"}} >
                    {parsedMessage}
                </Box>
            </Link>
        </HtmlTooltip>
    )
}