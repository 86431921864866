import logoHelpScout from 'components/images/logos/logo_helpscout.svg'
import useHelpScoutSetup from './useHelpScoutSetup'
import ChannelCard from '../../ChannelCard'

export default function HelpScoutEntry() {
	const { initiateHelpScoutSetup } = useHelpScoutSetup({ callbackPath: '/helpscout/accounts' })
	return (
		<ChannelCard
			icon={logoHelpScout}
			label={'HelpScout'}
			description={'Clear your email ticket backlog'}
			onClick={initiateHelpScoutSetup}
		/>
	)
}