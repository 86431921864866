export class CompanyTemplateBuilder {
    constructor() {
        this.template = {};
    }

    setId(id){
        this.template.id = id;
        return this;
    }

    setName(name){
        this.template.name = name;
        return this;
    }

    setDescription(description){
        this.template.description = description;
        return this;
    }

    setActions(actions){
        this.template.actions = actions;
        return this;
    }

    build(){
        return this.template;
    }
}
