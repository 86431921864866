import React, {Fragment} from "react";
import Button from "@mui/material/Button";
import {Box} from "@mui/system";
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import CircularProgress from '@mui/material/CircularProgress';


function ActionButton({ label, action, disabled, buttonBordered }) {
  return(
    <Box sx={{height: '50px'}}>
      <Button sx={buttonBordered ? styles.buttonBordered : styles.buttonColored}
              type="submit"
              onClick={action}
              variant="contained"
              disableElevation
              disabled={disabled}>
        {label}
      </Button>
    </Box>
  )
}

function AnchorButton({ label, link, buttonBordered }) {
  return(
    <Box sx={{height: '50px'}}>
      <Box sx={{position: 'relative', top: '10px'}}>
        <a href={link} style={buttonBordered ? styles.buttonBordered : styles.buttonColored}
           className={'add-more-channel-btn-adj'}>
          {label}
        </a>
      </Box>
    </Box>
  )
}

function LinkButton({ label, to, disabled, buttonBordered }) {
  return(
    <Box sx={{height: '50px'}}>
      <Button sx={buttonBordered ? styles.buttonBordered : styles.buttonColored}
              component={Link}
              to={to}
              variant="contained"
              disableElevation
              disabled={disabled}
      >
        {label}
      </Button>
    </Box>
  )
}

function SignupButton({label, action, disabled, isLoading}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return(
    <Box width={{xs: '100%', sm: '100%', md: '160px', lg: '160px', }}>
      <Button sx={styles.signupButton}
      type="submit"
      onClick={action}
      disabled={disabled}
      variant="contained"
      disableElevation
      endIcon={ isLoading ?
        <CircularProgress size={25} sx={styles.progress}/> :
        ( isMobile ? <ArrowForwardIcon/> : <Fragment/>) }>
        {label}
      </Button>
    </Box>
  )
}

export const buttonContained = {
  ActionButton,
  LinkButton,
  SignupButton,
  AnchorButton
};

const styles = {
  signupButton: {
    width: '100%',
    fontSize: '18px',
    padding: '10px 0',
    color: '#fff',
    fontFamily: 'LarsseitBold',
    textTransform: 'capitalize',
    borderRadius: '50px',
    backgroundColor: '#368C86',
    ':hover': {
      backgroundColor: '#34756f',
      color: 'white',
    },
    ':disabled': {
      color: '#fff',
      backgroundColor: '#368C8680'
    }
  },
  buttonBordered: {
    width: '100%',
    fontSize: '18px',
    padding: '10px 30px',
    color: '#368C86',
    fontFamily: 'LarsseitMedium',
    textTransform: 'none',
    borderRadius: '50px',
    backgroundColor: '#ffffff',
    border: '1px solid #368C86',
    ':hover': {
      backgroundColor: '#ffffff',
      color: '#368C86',
    },
    ':disabled': {
      color: '#368C8680',
      backgroundColor: '#f5f5f5',
      border: '1px solid #368C8680'
    },
    ':focus': {
      outline: '0'
    }
  },
  buttonColored: {
    width: '100%',
    fontSize: '18px',
    padding: '10px 30px',
    color: '#fff',
    fontFamily: 'LarsseitMedium',
    textTransform: 'none',
    borderRadius: '50px',
    backgroundColor: '#368C86',
    ':hover': {
      backgroundColor: '#34756f',
      color: 'white',
    },
    ':disabled': {
      color: '#fff',
      backgroundColor: '#368C8680'
    },
    ':focus': {
      outline: '0'
    }
  },
  progress: {
    color: '#ffffff',
    marginLeft: '10px'
  }
};