export const trustpilotConstants = {
    TRUSTPILOT_SEND_TO_TRENDS_REQUEST: 'TRUSTPILOT_SEND_TO_TRENDS_REQUEST',
    TRUSTPILOT_SEND_TO_TRENDS_SUCCESS: 'TRUSTPILOT_SEND_TO_TRENDS_SUCCESS',
    TRUSTPILOT_SEND_TO_TRENDS_FAILURE: 'TRUSTPILOT_SEND_TO_TRENDS_FAILURE',

    REFRESH_TRUSTPILOT_TOKEN_REQUEST: 'REFRESH_TRUSTPILOT_TOKEN_REQUEST',
    REFRESH_TRUSTPILOT_TOKEN_SUCCESS: 'REFRESH_TRUSTPILOT_TOKEN_SUCCESS',
    REFRESH_TRUSTPILOT_TOKEN_FAILURE: 'REFRESH_TRUSTPILOT_TOKEN_FAILURE',

    REFRESH_ALL_TRUSTPILOT_TOKEN_REQUEST: 'REFRESH_ALL_TRUSTPILOT_TOKEN_REQUEST',
    REFRESH_ALL_TRUSTPILOT_TOKEN_SUCCESS: 'REFRESH_ALL_TRUSTPILOT_TOKEN_SUCCESS',
    REFRESH_ALL_TRUSTPILOT_TOKEN_FAILURE: 'REFRESH_ALL_TRUSTPILOT_TOKEN_FAILURE',

    SAVE_TRUSTPILOT_CREDENTIALS_REQUEST: 'SAVE_TRUSTPILOT_CREDENTIALS_REQUEST',
    SAVE_TRUSTPILOT_CREDENTIALS_SUCCESS: 'SAVE_TRUSTPILOT_CREDENTIALS_SUCCESS',
    SAVE_TRUSTPILOT_CREDENTIALS_FAILURE: 'SAVE_TRUSTPILOT_CREDENTIALS_FAILURE',
}