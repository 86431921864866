import React from "react";
import {Box} from "@mui/system";
import _ from "lodash";
import styles from "./templateStyles";
import {useDispatch} from "react-redux";
import {proactiveActions} from "_actions";
import {parseHtmlWithImage} from "_helpers";
import {buildEditSuggestedResponsePayload, buildUpdateSuggestedResponsePayload} from "./CampaignUtils";
import useSuggestedResponse from "./hooks/useSuggestedResponse";
import SuggestedResponseEditor from "./SuggestedResponseEditor";
import SuggestedResponseActions from "./SuggestedResponseActions";
import {proactiveEmailChannels} from "_constants";

export default function SuggestedResponseButton ({
    item,
    index,
    responses,
    selectedResponses,
    handleSelectedResponses,
    setIsEdited,
    handleOpenEditResponse,
    handleCloseEditResponse,
    isEditing,
    selectedResponseId,
    channel
}) {
    const id = _.get(item, 'id');
    const dispatch = useDispatch();
    let {loadingUpdateSuggestedResponse, updatingResponseId} = useSuggestedResponse();
    let text = _.get(item, 'text');
    let openEditor = isEditing && selectedResponseId === id;
    let editedSuggestedResponse = '';
    const isRejected = _.get(item, 'isRejected');
    const isEmail = proactiveEmailChannels.includes(channel);
    const updateResponse = (payload) => {
        dispatch(proactiveActions.updateResponse(id, payload));
        const selectedItem = _.find([...selectedResponses], item) || item;
        Object.assign(selectedItem, {isRejected: payload.isRejected});
        selectedResponses.add(selectedItem);
        handleSelectedResponses(selectedResponses);
        setIsEdited(true);
    }

    const editSuggestedResponseText = (payload) => {
        dispatch(proactiveActions.updateResponse(id, payload));
    }

    const addResponse = () => {
        const payload = buildUpdateSuggestedResponsePayload(false)
        updateResponse(payload);
    }

    const removeResponse = () => {
        const payload = buildUpdateSuggestedResponsePayload(true);
        updateResponse(payload);
    }

    const handleResponse = (value) => {
        if(isRejected) {
            addResponse(value);
        }
        else removeResponse(value);
    }

    const handleOpenEditor = (responseId) => {
        handleOpenEditResponse(responseId);
    }

    const handleCloseEditor = () => {
        handleCloseEditResponse();
    }

    const handleEditedSuggestedResponse = (suggestedResponseId, editorText) => {
        editedSuggestedResponse = editorText;
    }

    const handleEditSuggestedResponse = () => {
        const payload = buildEditSuggestedResponsePayload(editedSuggestedResponse);
        editSuggestedResponseText(payload);
        handleCloseEditResponse();
    }

    const Content = () => {
        if(openEditor) {
            return (
                <SuggestedResponseEditor
                    text={text}
                    suggestedResponseId={id}
                    handleEditedSuggestedResponse={handleEditedSuggestedResponse}
                    isEmail={isEmail}
                />
            );
        }
        return <Box>{ parseHtmlWithImage(text) }</Box>;
    }

    return (
        <Box component={'li'} sx={{...styles.replyItem, ... openEditor && styles.editReplyItem}}>
            <Box sx={styles.replyItemTitle}>
                { `Reply ${index + 1} of ${responses.length}` }
            </Box>
            <Box sx={styles.replyItemBody}>
                <Content />
            </Box>
            <Box sx={styles.suggestedResponseActionsContainer}>
                <SuggestedResponseActions
                    suggestedResponseId={id}
                    openEditor={openEditor}
                    handleOpenEditor={handleOpenEditor}
                    handleCloseEditor={handleCloseEditor}
                    handleResponse={handleResponse}
                    isRejected={isRejected}
                    handleEditSuggestedResponse={handleEditSuggestedResponse}
                    loadingUpdateSuggestedResponse={loadingUpdateSuggestedResponse}
                    updatingResponseId={updatingResponseId}
                />
            </Box>
        </Box>
    )
}