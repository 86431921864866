import { attributesConstants } from '_constants';

const INITIAL_STATE = {
    attributes: {},
    loadingAttributes: false
};

export function attributesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case attributesConstants.GET_ATTRIBUTES_REQUEST:
            return {...state, loadingAttributes: true};
        case attributesConstants.GET_ATTRIBUTES_SUCCESS:
            return {...state, attributes: action.attributes, loadingAttributes: false};
        case attributesConstants.GET_ATTRIBUTES_FAILURE:
            return {...state, error: action.error, loadingAttributes: false};
        default:
            return state
    }
}