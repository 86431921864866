import {authHeader, handleHttpResponse, objectToQueryString} from '_helpers';
import {SERVER_URL} from 'config';

export const dataSourceHandleService = {
    getAll,
    getDataSourceHandle,
    deactivateAll,
    update
};

function getAll(params = {}) {

    return fetch(`${SERVER_URL}/api/handles?${objectToQueryString(params)}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}

function deactivateAll() {

    return fetch(`${SERVER_URL}/api/handles/deactivateAll`,
        {
            method: 'PUT',
            headers: authHeader()
        })
        .then(handleHttpResponse)
}


function getDataSourceHandle(id) {

    return fetch(`${SERVER_URL}/api/handles/${id}`,
        {
            method: 'GET',
            headers: authHeader()
        })
        .then(handleHttpResponse);
}

function update(data, handleId) {

    return fetch(`${SERVER_URL}/api/handles/${handleId}/update`,
        {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(data)
        })
        .then(handleHttpResponse);
}
