import React from "react"
import {MetricsDetailHeaderLabel} from "./MetricsDetailHeaderLabel";
import {MetricsDetailHeaderFormFields} from "./MetricsDetailHeaderFormFields";
import {Stack} from "@mui/material";
import {styles} from "../MetricsDetailsStyles";

export const MetricsDetailHeader = ({
                                 pageFilter,
                                 allHandles,
                                 isFilterDropdownOpen,
                                 setIsFilterDropdownOpen,
                                 isPageSelectionDropdownOpen,
                                 setIsPageSelectionDropdownOpen,
                                 selectedDropdownOption,
                                 selectedCriteria,
                                 setSelectedCriteria,
                                 selectedChannels,
                                 setSelectedChannels,
                                 dateRange,
                                 setDateRange,
                                 onPageFilterDropdownChange,
                                 onFilterFormSubmit,
                                 onSelectChangeHandler,
                                 handleFilterReset,
                                 handleExport,
                                 filterRef,
                                 filterPageRef,
                                 selectedCheckboxes,
                                 setSelectedCheckboxes,
                                 allChannelsSelected,
                                 setAllChannelsSelected,
 }) => {

    return (
        <>
            <Stack direction={'column'} sx={styles.hero} spacing={2} style={{paddingTop: '55px'}}>
                <MetricsDetailHeaderLabel />
                <MetricsDetailHeaderFormFields
                    pageFilter={pageFilter}
                    allHandles={allHandles}
                    isFilterDropdownOpen={isFilterDropdownOpen}
                    setIsFilterDropdownOpen={setIsFilterDropdownOpen}
                    isPageSelectionDropdownOpen={isPageSelectionDropdownOpen}
                    setIsPageSelectionDropdownOpen={setIsPageSelectionDropdownOpen}
                    selectedDropdownOption={selectedDropdownOption}
                    selectedChannels={selectedChannels}
                    selectedCriteria={selectedCriteria}
                    setSelectedCriteria={setSelectedCriteria}
                    setSelectedChannels={setSelectedChannels}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    onPageFilterDropdownChange={onPageFilterDropdownChange}
                    onFilterFormSubmit={onFilterFormSubmit}
                    onSelectChangeHandler={onSelectChangeHandler}
                    handleFilterReset={handleFilterReset}
                    handleExport={handleExport}
                    filterRef={filterRef}
                    filterPageRef={filterPageRef}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    allChannelsSelected={allChannelsSelected}
                    setAllChannelsSelected={setAllChannelsSelected}
                />
            </Stack>
        </>
    )
}