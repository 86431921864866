import React from "react";
import {Box} from "@mui/material";
import BasicModal from "../../../Onboarding/Utilities/BasicModal";
import ActionButton from "../../../../common/ActionButton";
import {Field, reduxForm} from "redux-form";
import BasicFormTextField from "../../../../common/BasicFormTextField";
import {useDispatch} from "react-redux";
import {organizationSettingsActions} from "../../../../../../_actions";

function RemoveCompanyConfirmationModal(props) {
    const {openConfirmationModal, closeConfirmationModal, handleSubmit, organizationName, organizationId} = props
    const [organizationNameError, setOrganizationNameError] = React.useState(empty())
    const [submitDisabled, setSubmitDisabled] = React.useState(false)
    const dispatch = useDispatch();

    const validateOrganizationName = (value) => {
        if (value != organizationName) {
            setOrganizationNameError(errorObject("Please provide correct organization name"))
            return false
        } else {
            setOrganizationNameError(empty())
            return true
        }
    }

    const buildPayload = (orgName) => {
        return {
            organizationId: organizationId,
            organizationName: orgName
        }
    }

    const handleSave = (data) => {
        if (!validateOrganizationName(data.organizationName)) {
            return
        } else {
            setSubmitDisabled(true)
            dispatch(organizationSettingsActions.initiateRemovalProcess(buildPayload(data.organizationName)))
                .then(() => {
                        closeConfirmationModal()
                        setSubmitDisabled(false)
                        dispatch(organizationSettingsActions.fetchOrganizationRemovalProcesses(organizationId))
                    }
                )
        }
    }

    return (
        <BasicModal
            open={openConfirmationModal}
            handleClose={closeConfirmationModal}
            title="Confirm company removal"
        >
            <form name="form" onSubmit={handleSubmit(handleSave)}>
                <Box mb={6} mt={6}>
                    <Box sx={styles.inputDesc}>To confirm deletion, enter the name of the organization in the text input
                        field.</Box>
                    <Box sx={styles.companyName}>{organizationName}</Box>
                    <Field
                        type="text"
                        name="organizationName"
                        component={BasicFormTextField}
                        meta={organizationNameError}
                    />
                </Box>
                <Box sx={styles.footer}>
                    <Box sx={styles.actionSection}>
                        <ActionButton
                            label={'Cancel'}
                            buttonBordered={true}
                            useBorderColor="#368C86"
                            action={closeConfirmationModal}
                            type={'button'}
                        />
                    </Box>

                    <Box sx={styles.actionSection}>
                        <ActionButton
                            label={'Continue'}
                            disabled={submitDisabled}
                        />
                    </Box>
                </Box>
            </form>
        </BasicModal>
    )
}

const errorObject = (message) => {
    return {
        touched: true,
        error: message
    }
}

const empty = (message) => {
    return {
        touched: false
    }
}

const styles = {
    inputDesc: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        color: '#495D5E',
        mb: '7px',
    },
    actionSection: {
        width: '120px'
    },
    companyName: {
        fontFamily: 'LarsseitMedium',
        fontSize: '14px',
        fontWeight: 500,
        fontStyle: "italic",
        color: '#495D5E',
        mb: '30px',
    },
    footer: {
        display: 'flex',
        gap: '18px',
        justifyContent: 'flex-end',
        paddingTop: '20px'
    },
};

const formConfig = {
    form: 'RemoveCompanyConfirmationForm',
};

RemoveCompanyConfirmationModal = reduxForm(formConfig)(RemoveCompanyConfirmationModal)

export default RemoveCompanyConfirmationModal