import _ from "lodash";

export default function useOrganizationURI() {
    const pathRoot = 'organizations';

    const setUri = (id) => {
        const url = new URL(window.location);
        url.pathname = _.isEmpty(id) ? `/${pathRoot}/settings` : `/${pathRoot}/${id}/settings`;
        window.history.pushState(null, '', url);
    }

    return {
        setUri,
    }
}