import {Button, ListGroup} from "react-bootstrap";
import ListGroupItem from "react-bootstrap/lib/ListGroupItem";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "@material-ui/core/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import {Field, reduxForm} from "redux-form";
import {required} from "../../_helpers";
import React from "react";
import {InputTextField} from "../common/formFields/InputTextField";

const FindAndReplaceForm = props => {
    const {handleSubmit, submitButtonText} = props;
    return (
        <ListGroup>
            <ListGroupItem>
                <Form onSubmit={handleSubmit} >
                    <FormGroup>
                        <ControlLabel style={{paddingBottom: '3px'}}>Find</ControlLabel>
                        <Field
                            name="findText"
                            component={InputTextField}
                            type="textArea"
                            placeHolder="Find"
                            className='form-control'
                            style={{padding: '20px 5px'}}
                            useWithRedux={true}
                            outputHtml={true}
                            validateForm={true}
                            validate={required}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel style={{paddingBottom: '3px', marginTop: '5px'}}>Replace</ControlLabel>
                        <Field
                            name="replacementText"
                            component={InputTextField}
                            type="textArea"
                            placeHolder="Replace"
                            className='form-control'
                            style={{padding: '20px 5px'}}
                            useWithRedux={true}
                            outputHtml={true}
                            validateForm={true}
                            validate={required}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Button type="submit" className="btn btn-success font-15"
                                style={{padding: '30px 5', marginTop: '20px', width: '30%'}}>{submitButtonText ? submitButtonText : 'Submit'}</Button>
                    </FormGroup>
                </Form>
            </ListGroupItem>
        </ListGroup>
    )
}
const connectedComponent = reduxForm({
    form: 'findAndReplaceForm'
})(FindAndReplaceForm);

export {connectedComponent as FindAndReplaceForm}
