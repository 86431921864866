import React, {Fragment, useState} from "react";
import ResponseItem from "./ResponseItem";
import {Box} from "@mui/system";
import useViewHtmlBody from "../../../Utilities/hooks/useViewHtmlBody";

export default function ResponseContent({responseOptions, isScrollComplete, editedResponseObj, editedSubjectObj}) {
    const [isEditing, setIsEditing] = useState(false);
    const [isResponseSelected, setIsResponseSelected] = useState(false);
    const [selectedResponseId, setSelectedResponseId] = useState('');
    const {loadingHtmlBody} = useViewHtmlBody();
    const count = responseOptions.length;

    const handleOpenEditResponse = (responseId) => {
        setIsEditing(true);
        setIsResponseSelected(true);
        setSelectedResponseId(responseId);
    };

    const handleCloseEditResponse = () => {
        if (!loadingHtmlBody) {
            setIsEditing(false);
            setIsResponseSelected(false);
            setSelectedResponseId('');
        }
    };

    return(
        <Box component={'ul'} sx={style.ul}>
            {
                responseOptions.map((response, index)=>{
                    return(
                        <Fragment key={index}>
                            <ResponseItem
                                response={response}
                                index={index}
                                count={count}
                                isScrollComplete={isScrollComplete}
                                selectedResponseId={selectedResponseId}
                                isEditing={isEditing}
                                isResponseSelected={isResponseSelected}
                                handleOpenEditResponse={handleOpenEditResponse}
                                handleCloseEditResponse={handleCloseEditResponse}
                                editedResponseObj={editedResponseObj}
                                editedSubjectObj={editedSubjectObj}
                            />
                        </Fragment>
                    )
                })
            }
        </Box>
    )
}

const style = {
    ul: {
        listStyle: 'none',
        m: 0,
        p: 0
    },
}