import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

export default function BasicFilterSelect({options, placeholder, onChange, value, textFieldStyle = {}, listBoxHeight = 150}) {
    const handleOnChange = (event, value) => {
        onChange(value);
    }

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.name}
            filterOptions={filterOptions}
            ListboxProps={{ style:  {maxHeight: listBoxHeight, ...styles.listbox}}}
            onChange={handleOnChange}
            value={value}
            renderInput={(params) =>
                <TextField
                    {...params}
                    sx={{...styles.textField, ...textFieldStyle}}
                    placeholder={placeholder}
                />
            }
        />
    );
}

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name,
});

const styles = {
    textField: {
        width: '100%',
        fontSize: '16px !important',
        height: '35px',
        fontFamily: 'LarsseitRegular',
        backgroundColor: 'unset !important',
        color: '#0C2728',
        [`& fieldset`]: {
            border: '1px solid #0C2728',
            borderRadius: '5px',
        },
        [`& label`]: {
            fontSize: '16px',
        },
        '& label.Mui-focused': {
            color: '#0C2728',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '1.5px',
                borderColor: '#9EA9A9',
            },
            '&:hover fieldset': {
                borderColor: '#0C2728',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#368C86',
            },
        },
        '& .MuiInputBase-input': {
            height: '8px',
        },
        'input': {
            '&::placeholder': {
                fontFamily: 'LarsseitRegular',
            }
        }
    },
    listbox: {
        fontFamily: 'LarsseitRegular',
    }
};