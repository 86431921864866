export class InternalNoteAssigneeBuilder {
    constructor() {
        this.internalNoteAssignee = {};
    }

    setId(id){
        this.internalNoteAssignee.id = id;
        return this;
    }

    setName(name){
        this.internalNoteAssignee.name = name;
        return this;
    }

    setEmail(email){
        this.internalNoteAssignee.email = email;
        return this;
    }

    build(){
        return this.internalNoteAssignee;
    }
}
