import React from 'react'
import {userRoles, platformConstants, msgSource} from "_constants";
import { Authorization } from "components/Authorization";
import { ChatdeskAdminDashboard, CompanyAdminDashboard, ReviewerDashboard } from "pages/Dashboards";
import ExpertDashboard from "../components/v2/views/ExpertDashboard/ExpertDashboard";
import { getCurrentUser } from "./auth.helpers";
import * as moment from 'moment-timezone';
import {filterObjectValue, getArrayFromObjectArray, shortenString} from "./string.helpers";
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";
import {messageActions, websocketActions} from "../_actions";


/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 05/10/2018 : 2:35 PM
 * @author Chris Okebata
 */
export const currentDashboard = () => {
    const ChatdeskAdminDash = Authorization([userRoles.CHATDESK_ADMIN_ROLE])(ChatdeskAdminDashboard);
    const BillingAdminDash = Authorization([userRoles.BILLING_ADMIN_ROLE])(ChatdeskAdminDashboard);
    const ChatdeskReviewerDash = Authorization([userRoles.CHATDESK_REVIEWER_ROLE])(ReviewerDashboard);
    const ChatdeskAgentDash = Authorization([userRoles.CHATDESK_AGENT_ROLE])(ExpertDashboard);

    if (!getCurrentUser() || !getCurrentUser().roles) {
        return
    }

    //TODO: Test for all roles that the user has, not just the fist one.
    switch (getCurrentUser().roles[0]) {
        case userRoles.CHATDESK_ADMIN_ROLE:
            return <ChatdeskAdminDash/>;
        case userRoles.BILLING_ADMIN_ROLE:
            return <BillingAdminDash/>;
        case userRoles.CHATDESK_REVIEWER_ROLE:
            return <ChatdeskReviewerDash/>;
        case userRoles.COMPANY_ADMIN_ROLE:
            return <CompanyAdminDashboard/>;
        case userRoles.CHATDESK_AGENT_ROLE:
            return <ChatdeskAgentDash/>;
        default:
            return <h1 className='text-center'>You seem to be lost!</h1>
    }
};


/**
 * Converts the score in percent to a star rating
 * @param score: a number between 0 and 1
 * @returns {number}
 */
export const calculateRating = (score) => {
    let numberOfStars = 5;
    let rating = score * numberOfStars;
    rating = Math.round(rating * 10) / 10;
    return rating;
};


/**
 * This gets the children in the props, and identifies each by a unique key
 * @param key
 * @param props
 * @returns {*}
 */
export const getComponentByKey = (key, props) => {
    if(!props.children) return '';

    return props.children.filter( (comp) => {
        return comp.key === key;
    });
};

export const formatDateTime = (date) => {
    return moment(date).format('MMM D') + " \xa0 " + moment(date).format('h:mm A');
};

export const isObjectEmpty = (object) => {
    if(!object) return true;
    return Object.keys(object).length === 0 && (object.constructor === Object || object.constructor === Array);
};

export const groupDataBy = (array, key, subKey) => {
    let result = {};

    Object.values(array).map((item) => {
          if(Object.prototype.toString.call(item[key]) === '[object Object]'){
              return result[item[key][subKey]] = (result[item[key][subKey]] || []).concat(item);
          }
          else{
              return result[item[key]] = (result[item[key]] || []).concat(item);
          }
      }
    );

    return result;
};

export const getTimeInEST = (date) => {
    return moment.tz(date, "America/New_York")
};

export const convertUtcToTimezone = (time, timezone) => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    const utcTime = moment.tz(time,'UTC');
    let timezoneTime = moment.tz(utcTime, timezone).format(format);

    return timezoneTime
};


export const addEllipsisToText = (text, maxLength) => {
    let shortenedText = shortenString(text, maxLength) + ( text.length > maxLength ? '...' : '' );
     return ReactHtmlParser(shortenedText).join(' ');
};

export const defaultDateRange = () => {
    return {
        'Today': [moment(), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Month to Date': [moment().startOf('month'), moment().endOf('month')],
        'Year to date': [moment().startOf('year'), moment()],
        'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }
};

export const removeItemFromArray = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }

    return array;
};

/**
 * @param objectArray {object}
 * @param property {string}
 * @param value {any}
 * @returns {{}[]}
 */
export function removeItemFromObjectArray(objectArray, property, value) {
    return _.remove(objectArray, obj => [value].includes(obj[property]));
}

/**
 * Check if a text contains data
 * @param text
 * @returns {boolean}
 */
export const stringContainsData = (text) => {
    return !!text
};

/**
 * Check if a link is a valid href else attach double forward slash to it
 * @param link
 * @returns String {link}
 */
export const ensureValidHref = (link) => {
    if(!link) return '#';
    if (link.substring(0, 4) !== 'http') {
        return link = '//' + link;
    } else return  link
};

/** This method sorts an array of days in the right order
 *  for example ["Friday", "Wednesday", "Monday"] becomes ["Monday", "Wednesday", "Friday"]
 * @param arrayOfDays
 * @return array
 */
export const sortArrayOfDays = (arrayOfDays) => {
    const order = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 7 };

    const sortedArray = arrayOfDays.sort(function (a, b) {
        return order[a] - order[b];
    });

    return sortedArray
};

/**
 * Get the time difference in seconds
 * @param startDate
 * @param endDate
 * @returns {number}
 */
export const timeDifferenceInSeconds = (startDate, endDate) => {
    if(!startDate || !endDate) return 0;

    let timeDiff = moment(endDate,"YYYY-MM-DD HH:mm:ss").diff(moment(startDate,"YYYY-MM-DD HH:mm:ss"));
    let duration = moment.duration(timeDiff).asSeconds();

    return duration;
};

/**
 * Converts seconds to minutes / hour
 * @param seconds
 * @returns {*}
 */
export const secondsToMinutesHour = (seconds) => {
    if(typeof seconds !== 'number') return '00:00';

    const secondsLimit = 60;
    const hoursLimit = 3600;
    const minDeci = 10;
    let secs = seconds % secondsLimit;
    let mins = (seconds / secondsLimit) % secondsLimit;
    let hrs = (seconds/hoursLimit);
    mins = mins ? mins.toString().split('.')[0] : 0;
    hrs = hrs ? hrs.toString().split('.')[0] : 0;

    if((hrs / minDeci) < 1) hrs = `0${hrs}`;
    if((mins / minDeci) < 1) mins = `0${mins}`;
    if((secs / minDeci) < 1) secs = `0${secs}`;

    return `${hrs}:${mins}:${secs}`
};

/**
 * Convert array items To Strings
 * @param array
 * @returns {*}
 */
export const convertArrayItemsToString = (array) => {
    return array.map(channel => {
        return String(channel)
    });
};

/**
 *
 * @param messages
 * @returns {*}
 */
export const processAdminMessage = (messages) => {

    if(isObjectEmpty(messages)){
        return { messages: [], params: {}, count: 0, resendForResponseGenList: [] };
    }

    if(_.get(messages, 'params.platform') === platformConstants.TEAMS){
        return {
            messages: messages.messages,
            params: messages.params,
            count: messages.count,
            resendForResponseGenList: messages.resendForResponseGenList
        };
    }else if((_.get(messages, 'params.platform') === platformConstants.TRENDS) || (_.get(messages, 'params.userAction') === 'all_messages')){

        const teamsMessages = _.get(messages, 'teamsMessages');
        const trendsMessages = _.get(messages, 'trendsMessages.messages');

        if(teamsMessages.length > 0) {
            for(const property of trendsMessages){
                const obj = filterObjectValue(teamsMessages || '', 'trendsId', property.id);

                if(!isObjectEmpty(obj)){
                    property.actioned = obj[0].actioned;
                    property.direction = obj[0].direction;
                    property.isDispatchedSuccessfully = obj[0].isDispatchedSuccessfully;
                    property.lockedAtTime = obj[0].lockedAtTime;
                    property.needsAdminAction = obj[0].needsAdminAction;
                    property.repliedById = obj[0].repliedById;
                    property.hasEmployeeSeen = obj[0].hasEmployeeSeen;
                    property.lockedByUser = obj[0].lockedByUser;
                }
            }
        }

        return {
            messages: trendsMessages,
            params: _.get(messages, 'params'),
            count: _.get(messages, 'trendsMessages.count'),
            resendForResponseGenList: _.get(messages, 'resendForResponseGenList')
        };
    }
};

export const refreshPage = () => {
    window.location.reload();
}

export const v2Editor = [
      "Teams Fashion Company", "Agron"
];

export function getEditorConfig(sourceName) {
    const msgSourceName = _.toString(sourceName).toLowerCase();
    switch (msgSourceName) {
        case msgSource.TIKTOK:
        case msgSource.TIKTOK_ADS: return {
            isCharDisplay: true,
            charLength: 150
        }
        case msgSource.TWITTER: return {
            isCharDisplay: true,
            charLength: 280
        }
        case msgSource.INSTAGRAM: return {
            isCharDisplay: true,
            charLength: 1000
        }
        default: return {
            isCharDisplay: false,
            charLength: undefined
        }
    }
}

export function processWebsocketMessage (websocketMessage, messages, count, platform) {
    if (!_.isEmpty(websocketMessage)) {
        const webSocketMsg = _.get(websocketMessage, 'message');
        const messageEventType = _.get(webSocketMsg, 'messageEventType');
        let webSocketMsgId = _.get(webSocketMsg, 'id');
        const messageIdArr = [...new Set(getArrayFromObjectArray(messages, 'id'))] || [];

        if (platform === platformConstants.TEAMS && messageEventType === 'MESSAGE_WORKED_ON') {
            webSocketMsgId = _.get(webSocketMsg, 'messageId') || _.get(webSocketMsg, 'id');
            messages = messages.map(message => {
                const msg = [webSocketMsg].find(wsMsg => {
                    const id = wsMsg.messageId || wsMsg.id
                    return id === message.id
                });
                if(!_.isEmpty(msg)) {
                    return Object.assign(msg, {id: webSocketMsgId, actionTaken: webSocketMsg.actionTaken});
                }
                return message;
            });
        } else if (platform === platformConstants.TRENDS && messageEventType === 'MESSAGE_WORKED_ON') {
            webSocketMsgId = _.get(webSocketMsg, 'messageTrendsId');
            if(messageIdArr.includes(webSocketMsgId)) {
                messages = messages.map(obj => [webSocketMsg].find(o => o.messageTrendsId === obj.id) || obj);
            }
        } else {
            messages.unshift(webSocketMsg);
            if(messages.length > 10) {
                messages.pop();
            }
            count = count + 1;
        }
    }
    return {processedMessages: messages, processedMessageCount: count}
}
