import FilterButton from "../FilterUtililties/FilterButton";
import React, {Fragment, useRef} from "react";
import ChannelsFilter from "./Test-ChannelsFilter";
import {BasicPopover} from "../../BasicPopover";
import TestChannelsFilter from "./ChannelsFilter";

export default function ChannelsFilterEntry({}) {
    const ref = useRef();

    return(
        <Fragment>
            <BasicPopover
                ref={ref}
                id={'channel-filter'}
                ButtonComponent={FilterButton}>
                <TestChannelsFilter handleClose={() => ref.current.close()}/>
            </BasicPopover>
        </Fragment>
    )
}

