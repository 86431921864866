import {oauthConstants} from "_constants";

const INITIAL_STATE = {
    data: {},
    error: '',
    loading: true,
    channel: ''
};

export const appcredentialsReducer = (state=INITIAL_STATE, action) => {

    switch (action.type) {
        case oauthConstants.FACEBOOK_CREDENTIALS_REQUEST:
            return {...state, loading: true, channel: 'facebook'};

        case oauthConstants.FACEBOOK_CREDENTIALS_SUCCESS:
            return {...state, data: action.payload, loading: false, channel: 'facebook'};

        case oauthConstants.FACEBOOK_CREDENTIALS_FAILURE:
            return {...state, error: action.error, loading: false, channel: 'facebook'};

        default:
            return state
    }
};