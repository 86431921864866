import {useDispatch} from "react-redux";
import {useEffect, useRef} from "react";
import {responseOptionActions} from "_actions";

export default function () {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            resetState();
            return () => effectRan.current = true;
        }
    }, []);

    const resetState = () => {
        dispatch(responseOptionActions.clearAddedResponseOption());
    }
}