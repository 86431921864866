import React from "react";
import { reduxForm } from "redux-form";
import {ChannelsCheckboxes, CriteriaResponseButtons} from "../Fields";
import {csatCriteriaOptions} from "../MetricsDetailUtils";
import Stack from "@mui/material/Stack";

const CsatFilterDropdownForm = ({
                                    handles,
                                    selectedCriteria,
                                    setSelectedCriteria,
                                    setSelectedChannels,
                                    selectedChannels,
                                    allChannelsSelected,
                                    setAllChannelsSelected
}) => {

    return (
        <Stack direction={'row'} spacing={1}>
            <ChannelsCheckboxes
                handles={handles}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                allChannelsSelected={allChannelsSelected}
                setAllChannelsSelected={setAllChannelsSelected}
            />

            <div className="filter-all-criteria" style={{marginLeft: '15px'}}>
                <CriteriaResponseButtons
                    selectedCriteria={selectedCriteria}
                    setSelectedCriteria={setSelectedCriteria}
                    criteriaOptions={csatCriteriaOptions}
                />
            </div>
        </Stack>
    )
}

const csatFilterDropdownForm = reduxForm({
    form: 'CsatFilterDropdownForm'
})(CsatFilterDropdownForm);

export { csatFilterDropdownForm as CsatFilterDropdownForm }