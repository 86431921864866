import React from "react";
import { Button } from 'react-bootstrap';
import { Authorization } from 'components/Authorization';
import { userRoles as roles } from '_constants';

/**
 * @author Teyim Pila <teyim.pila@gmail.com>
 * @date 20/12/2018 : 10:28 AM
 */
const currentUser = localStorage.user;
const BillingAdminAuth = Authorization([roles.BILLING_ADMIN_ROLE]) ||
        currentUser.username === 'aniekan-teams@chatdesk.com';

const generalSettings = (props) => (
    <div>
        <Button className={props.settings.isDispatchEnabled ? 'btn-danger' : 'btn-success'}
                onClick={() => props.updateSystemSettings({
                    ...props.settings,
                    isDispatchEnabled: !props.settings.isDispatchEnabled
                })}>
            {props.settings.isDispatchEnabled ? 'Disable Dispatcher' : 'Enable Dispatcher'}
        </Button>
        <div style={{marginTop:'10px'}}>
            <Button className={props.settings.isStripeEnabled ? 'btn-danger' : 'btn-success'}
                    onClick={() => props.updateSystemSettings({
                        ...props.settings,
                        isStripeEnabled: !props.settings.isStripeEnabled
                    })}>
                {props.settings.isStripeEnabled ? 'Disable Stripe' : 'Enable Stripe'}
            </Button>
        </div>
        {BillingAdminAuth &&
            <div style={{marginTop:'10px'}}>
                <div style={{width: "30%"}}>
                    <span className="font-14 color-grey-dark">Delete All Messages Earlier Than:</span>
                    <label className="calendar-today tms-inbox-calendar" style={{display: "block"}}>
                        <input style={{width: "100%"}} type={"date"} name={"endDate"} value={props.endDate} onChange={props.handleChange} />
                    </label>
                    <Button className="btn-danger"
                            onClick={props.deleteAzurePayload}>
                        Delete Azure Payload
                    </Button>
                </div>
            </div>
        }
        <div style={{marginTop:'10px'}}>
            <hr />
            <div style={{width: "30%"}}>
                <Button className="btn-info"
                        onClick={props.jiraOauth}>
                    Perform Jira Oauth
                </Button>
            </div>
        </div>
    </div>
);


export default generalSettings



