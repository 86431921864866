import React from "react";
import {useSelector} from "react-redux";

export default function useFreshdeskSetup(){
	const freshdesk = useSelector((state) => state.freshdeskAuth);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const performAction = () => {
    handleOpen();
  };

	return {
		initiateFreshdeskSetup: performAction,
		freshdeskSetupOpen: open, 
		handleFreshdeskSetupOpen: handleOpen,
		handleFreshdeskSetupClose: handleClose
	};
}