import React, {useEffect} from 'react';

const ProactiveResponseButtons = ({
    selectedCriteria,
    setSelectedCriteria,
    criteriaOptions,
    setLiked,
    setReplied,
    setNoEngagement,
   setFollowed,
   setConverted,
    setFollowUpSent,
    setConvertedAfterFollowUpSent
}) => {
    const handleCriteriaChange = (criteriaSelected, value) => {
        if (criteriaSelected) {
            setSelectedCriteria(value);
        } else {
            setSelectedCriteria(...selectedCriteria);
        }
    }

    const clearAllFilters = () => {
        setLiked(false);
        setReplied(false);
        setFollowed(false);
        setConverted(false);
        setNoEngagement(false);
        setFollowUpSent(false);
        setConvertedAfterFollowUpSent(false);
    }

    useEffect(() => {
        // This effect runs after the state has been updated.
        if (selectedCriteria === 'liked') {
            clearAllFilters();
            setLiked(true);
        } else if (selectedCriteria === 'replied') {
            clearAllFilters();
            setReplied(true);
        } else if (selectedCriteria === 'noEngagementYet') {
            clearAllFilters();
            setNoEngagement(true);
        } else if (selectedCriteria === 'all_messages') {
            clearAllFilters();
        } else if (selectedCriteria === 'followed') {
            clearAllFilters();
            setFollowed(true);
        } else if (selectedCriteria === 'converted') {
            clearAllFilters();
            setConverted(true);
        } else if (selectedCriteria === 'followUpSent') {
            clearAllFilters();
            setFollowUpSent(true);
        } else if (selectedCriteria === 'convertedAfterFollowUpSent') {
            clearAllFilters();
            setConvertedAfterFollowUpSent(true);
        }
    }, [selectedCriteria, setLiked, setReplied, setNoEngagement, setFollowed, setConverted, setFollowUpSent, setConvertedAfterFollowUpSent]);


    return (
        <div className="criteria-filter">
            <span className="criteria-heading">Engagement</span>
            {criteriaOptions.map((option, index) =>
                <label key={index} className="criteria-radio-label" htmlFor={option.name}
                       onClick={(e) => handleCriteriaChange}>
                    {option.value}
                    <input
                        type="radio"
                        name="radio"
                        value={option.name}
                        id={option.name}
                        onChange={(e) => handleCriteriaChange(e.target.checked, option.name)}
                        checked={selectedCriteria === option.name}
                    />
                    <span className="radio-checkmark"/>
                </label>
            )}
        </div>
    )
}

export {ProactiveResponseButtons}