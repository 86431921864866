import React from "react";
import {Field, reduxForm} from 'redux-form'
import Grid from '@material-ui/core/Grid';
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {SelectField} from "components/common/formFields/SelectField";

/**
 * This form displays all fields for users to fill
 * Users have to chose:
 * the screenshot type and
 * manually input the message url
 * This was the first implementation for screenshots and may soon
 * be deprecated
 * @param typeOptions
 * @param handleSubmit
 * @param classes
 * @param handleFileChange
 * @returns {JSX.Element}
 * @constructor
 */
const ScreenshotsCreateForm = ({typeOptions, handleSubmit, classes}) => {

    return (
        <Grid>
            <Form onSubmit={handleSubmit} className="margin-top-30" horizontal>
                <FormGroup className="margin-bottom-30" controlId="formHorizontalMessageUrl">
                    <Col item={true} sm={3} className="padding-left-40 label-container">
                        <label className={classes.formLabel}>Teams link</label>
                    </Col>
                    <Col sm={9} className="padding-right-40">
                        <Field
                            name="messageUrl"
                            component="input"
                            type="url"
                            placeholder="Teams Link"
                            className="form-control"
                            style={{padding: '24px 3px'}}
                            required={true}
                        />
                    </Col>
                </FormGroup>

                <FormGroup className="margin-bottom-30" controlId="formHorizontalDescription">
                    <Col item={true} sm={3} className="padding-left-40 label-container">
                        <label className={classes.formLabel}>Description</label>
                    </Col>
                    <Col sm={9}>
                        <Field
                            name="description"
                            component="textarea"
                            type="textarea"
                            placeholder="Description"
                            className='form-control'
                            style={{padding: '20px 3px'}}
                        />
                    </Col>
                </FormGroup>

                <FormGroup className="margin-bottom-30" controlId="formHorizontalType">
                    <Col item={true} sm={3} className="padding-left-40 label-container">
                        <label className={classes.formLabel}>Type</label>
                    </Col>
                    <Col sm={9}>
                        <Field
                            name="type"
                            component={SelectField}
                            options={typeOptions}
                            className='form-control'
                            style={{padding: '20px 3px'}}
                            optionPropertyId="id"
                            optionPropertyName="name"
                            placeholder="Select Type"

                        />
                    </Col>
                </FormGroup>


                <FormGroup>
                    <Col smOffset={3} sm={10}>
                        <Button type="submit" className="btn btn-success font-15"
                                style={{padding: '30px 5'}}>Submit</Button>
                    </Col>
                </FormGroup>
            </Form>
        </Grid>

    )
};



const connectedComponent =  reduxForm({
    form: 'screenshotAddForm', // a unique identifier for this form
})(ScreenshotsCreateForm);

export {connectedComponent as PrimaryCreateForm}
