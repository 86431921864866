export const payoutsConstants = {
    GET_ALL_PAYOUTS_REQUEST: 'GET_ALL_PAYOUTS_REQUEST',
    GET_ALL_PAYOUTS_SUCCESS: 'GET_ALL_PAYOUTS_SUCCESS',
    GET_ALL_PAYOUTS_FAILURE: 'GET_ALL_PAYOUTS_FAILURE',

    GET_PAYOUT_REQUEST: 'GET_PAYOUT_REQUEST',
    GET_PAYOUT_SUCCESS: 'GET_PAYOUT_SUCCESS',
    GET_PAYOUT_FAILURE: 'GET_PAYOUT_FAILURE',

    CREATE_PAYOUT_REQUEST: 'CREATE_PAYOUT_REQUEST',
    CREATE_PAYOUT_SUCCESS: 'CREATE_PAYOUT_SUCCESS',
    CREATE_PAYOUT_FAILURE: 'CREATE_PAYOUT_FAILURE',

    UPDATE_PAYOUT_REQUEST: 'UPDATE_PAYOUT_REQUEST',
    UPDATE_PAYOUT_SUCCESS: 'UPDATE_PAYOUT_SUCCESS',
    UPDATE_PAYOUT_FAILURE: 'UPDATE_PAYOUT_FAILURE',

};
