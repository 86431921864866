import styles from "./conversationStyles";
import {Box} from "@mui/system";
import EmailInfoItem from "./EmailInfoItem";

/**
 * @param label {string}
 * @param copyText {string}
 * @param withCopyAction {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
export default function EmailInfo({label, copyText, withCopyAction}) {
    return(
        <Box sx={styles.infoItem}>
            <Box sx={{...styles.ellipsis, ...style.item}}>
                <EmailInfoItem
                    label={label}
                    copyText={copyText}
                    withCopyAction={withCopyAction}
                />
            </Box>
        </Box>
    )
}

const style = {
    item: {
        padding: '2px 0 3px 0',
        a:{
            fontSize: '14px',
            color: '#0C2728',
            fontFamily: 'LarsseitRegular',
            textDecoration: 'underline'
        }
    }
}