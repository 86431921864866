import React, { Fragment } from 'react'
import { AddOrganizationSubscription } from 'components/Payments'
import { SubscriptionHistory } from 'components/Payments'
import {connect} from "react-redux";

const SubscribeDetails = (props) => {
    const { organization, subscriptions, input, ...rest } = props
    return (
        <Fragment>
            <SubscriptionHistory
                {...rest}
                organization={organization}
            />
            <AddOrganizationSubscription
                {...rest}
                subscriptions={subscriptions}
                organization={organization}
                input={input}
            />
        </Fragment>
    )
};

function mapStateToProps(state) {
    const { subscription: { all_subscriptions }} = state
    return {
        subscriptions: all_subscriptions
    }
}

let subscribeConnect = connect(mapStateToProps)(SubscribeDetails)
export { subscribeConnect as SubscribeDetails }
