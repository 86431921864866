import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getVotingOrganization} from "_helpers";
import {dataSourceHandleActions} from "_actions";
import _ from "lodash";

export default function () {
    const dispatch = useDispatch();
    const effectRan = useRef(false);

    useEffect(() => {
        if(effectRan.current === false) {
            getAllDataSourceHandles();
            return () => effectRan.current = true;
        }
    }, []);

    const getAllDataSourceHandles = () => {
        const organization = getVotingOrganization();
        dispatch(dataSourceHandleActions.getAll({organization: _.get(organization, 'id')}));

    }

    const dataSourceHandles = useSelector(state => state.dataSourceHandles.dataSourceHandles);
    const loadingDataSourceHandles = useSelector(state => state.dataSourceHandles.loading);
    return { dataSourceHandles, loadingDataSourceHandles };
}