import {useEffect, useState} from "react";
import {CLIENT_URL} from "config";
import {useDispatch, useSelector} from "react-redux";
import {utilityService} from "_services";
import setRedirectUrl from "../../Utilities/setRedirectUrl";
import {tiktokAdsOauthActions} from "../../../../../../../_actions/tiktokads.oauth.actions";

export default function useTikTokAdsSetup({
                                         callbackPath,
                                       }){
  const dispatch = useDispatch();
  const tiktok = useSelector((state) => state.tiktokAdsOauth);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const performAction = () => {
    setShouldRedirect(true)
    const callbackUrl = `${CLIENT_URL}${callbackPath}`;
    setRedirectUrl(callbackUrl)
    dispatch(tiktokAdsOauthActions.initiateOauth());
  };

  useEffect(() => {
    if (!tiktok.loading && tiktok.authUrl.url && shouldRedirect){
      setShouldRedirect(false)
      utilityService.redirectToAuthUrl(tiktok.authUrl.url)
    }
  });

  return { initiateTikTokSetup: performAction };
}