import React from "react";
import {pageFilterToDropdownMapping} from "../MetricsDetailUtils";
import {Box, Stack} from "@mui/material";
import {styles} from "../MetricsDetailsStyles";
import {PageTitle} from "../PageTitle";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {MetricsDetailFilter} from "../MetricsDetailFilter";
import { Button } from "react-bootstrap";
import { FilterOptionsDropdown } from "../Fields";
import DateRangeForm from "../Forms/DateRangeForm";

export const MetricsDetailHeaderFormFields = ({
                                   pageFilter,
                                   allHandles,
                                   isFilterDropdownOpen,
                                   setIsFilterDropdownOpen,
                                   isPageSelectionDropdownOpen,
                                   setIsPageSelectionDropdownOpen,
                                   selectedDropdownOption,
                                   selectedCriteria,
                                   setSelectedCriteria,
                                   selectedChannels,
                                   setSelectedChannels,
                                   dateRange,
                                   setDateRange,
                                   onFilterFormSubmit,
                                   onSelectChangeHandler,
                                   handleFilterReset,
                                   handleExport,
                                   filterRef,
                                   filterPageRef,
                                   selectedCheckboxes,
                                   setSelectedCheckboxes,
                                   allChannelsSelected,
                                   setAllChannelsSelected,
 }) => {
    const filterDropdownComponent = pageFilterToDropdownMapping({
        allHandles,
        selectedCriteria,
        setSelectedCriteria: setSelectedCriteria,
        setSelectedChannels: setSelectedChannels,
        selectedChannels: selectedChannels,
        selectedCheckboxes,
        setSelectedCheckboxes,
        allChannelsSelected,
        setAllChannelsSelected,
    })[pageFilter];

    return (
        <Stack direction={{xs: 'column', md: 'row'}} sx={styles.hero} spacing={1}>
            { pageFilter && <PageTitle pageFilter={pageFilter}/> }

            <Stack
                direction={{xs: 'column', md: 'row'}}
                sx={{width: '70%', justifyContent: 'flex-end'}}
            >
                <Box style={{ position: 'relative' }} ref={filterPageRef}>
                    <button
                        className="pageSelectionFilter"
                        type="button"
                        style={{ overflow: 'hidden', textWrap: 'wrap'}}
                        onClick={() => setIsPageSelectionDropdownOpen(!isPageSelectionDropdownOpen)}>
                        {selectedDropdownOption.label}
                        <ArrowDropDownIcon sx={styles.arrowDownIcon}/>
                    </button>
                    {isPageSelectionDropdownOpen &&
                        <FilterOptionsDropdown
                            selectedDropdownOption={selectedDropdownOption}
                            onSelectChangeHandler={onSelectChangeHandler}
                        />
                    }
                </Box>

                <Box sx={{width: {xs: '100%', sm: 'auto'}, marginRight: '12px'}}>
                    <DateRangeForm
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        setDates={setDateRange}
                />
                </Box>
                <div ref={filterRef} className="position-relative">
                    <button
                        className="metricsDetailsFilter"
                        type="button"
                        onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}>
                        <TuneIcon sx={styles.tuneIcon}
                        />
                        Filters
                        <ArrowDropDownIcon sx={styles.arrowDownIcon}/>
                    </button>
                    {isFilterDropdownOpen && <MetricsDetailFilter
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        filterDropDown={filterDropdownComponent}
                        onFilterFormSubmit={onFilterFormSubmit}
                        handleFilterReset={handleFilterReset}
                    />}
                </div>
                <Box>
                    <Button
                        onClick={handleExport}
                        className="btn btn-success"
                        type="submit"
                        style={{
                            width: '120px',
                            height: '40px',
                            background: '#144042',
                            borderRadius: '60px',
                            fontSize: '14px'
                        }}>
                        Export
                    </Button>
                </Box>
            </Stack>
        </Stack>
    )
}