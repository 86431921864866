import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import ResponsePayload from 'components/ResponsePayload/ResponsePayload';
import {MessageActionsV2} from './MessageActionsV2';
import MoreVertIcon from '@material-ui/icons/MoreVert';

class ToggleMenuV2 extends Component {
    state = {
        anchorElement: null,
        hideModal: false,
    };

    /**
     * Identifies the clicked item and opens the popup menu for the three dots
     * @param event
     */
    handleClick = event => {
        this.setState({anchorElement: event.currentTarget});

        if (this.state.hideModal) {
            if (document.getElementById("long-menu")) document.getElementById("long-menu").classList.remove("position-static");
            this.setState({
                hideModal: false
            });
        }

        this.props.lockMessage();

    };

    /**
     * Responsible for closing the popup menu
     */
    handleClose = () => {
        this.setState({anchorElement: null});
    };

    hideModal = (e) => {

        if (!this.state.hideModal) {
            /**
             * The following lines of code were added to fix some issues that arise
             * likely because there are two modals on the same page
             * and one modal is a parent modal to the other.
             * **/

            /**
             * This was added because the body overflow property was still hidden
             * when the child modal is closed
             **/
            document.body.style.removeProperty("overflow");

            /**
             * A fix for making the modal window of the conversation thread and response payload scrollable.
             **/
            document.getElementById("long-menu").classList.add("position-static");
            this.setState({hideModal: !this.state.hideModal});
        } else {
            const target = e.target;

            if (target.classList.contains("close")) {
                this.handleClose();
                this.setState({hideModal: !this.state.hideModal});
            }
        }
    };

    render() {
        const {
            message, deleteMessageFromSource, escalateMessage, closeModalHandler, showModalHandle,
            skipMessage, updateMessageFromSource, blacklist, classes, platform, isMessageActioned, isBlacklistAllowed, showScreenshotModal,mainMessage,
            likeMessageFromSource, resendForResponseGenList, sendMessageForResponseGen, showJiraSubmitBug,
            onShowJiraModal, positiveFeedbackData, undoMessage,showPrivateReplyModal, actionMessage, isChangePracticePriorityAllowed, changePracticePriority, updatedIsReviewed
        } = this.props;
        const { anchorElement, hideModal } = this.state;
        const open = Boolean(anchorElement);

        let options = [
            {
                id: 1,
                component: <ResponsePayload
                    message={message}
                    hideParentModal={this.hideModal}
                    isMessageDetailsPage={true} />
            }
        ];

        const isReviewed = (msgIsReviewedInitialState, updatedIsReviewed) => {
            if (updatedIsReviewed === undefined) {
                return msgIsReviewedInitialState
            } else {
                return updatedIsReviewed
            }
        }

        return (
            <Row>
                <Col xs={12} md={12} className="toggle-button-container">
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        style={{padding: "10px"}}
                    >
                        <MoreVertIcon size="small"/>
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorElement}
                        open={open}
                        onClose={this.handleClose}
                        className="message-details-menu"
                        PaperProps={{
                            style: {
                                maxHeight: 100 * 4.5,
                                minWidth: 150,
                                border: "solid 1px #dee2e0",
                                boxShadow: "0 2px 4px 0 rgba(196, 201, 199, 0.5)",
                                display: hideModal ? "none" : ""
                            }
                        }}
                    >
                        {options.map(option => (
                            <MenuItem key={option.id} onClick={this.hideModal} >
                                {option.component}
                            </MenuItem>
                        ))},

                        {showJiraSubmitBug &&
                        <MenuItem className="update-menu-item">
                            <button type="button" className="font-14 color-green tms-no-underline btn-link"
                                    onClick={(e) => {this.hideModal(); onShowJiraModal(e)}}>
                                Submit Jira Bug
                            </button>
                        </MenuItem>
                        }

                        {isChangePracticePriorityAllowed &&
                            <MenuItem key="isChangePracticePriorityAllowed"className="update-menu-item" onClick={this.handleClose}>
                                <button type="button" className="font-14 color-green tms-no-underline btn-link"
                                        onClick={() => changePracticePriority(mainMessage.id, !isReviewed(mainMessage.isReviewed, updatedIsReviewed))}>
                                    {isReviewed(mainMessage.isReviewed, updatedIsReviewed) ? "Mark as low priority for practice" : "Mark as high priority for practice"}
                                </button>
                            </MenuItem>
                        }

                        <MessageActionsV2
                            message={message}
                            mainMessage={mainMessage}
                            updateMessageFromSource={updateMessageFromSource}
                            skipMessage={skipMessage}
                            classes={classes}
                            blacklist={blacklist}
                            platform={platform}
                            handleClose={this.handleClose}
                            isBlacklistAllowed={isBlacklistAllowed}
                            isMessageActioned={isMessageActioned}
                            escalateMessage={escalateMessage}
                            deleteMessageFromSource={deleteMessageFromSource}
                            showEditModalButton={false}
                            showScreenshotModal={showScreenshotModal}
                            closeModalHandler={closeModalHandler}
                            resendForResponseGenList={resendForResponseGenList}
                            sendMessageForResponseGen={sendMessageForResponseGen}
                            showModalHandle={showModalHandle}
                            likeMessageFromSource={likeMessageFromSource}
                            showJiraModal={onShowJiraModal}
                            positiveFeedbackData={positiveFeedbackData}
                            showUndoButton={true}
                            undoMessage={undoMessage}
                            showPrivateReplyModal={showPrivateReplyModal}
                            actionMessage={actionMessage}
                        />
                    </Menu>
                </Col>
            </Row>
        )
    }
}

export { ToggleMenuV2 }
