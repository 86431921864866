import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from 'components/common';
import {history} from "_helpers";
import {tiktokAdsOauthActions} from "../../../../../../../_actions/tiktokads.oauth.actions";


export default function TikTokAdsUrlRedirect() {
  const dispatch = useDispatch();
  const url = new URL(document.location);
  const values = new URLSearchParams(url.search);
  const tiktokAuthToken = values.get('auth_code');

  useEffect(() => {
    if (tiktokAuthToken) {
      const params = { auth_code: tiktokAuthToken};
      dispatch(tiktokAdsOauthActions.generateAccessToken(params));
    }
  }, []);

  const tiktok = useSelector((state) => state.tiktokAdsOauth);

  if (tiktok.loading) {
    return <Loading />;
  }

  history.push('/company/connectchannels');

  return(
    <Fragment/>
  )
}