import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from 'components/common/index';
import {employmentActions, messageActions} from '_actions/index';
import { Link } from 'react-router-dom';
import {
  getCurrentUser,
  history,
  setCurrentEmployment,
  filterObjectValue,
  setCurrentScheduledOrgs,
  testCompanyAccounts
} from '_helpers';
import styles from 'pages/Dashboards/AgentDashboard/styles';
import { AgentOrganization } from 'pages/Dashboards/AgentDashboard/AgentOrganization';
import _ from "lodash";
import {Grid, withStyles} from '@material-ui/core';
import PropTypes from "prop-types";
import {clientType, requestOrigin} from "_constants";

class AgentDashboard extends Component {

  componentDidMount() {

    const { id } = getCurrentUser();
    const { dispatch } = this.props;

    /**
     * When page loads, get all user's employments
     * and populate organization dropdown with associated organizations.
     */
    dispatch(employmentActions.getUserEmployments(id,
      { visibility: ['PUBLIC', 'PRIVATE', 'FEATURED'], clientType: clientType.WEB_APP }, requestOrigin.AGENT_HOME
    ));

    dispatch(employmentActions.getOrganizationScore(id));
    dispatch(messageActions.clearConversation());
  }

  /**
   * Handles the 'submission' of the 'form' used in
   * selecting organization that agent will vote for.
   * @param organizationId
   * @param scheduledOrgObj
   */
  handleSubmit = (organizationId, scheduledOrgObj) => {
    /**
     * Filter employments to get the one corresponding to the
     * organization that the agent selected to vote on and stores that in local storage.
     */
    const employment = this.props.employments.filter((employment) => {
      return employment.organization.id === organizationId
    })[0];

    setCurrentEmployment(employment);
    setCurrentScheduledOrgs(scheduledOrgObj);

    if(employment){
      history.push('/vote');
    }
  };


  render() {

    const {
      loadingEmployments, employments,
      loadingOrgWithMsg, organizationWithMessages, user, loadingScheduledOrgs
    } = this.props;

    const { rootWrapper, textContainer } = styles;

    let hiredOrgs = filterObjectValue(employments || [], 'status', 'HIRED');
    let unemployedOrgs = filterObjectValue(employments || [], 'status', 'UNEMPLOYED');

    if (loadingEmployments || loadingOrgWithMsg || loadingScheduledOrgs) {
      return <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100vh'}}><Loading/></div>
    }

    if(organizationWithMessages && organizationWithMessages.length > 0){
      for (const property of hiredOrgs) {
        property.messageCount = 0;

        for(const item of organizationWithMessages){
          if(_.get(property, 'organization.id') === _.get(item, 'organization')){
            property.messageCount = _.get(item, 'messageCount')
          }
        }
      }
    }

    /**
     * Sort the organizations an agent is hired to by message count if it exists
     **/
    hiredOrgs.sort((arg_1, arg_2) => (_.get(arg_1, 'messageCount') < _.get(arg_2, 'messageCount')) ? 1 : -1);

    const noEmploymentExists = employments && employments.length === 0;

    return (
      <div style={rootWrapper}>

        <Grid container>

          <Grid item xs={12} sm={12} md={7} lg={7} style={{backgroundColor: '#f3f3f3'}}>
            <div style={noEmploymentExists ?
              {padding: '60px 65px 65px 65px', height: 'auto'} : {padding: '35px 65px 45px 65px', height: 'auto'}}>
              <div style={ noEmploymentExists ?
                {height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'} : {}}>

                <div style={ noEmploymentExists ?
                  {display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'} :
                  {display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'}}>
                  <div className="font-28-heavy color-grey-dark" style={ noEmploymentExists ?
                    {lineHeight: '25px', marginBottom: '20px'} : {lineHeight: '25px', marginBottom: '15px'}}>
                    {`Welcome ${user.firstName}!`}
                  </div>

                  { hiredOrgs.length > 0 &&
                    <AgentOrganization
                      employmentDesc={'You’re on the team'}
                      employmentList={hiredOrgs}
                      handleSubmit={this.handleSubmit}
                      isHired={true}
                    />
                  }

                  { unemployedOrgs.length > 0 &&
                    <AgentOrganization
                      employmentDesc={'Continue practicing'}
                      employmentList={unemployedOrgs}
                      handleSubmit={this.handleSubmit}
                      isHired={false}
                    />
                  }

                  <div style={{marginTop:'15px'}}>
                    <Link to='/employers' className="btn btn-success"
                          style={{fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'normal'}}>View all companies</Link>
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} style={{backgroundColor: '#ffffff'}}>
            <div style={{padding: '60px', height: 'calc(100vh - 80px)'}}>
              <div style={{height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <div style={textContainer}>
                  {/*<div className="font-64-heavy color-grey-dark" style={{lineHeight: '55px'}}>*/}
                    {/*{totalMessagesEvaluated}*/}
                  {/*</div>*/}

                  {/*<div className="font-20-bold color-grey-dark" style={{lineHeight: '20px', marginTop:'20px'}}>*/}
                    {/*Customer messages evaluated*/}
                  {/*</div>*/}
                </div>

              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    auth: { user },
    employment: {
      userEmployments,
      organizationScores,
      organizationWithMessages,
      loading,
      loadingOrgScores,
      loadingOrgWithMsg,
    },
    schedules:{loadingScheduledOrgs}
  } = state;

  const employments = _.get(userEmployments, 'employments');
  const totalMessagesEvaluated = _.get(userEmployments, 'totalMessagesEvaluated');

  return {
    user,
    employments,
    organizationScores,
    organizationWithMessages: organizationWithMessages.organizationList,
    loadingEmployments: loading,
    loadingOrgScores,
    loadingOrgWithMsg,
    totalMessagesEvaluated,
    loadingScheduledOrgs
  };
}

AgentDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

AgentDashboard = connect(mapStateToProps)(withStyles(styles)(AgentDashboard));
export { AgentDashboard };
