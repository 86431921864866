import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {GenericModal} from "../../common";
import {messageActions} from "_actions";
import {OriginalConversationContent} from "./OriginalConversationContent";
import _ from "lodash";
import {platformConstants} from "_constants/message.constants";


/**
 * @author Chris Okebata
 */

class OriginalConversation extends Component{

  constructor(props){
    super(props);

    this.state = {
      showModal: false,
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  openModal = (message) => {
    const { dispatch } = this.props;
    dispatch(messageActions.getFullEmailConversation(_.get(message, 'id'), platformConstants.TRENDS));
    this.setState({ showModal: true });
  };


  render() {
    const { showModal } = this.state;
    const { message, emailConversationData, loadingEmailConversation } = this.props;


    return(
      <Fragment>
        <button
          className="font-14 color-green tms-no-underline btn-link"
          onClick={() => this.openModal(message)}
          type="button"
          style={{padding: 0, outline: 'none', lineHeight: '14px'}}
        >
          View original
        </button>

        <GenericModal
          headerText="Customer original conversation"
          hideFooter
          show={showModal}
          onHide={this.hideModal}
          size="lg"
          isConversationThread={true}
        >
          <OriginalConversationContent
            message={message}
            emailConversationData={emailConversationData}
            loadingEmailConversation={loadingEmailConversation}
          />
        </GenericModal>
      </Fragment>
    )
  }

}

function mapStateToProps(state) {
  const { fullEmailConversation : { emailConversationData, loadingEmailConversation } } = state;
  return {
    emailConversationData,
    loadingEmailConversation
  };
}

const connectedOriginalConversation = connect(mapStateToProps)(OriginalConversation);

export { connectedOriginalConversation as OriginalConversation };