import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import {history} from "_helpers";
import ReactGA from 'react-ga';
import {GOOGLE_ANALYTICS_TRACKING_ID} from "_constants";

function Auth0Init () {

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname);

    }, []);

  const {isLoading, loginWithRedirect, isAuthenticated} = useAuth0();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const auth0 = useSelector((state) => state.auth0);
  const {logoutLoading} = auth0;

  let slugArr = ['login', 'signup'], slug = slugArr[0];
  const url = window.location.href.trim();
  if(url.includes(slugArr[1])) slug = slugArr[1];

  /**
   * If a user is currently logged in, redirect
   */
  if (currentUser) {
    history.push('/dashboard');
  }

  if(!currentUser && !logoutLoading && !isLoading && isAuthenticated){
    history.push('/contact-support')
  }

  if(!currentUser && !isLoading && !isAuthenticated) {
    loginWithRedirect({screen_hint: `${slug}`});
  }

  return <div/>;
}

export default Auth0Init;
