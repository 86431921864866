import {useMemo} from 'react';
import {useSelector} from "react-redux";
import _ from "lodash";
import {workMode} from "_constants";
import {
    buildConversation,
    buildOriginalConversation,
    isViewOriginalSupported
} from "../../../utilities/ConversationUtils";
import {RefreshToast} from "../../../common/RefreshToast";
import {getWorkMode} from "_helpers";

export default function () {
    const message = useSelector(state => state.messages.nextMessage);
    const loadingNextMessage = useSelector(state => state.messages.loadingNextMessage);
    const originalConversation = useSelector(state => state.fullEmailConversation.emailConversationData);
    const originalConversationList = _.get(originalConversation, 'emailConversation') || [];
    const loadingOriginalConversation = useSelector(state => state.fullEmailConversation.loadingEmailConversation);
    const originalConversationError = useSelector(state => state.fullEmailConversation.error);
    const conversationFromTrendsList = useSelector(state => state.conversation.conversation);
    const loadingFromTrendsConversation = useSelector(state => state.conversation.loading);
    const conversationError = useSelector(state => state.conversation.error);
    const loading = loadingOriginalConversation || loadingFromTrendsConversation || loadingNextMessage;
    const error = originalConversationError || conversationError;
    const userWorkMode = getWorkMode();

    const conversation = useMemo(() => {
        if(!_.isEmpty(message) && !loadingNextMessage) {
            let { id, sourceName, dataSourceHandle, isProactive } = message;
            sourceName = sourceName && sourceName.toLowerCase();
            const displayInApp = _.get(dataSourceHandle, 'displayInApp');
            const handleName = _.get(dataSourceHandle, 'name');
            const hasTrendsData = !loadingFromTrendsConversation && conversationFromTrendsList.length > 0;

            if(isViewOriginalSupported(sourceName, handleName, displayInApp, isProactive)) {
                if(!loadingOriginalConversation && !_.isEmpty(originalConversation)) {
                    if(!loadingOriginalConversation && originalConversationList.length > 0 && originalConversation.messageId === id) {
                        console.log("building conversation from source", originalConversationList)
                        RefreshToast.close();
                        return buildOriginalConversation(originalConversationList);
                    }
                    /**
                     * Display only the incoming message if the conversation result is empty
                     */
                    console.log("loadingOriginalConversation: ", loadingOriginalConversation)
                    console.log("originalConversation: ", originalConversation)
                    console.log("building conversation from message", message)
                    if (_.get(dataSourceHandle, 'displayInApp')){
                        RefreshToast.showMessage('Refresh to fetch conversation');
                    }
                    return buildConversation([message]);
                }
            } else if(isProactive) {
                console.log("building conversation with proactive message")
                return buildConversation([message]);
            } else if(hasTrendsData) {
                console.log("conversationFromTrendsList: ", conversationFromTrendsList)
                console.log("building conversation from trends")
                return buildConversation(conversationFromTrendsList);
            }
            /**
             * Display only the incoming message if the user is in practice mode or when fetching the conversation fails
             */
            if((userWorkMode === workMode.PRACTICE || (!_.isEmpty(error)) ||
                (_.isEmpty(conversationFromTrendsList) && _.isEmpty(originalConversationList)) && !loading)) {
                console.log("userWorkMode: ", userWorkMode)
                console.log("error: ", error)
                console.log("building conversation from message due to practice mode or error")
                return buildConversation([message]);
            }
        }
    }, [loading, _.get(originalConversation, 'messageId')]) || [];

    return { message, conversation, loading };
}