import ConnectChannelButton from "../Utilities/ConnectChannelButton";
import React, {Fragment} from "react";
import shopifyLogo from "components/images/logos/logo_shopify.svg";
import ShopifySetupModalForm from "./ShopifySetupModalForm";
import BasicModal from "../../../Utilities/BasicModal";

export default function ShopifySetup(){

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const performAction = () => {
    handleOpen();
  };

  return(
    <Fragment>
      <ConnectChannelButton
        label={'Connect with Shopify'}
        channelIcon={shopifyLogo}
        action={performAction}/>

      <BasicModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        title={'Connect with Shopify'}>

        <ShopifySetupModalForm
          description={'Please copy and paste your Shopify subdomain here:'}
          placeholder={'yoursubdomain'}
          id={'yoursubdomain'}
          channelDomain={'.myshopify.com'}
          buttonLabel={'Submit'}
          openInNewTab={true}
          handleClose={handleClose}
          callbackPath={'/signup/shopify-redirect'}
        />

      </BasicModal>
    </Fragment>
  )
}