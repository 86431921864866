import React, {Component} from 'react';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'components/../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


/**
 * @author: Chris Okebata
 */

class TextEditor extends Component {

  constructor(props){
    super(props);

    const blocksFromHtml = htmlToDraft(this.props.defaultText || '');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const textEditorState = EditorState.createWithContent(contentState);

    this.state = {
      editorState: textEditorState,
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });

    const {
      isEmail,
      handleTextChange,
      input,
      useWithRedux,
      outputHtml,
    } = this.props;

    if (isEmail) {
      handleTextChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    } else {
      if (handleTextChange) {
        handleTextChange(editorState.getCurrentContent().getPlainText())
      } else {
        if (useWithRedux) {
          if (outputHtml){
            input.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
          } else {
            input.onChange(editorState.getCurrentContent().getPlainText())
          }
        }
      }
    }
  };

  clearEditor = () => {
    const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({editorState});
  };

  render() {
    const { editorState } = this.state;
    const meta = this.props.meta || {};
    const {touched, error, warning} = meta;
    const { isEmail, validateForm} = this.props;
    const options = isEmail ? ['inline', 'link', 'emoji', 'history'] : ['inline', 'emoji', 'history'];

    return (
      <div style={{width: '100%', zIndex: '0'}}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          stripPastedStyles={true}
          toolbar={{
            options: options,
            inline: {
              options: ['bold'],
            },
          }}
        />

        {validateForm &&
        <div className="font-12 color-danger">
          {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
        }
      </div>
    );
  }
}

export {TextEditor}