import React, {useState, useEffect} from "react";
import {Box} from "@mui/system";
import {Divider, Grid} from "@mui/material";
import {connect, useDispatch, useSelector} from "react-redux";
import {Field, reduxForm} from "redux-form";
import _ from "lodash";
import styles from "../Utilities/templateStyles";
import BasicFormTextField from "../../../common/BasicFormTextField";
import BasicMultilineTextField from "../../../common/BasicMultilineTextField";
import TextButton from "../../../common/TextButton";
import ActionButton from "../../../common/ActionButton";
import TemplateField from "../Utilities/TemplateField";
import {minLength, required, validateCharLength} from "_helpers";
import {SnackBar} from "../../../../common";
import {buildGenerateRepliesPayload} from "../Utilities/CampaignUtils";
import {proactiveActions} from "_actions";
import IconAdd from "../../../common/IconAdd";
import {disabledGenerateResponsesChannels} from "../../../../../_constants";
import ChannelSelect from "../../../common/ChannelSelect";
import TemplateTextEditor from "../Utilities/TemplateTextEditor"

function CreateCriteriaForm({handleSubmit, handleCriteria, clearSelectedReplies, handleChannelSelection}) {
    const dispatch = useDispatch();
    let [fieldValues, setFieldValues] = useState({
        minDaysBetweenSameReplyUsages: '30'
    });

    const proactive = useSelector(state => state.proactive);
    const loadingGenerateReplies = proactive.loadingGenerateReplies;
    const [generateRepliesVisible, setGenerateRepliesVisible] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState("");
    const isResponseDisabled = disabledGenerateResponsesChannels.includes(selectedChannel)

    const handleFieldChange = (event) => {
        const fieldName = event.target.name;
        const value = event.target.value;
        if(!_.isEmpty(value)) Object.assign(fieldValues, { [fieldName] : value });
        else delete fieldValues[fieldName];
        setFieldValues(fieldValues);
        handleCriteria(fieldValues);
    }

    const handleRichTextChange = (fieldName, value) => {
        if(!_.isEmpty(value)) Object.assign(fieldValues, {[fieldName]: value})
        else delete fieldValues[fieldName];
        setFieldValues(fieldValues);
        handleCriteria(fieldValues);
    }

    const handleChannelChange = (event) => {
        handleFieldChange(event)
        handleChannelSelection(event.target.value)
        setSelectedChannel(event.target.value)
        if (disabledGenerateResponsesChannels.includes(event.target.value)) {
            setGenerateRepliesVisible(false)
        } else {
            setGenerateRepliesVisible(true)
        }
    }

    const [templateList, setTemplateList] = useState([
        <TemplateField required={required} index={0} handleChange={handleFieldChange}/>
    ]);

    const handleGenerateReplies = (values) => {
        const payload = buildGenerateRepliesPayload(values)
        dispatch(proactiveActions.generateReplies(payload));
    };

    const addTemplate = () => {
        const templateCount = templateList.length;
        if(templateCount >= 3) {
            SnackBar.showMessage("You have reached the maximum number of templates");
            return
        }

        const template = isResponseDisabled ?
            <TemplateTextEditor index={templateCount} handleChange={handleRichTextChange} /> :
            <TemplateField required={required} index={templateCount} handleChange={handleFieldChange} />;
        setTemplateList(templateList.concat(template));
    }

    const renderTemplates = (template, index) => {
        return <Box key={index}>{template}</Box>
    }

    useEffect(() => {
        if(selectedChannel){
            const template = isResponseDisabled ?
                <TemplateTextEditor index={0} handleChange={handleRichTextChange} /> :
                <TemplateField required={required} index={0} handleChange={handleFieldChange} />;
            setTemplateList([template])
        }
    },[selectedChannel])

    return(
        <>
            <Grid item xs={12} md={isResponseDisabled ? 12 : 6} sx={{...styles.criteriaContainer, borderRight: isResponseDisabled ? "" : containerStyle}}>
                <form name="form" onSubmit={handleSubmit(handleGenerateReplies)} autoComplete="off">
                    <Box sx={styles.heading}>
                        Criteria
                    </Box>
                    <Box sx={styles.nameInputContainer}>
                        <Box>
                            <Box sx={styles.inputDesc}>Message template name</Box>
                            <Field
                                name="name"
                                component={BasicFormTextField}
                                placeholder={'Enter name'}
                                onChange={handleFieldChange}
                                validate={required}
                            />
                        </Box>
                        <Box sx={styles.channelInputContainer}>
                            <Box>
                                <Box sx={styles.inputDesc}>Template channel</Box>
                                <Field
                                    name="channel"
                                    component={ChannelSelect}
                                    handleChange={handleChannelChange}
                                    placeholder={'Select channel'}
                                />
                            </Box>
                        </Box>
                        <Box sx={styles.topicInputContainer}>
                            <Box sx={styles.inputDesc}>Topic</Box>
                            <Field
                                name="topic"
                                component={BasicMultilineTextField}
                                rowsCount={4}
                                placeholder={'Enter topic'}
                                onChange={handleFieldChange}
                                validate={validateCharLength}
                            />
                        </Box>
                        {generateRepliesVisible &&
                            <Box sx={styles.durationInputContainer}>
                                <Box sx={styles.inputDesc}>Days between same user replies</Box>
                                <Field
                                    name="minDaysBetweenSameReplyUsages"
                                    component={BasicFormTextField}
                                    placeholder={'Enter the number of days'}
                                    onChange={handleFieldChange}
                                    props={{type: 'number', min: 0}}
                                    validate={required}
                                />
                            </Box>
                        }
                        <Divider orientation={'horizontal'} flexItem sx={styles.sectionDivider}/>
                        {templateList.map((template, index) => renderTemplates(template, index))}
                        <Box>
                            <TextButton
                                label={'Add another template'}
                                action={addTemplate}
                                startIcon={<IconAdd/>}
                            />
                        </Box>
                        {generateRepliesVisible &&
                            <Box sx={styles.generateRepliesButtonContainer}>
                                <ActionButton
                                    label={'Generate replies'}
                                    action={clearSelectedReplies}
                                    disabled={loadingGenerateReplies}
                                    withIcon={false}
                                />
                            </Box>
                        }
                    </Box>
                </form>
            </Grid>
        </>
    )
}

const containerStyle = {md: '1px solid #CED4D4', lg: '1px solid #CED4D4'}

function validate(values) {
    const errors = {};
    const {channel} = values;
    if(_.isEmpty(_.toString(channel)) || channel === 'none') errors.channel = 'invalid channel';
    return errors;
}

const formConfig = {
    form: 'CreateCriteriaForm',
    validate
};

CreateCriteriaForm = reduxForm(formConfig)(CreateCriteriaForm)
CreateCriteriaForm = connect(state => (
    {
        initialValues: {
            minDaysBetweenSameReplyUsages: '30'
        }
    }),
)(CreateCriteriaForm);
export default CreateCriteriaForm;