import React, {Fragment, useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {customerIoAuthActions, msgSourceActions} from "_actions";
import {Box} from "@mui/material";
import {Input} from "../../Utilities/Input";
import basicModalStyles from "../../Utilities/basicModalStyles";
import SetupModalBottomBar from "../../Utilities/SetupModalBottomBar";
import {isEmpty} from "lodash";

function CustomerIoSetupModalForm({description, buttonLabel, extraInfoLabel, openInNewTab, handleSubmit}) {
    const dispatch = useDispatch();
    const customerIo = useSelector((state) => state.customerIoAuth)

    const [disabled, setDisabled] = React.useState(true);
    const [apiKeyError, setApiKeyError] = React.useState(undefined)
    const [nameError, setNameError] = React.useState(undefined)
    const loading = React.useMemo(() => customerIo.loading && !disabled, [customerIo]);

    const placeholders = {
        name: 'Name',
        apiKey: 'API Key'
    }

    const errorObject = (message) => {
        return {
            touched: true,
            error: message
        }
    }

    useEffect(() => {
        dispatch(msgSourceActions.getMsgSourceLink('customerio'))
    }, []);

    const validateApiKey = (apiKey) => {
        if (!apiKey) {
            setApiKeyError(errorObject("API Key value is required"))
            return false
        } else {
            setApiKeyError(undefined)
            return true
        }
    }

    const validateName = (name) => {
        if (!name) {
            setNameError(errorObject("Name value is required"))
            return false
        } else {
            setNameError(undefined)
            return true
        }
    }

    const onSubmit = (props) => {
        if (!validateName(props.name) | !validateApiKey(props.apiKey)) {
            return
        }

        const params = {
            name: props.name,
            apiKey: props.apiKey
        };
        dispatch(customerIoAuthActions.saveAccount(params))
    };

    const performAction = (value) => {
        setDisabled(isEmpty(value));
    };

    return (
        <Fragment>
            <Box sx={basicModalStyles.container}>
                <Box sx={basicModalStyles.description}>
                    {description}
                </Box>

                <form name="customerIoForm" onSubmit={handleSubmit(onSubmit)}>
                    <Field
                        name={'name'}
                        component={Input}
                        placeholder={placeholders.name}
                        meta={nameError}
                        performAction={performAction}
                    />

                    <Field
                        name={'apiKey'}
                        component={Input}
                        placeholder={placeholders.apiKey}
                        meta={apiKeyError}
                        performAction={performAction}
                    />

                    <SetupModalBottomBar
                        extraInfoLabel={extraInfoLabel}
                        openInNewTab={openInNewTab}
                        buttonLabel={buttonLabel}
                        disabled={loading || disabled}
                        loading={loading}
                    />
                </form>
            </Box>
        </Fragment>
    )
}

const formConfig = {
    form: 'customerIoForm',
};

export default reduxForm(formConfig)(CustomerIoSetupModalForm);