import { SERVER_URL } from 'config';
import { authHeader, handleHttpResponse, objectToQueryString } from '_helpers';


function initiateOauth(callbackUrl) {
  return fetch(`${SERVER_URL}/api/frontapp/oauthCode?callbackUrl=${callbackUrl}`,
    {
      method: 'GET',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

function oauthAccessToken(params) {
  return fetch(`${SERVER_URL}/api/frontapp/oauthToken?${objectToQueryString(params)}`,
    {
      method: 'POST',
      headers: authHeader(),
    })
    .then(handleHttpResponse);
}

function companyInboxes(formData) {
  return fetch(`${SERVER_URL}/api/frontapp/inboxes`,
    {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(formData),
    })
    .then(handleHttpResponse);
}

function getFrontChatData() {
    return fetch(`${SERVER_URL}/api/frontapp/chatData`,
        {
            method: 'GET',
            headers: authHeader(),
        })
        .then(handleHttpResponse);
}

export const frontAppOauthService = {
  initiateOauth,
  oauthAccessToken,
  companyInboxes,
  getFrontChatData
};
