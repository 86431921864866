import {alertMessages, utilityConstants} from "_constants";
import {SnackBar} from "../components/common";
import {utilityService} from "_services";
import {triggerBase64Download} from 'common-base64-downloader-react';
import randomstring from 'randomstring';
import mime from 'mime-types';

export const utilityActions = {
    getVerticals,
    copyToClipboard,
    getRootUrl,
    getCurrentPath,
    getLanguages,
    setVerticalScrollComplete
};


function getVerticals() {

    return dispatch => {
        dispatch(request());

        utilityService.getVerticals()
            .then(verticals => {
                    dispatch(success(verticals))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function success(verticals) {
        return {type: utilityConstants.GET_VERTICALS_SUCCESS, payload: verticals}
    }

    function request() {
        return {type: utilityConstants.GET_VERTICALS_REQUEST}
    }

    function failure(error) {
        return {type: utilityConstants.GET_VERTICALS_FAILURE, payload: error}
    }
}

function setVerticalScrollComplete(status) {
    return dispatch => {
        dispatch(setStatus(status));
    };
    function setStatus(status) {
        return {type: utilityConstants.SET_VERTICAL_SCROLL_COMPLETE, status: status}
    }
}

function getLanguages() {

    return dispatch => {
        dispatch(request());

        utilityService.getLanguages()
            .then(
                languages => dispatch(success(languages)),
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function success(languages) {
        return {type: utilityConstants.GET_LANGUAGES_SUCCESS, payload: languages}
    }

    function request() {
        return {type: utilityConstants.GET_LANGUAGES_REQUEST}
    }

    function failure(error) {
        return {type: utilityConstants.GET_LANGUAGES_FAILURE, payload: error}
    }
}

function copyToClipboard(event, textArea) {
    return () => {
        textArea.select();
        document.execCommand('copy');
        event.target.focus();
    };
}

function getRootUrl() {
    return window.location.origin
}

function getCurrentPath() {
    return `${window.location.pathname}${window.location.search}`;
}

export function copyTextToClipboard(textId) {
    const textToBeCopied = document.getElementById(textId).innerHTML;
    if (!navigator.clipboard) {
        try {
            const textArea = document.createElement('textarea');
            textArea.value = textToBeCopied;
            copyFromTextAreaToClipboard(textArea);
            SnackBar.showMessage(alertMessages.COPY_CUSTOMER_NAME_SUCCESS);
        } catch (e) {
            SnackBar.showMessage(alertMessages.COPY_CUSTOMER_NAME_FAILURE);
        }
    } else {
        navigator.clipboard.writeText(textToBeCopied).then(function () {
            SnackBar.showMessage(alertMessages.COPY_CUSTOMER_NAME_SUCCESS);
        }, function (e) {
            SnackBar.showMessage(alertMessages.COPY_CUSTOMER_NAME_FAILURE);
        });
    }
}

export function copyTicketLinkToClipboard(ticketLinkId) {
    const linkToBeCopied = document.getElementById(ticketLinkId).getAttribute("href");
    if (!navigator.clipboard) {
        alert('Your browser doesn\'t support copy to clipboard');
        try {
            const textArea = document.createElement('textarea');
            textArea.value = linkToBeCopied;
            copyFromTextAreaToClipboard(textArea);
            SnackBar.showMessage(alertMessages.COPY_TICKET_LINK_SUCCESS);

        } catch (e) {
            SnackBar.showMessage(alertMessages.COPY_TICKET_LINK_FAILURE);
        }
    } else {
        navigator.clipboard.writeText(linkToBeCopied).then(function () {
            SnackBar.showMessage(alertMessages.COPY_TICKET_LINK_SUCCESS);
        }, function (e) {
            SnackBar.showMessage(alertMessages.COPY_TICKET_LINK_FAILURE);
        });
    }
}

function copyFromTextAreaToClipboard(textArea) {
    textArea.setAttribute('readonly', '');
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(textArea);
}

export function copyValueToClipboard(textValue, onCopySuccessToast = '') {
    if (!navigator.clipboard) {
        SnackBar.showMessage(alertMessages.COPY_VALUE_FAILURE);
    } else {
        navigator.clipboard.writeText(textValue)
            .then(() => {
                SnackBar.showMessage(onCopySuccessToast || alertMessages.COPY_VALUE_SUCCESS);
            }, (e) => {
                SnackBar.showMessage(alertMessages.COPY_VALUE_SUCCESS);
            });
    }
}

/**
 * Download the base 64 string as an image
 * @param base64
 * @param mimeType
 * @returns {string}
 */
export function downloadBase64File(base64, mimeType) {
    if (!base64 || !mimeType) return "";
    const newFileName = `chatdesk-${randomstring.generate(12)}`;
    const data = `data:${mimeType};base64,${base64}`;
    triggerBase64Download(data, newFileName);
}

/**
 * Get mime from a file name
 * @param filename
 * @returns {boolean|string}
 */
export function getMimeFromFile(filename) {
    if (!filename) return "";
    return mime.lookup(filename);
}

/**
 * Extract a Base64 String from data
 * @param base64Data
 * @returns {string}
 */
export const getBase64StringFromData = (base64Data) => {
    return base64Data.split('base64,')[1]
};