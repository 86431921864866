import importedStyles from "../Utilities/styles";

export default {
    root: {
        ...importedStyles.root,
        backgroundColor: '#EFEAE7',
        pt: 16,
        pb: 10,
        minHeight: '100vh',
    },
    header: {
        borderBottom: '1px solid #CED4D4',
        padding: '15px 20px',
        fontFamily: 'LarsseitMedium',
        fontSize: '18px',
        fontWeight: 500,
        color: '#0C2728',
    },
    container: {
        px: { xs: 2, sm: 6 },
        maxWidth: '1000px',
        position: 'relative',
    },
    containerBody: {
        backgroundColor: '#FFF',
        border: '1px solid #CED4D4',
        borderRadius: '5px',
        overflow: 'hidden',
    }
}