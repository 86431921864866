import {messageActions, voteActions} from "_actions";
import {cannotResolveOptionType} from "_constants";
import _ from "lodash";

export function submitReason(dispatch, payload, optionType) {
    if(_.isEmpty(payload)) return;
    switch (optionType) {
        case cannotResolveOptionType.ESCALATE: return dispatch(messageActions.escalate(payload));
        case cannotResolveOptionType.NO_RESPONSE_NEEDED: return dispatch(messageActions.skipMessage(payload));
        case cannotResolveOptionType.SKIPPED: return dispatch(voteActions.submitResponse(payload));
        case cannotResolveOptionType.INTERNAL_NOTE: return dispatch(messageActions.submitInternalNote(payload));
        case cannotResolveOptionType.REROUTE: return dispatch(messageActions.rerouteMessage(payload));
    }
}